import React , {useState} from 'react';
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {
  authValid ,
  dataAccessLoading , dataValid ,
  formatNumber ,
  initModal ,
  loadingRemove , numberLocaleString
} from "../../../../../function/function";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import styled from "styled-components";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import RawMaterialMultipleReg from "./RawMaterialMultipleReg";

const RawMaterialsRegistration = () => {
  const {t}:any = useTranslation()
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [accountCode , setAccountCode] = useState<any>({})
  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
  const [rawMaterialState, setRawMaterialState] = useState<any>({
                                                                    rawMaterialSerialCode: '' ,
                                                                    rawMaterialName: '' ,
                                                                    material: '' ,
                                                                    thickness: '' ,
                                                                    width: '' ,
                                                                    length: '' ,
                                                                    rawMaterialUnit: 'ea' ,
                                                                    materialType: '' ,
                                                                    rawMaterialStock: '' ,
                                                                    customer: '' ,
                                                                    standardDate: '' ,
                                                                    codeDoubleCheck: false ,
                                                                  })
  const [multipleReg, setMultipleReg] = useState<boolean>(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string) => {
    const {name, value} = e.target
    setRawMaterialState({...rawMaterialState,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? formatNumber(value).replace(/,/g,'')  :
          value.trim()})
  }

  const serialDoubleCheck = async (serialCode: string) => {
    if (!serialCode) {
      setDataModal({ menu: 'confirm', message: '원자재 CODE를 입력해 주세요.' });
      return;
    }

    try {
      const response = await client.post('/rawMaterial/serial/check', {
        companyCode: selectedCompany.companyCode,
        rawMaterialSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 원자재 CODE 입니다.' });
        setRawMaterialState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
        }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 원자재 CODE 입니다.' });
        setRawMaterialState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };


  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      serialDoubleCheck(rawMaterialState.rawMaterialSerialCode).then()
    }
  }


  const rawMaterialRegisterRes = async () => {
    if( !rawMaterialState.rawMaterialSerialCode ){
      setDataModal( {menu: 'confirm' , message: '원자재 CODE를 입력해 주세요.'} )

    }else if( !rawMaterialState.codeDoubleCheck ){
        setDataModal( {menu: 'confirm' , message: '원자재 CODE 중복 확인을 해주세요.'} )
    }
    else if( !rawMaterialState.rawMaterialName ){
      setDataModal( {menu: 'confirm' , message: '원자재 품명을 입력해 주세요.'} )
    } else if (!rawMaterialState.rawMaterialUnit){
      setDataModal( {menu: 'confirm' , message: '원자재 단위를 입력해 주세요.'} )
    } else {
      try {
        dataAccessLoading()
        const response = await client.post('/rawMaterial/register' , [{
          ...rawMaterialState ,
          companyCode: selectedCompany.companyCode }] , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setDataModal({menu: 'success' , message: '원자재 정보가 등록 되었습니다.'})
            loadingRemove()
          } , 500)
          setTimeout(() => {
            window.location.href = authValid()? '/admin/fas/rawmaterial' : '/fas/rawmaterial'
          } , 1000)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = authValid() ? '/admin/fas/rawmaterial' : '/fas/rawmaterial'}}
          />
      }
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => setDataModal( initModal )}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {
                                if( dataModal.index === 0 ){
                                  Object.assign( rawMaterialState ,
                                                 {
                                                   customer: accountCode.customerName ,
                                                   customerName: accountCode.customerName
                                                 } )
                                } else{
                                  Object.assign( rawMaterialState , {
                                    customer: accountCode.customerName ,
                                    customerName: accountCode.customerName
                                  } )
                                }
                                setDataModal( initModal )
                              }}
                              cancelEvent={() => {
                                setDataModal( initModal )
                                setAccountCode( {} )
                              }}
                              receiveData={setAccountCode}/>}
      <div style={{display: 'flex' , justifyContent: 'space-between'}}>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 원자재 기본정보 > 등록' + ( multipleReg ? ' (다중)' : '' )}
                   unitCase={'MenuTitle'}/>
        {multipleReg ?
          <div style={{display: 'flex'}}>
            <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                         clickEvent={() => {
                           setDataModal( {
                                           menu: 'cancel' ,
                                           message: '목록으로 이동 하시겠습니까?'
                                         } )
                         }}/>
            <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                         clickEvent={() => setMultipleReg( false )}/>
          </div>
          :
          <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                       clickEvent={() => setMultipleReg( true )}/>
        }
      </div>
        <ContentFrame height={'680px'} padding={'30px 30px 18px'}>
          {multipleReg ? <RawMaterialMultipleReg/>
            :
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'원자재 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'}
                                   clickEvent={() => {
                                     setDataModal( {
                                                     menu: 'cancel' ,
                                                     message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                   } )
                                   }}/>
                      <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                   clickEvent={rawMaterialRegisterRes}/>
                    </div>
                  </div>
                </div>
                <RegisterComponent type={'doubleCheck'} onKeyPress={onKeyPress} paddingRight={true}
                                   title={'원자재 CODE'} name={'rawMaterialSerialCode'}
                                   buttonName={rawMaterialState.codeDoubleCheck ? '확인됨' : '중복확인'}
                                   disabled={( !rawMaterialState.rawMaterialSerialCode || rawMaterialState.codeDoubleCheck )}
                                   value={rawMaterialState.rawMaterialSerialCode}
                                   required={true}
                                   onChange={( e ) => setRawMaterialState( ( prev: any ) => ( {
                                     ...prev ,
                                     rawMaterialSerialCode: e.target.value ,
                                     codeDoubleCheck: false
                                   } ) )}
                                   clickEvent={() => {serialDoubleCheck( rawMaterialState.rawMaterialSerialCode ).then()}}/>

                <RegisterComponent type={'text'} title={'원자재 품명'} name={'rawMaterialName'}
                                   value={rawMaterialState.rawMaterialName} paddingRight={true}
                                   required={true}
                                   onChange={onChange}/>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                  <RegisterComponent type={'text'} title={'재질'} name={'material'} value={rawMaterialState.material}
                                     paddingRight={true} onChange={onChange}/>

                  <RegisterComponent type={'drop'} title={'재질 종류'}
                                     name={'materialType'} value={rawMaterialState.materialType}
                                     onChange={onChange}
                                     dropClickEvent={( v ) => {
                                       setRawMaterialState( ( prev: any ) => ( {
                                         ...prev ,
                                         materialType: v ,
                                         rawMaterialUnit: v === 'COIL' || v === 'SHEET' ? 'kg' : 'ea' ,
                                         rawMaterialStock: '' ,
                                         length: v === 'COIL' ? '' : prev.length,
                                         standard: (v === '소재' || v === '절단')? 'ø' : '',
                                       } ) )
                                     }}
                                     dropArray={['COIL' , 'SHEET', '소재', '절단']}/>

                  <RegisterComponent type={'empty'} title={'-'} name={'empty'} value={'-'}
                                     paddingRight={true}
                                     onChange={( e ) => {onChange( e , 'number' )}}/>

                  <RegisterComponent type={!(rawMaterialState.materialType === '소재' || rawMaterialState.materialType === '절단')? 'empty' : 'drop'}
                                     title={'규격'}
                                     name={'standard'} value={dataValid(rawMaterialState.standard)}
                                     onChange={onChange}
                                     disabled={!(rawMaterialState.materialType === '소재' || rawMaterialState.materialType === '절단')}
                                     dropClickEvent={( v ) => {
                                       setRawMaterialState( ( prev: any ) => ( {
                                         ...prev ,
                                         standard: v,
                                       } ) )
                                     }}
                                     dropArray={['ø','六','□']}/>

                  <RegisterComponent type={'unit'} title={'가로'} name={'width'}
                                     value={dataValid( rawMaterialState.width , '0' )}
                                     unit={'mm'} paddingRight={true} limit={15}
                                     onChange={( e: any ) => {onChange( e , 'decimal' )}}/>
                  <RegisterComponent type={'unit'} title={'세로'} name={'length'}
                                     value={dataValid( rawMaterialState.length , '0' )}
                                     unit={'mm'} paddingRight={true}
                                     disabled={rawMaterialState?.materialType === 'COIL'} limit={15}
                                     onChange={( e: any ) => {onChange( e , 'decimal' )}}/>
                  <RegisterComponent type={'unit'} title={'두께'} name={'thickness'}
                                     value={dataValid( rawMaterialState.thickness , '0' )}
                                     unit={'T'} paddingRight={true} limit={15}
                                     onChange={( e: any ) => {onChange( e , 'decimal' )}}/>
                  <RegisterComponent type={'drop'} title={'단위'} name={'rawMaterialUnit'}
                                     value={rawMaterialState.rawMaterialUnit}
                                     required={true}
                                     dropClickEvent={
                                       ( v ) => {
                                         setRawMaterialState( ( prev: any ) => ( {
                                           ...prev ,
                                           rawMaterialUnit: v ,
                                           rawMaterialStock: ''
                                         } ) )
                                       }
                                     }
                                     dropArray={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>

                  <RegisterComponent type={'unit'} title={'안전 재고량'} name={'rawMaterialStock'} paddingRight={true}
                                     limit={15}
                                     value={rawMaterialState?.rawMaterialUnit !== 'ea' ? rawMaterialState?.rawMaterialStock : numberLocaleString( rawMaterialState.rawMaterialStock )}
                                     unit={rawMaterialState.rawMaterialUnit}
                                     onChange={( e: any ) => {onChange( e , rawMaterialState?.rawMaterialUnit === 'ea' ? 'number' : 'decimal' )}
                                     }/>
                  <RegisterComponent type={'unit'} title={'사용 기준일'} name={'standardDate'} limit={9}
                                     value={numberLocaleString( rawMaterialState?.standardDate )} unit={'일'}
                                     paddingRight={true}
                                     onChange={( e ) => {onChange( e , 'number' )}}/>
                </div>
                  <RegisterComponent type={'search'} title={'거래처명'} name={'customerName'}
                                     value={rawMaterialState.customer}
                                     paddingRight={true}
                                     bottomBorder={true}
                                     onChange={() => {}} clickEvent={() => {
                    setDataModal( {menu: 'account' , index: 0} )
                  }}/>

              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              <div className={'scroll_comp'} style={{width: '379px' , height: '600px'}}></div>
            </div>}
        </ContentFrame>
    </SectionFrame>
);
};
export default RawMaterialsRegistration;


const RegisterComp = styled.div`
  width: 784px;
  
  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
 `
