import React , {useState} from 'react';
import {
  ContentFrame ,
  NoData ,
  SectionFrame , validationValue
} from "../../../style/StyleComponent";
import MenuTitle from "../../../components/title/MenuTitle";
import client from "../../../common/Config";
import {dataValid , initError} from "../../../function/function";
import {Authorization} from "../../../common/authorization";
import {Error_Type} from "../../../types/Fundamental";
import ListInnerInput from "../../../components/input/ListInnerInput";
import {GridTableComponent, GridTableSelect} from "../../../style/StyleModule";

const RankPageContainer = () => {

  const [errorState,setErrorState] = useState<Error_Type>(initError)
  const [logDataList, setLogDataList] = useState<any[]>([])
  const logMenu = ['순위', '업체명', '총 조회 횟수', '로그인 횟수', '최다 접속 페이지', '최다 페이지 접속 횟수']

  const dataLogListRes = async ()=> {
    try {
      const response = await client.get('/data/log/top',{headers:{Authorization}})
      const data = response.data.data
      if(response.data.status === 200){
        setLogDataList(data)
        setErrorState({notice: data.length === 0? '로그 정보가 없습니다.' : '로그 정보를 가져올 수 없습니다.'})
      }
      else{
        setErrorState({notice:'로그 정보를 가져올 수 없습니다.'})
        setLogDataList([])
      }
    }
    catch (e) {
      setErrorState({notice:'* 서버 에러 입니다. 관리자에게 문의해 주세요.'})
      console.log(e)
    }
  }
  React.useEffect(()=>{
    dataLogListRes().then()
  },[])

  return (
    <React.Fragment>
      <SectionFrame>
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <MenuTitle title={'금주 접속페이지 순위'} unitCase={'MenuTitle'}/>
        </div>
        <ContentFrame padding={'30px 30px 20px 30px'} justify={'space-between'}>
          <div>
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
              <MenuTitle title={'금주 접속페이지 순위'} unitCase={'MenuCompTitle'}/>
            </div>
            <GridTableComponent tempCol={'80px 208px repeat(2, 148px) 1fr 168px'}
                                scrollY_Hide={true} scrollX_Hide={true}
                                scrollWidth={1210}
                                height={538}>

              <div className={'grid-title-unchecked'}>
                {logMenu.map((v)=>(
                  <div key={v}>{v}</div>))}
              </div>
              {logDataList?.length === 0?
                <NoData>{dataValid(errorState.notice,'목록을 가져오는 중 입니다.')}</NoData>
                :
                logDataList?.map((v:any, i:number)=> (
                  <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                    <div>{i+1}</div>
                    {validationValue(v.companyName)}
                    <ListInnerInput type={'noneInputUnit'} inputData={v.count} unit={'회'}/>
                    <ListInnerInput type={'noneInputUnit'} inputData={v.loginCount} unit={'회'}/>
                    {validationValue(v.frequentMenu)}
                    <ListInnerInput type={'noneInputUnit'} inputData={v.frequentMenuCount} unit={'회'}/>
                  </GridTableSelect> ))
              }
            </GridTableComponent>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default RankPageContainer;
