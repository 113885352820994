import React, {useEffect} from 'react';
import {Routes, Route, Navigate, useLocation} from "react-router-dom";
import Notiflix from "notiflix";
import {useRecoilState} from "recoil";
import {companySolutionFilter} from "../common/StateManage";
import client from '../common/Config'
import {Authorization} from '../common/authorization'

import FasMain from "../pages/user/fas/FasMain";
import FasFactory from "../pages/common/fas/FasFactory";
import FasFacility from "../pages/common/fas/FasFacility";
import FasNotice from "../pages/user/fas/FasNotice";

import AdminFasMain from "../pages/admin/fas/AdminFasMain"
import AdminFasPartners from "../pages/admin/fas/AdminFasPartners";
import AdminFasCompanyBasic from "../pages/admin/fas/AdminFasCompanyBasic";

import AdminFasLayOut from "../pages/admin/fas/AdminFasLayOut";

import AdminFasCompanyNotice from "../pages/admin/fas/AdminFasCompanyNotice";

import AdminFasEventNotice from "../pages/admin/fas/AdminFasEventNotice";
import AdminFasWiki from "../pages/admin/fas/AdminFasWiki";

import FasProduct from "../pages/common/fas/FasProduct";
import FasProcess from "../pages/user/fas/FasProcess";

import FasWiki from "../pages/user/fas/FasWiki";

import AdminFasCompanyProcess from "../pages/admin/fas/AdminFasCompanyProcess";

import AdminFasSolutionReq from "../pages/admin/fas/AdminFasSolutionReq";

import AdminFasFacilityDaily from "../pages/admin/fas/AdminFasFacilityDaily";

import FasAccount from "../pages/common/fas/FasAccount";
import FasDefect from "../pages/common/fas/FasDefect";
import FasDocument from "../pages/common/fas/FasDocument";
import FasRawMaterial from "../pages/common/fas/FasRawMaterial";
import FasSubMaterial from "../pages/common/fas/FasSubMaterial";
import FasTypeProcess from "../pages/common/fas/FasType_Process";
import FasAccountModel from "../pages/common/fas/FasAccountModel";
import FasPeripherals from "../pages/common/fas/FasPeripherals";
import FasMold from "../pages/common/fas/FasMold";

import FasBarcode from "../pages/common/fas/FasBarcode";
import FasPause from "../pages/common/fas/FasPause";
import FasEventNotice from '../pages/user/fas/FasEventNotice';

import FasLayout from "../pages/common/fas/FasLayout";
import AdminFasBusiness from "../pages/admin/fas/AdminFasBusiness";
import FasMyPage from "../pages/common/fas/FasMyPage";
import AdminFasPartnerPermission from "../pages/admin/fas/AdminFasPartnerPermission";
import AdminFasPartnerUser from "../pages/admin/fas/AdminFasPartnerUser";
import AdminFasCompanyBasicPermission from "../pages/admin/fas/AdminFasCompanyBasicPermission";
import AdminFasCompanyBasicUser from "../pages/admin/fas/AdminFasCompanyBasicUser";
import FullScreenProcess from '../containers/common/fas/FullScreenProcess'
import SmartFactoryLogPage from '../pages/admin/SmartFactoryLogPage'

import RankConnectPage from "../pages/admin/RankConnectPage";
import LoginCheckContainer from "../containers/common/LoginCheckContainer";

const Router = () => {
	const location = useLocation()
	const realDate = + new Date()
	const [,setSolutionFilter] = useRecoilState(companySolutionFilter)

	const solutionURL = (url:string) => {
		if(url.includes('fas')){
			return 'FAS'
		}
		else if(url.includes('pbas')){
			return 'P-BAS'
		}
		else if(url.includes('pms')){
			return 'P-MS'
		}
		else if(url.includes('vms')){
			return 'V-MS'
		}
		else if(url.includes('fems')){
			return 'F-EMS'
		}
		else if(url.includes('mes')){
			return 'MES'
		}
		else{
			return 'FAS'
		}
	}

	const logoutClick = async () => {
		if(window.localStorage.getItem('auth') === 'company' || window.localStorage.getItem('auth') === 'system'){
			try {
				const response = await client.post('/logOut',{
					id: window.localStorage.getItem('companyID'),
					password:'@'
				},{headers:{Authorization}})
			}catch (e) {
				console.log(e)
			}
			finally {
				window.localStorage.clear()
				setTimeout(()=>{
					window.location.replace('https://test.z-fas.com')
					// window.location.replace('http://localhost:3000')
				},500)
			}
		}else{
			window.localStorage.clear()
			setTimeout(()=>{
				window.location.replace('https://test.z-fas.com')
				// window.location.replace('http://localhost:3000')
			},500)
		}
	}


	useEffect(()=>{
		setSolutionFilter(solutionURL(location.pathname))
		// @ts-ignore
		if( parseInt(window.localStorage.getItem('timestamp')) < realDate){
			logoutClick().then()
			Notiflix.Report.info('로그인 시간이 만료 되었습니다.','로그인 화면으로 이동합니다.','확인',
													 ()=>{
														 window.location.reload()
													 },{fontFamily:'NanumGothicOTF',  cssAnimationStyle:'zoom', titleFontSize:'18px', messageFontSize:'14px',
														 svgSize:'80px', info:{ svgColor:'#CFFA61',buttonBackground:'#0058ff', backOverlayColor:'rgba(0,88,255,0.2)' } })
		}
	},[])

	if(window.localStorage.getItem('auth') === null){
		return(
			<Routes>
				<Route element={<LoginCheckContainer/>} path={`/${localStorage.getItem('companyID') || ':id'}`}/>
				<Route path={"/*"} element={<Navigate replace to={`/${localStorage.getItem('companyID') || ':id'}`}/>}/>
				<Route path={"/"} element={<Navigate replace to={`/${localStorage.getItem('companyID') || ':id'}`}/>}/>
			</Routes>
		)
	}else{
			if( window.localStorage.getItem( 'tokenInfo' ) === '200' && window.localStorage.getItem( 'auth' )?.includes('admin')){
				{/*충전소 페이지*/}
				return (
					<Routes location={location} key={location.pathname}>
						<Route element={<AdminFasMain/>} path={'/admin/main'}/>
						<Route element={<FasMyPage/>} path={'/admin/myPage/:id'}/>
						<Route element={<FasMyPage/>} path={'/admin/myPage/modify/:id'}/>
						<Route element={<SmartFactoryLogPage/>} path={'/admin/smartlog'}/>
						<Route element={<RankConnectPage/>} path={'/admin/rankpage'}/>
						{/*<Route element={<TestContainer/>} path={'/admin/test'}/>*/}
						{/*	if(window.localStorage.getItem('companyID') === 'charging'){*/}
						{/*	return (*/}
						{/*	<Routes location={location} key={location.pathname}>*/}
						{/*	<Route element={<EmsChargingStation/>}  path={'/admin/ems/charging/station/test'}/>*/}
						{/*	</Routes>*/}
						{/*	)*/}
						{/*}else{*/}

						{/*지사/협력사 */}
						<Route element={<AdminFasPartners/>} path={'/admin/fas/partners'}/>
						<Route element={<AdminFasPartners/>} path={'/admin/fas/partners/register'}/>
						<Route element={<AdminFasPartners/>} path={'/admin/fas/partners/:id'}/>
						<Route element={<AdminFasPartners/>} path={'/admin/fas/partners/modify/:id'}/>
						<Route element={<AdminFasPartners/>} path={'/admin/fas/partners/reg_facility/:id'}/>

						{/*지사/협력사 권한관리*/}
						<Route element={<AdminFasPartnerPermission/>} path={'/admin/fas/partners/auth'}/>
						{/*지사/협력사 유저관리*/}
						<Route element={<AdminFasPartnerUser/>} path={'/admin/fas/partners/user'}/>
						<Route element={<AdminFasPartnerUser/>} path={'/admin/fas/partners/user/register'}/>
						<Route element={<AdminFasPartnerUser/>} path={'/admin/fas/partners/user/detail/:id'}/>
						<Route element={<AdminFasPartnerUser/>} path={'/admin/fas/partners/user/modify/:id'}/>

						{/*업체정보*/}
						<Route element={<AdminFasCompanyBasic/>} path={'/admin/fas/basic'}/>
						<Route element={<AdminFasCompanyBasic/>} path={`/admin/fas/basic/register`}/>
						<Route element={<AdminFasCompanyBasic/>} path={'/admin/fas/basic/:id'}/>
						<Route element={<AdminFasCompanyBasic/>} path={'/admin/fas/basic/modify/:id'}/>

						{/*업체정보 권한관리*/}
						<Route element={<AdminFasCompanyBasicPermission/>} path={'/admin/fas/basic/auth'}/>

						{/*업체정보 유저관리*/}
						<Route element={<AdminFasCompanyBasicUser/>} path={'/admin/fas/basic/user'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/admin/fas/basic/user/register'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/admin/fas/basic/user/detail/:id'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/admin/fas/basic/user/modify/:id'}/>


						{/*공장정보*/}
						<Route element={<FasFactory/>} path={'/admin/fas/factory'}/>
						<Route element={<FasFactory/>} path={'/admin/fas/factory/register'}/>
						<Route element={<FasFactory/>} path={'/admin/fas/factory/:id'}/>
						<Route element={<FasFactory/>} path={'/admin/fas/factory/modify/:id'}/>

						{/*거래처 기준정보 */}
						<Route element={<FasAccount/>} path={'/admin/fas/account'}/>
						<Route element={<FasAccount/>} path={'/admin/fas/account/register'}/>
						<Route element={<FasAccount/>} path={'/admin/fas/account/:customerCode'}/>


						{/*거래처 모델 기준정보*/}
						<Route element={<FasAccountModel/>} path={'/admin/fas/model'}/>
						<Route element={<FasAccountModel/>} path={'/admin/fas/model/register'}/>
						<Route element={<FasAccountModel/>} path={'/admin/fas/model/:customerModelCode'}/>

						{/*변경 예정 설비정보 페이지*/}
						<Route element={<FasFacility/>} path={'/admin/fas/facility'}/>
						<Route element={<FasFacility/>} path={'/admin/fas/facility/register'}/>
						<Route element={<FasFacility/>} path={'/admin/fas/facility/modify/:facilityCode'}/>
						<Route element={<FasFacility/>} path={'/admin/fas/facility/:facilityCode'}/>


						{/*주변장치 관리*/}
						<Route element={<FasPeripherals/>} path={'/admin/fas/peripherals'}/>
						<Route element={<FasPeripherals/>} path={'/admin/fas/peripherals/register'}/>
						<Route element={<FasPeripherals/>} path={'/admin/fas/peripherals/:peripheralCode'}/>
						<Route element={<FasPeripherals/>} path={'/admin/fas/peripherals/modify/:peripheralCode'}/>

						{/*설비 일상점검 페이지 */}
						<Route element={<AdminFasFacilityDaily/>} path={'/admin/fas/facility/daily'}/>

						{/*품목 관리 */}
						<Route element={<FasProduct/>} path={'/admin/fas/product'}/>
						<Route element={<FasProduct/>} path={'/admin/fas/product/:productCode'}/>
						<Route element={<FasProduct/>} path={'/admin/fas/product/modify/:productCode'}/>
						<Route element={<FasProduct/>} path={'/admin/fas/product/inspection/:productCode'}/>
						<Route element={<FasProduct/>} path={'/admin/fas/product/test'}/>

						{/*원자재 관리*/}
						<Route element={<FasRawMaterial/>} path={'/admin/fas/rawmaterial'}/>
						<Route element={<FasRawMaterial/>} path={'/admin/fas/rawmaterial/register'}/>
						<Route element={<FasRawMaterial/>} path={'/admin/fas/rawmaterial/:rawMaterialCode'}/>

						{/*부자재 관리 */}
						<Route element={<FasSubMaterial/>} path={'/admin/fas/submaterial'}/>
						<Route element={<FasSubMaterial/>} path={'/admin/fas/submaterial/register'}/>
						<Route element={<FasSubMaterial/>} path={'/admin/fas/submaterial/:subMaterialCode'}/>
						{/*금형 기본정보 관리 */}
						<Route element={<FasMold/>} path={'/admin/fas/mold'}/>
						<Route element={<FasMold/>} path={'/admin/fas/mold/register'}/>
						<Route element={<FasMold/>} path={'/admin/fas/mold/:moldCode'}/>

						{/*공정 관리*/}
						<Route element={<AdminFasCompanyProcess/>} path={'/admin/fas/process'}/>
						<Route element={<AdminFasCompanyProcess/>} path={'/admin/mes/process'}/>
						<Route element={<AdminFasCompanyProcess/>} path={'/admin/fas/process/:companyCode'}/>
						<Route element={<AdminFasCompanyProcess/>} path={'/admin/mes/process/:companyCode'}/>
						<Route element={<FullScreenProcess/>} path={'/admin/fas/full_process'}/>

						{/*표준문서관리*/}
						<Route element={<FasDocument/>} path={'/admin/fas/document'}/>

						<Route element={<FasBarcode/>} path={'/admin/fas/barcode'}/>

						{/*공정종류 관리*/}
						<Route element={<FasTypeProcess/>} path={'/admin/fas/type_process'}/>

						{/*일시정지 유형 관리 */}
						<Route element={<FasPause/>} path={'/admin/fas/pause'}/>

						{/*공정별 불량 유형 관리*/}
						<Route element={<FasDefect/>} path={'/admin/fas/defect'}/>

						<Route element={<AdminFasLayOut/>} path={'/admin/fas/drawing'}/>
						<Route element={<AdminFasCompanyNotice/>} path={'/admin/fas/notice'}/>

						<Route element={<AdminFasSolutionReq/>} path={'/admin/fas/solution'}/>
						<Route element={<AdminFasSolutionReq/>} path={'/admin/fas/solution/register'}/>
						<Route element={<AdminFasSolutionReq/>} path={'/admin/fas/solution/:id'}/>
						{/*{관리자 게시판 수정}*/}

						<Route element={<AdminFasEventNotice/>} path={'/admin/fas/info'}/>
						<Route element={<AdminFasEventNotice/>} path={'/admin/fas/info/register'}/>
						<Route element={<AdminFasEventNotice/>} path={'/admin/fas/info/:id'}/>
						<Route element={<AdminFasEventNotice/>} path={'/admin/fas/info/modify/:id'}/>

						<Route element={<AdminFasWiki/>} path={'/admin/fas/wiki'}/>
						<Route element={<AdminFasWiki/>} path={'/admin/fas/wiki/register'}/>
						<Route element={<AdminFasWiki/>} path={'/admin/fas/wiki/modify/:wikiCode'}/>

						<Route element={<AdminFasBusiness/>} path={'/admin/fas/business'}/>
						<Route element={<AdminFasBusiness/>} path={'/admin/fas/business/:id'}/>
						<Route element={<AdminFasBusiness/>} path={'/admin/fas/business/modify/:id'}/>
						<Route element={<AdminFasBusiness/>} path={'/admin/fas/business/register'}/>

						<Route path={'/admin'} element={<Navigate replace to={'/admin/main'}/>}/>
						<Route path={'/'} element={<Navigate replace to={'/admin/main'}/>}/>
						<Route path={'/*'} element={<Navigate replace to={'/admin/main'}/>}/>

					</Routes>
				)
			} else{
				return (
					<Routes>
						<Route element={<LoginCheckContainer/>} path={`/${localStorage.getItem('companyID')}`}/>
						<Route element={<FasMain/>} path={'/fas/main'}/>
						<Route element={<FasMyPage/>} path={'/myPage/:id'}/>
						<Route element={<FasMyPage/>} path={'/myPage/modify/:id'}/>
						{/*업체정보 권한관리*/}
						<Route element={<AdminFasCompanyBasicPermission/>} path={'/fas/basic/auth'}/>
						{/*업체정보 유저관리*/}
						<Route element={<AdminFasCompanyBasicUser/>} path={'/fas/basic/user'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/fas/basic/user/register'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/fas/basic/user/detail/:id'}/>
						<Route element={<AdminFasCompanyBasicUser/>} path={'/fas/basic/user/modify/:id'}/>

						<Route element={<FasFactory/>} path={'/fas/factory'}/>
						<Route element={<FasFactory/>} path={'/fas/factory/register'}/>
						<Route element={<FasFactory/>} path={'/fas/factory/:id'}/>
						<Route element={<FasFactory/>} path={'/fas/factory/modify/:id'}/>

						<Route element={<FasFacility/>} path={'/fas/facility'}/>
						<Route element={<FasFacility/>} path={'/fas/facility/register'}/>
						<Route element={<FasFacility/>} path={'/fas/facility/:facilityCode'}/>
						<Route element={<FasFacility/>} path={'/fas/facility/modify/:facilityCode'}/>

						<Route element={<FasProduct/>} path={'/fas/product'}/>
						<Route element={<FasProduct/>} path={'/fas/product/register'}/>
						<Route element={<FasProduct/>} path={'/fas/product/:productCode'}/>
						<Route element={<FasProduct/>} path={'/fas/product/modify/:productCode'}/>
						<Route element={<FasProduct/>} path={'/fas/product/inspection/:productCode'}/>
						<Route element={<FasProduct/>} path={'/fas/product/bom'}/>

						<Route element={<FasAccount/>} path={'/fas/account'}/>
						<Route element={<FasAccount/>} path={'/fas/account/register'}/>
						<Route element={<FasAccount/>} path={'/fas/account/:customerCode'}/>

						<Route element={<FasAccountModel/>} path={'/fas/model'}/>
						<Route element={<FasAccountModel/>} path={'/fas/model/register'}/>
						<Route element={<FasAccountModel/>} path={'/fas/model/:customerModelCode'}/>

						<Route element={<FasPeripherals/>} path={'/fas/peripherals'}/>
						<Route element={<FasPeripherals/>} path={'/fas/peripherals/:peripheralCode'}/>
						<Route element={<FasPeripherals/>} path={'/fas/peripherals/modify/:peripheralCode'}/>

						<Route element={<FasProcess/>} path={'/fas/process'}/>
						<Route element={<FasProcess/>} path={'/fas/process/:companyCode'}/>
						<Route element={<FullScreenProcess/>} path={'/fas/full_process'}/>



						<Route element={<FasMold/>} path={'/fas/mold'}/>
						<Route element={<FasMold/>} path={'/fas/mold/register'}/>
						<Route element={<FasMold/>} path={'/fas/mold/:moldCode'}/>

						<Route element={<FasRawMaterial/>} path={'/fas/rawmaterial'}/>
						<Route element={<FasRawMaterial/>} path={'/fas/rawmaterial/register'}/>
						<Route element={<FasRawMaterial/>} path={'/fas/rawmaterial/:rawMaterialCode'}/>

						<Route element={<FasSubMaterial/>} path={'/fas/submaterial'}/>
						<Route element={<FasSubMaterial/>} path={'/fas/submaterial/register'}/>
						<Route element={<FasSubMaterial/>} path={'/fas/submaterial/:subMaterialCode'}/>


						<Route element={<FasTypeProcess/>} path={'/fas/type_process'}/>

						<Route element={<FasPause/>} path={'/fas/pause'}/>
						<Route element={<FasDefect/>} path={'/fas/defect'}/>
						<Route element={<FasBarcode/>} path={'/fas/barcode'}/>

						<Route element={<FasLayout/>} path={'/fas/drawing'}/>


						<Route element={<FasDocument/>} path={'/fas/document'}/>

						<Route element={<FasNotice/>} path={'/fas/notice'}/>

						<Route element={<FasEventNotice/>} path={'/fas/info'}/>
						<Route element={<FasEventNotice/>} path={'/fas/info/:id'}/>

						<Route element={<FasWiki/>} path={'/fas/wiki'}/>
						<Route element={<FasWiki/>} path={'/fas/wiki/register'}/>
						<Route element={<FasWiki/>} path={'/fas/wiki/:wikiCode'}/>
						<Route element={<FasWiki/>} path={'/fas/wiki/modify/:wikiCode'}/>
						<Route element={<FasWiki/>} path={'/fas/info'}/>

						<Route element={<FasProcess/>} path={'/mes/process'}/>
						<Route element={<FasProcess/>} path={'/mes/process/:companyCode'}/>

						<Route path={'/'} element={<Navigate replace to={`/${localStorage.getItem('companyID') || ':id'}`}/>}/>
						<Route path={'/*'} element={<Navigate replace to={`/${localStorage.getItem('companyID') || ':id'}`}/>}/>
						<Route path={'/admin/*'} element={<Navigate replace to={`/${localStorage.getItem('companyID') || ':id'}`}/>}/>
					</Routes>
				)
			}
		}
};

export default Router;
