import React , {useEffect , useState} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  tooltipModule ,
} from "../../../../../style/StyleComponent";
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";
import {Error_Type , Modal_View_Type , MoldInfoType} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedState ,
  allPersistCheckedFunction , authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid , decimalNumberValid ,
  filterCheckedRow , handleDecimalChange ,
  initError ,
  initModal ,
  loadingRemove , menuVisitLogRegister ,
  modalListLoading , numberLocaleString ,
  onChangeArray , onChangeCheck ,
  onChangeNumberArray ,
  removeCheckedFunction ,
  singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ProductMoldView from "../../../../../components/modal/ProductMoldView";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  persistBlockPage ,
  persistCompanyData ,
  selectedCode ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import FilterWithSearchBar from "../../../../../components/input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const MoldManagement = () => {
  const {t}: any = useTranslation();

  const moldTitle = ['금형 CODE' , '금형명' , '캐비티' , 'SPM' , '슬라이드 위치' , '점검타수' , '최대타수' , '점검주기' , '현재타수' , '생산 품목'];
  const needValue = ['금형 CODE' , '금형명' , '캐비티']
  const [rowList , setRowList] = useState<MoldInfoType[]>([])
  const [checkedList , setCheckedList] = useState<any>([])
  const [idIndex , setIdIndex] = useState<number>(1)
  const [hoverIcon , setHoverIcon] = useState<string>(modify)
  const [iconSelect , setIconSelect] = useState<number>(- 1)
  const [focusList , setFocusList] = useState<number>(- 1)
  const [page , setPage] = useState<number>(1);
  const [totalPage , setTotalPage] = useState<number>(1);
  const [moldDataList , setMoldDataList] = useState<any[]>([]);
  const [productModal , setProductModal] = useState<{
    open: string,
    index: number,
    view: boolean
  }>({open: '' , index: - 1 , view: false})
  const [moreProduct , setMoreProduct] = useState<any>([])
  const selectedCompany= useRecoilValue<any>(authValid() ? persistCompanyData : userPersistCompanyData)
  const [selectCode , setSelectCode] = useRecoilState(selectedCode)
  const [errorState , setErrorState] = useState<Error_Type>(initError)
  const [dataModal , setDataModal] = useState<Modal_View_Type>(initModal)
  const [selectMoldData , setSelectMoldData] = useState<any>({});
  const [searchValue , setSearchValue] = useState<string>('');
  const [pushValue , setPushValue] = useState<string>('');
  const [inputSerialCode , setInputSerialCode] = useState<string>('')
  const [checkIndex , setCheckIndex] = useState<number>(- 1)
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>([])
  const [pageBlock , setPageBlock] = useRecoilState<number>(persistBlockPage);
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const scrollRef = React.useRef<any>(null)
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
    scrollRef.current.scrollLeft = 0
  }
  const updateFunction = () => {
    if(page === 1){
      if(pushValue === ''){
        moldListRes().then()
      } else{
        moldSearchRes().then()
      }
    } else{
      setPage(1)
    }
  }
  const addListRow = () => {
    setIdIndex(idIndex + 1)
    const rowsInput = {
      id: idIndex , companyCode: selectedCompany.companyCode , currentStrokes: '' ,
      moldSerialCode: '' , moldName: '' , cavity: '' , spm: '' , maxStrokes: '' ,
      slideCounter: '' , checkCycle: '' , checkStrokes: '' , productCode: '' , codeDoubleCheck: false
    }
    setRowList([...rowList , rowsInput])
    setCheckedList([...checkedList , rowsInput.id])
    scrollToStart()
  }
  const checkboxValid = () => {
    if(rowList.length !== 0){
      return {list: rowList , id: 'id'}
    } else{
      return {list: moldDataList , id: 'moldCode'}
    }
  }
  //삭제 validation 함수
  const onMoldDeleteFunc = () => {
    if(rowList.length === 0){
      setDataModal({menu: 'select' , message: `${checkedList.length}개의 금형을 삭제 하시겠습니까?`})
    } else{
      setDataModal({menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"삭제" 하시겠습니까?'})
    }
  }
  //수정
  const moldUpdateRes = async () => {
    if(!moldDataList[iconSelect].moldSerialCode){
      setDataModal({menu: 'confirm' , message: '금형 CODE가 없습니다.'})
    } else if(!moldDataList[iconSelect].moldName){
      setDataModal({menu: 'confirm' , message: '금형명이 없습니다.'})
    } else if(!moldDataList[iconSelect].cavity || moldDataList[iconSelect].cavity === '0'){
      setDataModal({menu: 'confirm' , message: '캐비티 지수를 입력 해야 합니다.'})
    } else if(!moldDataList[iconSelect].codeDoubleCheck){
      setDataModal({menu: 'confirm' , message: '금형CODE 중복 확인을 해주세요.'})
    } else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post(`/mold/update` , {
          companyCode: selectedCompany?.companyCode ,
          ...moldDataList[iconSelect]
        } , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            updateFunction()
            setIconSelect(- 1)
            setCheckedList([])
            setDataModal({menu: 'success' , message: '수정 되었습니다.'})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  const moldListRes = () => ListCustomHooks(`/mold/list/${page}/10/${selectedCompany.companyCode}` , page , setTotalPage , setPageBlock , setMoldDataList , setErrorState , setPushValue , setSearchValue)
  const moldSearchRes = () => ListSearchPostCustomHooks(`/mold/search` , page , setTotalPage , setPageBlock , setMoldDataList , setErrorState ,
                                                        {page: page ,
                                                          size: 10 ,
                                                          keyword: pushValue ,
                                                          filter:categorySelect.type,
                                                          companyCode: selectedCompany.companyCode
                                                        })
  //리스트
  // const moldListRes = async () =>{
  //     try {
  //         listLoadingCircle()
  //         const response = await client.get(`/mold/list/${page}/${10}/${selectedCompany.companyCode}`,{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         const valid = response.data
  //
  //         if(valid.status !== 200){
  //             setErrorState({notice:valid.message,status:valid.status})
  //             setMoldDataList([]);
  //         }else{
  //             setTotalPage(data.total_page);
  //             setSearchValue('')
  //             setPushValue('')
  //             setErrorState({notice:t(' * 등록된 금형정보가 없습니다. 등록해 주세요.'),status:valid.status})
  //             setMoldDataList(data.row)
  //             // if(data.total_page > 1 && page > 1){
  //             //     setMoldDataList((prev:any)=> prev.concat(data.row))
  //             // }else{
  //             //     setMoldDataList(data.row)
  //             // }
  //         }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //
  // //검색
  // const moldSearchRes = async () =>{
  //     listLoadingCircle()
  //     try {
  //         const response = await client.post(`/mold/search`,{
  //             page:page,
  //             size:10,
  //             companyCode:selectedCompany.companyCode,
  //             keyword:pushValue
  //         },{
  //                                                headers:{Authorization}
  //                                            })
  //         const data = response.data.data
  //         setTotalPage(data.total_page)
  //         setMoldDataList(data.row)
  //         // if(data.total_page > 1 && page > 1){
  //         //     setMoldDataList((prev:any)=> prev.concat(data.row))
  //         // }else{
  //         //     setMoldDataList(data.row)
  //         // }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //등록
  const moldRegisterRes = async () => {
    if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.moldSerialCode)){
      setDataModal({menu: 'confirm' , message: '금형 CODE를 입력해 주세요.'})
    }
    else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.codeDoubleCheck)){
      setDataModal({menu: 'confirm' , message: '금형 코드 중복확인을 해주세요.'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.moldName)){
      setDataModal({menu: 'confirm' , message: '금형 품명을 입력해 주세요.'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.cavity)){
      setDataModal({menu: 'confirm' , message: '캐비티를 입력해 주세요._1'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => v.cavity === '0')){
      setDataModal({menu: 'confirm' , message: '캐비티를 입력해 주세요.'})
    }
      // else if(filterCheckedRow(rowList,checkedList,'id').find((v)=>!v.maxStrokes)){
      //     setDataModal({menu:'confirm',message:'최대 타수를 입력해주세요.'})
      // }
      // else if(filterCheckedRow(rowList,checkedList,'id').find((v)=>!v.currentStrokes)){
      //     setDataModal({menu:'confirm',message:'현재 타수를 입력해주세요.'})
    // }
    else{
      try {
        dataAccessLoading()
        const response = await client.post(`/mold/register` ,
                                           filterCheckedRow(rowList , checkedList , 'id')
          , {headers: {Authorization}})
        const data = response.data;
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setRowList([])
            setCheckedList([])
            updateFunction()
            setDataModal({menu: 'success' , message: '등록 되었습니다.'})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  //삭제
  const moldDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/mold/delete` , checkedList , {headers: {Authorization}})
      const data = response.data
      const repairCaseArray = data.data?.moldManagement?.map((v: any) => ( {code: v , case: '금형 수리요청에 등록되어 있습니다.'} ))
      const productCaseArray = data.data?.product?.map((v: any) => ( {code: v , case: '품목에 등록된 금형입니다.'} ))
      const caseArray = [repairCaseArray , productCaseArray].flat().filter((v) => ( v ))
      if(data.status === 200){
        setTimeout(() => {
          setPage(1)
          setTotalPage(1)
          setDataModal({menu: 'success' , message: '선택한 금형이 삭제 되었습니다.'})
          removeCheckedFunction(setMoldDataList , moldDataList , setCheckedList , checkedList , 'moldCode')
          scrollToStart()
          if(page === 1){
            if(pushValue === ''){
              moldListRes().then()
            } else{
              moldSearchRes().then()
            }
          }
          loadingRemove()
        } , 500)
      } else if(data.status === 601){
        setTimeout(() => {
          setPage(1)
          setTotalPage(1)
          setIconSelect(- 1)
          setDataModal({menu: 'deleteCase'})
          setDeleteCaseArray(caseArray)
          setCheckedList(checkedList.filter((v: string) => !data.data.success.includes(v)))
          setMoldDataList(moldDataList.filter((v: any) => !data.data.success.includes(v['moldCode'])))
          scrollToStart()
          if(page === 1){
            if(pushValue === ''){
              moldListRes().then()
            } else{
              moldSearchRes().then()
            }
          }
          loadingRemove()
        } , 500)
      } else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }
  React.useEffect(() => {
    if(selectedCompany.companyCode !== undefined){
      if(pushValue === ''){
        moldListRes().then()
      } else{
        moldSearchRes().then()
      }
    }
  } , [pushValue , page , selectedCompany,categorySelect.type])
  //금형 시리얼코드 중복체크
  const serialDoubleCheck = async (serialCode: string , index: number) => {
    setCheckIndex(index)
    const regCount = rowList.filter((v) => ( v.moldSerialCode.includes(serialCode) && v.codeDoubleCheck )).length
    try {
      const response = await client.post('/mold/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        moldSerialCode: serialCode
      } , {headers: {Authorization}})
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        moldSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }
      if(rowList.length !== 0){
        if(duplicateCount[0] === 0){
          if(regCount !== 0){
            setInputSerialCode(regCount === 0 ? serialCode : serialCode + `(${regCount})`)
            setDataModal({menu: 'doubleCheck' , message: '중복 입력된 금형CODE 입니다. \n 중복사용 하시겠습니까?'})
          } else{
            setRowList(copyRowList)
            setDataModal({menu: 'confirm' , message: '중복되지 않은 금형CODE 입니다.'})
          }
        } else{
          if(duplicateCount.length === 1){
            setInputSerialCode(serialCode + `(${duplicateCount[0] + regCount})`)
          } else{
            if(regCount < duplicateCount.length){
              setInputSerialCode(serialCode + `(${duplicateCount[regCount]})`)
            } else{
              setInputSerialCode(serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})`)
            }
          }
          setDataModal({menu: 'doubleCheck' , message: '이미 사용중인 금형CODE 입니다. \n 중복사용 하시겠습니까?'})
        }
      } else{
        if(duplicateCount[0] === 0){
          const copyMaterialList = [...moldDataList]
          copyMaterialList[index] = {
            ...copyMaterialList[index] , codeDoubleCheck: true ,
            moldSerialCode: serialCode
          }
          setMoldDataList(copyMaterialList)
          setDataModal({menu: 'confirm' , message: '중복되지 않은 금형CODE 입니다.'})
        } else{
          setDataModal({menu: 'doubleCheck' , message: '이미 사용중인 금형CODE 입니다. \n 중복사용 하시겠습니까?'})
          setInputSerialCode(serialCode + `(${duplicateCount[0]})`)
        }
      }
    }
    catch (e) {
      console.log(e)
      setDataModal({menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
    }
  }
  const moldProductViewRes = async (moldCode: string) => {
    modalListLoading()
    try {
      const response = await client.get(`/mold/product/view/${moldCode}` , {
        headers: {Authorization}
      })
      console.log(response.data.data)
      setMoreProduct(response.data.data.row)
      loadingRemove()
    }
    catch (e) {
      console.log(e)
    }
  }
  const onDataResetFunc = () => {
    setRowList([]);
    setCheckedList([]);
    setMoldDataList([])
    setPage(1);
    setPageBlock(0)
    setTotalPage(1)
    setSearchValue('');
    setPushValue('');
    setErrorState(initError)
  }

  React.useEffect(()=>{
    onDataResetFunc()
  },[selectedCompany.companyCode])

  const cancelFuc = () => {
    setHoverIcon(modify);
    setIconSelect(- 1);
    setCheckedList([])
    setDataModal(initModal)
    if(pushValue === ''){
      setMoldDataList(moldDataList.map((v: any) => {return v.moldCode === selectCode ? {...v , ...selectMoldData} : v}))
    } else{
      moldSearchRes().then()
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(rowList.length > 0 || iconSelect !== - 1){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload);
    };
  } , [rowList.length , iconSelect]);
  const [ , setIsScrolling] = useState<boolean>(true);
  const handleScroll = () => {
    //확인된 최대값 숫자 넣기
    if(scrollRef?.current?.scrollLeft === 348){
      setIsScrolling(false)
    } else{
      setIsScrolling(true)
    }
  };

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'금형 기본정보').then()
    }
  },[])

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={() => {
        setDataModal(initModal)
        if(dataModal.message?.includes('등록')){
          moldListRes().then();
        }
      }}/> : dataModal.menu === 'error' ?
        <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={() => setDataModal(initModal)}/>
        : dataModal.menu === 'warn' ?
          <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={() => setDataModal(initModal)}/> : null}

      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'금형 CODE'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={() => {
            setDataModal(initModal)
            if(dataModal.message?.includes('등록')){
              moldListRes().then();
            }
          }}/>}

      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'*삭제된 데이터는 복구되지 않습니다.'}
                                                          cancelEvent={() => setDataModal(initModal)}
                                                          clickEvent={() => {
                                                            moldDeleteRes().then();
                                                            setDataModal(initModal)
                                                          }}/>}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             if(rowList.length !== 0){
                               const copyRowList = [...rowList]
                               copyRowList[checkIndex] = {...copyRowList[checkIndex] , codeDoubleCheck: true , moldSerialCode: inputSerialCode}
                               setRowList(copyRowList)
                             } else{
                               const copyMaterialList = [...moldDataList]
                               copyMaterialList[checkIndex] = {
                                 ...copyMaterialList[checkIndex] , codeDoubleCheck: true ,
                                 moldSerialCode: inputSerialCode
                               }
                               setMoldDataList(copyMaterialList)
                             }
                             setDataModal(initModal)
                           }}
                           cancelEvent={() => setDataModal(initModal)}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {productModal.open === 'product' &&
          <ProductMoldView arrayData={moreProduct}
                           closeButton={() => setProductModal({open: '' , index: - 1 , view: false})}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal(initModal)}
                              clickEvent={moldRegisterRes}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if(rowList.length !== 0){
                                  removeCheckedFunction(setRowList , rowList , setCheckedList , checkedList , 'id')
                                  setSelectMoldData({})
                                  setDataModal(initModal)
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal(initModal)}
                              clickEvent={moldUpdateRes}/>}
      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'mold'} type={'download'} companyData={selectedCompany}
                              disabled={moldDataList.length === 0}/>
              <ExcelApiButton category={'mold'} type={'format'} companyData={selectedCompany}
                              disabled={buttonAuthValid('create' , '14')}/>
              <ExcelApiButton category={'mold'} type={'upload'} companyData={selectedCompany}
                              disabled={buttonAuthValid('create' , '14')}/>
            </React.Fragment>
          }/>
      }
      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 금형 기본정보'} unitCase={'MenuTitle'}/>
          </div>
        </div>
        {/*금형 기본정보 */}
        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'금형 기본정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                {/*<SearchBar keyFunction={(e) => setSearchValue(e.target.value.trimStart())} value={searchValue}*/}
                {/*           keyPressDisable={true}*/}
                {/*           disabled={( moldDataList.length === 0 && pushValue === '' ) || rowList.length !== 0 || iconSelect !== - 1}*/}
                {/*           clearButtonAccess={searchValue !== ''} clearButton={() => {*/}
                {/*              moldListRes().then();*/}
                {/*              setPage(1);*/}
                {/*              setPageBlock(0)*/}
                {/*            }}*/}
                {/*           clickEvent={() => {*/}
                {/*             setPushValue(searchValue);*/}
                {/*             setPage(1)*/}
                {/*           }}/>*/}

                <FilterWithSearchBar keyFunction={(e) => setSearchValue(e.target.value.trimStart())} value={searchValue}
                                     keyPressDisable={true}
                                     disabled={( moldDataList.length === 0 && pushValue === '' ) || rowList.length !== 0 || iconSelect !== - 1}
                                     clearButtonAccess={searchValue !== ''} clearButton={() => {
                                moldListRes().then();
                                setPage(1);
                                setPageBlock(0)
                              }}
                                     clickEvent={() => {
                                       setPushValue(searchValue);
                                       setPage(1)
                                     }}
                                     margin={'0'}
                                     selectKeyword={categorySelect.value}
                                     categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                     categoryTypeArray={['@','mold_serial_code','mold_name']}
                                     categoryValueArray={['전체','금형 CODE','금형명']}
                                     placeholder={t('검색')}/>


                <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>

                {/*{selectedCompany.companyCode !== undefined &&*/}
                {/*    <MainButtons name={'행추가'} width={'80px'} clickEvent={addListRow}*/}
                {/*                 disabled={buttonAuthValid('create' , '14') || ( iconSelect !== - 1 || pushValue !== '' || checkedList[0]?.length > 1 || errorState.status !== 200 )}/>}*/}
                {/*<MainButtons name={'저장'} width={'80px'} buttonType={'cta'}*/}
                {/*             disabled={buttonAuthValid('create' , '14') || ( rowList.length === 0 || checkedList.length === 0 )}*/}
                {/*             clickEvent={() => {*/}
                {/*               if(checkedList.length === rowList.length){*/}
                {/*                 moldRegisterRes().then()*/}
                {/*               } else{*/}
                {/*                 setDataModal({menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'})*/}
                {/*               }*/}
                {/*             }}/>*/}

                {checkedList?.length !== 0 &&
                  <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                              marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
                              disabled={
                                buttonAuthValid( 'rowDelete' , '14' ) ?
                                  rowList.length === 0
                                  :
                                  buttonAuthValid( 'delete' , '14' ) || ( checkedList.length === 0 )}
                              clickEvent={onMoldDeleteFunc}/>}

                {(rowList?.length === 0 && iconSelect === -1 && checkedList?.length === 0) &&
                  <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                              marginDis={true}
                              disabled={selectedCompany.companyCode === undefined || buttonAuthValid( 'create' , '14' )}
                              clickEvent={() => window.location.href = authValid() ? '/admin/fas/mold/register' : '/fas/mold/register'}/>}
              </div>
            </div>
            {/*{iconSelect === - 1 &&*/}
            {/*  <ShowMore showMore={isScrolling} width={120} height={authValid ? 335 : 530} right={40} top={78}*/}
            {/*            horizonType={true}/>}*/}
            {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
            {/*                next={()=>setPage((prev)=> prev+1)}*/}
            {/*                hasMore={totalPage > page} dataLength={dataValid(moldDataList?.length, 0)}*/}
            {/*                loader={<div/>}>*/}
            <GridTableComponent id={'grid-scroll'} scrollWidth={1548} onScroll={handleScroll}
                                titleCheckDisabled={iconSelect !== - 1}
                                height={538}
                                ref={scrollRef} scrollY_Hide={rowList?.length < 10}
              // height={listHeightValid(authValid,{total: totalPage, page: page})}
                                tempCol={'40px repeat(2, 220px) repeat(7, 140px) 88px'}>

              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox"
                         style={{pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` , opacity: `${iconSelect === - 1 ? 1 : 0.5}`}}
                         onChange={(e) => allPersistCheckedFunction(e.target.checked , {dataList: checkboxValid().list , checkedList: checkedList , id: checkboxValid().id , setState: setCheckedList})}
                         checked={allCheckedState({dataList: checkboxValid().list , checkedList: checkedList , id: checkboxValid().id})}/>
                </label>
                {moldTitle.map((v) => (
                  <TableValue title={v} key={v} viewType={iconSelect !== - 1 || rowList.length !== 0}
                              requiredValue={needValue}/> ))}
              </div>

              {rowList.length === 0 && moldDataList.length === 0 ? pushValue === '' ? errorState.status !== 200 ?
                    listResultMessage(authValid() , dataValid(errorState.notice , '* 업체를 선택해 주세요.') , {width: 1210 , admin: 480 , user: 480})
                    :
                    listResultMessage(authValid() , dataValid(errorState.notice , '') ,
                                      {width: 1210 , admin: 480 , user: 480} , () => window.location.href = authValid() ? '/admin/fas/mold/register' : '/fas/mold/register' , buttonAuthValid('create' , '14'),'등록')
                  :
                  listResultMessage(authValid() , '*검색 결과가 없습니다.' , {width: 1210 , admin: 480 , user: 480})
                :
                rowList.map((v , i) => (
                  <div className={'grid-row-list'}>
                    <label className={'checkbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={(e: any) => singleCheckedFunction(e.target.checked , v.id , setCheckedList , checkedList)}
                                      checked={checkedList.includes(v.id)}/>
                    </label>
                    {/*<ListInnerInput type={'text'} placeholder={'금형 CODE'} inputData={v.moldSerialCode} name={'moldSerialCode'} onChange={(e)=>{onChangeArray(i,e,rowList,setRowList)}}/>*/}
                    <ListInnerInput type={'withButton'} width={'120px'}
                                    name={'moldSerialCode'} placeholder={'-'}
                                    inputData={v.moldSerialCode} inputDisabled={v.codeDoubleCheck}
                                    onChange={(e) => onChangeArray(i , e , rowList , setRowList , true)}
                                    clickButton={() => {serialDoubleCheck(v.moldSerialCode , i).then()}}
                                    buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                                    buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.moldSerialCode}/>

                    <ListInnerInput type={'text'} placeholder={'금형 품명'} inputData={v.moldName} name={'moldName'}
                                    onChange={(e) => {onChangeArray(i , e , rowList , setRowList , true)}}/>

                    <ListInnerInput type={'unit'} width={'140px'} unit={'ea'} name={'cavity'} placeholder={'0'}
                                    inputData={numberLocaleString(v.cavity)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , 'ea')}}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={'타'} name={'spm'} placeholder={'0'}
                                    inputData={numberLocaleString(v.spm)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , 'ea')}}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={'mm'} name={'slideCounter'} placeholder={'0'}
                                    inputData={decimalNumberValid(v.slideCounter)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , '')}}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={'타'} name={'checkStrokes'} placeholder={'0'}
                                    inputData={numberLocaleString(v.checkStrokes)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , 'ea')}}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={'타'} name={'maxStrokes'} placeholder={'0'}
                                    inputData={numberLocaleString(v.maxStrokes)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , 'ea')}}/>
                    <ListInnerInput type={'unit'} unit={'일'} placeholder={'-'} limit={6}
                                    inputData={numberLocaleString(v.checkCycle)} name={'checkCycle'}
                                    onChange={(e) => {onChangeNumberArray(i , e , rowList , setRowList)}}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={'타'} name={'currentStrokes'} placeholder={'0'}
                                    inputData={numberLocaleString(v.currentStrokes)} limit={15}
                                    onChange={(e) => {handleDecimalChange(i , e , rowList , setRowList , 'ea')}}/>
                    {/*<ListInnerInput type={'textDrop'} width={'80px'} placeholder={'-'} maxLength={5} inputData={v.checkCycle} name={'checkCycle'}*/}
                    {/*                selectDrop={(v)=>{setSelectDate(v)}} dropMenuList={['일','주','월']} onChange={(e)=>{onChangeNumberArray(index,e,rowList,setRowList)}}/>*/}
                    <SelectProductCode style={{opacity: 0.3}}>{'품목 보기'}</SelectProductCode>

                  </div>
                ))}

              {rowList.length === 0 &&
                moldDataList.map((v: any , i: number) => (
                  <React.Fragment>
                    {
                      <GridTableSelect selected={v.moldCode === selectCode}
                                       disabled={rowList.length !== 0 || iconSelect !== - 1}
                                       checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                       className={'grid-list'} onClick={() => setSelectCode(v.moldCode)}
                                       onDoubleClick={() => {
                                         window.location.href = `${authValid() ? `/admin/fas/mold/${v?.moldCode}` : `/fas/mold/${v?.moldCode}`}`
                                       }}
                                       onMouseOut={() => setFocusList(- 1)} onMouseOver={() => setFocusList(i)}>
                        <label className={'selectCheckbox'}>
                          <ListInnerInput type={'check'} name={'moldCode'}
                                          onChange={(e: any) => singleCheckedFunction(e.target.checked , v.moldCode , setCheckedList , checkedList)}
                                          checked={checkedList.includes(v.moldCode)}/>
                        </label>
                        {autoTextValueValidation({value:v.moldSerialCode})}
                        {tooltipModule( dataValid( v.moldName ) , 10 )}
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.cavity)} unit={'ea'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.spm)} unit={'타'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.slideCounter)}
                                        unit={'mm'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.checkStrokes)}
                                        unit={'타'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.maxStrokes)} unit={'타'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.checkCycle)} unit={'일'}/>
                        <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.currentStrokes)}
                                        unit={'타'}/>
                        <SelectProductCode onClick={() => {
                          moldProductViewRes(v.moldCode).then()
                          setProductModal({open: 'product' , index: i , view: true})
                        }}>{'품목 보기'}
                        </SelectProductCode>
                      </GridTableSelect>
                    }
                  </React.Fragment>
                ))}
            </GridTableComponent>
            {/*</InfiniteScroll>*/}
          </div>
          <div style={{display:'flex', justifyContent:'space-between'}}>
            <div>
              {rowList.length === 0 &&
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage} disabled={iconSelect !== -1}/>}
            </div>
            <HorizontalMoveScrollButton
              leftClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
              rightClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default MoldManagement;
const SelectProductCode = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #5A607F;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;

  &:hover {
    font-weight: 900;
  }
`

