import React from 'react';
import styled from "styled-components";
import client from "../../common/Config";
import PreviewImage from "../display/PreviewImage";
import ConfirmPopup from "../modal/ConfirmPopup";
import {useTranslation} from "react-i18next";
import {dataValid , initModal , loadingRemove , uploadingLoading} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import DetailImageView from "../modal/DetailImageView";

interface Props {
	imageKey: (data:string|null)=>void
	receiveKey?: string
	receiveUrl?: string
	receiveProfileKey?: (data?:string)=>void
	inputSize?: number
	radiusSelect?: boolean
	profileKey?: string
	profileSelect: boolean
	disableDelete?: boolean
}

const SingleImageUploadBox:React.FunctionComponent<Props> = ({ imageKey,
	                                                             receiveUrl,
	                                                             receiveKey,
	                                                             radiusSelect,
	                                                             inputSize}) => {

	const [imagePreviewState, setImagePreviewState] = React.useState<string|null|any>(null)
	const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)
	const inputRef = React.useRef<any>(null)
	const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data: any) => {
		e.preventDefault()
		uploadingLoading('업로드 중 입니다...')
		const formData = new FormData()
		formData.append('file',data)
		try {
			const response = await client.post('/file/upload',
				formData
				// ,{headers:{ "Content-Type": "multipart/form-data" }}
			)
			const data = response.data.data
			// uploadData({imgKey:data.imgKey,})

			setImagePreviewState(data.imgUrl)
			inputRef.current.value = ""
			imageKey(data.imgKey)
			loadingRemove()
		}catch (e) {
			loadingRemove()
			console.log(e)
		}
	}

	React.useEffect(()=>{
		if(receiveKey)
			imageKey(receiveKey)
	},[receiveKey])

	React.useEffect(()=>{
		if(receiveUrl){
			setImagePreviewState(receiveUrl)
		}
	},[receiveUrl])

	// const imageReceiver = React.useCallback(()=>{
	// 	imageKey(imageKeyState)
	// },[])
	//
	// React.useEffect(()=>{
	// 	imageReceiver()
	// },[imageKeyState])


	const deleteImage = () => {
		setImagePreviewState(null)
		imageKey(null)
	}

	const extendReg = /(.*?)\.(jpg|jpeg|png)$/
	const { t }:any = useTranslation();
	return (
				<TotalComponent>
					{dataModal.menu === 'upload' && <ConfirmPopup statusText={dataModal.message}
																												clickEvent={()=>setDataModal(initModal)}/>}
					{/*@ts-ignore*/}
					{dataModal.menu === 'image' && <DetailImageView image={imagePreviewState} closeMenu={()=>{setDataModal(initModal)}}/>}

					{!imagePreviewState ?
						//@ts-ignore
						<AddImageBox style={{fontSize:`${inputSize/100}vw`,width: `${inputSize}px`, height: `${inputSize}px`,
							borderRadius:`${radiusSelect? '50%' : '6px'}`,	lineHeight:`${inputSize}px`,overflow:'hidden'}} className={'standard_Text'}>
							{'┼'}
							<input type={'file'} style={{display:'none'}} ref={inputRef} accept={'image/*'}
										 onChange={(e:any)=> {
											 if(inputRef.current.value.match(extendReg)){
												 if(e.target.files[0].size < 10*1024*1024){
													 fileUpload(e, e.target.files[0]).then()
												 }else{
													 setDataModal({menu:'upload', message: t('10MB 이하의 파일을 업로드 해주세요.')})
													 inputRef.current.value = ""
												 }
											 }else{
												 setDataModal({menu:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})
											 }
										 }}/>
						</AddImageBox>
						:
						<PreviewImage clickEvent={()=>{setDataModal({menu:'image'})}}
													imageUrl={dataValid(imagePreviewState,'')} deleteImg={deleteImage}
													previewSize={inputSize} radiusCircle={radiusSelect}/>
					}
				</TotalComponent>
	);
};

export default SingleImageUploadBox;

const TotalComponent = styled.div`
		box-sizing: border-box;
		height: 168px;
	`

const AddImageBox = styled.label`
    width: 168px;
    height: 168px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #D7DBEC;
    color: #a1a1a1;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 168px;
    user-select: none;
    cursor: pointer;
	overflow: hidden;
	`