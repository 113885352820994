import React , {useRef , useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid , dataAccessLoading ,
  dataValid , filterCheckedRow ,
  handleDecimalChange , handleRowDelete ,
  initModal , loadingRemove ,
  numberLocaleString ,
  onMultiRegChangeArray ,
  singleCheckedFunction
} from "../../../../../function/function";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const SubMaterialsMultipleReg = () => {
  const {t}: any = useTranslation();
  const subMaterialTitle = ['부자재 CODE' , '부자재 품명' , '단위' , '안전 재고량' , '거래처'];
  const needValue = ['부자재 CODE' , '부자재 품명' , '단위']

  const [accountSearchModal , setAccountSearchModal] = useState<{
    open: boolean,
    idx: number
  }>( {open: false , idx: - 1} );
  const [accountCode , setAccountCode] = useState<any>( {} )
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )

  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal );
  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )
  const scrollRef = useRef<any>( null )

  const initialObject = {
    companyCode: selectedCompany.companyCode ,
    customer: '' ,
    subMaterialName: '' ,
    subMaterialSerialCode: '' ,
    subMaterialStock: '' ,
    subMaterialUnit: 'ea' ,
    codeDoubleCheck: false
  }
  const generateInitialRowList = Array.from( {length: 10} , ( _ , i ) => ( {
    ...initialObject ,
    id: i + 1
  } ) )
  const [selectedID, setSelectedID] = useState<number>(0)
  const [rowList , setRowList] = useState<any[]>( generateInitialRowList )
  const [checkedList , setCheckedList] = useState<any>( [] )

  const subMaterialRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialSerialCode ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 코드를 입력해 주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 코드 중복 확인을 해주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialName ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 품명을 입력해 주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialUnit ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 단위를 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/subMaterial/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {headers: {Authorization}} )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( generateInitialRowList )
            setCheckedList( [] )
            setDataModal( {menu: 'success' , message: '부자재 정보가 등록 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = rowList.filter( ( v ) => ( v.subMaterialSerialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/subMaterial/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        subMaterialSerialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        subMaterialSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }

        if( duplicateCount[0] === 0 ){
          if( regCount !== 0 ){
            setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
            setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 부자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
          } else{
            setRowList( copyRowList )
            setDataModal( {menu: 'confirm' , message: '중복되지 않은 부자재CODE 입니다.'} )
          }
        } else{
          if( duplicateCount.length === 1 ){
            setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
          } else{
            if( regCount < duplicateCount.length ){
              setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
            } else{
              setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
            }
          }
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 부자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }

    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }

  const concatCheckedID = checkedList.includes(selectedID)
  console.log(checkedList)
  console.log(rowList)
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {/*거래처 검색창*/}
      {accountSearchModal.open &&
          <AccountSearchModal receiveData={( value ) => {setAccountCode( value )}}
                              companyCode={selectedCompany.companyCode}
                              selectCode={accountCode.customerCode} modalCase={'customer'} authValid={authValid()}
                              cancelEvent={() => {
                                setAccountSearchModal( {open: false , idx: - 1} );
                                setAccountCode( {} )
                              }}
                              confirmEvent={() => {

                                Object.assign( rowList[accountSearchModal.idx] , {customer: accountCode.customerName} )
                                if(!concatCheckedID){
                                  setCheckedList(checkedList.concat(selectedID))
                                }
                                setAccountSearchModal( {open: false , idx: - 1} );
                              }}/>}
      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {

                              const copyRowList = [...rowList]
                              copyRowList[checkIndex] = {
                                ...copyRowList[checkIndex] ,
                                codeDoubleCheck: true ,
                                subMaterialSerialCode: inputSerialCode
                              }
                              setRowList( copyRowList )
                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}



      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}


      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={subMaterialRegisterRes}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'subMaterial'} type={'format'} disabled={buttonAuthValid( 'create' , '10' )}
                              companyData={selectedCompany}/>
              <ExcelApiButton category={'subMaterial'} type={'upload'} disabled={buttonAuthValid( 'create' , '10' )}
                              companyData={selectedCompany}/>
            </React.Fragment>
          }/>
      }
      <div>
        <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <MenuTitle title={'부자재 기본정보 등록'} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'Excel'} clickEvent={() => {setDataModal( {menu: 'excel'} )}}/>
            <MainButtons name={'삭제'} width={'80px'}
                         disabled={checkedList.length === 0}
                         clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
            <MainButtons name={'저장'} buttonType={'cta'} width={'80px'} marginDis={true}
                         disabled={checkedList.length === 0}
                         clickEvent={() => {setDataModal( {menu: 'register' , message: '부자재를 등록 하시겠습니까?'} )}}/>
          </div>
        </div>
        <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                            ref={scrollRef}
                            scrollWidth={1210}
                            height={528}
                            scrollY_Hide={rowList?.length < 10}
          // height={listHeightValid(authValid,{total: totalPage, page: page},true)}
                            tempCol={'40px 240px 1fr 120px 180px 244px'}>
          <div className={'grid-title'}>
            <label className={'checkbox'}>
              <input type="checkbox"
                     onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                       dataList: rowList ,
                       checkedList: checkedList ,
                       id: 'id',
                       setState: setCheckedList
                     } )}
                     checked={allCheckedState( {
                                                 dataList: rowList ,
                                                 checkedList: checkedList ,
                                                 id: 'id'
                                               } )}/>
            </label>
            {subMaterialTitle.map( ( v ) => (
              <TableValue title={v} key={v} viewType={true}
                          requiredValue={needValue}/> ) )}
          </div>

          {rowList.map( ( v , i , arr ) => (
              <GridTableSelect className={'grid-row-list'} checkboxNumber={i + 1}>
                <label className={'selectCheckbox'}>
                  <ListInnerInput type={'check'} name={'id'}
                                  onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                  checked={checkedList.includes( v.id )}/>
                </label>
                {/*<ListInnerInput type={'text'} name={'subMaterialSerialCode'} placeholder={'부자재 CODE'}*/}
                {/*                inputData={v.subMaterialSerialCode} onChange={(e) => onChangeArray(i, e,rowList,setRowList)}/>*/}

                <ListInnerInput type={'withButton'} width={'120px'}
                                name={'subMaterialSerialCode'} placeholder={'-'}
                                inputData={v.subMaterialSerialCode} inputDisabled={v.codeDoubleCheck}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id,true )}
                                clickButton={() => {serialDoubleCheck( v.subMaterialSerialCode , i ).then()}}
                                buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                                buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.subMaterialSerialCode}/>

                <ListInnerInput type={'text'} name={'subMaterialName'} placeholder={'부자재 품명'} width={'100%'}
                                inputData={v.subMaterialName}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id,true )}/>
                <ListInnerInput type={'drop'} inputData={v.subMaterialUnit}
                                dropTopDirection={i >= 16}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id,true )} tabIndex={- 1}
                                selectDrop={( el ) => {
                                  setSelectedID(v.id)
                                  setRowList( ( prev: any ) => {
                                    return prev.map( ( item: any , index: number ) => {
                                      if( index === i ){
                                        return {
                                          ...item ,
                                          subMaterialUnit: el , subMaterialStock: ''
                                        };
                                      }
                                      return item;
                                    } );
                                  } )
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}
                                name={'subMaterialUnit'} dropMenuList={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>
                <ListInnerInput type={'unit'} unit={dataValid( rowList[i].subMaterialUnit , 'ea' )}
                                name={'subMaterialStock'}
                                placeholder={'-'}
                                inputData={v.subMaterialStock?.includes( '.' ) ? v.subMaterialStock : numberLocaleString( v.subMaterialStock )}
                                limit={15}
                                onChange={( e ) => {
                                  handleDecimalChange( i , e , rowList , setRowList , v.subMaterialUnit )
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }
                }/>
                <ListInnerInput type={'search'} name={'customer'} placeholder={'검색'} tabIndex={- 1}
                                inputData={v.customer}
                                clickButton={() => {
                                  setSelectedID(v.id)
                                  setAccountSearchModal( {open: true , idx: i} )
                                }}/>

              </GridTableSelect>
            ) )}
        </GridTableComponent>
      </div>
    </React.Fragment>
  );
};
export default SubMaterialsMultipleReg;
