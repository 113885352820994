import React from 'react';
import styled from 'styled-components'
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {useTranslation} from "react-i18next";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import RegisterInput from "../../../../../components/input/RegisterInput";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import AttachMultipleUploadBox from "../../../../../components/input/AttachMultipleUploadBox";

const WikiRegister = () => {
  const {t}:any = useTranslation()
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [inputState,setInputState] = React.useState<any>({
                                                            backManager:'',
                                                            frontManager:'',
                                                            solution:'',
                                                            wikiType:'',
                                                            wikiQuestion:'',
                                                            wikiTitle:''
                                                          });
  const [fileKey,setFileKey] = React.useState<any[]>([]);
  const [imageKeyState, setImageKeyState] = React.useState<string[]>([]);

  const onChangeValue = (e:any,trim?:boolean) => {
    const {value, name} = e.target
    setInputState({
                    ...inputState,
                    [name]: trim ? value.trim() : value
                  });
  }

  const wikiRegRes = async () =>{
    if(!inputState.wikiTitle){
      setDataModal({menu:'confirm',message:'제목을 입력해주세요.'})
    }else if(!inputState.wikiType){
      setDataModal({menu:'confirm',message:'유형을 선택해주세요.'})
    }else{
      try {
        dataAccessLoading()
        const response = await client.post(`/wiki/register`,{
          ...inputState,
          wikiImage:dataValid(imageKeyState,[]),
          wikiFile:dataValid(fileKey,[]),
        },{
                                             headers:{Authorization}
                                           })
        const data = response.data
        if(data.status !== 200) {
          setDataModal({menu:'confirm', message:t(data.message)})
          loadingRemove()
        }else{
          setTimeout(()=>{
            setDataModal({menu:'success', message:t('WIKI 정보가 저장 되었습니다.')})
            loadingRemove()
          },500)
        }
        loadingRemove()
      }catch ( e ) {
        loadingRemove()
        console.log(e)
      }
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal); window.location.href = '/admin/fas/wiki' }}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

      {dataModal.menu === 'confirm' &&
        <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>
      }
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              clickEvent={()=>{ window.location.href = `/admin/fas/wiki`}}
          />
      }

      <MenuTitle title={'게시판 관리 > WIKI 관리 > 등록'} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'} justify={'flex-start'}>
        <ViewComponent>
          <div style={{display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
            <MenuTitle title={'WIKI 등록'} unitCase={'MenuCompTitle'}/>
            <div style={{display:'flex'}}>
              <MainButtons name={'취소'} width={'56px'} clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
              <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'} clickEvent={wikiRegRes}/>
            </div>
          </div>
          <div className={'scroll_func'}>
            <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
              <RegisterComponent type={'drop'} title={'솔루션'} dropWidth={'192px'}
                                 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, solution: v}))}
                                 name={'solution'} value={inputState.solution} onChange={onChangeValue} dropAddNoneSelect={true}
                                 dropArray={['FAS','P-BAS','P-MS','C-MS','F-EMS','MES']}/>

              <RegisterComponent type={'drop'} title={'유형'} dropWidth={'192px'} required={true}
                                 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, wikiType: v}))}
                                 name={'wikiType'} value={inputState.wikiType} onChange={onChangeValue} dropAddNoneSelect={true}
                                 dropArray={['기획 회의','개발중','개발완료','보류']}/>

              <RegisterComponent type={'drop'} title={'프론트 담당자'} dropWidth={'192px'}
                                 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, frontManager: v}))}
                                 name={'frontManager'} value={inputState.frontManager} onChange={onChangeValue} dropAddNoneSelect={true}
                                 dropArray={['송재훈','박서후','양소윤']}/>

              <RegisterComponent type={'drop'} title={'백엔드 담당자'} dropWidth={'192px'}
                                 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, backManager: v}))}
                                 name={'backManager'} value={inputState.backManager} onChange={onChangeValue} dropAddNoneSelect={true}
                                 dropArray={['박창민','최지수']}/>
            </div>
            <RegisterComponent type={'text'} value={inputState.wikiTitle} limit={100}
                               required={true} title={t('제목')} placeholder={t('제목을 입력해 주세요.')}
                               onChange={(e)=>{onChangeValue(e)}} name={'wikiTitle'}/>

            <AttachMultipleUploadBox fileKeyArray={(data)=>{setFileKey(data)}}
                                     placeholder={'* 업로드 버튼을 클릭하여 파일을 업로드해주세요.'}/>

            <div style={{display:'grid',gridTemplateColumns:'168px 1fr',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>
              <div className={'info-title'} style={{height: '364px'}}>{t('내용')}</div>
              <div className={'info-content'} style={{height: '364px'}}>
                <RegisterInput type={'textarea'} name={'wikiQuestion'} value={inputState.wikiQuestion}
                               maxHeight={'348px'} limit={10000}
                               onChange={onChangeValue} placeholder={t('내용을 입력해주세요.')}/>
              </div>
            </div>
          </div>
        </ViewComponent>

        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>

        <div style={{width: '377px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <MenuTitle title={'이미지 등록'} unitCase={'MenuCompTitle'}/>
            <div style={{color: '#7E84A3', fontSize: '12px', textAlign: 'right', lineHeight: '16px', height: '34px'}}>
              <div>{t('*최대 4개의 이미지 등록 가능')}</div>
              <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
            </div>
          </div>
          <div style={{marginTop:'16px'}}>
            <TotalImageUploadBox imageKeyArray={(data)=>(setImageKeyState(data))}
                                 profileSelect={false}/>
          </div>
        </div>

      </ContentFrame>
    </SectionFrame>
  );
};
export default WikiRegister;

const ViewComponent = styled.div`
  width: 784px;
  height: 584px;
  
  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }

`