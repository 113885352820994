import React,{useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
  GridTableComponent , GridTableSelect , listResultMessage ,
  orderComponent ,
  tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import {FacilityDataType} from "../../types/Fundamental";
import {
  authValid ,
  dataValid , handleDecimalChange ,
  loadingRemove ,
  locationFilter ,
  modalListLoading ,
  numberLocaleString
} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import MainButtons from "../button/MainButtons";
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import ListInnerInput from "../input/ListInnerInput";
import TableValue from "../../style/TableValue";
import {useTranslation} from "react-i18next";
import SearchBar from "../input/SearchBar";

interface FacilityProps {
  companyCode: string
  viewType?: boolean
  cancelEvent: ()=>void
  selectProduct: any
  selectedFacility?: string[]
  receivedFacility:(data:any[])=>void
}

const FacilityRegisterModal:React.FunctionComponent<FacilityProps> = ({companyCode, selectedFacility, receivedFacility,
                                                                        selectProduct, cancelEvent, viewType}) => {
  const [facilityListData, setFacilityListData] = useState<Readonly<FacilityDataType[]>>([])
  const [selectFacilityData, setSelectFacilityData] = useState<string[]>(selectedFacility ? selectedFacility : []);
  const [checkedList, setCheckedList] = useState<string[]>(selectedFacility ? selectedFacility :  [])
  const [apiMessage, setApiMessage] = useState<string>('')
  const [searchInput, setSearchInput] = useState<string>('')
  const [pushInput, setPushInput] = useState<string>('')

  const titleList = authValid()? ['NO.','기종','제조번호','제조사','기계명','ICT번호','HW','버전','최대톤수', '금형채널'] : ['NO.','기종','제조번호','제조사','기계명','HW','버전','최대톤수', '금형채널']

  const selectCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
 const {t}:any = useTranslation()
  const onSelectedList = (select:boolean, value:any) =>{
    if(select){
      setSelectFacilityData([...selectFacilityData, value.facilityCode])
      setCheckedList((prev)=>([...prev, value.facilityCode]))
    }else{
      setSelectFacilityData(selectFacilityData.filter(el=>el !== value.facilityCode));
      setCheckedList(checkedList.filter(el => el !== value.facilityCode))
    }
  }

  const facilityListRes = async () => {
    setApiMessage('기계 목록을 가져오는 중 입니다.')
    try {
    modalListLoading()
      const response = await client.get(`/facility/infinite/facility/list/${companyCode}/@/${dataValid(pushInput,'@')}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      if(data){
        setFacilityListData(data.row)
      }else{
        setFacilityListData([])
      }
      loadingRemove()
      setApiMessage('등록된 기계가 없습니다.')
    }catch (e:any) {
      loadingRemove()
      console.log(e)
      setApiMessage(e.message)
    }
  }

  React.useEffect(()=>{
    facilityListRes().then()
  },[pushInput])

  const productChannelCheckRes = async (facilityCode: string, channel: string|number) => {
    try {
      const response = await client.get(`/product/channel/check/${facilityCode}/${channel}`,{headers:{Authorization}})
      console.log(response)
    }
    catch ( e ) {
      console.log(e)
    }
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          <BaseComponent>
            <div className={'modal_title'} style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff',
              color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'
            }}>
              <div>{t(viewType)? t('기계 보기') : t('기계 등록')}</div>
              {!viewType && <div>{t('(해당 제품 생산시 사용 하는 기계를 등록해 주세요.)')}</div>}
            </div>
            <div className={'modal_content'}>
              <ModalInfoTable>
                {orderComponent(t('업체명'),selectCompany.companyName)}
                {orderComponent(t('지역'),locationFilter(selectCompany.address))}
                {orderComponent(t('업체코드'),selectCompany.companyCode)}
                {orderComponent(t('단위'),selectProduct.unit)}
                {orderComponent(t('모델'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerModelName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                {orderComponent('CODE',tooltipModule(dataValid(selectProduct.serialCode || selectProduct.productSerialCode)))}
                {orderComponent(t('품명'),tooltipModule(dataValid(selectProduct.productName)))}
                {orderComponent(t('품목 종류'),selectProduct.productType || selectProduct.type)}
              </ModalInfoTable>
              <div style={{display:'flex', justifyContent:'space-between',marginTop:'16px'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                  <div style={{fontSize:'18px', fontWeight:800, marginRight:'8px'}}>{t('기계 리스트')}</div>
                  {!viewType &&
                  <div style={{display:'flex', fontSize:'14px', fontWeight:800, alignItems:'center'}}>
                    {t('(선택된 기계 수:')}
                    <div style={{color:'#0058ff', textDecoration:'underline', textUnderlineOffset:'3px',margin:'0 4px'}}>{selectFacilityData.length}</div>
                    {t('개)')}
                  </div>}
                </div>
                {/*선택한 모든 데이터 초기화 하는 버튼 있어야 할듯*/}
                {/*{!viewType && <MainButtons name={'선택 초기화'} marginDis={true}*/}
                {/*                           clickEvent={()=> {*/}
                {/*                             setSelectFacilityData([])*/}
                {/*                             setCheckedList([])}}/>}*/}
                <SearchBar clickEvent={()=> {setPushInput(searchInput)}} value={searchInput} clearButton={()=>{setPushInput(''); setSearchInput('')}}
                           keyFunction={(e)=>(setSearchInput(e.target.value.trimStart()))} clearButtonAccess={searchInput !== ''} disabled={facilityListData.length === 0 && pushInput === ''}
                />
              </div>
              <GridTableComponent tempCol={authValid() ? '40px 52px 140px 80px 108px 80px 108px 108px 120px 120px' : '40px 52px 140px 80px 148px 193px 128px 80px 120px 120px'}
                                  scrollWidth={1116}
                                  height={250} id={'grid-scroll'}>
                <div className={'grid-title-unchecked'}>
                  {titleList.map((v)=>(
                    <TableValue title={v} key={v} requiredValue={['']}/>
                  ))}
                </div>
                {
                  viewType?
                    facilityListData.filter((v:any)=>(selectedFacility?.includes(v.facilityCode))).length === 0?
                      listResultMessage(true, apiMessage,{width:1000, admin:192, user:192})
                      :
                      // 기계 보기
                      facilityListData.filter((v:any)=>(selectedFacility?.includes(v.facilityCode)))?.map((value,index)=>(
                                     <GridTableSelect selected={false}
                                                      className={'grid-list-unchecked-no_icon'} key={value.facilityCode}>
                                       <div>{index+1}</div>
                                       {validationValue(value.type)}
                                       {tooltipModule(dataValid(value.manufacturingNumber),16)}
                                       {tooltipModule(dataValid(value.maker),8)}
                                       {tooltipModule(dataValid(value.facilityName),12)}
                                       {authValid() && validationValue(value.ictNumber,'할당 안됨')}
                                       {tooltipModule(dataValid(value.hw,8))}
                                       {validationValue(value.version)}
                                       <ListInnerInput type={'noneInputUnit'} inputData={dataValid(numberLocaleString(value.maxTon),'-')} unit={'ton'}/>
                                       {validationValue(value.channel)}
                                     </GridTableSelect>
                                   ))
                    :
                    //기계 선택(등록)
                    facilityListData.length === 0?
                      listResultMessage(true, '* 등록된 정보가 없습니다.',{width:1000, admin:192, user:192})
                      :
                      facilityListData?.map((value,index)=>(
                        <GridTableSelect selected={selectFacilityData?.map((v:any)=>(v)).includes(value.facilityCode)}
                                         // style={{transform:`translateY(${-index}px)`}}
                                         onClick={()=>{onSelectedList(!selectFacilityData?.map((v:any)=>(v)).includes(value.facilityCode), value)}}
                                         className={'grid-list-unchecked-no_icon'} key={value.facilityCode}>
                          <div>{index+1}</div>
                          {validationValue(value.type)}
                          {tooltipModule(dataValid(value.manufacturingNumber),16)}
                          {tooltipModule(dataValid(value.maker),8)}
                          {tooltipModule(dataValid(value.facilityName),12)}
                          {authValid() && validationValue(value.ictNumber,'할당 안됨')}
                          {tooltipModule(dataValid(value.hw),10)}
                          {tooltipModule(dataValid(value.version),10)}
                          <ListInnerInput type={'noneInputUnit'} inputData={dataValid(value.maxTon,'-')} unit={'ton'}/>
                          <ListInnerInput type={'withButton'}
                                          name={'channel'} placeholder={'-'}
                                          inputData={value.channel}
                                          onChange={( e ) => handleDecimalChange( index , e , facilityListData , setFacilityListData ,'ea' )}
                                          clickButton={() => productChannelCheckRes(dataValid(value.facilityCode), dataValid(value.channel, null))}
                                          buttonText={'중복확인'}
                                          buttonWidth={'60px'}/>
                        </GridTableSelect>
                      ))
                }
              </GridTableComponent>
              <div style={{display:'flex',justifyContent:'center',marginTop:'16px'}}>
                {!viewType?
                  <React.Fragment>
                    <MainButtons name={'취소'} width={'144px'} margin={'0 12px'} clickEvent={cancelEvent}/>
                    <MainButtons name={'선택'} width={'144px'} margin={'0 12px'} clickEvent={()=> {
                      receivedFacility(checkedList);cancelEvent()}} buttonType={'popupCta'}/>
                  </React.Fragment>
                  :
                  <MainButtons name={'확인'} width={'144px'} buttonType={'popupCta'} clickEvent={cancelEvent}/>}
              </div>
            </div>
          </BaseComponent>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default FacilityRegisterModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 512px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;

  .modal_title {
    display: flex;
    //background-color: #CFFA61;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    &>div:nth-child(1) {
      font-size: 18px;
      font-weight: 800;
      margin-right: 8px;
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      color: rgba(23, 23, 37, 0.5);
    }
  }
  
  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
  }
`

const ModalInfoTable =  styled.div`
    border-bottom: 1px solid #E6E9F4;
    display: grid;
    grid-template-columns: repeat(4, 104px 146px);
    align-items: center;
    box-sizing: border-box;
    .order{
        &_title{
            height: 32px;
            background-color: #F5F6FA;
            display: flex;
            align-items: center;
            padding-left: 16px;
            font-weight: bold;
            font-size: 12px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
        }
        &_content{
            font-size: 14px;
            font-weight: normal;
            color: #171725;
            display: flex;
            align-items: center;
            height: 32px;
            padding-left: 16px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
            white-space: pre;
        }
    }
`

