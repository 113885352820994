import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import RegisterInput from "../input/RegisterInput";
import MainButtons from "../button/MainButtons";
import {
  dataAccessLoading ,
  dataValid ,
  initModal , isTouchDevice ,
  loadingRemove ,
  modalListLoading
} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "./ConfirmPopup";
import SelectConfirmModal from "./SelectConfirmModal";
import ToastPopup from "./ToastPopup";
import { useTranslation } from 'react-i18next';
import Draggable from "react-draggable";

interface Props {
  fileName?:any
  currentPosition: string
  clickEvent?: () => void
  cancelEvent?: () => void
  companyCode: string
  SDMCode: string|null
}

const DocumentFileMove:React.FunctionComponent<Props> = ({companyCode, SDMCode,fileName, currentPosition,
                                                           cancelEvent}) => {

  const [totalDocument, setTotalDocument] = React.useState<any[]>([])
  const [hoverDocument, setHoverDocument] = React.useState<string|null>(null)
  const [selectSDMCode, setSelectSDMCode] = React.useState<{SDMTopCode: string|null, name: string}>({SDMTopCode: null, name:'표준문서 관리'})
  const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)

  const [,setAddArray] = React.useState<boolean>(false)
  const {t}:any = useTranslation()
  const documentFolderListRes = async (folderSDMCode: string|null, selectItem:any|null) => {
    try {
    modalListLoading()
      const response = await client.post('/SDM/list',
                                         {companyCode: companyCode, SDMCode: folderSDMCode},
                                         {headers:{Authorization}})
      const data = response.data
      if(folderSDMCode === null){
        setTotalDocument(([{name:'표준문서 관리', SDMCode:null, SDMTopCode:null, lowerFolder: data.data.row.filter((item:any) => item.type === '폴더') }]))
      }else{
        if(Object.keys(selectItem).includes('lowerFolder')){
          delete selectItem.lowerFolder
        }else{
          Object.assign(selectItem, {lowerFolder: data.data.row.filter((item:any) => item.type === '폴더')})
        }
      }
      //사실상 쓰이진 않는데, 상태변화가 변경되면서, object 목록이 새로 렌더링 되는 효과가 있음.
      setAddArray(false)
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const fileMoveRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.get(`/SDM/moveFile/${dataValid(selectSDMCode.SDMTopCode, 'topLevel')}/${SDMCode}`,
                                        {headers:{Authorization}})
      console.log(response.data)
      if(response.data.status === 200){
        setTimeout(()=>{
          loadingRemove()
          setDataModal({menu:'success', message:`선택한 파일이 \n ${selectSDMCode.name.substring(0, 10) + '...'} 폴더로 이동 했습니다.`})
        },500)
      }else{
        loadingRemove()
        setDataModal({menu:'confirm', message: response.data.message})
      }

    }catch ( e ){
      loadingRemove()
      console.log(e)
    }
  }
  const lowerDocumentOpen = (lowerFolder:any[], grade: number) => {
    return (
      <React.Fragment>

        {lowerFolder?.map((item:any)=> (
          <React.Fragment>
            <div style={{backgroundColor:`${(item.SDMCode === hoverDocument || item.SDMCode === selectSDMCode.SDMTopCode)? 'rgba(0, 88, 255, 0.05)' : '#fff'}`,textIndent:`${8*grade}px`,
              color:`${(item.SDMCode === hoverDocument || item.SDMCode === selectSDMCode.SDMTopCode)? '#0058ff' : '#171725'}`,
              border:`1px solid ${item.SDMCode === selectSDMCode.SDMTopCode? '#0058ff' : '#fff'}`,
              boxSizing:'border-box', userSelect:'none',
              fontWeight: `${(item.SDMCode === hoverDocument || item.SDMCode === selectSDMCode.SDMTopCode)? 700 : 400}`}}
                 onMouseOver={()=>setHoverDocument(item.SDMCode)}
                 onDoubleClick={()=>{
                   documentFolderListRes(item.SDMCode, item).then()
                 }}
                 onClick={(e)=>{
              e.stopPropagation()
              setSelectSDMCode({SDMTopCode: item.SDMCode, name: item.name})
              setAddArray(true)}}>{item.name}
            </div>
            <React.Fragment>
              {lowerDocumentOpen(item?.lowerFolder, grade+1)}
            </React.Fragment>
          </React.Fragment>))
        }
      </React.Fragment>
    )
  }

  React.useEffect(()=>{
    documentFolderListRes(null,null).then()
  },[])

  const ellipsisText = (folderName:string) => {
    if(folderName.length > 10){
      return folderName.substring(0, 10) + '...'
    }else{
      return folderName
    }
  }

  return (
    <ModalBase modalContent={
      <React.Fragment>
        {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=> {if(dataModal.message?.includes('이동')){
          // setPersistSDMCode(selectSDMCode.SDMTopCode)
          if( cancelEvent ){
            cancelEvent()
          }
          window.location.reload()}
          setDataModal(initModal)}}/>
          : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=> {setDataModal(initModal)}}/>
            : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=> {setDataModal(initModal)}}/> : null}
        {dataModal.menu === 'confirm' &&
            <ConfirmPopup statusText={dataModal.message}
                          clickEvent={()=>{
                            if(dataModal.message?.includes('이동')){
                              // setPersistSDMCode(selectSDMCode.SDMTopCode)
                              if( cancelEvent ){
                                cancelEvent()
                              }
                              window.location.reload()}
                            setDataModal(initModal)
                          }}/>}
        {dataModal.menu === 'select' &&
            <SelectConfirmModal statusText={dataModal.message}
                                clickEvent={fileMoveRes}
                                cancelEvent={()=>setDataModal(initModal)}/>}

        <Draggable disabled={isTouchDevice}>
          <BaseComponent>
            <div className={'modal_title'}>{'파일 이동'}</div>
            <div className={'modal_list'} onMouseDown={(e)=>e.stopPropagation()}>
              <div>
                <div style={{display:'flex', alignItems:'center',userSelect:'none'}}>
                  <div style={{fontWeight:800, fontSize:'14px',marginRight:'24px'}}>{'선택 파일'}</div>
                  <RegisterInput type={'input'} value={fileName} name={'file'} disabled={true} maxWidth={'286px'}/>
                </div>
                <div style={{display:'flex', flexDirection:'column', paddingTop:'8px'}}>
                  <div style={{display:'flex', alignItems:'center',userSelect:'none'}}>
                    <div style={{fontWeight:800, fontSize:'14px',marginRight:'24px'}}>{'위치 선택'}</div>
                    <RegisterInput type={'input'} value={ellipsisText(currentPosition) + ' ▶ ' + ellipsisText(selectSDMCode.name)}
                                   name={'file'} disabled={true} maxWidth={'286px'}/>
                  </div>

                  <div className={'file_list'}>
                    <div className={'file_list_title'}>{'폴더명'}</div>
                    <div className={'file_list_content'}>
                      {totalDocument?.map((v:any)=>(
                        <React.Fragment>
                          <div onMouseOver={()=>setHoverDocument(null)}
                               onDoubleClick={()=>{
                               }}
                               onClick={(e)=>{
                                 e.stopPropagation()
                                 documentFolderListRes(v.SDMCode, v).then()
                                 setSelectSDMCode({SDMTopCode: v.SDMCode, name: v.name})
                                 setAddArray(true)
                               }}>{v.name}</div>
                          {lowerDocumentOpen(v?.lowerFolder,2)}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div style={{display:'flex',justifyContent:'center'}}>
                <MainButtons name={'취소'} width={'114px'} margin={'0 12px'} clickEvent={cancelEvent} />
                <MainButtons name={'선택'} width={'114px'} margin={'0 12px'} clickEvent={()=>setDataModal({menu:'select', message:'해당 폴더로 파일을 이동할까요?'})} buttonType={'popupCta'}/>
              </div>
            </div>
          </BaseComponent>
        </Draggable>

      </React.Fragment>
    }/>
  );
};
export default DocumentFileMove;

const BaseComponent = styled.div`
  width: 416px;
  height: 512px;
  box-sizing: border-box;

  .modal_title {
    height: 56px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 4px #15223214;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 18px;
    justify-content: space-between;
    user-select: none;
    cursor: all-scroll;
  }

  .modal_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    background-color: #fff;
    height: 456px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 0 0 6px 6px;
  }

  .file_list {
    width: 100%;
    height: 264px;
    border-radius: 6px;
    border: 1px solid #d7dbec;
    overflow: hidden;
    box-sizing: border-box;
    margin-top: 6px;
    .file_list_title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 48px;
      font-weight: 800;
      font-size: 12px;
      background-color: #F5F6FA;
      padding: 0 16px;
      align-self: center;
    }

    .file_list_content {
      height: 216px;
      overflow-y: scroll;
      
      &>div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 48px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 88, 255, 0.05);
          font-weight: 700;
          color: #0058ff;
        }
      }
      
      &>div:first-child{
        padding: 0 16px;
        font-size: 14px;
      }
      
      &>div:not(:first-child) {
        padding: 0 20px;
        font-size: 14px;
        position: relative;
        &::before{
          position: absolute;
          content:'┗';
          left: 0;
        }
      }

      &::-webkit-scrollbar {
        width: 24px;
        display: block;
        background-color: transparent;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #E6E9F4;
        border-radius: 16px;
        background-clip: padding-box;
        border: 8px solid transparent;
      }
    }

    //부모 태그에 스냅타입 지정
    //scroll-snap-type: y mandatory;

    //&>div>div{
    //  white-space: pre;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  margin: 0;
    //}
  }
`