import React , {useEffect} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useParams} from "react-router-dom";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import SingleDrawingUploadBox from "../../../../../components/input/SingleDrawingUploadBox";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import FactorySegmentationModal from "../../../../../components/modal/FactorySegmentationModal";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading , dataValid ,
  initModal ,
  loadingRemove ,
} from "../../../../../function/function";
import {phoneReg} from "../../../../../ValidationCheck";
import ToastPopup from "../../../../../components/modal/ToastPopup";

export type Factory_Type = {
  companyName: string,
  president: string,
  factoryName: string,
  address: string,
  detailAddress: string,
  factoryNumber: string,
  factoryCode: string,
  factoryManager: string,
  managerNumber: string,
  factoryDrawing: string | null,
  factoryDrawingUrl?: string | null,
  factoryImage: [],
  notice: string,
  factoryImageUrl: []
  factorySubdivision: string[]
}
const FactoryModify = () => {
  const params = useParams()
  const {id} = params
  const [imageKeyState , setImageKeyState] = React.useState<string[]>( [] );
  const [changeDrawingUrl ,] = React.useState<string | null>( null )
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [alertInput , setAlertInput] = React.useState<string>( '' )
  const [factoryDetail , setFactoryDetail] = React.useState( {companyName: '' , president: '' , factoryDrawing: ''} )
  const [openPostCode , setOpenPostCode] = React.useState<boolean>( false );
  const [factoryModify , setFactoryModify] = React.useState<Factory_Type | any>( {} )
  const {t}: any = useTranslation();
  const onEdit = ( e: any , trim?: boolean ) => {
    const {name , value} = e.target;
    setFactoryModify( {
                        ...factoryModify ,
                        companyName: factoryDetail.companyName ,
                        president: factoryDetail.president ,
                        [name]: trim ? value.trim() : value
                      } )
  }
  //업체명 대표고정값
  const detailFactory = async () => {
    try {
      const response = await client.post( '/factory/detail' ,
                                          {factoryCode: id} ,
                                          {headers: {Authorization}} )
      const data = response.data.data
      setFactoryModify( data )
      setFactoryDetail( {
                          companyName: data.companyName ,
                          president: data.president ,
                          factoryDrawing: data.factoryDrawingUrl
                        } )
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: e.meesage} )
    }
  }
  useEffect( () => {
    detailFactory().then()
  } , [] )
  //삭제 API
  const factoryDeleteFunction = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( '/factory/delete' , {
                                            factoryCode: id
                                          } ,
                                          {headers: {Authorization}}
      )
      if( response.data.status !== 200 ){
        setDataModal( {menu: 'confirm' , message: response.data.message} )
        loadingRemove()
      } else{
        setTimeout( () => {
          setDataModal( {menu: 'success' , message: '삭제 되었습니다.'} )
          loadingRemove()
        } , 500 )
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  const closeModify = () => {
    setDataModal( initModal )
    if( dataModal.message === t( '삭제 되었습니다.' ) ){
      window.location.href = `${authValid() ? `/admin/fas/factory` : `/fas/factory`}`
    } else if( dataModal.message === t( '수정 되었습니다.' ) ){
      window.location.href = `${authValid() ? `/admin/fas/factory/${id}` : `/fas/factory/${id}`}`
    }
  }
  // !passwordReg.test(customerRegister.password)
  //수정 API
  const factoryUpdateFunction = async () => {
    if( !factoryModify.factoryName ){
      setDataModal( {menu: 'confirm' , message: '공장명을 입력하지 않았습니다.'} )
      setAlertInput( '공장명을 입력하지 않았습니다.' )
    } else if( !factoryModify.factoryManager ){
      setDataModal( {menu: 'confirm' , message: '공장 담당자를 입력하지 않았습니다.'} )
      setAlertInput( '공장 담당자를 입력하지 않았습니다.' )
    } else if( !phoneReg.test( factoryModify.factoryNumber.trim() ) && factoryModify.factoryNumber ){
      setDataModal( {menu: 'confirm' , message: '공장 전화번호 형식이 잘못 되었습니다.'} )
      setAlertInput( '공장 전화번호 형식이 잘못 되었습니다.' )
    } else if( !factoryModify.managerNumber ){
      setDataModal( {menu: 'confirm' , message: '담당자 전화번호를 입력하지 않았습니다.'} )
      setAlertInput( '담당자 전화번호를 입력하지 않았습니다.' )
    } else{
      try {
        setDataModal( initModal )
        dataAccessLoading()
        const response = await client.post( '/factory/update' , {
          ...factoryModify ,
          factoryCode: id ,
          factoryNumber: factoryModify.factoryNumber.trim() ,
          companyName: factoryDetail.companyName ,
          president: factoryDetail.president ,
          factoryImage: imageKeyState ,
        } , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: t( data.message )} )
          setAlertInput( data.message )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: t( '수정 되었습니다.' )} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  const addressHandle = {
    clickBtn: () => setOpenPostCode( current => !current ) ,
    selectAddress: ( data: any ) => {
      factoryModify.address = data.address + `${!data.buildingName ? '' : `(${data.buildingName})`}`
      setOpenPostCode( false );
      setFactoryModify( {...factoryModify , detailAddress: ''} )
    } ,
  }
  const addressStyle: any = {
    display: 'block' ,
    width: '500px' ,
    height: '600px' ,
    position: 'absolute' ,
    boxShadow: '0 1px 7px #E6E9F4' ,
    borderRadius: '8px' ,
    backgroundColor: '#fff' ,
    padding: "7px" ,
    zIndex: 100 ,
  }
  const segmentHandler = ( segment: string[] ) => {
    Object.assign( factoryModify , {factorySubdivision: segment} )
    setDataModal( initModal )
  }
  const cancelInputPrint = () => {
    setFactoryModify( {...factoryModify , factoryDrawing: null , factoryDrawingUrl: null} )
  }
  const alertPoint = ( key: string ) => {
    if( key === 'companyName' ){
      return alertInput === '업체를 입력하지 않았습니다.';
    }
    if( key === 'factoryName' ){
      return alertInput === '공장명을 입력하지 않았습니다.';
    }
    if( key === 'address' ){
      return alertInput === '주소을 입력하지 않았습니다.';
    }
    if( key === 'factoryManager' ){
      if( alertInput === '공장 담당자를 입력하지 않았습니다.' ){
        return true
      } else return alertInput === '담당자명에 공백(스페이스)은 사용할 수 없습니다.';
    }
    if( key === 'factoryNumber' ){
      if( alertInput === '전화번호 형식 다름.' ){
        return true
      } else if( alertInput === '공장 전화번호 형식이 잘못 되었습니다.' ){
        return true
      } else return alertInput === '전화번호에 공백(스페이스)은 사용할 수 없습니다.';
    }
    if( key === 'managerNumber' ){
      if( alertInput === '담당자 전화번호 형식 다름.' ){
        return true
      } else if( alertInput === '담당자 전화번호를 입력하지 않았습니다.' ){
        return true
      } else return alertInput === '전화번호에 공백(스페이스)은 사용할 수 없습니다.';
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  return (
    <SectionFrame>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t( dataModal.message )} closeEvent={()=>setDataModal(initModal)} state={'error'}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} closeEvent={()=>setDataModal(initModal)} state={'warn'}/> : null}

      {dataModal.menu === 'printView' &&
        //@ts-ignore
          <DetailImageView image={factoryModify?.factoryDrawingUrl} closeMenu={() => {setDataModal( initModal )}}/>
      }
      {dataModal.menu === 'image' &&
          <DetailImageView image={`${changeDrawingUrl === null ? factoryDetail.factoryDrawing : changeDrawingUrl}`}
                           closeMenu={() => ( setDataModal( initModal ) )}/>}

      {dataModal.menu === 'confirm' && <ConfirmPopup clickEvent={closeModify} statusText={t( dataModal.message )}/>}

      {dataModal.menu === 'select' && <SelectConfirmModal statusText={t( dataModal.message )}
                                                          noticeText={t( ' * "예" 선택시 모든데이터는 삭제됩니다.' )}
                                                          clickEvent={() => {
                                                            factoryDeleteFunction().then();
                                                            setDataModal( initModal )
                                                          }}
                                                          cancelEvent={() => ( setDataModal( initModal ) )}/>}
      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={factoryUpdateFunction}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              clickEvent={() => { window.location.href = authValid() ? `/admin/fas/factory/${id}` : `/fas/factory/${id}`}}
          />
      }

      {dataModal.menu === 'segment' &&
          <FactorySegmentationModal factorySegment={factoryModify.factorySubdivision} segmentMenu={'register'}
                                    clickEvent={( v ) => segmentHandler( v )}
                                    factoryName={factoryModify.factoryName === '' ? undefined : factoryModify.factoryName}
                                    closeEvent={() => setDataModal( initModal )}/>}

      {openPostCode && <ModalBase clickEvent={() => setOpenPostCode( false )} modalContent={
        <DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
                      style={addressStyle}/>}/>}


      <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 공장 기본정보 > 수정'} unitCase={'MenuTitle'}/>
      <ContentFrame>
        <div style={{display: 'flex' , userSelect: 'none'}}>
          <EditBox>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'공장 기본정보 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons width={'56px'} name={'취소'}
                             clickEvent={() => {
                               setDataModal( {
                                               menu: 'cancel' ,
                                               message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                             } )
                             }}/>
                <MainButtons width={'56px'} name={'삭제'} disabled={buttonAuthValid( 'delete' , '3' )} buttonType={'delete'}
                             clickEvent={() => ( setDataModal( {menu: 'select' , message: '삭제 하시겠습니까?'} ) )}/>
                <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                             disabled={buttonAuthValid( 'update' , '3' )}
                             clickEvent={() => {setDataModal( {menu: 'register' , message: '이대로 수정하시겠습니까?'} )}}/>
              </div>
            </div>
            <div className={'scroll_func'}>
              {authValid() &&
                  <React.Fragment>
                    <ListInfoComponent title={t( '업체명' )} value={factoryDetail.companyName}/>
                    <ListInfoComponent title={t( '대표' )} value={factoryDetail.president}/>
                  </React.Fragment>
              }

              <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 392px)'}}>
                <RegisterComponent type={'text'} onChange={( e ) => onEdit( e , true )} title={t( '공장명' )}
                                   required={true}
                                   error={alertPoint( 'factoryName' )} clickEvent={() => setAlertInput( '' )}
                                   value={factoryModify.factoryName} name={'factoryName'} paddingRight={true}/>

                <RegisterComponent type={'segment'} onChange={onEdit} title={t( '공장 세분화' )}
                                   disabled={buttonAuthValid( 'update' , '3' )}
                                   clickEvent={() => setDataModal( {menu: 'segment' , message: ''} )}
                                   value={''} segmentAccess={factoryModify?.factorySubdivision?.length !== 0}
                                   name={'factorySubdivision'} buttonName={'세분화 수정'}/>
              </div>

              <RegisterComponent type={'address'} onChange={onEdit} title={t( '공장 주소' )} width={'72px'} required={true}
                                 name={'address'} subName={'detailAddress'} subValue={factoryModify.detailAddress}
                                 clickEvent={() => {
                                   addressHandle.clickBtn();
                                   setAlertInput( '' )
                                 }} value={factoryModify.address}/>

              <RegisterComponent type={'phone'} value={factoryModify.factoryNumber}
                                 error={alertPoint( 'factoryNumber' )} title={t( '전화번호' )}
                                 onChange={onEdit} name={'factoryNumber'} clickEvent={() => setAlertInput( '' )}
                                 numberFormat={factoryModify.factoryNumber}/>

              <RegisterComponent type={'text'} onChange={onEdit} title={t( '공장 담당자' )}
                                 error={alertPoint( 'factoryManager' )} required={true}
                                 clickEvent={() => setAlertInput( '' )}
                                 value={factoryModify.factoryManager} name={'factoryManager'}/>

              <RegisterComponent type={'phone'} value={factoryModify.managerNumber}
                                 error={alertPoint( 'managerNumber' )} title={t( '공장 담당자 전화번호' )} required={true}
                                 onChange={onEdit} name={'managerNumber'} clickEvent={() => setAlertInput( '' )}
                                 numberFormat={factoryModify.managerNumber}/>

              <RegisterComponent type={'print'} title={t( '도면정보' )} buttonName={'업로드'} name={'factoryDrawing'}
                                 disabled={!factoryModify.factoryDrawingUrl}
                                 clickEvent={() => setDataModal( factoryModify.factoryDrawingUrl ? {menu: 'printView'} : initModal )}
                                 clearEvent={cancelInputPrint}
                                 printTag={<SingleDrawingUploadBox buttonWidth={'72px'}
                                                                   receiveData={( data ) => setFactoryModify( {
                                                                                                                ...factoryModify ,
                                                                                                                factoryDrawing: data.imgKey ,
                                                                                                                factoryDrawingUrl: data.imgUrl
                                                                                                              } )}/>}
                                 width={'72px'}
                                 value={!factoryModify.factoryDrawingUrl ? '도면을 등록해주세요.' : factoryModify.factoryDrawingUrl?.split( '/' ).pop()}/>
              <div style={{display: 'flex' , borderBottom: '1px solid #E6E9F4' , boxSizing: 'border-box'}}>
                <div className={'input_title'} style={{height: '180px'}}>{t( '내용' )}</div>
                <div className={'input_box'} style={{height: '180px'}}>
                  <InputTextArea name={'notice'} value={factoryModify.notice} placeholder={t( '내용을 입력해주세요.' )}
                                 onChange={onEdit}/>
                </div>
              </div>
            </div>
          </EditBox>
          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>
          <div style={{width: '370px' , height: '34px' , alignItems: 'center'}}>
            <div style={{display: 'flex' , justifyContent: 'space-between' , marginBottom: '16px'}}>
              <MenuTitle title={'공장 이미지 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                <div>{t( '*최대 4개의 이미지 등록 가능' )}</div>
                <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
              </div>
            </div>
            <TotalImageUploadBox imageKeyArray={( data ) => setImageKeyState( data )} profileSelect={false}
                                 receiveUrl={dataValid( factoryModify?.factoryImageUrl , [] )}
                                 receiveKey={factoryModify?.factoryImage}/>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default FactoryModify;
const EditBox = styled.div`
  width: 784px;
  height: 620px;

  .scroll_func {
    width: 810px;
    height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  .input {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }

    &_box {
      border-top: 1px solid #E6E9F4;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }

    &_tel {
      width: 600px;
      height: 32px;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #D7DBEC;
      border-radius: 4px;
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      padding: 0 8px;

      &::placeholder {
        font-size: 14px;
        color: rgba(23, 23, 37, 0.3);
        font-weight: 700;
        font-family: "NanumGothicOTF", sans-serif;
      }
    }
`
const InputTextArea = styled.textarea`
  width: 600px;
  height: 164px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  white-space: pre-wrap;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d7dbec;
  font-family: "NanumGothicOTF", sans-serif;

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: 700;
    font-family: "NanumGothicOTF", sans-serif;
  }

  //overflow-y: overlay;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;


  &::-webkit-scrollbar {
    width: 15px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`
