import React , {useEffect , useRef , useState} from 'react';
import styled from "styled-components";
import checked from "../../asset/image/check-icon.svg"


interface Props {
  // filterOptions: { id: number; label: string; checked: boolean }[];
  // onFilterChange: (newFilterOptions: { id: number; label: string; checked: boolean }[]) => void;
  checkedData:(data:any)=>void
  affiliateViewType?:boolean
}




const CheckDropFilter:React.FC<Props> = ({checkedData,affiliateViewType}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (e : React.MouseEvent<HTMLDivElement>) => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // const onFilterOptionChange = (id: number, checked: boolean) => {
  //   const newFilterOptions = filterOptions.map((option) =>
  //                                                option.id === id ? { ...option, checked: !option.checked } : option
  //   );
  //
  //   const isOptionChecked = newFilterOptions.some(option => option.checked);
  //   if (!isOptionChecked) {
  //     setIsDropdownOpen(false);
  //   } else {
  //     onFilterChange(newFilterOptions);
  //   }
  // };
  //
  // const selectedLabels = filterOptions
  // .filter(option => option.checked)
  //  .map(option => option.label);


  const [selectedOption, setSelectedOption] = useState('@');

  const handleOptionChange = (e:any) => {
    setSelectedOption(e.target.value);
    checkedData(e.target.value);
    setIsDropdownOpen(false);
  };
  console.log(selectedOption)
  return (
    <BaseComp>
      <DropdownButton onClick={toggleDropdown}>
        {/*<div style={{fontSize:'12px'}}>*/}
        {/*  {selectedLabels.length === filterOptions.length*/}
        {/*    ? '전체'*/}
        {/*    : selectedLabels.length === 0*/}
        {/*      ? '전체'*/}
        {/*      : 'M'}*/}
        {/*</div>*/}
        <div style={{fontSize:'12px'}}>{selectedOption === '@'? '전체' : selectedOption === 'mida' ?  '마이다' : '협력사'}</div>
         {/*<input type={'checkbox'}*/}
         {/*       checked={isAllChecked}*/}
         {/*       onChange={(e) => {*/}
         {/*         const newFilterOptions = filterOptions.map((option) => ({*/}
         {/*           ...option,*/}
         {/*           checked: e.target.checked,*/}
         {/*         }));*/}
         {/*         onFilterChange(newFilterOptions);*/}
         {/*       }}*/}
         {/* />*/}
      </DropdownButton>
      {isDropdownOpen && (
        <DropdownContent ref={dropdownRef}>
          <label style={{display:'flex', alignItems:'center',marginBottom:'10px'}} htmlFor={'all'}>
            <input id={'all'} style={{margin:'0 8px 0 0'}}
              type="radio"
              value="@"
              checked={selectedOption === '@'}
              onChange={handleOptionChange}
            />
            <div style={{fontSize:'12px'}}>{'전체'}</div>
          </label>
          {affiliateViewType &&
              <label style={{display:'flex', alignItems:'center',marginBottom:'10px'}} htmlFor={'affiliate'}>
                <input id={'affiliate'} style={{margin:'0 8px 0 0'}}
                       type="radio"
                       value="affiliate"
                       checked={selectedOption === 'affiliate'}
                       onChange={handleOptionChange}
                />
                <div style={{fontSize:'12px'}}>{'협력사'}</div>
              </label>
          }

          <label style={{display:'flex', alignItems:'center'}} htmlFor={'mida'}>
            <input id={'mida'} style={{margin:'0 8px 0 0'}}
              type="radio"
              value="mida"
              checked={selectedOption === 'mida'}
              onChange={handleOptionChange}
            />
            <div style={{fontSize:'12px'}}>{'마이다 프레스 공급업체'}</div>
          </label>

          {/*{filterOptions.map((v) => (*/}
          {/*  <label key={v.id} style={{ display: 'flex',alignItems:'center' }}>*/}
          {/*    <input*/}
          {/*      type="checkbox"*/}
          {/*      checked={v.checked}*/}
          {/*      onChange={(e) => onFilterOptionChange(v.id, e.target.checked)}*/}
          {/*    />*/}
          {/*    <div style={{fontSize:'12px',marginLeft:'8px'}}>{v.label}</div>*/}
          {/*  </label>*/}
          {/*))}*/}
        </DropdownContent>
      )}

    </BaseComp>
  );
};
export default CheckDropFilter;

const BaseComp = styled.div`
  position: relative;
  display: inline-block;
  
input[type='checkbox'] {
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #7E84A3;
}
input[type='checkbox']:checked {
  background-color: #0058FF;
  background-image: url(${checked});
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}
`

const DropdownButton = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #D7DBEC;
  cursor: pointer;
  z-index: 102;
  align-items: center;
  width: 56px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  margin-left: 8px;
  padding: 0 8px;
  
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #171725;
  }

  &:hover {
    background-color: #f6f6f6;
  }

  &:focus {
    outline: none;
  }
  
  `

const DropdownContent = styled.div`
  position: absolute;
  left:5px;
  background-color: #ffffff;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 101;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #D7DBEC;
  overflow: auto;
  display: block;
  
  label {
    &:hover {
      background-color: #f6f6f6;
      cursor: pointer;
    }
  }
  
   
`;
