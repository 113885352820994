import React , {useEffect , useState} from 'react';
import ModalBase from "../../ModalBase";
import styled from "styled-components";
import {
  autoTextValueValidation ,
  GridTableComponent ,
  GridTableSelect ,
  modalTitleBase , NoData ,
  orderComponent ,
  tooltipModule ,
  validationValue
} from "../../../../style/StyleComponent";
import {
  authValid ,
  customerCount ,
  dataValid ,
  decimalNumberValid ,
  formatNumber ,
  initModal
} from "../../../../function/function";
import ListInnerInput from "../../../input/ListInnerInput";
import {AutoTextSize} from "auto-text-size";
import TESTInnerInput from "../../../input/TESTInnerInput";
import MainButtons from "../../../button/MainButtons";
import bottom from "../../../../asset/image/buttonTriangleBottom.svg";
import top from "../../../../asset/image/buttonTriangleTop.svg";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Modal_View_Type} from "../../../../types/Fundamental";
import TableValue from "../../../../style/TableValue";
import SelectConfirmModal from "../../SelectConfirmModal";

const BomViewerModal:React.FunctionComponent<{bomDetailData:any, closeEvent:()=>void, manageButton?:boolean}> = ({bomDetailData, closeEvent, manageButton}) => {

  const [rowList, setRowList] = useState<any[]>([])
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [loadingBom, setLoadingBom] = useState<boolean>(false)

  const productTypeTitle = ['Lv.', 'CODE(품번)', '품명', '품목 종류', '단위', '생산공정', 'BOM', '사용량 / 소요량', '캐비티 적용']
  const bomTreeListRes = async () => {
    setLoadingBom(true)
    try {
      const response = await client.get(`/product/bom/tree/list/${bomDetailData?.productCode}`,{headers:{Authorization}})
      const data = response.data.data
      if(response.data.status === 200){
        setRowList(data)
      }
      else{
        setDataModal({menu:'warn', message:response.data.message})
      }
    }
    catch ( e:any ) {
      console.log(e)
      setDataModal({menu:'warn', message:e.message})
    }
    finally {
      setLoadingBom(false)
    }
  }

  useEffect(()=>{
    bomTreeListRes().then()
  },[])

  const openRow = (selectedItem: any) => {
    const toggleOpenState = (item: any) => {
      if (item === selectedItem) {
        return { ...item, open: !item.open, }
      }
      else if (item.bomInfo) {
        return { ...item, bomInfo: item.bomInfo.map(toggleOpenState) }
      }
      return item
    }

    setRowList(prevRowData => {
      return prevRowData.map( toggleOpenState )
    })
  }


  const lowerBomListOpen = (bomList:any[], parentCode: string = "") => {
    return (
      <React.Fragment>
        {bomList?.map((data:any,i:any)=>(
          <React.Fragment>
            <GridTableSelect style={{backgroundColor:
                `rgba(0,88,255,${0.06 * data.depthLevel})` }} className={'grid-list-unchecked-no_icon'}>
              <div
                style={{marginLeft: `${(data.depthLevel <= 2)? '0' :  (data.depthLevel - 1) * 8}px` , display:'flex'}}
              >
                <span style={{marginRight:'4px', fontWeight:800, fontSize:'16px'}}>{(data.depthLevel !== 1? '┗ ' : '')}</span>
                <div style={{width:'60px'}}>
                  <AutoTextSize maxFontSizePx={14} minFontSizePx={10}>
                    {data.levelPosition}
                  </AutoTextSize>
                </div>
              </div>

              {autoTextValueValidation({value: data.serialCode})}
              {autoTextValueValidation({value: data.productName})}

              {validationValue(data?.type)}
              {validationValue(data?.unit)}
              {validationValue(data?.processName)}

              {
                (data?.type === '원자재' || data?.type === '부자재')?
                  <div>{'-'}</div>
                :
                <MainButtons name={data?.bomInfo?.length === 0? 'BOM 없음' :  data.open? 'BOM 닫기' : 'BOM 열기'}
                             disabled={(data?.bomInfo?.length === 0)} selected={data.open}
                             buttonType={'cta'} withIcon={data?.bomInfo?.length !== 0} iconPositionBack={true} icon={data?.open? bottom : top}
                             margin={'0'} width={'108px'} clickEvent={()=> {openRow(data)}}
                />
              }
              <ListInnerInput type={'noneInputUnit'} inputData={decimalNumberValid(data.usage)}
                              unit={data.unit} style={{width:'106px'}}/>

              <div>{data.requirementFormula === '1'? '-' : '적용됨'}</div>
            </GridTableSelect>
            {data?.open && lowerBomListOpen(data?.bomInfo, parentCode + (i + 1) + "-")}
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {dataModal.menu === 'select' && <SelectConfirmModal
              statusText={dataModal.message} clickEvent={()=> {
            window.open( authValid() ? `/admin/fas/product/bom` : `/fas/product/bom` )
            setDataModal(initModal)
          }}
              cancelEvent={()=>setDataModal(initModal)}
          />}
          {modalTitleBase({mainTitle:'BOM 보기'})}
          <div className={'modal_content'}>
            <TableDefaultOption>

              {orderComponent('거래처',
                              (bomDetailData && bomDetailData.customer && bomDetailData.customer.length > 0 && Object.keys(bomDetailData.customer[0]).length > 0)
                                ? customerCount(bomDetailData.customer)[0]?.customerName +
                                ((customerCount(bomDetailData.customer).length - 1) !== 0 ? ` 외 ${customerCount(bomDetailData.customer).length - 1}` : '')
                                : '-'
              )}

              {orderComponent('모델',
                              (bomDetailData && bomDetailData.customer && bomDetailData.customer.length > 0 && Object.keys(bomDetailData.customer[0]).length > 0)
                                ? bomDetailData.customer[0].customerModelName +
                                ((bomDetailData.customer.length - 1) !== 0 ? ` 외 ${bomDetailData.customer.length - 1}` : '')
                                : '-'
              )}
              {orderComponent('CODE' , bomDetailData.serialCode)}
              {orderComponent('품명' , tooltipModule(bomDetailData.productName , 10))}
              {orderComponent('품목 종류' , bomDetailData.type)}
              {orderComponent('생산공정' , tooltipModule(bomDetailData.processName , 8))}
              {orderComponent('단위' , bomDetailData.unit , 'ea')}
              {orderComponent('캐비티',bomDetailData.cavity ,1)}
            </TableDefaultOption>
            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'8px'}}>
              <MainButtons name={'새로고침'} loading={loadingBom} disabled={loadingBom} marginDis={!manageButton}
                           clickEvent={bomTreeListRes} />
              {manageButton && <MainButtons name={'BOM 관리'} marginDis={true}
                            clickEvent={() => setDataModal( {menu: 'select' , message: 'BOM 관리 페이지로\n이동 하시겠습니까?'} )}/>}
            </div>
            <GridTableComponent tempCol={'120px repeat(2, 140px) repeat(3, 80px) 128px 128px 60px'}
                                gridGap={8} marginCus={8} scrollX_Hide={true} scrollWidth={1070}>
              <div className={'grid-title-unchecked'}>
                {productTypeTitle.map((v)=>(
                  <TableValue title={v} requiredValue={[]}/>
                ))}
              </div>
              {
                rowList?.length === 0?
                <NoData height={200}>{loadingBom? '* BOM을 가져오는 중 입니다.' : '* 등록된 BOM이 없습니다.'}</NoData>
                :
                lowerBomListOpen(rowList)
              }
            </GridTableComponent>
            <div style={{marginTop:'16px', display:'flex', justifyContent:'center'}}>
              <MainButtons name={'확인'} buttonType={'popupNormal'} width={'120px'} clickEvent={closeEvent}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default BomViewerModal;

const BaseComponent = styled.div`
  width: 1112px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    user-select: none;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 784px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
    justify-content: space-between;
  }
`

const TableDefaultOption = styled.div`
  //margin: 16px;
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns:  repeat(4, 104px 166px);
  align-items: center;
  box-sizing: border-box;

  .order {
    &_title {
      height: 32px;
      background-color: #F5F6FA;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }

    &_content {
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
      white-space: pre;
    }
  }
`
