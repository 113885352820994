import React , {useState , useEffect} from 'react';
import {ContentFrame , NoData , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import styled from "styled-components";
import {Modal_View_Type , PeripheralType} from "../../../../../types/Fundamental";
import PreviewImage from "../../../../../components/display/PreviewImage";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";

import {
  authValid ,
  buttonAuthValid ,
  dataValid ,
  initModal ,
  locationFilter
} from "../../../../../function/function";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import {useLocation} from "react-router-dom";

const PeripheralsDetail = () => {
  const location = useLocation()
  const getPeripheralsCode: string | undefined = location.pathname.split( '/' ).pop()
  const [peripheralDetail , setPeripheralDetail] = useState<PeripheralType | any>();
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const PeripheralsDetailRes = async () => {
    try {
      const response = await client.post( `/peripheral/detail` , {
        peripheralCode: getPeripheralsCode
      } , {headers: {Authorization}} )
      const data = response.data.data
      setPeripheralDetail( data );
    } catch ( e ) {
      console.log( e )
    }
  }
  useEffect( () => {
    PeripheralsDetailRes().then()
  } , [] )
  return (
    <SectionFrame>
      {dataModal.menu === 'preview' &&
          <ImageDetailPreview profile={peripheralDetail?.peripheralImgUrl[dataModal.index]}
                              imageUrl={peripheralDetail?.peripheralImgUrl}
                              clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'spec' &&
          <DetailImageView image={peripheralDetail.specImgUrl} closeMenu={() => {setDataModal( initModal )}}/>}
      {dataModal.menu === 'ability' &&
          <DetailImageView image={peripheralDetail.abilityImgUrl} closeMenu={() => {setDataModal( initModal )}}/>}
      {dataModal.menu === 'manual' &&
          <DetailImageView image={peripheralDetail.manualImgUrl} closeMenu={() => {setDataModal( initModal )}}/>}

      <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 주변장치 기본정보 > 조회'}
                 unitCase={'MenuTitle'}/>
      <ContentFrame height={'680px'}>
        <div style={{display: 'flex' , height: '620px'}}>
          <RegisterComp>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'주변장치 기본정보 조회'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex'}}>
                  <MainButtons name={'목록'} width={'56px'} clickEvent={() => {
                    window.location.href = `${authValid() ? '/admin/fas/peripherals' : '/fas/peripherals'}`
                  }}/>
                  <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                               disabled={buttonAuthValid( 'update' , '7' )}
                               clickEvent={() => {window.location.href = authValid() ? `/admin/fas/peripherals/modify/${peripheralDetail?.peripheralCode}` : `/fas/peripherals/modify/${peripheralDetail?.peripheralCode}`}}/>
                </div>
              </div>
            </div>
            {authValid() &&
                <React.Fragment>
                  <ListInfoComponent title={'업체명'} value={dataValid( peripheralDetail?.companyName , '업체 미선택' )}
                                     bottomBorder={false}/>
                  <ListInfoComponent title={'지역'}
                                     value={locationFilter( peripheralDetail?.companyAddress?.split( '/' ).pop() )}
                                     bottomBorder={false}/>
                </React.Fragment>}
            <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 1fr)'}}>
              <ListInfoComponent title={'공장명'} value={dataValid( peripheralDetail?.factoryName , '미등록' )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'공장 세분화'} value={dataValid( peripheralDetail?.factorySubDivision , '미등록' )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'장치 종류'} value={dataValid( peripheralDetail?.peripheralType , )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'담당자'} value={dataValid( peripheralDetail?.managerName )} bottomBorder={false}/>
              <ListInfoComponent title={'제조 연월일'} value={dataValid( peripheralDetail?.manufactureDate )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'장치 제조번호'} value={dataValid( peripheralDetail?.peripheralNumber )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'장치 제조사'} value={dataValid( peripheralDetail?.peripheralManufacturer )}
                                 bottomBorder={false}/>
              <ListInfoComponent title={'장치 이름'} value={dataValid( peripheralDetail?.peripheralName )}
                                 bottomBorder={false}/>
              {/*<ListInfoComponent type={'textClick'} title={'연동설비'} contentText={'연동 설비 보기'} value={'연동설비'} bottomBorder={false}/>*/}
              {/*<ListInfoComponent title={'-'} value={''} bottomBorder={false}/>*/}
            </div>
            <ListInfoComponent type={'textarea'} height={'240px'} title={'비고'}
                               value={dataValid( peripheralDetail?.note , <NoData
                                 height={220}>{' * 작성된 내용이 없습니다.'}</NoData> )} bottomBorder={true}/>
          </RegisterComp>

          <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
          {/*@ts-ignore*/}
          <div className={'scroll_comp'} style={{width: '379px' , overflowY: 'overlay'}}>
            <div style={{display: 'flex' , flexWrap: 'wrap'}}>
              <div>
                <MenuTitle title={'장치 이미지'} unitCase={'MenuCompTitle'}/>
                {/*Grid 안에서 gap 은 스마트TV 에서 적용됨.*/}
                <div
                  style={{marginTop: '16px' , display: 'grid' , gridTemplateColumns: 'repeat(2, 168px)' , gap: '16px'}}>
                  {!peripheralDetail?.peripheralImgUrl.length ?
                    <PreviewImage imageUrl={''} deleteImg={() => ''} noneDelete={true}/>
                    :
                    peripheralDetail?.peripheralImgUrl?.map( ( v: any , i: number ) => (
                      <PreviewImage imageUrl={v} deleteImg={() => ''} noneDelete={true}
                                    clickEvent={() => {setDataModal( {menu: 'preview' , index: i} )}}/>
                    ) )
                  }
                </div>
              </div>
            </div>

            <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 168px)' , gap: '16px' , marginTop: '20px'}}>
              <div>
                <MenuTitle title={'스펙 명판'} unitCase={'MenuCompTitle'}/>
                <PreviewImage imageUrl={dataValid( peripheralDetail?.specImgUrl , '' )}
                              clickEvent={() => {!peripheralDetail?.specImgUrl ? setDataModal( initModal ) : setDataModal( {menu: 'spec'} )}}
                              deleteImg={() => ''} noneDelete={true}/>
              </div>
              <div>
                <MenuTitle title={'능력 명판'} unitCase={'MenuCompTitle'}/>
                <PreviewImage imageUrl={dataValid( peripheralDetail?.abilityImgUrl , '' )}
                              clickEvent={() => {!peripheralDetail?.abilityImgUrl ? setDataModal( initModal ) : setDataModal( {menu: 'ability'} )}}
                              deleteImg={() => ''} noneDelete={true}/>
              </div>
              <div>
                <MenuTitle title={'사용 설명서'} unitCase={'MenuCompTitle'}/>
                <PreviewImage imageUrl={dataValid( peripheralDetail?.manualImgUrl , '' )}
                              clickEvent={() => {!peripheralDetail?.manualImgUrl ? setDataModal( initModal ) : setDataModal( {menu: 'manual'} )}}
                              deleteImg={() => ''} noneDelete={true}/>
              </div>
            </div>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default PeripheralsDetail;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}
  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`
