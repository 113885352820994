import React , {useState , useRef , useEffect} from 'react';
import {
	autoTextValueValidation ,
	ContentFrame ,
	listResultMessage ,
	SectionFrame ,
	validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";
import SearchBar from "../../../../../components/input/SearchBar";
import {Error_Type , Modal_View_Type} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {
	allCheckedState , allPersistCheckedFunction , authValid , buttonAuthValid ,
	dataAccessLoading ,
	dataValid ,
	filterCheckedRow ,
	initError ,
	initModal ,
	loadingRemove , menuVisitLogRegister , onChangeArray ,
	removeCheckedFunction ,
	singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState , useRecoilValue} from "recoil";
import {
	persistBlockPage ,
	persistCompanyData ,
	selectedCode ,
	userPersistCompanyData
} from "../../../../../common/StateManage";
import styled from "styled-components";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import SingleListImageUploadModal from "../../../../../components/modal/SingleListImageUploadModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";

import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";


const AccountManagement = () => {
	const {t}: any = useTranslation();

	const customerTitle = ['거래처명', '거래처구분', '대표자명', '담당자명', '전화번호(거래처)', '휴대폰번호(담당자)', 'FAX', '주소', '상세주소', '사업자번호', '사업자 등록증','통장 사본'];
	const needValue = ['거래처명','대표자명','담당자명','휴대폰번호(담당자)','주소']
	const [rowList, setRowList] = useState<any[]>([])
	const [checkedList, setCheckedList] = useState<any>([])
	const [idIndex, setIdIndex] = useState<number>(1)
	const [hoverIcon, setHoverIcon] = useState<string>(modify)
	const [iconSelect, setIconSelect] = useState<number>(-1)
	const [focusList, setFocusList] = useState<number>(-1)
	const [customerList, setCustomerList] = useState<any[]>([]);
	const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
	const [selectRowIndex, setSelectRowIndex] = useState<number>(0)
	const [page, setPage] = useState<number>(1)
	const [totalPage, setTotalPage] = useState<number>(1)
	const [searchInput, setSearchInput] = useState<string>('')
	const [pushInput, setPushInput] = useState<string>('')
	const [selectCode, setSelectCode] = useRecoilState(selectedCode)
	const [selectAccountData, setSelectAccountData] =useState<any>({});
	const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
	const [errorState,setErrorState] = useState<Error_Type>( initError)

	const [deleteCaseArray, setDeleteCaseArray] = useState<any>([])
	const [pageBlock, setPageBlock] = useRecoilState<number>(persistBlockPage);
	const scrollRef = useRef<any>(null)

	const scrollToStart = () => {
		scrollRef.current.scrollLeft = 0
		scrollRef.current.scrollTop = 0
	}
	const addListRow = () => {
		setIdIndex(idIndex + 1)
		const rowInput = {
			id: idIndex,
			companyCode:selectedCompany.companyCode,
			customerName:'',
			president:'',
			managerName:'',
			phoneNumber:'',
			managerNumber:'',
			fax:'',
			address:'',
			detailAddress:'',
			businessNumber:'',
			businessRegistration:'',
			bankBook:'',
			bankBookUrl:'',
			businessRegistrationUrl:'',
			division:'',
		}
		setRowList([...rowList, rowInput])
		setCheckedList([...checkedList, rowInput.id])
		scrollToStart()
	}


	const updateFunction =() =>{
		if(page === 1){
			if(pushInput === ''){
				customerListRes().then()
			}else{
				customerSearchListRes().then()
			}
		}else{
			setPage(1)
		}
	}

	const checkboxValid = () => {
		if(rowList.length !== 0){
			return {list: rowList, id: 'id'}
		}else{
			return {list: customerList, id: 'customerCode'}
		}
	}


	const onDeleteFun = () => {
		if(rowList.length === 0){
			setDataModal({menu: 'select', message: `${checkedList.length}개의 거래처를 삭제 하시겠습니까?`})
		}else{
			setDataModal({menu: 'back', message: '선택된 행의 정보를 삭제하시겠습니까?'})
		}
	}


	const customerListRes = ()=> ListCustomHooks(`/customer/list/${page}/${10}/${selectedCompany.companyCode}`,page,setTotalPage,setPageBlock,setCustomerList,setErrorState,setPushInput,setSearchInput)
	const customerSearchListRes = () => ListSearchPostCustomHooks(`/customer/search`,page,setTotalPage,setPageBlock,setCustomerList,setErrorState,{page:page,size:10,companyCode:selectedCompany.companyCode,keyword:pushInput})

	//리스트
	// const customerListRes = async () => {
	// 	try {
	// 		listLoadingCircle()
	// 		const response = await client.get(`/customer/list/${page}/${10}/${selectedCompany.companyCode}`,{
	// 			headers:{Authorization}
	// 		})
	// 		const data = response.data.data
	// 		const valid = response.data
	//
	// 		if(valid.status !== 200){
	// 			setErrorState({status:valid.status,notice:valid.message})
	// 			setCustomerList([])
	// 		}else{
	// 			setTotalPage(data.total_page)
	// 			setErrorState({status:valid.status,notice:t('* 등록된 거래처가 없습니다. 거래처를 등록해 주세요.')})
	// 			setPushInput('');
	// 			setSearchInput('');
	// 			setCustomerList(data.row)
	// 			// if(data.total_page > 1 && page > 1){
	// 			// 	setCustomerList((prev:any)=> prev.concat(data.row))
	// 			// }else{
	// 			// 	setCustomerList(data.row)
	// 			// }
	// 		}
	// 		loadingRemove()
	// 	}catch (e) {
	// 		console.log(e)
	// 		loadingRemove()
	// 	}
	// }
	//
	//
	//
	// //검색
	// const customerSearchListRes = async () => {
	// 	try {
	// 		listLoadingCircle()
	// 		const response = await client.post(`/customer/search`,{
	// 			///${page}/${10}/${selectedCompany.companyCode}/${pushInput}
	// 			page:page,
	// 			size:10,
	// 			companyCode:selectedCompany.companyCode,
	// 			keyword:pushInput
	// 		},{
	// 			headers:{Authorization}
	// 		})
	// 		const data = response.data.data
	// 		setTotalPage(data.total_page)
	// 		setCustomerList(data.row)
	// 		// if(data.total_page > 1 && page > 1){
	// 		// 	setCustomerList((prev:any)=> prev.concat(data.row))
	// 		// }else{
	// 		// 	setCustomerList(data.row)
	// 		// }
	// 		loadingRemove()
	// 	}catch (e:any) {
	// 		console.log(e)
	// 		setDataModal({menu:'confirm',message:e.message})
	// 		loadingRemove()
	// 	}
	// }
	//등록
	const customerRegisterRes = async () => {
		if(filterCheckedRow(rowList, checkedList, 'id').find((v)=>!v.customerName.trim())){
			setDataModal({menu:'confirm',message:'거래처명을 입력해 주세요.'})
		}

		else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.president.trim()))){
			setDataModal({menu:'confirm', message:'대표자를 입력해 주세요.'})
		}
		else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.managerName.trim()))){
			setDataModal({menu:'confirm', message:'담당자를 입력해 주세요.'})
		}
		else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.managerNumber))){
			setDataModal({menu:'confirm', message:'담당자 번호를 입력해 주세요.'})
		}
		else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.address))){
			setDataModal({menu:'confirm', message:'주소를 선택해 주세요.'})
		}
		else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>( (v.businessNumber.length !== 12) && v.businessNumber)))){
			setDataModal({menu:'confirm', message:'사업자 번호 형식이 맞지 않습니다.'})
		}
		else{
			try {
				dataAccessLoading()
				const response = await client.post( '/customer/register' ,
																						filterCheckedRow( rowList , checkedList , 'id' ) ,
																						{headers: {Authorization}} )
				const data = response.data
				if( data.status !== 200 ){
					loadingRemove()
					setDataModal( {menu: 'confirm' , message: data.message} )
				} else{
					setTimeout(() => {
						setRowList([])
						setCheckedList([])
						updateFunction()
						setDataModal( {menu: 'success' , message: '등록 되었습니다.'} )
						loadingRemove()
					} , 500)
				}
			} catch ( e: any ) {
				console.log( e )
				setDataModal( {menu: 'error' , message: e.message} )
				loadingRemove()
			}
		}
	}
	//삭제
	const customerDeleteRes = async () => {
		try {
			setDataModal(initModal)
			dataAccessLoading()
			const response = await client.post('/customer/delete',[
				...checkedList
			],{headers:{Authorization}})
			const data = response.data
			const customerModelArray = data.data?.customerModel?.map((v:any)=>({code:v, case:'등록된 모델이 존재합니다.'}))

			if(data.status === 200){
				setTimeout(()=>{
					setPage(1)
					setTotalPage(1)
					removeCheckedFunction(setCustomerList, customerList, setCheckedList, checkedList, 'customerCode')
					scrollToStart()
					if(page === 1){
						if(pushInput === ''){
							customerListRes().then()
						}else{
							customerSearchListRes().then()
						}
					}
					setDataModal({menu:'success',message:'거래처가 삭제 되었습니다.'})
					loadingRemove()
				},500)
			}
			else if(data.status === 601){
				setTimeout(()=>{
					setPage(1)
					setTotalPage(1)
					setIconSelect(-1)
					setCustomerList(customerList.filter((v:any)=> !data.data.success.includes(v['customerCode'])))
					setCheckedList(checkedList.filter((v:string)=> !data.data.success.includes(v)))
					setDeleteCaseArray(customerModelArray)
					setDataModal( {menu: 'deleteCase'})
					scrollToStart()
					if(page === 1){
						if(pushInput === ''){
							customerListRes().then()
						}else{
							customerSearchListRes().then()
						}
					}
					loadingRemove()
				},500)
			}
			else{
				setDataModal({menu:'error',message:data.message})
				loadingRemove()
			}

		}catch (e:any) {
			if(e.response.status === 500){
				setDataModal({menu:'confirm',message:'사용중인 거래처 정보가 존재합니다.'})
			}
			console.log(e)
			loadingRemove()
		}
	}

	//수정
	const customerUpdateRes = async () => {
		if(!customerList[iconSelect].customerName.trim()){
			setDataModal({menu:'confirm', message:'거래처명을 입력해 주세요.'})
		}
		else if(!customerList[iconSelect].president.trim()){
			setDataModal({menu:'confirm', message:'대표자를 입력해 주세요.'})
		}
		else if(!customerList[iconSelect].managerName.trim()){
			setDataModal({menu:'confirm', message:'담당자를 입력해 주세요.'})
		}
		else if(!customerList[iconSelect].managerNumber){
			setDataModal({menu:'confirm', message:'담당자를 번호를 입력해 주세요.'})
		}
		else if(!customerList[iconSelect].address){
			setDataModal({menu:'confirm', message:'주소를 선택해 주세요.'})
		}
		else if(customerList[iconSelect].businessNumber && customerList[iconSelect].businessNumber.length !== 12){
			setDataModal({menu:'confirm', message:'사업자 번호 형식이 맞지 않습니다.'})
		}
		else{
			try {
				setDataModal(initModal)
				dataAccessLoading()
				const response = await client.post('/customer/update', {
					companyCode: selectedCompany.companyCode,
					...customerList[iconSelect]

				},{headers:{Authorization}})
				const data = response.data
				if(data.status !== 200){
					setDataModal({menu:'confirm',message: data.message})
					loadingRemove()
				}else{
					setTimeout(()=>{
						updateFunction()
						setIconSelect(-1)
						setCheckedList([])
						setDataModal({menu:'success',message:'수정 되었습니다.'})
						loadingRemove()
					},500)
				}
			}catch (e:any){
				console.log(e)
				setDataModal({menu:'error',message:e.message})
				loadingRemove()
			}
		}
	}

	const AddressStyle:React.CSSProperties|undefined ={
		display:'block',
		width:500,
		height:600,
		position:'absolute',
		boxShadow:'0 1px 7px #E6E9F4',
		borderRadius:'8px',
		backgroundColor:'#fff',
		padding: "7px",
		zIndex: 100,
	}

	const addressHandle = {
		// clickBtn : () => {
		// 	setDataModal({menu:'post'});
		// },
		selectAddress: (data: any) => {
			if(iconSelect === -1){
				rowList[selectRowIndex].address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
				rowList[selectRowIndex].detailAddress = ''
			}else{
				customerList[iconSelect].address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
				customerList[iconSelect].detailAddress = ''
			}
			setDataModal(initModal);
		},
	}

	React.useEffect(()=>{
		if(selectedCompany.companyCode !== undefined){
			if(pushInput === ''){
				customerListRes().then()
			}else{
				customerSearchListRes().then()
			}
		}
	},[pushInput, page, selectedCompany.companyCode,errorState.status])

	// 헤더에서 업체가 변경되었을때, useEffect로 아래의 상태변화가 되도록?
	const onDataResetFunc =() =>{
		setRowList([]);
		setCheckedList([]);
		setCustomerList([])
		setPage(1);
		setIconSelect(-1)
		setPushInput('')
		setSearchInput('')
		setPageBlock(0)
		setTotalPage(1)
		setErrorState(initError)
	}

	React.useEffect(()=>{
		onDataResetFunc()
	},[selectedCompany.companyCode])
	// const navigate = useNavigate(); //변수 할당 시켜서 사용
	// const onClickBtn = () => {
	// 	navigate(-1); // 바로 이전 페이지로 이동, '/main' 등 직접 지정도 당연히 가능
	// };

	const cancelFuc = () =>{
		setHoverIcon(modify);
		setIconSelect(-1);
		setCheckedList([])
		setDataModal(initModal)
		if(searchInput === ''){
			setCustomerList(customerList.map((v:any)=>{return v.customerCode === selectCode? {...v, ...selectAccountData} : v}))
		}else{
			customerSearchListRes().then()
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (event:BeforeUnloadEvent) => {
			if (rowList.length > 0 || iconSelect !== -1) {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [rowList.length,iconSelect]);

	// const [isScrolling, setIsScrolling] = useState<boolean>(true);
	//
	// const handleScroll = () => {
	// 	//확인된 최대값 숫자 넣기
	// 	if(scrollRef?.current?.scrollLeft === 424){
	// 		setIsScrolling(false)
	// 	}else{
	// 		setIsScrolling(true)
	// 	}
	// };

	useEffect(()=>{
		if(!window.localStorage.getItem('auth')!?.includes('admin')){
			menuVisitLogRegister(window.localStorage.getItem('companyID'),'거래처 기본정보').then()
		}
	},[])

	return (
		<SectionFrame>
			{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

			{dataModal.menu === 'confirm' &&
				<ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}

			{dataModal.menu === 'business' &&
				<SingleListImageUploadModal viewType={dataModal.view}
																		clickEvent={(v)=>{
																			rowList.length !== 0?
																				Object.assign(rowList[dataModal.index],{businessRegistration: v.key, businessRegistrationUrl: v.url})
																				:
																				Object.assign(customerList[dataModal.index] , {businessRegistration: v.key, businessRegistrationUrl: v.url});
																		}}
																		cancelEvent={()=>setDataModal(initModal)} authValid={authValid()}
																		receiveKey={(rowList.length !== 0) ? rowList[dataModal.index].businessRegistration : customerList[dataModal.index].businessRegistration}
																		receiveUrl={(rowList.length !== 0) ? rowList[dataModal.index].businessRegistrationUrl : customerList[dataModal.index].businessRegistrationUrl}
				/>}

			{dataModal.menu === 'bankBook' &&
				<SingleListImageUploadModal viewType={dataModal.view}
																		clickEvent={(v)=>{
																			rowList.length !== 0? Object.assign(rowList[dataModal.index],{bankBook: v.key, bankBookUrl: v.url})
																				:
																				Object.assign(customerList[dataModal.index] , {bankBook: v.key, bankBookUrl: v.url});
																		}}
																		cancelEvent={()=>setDataModal(initModal)} authValid={authValid()}
																		receiveKey={(rowList.length !== 0) ? rowList[dataModal.index].bankBook : customerList[dataModal.index].bankBook}
																		receiveUrl={(rowList.length !== 0) ? rowList[dataModal.index].bankBookUrl : customerList[dataModal.index].bankBookUrl}
				/>}

			{dataModal.menu === 'post' &&
				<ModalBase clickEvent={() => setDataModal( initModal )}
									 modalContent={<DaumPostcode onComplete={addressHandle.selectAddress}
																							 style={AddressStyle}
																							 autoClose={false}/>}/>}

			{dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
																													noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
																													cancelEvent={()=>setDataModal(initModal)}
																													clickEvent={()=>{customerDeleteRes().then(); setDataModal(initModal)}}/>}

			{dataModal.menu ==='register'&&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={'* 선택되지 않은 리스트는 저장 되지 않습니다.'}
														cancelEvent={()=>setDataModal(initModal)}
														clickEvent={customerRegisterRes}/>}

			{dataModal.menu === 'modify'&&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
														cancelEvent={()=>setDataModal(initModal)}
														clickEvent={customerUpdateRes}/>}

			{dataModal.menu ==='back'&&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={'* "예" 선택시 저장없이 이동합니다.'}
														viewType={true}
														cancelEvent={()=>setDataModal(initModal)}
														clickEvent={()=>{
															if( rowList.length !== 0 ){
																removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
																setSelectAccountData({})
																setDataModal(initModal)
															} else{
																cancelFuc()
															}
														}}/>}

			{dataModal.menu === 'deleteCase' &&
				<DeleteCaseModal targetName={'거래처명'} caseArray={deleteCaseArray} closeEvent={()=>setDataModal(initModal)}/>}

			{dataModal.menu === 'excel' &&
				<ExcelModal closeEvent={()=>{setDataModal(initModal)}}
										excelContent={
											<React.Fragment>
												<ExcelApiButton category={'customer'} type={'download'} companyData={selectedCompany} disabled={customerList.length === 0} />
												<ExcelApiButton category={'customer'} disabled={buttonAuthValid('create','4')} type={'format'} companyData={selectedCompany} />
												<ExcelApiButton category={'customer'} disabled={buttonAuthValid('create','4')} type={'upload'} companyData={selectedCompany}/>
											</React.Fragment>
										}
				/>
			}
			<div style={{display:'flex', justifyContent:'space-between'}}>
				<div style={{display:'flex'}}>
					<MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 기본정보'} unitCase={'MenuTitle'}/>
				</div>
				{rowList?.length !== 0 &&
				<MainButtons name={'목록'} width={'56px'}
										 clickEvent={() => {
					window.location.href = `${authValid() ? '/admin/fas/account' : '/fas/account'}`
				}}/>}
			</div>

			{/*거래처 정보관리 */}
			<ContentFrame height={'680px'}
										justify={'space-between'}
										padding={'30px 30px 20px'}>
				<div>
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						<MenuTitle title={'거래처 기본정보'} unitCase={'MenuCompTitle'}/>
						<div style={{display: 'flex'}}>
							<SearchBar clickEvent={()=> {setPushInput(searchInput); setPage(1); setCheckedList([])}} value={searchInput}
												 clearButton={()=>{customerListRes().then(); setPage(1)}} keyPressDisable={true}
												 keyFunction={(e)=>(setSearchInput(e.target.value.trimStart()))} clearButtonAccess={searchInput !== ''}
												 disabled={(customerList.length === 0 && pushInput === '') || rowList.length !== 0 || iconSelect !== -1}/>
							<MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>

							{/*{selectedCompany.companyCode !== undefined &&*/}
							{/*	<MainButtons name={'행추가'} width={'80px'} clickEvent={addListRow}*/}
							{/*							 disabled={buttonAuthValid('create','4') || iconSelect !== - 1 || pushInput !== '' || checkedList[0]?.length > 1 || errorState.status !== 200}/>}*/}

							{(checkedList?.length !== 0) &&
								<MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
														disabled={
															buttonAuthValid( 'rowDelete' , '4' ) ? rowList.length === 0
																:
																buttonAuthValid( 'delete' , '4' ) || checkedList.length === 0}
														marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
														clickEvent={onDeleteFun}/>}

							{/*{rowList?.length !== 0 &&*/}
							{/*	<MainButtons name={'저장'} width={'80px'} buttonType={'cta'}*/}
							{/*							 disabled={buttonAuthValid('create','4') || rowList.length === 0 || checkedList.length === 0}*/}
							{/*							 clickEvent={() => {*/}
							{/*								 if( checkedList.length === rowList.length ){*/}
							{/*									 customerRegisterRes().then()*/}
							{/*								 } else{*/}
							{/*									 setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'} )*/}
							{/*								 }}}/>*/}
							{/*}*/}
							{(rowList?.length === 0 && iconSelect === -1 && checkedList?.length === 0) &&
								<MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
														marginDis={true}
														disabled={buttonAuthValid('create' ,'4') || (!selectedCompany.companyCode)}
														clickEvent={() => window.location.href = authValid() ? '/admin/fas/account/register' : '/fas/account/register'}/>}

						</div>
					</div>
					{/*{iconSelect === -1 &&*/}
					{/*	<ShowMore showMore={isScrolling} width={70} height={authValid?335:530} right={40} top={78} horizonType={true}/>}*/}
					{/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
					{/*								next={()=>setPage((prev)=> prev+1)}*/}
					{/*								hasMore={totalPage > page} dataLength={dataValid(customerList?.length, 0)}*/}
					{/*								loader={<div/>}>*/}
					<GridTableComponent id={'grid-scroll'} ref={scrollRef}
															titleCheckDisabled={iconSelect !== -1}
															scrollWidth={1910}
															scrollY_Hide={rowList?.length < 10}
															height={538}
						// height={listHeightValid(authValid,{total: totalPage, page: page})}
															tempCol={'40px repeat(7, 150px) 240px 160px repeat(3, 140px)'}>

						<div className={'grid-title'}>
							<label className={'checkbox'}>
								<input type="checkbox"
											 style={{pointerEvents:`${iconSelect === -1 ? 'auto' : 'none'}`,opacity:`${iconSelect === -1 ? 1 : 0.5}`}}
											 onChange={(e)=>allPersistCheckedFunction(e.target.checked,{dataList: checkboxValid().list, checkedList: checkedList, id: checkboxValid().id, setState: setCheckedList})}
											 checked={allCheckedState({dataList: checkboxValid().list, checkedList: checkedList, id: checkboxValid().id})}/>
							</label>
							{customerTitle.map((v) => (
								<TableValue title={v} key={v} viewType={iconSelect !== -1 || rowList.length !== 0} requiredValue={needValue}/>))}
						</div>

						{rowList.length === 0 && customerList.length === 0 ? pushInput === ''? errorState.status !== 200 ?
									listResultMessage(authValid(), dataValid(errorState.notice, t('* 업체를 선택해 주세요.')),
																		{width:1210, admin: 480, user:480})
									:
									listResultMessage(authValid(), dataValid(errorState.notice, ''),
																		{width:1210, admin: 480, user:480},() => window.location.href = authValid() ? '/admin/fas/account/register' : '/fas/account/register', buttonAuthValid('create', '4'),'등록')
								:
								listResultMessage(authValid(), '*검색 결과가 없습니다.',{width:1210, admin: 480, user:480})
							:


							rowList.map((value, index)=>(
								<div className={'grid-row-list'}>
									<label className={'checkbox'}>
										<ListInnerInput type={'check'} name={'id'}
																		onChange={(e: any)=>singleCheckedFunction(e.target.checked, value.id, setCheckedList, checkedList)}
																		checked={checkedList.includes(value.id)}/>
									</label>
									<ListInnerInput type={'text'} width={'120px'} name={'customerName'} placeholder={'거래처명'} inputData={value.customerName}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}/>
									<ListInnerInput type={'text'} width={'120px'} name={'division'} placeholder={'거래처구분'} inputData={value.division}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}/>
									<ListInnerInput type={'text'} width={'120px'} name={'president'} placeholder={'대표자명'} inputData={value.president}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}/>
									<ListInnerInput type={'text'} width={'120px'} name={'managerName'} placeholder={'담당자명'} inputData={value.managerName}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}/>
									<ListInnerInput type={'phone'} width={'120px'} name={'phoneNumber'} placeholder={'전화번호'} inputData={value.phoneNumber}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList, true)}/>
									<ListInnerInput type={'phone'} width={'120px'} name={'managerNumber'} placeholder={'휴대폰번호'} inputData={value.managerNumber}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList, true)}/>
									<ListInnerInput type={'phone'} width={'120px'} name={'fax'} placeholder={'FAX 번호'} inputData={value.fax}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList, true)}/>
									<ListInnerInput type={'address'} width={'120px'} name={'address'} placeholder={'-'}
																	inputData={value.address}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}
																	clickButton={()=> {
																		setSelectRowIndex(index)
																		setDataModal({menu: 'post'})
																	}}
																	addressClick={()=>setDataModal({menu:'post'})}/>

									<ListInnerInput type={'text'} width={'120px'} name={'detailAddress'} placeholder={'상세주소'} inputData={value.detailAddress}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList)}/>
									<ListInnerInput type={'businessNumber'} width={'120px'} name={'businessNumber'} placeholder={'사업자번호'} inputData={value.businessNumber}
																	onChange={(e) => onChangeArray(index, e,rowList,setRowList, true)}/>

									<MainButtons name={`사업자 등록증 ${!value.businessRegistration? '등록' : '변경'}`} width={'120px'} buttonType={!value.businessRegistration? 'cta' : 'popupCta'}
															 clickEvent={()=>setDataModal({menu:'business', index: index, view: false})} margin={'0'}/>
									<MainButtons name={`통장 사본 ${!value.bankBook? '등록' : '변경'}`} width={'120px'} buttonType={!value.bankBook? 'cta' : 'popupCta'}
															 clickEvent={()=>setDataModal({menu:'bankBook', index: index, view: false})} margin={'0'}/>
								</div>
							))}

						{rowList.length === 0 &&
							customerList.map((value:any, index:number)=>(
								<React.Fragment>
									{
										<GridTableSelect selected={value.customerCode === selectCode}
																		 disabled={rowList.length !== 0 || iconSelect !== -1}
										                 checkboxNumber={( ( index + 1 ) + page * 10 ) - 10}
																		 onDoubleClick={() => {
																			 window.location.href = `${authValid() ? `/admin/fas/account/${value?.customerCode}` : `/fas/account/${value?.customerCode}`}`
																		 }}
																		 className={'grid-list'} onClick={()=>setSelectCode(value.customerCode)}
																		 onMouseOut={() => setFocusList(-1)} onMouseOver={() => setFocusList(index)}>
											<label className={'selectCheckbox'}>
												<ListInnerInput type={'check'} name={'customerCode'}
																				onChange={(e: any)=>singleCheckedFunction(e.target.checked, value.customerCode, setCheckedList, checkedList)}
																				checked={checkedList.includes(value.customerCode)}/>
											</label>
											{autoTextValueValidation({value:value.customerName})}
											{validationValue(value.division)}
											{autoTextValueValidation({value:value.president})}
											{autoTextValueValidation({value:value.managerName})}
											{validationValue(value.phoneNumber)}
											{validationValue(value.managerNumber)}
											{validationValue(value.fax)}
											{autoTextValueValidation({value:value.address})}
											{autoTextValueValidation({value:value.detailAddress})}
											<ListInnerInput type={'noneInputText'} clickButton={()=>setSelectRowIndex(index)}
																			inputData={dataValid(value.businessNumber)}/>


											{!value.businessRegistration?
												<div>{'-'}</div>
												:
												<ViewImageText disabled={rowList.length !== 0}
																			 onClick={(e)=>{e.stopPropagation(); setSelectRowIndex(index);
																				 setDataModal({menu:'business', index:index,  view: true})}}>{'사업자 등록증 보기'}</ViewImageText>
											}
											{!value.bankBook?
												<div>{'-'}</div>
												:
												<ViewImageText disabled={rowList.length !== 0}
																			 onClick={(e)=>{e.stopPropagation(); setSelectRowIndex(index);
																				 setDataModal({menu:'bankBook', index:index,  view: true})}}>{'통장 사본 보기'}</ViewImageText>
											}
										</GridTableSelect>
									}
								</React.Fragment>
							))}
					</GridTableComponent>
					{/*</InfiniteScroll>*/}
				</div>
				<div style={{display:'flex', justifyContent:'space-between'}}>
					<div>
						{rowList.length === 0 &&
							<PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
															blockNum={pageBlock} setBlockNum={setPageBlock}
															counts={totalPage} disabled={iconSelect !== -1}/>}
					</div>
					<HorizontalMoveScrollButton
						leftClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
						rightClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
				</div>
			</ContentFrame>
		</SectionFrame>
	);
};

export default AccountManagement;

const ViewImageText = styled.div<{disabled: boolean}>`
  font-size: 12px;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #7E84A3;
  pointer-events: ${props => props.disabled? 'none' : 'inherit'};
  &:hover{
    color: #0058FF;
  }
`


