import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import EventManagement from "../../../containers/admin/fas/notice/eventnotice/EventManagement";
import EventDetail from "../../../containers/admin/fas/notice/eventnotice/EventDetail";
import EventRegistration from "../../../containers/admin/fas/notice/eventnotice/EventRegistration";
import EventModify from "../../../containers/admin/fas/notice/eventnotice/EventModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const AdminFasEventNotice = () => {
    const location = useLocation();
    const locationId = location.pathname.length
    const register = location.pathname.includes('register')
    const modify = location.pathname.includes('modify')

    return (
      <PageContainer>
          <IntegratedSideMenu/>
          {locationId === 15 ?
            <SectionContainer contentContainer={<EventManagement/>}/>
            :
            register?
              <SectionContainer contentContainer={<EventRegistration/>}/>
              :
              modify?
                <SectionContainer contentContainer={<EventModify/>}/>
                :
                <SectionContainer contentContainer={<EventDetail/>}/>
          }

      </PageContainer>
    );
};

export default AdminFasEventNotice;
