import React from 'react';
import styled from "styled-components";
import {ButtonType} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";

const ControlButton:React.FunctionComponent<ButtonType> = ({clickCus,name, clickEvent, margin, padding,opacity,width,height,type}) => {
	const { t }:any = useTranslation()
	switch (type) {
		case 'blue':
			return (
					<SecondaryButton style={{width:`${width}`,margin: `${margin}`, padding: `${padding}`, opacity: `${opacity ? 0 : 1}`,


						height:`${height === undefined ? '32px' : height}`}}
									 onClick={clickEvent}>
						{t(name)}
					</SecondaryButton>
			);

		 default:
			return (
				<BaseButton style={{margin: `${margin}`,width:`${width}`, padding: `${padding}`, opacity: `${opacity ? 0 : 1}`}}
							onClick={clickEvent} clickCus={clickCus}>
					{t(name)}
				</BaseButton>

			);
	}
};
export default ControlButton;

const BaseButton = styled.div<{clickCus?:boolean}>`
	 
	font-size: 14px;
	font-weight: 700;
	border-radius: 4px;
 	border: 1px solid #D7DBEC;
	//color: #5A607F;
	white-space: nowrap;
	 
	//box-sizing: border-box;
	padding: 9px 12px;
	line-height: 14px;
	text-align: center;
	align-self: center;
	justify-content: center;
 	
	cursor: pointer;
	transition: all 0.3s;
	user-select: none;
	display: flex;
	//background-color: #fff;
	background-color: ${props => props.clickCus ?  '#5A607F' : '#fff'};
	color: ${props => props.clickCus ?  '#fff' : '#5A607F'};
	
	&:hover{
		background-color: #5A607F;
		color: #fff;
	}
`

const SecondaryButton = styled.div`
	
		 
	    height: 32px;
		border: 1px solid #0058FF;
		background-color: #fff;
		border-radius: 4px;
		align-items: center;
		text-align: center;
	    display: flex;
	    justify-content: center;
		font-size: 14px;
		color: #0058FF;
		font-weight: 600;
	   	cursor: pointer;
	 
   
	&:hover{
		background-color: #0058FF;
		color: #fff;
	}
 

`;
