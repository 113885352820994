import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import WikiDetail from "../../../containers/admin/fas/notice/wiki/WikiDetail";
import WikiList from "../../../containers/admin/fas/notice/wiki/WikiList";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import WikiRegister from "../../../containers/admin/fas/notice/wiki/WikiRegister";

const AdminFasWiki = () => {
    const location = useLocation();
  const locationId = location.pathname.length
  const register = location.pathname.includes('register')
  const modify = location.pathname.includes('modify')

  return (
      <PageContainer>
          <IntegratedSideMenu/>
          {
            register ?
              <SectionContainer contentContainer={<WikiRegister/>}/>
            :
              modify ?
                <SectionContainer contentContainer={<WikiDetail/>}/>
            :
                <SectionContainer contentContainer={<WikiList/>}/>
          }
      </PageContainer>
    );
};

export default AdminFasWiki;


