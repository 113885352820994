import React , {useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import SearchBar from "../../../../../components/input/SearchBar";
import {
  ContentFrame ,
  NoData ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import PaginationList from "../../../../../components/pagination/PaginationList";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {buttonAuthValid , initError , listLoadingCircle , loadingRemove} from "../../../../../function/function";
import {useRecoilState} from "recoil";
import {persistBlockPage , persistPage , selectedCode} from "../../../../../common/StateManage";
import {Error_Type} from "../../../../../types/Fundamental";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const EventManagement = () => {
  const [infoPage , setInfoPage] = useRecoilState(persistPage)
  const [pageBlock , setPageBlock] = useRecoilState(persistBlockPage)
  const [totalPage , setTotalPage] = useState<number>(1)
  const [infoList , setInfoList] = useState<any>([]);
  const [selectCode , setSelectCode] = useRecoilState(selectedCode)
  const [searchInput , setSearchInput] = React.useState<string>('')
  const [pushInput , setPushInput] = React.useState<string>('')
  const [errorState , setErrorState] = React.useState<Error_Type>(initError)
  const {t}: any = useTranslation()
  // console.log(totalList.reverse().slice())
  const infoListRes = async () => {
    listLoadingCircle()
    try {
      const response = await client.get(`/infoNotice/list/${infoPage}/${10}` , {
        headers: {Authorization}
      })
      const data = response.data.data;
      const valid = response.data
      if(valid.status !== 200){
        setInfoList([])
        setErrorState({notice: valid.message , status: valid.status})
      } else{
        setErrorState({notice: ' * 등록된 이벤트&공지가 없습니다.' , status: valid.status})
        setPushInput('')
        setSearchInput('')
        setInfoList(data.row)
        setTotalPage(data.total_page)
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const infoListSearchRes = async () => {
    listLoadingCircle()
    try {
      const response = await client.get(`/infoNotice/search/${infoPage}/${10}/${pushInput}` , {
        headers: {Authorization}
      })
      setTotalPage(response.data.data.total_page)
      setInfoList(response.data.data.row)
      loadingRemove(300)
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  React.useEffect(() => {
                    if(pushInput === ''){
                      infoListRes().then()
                    } else{
                      infoListSearchRes().then()
                    }
                  }
    , [infoPage , pushInput])
  const dateCut = (date: string) => {
    return infoList.modifiedDate = date.substring(0 , 10)
  }
  return (
    <SectionFrame>
      <React.Fragment>
        <MenuTitle title={'게시판 관리 > 이벤트&공지 관리'} unitCase={'MenuTitle'}/>
        <ContentFrame justify={'space-between'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'이벤트&공지'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex' , alignItems: 'center'}}>
                <SearchBar clickEvent={() => {
                  setPushInput(searchInput);
                  setInfoPage(1)
                }} value={searchInput}
                           clearButtonAccess={searchInput !== ''} clearButton={infoListRes}
                           keyFunction={(e) => ( setSearchInput(e.target.value.trimStart()) )}
                           disabled={errorState.status !== 200}/>
                <MainButtons name={'등록'} buttonType={'cta'} marginDis={true} disabled={buttonAuthValid('create' , '20')}
                             width={'80px'} clickEvent={() => {window.location.href = `/admin/fas/info/register`}}/>
              </div>
            </div>
            <GridTableComponent tempCol={'60px 560px 1fr'} scrollWidth={1210}
                                scrollX_Hide={true} scrollY_Hide={true}>
              <div className={'grid-title-unchecked'}>
                {['No.' , '제목' , '등록일'].map((v) => (
                  <div key={v}>{t(v)}</div>
                ))}
              </div>
              {infoList.length === 0 ? pushInput === '' ? errorState.status !== 200 ?
                    <NoData>{errorState.notice}</NoData>
                    :
                    <NoData>{errorState.notice}</NoData>
                  :
                  <NoData>{' * 검색 결과가 없습니다.'}</NoData>
                :
                infoList.map((v: any) => (
                  <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.infoNum}
                                   selected={v.infoNum === selectCode}
                                   onClick={() => setSelectCode(v.infoNum)}
                                   onDoubleClick={() => {window.location.href = `/admin/fas/info/${v.infoNum}`}}>
                    {validationValue(v.no)}
                    {validationValue(v.title)}
                    {validationValue(dateCut(v.registrationDate))}
                  </GridTableSelect>
                ))
              }
            </GridTableComponent>
          </div>

          {infoList.length !== 0 &&
            <PaginationList limit={10} pageLimit={10} page={infoPage} setPage={setInfoPage}
                            blockNum={pageBlock} setBlockNum={setPageBlock}
                            counts={totalPage}/>}
        </ContentFrame>
      </React.Fragment>

    </SectionFrame>
  );
};
export default EventManagement;
