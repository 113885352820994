import React from 'react';
import {CircleIconProps} from "../../types/Fundamental";

const SolutionIcon:React.FunctionComponent<CircleIconProps> = ({iconType, title, iconColor, auth,circleTitle}) => {

	const createCircle = (type: number, title: string, circleTitle: string | undefined, color?: boolean | undefined, auth?: string,) => {
		switch (type) {
			case 0 : return (
				<div style={{display:'flex'}}>
					<div style={{width:'24px', height:'24px', borderRadius:'50%', textAlign:'center',
						backgroundColor:
						`${ 	title === 'F'? '#CC5642' :
								title === 'P'? '#54BEEA' :
								title === 'R'? '#4AA261' :
								title === 'C'? '#E79F3C' :
								title === 'A'? '#6963B0' : 'transparent'}
						`
						, fontSize:'14px',color:'#fff', lineHeight:'24px',
						fontWeight:700}}>
						{title}
					</div>
					<div style={{marginLeft: '8px', fontSize:'14px', fontWeight:400, color:'#101010', alignSelf:'center' }}>
						{   title === 'F'? 'F-EMS(Factory-Energy Management System)' :
							title === 'R'? 'R-BAS(Robot-Before After Service)' :
							title === 'C'? 'C-BAS(CNC-Before After Service)' :
							title === 'P'? 'P-BAS(Press-Before After Service)' :
							title === 'A'? 'A-BAS(Automation-Before After Service)' : ''}
					</div>
				</div>
			)

			case 1 : return (
				<div style={{width:'42px', height:'42px', borderRadius:'50%', textAlign:'center',
					backgroundColor:
						`${ 		color? title === 'F-EMS'? '#CC5642' :
										   title === 'P-BAS'? '#54BEEA' :
										   title === 'R-BAS'? '#4AA261' :
										   title === 'C-BAS'? '#E79F3C' :
										   title === 'V-MS'? '#F9E66E' : 
										   title === 'A-BAS'? '#6963B0' : 
										   title === 'P-MS' ?  '#203F97' : 'transparent' : '#D5D7E3'}
						`
					, fontSize:'30px', color:'#fff', lineHeight:'46px',
					fontWeight:700}}>
					{circleTitle}
				</div>
			)
			case 2 : return (
				<div style={{display:'flex',flexDirection:'column',alignItems:'center',gap:'8px',userSelect:'none'}}>
					<div style={{width:'24px', height:'24px', borderRadius:'50%', textAlign:'center',
						backgroundColor:
							`${ 	title === 'F'? '#CC5642' :
									title === 'P'? '#54BEEA' :
									title === 'R'? '#4AA261' :
									title === 'C'? '#E79F3C' :
									title === 'A'? '#6963B0' : 'transparent'}`
						,fontSize:'14px',color:'#fff', lineHeight:'24px'}}>
						{title}
					</div>
					<div style={{color:'#101010',fontSize:'10px',transform:'scale(80%)',opacity:0.5}}>
						{   title === 'F'? 'F-EMS' :
							title === 'R'? 'R-BAS' :
							title === 'C'? 'C-BAS' :
							title === 'P'? 'P-BAS' :
							title === 'A'? 'A-BAS' : ''}
					</div>
				</div>
			)
		}
	}


	return (
		<React.Fragment>
			{createCircle(iconType, title,circleTitle, iconColor)}
		</React.Fragment>
	);
};

export default SolutionIcon;

