import React from 'react';
import {linearProgressClasses, styled} from "@mui/material";
import {CircularProgress, LinearProgress, Box} from "@mui/material";
import ModalBase from "./ModalBase";
import {useTranslation} from "react-i18next";

interface Props {
	loadingText?: string
	backCss?: boolean
}

const LoadingComponent:React.FunctionComponent<Props> = ({loadingText, backCss}) => {
	const { t }:any = useTranslation();
	return (
			<ModalBase transparent={backCss ?? true} modalContent={
				<React.Fragment>
					<div style={{display:'flex', flexDirection:'column', width:'290px',
						boxShadow:'0 1px 4px gray',backgroundColor:'rgb(255,255,255,1)',
						borderRadius:'6px',alignItems:'center', boxSizing:'border-box', padding:'25px', gap:'40px'}}>
						<div style={{fontSize:'18px', fontWeight:700, lineHeight:'30px'}}>
							{loadingText ?? t('잠시만 기다려 주세요...')}
						</div>
						<Box sx={{width:'240px'}}>
							{/*<CircularProgress sx={{color:'#CFFA61'}} thickness={5} size={90} disableShrink={false}/>*/}
							<BorderLinearProgress/>
						</Box>
					</div>
				</React.Fragment>
			}/>
	);
};

export default LoadingComponent;

const BorderLinearProgress = styled(LinearProgress)(({theme})=>({
	height: 15,
	borderRadius: 6,


}))