import client from "./common/Config";
import {useTranslation} from "react-i18next";

export const passwordReg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9?@$!#%^*()&]{8,20}$/;
//영문 숫자 4~20 자 만 가능
// export const passwordReg = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,20}$/;
export const idReg = /^(?=.*[a-zA-Z])(?=.*\d|.*[a-zA-Z])[a-zA-Z\d]{4,20}$/;
// export const emailReg= /^[_a-z0-9-]+(.[_a-z0-9-]+)*@(?:\w+\.)+\w+$/;\
export const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneReg = /^\d{2,4}-?\d{3,4}-?\d{4}$/;
export const specialCharExcludeReg = /[^\ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9()\-,_]/

export const PasswordCheck = ( partnerRegister: any , setPassword: Function , setPwCheckNormal: Function ) => {
  if( !partnerRegister.password || !partnerRegister.passwordCheck ){
    setPassword( {text: '비밀번호를 입력하시오.' , color: '#ff0000'} )
    setPwCheckNormal( false )
  } else{
    if( partnerRegister.password.length < 8 || partnerRegister.passwordCheck.length < 8 ){
      setPassword( {text: '비밀번호가 8자리 이상 이어야 합니다.' , color: '#ff0000'} )
      setPwCheckNormal( false )
    } else{
      if( !passwordReg.test( partnerRegister.password ) ){
        setPassword( {text: '영문,숫자를 혼용하여 입력바랍니다.' , color: '#ff0000'} )
        setPwCheckNormal( false )
      } else{
        if( partnerRegister.password === partnerRegister.passwordCheck ){
          setPassword( {text: '비밀번호가 일치합니다.' , color: '#0058FF'} );
          setPwCheckNormal( true )
        } else{
          setPassword( {text: '비밀번호가 일치하지 않습니다.' , color: '#ff0000'} )
          setPwCheckNormal( false )
        }
      }
    }
  }
}
export const IdDuplicate = ( partnerRegister: any , setIdCheck: Function , setIdCheckNormal: Function ) => {
  const duplicateIdCheck = async () => {
    try {
      const response = await client.post( '/duplicate' , {
        id: partnerRegister.id ,
      } )
      if( !partnerRegister.id ){
        setIdCheck( {text: '아이디를 입력하세요.' , color: '#FF0000'} );
        setIdCheckNormal( false )
      } else{
        if( partnerRegister.id.length < 4 ){
          setIdCheck( {text: '아이디를 4자리 이상 입력하시오.' , color: '#FF0000'} );
          setIdCheckNormal( false )
        } else{
          if( !idReg.test( partnerRegister.id ) ){
            setIdCheck( {text: '특수문자를 혼용할수 없습니다.' , color: '#FF0000'} );
            setIdCheckNormal( false )
          } else if( response.data.message === '사용할 수 없는 아이디입니다.' ){
            setIdCheck( {text: 'ID가 중복 되었습니다.' , color: '#FF0000'} );
            setIdCheckNormal( false )
          } else{
            setIdCheck( {text: '사용 가능한 ID입니다.' , color: '#0058FF'} );
            setIdCheckNormal( true )
          }
        }
      }
    } catch ( e ) {
      console.log( e )
    }
  }
  return duplicateIdCheck()
}
export const onIdCheckValid = ( value: string , setIdCheck: Function ) => {
  if( !idReg.test( value ) ){
    setIdCheck( {text: '*4~20자의 영문,숫자만 사용가능 합니다.' , color: '#ff0000'} )
  } else{
    setIdCheck( {text: '*ID 중복확인을 해주세요.' , color: '#ff0000'} )
  }
}
export const onPwCheckValid = ( value: string , setPassword: Function ) => {
  if( !passwordReg.test( value ) ){
    setPassword( {text: '*사용할 수 없는 비밀번호 입니다.' , color: '#ff0000'} )
  } else{
    setPassword( {text: '*사용가능한 비밀번호 입니다.' , color: '#0058FF'} )
  }
}
export const onEmailValid = ( value: string , setEmailCheck: Function ) => {
  if( !emailReg.test( value ) ){
    setEmailCheck( {text: '*이메일 주소를 다시 확인해 주세요.' , color: '#FF0000'} );
  } else{
    setEmailCheck( {text: '*올바른 형식의 이메일 입니다.' , color: '#0058FF'} )
  }
}

