import home from '../asset/image/ic_home.svg';
import softDocLogo from '../asset/image/ic_invoice.svg'
import aiIcon from '../asset/image/ai/module icon_AI_00.svg'
export const categoryMenu = [
  {
    title: 'FAS' , icon: home , subTitle: [
      {title: '메인화면' , link: '/admin/main' , menuNo: '0'} ,
      {
        title: '지사/협력사 관리' , subMenu: [
          {title: '지사/협력사 기본정보' , link: '/admin/fas/partners' , menuNo: '1'} ,
          {title: '지사/협력사 권한관리' , link: '/admin/fas/partners/auth' , menuNo: '91'} ,
          {title: '지사/협력사 유저관리' , link: '/admin/fas/partners/user' , menuNo: '92'} ,
        ] ,
      } ,
      {
        title: '업체/기본정보 관리' , subMenu: [
          {title: '업체 기본정보' , link: '/admin/fas/basic' , menuNo: '2'} ,
          {title: '업체 권한관리' , link: '/admin/fas/basic/auth' , menuNo: '93'} ,
          {title: '업체 유저관리' , link: '/admin/fas/basic/user' , menuNo: '94'} ,
          {title: '공장 기본정보' , link: '/admin/fas/factory' , menuNo: '3'} ,
          {title: '거래처 기본정보' , link: '/admin/fas/account' , menuNo: '4'} ,
          {title: '거래처 모델 관리' , link: '/admin/fas/model' , menuNo: '5'} ,
          {title: '기계 기본정보' , link: '/admin/fas/facility' , menuNo: '6'} ,
          {title: '주변장치 기본정보' , link: '/admin/fas/peripherals' , menuNo: '7'} ,
          {title: '품목 기본정보' , link: '/admin/fas/product' , menuNo: '8'} ,
          {title: '원자재 기본정보' , link: '/admin/fas/rawmaterial' , menuNo: '9'} ,
          {title: '부자재 기본정보' , link: '/admin/fas/submaterial' , menuNo: '10'} ,
          {title: '도면 관리' , link: '/admin/fas/drawing' , menuNo: '11'} ,
          {title: '공정 관리' , link: '/admin/fas/process' , menuNo: '12'} ,
          {title: '공정 종류 관리' , link: '/admin/fas/type_process' , menuNo: '13'} ,
          {title: '금형 기본정보' , link: '/admin/fas/mold' , menuNo: '14'} ,
          {title: '일시정지 유형 관리' , link: '/admin/fas/pause' , menuNo: '15'} ,
          {title: '불량 유형 관리' , link: '/admin/fas/defect' , menuNo: '16'} ,
          // {title:'업체별 알림', link:'/admin/fas/notice',menuNo:'17'},
          {title: '업체별 알림' , link: 'not' , menuNo: '17'} ,
          // {title:'바코드 관리', link:'/admin/fas/barcode',menuNo:'18'},
          {title: '바코드 관리' , link: 'not' , menuNo: '18'} ,
          // {title: '표준문서 관리' , link: 'not' , menuNo: '19'} ,
          {title: '표준문서 관리' , link: '/admin/fas/document' , menuNo: '19'} ,
        ]
      } ,
      {
        title: '게시판 관리' , subMenu: [
          {title: '이벤트 & 공지 관리' , link: '/admin/fas/info' , menuNo: '20'} ,
          {title: 'WIKI 관리' , link: '/admin/fas/wiki' , menuNo: '21'} ,
        ]
      } ,
      {title: '영업관리' , link: '/admin/fas/business' , menuNo: '22'} ,
      {title: '솔루션 요청관리' , link: '/admin/fas/solution' , menuNo: '23'} ,
    ]
  } ,
]
export const userCategory = [
  {
    title: 'FAS' , icon: softDocLogo , subTitle: [
      {title: '메인화면' , link: `/fas/${window.localStorage.getItem('companyID')}` , menuNo: '0'} ,
      {
        title: '기본정보 관리' , subMenu: [
          {title: '권한 관리' , link: '/fas/basic/auth' , menuNo: '93'} ,
          {title: '유저 관리' , link: '/fas/basic/user' , menuNo: '94'} ,
          {title: '공장 기본정보' , link: '/fas/factory' , menuNo: '3'} ,
          {title: '거래처 기본정보' , link: '/fas/account' , menuNo: '4'} ,
          {title: '거래처 모델 관리' , link: '/fas/model' , menuNo: '5'} ,
          {title: '기계 기본정보' , link: '/fas/facility' , menuNo: '6'} ,
          {title: '주변장치 기본정보' , link: '/fas/peripherals' , menuNo: '7'} ,
          {title: '품목 기본정보' , link: '/fas/product' , menuNo: '8'} ,
          {title: '원자재 기본정보' , link: '/fas/rawmaterial' , menuNo: '9'} ,
          {title: '부자재 기본정보' , link: '/fas/submaterial' , menuNo: '10'} ,
          {title: '공정 관리' , link: '/fas/process' , menuNo: '12'} ,
          {title: '공정 종류 관리' , link: '/fas/type_process' , menuNo: '13'} ,
          {title: '금형 기본정보' , link: '/fas/mold' , menuNo: '14'} ,
          {title: '일시정지 유형 관리' , link: '/fas/pause' , menuNo: '15'} ,
          {title: '불량 유형 관리' , link: '/fas/defect' , menuNo: '16'} ,
          {title: '도면 관리' , link: '/fas/drawing' , menuNo: '11'} ,
          // {title: '바코드 관리' , link: '/fas/barcode' , menuNo: '18'} ,
          {title: '바코드 관리' , link: 'not' , menuNo: '18'} ,
          {title: '표준문서 관리' , link: '/fas/document' , menuNo: '19'} ,
        ]
      } ,
      {title: '알림' , link: window.localStorage.getItem('companyCode') === "0RH32ER84Z"? '/fas/notice' : 'not' , menuNo: '1'} ,
      // {title: '알림' , link: 'not' , menuNo: '1'} ,
      {title: 'WIKI' , link: '/fas/wiki' , menuNo: '2'} ,
    ]
  } ,
]
export const affiliateMenu = [
  {
    title: 'FAS' , icon: softDocLogo , subTitle: [
      {title: '메인화면' , link: '/fas/main' , menuNo: '0'} ,
      {title: '기계 기본정보' , link: '/fas/facility' , menuNo: '6'} ,
      {title: '알림' , link: 'not' , menuNo: '1'} ,
      {title: 'WIKI' , link: '/fas/wiki' , menuNo: '2'} ,
    ]
  } ,
]
//read, create, update,delete
export const authAdminCategory = [
  {
    title: 'FAS' , icon: home , subTitle: [
      // {title:'FAS 메인화면',menuIndex:'0',read:'possible'},
      {
        title: '지사/협력사 관리' , subMenu: [
          {title: '지사/협력사 관리 기본정보' , menuIndex: '1' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '지사/협력사 관리 권한관리' , menuIndex: '91' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '지사/협력사 관리 유저관리' , menuIndex: '92' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '업체/기본정보 관리' , subMenu: [
          {title: '업체 기본정보' , menuIndex: '2' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          //여기 바꿔야함 menuIndex
          {title: '업체 권한관리' , menuIndex: '93' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '업체 유저관리' , menuIndex: '94' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '공장 기본정보' , menuIndex: '3' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '거래처 기본정보' , menuIndex: '4' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '거래처 모델 관리' , menuIndex: '5' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '기계 기본정보' , menuIndex: '6' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '주변장치 기본정보' , menuIndex: '7' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '품목 기본정보' , menuIndex: '8' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '원자재 기본정보' , menuIndex: '9' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '부자재 기본정보' , menuIndex: '10' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '도면 관리' , menuIndex: '11' , read: 'possible'} ,
          {title: '공정 관리' , menuIndex: '12' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '공정 종류 관리' , menuIndex: '13' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '금형 기본정보' , menuIndex: '14' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '일시정지 유형 관리' , menuIndex: '15' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '불량 유형 관리' , menuIndex: '16' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '업체별 알림' , menuIndex: '17' , read: 'possible'} ,
          {title: '바코드 관리' , menuIndex: '18' , read: 'possible'} ,
          {title: '표준문서 관리' , menuIndex: '19' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
        ]
      } ,
      {
        title: '게시판 관리' , subMenu: [
          {title: '이벤트 & 공지 관리' , menuIndex: '20' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: 'WIKI 관리' , menuIndex: '21' , read: 'possible' , create: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {title: '영업관리' , menuIndex: '22' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
      {title: '솔루션 요청관리' , menuIndex: '23' , read: 'possible' , create: 'impossible' , update: 'impossible'} ,
    ]
  } ,
  {
    title: 'P-BAS' , icon: softDocLogo , subTitle: [
      {title: 'P-BAS 메인화면' , menuIndex: '24' , read: 'possible'} ,
      {title: '업체별 요청관리' , menuIndex: '25' , read: 'possible' , create: 'impossible'} ,
      {title: '업체별 예지보전 알림' , menuIndex: '26' , read: 'possible' , create: 'impossible'} ,
      {title: '예지보전 관리이력' , menuIndex: '27' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
      {title: '설비별 예지보전 관리' , menuIndex: '28' , read: 'possible' , create: 'impossible'} ,
      {title: '예지보전 항목 관리' , menuIndex: '29' , read: 'possible' , create: 'impossible' , delete: 'impossible'} ,
      {title: '네트워크 설비관리' , menuIndex: '104' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'P-MS' , icon: softDocLogo , subTitle:
      [
        {title: '설비 모니터링 시스템' , menuIndex: '30' , read: 'possible' , update: 'impossible'} ,
        {title: '프레스 모니터링' , menuIndex: '31' , read: 'possible' , update: 'impossible'} ,
        {title: '컴프레셔 모니터링' ,  menuIndex: '107' , read: 'possible'} ,
        {title: '라인 모니터링' ,  menuIndex: '108' , read: 'possible'} ,
        {
          title: '금형 관리' ,
          subMenu: [
            {title: '금형 타수 관리' , menuIndex: '32' , read: 'possible'} ,
            {title: '금형 수리요청 등록' , menuIndex: '33' , read: 'possible' , create: 'impossible'} ,
            {title: '금형 수리요청 리스트' , menuIndex: '34' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
            {title: '금형 수리완료 리스트' , menuIndex: '35' , read: 'possible'} ,
            {title: '금형 수리완료 리스트(관리자용)' , menuIndex: '36' , read: 'possible' , update: 'impossible'} ,
            {title: '금형 문제 유형 등록' , menuIndex: '37' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          ]
        } ,
        {
          title: '프레스 관리' ,
          subMenu: [
            {title: '운전관리 모니터링' , menuIndex: '38' , read: 'possible'} ,
            {title: '로드톤 모니터링' , menuIndex: '39' , read: 'possible'} ,
            {title: '에러 보기' , menuIndex: '40' , read: 'possible'} ,
            {title: '파라미터 보기' , menuIndex: '41' , read: 'possible'} ,
            {title: '캠 보기' , menuIndex: '42' , read: 'possible'} ,
            {title: '클러치&브레이크' , menuIndex: '43' , read: 'possible'} ,
            {title: '설비 수리요청 등록' , menuIndex: '44' , read: 'possible' , create: 'impossible'} ,
            {title: '설비 수리요청 리스트' , menuIndex: '45' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
            {title: '설비 수리완료 리스트' , menuIndex: '46' , read: 'possible'} ,
            {title: '설비 수리완료 리스트(관리자용)' , menuIndex: '47' , read: 'possible' , update: 'impossible'} ,
            {title: '설비 문제 유형 등록' , menuIndex: '48' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          ]
        } ,
        {
          title: '프레스 데이터 통계 및 분석' ,
          subMenu: [
            {title: '생산량' , menuIndex: '49' , read: 'possible'} ,
            {title: '능력' , menuIndex: '50' , read: 'possible'} ,
            {title: '에러' , menuIndex: '51' , read: 'possible'} ,
            {title: '전력' , menuIndex: '52' , read: 'possible'} ,
            {title: '기계 비 가동시간' , menuIndex: '53' , read: 'possible'} ,
            {title: '작업시간' , menuIndex: '54' , read: 'possible'} ,
          ]
        }
      ]
  } ,
  {
    title: '산업안전 모니터링' , icon: softDocLogo , subTitle: [
      {title: '화재예방 모니터링' ,  menuIndex: '106' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'C-MS' , icon: softDocLogo , subTitle:
      [
        {title: 'CNC 모니터링' , menuIndex: '95' , read: 'possible',update:'impossible'} ,
        {
          title: 'CNC 데이터 통계 및 분석' ,
          subMenu: [
            {title:'생산량',menuIndex:'96', read: 'possible'},
            {title:'에러',menuIndex:'97', read: 'possible'},
            {title:'기계 비 가동시간',menuIndex:'98', read: 'possible'},
          ]
        } ,
      ]
  },
  {
    title: 'V-MS' , icon: softDocLogo , subTitle:
      [
        {title: '비전 모니터링' , menuIndex: '99' , read: 'possible'} ,
        {title: '시/검사 이력' , menuIndex: '100' , read: 'possible'} ,
        {title: '품목별 통계 및 분석' , menuIndex: '101' , read: 'possible'} ,
        {title: '파라미터 보기' , menuIndex: '102' , read: 'possible'} ,
        {title: '검사 파라미터 설정' , menuIndex: '103' , read: 'possible'} ,
      ]
  },
  {
    title: 'F-EMS' , icon: softDocLogo , subTitle: [
      {title: 'F-EMS 메인화면' , menuIndex: '55' , read: 'possible'} ,
      {title: '설비별 리포트' , menuIndex: '56' , read: 'possible'} ,
      {title: '전체설비 리포트' , menuIndex: '57' , read: 'possible'} ,
      {title: '프레스 에너지 모니터링' , menuIndex: '58' , read: 'possible'} ,
      {title: '에너지 알람 로그' , menuIndex: '59' , read: 'possible'} ,
      {title: '한전 사용량 보기' , menuIndex: '60' , read: 'possible'} ,
      {title: '에너지 수요예측' , menuIndex: '61' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'MES' , icon: softDocLogo ,
    subTitle: [
      {title: 'Home' , menuIndex: '62' , read: 'possible'} ,
      {
        title: '인사 관리' ,
        subMenu: [
          {title: '권한 관리' , menuIndex: '63' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '유저 관리' , menuIndex: '64' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '영업 관리' ,
        subMenu: [
          {title: '수주 관리' , menuIndex: '65' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '출하 관리' , menuIndex: '66' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '공정 관리' ,
        subMenu: [
          {title: '공정정보 관리' , menuIndex: '67' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '공정 세분화 관리' , menuIndex: '68' , read: 'possible' , create: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '원자재 관리' ,
        subMenu: [
          {title: '원자재 입고내역' , read: 'possible' ,menuIndex: '69'} ,
          {title: '원자재 불출내역' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible' ,menuIndex: '106'} ,
          {title: '원자재 재고현황' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible' ,menuIndex: '70'} ,

          {title: '원자재 입고 등록' , menuIndex: '69' , read: 'possible' , create: 'impossible'} ,
          {title: '원자재 재고 현황' , menuIndex: '70' , read: 'possible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '부자재 관리' ,
        subMenu: [
          {title: '부자재 입고 등록' , menuIndex: '71' , read: 'possible' , create: 'impossible'} ,
          {title: '부자재 재고 현황' , menuIndex: '72' , read: 'possible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '생산 관리' ,
        subMenu: [
          {title: '작업지시서 등록' , menuIndex: '73' , read: 'possible' , create: 'impossible'} ,
          {title: '금일 작업지시서' , menuIndex: '74' , read: 'possible' , update: 'impossible'} ,
          {title: '작업지시서 리스트' , menuIndex: '75' , read: 'possible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '작업이력 리스트' , menuIndex: '76' , read: 'possible' , update: 'impossible' , delete: 'impossible' , create: 'impossible'} ,
          {title: '작업완료 리스트' , menuIndex: '77' , read: 'possible' , update: 'impossible' , create: 'impossible'} ,
          {title: '불량이력 리스트' , menuIndex: '78' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'}
        ]
      } ,
      {
        title: '품질 관리' ,
        subMenu: [
          {title: '자주검사 리스트' , menuIndex: '79' , read: 'possible' , create: 'impossible' , update: 'impossible'} ,
          {title: '작업표준서 리스트' , menuIndex: '80' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '제품 변경점 리스트' , menuIndex: '81' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '불량 통계' , menuIndex: '82' , read: 'possible'} ,
        ]
      } ,
      {
        title: '재고 관리' ,
        subMenu: [
          {title: '재고 현황' , menuIndex: '83' , read: 'possible'} ,
          {title: '생산/출하 현황' , menuIndex: '84' , read: 'possible'} ,
          {title: '생산/출하 현황(관리자용)' , menuIndex: '85' , read: 'possible' , update: 'impossible'} ,
        ]
      } ,
      {
        title: 'KPI 지수' ,
        subMenu: [
          {title: '생산지수(P)' , menuIndex: '86' , read: 'possible'} ,
          {title: '품질지수(Q)' , menuIndex: '87' , read: 'possible'} ,
          {title: '원가지수(C)' , menuIndex: '88' , read: 'possible'} ,
          {title: '납기지수(D)' , menuIndex: '89' , read: 'possible'} ,
          {title: '에너지지수(E)' , menuIndex: '90' , read: 'possible'} ,
        ]
      } ,
    ]
  } ,
  {
    title: 'i-MES' , icon: aiIcon ,
    subTitle: [
      {title: '생산관리 - i' , menuIndex: '105' , read: 'possible'}
    ]
  }
]
export const authAffiliateCategory = [
  {
    title: 'FAS' , icon: softDocLogo , subTitle: [
      {title: '권한관리' , menuIndex: '93' , read: 'possible'} ,
      {title: '기계 기본정보' , menuIndex: '6' , read: 'possible'} ,
      {title: '알림' , menuIndex: '1' , read: 'possible'} ,
      {title: 'WIKI' , menuIndex: '2' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'P-MS' , icon: softDocLogo , subTitle: [
      {title: '설비 모니터링 시스템' , menuIndex: '30' , read: 'possible'} ,
      {title: '프레스 모니터링' , menuIndex: '31' , read: 'possible'} ,
      {title: '컴프레셔 모니터링' ,  menuIndex: '107' , read: 'possible'} ,
      {title: '라인 모니터링' ,  menuIndex: '108' , read: 'possible'} ,
      {
        title: '프레스 관리' ,
        subMenu: [
          {title: '운전관리 모니터링' , menuIndex: '38' , read: 'possible'} ,
          {title: '로드톤 모니터링' , menuIndex: '39' , read: 'possible'} ,
          {title: '에러 보기' , menuIndex: '40' , read: 'possible'} ,
          {title: '파라미터 보기' , menuIndex: '41' , read: 'possible'} ,
          {title: '캠 보기' , menuIndex: '42' , read: 'possible'} ,
          {title: '클러치&브레이크' , menuIndex: '43' , read: 'possible'} ,
        ]
      } ,
      {
        title: '프레스 데이터 통계 및 분석' ,
        subMenu: [
          {title: '생산량' , menuIndex: '49' , read: 'possible'} ,
          {title: '능력' , menuIndex: '50' , read: 'possible'} ,
          {title: '에러' , menuIndex: '51' , read: 'possible'} ,
          {title: '전력' , menuIndex: '52' , read: 'possible'} ,
          {title: '기계 비 가동시간' , menuIndex: '53' , read: 'possible'} ,
          {title: '작업시간' , menuIndex: '54' , read: 'possible'} ,
        ]
      }
    ]
  },
  {
    title: '산업안전 모니터링' , icon: softDocLogo , subTitle: [
      {title: '화재예방 모니터링' ,  menuIndex: '106' , read: 'possible'} ,
    ]
  } ,
]
export const authUserCategory = [
  {
    title: 'FAS' , icon: softDocLogo , subTitle: [
      // {title:'메인화면',menuIndex:'0',read:'possible',update:'impossible'},
      {
        title: '기본정보 관리' , subMenu: [
          {title: '권한관리' , menuIndex: '93' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '유저관리' , menuIndex: '94' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '공장 기본정보' , menuIndex: '3' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '거래처 기본정보' , menuIndex: '4' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '거래처 모델 관리' , menuIndex: '5' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '기계 기본정보' , menuIndex: '6' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '주변장치 기본정보' , menuIndex: '7' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '품목 기본정보' , menuIndex: '8' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '원자재 기본정보' , menuIndex: '9' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '부자재 기본정보' , menuIndex: '10' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '공정 관리' , menuIndex: '12' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '공정 종류 관리' , menuIndex: '13' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '금형 기본정보' , menuIndex: '14' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '일시정지 유형 관리' , menuIndex: '15' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '불량 유형 관리' , menuIndex: '16' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '도면 관리' , menuIndex: '11' , read: 'possible'} ,
          {title: '바코드 관리' , menuIndex: '18' , read: 'possible'} ,
          {title: '표준문서 관리' , menuIndex: '19' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
        ]
      } ,
      {title: '알림' , menuIndex: '1' , read: 'possible'} ,
      {title: 'WIKI' , menuIndex: '2' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: "impossible"} ,
    ]
  } ,
  {
    title: 'P-BAS' , icon: softDocLogo , subTitle: [
      {title: '메인화면' , menuIndex: '17' , read: 'possible'} ,
      {title: '예지보전 알림' , menuIndex: '20' , read: 'possible'} ,
      {title: '예지보전 관리이력' , menuIndex: '21' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'P-MS' , icon: softDocLogo , subTitle:
      [
        {title: '설비 모니터링 시스템' , menuIndex: '30' , read: 'possible' , update: 'impossible'} ,
        {title: '프레스 모니터링' , menuIndex: '31' , read: 'possible' , update: 'impossible'} ,
        {title: '컴프레셔 모니터링' ,  menuIndex: '107' , read: 'possible'} ,
        {title: '라인 모니터링' ,  menuIndex: '108' , read: 'possible'} ,
        // {title: '화재예방 모니터링' , menuIndex: '106' , read: 'possible' , update: 'impossible'} ,
        {
          title: '금형 관리' ,
          subMenu: [
            {title: '금형 타수 관리' , menuIndex: '32' , read: 'possible'} ,
            {title: '금형 수리요청 등록' , menuIndex: '33' , read: 'possible' , create: 'impossible'} ,
            {title: '금형 수리요청 리스트' , menuIndex: '34' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
            {title: '금형 수리완료 리스트' , menuIndex: '35' , read: 'possible'} ,
            {title: '금형 수리완료 리스트(관리자용)' , menuIndex: '36' , read: 'possible' , update: 'impossible'} ,
            {title: '금형 문제 유형 등록' , menuIndex: '37' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          ]
        } ,
        {
          title: '프레스 관리' ,
          subMenu: [
            {title: '운전관리 모니터링' , menuIndex: '38' , read: 'possible'} ,
            {title: '로드톤 모니터링' , menuIndex: '39' , read: 'possible'} ,
            {title: '에러 보기' , menuIndex: '40' , read: 'possible'} ,
            {title: '파라미터 보기' , menuIndex: '41' , read: 'possible'} ,
            {title: '캠 보기' , menuIndex: '42' , read: 'possible'} ,
            {title: '클러치&브레이크' , menuIndex: '43' , read: 'possible'} ,
            {title: '설비 수리요청 등록' , menuIndex: '44' , read: 'possible' , create: 'impossible'} ,
            {title: '설비 수리요청 리스트' , menuIndex: '45' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
            {title: '설비 수리완료 리스트' , menuIndex: '46' , read: 'possible'} ,
            {title: '설비 수리완료 리스트(관리자용)' , menuIndex: '47' , read: 'possible' , update: 'impossible'} ,
            {title: '설비 문제 유형 등록' , menuIndex: '48' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          ]
        } ,
        {
          title: '프레스 데이터 통계 및 분석' ,
          subMenu: [
            {title: '생산량' , menuIndex: '49' , read: 'possible'} ,
            {title: '능력' , menuIndex: '50' , read: 'possible'} ,
            {title: '에러' , menuIndex: '51' , read: 'possible'} ,
            {title: '전력' , menuIndex: '52' , read: 'possible'} ,
            {title: '기계 비 가동시간' , menuIndex: '53' , read: 'possible'} ,
            {title: '작업시간' , menuIndex: '54' , read: 'possible'} ,
          ]
        }
      ]
  } ,
  {
    title: '산업안전 모니터링' , icon: softDocLogo , subTitle: [
      {title: '화재예방 모니터링' ,  menuIndex: '106' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'C-MS' , icon: softDocLogo , subTitle:
      [
        {title: 'CNC 모니터링' , menuIndex: '95' , read: 'possible',update:'impossible'} ,
        {
          title: 'CNC 데이터 통계 및 분석' ,
          subMenu: [
            {title:'생산량',menuIndex:'96', read: 'possible'},
            {title:'에러',menuIndex:'97', read: 'possible'},
            {title:'기계 비 가동시간',menuIndex:'98', read: 'possible'},
          ]
        } ,
      ]
  },
  {
    title: 'V-MS' , icon: softDocLogo , subTitle:
      [
        {title: '비전 모니터링' , menuIndex: '99' , read: 'possible'} ,
        {title: '시/검사 이력' , menuIndex: '100' , read: 'possible'} ,
        {title: '품목별 통계 및 분석' , menuIndex: '101' , read: 'possible'} ,
        {title: '파라미터 보기' , menuIndex: '102' , read: 'possible'} ,
        {title: '검사 파라미터 설정' , menuIndex: '103' , read: 'possible'} ,
      ]
  },
  {
    title: 'F-EMS' , icon: softDocLogo ,
    subTitle: [
      {title: 'F-EMS 메인화면' , menuIndex: '55' , read: 'possible'} ,
      {title: '설비별 리포트' , menuIndex: '56' , read: 'possible'} ,
      {title: '전체설비 리포트' , menuIndex: '57' , read: 'possible'} ,
      {title: '프레스 에너지 모니터링' , menuIndex: '58' , read: 'possible'} ,
      {title: '에너지 알람 로그' , menuIndex: '59' , read: 'possible'} ,
      {title: '한전 사용량 보기' , menuIndex: '60' , read: 'possible'} ,
      {title: '에너지 수요예측' , menuIndex: '61' , read: 'possible'} ,
    ]
  } ,
  {
    title: 'MES' , icon: softDocLogo , subTitle: [
      {title: 'Home' , menuIndex: '62' , read: 'possible'} ,
      {
        title: '인사 관리' ,
        subMenu: [
          {title: '권한 관리' , menuIndex: '63' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '유저 관리' , menuIndex: '64' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '영업 관리' ,
        subMenu: [
          {title: '수주 관리' , menuIndex: '65' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '출하 관리' , menuIndex: '66' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '공정 관리' ,
        subMenu: [
          {title: '공정정보 관리' , menuIndex: '67' , read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible'} ,
          {title: '공정 세분화 관리' , menuIndex: '68' , read: 'possible' , create: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '원자재 관리' ,
        subMenu: [
          {title: '원자재 입고내역' ,menuIndex: '69', read: 'possible' } ,
          {title: '원자재 불출내역' ,menuIndex: '106', read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible' } ,
          {title: '원자재 재고현황' ,menuIndex: '70', read: 'possible' , create: 'impossible' , update: 'impossible' , delete: 'impossible' } ,
        ]
      } ,
      {
        title: '부자재 관리' ,
        subMenu: [
          {title: '부자재 입고 등록' , menuIndex: '71' , read: 'possible' , create: 'impossible'} ,
          {title: '부자재 재고 현황' , menuIndex: '72' , read: 'possible' , update: 'impossible' , delete: 'impossible'} ,
        ]
      } ,
      {
        title: '생산 관리' ,
        subMenu: [
          {title: '작업지시서 등록' , menuIndex: '73' , read: 'possible' , create: 'impossible'} ,
          {title: '금일 작업지시서' , menuIndex: '74' , read: 'possible' , update: 'impossible'} ,
          {title: '작업지시서 리스트' , menuIndex: '75' , read: 'possible' , delete: 'impossible'} ,
          {title: '작업이력 리스트' , menuIndex: '76' , read: 'possible' , update: 'impossible' , delete: 'impossible' , create: 'impossible'} ,
          {title: '작업완료 리스트' , menuIndex: '77' , read: 'possible' , update: 'impossible' , create: 'impossible'} ,
          {title: '불량이력 리스트' , menuIndex: '78' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'}
        ]
      } ,
      {
        title: '품질 관리' ,
        subMenu: [
          {title: '자주검사 리스트' , menuIndex: '79' , read: 'possible' , create: 'impossible' , update: 'impossible'} ,
          {title: '작업표준서 리스트' , menuIndex: '80' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '제품 변경점 리스트' , menuIndex: '81' , read: 'possible' , create: 'impossible' , delete: 'impossible' , update: 'impossible'} ,
          {title: '불량 통계' , menuIndex: '82' , read: 'possible'} ,
        ]
      } ,
      {
        title: '재고 관리' ,
        subMenu: [
          {title: '재고 현황' , menuIndex: '83' , read: 'possible'} ,
          {title: '생산/출하 현황' , menuIndex: '84' , read: 'possible'} ,
          {title: '생산/출하 현황(관리자용)' , menuIndex: '85' , read: 'possible' , update: 'impossible'} ,
        ]
      } ,
      {
        title: 'KPI 지수' ,
        subMenu: [
          {title: '생산지수(P)' , menuIndex: '86' , read: 'possible'} ,
          {title: '품질지수(Q)' , menuIndex: '87' , read: 'possible'} ,
          {title: '원가지수(C)' , menuIndex: '88' , read: 'possible'} ,
          {title: '납기지수(D)' , menuIndex: '89' , read: 'possible'} ,
          {title: '에너지지수(E)' , menuIndex: '90' , read: 'possible'} ,
        ]
      } ,
    ]
  }
]

export const realTimeStatusCircle = [
  {color: '#FFC700' , text: '예지보전'} ,
  {color: '#FF9A00' , text: '에러'} ,
  {color: '#F0142F' , text: '긴급'} ,
  {color: '#D5D7E3' , text: '일상점검'} ,
]

export const errorCodeList:any = {
  error01: '키캠 접점 이상' ,
  error02: '프로세스-C19-C20' ,
  error03: '프로세스-COM' ,
  error04: '프로세스-MEM' ,
  error05: '프로세스-FLASH MEM' ,
  error06: '프로세스-FLASH WR' ,
  error07: '조작반 비상정지 A' ,
  error08: '조작반 비상정지 B' ,
  error09: '캠 설정이상-안정기' ,
  error10: '캠 설정이상-상사점' ,
  error11: '캠 설정이상-오버런' ,
  error12: '캠 설정이상-안전일행정' ,
  error13: '안전센서' ,
  error14: '운전중 키캠 변경' ,
  error15: '프리셋 카운터 완료' ,
  error16: 'SPM 초과' ,
  error17: 'QDC 동작 중 키캠 변경' ,
  error18: '운전 중 자동/수동 변경' ,
  error19: '포터블 비상정지' ,
  error20: '후 좌 비상정지' ,
  error21: '후 우 비상정지' ,
  error22: '전 좌 비상정지' ,
  error23: '로드톤 초과' ,
  error24: '정/역 SQL 동작(모터OFF중)' ,
  error25: 'QDC 압력센터' ,
  error26: '외부 인터록' ,
  error27: 'R 비상정지 A' ,
  error28: '안전 플러그' ,
  error29: '광선식 안정기' ,
  error30: '자동화 인터록' ,
  error31: '윤활 펌프' ,
  error32: '인버터' ,
  error33: '공압' ,
  error34: '메인모터 릴레이 이상(C03, C04)' ,
  error35: '미스피드' ,
  error36: '프레스 과부하' ,
  error37: '릴레이보드 SP1' ,
  error38: '슬라이드 차단기 트림' ,
  error39: '-' ,
  error40: '-' ,
  error41: '슬라이드 모터 저전류' ,
  error42: '슬라이드 모터 과전류' ,
  error43: '메인모터 과전류' ,
  error44: '메인모터 저전류' ,
  error45: '브레이크 밀림' ,
  error46: '온도 과열' ,
  error47: '엔코더 반응시간' ,
  error48: '엔코더 변위량' ,
  error49: '오버런(엔코더)' ,
  error50: '양수조작 방호장치 접점 이상' ,
  error51: '휠 동작증 모터 기동' ,
  error52: '모터 기동 중 운전방향 변경' ,
  error53: '클러치 반응시간' ,
  error54: '릴레이 융착' ,
}
export const statisticErrorCode = {
  error01: '키캠 접점 이상' ,
  error02: '-' ,
  error03: '-' ,
  error04: '-' ,
  error05: '-' ,
  error06: '프로세스 이상-FLASH WR' ,
  error07: '(M21)조작반 비상정지 A' ,
  error08: '(26)조작반 비상정지 B' ,
  error09: '(CAM7)안정기 캠 미설정' ,
  error10: '(CAM8)상사점 캠 미설정' ,
  error11: '(CAM6)오버런 캠 미설정' ,
  error12: '(CAM10)안전일행정 캠 미설정' ,
  error13: '(52)안전센서 접점 이상' ,
  error14: '(운전중 키캠변경)' ,
  error15: '프리셋 카운터 생산 완료' ,
  error16: 'SPM 설정 속도 초과' ,
  error17: '-' ,
  error18: '(X10)운전 중 자동/수동 변경' ,
  error19: '(27)포터블 비상정지' ,
  error20: '(28)후 좌 비상정지' ,
  error21: '(29)후 우 비상정지' ,
  error22: '(30)전 좌 비상정지' ,
  error23: '설정 로드톤 초과' ,
  error24: '(X21)정/역 SOL ON 이상' ,
  error25: '(X11)QDC 압력센서 접전 이상' ,
  error26: '(25)외부 인터록 접점 이상' ,
  error27: '(21)릴레이보드 비상정지' ,
  error28: '(42)안전 플러그 접점 이상' ,
  error29: '(41)광선식 안정기 접점 이상' ,
  error30: '(X12)자동화 인터록 접점 이상' ,
  error31: '(X0F0)윤활 펌프 접점 이상' ,
  error32: '(X0E)인버터 접점 이상' ,
  error33: '(X0C)공압 접점 이상' ,
  error34: '메인모터 릴레이 이상(C03, C04)' ,
  error35: '(X07)미스피드 접점 이상' ,
  error36: '(X05)프레스 과부하 접점 이상' ,
  error37: '(SP1)릴레이보드 스페어1 접점 이상' ,
  error38: '(SP2)슬라이드 모터 차단기 트립' ,
  error39: '-' ,
  error40: '-' ,
  error41: '(X0D)슬라이드 모터 저전류' ,
  error42: '(X0D)슬라이드 모터 과전류' ,
  error43: '메인모터 과전류' ,
  error44: '메인모터 저전류' ,
  error45: '클러치&브레이크 밀림 설정 초과' ,
  error46: '(TP1,TP2)온도 과열' ,
  error47: '(EN)엔코더 이상1(엔코더 반응시간)' ,
  error48: '(EN)엔코더 이상2(각도변위량증가)' ,
  error49: '오버런 검출' ,
  error50: '양수조작 접점 이상' ,
  error51: '휠 동작증 모터 스위치 ON' ,
  error52: '모터 동작중 운전 동작 방향 변경' ,
  error53: '클러치 반응 시간 초과' ,
  error54: '릴레이 융착' ,
}

export const solutionData = [
  {
    id: '01' , contents: [
      {text: '모니터의 운전관리->입력확인 화면모드에서 X00,X01,X02,X03 의 상태를 확인바랍니다'} ,
      {text: '배선번호(X00,X01,X02,X03) 의 상태를 확인 바랍니다. (메인보드 단자 및 키캠 단자)'} ,
      {text: '파라미터 설정 화면에서 파라미터 01~04번의 값이 ‘2’(A접점) 상태인지 확인바랍니다.'} ,
      {text: '파라미터 설정 화면에서 파라미터 56번(키캠 응답속도) 값을 조정할 수 있습니다.'}
    ]
  } ,
  {
    id: '02' , contents: [
      {text: '간헐적으로 발생할 수 있으나, 횟수가 빈번할경우 담당자에게 연락바랍니다.'} ,
      {text: '메인보드 교체를 요망합니다.'} ,
    ]
  } ,
  {
    id: '03' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  } ,
  {
    id: '04' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  }
  ,
  {
    id: '05' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  } ,
  {
    id: '06' , contents: [
      {text: '간헐적으로 발생할 수 있으나, 횟수가 빈번할경우 담당자에게 연락바랍니다.'} ,
      {text: '메인보드 교체를 요망합니다.'} ,
    ]
  } ,
  {
    id: '07' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(M21,26)을 확인바랍니다. (메인보드 및 조작반 비상정지 스위치)'} ,
      {text: '파라미터 7~8번을 확인바랍니다.\n-7번 값이 1(B접점)인지 확인바랍니다.\n-8번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당 에러는 M21번 접점 이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '08' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(M21,26)을 확인바랍니다. (메인보드 및 조작반 비상정지 스위치)'} ,
      {text: '파라미터 7~8번을 확인바랍니다.\n-7번 값이 1(B접점)인지 확인바랍니다.\n-8번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당 에러는 26번 접점 이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '09' , contents: [
      {text: '캠 설정 7번(광선식 안정기 체크 각도) 가 설정값이 없을 경우 발생합니다.(ON:0도 OFF:0도)'} ,
      {text: '기본 값은 ON:5도 OFF:180도 입니다.'} ,
      {text: '작업 환경에 맞게 조절 가능합니다.'} ,
    ]
  } ,
  {
    id: '10' , contents: [
      {text: '캠 설정 8번(상사점 확인 각도) 가 설정값이 없을 경우 발생합니다.(ON:0도 OFF:0도)'} ,
      {text: '기본 값은 ON:330도 OFF:30도 입니다.'} ,
      {text: '작업 환경에 맞게 조절 가능합니다.'} ,
    ]
  } ,
  {
    id: '11' , contents: [
      {text: '캠 설정 6번(오버런 각도) 가 설정값이 없을 경우 발생합니다.(ON:0도 OFF:0도)'} ,
      {text: '기본 값은 ON:30도 OFF:180도 입니다.'} ,
      {text: '작업 환경에 맞게 조절 가능합니다.'} ,
    ]
  } ,
  {
    id: '12' , contents: [
      {text: '캠 설정 10번(안전일행정 캠 각도) 가 설정값이 없을 경우 발생합니다.(ON:0도 OFF:0도)'} ,
      {text: '기본 값은  ON:165도 OFF:359도 입니다.'} ,
      {text: '작업 환경에 맞게 조절 가능합니다.'} ,
    ]
  } ,
  {
    id: '13' , contents: [
      {text: '기계적 각도(반달센서)와 오차가 생길 경우 발생합니다.\n(기계적 각도가 틀어지는 부분을 체크하는 안전 장치 입니다.)'} ,
      {text: '52(안전센서) 배선을 확인하세요. 릴레이 보드 및 안전 센서'} ,
      {text: '파라미터 13번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: '기계적으로 반달 센서가 안달려 있을 수 있으니, 사용하지 못하는 기계들은 파라미터99번을 이용하여 미사용설정 해주시기 바랍니다. 2(미사용)\n'} ,
      {text: '관련 각도 체크 캠은 11번을 이용하여 조정 가능합니다. 기본설정(ON:160 OFF:355)'} ,
    ]
  } ,
  {
    id: '14' , contents: [
      {text: '운전 중 키캠을 강제로 변경 시 안전에러 발생합니다.'} ,
      {text: '기계가 완전히 정지 후 키캠을 조작 바랍니다.'} ,
      {text: '키캠을 조작하지 않았음에도 에러가 발생 할 경우 전기배선(X00,X01,X02,X03)을 확인 바랍니다.'} ,
      {text: '키캠 교체를 권장합니다.'} ,
    ]
  } ,
  {
    id: '15' , contents: [
      {text: 'Preset Counter 설정치 생산량이 완료되었음을 알립니다.'} ,
      {text: '모니터 상(매인모니터) 리셋 버튼을 통해서도 리셋이 가능합니다.'} ,
      {text: '키캠이 촌동이 아니더라도 양수의 리셋을 눌러도 리셋이 가능합니다.'} ,
      {text: '프리셋 카운터 완료시 예비캠을 통해 신호를 보낼 수 있습니다.'} ,
      {text: '예비캠5번을 예비캠으로 사용할지, 카운터 완료 목적으로 사용할지 선택가능합니다.'} ,
      {text: '파라미터 50번을 이용하여 1(카운트완료),2(예비캠5) 를 변경하여 사용 가능합니다.'} ,
    ]
  } ,
  {
    id: '16' , contents: [
      {text: '기계의 SPM이 설정치보다 높을 경우 발생합니다.'} ,
      {text: '파라미터 75번 값을 통해 설정이 가능합니다.(MAX 200 이하 권장)'} ,
      {text: '해당 에러 발생 시 안전상 메인모터가 강제로 꺼지게 됩니다.'} ,
    ]
  } ,
  {
    id: '17' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  } ,
  {
    id: '18' , contents: [
      {text: '운전 중 자동/수동 키 스위치를 변경 시 안전 상 발생합니다.'} ,
      {text: '프레스가 완전히 정지 후, 조작 바랍니다.'} ,
      {text: 'X10(자동/수동) 선택 스위치가 고장 나 발생할 수 있습니다. 확인 후 교체 바랍니다.'} ,
      {text: '파라미터 9번을 이용하여 접점 상태를 확인하세요.'} ,
      {text: '기본설정은 2(A접점) 입니다.'} ,
    ]
  } ,
  {
    id: '19' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(21,27)을 확인바랍니다. (포터블 비상정지 스위치)'} ,
      {text: ' 파라미터 19,27번을 확인바랍니다.\n-19번 값이 1(B접점)인지 확인바랍니다\n-27번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당에러는 21번 접점이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '20' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(21,28)을 확인바랍니다. (포터블 비상정지 스위치)'} ,
      {text: ' 파라미터 20,27번을 확인바랍니다.\n-20번 값이 1(B접점)인지 확인바랍니다\n-27번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당에러는 21번 접점이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '21' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(21,29)을 확인바랍니다. (포터블 비상정지 스위치)'} ,
      {text: ' 파라미터  21,27번을 확인바랍니다.\n-21번 값이 1(B접점)인지 확인바랍니다\n-27번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당에러는 21번 접점이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '22' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(21,30)을 확인바랍니다. (포터블 비상정지 스위치)'} ,
      {text: ' 파라미터  22,27번을 확인바랍니다.\n-22번 값이 1(B접점)인지 확인바랍니다\n-27번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당에러는 21번 접점이상입니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '23' , contents: [
      {text: '압전압 식 로드 톤을 사용할 경우 로드 오버톤 시 발생합니다.'} ,
      {text: '오버톤이 왜 발생 했는지 환경 확인 바랍니다.'} ,
      {text: '센서가 고장났을 경우 파라미터 44번을 변경하여(미사용) 임시 조치 가능합니다.'} ,
      {text: '파라미터 95번은 기계 로드톤 초과 상한치를 입력할 수 있습니다.(프레스 사양 권장)'} ,
      {text: '파라미터 44번 1(사용)으로 설정 후 파라미터 95번 값을 프레스 사양 ( ex)200톤 )을 설정 하시면 설정 가능합니다.'} ,
      {text: '로드톤 Cul 계산을 다시 해야하는 상황이 올 수 있습니다.(압의 변화 또는 압센서 고장)'} ,
      {text: '로드 채널에서 Cul 계산을 확인 하는 작업은 반드시 담당자에게 연락을 취해 주시기 바랍니다'} ,
      {text: '압전압식 특성상 압전압 범위 50%이하는 정확하지 않을 수 있으니, 참고바랍니다'} ,
    ]
  } ,
  {
    id: '24' , contents: [
      {text: '모터가 꺼졌으나, C19, C20 Relay(운전 SOl)이 살았을 경우 발생합니다.'} ,
      {text: '릴레이보드 C19,C20 릴레이 고장 유무를 확인 바랍니다.'} ,
      {text: '파라미터 46번 응답시간 조정을 통해 완화시킬 수 있습니다.'} ,
    ]
  } ,
  {
    id: '25' , contents: [
      {text: 'QDC 압력 센서가 이상 발생(접점 이상)입니다.'} ,
      {text: 'X11 접점 상태가 정상인지 확인바랍니다.(릴레이보드 X11, 압력센서)'} ,
      {text: 'QDC 장치(옵션)에 따라 확인바랍니다.'} ,
      {text: 'QDC 옵션을 사용하지 않을 경우, Common을 통해 강제 정상 상황을 만들어주세요.'} ,
      {text: '파라미터 49번 응답시간 조정을 통해 완화시킬 수 있습니다.'} ,
      {text: '파라미터 25번을 통해 X11 접점상태 변경이 가능합니다.'} ,
      {text: '기본 설정은 1(B접점)입니다.'} ,
    ]
  } ,
  {
    id: '26' , contents: [
      {text: '26(외부 인터록) 배선을 확인하세요.'} ,
      {text: '파라미터 26번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: '외부 인터록은 자동 복귀, 수동복귀 타입으로 선택가능합니다.'} ,
      {text: '파라미터 52번 값을 변경할 수 있습니다. 1(수동복귀), 2(자동복귀)'} ,
    ]
  } ,
  {
    id: '27' , contents: [
      {text: '비상정지 스위치가 눌렸는지 확인바랍니다.'} ,
      {text: '배선번호(21)을 확인바랍니다. ( 비상정지 스위치)'} ,
      {text: '파라미터 27번을 확인바랍니다.\n-27번 값이 2(A접점)인지 확인바랍니다.'} ,
      {text: '비상정지는 안전상 A,B접을 동시에 쓰고있습니다. 해당 에러는 21번 접점 이상입니다.'} ,
      {text: '27,28,29,30 비상 스위치와 같이 쓰고 있으니, 스위치도 함께 확인 바랍니다.'} ,
      {text: '비상정지 시 안전을 위한 강제로 메인모터를 끄는 모드가 있습니다.'} ,
      {text: '필요시, 파라미터 54번 값을 1(메인모터 꺼짐)로 변경하면 비상정지가 발생 시 메인모터는 꺼지게 됩니다.'} ,
      {text: '기본 설정은 2(메인모터 켜짐)입니다.'} ,
    ]
  } ,
  {
    id: '28' , contents: [
      {text: '42(안전 플러그) 배선을 확인하세요.'} ,
      {text: '파라미터 28번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 1(B접점)입니다.'} ,
      {text: '안전플러그 고장 유무를 확인하세요.'} ,
    ]
  } ,
  {
    id: '29' , contents: [
      {text: '41(광선식 안정기) 배선을 확인하세요.'} ,
      {text: '파라미터 29번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 1(B접점)입니다.'} ,
      {text: '광선식 안정기 고장 유무를 확인하세요.'} ,
      {text: '안정기 동작 모드는 촌동, 안전일행정, 연속모드 별로 설정할 수 있습니다.'} ,
      {text: '파라미터 91번(촌동 안정기 검출 형태)를 통해 설정 가능합니다.'} ,
      {text: '파라미터 92번(안전일행정 검출 형태)를 통해 설정 가능합니다.'} ,
      {text: '파라미터 93번(연속 검출 형태)를 통해 설정 가능합니다.'} ,
      {text: '91~93번 중 값을 1로 설정할 경우, 전구간 검출 형태로 변경됩니다.'} ,
      {text: '91~93번 중 값을 2로 설정할 경우, 캠7번 값에 의해 구동됩니다.'} ,
      {text: '촌동 모드는 전체적으로 자동 복귀 형태입니다.'} ,
      {text: '안전일행정은 촌동구간:자동복귀, 자동상사점구간: 수동복귀 입니다.'} ,
      {text: '연속은 수동 복귀 형태입니다'} ,
    ]
  } ,
  {
    id: '30' , contents: [
      {text: 'X12(자동화 인터록) 배선을 확인하세요.'} ,
      {text: '파라미터 30번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 1(B접점)입니다.'} ,
      {text: '자동화 라인과 연동 시 자동화 라인 장비 또는 장치를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '31' , contents: [
      {text: 'X0F(윤활 펌프) 배선을 확인하세요.'} ,
      {text: '파라미터 31번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: '윤활펌프 센서를 확인하여 고장 유무를 확인하세요.'} ,
      {text: '파라미터59번을 통해 윤활펌프 모드를 선택할 수 있습니다. 압력센서 종류에 따라 1~3번까지 선택가능합니다.'} ,
      {text: '일반 압력센서는 1번으로 셋팅됩니다.'} ,
    ]
  } ,
  {
    id: '32' , contents: [
      {text: 'X0E(인버터) 배선을 확인하세요.'} ,
      {text: '파라미터 32번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 1(B접점)입니다.'} ,
      {text: '인버터의 과부하 또는 고장으로 인한 신호를 받으므로, 인버터 장비를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '33' , contents: [
      {text: 'X0C(공압) 배선을 확인하세요.'} ,
      {text: '파라미터 33번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 1(B접점)입니다.'} ,
      {text: '프레스 공압라인에 공압이 들어왔는지 확인 바랍니다.'} ,
    ]
  } ,
  {
    id: '34' , contents: [
      {text: '메인보드-릴레이보드 사이를 연결하는 JP6-1 IDC 케이블 연결상태를 확인합니다. '} ,
      {text: '모터ON 스위치를 눌러 메인모터를 가동해도 C03,C04 릴레이가 작동하는지 확인합니다.'} ,
      {text: '릴레이가 작동하지 않으면 릴레이를 새 부품으로 교체합니다.'} ,
    ]
  } ,
  {
    id: '35' , contents: [
      {text: 'X07(미스피드) 배선을 확인하세요.'} ,
      {text: '파라미터 35번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: 'X07 라인에 연결된 장비(미스피드 또는 센서)를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '36' , contents: [
      {text: 'X05(프레스 과부하) 배선을 확인하세요.'} ,
      {text: '파라미터 35번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: '과부하 해제 절차는 다음과 같습니다.'} ,
      {text: '과부하 발생 후 촌동 리셋을 눌러줍니다.'} ,
      {text: '임시적으로 에러는 해제되며, 과부하 압은 빠져있으나 구동이 가능합니다.'} ,
      {text: '양수를 통해 상사점으로 올려줍니다.'} ,
      {text: '상사점 도달시 다시 한번 에러가 발생합니다.'} ,
      {text: '파라미터 55번 값이 1(오토리셋),2(버튼리셋)에 따라 복귀 형태가 달라집니다.'} ,
      {text: '-1일경우 상사점에서 자동으로 과부하 압력이 차게 되며, 압이 복귀될시 자동으로 복귀되어 정상처리됩니다.'} ,
      {text: '-2일경우 상사점에서 촌동 리셋 후에 과부하 압력이 차게되며, 압이 복귀될시 정상처리됩니다.'} ,
    ]
  } ,
  {
    id: '37' , contents: [
      {text: 'SP1(스페어접점) 배선을 확인하세요.'} ,
      {text: '파라미터 37번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
      {text: 'SP1 라인에 연결된 장비를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '38' , contents: [
      {text: '슬라이드모터 차단기의 트립 스위치가 올라와 있는지 확인바랍니다.'} ,
      {text: 'SP2(슬라이드 차단기 트립) 배선을 확인하세요. '} ,
      {text: '파라미터 38번 접점 유무를 확인하세요.'} ,
      {text: '기본설정은 2(A접점)입니다.'} ,
    ]
  } ,
  {
    id: '39' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  } ,
  {
    id: '40' , contents: [
      {text: '현재는 사용하지 않습니다.'} ,
    ]
  } ,
  {
    id: '41' , contents: [
      {text: '슬라이드 모터 설정치 전류보다 낮을 경우 발생합니다.'} ,
      {text: '파라미터 48번 값에 의해 자동으로 시간이 지난 후 복귀 됩니다.'} ,
      {text: '70번 저전류 설정치 값을 조정이 가능합니다.'} ,
      {text: '71번 저전류 발생시 허용시간 조정이 가능합니다.'} ,
      {text: '슬라이드 모터 이상 유무를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '42' , contents: [
      {text: '슬라이드 모터 설정치 전류보다 높을 경우 발생합니다.'} ,
      {text: '파라미터 48번 값에 의해 자동으로 시간이 지난 후 복귀 됩니다.'} ,
      {text: '68번 과전류 설정치 값을 조정이 가능합니다.'} ,
      {text: '69번 과전류 발생시 허용시간 조정이 가능합니다.'} ,
      {text: '슬라이드 모터 이상 유무를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '43' , contents: [
      {text: '메인 모터 설정치 전류보다 높을 경우 발생합니다.'} ,
      {text: '파라미터 61번을통해 Y-D 시간 조절이 가능합니다.'} ,
      {text: '62번 과전류 설정치 값을 조정이 가능합니다. Y운전'} ,
      {text: '63번 과전류 발생시 허용시간 조정이 가능합니다. Y운전'} ,
      {text: '64번 과전류 설정치 값을 조정이 가능합니다. D운전'} ,
      {text: '65번 과전류 발생시 허용시간 조정이 가능합니다. D운전'} ,
    ]
  } ,
  {
    id: '44' , contents: [
      {text: '메인 모터 설정치 전류보다 낮을 경우 발생합니다.'} ,
      {text: '파라미터 61번을 통해 Y-D 시간 조절이 가능합니다.'} ,
      {text: '66번 저전류 설정치 값을 조정이 가능합니다.'} ,
      {text: '67번 저전류 발생시 허용시간 조정이 가능합니다.'} ,
      {text: '메인 모터 이상 유무를 확인바랍니다'} ,
    ]
  } ,
  {
    id: '45' , contents: [
      {text: '밀림 값이 설정치 보다 초과됬을 경우 발생합니다.'} ,
      {text: '파라미터 77번 값을 통해 설정치를 조정 가능합니다.'} ,
      {text: '100 이상 쓰지 않는 것을 권장합니다.'} ,
      {text: '해당 에러는 양수를 OFF 했음에도 불구하고 기계적 밀림이 100도 이상 내려온다는 의미입니다.'} ,
      {text: '클러치&브레이크 보수 유무를 확인바랍니다.'} ,
    ]
  } ,
  {
    id: '46' , contents: [
      {text: '설정 온도보다 높을 경우 발생합니다.(옵션)'} ,
      {text: '모니터 화면에서 온도 설정을 할 수 있습니다.'} ,
      {text: '보통 좌/우측 클러치에 온도 센서를 달아 클러치 보호를 위한 목적입니다.'} ,
    ]
  } ,
  {
    id: '47' , contents: [
      {text: '양수 후 기계적 각도(엔코더)가 변화가 일정 시간동안 없었을 때 발생합니다.'} ,
      {text: '엔코더가 이상일 경우 발생하거나, 작업 부주의로 인하여 자주 발생합니다.(촌동작업 금형교체시 )'} ,
      {text: '78번 값을 통해 변화 시간을 제어 할 수 있습니다.'} ,
      {text: '불편할 경우 78번 값을 255로 셋팅 바랍니다.'} ,
      {text: '물리적으로 엔코더가 고장 낫을 경우 안전사고가 발생할 수도 있으니, 가능하면 78번 값을 적절히 활용바랍니다.'} ,
    ]
  } ,
  {
    id: '48' , contents: [
      {text: '엔코더 각도 체크를 했을 때 1도 단위로 체크를 하지 못할 경우 발생합니다.'} ,
      {text: '엔코더가 이상일 경우가 많으니, 반드시 엔코더 교체를 요망합니다.'} ,
      {text: '프레스는 엔코더가 중요한 역할을 하므로 안전 사고 대비를 위해 해당 에러가 자주 발생시 교체 요망합니다.'} ,
    ]
  } ,
  {
    id: '49' , contents: [
      {text: '안전일행정 작업 시 상사점 정지 각도가 캠6번(30도)보다 넘어섰을 경우 발생합니다.'} ,
      {text: '기계적 밀림이 일정 수치 이상 발생했을 때 자주 발생하므로, 클러치 및 브레이크 보전관리를 요망합니다.'} ,
      {text: '캠 6번 (ON:30, OFF:180) 도 각도를 조정하여 오버런 발생 시점을 조정할 수 있습니다.'} ,
      {text: '안전 상 이유로 오버런 각도를 35도 이상늘리지 마시고, 임시적 조치로만 활용해주시기 바랍니다.'} ,
    ]
  } ,
  {
    id: '50' , contents: [
      {text: '양수 오른쪽 왼쪽 눌림 시간 차이가 설정치 이상 발생 했을 경우 발생합니다.'} ,
      {text: '파라미터 79번값을 이용하여 시간차를 조정할 수 있습니다.'} ,
      {text: '양수 내부에 타이머가 장착되어 2중 장치로서 역할을 합니다.'} ,
      {text: '반드시 양수 작업 시에는 양손을 이용하여 작업해주시기 바랍니다.'} ,
    ]
  } ,
  {
    id: '51' , contents: [
      {text: '메인 모터가 완전히 정지 하지 않는 상태에서 모터 스위치를 켰을 때 발생합니다.'} ,
      {text: '메인 모터가 일정 SPM을 돌고 있는 상태에서 모터를 켰을 경우 기계적 마모가 발생 할 수 있으니, 일정 SPM이하에서만 모터를 켜주시기 바랍니다.'} ,
      {text: '파라미터 47번 값을 이용하여 일정 SPM 이하를 0으로 인식시켜주는 소거 값이 존재합니다. 적절하게 맞춰 사용하시면 됩니다.'} ,
    ]
  } ,
  {
    id: '52' , contents: [
      {text: '메인 모터가 동작 중에 정/역 운전을 변경시에 발생합니다.'} ,
      {text: '운전 방향을 메인 모터가 완전히 정지 하지 않은 상태에서 변경하게 되면 메인모터 무리 및 기계적 마모가 발생할 수 있으니, 반드시 정지 후에 변경하기 바랍니다.'} ,
    ]
  } ,
  {
    id: '53' , contents: [
      {text: '양수 운전 후 클러치가 실제로 반응하는 시간보다 클 경우 발생합니다.'} ,
      {text: '클러치&브레이크의 SOL 동작 속도가 느릴 경우 기계적 보정이 필요로 합니다.'} ,
      {text: '파라미터 78번을 이용하여 응답시간을 조정할 수 있습니다.'} ,
      {text: 'C19, C20 릴레이의 이상유무를 확인바랍니다'} ,
    ]
  } ,
  {
    id: '54' , contents: [
      {text: '양수 운전이 발생하지 않았음에도 운전 릴레이가 살았을 경우 발생합니다.'} ,
      {text: '파라미터 83번을 이용하여 응답시간을 조정할 수 있습니다.'} ,
      {text: 'C19, C20 릴레이의 이상유무를 확인바랍니다'} ,
    ]
  } ,
]
export const productSearchData = [
  {account: '진주상사' , unit: 'ea' , model: '한국차' , code: 'SU-8748465-1' , productName: 'AU122' , productType: '반제품' , process: '프레스' , productCode: 'productCode#212'} ,
  {account: '대성상사' , unit: 'g' , model: '일본차' , code: 'SU-678456354-5' , productName: 'BW672' , productType: '반제품' , process: '프레스' , productCode: 'productCode#12'} ,
  {account: '삼성상사' , unit: 'g' , model: '중국차' , code: 'SU-45645234-2' , productName: 'DE112' , productType: '반제품' , process: '프레스' , productCode: 'productCode#123'} ,
  {account: '대춘상사' , unit: 'ton' , model: '필리핀차' , code: 'SU-2034534521-1' , productName: 'GF252' , productType: '부자재' , process: '프레스' , productCode: 'productCode#345'} ,
  {account: '잔사상사' , unit: 'kg' , model: '대만차' , code: 'SU-245645634-45' , productName: '3A552' , productType: '부자재' , process: '프레스' , productCode: 'productCode#2562'} ,
  {account: '진주상사' , unit: 'ea' , model: '진주차' , code: 'SU-234534512-6' , productName: 'EF232' , productType: '원자재' , process: '프레스' , productCode: 'productCode#677'} ,
]

export const timeDataSet = {
  firstTime: ['00' , '01' , '02' , '03' , '04' , '05' , '06' , '07' , '08' , '09' , '10' , '11' , '12' , '13' , '14'] ,
  secondTime: ['15' , '16' , '17' , '18' , '19' , '20' , '21' , '22' , '23' , '24' , '25' , '26' , '27' , '28' , '29'] ,
  thirdTime: ['30' , '31' , '32' , '33' , '34' , '35' , '36' , '37' , '38' , '39' , '40' , '41' , '42' , '43' , '44'] ,
  forthTime: ['45' , '46' , '47' , '48' , '49' , '50' , '51' , '52' , '53' , '54' , '55' , '56' , '57' , '58' , '59']
}

export const tempSignUpTerms = [
  {
    checkTitle: '이용약관 동의' , valueID: 'terms' ,
    title: '개인정보의 수집' ,
    contents: `개인정보의 수집 1) 신규로 개인정보를 수집하는 경우 
                  서비스 제공을 위해 개인정보 수집이 반드시 필요한지 다시 한 번 검토하고, 최소한의 개인정보 수집인지 확인해 
                  주세요. 회원 가입 당시 수집한 개인정보 외에 추가로 개인정보 수집이 필요할 때는 개인정보 추가 수집에 대해 
                  이용자의 동의를 받아야 합니다.
                   2) 적법한 동의 구현 개인정보 수집이 반드시 필요하다면, 법률에 의해 적법하게 동의를 받고 있는지 확인해
                   주세요. 최소한의 개인정보 수집이 맞는지, 수집하는 개인정보에 대하여 필수항목과 선택항목을 구분하였는지, 
                   민감정보 및 고유식별정보는 개별 동의를 구현하였는지, 법령에 의하여 수집이 허용된 경우가 아닌데도 
                   주민등록번호를 수집하는 경우는 없는지, 만 14세 미만 아동의 경우 법정대리인 동의가 구현되어 있는지 등을 
                   모두 확인해야 합니다. 
                   ※ 서비스 제공 과정에서 개인위치정보의 처리가 발생한다면? 회사의 위치기반서비스 사업자 및 이용약관의 
                   방송통신위원회 신고 유무를 확인해야 합니다. 또한 이용자들에게 위치기반서비스 이용약관 동의를 받아야 합니다.`
  } ,
  {
    checkTitle: '개인정보 수집 및 이용 동의' , valueID: 'privacy' ,
    title: '개인정보의 수집' ,
    contents: `개인정보의 수집 1) 신규로 개인정보를 수집하는 경우 
                  서비스 제공을 위해 개인정보 수집이 반드시 필요한지 다시 한 번 검토하고, 최소한의 개인정보 수집인지 확인해 
                  주세요. 회원 가입 당시 수집한 개인정보 외에 추가로 개인정보 수집이 필요할 때는 개인정보 추가 수집에 대해 
                  이용자의 동의를 받아야 합니다.
                   2) 적법한 동의 구현 개인정보 수집이 반드시 필요하다면, 법률에 의해 적법하게 동의를 받고 있는지 확인해
                   주세요. 최소한의 개인정보 수집이 맞는지, 수집하는 개인정보에 대하여 필수항목과 선택항목을 구분하였는지, 
                   민감정보 및 고유식별정보는 개별 동의를 구현하였는지, 법령에 의하여 수집이 허용된 경우가 아닌데도 
                   주민등록번호를 수집하는 경우는 없는지, 만 14세 미만 아동의 경우 법정대리인 동의가 구현되어 있는지 등을 
                   모두 확인해야 합니다. 
                   ※ 서비스 제공 과정에서 개인위치정보의 처리가 발생한다면? 회사의 위치기반서비스 사업자 및 이용약관의 
                   방송통신위원회 신고 유무를 확인해야 합니다. 또한 이용자들에게 위치기반서비스 이용약관 동의를 받아야 합니다.`
  } ,
  {checkTitle: '정보 수신 동의' , valueID: 'info' ,}
]





























