import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MainButtons from "../button/MainButtons";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime'
import client from '../../common/Config'
import {Authorization} from '../../common/authorization'
import {useParams} from 'react-router-dom'
import ToastPopup from '../modal/ToastPopup'
import {dataAccessLoading , dataValid , initModal , listLoadingCircle , loadingRemove} from '../../function/function'
import {Modal_View_Type} from '../../types/Fundamental'
import SelectConfirmModal from '../modal/SelectConfirmModal'
import {useRecoilState} from 'recoil'
import {selectedCommentCodeState} from '../../common/StateManage'
import WikiCommentDetail from './WikiCommentDetail'

interface CommentType {
  id: string
  comment: string
  writer: string
  commentCode: string
  registrationDate: string
  modifiedDate: string
  profileImageUrl: string
  authority: string
  reply:any[]
  statement: string
}

const WiKiComment = () => {

  const [commentList, setCommentList] = useState<CommentType[]>([]);
  const [commentValue, setCommentValue] = useState<string>('')
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [selectedComment, setSelectedComment] = useRecoilState<string>(selectedCommentCodeState)
  const [commentLoading, setCommentLoading] = useState<boolean>(true)
  const params = useParams()
  const {wikiCode} = params

  const [inputLoading, setInputLoading] = useState<boolean>(false)
  dayjs.extend(relativeTime)

  let requestCount = 0
  const wikiCommentListRes = async () => {
    try {
      requestCount++
      const response = await client.get(`/wiki/comment/list/1/60/${wikiCode}`,{headers:{Authorization}})
      setCommentList(response.data.data.row)
    }
    catch (e) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }

  useEffect(()=>{
    wikiCommentListRes().then(()=>{
      if (requestCount === 0) {
        setCommentLoading(false)
        const refresh = setInterval(() => {
          wikiCommentListRes().then()
        }, 10000)
        return () => {clearInterval(refresh)}
      }
    })
  },[])


  //유저 계정상태에서 댓글 등록 시 재문의를 선택했을 경우, 해당 위키의 타입을 변경하기 위한 기능
  const wikiTypeUpdate = async () => {
    //처음 위키 세부 정보를 먼저 가져온 뒤에 수정 하는 방식
    try {
      const response = await client.post('/wiki/detail',{
        wikiCode: wikiCode,
      },{headers:{Authorization}})
      const wikiData = response.data.data

      try {
        dataAccessLoading()
        const response = await client.post('/wiki/update',{
          ...wikiData,
          wikiFinish: '0',
          wikiType:'재문의'
        },{headers:{Authorization}})
        const data = response.data
        if(data.status !== 200){
          loadingRemove()
        }else{
          setTimeout(()=>{
            loadingRemove()
          },300)
        }
      }catch (e:any) {
        console.log(e)
        setDataModal({menu:'confirm',message:e.message})
        loadingRemove()
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  // const wikiModifyRes = async () => {
  //     try {
  //       dataAccessLoading()
  //       const response = await client.post('/wiki/update',{
  //         ...wikiData,
  //         wikiFinish: '0',
  //         wikiType:'재문의'
  //       },{headers:{Authorization}})
  //       const data = response.data
  //       if(data.status!==200){
  //         setDataModal({menu:'confirm',message:data.message})
  //         loadingRemove()
  //       }else{
  //         setTimeout(()=>{
  //           loadingRemove()
  //           setDataModal({menu:'success',message:'수정 되었습니다.'})
  //         },500)
  //       }
  //     }catch (e:any) {
  //       console.log(e)
  //       setDataModal({menu:'confirm',message:e.message})
  //       loadingRemove()
  //     }
  //   }






  const wikiCommentReg = async () => {
    setInputLoading(true)
    try {
      const response = await client.post('/wiki/comment/register',{
        comment: commentValue,
        parentCode: wikiCode
      },{headers:{Authorization}})

      if(response.data.status === 200){
        setCommentValue('')
        textareaRef.current.style.height = '48px'
        wikiCommentListRes().then()
      }
      else{
        setDataModal({menu:'error', message: response.data.message})
      }
    }
    catch (e:any) {
      console.log(e)
      setDataModal({menu:'error', message: e.message})
    }
    finally {
      setInputLoading(false)
    }
  }

  const wikiCommentDelete = async () => {
    listLoadingCircle()
    setDataModal(initModal)
    try {
      const response = await client.post('/wiki/comment/delete',{
        commentCode: selectedComment
      },{headers:{Authorization}})

      if(response.data.status === 200){
        wikiCommentListRes().then(()=>{
          setCommentValue('')
          setSelectedComment('')
          setDataModal({menu:'success', message:'댓글이 삭제 되었습니다.'})
          loadingRemove()
        })
      }
      else{
        setDataModal({menu:'error', message: response.data.message})
        loadingRemove()
      }
    }
    catch (e:any) {
      console.log(e)
      setDataModal({menu:'error', message: e.message})
      loadingRemove()
    }
    finally {
      setInputLoading(false)
    }
  }


  const textareaRef = React.useRef<any>(null)
  // const modifiedRef = React.useRef<any>(null)

  const autoResize = (ref:any) => {

    const textarea = ref.current;
    textarea.style.height = 'auto';

    const contentHeight = textarea.scrollHeight;
    textarea.style.height = contentHeight + 'px';

    // 커서를 항상 맨 아래에 위치하도록 조절
    textarea.scrollTop = textarea.scrollHeight;

    // 내부 padding을 8px로 설정 ------------- 기획 디자인 변경으로 사용안함
    // textarea.style.paddingTop = '8px';
    // textarea.style.paddingBottom = '8px';
  }

  // const autoModifiedResize = () => {
  //
  //   const textarea = modifiedRef.current;
  //
  //   textarea.style.height = 'auto';
  //   const contentHeight = textarea.scrollHeight;
  //   textarea.style.height = contentHeight + 'px';
  //
  //   // 커서를 항상 맨 아래에 위치하도록 조절
  //   textarea.scrollTop = textarea.scrollHeight;
  //
  //   // 내부 padding을 8px로 설정 ------------- 기획 디자인 변경으로 사용안함
  //   // textarea.style.paddingTop = '8px';
  //   // textarea.style.paddingBottom = '8px';
  // }

  const activeReply = commentList?.filter((el)=>(el.statement === 'active'))
  //대댓글 사이에
  const replyLength = commentList?.length === 0? 0 :
    commentList?.map((v)=>(v?.reply.filter((re)=>re.statement === 'active').length))?.reduce((acc, value)=>(acc + value))

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={(dataModal.message)} state={'error'} closeEvent={()=> {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={(dataModal.message)} state={'warn'} closeEvent={()=> {setDataModal( initModal )}}/>: null}

      {dataModal.menu === 'notice' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'\"예\" 선택시 게시판 상태가 재문의로 변경됩니다.'}
                                                          clickEvent={()=>{
                                                            wikiCommentReg().then()
                                                            wikiTypeUpdate().then()
                                                            setCommentValue('')
                                                            setSelectedComment('')
                                                            setDataModal(initModal)
                                                          }}
                                                          cancelEvent={()=> {
                                                            wikiCommentReg().then()

                                                            setCommentValue('')
                                                            setSelectedComment('')
                                                            setDataModal(initModal)
                                                          }}/>}


      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'삭제된 댓글은 복구할 수 없습니다.'}
                                                          clickEvent={wikiCommentDelete}
                                                          cancelEvent={()=> {
                                                            setCommentValue('')
                                                            setSelectedComment('')
                                                            setDataModal(initModal)
                                                          }}/>}

      {
        commentLoading?
          <div style={{color:'#7E84A3', fontSize:'12px', textAlign:'center', padding:'16px 0',borderTop:'1px solid #E5E5E5', userSelect:'none'}}>
            {'댓글 목록을 불러오는 중 입니다...'}
          </div>
          :
          <BaseComp>
            <div style={{fontWeight: 800 , fontSize: '12px'}}>
              {( dataValid(activeReply?.length + replyLength , 0) ) + '개의 댓글'}
            </div>

            <React.Fragment>
              {/*목록 부분 */}
              {commentList?.map((v) => (
                <WikiCommentDetail replyData={v}
                                   cancelEvent={
                                     () => {
                                       setCommentList([])
                                       wikiCommentListRes().then()
                                     }
                                   }
                                   listApi={wikiCommentListRes}
                />
              ))}
            </React.Fragment>

            <React.Fragment>
              {/*입력 부분 */}
              {/*<div style={{display: 'flex' , alignItems: 'flex-start' , width: '100%' , margin: '20px 8px' , gap: '10px',*/}
              {/*  opacity: (selectedComment)? 0.1 : 1, pointerEvents: (selectedComment)? 'none' : 'auto'*/}
              {/*}}>*/}
              {/*  <img src={`${window.localStorage.getItem('profile')}` ?? `${ProfileSample}`} alt="" width={38} height={38}*/}
              {/*       style={{*/}
              {/*         borderRadius: '50%' , border: '1px solid #e0e0e0' , boxSizing: 'border-box' ,*/}
              {/*         display: 'block' , objectFit: 'cover'*/}
              {/*       }}*/}
              {/*       onError={(e: React.ChangeEvent<HTMLImageElement>) => e.target.src = ProfileSample}/>*/}

              {/*  <InputTextBox>*/}
              {/*    <textarea className={'auto-resize-input'}*/}
              {/*              value={(selectedComment === '')? commentValue : ''}*/}
              {/*              ref={textareaRef} onInput={()=>autoResize(textareaRef)}*/}
              {/*              placeholder={'댓글을 입력해 주세요.'} spellCheck={false}*/}
              {/*              onChange={(e) => (setCommentValue(e.target.value))}/>*/}
              {/*  </InputTextBox>*/}

              {/*  <MainButtons name={'등록'} buttonType={'cta'}*/}
              {/*               loading={(inputLoading)} disabled={((selectedComment !== '') || (!commentValue || inputLoading))}*/}
              {/*               width={'56px'} margin={'0 0 0 0px'}*/}
              {/*               clickEvent={()=>{*/}
              {/*                if((window.localStorage.getItem('auth') !== 'adminUser') && (window.localStorage.getItem('auth') !== 'admin')){*/}
              {/*                  setDataModal({menu:'notice', message:'작성한 글을 재문의 상태로 \n등록 하시겠습니까?'})*/}
              {/*                }else{*/}
              {/*                  wikiCommentReg().then()*/}
              {/*                }*/}
              {/*               }}/>*/}
              {/*</div>*/}
              <div style={{width: '100%' , height: '1px' , backgroundColor: ''}}/>
              <div style={{
                display: 'flex' , alignItems: 'flex-start' , minHeight: '72px' , width: '100%' , marginTop: '8px' ,
                opacity: selectedComment ? 0.1 : 1 , pointerEvents: selectedComment ? 'none' : 'auto' , padding: '8px 16px' ,
                boxSizing: 'border-box' , border: '1px solid #D7DBEC' , borderRadius: '4px'
              }}>
                <InputTextBox style={{boxSizing: 'border-box'}}>
                  <div style={{display: 'flex' , justifyContent: 'space-between' ,}}>
                    <div
                      style={{color: '#171725' , fontSize: '12px' , fontWeight: 700}}>{window.localStorage.getItem('userName')}</div>
                    <div style={{color: '#7E84A3' , fontSize: '10px'}}>{commentValue.length + '/' + '3000'}</div>
                  </div>
                  <div style={{display: 'flex' , justifyContent: 'space-between' , marginTop: '8px'}}>
                            <textarea className={'auto-resize-input'} maxLength={3000}
                                      value={selectedComment ? '' : commentValue}
                                      ref={textareaRef} onInput={() => autoResize(textareaRef)}
                                      placeholder={'댓글을 남겨보세요.'} spellCheck={false}
                                      onChange={(e) => ( setCommentValue(e.target.value) )}/>
                    <MainButtons name={'등록'} buttonType={'cta'}
                                 loading={inputLoading}
                                 disabled={selectedComment !== '' || ( !commentValue || inputLoading )}
                                 width={'56px'} margin={'0 0 0 0px'}
                                 clickEvent={()=>{
                                   if((window.localStorage.getItem('auth') !== 'adminUser') && (window.localStorage.getItem('auth') !== 'admin')){
                                     setDataModal({menu:'notice', message:'작성한 글을 재문의 상태로 \n등록 하시겠습니까?'})
                                   }else{
                                     wikiCommentReg().then()
                                   }
                                 }
                    }/>
                  </div>
                </InputTextBox>
              </div>
            </React.Fragment>
          </BaseComp>}

    </React.Fragment>
  );
};
export default WiKiComment;



const BaseComp =styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  border-top: 1px solid #E5E5E5;
  padding-top: 16px;
  box-sizing: border-box;


  .reply-button{
    font-size: 10px;
    //margin: 0 10px;
    margin-left: 19px;
    color: #7E84A3;
    user-select: none;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
    &:hover{
      color: #0058ff;
      font-weight: 700;
    }
  }

`


const InputTextBox = styled.div<{error?: boolean, selected?: boolean, readonly?: boolean}>`
  width: 100%;
  //max-width: 650px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;

  .auto-resize-input{
    width: 100%;
    height: auto;
    outline: none;
    box-sizing: border-box;
    //padding: 8px;
    padding: 0;
    border-radius: 4px;
    border: none;
      //border:  ${props => props.readonly? '1px solid #d7dbec' : '2px solid rgba(0,88,255,0.85)'};
    resize: none;
    font-size: 12px;
    font-family: Pretendard, sans-serif;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    margin-right: 48px;
    // &:focus{
      //   border: ${props => props.readonly? '1px solid #d7dbec' : props => props.selected? 2 : 1}px solid ${props => props.error? '#F0142F' : '#0058ff'};
    //   font-weight: 400;
    // }

    &::placeholder{
      font-size: 12px;
      font-weight: normal;
      color: rgba(23, 23, 37, 0.3);
      font-family: Pretendard, sans-serif;
    }

    &::-webkit-scrollbar {
      width: 15px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E6E9F4;
      border-radius: 10px;
      background-clip: padding-box;
      border: 6px solid transparent;
    }
  }
`