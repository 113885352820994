import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import CustomerNotice from "../../../containers/admin/fas/company/alarm/CustomerNotice";
import {PageContainer} from "../../../style/StyleComponent";
import CustomerNoticeDetail from "../../../containers/admin/fas/company/alarm/CustomerNoticeDetail";
import {useLocation} from "react-router-dom";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const AdminFasCompanyNotice = () => {
	const location = useLocation()
	const {pathname} = location
	return (
		<PageContainer>
			<IntegratedSideMenu/>
			{pathname.length === 17?
				<SectionContainer contentContainer={<CustomerNotice/>}/>
				:
				<SectionContainer contentContainer={<CustomerNoticeDetail/>}/>
			}
		</PageContainer>
	);
};

export default AdminFasCompanyNotice;