import React,{useState} from 'react';
import styled from "styled-components";
import SearchBar from "../input/SearchBar";
import {
  GridTableComponent ,
  GridTableSelect ,
  listResultMessage ,
  tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  companySolutionFilter , persistCompanyData , userPersistCompanyData ,
} from "../../common/StateManage";
import {Error_Type} from "../../types/Fundamental";
import {dataValid , initError , loadingRemove} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import arrow from '../../asset/image/chevron-left.svg'
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import LoadingProgressCircle from "../progress/LoadingProgressCircle";
import PaginationList from "../pagination/PaginationList";

interface List_Props {
  receivedData:(data:any)=>void
  onlyList?: boolean
  noFilter?: boolean
  requireCompanyData?: boolean
  filterClick?:()=>void
  affiliate?:boolean
}

const SolutionCompanyFilterList:React.FunctionComponent<List_Props> = ({affiliate,receivedData, onlyList,noFilter, requireCompanyData,filterClick}) => {
  const {t}:any = useTranslation()
  const [listOpen, setListOpen] = useState(true)
  const [selectSolution, setSelectSolution] = useRecoilState<string>(companySolutionFilter)
  const solutionMenu = ['전체','P-BAS','R-BAS','C-BAS','A-BAS','F-EMS']
  // const [page, setPage] = useState<number>(1)
  const [companyDataList, setCompanyDataList] = useState<any>([])
  const [searchState, setSearchState] = useState<{input: string, value: string}>({input:'', value:''})
  const [errorState,setErrorState] = useState<Error_Type>(initError)

  const location = useLocation()
  const authValid = location.pathname.includes('/admin')
  const companyCode = useRecoilValue(authValid ? persistCompanyData : userPersistCompanyData)

  const [selectedCompany, setSelectedCompany] = useState<string>(dataValid(companyCode.companyCode, ''))
  const [loading, setLoading] = useState<boolean>(false)

  const solutionRef = React.useRef<any>(null)
  const [scrollPosition, setScrollPosition] = useState<number>(0)

  const [clickPage, setClickPage] = useState<number>(1)
  const [pageBlock, setPageBlock] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(1)
  React.useEffect(()=>{
    if(selectSolution === 'F-EMS'){
      solutionRef.current?.scrollTo({left:72})
    }
 },[])
  React.useEffect(()=>{
    if(!companyCode.companyCode){
      setSelectedCompany('')
    }
  },[companyCode.companyCode])
  const selectSolutionValue = selectSolution === 'MES' ? '@' : location.pathname.includes('pbas') ? 'P-BAS' : selectSolution;
  const companyListRes = async () => {
    setLoading(true)
    try {
      // listLoadingCircle()/company
      const response =
        requireCompanyData ?
          await client.post( `/company/search`,
                             {page:clickPage, size:10, keyword:'@', type:selectSolutionValue},
                             {headers:{Authorization}})
          :
          await client.get(`/company/total/${affiliate ? '@': selectSolutionValue}`,{
            headers:{Authorization}
          })
      const data = response.data.data
      const valid = response.data
      setTotalPage(data.total_page)
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setCompanyDataList([]);
      }else{
        setErrorState({notice:t('* 검색결과가 없습니다.'), status:valid.status})
        // if(data.total_page > 1 && page > 1){
        //   setCompanyDataList((prev:any)=> prev.concat(data.row))
        // }else{
        //   setCompanyDataList(data.companyAddress)
        // }
        if(requireCompanyData){
          setCompanyDataList(data.row)
        }else{
          setCompanyDataList(data.companyAddress)
        }
      }
      loadingRemove()
    }catch (e:any) {
      console.log(e)
      setErrorState({notice:'* 관리자에게 문의해주세요.',status:e.status})
      loadingRemove()
    }
    finally {
      setLoading(false)
    }
  }

  const companySearchRes = async () => {
    try {
      // listLoadingCircle()
      const response = await client.post(
        requireCompanyData? `/company/search` : `/company/total/search`, {
          page:clickPage,
          size:10,
          keyword:searchState.value,
          type:requireCompanyData ? selectSolution !== 'MES'? selectSolution : '@' : selectSolution === 'MES'? '@' : affiliate ? '@' : selectSolution
        },{headers:{Authorization}})
      const data = response.data.data
      const valid = response.data

      setTotalPage(data.total_page)
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setCompanyDataList([]);
      }else{
        setErrorState({notice:t('* 검색결과가 없습니다.'),status:valid.status})
        // if(data.total_page > 1 && page > 1){
        //   setCompanyDataList((prev:any)=> prev.concat(data.row))
        // }else{
        //   setCompanyDataList(data.companyAddress)
        // }
        if(requireCompanyData){
          setCompanyDataList(data.row)
        }else{
          setCompanyDataList(data.companyAddress)
        }
      }
      loadingRemove()
    }catch (e:any) {
      loadingRemove()
      setErrorState({notice:'* 관리자에게 문의해주세요.',status:e.status})
      console.log(e)
    }
  }

  const labelColor = (text: string) => {
    return (
      text === 'P-BAS'? '#54BEEA' :
        text === 'R-BAS'? '#4AA261' :
          text === 'C-BAS'? '#E79F3C' :
            text === 'A-BAS'? '#6963B0' :
              text === 'F-EMS'? '#CC5642' : '#fff'
    )
  }
  const searchEventFunc = (v:string) => {
    setSelectSolution( ( v === '전체' ) ? '@' : v )
    if( filterClick ){
      filterClick()
    }
    // setPage(1)
  }

  React.useEffect(() => {
                    if (searchState.value === '') {
                      companyListRes().then()
                    } else {
                      companySearchRes().then()
                    }
                  }
    , [searchState.value, selectSolution, pageBlock, clickPage])

  // React.useEffect(()=>{
  //   setSelectedCompany({})
  // },[])

  // const [myArray, setMyArray] = useState([1, 2, 3, 4, 5]);
  // const moveToFront = (i:number)=>{
  //   const selectedItem = myArray.splice(i,1)[0];
  //   setMyArray([selectedItem, ...myArray]);
  // }
  return (
    <div style={{display:'flex', position:'relative'}}>
      <BaseComponent style={{width: listOpen? '408px':'0px', height: (onlyList || noFilter)? '680px' : '712px'}} arrow={arrow}>

        {!onlyList && !noFilter && <React.Fragment>
          <div className={'top-solution-group'} ref={solutionRef}
               onScroll={() => setScrollPosition( solutionRef.current.scrollLeft )}
               style={{scrollBehavior: 'smooth'}}>

            {solutionMenu.map( ( v ) => (
              <TopSolutionButton onClick={() => searchEventFunc( v )}
                                 selected={( selectSolution === '@' ? '전체' : selectSolution ) === v} text={v}
                                 key={v}>{t(v)}</TopSolutionButton>
            ) )}
          </div>
          <div style={{position: 'absolute' , top: 0 , display: 'flex'}}>
            {scrollPosition !== 0 &&
                <div className={'solution-arrow'}
                     onClick={() => {solutionRef.current.scrollTo( 0 , 0 )}}>{''}</div>
            }
            {scrollPosition !== 72 &&
                <div className={'solution-arrow-right'}
                     style={{transform: scrollPosition === 0 ? 'translateX(376px)' : 'translateX(344px)'}}
                     onClick={() => {solutionRef.current.scrollTo( 72 , 0 );}}>{''}</div>}
          </div>
          <div style={{height: '8px' , backgroundColor: labelColor( selectSolution )}}/>
        </React.Fragment>}

        <div style={{boxSizing:'border-box', padding: (onlyList||noFilter) ? '30px 4px 0 32px' : '16px 4px 0 32px'}}>
          <div style={{display:'flex', alignItems:'center',justifyContent:'space-between',width:'372px'}}>
            <div style={{fontSize:'18px', fontWeight:800}}>{t('업체 리스트')}</div>
            <SearchBar margin={'0 20px'} keyFunction={(e)=>setSearchState((prev)=>({...prev, input: e.target.value.trimStart() }))}
                       disabled={loading}
                       clickEvent={()=> {setClickPage(1)
                         setSearchState( ( prev ) => ( {...prev , value: prev.input} ) )}}/>
          </div>

          <GridTableComponent id={'grid-scroll'} height={538} scrollY_Hide={requireCompanyData}
                              tempCol={'128px 200px 96px'} scrollWidth={510}>
            <div className={'grid-title-unchecked'}>
              <div>{t('업체명')}</div>
              <div>{t('주소')}</div>
              <div>{t('업체코드')}</div>
            </div>
            {
              loading?
                <LoadingProgressCircle height={400}/>
                :
               companyDataList?.length === 0?
                listResultMessage(true, errorState.notice,{admin:480, user:480, width:362})
                :
                companyDataList.map((v:any)=>(
                  <GridTableSelect selected={v.companyCode === selectedCompany}
                                   key={v.companyCode}
                                   onClick={()=> {
                                     setSelectedCompany(v.companyCode)
                                     receivedData(v)
                                   }}
                                   className={'grid-list-unchecked-no_icon'}>
                    {validationValue(v.companyName)}
                    {tooltipModule(dataValid(v.address))}
                    {validationValue(v.companyCode)}
                  </GridTableSelect>
                ))
            }
          </GridTableComponent>
          {requireCompanyData && <div style={{marginTop: '8px'}}>
            <PaginationList limit={10} setBlockNum={setPageBlock} blockNum={pageBlock} pageLimit={5}
                            setPage={setClickPage} counts={totalPage} page={clickPage}/>
          </div>}
        </div>
      </BaseComponent>
      {!onlyList && <CloseButton onClick={() => setListOpen( !listOpen )}>
        {listOpen ? '<' : '>'}
      </CloseButton>}
    </div>
  );
};
export default SolutionCompanyFilterList;

const BaseComponent = styled.div<{arrow?:string}>`
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  transition: width 0.2s;
  position: relative;

  .top-solution-group {
    display: flex;
    position: relative;
    width: 408px;
    overflow-x: scroll;
    height: 48px;
    align-items: flex-end;
  }

  .solution-arrow {
    min-width: 32px;
    height: 48px;
    user-select: none;
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    z-index: 50;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);

    &:hover {
      box-shadow: 0 1px 4px #15223214;
      background-color: #fff
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 100%;
      background-image: url(${props => props.arrow});
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
    }
  }

  .solution-arrow-right {
    min-width: 32px;
    height: 48px;
    user-select: none;
    cursor: pointer;
    text-align: center;
    line-height: 48px;
    z-index: 50;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);

    &:hover {
      box-shadow: 0 1px 4px #15223214;
      background-color: #fff
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 48px;
      background-image: url(${props => props.arrow});
      transform: rotateZ(180deg);
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
    }
  }
`

const TopSolutionButton = styled.div<{selected?:boolean, text?: string}>`
  //min-width: 68px;
  min-width: 80px;
  height: ${props => props.selected? 48 : 40}px;
  opacity: ${props => props.selected? 1 : 0.5};
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  color: ${props => props.text === '전체'? '#171725' : '#F5F6FA'};
  background-color: ${
  props => props.text === 'P-BAS'? '#54BEEA' :
    props => props.text === 'R-BAS'? '#4AA261' :
      props => props.text === 'C-BAS'? '#E79F3C' :
        props => props.text === 'A-BAS'? '#6963B0' :
          props => props.text === 'F-EMS'? '#CC5642' : '#fff'
};
  user-select: none;
  cursor: pointer;
  box-shadow: 0 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-sizing: border-box;
  padding-bottom: 11px;
  font-size: 14px;
  transition: height 0.1s;
  &:hover{
    opacity: 1;
  }
`

const CloseButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 24px;
  height: 120px;
  align-self: center;
  background-color: #fff;
  border-radius: 0 6px 6px 0;
  box-shadow: 0 1px 4px #15223214;
  cursor: pointer;
  pointer-events: initial;
  text-align: center;
  user-select: none;
`