import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import DefectTypeManagement from "../../../containers/admin/fas/company/processtype/DefectTypeManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasDefect = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<DefectTypeManagement/>}/>
    </PageContainer>
  );
};

export default FasDefect;