import React from 'react';
import {ContentFrame, SectionFrame} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";

import styled from "styled-components";
import {useParams} from "react-router-dom";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Modal_View_Type} from "../../../../types/Fundamental";
import TotalImageUploadBox from "../../../../components/input/TotalImageUploadBox";
import {useTranslation} from "react-i18next";
import {
	buttonAuthValid ,
	dataAccessLoading , dataValid ,
	initModal ,
	loadingRemove
} from "../../../../function/function";
import MainButtons from "../../../../components/button/MainButtons";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import RegisterInput from "../../../../components/input/RegisterInput";
import ToastPopup from "../../../../components/modal/ToastPopup";
import AttachMultipleUploadBox from "../../../../components/input/AttachMultipleUploadBox";
import {useRecoilValue} from "recoil";
import {selectedListCountValue} from "../../../../common/StateManage";

const UserWikiModify = () => {
	const solutionAuth = `${window.localStorage.getItem('solution')}`.split(',').map((v)=>(v)).concat('MES');

	const location = useParams()

	const { t }:any = useTranslation();
	const [inputState, setInputState] = React.useState<any>({})
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const [imageKeyState, setImageKeyState] = React.useState<string[]>([])
	const [receiveFileData,setReceiveFileData] = React.useState<any>({fileKey:[],fileName:[],fileUrl:[]})
	const [fileKeyState,setFileKeyState] = React.useState<string[]>([]);
	const selectedCount = useRecoilValue(selectedListCountValue)

	const onChangeValue = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
		const {value, name} = e.target

		setInputState({
			...inputState,
			[name]: value
		});
	}


	const wikiDetailRes = async () => {
		try {
			const response = await client.post('/wiki/detail',{
				wikiCode: location.wikiCode,
				count:`${Number(selectedCount)+1}`
			},{headers:{Authorization}})
			const data = response.data.data
			setInputState(data)
			setReceiveFileData({fileKey:data.wikiFile,fileName:data.wikiFileName,fileUrl:data.wikiFileUrl})
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		wikiDetailRes().then()
	},[])
	const wikiModifyRes = async () => {
		if(inputState.wikiTitle === ''){
			setDataModal( {menu: 'confirm' , message: '제목을 입력해 주세요.'} )
		}else if(inputState.wikiType === ''){
			setDataModal( {menu: 'confirm' , message: '내용을 입력해 주세요.'} )
		}else{
			try {
				dataAccessLoading()
				const response = await client.post('/wiki/update',{
					...inputState,
					wikiImage: imageKeyState,
					wikiFile:dataValid(fileKeyState,[]),
					wikiFinish: '0',
				},{headers:{Authorization}})
				const data = response.data
				if(data.status!==200){
					setDataModal({menu:'confirm',message:data.message})
					loadingRemove()
				}else{
					setTimeout(()=>{
						loadingRemove()
						setDataModal({menu:'success',message:'수정 되었습니다.'})
					},500)
				}
			}catch (e:any) {
				console.log(e)
				setDataModal({menu:'confirm',message:e.message})
				loadingRemove()
			}
		}
	}
	console.log(inputState)

	const wikiDeleteRes = async () => {
		try {
			dataAccessLoading()
			const response = await client.post('/wiki/delete',{
				wikiCode: location.wikiCode
			},{headers:{Authorization}})
			const data = response.data
			if(data.status!==200){
				setDataModal({menu:'confirm',message:data.message})
				loadingRemove()
			}else{
				setTimeout(()=>{
					loadingRemove()
					setDataModal({menu:'success',message:'삭제 되었습니다.'})
				},500)
			}
		}catch (e:any) {
			console.log(e)
			setDataModal({menu:'confirm',message:e.message})
			loadingRemove()
		}
	}

	// React.useEffect(() => {
	// 	const preventClose = (e: BeforeUnloadEvent) => {
	// 		e.preventDefault();
	// 		if(dataModal.message?.includes('삭제')) {
	// 			return;
	// 		}else if(dataModal.message?.includes('수정')) {
	// 			return;
	// 		}
	// 		e.returnValue = true;
	// 	};
	// 	window.addEventListener(  "beforeunload" , preventClose );
	// 	return () => {
	// 		window.removeEventListener( "beforeunload" , preventClose );
	// 	};
	// }, [dataModal.message]);

	return (
		<SectionFrame>
			{dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message} clickEvent={() => {setDataModal( initModal )}}/>}

			{dataModal.menu === 'success' ?
				<ToastPopup text={t(dataModal.message)} state={'success'} timeSet={800}
										closeEvent={() => {
											setDataModal(initModal)
											if(dataModal.message === t('삭제 되었습니다.')){
												window.location.href = `/fas/wiki`
											}if(dataModal.message === t('수정 되었습니다.')){
												window.location.href = `/fas/wiki/${inputState.wikiCode}`
											}}}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

			{dataModal.menu === 'select' &&
				<SelectConfirmModal statusText={dataModal.message}
														clickEvent={()=>{wikiDeleteRes().then(); setDataModal(initModal)}}
														noticeText={' * 삭제시 데이터는 복구되지 않습니다.'}
														cancelEvent={()=>{setDataModal(initModal)}}/>
			}

			{dataModal.menu === 'register' &&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
														clickEvent={()=>{wikiModifyRes().then(); setDataModal(initModal)}}
														cancelEvent={()=>{setDataModal(initModal)}}/>}

			{dataModal.menu === 'back' &&
				<SelectConfirmModal statusText={dataModal.message}
														viewType={true}
														noticeText={' * "예" 선택시 저장없이 이동합니다.'}
														clickEvent={()=>{window.location.href=`/fas/wiki/${location.wikiCode}`; setDataModal(initModal)}}
														cancelEvent={()=>{setDataModal(initModal)}}/>}

			<MenuTitle title={'WIKI > 수정'} unitCase={'MenuTitle'}/>
			<ContentFrame flexDirection={'row'}>
				<div>
					<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
						<MenuTitle title={'WIKI 수정'} unitCase={'MenuCompTitle'}/>
						<div style={{display:'flex'}}>
							<MainButtons width={'56px'} name={'취소'}
													 clickEvent={()=>{setDataModal({menu:'back',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
							<MainButtons name={'삭제'} width={'56px'} disabled={buttonAuthValid('delete','2')} clickEvent={()=>setDataModal({menu:'select', message:t('정말 삭제 하시겠습니까?')})}/>
							<MainButtons buttonType={'cta'} width={'56px'} name={'저장'} marginDis={true} disabled={buttonAuthValid('update','2')}
													 clickEvent={()=>{setDataModal({menu:'register',message:'이대로 수정하시겠습니까?'})}}/>
						</div>
					</div>
					<ViewComponent>
						<div className={'scroll_func'}>
							<RegisterComponent type={'drop'} title={'솔루션'} dropWidth={'192px'}
																 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, solution: v}))}
																 name={'solution'} value={inputState.solution} onChange={onChangeValue} dropAddNoneSelect={true}
																 dropArray={solutionAuth}/>
							{/*유저 위키 수정 화면에서, wikiType은 항상 '문의'로 고정 */}
							{/*<div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>*/}
							{/*	<RegisterComponent type={'drop'} title={'솔루션'} dropWidth={'192px'}*/}
							{/*										 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, solution: v}))}*/}
							{/*										 name={'solution'} value={inputState.solution} onChange={onChangeValue} dropAddNoneSelect={true}*/}
							{/*										 dropArray={solutionAuth}/>*/}
							{/*	<RegisterComponent type={'drop'} title={'유형'} dropWidth={'192px'} required={true}*/}
							{/*										 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, wikiType: v}))}*/}
							{/*										 name={'wikiType'} value={inputState.wikiType} onChange={onChangeValue} dropAddNoneSelect={true}*/}
							{/*										 dropArray={['에러','요청','문의']}/>*/}
							{/*</div>*/}

							<RegisterComponent type={'text'} value={inputState.wikiTitle}
																 required={true} title={t('제목')} placeholder={t('제목을 입력해 주세요.')}
																 onChange={(e)=>{onChangeValue(e)}} name={'wikiTitle'}/>


							<AttachMultipleUploadBox fileKeyArray={(data)=>{setFileKeyState(data)}}
																			 receiveKey={dataValid(receiveFileData.fileKey,[])}
																			 receiveUrl={dataValid(receiveFileData.fileUrl,[])}
																			 receiveFileName={dataValid(receiveFileData.fileName,[])}
																			 placeholder={'* 업로드 버튼을 클릭하여 파일을 등록 해주세요.'}/>

							<div style={{display:'grid',gridTemplateColumns:'168px 1fr',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>
								<div className={'info-title'} style={{height: '364px'}}>
									{t('내용')}
									<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>
								</div>
								<div className={'info-content'} style={{height: '364px'}}>
									<RegisterInput type={'textarea'} name={'wikiQuestion'} value={inputState.wikiQuestion}
																 maxHeight={'348px'} limit={10000}
																 onChange={onChangeValue} placeholder={t('내용을 입력해주세요.')}/>
								</div>
							</div>
						</div>

					</ViewComponent>
				</div>
				<div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>
				<div style={{width:'369px'}}>
					<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
						<MenuTitle title={'이미지 수정'} unitCase={'MenuCompTitle'}/>
						<div style={{color:'#7E84A3', fontSize:'12px',textAlign:'right'}}>
							<div>{t('*최대 4개의 이미지 등록 가능')}</div>
							<div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
						</div>
					</div>

					<TotalImageUploadBox receiveKey={inputState?.wikiImage} profileSelect={false} receiveUrl={inputState?.wikiImageUrl}
						                     imageKeyArray={(data)=>setImageKeyState(data)}/>
				</div>
			</ContentFrame>
		</SectionFrame>
	);
};

export default UserWikiModify;


const ViewComponent = styled.div`
	width: 784px;
	height: 584px;

	.scroll_func{
		width: 804px;
		height: 568px;
		overflow-x: hidden;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 20px;
			display: block;
		}
	}
	.info, .reg {
		display: grid;
		box-sizing: border-box;

		&-title {
			padding: 16px;
			width: 168px;
			box-sizing: border-box;
			display: flex;
			//justify-content: space-between;
			align-items: center;
			height: 48px;
			background-color: #F5F6FA;
			color: #131523;
			font-size: 12px;
			font-weight: 800;
			border-bottom: 1px solid rgba(213, 215, 227, 0.5);

		}

		&-content {
			display: flex;
			padding-left: 16px;
			min-width: 224px;
			box-sizing: border-box;
			font-size: 14px;
			height: 48px;
			border-bottom: 1px solid rgba(213, 215, 227, 0.5);
			align-items: center;
		}
	}

`