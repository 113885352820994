import React,{ useState } from 'react';
import styled from "styled-components";
import SolutionIcon from "../icon/SolutionIcon";
import {Modal_View_Type , SystemLinkType} from "../../types/Fundamental";
import ConfirmPopup from "../modal/ConfirmPopup";
import {initModal , solutionTitleFunc} from "../../function/function";
import SolutionRequestModal from "../modal/SolutionRequestModal";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import SelectConfirmModal from "../modal/SelectConfirmModal";
import MainButtons from "../button/MainButtons";
import dayjs from "dayjs";

const SystemLink:React.FunctionComponent<SystemLinkType> = ({iconType,iconTitle, statement}) => {

	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
	const solutionAuth = `${window.localStorage.getItem('solution')}`.split(',').map((v)=>(v));

	const solutionUpdateRes = async (solutionNo?: string) => {
		try {
			const response = await client.post('/solution/update',{
				notice:'고객 요청으로 취소',
				representative: window.localStorage.getItem('company'),
				solutionNo: solutionNo ?? '',
				statement: 'cancel',
				finishDate: dayjs().format('YYYY.MM.DD')
			},{headers:{Authorization}})
			console.log(response.data)
			window.location.reload()
		}catch (e) {
			console.log(e)
		}
	}

	const solutionStatementValid = (statement?: string) => {
		if(!solutionAuth.includes(iconType)){
			if(statement === ''){
				setDataModal({menu:'register'})
			}
			if(statement === 'wait'){
				setDataModal({menu:'select', message:'요청 대기중 입니다. 취소 하시겠습니까?'})
			}
			if(statement === 'run'){
				setDataModal({menu:'confirm', message:'솔루션 진행중 입니다.'})
			}
		}else{
			if(solutionAuth.includes(iconType) && statement === ''){
				setDataModal({menu:'register'})
			}
			if(solutionAuth.includes(iconType) && statement === 'wait'){
				setDataModal({menu:'select', message:'해지 대기중 입니다. 취소 하시겠습니까?'})
			}
			if(solutionAuth.includes(iconType) && statement === 'run'){
				setDataModal({menu:'confirm', message:'솔루션 해지 진행중 입니다.'})
			}
		}
	}

	return (
		<BaseComponent>
			{dataModal.menu === 'register' &&
					<SolutionRequestModal solution={iconType} type={solutionAuth.includes(iconType)} confirmEvent={()=> setTimeout(()=>window.location.reload(),1000)}
					                      cancelEvent={()=>setDataModal(initModal)}/>}
			{dataModal.menu === 'select' &&
					<SelectConfirmModal statusText={dataModal?.message}
					                    clickEvent={()=>solutionUpdateRes(statement?.solutionNo).then()}
					                    cancelEvent={()=>setDataModal(initModal)}/>}
			{dataModal.menu === 'confirm' &&
					<ConfirmPopup statusText={dataModal?.message}
					              noticeText={dataModal?.message === '구독 해제 기능 준비중 입니다.' && '(032)505-8055 문의 바랍니다.'}
					              clickEvent={()=>setDataModal(initModal)}/>}

			<div style={{display:'flex', alignItems:'center'}}>
                <SolutionIcon iconType={1} title={iconType} circleTitle={iconTitle}
                              iconColor={solutionAuth.includes(iconType)}/>
				<div style={{marginLeft:'14px', alignSelf:'center'}}>
					<div style={{fontSize:'14px', color:'#171725', fontWeight:700, marginBottom:'3px'}}>
						{iconType}
					</div>
					<div style={{fontSize:'12px', color:'#7E84A3', marginTop:'3px'}}>
						{solutionTitleFunc('solution', iconType)}
					</div>
				</div>
			</div>
			<MainButtons buttonType={'solution'} selected={solutionAuth.includes(iconType)} width={'80px'}
				name={(solutionAuth.includes(iconType) && statement?.statement === '') ? '구독해제' :
				(solutionAuth.includes(iconType) && statement?.statement === 'wait')? '해지 대기 중' :
					(solutionAuth.includes(iconType) && statement?.statement === 'run')? '해지 요청 중' :
						statement?.statement === ''? '구독하기' : statement?.statement === 'wait'? '요청 중' :
							statement?.statement === 'run'? '진행 중' : '구독'}
				           clickEvent={()=>solutionStatementValid(statement?.statement)}
			/>
		</BaseComponent>
	);
};

export default SystemLink;

	const BaseComponent = styled.div`
		display: flex;
		justify-content: space-between;
		height: 70px;
		user-select: none;
	`