import React , {useEffect , useState} from 'react';
import styled from 'styled-components'
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {
  ContentFrame ,
  GridTable , multipleImageGridStyle ,
  SectionFrame , tooltipModule , validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  authValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {Modal_View_Type , ProductListType} from "../../../../../types/Fundamental";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useParams} from "react-router-dom";
import MesSearchTypeModal from "../../../../../components/modal/MesSearchTypeModal";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useTranslation} from "react-i18next";
import InspectionFormModal from "../../../../../components/modal/InspectionFormModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import FacilityRegisterModal from "../../../../../components/modal/FacilityRegisterModal";
import PreviewImage from "../../../../../components/display/PreviewImage";
import noImage from "../../../../../asset/image/noImage.svg";
import BomViewerModal from "../../../../../components/modal/fas/product/BomViewerModal";

const InspectionItemRegister = () => {
  const selectCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const titleArray = ['거래처' , '모델' , 'CODE' , '품명' , '품목 종류' , '단위' , 'BOM' , '생산공정' , '설비' , '기준 UPH' ,
    '작업 표준서' , '제품 이미지'];
  const [rowList , setRowList] = React.useState<any[]>( [{id: 1 , inspectionItems: '' , inspectionCriteria: ''}] );
  const [idIndex , setIdIndex] = React.useState<number>( rowList.length + 1 )
  const [selectDrop , setSelectDrop] = React.useState<number>( 1 );
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal );
  const [productData , setProductData] = useState<ProductListType>( {customer: []} )
  const [isOpenProductInfo , setIsOpenProductInfo] = useState<boolean>( false );
  const [deleteIndex , setDeleteIndex] = React.useState<number[]>( [] )
  // const [detailDataState, setDetailDataState] = useState<ProductListType>({customer:[]})
  const {t}: any = useTranslation()
  const params = useParams()
  const {productCode} = params
  // const [inspectionData,setInspectionData]  = React.useState<QualityInspectionItem>();
  // const formData = inspectionData?.inspectionItems?.map((v:any,index:number)=>(Object.assign(inspectionData?.inspectionCriteria?.map((data:any)=>(Object.assign({}, {inspectionCriteria:data})))[index],{inspectionItems:v})))
  const addListRow = () => {
    if( rowList.length > 31 ){
      setDataModal( {menu: 'warn' , message: '검사항목을 더이상 추가할수 없습니다.'} )
    } else{
      setIdIndex( idIndex + 1 )
      const rowInput = {
        id: idIndex ,
        inspectionItems: '' , // 검사항목명
        inspectionCriteria: '' , //검사 기준
      }
      setRowList( [...rowList , rowInput] )
    }
  }
  const removeList = ( value: number ) => {
    setRowList( rowList.filter( ( v ) => v !== value ) )
  }
  const onChangeArray = ( index: number , e: React.ChangeEvent<HTMLInputElement> ) => {
    const {value , name} = e.target
    const rowsInput = [...rowList]
    // @ts-ignore
    rowsInput[index][name] = value
    setRowList( rowsInput )
  }
  const productDetailRes = async () => {
    try {
      const response = await client.post( `/product/detail` , {
        productCode: productCode
      } , {headers: {Authorization}} )
      setProductData( response.data.data )
    } catch ( e ) {
      console.log( e )
    }
  }
  const inspectionItemDataRes = async () => {
    try {
      const response = await client.post( `/quality/test/read` , {
        productCode: productCode
      } , {headers: {Authorization}} )
      const data = response.data.data;
      if( data === null ){
        return
      } else{
        setSelectDrop( Number( data?.inspectionSample ?? 1 ) )
        setRowList( data.inspectionItems.map( ( v: string , index: number ) => (
          {
            inspectionItems: v ,
            inspectionCriteria: data?.inspectionCriteria[index] ,
            id: index + 1 ,
            pk: data?.qualityTestCode
          }
        ) ) )
      }
      console.log()
    } catch ( e ) {
      console.log( e )
    }
  }
  console.log( deleteIndex )
  React.useEffect( () => {
    productDetailRes().then()
    inspectionItemDataRes().then()
  } , [] )
  const inspectionItemRegRes = async () => {
    if( rowList.map( ( array ) => ( array.inspectionItems.length === 0 ) ).find( ( v ) => v ) !== undefined ){
      setDataModal( {menu: 'warn' , message: '항목명을 입력하세요.'} )
    } else if( rowList.map( ( array ) => ( array.inspectionCriteria.length === 0 ) ).find( ( v ) => v ) !== undefined ){
      setDataModal( {menu: 'warn' , message: '검사 기준명을 입력하세요.'} )
    } else{
      try {
        dataAccessLoading()
        const res = await client.post( `quality/test/register/` , {
          companyCode: selectCompany.companyCode ,
          productCode: productCode ,
          inspectionSample: selectDrop.toString() , //시료수
          inspectionItems: rowList.map( ( v ) => ( v.inspectionItems ) ) , //항목형
          inspectionCriteria: rowList.map( ( v ) => ( v.inspectionCriteria ) ) , //기준
          deleteIndex: dataValid( deleteIndex , [] )
        } , {headers: {Authorization}} )
        const data = res.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'warn' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( [] );
            removeList( 500 )
            setDataModal( {menu: 'success' , message: '항목이 저장 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        loadingRemove()
        setDataModal( {menu: 'warn' , message: e.message} )
        removeList( 500 )
      }
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  const customerCount = ( customer: any ) => customer.filter( ( v: any , i: number , arr: any ) => arr.findIndex( ( el: any ) => ( el.customerCode === v.customerCode ) ) === i )
  // const customerCount = (customer:any) => customer?.filter((v:any,i:number,arr:any)=> arr.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)
  // console.log(customerCount(productData?.customer)?.map((v:any)=>(v?.customerName)).join(','),0,validationValue(customerCount(productData?.customer)[0]?.customerName + ((customerCount(productData?.customer)?.length -1) !== 0 ?` 외 ${customerCount(productData?.customer)?.length-1}` : '')))
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {
          if( dataModal.message?.includes( '저장' ) ){
            window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`
          }
          setDataModal( initModal )
        }}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={() => {setDataModal( initModal )}}/>}


      {dataModal.menu === 'bom' &&
          <BomViewerModal bomDetailData={productData} closeEvent={()=>setDataModal(initModal)}/>
      }

      {dataModal.menu === 'facility' &&
          <FacilityRegisterModal companyCode={selectCompany.companyCode} viewType={dataModal.view}
                                 selectedFacility={productData?.facilityCode}
                                 receivedFacility={( v ) => {
                                   Object.assign( productData , {facilityCode: v} )
                                   // Object.assign(productData, {facilityCode: v})
                                 }}
                                 selectProduct={productData}
                                 cancelEvent={() => setDataModal( initModal )}
          />
      }

      {dataModal.menu === 'preview' &&
          <InspectionFormModal productData={productData} getData={rowList} previewType={true}
                               imageUrlList={productData?.imageUrl}
                               cancelEvent={() => {setDataModal( initModal )}} isOpenedProps={isOpenProductInfo}
                               openEvent={() => {setIsOpenProductInfo( !isOpenProductInfo )}}
                               inspectionLength={rowList?.length <= 8 ? 8 : rowList?.length}
                               sampleIdx={selectDrop}/>
      }


      {dataModal.menu === 'workStandard' &&
        //@ts-ignore
          <ImageDetailPreview imageUrl={productData?.workStandardImgUrl} profile={productData?.workStandardImgUrl[0]}
                              clickEvent={() => setDataModal( initModal )}/>}
      {dataModal.menu === 'image' &&
        //@ts-ignore
          <ImageDetailPreview imageUrl={productData?.imageUrl} profile={productData?.imageUrl[0]}
                              clickEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={"* '예' 선택시, 모든 초중종 양식의 항목이 변경됩니다."}
                              viewType={true}
                              clickEvent={inspectionItemRegRes}
                              cancelEvent={() => {setDataModal( initModal )}}
          />
      }

      {dataModal.menu === 'mold' &&
          <MesSearchTypeModal viewType={true}
                              closeButton={() => setDataModal( initModal )}
                              category={dataModal.menu}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`}}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      <SectionFrame>
        <MenuTitle
          title={( authValid() ? '업체/' : '' ) + `기본정보 관리 > 품목 기본정보 > ${rowList[0]?.pk ? '초·중·종 검사항목 수정' : '초·중·종 검사항목 등록'}`}
          unitCase={'MenuTitle'}/>

        <ContentFrame height={'208px'} padding={'30px 30px 16px 30px'}>
          <div style={{display: 'flex' , justifyContent: 'space-between'}}>
            <MenuTitle title={'품목 정보'} unitCase={'MenuCompTitle'}/>
            <MainButtons name={'목록'} width={'80px'} marginDis={true}
                         clickEvent={() => window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`}/>
          </div>
          <GridTable tempCol={'112px 90px 120px 90px 80px 56px 80px 100px 80px 80px 120px 120px'}
                     scrollActiveY={'hidden'} scrollActiveX={'scroll'}>
            <div className={'table_title'} style={{width: '1400px'}}>
              {titleArray.map( ( v ) => (
                <div>{t( v )}</div>
              ) )}
            </div>
            <div className={'table_list'} style={{width: '1400px'}}>
              {/*@ts-ignore*/}
              {/*{tooltipModule(productData?.customer[0]?.customerModelName + ((productData?.customer.length -1) !== 0 ?` 외 ${productData?.customer?.length-1}` : ''))}*/}
              {/*{validationValue('')}*/}

              {tooltipModule( customerCount( productData?.customer ).map( ( v: any ) => ( v?.customerName ) ).join( ',' ) , 0 , validationValue( customerCount( productData?.customer )[0]?.customerName + ( ( customerCount( productData?.customer )?.length - 1 ) !== 0 ? ` 외 ${customerCount( productData?.customer )?.length - 1}` : '' ) ) )}
              {/*@ts-ignore*/}
              {tooltipModule( productData?.customer?.map( ( v: any ) => ( v?.customerModelName ) ).join( ',' ) , 0 , validationValue( productData?.customer[0]?.customerModelName + ( ( productData?.customer?.length - 1 ) !== 0 ? ` 외 ${productData?.customer?.length - 1}` : '' ) ) )}

              {tooltipModule( dataValid( productData?.serialCode ) , 12 )}
              {tooltipModule( dataValid( productData?.productName ) )}
              {validationValue( productData?.type )}
              {validationValue( productData?.unit , 'ea' )}
              <ListInnerInput type={'clickText'} inputData={t( 'BOM 보기' )}
                              clickButton={() => setDataModal( {menu: 'bom' , message: ''} )}/>
              {validationValue( productData?.processName )}
              <ListInnerInput type={'clickText'} inputData={t( '설비 보기' )}
                              clickButton={() => setDataModal( {menu: 'facility' , view: true} )}/>
              {validationValue( productData?.uph )}

              {productData?.workStandardImgUrl?.length === 0 ?
                <div>{'-'}</div>
                :
                <ListInnerInput type={'clickText'} inputData={t( '작업표준서 보기' )}
                                clickButton={( e ) => {
                                  e.stopPropagation()
                                  setDataModal( {menu: 'workStandard' , view: true} )
                                }}/>}

              {productData?.imageUrl?.length === 0 ?
                <div>{'-'}</div>
                :
                <ListInnerInput type={'clickText'} inputData={'이미지 보기'}
                                clickButton={() => {
                                  setDataModal( {menu: 'image' , view: true} )
                                }}/>}
            </div>
            {/*<NoData height={54}>{' * 품목 정보가 들어가야하는 곳입니다. 품목상세보기 API 바인딩 필요'}</NoData>*/}
          </GridTable>
        </ContentFrame>
        <ContentFrame height={'456px'} margin={'16px 0 0 0'} flexDirection={'row'}>
          <ViewType>
            <div style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between'}}>
              <MenuTitle title={rowList[0]?.pk ? '초·중·종 검사항목 수정' : '초·중·종 검사항목 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'양식 보기'} clickEvent={() => {setDataModal( {menu: 'preview'} )}}/>
                <MainButtons name={'취소'} width={'80px'} clickEvent={() => {
                  setDataModal( {
                                  menu: 'cancel' ,
                                  message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                } )
                }}/>
                <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                             clickEvent={() => {
                               if( rowList[0]?.pk ){
                                 setDataModal( {menu: 'modify' , message: '이대로 수정하시겠습니까?'} )
                               } else{
                                 inspectionItemRegRes().then()
                               }
                             }} marginDis={true}/>
              </div>
            </div>
            <Information style={{margin: '16px 0'}}>
              <div className={'info'} style={{
                display: 'grid' ,
                gridTemplateColumns: '168px 240px 168px 240px' ,
                borderBottom: '1px solid rgba(213, 215, 227, 0.5)'
              }}>
                <div className={'reg-title'}>
                  {t( '시료 개수' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'reg-content'} style={{display: 'flex'}}>
                  <ListInnerInput type={'drop'} dropMenuList={[1 , 2 , 3 , 4 , 5]} width={'232px'}
                                  inputData={selectDrop} selectDrop={( v ) => {setSelectDrop( v )}}/>
                </div>
                <div className={'reg-title'}>{'-'}</div>
                <div className={'reg-content'} style={{display: 'flex'}}>
                  <div>{''}</div>
                </div>
              </div>
            </Information>

            <MenuTitle title={'검사 항목'} unitCase={'MenuCompTitle'}/>
            <div className={'scroll_func'} style={{borderTop: '1px solid rgba(213, 215, 227, 0.5)'}}>
              {rowList.map( ( v , index ) => (
                <Information>
                  <div className={'info'} style={{display: 'grid' , gridTemplateColumns: '104px 712px'}}>
                    <div className={'reg-title'} style={{width: '104px'}}>
                      {t( '검사0' ) + ( index + 1 )}
                      <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                    </div>
                    <div style={{display: 'flex' , alignItems: 'center' , borderBottom: '1px solid #E6E9F4'}}>
                      <div style={{display: 'flex' , alignItems: 'center' , paddingLeft: '24px'}}>
                        <div style={{fontSize: '12px' , fontWeight: 'bold' , marginRight: '16px'}}>{t( '항목명' )}</div>
                        <ListInnerInput type={'text'} width={'224px'} name={'inspectionItems'}
                                        inputData={v.inspectionItems} limit={24}
                                        onChange={( e ) => ( onChangeArray( index , e ) )}/>
                      </div>
                      <div style={{display: 'flex' , alignItems: 'center' , paddingLeft: '36px'}}>
                        <div style={{fontSize: '12px' , fontWeight: 'bold' , marginRight: '16px'}}>{t( '검사기준' )}</div>
                        <ListInnerInput type={'text'} width={'224px'} name={'inspectionCriteria'}
                                        inputData={v.inspectionCriteria} limit={24}
                                        onChange={( e ) => ( onChangeArray( index , e ) )}/>
                      </div>
                      {index === 0 ?
                        <MainButtons name={'추가'} width={'56px'} clickEvent={addListRow} margin={'0 0 0 16px'}/>
                        :
                        <div style={{cursor: 'pointer' , fontWeight: 900 , minWidth: '54px' , marginLeft: '16px'}}
                             onClick={() => {
                               if( rowList[0]?.pk ){
                                 setDeleteIndex( prev => [...prev , index] )
                               }
                               removeList( v )
                             }}>{"X"}</div>
                      }
                    </div>
                  </div>
                </Information>
              ) )
              }
            </div>
          </ViewType>
          <div style={{width: '1px' , height: '348px' , backgroundColor: '#E6E9F4' , margin: '48px 24px 0 24px'}}/>
          <div style={{width: '345px' , height: '34px' , alignItems: 'center'}}>
            <MenuTitle title={'품목 이미지'} unitCase={'MenuCompTitle'}/>
            <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
              {productData?.imageUrl?.length !== 0 ?
                productData?.imageUrl?.map( ( item , index ) => (
                  <PreviewImage imageUrl={item} key={index} noneDelete={true}
                                clickEvent={() => setDataModal( {menu: 'image' , index: index} )}/>
                ) )
                :
                <PreviewImage imageUrl={noImage} noneDelete={true}/>
              }
            </div>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default InspectionItemRegister;
const ViewType = styled.div`
  height: 570px;
  width: 816px;

  .scroll_func {
    height: 250px;
    width: 816px;
    overflow: overlay;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 15px;
      display: block;
    }
  }
`
const Information = styled.div`
  display: grid;
  box-sizing: border-box;
  width: 816px;


  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  & > div:first-child {
    //border-top: 1px solid rgba(213, 215, 227, 0.5);
    width: 816px;
  }

  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;

    }

    &-content {

      display: flex;
      padding: 0 4px 0 8px;
      min-width: 240px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      align-items: center;

    }

    &-content-reg {
      width: 600px;
      height: 80px;
    }
  }
`