import React from 'react';
import ModalBase from "./ModalBase";
import {orderComponent , tooltipModule} from "../../style/StyleComponent";
import ListInnerInput from "../input/ListInnerInput";
import MainButtons from "../button/MainButtons";
import styled from "styled-components";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {authValid , dataValid} from "../../function/function";
import arrow from "../../asset/image/chevron-left.svg";
import noImage2 from "../../asset/image/noImage.svg";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";


interface InterfaceProps {
  productData:any
  sampleIdx:number
  getData:any[]
  cancelEvent:()=>void
  previewType?:boolean
  isOpenedProps?:boolean
  openEvent?:()=>void
  imageUrlList?:string[]
  inspectionLength:number
}

const InspectionFormModal = ({productData = {imageUrl:[]},sampleIdx,getData,cancelEvent,previewType,isOpenedProps,openEvent,imageUrlList = [],inspectionLength}:InterfaceProps) => {
  const {t}:any = useTranslation()
  const [columnLength,setColumnLength] = React.useState<number>(inspectionLength ?? 8);
  const inspectionCount = Array.from({length: sampleIdx}, (v, i) => i + 1);
  const countMinus = Array.from({length:5-sampleIdx},(v,i)=> '-');
  // @ts-ignore
  const noneInput = Array.from({length:columnLength -getData?.length},(v,i) => '-');
  const noneInspection = Array.from({length:columnLength},(v,i) => '-');


  const matrixFunction = (m:any, n:any, initial:any) => {
    let a, i, j, mat = [];
    for (i = 0; i < m; i += 1) {
      a = [];
      for (j = 0; j < n; j += 1) {
        a[j] = initial
      }
      mat[i] = a;
    }
    return mat;
  };
  const filterFirst:any = matrixFunction(5,columnLength,'-').map(data => ([...data , 'NotSelect']))
  const filterMiddle:any = matrixFunction(5,columnLength,'-').map(data => ([...data ,'NotSelect']))
  const filterLast:any = matrixFunction(5,columnLength,'-').map(data => ([...data ,'NotSelect']))

  const [inspectionDataFirst,setInspectionDataFirst]  = React.useState<any>(filterFirst);
  const [inspectionDataMiddle,setInspectionDataMiddle]  = React.useState<any>(filterMiddle);
  const [inspectionDataLast,setInspectionDataLast]  = React.useState<any>(filterLast);

  const [selectFirstDropValue,setSelectFirstDropValue] = React.useState<string>('');
  const [selectMiddleDropValue,setSelectMiddleDropValue] = React.useState<string>('');
  const [selectLastDropValue,setSelectLastDropValue] = React.useState<string>('');



  const [selectIndexFirst,setSelectIndexFirst] = React.useState<number>(0)
  const [selectIndexMiddle,setSelectIndexMiddle] = React.useState<number>(0)
  const [selectIndexLast,setSelectIndexLast] = React.useState<number>(0)
  //@ts-ignore
  const [selectedImgUrl,setSelectedImgUrl] = React.useState<string>(imageUrlList[0] ?? '')


  const onChangeArray = (parentIndex:number,index:number,e:React.ChangeEvent<HTMLInputElement>,state:number[], setState:Function,) => {
    const {value} = e.target
    const rowsInput = [...state]
    // @ts-ignore
    rowsInput[parentIndex][index] = value
    setState(rowsInput)
  }
  const onChangeResult = (parentIndex:number,e:React.ChangeEvent<HTMLInputElement>,state:any,setState:Function) =>{
    const {value} = e.target
    const rowsInput = [...state]
    // @ts-ignore
    rowsInput[parentIndex] = value
    setState(rowsInput)
  }



  const RegisterArray = [
    {id:'초품',writeDate:dayjs().format('YYYY.MM.DD'),writeTime:'00:00:00',writer:''},
    {id:'중품',writeDate:dayjs().format('YYYY.MM.DD'),writeTime:'00:00:00',writer:''},
    {id:'종품',writeDate:dayjs().format('YYYY.MM.DD'),writeTime:'00:00:00',writer:''},
  ]
  const [inspectionInfo,setInspectionInfo] = React.useState<any[]>(RegisterArray)

  const onUpdateArray = (index: number, e:React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target
    const rowsInput = [...inspectionInfo]
    // @ts-ignore
    rowsInput[index][name] = value
    setInspectionInfo(rowsInput)
  }

  return (
    <ModalBase modalContent={
      <BaseComponent>
        <div  className={'modal'} style={{backgroundColor:authValid()  ? '#CFFA61' : '#0058ff',color: authValid()  ? '#171725' : '#fff'}}>
          <div>{t('초·중·종 검사항목 양식')}</div>
          {previewType &&
              <div className={'modal_sub_title'}>{t('(미리보기 화면입니다.)')}</div>
          }
        </div>
        <div style={{display:'flex',justifyContent:'space-between',padding:'8px 16px',boxSizing:'border-box',alignItems:'center'}}>
          <div style={{fontSize:'14px',display:'flex',visibility:!isOpenedProps ? 'visible':'hidden'}}>
            <div style={{fontWeight:'bold'}}>{'제품 정보'}</div>
            <div style={{color:'#0058FF',marginLeft:'16px'}}>{'(' + productData?.serialCode + ' / ' + productData?.productName + ')'}</div>
          </div>

          <MainButtons name={!isOpenedProps ? '숨기기' : '펼치기'} icon={arrow}
                       clickEvent={openEvent}
                       withIcon={true} iconPositionBack={true} marginDis={true}
                       iconStyle={{transform:`rotateZ(270deg) rotateY(${!isOpenedProps ? 180 : 0}deg)`}}/>
        </div>
        {!isOpenedProps ?
          <div style={{display:'flex',height:!isOpenedProps ? '288px' : '0'}}>
            <MoreImageInfo infoOpen={!isOpenedProps}>
              <TransformWrapper>
                <div style={{borderRadius:'8px',border:'1px solid #D7DBEC',height:!isOpenedProps ? '272px' : '0'}}>
                  <TransformComponent>
                    <img src={selectedImgUrl === '' ? noImage2 : selectedImgUrl} width={742} height={272}
                         style={{objectFit:'contain',width:'704px',transition:'all 300ms',}}/>
                  </TransformComponent>
                </div>
              </TransformWrapper>
            </MoreImageInfo>
            <div>
              <TableOpenOptions openProps={!isOpenedProps}>
                {orderComponent(t('지시 고유번호'), productData?.uniqueWorkOrderNumber)}
                {orderComponent('CODE', productData?.serialCode)}
                {orderComponent(t('품명'), productData?.productName)}
                {orderComponent(t('품목 종류'), productData?.type)}
                {orderComponent(t('작업자'), productData?.worker)}
                {orderComponent(t('생산공정'), productData?.processName)}
                {orderComponent(t('설비명'), productData?.facilityNameList?.map( ( v: any ) => ( v ) ).join( ',' ))}
              </TableOpenOptions>
              <div style={{display:'flex', gap:'16px',marginTop:'4px',cursor:'pointer'}}>
                {imageUrlList?.length === 0 ?
                  <img src={noImage2} width={244} height={140}
                       style={{borderRadius:'8px',border:'1px solid #D7DBEC'}}/>
                  :
                  imageUrlList?.map((v:string,i:number)=>(
                  <img src={v} width={179} height={140} onClick={()=>{setSelectedImgUrl(v)}}
                       style={{borderRadius:'8px',border:`1px solid ${selectedImgUrl === v ?  '#0058FF' : '#D7DBEC'}`}}/>
                  ))
                }
              </div>

            </div>

          </div>
          :
          <TableDefaultOption>
            {orderComponent(t('지시 고유번호'), productData?.uniqueWorkOrderNumber)}
            {orderComponent('CODE', productData?.serialCode)}
            {orderComponent(t('품명'), productData?.productName)}
            {orderComponent(t('품목 종류'), productData?.type)}
            {orderComponent(t('작업자'), productData?.worker)}
            {orderComponent(t('생산공정'), productData?.processName)}
            {orderComponent(t('설비명'), productData?.facilityNameList?.map( ( v: any ) => ( v ) ).join( ',' ))}
          </TableDefaultOption>
        }


        <div className={'ins_title'}>{t('초·중·종 검사 결과 양식')}</div>

        <InspectionBox isOpen={!isOpenedProps}>
          {/*초품 리스트*/}
          <div style={{display:'flex'}}>
            <div className={'ins_title_box'}>
              <div style={{fontSize:'12px',fontWeight:900}}>{t('초품')}</div>
              <ListInnerInput type={'calendar'} name={'writeDate'} inputData={inspectionInfo[0].writeDate}
                              onChange={(e)=>Object.assign(inspectionInfo[0], {writeDate : dayjs(e).format('YYYY.MM.DD')})}/>
              <ListInnerInput type={'timer'} placeholder={'00:00:00'} inputData={inspectionInfo[0].writeTime}
                              onChange={(e)=>(onUpdateArray(0,e))} name={'writeTime'}/>
              <ListInnerInput type={'text'} placeholder={t('작성자')} inputData={inspectionInfo[0].writer}
                              onChange={(e)=>(onUpdateArray(0,e))} name={'writer'}/>
            </div>

            <div style={{display:'flex',flexDirection:'column'}}>
              <div style={{display:'flex'}}>
                <div style={{display:'flex',flexDirection:'column'}} >
                  <div className={'ins_title_sample'}>{t('시료')}</div>
                  <div className={'ins_title_sample'}>{t('기준')}</div>
                </div>
                {getData?.map((v)=>(
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <div className={'ins_title_criteria'}>{!v.inspectionItems?  '-' : v.inspectionItems}</div>
                    <div className={'ins_title_criteria'}>{!v.inspectionCriteria?'-' : v.inspectionCriteria}</div>
                  </div>
                ))}

                <div style={{display:'flex'}}>
                  {noneInput?.map((v)=>(
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div className={'ins_title_criteria'}>{v}</div>
                      <div className={'ins_title_criteria'}>{v}</div>
                    </div>
                  ))}
                </div>
                <ResultBox>{t('결과')}</ResultBox>
              </div>
              <div>
                {inspectionCount?.map((v,index)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>{v}</div>
                    {inspectionDataFirst[index].slice(0,getData.length)?.map((value:any,idx:number)=>(
                      <div className={'ins_title_input'}>
                        <ListInnerInput type={'number'} inputData={value} width={'96px'}
                                        height={'24px'} placeholder={'0'} limit={5}
                                        onChange={(e)=>(onChangeArray(index,idx,e,inspectionDataFirst,setInspectionDataFirst))}/>
                      </div>
                    ))}
                    {inspectionDataFirst[index].slice(0,columnLength - getData.length)?.map(()=>(
                      <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>{'-'}</div>
                    ))}
                    <div className={'ins_title_result'}>
                      <ListInnerInput type={'drop'} disabled={true} dropMenuList={['합격','불합격']} onChange={(e)=>onChangeResult(selectIndexFirst,e,inspectionDataFirst,setInspectionDataFirst)}
                                      selectDrop={(value)=> {
                                        setSelectFirstDropValue(value)
                                        setSelectIndexFirst(index)
                                        Object.assign(inspectionDataFirst[index], {columnLength:value})
                                      }}
                                      inputData={selectFirstDropValue}/>
                    </div>
                  </div>
                ))}
              </div>
              {/*시료수를 5이상 안했을때 -로 대체*/}
              <div style={{display:'flex',flexDirection:'column'}}>
                {countMinus.map((v)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>
                      {v}
                    </div>
                    <div style={{display:'flex'}}>
                      {noneInspection.map((value,index)=>(
                        <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>
                          {value}
                        </div>
                      ))}
                      <div style={{width:'104px'}} className={'ins_title_criteria'}>{t('결과없음')}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>


          {/*중품 리스트*/}
          <div style={{display:'flex'}}>
            <div className={'ins_title_box'}>
              <div style={{fontSize:'12px',fontWeight:900}}>{t('중품')}</div>
              <ListInnerInput type={'calendar'} name={'writeDate'} inputData={inspectionInfo[1].writeDate}
                              onChange={(e)=>Object.assign(inspectionInfo[1], {writeDate : dayjs(e).format('YYYY.MM.DD')})}/>
              <ListInnerInput type={'timer'} placeholder={'00:00:00'} inputData={inspectionInfo[1].writeTime}
                              onChange={(e)=>(onUpdateArray(1,e))} name={'writeTime'}/>
              <div style={{fontSize:'12px',fontWeight:900}}>{t('작성자')}</div>
              <ListInnerInput type={'text'} placeholder={t('작성자')} inputData={inspectionInfo[1].writer}
                              onChange={(e)=>(onUpdateArray(1,e))} name={'writer'}/>
            </div>

            <div style={{display:'flex',flexDirection:'column'}}>
              <div style={{display:'flex'}}>
                <div style={{display:'flex',flexDirection:'column'}} >
                  <div className={'ins_title_sample'}>{t('시료')}</div>
                  <div className={'ins_title_sample'}>{t('기준')}</div>
                </div>
                {getData?.map((v)=>(
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <div className={'ins_title_criteria'}>{!v.inspectionItems?  '-' : v.inspectionItems}</div>
                    <div className={'ins_title_criteria'}>{!v.inspectionCriteria?'-' : v.inspectionCriteria}</div>
                  </div>
                ))}

                <div style={{display:'flex'}}>
                  {noneInput?.map((v)=>(
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div className={'ins_title_criteria'}>{v}</div>
                      <div className={'ins_title_criteria'}>{v}</div>
                    </div>
                  ))}
                </div>
                <ResultBox>{t('결과')}</ResultBox>
              </div>
              <div>
                {inspectionCount?.map((v,index)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>{v}</div>
                    {inspectionDataMiddle[index].slice(0,getData.length)?.map((value:any,idx:number)=>(
                      <div className={'ins_title_input'}>
                        <ListInnerInput type={'number'} inputData={value} width={'96px'}
                                        height={'24px'} placeholder={'0'} limit={5}
                                        onChange={(e)=>(onChangeArray(index,idx,e,inspectionDataMiddle,setInspectionDataMiddle))}/>
                      </div>
                    ))}
                    {inspectionDataMiddle[index].slice(0,columnLength - getData.length)?.map(()=>(
                      <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>{'-'}</div>
                    ))}
                    <div className={'ins_title_result'}>
                      <ListInnerInput type={'drop'} disabled={true} dropMenuList={['합격','불합격']} onChange={(e)=>onChangeResult(selectIndexMiddle,e,inspectionDataMiddle,setInspectionDataMiddle)}
                                      selectDrop={(value)=> {
                                        setSelectMiddleDropValue(value)
                                        setSelectIndexMiddle(index)
                                        Object.assign(inspectionDataMiddle[index], {columnLength:value})
                                      }}
                                      inputData={selectMiddleDropValue}/>
                    </div>
                  </div>
                ))}
              </div>

              {/*시료수를 5이상 안했을때 -로 대체*/}
              <div style={{display:'flex',flexDirection:'column'}}>
                {countMinus.map((v)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>
                      {v}
                    </div>
                    <div style={{display:'flex'}}>
                      {noneInspection.map((v)=>(
                        <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>
                          {v}
                        </div>
                      ))}
                      <div style={{width:'104px'}} className={'ins_title_criteria'}>{t('결과없음')}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>


          {/*종품 리스트*/}
          <div style={{display:'flex'}}>
            <div className={'ins_title_box'}>
              <div style={{fontSize:'12px',fontWeight:900}}>{t('종품')}</div>
              <ListInnerInput type={'calendar'} name={'writeDate'} inputData={inspectionInfo[2].writeDate}
                              onChange={(e)=>Object.assign(inspectionInfo[2], {writeDate : dayjs(e).format('YYYY.MM.DD')})}/>
              <ListInnerInput type={'timer'} placeholder={'00:00:00'} inputData={inspectionInfo[2].writeTime}
                              onChange={(e)=>(onUpdateArray(2,e))} name={'writeTime'}/>
              <div style={{fontSize:'12px',fontWeight:900}}>{t('작성자')}</div>
              <ListInnerInput type={'text'} placeholder={t('작성자')} inputData={inspectionInfo[2].writer}
                              onChange={(e)=>(onUpdateArray(2,e))} name={'writer'}/>
            </div>

            <div style={{display:'flex',flexDirection:'column'}}>
              <div style={{display:'flex'}}>
                <div style={{display:'flex',flexDirection:'column'}} >
                  <div className={'ins_title_sample'}>{t('시료')}</div>
                  <div className={'ins_title_sample'}>{t('기준')}</div>
                </div>
                {getData?.map((v)=>(
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <div className={'ins_title_criteria'}>{!v.inspectionItems?  '-' : v.inspectionItems}</div>
                    <div className={'ins_title_criteria'}>{!v.inspectionCriteria?'-' : v.inspectionCriteria}</div>
                  </div>
                ))}

                <div style={{display:'flex'}}>
                  {noneInput?.map((v)=>(
                    <div style={{display:'flex',flexDirection:'column'}}>
                      <div className={'ins_title_criteria'}>{v}</div>
                      <div className={'ins_title_criteria'}>{v}</div>
                    </div>
                  ))}
                </div>
                <ResultBox>{t('결과')}</ResultBox>
              </div>
              <div>
                {inspectionCount?.map((v,index)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>{v}</div>
                    {inspectionDataLast[index].slice(0,getData.length)?.map((value:any,idx:number)=>(
                      <div className={'ins_title_input'}>
                        <ListInnerInput type={'number'} inputData={value} width={'96px'}
                                        height={'24px'} placeholder={'0'}  limit={5}
                                        onChange={(e)=>(onChangeArray(index,idx,e,inspectionDataLast,setInspectionDataLast))}/>
                      </div>
                    ))}
                    {inspectionDataLast[index].slice(0,columnLength - getData.length)?.map((item:any,idx:number)=>(
                      <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>{'-'}</div>
                    ))}
                    <div className={'ins_title_result'}>
                      <ListInnerInput type={'drop'} disabled={true} dropMenuList={['합격','불합격']} onChange={(e)=>onChangeResult(selectIndexLast,e,inspectionDataLast,setInspectionDataLast)}
                                      selectDrop={(value)=> {
                                        setSelectLastDropValue(value)
                                        setSelectIndexLast(index)
                                        Object.assign(inspectionDataLast[index], {columnLength:value})
                                      }}
                                      inputData={selectLastDropValue}/>
                    </div>
                  </div>
                ))}
              </div>

              {/*시료수를 5이상 안했을때 -로 대체*/}
              <div style={{display:'flex',flexDirection:'column'}}>
                {countMinus.map((v)=>(
                  <div style={{display:'flex'}}>
                    <div style={{fontWeight:400}} className={'ins_title_sample'}>
                      {v}
                    </div>
                    <div style={{display:'flex'}}>
                      {noneInspection.map((v,index)=>(
                        <div className={'ins_title_input'} style={{fontSize:'12px',fontWeight:400}}>
                          {v}
                        </div>
                      ))}
                      <div style={{width:'104px'}} className={'ins_title_criteria'}>{t('결과없음')}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </InspectionBox>
        <div style={{display:'flex',gap:'16px',justifyContent:'center',marginTop:'16px'}}>
          <MainButtons name={'확인'} width={'144px'} clickEvent={cancelEvent}/>
        </div>
      </BaseComponent>
    }
    />
  );
};

export default InspectionFormModal;

const BaseComponent = styled.div`
  width: 1538px;
  height: 728px;
  overflow: hidden;
  position: absolute;
  //justify-content: space-between;
  padding-bottom: 16px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;

  .modal{
    width: 1538px;
    min-height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 900;
    &_title{
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      padding: 0 16px 0 16px;
      box-sizing: border-box;
    }
    &_sub_title{
      margin-left: 8px;
      font-size: 14px;
      font-weight: bold;
      opacity: 0.5;
    }
  }

  .ins_title{
    margin: 0 16px;
    width: 1505px;
    min-height: 48px ;
    background-color: #D7DBEC;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 900;
    border-right: 1px solid #D7DBEC;
    border-left: 1px solid #D7DBEC;
    border-bottom: 1px solid #D7DBEC;
  }
`


const MoreImageInfo = styled.div<{infoOpen?:boolean}>`
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  height: ${p=>p.infoOpen ? 288 : 0}px;
  transition: all 300ms;
  overflow: hidden;
`
const TableDefaultOption =  styled.div`
    margin: 0 16px 0 16px;
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr;
  align-items: center;
  box-sizing: border-box;

  &>div:nth-child(13) {
    grid-column: 5 / 6;
    grid-row: 2 / 5;
  }
  &>div:nth-child(14) {
    grid-column: 9 / 6;
    grid-row: 2 / 5;
  }
  .order{
        &_title{
          height: 32px;
          background-color: #F5F6FA;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-weight: bold;
          font-size: 12px;
          box-sizing: border-box;
          border-top: 1px solid #E6E9F4;
        }
        &_content{
          font-size: 14px;
          font-weight: normal;
          color: #171725;
          display: flex;
          align-items: center;
          height: 32px;
          padding-left: 16px;
          box-sizing: border-box;
          border-top: 1px solid #E6E9F4;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
   }
`
const TableOpenOptions = styled.div<{openProps?:boolean}>`
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns: 0.7fr 1fr 0.7fr 1fr;
  box-sizing: border-box;
  transition: all 300ms;
  width: 774px;
  height: ${p=>p.openProps ? 128 : 0}px;
  margin-right: 16px;

  &>div:nth-child(13) {
    grid-column: 1 / 1;
    grid-row: 4 / 4;
  }
  &>div:nth-child(14) {
    grid-column: 5 / 2;
    grid-row: 4 / 5;
  }
  .order{
    &_title{
      height: 32px;
      background-color: #F5F6FA;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }
    &_content{
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`
const ResultBox = styled.div`
  width: 104px;
  height: 64px;
  background-color: #F5F6FA;
  border-right: 1px solid #D7DBEC;
  border-bottom: 1px solid #D7DBEC;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 900;
`

const InspectionBox = styled.div<{isOpen?:boolean}>`
    margin-left: 16px;
    height: ${p=>p.isOpen ? 224 : 448}px;
    box-sizing: border-box;
    transition: all 500ms;
  overflow-x:scroll;
  &::-webkit-scrollbar{
    display: block;
    width: 18px;
  }
  
  .ins_title{
    width: 1498px;
    height: 48px ;
    background-color: #D7DBEC;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 900;
    border-right: 1px solid #D7DBEC;
    border-left: 1px solid #D7DBEC;
    border-bottom: 1px solid #D7DBEC;
   &_result{
     pointer-events: none;
     width:104px;
     height:30px;
     font-size: 12px;
     border-bottom: 1px solid  #E6E9F4;
     box-sizing: border-box;
   }
   
    &_box{
      opacity: 0.5;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 8px 16px;
      min-width: 172px;
      height: 224px;
      background-color: #F5F6FA;
      box-sizing: border-box;
      border-right: 1px solid #D7DBEC;
      border-left: 1px solid #D7DBEC;
      border-bottom: 1px solid #D7DBEC;
    }
    &_sample{
      width: 108px;
      height: 32px;
      background-color: #F5F6FA;
      box-sizing: border-box;
      border-bottom: 1px solid #D7DBEC;
      border-right: 1px solid #D7DBEC;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      font-size: 12px;
    }
    &_criteria{
      width: 140px;
      height: 32px;
      background-color: #F5F6FA;
      box-sizing: border-box;
      border-bottom: 1px solid #D7DBEC;
      border-right: 1px solid #D7DBEC;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &_input{
      opacity: 0.5;
      pointer-events: none;
      width: 140px;
      height: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #D7DBEC;
      border-right: 1px solid #D7DBEC;
      display: flex;
      align-items: center;
      justify-content: center;
    } }
  `




























