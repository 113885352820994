import React , {useEffect , useState} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame , tooltipModule , validationValue ,
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";

import {Error_Type , Modal_View_Type , ProcessKindsType} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedState ,
  allPersistCheckedFunction , authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  filterCheckedRow , handleRowDelete , hasDuplicate ,
  initError ,
  initModal ,
  listLoadingCircle ,
  loadingRemove , menuVisitLogRegister , onChangeArray , onMultiRegChangeArray ,
  removeCheckedFunction ,
  singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilValue} from "recoil";
import {
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../../common/StateManage";

// import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import styled from 'styled-components'
import ProcessTypeLinkedFacilityModal from '../../../../../components/modal/pms/ProcessTypeLinkedFacilityModal'
import SearchBar from '../../../../../components/input/SearchBar'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import {AutoTextSize} from "auto-text-size";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const ProcessTypeManagement = () => {
  const {t}: any = useTranslation();

  const selectedCompany  = useRecoilValue<any>(authValid() ? persistCompanyData : userPersistCompanyData)
  const initialObject = {
    companyCode: selectedCompany.companyCode,
    processName:''
  }
  const generateInitialRowList = Array.from( {length: 20} , ( _ , i ) => ( {
    ...initialObject ,
    id: i + 1
  } ) )

  const [rowList , setRowList] = useState<ProcessKindsType[]>([])
  const [checkedList , setCheckedList] = useState<any>([])

  const [hoverIcon , setHoverIcon] = useState<string>(modify)
  const [iconSelect , setIconSelect] = useState<number>(- 1)
  const [focusList , setFocusList] = useState<number>(- 1)
  const [selectCode , setSelectCode] = useState<string>('')
  const [selectProcessData , setSelectProcessData] = useState<any>({});
  const [processList , setProcessList] = useState<any[]>([]);

  const [page , setPage] = useState<number>(1);
  const [totalPage , setTotalPage] = useState<number>(1);
  const [pageBlock , setPageBlock] = useState<number>(0);

  const [selectedFacility, setSelectedFacility] = useState<string[]>([])
  const [selectedProcessName, setSelectedProcessName] = useState<string>('')
  // const [ , setSearchValue] = React.useState<string>('');
  const [searchState, setSearchState] = useState<{input: string, value: string}>({input:'', value:''})
  const [errorState , setErrorState] = React.useState<Error_Type>(initError)
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>([])

  const scrollRef = React.useRef<any>(null)
  const scrollToTop = () => {
    scrollRef.current.scrollTop = 0
  }
  // const addListRow = () => {
  //   scrollToTop()
  //   setIdIndex(idIndex + 1)
  //   setSelectedFacility([])
  //   setSelectedProcessName('')
  //   setSelectCode('')
  //   const rowInput = {
  //     id: idIndex ,
  //     companyCode: selectedCompany.companyCode ,
  //     processName: '' ,
  //   }
  //   setRowList([...rowList , rowInput])
  //   setCheckedList([...checkedList , rowInput.id])
  // }
  const updateFunction = () => {
    if(page === 1){
      if(searchState.value === ''){
        processListRes().then()
      } else{
        processTypeSearchRes().then()
      }
    } else{
      setPage(1)
    }
  }
  const checkboxValid = () => {
    if(rowList.length !== 0){
      return {list: rowList , id: 'id'}
    } else{
      return {list: processList , id: 'processCode'}
    }
  }
  //리스트
  const processListRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.get(`/process/type/list/${page}/10/${selectedCompany.companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice: valid.message , status: valid.status})
        setProcessList([]);
      } else{
        setTotalPage(data.total_page);
        setErrorState({notice: t('* 등록된 공정종류가 없습니다. 등록해 주세요.') , status: valid.status})
        setProcessList(data.row)
        // if(data.total_page > 1 && page > 1){
        //     setProcessList((prev:any)=> prev.concat(data.row))
        // }else{
        //     setProcessList(data.row)
        // }
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  //등록
  const processTypeRegisterRes = async () => {
    if(filterCheckedRow(rowList , checkedList , 'id').find((v) => ( !v.processName.trim() ))){
      setDataModal({menu: 'confirm' , message: '공정명을 입력해 주세요.'})
    } else if(hasDuplicate(rowList.filter(item => checkedList.includes(item.id)),'processName')){
      setDataModal({menu: 'confirm' , message: '입력한 공정명이 중복 되었습니다.'})
    } else{
      try {
        dataAccessLoading()
        const response = await client.post(`/process/type/register` ,
                                           filterCheckedRow(rowList.map((v) => ( {...v , processName: v.processName.trim()} )) , checkedList , 'id')
          , {headers: {Authorization}})
        const data = response.data;
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setRowList([])
            setCheckedList([])
            updateFunction()
            setDataModal({menu: 'success' , message: '공정 종류가 등록 되었습니다.'})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  //수정
  const processTypeUpdateRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/process/type/update` , {
        companyCode: selectedCompany?.companyCode ,
        ...processList[iconSelect] ,
        processName: processList[iconSelect].processName.trim()
      } , {headers: {Authorization}})
      const data = response.data
      if(data.status !== 200){
        setDataModal({menu: 'confirm' , message: data.message})
        loadingRemove()
      } else{
        setTimeout(() => {
          updateFunction()
          setCheckedList([])
          setIconSelect(- 1)
          setSelectedProcessName('')
          setDataModal({menu: 'success' , message: '수정 되었습니다.'})
          loadingRemove()
        } , 500)
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }
  //검색
  const processTypeSearchRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.post(`/process/type/search` , {
        page: page ,
        size: 10 ,
        companyCode: selectedCompany.companyCode ,
        keyword: searchState.value
      } , {headers: {Authorization}})
      const data = response.data.data
      setTotalPage(data.total_page)
      setProcessList(data.row)
      // if(data.total_page > 1 && page > 1){
      //     setProcessList((prev:any)=> prev.concat(data.row))
      // }else{
      //     setProcessList(data.row)
      // }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  //삭제
  const processTypeDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/process/type/delete` , [...checkedList] , {headers: {Authorization}})
      const data = response.data
      const defectCaseArray = data.data?.defectProcess?.map((v: any) => ( {code: v , case: '불량 유형에 등록되어 있습니다.'} ))
      const pauseCaseArray = data.data?.pauseProcess?.map((v: any) => ( {code: v , case: '일시정지 유형에 등록되어 있습니다.'} ))
      const productCaseArray = data.data?.product?.map((v: any) => ( {code: v , case: '품목에 등록되어 있습니다.'} ))
      const segmentArray = data.data?.segment?.map((v: any) => ( {code: v , case: '공정 세분화가 등록되어 있습니다.'} ))
      const workHistoryArray = data.data?.workHistory?.map((v: any) => ( {code: v , case: '작업이력에 사용중에 있습니다.'} ))

      const caseArray = [defectCaseArray , pauseCaseArray , productCaseArray , segmentArray , workHistoryArray].flat().filter((v) => ( v ))


      if(data.status === 200){
        setTimeout(() => {
          setPage(1)
          setTotalPage(1)
          setDataModal({menu: 'success' , message: '선택한 공정 종류가 삭제 되었습니다.'})
          setCheckedList([])
          setSearchState({input:'', value:''})
          setSelectedFacility([])
          setSelectCode('')
          setIconSelect(- 1)
          removeCheckedFunction(setProcessList , processList , setCheckedList , checkedList , 'processCode')
          scrollToTop()
          if(page === 1){
            if(searchState.value === ''){
              processListRes().then()
            } else{
              processTypeSearchRes().then()
            }
          }
          loadingRemove()
        } , 500)
      } else if(data.status === 601){
        setTimeout(() => {
          setPage(1)
          setTotalPage(1)
          setIconSelect(- 1)
          setDataModal({menu: 'deleteCase'})
          setDeleteCaseArray(caseArray)
          setCheckedList(checkedList.filter((v: string) => !data.data.success.includes(v)))
          setProcessList(processList.filter((v: any) => !data.data.success.includes(v['processCode'])))
          scrollToTop()
          if(page === 1){
            if(searchState.value === ''){
              processListRes().then()
            } else{
              processTypeSearchRes().then()
            }
          }
          loadingRemove()
        } , 500)
      } else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }
  React.useEffect(() => {
    if(selectedCompany.companyCode !== undefined){
      if(searchState.value === ''){
        processListRes().then()
      } else{
        processTypeSearchRes().then()
      }
    }
  } , [selectedCompany , searchState.value , page])

  const DeleteFunc = () => {
    if(rowList.length === 0){
      setDataModal({menu: 'select' , message: `${checkedList.length}개의 공정 종류를 삭제 하시겠습니까?`})
    } else{
      setDataModal({menu: 'back' , message: '선택한 행의 입력된 정보를\n 지우시겠습니까?'})
    }
  }
  const onDataResetFunc = () => {
    setRowList([]);
    setCheckedList([]);
    setSearchState({input:'', value:''})
    setPage(1);
    setPageBlock(0)
    setTotalPage(1)
    setIconSelect(- 1);
    setSelectProcessData({});
    setSelectCode('')
    setSelectedProcessName('')
    setSelectedFacility([])
    setProcessList([])
    setErrorState(initError)
  }

  React.useEffect(()=>{
    onDataResetFunc()
  },[selectedCompany.companyCode])

  const cancelFuc = () => {
    setHoverIcon(modify);
    setIconSelect(- 1);
    setCheckedList([])
    setDataModal(initModal)
    if(searchState.value === ''){
      setProcessList(processList.map((v: any) => {return v.processCode === selectCode ? {...v , ...selectProcessData} : v}))
    } else{
      processTypeSearchRes().then()
    }
  }
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(rowList.length > 0 || iconSelect !== - 1){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload);
    };
  } , [rowList.length , iconSelect]);

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'공정 종류 관리').then()
    }
  },[])

  return (
    <React.Fragment>
      {dataModal.menu === 'linkedFacility' &&
        <ProcessTypeLinkedFacilityModal receivedEvent={(e) => {setSelectedFacility(e); processListRes().then()}}
                                        selectedFacilityList={selectedFacility}
                                        processCode={selectCode}
                                        processName={selectedProcessName}
                                        closeEvent={() => {
                                          setDataModal(initModal);
                                        }}/>

      }
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={() => {setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={() => {setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}
                                                    closeEvent={() => {setDataModal(initModal)}}/> : null}

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'process/type'} type={'download'} companyData={selectedCompany}/>
              <ExcelApiButton category={'process/type'} type={'format'} companyData={selectedCompany}
                              disabled={buttonAuthValid( 'create' , '13' )}/>
              <ExcelApiButton category={'process/type'} disabled={buttonAuthValid( 'create' , '13' )} type={'upload'}
                              companyData={selectedCompany}/>
            </React.Fragment>
          }/>
      }


      {dataModal.menu === 'deleteCase' &&
        <DeleteCaseModal targetName={'공정명'} caseArray={deleteCaseArray} closeEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'confirm' &&
        <ConfirmPopup statusText={t(dataModal.message)} clickEvent={() => {setDataModal(initModal);}}/>}

      {dataModal.menu === 'register' &&
        <SelectConfirmModal statusText={t(dataModal.message)}
                            noticeText={t('* 선택되지 않은 리스트는 저장 되지 않습니다.')}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={processTypeRegisterRes}/>}

      {dataModal.menu === 'select' &&
        <SelectConfirmModal statusText={t(dataModal.message)}
                            noticeText={t('* 삭제된 데이터는 복구되지 않습니다.')}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={processTypeDeleteRes}/>}

      {dataModal.menu === 'list' &&
          <SelectConfirmModal statusText={t(dataModal.message)}
                              noticeText={''}
                              cancelEvent={() => setDataModal(initModal)}
                              clickEvent={()=>{
                                setRowList([]);
                                setCheckedList([])
                                setDataModal(initModal)
                              }}/>}


      {dataModal.menu === 'back' &&
        <SelectConfirmModal statusText={t(dataModal.message)}
                            viewType={true}
                            noticeText={''}
                            clickEvent={() => {
                              if(rowList.length !== 0){
                                // removeCheckedFunction(setRowList , rowList , setCheckedList , checkedList , 'id')
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setSelectProcessData({})
                                setDataModal(initModal)
                              } else{
                                cancelFuc()
                              }
                            }}
                            cancelEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'modify' &&
        <SelectConfirmModal statusText={t(dataModal.message)}
                            noticeText={t('* "예" 선택시 변경전 데이터는 복구되지 않습니다.')}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={() => {
                              processTypeUpdateRes().then();
                              setDataModal(initModal)
                            }}/>}

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <MenuTitle title={(authValid() ? '업체/' : '' ) + '기본정보 관리 > 공정 종류 관리'} unitCase={'MenuTitle'}/>
          <MainButtons name={'Excel'} disabled={!selectedCompany.companyCode} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
        </div>
        {/*공정 종류 관리 */}
        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div style={{display:'grid', gridTemplateColumns:'1fr 60px 1fr'}}>
            <div>
              <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
                <MenuTitle title={'공정 종류 관리'} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <SearchBar minWidth={'140px'} width={'140px'}
                    clickEvent={() => {
                    setSearchState((prev)=>({...prev, value: prev.input}))
                    setSelectedFacility([])
                    setSelectedProcessName('')
                    setSelectCode('')
                    setPage(1)
                  }} keyPressDisable={true}
                             keyFunction={(e) => setSearchState((prev)=>({...prev, input: e.target.value.trimStart()}))}
                             value={searchState.input}
                             clearButtonAccess={searchState.input !== ''}
                             clearButton={() => {
                               setSearchState({input:'', value:''})
                               setPage(1)}}
                             disabled={( processList.length === 0 && searchState.value === '' ) || rowList.length !== 0 || iconSelect !== - 1}/>
                  {/*<ExcelApiButton category={'process/type'} type={'download'} companyData={selectedCompany}*/}
                  {/*                disabled={processList.length === 0}/>*/}
                  {/*<ExcelApiButton category={'process/type'} type={'format'} companyData={selectedCompany}*/}
                  {/*                disabled={buttonAuthValid('create' , '13')}/>*/}
                  {/*<ExcelApiButton category={'process/type'} type={'upload'} companyData={selectedCompany}*/}
                  {/*                disabled={buttonAuthValid('create' , '13')}/>*/}

                  {selectedCompany.companyCode !== undefined &&
                    <MainButtons name={rowList?.length === 0? '등록' : '취소'} width={'80px'} clickEvent={()=> {
                      if(rowList?.length === 0){
                        setRowList(generateInitialRowList)
                      }
                      else{
                        if(checkedList?.length === 0){
                          setRowList([])
                        }
                        else{
                          setDataModal({menu:'list', message:'입력된 내용이 저장되지 않았습니다. \n목록으로 이동하시겠습니까?'})
                        }
                      }
                    }} disabled={buttonAuthValid('create' , '13') || ( iconSelect !== - 1 || checkedList[0]?.length > 1 || errorState.status !== 200 )}/>}


                  <MainButtons name={'저장'} width={'80px'} disabled={
                    buttonAuthValid('create' , '13') || ( rowList.length === 0 || checkedList.length === 0 )
                  } buttonType={'cta'}
                               clickEvent={() => {
                                 if(checkedList.length === rowList.length){
                                   processTypeRegisterRes().then()
                                 } else{
                                   setDataModal({menu: 'register' , message: '등록을 진행 하시겠습니까?'})
                                 }
                               }}/>


                  <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'} marginDis={true}
                               disabled={
                                 buttonAuthValid('rowDelete' , '13') ?
                                   rowList.length === 0
                                   :
                                   (buttonAuthValid('update' , '13') || checkedList.length === 0 )
                               } clickEvent={DeleteFunc}/>
                </div>
              </div>
              <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                                  titleCheckDisabled={iconSelect !== - 1}
                                  ref={scrollRef} scrollWidth={rowList?.length < 10? 586 : 576}
                                  scrollY_Hide={rowList?.length < 10}
                                  height={528}
                                  tempCol={'40px 180px 124px 1fr'}>
                <div className={'grid-title'}>
                  <label className={'checkbox'}>
                    <input type="checkbox"
                           style={{pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` , opacity: `${iconSelect === - 1 ? 1 : 0.5}`}}
                           onChange={(e) => allPersistCheckedFunction(e.target.checked , {dataList: checkboxValid().list , checkedList: checkedList , id: checkboxValid().id , setState: setCheckedList})}
                           checked={allCheckedState({dataList: checkboxValid().list , checkedList: checkedList , id: checkboxValid().id})}/>
                  </label>
                  {['공정명','연결 기계수'].map((name) => (
                    <TableValue title={name} requiredValue={['공정명']}
                                viewType={rowList.length !== 0 || iconSelect !== - 1}/>
                  ))}
                  <div>
                    <div style={{position:'absolute', top:'16px', right:'8px', backgroundColor:'transparent', border: 'none',
                      height:'16px', fontWeight: 400}}>{'*공정명 '}
                      <span style={{color:'#0058ff', fontWeight:700}}>{'저장'}</span>
                      {' 후 우측 메뉴를 선택해 주세요.'}
                    </div>
                  </div>
                </div>

                {(rowList.length === 0 && processList.length === 0) ?
                  searchState.value === '' ?
                    errorState.status !== 200 ?
                      listResultMessage(authValid() , dataValid(errorState.notice , t('* 업체를 선택해 주세요.')) , {width: 578 , admin: 480 , user: 480})
                      :
                      listResultMessage(authValid(), dataValid(errorState.notice , '') ,
                                        {width: 578 , admin: 480 , user: 480})
                    :
                    listResultMessage(authValid() , t('*검색 결과가 없습니다.') , {width: 578 , admin: 480 , user: 480})
                  :
                  rowList.map((v , i) => (
                    <div className={'grid-row-list'}>
                      <label className={'checkbox'}>
                        <ListInnerInput type={'check'} name={'id'}
                                        onChange={(e: any) => singleCheckedFunction(e.target.checked , v.id , setCheckedList , checkedList)}
                                        checked={checkedList.includes(v.id)}/>
                      </label>
                      <ListInnerInput type={'text'} placeholder={'공정명'} inputData={v.processName} name={'processName'}
                                      onChange={(e) => {onMultiRegChangeArray(i , e , rowList , setRowList, checkedList, setCheckedList, v.id)}}/>
                    </div>
                  ))}

                {rowList.length === 0 &&
                  processList.map((v: any , i: number) => (
                    <React.Fragment>
                      {iconSelect !== i ?
                        <GridTableSelect selected={v.processCode === selectCode}
                                         disabled={rowList.length !== 0 || iconSelect !== - 1}
                                         checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                         className={'grid-list'} onClick={() => {
                          setSelectCode(v.processCode)
                          setSelectedFacility(v.facility)
                          setSelectedProcessName(v.processName)
                        }} onMouseOut={() => setFocusList(- 1)} onMouseOver={() => setFocusList(i)}>
                          <label className={'selectCheckbox'}>
                            <ListInnerInput type={'check'} name={'processCode'}
                                            onChange={(e: any) => singleCheckedFunction(e.target.checked , v.processCode , setCheckedList , checkedList)}
                                            checked={checkedList.includes(v.processCode)}/>
                          </label>
                          {tooltipModule(dataValid(v.processName , 8))}
                          <ListInnerInput type={'noneInputUnit'} unit={'대'} inputData={dataValid(v.facility?.length,0)}/>
                        <div>
                          <div className={'pencil-icon'}>
                            {i === focusList &&
                              <img src={hoverIcon} width={( buttonAuthValid('update' , '13') ? 0 : 24 )} alt=""
                                   onClick={(e) => {
                                     e.stopPropagation();
                                     setSelectCode(v.processCode)
                                     setIconSelect(i);
                                     setCheckedList([v.processCode])
                                     setSelectedProcessName(v.processName)
                                     setSelectedFacility(v.facility)
                                     // setSelectedFacility([])
                                     setSelectProcessData({...v})
                                   }}
                                   onMouseOut={() => setHoverIcon(modify)} onMouseOver={() => setHoverIcon(modifyHover)}/>
                            }
                          </div>
                        </div>

                        </GridTableSelect>
                        :
                        <GridTableSelect className={'grid-list'}
                                         checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                         selected={v.processCode === selectCode}>
                          <label className={'selectCheckbox'}>
                            <ListInnerInput type={'check'} name={'processCode'}
                                            onChange={(e: any) => singleCheckedFunction(e.target.checked , v.processCode , setCheckedList , checkedList)}
                                            checked={checkedList.includes(v.processCode)}/>
                          </label>
                          <ListInnerInput type={'text'} placeholder={'공정명'} inputData={v.processName} name={'processName'}
                                          onChange={(e) => {onChangeArray(iconSelect , e , processList , setProcessList)}}/>
                          <ListInnerInput type={'noneInputUnit'} unit={'대'} inputData={dataValid(v.facility?.length,0)}/>
                          <div>
                            <div className={'select-icon'} onClick={(e) => e.stopPropagation()}>
                              <img src={cancelIcon} width={24} alt=""
                                   onClick={() => {setDataModal({menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                              <img src={checkIcon} width={24}
                                   onClick={() => {
                                     if(!v.processName){
                                       setDataModal({menu: 'confirm' , message: '공정명을 입력해 주세요.'})
                                     } else{
                                       setDataModal({menu: 'modify' , message: '이대로 수정 하시겠습니까?'})
                                     }
                                   }} alt=""/>
                            </div>
                          </div>
                        </GridTableSelect>
                      }
                    </React.Fragment>
                  ))}
              </GridTableComponent>
              <div style={{marginTop:'18px'}}>
                {rowList.length === 0 &&
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage} disabled={iconSelect !== - 1}/>}
              </div>
            </div>
            {/*중간 구분선*/}
            <MiddleLine lineHeight={528}>
              <div style={{height:'32px', visibility:'hidden'}}/>
              <div className={'line-css'}></div>
            </MiddleLine>
            {/*선택 기계 리스트*/}
            <div>
              <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                  <MenuTitle title={'기계 리스트'} unitCase={'MenuCompTitle'}/>
                  {selectedProcessName && <div style={{color: '#0058ff' , marginLeft: '13px' , fontWeight: 700 , fontSize: '18px'}}>
                    {`(${dataValid(selectedProcessName)}: ${dataValid(selectedFacility?.length,0)}대)`}
                  </div>}
                </div>

                {!buttonAuthValid('update' , '13') &&
                  <MainButtons name={'수정'} marginDis={true}
                               clickEvent={()=>setDataModal({menu:'linkedFacility', message:'선택한 기계를 해당 공정에 \n적용 하시겠습니까?'})}
                    // clickEvent={()=>setDataModal({menu:'facility', message:'선택한 기계를 해당 공정에 \n적용 하시겠습니까?'})}
                               disabled={(!selectCode || iconSelect !== -1 || selectedFacility === undefined)}
                               width={'80px'}/>}
              </div>

              <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                                  titleCheckDisabled={iconSelect !== - 1}
                                  ref={scrollRef} scrollWidth={574}
                                  scrollY_Hide={(selectedFacility?.length < 10)}
                                  height={528}
                                  tempCol={authValid()? '88px 128px 148px 90px 120px' : '88px 168px 188px 130px'}>
                <div className={'grid-title-unchecked'}>
                  {['기종','기계명','제조번호','제조사'].map((name) => (
                    <TableValue title={name} key={name} requiredValue={[]}/>
                  ))}
                  {authValid() && <TableValue title={'ICT번호'} requiredValue={[]}/>}
                </div>
                <React.Fragment>
                  {
                    !selectedCompany.companyCode?
                      listResultMessage(authValid() , dataValid(errorState.notice , t('* 업체를 선택해 주세요.')) , {width: 578 , admin: 480 , user: 480})
                      :

                      selectedFacility === undefined?
                        listResultMessage(authValid() , '기계를 선택할 수 없습니다.' , {width: 578 , admin: 480 , user: 480})
                        :
                      rowList?.length !== 0?
                        listResultMessage(authValid(), dataValid('* 공정 종류를 등록해 주세요.' , '') ,
                                          {width: 578 , admin: 480 , user: 480} )
                        :

                        !selectCode?
                          listResultMessage(authValid() , '* 공정 종류를 선택해 주세요.' , {width: 578 , admin: 480 , user: 480})
                          :
                          selectedFacility?.length === 0?
                            listResultMessage(authValid(), dataValid('* 등록된 기계가 없습니다.' , '') ,
                                              {width: 578 , admin: 480 , user: 480} , ()=>setDataModal({menu:'linkedFacility'}),
                                              buttonAuthValid('create' , '6'), '등록')
                            :
                            selectedFacility?.map((v:any)=>(
                              <GridTableSelect className={'grid-list-unchecked'}>
                                {autoTextValueValidation({value: v.type})}
                                {autoTextValueValidation({value: v.facilityName})}
                                {autoTextValueValidation({value: v.manufacturingNumber})}
                                {validationValue(v.maker)}
                                {authValid() && validationValue(authValid()? v.ictNumber : '')}
                              </GridTableSelect>
                            ))
                  }
                </React.Fragment>
              </GridTableComponent>
            </div>
          </div>

        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default ProcessTypeManagement;

const MiddleLine = styled.div<{lineHeight: number}>`
  .line-css{
    position: relative;
    height: 100%;
    &::before{
      content: '';
      height: ${props => props.lineHeight?? 528}px;
      width: 1px;
      position: absolute;
      top: 16px;
      left: 24px;
      background-color: #E6E9F4;
    }
  }
`
