import React , {useState} from 'react';
import styled from 'styled-components'
import ModalBase from '../ModalBase'
import {
  listResultMessage ,
  modalTitleBase ,
  validationValue
} from '../../../style/StyleComponent'
import client from '../../../common/Config'
import {Authorization} from '../../../common/authorization'
import {useRecoilValue} from 'recoil'
import {
  authValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  listLoadingCircle ,
  loadingRemove
} from '../../../function/function'
import {persistCompanyData , userPersistCompanyData} from '../../../common/StateManage'
import SearchBar from '../../input/SearchBar'
import MainButtons from '../../button/MainButtons'
import {cloneDeep} from 'lodash'
import CheckBox from '../../input/CheckBox'
import {Modal_View_Type} from '../../../types/Fundamental'
import ToastPopup from '../ToastPopup'
import InfiniteScroll from 'react-infinite-scroll-component'
import SelectConfirmModal from '../SelectConfirmModal'
import FilterWithSearchBar from "../../input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../../style/StyleModule";

interface Popup_Type {
  closeEvent: () => void
  receivedEvent: ( data: any ) => void
  selectedFacilityList: any[]
  processCode: string
  processName: string
}

// 선택된 공정에 기계를 선택하여 연결시키는 모달창
const ProcessTypeLinkedFacilityModal: React.FunctionComponent<Popup_Type> = ( {
                                                                                receivedEvent ,
                                                                                closeEvent ,
                                                                                selectedFacilityList ,
                                                                                processName ,
                                                                                processCode
                                                                              } ) => {
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [facilityPage , setFacilityPage] = useState<number>( 1 )
  const [facilityTotalPage , setFacilityTotalPage] = useState<number>( 1 )
  // const [facilityPageBlock, setFacilityPageBlock] = useState<number>(0)
  const [facilityList , setFacilityList] = useState<any>( [] )
  const receivedSelected = cloneDeep( selectedFacilityList )
  const [selectedFacility , setSelectedFacility] = useState<any>( [...receivedSelected] )
  const [hoverIndex , setHoverIndex] = useState<number>( - 1 )
  const [bindIndex , setBindIndex] = useState<number>( - 1 )
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [searchState , setSearchState] = useState<{ input: string, value: string }>( {input: '' , value: ''} )
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const onCheckedFacility = ( checked: any , item: any ) => {
    if( checked ){
      setSelectedFacility( [...selectedFacility , item] )
    } else{
      setSelectedFacility( selectedFacility.filter( ( el: any ) => el.facilityCode !== item.facilityCode ) );
    }
    setBindIndex( - 1 )
  };
  const facilityListRes = async () => {
    listLoadingCircle()
    try {
      const response = await client.get( `/facility/list/${facilityPage}/10/${selectedCompany.companyCode}` , {headers: {Authorization}} )
      const data = response.data.data
      setFacilityTotalPage( data.total_page )
      if( response.data.status === 200 ){
        if( data.total_page > 1 && facilityPage > 1 ){
          setFacilityList( ( prev: any ) => prev.concat( dataValid( data.row , [] ) ) )
        } else{
          setFacilityList( data.row )
        }
      } else{
        setFacilityList( [] )
      }
      loadingRemove()
    } catch ( e ) {
      loadingRemove()
      console.log( e )
    }
  }
  const facilityListSearchRes = async () => {
    listLoadingCircle()
    try {
      const response = await client.post( '/facility/admin/row/search/companyCode' , {
        page: facilityPage ,
        size: 10 ,
        type: '@' ,
        filter:categorySelect.type,
        keyword: searchState.value ,
        companyCode: selectedCompany.companyCode
      } , {headers: {Authorization}} )
      const data = response.data.data
      setFacilityTotalPage( data.total_page )
      if( response.data.status === 200 ){
        if( data.total_page > 1 && facilityPage > 1 ){
          setFacilityList( ( prev: any ) => prev.concat( dataValid( data.row , [] ) ) )
        } else{
          setFacilityList( data.row )
        }
      } else{
        setFacilityList( [] )
      }
      loadingRemove()
    } catch ( e ) {
      loadingRemove()
      console.log( e )
    }
  }
  React.useEffect( () => {
    if( searchState.value === '' ){
      facilityListRes().then()
    } else{
      facilityListSearchRes().then()
    }
  } , [facilityPage , searchState.value,categorySelect.type] )
  // 선택한 설비의 위치 위아래 순서를 변경하는 함수
  // 서로 지정된 index값을 비교하여 배열을 변경
  const positionChangeList = ( index: number , direction: string ) => {
    if( direction === 'top' && index !== 0 ){
      [selectedFacility[index] , selectedFacility[index - 1]] = [selectedFacility[index - 1] , selectedFacility[index]]
      setBindIndex( ( prev: number ) => ( prev - 1 ) )
    }
    if( direction === 'bottom' && index !== selectedFacility.length - 1 ){
      [selectedFacility[index] , selectedFacility[index + 1]] = [selectedFacility[index + 1] , selectedFacility[index]]
      setBindIndex( ( prev: number ) => ( prev + 1 ) )
    }
  }
  const processSelectedFacilityRes = async () => {
    setDataModal( initModal )
    dataAccessLoading()
    try {
      const response = await client.post( '/pms/facility/register' , {
        companyCode: selectedCompany.companyCode ,
        facilityCodeList: selectedFacility.map( ( v: any ) => ( v.facilityCode ) ) ,
        monitoringTypeCode: processCode
      } , {headers: {Authorization}} )
      setTimeout( () => {
        if( response.data.status === 200 ){
          setDataModal( {menu: 'success' , message: '연결된 기계 정보가 변경 되었습니다.'} )
          receivedEvent( selectedFacility )
        } else{
          setDataModal( {menu: 'warn' , message: response.data.message} )
        }
        loadingRemove()
      } , 500 )
    } catch ( e: any ) {
      loadingRemove()
      setDataModal( {menu: 'error' , message: e.message} )
      console.log( e )
    }
  }

  //선택했을때 기계 목록이 제외되는 필터 리스트
  const filterList = facilityList?.filter((v: any) => ( !selectedFacility.some((el: any) => el.facilityCode === v.facilityCode) ))
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {dataModal.menu === 'success' ?
            <ToastPopup text={( dataModal.message )} state={'success'}
                        closeEvent={() => {
                          setDataModal( initModal );
                          closeEvent()
                        }}/>
            : dataModal.menu === 'error' ?
              <ToastPopup text={( dataModal.message )} state={'error'}
                          closeEvent={() => {setDataModal( initModal )}}/>
              : dataModal.menu === 'warn' ?
                <ToastPopup text={( dataModal.message )} state={'warn'}
                            closeEvent={() => {setDataModal( initModal )}}/> : null}
          {dataModal.menu === 'select' &&
              <SelectConfirmModal statusText={dataModal.message}
                                  clickEvent={() => {
                                    setSelectedFacility( [...receivedSelected] )
                                    setDataModal( initModal )
                                  }}
                                  cancelEvent={() => setDataModal( initModal )}/>}

          {modalTitleBase( {mainTitle: `기계 선택 (${dataValid( processName )})`} )}
          <div className={'modal_content'}>
            <div className={'list_divide'}>
              {/*선택된 업체의 기계 리스트*/}
              <div>
                <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
                  <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', color: '#7E84A3' , fontSize: '12px'}}>
                    <div>{'기계 리스트'}</div>
                    <div style={{marginLeft:'8px'}}>
                      {'(최대'}
                      <span style={{color:'#0058ff', fontWeight:700, marginLeft:'4px'}}>{'15'}</span>
                      {'개까지 선택가능)'}
                    </div>
                  </div>

                  {/*<SearchBar value={searchState.input}*/}
                  {/*           keyFunction={( e ) => setSearchState( ( prev ) => ( {...prev , input: e.target.value} ) )}*/}
                  {/*           clickEvent={() => {*/}
                  {/*             setSearchState( ( prev ) => ( {...prev , value: prev.input} ) )*/}
                  {/*             setFacilityPage( 1 )*/}
                  {/*           }}*/}
                  {/*/>*/}

                  <FilterWithSearchBar value={searchState.input}
                                       keyFunction={( e ) => setSearchState( ( prev ) => ( {...prev , input: e.target.value} ) )}
                                       clickEvent={() => {
                                         setSearchState( ( prev ) => ( {...prev , value: prev.input} ) )
                                         setFacilityPage( 1 )
                                       }}
                                       margin={'0'} minWidth={'112px'} widthCus={'144px'}
                                       selectKeyword={categorySelect.value}
                                       categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                       categoryTypeArray={['@','manufacturing_number','maker','facility_name']}
                                       categoryValueArray={['전체','제조번호','제조사','기계명']}
                                       placeholder={'검색'}/>

                </div>
                <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>}
                                next={() => setFacilityPage( ( prev ) => ( prev + 1 ) )}
                                hasMore={facilityTotalPage > facilityPage}
                                dataLength={dataValid( filterList?.length )}>
                  <GridTableComponent id={'grid-scroll'}
                                      tempCol={'120px 100px 1fr'} style={{margin: '8px 0 0 0'}}
                                      scrollWidth={470} scrollX_Hide={true} height={310}>
                    <div className={'grid-title-unchecked'}>
                      {['제조번호' , '제조사' , '기계명'].map( ( v ) => (
                        <div key={v}>{v}</div>
                      ) )}
                    </div>
                    {
                      facilityList?.length === 0 ?
                        listResultMessage( authValid() , '등록된 기계가 없습니다.' , {admin: 262 , user: 262 , width: 470} )
                        :

                        filterList?.length === 0 ?
                          listResultMessage( authValid() , '모든 기계가 선택 되었습니다.' , {admin: 262 , user: 262 , width: 470} )
                          :

                        filterList?.map((v:any)=>(
                          <label style={{pointerEvents: (selectedFacility?.length === 15)? 'none' : 'auto'}}>
                            <GridTableSelect className={'grid-list-unchecked-no_icon'} disabled={selectedFacility?.length === 15}
                                             style={{borderLeft:'1px solid #D7DBEC'}}
                                             selected={selectedFacility?.some( ( el: any ) => ( el.facilityCode.includes( v.facilityCode )))}>
                              <CheckBox hideCheckBox={true}
                                        clickEvent={( e: any ) => {

                                          // if(v.type !== '프레스'){
                                          //   setDataModal({menu:'warn', message:
                                          //       `선택된 기계 타입은 ${v.type} 입니다. 프레스 타입만 등록 가능 합니다.`
                                          //   })
                                          // }
                                          // else{
                                          // }
                                            if((facilityTotalPage !== facilityPage) && (filterList?.length <= 6)){
                                              setFacilityPage((prev)=>(prev+1))
                                            }
                                            onCheckedFacility( e.target.checked , v )
                                        }}
                                        checkStatus={selectedFacility?.map( ( el: any ) => el.facilityCode ).includes( v.facilityCode )}
                                        name={'facilityCode'}/>
                              {validationValue( v.manufacturingNumber )}
                              {validationValue( v.maker )}
                              {validationValue( v.facilityName )}
                            </GridTableSelect>
                          </label>
                        ))

                        // facilityList?.map( ( v: any ) => (
                        //   <label>
                        //     <GridTableSelect className={'grid-list-unchecked-no_icon'}
                        //                      selected={selectedFacility?.some( ( el: any ) => ( el.facilityCode.includes( v.facilityCode ) ) )}>
                        //       <CheckBox hideCheckBox={true}
                        //                 clickEvent={( e: any ) => {
                        //                   if(v.type !== '프레스'){
                        //                     setDataModal({menu:'warn', message:
                        //                         `선택된 기계 타입은 ${v.type} 입니다. 프레스 타입만 등록 가능 합니다.`
                        //                     })
                        //                   }
                        //                   else{
                        //                     onCheckedFacility( e.target.checked , v )
                        //                   }
                        //                 }}
                        //                 checkStatus={selectedFacility?.map( ( el: any ) => el.facilityCode ).includes( v.facilityCode )}
                        //                 name={'facilityCode'}/>
                        //       {validationValue( v.manufacturingNumber )}
                        //       {validationValue( v.maker )}
                        //       {validationValue( v.facilityName )}
                        //     </GridTableSelect>
                        //   </label>
                        // ) )
                    }
                  </GridTableComponent>
                </InfiniteScroll>
              </div>

              <div>
                <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
                  <div style={{color: '#7E84A3' , fontSize: '12px'}}>{'선택된 기계'}
                    <span style={{
                      color: '#0085ff' ,
                      textDecoration: 'underline' ,
                      textUnderlineOffset: '2px' ,
                      fontWeight: 700 ,
                      marginLeft: '8px'
                    }}>
                      {String( dataValid( selectedFacility?.length , 0 ) ).padStart( 2 , '0' )}
                    </span>
                    <span style={{fontWeight: 700 , color: '#171725'}}>{'개'}</span>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div style={{
                      display: 'flex' ,
                      opacity: (( bindIndex === - 1 || selectedFacility?.length === 0 ) || selectedFacility?.length <= (bindIndex)) ? 0.3 : 1 ,
                      pointerEvents: (( bindIndex === - 1 || selectedFacility?.length === 0 ) || selectedFacility?.length <= (bindIndex)) ? 'none' : 'auto'
                    }}>
                      <ArrowButton
                        onClick={() => positionChangeList( bindIndex , 'bottom' )}>
                        {'>'}
                      </ArrowButton>
                      <ArrowButton
                        onClick={() => positionChangeList( bindIndex , 'top' )}>
                        {'<'}
                      </ArrowButton>
                    </div>
                    <MainButtons name={'되돌리기'} disabled={receivedSelected?.length === 0}
                                 clickEvent={() => setDataModal( {menu: 'select' , message: '이전 선택된 목록으로 되돌리시겠습니까?'} )}/>
                  </div>
                </div>
                <GridTableComponent tempCol={'60px 120px 100px 1fr'} style={{margin: '8px 0 0 0'}}
                                    scrollWidth={470} scrollX_Hide={true} height={310}>
                  <div className={'grid-title-unchecked'}>
                    {['NO.' , '제조번호' , '제조사' , '기계명'].map( ( v ) => (
                      <div key={v}>{v}</div>
                    ) )}
                  </div>
                  {
                    selectedFacility?.length === 0 ?
                      listResultMessage( authValid() , '선택된 기계가 없습니다.' , {admin: 262 , user: 262 , width: 470} )
                      :
                      selectedFacility?.map( ( v: any , i: number ) => (
                        <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                         onClick={() => setBindIndex( i )}
                                         selected={i === bindIndex}
                                         onMouseEnter={() => setHoverIndex( i )}
                                         onMouseLeave={() => setHoverIndex( - 1 )}>
                          {
                            i === hoverIndex ?
                              <HoverDeleteButton>
                                <div className={'facility_cancel'}
                                     onClick={() => setSelectedFacility( selectedFacility.filter( ( el: any ) => el.facilityCode !== v.facilityCode ) )}>
                                  <div>
                                    {'╋'}
                                  </div>
                                </div>
                              </HoverDeleteButton>
                              :
                              validationValue( i + 1 , 0 )
                          }
                          {validationValue( v.manufacturingNumber )}
                          {validationValue( v.maker )}
                          {validationValue( v.facilityName )}
                        </GridTableSelect>
                      ) )
                  }
                </GridTableComponent>
              </div>
            </div>
            <div style={{display: 'flex' , justifyContent: 'center' , marginTop: '36px'}}>
              <MainButtons name={'취소'} clickEvent={closeEvent} width={'144px'} height={'40px'}
                           buttonType={'popupNormal'}/>
              <MainButtons name={'확인'} clickEvent={() => {
                processSelectedFacilityRes().then()
              }} width={'144px'} height={'40px'} buttonType={'popupCta'}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default ProcessTypeLinkedFacilityModal;

const BaseComponent = styled.div`
  width: 1016px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    cursor: move;
  }

  .modal_content {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 8px 16px 24px;
    justify-content: space-between;

    .list_divide {
      display: grid;
      grid-template-columns: 480px 480px;
      gap: 24px;
    }
  }
`

const ArrowButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid #D7DBEC;
  text-align: center;
  transform: rotate(90deg);
  font-weight: 700;
  cursor: pointer;
  margin: 0 8px;
  color: #5A607F;

  &:hover {
    border: 1px solid #0058ff;
    color: #0058ff;
    background-color: rgba(0, 88, 255, 0.1);
  }
`

const HoverDeleteButton = styled.div` 
 position: relative;
   
		.facility_cancel{
			width: 24px;
			height: 24px;
			border: 1px solid #d7dbec;
			border-radius: 4px;
			color: #5a607f;
			text-align: center;
			line-height: 24px;

			cursor: pointer;
			background-color: #fff;
			&:hover{
				background-color: #5a607f;
				color: #fff;
			}
			&>div{
				transform: rotateZ(45deg);
			}
		}
`
