import React from 'react';
import ModalBase from "./ModalBase";
import styled from 'styled-components';
import SelectEventButton from "../button/SelectEventButton";
import {Tooltip, Zoom} from "@mui/material";

interface Interface {
    confirmEvent:() => void
    noticeTitle?:string
    noticeContent?:string
}

const InfoNoticeViewModal = ({confirmEvent,noticeTitle,noticeContent}:Interface) => {

    return (
        <React.Fragment>
            <ModalBase zIndex={1500} modalContent={
                <ModalHeader>
                    <div className={'modal_title'} style={{backgroundColor:`${window.localStorage.getItem('auth')?.includes('admin')? '#CFFA61' : '#0058ff'}`}}>
                        {'이벤트&공지사항'}
                    </div>
                    <div style={{display:'flex', flexDirection:'column',justifyContent:'space-between', boxSizing:'border-box', padding:'24px 0 30px'}}>
                        <div className={'info'}>
                            <div className={'info_title'} style={{height:'48px'}}>{'제목'}</div>
                            <Tooltip componentsProps={{tooltip:{sx:{backgroundColor:'#D7DBEC', color:'#171725',display:`${Number(noticeTitle?.length) > 31 ? 'block' : 'none'}`}}}}
                                     title={<div style={{fontSize:'14px', fontWeight:700, fontFamily:'Nanum Gothic',padding:'6px', boxSizing:'border-box'}}>{noticeTitle}</div>}
                                     TransitionComponent={Zoom} enterDelay={300} placement={'right'} followCursor>
                                <div className={'info_target_title'} style={{height:'48px'}}>{noticeTitle}</div>
                            </Tooltip>
                        </div>
                        <div className={'info'}>
                            <div className={'info_title'} style={{height:'432px'}}>{'내용'}</div>
                            <div className={'info_target_content'} style={{height:'432px'}}>
                                <div className={'scroll_bar'}>
                                    {noticeContent}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <SelectEventButton buttonName={'확인'} buttonBorder={true} buttonStatus={false}
                                           buttonEvent={()=>confirmEvent()} width={'144px'}/>
                    </div>
                </ModalHeader>
            }/>
        </React.Fragment>
    );
};

export default InfoNoticeViewModal;

const ModalHeader = styled.div`
  position: absolute;
  width: 528px;
  height: 656px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .modal{
    &_title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 56px;
      font-size: 18px;
      color: #fff;
      font-weight: 700;
      box-sizing: border-box;
      padding: 0 16px;
      user-select: none;
    }
  }
  .info{
    display: grid;
    grid-template-columns: 104px 392px;
    box-sizing: border-box;
    padding: 0 16px;
        &_title{
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          background-color: #F5F6FA;
          color: #131523;
          font-size: 12px;
          font-weight: 800;
          border-bottom: 1px solid rgba(213, 215, 227, 0.5);
          padding: 0 16px;
          user-select: none;
        }
        &_target_title{
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: normal;
          line-height: 15px;
          color: #171725;
          padding: 16px;
          box-sizing: border-box;
          text-align: left;
          border-bottom: 1px solid rgba(213, 215, 227, 0.5);
        }
        &_target_content{
          font-size: 14px;
          font-weight: normal;
          white-space: pre-wrap;
          line-height: 20px;
          color: #171725;
          display: flex;
          padding: 16px;
          box-sizing: border-box;
          text-align: left;
          border-bottom: 1px solid rgba(213, 215, 227, 0.5);
          .scroll_bar{
            overflow-y: scroll;
           &::-webkit-scrollbar{
             display: block;
             width: 15px;
           } 
          }
        }
  }
`
