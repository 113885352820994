import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from '../locale/translation_en.json'
import ko from "../locale/translation_ko.json";

// const i18nLang = (changeLang: string, persistLang: string) => {

// @ts-ignore
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            ko: {
                translation: ko,
            },
        },
        lng: window.localStorage.getItem('lang') ?? "ko-KR",
        // lng: `${persistLang}`,
        fallbackLng: {
            "ko-KR": ["ko-KR"],
            default: ["en-US"],
        },
        debug: false,
        defaultNS: "translation",
        ns: "translation",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        }
    }).then();
// i18n.changeLanguage(changeLang)
// }

export default i18n;