import React, {useRef, useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";

import ConfirmPopup from "./ConfirmPopup";
import {useTranslation} from "react-i18next";
import MainButtons from "../button/MainButtons";
import {useLocation} from "react-router-dom";
import {dataValid , initModal} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import ToastPopup from "./ToastPopup";
import {tooltipModule} from "../../style/StyleComponent";

interface Props {
	uploadEvent: (data: any) => void
	closeEvent: () => void
	category: string
}

const FileUploadPop:React.FunctionComponent<Props> = ({uploadEvent, closeEvent, category}) => {
	const [fileState, setFileState] = useState<any>()
	const [uploadFileName, setUploadFileName] = useState<string>(  '')
	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)

	const { t }:any = useTranslation();
	const location = useLocation()
	const authValid = location.pathname.includes('/admin')
	const selectCompanyData = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)

	const uploadRef = useRef<HTMLInputElement>(null)
	const excelUploader = (e:any) => {
		setFileState(e.target.files[0])
		setUploadFileName(!e.target.files[0]? '' : e.target.files[0].name)
	}

	const formatType = (type: string) => {
		switch (type){
			case 'customer' : return '거래처기본'
			case 'customerModel' : return '거래처모델'
			case 'product' : return '품목'
			case 'rawMaterial' : return '원자재_기본'
			case 'subMaterial' : return '부자재_기본'
			case 'mold' : return '금형'
			case 'peripheral' : return '주변장치'
			case 'process/type' : return '공정'
			case 'facilityManagement/request' : return '설비수리요청'
			case 'facilityManagement/complete' : return '설비수리완료'
			case 'facilityProblem/type' : return '설비문제유형'
			case 'facilityProblem/type/request' : return '설비문제유형'
			case 'moldManagement/request' : return '금형수리요청'
			case 'moldManagement/complete' : return '금형수리완료'
			case 'rawMaterial/inventory' : return '원자재_재고'
			case 'subMaterial/inventory' : return '부자재_재고'
			case 'orders' : return '수주_정보'
			case 'deliver' : return '출하_정보'
			case 'workOrder' : return '작업지시서'
			case 'facility' : return '기계'
		}
	}

	const includesIgnoreCase = (fileName:string, value: string) => {
		const normalizedFileName = fileName.normalize('NFC').toLowerCase();
		const normalizedValue = value.normalize('NFC').toLowerCase();
		return !normalizedFileName.includes(normalizedValue);
	};
	const uploadFile = () => {
		if(fileState){
			if(!['.xlsx', '.xls'].some((v)=>(uploadFileName.includes(v)))){
				setDataModal({menu:'confirm', message:'잘못된 파일 형식 입니다. \n xlsx, xls 파일만 등록 가능합니다.'})
			}
			else if(includesIgnoreCase(uploadFileName, selectCompanyData.companyCode)){
				setDataModal({menu:'confirm', message:'등록할 업체의 양식과 다릅니다. \n 업체명(업체코드)을 다시 확인해 주세요.'})
			}
			else if(includesIgnoreCase(uploadFileName, dataValid(formatType(category),''))){
				setDataModal({menu:'confirm', message:`${formatType(category)} 양식이 아닙니다. \n 양식을 다시 확인해 주세요.`})
			}
			else if(includesIgnoreCase(uploadFileName,'양식')){
				setDataModal({menu:'confirm', message:'잘못된 엑셀 양식이거나 목록 파일 입니다. \n 양식을 받고 입력하여 등록해 주세요.'})
			}
			else if(fileState.size > 10485760){
				setDataModal({menu:'confirm', message:'업로드할 파일이 너무 큽니다. \n 파일 크기가 10Mb를 넘길 수 없습니다.'})
			}
			else{
				uploadEvent(fileState)
			}
		} else {
			setDataModal({menu:'warn', message:t('파일을 첨부해주세요.')})
		}
	}

	return (
		<ModalBase modalContent={
			<ModalFrame>
				{dataModal.menu === 'warn'&& <ToastPopup text={dataModal.message} state={'warn'}
																								 timeSet={700} closeEvent={()=>setDataModal(initModal)}/> }
				{dataModal.menu === 'confirm' &&
					<ConfirmPopup statusText={dataModal.message}
												clickEvent={()=>setDataModal(initModal)}/>}
				<div style={{backgroundColor:`${authValid? '#CFFA61' : '#0058ff'}`, height:'56px'}}/>
				<div className={'file_text'}>{t('엑셀(Excel) 파일을 등록해주세요.')}
					<div style={{textAlign:'center',marginTop:'10px',fontSize:'14px',fontWeight:'normal'}}>
						{t('* xlsx,xls 파일만 등록 가능합니다')}
					</div>
				</div>
				<div style={{display:'flex', justifyContent:'center'}}>
					{tooltipModule(
						dataValid(uploadFileName, t('파일을 선택해주세요.')),13,
						<label htmlFor={'excel_upload'} className={'file_name'}>
							{dataValid(uploadFileName, t('파일을 선택해주세요.'))}
						</label>
					)}
					<input type="file" ref={uploadRef} id={'excel_upload'} style={{display:'none'}}
								 onChange={excelUploader}
								 accept=".xlsx, .xls" />
					<MainButtons name={'파일 첨부'} width={'80px'} clickEvent={()=> {uploadRef.current?.click()}} />
					{/*<ControlButton name={'삭제'} clickEvent={() => resetFile()}/>*/}
				</div>
				<div style={{display:'flex', justifyContent:'center',marginBottom:'24px'}}>
					<MainButtons clickEvent={closeEvent} buttonType={'popupNormal'} width={'140px'} name={t('취소')}/>
					<MainButtons clickEvent={uploadFile} buttonType={'popupCta'} width={'140px'} name={t('등록')}/>
				</div>
			</ModalFrame>
		}/>
	);
};

export default FileUploadPop;

const ModalFrame = styled.div`
	position: absolute;
	min-width: 550px;
	height: 262px;
	border-radius: 6px;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	
	.file_text{
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		
	}
	
	.file_name{
		border: 1px solid #E5E5E5;
		border-radius: 4px;
		height: 36px;
		width: 350px;
		text-overflow: ellipsis;
		font-size: 14px;
		text-align: center;
		line-height: 36px;
		color: #7E84A3;
		box-sizing: border-box;
		overflow: hidden;
		cursor: pointer;
	}
`