import React , {useState , useEffect , useRef} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame , tooltipModule , validationValue ,
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  bomProductState ,
  persistBlockPage , persistCompanyData , persistPage ,
  searchPersistState , selectedCode , userPersistCompanyData ,
} from "../../../../../common/StateManage";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  allCheckedState , allPersistCheckedFunction , authValid , buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  filterCheckedRow , handleDecimalChange , hasDuplicate ,
  initError ,
  initModal , listLoadingCircle ,
  loadingRemove , menuVisitLogRegister , numberLocaleString ,
  onChangeArray , onChangeCheck , onChangeNumberArray ,
  removeCheckedFunction ,
  rowListDropDirection ,
  singleCheckedFunction
} from "../../../../../function/function";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import {
  Error_Type ,
  Modal_View_Type ,
  ProductInfoType ,
  QualityInspectionItem
} from "../../../../../types/Fundamental";

import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import InspectionFormModal from "../../../../../components/modal/InspectionFormModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import ListImageUploadModal from "../../../../../components/modal/ListImageUploadModal";
import TableValue from "../../../../../style/TableValue";
import MoldRegisterModal from "../../../../../components/modal/MoldRegisterModal";
import {useTranslation} from "react-i18next";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import AccountSelectModal from "../../../../../components/modal/AccountSelectModal";
import PaginationList from '../../../../../components/pagination/PaginationList'
import FacilityProductAutoCheckModal from '../../../../../components/modal/FacilityProductAutoCheckModal'
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import ProcessWaveProductRegModal from '../../../../../components/modal/ProcessWaveProductRegModal'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import FilterWithSearchBar from "../../../../../components/input/FilterWithSearchBar";
import BomViewerModal from "../../../../../components/modal/fas/product/BomViewerModal";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const ProductManagement = () => {
  const productTitle = ['거래처' , '모델' , 'CODE(품번)' , '품명' , '품목 종류' , '단위' ,'품목 중량', 'BOM' , '생산공정' , '캐비티' ,'Feeder', '기계' , '기준 UPH' , '금형' , '작업 표준서' , '초·중·종 검사' , '품목 이미지']
  const needValue = ['거래처' , '모델' , 'CODE(품번)' , '품명' , '품목 종류' , '단위' , '생산공정'];
  const companyCodeState= useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [searchState , setSearchState] = useRecoilState<{ input: string, value: string }>( searchPersistState )
  const [productList , setProductList] = useState<any[]>( [] )
  //품목 리스트 페이지 state
  const [productPage , setProductPage] = useRecoilState<number>( persistPage );
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage );
  const [totalPage , setTotalPage] = useState<number>( 1 )
  // 품목에 등록 가능한 공정종류 리스트와 페이지 state
  const [processList , setProcessList] = useState<any[]>( [] )
  const [processPage , setProcessPage] = useState<number>( 1 )
  const [processTotal , setProcessTotal] = useState<number>( 1 )
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [idIndex , setIdIndex] = useState<number>( 1 )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal );
  const [, setSelectIndex] = useState<number>( 0 )
  const [registerList , setRegisterList] = useState<any[]>( [] )
  const [hoverIcon , setHoverIcon] = useState<string>( modify )
  const [iconSelect , setIconSelect] = useState<number>( - 1 )
  const [focusList , setFocusList] = useState<number>( - 1 )
  const [selectProductObject , setSelectProductObject] = useState<ProductInfoType | any>( {} );
  const [selectCode , setSelectCode] = useRecoilState( selectedCode )
  const [isOpen,setIsOpen] = React.useState<boolean>(false);
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const initProductData = {
    customer:[], serialCode: '' , unit: '' , productName: '' ,
    type: '' , productCode: '' , uph: '' , processName: ''
  }
  const [bomRegister , setBomRegister] = useRecoilState<ProductInfoType>( bomProductState )
  const [inspectionData , setInspectionData] = useState<QualityInspectionItem | any>();
  const [errorState , setErrorState] = useState<Error_Type>( initError )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )

  const {t}: any = useTranslation()
  const formData = inspectionData?.inspectionItems?.map( ( v: any , index: number ) => ( Object.assign( inspectionData?.inspectionCriteria?.map( ( data: any ) => ( Object.assign( {} , {inspectionCriteria: data} ) ) )[index] , {inspectionItems: v} ) ) )
  const scrollRef = useRef<any>( null )
  const scrollToStart = () => {
    scrollRef.current.scrollLeft = 0
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    if( processList.length === 0 ){
      setDataModal( {menu: 'process' , message: ' * 등록 된 생산 공정이 없습니다.\n 페이지를 이동 하시겠습니까?'} )
    } else{
      scrollToStart()
      setIdIndex( idIndex + 1 )
      const rowInput = {
        id: idIndex ,
        companyCode: companyCodeState.companyCode ,
        customer: [] ,
        notice: '' ,
        processCode: '' ,
        processName: '' ,
        productName: '' ,
        serialCode: '' ,
        facilityCode: [] ,
        moldCode: [] ,
        type: '' ,
        uph: '0' ,
        unit: 'ea' ,
        image: [] ,
        workStandardImg: [] ,
        codeDoubleCheck: false ,
        cavity: '1' ,
        weight:'0', weightUnit:'g', length:'0'
        // loadTonRange:'',
        // slideCounterRange:'',
        // autoWorkOrderCheck: '0',
        // autoWorkHistoryCheck: '0',
        // autoProcess:{workOrder:'0', workHistory:'0'}
      }
      setRegisterList( [...registerList , rowInput] )
      setCheckedList( [...checkedList , rowInput.id] )
    }
  }
  const searchEventFunc = () => {
    // setPrevSearch(searchInput)
    setSearchState( ( prev ) => ( {...prev , value: prev.input} ) )
    setProductPage( 1 )
    setPageBlock( 0 )
    setCheckedList( [] )
  }
  const searchInitFunc = () => {
    // setSearchInput('')
    // setPrevSearch('')
    setSearchState( {input: '' , value: ''} )
    setProductPage( 1 )
    setPageBlock( 0 )
  }
  const onDeleteFun = () => {
    if( registerList.length === 0 ){
      setDataModal( {menu: 'select' , message: `${checkedList.length}개의 품목을 삭제 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'back' , message: '선택된 행의 정보를 삭제 하시겠습니까?'} )
    }
  }
  const productListRes = async () => {
    setErrorState( {notice: '* 목록을 가져오는 중 입니다.' , status: ''} )
    try {
      listLoadingCircle()
      const response = await client.get( `/product/list/${productPage}/10/${companyCodeState.companyCode}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      const valid = response.data
      if( valid.status !== 200 ){
        setErrorState( {notice: valid.message , status: valid.status} )
        setProductList( [] );
      } else{
        setTotalPage( data.total_page )
        setErrorState( {notice: t( '* 등록된 품목이 없습니다. 등록해 주세요.' ) , status: valid.status} )
        setProductList( dataValid( data.row , [] ) )
        // 더 이상 인피니티 스크롤을 사용해서 목록을 붙이지 않음
        // if(data.total_page > 1 && productPage > 1){
        // 	if(!data.row){
        // 		setErrorState({notice:'* 목록을 가져올 수 없습니다. 관리자에게 문의해 주세요.', status:100})
        // 	}
        // 	setProductList((prev:any)=> prev.concat(dataValid(data.row,[])))
        // }else{
        // 	if(!data.row){
        // 		setErrorState({notice:'* 목록을 가져올 수 없습니다. 관리자에게 문의해 주세요.', status:100})
        // 	}
        // 	setProductList(dataValid(data.row,[]))
        // }
      }
      loadingRemove()
    } catch ( e: any ) {
      console.log( e )
      loadingRemove()
    }
  }
  // const productListRes = ()=> ListCustomHooks(`/product/list/${productPage}/10/${companyCodeState.companyCode}`,productPage,setTotalPage,setPageBlock,setProductList,setErrorState,setPrevSearch,setSearchInput)
  // const productSearchListRes = () => ListSearchPostCustomHooks(`/product/search` , productPage , setTotalPage , setPageBlock , setProductList , setErrorState , {page: productPage , size: 10 , keyword: searchState.value , companyCode: companyCodeState.companyCode})
  const productSearchListRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.post( `/product/search` , {
        //${productPage}/10/${companyCodeState.companyCode}/${encodeURIComponent(searchInput)
        companyCode: companyCodeState.companyCode ,
        keyword: searchState.value ,
        page: productPage ,
        size: 10,
        filter:categorySelect.type
      } , {
                                            headers: {Authorization}
                                          } )
      const data = response.data.data
      const valid = response.data
      setTotalPage( data.total_page || data.totalPage )
      if( valid.status !== 200 ){
        setErrorState( {notice: valid.message , status: valid.status} )
        setProductList( [] );
      } else{
        setErrorState( {notice: t( '* 검색된 품목이 없습니다.' ) , status: valid.status} )
        setProductList( dataValid( data.row , [] ) )
        // 더 이상 인피니티 스크롤을 사용해서 목록을 붙이지 않음
        // if((data.total_page || data.totalPage) > 1 && productPage > 1){
        // 	if(!data.row){
        // 		setErrorState({notice:'* 목록을 가져올 수 없습니다. 관리자에게 문의해 주세요.', status:100})
        // 	}
        // 	setProductList((prev:any)=> prev.concat(dataValid(data.row,[])))
        // }else{
        // 	if(!data.row){
        // 		setErrorState({notice:'* 목록을 가져올 수 없습니다. 관리자에게 문의해 주세요.', status:100})
        // 	}
        // 	setProductList(dataValid(data.row,[]))
        // }
      }
      loadingRemove()
    } catch ( e ) {
      loadingRemove()
      console.log( e )
      setErrorState( {notice: '* 목록을 가져올 수 없습니다. 관리자에게 문의해 주세요.' , status: 100} )
      setProductList( [] )
    }
  }
  const productRegisterRes = async () => {
    if( ( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.customer[0]?.customerCode ) ) ){
      setDataModal( {menu: 'confirm' , message: '거래처와 모델을 선택해 주세요.'} )
    }
      // else if((filterCheckedRow(registerList, checkedList,'id').find((v)=>!v.customer[0]?.customerModelCode))){
      // 	setDataModal({menu:'confirm', message:'거래처 모델을 선택해 주세요.'})
    // }
    else if( ( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.serialCode ) ) ){
      setDataModal( {menu: 'confirm' , message: '품목 코드를 입력해 주세요.'} )
    } else if( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '품목 코드 중복 확인을 해주세요.'} )
    }
    else if( ( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.productName ) ) ){
      setDataModal( {menu: 'confirm' , message: '품목명을 입력해 주세요.'} )
    } else if( hasDuplicate(registerList.filter(item => checkedList.includes(item.id)),'productName') ){
      setDataModal( {menu: 'confirm' , message: '중복 되는 품명이 입력 되었습니다.'} )
    } else if( ( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.type ) ) ){
      setDataModal( {menu: 'confirm' , message: '폼목 종류를 선택해 주세요.'} )
    }
      // else if((filterCheckedRow(registerList, checkedList,'id').find((v)=>!v.unit))){
      // 	setDataModal({menu:'confirm', message:'단위를 선택해 주세요.'})
    // }
    else if( ( filterCheckedRow( registerList , checkedList , 'id' ).find( ( v ) => !v.processCode ) ) ){
      setDataModal( {menu: 'confirm' , message: '생산 공정을 선택해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/product/register' ,
                                            filterCheckedRow( registerList , checkedList , 'id' ) ,
                                            {headers: {Authorization}} )
        const data = response.data
        if( response.data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: t( data.message ) , status: data.status} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: t( '품목이 등록 되었습니다.' )} )
            setCheckedList( [] )
            setRegisterList( [] )
            if( productPage === 1 ){
              if( searchState.value === '' ){
                productListRes().then()
              } else{
                productSearchListRes().then()
              }
            } else{
              setProductPage( 1 )
            }
            loadingRemove()
          } , 500 )
          setDataModal( initModal )
        }
      } catch ( e ) {
        console.log( e )
        //@ts-ignore
        setDataModal( {menu: 'error' , message: t( '등록 실패. 관리자에게 문의해주세요.' )} )
        loadingRemove()
      }
    }
  }
  //제품 삭제 함수
  const productDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( `/product/delete` ,
                                          checkedList
        , {headers: {Authorization}} )
      const data = response.data
      const bomCaseArray = data.data?.bom?.map( ( v: any ) => ( {
        code: v.code ,
        case: v.used + '의 하위 BOM으로 등록 되어 있습니다.'
      } ) )
      const orderCaseArray = data.data?.orders?.map( ( v: any ) => ( {code: v , case: '수주가 등록 되어 있습니다.'} ) )
      const workOrderCaseArray = data.data?.workOrder?.map( ( v: any ) => ( {code: v , case: '작업지시서가 등록 되어 있습니다.'} ) )
      const caseArray = [bomCaseArray , orderCaseArray , workOrderCaseArray].flat().filter( ( v ) => ( v ) )
      if( data.status === 200 ){
        setTimeout( () => {
          setProductPage( 1 )
          setTotalPage( 1 )
          setIconSelect( - 1 )
          setDataModal( {menu: 'success' , message: '선택한 품목이 삭제 되었습니다.'} )
          removeCheckedFunction( setProductList , productList , setCheckedList , checkedList , 'productCode' )
          scrollToStart()
          if( productPage === 1 ){
            if( searchState.value === '' ){
              productListRes().then()
            } else{
              productSearchListRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setProductPage( 1 )
          setTotalPage( 1 )
          setIconSelect( - 1 )
          if( ( data.data?.bom?.length === 0 && data.data?.orders?.length === 0 && data.data?.workOrder?.length === 0 ) ){
            setDataModal( {menu: 'success' , message: '선택한 품목이 삭제 되었습니다.'} )
          } else{
            setDataModal( {menu: 'deleteCase'} )
          }
          setDeleteCaseArray( caseArray )
          setCheckedList( checkedList.filter( ( v: string ) => !data.data.success.includes( v ) ) )
          setProductList( productList.filter( ( v: any ) => !data.data.success.includes( v['productCode'] ) ) )
          scrollToStart()
          if( productPage === 1 ){
            if( searchState.value === '' ){
              productListRes().then()
            } else{
              productSearchListRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      loadingRemove()
      setDataModal( {menu: 'error' , message: e.message} )
      console.log( e )
    }
  }

  const inspectionItemDataRes = async () => {
    try {
      const response = await client.post( `/quality/test/read` , {
        productCode: selectProductObject.productCode
      } , {headers: {Authorization}} )
      const data = response.data.data;
      setInspectionData( data )
    } catch ( e ) {
      console.log( e )
    }
  }
  //제품 수정
  const productUpdateRes = async () => {
    if( !productList[iconSelect].customer[0]?.customerCode ){
      setDataModal( {menu: 'confirm' , message: '거래처를 선택해 주세요.'} )
    }
    else if( !productList[iconSelect].customer[0]?.customerModelCode ){
      setDataModal( {menu: 'confirm' , message: '거래처 모델명이 없습니다.'} )
    }
    else if( !productList[iconSelect].serialCode ){
      setDataModal( {menu: 'confirm' , message: '품목 코드가 없습니다.'} )
    }
    else if( !productList[iconSelect].codeDoubleCheck ){
      setDataModal( {menu: 'confirm' , message: '품목 코드 중복 확인을 해주세요.'} )
    }
    else if( !productList[iconSelect].productName ){
      setDataModal( {menu: 'confirm' , message: '품명이 없습니다.'} )
    }
    else if( !productList[iconSelect].serialCode ){
      setDataModal( {menu: 'confirm' , message: '품목 코드를 입력해 주세요.'} )
    }
    else if( !productList[iconSelect].processName ){
      setDataModal( {menu: 'confirm' , message: '생산 공정을 선택해 주세요.'} )
    }
    else{
      try {
        setDataModal( initModal )
        dataAccessLoading()
        const response = await client.post( `/product/update` , {
          companyCode: companyCodeState.companyCode ,
          ...productList[iconSelect] ,
          uph: dataValid(productList[iconSelect].uph,'0'),
          image: dataValid( productList[iconSelect].image.flat( 1 ) , [] ) ,
          workStandardImg: dataValid( productList[iconSelect].workStandardImg , [] ) ,
          facilityCode: dataValid( productList[iconSelect].facilityCode , [] ) ,
          moldCode: dataValid( productList[iconSelect].moldCode , [] )
        } , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message , status: data.status} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: '수정 되었습니다.' , status: data.status} )
            setProductList( productList.map( ( v ) => {return v.productCode === selectCode ? {...v , ...productList[iconSelect]} : v} ) )
            if( searchState.value === '' ){
              productListRes().then()
            } else{
              productSearchListRes().then()
            }
            setCheckedList( [] )
            setIconSelect( - 1 )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  console.log(productList)
  //품목 시리얼코드 중복체크
  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = registerList.filter( ( v ) => ( v.serialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/product/serial/check' , {
        companyCode: companyCodeState.companyCode ,
        serialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...registerList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        serialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }
      if( registerList.length !== 0 ){
        if( duplicateCount[0] === 0 ){
          if( regCount !== 0 ){
            setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
            setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
          } else{
            setRegisterList( copyRowList )
            setDataModal( {menu: 'success' , message: '중복되지 않은 시리얼CODE 입니다.'} )
          }
        } else{
          if( duplicateCount.length === 1 ){
            setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
          } else{
            if( regCount < duplicateCount.length ){
              setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
            } else{
              setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
            }
          }
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      } else{
        if( duplicateCount[0] === 0 ){
          const copyMaterialList = [...productList]
          copyMaterialList[index] = {
            ...copyMaterialList[index] , codeDoubleCheck: true ,
            serialCode: serialCode
          }
          setProductList( copyMaterialList )
          setDataModal( {menu: 'confirm' , message: '중복되지 않은 시리얼CODE 입니다.'} )
        } else{
          setInputSerialCode( serialCode + `(${duplicateCount[0]})` )
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }
  const processListRes = async () => {
    try {
      const response = await client.get( `/process/type/list/${processPage}/10/${companyCodeState.companyCode}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      setProcessTotal( data.total_page )
      if( data.total_page > 1 && processPage > 1 ){
        setProcessList( ( prev: any ) => prev.concat( data.row ) )
      } else{
        setProcessList( data.row )
      }
    } catch ( e ) {
      console.log( e )
    }
  }
  useEffect( () => {
      inspectionItemDataRes().then()
  } , [selectProductObject.productCode])

  useEffect( () => {
    if( companyCodeState.companyCode !== undefined ){
      if( searchState.value === '' ){
        productListRes().then()
      } else{
        productSearchListRes().then()
      }
    }
  } , [companyCodeState , productPage , searchState.value,categorySelect.type] )

  useEffect( () => {
    processListRes().then()
  } , [companyCodeState , processPage] )
  const selectFacilityPersist = () => {
    if( registerList.length === 0 ){
      return productList[dataModal.index]?.facilityCode
    } else{
      if( registerList[dataModal.index]?.facilityCode === '' ){
        return []
      } else{
        return registerList[dataModal.index]?.facilityCode
      }
    }
  }
  const selectMoldPersist = () => {
    if( registerList.length === 0 ){
      return productList[dataModal.index]?.moldCode
    } else{
      if( registerList[dataModal.index]?.moldCode === '' ){
        return []
      } else{
        return registerList[dataModal.index]?.moldCode
      }
    }
  }
  const checkboxValid = () => {
    if( registerList.length !== 0 ){
      return {list: registerList , id: 'id'}
    } else{
      return {list: productList , id: 'productCode'}
    }
  }
  const onDataResetFunc = () => {
    setProductPage( 1 );
    setPageBlock(0)
    setTotalPage(1)
    setRegisterList( [] );
    setCheckedList( [] );
    setProductList([])
    setErrorState(initError)
    setSearchState( {input: '' , value: ''} )
    setProcessPage( 1 )
    setIconSelect( - 1 );
  }

  const [initValid, setInitValid] = useState(true)

  React.useEffect(()=>{
    setInitValid(false)
  },[])

  React.useEffect(()=>{
    if(!initValid){
      onDataResetFunc()
    }
  },[companyCodeState.companyCode])

  const cancelFuc = () => {
    setHoverIcon( modify );
    setIconSelect( - 1 );
    setCheckedList( [] )
    setDataModal( initModal )
    if( searchState.value === '' ){
      setProductList( productList.map( ( v: any ) => {return v.productCode === selectCode ? {...v , ...selectProductObject} : v} ) )
    } else{
      productSearchListRes().then()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( registerList.length > 0 || iconSelect !== - 1 ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [registerList.length , iconSelect] );

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'품목 기본정보').then()
    }
  },[])

  const customerCount = ( customer: any ) => customer.filter( ( v: any , i: number , arr: any ) => arr.findIndex( ( el: any ) => ( el.customerCode === v.customerCode ) ) === i )
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}
      {dataModal.menu === 'insReg' &&
          <InspectionFormModal
              inspectionLength={formData?.length <= 8 ? 8 : formData?.length}
              productData={selectProductObject} getData={formData} imageUrlList={selectProductObject?.imageUrl}
              openEvent={()=>{setIsOpen(!isOpen)}} isOpenedProps={isOpen}
              cancelEvent={() => {setDataModal( initModal )}}
              sampleIdx={Number( inspectionData.inspectionSample )}/>
      }

      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'CODE'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             if( registerList.length !== 0 ){
                               const copyRowList = [...registerList]
                               copyRowList[checkIndex] = {
                                 ...copyRowList[checkIndex] ,
                                 codeDoubleCheck: true ,
                                 serialCode: inputSerialCode
                               }
                               setRegisterList( copyRowList )
                             } else{
                               const copyMaterialList = [...productList]
                               copyMaterialList[checkIndex] = {
                                 ...copyMaterialList[checkIndex] , codeDoubleCheck: true ,
                                 serialCode: inputSerialCode
                               }
                               setProductList( copyMaterialList )
                             }
                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {dataModal.menu === 'image' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view}
                                title={dataModal.view ? '품목 이미지 보기' : '품목 이미지 등록'}
                                receiveKey={registerList.length === 0 ? productList[dataModal.index].image.flat( 1 ) : registerList[dataModal.index].image}
                                receiveUrl={registerList.length === 0 ? productList[dataModal.index].imageUrl : registerList[dataModal.index].imageUrl}
                                clickEvent={( v ) => {
                                  registerList.length === 0 ?
                                    Object.assign( productList[dataModal.index] , {image: v.key , imageUrl: v.url} )
                                    :
                                    Object.assign( registerList[dataModal.index] , {image: v.key , imageUrl: v.url} );
                                }}
                                cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'workStandard' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view} workImgLength={true}
                                title={dataModal.view ? '작업표준서 보기' : '작업표준서 등록'}
                                receiveKey={registerList.length === 0 ? productList[dataModal.index].workStandardImg : registerList[dataModal.index].workStandardImg}
                                receiveUrl={registerList.length === 0 ? productList[dataModal.index].workStandardImgUrl : registerList[dataModal.index].workStandardImgUrl}
                                clickEvent={( v ) => {
                                  if( registerList.length === 0 ){
                                    Object.assign( productList[dataModal.index] , {
                                      workStandardImg: v.key ,
                                      workStandardImgUrl: v.url
                                    } );
                                  } else{
                                    Object.assign( registerList[dataModal.index] , {
                                      workStandardImg: v.key ,
                                      workStandardImgUrl: v.url
                                    } )
                                  }
                                }}
                                cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'preview' &&
          <DetailImageView image={productList[dataModal.index].imageUrl[0]}
                           closeMenu={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'*삭제 진행 시 해당 정보는 복구되지 않습니다.'}
                              clickEvent={() => {
                                productDeleteRes().then();
                                setDataModal( initModal )
                              }}
                              cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message}
                        clickEvent={() => {
                          setDataModal( initModal )
                        }
                        }/>}

      {dataModal.menu === 'process' &&
        ( buttonAuthValid( 'create' , '13' ) ?
            <ConfirmPopup statusText={'등록된 생산 공정이 없습니다.'}
                          noticeText={'* 공정 선택 없이 품목을 등록 할 수 없습니다.'}
                          clickEvent={() => {
                            setDataModal( initModal )
                          }
                          }/>
            :
            <SelectConfirmModal statusText={dataModal.message} noticeText={'* 공정 선택 없이 품목을 등록 할 수 없습니다.'}
                                clickEvent={() => window.location.href = authValid() ? '/admin/fas/type_process' : '/fas/type_process'}
                                cancelEvent={() => {
                                  setDataModal( initModal )
                                }
                                }/>
        )
      }

      {dataModal.menu === 'facility' &&
        // <FacilityRegisterModal companyCode={companyCodeState.companyCode} viewType={dataModal.view}
        // 											 receivedFacility={(e)=>{
        // 												 if(registerList.length === 0){
        // 													 setProductList(productList.map((v:any)=>{return v.productCode === selectCode? {...v, facilityCode: e} : v}))
        // 												 }else{
        // 													 setRegisterList(registerList.map((v:any,i:number)=>{return i === dataModal.index? {...v, facilityCode: e} : v}))
        // 												 }}}
        // 											 selectProduct={registerList.length === 0? productList[dataModal.index] : registerList[dataModal.index]}
        // 											 cancelEvent={()=>setDataModal(initModal)}
        // 											 selectedFacility={selectFacilityPersist()}/>
          <FacilityProductAutoCheckModal companyCode={companyCodeState.companyCode} viewType={dataModal.view}
                                         receivedFacility={( e ) => {
                                           if( registerList.length === 0 ){
                                             setProductList( productList.map( ( v: any ) => {
                                               return v.productCode === selectCode ? {
                                                 ...v ,
                                                 facilityCode: e.facilityCode ,
                                                 autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                                 autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                                 loadTonRange: e.loadTonRange ,
                                                 slideCountRange: e.slideCountRange ,
                                                 countType: e.countType ,
                                                 facilityStatus: e.facilityStatus,
                                                 channel: e.channel
                                               } : v
                                             } ) )
                                           } else{
                                             setRegisterList( registerList.map( ( v: any , i: number ) => {
                                               return i === dataModal.index ? {
                                                 ...v ,
                                                 facilityCode: e.facilityCode ,
                                                 autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                                 autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                                 loadTonRange: e.loadTonRange ,
                                                 slideCountRange: e.slideCountRange ,
                                                 countType: e.countType ,
                                                 facilityStatus: e.facilityStatus
                                               } : v
                                             } ) )
                                           }
                                         }}
                                         selectProduct={registerList.length === 0 ? productList[dataModal.index] : registerList[dataModal.index]}
                                         cancelEvent={() => setDataModal( initModal )}
                                         selectedFacility={selectFacilityPersist()}/>
      }

      {dataModal.menu === 'mold' &&
          <MoldRegisterModal
              selectProduct={registerList.length === 0 ? productList[dataModal.index] : registerList[dataModal.index]}
              companyCode={companyCodeState.companyCode} modified={dataModal.index !== -1}
              receivedMold={( e ) => {
                if( registerList.length === 0 ){
                  setProductList( productList.map( ( v: any ) => {
                    return v.productCode === selectCode ? {
                      ...v ,
                      moldCode: e
                    } : v
                  } ) )
                } else{
                  setRegisterList( registerList.map( ( v: any , i: number ) => {
                    return i === dataModal.index ? {
                      ...v ,
                      moldCode: e
                    } : v
                  } ) )
                }
              }}
              viewType={dataModal.view}
              cancelEvent={() => setDataModal( initModal )}
              selectedMold={selectMoldPersist()}/>}

      {(bomRegister.productCode !== '' && dataModal.menu !== 'bomMove') &&
          <BomViewerModal bomDetailData={bomRegister} closeEvent={()=> setBomRegister( initProductData )} manageButton={true} />
          // <BillOfMaterialModal bomDetailData={bomRegister} authValid={authValid()}
          //                      bomView={
          //                        iconSelect === - 1 ? ( productList?.find( ( el: any ) => ( el.productCode === selectCode ) ).bom !== 'empty' ) : false
          //                      }
          //                      bomStatus={( e ) => setProductList( productList.map( ( v: any ) => {
          //                        return v.productCode === selectCode ? {
          //                          ...v ,
          //                          bom: e
          //                        } : v
          //                      } ) )}
          //                      closeEvent={() => setBomRegister( {productCode: ''} )}/>
      }

      {dataModal.menu === 'bomMove' &&
        <SelectConfirmModal statusText={dataModal.message}
                            clickEvent={()=>{
                              window.open(authValid()? '/admin/fas/product/bom' : '/fas/product/bom')
                              setDataModal(initModal)
                            }}
                            cancelEvent={()=> {
                              setDataModal( initModal )
                              setBomRegister( initProductData )
                            }}/>
      }



      {dataModal.menu === 'customer' &&
          <AccountSelectModal initCustomerData={
            registerList.length === 0 ?
              productList.find( ( v ) => v.productCode === selectCode ).customer.map( ( el: any ) => ( {
                customerName: el.customerName ,
                customerCode: el.customerCode
              } ) ) :
              registerList.find( ( v , i ) => i === dataModal.index ).customer.map( ( el: any ) => ( {
                customerName: el.customerName ,
                customerCode: el.customerCode
              } ) )}
                              initModelData={
                                registerList.length === 0 ?
                                  productList.find( ( v ) => v.productCode === selectCode ).customer.map( ( el: any ) => ( {
                                    customerName: el.customerName ,
                                    customerCode: el.customerCode ,
                                    customerModelName: el.customerModelName ,
                                    customerModelCode: el.customerModelCode
                                  } ) ) :
                                  registerList.find( ( v , i ) => i === dataModal.index ).customer.map( ( el: any ) => ( {
                                    customerName: el.customerName ,
                                    customerCode: el.customerCode ,
                                    customerModelName: el.customerModelName ,
                                    customerModelCode: el.customerModelCode
                                  } ) )}
                              receivedData={( e ) => {
                                if( registerList.length === 0 ){
                                  setProductList( productList.map( ( v: any ) => {
                                    return v.productCode === selectCode ? {
                                      ...v ,
                                      customer: e
                                    } : v
                                  } ) )
                                } else{
                                  setRegisterList( registerList.map( ( v: any , i: number ) => {
                                    return i === dataModal.index ? {
                                      ...v ,
                                      customer: e
                                    } : v
                                  } ) )
                                }
                              }} closeModal={() => setDataModal( initModal )}/>}



      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'*선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={productRegisterRes}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={productUpdateRes}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              // noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              noticeText={''}
                              clickEvent={() => {
                                if( registerList.length !== 0 ){
                                  removeCheckedFunction( setRegisterList , registerList , setCheckedList , checkedList , 'id' )
                                  setSelectProductObject( {} )
                                  setDataModal( initModal )
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'integrated' &&
          <ProcessWaveProductRegModal responseAPI={()=>{
            setDataModal( initModal )
            if( searchState.value === '' ){
              productListRes().then()
            } else{
              productSearchListRes().then()
            }
          }} cancelEvent={() => {setDataModal( initModal )}}/>
      }
      {dataModal.menu === 'excel' &&
        <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
          <React.Fragment>
            <ExcelApiButton category={'product'} type={'download'} companyData={companyCodeState}
                            disabled={productList.length === 0 && registerList.length === 0}/>
            <ExcelApiButton category={'product'} type={'format'} companyData={companyCodeState}
                            disabled={buttonAuthValid( 'create' , '8' )}
                            formatText={'모델이 등록 되지 않은 거래처는 목록에 보이지 않습니다.'}
                            noticeModal={( processList?.length === 0 ) ? '생산 공정이 없이 품목을 등록할 수 없습니다. \n 공정 종류 관리에서 공정을 등록해 주세요.' : ''}/>
            <ExcelApiButton category={'product'} disabled={buttonAuthValid( 'create' , '8' )} type={'upload'}
                            companyData={companyCodeState}/>
          </React.Fragment>
        }/>
      }

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 품목 기본정보'} unitCase={'MenuTitle'}/>
          </div>
          {
            // ((window.localStorage.getItem('companyID') === 'ok1957') || window.localStorage.getItem('auth')?.includes('admin') )&&
              <MainButtons name={'BOM 관리 페이지'} width={'140px'} marginDis={true}
                           disabled={!companyCodeState.companyCode}
                           clickEvent={()=>{
                window.location.href = authValid()? '/admin/fas/product/bom' : '/fas/product/bom'
              }}/>}
        </div>

        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between'}}>
              <MenuTitle title={'품목 기본정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                {/*<SearchBar disabled={companyCodeState.companyCode === undefined || iconSelect !== - 1}*/}
                {/*           keyPressDisable={true}*/}
                {/*           keyFunction={( e ) => {*/}
                {/*             setSearchState( ( prev ) => ( {...prev , input: e.target.value.trimStart()} ) )*/}
                {/*           }}*/}
                {/*           value={searchState.input} clickEvent={searchEventFunc}*/}
                {/*           clearButtonAccess={searchState.input !== ''} clearButton={searchInitFunc}/>*/}

                <FilterWithSearchBar disabled={companyCodeState.companyCode === undefined || iconSelect !== - 1}
                                     keyPressDisable={true}
                                     keyFunction={( e ) => {
                                       setSearchState( ( prev ) => ( {...prev , input: e.target.value.trimStart()} ) )
                                     }}
                                     value={searchState.input} clickEvent={searchEventFunc}
                                     clearButtonAccess={searchState.input !== ''} clearButton={searchInitFunc}
                                     margin={'0'}
                                     selectKeyword={categorySelect.value}
                                     categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                     categoryTypeArray={['@','customer_name','customer_model_name','serial_code','product_name','type','process_name']}
                                     categoryValueArray={['전체','거래처','모델','CODE(품번)','품명','품목 종류','생산공정']}
                                     placeholder={t('검색')}
                />

                {/*<MainButtons name={'바코드 미리보기'} width={'112px'} disabled={true}/>*/}
                {/*<MainButtons name={'공정순 품목 등록'} width={'112px'} disabled={!companyCodeState.companyCode}*/}
                {/*             clickEvent={() => setDataModal( {menu: 'integrated'} )}*/}
                {/*  // clickEvent={()=>setDataModal({menu:'warn', message:'개발 진행중 입니다.'})}*/}
                {/*/>*/}
                <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
                {/*{companyCodeState.companyCode !== undefined &&*/}
                {/*    <MainButtons name={'행추가'} width={'80px'}*/}
                {/*                 disabled={buttonAuthValid( 'create' , '8' ) || ( registerList.length === 0 && checkedList.length > 0 ) || iconSelect !== - 1 || errorState.status !== 200}*/}
                {/*                 clickEvent={addListRow}/>}*/}
                {/*<MainButtons name={'저장'} buttonType={'cta'} width={'80px'}*/}
                {/*             disabled={buttonAuthValid( 'create' , '8' ) || companyCodeState.companyCode === undefined || iconSelect !== - 1 || registerList.length === 0 || checkedList.length === 0}*/}
                {/*             clickEvent={() => {*/}
                {/*               if( checkedList.length === registerList.length ){*/}
                {/*                 productRegisterRes().then()*/}
                {/*               } else{*/}
                {/*                 setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. \n 등록을 진행 하시겠습니까?'} )*/}
                {/*               }*/}
                {/*             }}/>*/}
                {checkedList?.length !== 0 &&
                  <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                              marginDis={( registerList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
                              disabled={
                                buttonAuthValid( 'rowDelete' , '8' ) ?
                                  registerList.length === 0
                                  :
                                  buttonAuthValid( 'delete' , '8' ) ||
                                  (checkedList.length === 0 )}
                              clickEvent={onDeleteFun}/>}

                {(registerList?.length === 0 && iconSelect === -1 && checkedList?.length === 0) &&
                  <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                              marginDis={true}
                              disabled={
                                buttonAuthValid( 'create' , '8' ) || companyCodeState.companyCode === undefined}
                              clickEvent={() => window.location.href = authValid() ? '/admin/fas/product/register' : `/fas/product/register`}/>}
              </div>
            </div>

            <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                                scrollY_Hide={registerList?.length < 10}
                                titleCheckDisabled={iconSelect !== - 1}
                                scrollWidth={2350}
                                height={538}
                                tempCol={'40px repeat(2, 180px) 220px 174px 108px 108px 140px 96px 172px 108px 160px 96px 140px 96px 128px 108px 96px'}>
              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox"
                         style={{
                           pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` ,
                           opacity: `${iconSelect === - 1 ? 1 : 0.5}`
                         }}
                         onChange={( e  ) => allPersistCheckedFunction( e.target.checked , {
                           dataList: checkboxValid().list ,
                           checkedList: checkedList ,
                           id: checkboxValid().id ,
                           setState: setCheckedList
                         } )}
                         checked={allCheckedState( {
                                                     dataList: checkboxValid().list ,
                                                     checkedList: checkedList ,
                                                     id: checkboxValid().id
                                                   } )}/>
                </label>
                {productTitle.map( ( v ) => (
                  <TableValue title={v} key={v} viewType={iconSelect !== - 1 || registerList.length !== 0}
                              requiredValue={needValue}/> ) )}
              </div>

              {errorState.status === 100 ?
                listResultMessage( authValid() , dataValid( errorState.notice ) , {
                  width: 1210 ,
                  admin: 480 ,
                  user: 480
                } )
                :
                registerList.length === 0 && productList.length === 0 ? searchState.value === '' ? errorState.status !== 200 ?
                      listResultMessage( authValid() , dataValid( errorState.notice , t( '* 업체를 선택해 주세요.' ) ) , {
                        width: 1210 ,
                        admin: 480 ,
                        user: 480
                      } )
                      :
                      listResultMessage( authValid() , dataValid( errorState.notice , '' ) ,
                                         {
                                           width: 1210 ,
                                           admin: 480 ,
                                           user: 480
                                         } , () => window.location.href = authValid() ? '/admin/fas/product/register' : `/fas/product/register` , buttonAuthValid( 'create' , '8' ) ,'등록')
                    :
                    listResultMessage( authValid() , dataValid( errorState.notice , t( '* 검색 결과가 없습니다.' ) ) , {
                      width: 1210 ,
                      admin: 480 ,
                      user: 480
                    } )
                  :
                  registerList.map( ( v , i , arr ) => (
                    <div className={'grid-row-list'}>
                      <label className={'checkbox'}>
                        <ListInnerInput type={'check'} name={'id'}
                                        onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                        checked={checkedList.includes( v.id )}/>
                      </label>
                      <ListInnerInput type={'accountSearch'} name={'customerName'}
                                      hoverText={customerCount( v.customer ).map( ( v: any ) => ( v.customerName ) ).join( ',' )}
                                      inputData={dataValid( customerCount( v.customer )[0]?.customerName ) + ( dataValid( ( customerCount( v.customer ).length - 1 ) , 0 ) > 0 ? ` 외 ${customerCount( v.customer ).length - 1}` : '' )}
                                      placeholder={'거래처'}
                                      onChange={( e: any ) => onChangeArray( i , e , registerList , setRegisterList )}
                                      clickButton={() => {setDataModal( {menu: 'customer' , index: i} )}}/>
                      <ListInnerInput type={'accountSearch'} name={'customerModelName'}
                                      hoverText={v.customer.map( ( v: any ) => ( v.customerModelName ) ).join( ',' )}
                                      inputData={dataValid( v.customer[0]?.customerModelName ) + ( dataValid( ( v.customer.length - 1 ) , 0 ) > 0 ? ` 외 ${v.customer.length - 1}` : '' )}
                                      placeholder={'모델'}
                                      onChange={( e: any ) => onChangeArray( i , e , registerList , setRegisterList )}
                                      clickButton={() => {
                                        setDataModal( {menu: 'customer' , index: i} )
                                        // if(!v.customerCode){
                                        // 	setDataModal({menu:'confirm', message:'거래처를 선택해 주세요.'})
                                        // }else{
                                        // 	setDataModal({menu: 'customerModel', index: i})
                                        // }
                                      }}
                      />

                      <ListInnerInput type={'withButton'} width={'120px'}
                                      name={'serialCode'} placeholder={'-'}
                                      inputData={v.serialCode} inputDisabled={v.codeDoubleCheck}
                                      onChange={( e ) => onChangeArray( i , e , registerList , setRegisterList , true )}
                                      clickButton={() => {serialDoubleCheck( v.serialCode , i ).then()}}
                                      buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                                      buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.serialCode}/>


                      <ListInnerInput type={'text'} name={'productName'} inputData={v.productName} placeholder={'품목명'}
                                      onChange={( e: any ) => onChangeArray( i , e , registerList , setRegisterList  )}/>
                      <ListInnerInput type={'drop'} name={'type'} inputData={v.type}
                                      selectDrop={( v ) => {
                                        setRegisterList( registerList.map( ( v ) => {return v.id === selectCode ? {...v , ...registerList[i]} : v} ) )
                                        Object.assign( registerList[i] , {type: v} )
                                      }
                      }
                                      dropTopDirection={rowListDropDirection( arr , i , productList )}
                                      dropMenuList={['완제품' , '반제품' , '재공품']}/>
                      <ListInnerInput type={'drop'} name={'unit'} inputData={v.unit}
                                      dropTopDirection={rowListDropDirection( arr , i , productList )}
                                      selectDrop={( v ) => {
                                        setSelectCode( v.id )
                                        setRegisterList( registerList.map( ( v ) => {return v.id === selectCode ? {...v , ...registerList[i]} : v} ) )
                                        Object.assign( registerList[i] , {unit: v , uph: ''} )
                                      }}
                                      dropMenuList={['ea' , 'g' , 'kg' , 'Ton']}/>

                      <span style={{display:'grid', gridTemplateColumns:'1fr 1fr' }}>
                        <ListInnerInput type={'unit'} name={'weight'} placeholder={'-'} width={'110'}
                                        unit={dataValid( v.weightUnit , 'g' )}
                                        inputData={v.weight.includes( '.' ) ? v.weight : numberLocaleString( v.weight )} limit={15}
                                        onChange={( e ) => {handleDecimalChange( i , e , registerList , setRegisterList , dataValid( v.weightUnit , 'g' ) )}}/>

                          <ListInnerInput type={'drop'} inputData={dataValid( v.weightUnit , 'g' )} width={'60'}
                                          dropTopDirection={rowListDropDirection( arr , i )}
                                          selectDrop={( v ) => {
                                            setSelectCode( v.id )
                                            setRegisterList( registerList.map( ( v ) => {return v.id === selectCode ? {...v , ...registerList[i]} : v} ) )
                                            Object.assign( registerList[i] , {weightUnit: v} )
                                          }}
                                          dropMenuList={['ea' , 'g' , 'kg' , 'Ton']}/>
                        </span>


                      {/*<ListInnerInput type={'drop'} name={'unit'} inputData={v.unit} dropMenuList={['ea']}/>*/}
                      <MainButtons name={'BOM 등록'} buttonType={'cta'} disabled={true} margin={'0'}
                                   clickEvent={() => {
                                     setDataModal({menu:'bomMove' , message:'BOM 등록을 위해 관리 페이지로\n이동 하시겠습니까?'})
                                     setBomRegister( {...v, bomAuto: true} )
                                   }}/>
                      <ListInnerInput type={'objectDrop'} name={'processName'}
                                      inputData={v.processName} dropKey={'processName'}
                                      dropScrollHas={processTotal > processPage}
                                      dropScrollEvent={() => setProcessPage( ( prev ) => prev + 1 )}
                                      dropMenuList={processList}
                                      dropTopDirection={rowListDropDirection( arr , i , productList )}
                                      selectDrop={( v ) => {
                                        Object.assign( registerList[i] ,
                                                       {processCode: v?.processCode , processName: v?.processName} )
                                      }}/>
                      <ListInnerInput type={'unit'} unit={'ea'} name={'cavity'}
                                      inputData={numberLocaleString( v.cavity )}
                                      limit={6}
                                      onChange={( e: any ) => onChangeNumberArray( i , e , registerList , setRegisterList )}/>

                      <ListInnerInput type={'unit'} width={'140px'} unit={'mm'} name={'length'}
                                      placeholder={'-'}
                                      inputData={dataValid( v.length?.includes( '.' ) ? v.length : numberLocaleString( v.length ) , 0 )}
                                      limit={15}
                                      onChange={( e ) => {handleDecimalChange( i , e , registerList , setRegisterList , 'mm')}}/>

                      <MainButtons name={( !v.facilityCode || !v.facilityCode[0] ) ? '기계 선택' : '기계 선택됨'} margin={'0'}
                                   buttonType={( !v.facilityCode || !v.facilityCode[0] ) ? 'cta' : 'popupCta'}
                                   clickEvent={() => setDataModal( {menu: 'facility' , index: i , view: false} )}/>

                      <ListInnerInput type={'unit'} width={'140px'} name={'uph'} placeholder={'-'}
                                      unit={dataValid( v.unit , 'ea' )}
                                      inputData={v.uph.includes( '.' ) ? v.uph : numberLocaleString( v.uph )} limit={15}
                                      onChange={( e ) => {handleDecimalChange( i , e , registerList , setRegisterList , dataValid( v.unit , 'ea' ) )}}/>

                      <MainButtons name={( !v.moldCode || !v.moldCode[0] ) ? '금형 선택' : '금형 선택됨'} margin={'0'}
                                   buttonType={( !v.moldCode || !v.moldCode[0] ) ? 'cta' : 'popupCta'}
                                   clickEvent={() => setDataModal( {menu: 'mold' , index: i , view: false} )}/>

                      <MainButtons name={!v.workStandardImg[0] ? '작업표준서 등록' : '작업표준서 변경'} margin={'0'}
                                   buttonType={!v.workStandardImg[0] ? 'cta' : 'popupCta'}
                                   width={'114px'}
                                   clickEvent={() => setDataModal( {menu: 'workStandard' , index: i , view: false} )}/>

                      <MainButtons name={'검사항목 등록'} buttonType={'cta'} margin={'0'} disabled={true}/>

                      <MainButtons name={!v.image[0] ? '이미지 등록' : '이미지 변경'} margin={'0'}
                                   buttonType={!v.image[0] ? 'cta' : 'popupCta'}
                                   clickEvent={() => setDataModal( {menu: 'image' , index: i , view: false} )}/>
                    </div>
                  ) )}

              {registerList?.length === 0 &&
                productList?.map( ( v: any , i: number , arr ) => (
                  <React.Fragment>
                    {
                      <GridTableSelect selected={v.productCode === selectCode}
                                       disabled={registerList.length !== 0 || iconSelect !== - 1}
                                       className={'grid-list'}
                                       onClick={() => {setSelectCode( v.productCode )}}
                                       checkboxNumber={( ( i + 1 ) + productPage * 10 ) - 10}
                                       onDoubleClick={() => window.location.href = `${authValid() ? `/admin/fas/product/${v.productCode}` : `/fas/product/${v.productCode}`}`}
                                       onMouseOut={() => setFocusList( - 1 )} onMouseOver={() => setFocusList( i )}>
                        <label className={'selectCheckbox'} onClick={( e ) => e.stopPropagation()}>
                          <ListInnerInput type={'check'} name={'rawMaterialCode'}
                                          onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.productCode , setCheckedList , checkedList )}
                                          checked={checkedList.includes( v.productCode )}/>
                        </label>
                        {autoTextValueValidation({value:  ( v.customer.length === 0 || !v.customer ) ? '' : customerCount( v.customer )[0].customerName + ( ( customerCount( v.customer ).length - 1 ) !== 0 ? ` 외 ${customerCount( v.customer ).length - 1}` : '' )   })}
                        {autoTextValueValidation({value:  ( v.customer.length === 0 || !v.customer ) ? '' : v.customer[0].customerModelName + ( ( v.customer.length - 1 ) !== 0 ? ` 외 ${v.customer.length - 1}` : '' )  })}
                        {/*{tooltipModule( customerCount( v.customer )?.map( ( v: any ) => ( v.customerName ) ).join( ',' ) , 0 , validationValue( ( v.customer.length === 0 || !v.customer ) ? '' : customerCount( v.customer )[0].customerName + ( ( customerCount( v.customer ).length - 1 ) !== 0 ? ` 외 ${customerCount( v.customer ).length - 1}` : '' ) ) )}*/}
                        {/*{tooltipModule( v.customer?.map( ( v: any ) => ( v.customerModelName ) ).join( ',' ) , 0 , validationValue( ( v.customer.length === 0 || !v.customer ) ? '' : v.customer[0].customerModelName + ( ( v.customer.length - 1 ) !== 0 ? ` 외 ${v.customer.length - 1}` : '' ) ) )}*/}
                        {autoTextValueValidation({value: v.serialCode})}
                        {autoTextValueValidation({value: v.productName})}
                        {validationValue( v.type )}
                        {validationValue( v.unit , 'ea' )}
                        <ListInnerInput type={'noneInputUnit'} unit={dataValid(v.weightUnit,'g')}
                                        inputData={numberLocaleString( v.weight)}/>
                        {v.bom === 'empty' ?
                          <div>
                            <MainButtons name={'BOM 등록'} buttonType={'cta'} margin={'0'} width={'88px'}
                                         disabled={buttonAuthValid( 'create' , '8' )}
                                         clickEvent={() => {
                                           setDataModal({menu:'bomMove' , message:'BOM 등록을 위해 관리 페이지로\n이동 하시겠습니까?'})
                                           setBomRegister( {...v, bomAuto: true} )
                                         }}/>
                          </div>

                          :
                          <ListInnerInput type={'clickText'} inputData={t( 'BOM 보기' )}
                                          clickButton={() => {
                                            setSelectCode( v.productCode );
                                            setBomRegister( {...v, bomAuto: true} )
                                          }}/>
                        }
                        {tooltipModule( v.processName )}
                        <ListInnerInput type={'noneInputUnit'} unit={'ea'} inputData={numberLocaleString( v.cavity )}/>
                        <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={numberLocaleString( v.length )}/>
                        <ListInnerInput type={'clickText'}
                                        inputData={( !v.facilityCode || !v.facilityCode[0] ) ? t( '기계 없음' ) : t( '기계 보기' )}
                                        disabled={( !v.facilityCode || !v.facilityCode[0] )}
                                        clickButton={() => setDataModal( {menu: 'facility' , index: i , view: true} )}/>
                        <ListInnerInput type={'noneInputUnit'} unit={dataValid( v.unit , 'ea' )}
                                        inputData={numberLocaleString( v.uph )}/>
                        <ListInnerInput type={'clickText'}
                                        inputData={( !v.moldCode || !v.moldCode[0] ) ? t( '금형 없음' ) : t( '금형 보기' )}
                                        disabled={( !v.moldCode || !v.moldCode[0] )}
                                        clickButton={() => setDataModal( {menu: 'mold' , index: i , view: true} )}/>
                        {( !v.workStandardImgUrl[0] ) ?
                          <div>{'-'}</div>
                          :
                          <ListInnerInput type={'clickText'} inputData={t( '작업표준서 보기' )}
                                          clickButton={( e ) => {
                                            e.stopPropagation();
                                            setSelectIndex( i );
                                            setDataModal( {menu: 'workStandard' , index: i , view: true} )
                                          }}/>}

                        {/*<ListInnerInput type={'clickText'} inputData={'작업표준서 보기'}*/}
                        {/*                clickButton={()=>setModalState({open: true, status:'confirm', message:'준비중 입니다.'})}/>*/}
                        {!v.qualityTestCode ?
                          <div>
                            <MainButtons name={'검사 항목 등록'} buttonType={'cta'} width={'96px'} margin={'0'}
                                         disabled={buttonAuthValid( 'create' , '8' )}
                                         clickEvent={() => {window.location.href = authValid() ? `/admin/fas/product/inspection/${v.productCode}` : `/fas/product/inspection/${v.productCode}`}}/>
                          </div>

                          :
                          <ListInnerInput type={'clickText'} inputData={t( '검사 양식 보기' )}
                                          onMouseEvent={()=>{setSelectProductObject(v)}}
                                          clickButton={() => {
                                            setSelectProductObject( v )
                                            setDataModal( {menu: 'insReg' , index: i} );
                                          }}/>
                        }

                        {!v.imageUrl[0] ?
                          <div>{'-'}</div>
                          :
                          <ListInnerInput type={'clickText'} inputData={'이미지 보기'}
                                          clickButton={() => {
                                            setSelectIndex( i )
                                            setDataModal( {menu: 'image' , index: i , view: true} )
                                          }}/>}
                      </GridTableSelect>
                    }
                  </React.Fragment>
                ) )}
            </GridTableComponent>
          </div>

          <div style={{display: 'flex' , justifyContent: 'space-between'}}>
            <div>{
              registerList?.length === 0 &&
                <PaginationList limit={10} pageLimit={10} page={productPage} setPage={setProductPage}
                                blockNum={pageBlock} setBlockNum={setPageBlock}
                                counts={totalPage} disabled={iconSelect !== - 1}/>}
            </div>
            <HorizontalMoveScrollButton
              leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
              rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
          </div>
          {/*</InfiniteScroll>*/}
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  )
}
export default ProductManagement;
