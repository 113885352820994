import React , {useState} from 'react';
import {
  ContentFrame ,
  listResultMessage ,
  SectionFrame , validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {useRecoilState} from "recoil";
import {
  companyInfoHideState ,
  persistBlockPage ,
  persistPartnersData ,
} from "../../../../../common/StateManage";
import PartnerInfoComponent from "../../../../../components/partner/PartnerInfoComponent";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  allCheckedFunction , buttonAuthValid ,
  dataAccessLoading , dataValid ,
  filterCheckedRow , hasDuplicate ,
  initModal ,
  listLoadingCircle ,
  loadingRemove , onChangeArray , removeCheckedFunction , singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import styled from "styled-components";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import PermissionSettingBox from "../../../../../components/check/PermissionSettingBox";
import CompanyInfoComponent from "../../../../../components/company/CompanyInfoComponent";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const PartnerPermissionManagement = () => {

  const [selectedPartnerData, setSelectedPartnerData] = useRecoilState(persistPartnersData)
  const [rowList, setRowList] = useState<any[]>([])
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [idIndex, setIdIndex] = useState<number>(1)
  const [hoverIcon, setHoverIcon] = useState<string>(modify)
  const [iconSelect, setIconSelect] = useState<number>(-1)
  const [focusList, setFocusList] = useState<number>(-1)
  const [checkedList, setCheckedList] = useState<number[]>([])
  const [authList,setAuthList] = useState<any[]>([]);
  const [prevAuthName, setPrevAuthName] = useState<string>('')
  const [selectAuthority, setSelectAuthority] = useState<{authoritiesCode: string, authoritiesName: string}>({authoritiesCode: '', authoritiesName: ''})
  const [menuAuthList,setMenuAuthList] = useState<any[]>( []);
  const [selectDetailMenu, setSelectDetailMenu] = useState<string[]>([])
  const [resetFunc,setResetFunc] = React.useState<boolean>(false);
  const [companyInfoHidden, setCompanyInfoHidden] = useRecoilState<boolean>(companyInfoHideState)
  const [pageBlock, setPageBlock] = useRecoilState<number>(persistBlockPage);

  const scrollRef = React.useRef<any>(null)
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
  }

  const addListRow = () => {
    setIdIndex(idIndex+1)
    const rowInput = {
      id: idIndex,
      authoritiesName: '',
    }
    setRowList([...rowList, rowInput])
    setCheckedList([...checkedList, rowInput.id])
    scrollToStart()
  }

  const onDeleteFunc = () =>{
    if(rowList.length === 0){
      setDataModal({menu: 'select', message: '삭제 하시겠습니까?'})
    }else{
      setDataModal({menu: 'back', message: '작성중인 내용이 저장되지 않았습니다.\n"삭제" 하시겠습니까?'})
    }
  }


  const checkboxValid = () => {
    if(rowList?.length !== 0){
      return {list: rowList, id: 'id'}
    }else{
      return {list: authList, id: 'authoritiesCode'}
    }
  }
  const authoritiesListRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.get(`/authorities/list/${selectedPartnerData.companyCode}`,{
        headers: {Authorization}
      })
      const data = response.data.data
      setAuthList(data.row)
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  React.useEffect(() => {
    authoritiesListRes().then()
  }, [selectedPartnerData.companyCode])


  const authoritiesReg = async () => {
    if(filterCheckedRow(rowList, checkedList, 'id').find((v)=> !v.authoritiesName) !== undefined){
      setDataModal({menu:'confirm', message: '권한명을 입력해주세요 .'})
    }
    else if(hasDuplicate(rowList.filter(item => checkedList.includes(item.id)),'authoritiesName')){
      setDataModal({menu:'confirm', message: '권한명이 중복 되었습니다.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post(`/authorities/register`,{
          authorities: filterCheckedRow(rowList, checkedList, 'id').map((v)=> v.authoritiesName),
          companyCode: selectedPartnerData.companyCode
        },{headers:{Authorization}})
        const data = response.data
        if(data.status === 200){
          setTimeout(()=>{
            setDataModal({menu:'success',index:-1, message:'권한 리스트가 등록 되었습니다.'})
            loadingRemove()
            setRowList([])
            setCheckedList([])
            authoritiesListRes().then()
          },500)
        }else{
          setDataModal({menu:'confirm', message: data.message})
          loadingRemove()
        }
      }catch (e:any) {
        console.log(e)
        loadingRemove()
        setDataModal({menu:'error', message: e.message})
      }
    }
  }

  const authoritiesUpdate = async () => {
    try {
      dataAccessLoading()
      const response = await client.post(`/authorities/update`,
                                         {...authList[iconSelect], companyCode: selectedPartnerData.companyCode},
                                         {headers:{Authorization}})
      const data = response.data
      if(data.status === 200){
        setTimeout(()=>{
          loadingRemove()
          setDataModal({menu:'success', message:'권한 리스트가 수정 되었습니다.'})
          setCheckedList([]);
          setRowList([])
          setIconSelect(-1)
        },500)
        setPrevAuthName(authList.find((v)=> v.authoritiesCode === authList[iconSelect].authoritiesCode)?.authoritiesName)
      }else{
        setDataModal({menu:'confirm', message: data.message})
      }
      loadingRemove()
    }catch (e:any) {
      console.log(e)
      loadingRemove()
      setDataModal({menu:'error',message: e.message})
    }
  }

  const authoritiesDelete = async () => {
    try {
      dataAccessLoading()
      const response = await client.post('/authorities/delete',{
        authoritiesCode: checkedList
      }, {headers: {Authorization}})
      const data = response.data
      if(data.status === 200){
        setTimeout(()=>{
          loadingRemove()
          removeCheckedFunction(setAuthList, authList, setCheckedList, checkedList, 'authoritiesCode')
          setIconSelect(-1);
          setDataModal({menu:'success', message:'권한 리스트가 삭제 되었습니다.'})
        },500)
        setSelectDetailMenu([])
        setPrevAuthName('')
      }else{
        setDataModal({menu:'confirm', message: data.message})
        loadingRemove()
      }
    }catch ( e ) {
      loadingRemove()
      console.log(e)
    }
  }


  const cancelFuc = () =>{
    setHoverIcon(modify);
    setIconSelect(-1);
    setCheckedList([]);
    setMenuAuthList([]);
    setSelectAuthority({authoritiesCode: '', authoritiesName: ''})
    setRowList([]);
    setDataModal(initModal)
    setAuthList(authList.map((v:any)=>{return v.authoritiesCode === selectAuthority.authoritiesCode ? {...v, ...selectAuthority} : v}))
    authoritiesListRes().then()
  }


  //권한 설정
  const authorityDetailListRes = async (authoritiesCode: string) => {
    listLoadingCircle()
    try {
      const response = await client.post('/authorities/detail/list',
                                         {authoritiesCode: authoritiesCode},
                                         {headers: {Authorization}})
      const data = response.data.data
      setSelectDetailMenu(dataValid(data?.authoritiesDetailList,[]))
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const authorityDetailReg = async () => {
      try {
        dataAccessLoading()
        const newData = { menu: '0', read: 'possible', update: 'possible', create: 'possible', delete: 'possible' };

        const response = await client.post(`/authorities/detail/register`,{
          authorities: menuAuthList.some(item => item.menu === newData.menu) ? menuAuthList : menuAuthList?.concat(newData),
          authoritiesCode: selectAuthority.authoritiesCode
        },{headers:{Authorization}})
        const data = response.data

        if(data.status === 200){
          setTimeout(()=>{
            setDataModal({menu:'success', message:'메뉴 권한이 변경 되었습니다.'})
            loadingRemove()
            setRowList([])

          },500)

        }else{
          setDataModal({menu:'confirm', message: data.message})
          loadingRemove()
        }
      }catch (e:any) {
        console.log(e)
        loadingRemove()
        setDataModal({menu:'error', message: e.message})
      }
  }
  const onResetData = (data:any) => {
    setSelectedPartnerData(data)
    setCheckedList([])
    setRowList([])
    setSelectDetailMenu([])
    setPrevAuthName('')
    setMenuAuthList([])
    setPageBlock(0)
  }


  return (
    <SectionFrame>
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup clickEvent={()=> {
            setDataModal( initModal)
          }} statusText={dataModal.message}/>}
      {dataModal.menu === 'select' && <SelectConfirmModal
          cancelEvent={()=> {
            setDataModal(initModal)
          }}
          clickEvent={()=> {
            if(dataModal.message?.includes('수정')){
              setPrevAuthName(selectAuthority.authoritiesName)
              authoritiesUpdate().then()
            }
            if(dataModal.message?.includes('저장')){
              setPrevAuthName(selectAuthority.authoritiesName)
              authorityDetailReg().then()
            }
            if(dataModal.message?.includes('삭제')){
              // setPrevAuthName(selectAuthority.authoritiesName)
              authoritiesDelete().then()
            }
            setDataModal(initModal)
          }}
          statusText={dataModal.message}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={authoritiesReg}/>}
      {dataModal.menu  === 'auth' &&
          <SelectConfirmModal
              cancelEvent={()=> {
                setDataModal(initModal)
              }}
              clickEvent={()=> {
                  setPrevAuthName(selectAuthority.authoritiesName)
                if(menuAuthList.length === 0){
                  setDataModal({menu:'confirm',message:"권한을 선택하여 저장해주세요."})
                }else{
                  setDataModal(initModal)
                  authorityDetailReg().then()
                }
              }}
              noticeText={'* "예"클릭시 해당 권한설정이 저장됩니다.'}
              statusText={dataModal.message}/>
      }

      {dataModal.menu === 'modify'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={authoritiesUpdate}/>}


      {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} closeEvent={()=> {setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={dataModal.message} state={'error'} closeEvent={()=> {setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message} state={'warn'} closeEvent={()=> {setDataModal(initModal)}}/> : null}

      {dataModal.menu === 'back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
                                  setSelectAuthority({authoritiesCode:'',authoritiesName:''});
                                  setDataModal(initModal)
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}

      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div style={{display:'flex'}}>
      <MenuTitle title={'지사/협력사 관리 > 지사/협력사 권한관리'} unitCase={'MenuTitle'}/>
          {companyInfoHidden &&
              <div style={{display:'flex'}}>
                <span style={{margin:'8px 8px 0',alignSelf:'center', fontSize:'24px', fontWeight:800}}>{'>'}</span>
                <span style={{alignItems:'center', alignSelf:'center', fontSize:'18px', marginTop:'8px', fontWeight:700, color:'#0058ff'}}>{selectedPartnerData?.companyName}</span>
              </div>}
        </div>
         <div style={{display: 'flex'}}>
          {companyInfoHidden && <PartnerInfoComponent partnerData={onResetData} basicButton={true}/>}
          <MainButtons name={companyInfoHidden ? '지사/협력사 정보 보기' : '지사/협력사 정보 감추기'} width={'160px'}
                       clickEvent={() => setCompanyInfoHidden(!companyInfoHidden)}/>
        </div>
      </div>
      {!companyInfoHidden &&  <PartnerInfoComponent partnerData={onResetData}/>}

      <ContentFrame height={companyInfoHidden?'680px':'456px'} margin={companyInfoHidden?'0':'16px 0 0 0'}  flexDirection={'row'} justify={'flex-start'}>
        <ViewBox>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
            <MenuTitle unitCase={'MenuCompTitle'} title={'권한 리스트'}/>
            <div style={{display:'flex'}}>
              <MainButtons name={'행추가'} width={'80px'}
                           disabled={buttonAuthValid('create','91') || iconSelect !== -1 || (rowList.length === 0 && checkedList.length >= 1) || !selectedPartnerData.companyCode}
                           clickEvent={addListRow}/>
              <MainButtons name={'삭제'} width={'80px'} marginDis={true} buttonType={'delete'}
                           disabled={
                             buttonAuthValid('rowDelete','91') ?
                              rowList.length === 0
                              :
                             buttonAuthValid('delete' ,'91') ||
                             checkedList.length === 0
                           }
                           clickEvent={onDeleteFunc}/>
              <MainButtons name={'저장'} buttonType={'cta'} width={'80px'}
                           disabled={buttonAuthValid('create','91') || rowList.length === 0 || checkedList.length === 0}
                           clickEvent={()=> {
                             if(checkedList.length === rowList.length){
                               authoritiesReg().then()
                             }else{
                               setDataModal( {menu: 'register' , index:-1, message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'})
                             }}}/>
            </div>
          </div>
          <GridTableComponent tempCol={'40px 272px 1fr'} scrollX_Hide={true} scrollWidth={570} height={companyInfoHidden? 538 : 336}
                              titleCheckDisabled={iconSelect !== -1}>
            <div className={'grid-title'}>
              <label className={'checkbox'}>
                <input type="checkbox" checked={checkedList.length === checkboxValid().list.length} style={{pointerEvents:`${iconSelect === -1 ? 'auto' : 'none'}`,opacity:`${iconSelect === -1 ? 1 : 0.5}`}}
                       onChange={(e) => allCheckedFunction(e.target.checked, checkboxValid().list, checkboxValid().id, setCheckedList)}/>
              </label>
              <TableValue title={'권한명'} requiredValue={['권한명']} viewType={rowList.length !== 0 || iconSelect !== -1}/>
              <div style={{opacity:0.5,fontSize:'12px',fontWeight:'normal',display:'flex',alignItems:'center'}}>{'*권한명 저장 후 우측 메뉴를 선택해주세요.'}</div>
            </div>
            {rowList.map((v, i)=>(
              <div className={'grid-row-list'} key={v.id}>
                <label className={'checkbox'}>
                  <ListInnerInput type={'check'}
                                  onChange={(e:any) => singleCheckedFunction(e.target.checked, v.id, setCheckedList, checkedList)}
                                  checked={checkedList.includes(v.id)}/>
                </label>
                <ListInnerInput type={'text'} width={'120px'} placeholder={'권한명'} inputData={v.authoritiesName}
                                name={'authoritiesName'} onChange={(e) => {onChangeArray(i, e, rowList, setRowList)}}/>
              </div>
            ))}
            {rowList.length === 0 &&  authList.length === 0 ?
              listResultMessage(true,'* 등록된 권한이 없습니다.',{width:576, admin:companyInfoHidden? 480 : 300, user:524})
              :
              authList.map((v:any, i:number)=>(
                <React.Fragment key={v.authoritiesCode}>
                  {iconSelect !== i?
                    <GridTableSelect selected={v.authoritiesCode === selectAuthority.authoritiesCode}
                                     disabled={rowList.length !== 0 || iconSelect !== -1}
                                     checkboxNumber={i + 1}
                                     className={'grid-list'}
                                     onClick={()=> {
                                       authorityDetailListRes(v.authoritiesCode).then()
                                       setSelectAuthority(v)
                                       setPrevAuthName(v.authoritiesName)
                                       setIconSelect(-1)
                                     }}
                                     onMouseOut={() => setFocusList(-1)} onMouseOver={() => setFocusList(i)}>
                      <label className={'selectCheckbox'}>
                        <ListInnerInput type={'check'} name={'authoritiesCode'}
                                        onChange={(e:any) => singleCheckedFunction(e.target.checked, v.authoritiesCode, setCheckedList, checkedList)}
                                        checked={checkedList.includes(v.authoritiesCode)}/>
                      </label>
                      {validationValue(v.authoritiesName)}
                      <div style={{backgroundColor: buttonAuthValid('update','91') ? '#F0F0F0' : ''}}>
                        <div className={'pencil-icon'}>
                          {i === focusList &&
                              <img src={hoverIcon} width={buttonAuthValid('update','91') ? 0 : 24} alt=""
                                   onClick={(e)=> {
                                     e.stopPropagation()
                                     setSelectAuthority(v)
                                     setCheckedList([v.authoritiesCode])
                                     setPrevAuthName(v.authoritiesName)
                                     setIconSelect(i)}}
                                   onMouseOut={()=>setHoverIcon(modify)} onMouseOver={()=>setHoverIcon(modifyHover)}/>
                          }
                        </div>
                      </div>
                    </GridTableSelect>
                    :
                    <GridTableSelect className={'grid-list'} disabled={rowList.length !== 0}
                                     checkboxNumber={i + 1}
                                     selected={v.authoritiesCode === selectAuthority.authoritiesCode}>
                      <label className={'selectCheckbox'}>
                        <ListInnerInput type={'check'} name={'authoritiesCode'}
                                        onChange={(e:any) => singleCheckedFunction(e.target.checked, v.authoritiesCode, setCheckedList, checkedList)}
                                        checked={checkedList.includes(v.authoritiesCode)}/>
                      </label>
                      <ListInnerInput type={'text'} width={'120px'} placeholder={'권한명'} inputData={v.authoritiesName}
                                      name={'authoritiesName'} onChange={(e) => {onChangeArray(iconSelect, e, authList, setAuthList)}}/>
                      <div className={'select-icon'} onClick={(e)=>e.stopPropagation()}>
                        <img src={cancelIcon} width={24} alt=""
                             onClick={()=> {setDataModal({menu:'back',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                        <img src={checkIcon} width={24}
                             onClick={()=> setDataModal({menu:'modify',  message:'권한명을 수정 하시겠습니까?'})}  alt=""/>
                      </div>
                    </GridTableSelect>
                  }
                </React.Fragment>
              ))}
          </GridTableComponent>
        </ViewBox>
        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
        <ViewBox style={{height:companyInfoHidden?'680px':'456px'}}>
          <PermissionSettingBox clickEvent={()=>{setDataModal({menu:'auth',message:'이대로 저장하시겠습니까?'})}} affiliateAuth={selectedPartnerData.authority}
                                disabled={buttonAuthValid('update','91') || prevAuthName === ''} height={companyInfoHidden? 538 : 336}
                                prevAuthName={prevAuthName} receiveMenu={dataValid(selectDetailMenu,[])}
                                receiveCheckedList={(data)=>{setMenuAuthList(data)}}
          />
        </ViewBox>
      </ContentFrame>
    </SectionFrame>
  );
};
export default PartnerPermissionManagement;

const ViewBox = styled.div`
  width: 580px;
  height: 456px;
`























