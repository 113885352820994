import React from 'react';
import {ContentFrame , modalOpenTextStyle , SectionFrame} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import MainButtons from "../../../../components/button/MainButtons";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import ListInfoComponent from "../../../../components/list/ListInfoComponent";
import {
  dataAccessLoading ,
  dataValid ,
  initModal ,
  listLoadingCircle ,
  loadingRemove
} from "../../../../function/function";
import SingleImageUploadBox from "../../../../components/input/SingleImageUploadBox";
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../types/Fundamental";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import PasswordChangeModal from "../../../../components/modal/PasswordChangeModal";
import FindPasswordModal from "../../../../components/modal/FindPasswordModal";
import ToastPopup from "../../../../components/modal/ToastPopup";
import {useLocation} from "react-router-dom";

const MyPageModify = () => {
  const {t} :any = useTranslation();
  const userId = window.localStorage.getItem('companyID')
  const location = useLocation()
  const authValid = location.pathname.includes('admin')
  const  [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
  const [emailStatus,setEmailStatus] = React.useState<string>('');
  const [password,setPassword] = React.useState<boolean>(false);
  const [adminPassword,setAdminPassword] = React.useState<string>('');

  const [myInfoModifyState,setMyInfoModifyState] = React.useState<any>({});
  const onChangeValue = (e:any) => {
    setMyInfoModifyState({...myInfoModifyState,[e.target.name]:e.target.value});
  }

  const myInfoRes = async () =>{
    try {
      listLoadingCircle()
      const response = await client.post(`system/user/detail`,{
        id:userId
      },{headers:{Authorization}})
      // console.log(response)
      const data = response.data.data
      console.log(data)
      setMyInfoModifyState(data)

      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }
  const myInfoModifyRes = async () =>{
    try {
      dataAccessLoading()
      const response = await client.post(`system/user/update`,{
        ...myInfoModifyState,
        password:adminPassword ?? '',
        phone_number:myInfoModifyState.phone_number,
        id:userId
      },{headers:{Authorization}})
      if (response.data.status !== 200) {
        setDataModal({menu:'confirm',message:response.data.message});
        loadingRemove()
      } else {
        setTimeout(()=>{
          setDataModal({menu:'success',message:adminPassword ? '비밀번호가 변경되었습니다.' :'수정 되었습니다.' })
          setAdminPassword('')
          loadingRemove()
        },500)
      }
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const closeModify = () =>{
    setDataModal(initModal)
   if(dataModal.message === t('수정 되었습니다.')){
     window.location.href =  authValid ? `/admin/myPage/${userId}` : `/myPage/${userId}`
    }
  }


  React.useEffect(()=>{
    myInfoRes().then()
  },[])

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              clickEvent={()=>{window.location.href = authValid ? `/admin/myPage/${userId}` : `/myPage/${userId}`}}
          />
      }
      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={myInfoModifyRes}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}


      {dataModal.menu === 'pwChange' &&
          <PasswordChangeModal clickEvent={()=>setDataModal(initModal)} closeClick={()=>{setDataModal(initModal)}} findEvent={()=>{setDataModal(initModal)}}/>
      }

      {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={{id:userId}}
                               receivePassword={(data)=>{setAdminPassword(data)}}
                               clickEvent={myInfoModifyRes} findEvent={()=>{setDataModal({menu:'findPw'})}}
                               closeClick={()=>{setDataModal(initModal)}}/>
      }



      {/*{dataModal.menu === 'findPw' &&*/}
      {/*    <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
      {/*}*/}
      {/*{password &&*/}
      {/*    <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
      {/*}*/}


      <MenuTitle title={'마이 페이지 수정'} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'}>
        <React.Fragment>
          <div style={{display:'flex', flexDirection:'column',width:'784px',boxSizing:'border-box'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
              <MenuTitle title={'나의 정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'취소'} width={'56px'} clickEvent={()=>setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}/>
                <MainButtons name={'저장'} clickEvent={()=>{setDataModal({menu:'register',message:'이대로 수정하시겠습니까?'})}} buttonType={'cta'} width={'56px'} marginDis={true}/>
              </div>
            </div>

            <div style={{display: 'grid' , gridTemplateColumns: '392px 392px'}}>
              <RegisterComponent type={'text'} name={'userName'} value={myInfoModifyState?.userName}  paddingRight={true}    required={true}
                                 title={t('성명')} onChange={onChangeValue}/>
              <ListInfoComponent title={t( '사원번호' )} value={dataValid( myInfoModifyState?.employeeNumber)} bottomBorder={false}/>
              <ListInfoComponent title={t( '직책' )} value={dataValid( myInfoModifyState?.userPosition)} bottomBorder={false}/>
              <ListInfoComponent title={t( '-' )} value={dataValid('-')} bottomBorder={false}/>
              {/*<ListInfoComponent title={t( '권한' )} value={dataValid( myInfoModifyState?.authorityName)} bottomBorder={false}/>*/}
            </div>
            <RegisterComponent type={'phone'} name={'phoneNumber'} value={myInfoModifyState?.phone_number} title={t('전화번호')} onChange={onChangeValue}  required={true}/>
            <RegisterComponent type={'text'} name={'email'} value={myInfoModifyState?.email} title={t('이메일')} onChange={onChangeValue}  required={true}/>
            <ListInfoComponent title={t( 'ID' )} value={dataValid( userId)} bottomBorder={false}/>
            <div style={{position: 'relative'}}>
              <RegisterComponent type={'button'} name={'pw'} value={''}
                                 clickEvent={()=>{setDataModal({menu:'pw'})}} bottomBorder={true}
                                 title={t( 'PW' )} buttonName={'변경하기'} width={'112px'}/>
              {/*<div onClick={() => {}}*/}
              {/*     style={{...modalOpenTextStyle , position: 'absolute' , top: '20px' , right: '0'}}> {'비밀번호 찾기'} </div>*/}
            </div>
          </div>

          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>
          <div style={{display: 'flex' , flexDirection: 'column'}}>
            <div>
              <MenuTitle title={'프로필'} unitCase={'MenuCompTitle'}/>
              <SingleImageUploadBox receiveKey={myInfoModifyState?.profileImage}
                                    receiveUrl={dataValid(myInfoModifyState?.profileImageUrl,'')}
                                    imageKey={(v)=>setMyInfoModifyState((prev:any)=>({...prev, profileImage:v}))}
                                    profileSelect={false}/>
            </div>
          </div>
        </React.Fragment>
      </ContentFrame>
    </SectionFrame>
  );
};
export default MyPageModify;
