import React , {useState} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {ModalLabel} from "../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import MainButtons from "../button/MainButtons";
import {dataAccessLoading , dataValid , initModal , listLoadingCircle , loadingRemove} from "../../function/function";
import {onEmailValid} from "../../ValidationCheck";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {Modal_View_Type} from "../../types/Fundamental";


interface FindProps{
  viewType?:boolean
  cancelEvent:()=>void
  clickEvent?:()=>void
  receiveEmail?:(email:string) =>void
  emailStatus?:string
}
const FindPasswordModal = ({cancelEvent,viewType,clickEvent,receiveEmail,emailStatus}:FindProps) => {
  const [inputEmail,setInputEmail] = React.useState<string>('');
  const [emailCheck,setEmailCheck] = useState({text:'* 비밀번호를 찾으시려는 아이디를 입력해주세요.',color:'#0058FF'})
  const [error,setError] = React.useState<boolean>(false);

  const location = useLocation()
  console.log(inputEmail)
  return (
    <ModalBase modalContent={
      viewType ?
        <ModalFrame>
          <ModalLabel location={location.pathname.slice(1,6).includes('admin')}>{'비밀번호 찾기'}</ModalLabel>
          <div style={{padding:'56px 16px 32px 16px',height:'210px',display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',lineHeight:'24px'}}>
              <div style={{fontSize:'12px'}}>{'등록된 이메일로 임시 비밀번호가 전송되었습니다.'}</div>
              <div style={{fontSize:'12px'}}>{emailStatus}</div>
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
              <MainButtons buttonType={'cta'} width={'144px'} name={'확인'} height={'40px'} clickEvent={cancelEvent} padding={'4px 32px'}/>
            </div>
          </div>
        </ModalFrame>
        :
        <ModalFrame>
          <ModalLabel location={location.pathname.slice(1,6).includes('admin')}>{'비밀번호 찾기'}</ModalLabel>
          <div style={{padding:'28px 16px 24px 16px',height:'210px',display:'flex',flexDirection:'column'}}>
            <div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold'}} className={'menu-text'}>{'아이디/ID'}</div>
            <InputText
              type={'email'} placeholder={'아이디/ID를 입력해주세요.'} name={'email'}
              className={'input-style'} value={inputEmail}
              onChange={(e) => {
                setInputEmail(e.target.value);
                onEmailValid(inputEmail, setEmailCheck)
              }}/>
            <div style={{display:'flex',fontSize:'12px' ,color:inputEmail === '' ? 'rgba(23,23,37,0.5)' : emailCheck.color,margin:'8px 0 36px 0',whiteSpace:"pre-wrap"}}>
              {inputEmail === '' ? '* 비밀번호를 찾으시려는 아이디를 입력해주세요.' : emailCheck.text}
            </div>
            <div style={{display:'flex',justifyContent:'center'}}>
              <MainButtons buttonType={'popupNormal'} width={'144px'} name={'취소'} clickEvent={cancelEvent} height={'40px'} />
              <MainButtons buttonType={'cta'} disabled={!inputEmail} width={'144px'} name={'확인'} height={'40px'} padding={'4px 32px'}
                           clickEvent={()=>{
                             if(emailCheck.color !== '#FF0000'){
                               if( receiveEmail ){
                                 receiveEmail( inputEmail );
                               }
                               if( clickEvent ){
                                 clickEvent()
                               }}
                             }
                            }
                           />
            </div>
          </div>
        </ModalFrame>
    }/>
  );
};
export default FindPasswordModal;

const ModalFrame = styled.div`
  position: absolute;
  min-width: 416px;
  height: 262px;
	border-radius: 6px;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 6px #15223214;

  .menu-text{
    color: #7E84A3;
    font-size: 12px;
    font-weight: 700;
    &::after{
      content:'*';
      margin-left: 4px;
      color: #F0142F;
    }
  }
`

const InputText = styled.input<{ error?: boolean }>`
  width: 384px;
  height: 32px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  font-size: 14px;
  white-space: pre-wrap;
  padding: 0 8px;
  margin-top: 8px;
  &:focus{
    transition: all 0.3s;
    border: 1px solid #0058ff;
  }
  
  &::placeholder{
    font-size: 14px;
    font-weight: normal;
    color: rgba(23,23,37,0.3);
    font-family: 'NanumGothicOTF', sans-serif;
  }
`
