import React from 'react';
import {ContentFrame, DatePickerComp, InputDate, SectionFrame} from "../../../../../style/StyleComponent";
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";

import ActionContentInputBox from "../../../../../components/input/ActionContentInputBox";
import {useTranslation} from "react-i18next";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useLocation} from "react-router-dom";
import {MaintenanceTypes, ModalType} from "../../../../../types/Fundamental";
import LoadingComponent from "../../../../../components/modal/LoadingComponent";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import MainButtons from "../../../../../components/button/MainButtons";

const CustomerNoticeDetail = () => {
    const { t }:any = useTranslation();
    const [imageKeyState, setImageKeyState] = React.useState<string[]>([])
    const [maintenanceData, setMaintenanceData] = React.useState<MaintenanceTypes>()
    const [receiveActionData, setReceiveActionData] = React.useState<any>()

    const [modalState, setModalState] = React.useState<ModalType>({open: false, status:'', message:''})

    const location = useLocation()
    const alarmNumber = location.pathname.split('/').pop()



    const maintenanceDetailRes = async () => {

        try {
            const response = await client.post('/maintenance/alarm/detail',{
                alarmNo: alarmNumber
            },{headers:{Authorization}})
            // console.log(response.data)
            setMaintenanceData(response.data.data)
        }catch (e) {
            console.log(e)
        }
        setModalState({open:false, status:'', message:''})
    }

    React.useEffect(()=>{
        maintenanceDetailRes().then()
    },[])

    // "actionTime": "string",
    //   "alarmNo": "string",
    //   "companyCode": "string",
    //   "consumableName": "string",
    //   "consumableNo": "string",
    //   "facilityCode": "string",
    //   "image": [
    //   "string"
    // ],
    //   "maintenanceNo": "string",
    //   "notice": "string",
    //   "responsibility": "string",
    //   "type": "string"
    //
    // const maintenanceActionRegisterRes = async () => {
    //     setModalState({open:true, status:'loading', message:''})
    //     try {
    //         const response = await client.post('/maintenanceAction/register',{
    //             alarmNo: alarmNumber,
    //             companyCode: maintenanceData?.companyCode,
    //             consumableNo: maintenanceData?.consumableNo,
    //             facilityCode: maintenanceData?.facilityCode,
    //             maintenanceNo: maintenanceData?.maintenanceNo,
    //             consumableName: receiveActionData?.consumableName === ''? maintenanceData?.consumableName : receiveActionData?.consumableName,
    //             actionTime: `${receiveActionData?.actionDate} ${receiveActionData?.actionHour}` ,
    //             notice: receiveActionData?.notice,
    //             responsibility: receiveActionData?.responsibility,
    //             type: receiveActionData?.type,
    //             image: imageKeyState
    //         },{headers:{Authorization}})
    //         console.log(response.data)
    //         setModalState({open:true, status:'confirm', message:t('조치 내용이 등록되었습니다.')})
    //     }catch (e) {
    //         console.log(e)
    //     }
    // }
    //
    // console.log(`${receiveActionData?.actionDate} ${receiveActionData?.actionHour}` )

    const noticeStyle = {
        color: 'rgba(0, 0, 0, 0.5)',
        fontSize: '12px'
    }
    const popStyle = {
        fontSize: '14px',
        fontWeight: 'bold',
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
        color:'#5A607F'
    }

    return (
        <SectionFrame>
                <MenuTitle title={'업체별 알림'} unitCase={'MenuTitle'}/>
        <ContentFrame flexDirection={'row'}>
            {modalState.open && modalState.status === 'loading' && <LoadingComponent/>}
            {modalState.open && modalState.status === 'confirm' && <ConfirmPopup
                clickEvent={()=>window.location.href = '/admin/pbas/company'}
                statusText={t(modalState.message)}/>}

            <ViewBox>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                    <MenuTitle title={t('알림 자세히보기')} unitCase={'MenuCompTitle'}/>
                    <div style={{display: 'flex', gap:'16px'}}>
                        <MainButtons name={'취소'} clickEvent={()=>window.location.href = '/admin/fas/solution'}
                                     width={'56px'}/>
                        <MainButtons name={'저장'} buttonType={'cta'} width={'56px'}/>
                    </div>
                </div>
                <div className={'scroll_func'}>
                    <Information>
                        <div className={'info'} style={{display:'grid',gridTemplateColumns:'168px 216px 168px 216px'}}>
                            <div className={'info-title'}>{t('업체명')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('업체코드')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('솔루션')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('기종')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('제조사')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('제조번호')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('ICT번호')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('설비명')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('소모품 종류')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('소모품명')}</div>
                            <div className={'info-content'} style={{display:'flex',justifyContent:'space-between'}}>
                                <div> {'DH0001_A'}</div>
                                <div style={popStyle}>{'관리 이력'}</div>
                            </div>
                            <div className={'info-title'}>{t('알림')}</div>
                            <div className={'info-content'} style={{display:'flex',justifyContent:'space-between'}}>
                                <div>{'주기지남'}</div>
                                <div style={popStyle}>{'연계 알림'}</div>
                            </div>
                            <div className={'info-title'}>{t('알림일시')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('타수')}</div>
                            <div className={'info-content'}>{''}</div>
                            <div className={'info-title'}>{t('기간')}</div>
                            <div className={'info-content'}>{''}</div>
                        </div>
                    </Information>

                    {/*조치내용*/}

                    <div style={{display: 'flex',gap:'24px', alignItems: 'center', marginBottom: '20px',marginTop:'45px'}}>
                        <MenuTitle title={t('조치 내용')} unitCase={'MenuCompTitle'}/>
                        <div style={{...noticeStyle}}>{t('*저장버튼으로 내용작성을 완료해주세요.')}</div>
                    </div>

                   {/*임시로 넣어둠 */}
                   <ActionContentInputBox receiveData={(v)=>setReceiveActionData(v)}/>
                </div>

            </ViewBox>
            <div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>

            <div style={{width:'377px'}}>
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                    <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                    <div style={popStyle}>{'연계 보기'}</div>
                    {/*<div style={{color:'#7E84A3',fontSize:'12px',lineHeight:'16px', height:'34px'}}>*/}
                    {/*    <div>{t('*최대 6개의 이미지 등록 가능')}</div>*/}
                    {/*    <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>*/}
                    {/*</div>*/}
                </div>
                <div style={{marginTop:'28px'}}>
                    <TotalImageUploadBox imageLength={6}
                                         imageKeyArray={(data)=>setImageKeyState(data)} profileSelect={false}/>
                </div>
            </div>


        </ContentFrame>

        </SectionFrame>
    );
};
export default CustomerNoticeDetail;


const ViewBox = styled.div`
width: 784px;
height: 600px;


.scroll_func{
        width: 800px;
        height: 540px;
        overflow-x: hidden;
        overflow-y: scroll;

    &::-webkit-scrollbar {
            display: block;
        }
    }

`


const Information = styled.div`
//border-top: 1px solid rgba(213, 215, 227, 0.5);
//border-bottom: 1px solid #F5F6FA;
display: grid;
box-sizing: border-box;
width: 784px;

input[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }

.info,.action {
        display: grid;
        box-sizing: border-box;
        width: 784px;
        border-top: 1px solid rgba(213, 215, 227, 0.5);

    &-title {
            padding: 16px;
            width: 168px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            background-color: #F5F6FA;
            color:#131523;
            font-size: 12px;
            font-weight: 800;
            border-bottom: 1px solid rgba(213, 215, 227, 0.5);

        }

    &-content {

            display: flex;
            padding: 16px;
            box-sizing: border-box;
            font-size: 14px;
            height: 48px;
            border-bottom: 1px solid rgba(213, 215, 227, 0.5);
            align-items: center;

        }
    &-content-req {
            width: 600px;
            height: 80px;
            overflow-y: scroll;
            overflow-x: hidden;
        &::-webkit-scrollbar {
                width: 15px;
                display: block;
            }
        &::-webkit-scrollbar-thumb {
                background-color: #D5D7E3;
                border-radius: 10px;
                background-clip: padding-box;
                border: 6px solid transparent;
            }
        &::-webkit-scrollbar-track{
                width: 21px;
                background: transparent;
            }
        }
  
 

    }
.scroll_func{
        width: 810px;
        height: 570px;
        overflow-y: scroll;
        overflow-x: hidden;
    &::-webkit-scrollbar {
            display: block;
        }
    }
`