import React , {useEffect , useState} from 'react';
import {
  ContentFrame ,
  listResultMessage ,
  SectionFrame , tooltipModule , validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import PartnerInfoComponent from "../../../../../components/partner/PartnerInfoComponent";
import {useRecoilState} from "recoil";
import {companyInfoHideState , persistBlockPage , persistPartnersData} from "../../../../../common/StateManage";
import {Error_Type , Modal_View_Type} from "../../../../../types/Fundamental";
import {
  allCheckedState , allPersistCheckedFunction , buttonAuthValid ,
  dataAccessLoading , dataValid ,
  filterCheckedRow , initError ,
  initModal ,
  loadingRemove , onChangeArray ,
  removeCheckedFunction , rowListDropDirection , singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import {passwordReg} from "../../../../../ValidationCheck";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useTranslation} from "react-i18next";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import SearchBar from "../../../../../components/input/SearchBar";
import MainButtons from "../../../../../components/button/MainButtons";

import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import hoverModify from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import PaginationList from "../../../../../components/pagination/PaginationList";

import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import PasswordCheckModal from '../../../../../components/modal/PasswordCheckModal'
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";


const PartnerUserManagement = () => {

  const [selectedPartnerData, setSelectedPartnerData] = useRecoilState(persistPartnersData)

  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [rowList, setRowList] = useState<any[]>([])
  const [checkedList, setCheckedList] = useState<number[]>([])
  const [idIndex, setIdIndex] = useState<number>(1)
  const [userList, setUserList] = useState<any[]>([])
  const [hoverIcon, setHoverIcon] = useState<string>(modify)
  const [iconSelect, setIconSelect] = useState<number>(-1)
  const [focusList, setFocusList] = useState<number>(-1)
  const [searchValue,setSearchValue] = useState<string>('');
  const [pushValue, setPushValue] = useState<string>('');
  const [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [authList,setAuthList] = useState<any[]>([]);
  const [prevSelectUserData, setPrevSelectUserData] = useState<any>({})
  const [selectedCode, setSelectedCode] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState<number>(-1)

  const [companyInfoHidden, setCompanyInfoHidden] = useRecoilState<boolean>(companyInfoHideState)
  const [pageBlock, setPageBlock] = useRecoilState<number>(persistBlockPage)
  const [,setErrorState] = useState<Error_Type>(initError)
  const [checkState, setCheckState] = useState<boolean>(false)

  const titleArray = (rowList?.length === 0 && iconSelect === -1)? ['성명','직책','전화번호','Email','권한','아이디'] : ['성명','직책','전화번호','Email','권한','아이디','비밀번호', '비밀번호 확인']
  const needValue = ['성명','전화번호','아이디','비밀번호','비밀번호 확인','Email']

  const {t}:any =useTranslation()

  const scrollRef = React.useRef<any>(null)
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
  }

  const addListRow = () => {
      setIdIndex(idIndex+1)
      const rowInput = {
        check: idIndex,
        companyCode: selectedPartnerData.companyCode,
        id: '',
        userName: '',
        userPosition: '',
        phone_number: '',
        email: '',
        password: '',
        authorityCode:'',
        authority:selectedPartnerData.authority === 'admin' ? 'adminUser' : 'affiliateUser',
        duplicateCheck: false
      }
      setRowList([...rowList, rowInput])
      setCheckedList([...checkedList, rowInput.check])
      scrollToStart()
  }

  const idReg = /^(?=.*[a-zA-Z])(?=.*\d|.*[a-zA-Z])[a-zA-Z\d]{4,20}$/;
  const emailReg= /^[_a-z0-9-]+(.[_a-z0-9-]+)*@(?:\w+\.)+\w+$/;
  const phoneReg = /^\d{2,3}-?\d{3,4}-?\d{4}$/;

  const userSignUpRes = async () => {
    if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.id) !== undefined)){
      setDataModal({menu:'warn', message:'아이디를 입력해 주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!idReg.test(v.id)))){
      setDataModal({menu:'warn', message:'아이디 형식을 4~20자 \n 영문, 영문+숫자를 입력해 주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>(!v.duplicateCheck)))){
      setDataModal({menu:'warn', message:'아이디 중복확인을 해주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.password) !== undefined)){
      setDataModal({menu:'warn', message:'비밀번호를 입력해 주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!passwordReg.test(v.password)))){
      setDataModal({menu:'warn', message:'비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>(v.password !== v.passwordCheck)))){
      setDataModal({menu:'confirm', message:'비밀번호가 일치하지 않습니다.'})
    }

    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.email) !== undefined)){
      setDataModal({menu:'warn', message:'이메일을 입력해 주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.email) === undefined) && (filterCheckedRow(rowList, checkedList,'check').find((v)=>!emailReg.test(v.email)))){
      // if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!emailReg.test(v.email)))){
      setDataModal({menu:'warn', message:'이메일 형식이 잘못 되었습니다.'})
      // }
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.phone_number) !== undefined)){
      setDataModal({menu:'warn', message:'전화번호를 입력해 주세요.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!phoneReg.test(v.phone_number)))){
      setDataModal({menu:'warn', message:'전화번호 형식이 잘못 되었습니다.'})
    }
    else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.userName) !== undefined)){
      setDataModal({menu:'warn', message:'성함을 입력해 주세요.'})
    }
    // else if((filterCheckedRow(rowList, checkedList,'check').find((v)=>!v.authorityName) !== undefined)){
    //   setDataModal({menu:'warn', message:'권한을 선택해 주세요.'})
    // }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/system/user/signup',
                                           filterCheckedRow(rowList, checkedList, 'check'),
                                           {headers:{Authorization}})
        const data = response.data
        if(data.status === 200){
          setTimeout(()=>{
            setRowList([])
            setDataModal({menu:'success', message:'계정이 등록 되었습니다.'})
            loadingRemove()
          },500)
        }else{
          loadingRemove()
          setDataModal({menu:'confirm', message:data.message})
        }
      }catch (e:any) {
        setDataModal({menu:'error', message:e.message})
        console.log(e)
        loadingRemove()
      }
    }
  }

  const userUpdateRes = async () => {

    // if(!userList[dataModal.index].password && checkState){
    //     setDataModal({menu:'confirm', message:'비밀번호를 입력해 주세요.'})
    // }
    // else if(!userList[dataModal.index].passwordCheck && checkState){
    //     setDataModal({menu:'confirm', message:'비밀번호를 한번 더 입력해 주세요.'})
    // }
    // else if(userList[dataModal.index].password !== userList[dataModal.index].passwordCheck && checkState){
    //     setDataModal({menu:'confirm', message:'비밀번호가 일치하지 않습니다.'})
    // }


    // if((userList[iconSelect].password || userList[iconSelect].passwordCheck)){
    //     setDataModal({menu:'confirm', message:'비밀번호를 한번 더 입력해 주세요.'})
    // }
    if(userList[iconSelect].password !== userList[iconSelect].passwordCheck){
        setDataModal({menu:'confirm', message:'입력한 비밀번호를 확인해 주세요.'})
    }

    else if(!userList[iconSelect].phone_number){
      setDataModal({menu:'confirm', message:'전화번호를 입력해 주세요.'})
    }
    else if((!phoneReg.test(userList[iconSelect].phone_number))){
      setDataModal({menu:'confirm', message:'전화번호 형식이 잘못 되었습니다.'})
    }
    else if(userList[iconSelect].email && !emailReg.test(userList[iconSelect].email)){
      setDataModal({menu:'confirm', message:'이메일 형식이 잘못 되었습니다.'})
    }
    else if(!userList[iconSelect].userName){
      setDataModal({menu:'confirm', message:'성함을 입력해 주세요.'})
    }
    // else if(!userList[dataModal.index].authorityCode){
    //   setDataModal({menu:'confirm', message:'권한을 선택해 주세요.'})
    // }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/system/user/update',
                                           userList[iconSelect],{headers:{Authorization}})
        const data = response.data
        if(data.status === 200){
          setTimeout(()=>{
            setIconSelect(-1)
            setCheckedList([])
            setCheckState(false)
            setDataModal({menu:'success', index: iconSelect, message:'계정 정보가 수정 되었습니다.'})
            loadingRemove()
          },500)
        }else{
          setDataModal({menu:'confirm', message:data.message})
          loadingRemove()
        }
      }catch (e:any) {
        setDataModal({menu:'error', message:e.message})
        console.log(e)
        loadingRemove()
      }
    }
  }

  const userDeleteRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post('/system/user/delete',{
        id: checkedList
      },{headers:{Authorization}})
      const data = response.data

      if(data.status === 200){
        setTimeout(()=>{
          setIconSelect(-1)
          setDataModal({menu:'success', message:'계정 정보가 삭제 되었습니다.'})

          if(userList.length === checkedList.length){
            if(page > 1){
              setPage((prev:number)=>(prev - 1))
            }
            else{
              setPage(1)
            }
          }
          if(pushValue === ''){
            userListRes().then()
          }
          else{
            userSearchList().then()
          }

          removeCheckedFunction(setUserList, userList, setCheckedList, checkedList, 'id')
          loadingRemove()
        },500)
      }else{
        setDataModal({menu:'confirm', message:data.message})
        loadingRemove()
      }
    }catch ( e:any ) {
      console.log(e)
      setDataModal({menu:'error', message:'서버 오류 입니다. 관리자에게 문의해 주세요.'})
      loadingRemove()
    }
  }

  const userListRes = ()=> ListCustomHooks(`/system/user/list/${page}/${10}/${selectedPartnerData.companyCode}`,page,setTotalPage,setPageBlock,setUserList,setErrorState,setSearchValue,setPushValue)
  const userSearchList = () => ListSearchPostCustomHooks(`/system/user/search`,page,setTotalPage,setPageBlock,setUserList,setErrorState,{page:page,size:10,companyCode:selectedPartnerData.companyCode,keyword:searchValue})

  //권한 목록
  const authoritiesListRes = async () => {
    try {
      const response = await client.get(`/authorities/list/${selectedPartnerData.companyCode}`,{
        headers: {Authorization}
      })
      const data = response.data.data
      setAuthList(data.row)
    }catch (e) {
      console.log(e)
    }
  }

  const idDuplicateCheck = async (id: string) => {
    try {
      const response = await client.post('/duplicate',{
        id: id
      },{headers: {Authorization}})
      const data = response.data

      if(data.status === 200){
        setDataModal({menu:'success', message:'사용 가능한 아이디 입니다.'})
        setRowList(rowList.map((el) => {return el.check === selectedRow ? {...el , duplicateCheck: true} : el}))
      }else{
        setDataModal({menu:'warn', message: '아이디 형식을 4~20자 \n 영문, 영문+숫자를 입력해 주세요.'})
      }

    }catch (e:any) {
      console.log(e)
      setDataModal({menu:'warn', message: e.message})
    }
  }

  const onChangeIDArray = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target
    const rowsInput: any = [...rowList]
    rowsInput[index][name] = value.trim()
    rowsInput[index]['duplicateCheck'] = false
    setRowList(rowsInput)
  }


  React.useEffect(()=>{
    authoritiesListRes().then()
    if(searchValue === ''){
      userListRes().then()
    }else{
      userSearchList().then()
    }
  },[selectedPartnerData, pushValue, page])


  const checkboxValid = () => {
    if(rowList.length !== 0){
      return {list: rowList, id: 'check'}
    }else{
      return {list: userList, id: 'id'}
    }
  }

  //삭제 VALIDATION FUNCTION
  const onDeleteFunc = () =>{
    if(rowList.length === 0){
      setDataModal({menu: 'select', message: `${checkedList.length}개의 유저 정보를 삭제 하시겠습니까?`})
    }else{
      setDataModal({menu: 'back', message: '작성중인 내용이 저장되지 않았습니다.\n"삭제" 하시겠습니까?'})
    }
  }


  const cancelFuc = () =>{
    setHoverIcon(modify);
    setIconSelect(-1);
    setCheckedList([])
    setDataModal(initModal)
    if(pushValue === ''){
      setUserList(userList.map((v:any)=>{return v.id === prevSelectUserData.id ? {...v, ...prevSelectUserData,password:''} : v}))
    }else{
      userSearchList().then()
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event:BeforeUnloadEvent) => {
      if (rowList.length > 0 || iconSelect !== -1) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [rowList.length,iconSelect]);

  const onResetData = (data:any) => {
    setSelectedPartnerData(data)
    setIconSelect(-1)
    setCheckedList([])
    setRowList([])
    setSearchValue('')
    setPushValue('')
    setPage(1)
    setTotalPage(1)
    setPageBlock(0)
  }
  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} timeSet={1000}
                                                  state={'success'} closeEvent={()=> {
          if(['등록'].some((v)=>(dataModal.message?.includes(v)))){
            window.location.reload()
          }
          if(dataModal.message?.includes('수정')){
            Object.assign(userList[dataModal.index], {password:'', passwordCheck:''} )
          }
          setDataModal(initModal)
        }}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)}
                                                   state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)}
                                                    state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={()=> {
            if(['등록'].some((v)=>(dataModal.message?.includes(v)))){
              window.location.reload()
            }
            if(dataModal.message?.includes('수정')){
              Object.assign(userList[dataModal.index], {password:'', passwordCheck:''} )
            }
            setDataModal(initModal)
          }}/>}
      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={dataModal.message?.includes('삭제')? '* 삭제된 데이터는 복구 할 수 없습니다.' : ''}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{
                                setDataModal(initModal)
                                if(dataModal.message?.includes('삭제')){
                                  userDeleteRes().then()
                                }
                                if(dataModal.message?.includes('수정')){
                                  // if(checkState){
                                  //   userUpdateRes().then()
                                  // }
                                  // else{
                                  //   setDataModal({menu: 'update'})
                                  // }
                                  userUpdateRes().then()
                                }
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={t(dataModal.message)}
                              noticeText={t('선택되지 않은 리스트는 저장 되지 않습니다')}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={userSignUpRes}/>}

      {dataModal.menu === 'back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
                              clickEvent={() => {
                                setCheckState(false)
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'check' )
                                  setPrevSelectUserData({});
                                  setDataModal(initModal)
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'check' &&
          <PasswordCheckModal detailInfo={userList[iconSelect]}
                              cancelEvent={()=>{setDataModal(initModal); setCheckState(false)}} viewType={true}
                              checkEvent={()=> {setDataModal(initModal); setCheckState(true)}}
                              noticeMessage={'* 비밀번호를 변경하기 위해 이전 비밀번호를 입력해 주세요.'}/>
      }

      {dataModal.menu === 'update' &&
          <PasswordCheckModal detailInfo={userList[iconSelect]}
                              cancelEvent={()=>{setDataModal(initModal); setCheckState(false)}} viewType={true}
                              checkEvent={()=> {userUpdateRes().then(); setDataModal(initModal); setCheckState(true)}}
                              noticeMessage={'* 유저 정보를 수정하려면 비밀번호를 입력해 주세요.'}/>
      }

      {/*{dataModal.menu  === 'auth' &&*/}
      {/*    <SelectConfirmModal statusText={t(dataModal.message)} noticeText={t('* "예" 클릭시 권한 등록페이지로 이동됩니다.')}*/}
      {/*                        clickEvent={()=>{setDataModal(initModal); window.location.href = authValid ? `/admin/mes/hr_permission` : `/mes/hr_permission`}} cancelEvent={()=>{setDataModal(initModal)}}/>*/}
      {/*}*/}


      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div style={{display:'flex'}}>
          <MenuTitle title={'지사/협력사 관리 > 지사/협력사 유저관리'} unitCase={'MenuTitle'}/>
          {companyInfoHidden &&
              <div style={{display:'flex'}}>
                <span style={{margin:'8px 8px 0',alignSelf:'center', fontSize:'24px', fontWeight:800}}>{'>'}</span>
                <span style={{alignItems:'center', alignSelf:'center', fontSize:'18px', marginTop:'8px', fontWeight:700, color:'#0058ff'}}> {selectedPartnerData.companyName}</span>
              </div>}
        </div>
         <div style={{display: 'flex'}}>
          {companyInfoHidden && <PartnerInfoComponent partnerData={onResetData} basicButton={true}/>}
          <MainButtons name={companyInfoHidden ? '지사/협력사 정보 보기' : '지사/협력사 정보 감추기'} width={'160px'}
                       clickEvent={() => setCompanyInfoHidden(!companyInfoHidden)}/>
        </div>
      </div>
      {!companyInfoHidden &&  <PartnerInfoComponent partnerData={onResetData}/> }

      <ContentFrame height={`${companyInfoHidden? '680px': '456px' }`}
                    justify={'space-between'}
                    padding={'30px 30px 20px'}
                    style={{marginTop: `${companyInfoHidden? '0' : '16px'}`}}>
        <div>
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
          <MenuTitle unitCase={'MenuCompTitle'} title={'유저 관리'}/>
          <div style={{display:'flex',alignItems:'center'}}>
            <SearchBar clickEvent={()=>{setPushValue(searchValue); setPage(1)}} value={searchValue} keyPressDisable={true} clearButtonAccess={searchValue !== ''}
                       keyFunction={(e)=>setSearchValue(e.target.value.trimStart())} clearButton={()=>{userListRes().then(); setPage(1)}}
                       disabled={(userList.length === 0 && pushValue === '') || rowList.length !== 0|| iconSelect !== -1}/>
            {/*{selectedPartnerData.companyCode !== undefined &&*/}
            {/*    <MainButtons name={'행추가'} clickEvent={addListRow} width={'80px'}*/}
            {/*                 disabled={buttonAuthValid('create', '92') || iconSelect !== -1 || (rowList.length === 0 && checkedList.length > 1)}/>}*/}
            {checkedList?.length !== 0 && <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                                                       disabled={buttonAuthValid('delete', '92') || iconSelect !== -1}
                                                       marginDis={(iconSelect !== - 1 || checkedList?.length !== 0 )}
                                                       clickEvent={()=>{
                                                         setDataModal({menu: 'select', message: `${checkedList.length}개의 유저 정보를 삭제 하시겠습니까?`})
                                                       }} />}
            <MainButtons name={'등록'} buttonType={'cta'} width={'80px'} marginDis={true}
                         disabled={buttonAuthValid('create', '92') || iconSelect !== -1 || !selectedPartnerData?.companyCode}
                         clickEvent={()=> {
                           window.location.href = `/admin/fas/partners/user/register`
                           // if(checkedList.length === rowList.length){
                           //   userSignUpRes().then()
                           // }else{
                           //   setDataModal( {menu: 'register', message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'})
                           // }
                         }}
            />
          </div>
        </div>
        {/*<InfiniteScroll scrollableTarget={'grid-scroll'}*/}
        {/*                next={()=>setPage((prev)=> prev+1)}*/}
        {/*                hasMore={totalPage > page} dataLength={dataValid(userList?.length, 0)}*/}
        {/*                loader={<div/>}>*/}
          <GridTableComponent id={'grid-scroll'}
                              titleCheckDisabled={iconSelect !== -1}
                              ref={scrollRef} scrollWidth={(rowList?.length === 0 && iconSelect === -1) ? companyInfoHidden? 1210 : 1200 : (iconSelect === -1)? 1300 : 1380}
                              scrollY_Hide={companyInfoHidden && rowList?.length < 10}
                              height={companyInfoHidden? 538 : 298}
                              tempCol={(rowList?.length === 0 && iconSelect === -1)? '40px repeat(3, 160px) 1fr 148px 224px' : '40px repeat(3, 120px) 164px 108px 164px 164px 164px 72px'}>
            <div className={'grid-title'}>
              <label className={'checkbox'}>
                <input type="checkbox"
                       style={{pointerEvents:`${iconSelect === -1 ? 'auto' : 'none'}`,opacity:`${iconSelect === -1 ? 1 : 0.5}`}}
                       onChange={(e)=>allPersistCheckedFunction(e.target.checked,{dataList: checkboxValid().list, checkedList: checkedList, id: checkboxValid().id, setState: setCheckedList})}
                       checked={allCheckedState({dataList: checkboxValid().list, checkedList: checkedList, id: checkboxValid().id})}/>
              </label>
              {titleArray.map((v)=>(
                <TableValue title={v} key={v} requiredValue={needValue} viewType={rowList.length !== 0 || iconSelect !== -1}/>
              ))}
            </div>
            {!selectedPartnerData.companyCode ?
              listResultMessage(true, t('* 지사/협력사를 선택해 주세요.'),{width:1200, admin:companyInfoHidden? 480 : 240, user:480})
              :
              rowList.length === 0 && userList.length === 0 ?
                pushValue === '' ?
                  listResultMessage(true, t('* 유저정보를 등록해 주세요.'),{width:1200, admin:companyInfoHidden? 480 : 240, user:480})
                  :
                  listResultMessage(true, t('* 검색결과가 없습니다.'),{width:1200, admin:companyInfoHidden? 480 : 240, user:480})
                :
                rowList.map((v, i,arr)=>(
                  <div className={'grid-row-list'} key={v.check} onMouseDown={()=>setSelectedRow(v.check)}>
                    <label className={'checkbox'}>
                      <ListInnerInput type={'check'} name={'check'}
                                      onChange={(e: any) => singleCheckedFunction(e.target.checked, v.check, setCheckedList, checkedList)}
                                      checked={checkedList.includes(v.check)}/>
                    </label>
                    <ListInnerInput type={'text'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)} placeholder={'이름'}
                                    name={'userName'} inputData={v.userName}/>
                    <ListInnerInput type={'text'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)} placeholder={'직책'}
                                    name={'userPosition'} inputData={v.userPosition}/>
                    <ListInnerInput type={'phone'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}
                                    name={'phone_number'} inputData={v.phone_number} placeholder={'번호 입력'}/>
                    <ListInnerInput type={'text'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}
                                    name={'email'} inputData={v.email}/>
                    <ListInnerInput type={'objectDrop'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}
                                    name={'authoritiesName'} inputData={v.authoritiesName} dropKey={'authoritiesName'}
                                    selectDrop={(v)=>Object.assign(rowList[i], {authorityCode:v?.authoritiesCode, authorityName:v?.authoritiesName})}
                                    dropTopDirection={rowListDropDirection(arr,i,userList)}
                                    dropMenuList={authList}/>
                    {/*<ListInnerInput type={'text'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}*/}
                    {/*                name={'id'} inputData={v.id}/>*/}
                    <ListInnerInput type={'withButton'} name={'id'} inputData={v.id}
                                    buttonText={v.duplicateCheck? '확인됨' : '중복확인'}
                                    buttonWidth={'60px'} checked={v.duplicateCheck} disabled={v.duplicateCheck}
                                    clickButton={()=> {
                                      idDuplicateCheck(v.id).then()
                                    }}
                                    onChange={(e)=>onChangeIDArray(i,e)} />

                    <ListInnerInput type={'password'} inputType={'password'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}
                                    name={'password'} inputData={v.password} placeholder={'비밀번호를 입력해 주세요.'}/>

                    <ListInnerInput type={'password'} onChange={(e)=>onChangeArray(i,e,rowList, setRowList)}
                                    name={'passwordCheck'} inputData={v.passwordCheck} placeholder={'비밀번호를 한번 더 입력해 주세요.'}/>
                  </div>
                ))}
            { rowList.length === 0 &&
              userList.map((v:any, i:number,arr)=>(
              <React.Fragment >
                {iconSelect !== i?
                  <GridTableSelect selected={v.id === selectedCode}
                                   disabled={rowList.length !== 0 || iconSelect !== -1}
                                   className={'grid-list'}
                                   checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                   onClick={()=>setSelectedCode(v.id)}
                                   onDoubleClick={()=>{window.location.href = `/admin/fas/partners/user/detail/${v.id}`}}
                                   onMouseOut={() => setFocusList(-1)} onMouseOver={() => setFocusList(i)}>
                    <label className={'selectCheckbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={(e: any) => singleCheckedFunction(e.target.checked, v.id, setCheckedList, checkedList)}
                                      checked={checkedList.includes(v.id)}/>
                    </label>
                    {validationValue(v.userName)}
                    {validationValue(v.userPosition)}
                    {validationValue(v.phone_number)}
                    {tooltipModule(dataValid(v.email))}
                    {validationValue(v.authorityName)}
                    {validationValue(v.id)}
                    {/*<div className={'pencil-icon'}>*/}
                    {/*  {i === focusList &&*/}
                    {/*      <img src={hoverIcon} width={buttonAuthValid('update', '92') ? 0 : 24} alt=""*/}
                    {/*           onClick={(e) => {*/}
                    {/*             e.stopPropagation()*/}
                    {/*             setPrevSelectUserData( {...v} )*/}
                    {/*             setIconSelect( i );*/}
                    {/*             setCheckedList([v.id])*/}
                    {/*             setSelectedCode(v.id)*/}
                    {/*           }}*/}
                    {/*           onMouseOut={()=>setHoverIcon(modify)} onMouseOver={()=>setHoverIcon(hoverModify)}/>*/}
                    {/*  }*/}
                    {/*</div>*/}
                  </GridTableSelect>
                  :
                  <GridTableSelect className={'grid-list'} selected={v.id === selectedCode}>
                    <label className={'selectCheckbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={(e: any) => singleCheckedFunction(e.target.checked, v.id, setCheckedList, checkedList)}
                                      checked={checkedList.includes(v.id)}/>
                    </label>
                    <ListInnerInput type={'text'} name={'userName'} inputData={v.userName} placeholder={'이름 입력'}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)}/>
                    <ListInnerInput type={'text'} name={'userPosition'} inputData={v.userPosition} placeholder={'직책 입력'}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)}/>
                    <ListInnerInput type={'phone'} name={'phone_number'} placeholder={'번호 입력'}
                                    inputData={dataValid(v.phone_number)}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)}/>
                    <ListInnerInput type={'text'} name={'email'} inputData={v.email} placeholder={'이메일 입력'}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)}/>
                    <ListInnerInput type={'objectDrop'} onChange={(e)=>onChangeArray(i,e,userList, setUserList)}
                                    name={'authoritiesName'} inputData={v.authoritiesName || v.authorityName} dropKey={'authoritiesName'}
                                    dropTopDirection={rowListDropDirection(arr,i)}
                                    selectDrop={(v)=>Object.assign(userList[i], {authorityCode:v?.authoritiesCode, authorityName:v?.authoritiesName})}
                                    dropMenuList={authList}/>
                    {validationValue(v.id)}
                    <ListInnerInput type={'password'} name={'password'} inputData={v.password}
                                    placeholder={'비밀번호를 변경하려면 입력해 주세요.'} readonly={!checkState}
                                    clickButton={()=> { !checkState && setDataModal({menu: 'check'})}}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)}/>

                    <ListInnerInput type={'password'} name={'passwordCheck'} inputData={v.passwordCheck} placeholder={'비밀번호를 한번 더 입력해주세요.'}
                                    clickButton={()=> { !checkState && setDataModal({menu: 'check'})}}
                                    onChange={(e:any) => onChangeArray(i, e, userList, setUserList)} readonly={!checkState} />
                    <div className={'select-icon'}
                         onClick={(e)=>e.stopPropagation()}>
                      <img src={cancelIcon} width={24} alt=""
                           onClick={()=> {setDataModal({menu:'back',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                      <img src={checkIcon} width={24}
                           onClick={()=>setDataModal({menu:'select', index:i, message:'유저 정보를 수정하시겠습니까?'})}  alt=""/>
                    </div>
                  </GridTableSelect>
                }
              </React.Fragment>
            ))}
          </GridTableComponent>
        {/*</InfiniteScroll>*/}
        </div>
        {rowList.length === 0 &&
            <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                            blockNum={pageBlock} setBlockNum={setPageBlock}
                            counts={totalPage} disabled={iconSelect !== -1}/>}
      </ContentFrame>
    </SectionFrame>
  );
};
export default PartnerUserManagement;
