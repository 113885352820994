import React from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import clearImage from '../../asset/image/refresh.svg'
import search from "../../asset/image/ic-search.svg";
import {GridTable, NoData, NoResult, TableList, validationValue} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import DropMenu from "../sideMenu/DropMenu";
import Notiflix from "notiflix";
import InfiniteScroll from "react-infinite-scroll-component";
import {loadingRemove, modalListLoading} from "../../function/function";
import MenuTitle from "../title/MenuTitle";
import {useLocation} from "react-router-dom";


//각각 타입이 완전히 정해지면 Fundamental.ts 파일에 옮겨놓아서 Import 형식으로 불러올예정

//제품 객체 타입
export type ProductType = {
    account:string //거래처
    model:string //모델
    code:string //CODE
    unit:string // 단위
    productName:string //품명
    productType:string //품목 종류
    productCode:string //표 리스트 선택했을때 selected boolean 값 주기 위해서 예시로준 dummyData
}

// 원자재 객체 타입
export type MaterialType ={
    rawCode:string //원자재 CODE
    rawName:string //원자재 품명
    material:string //재질
    thickness:string | number //두께
    materialWidth:string | number //가로(Coil 폭)
    materialLength:string | number //세로(Feeder)
    materialType:string //재질 종류
    account:string //거래처
    usedDate:string //사용기준일
    stockAmount:string //입고량
    stockDate:string//입고일
    lotNumber:string //원자재 LOT 번호
    materialSelectCode:string //표 리스트 선택했을때 boolean 값 주기 위해서 예시로준 dummyData
}


//부자재 객체 타입
export type SubMaterialType ={
    subMaterialCode?:string
    subMaterialSerialCode?: string
    subMaterialName?: string
    subMaterialStock?: string
    subMaterialUnit?:string
    customer?: string
}

//추가시 더필요한 Props 제작하세요. 그리고 주석 설명 달아주세요.
interface SearchProps{
    category:string //스위치문 타입
    gridColumn?:string //그리드 숫자값
    tableTitle?:string[] //표 타이틀 값
    closeButton:()=>void //확인 클릭이벤트
    moveModal?:boolean
    receiveProductData?:(v:ProductType)=>void       //제품검색 리스트 데이터 전달
    receiveMaterialData?:(v:any)=>void     //원자재 리스트 데이터 전달
    receiveSubsidiaryData?:(v:SubMaterialType)=>void //부자재 리스트 데이터 전달
    receiveMoldData?:(v:any)=>void
    selectCode?:string   //선택한 리스트중 한가지데이터 가져오는거
    customerCode?: string
    notUsedDrop?:boolean
    productList?:ProductType[]|undefined // <제품검색> 리스트 값
    materialList?:any[]|undefined // <원자재검색>리스트 값
    subsidiaryList?:SubMaterialType[]|undefined // <부자재검색> 리스트 값

    tableListContent ?: any //태그를 넣을수 있는 값

    selectedData?:(data:any[])=>void
    viewType?: boolean
    //목록에 선택된 코드들을 가져와서 필터링하기 위한 타입
    selectedCode?: string[]
    inputProps?: //input 태그 객체로 모은 Props
        {
            placeHolder?:string, //input 자리표시자
            value?: string | number | readonly string[] | undefined,// input value 값
            id?: string | undefined ,//input id 값
            name?: string|undefined , //input 요소 이름
            clickEvent?:()=>void,//돋보기 클릭이벤트
            clearButton?:()=>void,//검색시 파란색 이미지 생성
            keyFunction?: (e:React.ChangeEvent<HTMLInputElement>) => void, //input <Onchange> 이벤트 핸들러
            clearButtonAccess?: boolean//파란색 새로고침 버튼
        }
    duplicateArray?: string|any[] // 선택된 제품 목록에서 필터링 하기 위해 가져오는 배열
    selectCounter?: boolean
    accessButton?:()=>void
    hideDropMenu?: boolean
}

// const MesSearchTypeModal = ({category,
//                                 inputProps,
//                                 productList,subsidiaryList,materialList,moveModal,selectCounter,
//                                 receiveProductData, receiveMaterialData, receiveSubsidiaryData,receiveMoldData, viewType, selectedCode,
//                                 selectCode, tableListContent,tableTitle,gridColumn,closeButton, selectedData, duplicateArray}:SearchProps) => {
// =======
//           duplicateArray?: string|any[] // 선택된 제품 목록에서 필터링 하기 위해 가져오는 배열
//   selectCounter?: boolean
// }

const MesSearchTypeModal = ({category, hideDropMenu,
                              inputProps, customerCode, notUsedDrop,
                              productList,subsidiaryList,materialList,moveModal,selectCounter,
                              receiveProductData, receiveMaterialData, receiveSubsidiaryData,receiveMoldData, viewType, selectedCode,
                              selectCode, tableListContent,tableTitle,gridColumn,closeButton, selectedData, duplicateArray, accessButton}:SearchProps) => {

    const inputRef = React.useRef<HTMLInputElement>(null);
    const location = useLocation()
    const authValid = location.pathname.includes('/admin')
    const companyCodeState = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)
    const [page, setPage] = React.useState(1)
    const [totalPage, setTotalPage] = React.useState(1)
    const [modalDataList, setModalDataList] = React.useState<any[]>([])
    const [selectModalData, setSelectModalData] = React.useState<any>(selectedCode ?? [])
    const [checkedList, setCheckedList] = React.useState<string[]>(selectedCode??[])
    const [selectCategory, setSelectCategory] = React.useState<string>(category)
    const [selectProductData,setSelectProductData] = React.useState<any>({})
    const [searchInput, setSearchInput] = React.useState('')
    const [pushInput, setPushInput] = React.useState('')

    const modalListRes = async () => {
        try {
        modalListLoading()
            const response = await client.get(`/${selectCategory}/list/${page}/${companyCodeState.companyCode}`,{
                headers:{Authorization}
            })
            const data = response.data.data
            setTotalPage(data.total_page)
            if(data.total_page > 1 && page > 1){
                // if(selectCategory === 'product'){
                //     customerCode?
                //       setModalDataList((prev:any)=> prev.concat(data.row.filter((v:any)=> v.customerCode === customerCode)))
                //       :
                //       setModalDataList((prev:any)=> prev.concat(data.row))
                // }else{
                      setModalDataList((prev:any)=> prev.concat(data.row))
                // }

            }else{
                // if(data.total_page > page){
                //     setPage((v)=>v+1)
                // }
                // if(selectCategory === 'product'){
                //     customerCode?
                //       setModalDataList(data.row.filter((v:any)=> v.customerCode === customerCode))
                //       :
                //       setModalDataList(data.row)
                // }else{
                      setModalDataList(data.row)
                // }
            }
            loadingRemove()
        }catch (e) {
            console.log(e)
            loadingRemove()
        }
    }

    const modalListSearchRes = async () => {
        try {
        modalListLoading()
            const response = await client.get(`/${selectCategory}/search/${page}/${companyCodeState.companyCode}/${pushInput}`,{
                headers:{Authorization}
            })
            const data = response.data.data
            setTotalPage(data.total_page)
            if(data.total_page > 1 && page > 1){
                setModalDataList((prev:any)=> prev.concat(data.row))
            }else{
                setModalDataList(data.row)
            }
            console.log(response.data)
            loadingRemove()
        }catch (e) {
            console.log(e)
            loadingRemove()
        }
    }

    React.useEffect(()=>{
        if(searchInput === ''){
            modalListRes().then()
        }else{
            modalListSearchRes().then()
        }
        // if(category === 'productSearch'){
        //   modalListRes().then()
        // }
    },[selectCategory, page,pushInput])

    React.useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const onSelectedList = (select:boolean, value:any) =>{
        if(select){
            setSelectModalData([...selectModalData, value.moldCode])
            setCheckedList((prev)=>([...prev, value.moldCode]))
        }else{
            //@ts-ignore
            setSelectModalData(selectModalData.filter(el =>el !== value.moldCode));
            setCheckedList(checkedList.filter(el => el !== value.moldCode))
        }
    }

    //이미 등록된 제품, 원자재를 거르는 배열
    const filterData = modalDataList.filter((v)=>!duplicateArray?.includes( (v.productCode || v.rawMaterialCode || v.subMaterialCode) ))

    const keyPressEvent =  (e:React.KeyboardEvent) => {
        if(e.key === 'Enter'){
            //@ts-ignore
            setPushInput(searchInput)
            setPage(1)
        }
    }

    const innerUnit = (value:string | number,unit:string) =>{
        return (
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div>{Number(value).toLocaleString('kr-KR')}</div>
                <div style={{fontSize:'14px',opacity:0.5,fontWeight:"normal"}}>{unit}</div>
            </div>
        )
    }
    const searchTableComponent = (type: string, gridColumn?: string, tableTitle?: string[] ,selectCode?:any, tableListContent ?: any,
                                  productList?: ProductType[] | undefined,materialList?: MaterialType[] | undefined, subsidiaryList?: SubMaterialType[] | undefined) =>{
        switch (type){
            //제품검색 모달창
            case "product" : return(
                <GridTable tempCol={'128px 88px 160px 118px 88px 1fr'} scrollActiveY={'hidden'} scrollActiveX={'hidden'}>
                    <div className={'table_title'}>
                        {['거래처','모델','CODE','품명','품목 종류','단위'].map((v)=>(
                            <div>{v}</div>
                        ))}
                    </div>
                    <InfiniteScroll scrollableTarget={'scroll_func'}
                                    loader={<div/>}
                                    hasMore={totalPage > page}
                                    dataLength={modalDataList?.length === undefined? 0 : modalDataList?.length}
                                    next={()=>setPage((prev)=>prev+1)}>
                        <div className={'scroll_func'} id={'scroll_func'}>
                            {modalDataList?.length !== 0 ?
                                <div style={{height:`${modalDataList.length > 5? '280px' : '260px'}`}}>
                                    {filterData?.map((value)=>(
                                        <TableList selected={selectProductData?.productCode === value?.productCode} className={'table_list'}
                                                   onDoubleClick={()=> {
                                                       if(receiveProductData){
                                                           receiveProductData(selectProductData)
                                                       }
                                                       if(selectedData && !viewType){
                                                           selectedData(checkedList)}}}
                                          onMouseDown={()=>{setSelectProductData(value)}}>
                                            {validationValue(value.customerName)}
                                            {validationValue(value.customerModelName)}
                                            {validationValue(value.serialCode)}
                                            {validationValue(value.productName)}
                                            {validationValue(value.type)}
                                            {validationValue(value.unit, 'ea')}
                                        </TableList>
                                    ))}
                                </div>
                                :
                                <NoData height={240}>
                                    {'검색 결과가 없습니다.'}
                                </NoData>
                            }
                        </div>
                    </InfiniteScroll>
                </GridTable>
            )

            //원자재 검색 모달창
            case "rawMaterial" : return (
                <GridTable tempCol={'128px 120px 120px 80px 96px 96px 1fr'} scrollActiveY={'hidden'} scrollActiveX={'hidden'}>
                    <div className={'table_title'}>
                        {['원자재 CODE','원자재 품명','재질','두께','가로(Coil 폭)','세로(Feeder)','재질 종류'].map((v)=>(
                            <div>{v}</div>
                        ))}
                    </div>
                    <InfiniteScroll scrollableTarget={'scroll_func'}
                                    loader={<div/>}
                                    hasMore={totalPage > page}
                                    dataLength={modalDataList?.length === undefined? 0 : modalDataList?.length}
                                    next={()=>setPage((prev)=>prev+1)}>
                        <div className={'scroll_func'} id={'scroll_func'}>
                            {modalDataList?.length !== 0 ?
                                <div style={{height:`${modalDataList.length > 5? '280px' : '260px'}`}}>
                                    {filterData?.map((value)=>(
                                        //@ts-ignore
                                        <TableList selected={selectCode?.rawMaterialCode === value?.rawMaterialCode}
                                                   onDoubleClick={()=>{
                                                        if(receiveMaterialData){
                                                            receiveMaterialData(value)
                                                        }
                                                        closeButton()
                                                   }}
                                                   className={'table_list'}
                                                   // onDoubleClick={closeButton}
                                                   onClick={()=>{
                                                       if (receiveMaterialData) {
                                                           receiveMaterialData(value)
                                                       }}}>
                                            {validationValue(value.rawMaterialSerialCode)}
                                            {validationValue(value.rawMaterialName)}
                                            {validationValue(value.material)}
                                            <div>{innerUnit(value.thickness,'T')}</div>
                                            <div>{innerUnit(value.width,'mm')}</div>
                                            <div>{innerUnit(value.length,'mm')}</div>
                                            {validationValue(value.materialType)}
                                        </TableList>
                                    ))}
                                </div>
                                :
                                <NoData height={240}>
                                    {'검색 결과가 없습니다.'}
                                </NoData>
                            }
                        </div>
                    </InfiniteScroll>
                </GridTable>
            )
            //부자재 검색 모달창
            case "subMaterial" : return (
                <GridTable tempCol={'120px 120px 88px 1fr'} scrollActiveY={'hidden'} scrollActiveX={'hidden'}>
                    <div className={'table_title'}>
                        {['부자재 CODE','부자재 품명','단위','거래처'].map((v)=>(
                            <div>{v}</div>
                        ))}
                    </div>
                    <div className={'scroll_func'}>
                        {modalDataList?.length !== 0 ?
                            <React.Fragment>
                                {modalDataList?.map((value)=>(
                                    <TableList selected={selectCode?.subMaterialCode === value?.subMaterialCode} className={'table_list'}
                                               // onDoubleClick={closeButton}
                                               onDoubleClick={()=>{
                                                    if(receiveSubsidiaryData){
                                                        receiveSubsidiaryData(value)
                                                    }
                                                    closeButton()
                                               }}
                                               onClick={()=>{
                                                   if (receiveSubsidiaryData) {
                                                       receiveSubsidiaryData(value)
                                                   }}}>
                                        {validationValue(value.subMaterialSerialCode)}
                                        {validationValue(value.subMaterialName)}
                                        {validationValue(value.subMaterialUnit)}
                                        {validationValue(value.customer)}
                                    </TableList>
                                ))}
                            </React.Fragment>
                            :
                            <NoData height={240}>
                                {'검색 결과가 없습니다.'}
                            </NoData>
                        }
                    </div>
                </GridTable>
            )
            //금형 검색
            case "mold" : return(
                <GridTable tempCol={'120px 120px repeat(3, 96px) 1fr'} scrollActiveY={'hidden'} scrollActiveX={'hidden'}>
                    <div className={'table_title'}>
                        {['금형 CODE','금형명','캐비티','SPM','슬라이드 위치','현재타수'].map((v)=>(
                            <div>{v}</div>
                        ))}
                    </div>
                    <div className={'scroll_func'}>
                        {modalDataList?.length !== 0 ?
                            <React.Fragment>
                                {!viewType? modalDataList?.map((value)=>(
                                        <TableList selected={selectModalData.map((v:any)=>(v)).includes(value.moldCode)} className={'table_list'}
                                                   onDoubleClick={closeButton}
                                                   onClick={()=>{
                                                       onSelectedList(!selectModalData.map((v:any)=>(v)).includes(value.moldCode), value)
                                                       if (receiveMoldData) {
                                                           receiveMoldData(value)
                                                       }}}>
                                            {validationValue(value.moldSerialCode)}
                                            {validationValue(value.moldName)}
                                            {validationValue(value.cavity)}
                                            {validationValue(value.spm)}
                                            {validationValue(value.slideCounter)}
                                            {validationValue(value.currentStrokes)}
                                        </TableList>
                                    ))
                                    :
                                    modalDataList.filter((v)=>(selectedCode?.includes(v.moldCode))).length !== 0?
                                        modalDataList.filter((v)=>(selectedCode?.includes(v.moldCode))).map((value)=>(
                                            <TableList className={'table_list'} selected={false}>
                                                {validationValue(value.moldSerialCode)}
                                                {validationValue(value.moldName)}
                                                {validationValue(value.cavity)}
                                                {validationValue(value.spm)}
                                                {validationValue(value.slideCounter)}
                                                {validationValue(value.currentStrokes)}
                                            </TableList>
                                        ))
                                        :
                                        <NoData height={260}>{'등록된 금형이 없습니다.'}</NoData>
                                }
                            </React.Fragment>
                            :
                            <NoData height={240}>
                                {'검색 결과가 없습니다.'}
                            </NoData>
                        }
                    </div>
                </GridTable>
            )

            //생성하고 싶은 리스트 생성할수있는 Default
            default : return(
                <GridTable tempCol={gridColumn} scrollActiveY={'hidden'} scrollActiveX={'hidden'}>
                    <div className={'table_title'}>
                        {tableTitle?.map((v)=>(
                            <div>{v}</div>
                        ))}
                    </div>
                    <div className={'scroll_func'}>
                        {tableListContent}
                    </div>
                </GridTable>
            )
        }
    }


    const modalTitle = (category: string) => {
        switch (category){
            case 'product' : return '제품'
            case 'mold' : return '금형'
            case 'rawMaterial' : return '원자재'
            case 'subMaterial' : return '부자재'
            default : return ''
        }
    }

    return (
        <ModalBase zIndex={99} modalContent={
            <BaseComponent rightCus={moveModal} topCus={moveModal}>
                <div>
                    <div style={{display:'flex', gap:'16px',position:'relative'}}>
                        <div className={'modal'} style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff',
                            color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'}}>
                            {modalTitle(selectCategory) + (viewType?' 보기' : ' 검색')}
                        </div>

                        {category === 'product' && !viewType && !hideDropMenu &&
                            <DropMenu menuArray={['제품','원자재','부자재']} selectValue={modalTitle(selectCategory)}
                                      style={{display: notUsedDrop? 'none':'block'}}
                                      clickEvent={(v)=> {setPage(1)
                                          setModalDataList([])
                                          if(v === '제품'){
                                              setSelectCategory('product')
                                          }
                                          if(v === '원자재'){
                                              setSelectCategory('rawMaterial')
                                          }
                                          if(v === '부자재'){
                                              setSelectCategory('subMaterial')
                                          }
                                      }} left={'120px'} top={'12px'} />
                        }
                    </div>
                    <div style={{padding:'24px 16px 8px 16px'}}>
                        {/*{!viewType && <Container>*/}
                        {/*  <input ref={inputRef} type={'text'}*/}
                        {/*         placeholder={inputProps?.placeHolder === undefined ? '검색' : inputProps.placeHolder}*/}
                        {/*         onChange={inputProps?.keyFunction}*/}
                        {/*         value={inputProps?.value} id={inputProps?.id} name={inputProps?.name}*/}
                        {/*         onKeyPress={keyPressEvent}/>*/}
                        {/*  {inputProps?.clearButtonAccess &&*/}
                        {/*    <div style={{backgroundImage: `url(${clearImage})`}} onClick={inputProps?.clearButton}*/}
                        {/*         className={'search_button'}/>}*/}
                        {/*  <div style={{backgroundImage: `url(${search})`, minWidth: "24px"}} className={'search_button'}*/}
                        {/*       onClick={inputProps?.clickEvent}/>*/}
                        {/*</Container>}*/}
                        {!viewType &&
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <Container>
                                    <input ref={inputRef} type={'text'}
                                           placeholder={'검색'}
                                           onChange={(e)=>setSearchInput(e.target.value)}
                                           value={searchInput} id={''} name={''}
                                           onKeyPress={keyPressEvent}/>
                                    <div style={{backgroundImage: `url(${search})`, minWidth: "24px"}} className={'search_button'}
                                         onClick={inputProps?.clickEvent}/>
                                </Container>
                                {selectCounter &&
                                    <MenuTitle
                                        title={`선택된 장비 수: ${checkedList.length === undefined ? 0 : checkedList.length} 대`}
                                        unitCase={'MenuCompTitle'}/>}
                            </div>}


                        <React.Fragment>
                            {searchTableComponent(selectCategory, gridColumn,tableTitle,selectCode,tableListContent,productList,materialList,subsidiaryList)}
                        </React.Fragment>
                    </div>
                </div>

                <div style={{display:'flex',alignSelf:'center'}}>
                    {viewType?
                      <MainButtons name={'확인'} buttonType={'normal'} clickEvent={closeButton} width={'144px'}/>
                      :
                      <React.Fragment>
                        <MainButtons name={'취소'} buttonType={'normal'} clickEvent={closeButton} width={'144px'}/>
                        <MainButtons name={'확인'} buttonType={'cta'} clickEvent={()=> {
                            if(receiveProductData){
                                receiveProductData(selectProductData)
                            }
                            if(selectedData && !viewType){
                                selectedData(checkedList)}}
                        } width={'144px'}/>
                      </React.Fragment>
                    }
                </div>
            </BaseComponent>
        }/>
    );
};

export default MesSearchTypeModal;

const BaseComponent = styled.div<{rightCus?:boolean,topCus?:boolean}>`
  width: 1032px;
  height: 512px;
  position: absolute;
  justify-content: space-between;
  padding-bottom: 32px;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  right: ${p=>p.rightCus ? 21.5 : 'auto'}%;
  top:${p=>p.topCus ? 22.5 : 'auto'}%;
  
  .modal{
    width: 1032px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 900;
  }
  .scroll_func{
    height: 262px;
    overflow-y: overlay;
    overflow-x: hidden;
     &::-webkit-scrollbar{
       display: block;
       width: 19px;
     }
  }
`
const Container = styled.div`
  display: flex;
  box-sizing: border-box;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  height: 32px;
  width: 384px;
  align-items: center;
  
  input{
    width:100%;
    padding: 0 4px 0 8px;
    outline: none;
    border: none;
    font-family: "Nanum Gothic", sans-serif;
    &::placeholder{
      font-size: 14px;
      color: rgba(90,96,127,0.3);
    }
  }
  .search_button{
    width: 24px;
    height: 24px;
    margin-right: 4px;
    cursor: pointer;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`