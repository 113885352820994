import React , {useState} from 'react';
import styled from 'styled-components'
import ModalBase from "./ModalBase";
import {authValid , dataValid , initError , listLoadingCircle , loadingRemove} from "../../function/function";
import {GridTableComponent , GridTableSelect , NoData , validationValue} from "../../style/StyleComponent";
import SearchBar from "../input/SearchBar";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../CustomHooks";
import {Error_Type} from "../../types/Fundamental";
import dayjs from "dayjs";
import {cloneDeep} from "lodash";
import check from "../../asset/image/check_white.svg";
import ListInnerInput from "../input/ListInnerInput";

interface Interface {
  closeEvent?:() => void
  receiveData:(data:any)=>void
  setData:any[]
}

const WorkSelectModal = ({closeEvent,receiveData,setData}:Interface) => {
  const [workList, setWorkList] = useState<any[]>([])
  const [selectedWorkList,setSelectedWorkList] = React.useState<any>(setData ?? []);
  const [searchValue,setSearchValue] = useState<string>('');
  const [pushValue, setPushValue] = useState<string>('');
  const [page,setPage] = React.useState<number>(1);
  const [,setTotalPage] = React.useState<number>(0);
  const [,setPageBlock] = React.useState<number>(0)
  const [errorState,setErrorState] = React.useState<Error_Type>(initError)
  const [hoverIndex , setHoverIndex] = useState<number>(- 1)


  const workListRes = ()=> ListCustomHooks(`/system/user/list/${page}/${100}/@`,page,setTotalPage,setPageBlock,setWorkList,setErrorState,setSearchValue,setPushValue,true)
  const workSearchListRes = () => ListSearchPostCustomHooks(`/system/user/search`,page,setTotalPage,setPageBlock,setWorkList,setErrorState,{page:page,size:10,companyCode:'@',keyword:searchValue},true)

  React.useEffect(()=>{
    if(searchValue === ''){
      workListRes().then()
    }else{
      workSearchListRes().then()
    }
  },[pushValue, page])
  //작업자 선택시 배열안에 넣는 함수
  const handleClick = (item: any) => {
    const isItemSelected = selectedWorkList.some(( selectedItem: { id: any; }) => selectedItem.id === item.id);

    if (isItemSelected) {
      setSelectedWorkList(( prevList: any[]) => prevList.filter(selectedItem => selectedItem.id !== item.id));
    } else {
      setSelectedWorkList(( prevList: any) => [...prevList, item]);
    }
  };
  return (
    <ModalBase modalContent={
      <React.Fragment>
        <BaseComponent>
          <div className={'modal'} style={{backgroundColor:authValid()  ? '#CFFA61' : '#0058ff',color: authValid()  ? '#171725' : '#fff'}}>
            <div className={'modal_title'} >{'작업자 추가'}</div>
          </div>
          <div>
            <div style={{display:'grid', gridTemplateColumns:'396px 396px', gap:'16px',  boxSizing:'border-box', padding:'0 16px 16px',justifyContent:'space-between'}}>
              <div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',margin:'8px 0'}}>
                  <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>
                    {'작업자 추가'}
                  </div>
                  <SearchBar clickEvent={()=>{setPushValue(searchValue);}} value={searchValue}
                             keyPressDisable={true} clearButtonAccess={searchValue !== ''} keyFunction={(e)=>setSearchValue(e.target.value.trimStart())}
                             clearButton={()=>{workListRes().then();}} margin={'0'} widthCus={'396px'} placeholder={'유저 검색'}/>
                </div>

                <GridTableComponent tempCol={'108px 80px 1fr'} id={'grid-scroll'} marginCus={0}
                                    scrollWidth={386} scrollX_Hide={true} height={312}>
                  <div className={'grid-title-unchecked'}>
                    {  ['성명','직책','아이디'].map((v)=>(
                      <div key={v}>{v}</div>
                    ))}
                  </div>
                  {workList?.filter(item => item.id !== window.localStorage.getItem('companyID'))?.length === 0?
                    <NoData height={232}>{'* 검색결과가 없습니다.'}</NoData>
                    :
                    workList?.filter(item => item.id !== window.localStorage.getItem('companyID')).map((v,index)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       selected={selectedWorkList.some(( selectedItem: { id: any; }) => selectedItem.id === v.id)}
                                       onClick={()=>{handleClick(v);}}>
                        {validationValue(v.userName)}
                        {validationValue(v.userPosition)}
                        {validationValue(v.id)}
                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
              </div>
              <div>
                <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',lineHeight:'32px',margin:'8px 0'}}>
                  <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>
                    {'선택 리스트'}
                  </div>
                  <div style={{fontSize:'14px',textAlign:'end',color:'#0058FF',fontWeight:700}}>
                    { '선택 : ' + dataValid(selectedWorkList?.length,'-')}
                  </div>
                </div>
                <GridTableComponent tempCol={'108px 80px 112px 24px'} id={'grid-scroll'} marginCus={0}
                                    scrollWidth={386} scrollX_Hide={true} height={312}>
                  <div className={'grid-title-unchecked'}>
                    {  ['성명','직책','아이디'].map((v)=>( <div key={v + 'zestech_worker'}>{v}</div> ))}
                  </div>
                  {selectedWorkList?.length === 0?
                    <NoData height={232}>{'* 선택된 유저가 없습니다.'}</NoData>
                    :
                    selectedWorkList.map((v:any,index:number)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       onMouseEnter={() => setHoverIndex(index)}
                                       onMouseLeave={() => setHoverIndex(- 1)}
                      >
                        {validationValue(v.userName)}
                        {validationValue(v.userPosition)}
                        {validationValue(v.id)}
                        {
                          index === hoverIndex &&
                            <HoverDeleteButton>
                              <div className={'facility_cancel'}
                                   onClick={() => {
                                     setHoverIndex(-1);
                                     setSelectedWorkList(( prevList: any[]) => prevList.filter(selectedItem => selectedItem.id !== v.id));}}>
                                <div>
                                  {'╋'}
                                </div>
                              </div>
                            </HoverDeleteButton>
                        }

                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
              </div>
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'center'}}>
            <MainButtons name={'취소'} margin={'12px 16px 0 0'} width={'144px'} padding={'4px 32px'} height={'40px'}
                         clickEvent={closeEvent}/>
            <MainButtons name={'선택완료'} margin={'12px 0 0 0'} width={'144px'} padding={'4px 32px'} height={'40px'} buttonType={'cta'}
                         clickEvent={()=>{receiveData(selectedWorkList);}}
                         disabled={selectedWorkList?.length === 0}/>
          </div>

        </BaseComponent>
      </React.Fragment>
    }/>
  );
};
export default WorkSelectModal;

const BaseComponent = styled.div`
  width: 842px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  user-select: none;
  .modal{
    width: 842px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;
      &_title{
        font-size: 18px;
        font-weight: 900;
          margin-right: 8px;
      }
      &_sub_title{
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
      }
  }
`

const HoverDeleteButton = styled.div`
  position: absolute;
  user-select: none;
  right: 2%;
  .facility_index {
    opacity: 1;
  }

  .facility_cancel {
    width: 24px;
    height: 24px;
    border: 1px solid #0058FF;
    border-radius: 4px;
    color: #5a607f;
    text-align: center;
    line-height: 24px;

    cursor: pointer;
    background-color: #fff;

    &:hover {
      background-color: #0058FF;
      color: #fff;
      border: 1px solid #0058FF;
    }

    & > div {
      transform: rotateZ(45deg);
    }
  }
`
