import React from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , multipleImageGridStyle , NoData , SectionFrame} from "../../../../../style/StyleComponent";
import PreviewImage from "../../../../../components/display/PreviewImage";
import {useTranslation} from "react-i18next";
import {LoadDetailData} from "../../../../../CustomHooks";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import FactorySegmentationModal from "../../../../../components/modal/FactorySegmentationModal";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {authValid , buttonAuthValid , dataValid , initModal} from "../../../../../function/function";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";

const FactoryDetail = () => {
  const params = useParams()
  const {id} = params
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal );
  const [viewImage , setViewImage] = React.useState<boolean>( false )
  const {t}: any = useTranslation();
  // const detailFactory = async () =>{
  //     try{
  //         const response = await client.post('/factory/detail',
  //             {factoryCode:pathname.split('/')[4]},
  //             {headers:{Authorization}})
  //             setDetailData(response.data.data);
  //             console.log(response.data)
  //     } catch (e){
  //         console.log(e)
  //     }
  // }
  const detailData: any = LoadDetailData( '/factory/detail' , {factoryCode: id} );
  const drawingUrlViewEvent = () => {
    if( detailData.factoryDrawingUrl !== null ){
      setViewImage( true )
    }
  }
  const segmentHandler = ( segment: string[] ) => {
    Object.assign( detailData , {factorySubdivision: segment} )
    setDataModal( initModal )
  }
  return (
    <SectionFrame>
      {dataModal.menu === 'segment' &&
          <FactorySegmentationModal factorySegment={detailData.factorySubdivision} segmentMenu={'detail'}
                                    clickEvent={( v ) => segmentHandler( v )}
                                    factoryName={dataValid( detailData.factoryName )}
                                    closeEvent={() => setDataModal( initModal )}/>}
      {dataModal.menu === 'preview' &&
          <ImageDetailPreview profile={detailData?.factoryImageUrl[dataModal.index]}
                              imageUrl={dataValid( detailData?.factoryImageUrl , [] )}
                              clickEvent={() => {setDataModal( initModal )}}/>}
      <React.Fragment>
        {viewImage &&
            <DetailImageView image={detailData.factoryDrawingUrl} closeMenu={() => ( setViewImage( false ) )}/>}
        <MenuTitle title={(authValid() ? '업체/' : '' ) + '기본정보 관리 > 공장 기본정보 > 조회'} unitCase={'MenuTitle'}/>
        <ContentFrame>
          <div style={{display: 'flex' , userSelect: 'none'}}>
            <ViewComponent>
              <div style={{
                display: 'flex' ,
                justifyContent: 'space-between' ,
                alignItems: 'center' ,
                marginBottom: '16px'
              }}>
                <MenuTitle title={'공장 기본정보 조회'} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <MainButtons name={'목록'} width={'56px'}
                               clickEvent={() => window.location.href = `${authValid() ? `/admin/fas/factory` : `/fas/factory`}`}/>
                  <MainButtons width={'56px'} name={'수정'} disabled={buttonAuthValid( 'update' , '3' )} marginDis={true}
                               clickEvent={() => window.location.href = `${authValid() ? `/admin/fas/factory/modify/${id}` : `/fas/factory/modify/${id}`}`}/>
                </div>
              </div>
              {authValid() &&
                  <React.Fragment>
                    <ListInfoComponent title={t( '업체명' )} value={detailData.companyName}/>
                    <ListInfoComponent title={t( '대표' )} value={detailData.president}/>
                  </React.Fragment>}
              <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 392px)'}}>
                <ListInfoComponent title={t( '공장명' )} value={detailData.factoryName}/>
                <ListInfoComponent title={t( '공장 세분화' )}
                                   disabled={detailData.factorySubdivision?.length === 0}
                                   clickEvent={() => setDataModal( {menu: 'segment' , message: ''} )}
                                   type={'textClick'}
                                   contentText={detailData.factorySubdivision?.length === 0 ? '세분화 없음' : '세분화 보기'}/>
              </div>
              <ListInfoComponent title={t( '공장 주소' )}
                                 value={dataValid( detailData.address , '' ) + ' ' + dataValid( detailData.detailAddress , '' )}/>
              <ListInfoComponent title={t( '전화번호' )} value={dataValid( detailData.factoryNumber )}/>
              <ListInfoComponent title={t( '공장 담당자' )} value={detailData.factoryManager}/>
              <ListInfoComponent title={t( '공장 전화번호' )} value={detailData.managerNumber}/>
              <ListInfoComponent title={t( '도면정보' )} clickEvent={drawingUrlViewEvent}
                                 type={'textClick'}
                                 contentText={dataValid( detailData.factoryDrawingUrl?.split( '/' ).pop() , '-' )}/>
              <ListInfoComponent title={t( '내용' )} type={'textarea'}
                                 bottomBorder={true}
                                 value={
                                   dataValid( detailData.notice , <NoData
                                     height={90}>{t( '* 작성된 내용이 없습니다.' )}</NoData> )
                                 }/>
            </ViewComponent>
            <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>
            <div style={{width: '370px' , height: '34px' , alignItems: 'center'}}>
              <MenuTitle title={'공장 이미지'} unitCase={'MenuCompTitle'}/>
              <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
                {detailData?.factoryImage?.length !== 0 ?
                  detailData?.factoryImageUrl?.map( ( v: any , i: number ) => (
                    <PreviewImage imageUrl={v} noneDelete={true}
                                  clickEvent={() => {setDataModal( {menu: 'preview' , index: i} )}}/>
                  ) )
                  :
                  <PreviewImage imageUrl={''} noneDelete={true}/>}
              </div>
            </div>
          </div>
        </ContentFrame>
      </React.Fragment>
    </SectionFrame>
  );
};
export default FactoryDetail;
const ViewComponent = styled.div`
  width: 784px;
  height: 100%;

  .register_notice {
    min-height: 112px;
    height: 112px;
  }

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`