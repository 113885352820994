import React from 'react';

import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ButtonType} from "../../types/Fundamental";


interface Props {
    name: string
    clickEvent: any
    color?: string
    cancelEvent: ()=>void
    closeButton?: boolean
    width?: string
    disabled?: boolean
}

const SearchFilterButton:React.FC<Props> = ({name,clickEvent,color, cancelEvent, closeButton, width, disabled}) => {



    const {t}:any = useTranslation();
    return (
      <BaseComp onClick={clickEvent} width={width} disabled={disabled}>
          <div style={{display:'grid',alignItems:'center',overflow:'hidden',gridTemplateColumns:`${!closeButton? '1fr 16px' : '1fr'}`, gap:'10px'}}>
              <div className={'button_name'} style={{color:color}}>{t(name)}</div>
              <div className={'close'} style={{display:`${closeButton? 'none' : 'inline-block'}`}} onClick={(e)=>{e.stopPropagation();
                  cancelEvent()}}/>
          </div>
      </BaseComp>
    );
};

export default SearchFilterButton;

const BaseComp =styled.div<ButtonType>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.width ?? 'auto'};
    min-width: 120px;
    max-width: 180px;
    height: ${props => props.height ?? '32px'};
    border-radius: 4px;
    border:${props => props.border ?? '1px solid #D7DBEC'};
    box-sizing: border-box;
    padding:${props => props.padding ?? '0 16px'};
    cursor: pointer;
    transition: all 0.3s;
    font-size:${props => props.fontSize ?? '14px'};
    font-weight:${props => props.fontWeight ?? 'bold'};
    color:${props => props.color ?? '#0058FF'};
    margin: 0 8px;
    pointer-events: ${props => props.disabled? 'none' : 'auto'};
    opacity: ${props => props.disabled? 0.3 : 1};
    user-select: none;
    &:hover  {
        color: #fff;
        border: 1px solid #0058FF;
        background-color: #0058FF;
        .close:after{
            color: #d5d7e3;
        }
    }
    .close { 
        &:after {
            display: inline-block;
            content: "\\00d7";
            font-size: 18pt;
            font-weight: 400;
            color: #5A607F;
        }
        &:hover:after {
            color: #fff;
        }
    }
    .button_name{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
`








