import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import AccountManagement from "../../../containers/admin/fas/company/account/AccountManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useLocation} from "react-router-dom";
import AccountRegistration from "../../../containers/admin/fas/company/account/AccountRegistration";
import AccountDetail from "../../../containers/admin/fas/company/account/AccountDetail";
import TESTAccountMultipleReg from "../../../containers/admin/fas/company/account/AccountMultipleReg";

const FasAccount = () => {
  const location = useLocation()
  const {pathname} = location
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      { pathname.length === (location.pathname.includes('/admin')? 18 : 12)?
      <SectionContainer contentContainer={<AccountManagement/>}/>
        :
      location.pathname.includes('register') ?
      <SectionContainer contentContainer={<AccountRegistration/>}/>
        :
      <SectionContainer contentContainer={<AccountDetail/>}/>
      }
    </PageContainer>
  );
};

export default FasAccount;
