import React from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {ModalLabel} from "../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Error_Type} from "../../types/Fundamental";
import {dataValid , initError} from "../../function/function";


interface PasswordProps{
  viewMessage?:boolean;
  noticeMessage?:string
  detailInfo?:any;
  cancelEvent?:()=>void
  transparent?:boolean
  movePage?:string | any
  findClick?:()=>void
  viewType?:boolean
  checkEvent?:() => void
}
const PasswordCheckModal = ({viewMessage,detailInfo,cancelEvent,noticeMessage,transparent,movePage,findClick,viewType, checkEvent}:PasswordProps) => {
  const location = useLocation()
  const { t }:any = useTranslation();
  const [inputLogin, setInputLogin] = React.useState({id:detailInfo.id, pw:''})
  const [errorState, setErrorState] = React.useState<Error_Type>(initError)
  const [inputType, setInputType] = React.useState(false)

  const loginOnchange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setInputLogin({...inputLogin,
                    [name]:value
                  })
  }


  const onKeyPress = (e:any) => {
    if(e.key === 'Enter') {
      passwordCheckRes().then()
    }
  }

  const passwordCheckRes = async ()=>{
    try {
      const response = await client.post('/login',{
        id: inputLogin.id.trim(),
        password: inputLogin.pw.trim()
      },)
      if(response.data.status !== 200){
        setErrorState({notice:'* 비밀번호를 다시 확인해주세요.',status:response.data.status})

      }else{
        setErrorState({notice:'',status:response.data.status})
        movePage && (window.location.href = movePage)
        checkEvent && checkEvent()
      }
    }catch ( e ){
      console.log(e)
    }
  }


  return (
    <ModalBase transparent={transparent} modalContent={
      <ModalFrame>
        <ModalLabel location={location.pathname.slice(1,6).includes('admin')}>{'회원정보 확인'}</ModalLabel>
        <div style={{padding:'28px 16px 24px 16px',height:'210px',display:'flex',flexDirection:'column'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"12px"}}>
            <div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold'}} className={'menu-text'}>{'비밀번호'}</div>
            {!viewType &&
                <div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={findClick}>{'비밀번호 찾기'}</div>
            }
          </div>
          <div style={{position:'relative'}}>
            <InputText type={inputType ? 'text':'password'}
                       placeholder={'비밀번호를 입력해주세요.'} name={'pw'} className={'input-style'} readOnly={errorState.status === 200}
                       value={inputLogin.pw.trim()} onChange={loginOnchange} onKeyDown={onKeyPress}/>
            <div style={{position:'absolute',right:0, top:12, fontSize:'12px', color:'#7e84a3', cursor:'pointer', boxSizing:'border-box',paddingRight:'8px'}}
                 onClick={()=>setInputType(!inputType)}>
              {inputType ? 'HIDE' : 'SHOW'}
            </div>
          </div>
          <div style={{display:'flex',fontSize:'12px' ,color:errorState.notice === '* 비밀번호를 다시 확인해주세요.' ?  '#F0142F': 'rgba(23,23,37,0.5)',margin:'8px 0 36px 0',whiteSpace:"pre-wrap"}}>
            {dataValid(errorState.notice,noticeMessage)}
          </div>
          <div style={{display:'flex',justifyContent:'center'}}>
            <MainButtons buttonType={'popupNormal'} width={'144px'} name={'취소'} clickEvent={cancelEvent} height={'40px'}/>
            <MainButtons buttonType={'cta'} width={'144px'} name={'확인'} height={'40px'} clickEvent={passwordCheckRes} padding={'4px 32px'}/>
          </div>
        </div>
      </ModalFrame>
    }/>
  );
};
export default PasswordCheckModal;

const ModalFrame = styled.div`
  position: absolute;
  min-width: 416px;
  height: 262px;
	border-radius: 6px;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	box-shadow: 0 3px 6px #15223214;

  .menu-text{
    color: #7E84A3;
    font-size: 12px;
    font-weight: 700;
    &::after{
      content:'*';
      margin-left: 4px;
      color: #F0142F;
    }
  }
`

const InputText = styled.input`
  width: 384px;
  height: 32px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  font-size: 14px;
  white-space: pre-wrap;
  padding: 0 8px;
  
  &:focus{
    border: 1px solid #0058ff;
  }
  
  &::placeholder{
    font-size: 14px;
    font-weight: normal;
    color: rgba(23,23,37,0.3);
    font-family: 'NanumGothicOTF', sans-serif;
  }
`
