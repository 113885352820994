import React from 'react';
import styled from "styled-components";
import {RegisterInputProps} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";


const RegisterInput:React.FunctionComponent<RegisterInputProps> = ({ type, name, value,disabled,password,
																	   onChange, placeholder,onKeyPress, margin,tabIndex,
																	   maxWidth, maxHeight, limit, error, clickEvent, readOnly}) => {


	const enterKeyEvent = (e:any) => {
		if(e.key === 'Enter'){
			if (onKeyPress) {
				onKeyPress(e)
			}
		}
	}
	const {t}:any = useTranslation()

	const textInputCase = (type: string, name: string, value: string|number|undefined, onChange?:(e:any)=>void,
						   placeholder?: string, onKeyPress?:(e:any)=>void, limit?: number) => {
		switch (type){
			case 'input' : return (
				<InputText type={password?'password':'text'} name={name} value={value} onChange={onChange} disabled={disabled} error={error}
									 margin={margin} spellCheck={false} tabIndex={tabIndex} readOnly={readOnly}
						   onMouseDown={clickEvent} autoComplete={'off'} onFocus={e => e.target.select()}
						   placeholder={t(placeholder)} onKeyDown={enterKeyEvent} maxLength={limit} maxWidth={maxWidth ?? '600px'}/>
			)
			case 'textarea' : return (
				<InputTextBox name={name} value={value} onChange={onChange} placeholder={placeholder} error={error} onMouseDown={clickEvent} maxLength={limit}
							  maxWidth={maxWidth ?? '600px'} maxHeight={maxHeight ?? '96px'} onKeyDown={enterKeyEvent} spellCheck={false}/>
			)
		}
	}

	return (
		<React.Fragment>
			{textInputCase(type, name, value, onChange, placeholder, onKeyPress, limit)}
		</React.Fragment>
	);
};

export default RegisterInput;


const InputText= styled.input<{maxWidth: string, error?: boolean,margin?:string}>`
	width: 100%;
	height: 32px;
	max-width: ${props => props.maxWidth};
	box-sizing: border-box;
	outline: none;
	border: 1px solid ${props => props.error? '#F0142F' : '#D7DBEC'};
	border-radius: 4px;
	padding: 0 8px;
	font-size: 14px;
	font-family: "NanumGothicOTF", sans-serif;
	margin: ${props => props.margin ?? '4px 0'};	
	
	&:focus{
		transition: all 0.3s;
		border: 1px solid ${props => props.error? '#F0142F' : '#0058ff'};
		font-weight: 700;
	}
	
  &::placeholder{
	  font-family: "NanumGothicOTF", sans-serif;
	  font-size: 14px;
	  color: rgba(23, 23, 37, 0.3);
	  box-sizing: border-box;
	  font-weight: normal;
  }
`



const InputTextBox = styled.textarea<{ maxWidth: string , maxHeight: string, error?: boolean}>`
	width: 100%;
	height: ${props => props.maxHeight};
	max-width: ${props => props.maxWidth};
	outline: none;
	box-sizing: border-box;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #d7dbec;
	resize: none;
	font-size: 14px;
	font-family: "NanumGothicOTF", sans-serif;
	display: flex;
	align-items: center;
	line-height: 24px;
	
  &:focus{
    transition: all 0.3s;
    border: 1px solid ${props => props.error? '#F0142F' : '#0058ff'};
	  font-weight: 700;
  }
	
  &::placeholder{
	  font-size: 14px;
	  font-weight: normal;
	  color: rgba(23, 23, 37, 0.3);
	  font-family: "NanumGothicOTF", sans-serif;
  }

  &::-webkit-scrollbar {
		width: 15px;
	  display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`




