import React, {useEffect} from 'react';
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {dataValid} from "../../function/function";
import {setToken} from "../../common/tokenManager";
import {useLocation, useParams} from "react-router-dom";

const LoginCheckContainer = () => {
	const location = useLocation()
	console.log(location.pathname.replace('/',''))
	const loginCheck = async ()=> {
		try {
			const response = await client.post('/login/check',{id: location.pathname.replace('/','')},{headers:{Authorization}})
			const data = response.data.data
			if(data?.loginStatus){
				window.localStorage.setItem( 'companyCode' , data.company_code )
				window.localStorage.setItem( 'profile' , data.image_url )
				window.localStorage.setItem( 'auth' , data.authority )
				window.localStorage.setItem( 'userName' , data.user_name )
				// window.localStorage.setItem( 'companyID' , data.id)
				window.localStorage.setItem( 'authoritiesList' , dataValid( JSON.stringify( data.authoritiesList ) , '' ) )
				window.localStorage.setItem( 'loginStatus', data.loginStatus)
				if( !data.authority?.includes( 'admin' ) ){
					window.localStorage.setItem( 'solution' , data.solution )
					window.localStorage.setItem( 'logo' , data.logo_image_url )
					window.localStorage.setItem( 'company' , data.company_name )
					window.localStorage.setItem( 'address' , data.address )
					window.localStorage.setItem( 'alarmCount' , data.alarmCount )
					// window.localStorage.setItem('authoritiesList',JSON.stringify(data.authoritiesList))
				}
				setToken( {
					token: response.data.data.token
				} )
			}
			else{
				// console.log(data?.loginStatus)
				// window.location.replace('http://localhost:3000')
				window.location.replace('https://test.z-fas.com')
			}
		}
		catch ( e ) {
			console.log(e)
		}
	}

	useEffect(()=>{
		if(!window.localStorage.getItem('loginStatus') || window.localStorage.getItem('loginStatus') === 'false'){
			loginCheck().then()
		}
	},[])

	useEffect(()=>{
		if(!window.localStorage.getItem('companyID')){
			window.localStorage.setItem('companyID', location.pathname.replace('/',''))
		}
	},[])


	return (
		<div style={{backgroundColor:'#d7dbec', width:'100vw', height:'100vh', display:'flex', justifyContent:'center', alignItems:'center'}}>
			<div onClick={()=>window.location.href = '/fas/main'}
				style={{fontSize:'36px', fontWeight:700, cursor:'pointer', padding:'36px'}}>
				페이지 체크 중... 메인 페이지 있어야함.
			</div>
		</div>
	);
};

export default LoginCheckContainer;
