import React from 'react';
import styled from "styled-components";
import RegisterInput from "./RegisterInput";
import {RegisterInputProps} from "../../types/Fundamental";
import DropMenu from "../sideMenu/DropMenu";
import MainButtons from "../button/MainButtons";
import search from "../../asset/image/ic-search.svg";
import calendar from "../../asset/image/ic-calendar.svg";
import blueCheck from '../../asset/image/FAS_icon_blue_check.svg'
import xIcon from '../../asset/image/FAS_icon_x_close.svg'
import {PatternFormat} from "react-number-format";
import ReactDatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import dayjs from "dayjs";
import {OriginalPikerComp} from "../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import {dataValid , phoneNumberFormat} from "../../function/function";
import {useLocation} from "react-router-dom";
import ModalBase from "../modal/ModalBase";

const RegisterComponent:React.FunctionComponent<RegisterInputProps> = ({title, dropWidth,unit,background,
																																				 limit, error, width,
																																				 type, dropArray,buttonWidth,readOnly,
																																				 numberFormat, name, buttonName, component,
																																				 subName, subValue, value, password,
																																				 placeholder,margin,childComponent,listDeleteEvent,listDeleteView,
																																				 clickEvent,subClickEvent,top,left,
																																				 required, onChange,maxHeight,
																																				 onKeyPress, dropClickEvent,useDeleteButton,
																																				 segmentAccess, disabled,maxDate, dropValue,
																																				 titleDisabled, clearEvent,deleteEvent,
																																				 bottomBorder, tabIndex,printTag, buttonMargin,inputDisabled,
																																				 selectDate, paddingRight, dropAddNoneSelect, titleWidth
																																			 }) => {
	const location = useLocation()
	const authValid = location.pathname.includes('admin')
	const [dateSelected, setDateSelected] = React.useState<any>(selectDate)
	const [data, setData] = React.useState<any>(dropArray)
	const [resultDate, setResultDate] = React.useState(selectDate)
	const [showDatePicker, setShowDatePicker] = React.useState(false);
	const handleApply = () => {
		setShowDatePicker(false);
		setResultDate(!dateSelected ? dayjs().format('YYYY.MM.DD') :  dateSelected)
		onChange && onChange(!dateSelected ? dayjs().format('YYYY.MM.DD') :  dateSelected)
	};

	const heightValid = (type: string) => {
		if(type === 'address'){
			return 88
		}
		else if(type === 'textarea'){
			return 112
		} else if(type ==='signUpDoubleCheck'){
			return 76
		}
		else{
			return 48
		}
	}

	React.useEffect(()=>{
		setDateSelected(selectDate)
	},[selectDate])

	React.useEffect(()=>{
		setData(data)
	},[dropArray])
	// console.log(selectDate,'selectData')
	// console.log(resultDate,'resultDate')
	// console.log(dateSelected,'dataSelected')


	const {t}:any = useTranslation();

	interface CalenderProps {
		data: {
			date: Date,
			decreaseMonth(): void,
			decreaseYear(): void
			increaseMonth(): void,
			increaseYear():void
			prevMonthButtonDisabled: boolean,
			nextMonthButtonDisabled: boolean,
		};
		monthType?: boolean;
		yearType?: boolean;
		femsUse?:boolean
		selectEvent?:(date: Date, event: React.SyntheticEvent<any> | undefined)=>void
	}

	const CustomRender = ({data,monthType,yearType}:CalenderProps) => {
		const {t}:any = useTranslation();

		const formatDate = (d: Date): string => {
			if (monthType){
				const date = dayjs(d);
				const monthIndex = date.month();

				const year = date.format(t('YYYY년'));
				const month = date.format('MMMM');

				return  year + ' ' + month;

			}else if(yearType){
				const date = dayjs(d);
				const year = date.format(t('YYYY년'));
				return  year;
			} else {
				const date = dayjs(d);
				const year = date.format(t('YYYY'));
				const month = date.format(t('MM'));
				const day = date.format(t('DD'));
				return  year + '년 '+ month + '월';
			}
		};

		return (
			<div>
				<div style={{display:'flex',width:'100%',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
					<div style={{display:'flex',alignItems:'center'}}>
						<div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear} >
							<button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
						</div>
						<div className={'react-datepicker__navigation--previous'} onClick={data.decreaseMonth}>
							<button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
						</div>
					</div>
					<div style={{display:'flex',justifySelf:'center',fontSize:'14px',color:authValid ? '#000':'#fff'}} className={'react-datepicker__current-month'} >{formatDate(data.date)}</div>

					<div style={{display:'flex',alignItems:'center'}}>
						<div className={'react-datepicker__navigation--next'} onClick={data.increaseMonth}>
							<button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
						</div>
						<div className={'react-datepicker__navigation--next--year'}
								 onClick={data.increaseYear}>
							<button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
						</div>
					</div>
				</div>
				<div style={{display: 'flex' , position: 'absolute' , bottom: '-230px' , left: '8px'}}>
					<MainButtons name={'취소'} clickEvent={()=>{
						setShowDatePicker(false);
						setDateSelected(resultDate)
					}}/>
					<MainButtons name={'적용'} buttonType={'cta'} clickEvent={handleApply}/>
				</div>
			</div>
		)
	}

	const handleDateSelect = (date:Date) => {

		if (date.getTime() === new Date(dateSelected)?.getTime()) {
			handleApply()
			// console.log('================================')
			// console.log(selectDate,'selectDate')
			// console.log(dateSelected,'dateSelected')
			// console.log(resultDate,'resultDate')
		}
	};
	// console.log(selectDate,'selectedDate')
	// console.log(dateSelected,'dateSelected')
	// console.log(resultDate,'resultDate')

	const inputType = (type: string) => {
		switch (type) {
			case 'text' : return (
				<div>
					<RegisterInput type={'input'} name={name} value={value} maxWidth={width} onChange={onChange} limit={limit ?? 30} password={password}
												 error={error} clickEvent={clickEvent}
												 placeholder={t(placeholder ?? '내용을 입력해 주세요.')} onKeyPress={onKeyPress} disabled={disabled}/>
				</div>)

			case 'textarea' : return (
				<div>
					<RegisterInput type={'textarea'} name={name} value={value} onChange={onChange} limit={limit} maxHeight={maxHeight}
												 placeholder={t(placeholder ?? '내용을 입력해 주세요.')} error={error} disabled={disabled}/>
				</div>
			)

			case 'textButton' : return (
				<div style={{position:'relative',display:'flex'}} onClick={clickEvent}>
					<RegisterInput type={'input'} name={name} placeholder={t(placeholder ?? '검색')} onKeyPress={onKeyPress} error={error}
												 maxWidth={width? width : '600px'}
												 margin={margin}
												 value={value} onChange={onChange} clickEvent={clearEvent} disabled={disabled}/>
					<MainButtons name={t(buttonName ?? '검색')} buttonType={'cta'} marginDis={true} margin={buttonMargin?? '0 0 0 8px'}
											 width={buttonWidth} clickEvent={clickEvent}/>
				</div>)

			case 'unit' : return (
				<div style={{background:background}}>
					<RegisterInput type={'input'} name={name} value={value} onChange={onChange} limit={limit ?? 30} maxWidth={width}
												 error={error} clickEvent={clickEvent}
												 placeholder={placeholder} onKeyPress={onKeyPress} disabled={disabled}/>
					<div style={{color:'rgba(23,23,37,0.3)', position:'absolute', right:'24px', fontSize:'14px', lineHeight:'32px'}}>{unit}</div>
				</div>)

			case 'drop' :
				return (
					<div>
						<DropMenu menuArray={dropArray ?? []} width={dropWidth ?? '192px'} addNoneSelectMenu={dropAddNoneSelect}
											disabled={disabled} selectValue={value}
							//@ts-ignore
											clickEvent={(v)=>{dropClickEvent(v)}}
							// clickEvent={(data)=>{Object.assign(inputState, {[stateKey? stateKey : 'key']:data})}}
						/>
					</div>)
			case 'inputDrop' :
				return (

					<div style={{display:'flex'}}>
						<RegisterInput type={'input'} name={name} value={value} onChange={onChange}  maxWidth={'116px'}
													 error={error} clickEvent={clickEvent}
													 placeholder={t(placeholder ?? '')} onKeyPress={onKeyPress} disabled={disabled}/>
						<DropMenu menuArray={dropArray ?? []} width={dropWidth ?? '192px'} addNoneSelectMenu={dropAddNoneSelect}
											style={{left:'138px'}} selectValue={value}
											disabled={disabled}
							//@ts-ignore
											clickEvent={(v)=>{dropClickEvent(v)}}
							// clickEvent={(data)=>{Object.assign(inputState, {[stateKey? stateKey : 'key']:data})}}
						/>
					</div>
				)

			case 'inputWidthDrop' :
				return (
					<div style={{display:'flex'}}>
						<RegisterInput type={'input'} name={name} value={value} onChange={onChange}  maxWidth={'116px'}
													 error={error} clickEvent={clickEvent} limit={limit ?? 6}
													 placeholder={t(placeholder ?? '')} onKeyPress={onKeyPress} disabled={disabled}/>
						<DropMenu menuArray={dropArray ?? []} width={dropWidth ?? '192px'} addNoneSelectMenu={dropAddNoneSelect}
											style={{left:'138px'}} selectValue={dropValue}
											disabled={disabled}
							//@ts-ignore
											clickEvent={(v)=>{dropClickEvent(v)}}
							// clickEvent={(data)=>{Object.assign(inputState, {[stateKey? stateKey : 'key']:data})}}
						/>
					</div>
				)

			case 'search' : return (
				<div style={{position:'relative',display:'flex'}}>
					<RegisterInput type={'input'} name={name} placeholder={placeholder} onKeyPress={onKeyPress} error={error} maxWidth={width}
												 value={value} onChange={onChange} disabled={disabled} readOnly={readOnly} limit={limit ?? 30}/>
					<img src={search} onClick={clickEvent} alt="" style={{position:'absolute',right: paddingRight? 24 : 8, cursor:disabled ? 'auto' : 'pointer'}}/>
				</div>)

			case 'button' : return (
				<div>
					<MainButtons name={dataValid(t(buttonName),'')} width={width} disabled={disabled} clickEvent={clickEvent} marginDis={true} margin={'0'} buttonType={'cta'}/>
				</div>)

			case 'button_textClick' : return (
				<div style={{display:'flex',justifyContent:'space-between'}}>
					<MainButtons name={dataValid(t(buttonName),'')} width={width} clickEvent={clickEvent}/>
					<div className={'textClick'} onClick={subClickEvent}
							 style={{fontSize:'14px', fontWeight:700, cursor:'pointer', width:'auto',textUnderlineOffset:'4px'}}>{dataValid(name,'')}</div>

				</div>
			)
			case 'textClick' : return (
				<div className={'textClick'} onClick={clickEvent}
						 style={{fontSize:'14px', fontWeight:700, cursor:'pointer', width:'auto',textUnderlineOffset:'4px',
							 pointerEvents: disabled? 'none' : 'auto'}}>
					{dataValid(buttonName,'')}</div>)

			case 'calendar' : return (
				<div style={{position:'relative',display:'flex',background:background}}>
					<OriginalPikerComp authValid={authValid}>
						<div style={{display:useDeleteButton ? 'none' :'flex',position:'absolute',cursor:'pointer',top:-7, left: 145,fontWeight:700,zIndex:49}}
								 onClick={()=>{if(useDeleteButton){
									 return
								 }else{
									 setDateSelected('')
									 if(deleteEvent){
										 deleteEvent()
									 }
								 }
								 }}>
							{dateSelected && 'X'}
						</div>

						<div style={{display:'flex',position:'absolute',top:-19, left: 0, width:width ?? '192px',zIndex:1}}
								 onClick={()=>{setShowDatePicker(true); setResultDate(selectDate)}}>
							<RegisterInput type={'input'} tabIndex={tabIndex} name={name} placeholder={placeholder} onKeyPress={onKeyPress} error={error} disabled={disabled}
														 value={!dateSelected ?'':dayjs(dateSelected).format('YYYY.MM.DD')} onChange={onChange} />
							<img src={calendar} onClick={clickEvent} alt="" style={{position:'absolute', right: 6, top:8, cursor:'pointer'}}/>
						</div>
						{showDatePicker &&
							<ModalBase transparent={true} top={top} left={left} modalContent={
								<ReactDatePicker selected={!dateSelected ? dayjs().toDate() : dayjs(dateSelected).toDate()}
																 onChange={setDateSelected}
																 dateFormat={'yyyy/MM/dd'}
																 open={showDatePicker}
																 popperPlacement={'auto-start'}
																 shouldCloseOnSelect={showDatePicker}
																 onSelect={handleDateSelect}
																 locale={ko}
																 onClickOutside={()=>{setShowDatePicker(false); setDateSelected(resultDate)}}
																 maxDate={maxDate ?? new Date()}
																 customInput={<div style={{appearance:'none'}}/>}
																 renderCustomHeader= {({date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled}
																 ) => (<CustomRender data={{date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled, }}/>)}
								/>
							}/>
						}
					</OriginalPikerComp>
				</div>
			)


			case 'address' : return (
				<div style={{display:'flex', flexDirection:'column', height:'88px',justifyContent:'center'}}>
					<div style={{position:'relative',display:'flex',alignSelf:'flex-start',width:'100%'}}>
						<RegisterInput type={'input'} name={name}
													 placeholder={t(placeholder ?? '주소를 검색해 주세요.')}
													 onKeyPress={onKeyPress} error={error}
													 value={value} onChange={onChange} clickEvent={clickEvent}/>
						<MainButtons name={buttonName ?? '주소검색'} buttonType={'cta'} marginDis={true}
												 width={width} clickEvent={clickEvent}/>
					</div>
					<div style={{position:'relative',display:'flex',alignSelf:'flex-start',width:'100%'}}>
						<RegisterInput type={'input'} name={subName ?? ''} placeholder={t(placeholder ?? '상세 주소를 입력해 주세요.')}
													 onKeyPress={onKeyPress}
													 value={subValue} onChange={onChange} limit={limit ?? 30}/>
						{/*<div style={{minWidth:`${width}`}}>*/}
						{/*	/!*인풋 길이 맞추기 위한 빈 태그*!/*/}
						{/*</div>*/}
					</div>
				</div>
			)

			case 'phone' : return (
				<div>
					<PatternFormat format={phoneNumberFormat(numberFormat) ?? '###-####-####'} type={'tel'} onClick={clickEvent}
												 name={name} placeholder={t(placeholder ?? '번호를 입력해 주세요.')} onBlur={onChange}
												 autoComplete={'off'}
												 value={value} onChange={onChange} className={'formatInput'} />
				</div>
			)

			case 'bussinessNumber' : return (
				<div>
					<PatternFormat format={'###-##-#####'} type={'tel'} onClick={clickEvent} autoComplete={'off'}
												 name={name} placeholder={t(placeholder ?? '사업자 번호를 입력해 주세요.')} onBlur={onChange}
												 value={value} onChange={onChange} className={'formatInput'} />
				</div>
			)

			case 'segment' : return (
				<div style={{position:'relative',display:'flex'}}>
					<MainButtons name={t(buttonName ?? '')} width={width} disabled={disabled} clickEvent={clickEvent} marginDis={true}
											 margin={'0'}/>
					{segmentAccess && <img src={blueCheck} style={{marginLeft:'4px'}} alt=""/>}
				</div>)

			case 'print' :
				return (
					<div style={{position:'relative',display:'flex'}}>
						<RegisterInput type={'input'} name={name} placeholder={placeholder} clickEvent={clickEvent} error={error}
													 value={value} onChange={onChange} disabled={value === t('도면을 등록해 주세요.')} />
						{value !== t('도면을 등록해 주세요.') &&
							<img src={xIcon} alt="" onClick={clearEvent}
								//@ts-ignore
									 style={{position:'absolute',cursor:'pointer',display:disabled ? 'none' :"block", right: `${parseInt(width?.slice(0,-2)) + 28}px`}}/>}
						{printTag}
					</div>)

			case 'ict':
				return (
					<div style={{position:'relative',display:'flex',width:width}}>
						<RegisterInput type={'input'} name={name} placeholder={t(placeholder??'ICT번호를 생성해 주세요.')}
													 error={error} value={value} onChange={onChange} disabled={true} />
						<MainButtons name={buttonName ?? '발급'} width={buttonWidth ?? '48px'} marginDis={true} disabled={disabled} margin={'0'}
												 buttonType={'cta'} clickEvent={clickEvent}/>
					</div>
				)


			case 'doubleCheck':
				return (
					<div style={{position:'relative',display:'flex',width:width,background:background}}>
						<RegisterInput type={'input'} name={name} placeholder={t(placeholder??'내용을 입력해 주세요.')} onKeyPress={onKeyPress} disabled={inputDisabled}
													 error={error} value={value} onChange={onChange} clickEvent={clearEvent} maxWidth={width}/>
						<MainButtons name={buttonName ?? '중복확인'} width={'60px'} marginDis={true} disabled={disabled} margin={'0'}
												 buttonType={'cta'} clickEvent={clickEvent}/>
					</div>
				)
			case 'signUpDoubleCheck':
				return (
					<div style={{position:'relative',display:'flex',top:'-8px'}}>
						<RegisterInput type={'input'} name={name} placeholder={t(placeholder??'내용을 입력해 주세요.')}
													 maxWidth={'calc(100% - 90px)'} password={password} onKeyPress={onKeyPress}
													 error={error} value={value} onChange={onChange} clickEvent={clearEvent}/>
						<MainButtons name={buttonName ?? '중복확인'} width={'72px'} marginDis={true} disabled={disabled}
												 buttonType={'cta'} clickEvent={clickEvent}/>
					</div>
				)
			case 'emptyWithChild' : return (
				<div style={{fontSize:'14px',whiteSpace:'nowrap',background:background}}>{childComponent}</div>
			)

			case 'empty' : return (
				<div style={{fontSize:'14px',wordBreak: 'break-all'}}>{
					t(value)}
				</div>
			)
		}
	}

	return (
		<BaseComponent disabled={titleDisabled} error={error} bottomBorder={bottomBorder} paddingRight={paddingRight} titleWidth={titleWidth}>
			<div style={{height:`${heightValid(type)}px`, userSelect:'none',backgroundColor:readOnly ? '#D7DBEC' : '#F5F6FA'}}>
				{title}
				{required && <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>}
				{listDeleteView && <div className={'list_delete'} onClick={listDeleteEvent}>{'✕'}</div>}
			</div>
			{component? component : inputType(type)}
		</BaseComponent>
	);
};

export default RegisterComponent;

const BaseComponent = styled.div<{ disabled? : boolean , error?: boolean, bottomBorder?: boolean, width?:string, paddingRight?:boolean,titleWidth?:string}>`
	display: flex;
	border-top: 1px solid #E6E9F4;
	border-bottom: ${ props => props.bottomBorder? 1 : 0}px solid #E6E9F4;
	//user-select: none;

	& > div {
		//height: 48px; 
		box-sizing: border-box;
		padding-left: 16px;
		padding-right: ${props => props.paddingRight? '16px' : 0};
		align-items: center;
		display: flex;
	}

	& > div:first-child {
		width: ${props=> props.titleWidth ?? '168px'};
		min-width: ${props=> props.titleWidth ?? '168px'};
		background-color: #F5F6FA;
		font-size: 12px;
		font-weight: 800;
		pointer-events: ${ props => props.disabled? 'none' : 'auto'};
		color: rgba(19, 21, 35, ${props => props.disabled? 0.3 : 1});
	}

	& > div:last-child {
		width: 100%;
		max-width: 616px;
		//max-height: 48px;
		position: relative;
	}

	.textClick{
		text-decoration: underline;
		color: rgba(90,96,127,0.3);
		width: auto;
		&:hover{
			text-decoration: none;
			color: rgba(90,96,127,1)
		}
	}


	.formatInput {
		width: 100%;
		height: 32px;
		box-sizing: border-box;
		outline: none;
		border: 1px solid ${props => props.error? '#F0142F' : '#D7DBEC'};
		border-radius: 4px;
		padding: 0 8px;
		font-size: 14px;
		font-family: "NanumGothicOTF", sans-serif;


		&:focus{
			transition: all 0.3s;
			border: 1px solid ${props => props.error? '#F0142F' : '#0058ff'};
			font-weight: 700;
		}

		&::placeholder{
			font-family: "NanumGothicOTF", sans-serif;
			font-size: 14px;
			color: rgba(23, 23, 37, 0.3);
			box-sizing: border-box;
			font-weight: normal;
		}
	}
	.list_delete{
		width: 32px;
		height: 32px;
		border-radius: 4px;
		border: 1px solid #D7DBEC;
		background: #fff;
		font-weight: normal;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		box-sizing: border-box;
		cursor: pointer;
		margin: 0 16px 0 auto;
		&:hover{
			background: #5A607F;
			color:#fff;
		}
	}
`