import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import PartnerPermissionManagement from "../../../containers/admin/fas/partner/auth/PartnerPermissionManagement";

const AdminFasPartnerPermission = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
        <SectionContainer contentContainer={<PartnerPermissionManagement/>}/>
    </PageContainer>
  );
};
export default AdminFasPartnerPermission;