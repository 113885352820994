import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../components/title/MenuTitle";
import {
	ContentFrame ,
	GridTableComponent , GridTableSelect ,
	NoData ,
	SectionFrame ,
	validationValue
} from "../../../../style/StyleComponent";
import UserFactoryList from "../../../../components/list/UserFactoryList";
import checked from "../../../../asset/image/check-icon.svg";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {useRecoilState , useRecoilValue , useResetRecoilState} from "recoil";
import {
	calendarRangeEndDate , calendarRangeStartDate ,
	userPersistCompanyData
} from "../../../../common/StateManage";
import MainButtons from "../../../../components/button/MainButtons";
import ArrowButton from "../../../../components/button/ArrowButton";
import SelectEventButton from "../../../../components/button/SelectEventButton";
import TableValue from "../../../../style/TableValue";
import {useTranslation} from "react-i18next";
import CalendarRangePicker from "../../../../components/calendar/CalendarRangePicker";


const UserNoticeContainer = () => {
	const solutionData = ['P-BAS','P-MS','R-BAS','C-BAS','V-MS', 'A-BAS','F-EMS']
	const defaultChecked = ['P-BAS', 'V-MS']
	const menuTitle = ['솔루션','제조사','설비명','ICT번호','알림','소모품 종류','알림일시'];
	const selectedCompany = useRecoilValue(userPersistCompanyData)
  const {t}:any = useTranslation()
	const [factoryInfoDataState,setFactoryInfoDataState] = React.useState<any[]>([]);
	const [checkedList,setCheckedList] = React.useState<string[]>(defaultChecked);
	const [checkBoxDrop,setCheckBoxDrop] = React.useState<boolean>(false);
	const [dateSearch,setDateSearch] = React.useState<boolean>(false);
	const [selectFactoryData,setSelectFactoryData] = React.useState<any>({})
	const [selectIdx,setSelectIdx] = React.useState<number>(-1);
	const [startDate, setStartDate] = useRecoilState(calendarRangeStartDate)
	const [endDate, setEndDate] = useRecoilState(calendarRangeEndDate)
	const resetStartDate = useResetRecoilState(calendarRangeStartDate)
	const resetEndDate = useResetRecoilState(calendarRangeEndDate)

	const onCheckedType = (checked:any, item:any) => {
		if (checked) {
			setCheckedList([...checkedList, item]);
		} else if (!checked) {
			setCheckedList(checkedList.filter(el => el !== item));
		}
	};
	const totalAlarmList = async () =>{
		try {
			const response = await client.post(`/alarm/list`,{
				code:selectFactoryData?.factoryCode,
				date:`${startDate + '-' + endDate}`,
				solutionList:checkedList,
			},{headers:{Authorization}})
			const data = response.data.data
			setFactoryInfoDataState(data.row)
		}catch ( e:any) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		if(selectFactoryData.factoryCode){
			if(checkedList.length > 0){
				totalAlarmList().then()
				const timerAlarm = setInterval(()=>{
					totalAlarmList().then()
				},60000)
				return () => clearInterval(timerAlarm)
			}
		}
	},[startDate, endDate, selectFactoryData.factoryCode])

	const [currentTime, setCurrentTime] = useState(new Date());

	useEffect(() => {
		const timerID = setInterval(() => {
			setCurrentTime(new Date());
		}, 1000);
		return () => {
			clearInterval(timerID);
		};
	}, []);

	const getElapsedTime = (targetTime:any) => {
		const elapsedTime = currentTime.getTime() - targetTime.getTime();
		return Math.max(0, Math.floor(elapsedTime / 1000));
	};

	const timeCounter = (pastTime:any) =>{
		const targetTime = new Date(pastTime);
		const elapsedTime = getElapsedTime(targetTime);
		const hours = Math.floor(elapsedTime / 3600);
		const minutes = Math.floor((elapsedTime % 3600) / 60);
		if(hours === 0 && minutes !== 0){
			return minutes + '분 전'
		}else if(hours >= 24){
			return pastTime
		}else if(hours === 0 && minutes === 0){
			return '방금 전'
		}else{
			return hours  + '시간 전'
		}
	}

	return (
		<SectionFrame>
			<div style={{display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
				<MenuTitle title={'알림'} unitCase={'MenuTitle'}/>
				<div style={{display: 'flex',alignItems:'center'}}>
					<div style={{display: 'flex',marginRight:dateSearch ? "20px" : '0'}}>
						<SelectEventButton height={'34px'} buttonName={'실시간'} buttonEvent={() => {setDateSearch( false ); resetStartDate(); resetEndDate()}}
															 buttonStatus={!dateSearch} buttonBorder={true}/>
						<SelectEventButton height={'34px'} buttonName={'날짜검색'} buttonEvent={() => setDateSearch( true )}
															 buttonStatus={dateSearch} buttonBorder={true}/>
					</div>
					{dateSearch &&
						<CalendarRangePicker receiveDate={(v)=> {setStartDate(v.start); setEndDate(v.end)}} calendarName={'날짜검색'} disabled={!selectedCompany.companyCode}
																 currentSelectDate={{start:startDate, end:endDate}}/>
					}
				</div>
			</div>

			<div style={{display:'flex', justifyContent:'space-between'}}>
				<React.Fragment>
					<UserFactoryList receiveFactoryData={setSelectFactoryData}/>
					<ContentFrame width={'840px'} justify={'space-between'}>
						<div>
							<div style={{display:'flex',justifyContent:'space-between'}}>
								<MenuTitle title={selectFactoryData.factoryCode ? t('알림 정보') + `(${selectFactoryData.factoryName})` : t('알림 정보')} unitCase={'MenuCompTitle'}/>
								<div style={{display:'flex',alignItems:'center'}}>
									<MainButtons name={'필터 적용'} clickEvent={totalAlarmList} disabled={checkedList.length === 0 || !selectFactoryData.factoryCode}/>
									<InnerBox onClick={()=>{setCheckBoxDrop(!checkBoxDrop)}}>
										<ArrowButton arrowDirection={!checkBoxDrop ? 'bottom' :'top'}/>
									</InnerBox>
								</div>
							</div>
							<div style={{marginTop:'16px',pointerEvents:`${factoryInfoDataState.length === 0 ? 'none' : 'auto'}`}}>
								<CheckBox isOpened={checkBoxDrop}>
									<div className={'category_title'}>{t('솔루션')}</div>
									<div className={'category_list'}>
										{solutionData.map((v:any)=>(
											<div style={{minWidth:'96px'}}>
												<label style={{display:'flex',opacity:`${defaultChecked.includes(v) ? 1: 0.3}`,pointerEvents:`${defaultChecked.includes(v) ? 'auto': 'none'}`,alignItems:'center',gap:'8px'}}>
													{/*@ts-ignore*/}
													{/*<input type="checkbox" defaultChecked={v === 'P-BAS'}/>*/}
													<input type="checkbox" value={v} checked={checkedList.includes(v)}
																 onChange={(event)=>onCheckedType(event.target.checked,event.target.value)}/>
													<div>{v}</div>
												</label>
											</div>
										))}
									</div>
								</CheckBox>
							</div>
							<GridTableComponent id={'grid-scroll'}
																	titleCheckDisabled={true} height={510}
																	scrollWidth={980} marginCus={0}
																	tempCol={'48px 128px 128px 88px 144px 128px 1fr'}>
								<div className={'grid-title-unchecked'}>
									{menuTitle.map((name)=>
																	 <TableValue title={name} requiredValue={[]} key={name}/>
									)}
								</div>

								{!selectFactoryData.factoryCode ?
									<NoData>{t('* 공장을 선택해 주세요.')}</NoData>
									:
									factoryInfoDataState.length === 0 ?
										<NoData>{t('* 해당 공장에 알림정보가 없습니다.')}</NoData>
										:
										factoryInfoDataState.map((v:any,i:number)=>(
											<GridTableSelect  className={'grid-list-unchecked-no_icon'}
																				selected={i === selectIdx}
																				// onDoubleClick={()=>{window.location.href = `/admin/fems/alarm`}}
																				onClick={()=>{setSelectIdx(i)}}>
												{validationValue(v.solution)}
												{validationValue(v.maker)}
												{validationValue(v.facilityName)}
												{validationValue(v.ictNumber)}
												{validationValue(v.message)}
												{validationValue(v.alarmState === 'check' && '에러')}
												{validationValue(timeCounter(v.alarmTime))}
											</GridTableSelect>
										))}
							</GridTableComponent>

						</div>
					</ContentFrame>
				</React.Fragment>
			</div>
		</SectionFrame>
	);
};

export default UserNoticeContainer;

const CheckBox = styled.div<{isOpened?:boolean}>`
	display: flex;
	width: 780px;
	border-right: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
	border-left: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
	border-top: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
	border-bottom: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
	user-select: none;
	transition: all 400ms;


	.category_title{
		min-width: 96px;
		display: flex;
		align-items: center;
		padding-left: 16px;
		text-align: left;
		font-size: 12px;
		font-weight: bold;
		transition: all 400ms;
		//line-height: 48px;
		height: ${p=>p.isOpened ? 0 :'48px'};
		//min-height: 48px;
		opacity: ${p=>p.isOpened ? 0 : 1};
		overflow: ${p=>p.isOpened? 'hidden' : 'visible'};
		box-sizing: border-box;
		border-right: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
	}

	.category_list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		transition: all 400ms;
		white-space: pre-wrap;
		//line-height: 48px;
		//min-height: 48px;
		height: ${p=>p.isOpened ? 0 :'48px'};
		opacity: ${p=>p.isOpened ? 0 : 1};
		overflow: ${p=>p.isOpened? 'hidden' : 'visible'};
		font-size: 12px;
		font-weight: 300;
		color: #7E84A3;
		padding-left: 16px;
		box-sizing: border-box;

		input[type='checkbox'] {
			appearance: none;
			cursor: pointer;
			width: 16px;
			height: 16px;
			border: 1px solid #7E84A3;
		}

		input[type='checkbox']:checked {
			background-color: #0058FF;
			background-image: url(${checked});
			background-position: center;
			background-repeat: no-repeat;
			border: none;
		}
	}
`
const InnerBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #D7DBEC;
  cursor: pointer;
  &:hover{
    background-color: #F5F6FA;
  }
`