import React , {useState} from 'react';
import {
  ContentFrame ,
  GridTable ,
  GridTableComponent ,
  GridTableSelect ,
  NoData ,
  NoResult , validationValue
} from "../../style/StyleComponent";
import MenuTitle from "../title/MenuTitle";
import {FactoryDataType} from "../../types/Fundamental";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import styled from "styled-components";
import TimeDayModule from "../unit/TimeDayModule";
import {authValid , listLoadingCircle , loadingRemove} from "../../function/function";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useRecoilState , useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";

interface Props {
  receiveFactoryData: ( data: any ) => void
}

const UserFactoryList = ( {receiveFactoryData}: Props ) => {
  const {t}: any = useTranslation();
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [factoryList , setFactoryList] = useState<any[]>( [] )

  const [page, setPage] = useState(1)

  const [factoryCode , setFactoryCode] = useState<string>( '' );

  const [statusMessage , setStatusMessage] = useState<string>( '' )

  const factoryListRes = async ()=>{
      setStatusMessage('공장 목록을 불러오는 중 입니다.')
      try {
          // listLoadingCircle()
          // const response = await client.get(`/alarm/facAlarmCnt/${selectedCompany.companyCode}`,{
          const response = await client.get(`/factory/list/${page}/10/${selectedCompany.companyCode}`,{
              headers:{Authorization}
          })
          const data = response.data.data
          setFactoryList(data.row)
          setStatusMessage('등록된 공장이 없습니다.')
          loadingRemove()
      }catch (e){
          console.log(e)
          loadingRemove()
          setStatusMessage('목록을 가져올 수 없습니다. \n 관리자에게 문의해 주세요.')
      }
  }

  React.useEffect(()=>{
    factoryListRes().then()
  },[])

  // const orderData = factoryList?.sort( function ( a , b ) {return b.count - a.count} );
  // const totalCount = orderData?.map( ( v ) => ( v.count ) ).reduce( ( add , currentValue ) => ( add + currentValue ) , 0 )
  return (
    <ContentFrame width={'410px'}>
      <MenuTitle title={'공장 리스트'} unitCase={'MenuCompTitle'}/>

      <GridTableComponent tempCol={'136px 1fr'} scrollWidth={340} height={528} scrollX_Hide={true}>
        <div className={'grid-title-unchecked'}>
          {['공장명' , '시간'].map(( v) => (<div key={v}>{t( v )}</div>))}
        </div>
        {factoryList?.map((v)=>(
          <GridTableSelect className={'grid-list-unchecked-no_icon'}
                           selected={v.factoryCode === factoryCode}
                           onMouseDown={()=>setFactoryCode(v.factoryCode)}
                           onClick={()=>receiveFactoryData(v)}>
            {validationValue(v.factoryName)}
            {validationValue('','알림 없음')}
          </GridTableSelect>
        ))}
      </GridTableComponent>


      {/*<GridTable tempCol={'136px 1fr'}>*/}
      {/*  <div className={'table_title'}>*/}
      {/*    {['공장명' , '시간'].map( ( v ) => ( <div key={v}>{t( v )}</div> ) )}*/}
      {/*  </div>*/}
      {/*  {totalCount !== 0 &&*/}
      {/*    <ListWrap selected={factoryCode === '전체보기'} alarmCount={alarmTotalCount?.toString()}*/}
      {/*              className={'listWrap'} onClick={()=>{setFactoryCode('전체보기')}}>*/}
      {/*        <div>{'전체보기'}</div>*/}
      {/*        <div>{'-'}</div>*/}
      {/*    </ListWrap>*/}
      {/*  }*/}
      {/*</GridTable>*/}
      {/*{!orderData ?*/}
      {/*  <NoData>{statusMessage}</NoData>*/}
      {/*  :*/}
      {/*  orderData?.map( ( v , index ) => (*/}
      {/*    <ListWrap key={v.factoryCode} selected={v.factoryCode === factoryCode} className={'listWrap'}*/}
      {/*              onClick={() => {*/}
      {/*                receiveFactoryData( v );*/}
      {/*                setFactoryCode( v.factoryCode )*/}
      {/*              }} alarmCount={'0'}>*/}

      {/*      <div className={'alarm_circle'}>{v.factoryName}</div>*/}
      {/*      <div>{'알림 없음'}</div>*/}
      {/*      /!*{v.count === 0 ?*!/*/}
      {/*      /!*  <div>{'-'}</div>*!/*/}
      {/*      /!*  :*!/*/}
      {/*      /!*  <TimeDayModule alarmedTime={v.alarmTime}/>*!/*/}
      {/*      /!*}*!/*/}
      {/*    </ListWrap>*/}
      {/*  ) )}*/}

    </ContentFrame>
  );
};
export default UserFactoryList;
const ListWrap = styled.div<{ selected: boolean, alarmCount?: string, }>`
  &.listWrap {
    width: 100%;
    min-width: 350px;
    display: grid;
    grid-template-columns: 163px 1fr;
    font-size: 14px;
    align-items: center;
    padding-left: 16px;
    height: 46px;
    box-sizing: border-box;
    transition: all 0.2s;
    color: ${p => p.selected ? '#0058ff' : '#171725'};
    background-color: ${p => p.selected ? 'rgba(0, 88, 255, 0.05)' : '#fff'};
    border: 1px solid ${p => p.selected ? '#0058ff' : '#fff'};
    font-weight: ${p => p.selected ? 700 : 400};
    user-select: none;
    cursor: pointer;
    position: relative;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      background-color: rgba(0, 88, 255, 0.05);
      color: #0058FF;
      border: 1px solid ${p => p.selected ? '#0058ff' : 'rgba(0,88,255,0.05)'};
    }

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #F0142F;
      align-items: center;
      display: flex;
      justify-content: center;
      border-radius: 50%;
      position: absolute;
      right: 55%;
      visibility: ${p => p.alarmCount === '0' ? 'hidden' : 'visible'};
    }
  }
`
// const Circle = styled.div`
//   width: 18px;
//   height: 18px;
//   background-color: #F0142F;
//   align-items: center;
//   display: flex;
//   justify-content: center;
//   border-radius: 50%;
//   color: #fff;
//   font-size: 10px;
//   position: absolute;
//   right: 55%;