import React , {useEffect , useState} from 'react';
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {
  SectionFrame ,
  ContentFrame ,
  GridTable ,
  SelectGridList ,
  NoData ,
  validationValue ,
  listResultMessage ,
  tooltipModule ,
  ModalBackCss , autoTextValueValidation
} from "../../../../../style/StyleComponent";
import {
  persistPage ,
  persistBlockPage ,
  selectViewType ,
  selectedCode ,
  searchInputState ,
  facilityTypeState ,
  userPersistCompanyData , persistCompanyData ,
} from "../../../../../common/StateManage";
import MenuTitle from "../../../../../components/title/MenuTitle";
import SelectEventButton from "../../../../../components/button/SelectEventButton";
import FacilityContentBox from "../../../../../components/display/FacilityContentBox";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {useRecoilState , useRecoilValue} from "recoil";
import {useTranslation} from "react-i18next";
import {Error_Type , FacilityDataType , Modal_View_Type} from "../../../../../types/Fundamental";
import MainButtons from "../../../../../components/button/MainButtons";
import SearchBar from "../../../../../components/input/SearchBar";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import CompanyInfoComponent from "../../../../../components/company/CompanyInfoComponent";
import {
  affiliateAuth ,
  allCheckedFunction ,
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initError ,
  initModal ,
  loadingRemove ,
  menuVisitLogRegister ,
  removeCheckedFunction ,
  singleCheckedFunction
} from "../../../../../function/function";
import dayjs from "dayjs";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import modify from '../../../../../asset/image/FAS_list_icon_modify.svg'
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import {selectCompanyFunction} from "../../../../../components/header/Header";
import FacilityLog from "./FacilityLog";
import FilterWithSearchBar from "../../../../../components/input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

export type FacilityType = {
  profileImageUrl: string,
  type: string,
  manufacturingNumber: string,
  maker: string,
  ictNumber: string,
  companyName: string,
  facilityCode: string,
  facilityName: string,
  imageUrl: string[]
}
const FacilityList = () => {

  const titleList = authValid() ? ['No.' , '기종' , '제조번호' , '제조사' , '기계명' , 'ICT번호' , '업체명' , '네트워크' , 'HW'] : ['No.' , '기종' , '제조번호' , '제조사' , '기계명' , '네트워크' , 'HW']
  const [selectFacility , setSelectFacility] = useRecoilState(selectedCode)
  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  const [filterState , setFilterState] = useRecoilState(facilityTypeState)
  const [selectedView , setSelectedView] = useRecoilState(selectViewType)
  const [pageState , setPageState] = useRecoilState(persistPage)
  const [pageBlock , setPageBlock] = useRecoilState<number>(persistBlockPage);
  const [totalPage , setTotalPage] = useState<number>(0)
  // const [registerPageState, setRegisterPageState] = useRecoilState(facilityRoute)
  const [searchInput , setSearchInput] = useRecoilState<string>(searchInputState)
  const [prevSearch , setPrevSearch] = useState<string>(searchInput)
  const [facilityDataState , setFacilityDataState] = useState<any>([])
  // const [selectCompanyFilterState, setSelectCompanyFilterState] = useState<{companyName: string, companyCode: string}>({companyName:'', companyCode:'@'})
  // const [persistCompanyCode, setPersistCompanyCode] = useRecoilState(companyCodePersist)
  // const [prevCompanyData, setPrevCompanyData] = React.useState<any>({companyName:'', companyCode:'@'})
  // const [modalOpenState, setModalOpenState] = useState<boolean>(false)
  const [dataModal , setDataModal] = useState<Modal_View_Type>(initModal)
  const [errorState , setErrorState] = useState<Error_Type>(initError)
  const [prevImage , setPrevImage] = useState<string>('')
  const [checkedList , setCheckedList] = useState<any>([])
  const [idIndex , setIdIndex] = useState<number>(facilityDataState?.length)
  const [hoverIcon , setHoverIcon] = useState<string>(modify)
  const [iconSelect , setIconSelect] = useState<number>(- 1)
  const [focusList , setFocusList] = useState<number>(- 1)
  const [scrollPosition , setScrollPosition] = useState<number>(0)
  const initInputValue = {
    companyCode: 'noCodeData' ,
    factoryCode: 'noCodeData' ,
    facilityName: '' ,
    hw: '' ,
    ictNumber: '' ,
    image: null ,
    location: '' ,
    manufacturingNumber: '' ,
    maker: '' ,
    notice: '' ,
    type: '프레스' ,
    version: '' ,
    companyName: '' ,
    address: '' ,
  }
  const [modifyList , setModifyList] = React.useState<FacilityDataType>(initInputValue)
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})
  const onChangeValue = (e: any) => {
    const {value , name} = e.target
    setModifyList({
                    ...modifyList ,
                    [name]: value
                  });
  }
  const {t}: any = useTranslation();
  // const listFilter = [{name:t('전체'), keyValue:'@'},   {name:t('프레스'), keyValue:'프레스'},
  //     {name:t('로봇'), keyValue: '로봇'},{name:'CNC', keyValue: 'CNC'},{name:'용접기', keyValue: 'welding'},
  //     {name:'밀링', keyValue: 'milling'},{name:'선반', keyValue: 'lathe'},{name:'탭핑기', keyValue: 'tapping'},
  // ]
  const listFilter = [t('전체') , t('프레스') , t('로봇') , 'CNC' ,
    // t('용접기'),t('밀링'),t('선반'),t('탭핑기')
  ]
  const getCompanyID = window.localStorage.getItem('companyID')
  const getAuth = window.localStorage.getItem('auth')
  const facilityListRes = () => getAuth === 'affiliate' ?
    ListSearchPostCustomHooks(`/facility/affiliate/facility/list` , pageState , setTotalPage , setPageBlock , setFacilityDataState , setErrorState , {page: pageState , size: 9 , id: getCompanyID , type: filterState === '전체' ? '@' : filterState , keyword: dataValid(searchInput , '@')})
    :
    ListCustomHooks(`/facility/list/${pageState}/9/${dataValid(selectedCompany.companyCode,'@')}` , pageState , setTotalPage , setPageBlock , setFacilityDataState , setErrorState , setSearchInput , setPrevSearch)
  const facilitySearchRes = () => ListSearchPostCustomHooks(getAuth === 'affiliate' ? `/facility/affiliate/facility/list` : ( authValid() ?
                                                              !selectedCompany.companyCode?
                                                              `/facility/admin/row/search` : `/facility/admin/row/search/companyCode`

                                                              : `/facility/admin/row/search/companyCode` )
    , pageState , setTotalPage , setPageBlock , setFacilityDataState , setErrorState , {
                                                              companyCode: dataValid(selectedCompany.companyCode,'@'),
                                                              page: pageState , size: 9 ,
                                                              id: getCompanyID , filter:categorySelect.type,
                                                              type: filterState === '전체' ? '@' : filterState ,
                                                              keyword: dataValid(searchInput , '@') , })
  const facilitySimpleModify = async () => {
    try {
      dataAccessLoading()
      const response = await client.post(`/facility/simple/update/${selectFacility}` , {
        facilityName: modifyList.facilityName ,
        hw: modifyList.hw ,
        maker: modifyList.maker ,
        network: modifyList.network ,
        manufacturingNumber: modifyList.manufacturingNumber ,
      } , {headers: {Authorization}})
      const data = response.data
      if(data.status !== 200){
        setDataModal({menu: 'confirm' , message: t(data.message)})
        loadingRemove(200)
      } else{
        setTimeout(() => {
          setDataModal({menu: 'success' , message: t('수정 되었습니다.')})
          setFacilityDataState(facilityDataState.map((v: any) => {return v.facilityCode === selectFacility ? {...v , ...modifyList} : v}))
          setIconSelect(- 1)
          loadingRemove()
        } , 500)
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }
  const facilityInfinityRes = async () => {
    if(selectFacility === ''){
      setFacilityDataState([])
    } else{
      try {
        const response = await client.get(`/facility/infinite/facility/list/${selectFacility}/프레스/@` ,
                                          {headers: {Authorization}})
        const dataValue = response.data.data
        setFacilityDataState(dataValue.row)
      }
      catch (e) {
        console.log(e)
      }
    }
  }
  React.useEffect(() => {
    if(selectedView.index === 2){
      facilityInfinityRes().then()
    } else{
      if(filterState === '전체' && prevSearch === ''){
        facilityListRes().then()
      } else{
        facilitySearchRes().then()
      }
    }
  } , [pageState , prevSearch , filterState , selectedView, selectedCompany.companyCode,categorySelect.type])

  useEffect(()=>{
    selectCompanyFunction(()=>{
      setPageState(1)
      setPageBlock(0)
    })
  },[selectedCompany.companyCode])

  const [logState, setLogState] = useState<any[]>([])
  const facilityDetailResponse = async () => {
    try {
      const response = await client.post('/facility/detail',{
        facilityCode: selectFacility
      },{headers: {Authorization}})
      const data = response.data.data
      if(response.data.status === 200){
        setLogState(data.log)
      }
      else{
        setLogState([])
      }
    }catch (e) {
      console.log(e)
    }
  }

  useEffect(()=>{
    facilityDetailResponse().then()
  },[selectFacility])


  const searchEventFunc = () => {
    setPrevSearch(searchInput)
    setPageState(1)
    setPageBlock(0)
  }
  const addListRow = () => {
    setIdIndex(idIndex + 1)
    const rowInput = {
      id: idIndex , orderDate: dayjs(new Date()).format('YYYY.MM.DD') ,
      orderNumber: `20230101-000${idIndex}` ,
      deliveryDate: dayjs(new Date()).format('YYYY.MM.DD') ,
      orderCount: '0' , code: `SU${idIndex.toString().padStart(3 , '0')}` , unit: '-' , productName: '-' ,
      productType: '-' , model: '-' , account: '-'
    }
    setFacilityDataState([...facilityDataState , rowInput])
  }
  const onChangeArray = (index: number , e: React.ChangeEvent<HTMLInputElement>) => {
    const {value , name} = e.target
    const rowsInput = [...facilityDataState]
    // @ts-ignore
    rowsInput[index][name] = value
    setFacilityDataState(rowsInput)
  }
  const listRef = React.useRef<any>(null)
  listRef.current?.addEventListener('scroll' , () => setScrollPosition(listRef.current?.scrollLeft))
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(iconSelect !== - 1){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload);
    };
  } , [iconSelect]);

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'기계 기본정보').then()
    }
  },[])

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={() => {setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}

      {dataModal.menu === 'confirm' &&
        <ConfirmPopup statusText={dataModal.message} clickEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'back' &&
        <SelectConfirmModal statusText={dataModal.message}
                            viewType={true}
                            noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                            clickEvent={() => {
                              setDataModal(initModal)
                              setModifyList(initInputValue)
                              setIconSelect(- 1)
                            }}
                            cancelEvent={() => {
                              setDataModal(initModal)
                            }}/>}
      {dataModal.menu === 'excel' &&
        <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
          <React.Fragment>
            <ExcelApiButton category={'facility'} type={'download'} companyData={selectedCompany}
                            disabled={!selectedCompany.companyCode}/>
            <ExcelApiButton category={'facility'} type={'format'} companyData={selectedCompany}
                            disabled={buttonAuthValid( 'create' , '6' )}
                            formatText={''}/>
            <ExcelApiButton category={'facility'} disabled={buttonAuthValid( 'create' , '6' )} type={'upload'}
                            companyData={selectedCompany}/>
          </React.Fragment>
        }/>
      }

      {dataModal.menu === 'log' &&
        <ModalBackCss style={{backgroundColor:'#1315234D', paddingTop:'170px'}}>
          <FacilityLog logData={logState} height={'549px'} authValid={authValid()}
                       buttonActive={true} closeModal={()=> {
                         setDataModal( initModal )
                         setLogState([])
                       }
          }/>
        </ModalBackCss>

      }

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 기계 기본정보'} unitCase={'MenuTitle'}/>
          <div style={{
            display: 'flex' , alignSelf: 'center' , position: 'relative' ,
            //드랍메뉴로 변경할때 패딩 추가하기
            //     paddingRight:'88px'
          }}>
            {/*드랍메뉴로 변경될때 다시 살리기*/}
            {/*<div style={{display: 'flex'}}>*/}
            {/*    {['리스트', '썸네일','업체별'].map((v, i) => (*/}
            {/*        <SelectEventButton buttonName={t(v)} buttonStatus={selectedView.index === i} buttonBorder={true}*/}
            {/*                           buttonEvent={() => setSelectedView({index: i, value: v})}/>*/}
            {/*    ))}*/}
            {/*</div>*/}
            {listFilter.map((v , i) => (
              <SelectEventButton buttonName={v} key={v}
                                 buttonStatus={filterState === v}
                                 buttonEvent={() => {
                                   setFilterState(v)
                                   setPageState(1)
                                   setPageBlock(0)
                                 }} buttonBorder={i !== 2}/> ))}
            {/*임시로 가림, 추후에 기획 수정되면 다시 살리기*/}
            {/*<DropMenu menuArray={listFilter} left={`calc('100% - 88px')`} persistWeight={700}*/}
            {/*        currentIndex={listFilter.findIndex((v)=> v === filterState)}*/}
            {/*          clickEvent={(v)=>{*/}
            {/*              setFilterState(v)*/}
            {/*              setPageState(1)*/}
            {/*              setPageBlock(0)*/}
            {/*          }}*/}
            {/*          style={{top:0, right:0, maxWidth:'88px', fontWeight: 700}} height={37} width={'88px'} />*/}
          </div>
        </div>

        {selectedView.index !== 2 ?
          <ContentFrame justify={'space-between'}>
            <div>
              <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                <div style={{display: 'grid' , gridTemplateColumns: '100px 1fr' , gap: '40px' , alignItems: 'center'}}>
                  <MenuTitle title={'기계 기본정보'} unitCase={'MenuCompTitle'}/>

                  <div style={{display: 'flex'}}>
                    {['리스트' , '썸네일'].map((v , i) => (
                      <SelectEventButton buttonName={t(v)} buttonStatus={selectedView.index === i} buttonBorder={true}
                                         buttonEvent={() => setSelectedView({index: i , value: v})} key={v}/>
                    ))}
                  </div>
                </div>
                <div style={{display: 'flex' , alignItems: 'center'}}>
                  <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
                  {/*<SearchBar keyFunction={(e) => setSearchInput(e.target.value.trimStart())}*/}
                  {/*           value={searchInput} clickEvent={searchEventFunc} keyPressDisable={true}*/}
                  {/*  // disabled={errorState.status !== 200}*/}
                  {/*           clearButtonAccess={searchInput !== ''} clearButton={() => {*/}
                  {/*  setSearchInput('')*/}
                  {/*  setPageState(1)*/}
                  {/*  setPageBlock(0)*/}
                  {/*  setPrevSearch('')*/}
                  {/*}}/>*/}
                  <FilterWithSearchBar keyFunction={(e) => setSearchInput(e.target.value.trimStart())} margin={'0'}
                                       value={searchInput} clickEvent={searchEventFunc} keyPressDisable={true}
                                       clearButtonAccess={searchInput !== ''} clearButton={() => {
                                          setSearchInput('')
                                          setPageState(1)
                                          setPageBlock(0)
                                          setPrevSearch('')
                                        }}
                                       selectKeyword={categorySelect.value}
                                       categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                       categoryTypeArray={['@','type','manufacturing_number','maker','facility_name','ict_number','network','hw']}
                                       categoryValueArray={['전체','기종','제조번호','제조사','기계명','ICT번호','네트워크','HW']}
                                       placeholder={t('검색')}/>
                  {!affiliateAuth() &&
                    <MainButtons buttonType={'cta'} name={'등록'} marginDis={true}
                                 disabled={buttonAuthValid('create' , '6')}
                                 clickEvent={() => {window.location.href = authValid() ? `/admin/fas/facility/register` : '/fas/facility/register'}}
                                 width={'80px'}/>
                  }
                </div>
              </div>
              <React.Fragment>
                {/*리스트*/}
                {selectedView.index === 0 &&
                  <GridTableComponent
                    tempCol={authValid() ? '56px 88px 178px 108px 168px 108px repeat(3, 168px)' : '56px 108px repeat(4, 198px) 254px'}
                    scrollY_Hide={true} scrollX_Hide={true}
                    scrollWidth={1210}>
                    <div className={'grid-title-unchecked'}>
                      {titleList.map((v) => (
                        <div key={v}>{t(v)}</div>
                      ))}
                    </div>
                    {facilityDataState.length === 0 ?
                      listResultMessage(true , errorState.notice , {width: 1210 , admin: 450 , user: 450})
                      :
                      facilityDataState.map((v: {
                        type: string,
                        manufacturingNumber: string,
                        facilityCode: string,
                        maker: string,
                        ictNumber: string,
                        companyName: string,
                        facilityName: string,
                        hw: string,
                        network: string,
                      } , i: number) => (
                        <React.Fragment>
                          {
                            // iconSelect !== i ?
                            <GridTableSelect selected={v.facilityCode === selectFacility}
                                             onMouseOut={() => setFocusList(- 1)} onMouseOver={() => setFocusList(i)}
                                             onClick={() => {
                                               setSelectFacility(v.facilityCode)
                                             }}
                                             onDoubleClick={() => window.location.href = authValid() ? `/admin/fas/facility/${v.facilityCode}` :
                                               `/fas/facility/${v.facilityCode}`}
                                             disabled={iconSelect !== - 1}
                                             className={'grid-list-unchecked'}>
                              <div>{( ( i + 1 ) + pageState * 9 ) - 9}</div>
                              {validationValue(v.type)}
                              {autoTextValueValidation({value:v.manufacturingNumber})}
                              {autoTextValueValidation({value:v.maker})}
                              {autoTextValueValidation({value:v.facilityName})}
                              {authValid() && validationValue(v.ictNumber , '할당 안됨')}
                              {authValid() && validationValue(v.companyName)}
                              {autoTextValueValidation({value:v.network})}
                              {autoTextValueValidation({value:v.hw})}
                              {/*<ListInnerInput type={'noneInputText'} inputData={'변경 이력'} clickButton={()=>setDataModal({menu:'log'})}/>*/}
                              {/*<div className={'pencil-icon'}>*/}
                              {/*  {i === focusList &&*/}
                              {/*    <img src={hoverIcon}*/}
                              {/*         width={( buttonAuthValid('update' , '6') || affiliateAuth() ) ? 0 : 24} alt=""*/}
                              {/*         onClick={(e) => {*/}
                              {/*           e.stopPropagation();*/}
                              {/*           //@ts-ignore*/}
                              {/*           setModifyList(v)*/}
                              {/*           setIconSelect(i)*/}
                              {/*           setSelectFacility(v.facilityCode)*/}
                              {/*         }}*/}
                              {/*         onMouseOut={() => setHoverIcon(modify)}*/}
                              {/*         onMouseOver={() => setHoverIcon(modifyHover)}/>*/}
                              {/*  }*/}
                              {/*</div>*/}
                            </GridTableSelect>
                            // :
                            // <GridTableSelect selected={v.facilityCode === selectFacility}
                            //                  onClick={() => {setSelectFacility(v.facilityCode)}}
                            //                  className={'grid-list-unchecked'}>
                            //   <div>{( ( i + 1 ) + pageState * 9 ) - 9}</div>
                            //   {validationValue(v.type)}
                            //   <ListInnerInput type={'text'} name={'manufacturingNumber'}
                            //                   inputData={modifyList?.manufacturingNumber}
                            //                   placeholder={'제조번호'} onChange={onChangeValue}/>
                            //   <ListInnerInput type={'text'} name={'maker'} inputData={modifyList?.maker}
                            //                   placeholder={'제조사'} onChange={onChangeValue}/>
                            //   <ListInnerInput type={'text'} name={'facilityName'} inputData={modifyList?.facilityName}
                            //                   placeholder={'기계명'} onChange={onChangeValue}/>
                            //   {authValid() && validationValue(v.ictNumber , '할당 안됨')}
                            //   {authValid() && validationValue(v.companyName)}
                            //   <ListInnerInput type={'text'} name={'network'} inputData={modifyList?.network}
                            //                   placeholder={'네트워크'} onChange={onChangeValue}/>
                            //   <ListInnerInput type={'text'} name={'hw'} inputData={modifyList?.hw}
                            //                   placeholder={'하드웨어'} onChange={onChangeValue}/>
                            //   {/*<ListInnerInput type={'noneInputText'} inputData={'변경 이력'} clickButton={()=>setDataModal({menu:'log'})}/>*/}
                            //   <div className={'select-icon'} onClick={(e) => e.stopPropagation()}>
                            //
                            //     <img src={cancelIcon} width={24} alt=""
                            //          onClick={() => {setDataModal({menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                            //     <img src={checkIcon} width={24}
                            //          onClick={facilitySimpleModify} alt=""/>
                            //   </div>
                            // </GridTableSelect>
                          }
                        </React.Fragment>
                      ))
                    }
                  </GridTableComponent>}

                {/*썸네일*/}
                {selectedView.index === 1 &&
                  <div
                    style={{marginTop: '32px' , display: 'grid' , gridTemplateColumns: 'repeat(3, 384px)' , gap: '16px 28px'}}>
                    {facilityDataState?.length !== 0 ?
                      facilityDataState?.map((value: FacilityType) => (
                        <div key={value.facilityCode}>
                          <FacilityContentBox selectedBox={selectFacility === value.facilityCode}
                                              profileImageUrl={value.profileImageUrl}
                                              objectType={authValid() ?
                                                [
                                                  {title: '기종' , data: value.type} ,
                                                  {title: '제조번호' , data: tooltipModule(dataValid(value.manufacturingNumber , '없음') , 12)} ,
                                                  {title: '제조사' , data: dataValid(value.maker , '없음')} ,
                                                  {title: 'ICT번호' , data: dataValid(value.ictNumber , '할당 안됨')} ,
                                                  {title: '업체명' , data: dataValid(value.companyName)}
                                                ]
                                                :
                                                [
                                                  {title: '기종' , data: value.type} ,
                                                  {title: '제조번호' , data: tooltipModule(dataValid(value.manufacturingNumber , '없음') , 12)} ,
                                                  {title: '제조사' , data: dataValid(value.maker , '없음')} ,
                                                  {title: '기계명' , data: dataValid(value.facilityName)} ,
                                                ]
                                              }
                                              clickEvent={() => setSelectFacility(value.facilityCode)}
                                              doubleClickEvent={() => window.location.href = authValid() ? `/admin/fas/facility/${value.facilityCode}` : `/fas/facility/${value.facilityCode}`}
                                              imageClickEvent={() => setPrevImage(value.facilityCode)}/>
                          {prevImage === value.facilityCode && value.imageUrl.length !== 0 &&
                            <ImageDetailPreview
                              profile={value.profileImageUrl}
                              imageUrl={value.imageUrl}
                              clickEvent={() => setPrevImage('')}/>}
                        </div>
                      )) :
                      <React.Fragment>
                        <div/>
                        <NoData height={450}>{errorState.notice}</NoData>
                      </React.Fragment>
                    }
                  </div>
                }
              </React.Fragment>
            </div>
            {facilityDataState.length !== 0 &&
              <PaginationList limit={9} setBlockNum={setPageBlock} blockNum={pageBlock} pageLimit={10}
                              setPage={setPageState} counts={totalPage} page={pageState} disabled={iconSelect !== - 1}/>
            }
          </ContentFrame>
          :
          //업체별 (현재 사용안함)
          <div>
            <CompanyInfoComponent companyData={(v) => ( setSelectFacility(v.companyCode) )}/>

            <ContentFrame height={'456px'} margin={'16px 0 0 0'}>
              <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                <MenuTitle title={'기계 정보'} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <SearchBar keyFunction={(e) => setSearchInput(e.target.value.trimStart())} keyPressDisable={true}
                             value={searchInput} clickEvent={searchEventFunc}
                             clearButtonAccess={searchInput !== ''} clearButton={facilityListRes}/>
                  <MainButtons name={'바코드 미리보기'} width={'112px'}/>
                  <MainButtons name={'Excel로 받기'}/>
                  <MainButtons name={'Excel 양식'} width={'80px'}/>
                  <MainButtons name={'Excel 등록'} width={'80px'}/>
                  <MainButtons name={'행추가'} width={'80px'} clickEvent={addListRow}/>
                  <MainButtons name={'저장'} buttonType={'cta'} width={'80px'}/>
                  <MainButtons name={'삭제'} width={'80px'} marginDis={true}
                               clickEvent={() => removeCheckedFunction(setFacilityDataState , facilityDataState , setCheckedList , checkedList , 'facilityCode')}/>
                </div>
              </div>
              <GridTable tempCol={'40px 40px 80px repeat(6, 120px) repeat(4, 96px) 1fr'} ref={listRef}
                         scrollActiveY={'hidden'} overflow={'visible'}>
                <div className={'table_title'} style={{padding: 0 , gap: '8px' , width: '1463px'}}>
                  <div
                    style={{alignItems: 'flex-start' , display: 'flex' , flexDirection: 'column' , justifyContent: 'center'}}>
                    <div
                      style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center' , position: 'fixed' , backgroundColor: '#F5F6FA' , width: '40px' , alignItems: 'center'}}>
                      <input type="checkbox" checked={checkedList.length === facilityDataState.length}
                             onChange={(e) => allCheckedFunction(e.target.checked ,
                                                                 facilityDataState , 'facilityCode' , setCheckedList)}/>
                    </div>
                  </div>
                  {['No.' , '기종' , '제조번호' , '제조사' , '기계명' , 'ICT번호' , 'HW' , '버전' , '최대톤수' , '이미지' , '생산품목' , '일상점검'].map((v) => (
                    <div>{t(v)}</div>
                  ))}
                </div>

                <div style={{width: '1463px' , height: '292px' , overflowY: 'scroll'}}>
                  <div style={{width: '1463px'}}>
                    {facilityDataState.map((v: any , i: number) => (
                      <div key={v.facilityCode}>
                        {iconSelect !== i ?
                          //@ts-ignore
                          <SelectGridList tempCol={'40px 80px repeat(6, 120px) repeat(4, 96px) 1fr'}
                                          width={`${1210 + scrollPosition}px`}
                                          style={{gap: '8px' , paddingLeft: '48px'}} fontWeight={400}
                                          onMouseOut={() => setFocusList(- 1)}
                                          onMouseOver={() => setFocusList(i)}>
                            {/*@ts-ignore*/}
                            <div className={'checkbox'} style={{left: scrollPosition - 1}}>
                              <ListInnerInput type={'check'} name={'facilityCode'}
                                              onChange={(e: any) => singleCheckedFunction(e.target.checked , v.facilityCode , setCheckedList , checkedList)}
                                              checked={checkedList.includes(v.facilityCode)}/>
                            </div>
                            <div>{( facilityDataState.length - i )}</div>
                            <div>{v.type}</div>
                            <div>{v.manufacturingNumber}</div>
                            <div>{v.maker}</div>
                            <div>{v.facilityName}</div>
                            <div>{v.ictNumber}</div>
                            <div>{v.hw}</div>
                            <div>{v.version}</div>
                            <div>{v.maxTon}</div>
                            <MainButtons name={'이미지 등록'} buttonType={'cta'}/>
                            <div
                              style={{color: '#7E84A3' , fontSize: '12px' , textDecoration: 'underline'}}>{'생산품목 보기'}</div>
                            <MainButtons name={'일상점검 등록'} buttonType={'cta'}/>
                            {i === focusList &&
                              // 하단 스크롤 이동해도 버튼 위치 고정되도록
                              <div style={{
                                display: 'flex' , justifyContent: 'center' , flexDirection: 'column' , height: '48px' ,
                                position: 'absolute' , right: '16px' , boxSizing: 'border-box'
                              }}>
                                <img src={hoverIcon} width={24} alt=""
                                     style={{backgroundColor: '#fff' , pointerEvents: 'auto'}}
                                     onClick={() => setIconSelect(i)}
                                     onMouseOut={() => setHoverIcon(modify)}
                                     onMouseOver={() => setHoverIcon(modifyHover)}/>
                              </div>}
                          </SelectGridList>
                          :
                          <SelectGridList tempCol={'40px 80px repeat(6, 120px) repeat(4, 96px) 1fr'}
                                          style={{gap: '8px' , paddingLeft: '48px'}} fontWeight={400}
                                          width={`${1210 + scrollPosition}px`} key={v.facilityCode}
                                          onMouseOut={() => setFocusList(- 1)}
                                          onMouseOver={() => setFocusList(i)}>
                            <div className={'checkbox'} style={{left: scrollPosition - 1}}>
                              <ListInnerInput type={'check'} name={'facilityCode'}
                                              onChange={(e: any) => singleCheckedFunction(e.target.checked , v.facilityCode , setCheckedList , checkedList)}
                                              checked={checkedList.includes(v.facilityCode)}/>
                            </div>
                            <div>{( facilityDataState.length - i )}</div>
                            <ListInnerInput type={'drop'} inputData={v.type} dropMenuList={['프레스' , '로봇' , 'CNC']}/>
                            <ListInnerInput type={'text'} name={'manufacturingNumber'} inputData={v.manufacturingNumber}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'text'} name={'maker'} inputData={v.maker}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'text'} name={'facilityName'} inputData={v.facilityName}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'text'} name={'ictNumber'} inputData={v.ictNumber}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'text'} name={'hw'} inputData={v.hw}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'text'} name={'version'} inputData={v.version}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <ListInnerInput type={'unit'} name={'maxTon'} inputData={v.maxTon} unit={'ton'}
                                            onChange={(e) => onChangeArray(i , e)}/>
                            <MainButtons name={'이미지 등록'} buttonType={'cta'}/>
                            <div
                              style={{color: '#7E84A3' , fontSize: '12px' , textDecoration: 'underline'}}>{'생산품목 보기'}</div>
                            <MainButtons name={'일상점검 등록'} buttonType={'cta'}/>
                            <div style={{
                              display: 'grid' , gridTemplateColumns: 'repeat(2,24px)' , gap: '10px' , position: 'absolute' ,
                              height: '48px' , right: '16px' , boxSizing: 'border-box' , alignItems: 'center'
                            }}>
                              <img src={cancelIcon} width={24} style={{backgroundColor: '#fff'}} onClick={() => {
                                setIconSelect(- 1);
                                setHoverIcon(modify)
                              }} alt=""/>
                              <img src={checkIcon} width={24} style={{backgroundColor: '#fff'}} onClick={() => {
                                setIconSelect(- 1);
                              }} alt=""/>
                            </div>
                          </SelectGridList>
                        }
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{position: 'relative' , width: '1463px' , height: '100%'}}>
                  <div className={'scroll_var'}
                       style={{overflowY: 'scroll' , height: '292px' , width: '1463px' , position: 'absolute' , left: 0 , backgroundColor: 'transparent'}}>
                    {facilityDataState.map((v: any , i: number) => (
                      <SelectGridList tempCol={'40px 40px 80px repeat(6, 120px) repeat(4, 96px) 1fr'}
                                      key={v.facilityCode}
                                      style={{padding: 0 , gap: '8px'}}>
                        <div>{''}</div>
                        <div>{( facilityDataState.length - i )}</div>
                        <div>{v.type}</div>
                        <div>{v.manufacturingNumber}</div>
                        <div>{v.maker}</div>
                        <div>{v.facilityName}</div>
                        <div>{v.ictNumber}</div>
                        <div>{v.hw}</div>
                        <div>{v.version}</div>
                        <div>{v.maxTon}</div>
                        <MainButtons name={'이미지 등록'} buttonType={'cta'}/>
                        <div
                          style={{color: '#7E84A3' , fontSize: '12px' , textDecoration: 'underline'}}>{'생산품목 보기'}</div>
                        <MainButtons name={'일상점검 등록'} buttonType={'cta'}/>
                      </SelectGridList>
                    ))}
                  </div>

                  <div className={'scroll_var'}
                       style={{height: '292px' , width: '1210px' , position: 'sticky' , left: 0 , top: 0 , pointerEvents: 'none' , backgroundColor: 'transparent'}}>
                    {facilityDataState.map((v: any) => (
                      <div
                        style={{width: '1210px' , display: 'flex' , justifyContent: 'space-between' , height: '48px'}}>
                        <div
                          style={{display: 'flex' , justifyContent: 'center' , width: '40px' , boxSizing: 'border-box'}}>
                          <ListInnerInput type={'check'} name={'facilityCode'}
                                          onChange={(e: any) => singleCheckedFunction(e.target.checked , v.facilityCode , setCheckedList , checkedList)}
                                          checked={checkedList.includes(v.facilityCode)}/>
                        </div>
                        <div style={{
                          display: 'flex' , alignItems: 'flex-end' , justifyContent: 'center' , flexDirection: 'column' ,
                          paddingRight: '8px' , boxSizing: 'border-box' , zIndex: 49 , pointerEvents: 'none'
                        }}>
                          <div
                            style={{display: 'flex' , gap: '16px' , pointerEvents: 'auto' , position: 'sticky' , right: '8px'}}>
                            <img src={cancelIcon} width={24} style={{backgroundColor: '#fff'}} onClick={() => {
                              setIconSelect(- 1);
                              setHoverIcon(modify)
                            }} alt=""/>
                            <img src={checkIcon} width={24} style={{backgroundColor: '#fff'}} onClick={() => {
                              setIconSelect(- 1);
                            }} alt=""/>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </GridTable>
            </ContentFrame>
          </div>
        }

      </SectionFrame>

      {/* <FacilityRegister clickChangePage={()=> {*/}
      {/*    if(window.localStorage.getItem('regUrl') === '/admin/pbas/machine'){*/}
      {/*        setTimeout(()=>{*/}
      {/*            setRegisterPageState(false)*/}
      {/*        },500)*/}
      {/*        window.history.back()*/}
      {/*    }else{*/}
      {/*        setRegisterPageState(false)*/}
      {/*    }*/}
      {/*}}/>*/}
    </React.Fragment>
  );
};
export default FacilityList
