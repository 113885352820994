import React , {useRef , useState} from 'react';
import styled from 'styled-components';
import search from "../../asset/image/ic-search.svg";
import searchB from "../../asset/image/ic-search-b.svg";
import searchW from "../../asset/image/ic-search-w.svg";
import arrow from "../../asset/image/chevron-left.svg";
import {tooltipModule} from "../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import {dataValid} from "../../function/function";
import MainButtons from "../button/MainButtons";
import {AutoTextSize} from "auto-text-size";

type ButtonProps = {
  type: string;
  onChange?: ( e: React.ChangeEvent<HTMLInputElement> ) => void;
  onKeyDownEvent?: React.KeyboardEventHandler<HTMLDivElement>
  name?: string;
  width?: any;
  height?: string
  inputData?: string;
  placeholder?: string;
  disabled?: boolean;
  tabIndex?: number;
  clickButton?: () => void;
  clickInput?: () => void;
  dropMenuList?: any[]
  dropTopDirection?: boolean
  selectDrop?:(value:any)=>void
  border?: string
  borderRadius?:string
  dropScrollHas?:boolean
  dropScrollEvent?: ()=>void
  onBlur?:any
  unit?:string
  focusCancel?:boolean;
  limit?:number;
  tempState?:any;
  buttonText?:string;
  inputDisabled?:boolean;
  buttonWidth?:string;
  readonly?: boolean
};


const TESTInnerInput: React.FC<ButtonProps> = ( {
                                                  type ,
                                                  width ,
                                                  height ,
                                                  onKeyDownEvent ,
                                                  name ,
                                                  inputData ,
                                                  placeholder ,
                                                  tabIndex ,
                                                  disabled ,
                                                  onChange ,
                                                  clickButton , clickInput,
                                                  dropTopDirection ,
                                                  dropMenuList ,
                                                  selectDrop ,
                                                  border ,
                                                  borderRadius ,
                                                  dropScrollHas ,
                                                  dropScrollEvent ,
                                                  onBlur , readonly,
                                                  buttonWidth ,
                                                  unit ,
                                                  focusCancel,
                                                  buttonText ,
                                                  inputDisabled,
                                                  limit,tempState,
                                                } ) => {


  const [dropOpen,setDropOpen] = useState<boolean>(false)
  const [dropSelect, setDropSelect] = useState<any>(inputData)
  const dropMenuRef = useRef<any>( null )
  const {t}:any =useTranslation()

  const inputDefaultStyle = (inputWidth:string) => ({
    width: `calc(${inputWidth??'100%'} - 8px)`, // width 값을 동적으로 설정
    height: 'calc(100% - 4px)',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    fontFamily: 'NanumGothicOTF, sans-serif',
  });

  const inputCase = (type: string) => {
    switch ( type ) {
      case 'text' :
        return (
          <React.Fragment>
            {tooltipModule( inputData , 5 ,
              <InputBase style={{borderRadius: borderRadius , border: border , height: height , width: width}}>
                <input type={'text'} style={inputDefaultStyle(width)} name={name} onChange={onChange} autoComplete={'off'}
                       maxLength={limit ?? 30} onClick={clickButton}
                       placeholder={t( placeholder )} value={inputData} disabled={disabled}/>
              </InputBase>
            )}
          </React.Fragment>
        )
      case 'search' :
        return (
          <React.Fragment>
            <InputBase style={{border: border ?? ''}}>
              {readonly?
                <div style={{width: width}}>
                  <AutoTextSize minFontSizePx={10} maxFontSizePx={14}>
                    {dataValid(inputData)}
                  </AutoTextSize>
                </div>
                :
                <input type={'text'} name={name} value={inputData} autoComplete={'off'} readOnly={readonly}
                       tabIndex={tabIndex} disabled={disabled} onClick={clickInput}
                       placeholder={t( placeholder )} style={inputDefaultStyle(width)} onChange={onChange}/>
              }

              {tempState?
                <div style={{width: readonly?'32px' : '40px', height:'32px',backgroundColor: '#0058ff',alignItems:'center',borderRadius:'4px',
                  justifyContent:'center',display:'flex',
                  position:'relative'}}>
                  <img src={searchW} onClick={clickButton} alt={'searchW'}/>
                </div>
                :
                !inputData?
                  <img src={search} onClick={clickButton} alt={'search'}/>
                  :
                  <img src={searchB} onClick={clickButton} alt={'searchB'}/>
              }

            </InputBase>
            {/*{tooltipModule( inputData , 0 ,*/}
            {/*  <InputBase>*/}
            {/*    <input type={'text'} name={name} value={inputData} autoComplete={'off'} readOnly={false}*/}
            {/*           tabIndex={tabIndex} disabled={disabled} onMouseEnter={()=>console.log('클릭')}*/}
            {/*           placeholder={t( placeholder )} style={inputDefaultStyle(width)} onChange={onChange}/>*/}

            {/*    {tempState?*/}
            {/*      <div style={{width:'40px',height:'32px',backgroundColor: '#0058ff',alignItems:'center',borderRadius:'4px',*/}
            {/*        justifyContent:'center',display:'flex',*/}
            {/*        position:'relative'}}>*/}
            {/*        <img src={searchW} onClick={clickButton} alt={'searchW'}/>*/}
            {/*      </div>*/}
            {/*      :*/}
            {/*      !inputData?*/}
            {/*        <img src={search} onClick={clickButton} alt={'search'}/>*/}
            {/*        :*/}
            {/*        <img src={searchB} onClick={clickButton} alt={'searchB'}/>}*/}

            {/*  </InputBase>*/}
            {/*)}*/}
          </React.Fragment>
        )
      case 'unit' :
        return (
          <InputBase style={{
            paddingRight: '8px' ,
            cursor: 'pointer' ,
            pointerEvents: disabled ? 'none' : 'auto' ,
            opacity: disabled ? 0.5 : 1
          }} onMouseDown={clickButton}>
            <input type={'text'} name={name} value={inputData} onKeyDown={onKeyDownEvent}
                   onBlur={onBlur} placeholder={placeholder} style={inputDefaultStyle(width)}
                   onFocus={e => {!focusCancel && e.target.select()}}
                   onChange={onChange} autoComplete={'off'}/>
            <div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
          </InputBase>
        )

      case 'drop' : return (
        <div style={{display:'flex', flexDirection:'column', position:'relative',opacity:disabled ? 0.3: 1,pointerEvents:disabled?'none':'auto'}} ref={dropMenuRef}>
          <InputBase inputData={inputData}
                     onClick={()=> {setDropOpen( !dropOpen ); if(clickButton){clickButton()}}} style={{borderRadius:borderRadius,border:border}}>
            <input type={'text'} tabIndex={tabIndex} name={name} value={dropSelect} autoComplete={'off'} style={{caretColor:'transparent',cursor:'pointer',...inputDefaultStyle(width),
              fontSize:'12px'}}
                   placeholder={'선택'} readOnly={true}/>
            <img src={arrow} alt="" style={{transform:`rotateZ(270deg) rotateY(${dropOpen? 180 : 0}deg)`,transition:'all 0.35s',backgroundColor:'transparent'}}/>
          </InputBase>
          <DropBox dropTop={dropTopDirection}
                   onScroll={()=>{if(dropScrollHas){if( dropScrollEvent ){dropScrollEvent()}}}}>
            {dropMenuList?.map((v,i)=>(
              <DropBase style={{color: '#171725', fontWeight:400, borderRadius:borderRadius, fontSize: '14px'}} delay={i*0.02} border={true}
                        onClick={()=> {
                          setDropSelect(v)
                          setDropOpen(false)
                          if(selectDrop){
                            selectDrop(v === '선택 안함'? '' : v)
                          }}} open={dropOpen}>{v}
              </DropBase>
            ))}
          </DropBox>
        </div>
      )

      case 'withButton' : return (
        <InputBase>
          {tooltipModule(inputData,dataValid(limit,0),
            <input type={'text'} name={name} value={inputData} disabled={inputDisabled} placeholder={t(placeholder)}
                   style={{...inputDefaultStyle(width), opacity: inputDisabled? 0.7 : 1}} autoComplete={'off'} onChange={onChange}/>
          )}
          <div style={{position:'relative', right:'-1px'}}>
            <MainButtons name={buttonText??'확인'} buttonType={'cta'} margin={'0'} marginDis={true}
                         clickEvent={clickButton} width={buttonWidth ?? '36px'} disabled={disabled}/>
          </div>
        </InputBase>
      )

      case 'clickText': return (
        <div style={{ border: '1px solid #D7DBEC', backgroundColor: '#fff', borderRadius: '4px',
          borderTop: 'none',
          height:'32px',width:'130px',display:'flex',alignItems:'center',justifyContent:'center',boxSizing:'border-box'}}>
          <div style={{display:'flex', justifyContent:'space-between', boxSizing:'border-box'}}>
            <div style={{textDecoration:'underline', textUnderlineOffset:'3px',textOverflow:'ellipsis',cursor:'pointer',
              pointerEvents: disabled?'none':'inherit',opacity:disabled ? 0.5:1,
              overflow:'hidden', color:'#7E84A3',fontSize:'12px',fontWeight:'bold'}}
                 onClick={clickButton}>{inputData}</div>
          </div>
        </div>
      )
    }
  }


  return (
    <div onClick={(e)=> `${type.includes('none')? '' : e.stopPropagation()}`}
         style={{width:`${width}px`, alignSelf:'center', alignItems:'center',
           alignContent:'center',overflow:'visible'}}>
      {inputCase(type)}
    </div>
  );
};

export default TESTInnerInput;

const InputBase = styled.div<{inputData?:any}>`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d5d7e3;
  align-items: center;
  background-color: #fff;

  &:hover {
    border: 1px solid #0058ff;
  }

  &:focus-within {
    border: 1px solid #0058ff;
  }
`;
const DropBox = styled.div<{ dropTop?: boolean, dropHeight?: number, selected?: boolean }>`
  position: absolute;
  top: ${props => props.dropTop ? 'auto' : '32px'};
  bottom: ${props => props.dropTop ? '32px' : 'auto'};
  width: 100%;
  z-index: 49;
  max-height: ${props => props.dropHeight ?? 128}px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 14px;
  }
`
const DropBase = styled.div<{
  open: boolean,
  delay?: number,
  backColor?: string,
  border?: boolean,
  selected?: boolean
}>`
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  height: ${props => props.open ? 32 : 0}px;
  box-sizing: border-box;
  border-radius: 4px;
  border: ${props => props.open ? 1 : 0}px solid #D7DBEC;
  align-items: center;
  background-color: #fff;
  overflow: hidden;
  transition: height 0.05s ${props => props.delay}s, border 0.05s;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.backColor ? '#d7dbec' : '#fff'};
    border: ${props => props.border ? '1px solid #0058ff' : 'inherit'};
  }

  &:focus-within {
    border: 1px solid #0058FF;
    }
`;