import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import {SectionFrame , ContentFrame , ModalBackCss , multipleImageGridStyle} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import RegisterInput from "../../../../../components/input/RegisterInput";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";

import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {useLocation} from "react-router-dom";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import FacilityLog from "./FacilityLog";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {useTranslation} from "react-i18next";
import {FacilityDataType , Modal_View_Type , SearchCompanyType} from "../../../../../types/Fundamental";
import FactoryDropMenu from "../../../../../components/sideMenu/FactoryDropMenu";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {
	locationFilter ,
	initModal ,
	dataAccessLoading ,
	loadingRemove , dataValid ,
	listLoadingCircle , numberLocaleString , buttonAuthValid , authValid
} from "../../../../../function/function";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import FacilityDailyInspectionReg from "./FacilityDailyInspectionReg";
import dayjs from "dayjs";
import PeripheralRegisterModal from "../../../../../components/modal/PeripheralRegisterModal";
import ProductionItemModal from "../../../../../components/modal/ProductionItemModal";
import {persistBlockPage , persistPage} from "../../../../../common/StateManage";
import {useRecoilState} from "recoil";
import ToastPopup from "../../../../../components/modal/ToastPopup";
// import ProductionItemModal from "../../../../../components/modal/ProductionItemModal";

const FacilityModify = () => {

	const location = useLocation()
	// const companyData = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)

	const {pathname} = location
	const facilityCode = pathname.split('/').pop()

	const [factoryList, setFactoryList] = useState<SearchCompanyType[]>([])

	const [facilityData, setFacilityData] = useState<{current: FacilityDataType|any, log:any}>({current:{}, log:[]})
	const [inputState, setInputState] = useState<FacilityDataType | any>({})

	const [, setPage]= useRecoilState(persistPage)
	const [, setBlockPage] = useRecoilState(persistBlockPage)

	const connentCompany = [
		{company:'제스텍', device:'ZESTECH'},
		{company:'심팩', device:'SIMPAC'},
		{company:'마이다', device:'MIDA'},
		{company:'화일', device:'HIM'},
		{company:'국일', device:'KUKIL'},
		{company:'JNH', device:'JNH'},
		{company:'기타', device:'기타'},
	]

	const facilityDetailResponse = async () => {
		try {
			listLoadingCircle()
			const response = await client.post('/facility/detail',{
				facilityCode: facilityCode
			},{headers: {Authorization}})
			setInputState( {...response.data.data.current, codeDoubleCheck: true})
			setFacilityData(response.data.data)
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	React.useEffect(()=>{
		facilityDetailResponse().then()
	},[facilityCode])

	//@ts-ignore
	const [imageKeyState, setImageKeyState] = useState<any[]>([])
	//@ts-ignore
	// const [profileKey, setProfileKey] = useState<string>(facilityData?.current.profileImage)
	const [alertInput, setAlertInput] = useState<string>('')
	const [selectInspect, ] = useState<boolean>(false)
	const [factorySegment, setFactorySegment] = useState<string[]>(['미등록'])
	const [dataModal,setDataModal]= useState<Modal_View_Type>(initModal)
	const { t }:any = useTranslation();

	const facilityUpdateResponse = async () => {

		if(!inputState.facilityName){
			setDataModal({menu:'confirm', message:'기계명을 입력해 주세요.'})
			setAlertInput('기계명을 입력해 주세요.')
		}
		else if(!inputState.maker){
			setDataModal({menu:'confirm', message:'제조사를 입력해 주세요.'})
			setAlertInput('제조사를 입력해 주세요.')
		}
		// else if(inputState.ictNumber && inputState.ictNumber.length < 5){
		// 	setDataModal({menu:'confirm', message:'ICT번호 5자리를 입력해 주세요.'})
		// 	setAlertInput('ICT번호 5자리를 입력해 주세요.')
		// }
		else if(!inputState.maxTon && inputState.type === '프레스' || inputState.maxTon === '0' && inputState.type === '프레스'){
			setDataModal({menu:'confirm', message:'프레스의 최대 톤수를 입력해 주세요.'})
			setAlertInput('프레스의 최대 톤수를 입력해 주세요.')
		}
		else if(!inputState.codeDoubleCheck){
			setDataModal({menu:'confirm', message:'기계 제조번호를 확인해 주세요.'})
			setAlertInput('기계 제조번호를 확인해 주세요.')
		}
		else{
			try {
				setDataModal(initModal)
				dataAccessLoading()
				const response = await client.post('/facility/update',{
					...inputState,
					factoryCode: dataValid(inputState.factoryCode, 'noCodeData'),
					companyCode: dataValid(inputState?.companyCode, 'noCodeData'),
					image: dataValid(imageKeyState, []),
					profileImage: dataValid(inputState?.profileImage, ''),
					ictNumber: inputState?.ictNumber === 'C'? '00000' : dataValid(inputState?.ictNumber, '00000')
				},{headers:{Authorization}})
				const data = response.data

				if(data.status !== 200){
					setDataModal({menu:'confirm',message:t(data.message)})
					setAlertInput(data.message)
					loadingRemove()
				}else{
					setTimeout(()=>{
						setDataModal({menu:'success',message:t('기계 정보가 수정 되었습니다.')})
						loadingRemove()
					},500)
				}
			}catch (e:any){
				console.log(e)
				setDataModal({menu:'error',message:e.message})
				loadingRemove()
			}
		}
	}


	const facilityDeleteResponse = async () => {
		try {
			setDataModal(initModal)
			dataAccessLoading()
			const response = await client.post('/facility/delete',{
				facilityCode: facilityCode
			},{headers: {Authorization}})
			const data = response.data
			if(data.status !== 200){
				setDataModal({menu:'confirm',message:t(data.message)})
				loadingRemove()
			}else{
				setTimeout(()=>{
					setDataModal({menu:'success',message:t('기계 정보가 삭제 되었습니다.')})
					loadingRemove()
				},500)
			}
		}catch (e) {
			console.log(e)
		}
	}
	//현재는 10개 까지만 나오 도록 되어 있음..
	const factoryListRes = async  (companyCode?: string) => {
		try {
			const response =
				authValid() ?
					await client.post( `/factory/admin/search`,{page:1, size:10, keyword:companyCode},
														 {headers:{Authorization}})
					:
				await client.get( `/factory/list/1/15/${companyCode}`, {headers:{Authorization}})

			const data = response.data.data
			setFactoryList(data.row)
			const segment = await client.post('/factory/subdivision/list',{
				factoryCode: inputState.factoryCode
			},{headers:{Authorization}})
			const segmentData:any[] = segment.data.data.subdivision
			if(!segmentData[0]){
				setFactorySegment(['미등록'])
			}else{
				setFactorySegment(segmentData)
			}
		}catch (e) {
			console.log(e)
		}
	}

	const factoryDivision = async (factoryCode: string) => {
		try {
			const response = await client.post('/factory/subdivision/list',{
				factoryCode: factoryCode
			},{headers:{Authorization}})
			const segmentData = response.data.data.subdivision
			if(!segmentData[0]){
				setFactorySegment(['미등록'])
			}else{
				setFactorySegment(segmentData)
			}

		}catch (e) {
			console.log(e)
		}
	}


	const onChangeValue = (e:any, valid?:{number?:boolean, trim?: boolean}) => {
		const {value, name} = e.target
		setInputState({
										...inputState,
										[name]: valid?.number ? value.replace( /[^0-9]/g, '') : valid?.trim? value.trim() : value
									});
	}

	const onChangeICT = (e:any) => {
		const {value, name} = e.target
		const prefixMap: any = {
			'CNC': 'C',
			'비전기': 'V'
		};

		const prefix = prefixMap[inputState.type] || '';
		setInputState({
										...inputState,
										[name]:  value.replace( /[^0-9]/g, '').trim() === ''? prefix : prefix + value.replace( /[^0-9]/g, '').trim()
									});
	}

	const closeModify = () => {
		if(dataModal.message === t('기계 정보가 삭제 되었습니다.')){
			window.location.href  = authValid() ? '/admin/fas/facility' : '/fas/facility'
			setPage(1)
			setBlockPage(0)
		}else{
			setDataModal(initModal)
		}
		if(dataModal.message === t('기계 정보가 수정 되었습니다.')){
			window.location.href  = authValid() ? `/admin/fas/facility/${facilityData?.current.facilityCode}` : `/fas/facility/${facilityData?.current.facilityCode}`
		}else{
			setDataModal(initModal)
		}
	}

	React.useEffect(()=>{
		if(inputState?.companyCode){
			factoryListRes(inputState?.companyCode).then()
		}
	},[inputState?.companyCode])

	React.useEffect(()=>{
		setInputState((prev:any)=> prev)
	},[factorySegment])


	const alertPoint = (key: string) => {
		if(key === 'companyCode'){
			return alertInput === '업체를 선택해 주세요.';
		}
		if(key === 'facilityName'){
			return alertInput === '기계명을 입력해 주세요.';
		}
		// if(key === 'ictNumber'){
		// 	if(alertInput === 'ICT번호 5자리를 입력해 주세요.'){
		// 		return true;
		// 	}
		// 	else return alertInput === '이미 사용중인 ICT 번호 입니다.';
		// }
		if(key === 'maker'){
			return alertInput === '제조사를 입력해 주세요.';
		}
		if(key === 'maxTon'){
			return alertInput === '프레스의 최대 톤수를 입력해 주세요.';
		}
		if(key === 'manufacturingNumber'){
			return alertInput === '기계 제조번호를 확인해 주세요.';
		}
	}

	const generateIctNumber = async () => {
		try {
			const response = await client.post('/facility/ictNumber',{
				location: inputState.location
			},{headers:{Authorization}})
			const data = response.data.data
			setInputState((prev:any)=>({...prev, ictNumber: data.ictNumber}))
		}catch ( e ) {
			console.log(e)
			setDataModal({menu:'confirm', message:'ICT번호를 생성할 수 없습니다. \n 관리자에게 문의해 주세요.'})
		}
	}

	const serialDoubleCheck = async () => {
		try {
			const response = await client.post('/facility/serial/check',{
				manufacturingNumber: inputState.manufacturingNumber
			},{headers:{Authorization}})

			if(response.data.status === 200){
				setInputState((prev:any)=>({...prev, codeDoubleCheck: true}))
				setDataModal({menu:'confirm', message: response.data.message})
			}else{
				setDataModal({menu:'confirm', message: response.data.message})
			}
		}catch ( e ) {
			console.log(e)
			setDataModal({menu:'confirm', message:' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
		}
	}


	// React.useEffect(() => {
	// 		const handleBeforeUnload = (event:BeforeUnloadEvent) => {
	// 			if ( dataModal.menu === '' ) {
	// 				event.preventDefault();
	// 				event.returnValue = '';
	// 			}
	// 		};
	// 		window.addEventListener('beforeunload', handleBeforeUnload);
	//
	// 		return () => {
	// 			window.removeEventListener('beforeunload', handleBeforeUnload);
	// 		};
	// }, []);

	// React.useEffect(() => {
	// 	const preventClose = (e: BeforeUnloadEvent) => {
	// 		e.preventDefault();
	// 		if (dataModal.message?.includes('수정')) {
	// 			return;
	// 		}else if(dataModal.message?.includes('삭제')) {
	// 			return;
	// 		}else if(dataModal.message?.includes('저장')) {
	// 			return;
	// 		}
	// 		e.returnValue = true;
	// 	};
	// 	window.addEventListener(  "beforeunload" , preventClose );
	// 	return () => {
	// 		window.removeEventListener( "beforeunload" , preventClose );
	// 	};
	// }, [dataModal.message]);

	useEffect(() => {
		const handleBeforeUnload = (event:BeforeUnloadEvent) => {
			if (dataModal.menu === '') {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [dataModal.menu])
	return (
		<React.Fragment>
			{selectInspect ?
				<FacilityDailyInspectionReg facilityData={inputState}/>
				:
				<SectionFrame>
					{dataModal.menu === 'success' ?
						<ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={closeModify}/>
						: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={closeModify}/>
							: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={closeModify}/> : null}

					{dataModal.menu === 'confirm' &&
						<ConfirmPopup clickEvent={closeModify} statusText={dataModal.message}/>}

					{dataModal.menu === 'select' &&
						<SelectConfirmModal statusText={dataModal.message}
																noticeText={'* 삭제된 데이터는 복구 되지 않습니다.'}
																clickEvent={()=>{facilityDeleteResponse().then(); setDataModal(initModal)}}
																cancelEvent={()=>setDataModal(initModal)}/>}

					{dataModal.menu === 'ictCheck' &&
						<SelectConfirmModal statusText={dataModal.message} fontSize={18}
																noticeText={'* 재발급시 이전 ICT번호는 복구되지 않습니다.'}
																clickEvent={()=>{generateIctNumber().then(); setDataModal(initModal)}}
																cancelEvent={()=>setDataModal(initModal)}/>}

					{/*{dataModal.menu === 'search' &&*/}
					{/*	<CompanySelectModal */}
					{/*		receivedCompany={(v)=>{setInputState((prev:any)=>({...prev,*/}
					{/*			companyCode: v.companyCode,*/}
					{/*			companyName: v.companyName,*/}
					{/*			location: locationFilter(v.address)*/}
					{/*		}))}}*/}
					{/*		closeButton={()=>setDataModal(initModal)}/>*/}
					{/*}*/}

					{dataModal.menu === 'peripheral' &&
						<PeripheralRegisterModal companyCode={dataValid(facilityData?.current.companyCode,'')}
																		 selectFacility={facilityData?.current}
																		 cancelEvent={()=>setDataModal(initModal)} viewType={false}
																		 selectedPeripheral={dataValid(inputState?.peripheralCode,[])}
																		 receivedPeripheral={(v)=>setInputState((prev:any)=>({...prev, peripheralCode: v}))}/>}


					{dataModal.menu === 'log' &&
						<ModalBackCss style={{backgroundColor:'#1315234D', paddingTop:'170px'}}>
							<FacilityLog logData={facilityData?.log} height={'549px'} authValid={authValid()}
													 buttonActive={true} closeModal={()=>setDataModal(initModal)}/>
						</ModalBackCss>}

					{dataModal.menu === 'cancel' &&
						<SelectConfirmModal statusText={dataModal.message}
																viewType={true}
																noticeText={' * "예" 선택시 저장없이 이동합니다.'}
																clickEvent={()=>{window.location.href = authValid() ? `/admin/fas/facility/${facilityData?.current.facilityCode}` : `/fas/facility/${facilityData?.current.facilityCode}`}}
																cancelEvent={()=>{setDataModal(initModal)}}/>}

					{dataModal.menu === 'register' &&
						<SelectConfirmModal statusText={dataModal.message}
																noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
																clickEvent={facilityUpdateResponse}
																cancelEvent={()=>{setDataModal(initModal)}}/>}

					{dataModal.menu === 'production'&& <ProductionItemModal facilityDetail={facilityData} closeEvent={()=>{setDataModal(initModal)}}/>}


					<MenuTitle title={t((authValid()? '업체/' : '')+'기본정보 관리 > 기계 기본정보 > 수정')} unitCase={'MenuTitle'}/>
					<ContentFrame flexDirection={'row'} padding={'30px 20px 30px 30px'}>
						<ModifyComp>
							<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
								<MenuTitle title={t('기계 기본정보 수정')} unitCase={'MenuCompTitle'}/>
								<div style={{display: 'flex'}}>
									<LogButton
										onClick={() => setDataModal({menu: 'log', message: ''})}>{t('변경이력 보기')}</LogButton>
									<MainButtons width={'56px'} name={'취소'}
															 clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
									<MainButtons name={'삭제'} buttonType={'delete'} disabled={buttonAuthValid('delete','6')} width={'56px'} clickEvent={() => setDataModal({menu: 'select', message: '정말 삭제 하시겠습니까?'})}/>
									<MainButtons name={'저장'} disabled={buttonAuthValid('update','6')} buttonType={'cta'} width={'56px'} marginDis={true}
															 clickEvent={()=>{setDataModal({menu:'register',message:'이대로 수정 하시겠습니까?'})}}/>
								</div>
							</div>
							<div style={{marginTop: '16px'}} className={'scroll_func'}>
								{/*사용 안함*/}
								{/*<ModifyOption>*/}
								{/*	<div>{t('업체명')}</div>*/}
								{/*	<div style={{gap:'8px',position:'relative',display:'flex'}}>*/}
								{/*		<RegisterInput type={'input'} name={'companyName'} placeholder={'검색'} onKeyPress={enterEvent}*/}
								{/*		               value={inputState.companyName === 'noCodeData' ? '' : inputState.companyName} onChange={onChangeValue}/>*/}
								{/*		<CompanySearchButton onClick={()=>setModalState({open: true, status:'search',message:''})}>{'검색'}</CompanySearchButton>*/}
								{/*	</div>*/}
								{/*</ModifyOption>*/}
								{authValid() && <RegisterComponent type={'empty'} name={'companyName'} title={'업체명'}
																								 value={inputState?.companyName} onChange={onChangeValue}/>}

								{/*<ModifyOption>*/}
								{/*	<div>{t('지역')}</div>*/}
								{/*	<div>*/}
								{/*		<RegisterInput type={'input'} name={'location'} value={locationFilter(inputState.location)} onChange={onChangeValue} disabled={true}/>*/}
								{/*	</div>*/}
								{/*</ModifyOption>*/}
								{/*<ModifyOption>*/}
								{/*   <div>{t('공장명')}</div>*/}
								{/*   <div style={{gap:'8px',position:'relative',display:'flex',*/}
								{/*      pointerEvents:`${inputState.companyName === ''? 'none' : 'auto'}`,*/}
								{/*      opacity:`${inputState.companyName === ''? 0.5 : 1}`*/}
								{/*   }}>*/}
								{/*      <FactoryDropMenu clickEvent={(data)=>{Object.assign(inputState, data)}}*/}
								{/*                       currentIndex={factoryList.findIndex((v)=>v.factoryCode === inputState.factoryCode)}*/}
								{/*         //@ts-ignore*/}
								{/*                        menuArray={factoryList?.map((v)=>({factoryAddress: v.factoryAddress, factoryCode: v.factoryCode, factoryName: v.factoryName}))}/>*/}
								{/*   </div>*/}
								{/*</ModifyOption>*/}
								<div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 392px)'}}>
									{authValid() &&
										<React.Fragment>
											<RegisterComponent type={'empty'} name={'location'} value={locationFilter(inputState?.location)}
																				 title={t('지역')} onChange={onChangeValue}/>
											<RegisterComponent type={'empty'} name={'companyCode'} value={inputState?.companyCode}
																				 title={t('업체코드')} onChange={onChangeValue}/>
										</React.Fragment>}

									<ModifyOption>
										<div style={{userSelect:'none'}}>{t('공장명')}</div>
										<div style={{
											position: 'relative', display: 'flex',
											pointerEvents: `${inputState?.companyName === '' ? 'none' : 'auto'}`,
											opacity: `${inputState?.companyName === '' ? 0.5 : 1}`
										}}>
											{inputState?.companyCode === 'noCodeData' ?
												<FactoryDropMenu
													clickEvent={(data)=>{setInputState((prev:any)=>({...prev, ...data}))}}
													selectCode={''}
													menuArray={[{factoryName: '업체를 선택해 주세요.'}]}/>
												:
												<FactoryDropMenu clickEvent={(data) => {
													if(data.factoryName === t('선택 없음')){
														setFactorySegment(['미등록'])
														setInputState((prev:any)=>({...prev, factorySubdivision: ''}))
													}
													setInputState((prev:any)=>({...prev, ...data}))
													if(data.factoryCode){
														factoryDivision(dataValid(data.factoryCode,'')).then()
													}
												}} selectCode={dataValid(inputState.factoryCode,'')}
													//@ts-ignore
																				 menuArray={factoryList?.map((v) => ({
																					 factoryAddress: v.factoryAddress,
																					 factoryCode: v.factoryCode,
																					 factoryName: v.factoryName
																				 }))}/>
											}
										</div>
									</ModifyOption>


									<RegisterComponent type={'drop'} title={'공장 세분화'} dropWidth={'208px'}
																		 name={'factorySubdivision'} value={inputState?.factorySubdivision} onChange={onChangeValue}
																		 dropAddNoneSelect={factorySegment[0] !== '미등록'}
																		 dropClickEvent={(v)=>{
																			 if(v === '미등록'){
																				 setInputState((prev:any)=>({...prev, factorySubdivision: ''}))
																			 }else{
																				 setInputState((prev:any)=>({...prev, factorySubdivision: v}))}
																		 }}
																		 dropArray={factorySegment}/>

									<RegisterComponent type={'drop'} title={t('기종')} name={'type'} value={inputState?.type} required={true}
																		 dropClickEvent={(v) =>{
																			 if(v === 'CNC'){
																				 setInputState((prev:any) => ({...prev, type: v, ictNumber:''}))
																			 }
																			 else if(v !== '프레스'){
																				 setInputState((prev:any) => ({...prev, type: v, maxTon:'0', ictNumber:''}))
																			 }
																			 else if(v !== '용접기'){
																				 setInputState((prev:any) => ({...prev, type: v, weldingType:'', ictNumber:''}))
																			 }
																			 else{
																				 setInputState((prev:any) => ({...prev, type: v, ictNumber:''}))
																			 }}}
																		 dropArray={[t('프레스'), t('로봇'), t('CNC'), t('용접기'), t('밀링'), t('선반'), t('탭핑기'), t('비전기')]}
																		 onChange={onChangeValue}/>

									{/*<RegisterOption>*/}
									{/*   <div>{t('용접 종류')}</div>*/}
									{/*   <div style={{position:'relative'}}>*/}
									{/*      <DropMenu menuArray={['선택없음', '아르곤', '스팟', '통합']} width={'192px'}*/}
									{/*                clickEvent={(data)=>{Object.assign(inputState, {type: data})}}/>*/}
									{/*   </div>*/}
									{/*</RegisterOption>*/}
									<RegisterComponent type={'drop'} title={'용접 종류'} disabled={inputState?.type !== '용접기'}
																		 titleDisabled={inputState?.type !== '용접기'} dropWidth={'208px'} dropAddNoneSelect={true}
																		 name={'weldingType'} value={inputState?.weldingType} onChange={onChangeValue}
																		 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, weldingType: v}))}
																		 dropArray={['아르곤', '스팟', '통합']}/>

									<RegisterComponent type={'text'} name={'manager'} value={inputState?.manager} paddingRight={true}
																		 title={t('기계 담당자')} onChange={onChangeValue}/>
									<RegisterComponent type={'drop'} name={'overhaul'} value={inputState?.overhaul}
																		 title={t('오버홀')} dropArray={['유', '무']} dropWidth={'208px'}
																		 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, overhaul:v}))}
																		 onChange={onChangeValue}/>
									<RegisterComponent type={'calendar'} name={'productionDate'} value={inputState?.productionDate} left={150}
																		 selectDate={dataValid(inputState?.productionDate, '')}
																		 deleteEvent={()=>{setInputState((prev:any)=>({...prev, productionDate: ''}))}}
																		 title={t('제조 연월일')}
										// selectDate={!inputState.productionDate? dayjs().toDate() : dayjs(inputState.productionDate).toDate()}
																		 onChange={(v)=> setInputState((prev:any)=>({...prev, productionDate: dayjs(v).format('YYYY.MM.DD')}))} placeholder={'제조 연월일'}/>
									<RegisterComponent type={'doubleCheck'} name={'manufacturingNumber'}
																		 value={inputState?.manufacturingNumber} error={alertPoint('manufacturingNumber')}
																		 clickEvent={serialDoubleCheck} disabled={(inputState.codeDoubleCheck)} clearEvent={()=>setAlertInput('')}
																		 title={t('제조번호')} onChange={(e)=>(setInputState((prev:any)=>({...prev, manufacturingNumber: e.target.value , codeDoubleCheck:false})))}/>

									<RegisterComponent type={'text'} name={'maker'} value={inputState?.maker} required={true} paddingRight={true}
																		 title={t('제조사')} error={alertPoint('maker')} onChange={(e)=>onChangeValue(e,{trim: true})}/>
									<RegisterComponent type={'text'} name={'facilityName'} value={inputState?.facilityName}
																		 error={alertPoint('facilityName')}
																		 title={t('기계명')} onChange={(e)=>onChangeValue(e,{trim: true})} required={true}
																		 clickEvent={() => setAlertInput('')}/>
									{/*<RegisterComponent type={authValid? 'ict' : 'text'} name={'ictNumber'} required={authValid} buttonName={'재발급'} disabled={!authValid || buttonAuthValid('update','6')}*/}
									{/*									 value={inputState?.ictNumber}*/}
									{/*	// value={inputState?.ictNumber?.length === 8 ? inputState?.ictNumber.slice(3) : inputState?.ictNumber}*/}
									{/*									 title={t('ICT 번호')} onChange={(e)=>onChangeValue(e,{number: true})} limit={5}*/}
									{/*									 // error={alertPoint('ictNumber')}*/}
									{/*									 clickEvent={() => {*/}
									{/*										 setAlertInput('')*/}
									{/*										 setDataModal({menu:'ictCheck', message:'ICT번호 재발급시 새로운 번호가 부여됩니다. \n 재발급 하시겠습니까?'})*/}
									{/*									 }}*/}
									{/*									 placeholder={'5개의 숫자를 입력하세요.'} paddingRight={true}/>*/}

									{(inputState.type === 'CNC' || inputState.type === '비전기') ?
										<RegisterComponent type={'unit'} name={'ictNumber'} paddingRight={true}
																			 value={inputState.ictNumber} unit={''} limit={8}
																			 error={alertPoint('ictNumber')} clickEvent={()=>setAlertInput('')}
																			 title={t('ICT 번호')} onChange={onChangeICT} placeholder={'숫자 7자리를 입력해 주세요.'}/>
										:

										<RegisterComponent type={authValid()? 'ict' : 'text'} name={'ictNumber'} required={authValid()}
																			 buttonName={'재발급'} disabled={!authValid() || buttonAuthValid('update','6')}
																			 value={inputState?.ictNumber}
											// value={inputState?.ictNumber?.length === 8 ? inputState?.ictNumber.slice(3) : inputState?.ictNumber}
																			 title={t('ICT 번호')} onChange={(e)=>onChangeValue(e,{number: true})} limit={5}
											// error={alertPoint('ictNumber')}
																			 clickEvent={() => {
																				 setAlertInput('')
																				 setDataModal({menu:'ictCheck', message:'ICT번호 재발급시 새로운 번호가 부여됩니다. \n 재발급 하시겠습니까?'})
																			 }}
																			 placeholder={'ICT번호를 발급해 주세요.'} paddingRight={true}/>
									}

									<RegisterComponent type={'unit'} name={'maxTon'} value={numberLocaleString(inputState?.maxTon)} unit={'ton'}
																		 titleDisabled={inputState?.type !== '프레스'} required={inputState?.type === '프레스'}
																		 title={t('최대 톤수')}  disabled={inputState?.type !== '프레스'}
																		 error={alertPoint('maxTon')} clickEvent={()=>setAlertInput('')}
																		 onChange={(e)=>onChangeValue(e,{number: true})} limit={11} placeholder={'-'}/>
									<RegisterComponent type={'text'} name={'hw'} value={inputState?.hw}
																		 title={'HW'} onChange={onChangeValue} paddingRight={true}/>
									<RegisterComponent type={'text'} name={'version'} value={inputState?.version}
																		 title={t('버전')} onChange={onChangeValue}/>

									<RegisterComponent type={'text'} name={'network'} value={inputState?.network} paddingRight={true}
																		 title={t('네트워크')} onChange={onChangeValue}/>

									<RegisterComponent type={'unit'} name={'voltage'} value={numberLocaleString(inputState?.voltage)} unit={'V'}
																		 title={t('사용전압')} onChange={(e)=>onChangeValue(e,{number: true})} limit={11} placeholder={'-'}/>

									<RegisterComponent type={'textClick'} name={'생산품목'} buttonName={t('생산품목 보기')} value={''} clickEvent={()=>{setDataModal({menu:'production'})}}
																		 title={t('생산품목')} />

									<RegisterComponent type={'button'} name={'주변장치 등록'} value={''} disabled={buttonAuthValid('update','6')}
																		 clickEvent={()=>setDataModal({menu:'peripheral'})}
																		 buttonName={!inputState.peripheralCode?'주변장치 등록':t('주변장치 변경')} width={'114px'}
																		 title={t('주변장치')}/>

									{/*<RegisterComponent type={'empty'} name={'-'} value={'-'}*/}
									{/*									 title={t('-')} onChange={onChangeValue}/>*/}

									{/*<RegisterComponent type={'button_textClick'} name={'일상점검 양식 보기'} value={''}*/}
									{/*                   clickEvent={()=>setSelectInspect(true)}*/}
									{/*                   title={t('일상점검')} buttonName={'수정'} width={'56px'}*/}
									{/*                   subClickEvent={()=>''}/>*/}
									{/*<RegisterComponent type={'empty'} title={'-'} name={'.'} value={''} onChange={() => ''}/>*/}
								</div>

								<RegisterComponent type={''} name={''} value={''}
																	 component={
																		 <div style={{display:'flex'}}>
																			 {connentCompany.map((v)=>(
																				 <label style={{display:'flex', alignItems:'center', cursor:'pointer', userSelect:'none'}}>
																					 <input type="radio" name={'connent-company'} style={{margin:0}} checked={v.device === dataValid(inputState.device, 'ZESTECH')}
																									value={inputState.device}
																									onChange={()=>setInputState((prev:any)=>({...prev, device: v.device}))}/>
																					 <div style={{userSelect:'none', fontSize:'12px',
																						 fontWeight: v.device === inputState.device? 700 : 400,
																						 margin:'0 8px 0 4px'}}>{v.company}</div>
																				 </label>
																			 ))}
																		 </div>
																	 } title={'연결된 디바이스'}/>


								<ModifyOption style={{borderBottom: '1px solid #E6E9F4', height: '112px'}}>
									<div style={{height: '112px', lineHeight: '112px'}}>{t('비고')}</div>
									<div className={'modify_notice'}>
										<RegisterInput type={'textarea'} name={'notice'} value={inputState?.notice} onChange={onChangeValue}/>
									</div>
								</ModifyOption>
							</div>
						</ModifyComp>

						<div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 28px'}}/>

						<div className={'scroll_comp'} style={{width: '379px', overflowY: 'scroll'}}>
							<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'16px'}}>
								<MenuTitle title={'이미지 수정'} unitCase={'MenuCompTitle'}/>
								<div style={{color: '#7E84A3', fontSize: '12px', lineHeight: '16px', height: '34px'}}>
									<div>{t('*최대 4개의 이미지 등록 가능')}</div>
									<div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
								</div>
							</div>

							{/*@ts-ignore*/}
							<TotalImageUploadBox receiveKey={facilityData.current?.image} receiveUrl={facilityData.current?.imageUrl}
																	 profileKey={facilityData?.current.profileImage}
																	 imageKeyArray={(data) => setImageKeyState(data)}
																	 receiveProfileKey={(v: any) => setInputState((prev:any)=>({...prev, profileImage: v}))}
																	 profileSelect={true}/>

							<div style={{...multipleImageGridStyle, marginTop: '16px'}}>
								<div>
									<MenuTitle title={'스펙 명판'} unitCase={'MenuCompTitle'}/>
									<SingleImageUploadBox receiveKey={inputState?.facilitySpecImg}
																				receiveUrl={dataValid(inputState?.facilitySpecImageUrl,'')}
																				imageKey={(v)=>setInputState((prev:any)=>({...prev, facilitySpecImg:v}))}
																				profileSelect={false}/>
								</div>
								<div>
									<MenuTitle title={'능력 명판'} unitCase={'MenuCompTitle'}/>
									<SingleImageUploadBox receiveKey={inputState?.facilityAbilityImg}
																				receiveUrl={dataValid(inputState?.facilityAbilityImageUrl,'')}
																				imageKey={(v)=>setInputState((prev:any)=>({...prev, facilityAbilityImg:v}))}
																				profileSelect={false}/>
								</div>
								<div>
									<MenuTitle title={'사용 설명서'} unitCase={'MenuCompTitle'}/>
									<SingleImageUploadBox receiveKey={inputState?.facilityManualImg}
																				receiveUrl={dataValid(inputState?.facilityManualImageUrl,'')}
																				imageKey={(v)=>setInputState((prev:any)=>({...prev, facilityManualImg:v}))}
																				profileSelect={false}/>
								</div>
							</div>
						</div>
					</ContentFrame>
				</SectionFrame>
			}
		</React.Fragment>
	);
};


export default FacilityModify;

const ModifyComp = styled.div`
  width: 784px;
  height: 620px;

  .scroll_func{
    width: 804px;
    height: 589px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: block;
    }
    ::-webkit-scrollbar-track{
      width: 21px;
      background: transparent;
    }
  }
  .modify_notice{
    min-height: 112px;
    height: 112px;
  }
`

const ModifyOption = styled.div`
  display: flex;
  border-top: 1px solid #E6E9F4;
  &>div{
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    padding-left: 16px;
    align-items: center;
    display: flex;
  }
  &>div:first-child{
    width: 168px;
    min-width: 168px;
    color: #131523;
    background-color: #F5F6FA;
    font-size: 12px;
    font-weight: 800;
  }
  &>div:last-child{
    width: 100%;
    max-width: 616px;
    max-height: 48px;
  }
`

const LogButton = styled.div`
  color: #5A607F;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
  align-self: center;
  transition: all 0.3s;
  user-select: none;
  &:hover{
    color: #D7DBEC;
  }
`