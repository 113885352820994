import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import client from "../../../../../common/Config";
import {useParams} from "react-router-dom";
import checked from '../../../../../asset/image/check-icon.svg'
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {ContentFrame , SectionFrame , femsInfoStyle , NoData} from "../../../../../style/StyleComponent";
import {Authorization} from "../../../../../common/authorization";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  buttonAuthValid ,
  dataAccessLoading , dataValid ,
  initModal ,
  loadingRemove ,
  phoneNumberFormat
} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {
  emailReg , IdDuplicate , onIdCheckValid ,
  passwordReg ,
  phoneReg , specialCharExcludeReg
} from "../../../../../ValidationCheck";
import {useRecoilState , useResetRecoilState} from "recoil";
import {persistCompanyData} from "../../../../../common/StateManage";
import KepcoNOSearchModal from "../../../../../components/modal/KepcoNOSearchModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import PasswordChangeModal from "../../../../../components/modal/PasswordChangeModal";

const BasicModify = () => {
  const {t}: any = useTranslation();
  const companyID = useParams()
  // const [pwCheckState , setPwCheckState] = React.useState({text: '*비밀번호 확인을 해주세요.' , color: '#17172580'})
  const [pwCheckNormal , ] = useState<boolean>(false)
  // const [emailCheck,setEmailCheck] = React.useState({text:'*올바른 형식의 이메일 입니다.',color:'#0058FF'})
  const [imageState , setImageState] = useState<string | null>(null);
  const [logoState , setLogoState] = useState<string | null>(null);
  const [openPostCode , setOpenPostCode] = useState<boolean>(false)
  const [, setSearchAddressState] = useState('')
  const [dataModal , setDataModal] = useState<Modal_View_Type>(initModal)
  const [companyModify , setCompanyModify] = useState<Company_Input_Type | any>({})
  const [selectCompany , setSelectCompany] = useRecoilState(persistCompanyData)
  const [adminPassword , setAdminPassword] = useState<string>('');

  const resetCompanyData = useResetRecoilState(persistCompanyData);

  interface Company_Input_Type {
    id: string
    companyName: string
    president: string
    address: string
    detailAddress?: string
    email: string
    phone_number: string
    managerName: string
    managerNumber: string
    password?: string
    passwordCheck?: string
    notice?: string
    latitude?: number | string
    longitude?: number | string
    custNo?: number | string
    midaPress?: string
  }

  const companyDetailResponse = async () => {
    try {
      const response = await client.post('/company/detail' , {
        companyCode: companyID.id
      } , {headers: {Authorization}})
      const data = response.data.data
      setCompanyModify( {...data, idExist: !data.id? 'empty' : 'exist'})
      setAuthorizationArray(data.authorities.split(','))
    }
    catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    companyDetailResponse().then()
  } , [])
  const onEdit = (e: any , trim?: boolean) => {
    const {name , value} = e.target
    setCompanyModify({
                       ...companyModify ,
                       [name]: trim ? value.trim() : value
                     })
  }
  //삭제
  const companyDeleteResponse = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post('/company/delete' , {
        companyCode: companyID.id
      } , {headers: {Authorization}})
      if(response.data.status !== 200){
        setDataModal({menu: 'confirm' , message: t(response.data.message)})
        loadingRemove()
      } else{
        setTimeout(() => {
          ( selectCompany.id === companyModify.id ) && resetCompanyData()
          setDataModal({menu: 'success' , message: t('삭제 되었습니다.')})
          loadingRemove()
        } , 500)
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }

  //정보 수정 업데이트
  const companyUpdateResponse = async () => {
    if(!companyModify.companyName){
      setDataModal({menu: 'confirm' , message: ( '업체명을 입력해 주세요.' )})
    }
    else if(specialCharExcludeReg.test(companyModify.companyName)){
      setDataModal({menu: 'confirm' , message: '업체명에 특수문자를 입력할 수 없습니다.'})
    }
    else if(!companyModify.president){
      setDataModal({menu: 'confirm' , message: '대표자명을 입력해 주세요.'})
    }
    else if(!companyModify.managerName){
      setDataModal({menu: 'confirm' , message: '담당자를 입력해 주세요.'})
    }
    else if(!companyModify.phoneNumber){
      setDataModal({menu: 'confirm' , message: '업체 전화번호를 입력해 주세요.'})
    }
    else if(!phoneReg.test(companyModify.phoneNumber.trim()) && companyModify.phone_number){
      setDataModal({menu: 'confirm' , message: '업체 전화번호 형식이 맞지 않습니다.'})
    }
    else if(!companyModify.managerNumber){
      setDataModal({menu: 'confirm' , message: '담당자 전화번호를 입력해 주세요.'})
    }
    else if(!phoneReg.test(companyModify.managerNumber.trim()) && companyModify.managerNumber){
      setDataModal({menu: 'confirm' , message: '담당자 전화번호 형식이 맞지 않습니다.'})
    }
    else if(!companyModify.address){
      setDataModal({menu: 'confirm' , message: '주소를 입력해 주세요.'})
    }
    else if(!companyModify.email){
      setDataModal({menu: 'confirm' , message: '이메일을 입력해 주세요.'})
    }
    else if(!emailReg.test(companyModify.email)){
      setDataModal({menu: 'confirm' , message: '이메일 형식이 맞지 않습니다.'})
    }
    else if(!passwordReg.test(dataValid(companyModify?.password , '')) && companyModify.password){
      setDataModal({menu: 'confirm' , message: '비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
    }
    else if(companyModify.password && !companyModify.passwordCheck){
      setDataModal({menu: 'confirm' , message: ' 비밀번호를 한번 더 입력해 주세요.'})
    }
    else if(companyModify.password && !pwCheckNormal){
      setDataModal({menu: 'confirm' , message: ' 비밀번호가 일치한지 확인 해주세요.'})
    }
    else if(companyModify.password && ( companyModify.password !== companyModify.passwordCheck )){
      setDataModal({menu: 'confirm' , message: ' 비밀번호가 일치하지 않습니다.'})
    }
    else{
      if(!companyModify.password){
        delete companyModify.password
      }
      if(!companyModify.passwordCheck){
        delete companyModify.passwordCheck
      }
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post('/company/update' , {
          ...companyModify ,
          id: companyModify.id ,
          companyCode: companyModify.companyCode ,
          phone_number: companyModify.phoneNumber.trim() ,
          managerNumber: companyModify.managerNumber.trim() ,
          image: imageState ?? null ,
          logoImage: logoState ?? null ,
          authorities: !companyModify.id ? ['FAS'] : authorizationArray?.map(item => item === '' ? 'FAS' : item) ,
        } , {headers: {Authorization}})
        if(response.data.status !== 200){
          setDataModal({menu: 'confirm' , message: t(response.data.message)})
          loadingRemove()
        } else{
          setTimeout(() => {
            ( selectCompany.id === companyModify.id ) && setSelectCompany(companyModify)
            setDataModal({menu: 'success' , message: t('수정 되었습니다.')})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove(300)
      }
    }
  }
  // const onCheckPassword = (e: { target: { name: string; value: string } }) => {
  //
  //     const {name, value} = e.target
  //     setCompanyModify({
  //                          ...companyModify,
  //                          [name]: value
  //                      })
  //     onPwCheckValid(value, setPwCheckState)
  // }
  //
  // const onPwCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) =>{
  //     if(e.key === 'Enter'){
  //         PasswordCheck(companyModify,setPwCheckState,setPwCheckNormal);
  //     }
  // }
  const companyPasswordModifyRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post('/company/update' , {
        ...companyModify ,
        id: companyModify.id ,
        companyCode: companyModify.companyCode ,
        phone_number: companyModify.phoneNumber ,
        image: imageState ?? null ,
        logoImage: logoState ?? null ,
        authorities: authorizationArray?.map(item => item === '' ? 'FAS' : item) ,
        password: adminPassword
      } , {headers: {Authorization}})
      const data = response.data
      if(data.message !== 'success'){
        setDataModal({menu: 'confirm' , message: data.message});
        loadingRemove()
      } else{
        setDataModal({menu: 'success' , message: t('비밀번호가 변경 되었습니다.')});
        setTimeout(() => {
          loadingRemove()
          window.location.href = `/admin/fas/basic/${companyModify.companyCode}`
        } , 500)
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const closeModify = () => {
    setDataModal(initModal)
    if(dataModal.message === '삭제 되었습니다.'){
      window.location.href = `/admin/fas/basic`
    }
    if(dataModal.message === '수정 되었습니다.'){
      window.location.href = `/admin/fas/basic/${companyModify.companyCode}`
    }
  }

  const solutionArray = ['P-BAS' , 'P-MS' , 'R-BAS' , 'C-BAS' ,'C-MS' , 'V-MS', 'A-BAS' , 'F-EMS','산업안전 모니터링']
  const [authorizationArray , setAuthorizationArray] = React.useState(['FAS'])
  const onCheckedAuthorization = (checked: any , item: any) => {
    if(checked){
      // @ts-ignore
      setAuthorizationArray([...authorizationArray , item])
    } else{
      setAuthorizationArray(authorizationArray.filter(el => el !== item));
    }
  };
  const onCheckedMidaPress = (checked: any) => {
    if(checked){
      setCompanyModify({...companyModify , midaPress: '1'})
    } else{
      setCompanyModify({...companyModify , midaPress: '0'})
    }
  }
  const [femsInfo , setFemsInfo] = useState<boolean>(false)
  useEffect(() => {
    if(authorizationArray.includes('F-EMS')){
      setFemsInfo(true)
    } else{
      setFemsInfo(false)
    }
  } , [authorizationArray])
  const deleteSuccessEvent = () => {
    setDataModal(initModal)
    companyDeleteResponse().then()
  }
  const addressHandle = {
    clickBtn: () => setOpenPostCode(current => !current) ,
    selectAddress: (data: any) => {
      setSearchAddressState(data.address)
      companyModify.address = data.address + `${!data.buildingName ? '' : `(${data.buildingName})`}`
      setCompanyModify({...companyModify , detailAddress: ''})
      setOpenPostCode(false);
    } ,
  }
  const AddressStyle = {
    display: 'block' ,
    width: 500 ,
    height: 600 ,
    position: 'absolute' ,
    boxShadow: '0 1px 7px #E6E9F4' ,
    borderRadius: '8px' ,
    backgroundColor: '#fff' ,
    padding: "7px" ,
    zIndex: 100 ,
  }
  const {kakao}: any = window;
  const LocationPosition = () => {
    let geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(companyModify.address , function (result: any , status: any) {
      if(status === kakao.maps.services.Status.OK){
        let coords = new kakao.maps.LatLng(result[0].y , result[0].x);
        Object.assign(companyModify , {latitude: coords.getLat() , longitude: coords.getLng()})
        // setGetPosition((prevState)=>(prevState.concat({lat:coords.getLat(),lng:coords.getLng()})))
      }
    })
  }
  useEffect(() => {
    LocationPosition()
  } , [companyModify.address])
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(dataModal.menu === ''){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload);
    };
  } , [dataModal.menu]);
  // const [emailStatus , setEmailStatus] = React.useState<string>('');
  // const [ , setPassword] = React.useState<boolean>(false);
  //이메일보내는 테스트
  // const sendEmailRes = async () => {
  //   dataAccessLoading();
  //   setDataModal(initModal)
  //   // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
  //   setTimeout(() => {
  //     setPassword(true)
  //     loadingRemove();
  //     // 작업 완료 시 호출할 콜백 함수
  //   } , 1000);
  // };

  const [idCheck, setIdCheck] = React.useState({text:'*ID 중복확인을 해주세요.', color:''});
  const [idCheckNormal, setIdCheckNormal] = React.useState<boolean>(false);
  const onCheckId = (e: { target:{name: string, value:string }}) =>{
    const {name,value} = e.target;
    setCompanyModify({
                          ...companyModify,
                          [name]:value.trim()
                        })
    onIdCheckValid(value,setIdCheck)
    setIdCheckNormal(false)
  }

  const onIdCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      IdDuplicate(companyModify,setIdCheck,setIdCheckNormal).then()
    }
  }

  return (
    <SectionFrame>
      <MenuTitle title={'업체/기본정보 관리 > 업체 정보 > 수정'} unitCase={'MenuTitle'}/>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} timeSet={800}
                    state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={closeModify}/>
          : dataModal.menu === 'warn' ?
            <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={closeModify}/> : null}
      {dataModal.menu === 'confirm' && <ConfirmPopup clickEvent={closeModify} statusText={t(dataModal.message)}/>}
      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={t('* 등록된 정보(공장,설비,제품,공정,도면 등)가 모두 삭제됩니다.')}
                                                          clickEvent={deleteSuccessEvent}
                                                          cancelEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={t(dataModal.message)}
                              noticeText={t(' * "예" 선택시 변경전 데이터는 복구되지 않습니다.')}
                              clickEvent={companyUpdateResponse}
                              cancelEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={t(dataModal.message)}
                              viewType={true}
                              noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
                              cancelEvent={() => {setDataModal(initModal)}}
                              clickEvent={() => { window.location.href = `/admin/fas/basic/${companyModify.companyCode}`}}
          />
      }

      {dataModal.menu === 'search' &&
          <KepcoNOSearchModal
              companyName={companyModify.companyName}
              accessButton={(v) => {
                Object.assign(companyModify , {custNo: v.custNo})
                setDataModal(initModal)
              }}
              closeButton={() => setDataModal(initModal)}
          />}

      {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={companyModify} viewType={true}
                               receivePassword={(data) => {setAdminPassword(data)}}
                               skipPasswordCheck={companyModify.idExist === 'empty'}
                               clickEvent={companyPasswordModifyRes}
                               closeClick={() => {setDataModal(initModal)}}/>
      }

      {/*{dataModal.menu === 'findPw' &&*/}
      {/*  <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
      {/*}*/}
      {/*{password &&*/}
      {/*  <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
      {/*}*/}


      {openPostCode && <ModalBase clickEvent={() => setOpenPostCode(false)} modalContent={
        <DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
          //@ts-ignore
                      style={AddressStyle}/>}/>}
      <ContentFrame width={'1272px'} height={'680px'} padding={'30px'} flexDirection={'row'}>
        <div style={{boxSizing: 'border-box' , minHeight: '666px' , flexDirection: 'column'}}>
          <div
            style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , marginBottom: '20px'}}>
            <MenuTitle title={'업체 정보 수정'} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex'}}>
              <MainButtons width={'56px'} name={'취소'}
                           clickEvent={() => {setDataModal({menu: 'cancel' , message: t('작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?')})}}/>
              <MainButtons name={'삭제'} disabled={buttonAuthValid('delete' , '2')} width={'56px'}
                           clickEvent={() => setDataModal({menu: 'select' , message: t('정말 삭제 하시겠습니까?')})}/>
              <MainButtons name={'저장'} buttonType={'cta'} disabled={buttonAuthValid('update' , '2')} width={'56px'}
                           marginDis={true}
                           clickEvent={() => {setDataModal({menu: 'register' , message: t('이대로 수정하시겠습니까?')})}}/>
            </div>
          </div>
          <InputContainer>
            <div className={'scroll_func'}>
              <RegisterComponent type={'text'} name={'companyName'} value={companyModify.companyName}
                                 required={true}
                                 title={t('업체명')} onChange={(e) => onEdit(e , true)} placeholder={t('업체명을 입력해 주세요.')}/>

              <RegisterComponent type={'text'} name={'president'} value={companyModify.president}
                                 required={true}
                                 title={t('대표')} onChange={onEdit} placeholder={t('대표명을 입력해 주세요.')}/>

              <RegisterComponent type={'address'} onChange={onEdit} title={t('주소')} width={'72px'} required={true}
                                 name={'address'} subName={'detailAddress'}
                                 subValue={companyModify.detailAddress}
                                 clickEvent={addressHandle.clickBtn} value={companyModify.address}/>

              {/*주소끝*/}
              <RegisterComponent type={'phone'} value={companyModify.phoneNumber} error={false} title={t('전화번호')}
                                 onChange={onEdit} name={'phoneNumber'} required={true}
                                 numberFormat={companyModify.phoneNumber}/>

              <RegisterComponent type={'text'} name={'managerName'} value={companyModify.managerName} required={true}
                                 title={t('담당자')}
                                 onChange={onEdit} placeholder={t('담당자명을 입력해 주세요.')}/>

              <RegisterComponent type={'phone'} value={companyModify.managerNumber} error={false} title={t('담당자 전화번호')}
                                 onChange={onEdit} name={'managerNumber'} required={true}
                                 numberFormat={companyModify.managerNumber}/>

              <RegisterComponent type={'text'} value={dataValid(companyModify.email , '')}
                                 required={true}
                                 title={t('담당자 이메일')} placeholder={t('이메일을 입력해 주세요.')}
                                 onChange={onEdit} name={'email'} bottomBorder={true}/>

              <div style={{display: 'flex'}}>
                <div className={'indexBox'} style={{height: '80px' , border: 'none'}}>
                  {t('솔루션 권한')}
                  {/*<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
                </div>
                <div className={'inputWrap'}
                     style={{height: '80px' , border: 'none' , opacity: !companyModify.id ? 0.5 : 1 , pointerEvents: !companyModify.id ? 'none' : 'auto'}}>
                  <div className={'check_box'}>
                    <div style={{display: 'flex' , alignItems: 'center' , userSelect: 'none'}}>
                      <label style={{display: 'flex' , alignItems: 'center'}}>
                        <input type="checkbox" name={'authorities'} checked={companyModify.id}
                               style={{cursor: 'default' , marginRight: '8px'}}/>
                        <div>{'FAS'}</div>
                      </label>
                    </div>
                    {solutionArray.map((v) => {
                      return (
                        <div style={{display: 'flex' , alignItems: 'center'}}>
                          <label
                            style={{display: 'flex' , alignItems: 'center' , cursor: 'pointer' , userSelect: 'none'}}>
                            <input type="checkbox" value={v} name={'authorities'} style={{marginRight: '8px'}}
                                   onChange={(e) => ( onCheckedAuthorization(e.target.checked , e.target.value) )}
                              // @ts-ignore
                                   checked={!companyModify.id ? false : authorizationArray.includes(v)}/>
                            <div style={{whiteSpace:'nowrap'}}>{v}</div>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              {
                companyModify.idExist === 'empty'?
                  <div style={{position:'relative'}}>
                    <RegisterComponent type={'signUpDoubleCheck'} name={'id'} value={companyModify.id} buttonName={idCheckNormal? '사용가능' : '중복확인'}
                                       disabled={!companyModify.id || idCheckNormal} required={true} paddingRight={true}  clickEvent={()=>IdDuplicate( companyModify , setIdCheck , setIdCheckNormal ).then()}
                                       title={t('업체 ID')} onChange={onCheckId} placeholder={t('등록된 아이디가 없습니다.')}/>
                      <div style={{color: idCheck.color , fontSize: '12px' , fontWeight: 700, position:'absolute', bottom:'4px', left:'180px' }}>{!companyModify.id? '' : idCheck.text}</div>
                  </div>
                  // <RegisterComponent type={'text'} name={'id'} value={companyModify.id}
                  //                    required={true} paddingRight={true}
                  //                    title={t('업체 ID')} onChange={onEdit} placeholder={t('등록된 아이디가 없습니다.')}/>

                  :
                  <RegisterComponent type={'emptyWithChild'} title={t('업체 ID')} value={companyModify.id}
                                     childComponent={
                                       dataValid(companyModify.id , <NoData height={48}>{'* 현재 등록된 아이디가 없습니다.'}</NoData>
                                       )}
                                     name={'id'} bottomBorder={true}/>
              }


              {/*<div style={{display: 'grid' , gridTemplateColumns: '1fr 1fr'}}>*/}
              {/*  {*/}
              {/*    companyModify.idExist === 'empty'?*/}
              {/*      <RegisterComponent type={'text'} name={'id'} value={companyModify.id}*/}
              {/*                         required={true} paddingRight={true}*/}
              {/*                         title={t('업체 ID')} onChange={onEdit} placeholder={t('등록된 아이디가 없습니다.')}/>*/}
              {/*      :*/}
              {/*      <RegisterComponent type={'emptyWithChild'} title={t('업체 ID')} value={companyModify.id}*/}
              {/*                         childComponent={*/}
              {/*                           dataValid(companyModify.id , <NoData height={48}>{'* 현재 등록된 아이디가 없습니다.'}</NoData>*/}
              {/*                           )}*/}
              {/*                         name={'id'} bottomBorder={true}/>*/}
              {/*  }*/}

              {/*  /!*마이다 프레스 확인 *!/*/}
              {/*  <RegisterComponent type={'emptyWithChild'} value={companyModify.midaPress} title={t('마이다 프레스')}*/}
              {/*                     childComponent={*/}
              {/*                       <label*/}
              {/*                         style={{display: 'flex' , alignItems: 'center' , cursor: 'pointer' , userSelect: 'none'}}>*/}
              {/*                         <input type="checkbox" name={'midasPress'}*/}
              {/*                                checked={companyModify.midaPress === '1'}*/}
              {/*                                onChange={(e) => onCheckedMidaPress(e.target.checked)}*/}
              {/*                                style={{marginRight: '8px'}}/>*/}
              {/*                         <div style={{fontSize: '12px'}}>{t('마이다 프레스 납품업체')}</div>*/}
              {/*                       </label>*/}
              {/*                     } name={'midasPress'} bottomBorder={true}/>*/}
              {/*</div>*/}

              <div style={{display: 'flex', position:'relative'}}>
                <div className={'indexBox'} style={{height: '48px'}}>{'업체 PW'}</div>
                <div className={'inputWrap'}
                     style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center' , padding: '0 0 0 20px'}}>
                  <div
                    style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , width: '572px' , opacity: !companyModify.id ? 0.5 : 1 , pointerEvents: !companyModify.id ? 'none' : 'auto'}}>
                    <MainButtons name={'변경하기'} disabled={buttonAuthValid('update' , '2') || (!idCheckNormal && companyModify.idExist === 'empty')} buttonType={'cta'}
                                 margin={'0'} clickEvent={() => {
                      if(companyModify.idExist === 'empty' && !companyModify.id){
                        setDataModal({menu: 'warn', message:'아이디를 먼저 등록 후 비밀번호 수정이 가능합니다.'})
                      }
                      else{
                        setDataModal({menu: 'pw'})
                      }
                    }}/>
                    {/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
                    {/*    {'비밀번호 찾기'}*/}
                    {/*</div>*/}
                  </div>
                </div>

                <div style={{position:'absolute', right:'16px', top:'-2px'}}>
                <RegisterComponent type={'emptyWithChild'} value={companyModify.midaPress} title={t('마이다 프레스')}
                                   childComponent={
                                     <label
                                       style={{display: 'flex' , alignItems: 'center' , cursor: 'pointer' , userSelect: 'none'}}>
                                       <input type="checkbox" name={'midasPress'}
                                              checked={companyModify.midaPress === '1'}
                                              onChange={(e) => onCheckedMidaPress(e.target.checked)}
                                              style={{marginRight: '8px'}}/>
                                       <div style={{fontSize: '12px'}}>{t('마이다 프레스 납품업체')}</div>
                                     </label>
                                   } name={'midasPress'} bottomBorder={true}/>
                </div>
              </div>

              {/*<div style={{display: 'flex'}}>*/}
              {/*    <div className={'indexBox'} style={{height:'72px'}}>*/}
              {/*        {t('관리자 PW')}*/}
              {/*        <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
              {/*    </div>*/}
              {/*    <div className={'inputWrap'} style={{height: '72px'}}>*/}
              {/*        <InputComponent type='password' name={'password'} maxLength={30}*/}
              {/*                        placeholder={t('비밀번호를 변경하실 경우 새로 입력해주세요.')}*/}
              {/*                        value={companyModify.password} onChange={onCheckPassword} onKeyPress={onPwCheckEnter}/>*/}
              {/*        <div className={'formText'}>{t('*8글자 이상 영문,숫자(특수문자 혼용가능)를 사용해주세요.')}</div>*/}
              {/*    </div>*/}
              {/*</div>*/}

              {/*<div style={{display: 'flex'}}>*/}
              {/*    <div className={'indexBox'} style={{height:'72px'}}>*/}
              {/*        {t('관리자 PW 확인')}*/}
              {/*        <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
              {/*    </div>*/}
              {/*    <div className={'inputWrap'} style={{height: '72px'}}>*/}
              {/*        <div style={{display: 'flex', justifyContent:'center',alignItems: 'baseline'}}>*/}
              {/*            <InputComponent type="password" name={'passwordCheck'} maxLength={30}*/}
              {/*                            placeholder={t('비밀번호를 한번 더 입력해주세요.')}*/}
              {/*                            value={companyModify.passwordCheck} onChange={onEdit} width={'520px'} onKeyPress={onPwCheckEnter}/>*/}
              {/*            <MainButtons buttonType={'cta'} name={'확인'} marginDis={true}*/}
              {/*                         clickEvent={()=>PasswordCheck(companyModify,setPwCheckState,setPwCheckNormal)} width={'72px'}/>*/}
              {/*        </div>*/}
              {/*        <div className={'formText'} style={{marginTop: '8px', color: `${pwCheckState.color}`}}>{t(pwCheckState.text)}</div>*/}
              {/*    </div>*/}
              {/*</div>*/}
              <div style={{display: 'flex'}}>
                <div className={'indexBox'}>{t('업체코드')}</div>
                <div className={'inputWrap'}
                     style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center' , paddingLeft: '16px'}}>
                  <div style={{display: 'flex' , fontWeight: '400' , fontSize: '14px'}}>
                    {companyModify.companyCode}
                  </div>
                </div>
              </div>
              {femsInfo &&
                  <div style={{display: 'flex'}}>
                    <div className={'indexBox'}>{t('F-EMS 고객정보')}</div>
                    <div className={'inputWrap'}>
                      <div style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between'}}>
                        <div style={{
                          display: 'flex' , alignItems: 'center' ,
                          // pointerEvents: !companyModify.custNo? 'auto' : 'none'
                        }}
                             onClick={() => setDataModal({menu: 'search'})}>
                          <div style={{...femsInfoStyle , whiteSpace: 'nowrap'}}>{t('고객번호')}</div>
                          <InputComponent type="text" name={'custNo'} value={companyModify.custNo}
                                          disabled={true} style={{cursor: 'pointer'}}
                                          onChange={onEdit} width={'416px'} placeholder={'0000000000'} maxLength={10}/>
                          <MainButtons name={'고객번호 검색'} buttonType={'cta'} width={'112px'}
                            // disabled={companyModify.custNo}
                                       margin={'0 0 0 8px'} clickEvent={() => setDataModal({menu: 'search'})}/>
                        </div>
                      </div>
                    </div>
                  </div>
              }
              <div style={{display: 'flex'}}>
                <div className={'indexBox'} style={{height: '112px'}}> {t('비고')}</div>
                <div className={'inputWrap'} style={{height: '112px'}}>
                  <InputTextArea name={'notice'} value={companyModify.notice} placeholder={t('내용을 입력해 주세요.')}
                                 onChange={onEdit}/>
                </div>
              </div>
            </div>
          </InputContainer>
        </div>
        <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 30px'}}/>
        <div style={{width: '100%'}}>
          <div style={{display: 'flex' , flexDirection: 'column'}}>
            <div>
              <div
                style={{height: '34px' , display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , marginBottom: '20px'}}>
                <MenuTitle title={'프로필 수정'} unitCase={'MenuCompTitle'}/>
                <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                  <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                </div>
              </div>
              <SingleImageUploadBox imageKey={(data) => setImageState(data)}
                                    receiveKey={companyModify?.image} receiveUrl={companyModify?.imageUrl}
                                    inputSize={168} profileSelect={false}/>
            </div>
            <div style={{marginTop: '80px'}}>
              <div
                style={{height: '34px' , display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , marginBottom: '20px'}}>
                <MenuTitle title={'로고 수정'} unitCase={'MenuCompTitle'}/>
                <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                  <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                </div>
              </div>
              <SingleImageUploadBox imageKey={(data) => setLogoState(data)}
                                    receiveKey={companyModify?.logoImage} receiveUrl={companyModify?.logoImageUrl}
                                    inputSize={168} profileSelect={false}/>

            </div>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  )
}
export default BasicModify;
const InputContainer = styled.div`
  user-select: none;
  width: 784px;
  height: 620px;
  display: grid;
  grid-template-columns: 168px 568px;

  .scroll_func {
    width: 810px;
    height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: block;
    }
  }

  .formText {
    display: grid;
    font-size: 12px;
    color: rgba(23, 23, 37, 0.5);
    white-space: nowrap;
    font-weight: 400;
    margin-left: 8px;
  }

  .indexBox {
    display: flex;
    align-items: center;
    width: 168px;
    background-color: #F5F6FA;
    box-sizing: border-box;
    //height:80px;
    padding-left: 16px;
    //padding: 10px 16px;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 1px solid #e7e9ee;
  }

  .inputWrap {
    width: 616px;
    border-bottom: 1px solid #e7e9ee;
    min-height: 48px;
    box-sizing: border-box;
    padding: 8px 0 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .input_tel {
    width: 600px;
    height: 32px;
    padding: 0 8px;

    border: 1px solid #D7DBEC;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    font-size: 14px;
    color: #171725;
    font-weight: 400;
    font-family: 'NanumGothicOTF', sans-serif;

    &::placeholder {
      font-size: 14px;
      font-weight: 600;
      color: rgba(23, 23, 37, 0.3);
      font-family: 'NanumGothicOTF', sans-serif;
    }
  }

  .solution_box {
    font-weight: 900;
    font-size: 12px;
    height: 80px;
    background-color: #F5F6FA;
    display: flex;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #e7e9ee;
  }

  .check_box {
    width: 616px;
    box-sizing: border-box;
    font-size: 12px;
    font-family: 'NanumGothicOTF', sans-serif;
    font-weight: normal;
    color: #171725;
    display: grid;
    grid-template-columns:repeat(6, 90px);
    grid-template-rows: repeat(2, 30px);
  }
}

input[type='checkbox'] {
  appearance: none;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #7E84A3;
}

input[type='checkbox']:checked {
  background-color: #0058FF;
  background-image: url(${checked});
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}
`

interface inputProps {
  height?: string;
  width?: string;
}

const InputComponent = styled.input<inputProps>`
  width: ${props => props.width ? props.width : '600px'};
  height: ${props => props.height ? props.height : '32px'};
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  color: #171725;
  padding: 0 8px;
  font-family: 'NanumGothicOTF', sans-serif;
}

&::placeholder {
  font-size: 14px;
  font-weight: 600;
  color: rgba(23, 23, 37, 0.3);
  font-family: 'NanumGothicOTF', sans-serif;
}
`
const InputTextArea = styled.textarea`
  width: 600px;
  height: 100px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d7dbec;
  font-family: 'NanumGothicOTF', sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 15px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D5D7E3;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }

  &::-webkit-scrollbar-track {
    width: 21px;
    background: transparent;
  }

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: 600;
    font-family: "NanumGothicOTF", sans-serif;
  }

  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 15px;
    display: flex;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`
