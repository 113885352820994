import React from 'react';
import styled from 'styled-components';

interface Props{
  limit: number;
  page: number;
  setPage: Function;
  blockNum: number;
  setBlockNum: Function;
  counts: number;
  pageLimit: number
  justify?: string
  disabled?: boolean
  style? :React.CSSProperties|undefined
  buttonSize?: number
  buttonGap?: number
  mobileType?:boolean
  anchorEvent?:()=>void
}

const  PaginationList = ({limit,page,setPage,blockNum,setBlockNum,counts,pageLimit, justify,disabled, style, buttonSize, buttonGap,mobileType,anchorEvent}:Props) => {

  //새로운 배열을 만들기 위한 함수
  const createArr = (n: number) => {
    const iArr: number[] = new Array(n);
    for (let i = 0; i < n; i++) iArr[i] = i + 1;
    return iArr;
  }
  const totalPage: number = Math.ceil(((counts === undefined ? 1 : counts) * limit) / limit);  //보여줄 페이지네이션 갯수
  const blockArea: number = Number(blockNum * pageLimit)//화면전환시 페이지네이션 구역을지정
  const nArr = createArr(Number(totalPage === 0 ? Math.abs(1) : totalPage)); //전체페이지 갯수
  let pArr = nArr?.slice(blockArea, Number(pageLimit) + blockArea); // nArr을 잘라서 원하는 페이지네이션만보여주게작성

  const firstPage = () => {
    setPage(1);
    setBlockNum(0);
  }

  const lastPage = () => {
    setPage(totalPage);
    setBlockNum(Math.ceil(totalPage / pageLimit) - 1);
  }

  const prevPage = () => {
    if (page <= 1) {
      return;
    } // page가 1보다 작거나 같으면 아무 것도 리턴하지 않는다.
    if (page - pageLimit <= pageLimit * blockNum) {
      setBlockNum((n: number) => n - 1);
    } // 현재 페이지 - 1 이 보여줄 페이지네이션 개수(pageLimit) * blockNum 보다 작거나 같으면 setBlockNum에 - 1 을 작동시킨다.
    setPage((n: number) => pageLimit * blockNum); //setPage를 현재 페이지에서 -1 로 이동시킨다.
  };
  // console.log(page - pageLimit)
  // console.log(pageLimit * (blockNum+1))
  // console.log(page)

  const nextPage = () =>{
    if(page >= totalPage){
      setPage((n:number) => n + pageLimit)
    }
    if(pageLimit * Number(blockNum + 1) < Number(page + pageLimit)){
      setBlockNum((n:number) => n + 1 );
      setPage((n:number) => 1 + pageLimit * (blockNum + 1))
    }
    if(page >totalPage-pageLimit){
      setPage(totalPage)
      setPage((n:number) => totalPage)
    }
  }

  return (
    <React.Fragment>
      {mobileType ?
        <div style={{display:"grid",gridTemplateColumns:'1fr 1fr',margin:'16px 0',gridGap:'24px'}}>
          <LineButton onClick={()=> {prevPage();
            if( anchorEvent ){
              anchorEvent()
            }} }
                      style={{opacity:`${page <= pageLimit ? '0.3':'1'}`, pointerEvents:`${page <= pageLimit? 'none' : 'auto'}`}}>
            {'<'}
          </LineButton>
          <LineButton onClick={()=>{nextPage();
            if( anchorEvent ){
              anchorEvent()
            }}}
                      style={{opacity:`${pArr[0] > totalPage-pageLimit ? '0.3':'1'}`, pointerEvents:`${pArr[0] > totalPage-pageLimit? 'none' : 'auto'}`}}>
            {'>'}
          </LineButton>
        </div>
        :
        <Container buttonGap={buttonGap} buttonSize={buttonSize} style={{
          justifyContent:`${justify}`,opacity:`${disabled  ? '0.3' : '1'}`,
          pointerEvents:`${disabled ? 'none' : 'auto'}`,...style}}>
          <button className={'move_firstPage'} onClick={()=>{firstPage()}}
                  style={{opacity:`${blockNum === 0 ? '0.3' : '1'}`, pointerEvents:`${blockNum === 0? 'none' : 'auto'}`}}>
            {'<<'}
          </button>
          <button className={'move_previous'} onClick={()=>(prevPage())}
                  style={{opacity:`${page <= pageLimit ? '0.3':'1'}`, pointerEvents:`${page <= pageLimit? 'none' : 'auto'}`}}
                  disabled={page <= pageLimit}>
            {'<'}
          </button>
          <div>
            {pArr.map((v:number)=>(
              <button className={'page_button'} key={v} onClick={()=>(setPage(v))}
                      aria-current={page === v  ?  'page' : undefined}>
                {v}
              </button>
            ))}
          </div>
          <button className={'move_next'} onClick={()=>{nextPage()}} style={{opacity:`${pArr[0] > totalPage-pageLimit ? '0.3':'1'}`,
            pointerEvents:`${pArr[0] > totalPage-pageLimit? 'none' : 'auto'}`}} disabled={pArr[0] > totalPage-pageLimit}>
            {'>'}
          </button>
          <button className={'move_lastPage'} onClick={()=>{lastPage()}} style={{ opacity:`${pArr[0] > totalPage-pageLimit ? '0.3':'1'}`,
            pointerEvents:`${pArr[0] > totalPage-pageLimit? 'none' : 'auto'}`}}>
            {'>>'}
          </button>
        </Container>
      }
    </React.Fragment>


  );
};

export default PaginationList;

const Container = styled.div<{buttonSize?: number, buttonGap?: number}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 36px;
  user-select: none;
  //height: 150px;

  .move {
    &_firstPage, &_lastPage {
      width: ${props => props.buttonSize ?? 36}px;
      height: ${props => props.buttonSize ?? 36}px;
      border: none;
      font-weight: bold;
      background-color: transparent;
      cursor: pointer;
      color: #7E84A3;
      border-radius: 4px;
      font-family: "Nanum Gothic";
      transition: all 300ms;
      
      &:hover {
        background-color: #0058Ff;
        color: #fff;
        cursor: pointer;
      }
    }

    &_next, &_previous {
      width: ${props => props.buttonSize ?? 36}px;
      height: ${props => props.buttonSize ?? 36}px;
      background-color: transparent;
      border: none;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      color: #7E84A3;
      border-radius: 4px;
      font-family: "Nanum Gothic";
      transition: all 300ms;
      &:hover {
        background-color: #0058Ff;
        color: #fff;
        cursor: pointer;
      }
    }

  }

  .page_button {
    width: ${props => props.buttonSize ?? 36}px;
    height: ${props => props.buttonSize ?? 36}px;
    border: none;
    background-color: transparent;
    font-size: ${props => props.buttonSize ? 14 : 16}px;
    text-align: center;
    border-radius: 4px;
    font-family: 'Poppins';
    padding: ${props => props.buttonSize ? '0 2px' : '0px'};
    color: #7E84A3;
    transition: all 300ms;
    margin: 0 ${props => props.buttonGap ?? 0}px;
    
    &:hover {
      background-color: #0058Ff;
      color: #fff;
      cursor: pointer;
    }

    &[disbled] {
      background-color: red;
      cursor: revert;
      transform: revert;
      border-radius: 4px;
    }

    &[aria-current] {
      background-color: #0058Ff;
      border-radius: 4px;
      color: #fff;
      cursor: revert;
      transform: revert;
      opacity: 1;
    }
  }
`
const LineButton = styled.div<{toggleSelect?:boolean,widthCus?:number,heightCus?:number,disabled?:boolean}>`
  border-radius: 28px;
  min-height: ${p=>p.widthCus ?? 56}px;
  min-width: ${p=>p.heightCus ?? 152}px;
  border: 1px solid ${p=>p.toggleSelect ? '#0058FF' : '#D7DBEC'};
  background:  ${p=>p.toggleSelect ? '#0058FF' : '#FFF'};
  color:${p=>p.toggleSelect ? '#FFF' : '#7E84A3'};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  &:active {
    background-color: rgba(0, 88, 255, 0.05);
  }
`