import React from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {TransformComponent , TransformWrapper} from "react-zoom-pan-pinch";
import MainButtons from "../button/MainButtons";
import {NoData} from "../../style/StyleComponent";
import client from "../../common/Config";
import {
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "./ConfirmPopup";
import {useTranslation} from "react-i18next";

interface Props {
  authValid: boolean
  clickEvent:(data:{key: string|null|undefined, url:string|null|undefined})=>void
  cancelEvent: ()=>void
  receiveKey?: string|null
  receiveUrl?: string|null

  viewType?: boolean
  //현재 작업표준서 리스트 에서만 구분지음
  // 이미지 팝업 타이틀
  title?: string
}

const SingleListImageUploadModal:React.FunctionComponent<Props> = ({ authValid,
                                                                     receiveKey, viewType, title,
                                                                     receiveUrl,clickEvent, cancelEvent}) => {

  const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [imageData, setImageData] = React.useState<{key:string|null|undefined, url:string|null|undefined}>({key:receiveKey, url:receiveUrl})

  const inputRef = React.useRef<any>(null)

  const validTitleStyle = {
    backgroundColor : authValid? '#CFFA61' : '#0058ff',
    color : authValid? '#171725' : '#fff',
    fontWeight : 700,
    lineHeight:'56px',
    fontSize:'18px',
    paddingLeft:'16px'
  }

  const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data: any) => {
    e.preventDefault()
    dataAccessLoading()
    const formData = new FormData()
    formData.append('file',data)
    try {
      const response = await client.post('/file/upload',
                                         formData
                                         // ,{headers:{ "Content-Type": "multipart/form-data" }}
      )
      const data = response.data.data

      setImageData({key: data.imgKey, url: data.imgUrl})
      inputRef.current.value = ""

      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const extendReg = /(.*?)\.(jpg|jpeg|png)$/

  const {t}:any = useTranslation()

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {dataModal.menu === 'confirm' &&
              <ConfirmPopup statusText={dataModal.message}
                            clickEvent={()=> {
                              if(dataModal.message?.includes('등록')){
                                window.location.reload()
                              }
                              if(dataModal.message?.includes('삭제')){
                                window.location.reload()
                              }
                              setDataModal(initModal)}}/>}


          <BaseComponent>
            <div style={{...validTitleStyle, boxSizing:'border-box',}}
                 className={'modal_title'}>{dataValid(t(title), t('이미지 보기'))}</div>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', backgroundColor:'#fff', height:'672px',boxSizing:'border-box', padding:'16px 16px 24px'}}>
              <div>
                {!imageData.url?
                <div style={{display:'flex', flexDirection:'column', justifyContent:'center', margin:'0 auto',
                  border:'1px solid #d5d7e3', borderRadius:'6px',
                  width:'1000px', height:'542px', alignItems:'center'}}>
                  <NoData height={60}>{t('* 이미지를 등록해 주세요.')}</NoData>
                  <label className={'file_name'}>
                    {t('이미지 등록')}
                    <input type="file" style={{display:'none'}} ref={inputRef}
                           onChange={
                             (e:any)=>{
                               if(inputRef.current.value.match(extendReg)){
                                 if(e.target.files[0].size < 10*1024*1024){
                                   fileUpload(e, e.target.files[0]).then()
                                 }else{
                                   setDataModal({menu:'confirm', message: '10MB 이하의 파일을 업로드 해주세요.'})
                                   inputRef.current.value = ""
                                 }
                               }else{
                                 setDataModal({menu:'confirm', message: 'jpg, png 파일을 업로드 해주세요.'})
                               }
                             }}
                    />
                  </label>
                </div>

                :

                  <div style={{position:'relative'}}>
                    {!viewType && <div className={'x-mark_button'} onClick={() => setImageData( {key: '' , url: ''} )}>{'X'}</div>}
                    <TransformWrapper>
                      <TransformComponent wrapperStyle={{border:'1px solid #d5d7e3', borderRadius:'6px'}}>
                          <img className={'img_select'} src={`${imageData.url}`} width={1000} height={542}
                               style={{objectFit: 'contain'}} alt={''}/>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>}
              </div>


              <div style={{display:'flex', gap:'16px', justifyContent:'center'}}>

                <MainButtons width={'114px'} name={viewType? '확인' : '취소'}
                             clickEvent={cancelEvent}/>
                {!viewType &&
                <MainButtons buttonType={'popupCta'} width={'114px'} name={'확인'}
                  // disabled={dataValid( imageKey?.length , 0 ) === 0 }
                             clickEvent={()=>{
                               clickEvent(imageData);
                               cancelEvent()
                             }}/>
                }
              </div>

            </div>
          </BaseComponent>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default SingleListImageUploadModal;

const BaseComponent = styled.div`
  width: 1032px;
  height: 728px;
  border-radius: 6px;
  overflow: hidden;
  
  .x-mark_button{
    position: absolute;
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 6px;
    border: 1px solid #d5d7e3;
    background-color: #fff;
    top: 16px;
    right: 16px;
    cursor: pointer;
    align-items: center;
    text-align: center;
    z-index: 1000;
    
    &:hover{
      background-color: #d5d7e3;
    }
  }
  

  .file_name{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 114px;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    border-radius: 6px;
    height: 32px;
    color: #5a607f;
    border: 1px solid #d7dbec;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
      background-color: #5a607f;
      color: #fff;
    }
  }
`