import React , {useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import ControlButton from "../button/ControlButton";
import noImage2 from '../../asset/image/noImage.svg'
import {TransformWrapper,TransformComponent} from "react-zoom-pan-pinch";
import camera from '../../asset/image/mobile_camera_button.svg'
import {initModal , loadingRemove , uploadingLoading} from "../../function/function";
import client from "../../common/Config";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "./ConfirmPopup";
import {useTranslation} from "react-i18next";
import MainButtons from "../button/MainButtons";
interface Props {
	image: string
	closeMenu: ()=>void
	mobileView?:boolean
	title?:string
	itemName?:string
	imgData?: {imgKey:string, imgUrl:string}
	outImgData?:(data:{imgKey:string, imgUrl:string})=>void
	disableCamera?:boolean
	nextImage?:()=>void
	prevImage?:()=>void
	nextDisabled?:boolean
	prevDisabled?:boolean
	buttonDisabled?:boolean
	imgDownLoadType?:boolean
	downloadEvent?:()=>void
	nameView?:boolean
}

const DetailImageView:React.FunctionComponent<Props> = ({image, nameView,closeMenu,mobileView,title,disableCamera,nextImage,prevImage,nextDisabled,prevDisabled,
																													itemName, imgData, outImgData,imgDownLoadType,downloadEvent}) => {
	const [noImageState, setNoImageState] = React.useState<boolean>(false)
	const noImage = noImage2
	const uploadRef = React.useRef<any>(null)
	const [imgState, setImgState] = useState<any>(!imgData?.imgUrl? {imgKey:'', imgUrl:''} : imgData)

	const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
	const { t }:any = useTranslation();
	const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data:any) => {
		e.preventDefault()
		uploadingLoading('업로드 중 입니다...')
		const formData = new FormData()
		formData.append('file',data)
		try {
			const response = await client.post('/file/upload', formData )
			const data = response.data.data
			setImgState(data)
			outImgData && outImgData(data)
		}catch (e) {
			console.log(e)
		}
		loadingRemove()
	}

	React.useEffect(()=>{
		return setImgState( imgData );
	},[imgData])

	const extendReg = /(.*?)\.(jpg|jpeg|png)$/
	return (
		<React.Fragment>
			{dataModal.menu === 'upload' && <ConfirmPopup statusText={dataModal.message}
																										clickEvent={()=>setDataModal(initModal)}/>}
			{mobileView ?
				<MobileImageBase>
					 <MobileInnerImage>
						 <div className={'title_img'}>
							 <div style={{marginLeft:'24px'}}>{title}</div>
							 <div style={{color:'#7E84A3'}}>{itemName}</div>
						 </div>
						 {noImageState ?
							 noImage :
							 <div style={{position:'relative'}}>
								 {!disableCamera &&
									 <label htmlFor={'detail_modal_img_up'}
													style={{display:'flex', flexDirection:'column',justifyContent:'center', alignItems:'center',
														width:'72px', height:'72px', borderRadius:'16px', backgroundColor:'rgba(255,255,255,0.7)',
														position:'absolute', top:'1px', right:'2px', zIndex:60, border:'1px solid #D7DBEC'}}>
										 <input type="file" style={{display:'none'}} id={'detail_modal_img_up'} ref={uploadRef} accept={'image/*'}
														onChange={(e:any)=> {
															if(uploadRef.current.value.match(extendReg)){
																if(e.target.files[0].size < 10*1024*1024){
																	fileUpload(e, e.target.files[0]).then()
																}else{
																	setDataModal({menu:'upload', message: t('10MB 이하의 파일을 업로드 해주세요.')})
																	uploadRef.current.value = ""
																}
															}else{
																setDataModal({menu:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})
															}
														}}
										 />
										 <img src={camera} width={24} height={24} alt=""/>
										 <div style={{color:'#7E84A3', fontSize:'12px', fontWeight:700, marginTop:'8px'}}>{'재등록'}</div>
									 </label>
								 }
								 <div style={{display:'grid',gridTemplateColumns:'16px calc(100vw - 86px) 16px'}}>
									 <div onClick={nextImage} style={{opacity:prevDisabled ? 0 : 1,pointerEvents:prevDisabled ? 'none':'auto',alignSelf:'center',fontSize:'18px',fontWeight:'bold',color:'#7E84A3'}}>{'<'}</div>
									 <div>
										 <TransformWrapper>
											 <TransformComponent>
												 <img className={'img_select'} src={imgState.imgUrl}
															style={{objectFit:'contain', height:'350px',width:'calc(100vw - 86px)'}} alt={''}
															onError={()=>setNoImageState(true)}/>
											 </TransformComponent>
										 </TransformWrapper>
									 </div>
									 <div onClick={nextImage} style={{opacity:nextDisabled ? 0 : 1,pointerEvents:nextDisabled ? 'none':'auto',alignSelf:'center',fontSize:'18px',fontWeight:'bold',color:'#7E84A3'}}>{'>'}</div>
								 </div>
							 </div>
						 }
						 <div className={'title_btn'} onClick={closeMenu}>{'확인'}</div>
					 </MobileInnerImage>
				</MobileImageBase>
				:
				<ModalBase modalContent={
					<ViewImageBase>
						<div style={{display:imgDownLoadType ? 'flex' : 'none',alignSelf:'end'}}>
							<MainButtons name={'다운로드'} buttonType={'cta'} margin={'0 76px 0 0'} clickEvent={downloadEvent}/>
						</div>
						<div style={{display:nameView ? 'flex' : 'none'}}>
							<div style={{fontWeight:'bold'}}>{title}</div>
							<div style={{color:'#0058FF',marginLeft:'16px'}}>{itemName}</div>
						</div>
						{noImageState ?
							noImage :
							<div style={{border:'1px solid #d5d7e3',cursor:'move'}}>
								<TransformWrapper>
									<TransformComponent>
										<img className={'img_select'} src={image} width={550} height={520}
												 style={{objectFit:'contain'}} alt={''}
												 onError={()=>setNoImageState(true)}/>
									</TransformComponent>
								</TransformWrapper>
							</div>
						}
						<ControlButton clickEvent={closeMenu} name={'CLOSE'}/>
					</ViewImageBase>
				}/>
			}
		</React.Fragment>

	);
};

export default DetailImageView;

const ViewImageBase = styled.div`
  width: 700px;
  height: 700px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 0;
`

const MobileImageBase = styled.div`
	min-width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1500;
	background-color: rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 16px;
`
const MobileInnerImage = styled.div`
	min-width: 288px;
	height: 448px;
	background-color: #fff;
	box-shadow: 0 3px 6px #00000029;
	border-radius: 16px;
	opacity: 1;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	.title_img{
		min-width: 100%;
		min-height: 48px;
		display: grid;
		grid-template-columns: 88px 1fr;
		font-size: 14px;
		font-weight: bold;
		border-bottom: 1px solid #D7DBEC;
		align-items: center;
	}
	.title_btn{
		min-width: 100%;
		min-height: 48px;
		font-size: 14px;
		font-weight: bold;
		border-top: 1px solid #D7DBEC;
		color: #7E84A3;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`


























