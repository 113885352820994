import React , {useState, useRef} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import Draggable from "react-draggable";
import {
  autoTextValueValidation ,
  GridTableComponent , GridTableSelect , helperModule ,
  modalTitleBase ,
  NoData , tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import DropMenu from "../sideMenu/DropMenu";
import {
  dataValid ,
  initModal , isTouchDevice ,
  loadingRemove ,
  modalListLoading , numberFormatScale ,
  numberLocaleString
} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import ListInnerInput from "../input/ListInnerInput";
import MainButtons from "../button/MainButtons";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import ConfirmPopup from "./ConfirmPopup";
import {Modal_View_Type} from "../../types/Fundamental";
import SearchBar from "../input/SearchBar";
import {useTranslation} from "react-i18next";
import ToastPopup from "./ToastPopup";
import SelectConfirmModal from "./SelectConfirmModal";
import PaginationList from '../pagination/PaginationList'
import checked from "../../asset/image/check-icon.svg";
import FilterWithSearchBar from "../input/FilterWithSearchBar";
interface Modal_Props {
  authValid: boolean
  receiveData?: (data:any)=>void
  closeEvent:()=>void
  viewType?: boolean
  menuArrayType?: string
  filterArray?:string[]
  firstTypes?: string
  mainProductCode?: string
  selectedCode?: string
  subBomReg?: boolean
  receiveBomList?: (data:any[], product:any)=>void
  selectProductObj?:any
  prevSearchValue?:string
}

const IntegratedSearchModal:React.FunctionComponent<Modal_Props> = ({
                                                                      authValid,
                                                                      receiveData,prevSearchValue,
                                                                      viewType,selectProductObj,
                                                                      closeEvent, menuArrayType, selectedCode,
                                                                      filterArray, firstTypes, mainProductCode, subBomReg,receiveBomList}) => {
  const {t}:any = useTranslation()
  const modalRef = useRef(null);
  const [categorySelect, setCategorySelect] = useState<string>(dataValid(firstTypes,t('품목')))
  const [categoryTypeSelect , setCategoryTypeSelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const [page, setPage] = useState<number>(1)
  const [pageBlock, setPageBlock] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [integratedList, setIntegratedList] = useState<any>([])
  const [selectData, setSelectData] = useState<any>(selectProductObj ?? {})
  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [searchState, setSearchState] = useState<{input: string, value: string}>({input:prevSearchValue ?? '', value:prevSearchValue ?? ''})
  const [bomListData, ] = useState<any[]>([])

  const [totalBomReg, setTotalBomReg] = useState<boolean>(false)

  const selectedCompany = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)

  const categoryTranslate = (category: string) => {
    switch (category){
      case t('품목') : return 'product'
      case t('원자재') : return 'rawMaterial'
      case t('부자재') : return 'subMaterial'
      case t('금형') : return 'mold'
    }
  }

  // 구분 지어서 보여 줘야 할 카테고리 가 있다면 여기에 추가 해주세요
  const menuArrayTypes = (type: string) => {
    switch (type) {
      default : return [t('품목'),t('원자재'),t('부자재'),t('금형')]
      case 'noMold' : return [t('품목'),t('원자재'),t('부자재')]
      case 'onlyProduct' : return [t('품목')]
      case 'onlyRawMaterial' : return [t('원자재')]
      case 'onlySubMaterial' : return [t('부자재')]
      case 'onlyMold' : return [t('금형')]
    }
  }

  const byCategorySelectArrayFunc = {
    categoryValueArray(category:string){
      switch (category){
        case t('품목') : return ['전체','거래처','모델','CODE','품명']
        case t('원자재') : return ['전체','원자재 CODE','원자재 품명','재질','재질 종류','거래처']
        case t('부자재') : return ['전체','부자재 CODE','부자재 품명','거래처']
        case t('금형') : return ['전체','금형 CODE','금형명']
      }
    },
    categoryTypeArray(category:string){
      switch (category){
        case t('품목') : return ['@','customer_name','customer_model_name','serial_code','product_name']
        case t('원자재') : return ['@','raw_material_serial_code','raw_material_name','material','material_type','customer_name']
        case t('부자재') : return ['@','sub_material_serial_code','sub_material_name','customer_name']
        case t('금형') : return ['@','mold_serial_code','mold_name']
      }
    }
  }

  // const filterData = integratedList.filter((v:any)=>!duplicateArray?.includes((v.productCode || v.rawMaterialCode || v.subMaterialCode)))
  const customerCount = (customer:any) => customer.filter((v:any,i:number,arr:any)=> arr.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)

  const searchTableComponent = (category: string) =>{
    switch (category){
      //품목 모달창
      case t("품목") : return(
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'144px 184px 184px 184px 88px 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['거래처','모델','CODE(품번)','품명','품목 종류','단위'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.productCode}
                               selected={selectData?.productCode === v.productCode}
                // onClick={()=>{bomListRes(v).then()}}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.productCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 품목 입니다.'})
                                   }
                                   else if(mainProductCode === v.productCode){
                                     setDataModal({menu:'confirm', message:'BOM을 등록 하기 위해 선택된 품목 입니다.'})
                                   }
                                   else{
                                     if(subBomReg){
                                       setDataModal({menu:'bomSelect', message:'하위 BOM으로 등록된 제품도 \n 작업지시서를 등록 하시겠습니까?'})
                                     }else{
                                       if(receiveData){
                                         receiveData(v)
                                         closeEvent()
                                       }
                                     }
                                   }
                                 }else{
                                   if(subBomReg){
                                     // setDataModal({menu:'totalInventory'})
                                     bomListRes(v).then()
                                   }else{
                                     if(receiveData){
                                       receiveData(v)
                                       closeEvent()
                                     }
                                   }
                                 }
                               }} onMouseDown={()=>{setSelectData(v)}}>
                {(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :
                  tooltipModule(customerCount(v.customer).map((v:any)=>(v.customerName)).join(','),0,validationValue(customerCount(v.customer)[0].customerName + ((customerCount(v.customer).length -1) !== 0 ?` 외 ${customerCount(v.customer).length-1}` : '')))}
                {(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :
                  tooltipModule(v.customer.map((v:any)=>(v.customerModelName)).join(','),0,validationValue(v.customer[0].customerModelName + ((v.customer.length -1) !== 0 ?` 외 ${v.customer.length-1}` : '')))}
                {autoTextValueValidation({value:v.serialCode})}
                {autoTextValueValidation({value:v.productName})}
                {validationValue(v.type)}
                {validationValue(v.unit, 'ea')}
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
      //원자재 검색 모달창
      case t("원자재") : return (
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'188px 120px 120px repeat(3, 120px) 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['원자재 CODE','원자재 품명','재질','두께','가로(Coil 폭)','세로(Feeder)','재질 종류'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.rawMaterialCode}
                               selected={selectData?.rawMaterialCode === v.rawMaterialCode}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.rawMaterialCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 원자재 입니다.'})
                                   }else{
                                     if(receiveData){
                                       receiveData({...v, type:'원자재'})
                                       closeEvent()
                                     }}
                                 }else{
                                   if(receiveData){
                                     receiveData({...v, type:'원자재'})
                                     closeEvent()
                                   }}
                               }} onMouseDown={()=>{setSelectData( {...v, type:'원자재'})}}>
                {autoTextValueValidation({value:v.rawMaterialSerialCode})}
                {autoTextValueValidation({value:v.rawMaterialName})}
                {autoTextValueValidation({value:v.material})}
                {<ListInnerInput type={'noneInputUnit'} inputData={numberFormatScale(v.thickness)} unit={'T'}/>}
                {<ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.width)} unit={'mm'}/>}
                {<ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.length)} unit={'mm'}/>}
                {validationValue(v.materialType)}
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
      //부자재 검색 모달창
      case t("부자재") : return (
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'180px 120px 88px 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['부자재 CODE','부자재 품명','단위','거래처'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.subMaterialCode}
                               selected={selectData?.subMaterialCode === v.subMaterialCode}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.subMaterialCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 부자재 입니다.'})
                                   }else{
                                     if(receiveData){
                                       receiveData({...v, type:'부자재'})
                                       closeEvent()
                                     }}
                                 }else{
                                   if(receiveData){
                                     receiveData({...v, type:'부자재'})
                                     closeEvent()
                                   }}
                               }} onMouseDown={()=>{setSelectData({...v, type:'부자재'})}}>
                {autoTextValueValidation({value:v.subMaterialSerialCode})}
                {autoTextValueValidation({value:v.subMaterialName})}
                {validationValue(v.subMaterialUnit)}
                {autoTextValueValidation({value:v.customer})}
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
      //금형 검색
      case t("금형") : return(
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'96px 96px repeat(6, 140px)'}
                            height={298} scrollWidth={1161}
                            scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['금형 CODE','금형명','캐비티','SPM','슬라이드 위치','점검 타수','점검 주기','현재타수'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.moldCode}
                               selected={v.moldCode === (!selectData?.moldCode? selectedCode : selectData?.moldCode)}
                               onDoubleClick={()=>{
                                 if(receiveData){
                                   receiveData(v)
                                   closeEvent()
                                 }}}
                               onMouseDown={()=>{setSelectData(v)}}>
                {autoTextValueValidation({value:v.moldSerialCode})}
                {autoTextValueValidation({value:v.moldName})}
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.cavity)} unit={'ea'}/>
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.spm)} unit={'ea'}/>
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.slideCounter)} unit={'mm'}/>
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.checkStrokes)} unit={'타'}/>
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.checkCycle)} unit={'일'}/>
                <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(v.currentStrokes)} unit={'타'}/>
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
    }
  }

  const integratedListData = async () => {
    setLoadingMessage('목록을 불러오는 중 입니다.')
    const categoryMessage = (category: string) => {
      switch (category) {
        case t('품목') : return '품목이'
        case t('원자재') : return '원자재가'
        case t('부자재') : return '부자재가'
        case t('금형') : return '금형이'
      }
    }
    try {
      modalListLoading()
      const response = await client.get(`/${categoryTranslate(categorySelect)}/list/${page}/5/${selectedCompany.companyCode}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setIntegratedList((prev:any)=> prev.concat(data.row))
      // }else{
      //   setIntegratedList(data.row)
      // }
      setIntegratedList(data.row)
      loadingRemove()
      setLoadingMessage(t(`등록된 ${categoryMessage(categorySelect)} 없습니다.`))
    }catch (e) {
      console.log(e)
      loadingRemove()
      setLoadingMessage('서버 에러 입니다. 관리자에게 문의해 주세요.')
    }
  }

  const integratedSearchData = async () => {
    setLoadingMessage('목록을 불러오는 중 입니다.')
    try {
      modalListLoading()
      const response = await client.post(`/${categoryTranslate(categorySelect)}/search`,{
        //  /${page}/${10}/${selectedCompany.companyCode}/${searchState.value}
        companyCode:selectedCompany.companyCode,
        keyword:searchState.value,
        page:page,
        size: 5,
        filter:categoryTypeSelect.type
      },{
                                           headers:{Authorization}
                                         })
      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setIntegratedList((prev:any)=> prev.concat(data.row))
      // }else{
      //   setIntegratedList(data.row)
      // }
      setIntegratedList(data.row)
      loadingRemove()
      setLoadingMessage(`${categorySelect} 검색 결과가 없습니다.`)
    }catch (e) {
      console.log(e)
      loadingRemove()
      setLoadingMessage('서버 에러 입니다. 관리자에게 문의해 주세요.')
    }
  }

  React.useEffect(()=>{
    if(!searchState.value){
      integratedListData().then()
    }else{
      integratedSearchData().then()
    }
  },[categorySelect, page, searchState.value,categoryTypeSelect.type])

  const bomListRes = async (product:any) => {
    try {
      const response = await client.post('/product/bom/list',{
        productCode: product.productCode
      },{headers:{Authorization}})

      const data = response.data.data
      const filterBomData = data.row?.filter((v:any)=>(v.productSubCode.includes('product')))
      // const alarmData = data.row?.filter((v:any)=>(v.totalInventory <= 0))
      //
      // setAlarmBomList(alarmData)
      if(filterBomData.length !== 0){
        if(totalBomReg){
          receiveBomList && receiveBomList( filterBomData, selectData )
        }
        else{
          receiveData && receiveData(selectData)
        }
        closeEvent()
        // setDataModal({menu:'bomSelect', message:'하위 BOM으로 등록된 품목도 \n 작업지시서를 등록 하시겠습니까?'})
        // setBomListData(filterBomData)

      }else{
        receiveData && receiveData(product)
        // if(alarmData.length === 0){
        closeEvent()
        // }
      }
    }catch ( e ){
      setDataModal({menu:'bomError', message:'서버 에러입니다. \n 하위 BOM목록을 가져올 수 없습니다.'})
      console.log(e)
    }
  }
  // const caseBomList = alarmBomList?.filter((v)=>(v.totalInventory <= 0)).map((el)=> ({code: el.productName, case:el.productSubCode.includes('product') ? '해당 품목의 재고량이 없습니다.' : el.productSubCode.includes('rawMaterial') ? "해당 원자재의 재고량이 없습니다." : "해당 부자재의 재고량이 없습니다."}))

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal(initModal)}}/>
            : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent= {()=> {setDataModal(initModal)}}/> : null}

          {dataModal.menu === 'confirm' &&
            <ConfirmPopup clickEvent={()=>setDataModal(initModal)} statusText={dataModal.message}/>}

          {/*{dataModal.menu === 'totalInventory' &&*/}
          {/*    <DeleteCaseModal workOrder={true} targetName={'품목명'} caseArray={caseBomList}*/}
          {/*                     closeEvent={closeEvent}/>}*/}


          {dataModal.menu === 'bomSelect' &&
            <SelectConfirmModal thirdText={'취소'} thirdEvent={()=>setDataModal(initModal)}
              clickEvent={()=> {
                receiveBomList && receiveBomList( bomListData, selectData )
                // if(caseBomList.length !== 0){
                //   setDataModal({menu:'totalInventory'})
                // }else{
                closeEvent()
                // }
              }}
              cancelEvent={()=>{
                // setDataModal(initModal)
                receiveData && receiveData(selectData)
                closeEvent()
              }} noticeText={'"아니오" 선택 시, 현재 선택한 품목만 작업지시서를 등록합니다.'}
              statusText={dataModal.message}/>}

          {dataModal.menu === 'bomError' &&
            <ConfirmPopup clickEvent={()=>{
              receiveData && receiveData(selectData)
              closeEvent()
            }} noticeText={'선택한 품목만 작업지시서에 등록합니다.'} statusText={dataModal.message}/>}


          <Draggable ref={modalRef} disabled={isTouchDevice}>
            <BaseComponent>
              {modalTitleBase({mainTitle:t(`${categorySelect} `) + t(' 검색')},
                              (menuArrayTypes(dataValid(menuArrayType,'')).length !== 1) &&
                                <DropMenu menuArray={menuArrayTypes(dataValid(menuArrayType,''))} style={{color:'#131523'}}
                                          selectValue={categorySelect}
                                          clickEvent={(v)=> {
                                            setPage(1)
                                            setPageBlock(0)
                                            setCategorySelect(v)
                                            if(categorySelect !== v){
                                              setIntegratedList([])
                                            }
                                          }} left={'200px'} top={'12px'}/>)}
              <div className={'modal_content'} onMouseDown={(e)=>{e.stopPropagation()}}>
                <div>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <FilterWithSearchBar widthCus={'248px'} placeholder={t(`${categorySelect}`) + t(' 검색어를 입력해 주세요.')}
                                         value={searchState.input}
                                         keyFunction={(e)=>{
                                           setSearchState((prev:{input:string, value: string})=>({...prev, input: e.target.value.trimStart()}))}}
                                         clickEvent={()=>{
                                           setPage(1)
                                           setPageBlock(0)
                                           setSelectData({})
                                           setSearchState((prev:{input:string, value: string})=>({...prev, value: prev.input}))}}
                                         margin={'0'}
                                         selectKeyword={categoryTypeSelect.value}
                                         categorySelectEvent={(value,type)=>{setCategoryTypeSelect({value:value,type:type})}}
                                         categoryTypeArray={byCategorySelectArrayFunc.categoryTypeArray(categorySelect)}
                                         categoryValueArray={byCategorySelectArrayFunc.categoryValueArray(categorySelect)}

                    />

                    {subBomReg && helperModule('하위 BOM에 품목이 포함 되어 있는 경우 \n 함께 작업지시서 등록이 가능합니다.', '210px', '0 8px')}
                    {
                      subBomReg &&
                        <label style={{display:'flex', alignItems:'center'}}>
                          <input className={'sub-checkbox'}
                                 checked={totalBomReg}
                                 onChange={(e)=>{
                                   if(e.target.checked){
                                     setTotalBomReg(true)
                                   }
                                   else{
                                     setTotalBomReg(false)
                                   }
                                 }}
                                 type="checkbox" />
                          <div style={{fontSize:'12px', color:'#171725', fontWeight:400, marginLeft:'2px'}}>{'하위BOM 작업지시서 함께 등록'}</div>
                        </label>
                    }
                  </div>
                  {searchTableComponent(categorySelect)}
                </div>
                <div style={{padding:'8px 0 16px', borderTop:'1px solid #d7dbec'}}>
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage} justify={'center'}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage}/>
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                  {viewType?
                    <MainButtons name={'확인'} width={'144px'} buttonType={'popupNormal'}
                                 clickEvent={closeEvent}/>
                    :
                    <React.Fragment>
                      <MainButtons name={'취소'} width={'144px'} buttonType={'popupNormal'} margin={'0 12px'}
                                   clickEvent={closeEvent}/>
                      <MainButtons name={'선택 완료'} width={'144px'} margin={'0 12px'}
                                   disabled={(!selectData.productCode && !selectData.rawMaterialCode && !selectData.subMaterialCode && !selectData.moldCode)}
                                   buttonType={'popupCta'}
                                   clickEvent={()=>{
                                     if(filterArray){
                                       if(filterArray?.find((code:string)=>(code === (selectData?.productCode||selectData?.rawMaterialCode||selectData?.subMaterialCode)))){
                                         setDataModal({menu:'warn', message:`이미 등록된 ${categorySelect} 입니다.`})
                                       }else{
                                         if(receiveData){
                                           receiveData(selectData)
                                           closeEvent()
                                         }}
                                     }
                                     else{
                                       if(subBomReg){
                                         // setDataModal({menu:'totalInventory'})
                                         bomListRes(selectData).then()
                                       }else{
                                         if(receiveData){
                                           receiveData(selectData)
                                           closeEvent()
                                         }
                                       }
                                     }}}/>
                    </React.Fragment>
                  }
                </div>
              </div>
            </BaseComponent>
          </Draggable>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default IntegratedSearchModal;

const BaseComponent = styled.div`
  width: 1032px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    cursor: move;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }


  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D7DBEC;
    outline: none;
    box-sizing: border-box;
    background-color: #f5f6fa;
    cursor: pointer;
    align-self: center;

    &:checked {
      border: transparent;
      background-color: #0058Ff;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  
`
