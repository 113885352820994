import styled from "styled-components";
import {GridTableProps} from "./StyleComponent";
import checked from "../asset/image/check-icon.svg";
import {Colors} from "./theme";

export const GridTableComponent = styled.div<GridTableProps>`
  overflow-x: ${props => props.scrollX_Hide ? 'hidden' : 'scroll'};
  overflow-y: ${props => props.scrollY_Hide ? 'hidden' : 'scroll'};
  height: ${props => props.height}px;
  margin-top: ${props => props.marginCus ?? 16}px;
};

&::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: block;
  background-color: ${Colors.primary.white};
}

&::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  visibility: ${props => props.visible ? 'hidden' : 'visible'};
}

&::-webkit-scrollbar-track {
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: ${Colors.primary.checkboxAreaColor};
  visibility: ${props => props.visible ? 'hidden' : 'visible'};
}

.grid-title {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1210}px;
    //gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  background-color: ${Colors.private.tableTitleColor};
  font-weight: 800;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 48;


  & > div {
    display: flex;
    height: 100%;
    box-sizing: border-box;
      //padding-left: ${props => props.gridGap ?? '16px'};
    border: 1px solid ${Colors.primary.lineGrey};
    border-left: none;
    background-color: ${Colors.private.tableTitleColor};
    justify-content: center;
  }

  .checkbox {
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.primary.checkboxAreaColor};
    z-index: 45;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid ${Colors.primary.lineGrey};
    //border-bottom: none;
    pointer-events: ${props => props.titleCheckDisabled ? 'none' : 'inherit'};
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid ${Colors.primary.lineGrey};
    border-radius: 6px;
    outline: none;
    box-sizing: border-box;
    background-color: ${props => props.titleCheckDisabled ? `${Colors.primary.checkboxAreaColor}` : `${Colors.primary.white}`};
    cursor: pointer;
    align-self: center;
    &::before{
      width: 100%;
      height: 100%;
      align-items: center;
      position: absolute;
      color: ${Colors.primary.textGrey};
    }

    &:checked {
      position: relative;
      border: transparent;
      background-color: ${Colors.private.mainColor};
      box-shadow: inset 0 2px 4px #17172550;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${checked});
    }
  }


  .sticky {
    position: sticky;
    right: 0;
    background-color: ${Colors.primary.lineGrey};
    z-index: 45;
    align-items: center;
    display: flex;
    height: 48px;
    box-sizing: border-box;
  }

}

.grid-list, .grid-row-list {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
    //gap: ${props => props.gridGap ?? 16}px;
  width: ${props => props.scrollWidth ?? 1210}px;
  height: 48px;
  font-size: 14px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 14px);
  box-sizing: border-box;
  position: relative;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    word-break: keep-all;
    white-space: pre-line;
    align-self: center;
    box-sizing: border-box;
    //padding-left: 16px;
    height: 100%;
    border-bottom: 1px solid ${Colors.primary.lineGrey};
    border-right: 1px solid ${Colors.primary.lineGrey};
    padding: 0 16px;
  }
  & > span {
    justify-content: center;
    word-break: break-word;
    white-space: pre-line;
    align-self: center;
    box-sizing: border-box;
    //padding-left: 16px;
    height: 100%;
    border-bottom: 1px solid ${Colors.primary.lineGrey};
    border-right: 1px solid ${Colors.primary.lineGrey};
    padding: 0 16px;
  }
  //&::after{
  //  content: '';
  //  height: 100%;
  //  width:1px;
  //  right:0;
  //  position: absolute;
    //  background-color: ${Colors.private.mainColor};
  //}
  .checkbox {
    position: sticky;
    left: 0;
    background-color: ${Colors.primary.checkboxAreaColor};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    z-index: 45;
  }

  .selectCheckbox {
    position: sticky;
    left: 0;
    background-color: ${Colors.primary.checkboxAreaColor};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    z-index: 45;
  }

  .pencil-icon {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    position: sticky;
    flex-direction: column;
    right: 0;
    height: 100%;
    align-items: center;
    padding-right: 12px;
    border: none;
    //z-index테스트
    z-index: 45;
    & > img {
      background-color: ${Colors.primary.white};
    }
  }

  .select-icon {
    display: grid;
    grid-template-columns: repeat(2, 24px);
    gap: 10px;
    justify-content: flex-end;
    padding-right: 12px;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;

    & > img {
      background-color: ${Colors.primary.white};
    }
  }

  &.grid-row-list {
    .checkbox {
        //border-left: 1px solid ${Colors.primary.checkboxAreaColor};
    }
  }

  &:hover {
    &.grid-row-list {
        //border-right: 1px solid ${Colors.private.mainColor};
      background-color: #f1f6fe;
    }

      // border-top: 1px solid ${Colors.private.mainColor};
      // border-bottom: 1px solid ${Colors.private.mainColor};
    //background-color: #f1f6fe;
    color: ${Colors.private.mainColor};
    font-weight: bold;
    cursor: pointer;

    .checkbox {
        //border-left: 1px solid ${Colors.private.mainColor};
    }
  }
}

.grid-title-unchecked {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1210}px;
  // gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  color: #171725;
  background-color: ${Colors.private.tableTitleColor};
  font-weight: 800;
  box-sizing: border-box;
  align-items: center;
  position: sticky;
  top: 0;
  //padding-left: 16px;
  z-index: 48;
  & > div {
	  display: flex;
	  height: 100%;
	  box-sizing: border-box;
	  border: 1px solid ${Colors.primary.lineGrey};
	  border-left: none;
    background-color: ${Colors.private.tableTitleColor};
	  justify-content: center;
	  align-items: center;
  }
	&>div:nth-child(1){
    border: 1px solid ${Colors.primary.lineGrey};
	}

  .sticky_modal {
    position: sticky;
    right: 0;
    background-color: ${Colors.primary.lineGrey};
    z-index: 45;
    align-items: center;
    display: flex;
    height: 48px;
    box-sizing: border-box;
  }

  .sticky {
    position: sticky;
    right: 0;
    background-color: ${Colors.primary.lineGrey};
    z-index: 45;
    align-items: center;
    display: flex;
    width: 220px;
    height: 100%;
    padding-left: 16px;
    box-sizing: border-box;
  }
}

.grid-title-unchecked-merge {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1210}px;
  gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 16px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  background-color: ${Colors.private.tableTitleColor};
  font-weight: 800;
  box-sizing: border-box;
  align-items: center;
  position: sticky;
  top: 0;
  padding-left: 16px;
  z-index: 48;
  & > div {
    background-color: ${Colors.private.tableTitleColor};
  }

  .merge-title{
    display: grid;
    grid-template-columns: ${props => props.mergeTempCol};
  }

}


.grid-list-unchecked, .grid-list-unchecked-no_icon, .grid-list-unchecked-merge {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  //gap: ${props => props.gridGap ?? 16}px;
  width: ${props => props.scrollWidth ?? 1210}px;
  height: 48px;
  font-size: 14px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 14px);
  box-sizing: border-box;
  position: relative;
  //padding-left: 16px;
    //border-left: 1px solid ${Colors.primary.white};

  & > div {
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  overflow: hidden;
	  word-break: break-word;
	  white-space: pre-line;
	  align-self: center;
	  box-sizing: border-box;
	  //padding-left: 16px;
	  height: 100%;
	  border-bottom: 1px solid ${Colors.primary.lineGrey};
	  border-right: 1px solid ${Colors.primary.lineGrey};
	  padding: 0 16px;
  }
	&>div:nth-child(1){
		border-left: 1px solid ${Colors.primary.lineGrey}
	}

  &.grid-list-unchecked-merge{
    box-sizing: border-box;
    height: auto;
    min-height: 48px;
    //border-top: 1px solid transparent;
    //border-bottom: 1px solid transparent;
    &:hover{
        // border-top: 1px solid ${Colors.private.mainColor};
        // border-bottom: 1px solid ${Colors.private.mainColor};
    }
    .merge-inner{
      display: grid;
      grid-template-columns: ${props => props.mergeTempCol};
      &>div{
        min-height: 48px;
        align-items: center;
        align-self: center;
        line-height: 48px;
      }
    }
  }

  .pencil-icon {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;
    padding-right: 12px;

    & > img {
      background-color: ${Colors.primary.white};
      z-index: 45;
    }
  }

  .select-icon {
    display: grid;
    grid-template-columns: repeat(2, 24px);
    gap: 10px;
    justify-content: flex-end;
    padding-right: 12px;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;

    & > img {
      background-color: ${Colors.primary.white};
    }
  }

  &:hover {
    &.grid-list-unchecked-no_icon, &.grid-list-unchecked-merge {
        //border-right: 1px solid ${Colors.private.mainColor};
    }

      //border-left: 1px solid ${Colors.private.mainColor};
    //background-color: #f1f6fe;
    color: ${Colors.private.mainColor};
    font-weight: bold;
    cursor: pointer;
  }
}
`

export const GridTableSelect = styled.div<GridTableProps>`
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'inherit'};
  color: ${props => props.selected? `${Colors.private.mainColor}` : `${Colors.primary.textBlack}`};
  font-weight: ${props => props.selected? 700 : 400};
  background-color: ${props => props.selected? `${Colors.private.tableHoverColor}` : props.errorState? Colors.primary.error : 'auto'};
    // border-top: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    // border-bottom: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    // border-right: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
  //user-select: none;
    // z-index: ${props => props.selected? 47 : 'none'};

  .selectCheckbox{
    border: 1px solid ${Colors.primary.lineGrey};
    border-top: none;
  }
  .checkbox{
      // border-left: ${props => props.selected? `1px solid ${Colors.private.mainColor}`: `1px solid ${Colors.primary.lineGrey}`};
      // border-right: 1px solid ${Colors.primary.lineGrey};
      // border-top: 1px solid ${Colors.primary.lineGrey};
      // border-bottom: ${props => props.bottomLine? `1px solid ${Colors.primary.lineGrey}` :  'none'};
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid ${Colors.primary.lineGrey};
    border-radius: 6px;
    outline: none;
    box-sizing: border-box;
    background-color: ${props => props.titleCheckDisabled ? `${Colors.private.tableHoverColor}` : `${Colors.primary.white}`};
    cursor: pointer;
    align-self: center;
    &::before{
      width: 100%;
      height: 100%;
      align-items: center;
      position: absolute;
      color: ${Colors.primary.textGrey};
      content: "${props => props.checkboxNumber}";
      display: flex;
      justify-content: center;
      font-size: 12px;
    }

    &:checked {
      position: relative;
      border: transparent;
      background-color: ${Colors.private.mainColor};
      box-shadow: inset 0 2px 4px #17172550;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 12px;
      &::before{
        position: absolute;
        color: ${Colors.primary.white};
        content: "${props => props.checkboxNumber}";
      }
    }
  }

  .selectCheckbox {
      // border-left: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : `${Colors.primary.lineGrey}`};
  }
  &.grid-list-unchecked , &.grid-list-unchecked-no_icon{
      // border-left: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    color: ${ props => props.logCenterLine? `${Colors.primary.textGrey}` :  props.selected? `${Colors.private.mainColor}`  : props.errorState? `${Colors.primary.white}` : `${Colors.primary.textBlack}`};
    user-select: ${props => props.logCenterLine? 'none' : 'auto'};
    &::before{
      position: absolute;
      content: '';
      height: ${props => props.logCenterLine? 1 : 0}px;
      top: 24px;
      width: 100%;
      background-color: ${Colors.primary.textGrey};
    }

    &:hover{
      background-color: ${props => props.selected? `${Colors.private.tableSelectHoverColor}` : `${Colors.private.tableHoverColor}`};
    }
  }

  &.grid-list-unchecked-no_icon{
      //border-right: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    color: ${ props => props.selected? `${Colors.private.mainColor}` :  'auto'};
  }
  &.grid-row-list{
      // border-left: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
      // border-right: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    color: ${ props => props.logCenterLine? `${Colors.primary.textGrey}` :  props.selected? `${Colors.private.mainColor}` : props.errorState? `${Colors.primary.white}` : `${Colors.primary.textBlack}`};
    user-select: ${props => props.logCenterLine? 'none' : 'auto'};

    &:hover{
      background-color: ${props => props.selected? `${Colors.private.tableHoverColor}` : '#f1f6fe'};
    }
  }
  .select-icon, .pencil-icon {
      //border-right: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : `${Colors.primary.white}`}
  }

  .sticky {
    position: sticky;
    right: 0;
    background-color: ${props => props.selected? `${Colors.private.tableHoverColor}` : `${Colors.primary.white}`};
      // border-right: 1px solid ${props => props.selected? `${Colors.private.mainColor}` : 'auto'};
    z-index: 45;
    align-items: center;
    display: flex;
    width: auto;
    height: 100%;
    padding-left: 16px;
    box-sizing: border-box;


    &:hover {
      background-color: ${Colors.private.tableHoverColor};
        //border-right: 1px solid ${Colors.private.mainColor};
        //border-top : 1px solid ${Colors.private.mainColor};
        //border-bottom:  1px solid ${Colors.private.mainColor};
    }
  }

  &:hover {
      //border-top: 1px solid ${Colors.private.mainColor};
      //border-bottom: 1px solid ${Colors.private.mainColor};
    //pencil-icon이 없으므로 이부분 추가
      //border-right: 1px solid ${Colors.private.mainColor};

    background-color: ${Colors.private.tableHoverColor};
    color: ${Colors.private.mainColor};
    font-weight: bold;
    cursor: pointer;
    .selectCheckbox{
        //border-left: 1px solid ${Colors.private.mainColor};
    }

    .pencil-icon, .select-icon {
        //border-right: 1px solid ${Colors.private.mainColor};
    }
  }
`
