import React , {useState} from 'react';
import {
  ContentFrame ,
  femsInfoStyle , modalOpenTextStyle ,
  multipleImageGridStyle ,
  NoData , noticeTitleStyle ,
  SectionFrame ,
  validationValue
} from "../../../../style/StyleComponent";
import DetailImageView from "../../../../components/modal/DetailImageView";
import {
  dataAccessLoading ,
  dataValid ,
  initModal ,
  listLoadingCircle ,
  loadingRemove
} from "../../../../function/function";
import MenuTitle from "../../../../components/title/MenuTitle";
import MainButtons from "../../../../components/button/MainButtons";
import noImage from "../../../../asset/image/noImage.svg";
import {useTranslation} from "react-i18next";
import ListInfoComponent from "../../../../components/list/ListInfoComponent";
import PreviewImage from "../../../../components/display/PreviewImage";
import {Modal_View_Type} from "../../../../types/Fundamental";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import SingleImageUploadBox from "../../../../components/input/SingleImageUploadBox";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import PasswordChangeModal from "../../../../components/modal/PasswordChangeModal";
import FindPasswordModal from "../../../../components/modal/FindPasswordModal";
import ToastPopup from "../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import {useLocation} from "react-router-dom";

interface Props {
  data?: any
}

const MyPage:React.FC<Props> = ({data}) => {
  const location = useLocation()
  const authValid = location.pathname.includes('admin')
  const {t} :any = useTranslation();
  const userId = window.localStorage.getItem('companyID')
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal);
  const [myPageDetail,setMyPageDetail] = React.useState<any>({});
  const [emailStatus,setEmailStatus] = React.useState<string>('');
  const [password,setPassword] = React.useState<boolean>(false);
  const [adminPassword,setAdminPassword] = React.useState<string>('');


  const myInfoRes = async () =>{
    try {
      listLoadingCircle()
      const response = await client.post(`system/user/detail`,{
        id:userId
      },{headers:{Authorization}})
      // console.log(response)
      const data = response.data.data
      console.log(data)
      setMyPageDetail(data)
      window.localStorage.setItem('profile',data.profileImageUrl)
      window.localStorage.setItem('userName',data.userName)
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const myInfoPasswordModifyRes = async () =>{
    try {
      dataAccessLoading()
      const response = await client.post(`system/user/update`,{
        ...myPageDetail,
        password:adminPassword ?? '',
        phone_number:myPageDetail.phone_number,
        id:userId
      },{headers:{Authorization}})
      console.log(response)
      if (response.data.status !== 200) {
        setDataModal({menu:'confirm',message:response.data.message});
        loadingRemove()
      } else {
        setTimeout(()=>{
          setDataModal({menu:'success',message:'비밀번호가 변경되었습니다.' })
          setAdminPassword('')
          loadingRemove()
        },500)
      }
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const sendEmailRes = async () => {
    dataAccessLoading();
    setDataModal(initModal)
    // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
    setTimeout(() => {
      setPassword(true)
      loadingRemove();
      // 작업 완료 시 호출할 콜백 함수
    }, 1000);
  };



  React.useEffect(()=>{
    myInfoRes().then()
  },[])

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal); }}/>
      	: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
      		: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {/*{dataModal.menu === 'confirm' &&*/}
      {/*  <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>*/}
      {/*}*/}
      {dataModal.menu ==='profile' && <DetailImageView image={myPageDetail?.profileImageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'pwChange' &&
          <PasswordChangeModal clickEvent={()=>setDataModal(initModal)} closeClick={()=>{setDataModal(initModal)}} findEvent={()=>{setDataModal(initModal)}}/>
      }

      {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={{id:userId}} viewType={true}
                               receivePassword={(data)=>{setAdminPassword(data)}}
                               clickEvent={myInfoPasswordModifyRes} findEvent={()=>{setDataModal({menu:'findPw'})}}
                               closeClick={()=>{setDataModal(initModal)}}/>
      }

      {dataModal.menu === 'findPw' &&
          <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>
      }
      {password &&
          <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>
      }

      <MenuTitle title={'마이 페이지'} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'}  style={{padding:'28px'}}>
        <React.Fragment>
          <div style={{display:'flex', flexDirection:'column',width:'784px',boxSizing:'border-box'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
              <MenuTitle title={'나의 정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'이전'} width={'56px'} clickEvent={() => {window.location.href = authValid ? `/admin/fas/main` : `/fas/main`}}/>
                <MainButtons name={'수정'} width={'56px'} marginDis={true} clickEvent={()=>{window.location.href = authValid ? `/admin/myPage/modify/${userId}` : `/myPage/modify/${userId}`}}/>
              </div>
            </div>
            <div>
              <div style={{display:'grid', gridTemplateColumns: '1fr 1fr'}}>
                <ListInfoComponent title={t('성명')} value={dataValid(myPageDetail?.userName)} bottomBorder={false}/>
                <ListInfoComponent title={t('사원번호')} value={dataValid(myPageDetail.employeeNumber)} bottomBorder={false}/>
                <ListInfoComponent title={t('직책')} value={dataValid(myPageDetail.userPosition)} bottomBorder={false}/>
                <ListInfoComponent title={t('-')} value={dataValid('-')} bottomBorder={false}/>
                {/*<ListInfoComponent title={t('권한')} value={dataValid(myPageDetail.authorityName)} bottomBorder={false}/>*/}
              </div>
              <ListInfoComponent title={t('전화번호')} value={dataValid(myPageDetail?.phone_number)} bottomBorder={false}/>
              <ListInfoComponent title={t('이메일')} value={dataValid(myPageDetail?.email)} bottomBorder={false}/>
              <ListInfoComponent title={t('ID')} value={dataValid(userId)} bottomBorder={false}/>
              <div style={{position:'relative'}}>
                <RegisterComponent type={'button'} name={'비밀번호'} value={''} clickEvent={()=>{setDataModal({menu:'pw'})}} bottomBorder={true}
                                   title={t('PW')} buttonName={'변경하기'} width={'112px'} subClickEvent={()=>''}/>
                {/*<div style={{...modalOpenTextStyle,position:'absolute',top:'20px',right:'0'}} onClick={()=>{setDataModal({menu:'findPw'})}}> {'비밀번호 찾기'} </div>*/}
              </div>

            </div>
          </div>
          <div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>
          <div style={{display:'flex', flexDirection:'column'}}>
            <div>
              <MenuTitle title={'프로필'} unitCase={'MenuCompTitle'}/>
              <PreviewImage imageUrl={myPageDetail?.profileImageUrl}
                            clickEvent={()=> myPageDetail?.profileImageUrl && setDataModal({menu:'profile'})}
                            noneDelete={true} margin={16}/>
            </div>
          </div>
        </React.Fragment>
      </ContentFrame>
    </SectionFrame>
  );
};
export default MyPage;





