import React from 'react';
import styled from "styled-components";
import client from "../../common/Config";
import {authValid , initModal , loadingRemove , modalListLoading} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "../modal/ConfirmPopup";
import {Authorization} from "../../common/authorization";
import {useTranslation} from "react-i18next";
import ToastPopup from "../modal/ToastPopup";

interface Props {
  companyCode: string
  SDMCode: string|null
  disabled?: boolean
  onUploadSuccess?: (newFile: any) => void;
}

const FileUploadButton:React.FunctionComponent<Props> = ({companyCode, SDMCode, disabled,onUploadSuccess}) => {
  const {t}:any = useTranslation()
  const extendReg = /(.*?)\.(jpg|jpeg|png|doc|docx|hwp|pdf|ppt|psd|txt|xls|xlsx|zip|hip|mp4)$/
  const inputRef = React.useRef<any>(null)
  const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)

  const fileUpload = async (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    modalListLoading();
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', data);
    try {
      const response = await client.post('/file/SDM/upload',
                                         formData,
                                         {headers: {Authorization}});

      if (response.data.status === 200) {
        const registerResponse = await client.post('SDM/file/register', {
          SDMTopCode: SDMCode,
          companyCode: companyCode,
          fileCode: response.data.data.imgKey,
          name: data.name,
          type: data.type
        }, {headers: {Authorization}});
        loadingRemove(200);
        setDataModal({menu: 'success', message: '파일이 등록 되었습니다.'});

        if (onUploadSuccess) {
          setTimeout(() => {
            onUploadSuccess(registerResponse.data.data);
            setDataModal(initModal); // 모달 상태를 초기화
          }, 1000);
        }
      } else {
        setDataModal({menu: 'error', message: '파일 등록에 실패했습니다.'});
        loadingRemove();
      }

    } catch (e) {
      loadingRemove(200);
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal?.message)} state={'success'} closeEvent={()=> {
          if(dataModal?.message === '파일이 등록 되었습니다.'){

          } else{
            setDataModal(initModal)
          }
        }}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal?.message)} closeEvent={()=>setDataModal(initModal)} state={'error'}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal?.message)} closeEvent={()=>setDataModal(initModal)} state={'warn'}/> : null}

      {dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal?.message}
                                                     noticeText={String(dataModal?.message).includes('형식의')? 'jpg,jpeg,png,doc,docx,hwp,pdf,ppt,psd,txt,xls,xlsx,zip,hip \n 형식의 파일만 가능합니다.' : ''}
                                                     clickEvent={()=> {
                                                       if(dataModal?.message === '파일이 등록 되었습니다.'){
                                                         window.location.reload()
                                                       }
                                                       else{
                                                         setDataModal( initModal )
                                                       }
                                                     }}/>}
      <ButtonComponent style={{opacity: disabled? 0.5 : 1, pointerEvents: disabled? 'none' : 'auto'}}>
        {t('업로드')}
        <input style={{display:'none'}} type="file" ref={inputRef}
               onChange={(e:any)=>{
                 if(inputRef.current.value.match(extendReg)){
                   if(e.target.files[0].size < 512*1024*1024){
                     fileUpload(e, e.target.files[0]).then()
                   }else{
                     setDataModal({menu:'upload', message: '512MB 이하의 파일을 업로드 해주세요.'})
                     inputRef.current.value = ""
                   }
                 }else{
                   setDataModal({menu:'confirm', message: '잘못된 형식의 파일입니다.'})
                   inputRef.current.value = ""
                   // setUploadState({open: true, status:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})
                 }}}
        />
      </ButtonComponent>
    </React.Fragment>
  );

};
export default FileUploadButton;

const ButtonComponent = styled.label`
  align-self: center;
  text-align: center;
  width: 80px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  color: #5A607F;
  border: 1px solid #D7DBEC;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  margin: 0 8px;
  &:hover{
    background-color: #5A607F;
    color: #fff;
  }
`
