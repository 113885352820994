import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useParams} from "react-router-dom";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {Modal_View_Type , ProductInfoType , QualityInspectionItem} from "../../../../../types/Fundamental";
import {useRecoilState , useRecoilValue} from "recoil";
import {bomProductState , persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid , formatNumber , initModal ,
  loadingRemove ,
  locationFilter , modalListLoading , numberLocaleString
} from "../../../../../function/function";
import MoldRegisterModal from "../../../../../components/modal/MoldRegisterModal";
import InspectionFormModal from "../../../../../components/modal/InspectionFormModal";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import AccountSelectModal from "../../../../../components/modal/AccountSelectModal";
import FacilityProductAutoCheckModal from "../../../../../components/modal/FacilityProductAutoCheckModal";
import {cloneDeep} from "lodash";
import BomViewerModal from "../../../../../components/modal/fas/product/BomViewerModal";

const ProductModify = () => {
  const params = useParams()
  const {productCode} = params
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [productModify , setProductModify] = useState<any>( {customer: []} )
  const [imageKeyState , setImageKeyState] = useState<any[]>( [] )
  const [workStandardKeyState , setWorkStandardKeyState] = useState<any[]>( [] )
  const [inspectionData , setInspectionData] = useState<QualityInspectionItem | any>();
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const [isOpened , setIsOpened] = React.useState<boolean>( false );
  const initProductData = {
    customer:[], serialCode: '' , unit: '' , productName: '' ,
    type: '' , productCode: '' , uph: '' , processName: ''
  }

  const [bomRegister , setBomRegister] = useRecoilState<ProductInfoType>( bomProductState )

  const {t}: any = useTranslation();
  const onChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> , type?: string ) => {
    const {name , value} = e.target
    setProductModify( {
                        ...productModify , [name]: type === 'number' ? value.replace( /[^0-9]/g , '' ) :
        type === 'decimal' ? formatNumber( value ) :
          value.trim()
                      } )
  }
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [duplicateCount , setDuplicateCount] = useState( 0 )
  const productUpdateRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post( '/product/update' , {
        companyCode: selectedCompany.companyCode,
        productCode: productCode ,
        ...productModify ,
        facilityCode: dataValid( productModify?.facilityCode , [] ) ,
        moldCode: dataValid( productModify?.moldCode , [] ) ,
        image: dataValid( imageKeyState , [] ) ,
        workStandardImg: dataValid( workStandardKeyState , [] ) ,

      } , {headers: {Authorization}} )
      // setConfirm(true)
      if( response.data.status !== 200 ){
        setDataModal( {menu: 'error' , message: t( response.data.message )} )
        loadingRemove()
      } else{
        setTimeout( () => {
          setDataModal( {menu: 'success' , message: t( '수정 되었습니다.' )} )
          loadingRemove()
        } , 500 )
      }
    } catch ( e ) {
      console.log( e )
      loadingRemove()
    }
  }
  const productDeleteRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post( '/product/delete' , [productCode] , {headers: {Authorization}} )
      const data = response.data
      // const bomCaseArray = data.data?.bom?.map((v:any)=>({code: v.code, case: v.used + '의 하위 BOM으로 등록 되어 있습니다.'}))
      const caseArray =
        [
          {code: data.data?.bom[0]?.code , case: '은 ' + data.data?.bom[0]?.used + '의 하위 BOM으로 등록 되어 있습니다.'} ,
          {code: data.data?.orders , case: '은 수주가 등록 되어 있습니다.'} ,
          {code: data.data?.workOrder , case: '은 작업지시서가 등록 되어 있습니다.'}
        ]
      if( data.status === 200 ){
        setTimeout( () => {
          setDataModal( {menu: 'success' , message: '해당 품목이 삭제 되었습니다.'} )
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setDataModal( {menu: 'deleteCase'} )
          setDeleteCaseArray(caseArray.filter(item => item.code.length > 0))
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }

  const detailProductRes = async () => {
    try {
      const response = await client.post( '/product/detail' , {
        productCode: productCode
      } , {headers: {Authorization}} )
      setProductModify( response.data.data )
    } catch ( e ) {
      console.log( e )
    }
  }

  const copyProductData = cloneDeep(productModify)

  const inspectionItemDataRes = async () => {
    modalListLoading()
    try {
      const response = await client.post( `/quality/test/read` , {
        productCode: productCode
      } , {headers: {Authorization}} )
      const data = response.data.data;
      // console.log(data)
      setInspectionData( data )
      loadingRemove()
    } catch ( e ) {
      console.log( e )
      loadingRemove()
    }
  }

  React.useEffect( () => {
    detailProductRes().then()
    inspectionItemDataRes().then()
  } , [] )
  console.log(productModify)
  const customerCount = ( customer: any ) => customer.filter( ( v: any , i: number , arr: any ) => arr.findIndex( ( el: any ) => ( el.customerCode === v.customerCode ) ) === i )
  const formData = inspectionData?.inspectionItems?.map( ( v: any , index: number ) => ( Object.assign( inspectionData?.inspectionCriteria?.map( ( data: any ) => ( Object.assign( {} , {inspectionCriteria: data} ) ) )[index] , {inspectionItems: v} ) ) )
  const closeModify = () => {
    if( dataModal.message?.includes( '삭제' ) ){
      window.location.href = authValid() ? `/admin/fas/product` : `/fas/product`
      setDataModal( initModal )
    } else{
      setDataModal( initModal )
    }
    if( dataModal.message === t( '수정 되었습니다.' ) ){
      window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`
      setDataModal( initModal )
    } else{
      setDataModal( initModal )
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  const serialDoubleCheck = async () => {
    try {
      const response = await client.post( '/product/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        serialCode: productModify?.serialCode ,
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      setDuplicateCount( response.data.data[0] )
      if( duplicateCount[0] === 0 ){
        setProductModify( ( prev: any ) => ( {...prev , codeDoubleCheck: true} ) )
        setDataModal( {menu: 'confirm' , status: 200 , message: '중복되지 않은 시리얼CODE 입니다.'} )
      } else{
        setDataModal( {menu: 'doubleCheck' , status: 201 , message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'deleteCase' &&
          <ToastPopup state={'deleteCase'} closeEvent={() => setDataModal( initModal )} timeSet={3600000}
                      deleteCase={deleteCaseArray}/>}
      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             if( dataModal.status === 200 ){
                               setProductModify( ( prev: any ) => ( {...prev , codeDoubleCheck: true} ) )
                             } else{
                               setProductModify( ( prev: any ) => ( {
                                 ...prev ,
                                 serialCode: productModify?.serialCode + ` (${duplicateCount})` ,
                                 codeDoubleCheck: true
                               } ) )
                             }
                             setDataModal( initModal )
                           }
                           } cancelEvent={() => setDataModal( initModal )} buttonWidth={'124px'}
                           buttonFontSize={'12px'}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 작성중이신 수정항목은 저장되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {window.location.href = authValid() ? `/admin/fas/product/inspection/${productCode}` : `/fas/product/inspection/${productCode}`}}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={closeModify}/>}

      {(dataModal.menu === 'bomReg' && productModify.bom === 'empty') &&
          <SelectConfirmModal statusText={dataModal.message}
                              clickEvent={()=>{
                                window.open(authValid()? '/admin/fas/product/bom' : '/fas/product/bom')
                                setDataModal(initModal)
                              }}
                              cancelEvent={()=> {
                                setDataModal( initModal )
                                setBomRegister( initProductData )
                              }}/>


          // <BillOfMaterialModal bomDetailData={productModify} authValid={authValid()}
          //                      closeEvent={() => setDataModal( initModal )}/>
      }

      {(dataModal.menu === 'bomReg' && productModify.bom !== 'empty') &&
        <BomViewerModal bomDetailData={productModify} closeEvent={()=> {
          setDataModal( initModal )
          setBomRegister(initProductData)
        }} manageButton={true}/>

        // <BillOfMaterialModal bomDetailData={productModify} authValid={authValid()}
        //                      closeEvent={() => setDataModal( initModal )}/>

      }



      {dataModal.menu === 'facility' &&
          // <FacilityRegisterModal companyCode={selectedCompany.companyCode}
          //                        receivedFacility={( v ) => {
          //                          Object.assign( productModify , {facilityCode: v} )
          //                        }}
          //                        selectProduct={productModify}
          //                        selectedFacility={dataValid( productModify?.facilityCode , [] )}
          //                        cancelEvent={() => setDataModal( initModal )}/>


          <FacilityProductAutoCheckModal companyCode={selectedCompany.companyCode}
                                         receivedFacility={( e ) => {
                                           setProductModify((prev:any)=>({...prev,
                                             facilityCode: e.facilityCode ,
                                             autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                             autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                             loadTonRange: e.loadTonRange ,
                                             slideCountRange: e.slideCountRange ,
                                             countType: e.countType ,
                                             facilityStatus: e.facilityStatus,
                                             channel: e.channel
                                           }))

                                           // if( registerList.length === 0 ){
                                           //   setProductList( productList.map( ( v: any ) => {
                                           //     return v.productCode === selectCode ? {
                                           //       ...v ,
                                           //       facilityCode: e.facilityCode ,
                                           //       autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                           //       autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                           //       loadTonRange: e.loadTonRange ,
                                           //       slideCountRange: e.slideCountRange ,
                                           //       countType: e.countType ,
                                           //       facilityStatus: e.facilityStatus
                                           //     } : v
                                           //   } ) )
                                           // } else{
                                           //   setRegisterList( registerList.map( ( v: any , i: number ) => {
                                           //     return i === dataModal.index ? {
                                           //       ...v ,
                                           //       facilityCode: e.facilityCode ,
                                           //       autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                           //       autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                           //       loadTonRange: e.loadTonRange ,
                                           //       slideCountRange: e.slideCountRange ,
                                           //       countType: e.countType ,
                                           //       facilityStatus: e.facilityStatus
                                           //     } : v
                                           //   } ) )
                                           // }


                                         }}
                                         selectProduct={productModify}
                                         cancelEvent={() => setDataModal( initModal )}
                                         selectedFacility={productModify.facilityCode}/>


      }

      {dataModal.menu === 'insReg' &&
          <InspectionFormModal productData={productModify} getData={formData} imageUrlList={productModify?.imageUrl}
                               cancelEvent={() => {setDataModal( initModal )}}
                               inspectionLength={formData?.length <= 8 ? 8 : formData?.length}
                               openEvent={() => {setIsOpened( !isOpened )}} isOpenedProps={isOpened}
                               sampleIdx={Number( inspectionData.inspectionSample )}/>
      }

      {dataModal.menu === 'mold' &&
          <MoldRegisterModal selectProduct={productModify} modified={true}
                             companyCode={selectedCompany.companyCode}
                             receivedMold={( v ) => {
                               Object.assign( productModify , {moldCode: v} )
                             }}
                             selectedMold={dataValid( productModify?.moldCode , [] )}
                             cancelEvent={() => setDataModal( initModal )}/>}

      {/*{modalState.status === 'inspectionReg' &&*/}
      {/*    <ProductInspectionReg cancelEvent={()=> setModalState({open: false, status:'', message:''})}*/}
      {/*                          confirmEvent={()=> setModalState({open: false, status:'', message:''})}/>}*/}
      {/*{modalState.status === 'inspectionInfo' &&*/}
      {/*    <ProductInspectionResult  confirmEvent={()=> setModalState({open: false, status:'', message:''})*/}
      {/*    }/>}*/}

      {dataModal.menu === 'customer' &&
          <AccountSelectModal initCustomerData={productModify?.customer.map( ( v: any ) => ( {
            customerName: v.customerName ,
            customerCode: v.customerCode
          } ) )}
                              initModelData={productModify?.customer.map( ( v: any ) => ( {
                                customerName: v.customerName ,
                                customerCode: v.customerCode ,
                                customerModelName: v.customerModelName ,
                                customerModelCode: v.customerModelCode
                              } ) )}
                              receivedData={( e ) => {
                                setProductModify( ( prev: any ) => ( {...prev , customer: e} ) )
                              }} closeModal={() => setDataModal( initModal )}/>}
      {/*{(dataModal.menu === 'customer' || dataModal.menu === 'customerModel') &&*/}
      {/*  <AccountSearchModal receiveData ={(v)=>setSelectCustomer(v)} modalCase={dataModal.menu}*/}
      {/*                      companyCode={selectedCompany.companyCode} authValid={authValid}*/}
      {/*                      customerCode={dataValid(productModify.customerCode,'')}*/}
      {/*                      confirmEvent={()=> {setProductModify( (prev:any)=>({...prev, ...selectCustomer, customerModelCode:'', customerModelName:''}) );*/}
      {/*                          setDataModal(initModal)}}*/}
      {/*                      confirmModelEvent={()=> {setProductModify( (prev:any)=>({...prev, ...selectCustomer}) );setDataModal(initModal)}}*/}
      {/*                      cancelModelEvent={()=>setDataModal(initModal)}*/}
      {/*                      cancelEvent={()=> setDataModal(initModal)}/>}*/}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={() => {
                                productUpdateRes().then();
                                setDataModal( initModal )
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'delete' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 삭제된 데이터는 복구 되지 않습니다.'}
                              clickEvent={() => {productDeleteRes().then(); setDataModal(initModal)}}
                              cancelEvent={() => setDataModal( initModal )}/>}

      <SectionFrame>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 품목 기본정보 > 수정'}
                   unitCase={'MenuTitle'}/>
        <ContentFrame width={'1272px'} height={'680px'} flexDirection={'row'}>
          <div className={'scroll_box'} style={{width: '784px'}}>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'품목 기본정보 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons width={'56px'} name={'취소'}
                             clickEvent={() => {
                               setDataModal( {
                                               menu: 'cancel' ,
                                               message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                             } )
                             }}/>
                <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
                             disabled={buttonAuthValid( 'delete' , '8' )}
                             clickEvent={() => setDataModal( {menu: 'delete' , message: '정말 삭제 하시겠습니까?'} )}/>
                <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                             clickEvent={() => {setDataModal( {menu: 'register' , message: '이대로 수정 하시겠습니까?'} )}}/>
              </div>
            </div>
            <InputContainer>
              {authValid() &&
                  <ListInfoComponent title={t( '업체명' )} value={productModify?.companyName} bottomBorder={false}/>}
              <div style={{display: 'grid' , gridTemplateColumns: '1fr 1fr'}}>
                {authValid() &&
                    <React.Fragment>
                      <ListInfoComponent title={t( '대표자' )} value={dataValid( productModify?.president )}
                                         bottomBorder={false}/>
                      <ListInfoComponent title={t( '지역' )} value={dataValid( locationFilter( productModify?.address ) )}
                                         bottomBorder={false}/>
                    </React.Fragment>}

                <RegisterComponent type={'textButton'} title={t( '거래처' )} name={'customerName'}
                                   value={dataValid( ( customerCount( productModify?.customer )[0]?.customerName + ( ( customerCount( productModify?.customer )?.length - 1 ) !== 0 ? ` 외 ${customerCount( productModify?.customer )?.length - 1}` : '' ) ) )}
                                   required={true} buttonWidth={'40px'} buttonMargin={'0'}
                                   clickEvent={() => setDataModal( {menu: 'customer'} )} paddingRight={true}
                                   placeholder={t( '거래처' )}/>

                <RegisterComponent type={'textButton'} title={t( '모델' )} name={'customerModelName'}
                                   value={dataValid( productModify?.customer[0]?.customerModelName + ( ( productModify?.customer?.length - 1 ) !== 0 ? ` 외 ${productModify?.customer?.length - 1}` : '' ) , '' )}
                                   onChange={onChange} required={true} buttonWidth={'40px'} buttonMargin={'0'}
                                   clickEvent={() => {
                                     setDataModal( {menu: 'customer'} )
                                   }}
                                   placeholder={'모델'}/>
                <RegisterComponent type={'doubleCheck'} title={'CODE'} name={'serialCode'}
                                   value={productModify?.serialCode} required={true}
                                   paddingRight={true} disabled={productModify?.codeDoubleCheck}
                                   clickEvent={serialDoubleCheck}
                                   onChange={( e ) => setProductModify( ( prev: any ) => ( {
                                     ...prev ,
                                     serialCode: e.target.value.trim() ,
                                     codeDoubleCheck: false
                                   } ) )}/>
                <RegisterComponent type={'text'} title={'품명'} name={'productName'} value={productModify?.productName}
                                   required={true}
                                   onChange={onChange}/>

                <RegisterComponent type={'unit'} title={'캐비티'} name={'cavity'}
                                   unit={dataValid( productModify?.unit , 'ea' )}
                                   value={numberLocaleString(productModify?.cavity)}
                                   paddingRight={true} limit={11}
                                   onChange={(e)=>onChange(e,'number')}/>

                {/*<RegisterComponent type={'inputWidthDrop'} title={'품목 중량'} name={'cavity'}*/}
                {/*                   unit={dataValid( productModify?.unit , 'ea' )}*/}
                {/*                   value={numberLocaleString(productModify?.cavity)} limit={11}*/}
                {/*                   onChange={(e)=>onChange(e,'number')}/>*/}

                <RegisterComponent type={'inputWidthDrop'} title={'품목 중량'} name={'weight'} dropWidth={'86px'}
                                   dropArray={['ea','kg','g','Ton']} dropValue={dataValid(productModify?.weightUnit,'g')}
                                   value={productModify?.weight} limit={11}
                                   onChange={(e)=>onChange(e,'decimal')}
                                   dropClickEvent={(v)=>setProductModify((prev:any)=>({...prev, weightUnit: v}))}
                                   paddingRight={true} />

                <RegisterComponent type={'empty'} title={'-'} name={''} value={''}/>

                <RegisterComponent type={'unit'} title={'세로(Feeder)'} name={'length'}
                                   unit={'mm'}
                                   value={productModify?.length} limit={11}
                                   onChange={(e)=>onChange(e,'decimal')}/>

                <RegisterComponent type={'drop'} title={'품목 종류'} required={true}
                                   name={'type'} value={productModify?.type} onChange={onChange}
                                   dropClickEvent={( v ) => setProductModify( ( prev: any ) => ( {
                                     ...prev ,
                                     type: v
                                   } ) )}
                                   dropArray={['완제품' , '반제품' , '재공품']}/>

                <RegisterComponent type={'drop'} title={'단위'} required={true} dropWidth={'208px'}
                                   name={'unit'} value={dataValid( productModify?.unit , 'ea' )}
                                   onChange={onChange} dropClickEvent={( v ) => setProductModify( ( prev: any ) => ( {
                  ...prev ,
                  unit: v ,
                  uph: ''
                } ) )}
                                   dropArray={['ea' , 'kg' , 'g' , 'Ton']}/>

                <ListInfoComponent title={'생산 공정'} value={dataValid( productModify?.processName )} bottomBorder={false}/>

                <ListInfoComponent type={'button'} title={'기계'}
                                   buttonName={!productModify?.facilityCode ? '등록된 기계 없음' : '기계 수정'}
                                   clickEvent={() => setDataModal( {menu: 'facility'} )}
                                   bottomBorder={false}/>

                <RegisterComponent type={'unit'} title={'기준 UPH'} name={'uph'}
                                   unit={dataValid( productModify?.unit , 'ea' )}
                                   value={productModify?.unit === 'ea' ? numberLocaleString( productModify?.uph ) : productModify?.uph}
                                   limit={productModify?.unit === 'ea' ? 11 : 15}
                                   onChange={( e ) => {productModify?.unit === 'ea' ? onChange( e , 'number' ) : onChange( e , 'decimal' )}}/>

                <ListInfoComponent type={'button'} title={'BOM'}
                                   buttonName={productModify?.bom === 'empty' ? 'BOM 등록' : 'BOM 수정'}
                                   clickEvent={() => {
                                     setDataModal( {menu: 'bomReg',  message:'BOM 등록을 위해 관리 페이지로\n이동 하시겠습니까?'} )
                                     setBomRegister({...copyProductData, bomAuto: true })
                                   }}
                                   bottomBorder={false}/>

                <ListInfoComponent type={'button'} title={t( '초·중·종 검사' )}
                                   buttonName={!inspectionData ? t( '검사항목 등록' ) : t( '검사항목 수정' )}
                                   clickEvent={() => {
                                     if( !inspectionData ){
                                       window.location.href = authValid() ? `/admin/fas/product/inspection/${productCode}` :
                                         `/fas/product/inspection/${productCode}`
                                     } else{
                                       window.location.href = authValid() ? `/admin/fas/product/inspection/${productCode}` :
                                         `/fas/product/inspection/${productCode}`
                                     }
                                   }}
                                   bottomBorder={false}/>

                {/*<ListInfoComponent type={'button'} title={'초·중·종 검사'}*/}
                {/*                   buttonName={'검사항목 등록'}*/}
                {/*                   clickEvent={()=> {*/}
                {/*                       setDataModal({menu:'select',message:'등록 페이지로 이동 하시겠습니까?'})*/}
                {/*                   }}*/}
                {/*                   bottomBorder={false}/>*/}

                <ListInfoComponent type={'button'} title={'금형'}
                                   buttonName={!productModify?.moldCode ? '등록된 금형 없음' : '금형 수정'}
                                   clickEvent={() => setDataModal( {menu: 'mold'} )}
                                   bottomBorder={false}/>
              </div>
              <RegisterComponent title={'비고'} type={'textarea'} name={'notice'}
                                 onChange={onChange}
                                 value={productModify?.notice} bottomBorder={true}/>
            </InputContainer>
          </div>
          <div style={{width: '1px' , height: '549px' , backgroundColor: '#E6E9F4' , margin: '30px'}}/>
          <div className={'scroll_comp'} style={{width: '377px' , overflowY: 'scroll'}}>
            <div
              style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , marginBottom: '16px'}}>
              <MenuTitle title={'품목 이미지 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{color: '#7E84A3' , fontSize: '12px' , lineHeight: '16px' , height: '34px'}}>
                <div>{t( '*최대 4개의 이미지 등록 가능' )}</div>
                <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
              </div>
            </div>
            <TotalImageUploadBox receiveKey={productModify?.image}
                                 receiveUrl={productModify?.imageUrl} imageLength={4}
                                 imageUrlArray={( data ) => {
                                   setProductModify( ( prev: any ) => ( {
                                     ...prev ,
                                     imageUrl: data
                                   } ) )
                                 }}
                                 imageKeyArray={setImageKeyState} profileSelect={false}/>
            <div style={{
              display: 'flex' ,
              justifyContent: 'space-between' ,
              alignItems: 'center' ,
              margin: '24px 0 16px'
            }}>
              <MenuTitle title={'작업표준서 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{color: '#7E84A3' , fontSize: '12px' , lineHeight: '16px' , height: '34px'}}>
                <div>{t( '*최대 4개의 이미지 등록 가능' )}</div>
                <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
              </div>
            </div>

            <TotalImageUploadBox receiveKey={productModify?.workStandardImg}
                                 receiveUrl={productModify?.workStandardImgUrl} imageLength={4}
                                 imageKeyArray={setWorkStandardKeyState} profileSelect={false}/>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  )
}
export default ProductModify;
const InputContainer = styled.div`

  height: 570px;
  width: 784px;
  overflow-x: hidden;
  overflow-y: scroll;

  & > div {
    user-select: none;
    display: flex;
    min-height: 48px;
    line-height: 48px;
    box-sizing: border-box;
  }

  //.scroll_func{
  //  width: 810px;
  //  height: 584px;
  //  overflow-y: scroll;
  //  overflow-x: hidden;
  //  &::-webkit-scrollbar {
  //    display: block;
  //  }
  //}


  .formText {
    font-size: 12px;
    color: #171725;
    opacity: 0.5;
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    margin-left: 8px;
  }

  .indexBox {

    line-height: 30px;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 1px solid #e7e9ee;
  }

  .inputWrap {
    width: 616px;
    border-bottom: 1px solid #e7e9ee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 16px;

  }
`
