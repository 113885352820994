import React , {useEffect , useRef , useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , NoData , SectionFrame} from "../../../../../style/StyleComponent";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {
	authValid ,
	buttonAuthValid ,
	dataValid ,
	initModal ,
	listLoadingCircle ,
	loadingRemove , menuVisitLogRegister
} from "../../../../../function/function";
import SelectEventButton from "../../../../../components/button/SelectEventButton";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import CompanySearchList from "../../../../../components/list/CompanySearchList";
import {TransformComponent , TransformWrapper} from "react-zoom-pan-pinch";
import zoomInImg from '../../../../../asset/image/button/zoomIn_button.png'
import zoomOutImg from '../../../../../asset/image/button/zoomOut_button.png'

const CustomerLayoutCont = () => {

	const [selectPlant, setSelectPlant] = useState<{idx:number,value?:string,factoryName:string,factoryCode:string}>
	({idx:-1,value:'',factoryName:'',factoryCode:''})
	const companyInfo= useRecoilValue<any>(authValid() ? persistCompanyData : userPersistCompanyData)
	const [selectCompany, setSelectCompany] = useState<any>({companyCode:companyInfo.companyCode,companyName:companyInfo.companyName})
	const [detailFactoryList, setDetailFactoryList] = useState<any[]>([])
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)

	const [printReset, setPrintReset] = useState<boolean>(false)

	const { t }:any = useTranslation();

	const drawingPrintRef = useRef<any>(null)

	const factoryListRes = async () =>{
		listLoadingCircle()
		try {
			const response = await client.post(`/factory/user/search`,{
				page:1,
				size:10,
				companyCode:dataValid(selectCompany.companyCode,'@'),
				keyword:'@'
			},{
																					 headers:{Authorization}
																				 })
			const data = response.data.data
			if(data){
				setDetailFactoryList(data.row)
				setSelectPlant({idx:0, value: data.row[0].factoryDrawing, factoryName: data.row[0].factoryName, factoryCode: data.row[0].factoryCode})
			}else{
				setDetailFactoryList([]);
				setSelectPlant({idx:-1,value:'',factoryName:'',factoryCode:''})
			}
			loadingRemove()
		}catch ( e ){
			console.log(e)
			loadingRemove()
		}
	}

	React.useEffect(()=>{
		if(selectCompany.companyName !== ''){
			factoryListRes().then()
		}
	},[selectCompany])

	const downloadFile = (url: string, fileName: string) => {
		setDataModal({menu:'success',message:'도면을 다운로드합니다.'})
		fetch(url, { method: 'GET' })
		.then((res) => {
			return res.blob();
		})
		 .then((blob) => {
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = url;
			a.download = fileName + t(`.jpeg`);
			document.body.appendChild(a);
			a.click();
			setTimeout((_) => {
				window.URL.revokeObjectURL(url);
			}, 60000);
			a.remove();
		})
		 .catch((err) => {
			console.error('err: ', err);
		});
	};

	const refreshPrint = () => {
		setPrintReset(true)

		setTimeout(()=>{
			setPrintReset(false)
		},100)
	}


	const zoomIn = () => {
		drawingPrintRef.current?.zoomIn()
	}
	const zoomOut = () => {
		drawingPrintRef.current?.zoomOut()
	}

	useEffect(()=>{
		if(!window.localStorage.getItem('auth')!?.includes('admin')){
			menuVisitLogRegister(window.localStorage.getItem('companyID'),'도면 관리').then()
		}
	},[])

	return (
		<SectionFrame>
			{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal( initModal )}}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}
			{dataModal.menu === 'select' &&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={' * "예" 클릭시 공장 수정 페이지로 이동됩니다.'}
														clickEvent={()=>{
															window.open(authValid() ? `/admin/fas/factory/modify/${selectPlant.factoryCode}` : `/fas/factory/modify/${selectPlant.factoryCode}`)
															setDataModal(initModal)
															// window.location.href = authValid() ? `/admin/fas/factory/modify/${selectPlant.factoryCode}` : `/fas/factory/modify/${selectPlant.factoryCode}`
														}}
														cancelEvent={()=>{setDataModal(initModal)}}/>}

			{dataModal.menu === 'register' &&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={' * "예" 클릭시 공장 등록 페이지로 이동됩니다.'}
														clickEvent={()=>{
															window.open(authValid() ? `/admin/fas/factory/register` : `/fas/factory/modify/register`)
															setDataModal(initModal)
															// window.location.href = authValid() ? `/admin/fas/factory/register` : `/fas/factory/modify/register`
														}}
														cancelEvent={()=>{setDataModal(initModal)}}/>}


			<MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 도면 관리'} unitCase={'MenuTitle'}/>
			<div style={{display:'flex'}}>
				{authValid() &&
					<CompanySearchList companyDataReceive={(data)=>{setSelectCompany(data); setSelectPlant({idx:-1,value:'',factoryName:'',factoryCode:''})}}/>
				}
				<ContentFrame width={authValid() ? '840px' : '1272px'} margin={authValid() ? '0 0 0 20px' : '0'}>
					<div style={{display:'flex', justifyContent:'space-between',alignItems:'center',marginBottom:'8px'}}>
						<MenuTitle title={'도면 정보'} unitCase={'MenuCompTitle'}/>
						<div style={{display:'flex'}}>
							{(selectCompany.companyCode) &&
								<MainButtons name={'공장 등록'} disabled={buttonAuthValid('create', '3')}
														clickEvent={() => setDataModal({menu:'register',message:'공장 등록 페이지로 이동 하시겠습니까?'})}/>}

							<MainButtons name={'다운로드'} disabled={!selectPlant?.value}
													 buttonType={'cta'}
													 clickEvent={()=> downloadFile(detailFactoryList[selectPlant.idx]?.factoryDrawing, detailFactoryList[selectPlant.idx]?.factoryName)}/>
						</div>

					</div>
					<div style={{display: 'flex'}}>
						<div style={{display:'flex',overflow:'hidden',alignItems:'center',height:'32px',boxSizing:'border-box',borderRadius:'4px',backgroundColor:'#D7DBEC', marginBottom:'12px'}}>
							{detailFactoryList?.map((v:any, i:number) => (
								<SelectEventButton buttonName={v.factoryName} padding={'8px 14px'} buttonStatus={selectPlant.idx === i} key={i}
																	 buttonBorder={true} buttonEvent={() => {
																		 setSelectPlant( {idx:i, value:v.factoryDrawing,factoryName:v.factoryName,factoryCode:v.factoryCode});
																		 refreshPrint()
																	 }} height={'32px'}/>
							))}
						</div>
					</div>
					{selectPlant?.value ?

						<div style={{position:'relative'}}>

							{
								<div style={{position:'absolute', top:'24px', right:'16px', zIndex:100, display:'grid', gap:'12px', gridTemplateColumns:'1fr 1fr', userSelect:'none'}}>
									<img src={zoomInImg} style={{cursor:'pointer', width:'28px'}} onClick={zoomIn} alt=""/>
									<img src={zoomOutImg} style={{ cursor:'pointer', width:'28px'}}  onClick={zoomOut} alt=""/>
								</div>
							}

							{!printReset &&
								<TransformWrapper velocityAnimation={{disabled:true}} ref={drawingPrintRef} centerOnInit={true}>
									<TransformComponent wrapperStyle={{width: authValid() ? '778px' : '1210px', height: '532px', border:'1px solid #d7dbec'}}>
										{<img src={selectPlant.value} alt="" style={{
											objectFit: 'contain' ,
											objectPosition: 'center' ,
											maxWidth: authValid() ? '778px' : '1210px' ,
											maxHeight: '532px'
										}}/>}
									</TransformComponent>
								</TransformWrapper>}
						</div>

						// <ShowDrawingImage src={selectPlant.value} width={authValid() ? 778 : 1210} height={authValid() ? 532 : 532}/>

						:

						!selectCompany.companyCode ?
							<NoData>{' * 업체를 선택해주세요.'}</NoData>

							:
							detailFactoryList.length === 0 ?
								<NoData>{'  * <' + `${dataValid(selectCompany.companyName)}` + '> 업체에 등록된 공장이 없습니다.'}</NoData>
								:
								!selectPlant.value?
									<div style={{display:'flex',flexDirection:'column',height:'480px',justifyContent:'center'}}>
										<NoData height={60}>{' * <' + `${selectCompany.companyName}` + '> 업체에 등록된 <' +selectPlant.factoryName+'>의 공장도면이 없습니다.'}</NoData>
										<MainButtons name={'도면 등록'} height={'32px'} disabled={buttonAuthValid('update', '3')}
																 clickEvent={()=>{setDataModal({menu:'select',message:'공장 도면을 등록하시겠습니까?'})}}/>
									</div>
									:
									<NoData>{t('* 도면을 선택해주세요.')}</NoData>
					}
				</ContentFrame>
			</div>
		</SectionFrame>
	);
};

export default CustomerLayoutCont;

const ShowDrawingImage = styled.img<{width?:number,height?:number}>`
	width: ${p=>p.width}px ;
	height: ${p=>p.height}px;
	object-fit: contain;
	background-repeat: no-repeat;
	margin-top: 16px;
`