import React , {useState} from 'react';
import {useParams} from "react-router-dom";
import {AccountModelType , Modal_View_Type} from "../../../../../types/Fundamental";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
} from "../../../../../function/function";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import styled from "styled-components";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import {useTranslation} from "react-i18next";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";

const AccountModelDetail = () => {
  const {t}:any = useTranslation()

  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [selectPage, setSelectPage] = useState<number>(0)
  const [accountCode , setAccountCode] = useState<any>( {} )
  // const [customerModelList , setCustomerModelList] = React.useState<any[]>( [] )
  const [customerModelDetail, setCustomerModelDetail] = useState<AccountModelType | any>({})
  const [deleteCaseArray, setDeleteCaseArray] = useState<any>([])
  const [modelInitState, setModelInitState] = useState<AccountModelType | any>({})
  const params = useParams()
  const {customerModelCode} = params

  const onChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
     const {name , value} = e.target
    setCustomerModelDetail( ( prev: any ) => ( {...prev , [name]: value} ) )
  }

//거래처 모델 디테일
  const accountModelDetailRes = async () => {
    try{
      const response = await client.get(  `/customerModel/detail/${customerModelCode}` ,
                                          {headers: {'Authorization': Authorization}} )
      const data = response.data;
      setCustomerModelDetail( data.data )
      setModelInitState( data.data )
    } catch( e ){
      console.log( 'error', e )
    }
  }

  React.useEffect(() => {
    accountModelDetailRes().then()
  }, [])

  //거래처 모델 수정
  const accountModelUpdateRes = async () =>{
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/customerModel/update`,{
        ...customerModelDetail,
        companyCode: selectedCompany.companyCode,
        customerModelCode: customerModelDetail.customerModelCode,
      })
      const data = response.data
      if(data.status !== 200){
        setDataModal({menu:'error', message: data.message})
        loadingRemove()
      }else{
        setTimeout(()=>{
          setDataModal({menu:'success', message:'수정 되었습니다.'})
          setSelectPage(0)
          loadingRemove()
        },500)
      }
    }catch (e:any) {
      console.log(e)
      setDataModal({menu:'confirm',message:e.message})
      loadingRemove()
    }
  }

  const accountModelDeleteRes = async () =>{
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/customerModel/delete`,[
        customerModelCode],{headers:{Authorization}})
      const data = response.data
      const caseArray = data.data?.product?.map((v:any)=>({code:v, case:'해당 모델을 사용하는 품목이 존재합니다.'}))

      if(data.status === 200) {
        setTimeout(()=>{
          setDataModal({menu:'success',message:'거래처 모델이 삭제 되었습니다.'})
          loadingRemove()
          setTimeout(()=>{
            window.location.href = `${authValid() ? '/admin/fas/model' : '/fas/model'}`
          },500)
        },500)
      }

      else if(data.status === 601){
        setTimeout(()=>{
          setDeleteCaseArray(caseArray)
          setDataModal({menu:'deleteCase'})
          loadingRemove()
        },500)
      }

      else{
        setDataModal({menu:'warn',message: response.data.message})
        loadingRemove()
      }

    }catch (e:any) {
      console.log(e)
      setDataModal({menu:'error',message:e.message})
      loadingRemove()
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'모델'} caseArray={deleteCaseArray} closeEvent={()=>setDataModal(initModal)}/>}
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}
      {dataModal.menu ==='back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 저장없이 이동합니다.'}
                              viewType={true}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{
                                setCustomerModelDetail(modelInitState)
                                setDataModal(initModal)
                                setSelectPage(0)
                              }
                              }/>}
      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} modalCase={'customer'}
                              companyCode={selectedCompany.companyCode} authValid={authValid()}
                              cancelEvent={() => {
                                setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                                setAccountCode( {} )
                              }}
                              confirmEvent={() => {
                                setCustomerModelDetail( ( prev: any ) => ( {
                                  ...prev , customerName: accountCode.customerName ,
                                  businessNumber: accountCode.businessNumber , customerCode: accountCode.customerCode
                                } ) )
                                // if( dataModal.index === 0 ){
                                //   Object.assign( customerModelList[dataModal.index] ,
                                //                  {
                                //                    customerName: accountCode.customerName ,
                                //                    businessNumber: accountCode.businessNumber ,
                                //                    customerCode: accountCode.customerCode
                                //                  } )
                                // }
                                setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                              }} receiveData={( v ) => ( setAccountCode( v ) )}/>}
      {selectPage === 0 ?
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 모델 정보 > 조회'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
                  <MenuTitle title={'거래처 모델 정보 조회'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'목록'} width={'56px'} clickEvent={() => {
                        window.location.href = `${authValid() ? '/admin/fas/model' : '/fas/model'}`
                      }}/>
                      <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   disabled={buttonAuthValid( 'update' , '7' )}
                                   clickEvent={() => {setSelectPage( 1 )}}/>
                    </div>
                  </div>
                </div>
                <ListInfoComponent title={'거래처명'} value={dataValid(customerModelDetail?.customerName)}/>
                <ListInfoComponent title={'사업자 번호'} value={dataValid(customerModelDetail?.businessNumber)}/>
                <ListInfoComponent title={'모델'} value={dataValid(customerModelDetail?.customerModelName)} bottomBorder={true}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              {/*@ts-ignore*/}
              <div className={'scroll_comp'} style={{width: '379px' , overflowY: 'overlay'}}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>
        :
        // 수정 페이지
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 모델 정보 > 수정'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'거래처 모델 정보 수정'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={() => {
                        setDataModal({menu:'back',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?' })
                      }}/>
                      <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
                                   clickEvent={accountModelDeleteRes}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   clickEvent={accountModelUpdateRes}/>
                    </div>
                  </div>
                </div>
                <RegisterComponent type={'search'} title={'거래처명'} name={'customerName'} value={customerModelDetail?.customerName}
                                    onChange={() => {}} required={true} clickEvent={() => {
                    setDataModal( {menu: 'account' , index: 0} )
                  }}/>
                <RegisterComponent type={'text'} title={'사업자 번호'} name={'businessNumber'} value={customerModelDetail?.businessNumber}
                                   onChange={onChange}/>
                <RegisterComponent type={'text'} title={'모델'} name={'customerModelName'} value={customerModelDetail?.customerModelName}
                                    onChange={onChange}
                                   required={true} bottomBorder={true}/>
              </RegisterComp>
              <div style={{width: '1px', height:'568px', backgroundColor: '#e0e0e0', margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'} style={{width: '379px' , height: '600px' }}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>}

    </SectionFrame>
  );
};
export default AccountModelDetail;

const RegisterComp = styled.div`
  width: 784px;
  height: 100%;

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`