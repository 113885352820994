import React from 'react';
import styled from 'styled-components'
import {authAdminCategory , authAffiliateCategory , authUserCategory} from "../../common/DataSet";
import ArrowButton from "../button/ArrowButton";
import {dataValid} from "../../function/function";
import {GridTableComponent} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";


interface PermissionsProps{
  clickEvent:()=>void
  receiveCheckedList?:(data:any)=>void
  disabled?:boolean
  prevAuthName?:string
  receiveMenu?:any[]
  height?: number | undefined
  authority?:any
  affiliateAuth?:string
}
const PermissionSettingBox = ({clickEvent,disabled,prevAuthName,receiveCheckedList,receiveMenu,height,authority,affiliateAuth}:PermissionsProps) => {
  const authCompanyCategory = authUserCategory.filter(menuItem => dataValid(!authority ? window.localStorage.getItem('solution')!  :authority).split(',').includes(menuItem.title)|| menuItem.title === 'MES')
  const [authMenuList,setAuthMenuList] = React.useState<any[]>( (!authority && affiliateAuth === 'affiliate') ? authAffiliateCategory : !authority ? authAdminCategory :  authCompanyCategory)
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);
  const [menuHeights, setMenuHeights] = React.useState( authMenuList.map( () => 0));
  const [checkedList,setCheckedList] = React.useState<any[]>([])
//affiliateAuth === 'affiliate' ? authAffiliateCategory :
  React.useEffect(()=>{
    if(receiveMenu){
      setCheckedList( receiveMenu );
    }
  },[receiveMenu])

  React.useEffect(()=>{
    setAuthMenuList((!authority && affiliateAuth === 'affiliate') ? authAffiliateCategory : !authority ? authAdminCategory :  authCompanyCategory)
  },[affiliateAuth])

  const calculateMenuHeight = (index: number) => {
    const subTitleLength = authMenuList[index]?.subTitle?.length || 0;
    const subMenuLength = authMenuList[index]?.subTitle?.reduce((acc:any, sub:any) => {
      return acc + (sub.subMenu?.length || 0);
    }, 0) || 0;

    return 48 * (subTitleLength + subMenuLength);
  };

  const handleDivClick = (v:any) => {
    if (selectedValues.includes(v)) {
      setSelectedValues(selectedValues.filter((value) => value !== v));
    } else {
      setSelectedValues([...selectedValues, v]);
    }
  };

  const toggleSubMenuTitle = (itemIndex: any, subMenuIndex: any) => {
    setCheckedList((prevCheckedList) => {
      const updatedCheckedList = [...prevCheckedList];
      const subMenu = authMenuList[itemIndex]?.subTitle[subMenuIndex]?.subMenu;
      const itemTitle = authMenuList[itemIndex]?.subTitle[subMenuIndex];

      if (Array.isArray(subMenu)) {
        // Calculate all selected status and remove/add sub-items accordingly
        const isAllSelected = subMenu.every((subMenuItem: any) =>
                                              updatedCheckedList.some((item) => item.menu === subMenuItem.menuIndex)
        );

        subMenu.forEach((subMenuItem: any) => {
          const index = updatedCheckedList.findIndex((item) => item.menu === subMenuItem.menuIndex);

          if (isAllSelected) {
            // If all selected, remove sub-items and parent item
            if (index !== -1) {
              updatedCheckedList.splice(index, 1);
            }
          } else if (index === -1) {
            // If not all selected, add sub-items if they don't exist
            updatedCheckedList.push({
                                      menu: subMenuItem.menuIndex,
                                      delete: subMenuItem.delete || 'impossible',
                                      create: subMenuItem.create || 'impossible',
                                      update: subMenuItem.update || 'impossible',
                                      read: subMenuItem.read || 'impossible',
                                    });
          }
        });
      } else {
        if (itemTitle) {
          const index = updatedCheckedList.findIndex((item) => item.menu === itemTitle.menuIndex);

          if (index === -1) {
            updatedCheckedList.push({
                                      menu: itemTitle.menuIndex,
                                      delete: itemTitle.delete || 'impossible',
                                      create: itemTitle.create || 'impossible',
                                      update: itemTitle.update || 'impossible',
                                      read: itemTitle.read || 'impossible',
                                    });
          } else {
            // Check the condition for removing the item
            const menuItem = updatedCheckedList[index];
            updatedCheckedList.splice(index, 1);
          }
        }
      }

      return updatedCheckedList;
    });
  };

  const handleItemClick = (menuIndex: string, property: string) => {
    const itemIndex = checkedList.findIndex(item => item.menu === menuIndex);

    if (itemIndex !== -1) {
      const updatedCheckedList = [...checkedList];
      const currentItem = updatedCheckedList[itemIndex];

      if (property === 'delete') {
        currentItem.delete = currentItem.delete === 'possible' ? 'impossible' : 'possible';

        if (currentItem.delete === 'possible') {
          currentItem.update = 'possible';
          currentItem.create = 'possible';
        }
      } else if (property === 'update') {
        // 'update' 속성을 '가능'과 '불가능' 사이에서 토글
        currentItem.update = currentItem.update === 'possible' ? 'impossible' : 'possible';
        if (currentItem.update === 'impossible') {
          currentItem.delete = 'impossible';
        }else{
          currentItem.create ='possible'
        }
      } else if (property === 'create') {
        currentItem.create = currentItem.create === 'possible' ? 'impossible' : 'possible';
        if (currentItem.create === 'impossible') {
          currentItem.update = 'impossible';
          currentItem.delete = 'impossible';
        }
      } else if (property === 'read') {
        updatedCheckedList.splice(itemIndex, 1);
      }
      setCheckedList(updatedCheckedList);
    }
  };





  const singleCheckedFunction = (checked: boolean, item: any, setState: Function, state: any) => {
    if (checked) {
      setState((prev: any) => {
        return prev.some((el: any) => el.menu === item.menu) ? prev.filter((el: any) => el.menu !== item.menu) : [...prev, item];
      });
    } else {
      setState((prev: any) => prev.filter((el: any) => el.menu !== item.menu));
    }
  }

  return (
    <React.Fragment>
      {/*{dataModal.menu === 'select' &&*/}
      {/*  <SelectConfirmModal statusText={dataModal.message}*/}
      {/*                      noticeText={'"예" 클릭시 선택하신 모든 권한이 초기화됩니다.'}*/}
      {/*                      cancelEvent={()=>{setDataModal(initModal)}}*/}
      {/*                      clickEvent={()=>{setCheckedList([]); setDataModal(initModal)}}/>*/}
      {/*}*/}
      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <div style={{display:'flex',alignItems:'center',fontSize:'18px',fontWeight:900}}>
          <div>{'권한 설정'}</div>
          <div style={{color:'#0058FF',fontWeight:"normal",marginLeft:'8px'}}>
            ({dataValid(prevAuthName)})
          </div>
        </div>
        <div style={{display:'flex'}}>
          <div style={{opacity:0.5,fontSize:'12px',fontWeight:'normal',display:'flex',alignItems:'center'}}>{'* 권한별 메뉴 저장 필수'}</div>
          {/*<MainButtons margin={'0 0 0 16px'} selected={checkedList.length === 91} name={checkedList.length === 91  ? '전체 초기화' : '전체 선택'} */}
          {/*             clickEvent={handleSelectAll*/}
          {/*}/>*/}
          <MainButtons name={'저장'} marginDis={true} clickEvent={()=>{
            if( receiveCheckedList ){
              receiveCheckedList(checkedList);
            } clickEvent()}} disabled={disabled} margin={'0 0 0 16px'} buttonType={'cta'} width={'80px'}/>
        </div>
      </div>

      <GridTableComponent tempCol={'206px 288px 40px'} scrollX_Hide={true} scrollWidth={576} height={height ?? 572} >
        <SideMenu>
          {authMenuList.map((parent:any,i:number)=>(
            <div key={parent.title + i}>
              {parent.subTitle &&
                  <div className={'grid-title-unchecked'} style={{cursor:'pointer'}}
                       onMouseDown={()=>{
                         handleDivClick(parent.title);
                         const updatedHeights = [...menuHeights];
                         updatedHeights[i] = updatedHeights[i] === 0 ? calculateMenuHeight(i) : 0;
                         setMenuHeights(updatedHeights);}}>
                    <div>{parent.title}</div>
                    <div>{'권한'}</div>
                    <ArrowButton arrowDirection={selectedValues.includes(parent.title) ?  'bottom': 'top'}/>
                  </div>
              }
              <div style={{transition:'all 0.3s', height: `${selectedValues.includes(parent.title) ? menuHeights[i] : 0}px`, overflow:'hidden'}}>
                {parent.subTitle?.map((children:any,index:number)=>(
                  <div className={'border_menu'} key={children.title + index}>
                    <SubMenuTitle style={{opacity:disabled ? 0.5 : 1,pointerEvents:disabled ? 'none' : 'auto'}}>
                      <label className={'checkbox'} style={{display:'grid',gridTemplateColumns:'40px 1fr',alignItems:'center',cursor:'pointer'}}>
                        <input
                          type="checkbox"
                          checked={
                            (!children.subMenu || children.subMenu.length === 0) ?
                              checkedList.some(item => item.menu === children.menuIndex)
                              :
                              children.subMenu?.every((subMenuItem: { menuIndex: any; }) =>
                                                        checkedList.some(item => item.menu === subMenuItem.menuIndex)
                              )
                          }
                          onChange={() => toggleSubMenuTitle(i, index)}
                        />
                        <div>{children.title}</div>
                      </label>
                          <AuthButton select={checkedList.some(item => item.menu === children.menuIndex && item.read === 'possible')} disappear={!children.read}
                                      onClick={()=>{handleItemClick(children.menuIndex,'read')}}
                                      disable={disabled || !checkedList.some(item => item.menu === children.menuIndex)}>{'조회'}</AuthButton>
                          <AuthButton select={checkedList.some(item => item.menu === children.menuIndex && item.create === 'possible')} disappear={!children.create}
                                      onClick={()=>{handleItemClick(children.menuIndex,'create')}}
                                      disable={disabled || !checkedList.some(item => item.menu === children.menuIndex)}>{'등록'}</AuthButton>
                          <AuthButton select={checkedList.some(item => item.menu === children.menuIndex && item.update === 'possible')} disappear={!children.update}
                                      onClick={()=>{handleItemClick(children.menuIndex,'update')}}
                                      disable={disabled || !checkedList.some(item => item.menu === children.menuIndex)}>{'수정'}</AuthButton>
                          <AuthButton select={checkedList.some(item => item.menu === children.menuIndex && item.delete === 'possible')} disappear={!children.delete}
                                      onClick={()=>{handleItemClick(children.menuIndex,'delete')}}
                                      disable={disabled || !checkedList.some(item => item.menu === children.menuIndex)}>{'삭제'}</AuthButton>
                    </SubMenuTitle>

                    <div>
                      {children.subMenu?.map((sub:any,idx:number)=>(
                        <DetailMenuTitle key={sub.title + idx}  style={{opacity:disabled ? 0.5 : 1,pointerEvents:disabled ? 'none' : 'auto',}}>
                          <label className={'checkbox'} style={{display:'grid',gridTemplateColumns:'40px 1fr',alignItems:'center',cursor:'pointer'}}>
                            <input type="checkbox"
                                   checked={checkedList.some(item => item.menu === sub.menuIndex)}
                                   onChange={(e) => {singleCheckedFunction(e.target.checked, {menu:sub.menuIndex,read:sub.read,create:sub.create || 'impossible',update:sub.update|| 'impossible',delete:sub.delete|| 'impossible'}, setCheckedList, checkedList)}}/>
                            <div>{sub.title}</div>
                          </label>
                            <AuthButton select={checkedList.some(item => item.menu === sub.menuIndex && item.read === 'possible')} disappear={!sub.read}
                                        onClick={()=>{handleItemClick(sub.menuIndex,'read')}}
                                        disable={disabled || !checkedList.some(item => item.menu === sub.menuIndex)}>{'조회'}</AuthButton>
                            <AuthButton select={checkedList.some(item => item.menu === sub.menuIndex && item.create === 'possible')} disappear={!sub.create}
                                        onClick={()=>{handleItemClick(sub.menuIndex,'create')}}
                                        disable={disabled || !checkedList.some(item => item.menu === sub.menuIndex)}>{'등록'}</AuthButton>
                            <AuthButton select={checkedList.some(item => item.menu === sub.menuIndex && item.update === 'possible')} disappear={!sub.update}
                                        onClick={()=>{handleItemClick(sub.menuIndex,'update')}}
                                        disable={disabled || !checkedList.some(item => item.menu === sub.menuIndex)}>{'수정'}</AuthButton>
                            <AuthButton select={checkedList.some(item => item.menu === sub.menuIndex && item.delete === 'possible')} disappear={!sub.delete}
                                        onClick={()=>{handleItemClick(sub.menuIndex,'delete')}}
                                        disable={disabled || !checkedList.some(item => item.menu === sub.menuIndex)}>{'삭제'}</AuthButton>
                        </DetailMenuTitle>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </SideMenu>
      </GridTableComponent>
    </React.Fragment>
  );
};
export default PermissionSettingBox;

const SideMenu = styled.div`
  .border_menu{
    &>div:last-child>div:last-child{
      border-bottom: 1px solid #E6E9F4;
    }
  }
`
const SubMenuTitle = styled.div<{disabled?:boolean}>`
  display: grid;
  grid-template-columns: 220px 80px 80px 80px 80px;
  height: 48px;
  box-sizing: border-box;
  align-items: center;
  user-select: none;
  padding-left: 16px;
  font-size: 14px;
  font-weight: bold;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  border-bottom: 1px solid #E6E9F4;
  &:hover {
    background-color: rgba(0, 88, 255, 0.05);
    color: #0058ff;
  }
  & > label{
    height:48px;
    margin-right: 8px;
    border-right: 1px solid #E6E9F4;
    box-sizing: border-box;
  }
`

const DetailMenuTitle = styled.div<{disabled?:boolean, selected?:boolean}>`
  display: grid;
  grid-template-columns: 220px 80px 80px 80px 80px;
  user-select: none;
  height: 48px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  padding-left: 16px;
  font-size: 12px;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  color: ${props => props.selected? '#0058ff' : '#171725'};
  background-color: ${props => props.selected? 'rgba(0,88,255,0.05)' : 'transparent'};

  &:hover {
    background-color: rgba(0, 88, 255, 0.05);
    color: #0058ff;
  }
  & > label{
    height:48px;
    margin-right: 8px;
    border-right: 1px solid #E6E9F4;
    box-sizing: border-box;
  }
`
const AuthButton = styled.div<{select?:boolean,disable?:boolean,disappear?:boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height:32px;
  cursor: pointer;
  border-radius: 16px;
  font-size: 12px;
  font-weight: bold;
  visibility: ${p=>p.disappear ? 'hidden' : 'auto'};
  border: 1px solid ${props=>props.select ? '#0058FF' : '#E6E9F4'};
  background-color: ${props => props.select? '#0058FF' : 'transparent'};
  opacity: ${props => props.disable? 0.3 : 1};
  pointer-events: ${props => props.disable? 'none' : 'initial'};
  color:${props=>props.select? '#FFFFFF':'#171725'};
  &:hover{
    background-color: #0058FF;
    color: #FFF;
  }
  
`
