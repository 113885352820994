import React from 'react';
import styled from 'styled-components';
import {PartnerNoticeType} from "../../types/Fundamental";
import SolutionIcon from "../icon/SolutionIcon";
import OnlineStatus from "../display/OnlineStatus";

const PartnerRealTimeStatus:React.FunctionComponent<PartnerNoticeType> = ({ iconType,dateTime,clickEvent
                                                                              ,companyName,alarmMessage
                                                                              ,factoryName,alarmState
                                                                              ,facilityName,companyCode,makerNumber}) => {
    return (
        <Container>
            <div className={'solutionCircle'}>
                <SolutionIcon iconType={2} title={iconType}/>
            </div>
            <div style={{display:'flex',flexDirection:'column',gap:'4px'}}>
                <MainNoticeCont>
                    <div style={{display:'flex'}}>
                        <div>{companyName}</div>
                        <div>{'_' + factoryName}</div>
                    </div>
                    <div style={{display:'flex',gap:'8px',fontFamily:'Poppins'}}>
                        <OnlineStatus statusType={'lapseData'} title={dateTime} opacityCus={0.5}
                                      statusColor={alarmState === 'check' ? '#FFC700' :
                                                   alarmState === 'error' ? '#FFA900' :
                                                   alarmState === 'emergency' ? '#F0142F' :
                                                   alarmState === 'dailyCheck' ? '#D5D7E3' : ''}/>
                    </div>
                </MainNoticeCont>
                <div className={'inner_content'} onClick={()=>{clickEvent(companyCode)}}>
                    <div style={{display:'flex',gap:'8px'}}>
                        <div>{facilityName}</div>
                        <div>({makerNumber})</div>
                    </div>
                    <div>{alarmMessage}</div>
                </div>
            </div>
        </Container>
    );
};

export default PartnerRealTimeStatus;

const Container = styled.div`
  display: flex;
  max-width: 775px;
  box-sizing: border-box;
  height: 124px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 8px;
  margin-left: 5px;
  
  .solutionCircle{
    padding: 16px 0 0 10px;
  }
  
  
  .inner_content{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 725px;
    margin-left: 10px;
    height: 80px;
    box-sizing: border-box;
    padding: 13px 10px 8px 10px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 8px;
    &>div:nth-child(1){
      font-weight: 700;
      margin-bottom: 4px;
      font-size: 14px;
    }
    &:hover{
      box-shadow: 0 3px 6px #00000029;
      background-color: #F5F6FA;
      cursor: pointer;
      transition: all 150ms;
      &>div:nth-child(2){
        font-size: 14px;
      }
    }
  }
`

const MainNoticeCont = styled.div`
			font-size: 14px;
			color: #101010;
            padding: 20px 10px 0 10px;
            display: flex;
  line-height: 20px;
  align-items: center;
  height: 20px;
  width: 715px;
  justify-content: space-between;
  &>div:nth-child(1){
			font-weight: 700;
			margin-bottom: 4px;
		}
		&>div:nth-child(2){
			font-weight: 400;
			line-height: 20px;
		}
`