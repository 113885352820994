import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import {useLocation} from "react-router-dom";

import SolutionManagement from "../../../containers/admin/fas/solution/SolutionManagement";
import SolutionRequestDetail from "../../../containers/admin/fas/solution/SolutionRequestDetail";
import SolutionRequestRegister from "../../../containers/admin/fas/solution/SolutionRequestRegister";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const AdminFasSolutionReq = () => {
  const location = useLocation();
  const locationId = location.pathname.length
  const register = location.pathname.includes('register')
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {locationId === 19 ?
        <SectionContainer contentContainer={<SolutionManagement/>}/>
        :
        register ?
          <SectionContainer contentContainer={<SolutionRequestRegister/>}/>
          :
          <SectionContainer contentContainer={<SolutionRequestDetail/>}/>
      }
    </PageContainer>
  );
};

export default AdminFasSolutionReq;
