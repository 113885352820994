import React from 'react';
import PartnerSelectModal from "../modal/PartnerSelectModal";
import {useRecoilValue} from "recoil";
import {persistPartnersData} from "../../common/StateManage";
import {
  ContentFrame ,
  listResultMessage ,
  tooltipModule , validationValue
} from "../../style/StyleComponent";
import MenuTitle from "../title/MenuTitle";
import MainButtons from "../button/MainButtons";
import {useTranslation} from "react-i18next";
import {dataValid} from "../../function/function";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface Props {
  partnerData: (data:any)=>void
  resetEvent?:()=>void
  viewType?:boolean
  basicButton?: boolean
}

const PartnerInfoComponent = ({partnerData,resetEvent,basicButton,viewType}:Props) => {
  const { t }:any = useTranslation();
  const partnersTitle = ['지사/협력사명','대표','주소','전화번호','이메일','관리자 ID','업체코드','권한']
  const [searchCompany, setSearchCompany] = React.useState<boolean>(false)
  const selectedPartners= useRecoilValue(persistPartnersData)
  const scrollRef = React.useRef<HTMLDivElement>(null);

  return (
    <React.Fragment>
      {
        basicButton?
          <React.Fragment>
            {searchCompany && <PartnerSelectModal receivedCompany={partnerData} closeButton={()=>setSearchCompany(false)}/>}
            <MainButtons name={'지사/협력사 선택'} width={'120px'} marginDis={true} clickEvent={()=>{setSearchCompany(true);if (resetEvent) {resetEvent()}}}/>
          </React.Fragment>
          :
          !viewType ?
            <React.Fragment>
              {searchCompany  && <PartnerSelectModal receivedCompany={partnerData} closeButton={()=>setSearchCompany(false)}/>}
              <ContentFrame height={'208px'} padding={'30px 30px 16px 30px'}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <MenuTitle title={'지사/협력사 정보'} unitCase={'MenuCompTitle'}/>
                  <MainButtons name={'지사/협력사 선택'} width={'120px'} marginDis={true}
                               clickEvent={()=>{setSearchCompany(true);
                                 if (resetEvent) {resetEvent()}}}/>
                </div>
                <GridTableComponent tempCol={'128px 88px 288px 148px 1fr 120px 128px 88px'} scrollWidth={1210} height={106} ref={scrollRef} scrollX_Hide={true}
                                    scrollY_Hide={true}>
                  <div className={'grid-title-unchecked'}>
                    {partnersTitle.map((v)=>(
                      <div key={v}>{t(v)}</div>
                    ))}
                  </div>
                  {!selectedPartners.companyCode?
                    listResultMessage(true,t('* 지사/협력사를 선택해 주세요.'),{width:1210, admin:50,user:0})
                    :
                    <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                      {tooltipModule(dataValid(selectedPartners?.companyName))}
                      {tooltipModule(dataValid(selectedPartners?.president),7)}
                      {tooltipModule(dataValid(selectedPartners?.address),10)}
                      {validationValue(selectedPartners?.phoneNumber)}
                      {tooltipModule(dataValid(selectedPartners?.email),10)}
                      {tooltipModule(dataValid(selectedPartners?.id),10)}
                      {tooltipModule(dataValid(selectedPartners?.companyCode))}
                      <div>{selectedPartners?.authority === 'admin' ? '관리자' : '협력사'}</div>
                    </GridTableSelect>
                  }
                </GridTableComponent>
              </ContentFrame>
            </React.Fragment>
            :
            <React.Fragment>
              {searchCompany && <PartnerSelectModal receivedCompany={partnerData} closeButton={()=>setSearchCompany(false)}/>}
              <ContentFrame height={'208px'} padding={'30px 30px 16px 30px'}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                  <MenuTitle title={'지사/협력사 정보'} unitCase={'MenuCompTitle'}/>
                  <MainButtons name={'지사/협력사 선택'} width={'120px'} marginDis={true} clickEvent={()=>{setSearchCompany(true);if (resetEvent) {resetEvent()}}}/>
                </div>
                <GridTableComponent tempCol={'128px 88px 288px 148px 1fr 120px 128px 88px'} scrollWidth={1210} height={106} ref={scrollRef} scrollX_Hide={true}
                                    scrollY_Hide={true}>
                  <div className={'grid-title-unchecked'}>
                    {partnersTitle.map((v)=>(
                      <div key={v}>{t(v)}</div>
                    ))}
                  </div>
                  {!selectedPartners.companyCode?
                    listResultMessage(true,t('* 지사/협력사를 선택해 주세요.'),{width:1210, admin:50,user:0})
                    :
                    <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                      {tooltipModule(dataValid(selectedPartners?.companyName))}
                      {tooltipModule(dataValid(selectedPartners?.president),7)}
                      {tooltipModule(dataValid(selectedPartners?.address),10)}
                      {validationValue(selectedPartners?.phoneNumber)}
                      {tooltipModule(dataValid(selectedPartners?.email),10)}
                      {tooltipModule(dataValid(selectedPartners?.id),10)}
                      {tooltipModule(dataValid(selectedPartners?.companyCode))}
                      <div>{selectedPartners?.authority === 'admin' ? '관리자' : '협력사'}</div>
                    </GridTableSelect>
                  }
                </GridTableComponent>
              </ContentFrame>
            </React.Fragment>
      }
    </React.Fragment>
  );
}
export default PartnerInfoComponent;
