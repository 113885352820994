import React from 'react';
import styled from "styled-components";
import {Modal_View_Type} from "../../types/Fundamental";
import {authValid , dataValid , initModal} from "../../function/function";
import {useRecoilState , useRecoilValue} from "recoil";
import {persistCompanyData , searchInputState , userPersistCompanyData} from "../../common/StateManage";
import ToastPopup from "../modal/ToastPopup";
import clear from "../../asset/image/refresh.svg";
import search from "../../asset/image/ic-search.svg";
import DropMenu from "../sideMenu/DropMenu";

interface SearchProps {
  keyFunction?: (e:React.ChangeEvent<HTMLInputElement>) => void
  value?: string|number|null
  name?: string|number
  widthCus?: string
  id?: string|number
  width?: string
  inputWidth?: string
  clickEvent?:()=>void
  placeholder?:string
  disabled?: boolean
  clearButton?: ()=>void
  clearButtonAccess?: boolean
  margin?: string
  limit?: number
  focus?: boolean
  minWidth?: string
  keyPressDisable?:boolean
  categoryValueArray?:string[] | any[] | any
  categoryTypeArray?:string[] | any[] | any
  selectKeyword?:string
  categorySelectEvent:(value:string,type:string | any)=>void
}


const FilterWithSearchBar = ({keyFunction, value, name, id, disabled,keyPressDisable,categoryValueArray,selectKeyword,
                               width, widthCus,inputWidth, clickEvent,placeholder,minWidth,categorySelectEvent,categoryTypeArray,
                               clearButtonAccess, clearButton, margin,limit, focus}:SearchProps) => {

  const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [, setSearchInput] = useRecoilState<string>(searchInputState);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)

  React.useEffect(() => {
    if(focus ?? selectedCompany.companyCode){
      inputRef.current?.focus();
    }
  }, []);

  const keyPressEvent =  (e:any) => {
    if(keyPressDisable){
      if(e.key === 'Enter'){
        if( clickEvent ){
          clickEvent()
        }
      }
    }else{
      if(e.key === 'Enter'){
        if(['#', '%', '[', ']', '/', '?', ';',`\\`].some((v)=>(dataValid(value,'').includes(v)))){
          setDataModal({menu:'warn', message:'#,%,[,],/,?,;,\\이 포함된 문자는 검색할 수 없습니다.'})
          setSearchInput('')
        }
        else if(value === '.' || value === '..'){
          setDataModal({menu:'warn', message:'"." 혹은 ".." 은 검색할 수 없습니다.'})
          setSearchInput('')
        }
        else{
          if( clickEvent ){
            clickEvent()
          }
        }
      }
    }
  }

  const clickSearchButton = () => {
    if(keyPressDisable){
      if( clickEvent ){
        clickEvent()
      }
    }else{
      if(['#', '%', '[', ']', '/', '?', ';',`\\`].some((v)=>(dataValid(value,'').includes(v)))){
        setDataModal({menu:'warn', message:'#,%,[,],/,?,;,\\이 포함된 문자는 검색할 수 없습니다.'})
        setSearchInput('')
      }
      else if(value === '.' || value === '..'){
        setDataModal({menu:'warn', message:'"." 혹은 ".." 은 검색할 수 없습니다.'})
        setSearchInput('')
      }
      else{
        if( clickEvent ){
          clickEvent()
        }
      }
    }
  }



  const test = ['all','partner','president','address','phoneNumber','email','id','auth']
  return (
    <div style={{display:'flex',position:"relative",paddingLeft:'120px'}}>
      <DropMenu menuArray={categoryValueArray}
                type={'processWave'}// marginRight 추가
                //무조건 전체는 '@'
                selectValue={dataValid(selectKeyword,'전체')}
                left={'0'}
                top={'0'}
                persistWeight={800}
                width={'120px'}
                clickEvent={(data,index)=> {
                  categorySelectEvent(data,categoryTypeArray.find((item:any,i:number) => i === index))
                }
                }/>
        {dataModal.menu === 'warn' && <ToastPopup text={(dataModal.message)} state={'warn'} timeSet={1000}
                                                  closeEvent={()=> {setDataModal(initModal)}}/>}
        <BaseComponent disabled={disabled}  marginCus={margin} minWidth={minWidth}
                       style={{width:width, maxWidth:dataValid(widthCus, '192px')}}>
          {/*@ts-ignore*/}
          <input ref={inputRef} type="text"  placeholder={dataValid(placeholder,'검색')} onChange={keyFunction} value={value} id={id} name={name} width={inputWidth}
                 onKeyDown={keyPressEvent} maxLength={limit} />
          {clearButtonAccess &&	<div style={{backgroundImage:`url(${clear})`,minWidth:"24px"}} className={'search_button'}
                                      onClick={clearButton}/>}

          <div style={{backgroundImage:`url(${search})`,minWidth:"24px"}} className={'search_button'} onClick={clickSearchButton}/>
        </BaseComponent>
    </div>
  );
};
export default FilterWithSearchBar;
interface Style_Props {
  width?: string
  inputWidth?: string
  disabled?:boolean
  marginCus?: string
  minWidth?: string
}

const BaseComponent = styled.div<Style_Props>`
		display: flex;
		box-sizing: border-box;
		border: 1px solid #D7DBEC;
		border-radius: 4px;
		height: 32px;
	  margin: ${props => props.marginCus ?? '0 8px'};
		min-width: ${props => props.minWidth ?? '192px'};
		background-color: #ffffff;
		width: ${props => props.width};
		align-items: center;
    position: relative;
		opacity: ${p=>p.disabled ? 0.3 : 1};
		pointer-events: ${p=>p.disabled ? 'none' : 'auto'};
		user-select: ${p=>p.disabled ? 'none' : 'auto'};
	
		input{
			width: ${props => props.inputWidth ? props.inputWidth : '100%'};
			padding: 0 4px 0 8px;
			outline: none;
			border: none;
			font-family: "Nanum Gothic", sans-serif;
			&::placeholder{
				font-size: 14px;
				color: rgba(90,96,127,0.3);
			}
		}
		.search_button{
			width: 24px;
			height: 24px;
			margin-right: 4px;
			cursor: pointer;
			background-color: transparent;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	`