import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import MoldManagement from "../../../containers/admin/fas/company/mold/MoldManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useLocation} from "react-router-dom";
import MoldRegistration from "../../../containers/admin/fas/company/mold/MoldRegistration";
import MoldDetail from "../../../containers/admin/fas/company/mold/MoldDetail";

const FasMold = () => {
  const location = useLocation()
  const {pathname} = location

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {pathname.length === (location.pathname.includes('/admin') ? 15 : 9) ?
      <SectionContainer contentContainer={<MoldManagement/>}/>
        :
        location.pathname.includes('register') ?
        <SectionContainer contentContainer={<MoldRegistration/>}/>
          :
          <SectionContainer contentContainer={<MoldDetail/>}/>
      }
    </PageContainer>
  );
};

export default FasMold;