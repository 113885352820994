import React , {useState} from 'react';
import {
	ContentFrame ,
	GridTableComponent , GridTableSelect ,
	listResultMessage ,
	tooltipModule ,
	validationValue
} from "../../style/StyleComponent";
import MenuTitle from "../title/MenuTitle";
import MainButtons from "../button/MainButtons";
import {useRecoilValue} from "recoil";
import {persistCompanyData} from "../../common/StateManage";
import {dataValid , locationFilter} from "../../function/function";
import {useTranslation} from "react-i18next";
import CompanySelectModal from "../modal/CompanySelectModal";
import ShowMore from "../../style/ShowMore";
import PartnerSelectModal from "../modal/PartnerSelectModal";

interface Props {
	companyData: (data:any)=>void
	resetEvent?:()=>void
	viewType?:boolean
	basicButton?: boolean
}
const CompanyInfoComponent:React.FunctionComponent<Props> = ({companyData,resetEvent,viewType, basicButton}) => {
	const { t }:any = useTranslation();

	const companyTitle = ['업체명','대표','주소','전화번호','담당자','담당자 전화번호','담당자 이메일','관리자 ID','업체 코드','업체로고']
	const partnersTitle = ['지사/협력사명','대표','주소','전화번호','이메일','관리자 ID','업체코드','업체로고']
	const [searchCompany, setSearchCompany] = React.useState<boolean>(false)
	const selectedCompany= useRecoilValue(persistCompanyData)
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const [isScrolling, setIsScrolling] = useState<boolean>(true);


	const handleScroll = () => {
		//확인된 최대값 숫자 넣기
		if(scrollRef?.current?.scrollLeft === 131){
			setIsScrolling(false)
		}else{
			setIsScrolling(true)
		}
	};

	return (
		<React.Fragment>
			{
				basicButton?
					<React.Fragment>
						{searchCompany && <CompanySelectModal receivedCompany={companyData} closeButton={()=>setSearchCompany(false)}/>}
						<MainButtons name={'업체 선택'} width={'80px'} marginDis={true} clickEvent={()=>{setSearchCompany(true);if (resetEvent) {resetEvent()}}}/>
					</React.Fragment>
				:
				!viewType ?
				<React.Fragment>
					{searchCompany && <CompanySelectModal receivedCompany={companyData} closeButton={()=>setSearchCompany(false)}/>}
					<ContentFrame height={'208px'} padding={'30px 30px 16px 30px'}>
						<div style={{display:'flex', justifyContent:'space-between'}}>
								<MenuTitle title={'업체정보'} unitCase={'MenuCompTitle'}/>
								<MainButtons name={'업체 선택'} width={'80px'} marginDis={true} clickEvent={()=>{setSearchCompany(true);if (resetEvent) {resetEvent()}}}/>
						</div>

							<ShowMore showMore={isScrolling} width={50} height={95} right={30} top={78} horizonType={true}/>
						<GridTableComponent tempCol={'128px 88px 200px 120px 88px 120px 160px 88px 108px 80px'} onScroll={handleScroll}
																scrollWidth={1341} height={106} ref={scrollRef}
																scrollY_Hide={true}>
							<div className={'grid-title-unchecked'}>
								{companyTitle.map((v)=>(
									<div key={v}>{t(v)}</div>
								))}
							</div>
							{!selectedCompany.companyCode?
								listResultMessage(true,t('* 업체를 선택해 주세요.'),{width:1210, admin:50,user:0})
								:
								<GridTableSelect className={'grid-list-unchecked-no_icon'}>
									{tooltipModule(dataValid(selectedCompany?.companyName))}
									{tooltipModule(dataValid(selectedCompany?.president),7)}
									{tooltipModule(dataValid(selectedCompany?.address),10)}
									{validationValue(selectedCompany?.phoneNumber)}
									{validationValue(selectedCompany?.managerName)}
									{validationValue(selectedCompany?.managerNumber)}
									{tooltipModule(dataValid(selectedCompany?.email),10)}
									{tooltipModule(dataValid(selectedCompany?.id),10)}
									{tooltipModule(dataValid(selectedCompany?.companyCode))}
									<div>{selectedCompany?.logoImageUrl !== '' ? '-' : 'O' ?? '-'}</div>
								</GridTableSelect>}
						</GridTableComponent>
					</ContentFrame>
				</React.Fragment>
				:
				<React.Fragment>
					{searchCompany &&
						<CompanySelectModal receivedCompany={companyData} closeButton={()=>setSearchCompany(false)}/>}
					<ContentFrame height={'208px'} width={'416px'} padding={'30px 30px 16px 30px'}>
						<div style={{display:'flex', justifyContent:'space-between'}}>
							<MenuTitle title={'업체정보'} unitCase={'MenuCompTitle'}/>
							<MainButtons name={'업체 선택'} width={'80px'} marginDis={true}
													 clickEvent={()=>{setSearchCompany(true); if(resetEvent) {resetEvent()}}}/>
						</div>
						<GridTableComponent tempCol={'72px 88px 1fr'} scrollY_Hide={true} scrollX_Hide={true} scrollWidth={356}>
							<div className={'grid-title-unchecked'}>
								{['업체명','대표','주소'].map((v)=>(
									<div key={v}>{t(v)}</div>
								))}
							</div>
							{!selectedCompany.companyCode?
								listResultMessage(true, t('* 업체를 선택해 주세요.'),{width:356, admin:60, user:0})
								:
								<GridTableSelect className={'grid-list-unchecked-no_icon'}>
									{tooltipModule(dataValid(selectedCompany?.companyName))}
									{validationValue(selectedCompany?.president)}
									<div>{locationFilter(selectedCompany?.address)}</div>
								</GridTableSelect>}
						</GridTableComponent>
					</ContentFrame>
				</React.Fragment>
			}

		</React.Fragment>
	);
};

export default CompanyInfoComponent;
