import React from 'react';
import {PageContainer} from '../../style/StyleComponent'
import IntegratedSideMenu from '../../components/sideMenu/IntegratedSideMenu'
import SectionContainer from '../../containers/common/SectionContainer'
import SmartFactoryLogContainer from '../../containers/admin/fas/SmartFactoryLogContainer'

const SmartFactoryLogPage = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={
        <SmartFactoryLogContainer/>
      } gradeDisabled={true}/>
    </PageContainer>
  );
};
export default SmartFactoryLogPage;
