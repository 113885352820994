import React from 'react';
import ModalBase from "../ModalBase";
import styled from "styled-components";
import {modalTitleBase} from "../../../style/StyleComponent";
import {useRecoilValue} from "recoil";
import {authValid , dataValid} from "../../../function/function";
import {persistCompanyData , userPersistCompanyData} from "../../../common/StateManage";
import MainButtons from "../../button/MainButtons";

const SelectedCompanyInfoModal:React.FunctionComponent<{closeEvent: ()=>void}> = ({closeEvent}) => {

  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)

  const infoGridCell = (title: string, value: string) => {
    return (
      <React.Fragment>
        <div className={'grid_info_title'}>{dataValid(title)}</div>
        <div className={'grid_info_value'}>{dataValid(value)}</div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle:'업체 정보 : '}, <div style={{color: authValid()? '#0058ff' : '#fff', fontWeight:700, marginLeft:'8px'}}>{dataValid(selectedCompany.companyName)}</div>)}
          <div className={'modal_content'}>
            <InfoGrid>
              {infoGridCell('업체명', selectedCompany.companyName)}
              {infoGridCell('업체코드', selectedCompany.companyCode)}
              {infoGridCell('대표', selectedCompany.president)}
              {infoGridCell('주소', selectedCompany.address)}
              {infoGridCell('전화번호', selectedCompany.phoneNumber)}
              {infoGridCell('담당자', selectedCompany.managerName)}
              {infoGridCell('담당자 전화번호', selectedCompany.managerNumber)}
              {infoGridCell('담당자 이메일', selectedCompany.email)}
              {infoGridCell('관리자 ID', selectedCompany.id)}
            </InfoGrid>
            <div style={{display:'flex', justifyContent:'center', marginTop:'24px'}}>
              <MainButtons name={'확인'} buttonType={'popupCta'} height={'40px'} width={'144px'} clickEvent={closeEvent}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default SelectedCompanyInfoModal;

const BaseComponent = styled.div`
  width: 416px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    user-select: none;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr ;
  border-top: 1px solid #E6E9F4;
  .grid_info_title{
    background-color: #F5F6FA;
    height: 48px;
    color: #131523;
    font-weight: 700;
    font-size: 12px;
    white-space: pre-line;
    word-break: keep-all;
    box-sizing: border-box;
    padding-left: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E9F4;
  }
  
  .grid_info_value{
    box-sizing: border-box;
    padding-left: 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E6E9F4;
    font-size: 14px;
    white-space: pre-line;
    word-break: keep-all;
  }
`