import React from 'react';
import styled from "styled-components";
import {OnlineStatusProps} from "../../types/Fundamental";
import * as timeago from 'timeago.js'
// import ko from 'timeago.js/lib/lang/ko'
import en from 'timeago.js/lib/lang/en_US'
import TimeAgo from "timeago-react";
import TimeDayModule from "../unit/TimeDayModule";

const OnlineStatus:React.FunctionComponent<OnlineStatusProps> = ({statusType, opacityCus,statusColor, title}) => {

	timeago.register('en', en)

	const statusCreateComp = (type: string, statusColor: string, title: string) => {
		switch (type) {
			case "noticeType": return (
				<BaseComponent>
					<div className={'statusColor'} style={{backgroundColor: statusColor, marginLeft:'40px'}}/>
					<div style={{fontSize:'14px', fontFamily:'Poppins',color:'#5A607F'}}>{title}</div>
				</BaseComponent>
			)

			case "lapseData": return (
				<BaseComponent>
					<div className={'statusColor'} style={{backgroundColor: statusColor}}/>
					<div style={{fontSize:'14px',color:'#101010',fontFamily:'Arial',opacity:`${opacityCus}`}}>
						{/*<TimeAgo datetime={title} locale={'en'}/>*/}
						<TimeDayModule alarmedTime={title} selectLocale={true}/>
					</div>
				</BaseComponent>
			)

			case "alarmStatus": return (
				<BaseComponent>
					<div className={'statusColor'} style={{backgroundColor: statusColor, width:'16px', height:'16px'}}/>
					<div style={{fontSize:'12px',color:'#707070',fontWeight:700}}>{title}</div>
				</BaseComponent>
			)
		}
	}




	return (
		<React.Fragment>
			{statusCreateComp(statusType, statusColor, title)}
		</React.Fragment>
	);
};

export default OnlineStatus;

	const BaseComponent = styled.div`
		display: flex;
		align-items: center;
		user-select: none;
		.statusColor{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			margin-right: 8px;
			margin-bottom: 1px;
		}
	`