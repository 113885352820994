import React , {useState} from 'react';
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal , loadingRemove ,
} from "../../../../../function/function";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame ,SectionFrame} from '../../../../../style/StyleComponent';
import {useParams} from "react-router-dom";
import {AccountInfoType , Modal_View_Type} from "../../../../../types/Fundamental";
import styled from "styled-components";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import PreviewImage from "../../../../../components/display/PreviewImage";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import client from "../../../../../common/Config";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {Authorization} from "../../../../../common/authorization";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import {useTranslation} from "react-i18next";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";

const AccountDetail = () => {
  const {t}:any =useTranslation()
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [selectPage, setSelectPage] = useState<number>(0)
  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
  const params = useParams()
  const {customerCode} = params
  const [customerDetail, setCustomerDetail] = useState<AccountInfoType | any>({})
  const [deleteCaseArray, setDeleteCaseArray] = useState<any>([])
  const [customerInitState, setCustomerInitState] = useState<AccountInfoType | any>({})

  const onChange = (e: React.ChangeEvent<HTMLInputElement>,  trim?: boolean) => {
    const {name, value} = e.target
    setCustomerDetail({...customerDetail, [name]: trim? value.trim() : value})
  }

  //거래처 디테일
  const accountDetailRes = async () => {
    try{
      const response = await client.get(  `/customer/detail/${customerCode}` ,
                                          {headers: {'Authorization': Authorization}} )
      const data = response.data;
        setCustomerDetail( data.data )
       setCustomerInitState( data.data)
    } catch( error ){
      console.log( `error` , error )
    }
  }
  React.useEffect(() => {
    accountDetailRes().then()
  }, [])


  const addressHandle = {
    clickBtn : () => setDataModal({menu:'address',message:'주소를 입력해 주세요.'}),
    selectAddress: (data: any) => {
      customerDetail.address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
      setCustomerDetail({...customerDetail,detailAddress:''})
      setDataModal(initModal);
    },
  }
  const AddressStyle:React.CSSProperties|undefined ={
    display:'block',
    width:500,
    height:600,
    position:'absolute',
    boxShadow:'0 1px 7px #E6E9F4',
    borderRadius:'8px',
    backgroundColor:'#fff',
    padding: "7px",
    zIndex: 100,
  }

  const customerUpdateRes = async () => {
    if(!customerDetail.customerName.trim()){
      setDataModal({menu:'confirm', message:'거래처명을 입력해 주세요.'})
    }
    else if(!customerDetail.customerName.trim()){
      setDataModal({menu:'confirm', message:'대표자를 입력해 주세요.'})
    }
    else if(!customerDetail.managerName.trim()){
      setDataModal({menu:'confirm', message:'담당자를 입력해 주세요.'})
    }
    else if(!customerDetail.managerNumber){
      setDataModal({menu:'confirm', message:'담당자를 번호를 입력해 주세요.'})
    }
    else if(!customerDetail.address){
      setDataModal({menu:'confirm', message:'주소를 선택해 주세요.'})
    }
    else if(customerDetail.businessNumber && customerDetail.businessNumber.length !== 12){
      setDataModal({menu:'confirm', message:'사업자 번호 형식이 맞지 않습니다.'})
    }
    else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post('/customer/update', {
          ...customerDetail,
          companyCode: selectedCompany.companyCode,
          customerCode: customerCode
        },{headers:{Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu:'confirm',message: data.message})
          loadingRemove()
        }else{
          setTimeout(()=>{
            setDataModal({menu:'success',message:'수정 되었습니다.'})
            setSelectPage(0)
            accountDetailRes()
            loadingRemove()
          },500)
        }
      }catch (e:any){
        console.log(e)
        setDataModal({menu:'error',message:e.message})
        loadingRemove()
      }
    }
  }

  const customerDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( '/customer/delete' , [customerCode],
                                          {headers: {'Authorization': Authorization}} )
      const data = response.data
      const customerModelArray = data.data?.customerModel?.map((v:any)=>({code:v, case:'등록된 모델이 존재합니다.'}))

      if(data.status === 200){

        setTimeout( () => {
          setDataModal( {menu: 'success' , message: '거래처가 삭제 되었습니다.'} )
          loadingRemove()
        } , 500 )
        setTimeout( () => {
          window.location.href = `${authValid() ? '/admin/fas/account' : '/fas/account'}`
        } , 1500 )
      } 	else if(data.status === 601){
        setTimeout(()=>{
          setDeleteCaseArray(customerModelArray)
          setDataModal( {menu: 'deleteCase'} )
          loadingRemove()
        }
          ,500)
      }
      else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    }
    catch ( e:any ){
      if(e.response.status === 500){
        setDataModal({menu:'confirm',message:'사용중인 거래처 정보가 존재합니다.'})
      }
      console.log(e)
      loadingRemove()
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}
      {dataModal.menu === 'bankbook' && <DetailImageView image={customerDetail.bankBookUrl} closeMenu={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'business' && <DetailImageView image={customerDetail.businessRegistrationUrl} closeMenu={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'address' &&
          <ModalBase clickEvent={() => setDataModal( initModal )}
                     modalContent={<DaumPostcode onComplete={addressHandle.selectAddress}
                                                 style={AddressStyle}
                                                 autoClose={false}/>}/>}

      {dataModal.menu === 'remove' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
                                                          cancelEvent={()=>setDataModal(initModal)}
                                                          clickEvent={()=>{customerDeleteRes().then(); setDataModal(initModal)}}/>}
      {dataModal.menu === 'modify'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={customerUpdateRes}/>}

      {dataModal.menu ==='back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 저장없이 이동합니다.'}
                              viewType={true}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{
                                setCustomerDetail(customerInitState)
                                setDataModal(initModal)
                                setSelectPage(0)
                              }
                              }/>}
      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'거래처명'} caseArray={deleteCaseArray} closeEvent={()=>setDataModal(initModal)}/>}
        {selectPage === 0 ?
          <React.Fragment>
        <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 기본정보 > 조회'}
                 unitCase={'MenuTitle'}/>
      <ContentFrame height={'680px'}>
        <div style={{display: 'flex' , height: '620px'}}>
          <RegisterComp>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'거래처 기본정보 조회'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <div style={{display: 'flex'}}>
                  <MainButtons name={'목록'} width={'56px'} clickEvent={() => {
                    window.location.href = `${authValid() ? '/admin/fas/account' : '/fas/account'}`
                  }}/>
                  <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                               disabled={buttonAuthValid( 'update' , '7' )}
                               clickEvent={() => {setSelectPage( 1 )}}/>
                </div>
              </div>
            </div>
            <div style={{display:'grid',gridTemplateColumns:'repeat(2,1fr)'}}>
            <ListInfoComponent title={'거래처명'} value={dataValid( customerDetail?.customerName )}/>
            <ListInfoComponent title={'거래처 구분'} value={dataValid( customerDetail?.division  )}/>
            <ListInfoComponent title={'대표자명'} value= {dataValid( customerDetail?.president )}/>
            <ListInfoComponent title={'담당자명'} value={dataValid( customerDetail?.managerName )}/>
            <ListInfoComponent title={'전화번호(거래처)'} value={dataValid( customerDetail?.phoneNumber )}/>
            <ListInfoComponent title={'휴대폰번호(담당자)'} value={dataValid( customerDetail?.managerNumber )}/>
            </div>
            <ListInfoComponent title={'FAX'} value={dataValid( customerDetail?.fax )}/>
            <ListInfoComponent title={'주소'} value={dataValid( customerDetail?.address )}/>
            <ListInfoComponent title={'상세주소'} value={dataValid( customerDetail?.detailAddress )}/>
            <ListInfoComponent title={'사업자번호'} value={dataValid( customerDetail?.businessNumber)} bottomBorder={true}/>


          </RegisterComp>
          <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
          {/*@ts-ignore*/}
          <div className={'scroll_comp'} style={{width: '379px' , overflowY: 'overlay'}}>
            <div style={{display: 'flex', flexDirection:'column', gap: '30px' , marginTop: '20px'}}>
              <div style={{display: 'flex' , flexDirection:'column',gap:'10px'}}>
                <MenuTitle title={'사업자 등록증'} unitCase={'MenuCompTitle'}/>
                <PreviewImage imageUrl={customerDetail?.businessRegistrationUrl}
                              clickEvent={() => {setDataModal( {menu: 'business'} )}}
                              deleteImg={() => ''} noneDelete={true}/>

              </div>
              <div style={{display: 'flex' , flexDirection: 'column' , gap: '10px'}}>
                <MenuTitle title={'통장 사본'} unitCase={'MenuCompTitle'}/>
                <PreviewImage imageUrl= {customerDetail?.bankBookUrl}
                              clickEvent={() => {setDataModal( {menu: 'bankbook'} )}}
                              deleteImg={() => ''} noneDelete={true}/>
              </div>
            </div>
          </div>
        </div>
      </ContentFrame>
        </React.Fragment>
        :

        // 수정 페이지
        <React.Fragment>
        <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 기본정보 > 수정'}
                   unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'거래처 기본정보 수정'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={() => {
                        setDataModal( {menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )
                      }}/>
                      <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
                                   clickEvent={() => {setDataModal( {menu: 'remove' , message: '거래처를 삭제 하시겠습니까?'} )}}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   clickEvent={() => {setDataModal( {menu: 'modify' , message: '이대로 수정 하시겠습니까?'} )}
                                   }/>
                    </div>
                  </div>
                </div>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                  <RegisterComponent type={'text'} title={'거래처명'} name={'customerName'} paddingRight={true}
                                     value={customerDetail?.customerName} required={true}
                                     onChange={onChange}/>
                  <RegisterComponent type={'text'} title={'거래처 구분'} name={'division'} value={customerDetail?.division}
                                     onChange={onChange}/>
                  <RegisterComponent type={'text'} title={'대표자명'} name={'president'} value={customerDetail?.president}
                                     required={true} paddingRight={true}
                                     onChange={onChange}/>
                  <RegisterComponent type={'text'} title={'담당자명'} name={'managerName'}
                                     value={customerDetail?.managerName} required={true}
                                     onChange={onChange}/>
                  <RegisterComponent type={'phone'} title={'전화번호(거래처)'} name={'phoneNumber'}
                                     value={customerDetail?.phoneNumber} paddingRight={true}
                                     numberFormat={customerDetail.phoneNumber}
                                     onChange={(e)=>onChange(e,true)}/>
                  <RegisterComponent type={'phone'} title={'휴대폰번호(담당자)'} name={'managerNumber'}
                                     value={customerDetail?.managerNumber} required={true}
                                     numberFormat={customerDetail.managerNumber}
                                     onChange={(e)=>onChange(e,true)}/>
                </div>
                  <RegisterComponent type={'phone'} title={'FAX'} name={'fax'}
                                     numberFormat={customerDetail.fax}
                                     onChange={(e)=>onChange(e,true)}
                                     value={customerDetail?.fax}/>
                  <RegisterComponent type={'address'} title={'주소'} name={'address'}
                                     value={customerDetail?.address} subName={'detailAddress'} subValue={customerDetail?.detailAddress}
                                     required={true}
                                     onChange={onChange} clickEvent={addressHandle.clickBtn}/>
                  <RegisterComponent type={'bussinessNumber'} title={'사업자 번호'} name={'businessNumber'}
                                     value={customerDetail?.businessNumber}
                                     onChange={onChange} bottomBorder={true}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0', margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'} style={{width: '379px' , height: '600px' }}>
                  <div style={{display: 'flex' , flexDirection: 'column' , gap: '30px' , marginTop: '20px'}}>
                    <div style={{display: 'flex' , flexDirection: 'column' , gap: '10px'}}>
                      <MenuTitle title={'사업자 등록증'} unitCase={'MenuCompTitle'}/>
                      <SingleImageUploadBox imageKey={(v)=>setCustomerDetail((prev:any)=>({...prev, businessRegistration:v}))}
                                            receiveKey={customerDetail?.businessRegistration}
                                            receiveUrl={customerDetail?.businessRegistrationUrl} inputSize={168} profileSelect={false}/>

                    </div>
                    <div style={{display: 'flex' , flexDirection: 'column' , gap: '10px'}}>
                      <MenuTitle title={'통장 사본'} unitCase={'MenuCompTitle'}/>
                      <SingleImageUploadBox imageKey={(v)=>setCustomerDetail((prev:any)=>({...prev, bankBook:v}))}
                                            receiveKey={customerDetail?.bankBook}
                                            receiveUrl={customerDetail?.bankBookUrl} inputSize={168} profileSelect={false}/>

                    </div>
                  </div>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>}

    </SectionFrame>
  )
    ;
};
export default AccountDetail;

const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`