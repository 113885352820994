import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import ProcessList from "../../../containers/admin/fas/company/process/ProcessList";
import ProcessRegister from "../../../containers/admin/fas/company/process/ProcessRegister";
import {useLocation} from "react-router-dom";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const AdminFasCompanyProcess = () => {

	const location = useLocation()
	const {pathname} = location

	return (
		<PageContainer>
			<IntegratedSideMenu/>
			{pathname.length === 18?
				<SectionContainer contentContainer={<ProcessList/>}/>
				:
				<SectionContainer contentContainer={<ProcessRegister/>}/>}
		</PageContainer>
	);
};

export default AdminFasCompanyProcess;
