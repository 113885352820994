import React, {useState} from 'react';
import {
	SectionFrame ,
	ContentFrame ,
	validationValue , tooltipModule , GridTableComponent , GridTableSelect , autoTextValueValidation
} from "../../../../../style/StyleComponent";
import styled from 'styled-components'
import {Authorization} from "../../../../../common/authorization";
import client from "../../../../../common/Config";
import MenuTitle from "../../../../../components/title/MenuTitle";
import FacilityLog from "./FacilityLog";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";

import {useLocation , useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
	affiliateAuth ,
	buttonAuthValid ,
	dataValid ,
	initModal ,
	listLoadingCircle ,
	loadingRemove ,
	locationFilter , numberLocaleString
} from "../../../../../function/function";
import {FacilityDataType , Modal_View_Type} from "../../../../../types/Fundamental";
import MainButtons from "../../../../../components/button/MainButtons";
import ProductionItemModal from "../../../../../components/modal/ProductionItemModal";
import PeripheralRegisterModal from "../../../../../components/modal/PeripheralRegisterModal";

const FacilityDetailList = () => {

	const params = useParams()
	const {facilityCode} = params
	const location = useLocation()
	const authValid = location.pathname.includes('/admin')

	const facilityTitle = authValid? ['기종','오버홀','제조 연월일','제조번호','제조사','기계명','ICT번호','HW','버전', '네트워크',
		'최대 톤수','사용전압','업체명','지역','공장명','공장 세분화','기계 담당자','이미지','생산품목','일상점검','주변 장치'] :
		['기종','오버홀','제조 연월일','제조번호','제조사','기계명','HW','버전', '네트워크',
			'최대 톤수','사용전압','지역','공장명','공장 세분화','기계 담당자','이미지','생산품목','일상점검','주변 장치']
	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
	const [facilityData, setFacilityData] = useState<{current: FacilityDataType|any, log:any}>({current:{}, log:[]})

	const { t }:any = useTranslation();

	const facilityDetailResponse = async () => {
		try {
		listLoadingCircle()
			const response = await client.post('/facility/detail',{
				facilityCode: facilityCode
			},{headers: {Authorization}})
			setFacilityData(response.data.data)
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}
	React.useEffect(()=>{
		facilityDetailResponse().then()
	},[facilityCode])

	return (
		<React.Fragment>
			{dataModal.menu === 'preview' &&
				<ImageDetailPreview
					profile={facilityData.current?.profileImageUrl}
					imageUrl={facilityData.current?.imageUrl}
					clickEvent={() => setDataModal(initModal)}/>}
			{dataModal.menu === 'production'&&
				<ProductionItemModal facilityDetail={facilityData} closeEvent={()=>{setDataModal(initModal)}}/>
			}

			{dataModal.menu === 'peripheral' &&
				<PeripheralRegisterModal companyCode={dataValid(facilityData?.current?.companyCode)}
																 selectFacility={facilityData?.current}
																 cancelEvent={()=>setDataModal(initModal)} viewType={true}
																 selectedPeripheral={dataValid(facilityData?.current?.peripheralCode,[])}
																 receivedPeripheral={(v)=>setFacilityData((prev:any)=>({...prev, peripheralCode: v}))}/>
			}


			{/*{ imageViewData && 	<DetailImageView image={facilityData.current?.profileImageUrl} closeMenu={()=>setImageViewData(false)}/>}*/}

			<SectionFrame>
				<MenuTitle title={(authValid? '업체/' : '')+'기본정보 관리 > 기계 기본정보 > 조회'} unitCase={'MenuTitle'}/>
				<div style={{display:'flex',flexDirection:'column'}}>
					<ContentFrame height={'208px'} margin={'0 0 16px 0'} padding={'30px 30px 10px 30px'}>
						<div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
							<MenuTitle title={'기계 기본정보 조회'} unitCase={'MenuCompTitle'}/>
							<div style={{display:'flex'}}>
								<MainButtons name={'목록'} width={'56px'} clickEvent={()=>window.location.href = `${authValid ? '/admin/fas/facility' : '/fas/facility'}`} />

								{!affiliateAuth() &&
									<MainButtons name={'수정'} width={'56px'} disabled={buttonAuthValid('update','6')} clickEvent={()=>window.location.href = authValid ? `/admin/fas/facility/modify/${facilityData?.current.facilityCode}` : `/fas/facility/modify/${facilityData?.current.facilityCode}`} marginDis={true} />
								}
							</div>
						</div>
						<GridTableComponent scrollY_Hide={true} scrollWidth={2280}
							tempCol={authValid? '56px 40px 88px 160px 88px 128px 88px 88px 80px 128px 88px 88px 128px repeat(4, 88px) 56px 88px 96px 96px' : '56px 40px 88px 160px 88px repeat(4, 144px) 88px 88px 128px repeat(3, 88px) 56px 88px 96px 96px'}>
							<div className={'grid-title-unchecked'}>
								{facilityTitle?.map((v)=>(
									<div key={v}>{t(v)}</div>
								))}
							</div>
							<GridTableSelect className={'grid-list-unchecked-no_icon'}>
								{validationValue(facilityData?.current?.type)}
								{validationValue(facilityData?.current?.overhaul)}
								{validationValue(facilityData?.current?.productionDate)}
								{autoTextValueValidation({value:facilityData?.current?.manufacturingNumber})}
								{autoTextValueValidation({value:facilityData?.current?.maker})}
								{autoTextValueValidation({value:facilityData?.current?.facilityName})}
								{authValid && (<div>{facilityData?.current?.ictNumber === '00000' ? t('할당 안됨') :
									facilityData?.current?.ictNumber === '' ? t('할당 안됨') : facilityData?.current?.ictNumber}</div>)}
								{autoTextValueValidation({value:facilityData?.current?.hw})}
								{validationValue(facilityData?.current?.version)}
								{autoTextValueValidation({value:facilityData?.current?.network})}
								{validationValue(numberLocaleString(facilityData?.current?.maxTon))}
								{validationValue(numberLocaleString(facilityData?.current?.voltage))}
								{authValid && autoTextValueValidation({value:facilityData?.current?.companyName})}
								<div>{locationFilter(facilityData?.current?.location)}</div>
								{autoTextValueValidation({value:facilityData?.current?.factoryName})}
								{validationValue(facilityData?.current?.factorySubdivision)}
								{validationValue(facilityData?.current?.manager)}
								<ClickText onClick={()=> {facilityData?.current?.profileImage ? setDataModal({menu:'preview'}) : setDataModal(initModal)}}>
									{!facilityData?.current?.profileImage ? '-' : t('미리보기')}
								</ClickText>
								<ClickText onClick={()=>{setDataModal({menu:'production'})}}>{t('생산품목')}</ClickText>
								<ClickText>{!facilityData?.current?.dailyInspection ? '-' : t('일상점검 리스트')}</ClickText>

								<ClickText onClick={()=>{facilityData?.current?.peripheralCode ? setDataModal({menu:'peripheral'}) : setDataModal(initModal)}}>{!facilityData?.current?.peripheralCode ? '-' : t('주변장치 보기')}</ClickText>

							</GridTableSelect>
						</GridTableComponent>
					</ContentFrame>
					<FacilityLog logData={facilityData?.log} height={'456px'} buttonActive={false} authValid={authValid}/>
				</div>
			</SectionFrame>
			{/*<FacilityModify currentValue={facilityData?.current} logData={facilityData?.log}*/}
			{/*                clickChangePage={()=>setModifyPageState(false)}/>*/}
		</React.Fragment>
	);
};

export default FacilityDetailList;

const ClickText = styled.div`
	font-size: 14px;
	color: #171725;
	text-decoration: underline;
	&:hover{
		font-weight: bold;
		color: #0058FF;
		text-decoration: underline;
	}
`

