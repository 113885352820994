import React , {useEffect} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../components/title/MenuTitle";
import client from "../../../../common/Config";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import {ContentFrame , SectionFrame} from "../../../../style/StyleComponent";
import {Authorization} from "../../../../common/authorization";
import TotalImageUploadBox from "../../../../components/input/TotalImageUploadBox";
import ModalBase from "../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../components/button/MainButtons";
import {
  PasswordCheck ,
  IdDuplicate ,
  onIdCheckValid ,
  onPwCheckValid ,
  onEmailValid , idReg , passwordReg , emailReg , phoneReg , specialCharExcludeReg
} from '../../../../ValidationCheck'
import {
  // BeforeUnloadFunction ,
  dataAccessLoading ,
  initModal ,
  loadingRemove ,
  phoneNumberFormat
} from "../../../../function/function";
import {Modal_View_Type} from "../../../../types/Fundamental";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../components/modal/ToastPopup";

const PartnerRegistration = () => {
  const [password , setPassword] = React.useState( {text: '*비밀번호 확인을 해주세요.' , color: ''} );
  const [idCheck , setIdCheck] = React.useState( {text: '*ID 중복확인을 해주세요.' , color: ''} );
  const [emailCheck , setEmailCheck] = React.useState( {text: '' , color: ''} )
  const [idCheckNormal , setIdCheckNormal] = React.useState<boolean>( false );
  const [pwCheckNormal , setPwCheckNormal] = React.useState<boolean>( false );
  const [imageState , setImageState] = React.useState<string[]>( [] );
  const [imageLogoState , setImageLogoState] = React.useState<string[]>( [] );
  const [openPostCode , setOpenPostCode] = React.useState<boolean>( false );
  const [radioStatus , setRadioStatus] = React.useState<string>( 'admin' );
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [partnerRegister , setPartnerRegister] = React.useState<any>(
    {
      adminName: '' ,
      president: '' ,
      address: '' ,
      detailAddress: '' ,
      phoneNumber: '' ,
      email: '' ,
      id: '' ,
      password: '' ,
      passwordCheck: '' ,
      notice: '' ,
      image: '' ,
      logoImage: '' ,
      latitude: '' ,
      longitude: '' ,
    } )
  const {t}: any = useTranslation();
  const handleClickButton = ( radioButtonName: string ) => {
    setRadioStatus( radioButtonName )
  }
  const imageValue = () => {
    if( imageState !== null ){
      return imageState[0]
    } else{
      return null
    }
  }
  const logoImageValue = () => {
    if( imageLogoState !== null ){
      return imageLogoState[0]
    } else{
      return null
    }
  }
  const registerAdmin = async () => {
    if( !partnerRegister.id ){
      setDataModal( {menu: 'confirm' , message: '아이디를 입력해 주세요.'} )
    }
    else if( !idReg.test( partnerRegister.id ) ){
      setDataModal( {menu: 'confirm' , message: '아이디 형식은 4~20자 \n 영문, 영문+숫자 입력만 가능합니다.'} )
    }
    else if( !idCheckNormal ){
      setDataModal( {menu: 'confirm' , message: '아이디 중복확인을 해주세요.'} )
    }
    else if( !partnerRegister.password ){
      setDataModal( {menu: 'confirm' , message: '비밀번호를 입력해 주세요.'} )
    }
    else if( !passwordReg.test( partnerRegister.password ) ){
      setDataModal( {menu: 'confirm' , message: '비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'} )
    }
    else if( !partnerRegister.passwordCheck ){
      setDataModal( {menu: 'confirm' , message: '비밀번호를 한번 더 입력해 주세요.'} )
    }
    else if( !pwCheckNormal ){
      setDataModal( {menu: 'confirm' , message: '비밀번호가 일치한지 확인 해주세요.'} )
    }
    else if( partnerRegister.password !== partnerRegister.passwordCheck ){
      setDataModal( {menu: 'confirm' , message: '비밀번호가 일치하지 않습니다.'} )
    }
    else if( !partnerRegister.adminName ){
      setDataModal( {menu: 'confirm' , message: '지사/협력사 명을 입력해 주세요.'} )
    }
    else if(specialCharExcludeReg.test(partnerRegister.adminName) ){
      setDataModal( {menu: 'confirm' , message: '지사/협력사 명에 특수문자를 포함할 수 없습니다.'} )
    }
    else if( !partnerRegister.president ){
      setDataModal( {menu: 'confirm' , message: '대표자 명을 입력해 주세요.'} )
    }
    else if( !partnerRegister.phoneNumber ){
      setDataModal( {menu: 'confirm' , message: '전화번호를 입력해 주세요.'} )
    }
    else if( !phoneReg.test( partnerRegister.phoneNumber.trim() ) ){
      setDataModal( {menu: 'confirm' , message: '전화번호 형식이 맞지 않습니다.'} )
    }
    else if( !partnerRegister.address ){
      setDataModal( {menu: 'confirm' , message: '주소를 입력해 주세요.'} )
    }
    else if( !partnerRegister.email ){
      setDataModal( {menu: 'confirm' , message: '이메일을 입력해 주세요.'} )
    }
    else if( !emailReg.test( partnerRegister.email ) ){
      setDataModal( {menu: 'confirm' , message: '이메일 형식이 맞지 않습니다.'} )
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post( '/admin/signup' , {
                                              ...partnerRegister ,
                                              phoneNumber: partnerRegister.phoneNumber.trim() ,
                                              authority: radioStatus ,
                                              image: imageValue() ,
                                              logoImage: logoImageValue()
                                            } ,
                                            {headers: {Authorization}}
        )
        const data = response.data;
        if( data.message !== 'success' ){
          setDataModal( {menu: 'confirm' , message: data.message} );
          loadingRemove()
        } else{
          setTimeout( () => {
            loadingRemove()
            setDataModal( {menu: 'success' , message: t( '저장 되었습니다.' )} );
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e );
        setDataModal( {menu: 'error' , message: e.message} );
        loadingRemove()
      }
      // finally {
      //     setTimeout(()=>{
      //         setDataModal(initModal)
      //         window.location.href = `/admin/fas/partners`
      //     },3000)
      // }
    }
  }
  const onPartnerChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> , trim?: boolean ) => {
    const {name , value} = e.target;
    setPartnerRegister( {
                          ...partnerRegister ,
                          [name]: trim ? value.trim() : value
                        } )
  }
  const onPwCheckEnter = ( e: React.KeyboardEvent<HTMLInputElement> ) => {
    if( e.key === 'Enter' ){
      PasswordCheck( partnerRegister , setPassword , setPwCheckNormal );
    }
  }
  const onIdCheckEnter = ( e: React.KeyboardEvent<HTMLInputElement> ) => {
    if( e.key === 'Enter' ){
      IdDuplicate( partnerRegister , setIdCheck , setIdCheckNormal ).then()
    }
  }
  const onCheckId = ( e: { target: { name: string, value: string } } ) => {
    const {name , value} = e.target;
    setPartnerRegister( {
                          ...partnerRegister ,
                          [name]: value
                        } )
    onIdCheckValid( value , setIdCheck )
  }
  const onCheckPassword = ( e: { target: { name: string; value: string } } ) => {
    const {name , value} = e.target
    setPartnerRegister( {
                          ...partnerRegister ,
                          [name]: value
                        } )
    onPwCheckValid( value , setPassword )
  }
  const onCheckEmail = ( e: { target: { name: string, value: string } } ) => {
    const {name , value} = e.target;
    setPartnerRegister( {
                          ...partnerRegister ,
                          [name]: value
                        } )
    onEmailValid( value , setEmailCheck )
  }
  const addressHandle = {
    clickBtn: () => setOpenPostCode( current => !current ) ,
    selectAddress: ( data: any ) => {
      partnerRegister.address = data.address + ( data.buildingName !== '' ? ` (${data.buildingName})` : '' )
      setPartnerRegister( {...partnerRegister , detailAddress: ''} )
      setOpenPostCode( false );
    } ,
  }
  // BeforeUnloadFunction(dataModal.message?.includes('저장'), dataModal.message)
  // console.log(dataModal.message?.includes('저장'))
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  const LocationPosition = () => {
    //@ts-ignore
    let geocoder = new kakao.maps.services.Geocoder()
    //
    geocoder.addressSearch( partnerRegister.address , function ( result: any , status: any ) {
      if( status === kakao.maps.services.Status.OK ){
        let coords = new kakao.maps.LatLng( result[0].y , result[0].x );
        Object.assign( partnerRegister , {latitude: coords.getLat() , longitude: coords.getLng()} )
      }
    } )
  }
  useEffect( () => {
    LocationPosition()
  } , [partnerRegister.address] )
  console.log( partnerRegister )
  return (
    <SectionFrame>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {
          setDataModal( initModal )
          window.location.href = `/admin/fas/partners`
        }}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'save' &&
          <ConfirmPopup statusText={t( dataModal.message )}
                        clickEvent={() => {
                          window.location.href = `/admin/fas/partners`
                          setDataModal( initModal )
                        }}/>
      }

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={t( dataModal.message )}
                              noticeText={t( ' * "예" 선택시 저장없이 이동합니다.' )}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => {
                                window.location.href = `/admin/fas/partners`;
                                setDataModal( initModal )
                              }}
          />
      }

      {/*{dataModal.menu === 'save' &&*/}
      {/*  <ToastPopup state={'success'} text={dataModal.message}/>*/}
      {/*}*/}

      {openPostCode && <ModalBase clickEvent={() => setOpenPostCode( false )} modalContent={
        <DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
                      style={{
                        display: 'block' ,
                        width: '500px' ,
                        height: '600px' ,
                        position: 'absolute' ,
                        boxShadow: '0 1px 7px #E6E9F4' ,
                        borderRadius: '8px' ,
                        backgroundColor: '#fff' ,
                        padding: '7px' ,
                        zIndex: 100
                      }}/>}/>}
      <MenuTitle title={t( '지사/협력사 > 등록' )} unitCase={'MenuTitle'}/>
      <ContentFrame>
        <div style={{display: 'flex' , userSelect: 'none'}}>
          <RegisterBox>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'지사/협력사 > 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'취소'} width={'56px'}
                             clickEvent={() => {
                               setDataModal( {
                                               menu: 'cancel' ,
                                               message: t( '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?' )
                                             } )
                             }}/>
                <MainButtons buttonType={'cta'} width={'56px'} marginDis={true}
                             name={'저장'} clickEvent={registerAdmin}/>
              </div>
            </div>


            <div className={'scroll_func'}>
              <RegisterComponent type={'text'} name={'adminName'} value={partnerRegister.adminName} required={true}
                                 title={t( '지사/협력사명' )} onChange={( e ) => onPartnerChange( e , true )}
                                 placeholder={t( '지사/협력사명을 입력해 주세요.' )}/>

              <RegisterComponent type={'text'} name={'president'} value={partnerRegister.president} required={true}
                                 title={t( '대표' )} onChange={onPartnerChange} placeholder={t( '대표명을 입력해 주세요.' )}/>

              <RegisterComponent type={'address'} onChange={onPartnerChange} title={t( '주소' )} width={'72px'}
                                 required={true}
                                 name={'address'} subName={'detailAddress'} placeholder={t( '주소를 입력해주세요.' )}
                                 subValue={partnerRegister.detailAddress}
                                 clickEvent={addressHandle.clickBtn} value={partnerRegister.address}/>

              <RegisterComponent type={'phone'} value={partnerRegister.phoneNumber}
                                 error={false} title={t( '전화번호' )} required={true}
                                 onChange={onPartnerChange} name={'phoneNumber'}
                                 numberFormat={partnerRegister.phoneNumber}/>

              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '72px'}}>
                  {t( '이메일' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{height: '72px'}}>
                  <div style={{maxWidth: '616px'}}>
                    <InputText type="text" placeholder={t( '이메일을 입력해 주세요.' )} onChange={onCheckEmail}
                               name={'email'} value={partnerRegister.email} maxLength={30} height={'32px'}/>
                    <div className={'form_text'} style={{color: emailCheck.color}}>
                      {t( partnerRegister.email ) === '' ?
                        <div className={'form_text'} style={{margin: '0'}}>
                          {t( '* 사용하실 E-mail을 입력해 주세요.' )}
                        </div>
                        :
                        `${t( emailCheck.text )}`
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'}>
                  {t( '관리자 권한' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{display: 'flex'}}>
                  <label className={'radio_button'}>
                    <input type="radio"
                           checked={radioStatus === 'admin'} name={'authority'}
                           onChange={() => ( handleClickButton( 'admin' ) )}/>
                    <div>{t( '관리자' )}</div>
                  </label>
                  <label className={'radio_button'}>
                    <input type="radio"
                           checked={radioStatus === 'affiliate'} name={'authority'}
                           onChange={() => ( handleClickButton( 'affiliate' ) )}/>
                    <div>{t( '협력사' )}</div>
                  </label>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '72px'}}>
                  {t( '관리자 ID' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{height: '72px'}}>
                  <div style={{display: 'flex' , flexDirection: 'column'}}>
                    <div style={{display: 'flex'}}>
                      <InputText type="id" name={'id'} value={partnerRegister.id} onKeyDown={onIdCheckEnter}
                                 width={'520px'} height={'32px'}
                                 onChange={onCheckId}/>
                      <MainButtons buttonType={'cta'} name={'중복확인'} marginDis={true}
                                   clickEvent={() => IdDuplicate( partnerRegister , setIdCheck , setIdCheckNormal )}
                                   width={'72px'} height={'32px'}/>
                    </div>

                    <div className={'form_text'} style={{color: idCheck.color}}>{t( idCheck.text )}</div>
                  </div>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '72px'}}>
                  {t( '관리자 PW' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{height: '72px' , justifyContent: 'space-between'}}>
                  <div>
                    <InputText type="password" onChange={onCheckPassword} name={'password'}
                               value={partnerRegister.password}/>
                    <div className={'form_text'}>{t( '*8글자 이상 영문,숫자(특수문자 혼용가능)를 사용해주세요.' )}</div>
                  </div>
                </div>
              </div>


              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '72px'}}>
                  {t( '관리자 PW 확인' )}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{height: '72px'}}>
                  <div style={{display: 'flex' , flexDirection: 'column'}}>
                    <div style={{display: 'flex'}}>
                      <InputText type="password" name={'passwordCheck'} value={partnerRegister.passwordCheck}
                                 width={'520px'} height={'32px'} onChange={onPartnerChange}
                                 onKeyDown={onPwCheckEnter}/>
                      <MainButtons buttonType={'cta'} name={'확인'} width={'72px'} height={'32px'} marginDis={true}
                                   clickEvent={() => PasswordCheck( partnerRegister , setPassword , setPwCheckNormal )}/>
                    </div>

                    <div className={'form_text'} style={{color: password.color}}>{t( password.text )}</div>
                  </div>
                </div>
              </div>

              <RegisterComponent type={'textarea'} value={partnerRegister.notice} title={'비고'}
                                 placeholder={t( '내용을 입력해주세요.' )}
                                 name={'notice'} onChange={onPartnerChange} bottomBorder={true}/>
            </div>
          </RegisterBox>

          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>

          <div style={{display: 'flex' , flexDirection: 'column' , width: '100%'}}>
            <div>
              <div style={{height: '34px' , display: 'flex' , alignItems: 'center' , justifyContent: 'space-between'}}>
                <MenuTitle title={'프로필 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                  <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
                </div>
              </div>
              <div style={{marginTop: '16px' , display: 'flex' , justifyContent: 'flex-start'}}>
                <TotalImageUploadBox imageKeyArray={( data ) => setImageState( data )} inputSize={168}
                                     imageLength={1} profileSelect={false}/>
              </div>
            </div>
            {radioStatus === 'affiliate' &&
                <div style={{marginTop: '80px'}}>
                  <div style={{
                    height: '34px' ,
                    display: 'flex' ,
                    alignItems: 'center' ,
                    justifyContent: 'space-between'
                  }}>
                    <MenuTitle title={'협력사 로고 등록'} unitCase={'MenuCompTitle'}/>
                    <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                      <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
                    </div>
                  </div>
                  <div style={{marginTop: '16px' , display: 'flex' , justifyContent: 'flex-start'}}>
                    <TotalImageUploadBox imageKeyArray={( data ) => setImageLogoState( data )} inputSize={168}
                                         imageLength={1} profileSelect={false}/>
                  </div>
                </div>
            }
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default PartnerRegistration;
const RegisterBox = styled.div`
  width: 784px;
  height: 620px;

  .input {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      font-size: 12px;
      min-width: 168px;
      font-weight: 900;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }

    &_box {
      border-top: 1px solid #E6E9F4;
      width: 616px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 16px;

      .form_text {
        height: 14px;
        font-size: 12px;
        color: rgba(23, 23, 37, 0.5);
        display: flex;
        white-space: nowrap;
        font-weight: 400;
        margin-top: 8px;
      }

      .radio_button {
        font-size: 14px;
        display: flex;
        margin-right: 6px;
        text-align: center;
        font-weight: normal;
        align-items: center;

        & > input {
          height: 40px;
          cursor: pointer;
          outline: none;
          margin-right: 6px;
        }
      }
    }

    &_tel {
      width: 600px;
      height: 32px;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #D7DBEC;
      border-radius: 4px;
      font-size: 14px;
      padding: 0 8px;

      &:focus {
        border: 1px solid #0058ff;
      }

      &::placeholder {
        font-size: 14px;
        color: rgba(23, 23, 37, 0.3);
        font-family: 'NanumGothicOTF', sans-serif;
      }
    }
  }

  .scroll_func {
    width: 810px;
    height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;

    ::-webkit-scrollbar {
      display: block;
    }
  }

`

interface inputProps {
  height?: string;
  width?: string;
}

const InputText = styled.input<inputProps>`
  width: ${props => props.width ?? '600px'};
  height: ${props => props.height ?? '32px'};
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  font-size: 14px;
  white-space: pre-wrap;
  padding: 0 8px;

  &:focus {
    border: 1px solid #0058ff;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: normal;
    color: rgba(23, 23, 37, 0.3);
    font-family: 'NanumGothicOTF', sans-serif;
  }
`