import React , {useEffect} from 'react';
import {ContentFrame , SectionFrame , validationValue} from "../../../../../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import {useLocation , useParams} from "react-router-dom";
import {Modal_View_Type} from "../../../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
  phoneNumberFormat
} from "../../../../../../function/function";
import client from "../../../../../../common/Config";
import {Authorization} from "../../../../../../common/authorization";
import {emailReg} from "../../../../../../ValidationCheck";
import ToastPopup from "../../../../../../components/modal/ToastPopup";
import SelectConfirmModal from "../../../../../../components/modal/SelectConfirmModal";
import PasswordChangeModal from "../../../../../../components/modal/PasswordChangeModal";
import FindPasswordModal from "../../../../../../components/modal/FindPasswordModal";
import MenuTitle from "../../../../../../components/title/MenuTitle";
import styled from "styled-components";
import MainButtons from "../../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../../components/input/RegisterComponent";
import RegisterInput from "../../../../../../components/input/RegisterInput";
import SingleImageUploadBox from "../../../../../../components/input/SingleImageUploadBox";
import ConfirmPopup from "../../../../../../components/modal/ConfirmPopup";

const CompanyUserModify = () => {
  const location = useLocation()
  const authValid = location.pathname.includes('admin')

  const {t}:any = useTranslation()
  const params = useParams()
  const {id} = params
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
  const [authoritiesData,setAuthoritiesData] = React.useState<any>([]);
  const [profileImage,setProfileImage] = React.useState<string|null>(null);
  const [userModifyState,setUserModifyState] = React.useState<any>({});
  const [emailStatus,setEmailStatus] = React.useState<string>('');
  const [password,setPassword] = React.useState<boolean>(false);
  const [adminPassword,setAdminPassword] = React.useState<string>('');

  const userDetailRes = async () =>{
    try {
      const response = await client.post('/system/user/detail',{id:id},{headers: {Authorization}})
      const data = response.data.data
      setUserModifyState(data)
    }catch ( e ) {
      console.log(e)
    }
  }

  const userDeleteRes = async () =>{
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post('/system/user/delete', {
        id: [id]
      }, {headers: {Authorization}})
      if (response.data.status !== 200) {
        setDataModal({menu:'confirm',message:t(response.data.message)})
        loadingRemove()
      }else{
        setTimeout(()=>{
          setDataModal({menu:'success',message:'삭제 되었습니다.'})
          loadingRemove()
        },500)
      }
    }catch (e:any){
      console.log(e)
      setDataModal({menu:'error',message:e.message})
      loadingRemove()
    }
  }

  const userModifyRes = async () => {
    if(!userModifyState.userName){
      setDataModal({menu:'confirm', message:'성명을 입력해 주세요.'})
    }
    else if(!userModifyState.phone_number){
      setDataModal({menu:'confirm', message:'전화번호를 입력해주세요.'})
    }
    else if(!userModifyState.email){
      setDataModal({menu:'confirm', message:'이메일을 입력해 주세요.'})
    }
    else if(!emailReg.test(userModifyState.email)){
      setDataModal({menu:'confirm', message:'이메일 형식이 맞지 않습니다.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post(`/system/user/update`,{
          ...userModifyState,
          phone_number:userModifyState.phone_number,
          profileImage:profileImage,
          password:adminPassword ?? ''
        },{headers: {Authorization}})
        if (response.data.status !== 200) {
          setDataModal({menu:'confirm',message:response.data.message});
          loadingRemove()
        } else {
          setTimeout(()=>{
            setDataModal({menu:'success',message:adminPassword ? '비밀번호가 변경되었습니다.' : '수정 되었습니다.' })
            setAdminPassword('')
            loadingRemove()
          },500)
        }
      }catch ( e ) {
        console.log(e)
        loadingRemove()
      }
    }
  }

  const authoritiesListRes = async () => {
    try {
      const response = await client.get(`/authorities/list/${userModifyState.companyCode}`,{
        headers: {Authorization}
      })
      const data = response.data.data
      setAuthoritiesData(data.row)
    }catch (e) {
      console.log(e)
    }
  }

  const closeModify = () =>{
    setDataModal(initModal)
    if(dataModal.message === t('삭제 되었습니다.')){
      window.location.href = authValid ? '/admin/fas/basic/user' : `/fas/basic/user`
    }else if(dataModal.message === t('수정 되었습니다.')){
      window.location.href = authValid ?  `/admin/fas/basic/user/detail/${id}` : `/fas/basic/user/detail/${id}`
    }
  }


  const onEdit = (e: any, trim?: boolean) =>{
    const  { name , value } = e.target
    setUserModifyState({
                         ...userModifyState,
                         [name] : trim? value.trim() : value
                       })
  }

  React.useEffect(()=>{
    userModifyState.companyCode && authoritiesListRes().then()
  },[userModifyState.companyCode])


  useEffect(()=>{
    userDetailRes().then()
  },[])

  const sendEmailRes = async () => {
    dataAccessLoading();
    setDataModal(initModal)
    // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
    setTimeout(() => {
      setPassword(true)
      loadingRemove();
      // 작업 완료 시 호출할 콜백 함수
    }, 1000);
  };


  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

      {dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'select' && <SelectConfirmModal statusText={t(dataModal.message)}
                                                          noticeText={t(' * "예" 선택시 모든데이터는 삭제됩니다.')}
                                                          clickEvent={userDeleteRes}
                                                          cancelEvent={()=>(setDataModal(initModal))}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={()=>{userModifyRes().then(); setDataModal(initModal)}}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message} viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              clickEvent={()=>{window.location.href = authValid ? `/admin/fas/basic/user/detail/${id}` : `/fas/basic/user/detail/${id}`}}
          />
      }
      {dataModal.menu === 'pwChange' &&
          <PasswordChangeModal clickEvent={()=>setDataModal(initModal)} closeClick={()=>{setDataModal(initModal)}} findEvent={()=>{setDataModal(initModal)}}/>
      }

      {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={userModifyState} viewType={true}
                               receivePassword={(data)=>{setAdminPassword(data)}}
                               clickEvent={userModifyRes} findEvent={()=>{setDataModal({menu:'findPw'})}}
                               closeClick={()=>{setDataModal(initModal)}}/>
      }

      {/*{dataModal.menu === 'findPw' &&*/}
      {/*    <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
      {/*}*/}
      {/*{password &&*/}
      {/*    <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
      {/*}*/}
      <MenuTitle title={`${authValid ? '업체/기본정보 관리 > 업체 유저관리' : '기본정보 관리 > 유저관리'}` + ' > 수정'} unitCase={'MenuTitle'}/>
      <ContentFrame  flexDirection={'row'} justify={'flex-start'}>
        <ViewBox>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
            <MenuTitle title={'업체 정보'} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex',alignItems:'center'}}>
              <MainButtons name={'취소'} width={'56px'} clickEvent={()=>setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}/>
              <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'} disabled={buttonAuthValid('delete','94')} clickEvent={()=>{setDataModal({menu:'select',message:'삭제 하시겠습니까?'})}}/>
              <MainButtons name={'저장'} disabled={buttonAuthValid('update','94')} clickEvent={()=>{setDataModal({menu:'register',message:'이대로 수정하시겠습니까?'})}} buttonType={'cta'} width={'56px'} marginDis={true}/>
            </div>
          </div>
          <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)',borderBottom:'1px solid #E6E9F4'}}>
            <RegisterComponent type={'empty'} name={'companyCode'} value={userModifyState?.companyName} title={t('업체명')}/>
            <RegisterComponent type={'empty'} name={'companyCode'} value={userModifyState?.companyCode} title={t('업체코드')}/>
            <RegisterComponent type={'empty'} name={'companyCode'} value={userModifyState?.president} title={t('대표')}/>
            <RegisterComponent type={'empty'} name={'-'} value={'-'} title={'-'}/>
          </div>


          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0'}}>
            <MenuTitle title={'유저 정보'} unitCase={'MenuCompTitle'}/>
          </div>
          <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
            <RegisterComponent type={'text'} name={'userName'} value={userModifyState.userName} paddingRight={true} required={true}
                               title={'성명'} onChange={(e)=>onEdit(e, true)}/>
            <RegisterComponent type={'text'} name={'userPosition'} value={userModifyState.userPosition} paddingRight={true}
                               title={'직책'} onChange={(e)=>onEdit(e, true)}/>
            <RegisterComponent type={'text'} name={'employeeNumber'} value={userModifyState.employeeNumber} paddingRight={true}
                               title={'사원번호'} onChange={(e)=>onEdit(e, true)}/>

            <RegisterComponent type={'drop'} title={'권한'} dropWidth={'192px'} disabled={buttonAuthValid('update','93')}
                               dropClickEvent={(v)=>{setUserModifyState((prev:any)=>({...prev, authorityName: v, authorityCode:authoritiesData.find(( item: { authoritiesName: any; }) => item.authoritiesName === v)?.authoritiesCode}))}}
                               name={'weldingType'} value={userModifyState.authorityName} onChange={onEdit} dropAddNoneSelect={true}
                               dropArray={authoritiesData.map((v:any,index:any)=>(v.authoritiesName))}/>
          </div>
          <RegisterComponent type={'phone'} value={userModifyState.phone_number} error={false} title={t('전화번호')}
                             onChange={onEdit} name={'phone_number'} required={true}
                             numberFormat={userModifyState.phone_number}/>

          <RegisterComponent type={'text'} value={dataValid(userModifyState.email,'')}
                             required={true} title={t('이메일')} placeholder={t('이메일을 입력해 주세요.')}
                             onChange={onEdit} name={'email'} bottomBorder={true}/>

          <div className={'reg'} style={{display: 'grid', gridTemplateColumns: '168px 616px '}}>
            <div className={'reg-title'}>{t('ID')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(userModifyState.id)}
            </div>
          </div>

          <div style={{display:'flex'}}>
            <div className={'indexBox'} style={{height:'48px'}}>{'업체 PW'}</div>
            <div className={'inputWrap'} style={{display:'flex',flexDirection:'column',justifyContent:'center',padding: '0 0 0 20px'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'572px'}}>
                <MainButtons name={'변경하기'} disabled={buttonAuthValid('update','94')} buttonType={'cta'} margin={'0'} clickEvent={()=>{setDataModal({menu:'pw'})}}/>
                {/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
                {/*  {'비밀번호 찾기'}*/}
                {/*</div>*/}
              </div>
            </div>
          </div>

          <div style={{display:'grid',gridTemplateColumns:'168px 1fr'}}>
            <div className={'info-title'} style={{height: '112px'}}>{'비고'}</div>
            <div className={'info-content'} style={{height: '112px'}}>
              <RegisterInput type={'textarea'} name={'notice'} value={userModifyState.notice} maxHeight={'96px'} limit={1000}
                             onChange={onEdit} placeholder={'내용을 입력해주세요.'}/>
            </div>
          </div>
        </ViewBox>
        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
        <div style={{width: '377px'}}>
          <div style={{height:'34px',display:'flex',alignItems:'center',justifyContent:'space-between', marginBottom:'20px'}}>
            <MenuTitle title={'프로필 수정'} unitCase={'MenuCompTitle'}/>
            <div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
              <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
            </div>
          </div>
          <SingleImageUploadBox imageKey={(data)=> setProfileImage(data)}
                                receiveKey={userModifyState?.profileImage} receiveUrl={userModifyState?.profileImageUrl}
                                inputSize={168} profileSelect={false}/>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default CompanyUserModify;


const ViewBox = styled.div`
  width: 784px;
  height: 584px;



  .scroll_func{
    width: 810px;
    height: 584px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: grid;
    }
  }

  .formText{
    font-size: 12px;
    color: rgba(23,23,37,0.5);
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    margin-left: 8px;

  }

  .indexBox{
    width: 168px;
    line-height: 30px;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 800;
    border-bottom: 1px solid #e7e9ee;
  }
  .inputWrap{
    width: 616px;
    border-bottom: 1px solid #e7e9ee;
    box-sizing: border-box;
    padding: 8px 0 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .input_tel{
    width: 600px;
    height: 32px;
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    font-size: 14px;
    color: #171725;
    font-weight: normal;
    padding: 0 8px;
    font-family: 'NanumGothicOTF', sans-serif;
    &::placeholder{
      font-size: 14px;
      font-weight: 400;
      color: rgba(23,23,37,0.3);
      font-family: 'NanumGothicOTF', sans-serif;
    }
    &:focus{
      border: 1px solid #0058ff;
    }
  }

  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }
`
