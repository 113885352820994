import React, {useEffect, useState, useRef} from 'react';
import styled from "styled-components";
import calendar from "../../asset/image/ic-calendar.svg";
import search from '../../asset/image/ic-search.svg'
import arrow from '../../asset/image/chevron-left.svg'
import blueCancel from '../../asset/image/FAS_list_icon_cancle.svg'
import {NumericFormat, PatternFormat} from "react-number-format";
import ReactDatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import {DatePickerComp , OriginalPikerComp , tooltipModule} from "../../style/StyleComponent";
import DatePickerCustom from "../modal/DatePickerCustom";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import MainButtons from "../button/MainButtons";
import {
	dataValid ,
	loadingRemove ,
	phoneNumberFormat ,
	uploadingLoading
} from "../../function/function";
import client from "../../common/Config";
import {ModalType} from "../../types/Fundamental";
import ConfirmPopup from "../modal/ConfirmPopup";
import LoadingComponent from "../modal/LoadingComponent";
import ModalBase from "../modal/ModalBase";
import {useLocation} from "react-router-dom";
import {AutoTextSize} from "auto-text-size";

interface Props {
	type: string
	id?: string
	name?: string
	width?: string
	height?:string
	borderRadius?:string
	border?:string
	style?:any
	selected?: boolean
	left?:number
	buttonFontSize?: string
	inputData?: string|number|any
	inputDataStyle?: React.CSSProperties
	onChange?: (e:any)=>void
	clickButton?: (e?:any)=>void
	clickSearchIcon?: ()=>void
	clickInputTag?:()=>void
	placeholder?: string
	checked?: boolean
	unit?: any

	selectDrop?:(value:any)=>void
	dropMenuList?: any[]
	dropScrollEvent?: ()=>void
	dropScrollHas?: boolean
	dropAddNoneSelect?: boolean
	dropFontSize?: string
	// eventClick?: (e:MouseEvent)=>void
	dateFormat?:string
	selectDate?: any
	minDate?:any
	maxDate?:any

	addressClick?:()=>void
	imageKey?: (data:string|null)=>void
	imageUrl?: (data:string|null)=>void

	receiveUrl?: string
	dropKey?:any
	buttonText?: string
	buttonWidth?: string
	disabled?: boolean
	inputDisabled?: boolean
	viewType?:boolean
	maxLength?: number
	onBlur?: any
	onKeyDownEvent?: React.KeyboardEventHandler<HTMLDivElement>
	focusCancel?: boolean
	inputType?: string
	percentageGage?:number
	percentageWidth?:number
	setSaveState?:()=>void
	tabIndex?:number
	limit?:number
	dropTopDirection?: boolean
	onFocus?:boolean
	listDateReceive?:(data:any)=>void
	useDeleteButton?:boolean
	deleteEvent?:()=>void
	hoverText?:string
	useV2Button?:boolean
	readonly?: boolean
	allSelectDate?: boolean
	dropHeight?: number
	onMouseEvent?:()=>void
	inputDataAutoUpdate?: boolean
	calendarApplyAddEvent?: ()=> void
	calendarOpenAddEvent?: ()=> void
}

const ListInnerInput:React.FunctionComponent<Props> = ({ type,name, clickSearchIcon,left,
																												 width, height, dropHeight, id,
																												 imageKey, imageUrl,onFocus=false,
																												 inputData, inputDataStyle, border, buttonWidth,
																												 buttonText, dropAddNoneSelect,
																												 checked, unit, addressClick,
																												 disabled = false, dropScrollHas,onMouseEvent,
																												 style,selectDrop,borderRadius,
																												 onChange, placeholder,useV2Button,
																												 clickButton,dropMenuList,useDeleteButton,deleteEvent,
																												 setSaveState,tabIndex, dropFontSize,
																												 dateFormat,selectDate, receiveUrl, buttonFontSize,
																												 dropKey, dropScrollEvent , selected,
																												 viewType,onKeyDownEvent,onBlur, calendarApplyAddEvent, calendarOpenAddEvent,
																												 inputType, limit, inputDisabled, readonly,
																												 percentageGage=0,percentageWidth = 0, minDate, maxDate, hoverText, inputDataAutoUpdate, clickInputTag,
																												 maxLength,focusCancel,dropTopDirection,listDateReceive, allSelectDate
																											 }) => {

	const location = useLocation()
	const authValid = location.pathname.includes('admin')
	const [dropOpen,setDropOpen] = useState<boolean>(false)
	const [dropSelect, setDropSelect] = useState<any>(inputData)
	const [dropUnitSelect, setDropUnitSelect] = useState<any>(unit)
	const [dateSelected, setDateSelected] = useState<any>(selectDate)
	const [dateInput, setDateInput] = useState<any>(inputData)
	const [modalState, setModalState] = useState<ModalType>({open: false, status:'', message:''})

	const [imageUrlState, setImageUrlState] = useState<any>(receiveUrl)
	const [realTimeValue, setRealTimeValue] = useState<any>(inputData)
	const [dateTime, setDateTime] = useState(new Date())

	const {t}: any = useTranslation()

	const dropMenuRef = useRef<any>(null)

	useEffect(()=>{
							const handleClickOutside = (e:any) => {
								if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
									setDropOpen(false)
								}
							}
							document.addEventListener("mousedown", handleClickOutside);
							return () => {
								document.removeEventListener("mousedown", handleClickOutside);
							};
						}
		,[dropMenuRef])

	const inputStyle = {
		width:'calc(100% - 8px)',
		height:'calc(100% - 4px)',
		backgroundColor:'transparent',
		border:'none',outline:'none',
		fontFamily:'NanumGothicOTF, sans-serif',
		...inputDataStyle
	}

	const inputRef = useRef<any>(null)

	const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data: any) => {
		e.preventDefault()
		uploadingLoading('업로드 중 입니다..')
		const formData = new FormData()
		formData.append('file',data)
		try {
			const response = await client.post('/file/upload',
																				 formData
																				 // ,{headers:{ "Content-Type": "multipart/form-data" }}
			)
			const data = response.data.data
			// uploadData({imgKey:data.imgKey,})
			setImageUrlState(data.imgUrl)
			inputRef.current.value = ""
			if (imageKey) {
				imageKey(data.imgKey)
			}
			if(imageUrl){
				imageUrl(data.imgUrl)
			}

		}catch (e) {
			console.log(e)
		}
		loadingRemove()
	}

	//업로드시 바로 저장되게끔 새로 만들었음
	const onFileUploadSaveImg = async (e:React.ChangeEvent<HTMLInputElement>,data: any) => {
		e.preventDefault()
		uploadingLoading(t('이미지를 업로드 중입니다.'))
		const formData = new FormData()
		formData.append('file',data)
		try {
			const response = await client.post('/file/upload',
																				 formData
																				 // ,{headers:{ "Content-Type": "multipart/form-data" }}
			)
			const data = response.data.data
			// uploadData({imgKey:data.imgKey,})

			setImageUrlState(data.imgUrl)
			inputRef.current.value = ""
			if (imageKey) {
				imageKey(data.imgKey)
			}
			if(imageUrl){
				imageUrl(data.imgUrl)
			}
			loadingRemove(500)
			if(response.data.status === 200){
				if(setSaveState){
					setSaveState()
				}
			}
		}catch (e) {
			console.log(e)
		}

	}
	React.useEffect(()=>{
		// inputDataAutoUpdate 있는 이유 = dropMenu가 있는 행의 index값이 변경되거나 상위 행이 삭제 된 경우.
		// 변경된 inputData값이 실시간으로 변경되어 보여지지 않음.
		if(inputDataAutoUpdate){
			setDropSelect(inputData)
			setRealTimeValue(inputData)
		}
	},[inputData])
	//
	// const deleteImage = () => {
	//    setImageKeyState(null)
	// }

	const extendReg = /(.*?)\.(jpg|jpeg|png)$/

	const handleDateChange = (date: Date) => {
		// dayjs를 사용하여 날짜의 유효성을 검사합니다.
		if (dayjs(date).isValid()) {
			setDateSelected(date);
			setDateInput(dayjs(date).format('YYYY.MM.DD'));
			onChange && onChange(date);
		} else {
			// 유효하지 않은 날짜일 경우의 처리
			// 예: 상태 초기화 또는 에러 메시지 표시
			setDateSelected(null);
			setDateInput('');
		}
	};

	const handleTimeDateChange = (date: Date) => {
		setDateTime(date)
		setDateInput(dayjs(date).format('YYYY.MM.DD HH:mm:ss'))
		onChange && onChange(date)
	}

	const handleTimeChange = (date: Date) => {
		setDateTime(date)
		setDateInput(dayjs(date).format('HH:mm:ss'))
		onChange && onChange(date)
	}

	const handleMonthChange = (date: Date) => {
		setDateTime(date)
		setDateInput(dayjs(date).format('YYYY.MM'))
		onChange && onChange(date)
	}

	const dropMenuArray = [...dropMenuList ?? []]

	if(dropAddNoneSelect){
		dropMenuArray.unshift(t('선택 안함'))
	}

	interface CalenderProps {
		data: {
			date: Date,
			decreaseMonth(): void,
			decreaseYear(): void
			increaseMonth(): void,
			increaseYear():void
			prevMonthButtonDisabled: boolean,
			nextMonthButtonDisabled: boolean,
		};
		monthType?: boolean;
		yearType?: boolean;
		femsUse?:boolean
		selectEvent?:(date: Date, event: React.SyntheticEvent<any> | undefined)=>void
	}

	const [resultDate, setResultDate] = React.useState(selectDate)
	const [showDatePicker, setShowDatePicker] = React.useState(false);

	const handleApply = () => {
		setShowDatePicker(false);
		setResultDate(dateSelected)
		listDateReceive && listDateReceive(dateSelected)
		calendarApplyAddEvent && calendarApplyAddEvent()
	};

	React.useEffect(()=>{
		setResultDate(dateInput)
	},[])

	// allSelectDate 사용 없이 resultDate에 추가해도 문제 없을듯. 테스트 필요
	React.useEffect(()=>{
		if(allSelectDate){
			setResultDate(dayjs(selectDate).format('YYYY.MM.DD'))
		}
	},[selectDate])


	const CustomRender = ({data,monthType,yearType}:CalenderProps) => {
		const {t}:any = useTranslation();

		const formatDate = (d: Date): string => {
			if (monthType){
				const date = dayjs(d);
				const monthIndex = date.month();

				const year = date.format(t('YYYY년'));
				const month = date.format('MMMM');

				return  year + ' ' + month;

			}else if(yearType){
				const date = dayjs(d);
				const year = date.format(t('YYYY년'));
				return  year;
			} else {
				const date = dayjs(d);
				const year = date.format(t('YYYY'));
				const month = date.format(t('MM'));
				const day = date.format(t('DD'));
				return  year + '년 '+ month + '월';
			}
		};

		return (
			<div>
				<div style={{display:'flex',width:'100%',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
					<div style={{display:'flex',alignItems:'center'}}>
						<div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear} >
							<button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
						</div>
						<div className={'react-datepicker__navigation--previous'} onClick={data.decreaseMonth}>
							<button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
						</div>
					</div>
					<div style={{display:'flex',justifySelf:'center',fontSize:'14px',color:authValid ? '#000':'#fff'}}
							 className={'react-datepicker__current-month'} >{formatDate(data.date)}</div>

					<div style={{display:'flex',alignItems:'center'}}>
						<div className={'react-datepicker__navigation--next'} onClick={data.increaseMonth}>
							<button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
						</div>
						<div className={'react-datepicker__navigation--next--year'}
								 onClick={data.increaseYear}>
							<button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
						</div>
					</div>
				</div>
				<div style={{display: 'flex' , position: 'absolute' , bottom: '-230px' , left: '8px'}}>
					<MainButtons name={'취소'} clickEvent={()=>{setShowDatePicker(false); setDateSelected(resultDate)}}/>
					<MainButtons name={'적용'} buttonType={'cta'} clickEvent={handleApply}/>
				</div>
			</div>
		)
	}


	const inputCase = (type: string) => {
		switch (type) {
			case 'calendar' :
				return (
					<React.Fragment>
						{showDatePicker &&
							<OriginalPikerComp onClick={clickButton} authValid={authValid}>
								<div style={{position: "absolute" , left: '120px' , top: 0 , zIndex: 100}}>
									<ReactDatePicker selected={!dateSelected ? dayjs().toDate() : dayjs( dateSelected ).toDate()}
																	 onChange={handleDateChange}
																	 dateFormat={'yyyy/MM/dd'}
																	 open={showDatePicker}
																	 popperPlacement={'right-start'}
																	 shouldCloseOnSelect={showDatePicker}
																	 locale={ko} withPortal={false}
																	 onClickOutside={() => {
																		 setShowDatePicker( false );
																		 setDateSelected( resultDate )
																	 }}
																	 onSelect={( date: Date ) => {
																		 if( date.getTime() === new Date( dateSelected )?.getTime() ){
																			 handleApply()
																		 }
																	 }}
																	 minDate={minDate}
																	 maxDate={dataValid( maxDate , new Date() )}
																	 customInput={<div style={{appearance: 'none'}}/>}
																	 renderCustomHeader={( {
																													 date ,
																													 increaseMonth ,
																													 decreaseMonth ,
																													 increaseYear ,
																													 decreaseYear ,
																													 prevMonthButtonDisabled ,
																													 nextMonthButtonDisabled
																												 }
																	 ) => ( <CustomRender data={{
																		 date ,
																		 increaseMonth ,
																		 decreaseMonth ,
																		 increaseYear ,
																		 decreaseYear ,
																		 prevMonthButtonDisabled ,
																		 nextMonthButtonDisabled ,
																	 }}/> )}
									/>
								</div>
							</OriginalPikerComp>
						}
						<InputBase onClick={() => {
							setShowDatePicker( true );
							calendarOpenAddEvent && calendarOpenAddEvent()
						}} style={{...style , position: 'relative'}}>

							<input name={name} onChange={onChange} placeholder={t( placeholder )} disabled={true}
										 type={'text'} value={!resultDate ? '' : dayjs( resultDate ).format( 'YYYY.MM.DD' )}
										 style={inputStyle}/>
							<img src={calendar} alt=""/>
						</InputBase>
						{useDeleteButton &&
							<div style={{position: 'relative'}}>
								<div style={{position: "absolute" , bottom: '6px' , right: '28px'}}
										 onClick={() => {
											 setDateSelected( '' )
											 setResultDate( '' )
											 if( deleteEvent ){
												 deleteEvent()
											 }
										 }}
								>
									{resultDate && 'X'}
								</div>
							</div>
						}
					</React.Fragment>
				)
			case 'calendarWithTime' :
				return (
					<DatePickerComp auth={authValid}>
						<ReactDatePicker
							selected={dateTime}
							onChange={handleTimeDateChange}
							showTimeSelect
							showTimeInput
							timeInputLabel={'시간선택'}
							locale={ko}
							timeCaption={t( '시간' )}
							minDate={minDate}
							maxDate={dataValid( maxDate , new Date( dayjs().add( 1 , 'year' ).format( 'YYYY-MM-DD' ) ) )}
							popperPlacement={'auto-start'}
							dateFormat="MMMM d, yyyy h:mm aa"
							timeIntervals={1}
							customInput={
								<InputBase style={{width: width}}>
									<input name={name} onChange={onChange} placeholder={placeholder}
												 disabled={true}
												 type={'text'} value={dateInput} style={inputStyle}/>
									<img src={calendar} onClick={clickButton} alt=""/>
								</InputBase>}
							renderCustomHeader={( {
																			date ,
																			increaseMonth ,
																			decreaseMonth ,
																			increaseYear ,
																			decreaseYear ,
																			prevMonthButtonDisabled ,
																			nextMonthButtonDisabled ,
																		}
							) => (
								<DatePickerCustom authType={authValid} data={{
									date ,
									increaseMonth ,
									decreaseMonth ,
									increaseYear ,
									decreaseYear ,
									prevMonthButtonDisabled ,
									nextMonthButtonDisabled ,
								}}/>
							)}
						/>
					</DatePickerComp>
				)
			case 'calendarWithHour' :
				return (
					<DatePickerComp auth={authValid}>
						<ReactDatePicker
							selected={dateTime}
							onChange={handleTimeDateChange}
							showTimeSelect
							locale={ko}
							timeCaption={t( '시간' )}
							minDate={minDate}
							maxDate={dataValid( maxDate , new Date( dayjs().add( 1 , 'year' ).format( 'YYYY-MM-DD' ) ) )}
							popperPlacement={'auto-start'}
							dateFormat="MMMM d, yyyy h:mm aa"
							timeIntervals={60}
							customInput={
								<InputBase>
									<input name={name} onChange={onChange} placeholder={placeholder} disabled={true}
												 type={'text'} value={inputData} style={{...inputStyle , width: '120px'}}/>
									<img src={calendar} onClick={clickButton} alt=""/>
								</InputBase>}
							renderCustomHeader={( {
																			date ,
																			increaseMonth ,
																			decreaseMonth ,
																			increaseYear ,
																			decreaseYear ,
																			prevMonthButtonDisabled ,
																			nextMonthButtonDisabled ,
																		}
							) => (
								<DatePickerCustom authType={authValid} data={{
									date ,
									increaseMonth ,
									decreaseMonth ,
									increaseYear ,
									decreaseYear ,
									prevMonthButtonDisabled ,
									nextMonthButtonDisabled ,
								}}/>
							)}/>
					</DatePickerComp>
				)
			case 'time' :
				return (
					<DatePickerComp>
						<ReactDatePicker
							selected={dateTime}
							onChange={handleTimeChange}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							timeCaption={t( '시간' )}
							dateFormat="HH:mm aa"
							popperPlacement={'auto-start'}
							minTime={dayjs().add( 1 , 'hour' ).toDate()}
							maxTime={dayjs().add( 1 , 'year' ).toDate()}
							customInput={
								<InputBase style={{width: '96px'}}>
									<input name={name} onChange={onChange} placeholder={placeholder} disabled={true}
												 type={'text'} value={dateInput} style={inputStyle}/>
								</InputBase>}
						/>
					</DatePickerComp>
				)
			case 'month' :
				return (
					<DatePickerComp onClick={clickButton}>
						<ReactDatePicker selected={dateSelected}
														 onChange={handleMonthChange}
														 dateFormat={dateFormat || 'yyyy/MM'}
														 locale={ko}
														 showMonthYearPicker
														 popperPlacement={'auto-start'}
														 customInput={
															 <InputBase style={{width: '180px'}}>
																 <div style={{display: 'flex' , alignItems: 'center' , gap: '10px'}}>
																	 <img src={calendar} onClick={clickButton} alt=""/>
																	 <div style={{
																		 fontWeight: 700 ,
																		 fontSize: '14px' ,
																		 color: '#5A607F' ,
																		 userSelect: 'none' ,
																		 whiteSpace: 'nowrap'
																	 }}>{t( '기간 선택' )}</div>
																	 <input name={name} onChange={onChange} placeholder={placeholder} disabled={true}
																					type={'text'} value={dateInput} style={inputStyle}/>
																 </div>
															 </InputBase>
														 } renderCustomHeader={( {
																											 date ,
																											 increaseMonth ,
																											 decreaseMonth ,
																											 increaseYear ,
																											 decreaseYear ,
																											 prevMonthButtonDisabled ,
																											 nextMonthButtonDisabled ,
																										 }
						) => ( <DatePickerCustom monthType={true} authType={authValid} data={{
							date ,
							increaseMonth ,
							decreaseMonth ,
							increaseYear ,
							decreaseYear ,
							prevMonthButtonDisabled ,
							nextMonthButtonDisabled ,
						}}/> )}/>
					</DatePickerComp>
				)
			case 'timer' :
				return (
					<InputBase style={{borderRadius: borderRadius , border: border , height: height}}>
						<PatternFormat format={'##:##:##'} type={'text'} tabIndex={tabIndex}
													 name={name} placeholder={placeholder ?? t( '내용을 입력해 주세요.' )} autoComplete={'off'}
													 value={inputData} onChange={onChange} className={'formatInput'}/>
					</InputBase>
				)
			case 'calendarOverflow' :
				return (
					<React.Fragment>
						{showDatePicker &&
							<ModalBase transparent={true} top={120} left={viewType ? left ?? 100 : 480}
												 modalContent={
													 <OriginalPikerComp onClick={clickButton} authValid={authValid}
																							style={{position: 'absolute' , zIndex: 1501}}>
														 <ReactDatePicker
															 selected={!dateSelected ? dayjs().toDate() : dayjs( dateSelected ).toDate()}
															 onChange={handleDateChange}
															 dateFormat={'yyyy/MM/dd'}
															 open={showDatePicker}
															 onClickOutside={( e ) => {
																 e.stopPropagation();
																 setShowDatePicker( false );
																 setDateSelected( resultDate );
															 }}
															 popperPlacement={'auto-start'}
															 shouldCloseOnSelect={showDatePicker}
															 locale={ko}
															 withPortal={false}
															 maxDate={new Date()}
															 customInput={<div style={{appearance: 'none'}}/>}
															 renderCustomHeader={( {
																											 date ,
																											 increaseMonth ,
																											 decreaseMonth ,
																											 increaseYear ,
																											 decreaseYear ,
																											 prevMonthButtonDisabled ,
																											 nextMonthButtonDisabled
																										 }
															 ) => ( <CustomRender data={{
																 date ,
																 increaseMonth ,
																 decreaseMonth ,
																 increaseYear ,
																 decreaseYear ,
																 prevMonthButtonDisabled ,
																 nextMonthButtonDisabled ,
															 }}/> )}
														 />
													 </OriginalPikerComp>
												 }/>
						}
						<InputBase onClick={() => {setShowDatePicker( true );}}>
							<input name={name} onChange={onChange} placeholder={t( placeholder )} disabled={true}
										 type={'text'} value={!resultDate ? '' : dayjs( resultDate ).format( 'YYYY.MM.DD' )}
										 style={inputStyle}/>
							<img src={calendar} alt=""/>
						</InputBase>
					</React.Fragment>
				)
			case 'search' :
				return (
					<React.Fragment>
						{tooltipModule( inputData , 5 ,
							<InputBase onClick={clickInputTag}>
								<input type={'text'} name={name} value={inputData} autoComplete={'off'} readOnly={true}
											 tabIndex={tabIndex}
											 placeholder={t( placeholder )} style={inputStyle} onChange={onChange}/>
								<img src={search} onClick={clickButton} alt=""/>
							</InputBase>
						)}
					</React.Fragment>
				)
			case 'accountSearch' :
				return (
					<React.Fragment>
						{tooltipModule( hoverText , 5 ,
							<InputBase>
								<input type={'text'} name={name} value={inputData} autoComplete={'off'} readOnly={true}
											 tabIndex={tabIndex}
											 placeholder={t( placeholder )} style={inputStyle} onChange={onChange}/>
								<img src={search} onClick={clickButton}
										 style={{cursor: 'pointer' , pointerEvents: disabled ? 'none' : 'auto'}} alt=""/>
							</InputBase>
						)}
					</React.Fragment>
				)
			case 'searchWithButton' :
				return (
					<InputBase>
						{tooltipModule( inputData , dataValid( limit , 0 ) ,
							<input type={'text'} name={name} value={inputData} disabled={inputDisabled} placeholder={t( placeholder )}
										 style={{...inputStyle , opacity: inputDisabled ? 0.7 : 1}} autoComplete={'off'}
										 onChange={onChange}/>
						)}
						<img src={search} onClick={clickSearchIcon} style={{cursor: 'pointer'}} alt=""/>
						<div style={{position: 'relative' , right: '-1px'}}>
							<MainButtons name={buttonText ?? '생성'} buttonType={'cta'} margin={'0'} marginDis={true} selected={checked}
													 clickEvent={clickButton} width={buttonWidth ?? '36px'} disabled={disabled}/>
						</div>
					</InputBase>
				)
			case 'withButton' :
				return (
					<InputBase>
						{tooltipModule( inputData , dataValid( limit , 0 ) ,
							<input type={'text'} name={name} value={inputData} disabled={inputDisabled} placeholder={t( placeholder )}
										 style={{...inputStyle , opacity: inputDisabled ? 0.7 : 1}} maxLength={maxLength ?? 30}
										 autoComplete={'off'} onChange={onChange}/>
						)}
						<div style={{position: 'relative' , right: '-1px'}}>
							<MainButtons name={buttonText ?? '생성'} buttonType={'cta'} margin={'0'} marginDis={true} selected={checked}
													 clickEvent={clickButton} width={buttonWidth ?? '36px'} disabled={disabled}/>
						</div>
					</InputBase>
				)
			case 'withButtonUnit' :
				return (
					<InputBase>
						{tooltipModule( inputData , dataValid( limit , 0 ) ,
							<input type={'text'} name={name} value={inputData} disabled={inputDisabled} placeholder={t( placeholder )}
										 maxLength={limit}
										 style={{...inputStyle , opacity: inputDisabled ? 0.7 : 1}} autoComplete={'off'}
										 onChange={onChange}/>
						)}
						<div style={{
							fontSize: '12px' ,
							color: 'rgba(23,23,21,0.5)' ,
							paddingRight: '12px' ,
							whiteSpace: 'nowrap'
						}}>{unit}</div>
						<div style={{position: 'relative' , right: '-1px'}}>
							<MainButtons name={buttonText ?? '생성'} buttonType={'cta'} margin={'0'} marginDis={true} selected={checked}
													 clickEvent={clickButton} fontSize={buttonFontSize} width={buttonWidth ?? '36px'}
													 disabled={disabled}/>
						</div>
					</InputBase>
				)

			// font-size: 12px;
			// text-decoration: underline;
			// text-underline-offset: 3px;
			// color: #7E84A3;
			case 'noneInputText':
				return (
					<div style={{
						display: 'flex' , justifyContent: 'space-between' , boxSizing: 'border-box' , padding: '0 8px' ,
						opacity: disabled ? 0.5 : 1 ,
						pointerEvents: disabled ? 'none' : 'auto' ,
					}}>
						<div style={{
							textDecoration: 'underline' ,
							textUnderlineOffset: '3px' ,
							textOverflow: 'ellipsis' ,
							overflow: 'hidden' ,
							color: '#7e84a3' ,
							fontSize: '12px'
						}}
								 onClick={clickButton}>{inputData}</div>
					</div>
				)
			case 'clickText':
				return (
					<div style={{display: 'flex' , justifyContent: 'space-between' , boxSizing: 'border-box'}}>
						<div style={{
							textDecoration: 'underline' , textUnderlineOffset: '3px' , textOverflow: 'ellipsis' , cursor: 'pointer' ,
							pointerEvents: disabled ? 'none' : 'inherit' , opacity: disabled ? 0.5 : 1 ,
							overflow: 'hidden' , color: '#7e84a3' , fontSize: '12px' , ...inputDataStyle
						}}
								 onClick={clickButton} onMouseDown={onMouseEvent}> {inputData}</div>
					</div>
				)
			case 'number' :
				return (
					<InputBase style={{
						height: height ,
						width: width ,
						display: 'flex' ,
						boxSizing: 'border-box' ,
						padding: '0 8px' ,
						justifyContent: 'space-between'
					}} onClick={clickButton}>
						<NumericFormat name={name} onChange={onChange} placeholder={t( placeholder )} maxLength={limit}
													 disabled={disabled}
													 type={'text'} value={inputData} style={inputStyle} autoComplete={'off'}/>
						<div style={{
							fontSize: '12px' ,
							color: 'rgba(23,23,21,0.5)' ,
							display: `${viewType ? 'block' : 'none'}`
						}}>{unit}</div>
					</InputBase>
				)
			case 'text' :
				return (
					<React.Fragment>
						{tooltipModule( inputData , 5 ,
							<InputBase style={{borderRadius: borderRadius , border: border , height: height , width: width}}>
								<input type={'text'} style={inputStyle} name={name} onChange={onChange} autoComplete={'off'}
											 maxLength={limit ?? 30} onClick={clickButton}
											 placeholder={t( placeholder )} value={inputData} disabled={disabled}/>
							</InputBase>
						)}
					</React.Fragment>
				)
			case 'password' :
				return (
					<InputBase
						style={{borderRadius: borderRadius , border: border , height: height , width: width , fontSize: '12px'}}
						onClick={clickButton}>
						<input type={'password'} style={inputStyle} name={name} onChange={onChange} autoComplete={'off'}
									 placeholder={placeholder} value={inputData} disabled={disabled} readOnly={readonly}/>
					</InputBase>
				)
			case 'check' :
				return (
					<input type="checkbox" style={{cursor: 'pointer' , margin: 0 , ...style}} name={name} id={id}
								 onClick={clickButton} disabled={disabled} tabIndex={- 1}
								 onChange={onChange} checked={checked}/>
				)
			case 'unit' :
				return (
					<InputBase style={{
						paddingRight: '8px' ,
						cursor: 'pointer' ,
						pointerEvents: disabled ? 'none' : 'auto' ,
						opacity: disabled ? 0.5 : 1 , ...style
					}}
										 onMouseDown={clickButton}>
						<input type={inputType ?? 'text'} name={name} value={inputData} onKeyDown={onKeyDownEvent}
									 autoFocus={onFocus} readOnly={disabled}
									 maxLength={limit ?? 5} onBlur={onBlur} placeholder={placeholder} style={{
							...inputStyle ,
							pointerEvents: inputDisabled ? 'none' : 'auto' ,
							opacity: inputDisabled ? 0.5 : 1 ,
						}}
									 onFocus={e => {!focusCancel && e.target.select()}}
									 onChange={onChange} autoComplete={'off'}/>
						<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
					</InputBase>
				)
			case 'unitSelect' :
				return (
					<InputBase style={{paddingRight: '8px' , cursor: 'pointer'}} onClick={clickButton}>
						<div className={'unitSelect_hover'} style={{...inputStyle , alignSelf: 'center' , lineHeight: '28px'}}>
							{inputData}
						</div>
						{/*<input type={inputType ?? 'text'} name={name} value={inputData} onKeyDown={onKeyDownEvent} autoFocus={onFocus}*/}
						{/*          onBlur={onBlur} placeholder={placeholder} style={inputStyle} disabled={disabled}*/}
						{/*          onChange={onChange} autoComplete={'off'}/>*/}
						<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
					</InputBase>
				)
			case 'selectedUnit' :
				return (
					<InputBase style={{
						paddingRight: '8px' ,
						cursor: 'pointer' ,
						pointerEvents: disabled ? 'none' : 'auto' ,
						opacity: disabled ? 0.5 : 1
					}}>
						<input type={inputType ?? 'text'} name={name} value={inputData} onKeyDown={onKeyDownEvent}
									 maxLength={limit ?? 5} onBlur={onBlur} placeholder={placeholder} style={{
							...inputStyle ,
							color: selected ? '#0058ff' : '#171725' , fontWeight: selected ? 700 : 400
						}}
									 onFocus={e => {!focusCancel && e.target.select()}}
									 onChange={onChange} autoComplete={'off'}/>
						{selected ?
							<img src={blueCancel} alt="" onClick={clickButton}
									 style={{width: '16px' , height: '16px'}}/>
							:
							<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>}
					</InputBase>
				)
			case 'noneInputUnit' :
				return (
					<AutoTextSize minFontSizePx={12} maxFontSizePx={14}>
						<div style={{
							display: 'flex' ,
							boxSizing: 'border-box' ,
							paddingRight: '8px' ,
							justifyContent: 'space-between' ,
							gap: '4px'
						}}>
							<div style={{...style}} onClick={clickButton}>
								{inputData}
							</div>
							<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
						</div>
					</AutoTextSize>
				)
			case 'noneInputUnitClick':
				return (
					<div style={{
						display: 'flex' , justifyContent: 'space-between' , boxSizing: 'border-box' , padding: '0 8px' ,
						opacity: disabled ? 0.5 : 1 ,
						pointerEvents: disabled ? 'none' : 'auto' ,
					}}>
						<div style={{
							textDecoration: 'underline' ,
							textUnderlineOffset: '3px' ,
							textOverflow: 'ellipsis' ,
							overflow: 'hidden' ,
							color: '#7e84a3' ,
							fontSize: '12px'
						}} onClick={clickButton}>{inputData}</div>
						<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
					</div>
				)
			case 'usagePercent' :
				return (
					// @ts-ignore
					<ProgressBar overCount={percentageGage >= 100} style={{backgroundColor: '#E2E2E2'}}
											 percentage={percentageGage}>
						<div className={'bar'} style={{width: `${percentageWidth}%` , ...style}}/>
					</ProgressBar>
				)
			case 'deliverInputUnit' :
				return (
					<div style={{display: 'flex' , boxSizing: 'border-box' , padding: '0 8px' , justifyContent: 'space-between'}}>
						<div style={{...style}} onClick={clickButton}>{inputData}</div>
						<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
					</div>
				)
			case 'phone' :
				return (
					<InputBase style={{borderRadius: borderRadius , border: border , height: height}}
										 onBlur={onChange}>
						<PatternFormat format={phoneNumberFormat( inputData ) ?? '###-####-####'} type={'tel'}
													 name={name} placeholder={t( placeholder ?? '내용을 입력해 주세요.' )} autoComplete={'off'}
													 value={inputData}
													 onChange={onChange}
													 className={'formatInput'}/>
					</InputBase>
				)
			case 'businessNumber' :
				return (
					<InputBase style={{borderRadius: borderRadius , border: border , height: height}}>
						<PatternFormat format={'###-##-#####'} type={'tel'} autoComplete={'off'}
													 name={name} placeholder={t( placeholder ?? '내용을 입력해 주세요.' )}
													 value={inputData} onChange={onChange} className={'formatInput'}/>
					</InputBase>
				)
			case 'file' :
				return (
					<React.Fragment>
						{modalState.open && modalState.status === 'file' &&
							<ConfirmPopup clickEvent={() => setModalState( {open: false , status: '' , message: ''} )}
														statusText={modalState.message}/>}
						{modalState.open && modalState.status === 'loading' &&
							<LoadingComponent loadingText={modalState.message}/>}
						<InputBase style={{borderRadius: borderRadius , border: border , height: height , padding: 0}}
											 onClick={clickButton}>
							{( !imageUrlState || !imageUrlState[0] ) ?
								<label className={'prev_file'}>
									<input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={
										( e: any ) => {
											if( inputRef.current.value.match( extendReg ) ){
												if( e.target.files[0].size < 10 * 1024 * 1024 ){
													fileUpload( e , e.target.files[0] ).then()
												} else{
													setModalState( {open: true , status: 'file' , message: t( '10MB 이하의 파일을 업로드 해주세요.' )} )
													inputRef.current.value = ""
												}
											} else{
												setModalState( {open: true , status: 'file' , message: t( 'jpg, png 파일을 업로드 해주세요.' )} )
											}
										}}/>
									{buttonText}
								</label>
								:
								<label style={{color: '#171725' , fontWeight: 400}} className={'file_name'}>
									<input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={
										( e: any ) => {
											if( inputRef.current.value.match( extendReg ) ){
												if( e.target.files[0].size < 10 * 1024 * 1024 ){
													fileUpload( e , e.target.files[0] ).then()
												} else{
													setModalState( {open: true , status: 'file' , message: t( '10MB 이하의 파일을 업로드 해주세요.' )} )
													inputRef.current.value = ""
												}
											} else{
												setModalState( {open: true , status: 'file' , message: t( 'jpg, png 파일을 업로드 해주세요.' )} )
											}
										}}/>
									{imageUrlState}
								</label>}
						</InputBase>
					</React.Fragment>
				)
			case 'buttonFile' :
				return (
					<React.Fragment>
						{modalState.open && modalState.status === 'file' &&
							<ConfirmPopup clickEvent={() => setModalState( {open: false , status: '' , message: ''} )}
														statusText={modalState.message}/>}
						{modalState.open && modalState.status === 'loading' &&
							<LoadingComponent loadingText={modalState.message}/>}
						<InputBase style={{borderRadius: borderRadius , border: border , height: height , padding: 0}}
											 onClick={clickButton}>
							<label className={useV2Button ? 'prev_file_v2' : 'prev_file'}>
								<input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={
									( e: any ) => {
										if( inputRef.current.value.match( extendReg ) ){
											if( e.target.files[0].size < 10 * 1024 * 1024 ){
												fileUpload( e , e.target.files[0] ).then()
											} else{
												setModalState( {open: true , status: 'file' , message: t( '10MB 이하의 파일을 업로드 해주세요.' )} )
												inputRef.current.value = ""
											}
										} else{
											setModalState( {open: true , status: 'file' , message: t( 'jpg, png 파일을 업로드 해주세요.' )} )
										}
									}}/>
								{buttonText}
							</label>
						</InputBase>
					</React.Fragment>
				)
			case 'textClickFile':
				return (
					<React.Fragment>
						{modalState.open && modalState.status === 'file' &&
							<ConfirmPopup clickEvent={() => setModalState( {open: false , status: '' , message: ''} )}
														statusText={modalState.message}/>}
						{modalState.open && modalState.status === 'loading' &&
							<LoadingComponent loadingText={modalState.message}/>}
						<div
							style={{display: 'flex' , justifyContent: 'space-between' , boxSizing: 'border-box' , padding: '0 8px'}}>
							<label style={{
								textDecoration: 'underline' ,
								textUnderlineOffset: '3px' ,
								textOverflow: 'ellipsis' ,
								overflow: 'hidden' ,
								cursor: 'pointer'
							}}
										 onClick={clickButton}>
								<input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={
									( e: any ) => {
										if( inputRef.current.value.match( extendReg ) ){
											if( e.target.files[0].size < 10 * 1024 * 1024 ){
												fileUpload( e , e.target.files[0] ).then()
											} else{
												setModalState( {open: true , status: 'file' , message: t( '10MB 이하의 파일을 업로드 해주세요.' )} )
												inputRef.current.value = ""
											}
										} else{
											setModalState( {open: true , status: 'file' , message: t( 'jpg, png 파일을 업로드 해주세요.' )} )
										}
									}}/>
								{inputData}
							</label>
						</div>
					</React.Fragment>
				)
			case 'clickSaveFile':
				return (
					<React.Fragment>
						{modalState.open && modalState.status === 'file' &&
							<ConfirmPopup clickEvent={() => setModalState( {open: false , status: '' , message: ''} )}
														statusText={modalState.message}/>}
						{modalState.open && modalState.status === 'loading' && <LoadingComponent loadingText={modalState.message}/>}
						<InputBase style={{borderRadius: borderRadius , border: border , height: height , padding: 0}}
											 onClick={clickButton}>
							<label className={'prev_file'}>
								<input type={'file'} style={{display: 'none'}} ref={inputRef} onChange={
									( e: any ) => {
										if( inputRef.current.value.match( extendReg ) ){
											if( e.target.files[0].size < 10 * 1024 * 1024 ){
												onFileUploadSaveImg( e , e.target.files[0] ).then()
											} else{
												setModalState( {open: true , status: 'file' , message: t( '10MB 이하의 파일을 업로드 해주세요.' )} )
												inputRef.current.value = ""
											}
										} else{
											setModalState( {open: true , status: 'file' , message: t( 'jpg, png 파일을 업로드 해주세요.' )} )
										}
									}}/>
								{buttonText}
							</label>
						</InputBase>
					</React.Fragment>
				)
			case 'address' :
				return (
					<React.Fragment>
						{tooltipModule( inputData , 0 ,
							<InputBase onClick={() => {
								if( addressClick && inputData !== '' ){
									addressClick()
								}
							}}>
								<input type={'text'} name={name} value={inputData} autoComplete={'off'} disabled={true}
											 placeholder={placeholder} style={inputStyle} onChange={onChange}/>
								{
									// inputData === '' &&
									<MainButtons name={'주소검색'} clickEvent={() => {
										if( clickButton ){
											clickButton()
										}
									}} buttonType={'cta'} width={'72px'} margin={'0'} marginDis={true}/>
								}
							</InputBase>
						)}
					</React.Fragment>
				)
			case 'drop' :
				return (
					<div style={{
						display: 'flex' ,
						flexDirection: 'column' ,
						position: 'relative' ,
						opacity: disabled ? 0.3 : 1 ,
						pointerEvents: disabled ? 'none' : 'auto'
					}} ref={dropMenuRef}>
						<InputBase onClick={() => {
							setDropOpen( !dropOpen );
							if( clickButton ){
								clickButton()
							}
						}} style={{borderRadius: borderRadius , border: border}}>
							<input type={'text'} tabIndex={tabIndex} name={name} value={dropSelect && inputData} autoComplete={'off'}
										 style={{
											 caretColor: 'transparent' ,
											 cursor: 'pointer' , ...inputStyle ,
											 fontSize: '12px' , ...style
										 }}
										 placeholder={'선택'} readOnly={true}/>
							<img src={arrow} alt="" style={{
								transform: `rotateZ(270deg) rotateY(${dropOpen ? 180 : 0}deg)` ,
								transition: 'all 0.35s' ,
								backgroundColor: 'transparent'
							}}/>
						</InputBase>
						<DropBox dropTop={dropTopDirection} dropHeight={dropHeight}
										 onScroll={() => {
											 if( dropScrollHas ){
												 if( dropScrollEvent ){
													 dropScrollEvent()
												 }
											 }
										 }}>
							{dropMenuArray?.map( ( v , i ) => (
								<DropBase style={{
									color: '#171725' ,
									fontWeight: 400 ,
									borderRadius: borderRadius ,
									fontSize: dropFontSize ?? '14px'
								}} delay={i * 0.02} border={true}
													onClick={() => {
														setDropSelect( v )
														setDropOpen( false )
														if( selectDrop ){
															selectDrop( v === '선택 안함' ? '' : v )
														}
													}} open={dropOpen}>{v}
								</DropBase>
							) )}
						</DropBox>
					</div>
				)
			case 'selectDrop' :
				return (
					<div style={{display: 'flex' , flexDirection: 'column' , position: 'relative'}} ref={dropMenuRef}>
						<InputBase onClick={() => setDropOpen( !dropOpen )} style={{borderRadius: borderRadius , border: border}}>
							<input type={'text'} name={name} value={dropSelect && inputData} autoComplete={'off'} readOnly={true}
										 style={{caretColor: 'transparent' , cursor: 'pointer' , ...inputStyle , fontSize: '12px'}}
										 placeholder={'선택'} onChange={onChange}/>
							<img src={arrow} alt="" style={{
								transform: `rotateZ(270deg) rotateY(${dropOpen ? 180 : 0}deg)` ,
								transition: 'all 0.35s' ,
								backgroundColor: 'transparent'
							}}/>
						</InputBase>
						<DropBox dropTop={dropTopDirection}
										 onScroll={() => {
											 if( dropScrollHas ){
												 if( dropScrollEvent ){
													 dropScrollEvent()
												 }
											 }
										 }}>
							{dropMenuList?.map( ( v , i ) => (
								<DropBase style={{color: '#171725' , fontWeight: 400 , borderRadius: borderRadius}}
													delay={i * 0.02} border={true}
													onClick={() => {
														setDropSelect( v )
														setDropOpen( false )
														if( selectDrop ){
															selectDrop( v )
														}
													}} open={dropOpen}>{v}
								</DropBase>
							) )}
						</DropBox>
					</div>
				)
			case 'objectDrop' :
				return (
					<div style={{
						display: 'flex' ,
						flexDirection: 'column' ,
						position: 'relative' ,
						pointerEvents: disabled ? 'none' : 'auto' ,
						opacity: disabled ? 0.5 : 1
					}} ref={dropMenuRef}>
						<InputBase onClick={() => {
							setDropOpen( !dropOpen );
							if( clickButton ){
								clickButton()
							}
						}} style={{borderRadius: borderRadius , border: border}}>
							<input type={'text'} name={name} value={realTimeValue} autoComplete={'off'}
										 style={{caretColor: 'transparent' , cursor: 'pointer' , ...inputStyle , fontSize: '12px'}}
										 placeholder={'선택'} onChange={onChange} readOnly={true}/>
							<img src={arrow} alt="" style={{
								transform: `rotateZ(270deg) rotateY(${dropOpen ? 180 : 0}deg)` ,
								transition: 'all 0.35s' ,
								backgroundColor: 'transparent'
							}}/>
						</InputBase>
						<DropBox dropTop={dropTopDirection}
										 onScroll={() => {
											 if( dropScrollHas ){
												 if( dropScrollEvent ){
													 dropScrollEvent()
												 }
											 }
										 }}>
							{dropMenuList?.map( ( v , i ) => (
								<DropBase style={{color: '#171725' , fontWeight: 400 , borderRadius: borderRadius}}
													delay={i * 0.02} border={true}
													onClick={() => {
														setDropSelect( v )
														setDropOpen( false )
														setRealTimeValue( v[dropKey] )
														if( selectDrop ){
															selectDrop( v )
															//@ts-ignore
														}
													}} open={dropOpen}>
									{v[dropKey]}
								</DropBase>
							) )}
						</DropBox>
					</div>
				)
			case 'textDrop' :
				return (
					<div style={{display: 'flex' , flexDirection: 'column' , position: 'relative'}} ref={dropMenuRef}>
						<InputBase>
							<input type={'text'} name={name} value={inputData} placeholder={placeholder} maxLength={maxLength}
										 readOnly={true}
										 autoComplete={'off'} style={inputStyle}
										 onChange={onChange} onClick={clickButton}/>
							<div style={{fontSize: '12px' , color: 'rgba(23,23,21,0.5)'}}>{dropUnitSelect}</div>
							<img onClick={() => {setDropOpen( !dropOpen )}} src={arrow} alt=""
									 style={{
										 transform: `rotateZ(270deg) rotateY(${dropOpen ? 180 : 0}deg)` ,
										 transition: 'all 0.35s' ,
										 backgroundColor: 'transparent'
									 }}/>
						</InputBase>
						<div style={{position: 'absolute' , right: 0 , top: '32px' , width: width , zIndex: 49}}>
							{dropMenuList?.map( ( v , index ) => (
								<DropBase style={{color: '#171725' , fontWeight: 400 , borderRadius: borderRadius}}
													delay={index * 0.1} open={dropOpen} className={'hover_cus'} border={true}
													onClick={() => {
														setDropUnitSelect( v );
														setDropOpen( false );
														if( selectDrop ){
															selectDrop( v )
														}
													}}>{v}
								</DropBase>
							) )}
						</div>
					</div>
				)
			case 'timeDrop' :
				return (
					<div style={{
						display: 'flex' ,
						flexDirection: 'column' ,
						position: 'relative' ,
						pointerEvents: disabled ? 'none' : 'auto' ,
						opacity: disabled ? 0.5 : 1
					}} ref={dropMenuRef}>
						<InputBase onClick={() => {
							setDropOpen( !dropOpen );
							if( clickButton ){
								clickButton()
							}
						}} style={{borderRadius: borderRadius , border: border}}>
							<input type={'text'} tabIndex={tabIndex} name={name} value={dropSelect} autoComplete={'off'} style={{
								caretColor: 'transparent' ,
								cursor: 'pointer' , ...inputStyle ,
								color: '#5A607F' ,
								fontSize: '14px'
							}}
								//@ts-ignore
										 placeholder={'선택'} readOnly={true}/>
							<img src={arrow} alt="" style={{
								transform: `rotateZ(270deg) rotateY(${dropOpen ? 180 : 0}deg)` ,
								transition: 'all 0.2s' ,
								backgroundColor: 'transparent'
							}}/>
						</InputBase>
						<DropBox dropTop={dropTopDirection}
										 onScroll={() => {
											 if( dropScrollHas ){
												 if( dropScrollEvent ){
													 dropScrollEvent()
												 }
											 }
										 }}>
							{dropMenuList?.map( ( v , i ) => (
								<DropBase style={{color: '#5A607F' , fontSize: '14px' , fontWeight: 400 , borderRadius: borderRadius}}
													delay={i * 0.01} border={true}
													backColor={dropSelect ? '#0058FF0D' : '#fff'}
													onClick={() => {
														setDropSelect( v )
														setDropOpen( false )
														if( selectDrop ){
															selectDrop( v )
														}
													}} open={dropOpen}>{v}
								</DropBase>
							) )}
						</DropBox>
					</div>
				)
			case 'rangeDisplay':
				return (
					<div style={{
						display: 'flex' ,
						boxSizing: 'border-box' ,
						paddingRight: '8px' ,
						gap: '4px',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
            <span style={{textAlign: 'center' , pointerEvents: 'none'}}>
              {inputData?.toString().split( '~' )[0] || '0000'}
            </span>
						~
						<span style={{textAlign: 'center' , pointerEvents: 'none'}}>
              {inputData?.toString().split( '~' )[1] || '0000'}
            </span>
						<div style={{fontSize: '10px' , color: 'rgba(23,23,21,0.5)' , whiteSpace: 'nowrap'}}>{unit}</div>
					</div>
				);
			case 'range':
				return (
					<div style={{ display: 'flex' , justifyContent: 'space-between' , gap: '4px' }}>
					<InputBase style={{...style , display: 'flex' }}>
						<input
							type="text"
							name={`${name}_min`}
							value={inputData?.toString().split( '~' )[0]}
							placeholder=" "
							onChange={onChange}
							style={{...inputStyle , width: '45%' , textAlign: 'center'}}
						/>
						~
					</InputBase>
				<InputBase style={{...style , display: 'flex' }}>
						<input
							type="text"
							name={`${name}_max`}
							value={inputData?.toString().split( '~' )[1]}
							placeholder=" "
							onChange={onChange}
							style={{...inputStyle , width: '45%' , textAlign: 'center'}}
						/>
				</InputBase>
					</div>

				);
		}
	}
	return (
		<div onClick={(e)=> `${type.includes('none')? '' : e.stopPropagation()}`}
				 style={{width:`${width}px`, alignSelf:'center', alignItems:'center',
					 alignContent:'center',overflow:'visible'}}>
			{inputCase(type)}
		</div>
	);
};

export default ListInnerInput

const InputBase = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 8px;
	height: 32px;
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid #d5d7e3;
	align-items: center;
	background-color: #fff;

	&:hover {
		border: 1px solid #0058ff;
	}


	&:focus-within {
		border: 1px solid #0058FF;
	}

	.formatInput {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		outline: none;
		border-radius: 4px;
		padding: 0;
		font-size: 14px;
		font-family: "NanumGothicOTF", sans-serif;
		border: none;


		&:focus {
			transition: all 0.3s;
			//font-weight: 700;
		}

		&::placeholder {
			font-family: "NanumGothicOTF", sans-serif;
			font-size: 14px;
			//color: rgba(23, 23, 37, 0.3);
			box-sizing: border-box;
			font-weight: normal;
		}
	}

	input[type='number']::-webkit-outer-spin-button,
	input[type='number']::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.file_name {
		width: 100%;
		padding-left: 8px;
		box-sizing: border-box;
		text-decoration: underline;
		text-underline-offset: 3px;
		text-overflow: ellipsis;
		overflow: hidden;

		&:hover {
			text-decoration: none;
			cursor: pointer;
		}
	}

	.prev_file {
		color: #0058FF;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		width: 100%;
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
		transition: all 0.1s;

		&:hover {
			color: #fff;
			background-color: #0058ff;
			cursor: pointer;
		}
	}

	.prev_file_v2 {
		color: #5A607F;
		font-size: 14px;
		font-weight: 700;
		text-align: center;
		width: 100%;
		height: 32px;
		line-height: 32px;
		border-radius: 4px;
		transition: all 0.1s;

		&:hover {
			color: #fff;
			background-color: #5A607F;
			cursor: pointer;
		}
	}
`
const DropBox = styled.div<{dropTop?: boolean, dropHeight?: number}>`
	position: absolute;
	top: ${props => props.dropTop? 'auto' : '32px'};
	bottom: ${props => props.dropTop? '32px' : 'auto'};
	width: 100%;
	z-index: 49;
	max-height: ${props => props.dropHeight ?? 128}px;
	overflow-y: scroll;
	&::-webkit-scrollbar{
		display: block;
		width: 14px;
	}
`


const DropBase = styled.div<{open: boolean, delay?: number,backColor?:string, border?:boolean}>`
	display: flex;
	justify-content: space-between;
	padding-left: 8px;
	height: ${props=>props.open? 32 : 0}px;
	box-sizing: border-box;
	border-radius: 4px;
	border: ${props=>props.open? 1 : 0}px solid #D7DBEC;
	align-items: center;
	background-color: #fff;
	overflow: hidden;
	transition: height 0.05s ${props => props.delay}s, border 0.05s;
	cursor: pointer;
	&:hover{
		background-color: ${props=>props.backColor? '#d7dbec' : '#fff'};
		border: ${props=>props.border? '1px solid #0058ff': 'inherit'};
	}
`

const ProgressBar = styled.div<{width?:string,height?:string,percentage?:any ,overCount?:boolean}>`
	display: flex;
	height: ${p=>p.height ?? "32px"};
	box-sizing: border-box;
	width: ${p=>p.width ?? "96px"};
	padding: 4px 0;
	position: relative;
	&::before {
		content: '${p=>p.percentage < 100 ? p.percentage + '%': '초 과' }';
		position: absolute;
		color:${p=>p.percentage >= 50? '#fff' : '#000' };
		display: flex;
		align-self: center;
		font-size: 14px;
		font-weight: 900;
		left: 50%;
		transform: translate(-50%, 0%);
	}
	.bar {
		box-sizing: border-box;
		transition: width 0.8s;
		background: ${p=>p.overCount  ? 'red' : `linear-gradient(to right, #0058FF80, #0058FF)` };
		box-shadow: 0 3px 6px #00000029;
	}
`
