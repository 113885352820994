import React , {useRef , useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {
  authValid ,
  dataValid ,
  initModal , isTouchDevice , loadingRemove ,
  modalListLoading ,
  numberFormatScale ,
  numberLocaleString
} from "../../../function/function";
import {Modal_View_Type} from "../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../common/StateManage";
import {
  autoTextValueValidation ,
  GridTableComponent ,
  GridTableSelect , modalTitleBase ,
  NoData ,
  tooltipModule ,
  validationValue
} from "../../../style/StyleComponent";
import ListInnerInput from "../../input/ListInnerInput";
import client from "../../../common/Config";
import {Authorization} from "../../../common/authorization";
import ModalBase from "../ModalBase";
import ToastPopup from "../ToastPopup";
import ConfirmPopup from "../ConfirmPopup";
import Draggable from "react-draggable";
import DropMenu from "../../sideMenu/DropMenu";
import PaginationList from "../../pagination/PaginationList";
import MainButtons from "../../button/MainButtons";
import FilterWithSearchBar from "../../input/FilterWithSearchBar";

interface Modal_Props {
  receiveData?: (data:any)=>void
  closeEvent:()=>void
  viewType?: boolean
  menuArrayType?: string
  filterArray?:string[]
  firstTypes?: string
  mainProductCode?: string
  selectedCode?: string
  subBomReg?: boolean
  receiveBomList?: (data:any[], product:any)=>void
  selectProductObj?:any
}

const ProcessProductFilterModal:React.FunctionComponent<Modal_Props> = ({
                                                                          receiveData,
                                                                          viewType,
                                                                          closeEvent, menuArrayType,
                                                                          filterArray, firstTypes, mainProductCode}) => {

  const {t}:any = useTranslation()
  const modalRef = useRef(null);
  const [categorySelect, setCategorySelect] = useState<string>(dataValid(firstTypes,t('품목 전체')))
  const [categoryTypeSelect , setCategoryTypeSelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})
  const [page, setPage] = useState<number>(1)
  const [pageBlock, setPageBlock] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [integratedList, setIntegratedList] = useState<any>([])
  const [selectData, setSelectData] = useState<any>({})
  const [loadingMessage, setLoadingMessage] = useState<string>('')
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [searchState, setSearchState] = useState<{input: string, value: string}>({input:'', value:''})
  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  // const [newProduct, setNewProduct] = useState<any>({})


  const categoryTranslate = (category: string) => {
    switch (category){
      case t('품목') : return 'product'
      case t('원자재') : return 'rawMaterial'
      case t('부자재') : return 'subMaterial'
    }
  }

  const productCompare = categoryTranslate(categorySelect.includes('품')? '품목' : categorySelect) === 'product'

  // 구분 지어서 보여 줘야 할 카테고리 가 있다면 여기에 추가 해주세요
  const menuArrayTypes = (type: string) => {
    switch (type) {
      default : return [t('품목 전체'),t('완제품'),t('반제품'),t('재공품'),t('원자재'),t('부자재')]
      case 'product' : return [t('품목 전체'),t('완제품'),t('반제품'),t('재공품')]
      case 'rawMaterial' : return [t('원자재')]
      case 'process' : return [t('반제품'),t('재공품'),t('원자재'),t('부자재')]
    }
  }

  const byCategorySelectArrayFunc = {
    categoryValueArray(category:string){
      switch (category){
        case t('품목 전체') : return ['전체','거래처','모델','CODE','품명']
        case t('완제품') : return ['전체','거래처','모델','CODE','품명']
        case t('반제품') : return ['전체','거래처','모델','CODE','품명']
        case t('재공품') : return ['전체','거래처','모델','CODE','품명']
        case t('원자재') : return ['전체','원자재 CODE','원자재 품명','재질','재질 종류','거래처']
        case t('부자재') : return ['전체','부자재 CODE','부자재 품명','거래처']
      }
    },
    categoryTypeArray(category:string){
      switch (category){
        case t('품목 전체') : return ['@','customer_name','customer_model_name','serial_code','product_name']
        case t('완제품') : return ['@','customer_name','customer_model_name','serial_code','product_name']
        case t('반제품') : return ['@','customer_name','customer_model_name','serial_code','product_name']
        case t('재공품') : return ['@','customer_name','customer_model_name','serial_code','product_name']
        case t('원자재') : return ['@','raw_material_serial_code','raw_material_name','material','material_type','customer_name']
        case t('부자재') : return ['@','sub_material_serial_code','sub_material_name','customer_name']
      }
    }
  }


  const integratedListData = async () => {
    setLoadingMessage('목록을 불러오는 중 입니다.')
    const categoryMessage = (category: string) => {
      switch (category) {
        case t('품목') : return '품목이'
        case t('품목 전체') : return '품목이'
        case t('완제품') : return '품목이'
        case t('반제품') : return '품목이'
        case t('재공품') : return '품목이'
        case t('원자재') : return '원자재가'
        case t('부자재') : return '부자재가'
        case t('금형') : return '금형이'
      }
    }
    try {
      modalListLoading()
      const response = productCompare ?
        await client.post ( `/${categoryTranslate(categorySelect.includes('품')? '품목' : categorySelect)}/search/type`,{
                              companyCode:selectedCompany.companyCode,
                              keyword:searchState.value,
                              page:page,
                              size:5,
                              type:categorySelect,
                              filter:categoryTypeSelect.type
                            }
          ,{headers:{Authorization}})
        :
        await client.get( `/${categoryTranslate(categorySelect)}/list/${page}/5/${selectedCompany.companyCode}`
          ,{
                            headers:{Authorization}
                          })

      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setIntegratedList((prev:any)=> prev.concat(data.row))
      // }else{
      //   setIntegratedList(data.row)
      // }
      setIntegratedList(data.row)
      loadingRemove()
      setLoadingMessage(t(`등록된 ${categoryMessage(categorySelect)} 없습니다.`))
    }catch (e) {
      console.log(e)
      loadingRemove()
      setLoadingMessage('서버 에러 입니다. 관리자에게 문의해 주세요.')
    }
  }


  const integratedSearchData = async () => {
    setLoadingMessage('목록을 불러오는 중 입니다.')
    try {
      modalListLoading()
      const response = await client.post(
        productCompare?
          `/${categoryTranslate(categorySelect.includes('품')? '품목' : categorySelect)}/search/type`
          :
          `/${categoryTranslate(categorySelect)}/search`
        ,productCompare ? {
          companyCode:selectedCompany.companyCode,
          keyword:searchState.value,
          page:page,
          size: 5,
          type:categorySelect,
          filter:categoryTypeSelect.type
        } : {
          companyCode:selectedCompany.companyCode,
          keyword:searchState.value,
          page:page,
          size: 5,
          filter:categoryTypeSelect.type
        },{
          headers:{Authorization}
        })
      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setIntegratedList((prev:any)=> prev.concat(data.row))
      // }else{
      //   setIntegratedList(data.row)
      // }
      setIntegratedList(data.row)
      loadingRemove()
      setLoadingMessage(`${categorySelect} 검색 결과가 없습니다.`)
    }catch (e) {
      console.log(e)
      loadingRemove()
      setLoadingMessage('서버 에러 입니다. 관리자에게 문의해 주세요.')
    }
  }

  React.useEffect(()=>{
    if(!searchState.value){
      integratedListData().then()
    }else{
      integratedSearchData().then()
    }
  },[categorySelect, page, searchState.value,categoryTypeSelect.type])

  // const totalIntegratedData = integratedList.filter((v:any)=>(v.productCode !== mainProductCode))
  const customerCount = (customer:any) => customer.filter((v:any,i:number,arr:any)=> arr.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)
  const searchTableComponent = (category: string) =>{
    switch (category){
      //품목 모달창
      case t("품목") : return(
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(totalIntegratedData?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'120px 184px 184px 184px 88px  56px 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['거래처','모델','CODE','품명','품목 종류','단위','생산 공정'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.productCode}
                               selected={selectData?.productCode === v.productCode}
                               disabled={(v.productCode === mainProductCode || filterArray?.includes(v.productCode))}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.productCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 품목 입니다.'})
                                   }
                                   else if(mainProductCode === v.productCode){
                                     setDataModal({menu:'confirm', message:'BOM을 등록 하기 위해 선택된 품목 입니다.'})
                                   }
                                   else{
                                     if(receiveData){
                                       receiveData(v)
                                       closeEvent()
                                     }}
                                 }else{
                                   if(receiveData){
                                     receiveData(v)
                                     closeEvent()
                                   }}
                               }} onMouseDown={()=>{setSelectData(v)}}>
                {/*{(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :*/}
                {/*  tooltipModule(customerCount(v.customer).map((v:any)=>(v.customerName)).join(','),0,validationValue(customerCount(v.customer)[0].customerName + ((customerCount(v.customer).length -1) !== 0 ?` 외 ${customerCount(v.customer).length-1}` : '')))}*/}
                {/*{(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :*/}
                {/*  tooltipModule(v.customer.map((v:any)=>(v.customerModelName)).join(','),0,validationValue(v.customer[0].customerModelName + ((v.customer.length -1) !== 0 ?` 외 ${v.customer.length-1}` : '')))}*/}
                {autoTextValueValidation({value: customerCount(v.customer)[0].customerName + ((customerCount(v.customer).length -1) !== 0 ?` 외 ${customerCount(v.customer).length-1}` : '')})}
                {autoTextValueValidation({value: v.customer[0].customerModelName + ((v.customer.length -1) !== 0 ?` 외 ${v.customer.length-1}` : '')})}
                {tooltipModule(dataValid(v.serialCode))}
                {tooltipModule(dataValid(v.productName))}
                {validationValue(v.type)}
                {validationValue(v.unit, 'ea')}
                {validationValue(v.process)}
              </GridTableSelect>
            ))

          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
      //원자재 검색 모달창
      case t("원자재") : return (
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'160px 160px 90px 80px repeat(3, 120px) 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['원자재 CODE','원자재 품명','재질', '재질 종류', '두께','가로','세로'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.rawMaterialCode}
                               disabled={filterArray?.includes(v.rawMaterialCode)}
                               selected={selectData?.rawMaterialCode === v.rawMaterialCode}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.rawMaterialCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 원자재 입니다.'})
                                   }else{
                                     if(receiveData){
                                       receiveData({...v, type:'원자재'})
                                       closeEvent()
                                     }}
                                 }else{
                                   if(receiveData){
                                     receiveData({...v, type:'원자재'})
                                     closeEvent()
                                   }}
                               }} onMouseDown={()=>{setSelectData( {...v, type:'원자재'})}}>
                {autoTextValueValidation({value: v.rawMaterialSerialCode})}
                {autoTextValueValidation({value: v.rawMaterialName})}
                {autoTextValueValidation({value: v.material})}
                {validationValue(v.materialType)}
                {<ListInnerInput type={'noneInputUnit'} style={{width:'90px'}} inputData={numberFormatScale(v.thickness)} unit={'T'}/>}
                {<ListInnerInput type={'noneInputUnit'} style={{width:'90px'}} inputData={numberLocaleString(v.width)} unit={'mm'}/>}
                {<ListInnerInput type={'noneInputUnit'} style={{width:'90px'}} inputData={numberLocaleString(v.length)} unit={'mm'}/>}
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
      //부자재 검색 모달창
      case t("부자재") : return (
        // <InfiniteScroll scrollableTarget={'grid-scroll'} loader={<div/>} scrollThreshold={'100px'}
        //                 dataLength={dataValid(integratedList?.length, 0)}
        //                 next={()=>setPage((prev)=>prev+1)}
        //                 hasMore={totalPage > page}>
        <GridTableComponent id={'grid-scroll'} tempCol={'180px 180px 88px 120px 1fr'}
                            height={288} scrollWidth={1000}
                            scrollX_Hide={true} scrollY_Hide={true}>
          <div className={'grid-title-unchecked'}>
            {['부자재 CODE','부자재 품명','단위','거래처'].map((v)=>(
              <div key={v}>{t(v)}</div>
            ))}
          </div>
          {integratedList?.length === 0?
            <NoData height={240}>{loadingMessage}</NoData>
            :
            integratedList?.map((v:any)=>(
              <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.subMaterialCode}
                               disabled={filterArray?.includes(v.subMaterialCode)}
                               selected={selectData?.subMaterialCode === v.subMaterialCode}
                               onDoubleClick={()=>{
                                 if(filterArray){
                                   if(filterArray?.find((code:string)=> v.subMaterialCode === code)){
                                     setDataModal({menu:'warn', message:'이미 등록된 부자재 입니다.'})
                                   }else{
                                     if(receiveData){
                                       receiveData({...v, type:'부자재'})
                                       closeEvent()
                                     }}
                                 }else{
                                   if(receiveData){
                                     receiveData({...v, type:'부자재'})
                                     closeEvent()
                                   }}
                               }} onMouseDown={()=>{setSelectData({...v, type:'부자재'})}}>
                {autoTextValueValidation({value: v.subMaterialSerialCode})}
                {autoTextValueValidation({value: v.subMaterialName})}
                {validationValue(v.subMaterialUnit)}
                {validationValue(v.customer)}
              </GridTableSelect>
            ))
          }
        </GridTableComponent>
        // </InfiniteScroll>
      )
    }
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal(initModal)}}/>
            : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} timeSet={500} closeEvent= {()=> {setDataModal(initModal)}}/> : null}

          {dataModal.menu === 'confirm' &&
              <ConfirmPopup clickEvent={()=> {
                setDataModal( initModal )
                if(dataModal.message?.includes('검색어로')){
                  setSearchState((prev:{input: string, value: string})=>({...prev, value:''}))
                }
              }} statusText={dataModal.message}/>}

          {/*{dataModal.menu === 'new' &&*/}
          {/*    <NewRegistrationProductModal closeModal={()=>{setDataModal(initModal)}}*/}
          {/*                                 initCustomerData={selectProductObj}*/}
          {/*                                 initModelData={selectProductObj}*/}
          {/*                                 receivedData={()=>{''}}/>}*/}

          {/*{dataModal.menu === 'new' &&*/}
          {/*    <NewRegistrationProductModal*/}
          {/*        receivedData={(e)=> ((prev:any)=>({...prev, customer: e}))}*/}
          {/*        closeModal={()=>setDataModal(initModal)}*/}
          {/*        initModelData={dataValid(newProduct?.customer,[])}*/}
          {/*        initCustomerData={dataValid(newProduct?.customer,[])}/>}*/}


          <Draggable ref={modalRef} disabled={isTouchDevice}>
            <BaseComp>
              {modalTitleBase({mainTitle: t('품목 검색'), backgroundColor: authValid()? '#CFFA61' : '#7E84A3', color: authValid()? '#171725' : '#fff'}, '')}
              <div className={'modal_content'} onMouseDown={(e)=>{e.stopPropagation()}}>
                <div>
                  <div style={{display:'flex',position:'relative',justifyContent:'space-between', alignItems:'center',paddingLeft:'108px'}}>
                    <DropMenu menuArray={menuArrayTypes(dataValid(menuArrayType, ''))} type={'processWave'}
                              style={{ color: '#131523' }}  // marginRight 추가
                              selectValue={categorySelect}
                              persistWeight={800}
                              width={'108px'}
                              left={'0px'}
                              clickEvent={(v) => {
                                setPage(1);
                                setPageBlock(0);
                                setCategorySelect(v);
                                if (categorySelect !== v) {
                                  setIntegratedList([]);
                                }
                              }}  top={'0'} />
                    <FilterWithSearchBar margin={'0'}
                                         widthCus={'248px'} placeholder={t(`${categorySelect}`) + t(' 검색어를 입력해 주세요.')}
                                         keyPressDisable={true}
                                         keyFunction={(e)=>{
                                           setSearchState((prev:{input:string, value: string})=>({...prev, input: e.target.value.trimStart()}))}}
                                         clickEvent={()=>{
                                           setPage(1)
                                           setPageBlock(0)
                                           setSelectData({})
                                           setSearchState((prev:{input:string, value: string})=>({...prev, value: prev.input}))}}
                                         selectKeyword={categoryTypeSelect.value}
                                         categorySelectEvent={(value,type)=>{setCategoryTypeSelect({value:value,type:type})}}
                                         categoryTypeArray={byCategorySelectArrayFunc.categoryTypeArray(categorySelect)}
                                         categoryValueArray={byCategorySelectArrayFunc.categoryValueArray(categorySelect)}

                    />
                  {/*<SearchBar margin={'0 0 0 96px'}w*/}
                  {/*           widthCus={'248px'} placeholder={t(`${categorySelect}`) + t(' 검색어를 입력해 주세요.')}*/}
                  {/*           keyPressDisable={true}*/}
                  {/*           keyFunction={(e)=>{*/}
                  {/*             setSearchState((prev:{input:string, value: string})=>({...prev, input: e.target.value.trimStart()}))}}*/}
                  {/*           clickEvent={()=>{*/}
                  {/*             setPage(1)*/}
                  {/*             setPageBlock(0)*/}
                  {/*             setSelectData({})*/}
                  {/*             setSearchState((prev:{input:string, value: string})=>({...prev, value: prev.input}))}}/>*/}


                    <span style={{fontSize:'12px', alignItems:'center', color:'f0142f'}}>{'상위 단계 혹은 동일한 단계에 선택된 품목/자재는 BOM 등록을 할 수 없습니다.'}</span>

                    <div style={{display:'flex'}}>
                      <MainButtons name={'새로고침'} clickEvent={()=>{
                        if(page === 1){
                          if(!searchState.value){
                            integratedListData().then()
                          }else{
                            integratedSearchData().then()
                          }
                        }
                        else{
                          setPage(1)
                        }
                      }} width={'80px'} marginDis={true} />
                      {/*<MainButtons name={'신규등록'} clickEvent={()=>{setDataModal({menu:'new'})}} width={'80px'} buttonType={'cta'}/>*/}
                    </div>
                  </div>
                  {searchTableComponent(categorySelect.includes('품')?'품목' : categorySelect)}

                </div>
                <div style={{padding:'8px 0 16px', borderTop:'1px solid #d7dbec'}}>
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage} justify={'center'}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage}/>
                </div>

                <div style={{display:'flex', justifyContent:'center'}}>
                  {viewType?
                    <MainButtons name={'확인'} width={'144px'} buttonType={'popupNormal'}
                                 clickEvent={closeEvent}/>
                    :
                    <React.Fragment>
                      <MainButtons name={'취소'} width={'144px'} buttonType={'popupNormal'} margin={'0 12px'}
                                   clickEvent={closeEvent}/>
                      <MainButtons name={'선택 완료'} width={'144px'} margin={'0 12px'}
                                   disabled={(!selectData.productCode && !selectData.rawMaterialCode && !selectData.subMaterialCode && !selectData.moldCode)}
                                   buttonType={'popupCta'}
                                   clickEvent={()=>{
                                     if(filterArray){
                                       if(filterArray?.find((code:string)=>(code === (selectData?.productCode||selectData?.rawMaterialCode||selectData?.subMaterialCode)))){
                                         setDataModal({menu:'warn', message:`이미 등록된 ${categorySelect} 입니다.`})
                                       }else{
                                         if(receiveData){
                                           receiveData(selectData)
                                           closeEvent()
                                         }}
                                     }
                                     else{
                                       if(receiveData){
                                         receiveData(selectData)
                                         closeEvent()
                                       }}}}/>
                    </React.Fragment>
                  }
                </div>
              </div>
            </BaseComp>
          </Draggable>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default ProcessProductFilterModal;


const BaseComp = styled.div`
  width: 1032px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    cursor: move;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`
