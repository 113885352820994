import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import PauseTypeManagement from "../../../containers/admin/fas/company/processtype/PauseTypeManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasPause = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<PauseTypeManagement/>}/>
    </PageContainer>
  );
};
export default FasPause;
