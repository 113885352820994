import React from 'react';
import styled from "styled-components";
import {DatePickerComp} from "../../style/StyleComponent";
import ReactDatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import dayjs from "dayjs";
import calendar from "../../asset/image/ic-calendar.svg";
import DatePickerCustom from "../modal/DatePickerCustom";
import {useRecoilState} from "recoil";
import {f_ems_calendar_date , f_ems_graphDateState} from "../../common/StateManage";
import {getDay} from "date-fns";
import MainButtons from "./MainButtons";
import {useLocation} from "react-router-dom";
import {CircularProgress} from '@mui/material'

interface Interface {
  name?:string
  inputValue?:any
  onChange?: (e:any) =>void
  calenderName?:string
  disabled?:boolean
  femsUse?:boolean
  selectMonth?: boolean
  selectYear?: boolean
  femsAlarm?: boolean
  receiveDate?:(date:any)=>void
  resetButton?:boolean
  left?: string|number
  bottom?: string|number
  loading?: boolean
  buttonWidth?: string
  includeToday?: boolean
  dateFormatName?: string
}

const CalendarValidButton = ({resetButton,onChange,calenderName,disabled, selectMonth, selectYear, receiveDate,
                               left, bottom, loading, buttonWidth, includeToday, dateFormatName}:Interface) => {

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const initialDateSelected = new Date(today.getTime());
  !includeToday && initialDateSelected.setDate(initialDateSelected.getDate() - 1);
  const location = useLocation()
  const authValid = location.pathname.includes('admin')
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<any>((selectMonth || selectYear) ? new Date : initialDateSelected);
  const [resultData,setResultData] = React.useState(selectedDate)
  const handleApply = () => {
    if (receiveDate) {
      receiveDate(selectedDate);
    }
    setResultData(selectedDate)
    setShowDatePicker(false);
  };

  // const handleDateChange = (date: Date | any) => {
  //   setFemsDateSelected(date);
  //   setDateSelected(date); // 선택한 날짜를 dateSelected에 업데이트
  //   onChange && onChange(date);
  // };
  // console.log('====================')
  // console.log(dayjs(femsDateSelected).format('YYYY.MM.DD'),'FEMS')
  // console.log(dayjs(dateSelected).format('YYYY.MM.DD'),'NORMAL')
  // console.log(resetButton ? selectedDate + '1' : resultData + '2')
  // console.log(initialDateSelected)
  // console.log(selectedDate)''

  React.useEffect(()=>{
    resetButton && setResultData(initialDateSelected)
  },[resetButton])

  const handleDateSelect = (date:Date) => {
    if (date.getTime() === selectedDate?.getTime()) {
      if( receiveDate ){
        receiveDate( selectedDate )
      }
      setResultData(selectedDate)
      setShowDatePicker(false);
    }
  };
  return (
    <CalendarCustomContainer authType={authValid} selectMonth={selectMonth}>
      <BaseComponent onChange={onChange} widthCus={buttonWidth}
                     onClick={()=>{setShowDatePicker(true);}} style={{pointerEvents:disabled ? 'none' : 'auto', opacity:disabled? 0.3 : 1}}>
        {loading?
          <div style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column',height: '32px'}}>
            <CircularProgress size={20}/>
          </div>
          :

          <React.Fragment>
            <img src={calendar} alt=""/>
            {calenderName?
              <div style={{fontWeight:'700',marginRight:'4px',padding:'0 4px'}}>{calenderName}</div>: null}
            <div>{dateFormatName ?? dayjs(resultData).format(selectMonth? 'YYYY.MM' : selectYear? 'YYYY': 'YYYY.MM.DD')}</div>
          </React.Fragment>
        }
      </BaseComponent>

      <div style={{position:'absolute',left:left,bottom:bottom}}>
        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) =>setSelectedDate(date)}
          dateFormat={'yyyy/MM/dd'}
          locale={ko}
          open={showDatePicker}
          onSelect={handleDateSelect}
          onClickOutside={()=>{setShowDatePicker(false); setSelectedDate(resultData); setResultData(resultData)}}
          shouldCloseOnSelect={showDatePicker}
          showMonthYearPicker={selectMonth}
          showYearPicker={selectYear}
          yearItemNumber={9}
          maxDate={initialDateSelected}
          customInput={<div style={{appearance:'none'}}/>}
          renderCustomHeader= {({date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled}
          ) => (
            <div>
              <DatePickerCustom yearType={selectYear} monthType={selectMonth} authType={authValid}
                                data={{date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled, }}/>
              <div style={{display: 'flex' , position: 'absolute' , bottom: '-240px' , left: '8px'}}>
                <MainButtons name={'취소'} clickEvent={()=>{setShowDatePicker(false); setSelectedDate(resultData); setResultData(resultData)}}/>
                <MainButtons name={'적용'} buttonType={'cta'} clickEvent={handleApply}/>
              </div>
            </div>
          )}
        />
      </div>
    </CalendarCustomContainer>
  );
};
export default CalendarValidButton;

const BaseComponent = styled.div<{widthCus?:string,selectMonth?:boolean}>`
   display: flex;
   width: ${p=>p.widthCus ?? 'auto'};
   align-items: center;
  justify-content: center;
   background-color: #fff;
   font-size: 14px;
   border: 1px solid #D7DBEC;
   border-radius: 4px;
   padding: 4px 8px 4px 4px ;
   box-sizing: border-box;
   height: 32px;
   color: ${p=>p.selectMonth? '#0058ff' :'#5A607F'};
    font-weight: ${p=>p.selectMonth? '700' :'400'};
   user-select: none;
   cursor: pointer;
   transition: all 500ms;
   &:hover{
      background-color:${p=>p.selectMonth? '#0058ff' :'#5A607F'};
      color: #fff;
   }
  

`

const CalendarCustomContainer = styled.div<{authType?:boolean, selectMonth?:boolean}>`
  display: flex;
  font-family: "Nanum Gothic", sans-serif;
  
  .react-datepicker__triangle{
    display: none;
  }
  .react-datepicker__day-names{
    background-color: #fff;
  }

  .react-datepicker__tab-loop{
    position: relative;
    z-index: 50;
  }

  .react-datepicker{
    bottom: -275px;
    position: absolute;
    left: -340px;
    display: flex;
    font-family: 'NanumGothic', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #D7DBEC;
    line-height: 40px;
    margin-bottom: 16px;
    overflow: hidden;
  }
  
  .react-datepicker__month-container{
    height: ${props => props.selectMonth? 300 : 330}px;
    width: 240px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;

    .react-datepicker__header, react-datepicker__header--custom{
      border-bottom:none;
      font-weight: 600;
      z-index:50;

    }
    .react-datepicker__header{
      padding: 0;
      background-color: ${p=>p.authType? 'rgb(207, 250, 97)' : '#F5F6FA'};
      font-family: "Nanum Gothic", sans-serif;
      z-index:50;
    }
  }
  .react-datepicker__year--container{
    height: 300px;
    width: 240px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;

    .react-datepicker__header, react-datepicker__header--custom{
      border-bottom:none;
      font-weight: 600;
      z-index:50;
    }
    .react-datepicker__header{
      padding: 0;
      background-color: ${p=>p.authType? 'rgb(207, 250, 97)' : '#F5F6FA'};
      font-family: "Nanum Gothic", sans-serif;
      z-index:50;
    }
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; { /*<img src={calendar} onClick={clickEvent} alt="" style={{position:'absolute', right: '24px', cursor:'pointer'}}/>*/
    } -webkit-appearance: none;
  }
  .react-datepicker-wrapper{
    width: 0;
    position: relative;
  }

  .react-datepicker__month{
    text-align: center;
  }
  .react-datepicker__year-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 64px);
    align-items: center;
    text-align: center;
    grid-gap: 16px;
  }
  
  
  .react-datepicker__day--keyboard-selected{
    background-color: #0058FF;
    border-radius: 4px;
    color: #fff;
    font-family: 'Nanum Gothic OTF',sans-serif;
  }
  
  .react-datepicker__week{
    box-sizing: border-box;
    line-height: 32px;
    font-weight: 400;
    
    .react-datepicker__day--selected{
      background-color: #0058FF;
      font-weight: 400;
      color: #fff;
      outline: none;
    }
    
    .react-datepicker__day--today{
      font-weight: 400;
      position: relative;
      outline: none;
      &::before{
        content:'';
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        border: 1px solid #21D59B;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
      }
    }

    
    .react-datepicker__day--outside-month{
      visibility: hidden;
      outline: none;
    }
    
    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
      position: relative;
      color: #ccc;
      &::after{
        content: '';
        width: 3px;
        height: 3px;
        position: absolute;
        top:80%;
        border-radius: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        bottom: 0;
        background-color: transparent;
      } }
  }

  .react-datepicker__year-text{
    font-size: 12px;
    font-weight: 700;
    width: 56px;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;

    &:hover {
      color: #0058ff;
      border-radius: 4px;
      background-color: rgba(0, 88, 255, 0.3);
    }
  }
  
  .react-datepicker__year-text--selected{
    color: #fff;
    background-color: #0058ff;
  }
  
  .react-datepicker__monthPicker{
  display: grid;
  align-items: center;
  grid-gap: 4px;
}

.react-datepicker__month-text{
  font-size: 12px;
  font-weight: 700;
  width: 56px;
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
  &:hover {
    color: #0058ff;
    border-radius: 4px;
    background-color: rgba(0, 88, 255, 0.3);
  }
}

.react-datepicker__month-text--today{
  font-weight: 400;
  position: relative;
  outline: none;
  &::before{
    content:'';
    height: calc(100% - 2px);
    width: calc(100% - 32px);
    border: 1px solid #21D59B;
    position: absolute;
    top: 0;
    left: 15px;
    border-radius:  50%;
  }
}
  .react-datepicker__year-text--today{
    font-weight: 400;
    position: relative;
    outline: none;
    &::before{
      content:'';
      height: calc(100% - 4px);
      width: calc(100% - 28px);
      border: 1px solid #21D59B;
      position: absolute;
      top: 1px;
      left: 13px;
      border-radius:  50%;
    }
  }

  .react-datepicker__day{
    position: relative;
  }

  .react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range{
    background-color: #0058FF;
    color: #FFF;
  }

  .react-datepicker__month-container{
    display: flex;
    flex-direction: column;
    border: none;
  }
`