import React , {useState} from 'react';
import styled from "styled-components";
import {Map , MarkerClusterer , MapMarker} from "react-kakao-maps-sdk";
import SolutionCompanyFilterList from "../../../../components/modal/SolutionCompanyFilterList";
import {NoData , validationValue} from "../../../../style/StyleComponent";
import LegendStatus from "../../../../components/display/LegendStatus";
import arrow_bottom from "../../../../asset/image/chevron-down.svg";
import MainButtons from "../../../../components/button/MainButtons";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {useRecoilState , useRecoilValue} from "recoil";
import {companySolutionFilter , persistCompanyData , persistContainerMove} from "../../../../common/StateManage";
import {buttonAuthValid , dataValid , listLoadingCircle , loadingRemove} from "../../../../function/function";
import dayjs from "dayjs";
import first_maker from '../../../../asset/image/map_maker_1nd.svg'
import second_maker from '../../../../asset/image/map_marker_2nd.svg'
import third_maker from '../../../../asset/image/map_marker_3nd.svg'


interface Map_Types {
  closeMap: ()=>void
}


const FasMapOverviewContainer = ({closeMap}:Map_Types) => {
  const [centerPosition, setCenterPosition] = useState({lat: '36.47047524829324', lng: '127.86121117317678'})
  const [,setContainerMove] = useRecoilState(persistContainerMove)
  const [companyLocation, setCompanyLocation] = useState<any[]>([])
  const [mapLevel, setMapLevel] = useState(9)
  const [selectClusterData,setSelectClusterData] = useState<any[]>([])
  const [scrollBlock, setScrollBlock] = useState<boolean>(true)
  const [alarmSelect, setAlarmSelect] = useState<string>('')
  const [alarmHover, setAlarmHover] = useState<string>('')
  const [companyAddress,setCompanyAddress] = React.useState<string>('')
  const [businessCode,setBusinessCode] = React.useState<string>('')
  const [business,setBusiness] = React.useState<any[]>([])
  const [asData,setAsData] = React.useState<any[]>([])

  const selectSolution = useRecoilValue(companySolutionFilter)
  const [selectCompanyData,setSelectCompanyData] = useRecoilState(persistCompanyData);

  let requestCount = 0
  const allCompanyAddressRes = async () => {
    requestCount ++
    try {
      const response = await client.get(`/company/total/${selectSolution}`, {
        headers: {Authorization}
      })
      const data = response.data.data
      setCompanyLocation(data.companyAddress)
    } catch (e) {
      console.log(e)
    }
    finally {
      requestCount --
    }
  }

  ///business/management/search/${page}/${11}/${dataValid(containerMove === '전체' ? '@' : selectCompany.companyCode)}/@/${dataValid(searchValue,'@')}
  const businessListRes = async () =>{
    try {
      const response = await client.get(`/business/management/list/1/3/${dataValid(selectCompanyData?.companyCode)}/${'@'}`,{
        headers: {Authorization}
      })
      setBusiness(response.data.data.row)
    }catch ( e ) {
      console.log(e)
    }
  }
  const asListRes = async () =>{
    try{
      const response = await client.get(`/maintenance/action/mobile/history/list/1/3/${dataValid(selectCompanyData?.companyCode)}`,{
        headers: {Authorization}
      })
      setAsData(response.data.data.row)
    }catch ( e ) {
      console.log(e)
    }
  }

  React.useEffect(()=>{
    if(selectCompanyData?.companyCode){
      businessListRes().then()
      asListRes().then()
    }
  },[selectCompanyData?.companyCode])

  React.useEffect(() => {
    if(requestCount === 0){
      allCompanyAddressRes().then()
    }
  }, [selectSolution])

  // const [data,setData] = React.useState<any>();
  // //클러스터 내부에있는 마커 length
  // const clusterLength = data?._clusters?.map((v:any)=>(v?._markers?.length));
  // //클러스터 내부안에있는 마커 데이터
  // const clusterInnerData = data?._clusters?.map((v:any)=>(v?._markers?.map((v:any,index:number)=>(v))))?.map((subArray:any,index:number)=>(subArray?.map((v:any)=>(v?.Gb))))
  // //클러스터 내부에있는 마커 컬러 length
  // const clusterMarkerColorsLength = clusterInnerData?.map(( subArray: { filter: (arg0: (item: any) => any) => { (): any; new(): any; length: any; }; }) => subArray?.filter(item => item?.endsWith("_1"))?.length)
  // // console.log(clusterInnerData[0]?.filter(( item: string) => item.endsWith("_2")).length)
  // // data.filter(item => item.endsWith("_2")).length;
  // const result = clusterLength?.map((item:any, index:number) => {
  //   return item !== clusterMarkerColorsLength[index];
  // });

  const circleStyle = [
    { width: '40px', height: '40px', borderRadius: '50%', color: '#FFF', fontWeight: 600,
      background: 'transparent linear-gradient(180deg, #00439D 0%, #001B3E 100%) 0% 0% no-repeat padding-box',
      display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '18px',position:'relative ',}
  ]

  // const parseAddress = (address: string) => {
  //   const elements = address.split(' ');
  //   if (elements.length >= 3) {
  //     const city = elements[0]; // 시/도 이름
  //     const district = elements[1]; // 시/군/구 이름
  //     const town = elements[2]; // 읍/면/동 이름
  //     return {
  //       city,
  //       district,
  //       town,
  //     };
  //   }
  //   return null;
  // };


  return (
    <Container>
      <div onClick={()=>{setCompanyLocation([]); closeMap(); setSelectClusterData([])}} className={'out_button'}>{'지도 나가기'}</div>
      <Map id={'map'} center={{lat: parseFloat(centerPosition.lat), lng: parseFloat(centerPosition.lng)}}
        // draggable={clusterCompanyCode.length === 0}
           onDragStart={(target)=>{setSelectClusterData([]);
             setCenterPosition({lat:target.getCenter().getLat().toString(), lng:target.getCenter().getLng().toString()})
           }}
           zoomable={scrollBlock} onZoomChanged={(v)=>setMapLevel(v.getLevel())}
           onZoomStart={()=> {
             setAlarmHover('')
             setAlarmSelect('')
             setSelectClusterData([])
           }} onScroll={()=> {setAlarmSelect( '' ); setSelectClusterData([])}}
           level={mapLevel} isPanto={false} maxLevel={12} disableDoubleClickZoom={true}
           style={{width: '100%', height: '100%', position: 'relative',zIndex:1}}>
        <React.Fragment>
          <div style={{justifyContent: 'space-between', width: '100%', display: 'flex',zIndex:1, pointerEvents: 'none', boxSizing: 'border-box', padding: '0 40px', position: 'absolute'}}>
            <div style={{display: 'flex', pointerEvents: 'auto',flexDirection:'column'}}>
              <SolutionCompanyFilterList
                filterClick={()=>{
                  setSelectClusterData([]);
                  setCenterPosition({lat: '36.47047524829324', lng: '127.86121117317678'});
                  setSelectCompanyData({})
                  setMapLevel(9);
                  setAlarmSelect('')
                  setAlarmHover('')
                }}
                receivedData={(v)=> {
                  setSelectCompanyData({})
                  setSelectClusterData([])
                  setCompanyAddress(v.address)
                  setAlarmHover(v.companyCode)
                  setCenterPosition( {lat: v.latitude , lng: v.longitude} )
                  setTimeout(()=>{
                    setAlarmSelect(v.companyCode)
                  },200)
                  setMapLevel(5)
                }}/>
              {/*{companyAddress &&*/}
              {/*    <InfoMapCategory>*/}
              {/*      {validationValue(parseAddress(companyAddress)?.city)}*/}
              {/*      {validationValue('>')}*/}
              {/*      {validationValue(parseAddress(companyAddress)?.district)}*/}
              {/*      {validationValue('>')}*/}
              {/*      {validationValue(parseAddress(companyAddress)?.town)}*/}
              {/*    </InfoMapCategory>*/}
              {/*}*/}
            </div>
          </div>
        </React.Fragment>
        <MarkerClusterer averageCenter={true} minLevel={6}
                         clickable={true}
                         styles={circleStyle}
                         onClusterclick={(target, cluster) => {
                           //@ts-ignore
                           const transformArray = cluster.getMarkers().map((v)=>(v.getTitle())).map((v)=>{
                             const parts = v.split('_');
                             const companyName = parts[0]
                             const companyCode = parts[1]
                             const businessManagementCode = parts[2]
                             const managerName = parts[3]
                             const id = parts[4]
                             const markerDate = parts[5]
                             return {companyName,companyCode,businessManagementCode,managerName,id,markerDate}
                           })
                           setSelectClusterData(transformArray)
                           setCenterPosition( {lat: cluster.getClusterMarker().getPosition().getLat().toString() , lng: cluster.getClusterMarker().getPosition().getLng().toString()} )
                           setAlarmSelect('')
                           setAlarmHover('')
                         }}
                         disableClickZoom={true}
          // onClustered={(target, clusters)=>{
          //   target.setStyles( [{backgroundColor: 'red'}])
          // }}
          // onCreate={(target)=>{setData(target)}}
                         texts={((size) => (`${size}+`))}>
          {/*다중 메뉴*/}
          <React.Fragment>
            <div style={{position:"fixed",zIndex:2000,transform:'translateX(190px) translateY(140px)',display:selectClusterData.length === 0  ? 'none' : 'flex',flexDirection:'column',overflow:'scroll',width:'320px',height:'320px',pointerEvents:'initial'}}>
              <div className={'scroll_func'}>
                {selectClusterData.map((v:any,i:number)=>(
                  <div style={{display:'flex',flexDirection:'column'}}>
                    <AlarmTitle disabled={true} arrow={true} open={v.companyCode === selectCompanyData?.companyCode}
                                onClick={()=>{if(selectCompanyData?.companyCode === v.companyCode){
                                  setSelectCompanyData({})
                                }else{
                                  // businessListRes(v.companyCode).then()
                                  setSelectCompanyData(v)
                                }}}>
                      {/*<div>{v.markerDate}</div>*/}
                      <div style={{fontSize:'18px', fontWeight:700,color:v.markerDate=== '0' ? '#171725'  : v.markerDate === '1' ?  'rgb(255, 199, 0)' : '#F0142F'}}>{v.companyName}</div>
                      <img src={arrow_bottom} style={{alignSelf:'center',transform:`rotateX(${v.companyCode === selectCompanyData?.companyCode ? 180 : 0}deg)`}}/>
                    </AlarmTitle>

                    <AlarmList open={v.companyCode === selectCompanyData?.companyCode} disabled={true}
                               onMouseOut={()=>setScrollBlock(true)}
                               onMouseOver={()=>setScrollBlock(false)}>
                      <div style={{padding:'16px',boxSizing:'border-box'}}>
                        <div style={{width:'288px',fontSize:"12px",color:'#7E84A3',display:'flex',justifyContent:'space-between',fontWeight:'bold'}}>
                          <div>{'영업 관리'}</div>
                          <div style={{textDecoration:'underline 1px',textUnderlineOffset:'3px',cursor:'pointer',opacity:buttonAuthValid('read','22') ? 0.5 : 1,pointerEvents:buttonAuthValid('read','22') ? 'none':'auto'}} className={'view_more'}
                               onClick={()=>{setContainerMove('업체별'); window.location.href = `/admin/fas/business`}}>
                            {'더보기 >'}
                          </div>
                        </div>

                        <div style={{marginTop:'12px',display:'grid',gridTemplateColumns:'72px 216px'}}>
                          <MainButtons name={'등록'} disabled={buttonAuthValid('create','22')} width={'56px'} margin={'0'} clickEvent={()=>{window.location.href = `/admin/fas/business/register`;}}/>
                          {buttonAuthValid('read','22') ?
                            <NoData height={64}>{'영업이력 접근권한이 없습니다.'}</NoData>
                            :
                            business?.length === 0 ?
                              <NoData height={64}>{'* 영업이력이 없습니다.'}</NoData>
                              :
                              <div>
                                {business?.map((v:any)=>{
                                  return(
                                    <AlarmSubList select={v.businessManagementCode === businessCode} onClick={()=>{setBusinessCode(v.businessManagementCode)}}
                                                  style={{opacity:buttonAuthValid('read','22') ? 0.5 : 1,pointerEvents:buttonAuthValid('read','22') ? 'none':'auto'}}
                                                  onDoubleClick={()=>{ window.location.href = `/admin/fas/business/${v.businessManagementCode}`}}>
                                      {validationValue(v.writer)}
                                      {validationValue(dayjs(v.visitDate).format('YYYY.MM.DD'))}
                                      {/*<div>{v.writer}</div>*/}
                                      {/*<div>{v.visitDate}</div>*/}
                                    </AlarmSubList>
                                  )})
                                }
                              </div>
                          }
                        </div>
                        <div style={{height:'1px',backgroundColor:'#E6E9F4',margin:'16px 0'}}/>
                        <div style={{width:'288px',fontSize:"12px",color:'#7E84A3',display:'flex',justifyContent:'space-between',fontWeight:'bold'}}>
                          <div>{'A/S 관리'}</div>
                          <div style={{textDecoration:'underline 1px',textUnderlineOffset:'3px',cursor:'pointer'}} className={'view_more'}
                               onClick={()=>{setContainerMove('업체별'); window.location.href = `/admin/pbas/managelog`}}>
                            {'더보기 >'}
                          </div>
                        </div>
                        <div style={{marginTop:'12px',display:'grid',gridTemplateColumns:'72px 216px'}}>
                          <MainButtons name={'등록'} disabled={buttonAuthValid('create','27')} width={'56px'} margin={'0'}
                                       clickEvent={()=>{window.location.href = `/admin/pbas/managelog/register`;}}/>
                          <div>
                            {buttonAuthValid('read','27') ?
                              <NoData height={64}>{'* A/S(예지보전 관리이력) 권한이 없습니다.'}</NoData>
                              :
                              asData.length === 0 ?
                                <NoData height={64}>{'* A/S이력 없습니다.'}</NoData>
                                :
                                asData.slice(0,3).map((v)=>{
                                  return(
                                    <AlarmSubList select={v.maintenanceHistoryCode === businessCode}
                                                  onClick={()=>{setBusinessCode(v.maintenanceHistoryCode)}}
                                                  onDoubleClick={()=>{window.location.href = `/admin/pbas/managelog/detail/${v.maintenanceHistoryCode}`}}
                                    >
                                      <div>{v.responsibility}</div>
                                      <div>{v.division}</div>
                                      <div>{dayjs(v.actionTime).format('YYYY.MM.DD')}</div>
                                    </AlarmSubList>
                                  )
                                })}
                          </div>
                        </div>
                      </div>
                    </AlarmList>
                  </div>
                ))}
              </div>
            </div>
            {/*단일 메뉴*/}
            {companyLocation?.map((v:any,index:number) => (
              <div style={{display:'flex'}}>
                <MapMarker position={{lat: parseFloat(v.latitude), lng: parseFloat(v.longitude)}}
                           title={v.companyName + '_' + v.companyCode + '_' + v.businessManagementCode + '_' + v.managerName + '_' + v.companyId + '_' + v.markColors}
                           clickable={true}
                           image={
                             {
                               src:v.markColors === '0' ? first_maker : v.markColors === '1' ? second_maker : third_maker,
                               size:{width:56,height:56},
                               options:{alt:v.companyCode}
                             }
                           }
                           infoWindowOptions={
                             {
                               zIndex:10,
                             }
                           }
                           onClick={()=> {
                             setCompanyAddress(v.address)
                             setSelectClusterData([])
                             selectCompanyData?.companyCode('')
                             if(alarmSelect === v.companyCode){
                               setAlarmSelect('')
                               setAlarmHover('')
                             }else{
                               setAlarmSelect(v.companyCode)
                             }
                           }}
                           onMouseOut={()=> {
                             setAlarmHover( '' )
                           }}
                           onMouseOver={()=> {
                             setAlarmHover(v.companyCode)
                           }}
                >
                  {(v.companyCode === alarmSelect || alarmHover === v.companyCode) &&
                    <div style={{transform:'translateX(18px) translateY(5px)',scale:'105%'}}>
                      <AlarmTitle open={v.companyCode === selectCompanyData?.companyCode} onClick={()=> {setSelectCompanyData(v); if(selectCompanyData?.companyCode){setSelectCompanyData({})}}}>
                        <div style={{fontSize:'18px', fontWeight:700,color:v.markColors=== '0' ? '#171725'  : v.markColors === '1' ?  'rgb(255, 199, 0)' : '#F0142F'}}>{v.companyName}</div>
                        <img src={arrow_bottom} alt={''} style={{alignSelf:'center',transform:`rotateX(${v.companyCode === selectCompanyData?.companyCode ? 180 : 0}deg)`}}/>
                      </AlarmTitle>
                      <AlarmList open={v.companyCode === selectCompanyData?.companyCode}
                                 onMouseOut={()=>setScrollBlock(true)}
                                 onMouseOver={()=>setScrollBlock(false)}>
                        <div style={{padding:'16px',boxSizing:'border-box'}}>
                          <div style={{width:'288px',fontSize:"12px",color:'#7E84A3',display:'flex',justifyContent:'space-between',fontWeight:'bold'}}>
                            <div>{'영업 관리'}</div>
                            <div style={{textDecoration:'underline 1px',textUnderlineOffset:'3px',cursor:'pointer'}} className={'view_more'}
                                 onClick={()=>{setContainerMove('업체별'); window.location.href = `/admin/fas/business`}}>
                              {'더보기 >'}
                            </div>
                          </div>
                          <div style={{marginTop:'12px',display:'grid',gridTemplateColumns:'72px 216px'}}>
                            <MainButtons name={'등록'} disabled={buttonAuthValid('create','22')} width={'56px'} margin={'0'}
                                         clickEvent={()=>{window.location.href = `/admin/fas/business/register`;}}/>
                            {buttonAuthValid('read','22') ?
                              <NoData height={64}>{'영업이력 접근권한이 없습니다.'}</NoData>
                              :
                              business?.length === 0 ?
                                <NoData height={64}>{'* 영업이력이 없습니다.'}</NoData>
                                :
                                <div>
                                  {business?.map((v:any)=>{
                                    return(
                                      <AlarmSubList select={v.businessManagementCode === businessCode}
                                                    onClick={()=>{setBusinessCode(v.businessManagementCode)}}
                                                    style={{opacity:buttonAuthValid('read','22') ? 0.5 : 1,pointerEvents:buttonAuthValid('read','22') ? 'none':'auto'}}
                                                    onDoubleClick={()=>{window.location.href = `/admin/fas/business/${v.businessManagementCode}`}}>
                                        {validationValue(v.writer)}
                                        {validationValue(dayjs(v.visitDate).format('YYYY.MM.DD'))}
                                      </AlarmSubList>
                                    )
                                  })
                                  }
                                </div>
                            }
                          </div>
                          <div style={{height:'1px',backgroundColor:'#E6E9F4',margin:'16px 0'}}/>
                          <div style={{width:'288px',fontSize:"12px",color:'#7E84A3',display:'flex',justifyContent:'space-between',fontWeight:'bold'}}>
                            <div>{'A/S 관리'}</div>
                            <div style={{textDecoration:'underline 1px',textUnderlineOffset:'3px',cursor:'pointer'}} className={'view_more'}
                                 onClick={()=>{setContainerMove('업체별'); window.location.href = `/admin/pbas/managelog`}}>
                              {'더보기 >'}
                            </div>
                          </div>
                          <div style={{marginTop:'12px',display:'grid',gridTemplateColumns:'72px 216px'}}>
                            <MainButtons name={'등록'} width={'56px'} margin={'0'}  disabled={buttonAuthValid('create','27')}
                                         clickEvent={()=>{window.location.href = `/admin/pbas/managelog/register`;}}/>
                            {/*<NoData height={64}>{'* A/S이력 준비중입니다.'}</NoData>*/}
                            <div>
                              {buttonAuthValid('read','27') ?
                                <NoData height={64}>{'* A/S(예지보전 관리이력) 권한이 없습니다.'}</NoData>
                                :
                                asData.length === 0 ?
                                  <NoData height={64}>{'* A/S이력 없습니다.'}</NoData>
                                  :
                                  asData.slice(0,3).map((v)=>{
                                    return(
                                      <AlarmSubList select={v.maintenanceHistoryCode === businessCode}
                                                    onClick={()=>{setBusinessCode(v.maintenanceHistoryCode)}}
                                                    onDoubleClick={()=>{window.location.href = `/admin/pbas/managelog/detail/${v.maintenanceHistoryCode}`}}
                                      >
                                        <div>{v.responsibility}</div>
                                        <div>{v.division}</div>
                                        <div>{dayjs(v.actionTime).format('YYYY.MM.DD')}</div>
                                      </AlarmSubList>
                                    )
                                  })}
                            </div>
                          </div>
                        </div>
                      </AlarmList>
                    </div>
                  }
                </MapMarker>
              </div>
            ))}
          </React.Fragment>
        </MarkerClusterer>

      </Map>
    </Container>
  );
};
export default FasMapOverviewContainer;

const Container = styled.div`
  user-select: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .out_button{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 50;
    width: 120px;
    height: 40px;
    color:#5A607F;
    font-size: 14px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    border-top: 1px solid #d7dbec;
    border-radius: 4px;
    background-color: #fff;
    user-select: none;
    &:hover{
      background-color: #5A607F;
      color: #fff;
    }
  }
  .scroll_func{
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar{
      display: block;
    }
  }

`

const InfoMapCategory = styled.div<{disabled?:boolean}>`
  display: flex;
  justify-content: space-between;
  color: #7E84A3;
  font-weight: bold;
  font-size: 12px;
  opacity: ${p=>p.disabled ? 0 : 1};
  width: 220px;
  height: 32px;
  box-sizing: border-box;
  padding:10px 16px 8px 16px;
  box-shadow: 0 5px 15px #44444f1a;
  border-radius: 6px;
  background-color: #FFFFFF;
  align-items: center;
  margin-top: 24px;
`

const AlarmTitle = styled.div<{open?: boolean , disabled?:boolean,selected?:boolean,arrow?:boolean}>`
  display: flex;
  justify-content: space-between;
  width: 320px;
  height: 48px;
  border-radius: 8px;
  color:${props=>props.open ? '#0058FF':'#171725'};
  background-color: ${props => props.open? '#F2F6FF' : '#fff'};
  box-shadow: 0 3px 6px #00000029;
  line-height: 48px;
  box-sizing: border-box;
  padding: 0 16px;
  cursor: pointer;
  transform: ${p=>p.disabled ? '':'translateX(-18px) translateY(-5px)'};

  user-select: none;
  position: relative;
  &:hover{
    background-color: #F2F6FF;
  }
  &::before {
    display: ${p=>p.arrow ? 'none':'block'};
    content: "";
    position: absolute;
    top: 98%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #FFFFFF transparent transparent transparent;
    pointer-events: none;
  }
`
const AlarmList = styled.div<{open: boolean,disabled?:boolean}>`
  width: 320px;
  max-height: ${props => props.open ? '304px' : 0};
  overflow: ${props => props.open ? 'auto' : 'hidden'};
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 1px 4px #15223214;
  transition:all 200ms ease-in-out;
  border-radius: 6px;
  transform: ${p => p.disabled ? '' : 'translateX(-18px) translateY(-5px)'};
  z-index: 1500;
  position: relative;
  user-select: none;
  .view_more{
    &:hover{
      font-weight: 400;
    }
  }
`
const AlarmSubList = styled.div<{select?:boolean}>`
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  line-height: 32px;
  background-color: ${p=>p.select ? 'rgba(0,88,255,0.05)' : '#fff'};
  padding: 0 8px;
  cursor: pointer;
  box-sizing: border-box;
  &:hover{
    background-color: rgba(0,88,255,0.05);
    //opacity: 0.05;
  }
`
