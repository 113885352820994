import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import FactoryManagement from "../../../containers/admin/fas/company/factory/FactoryManagement";
import { useLocation } from 'react-router-dom';
import FactoryDetail from "../../../containers/admin/fas/company/factory/FactoryDetail";
import FactoryRegistration from "../../../containers/admin/fas/company/factory/FactoryRegistration";
import FactoryModify from "../../../containers/admin/fas/company/factory/FactoryModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasFactory = () => {

	const location = useLocation();
	const {pathname} = location

	return (
		<PageContainer>
			<IntegratedSideMenu/>
			<SectionContainer contentContainer={
				pathname.length === (location.pathname.includes('/admin')? 18 : 12)?
				<FactoryManagement/>
					:
					location.pathname.includes('register') ?
					<FactoryRegistration/>
						:
						location.pathname.includes('modify') ?
							<FactoryModify/>
							:
					<FactoryDetail/>
			}/>
		</PageContainer>
	);
};

export default FasFactory;

