import React , {useState} from 'react';
import styled from "styled-components";
import {FasMenuType , Modal_View_Type} from "../../types/Fundamental";
import arrowUp from '../../asset/image/ic-arrow-narrow-up.svg'
import arrowDown from '../../asset/image/ic-arrow-narrow-down.svg'
import {AlarmLogCircle , Icons , validationValue} from "../../style/StyleComponent";
import TimeAgo from 'timeago-react'
import * as timeago from 'timeago.js'
import ko from 'timeago.js/lib/lang/ko'
import {
  buttonAuthValid ,
  dataValid ,
  initModal ,
  numberLocaleString ,
  solutionTitleFunc
} from "../../function/function";
import {useTranslation} from "react-i18next";
import {useRecoilState , useRecoilValue} from "recoil";
import {companySolutionFilter , LanguageAtom} from "../../common/StateManage";
import mapIcon from '../../asset/image/map_position.svg'
import hoverMapIcon from '../../asset/image/hoverMapIcon.svg'
import ArrowButton from "../button/ArrowButton";
import ToastPopup from "../modal/ToastPopup";

const FasMenuSquare: React.FunctionComponent<FasMenuType> = ( {
                                                                solutionMenu ,
                                                                solutionAlarm ,
                                                                solutionAlarmCount ,
                                                                totalFacility ,
                                                                todayFacility ,
                                                                registerFacility ,
                                                                resultPercent ,
                                                                liveFacility ,
                                                                iconClick ,
                                                                comparePercent ,
                                                                realtimeAlarm ,
                                                                realtimeAlarmCount ,
                                                                allPressCount
                                                              } ) => {
  const numberFormat = new Intl.NumberFormat( 'ko' , {} )
  timeago.register( 'ko' , ko )
  const {t}: any = useTranslation()
  const selectLanguage = useRecoilValue( LanguageAtom )
  const [, setSelectSolution] = useRecoilState<string>( companySolutionFilter )
  const [iconHover , setIconHover] = useState( false )
  const [sideOpen , setSideOpen] = useState<boolean>( false )
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [open , setOpen] = useState( true )
  return (
    <SquareComponent>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {solutionMenu === 'V-MS' || solutionMenu === 'MES' || solutionMenu === 'i-MES' || solutionMenu === 'i-BAS' ||
      solutionMenu === 'R-MS' || solutionMenu === 'A-MS' || solutionMenu === 'C-MS' ?
        <StatusMenu style={{height: '104px'}} solutionColor={solutionTitleFunc( 'color' , solutionMenu )}>
          <div className={'solution_title'}>
            <div>{solutionMenu}</div>
            <img src={iconHover ? hoverMapIcon : mapIcon} alt="" width={32} height={32}
                 onMouseEnter={() => setIconHover( true )}
                 onMouseOut={() => setIconHover( false )}
                 onClick={() => {
                   setSelectSolution( solutionMenu )
                   if( iconClick ){
                     iconClick()
                   }
                 }}
                 style={{position: 'absolute' , top: '8px' , right: '8px' , cursor: 'pointer'}}/>
            <div style={{
              margin: '4px 0' ,
              fontSize: '13px' ,
              whiteSpace: 'nowrap'
            }}>{solutionTitleFunc( 'solution' , solutionMenu )}</div>
            <div style={{margin: '4px 0' , fontSize: '12px'}}>{solutionTitleFunc( 'ko' , solutionMenu )}</div>
          </div>
        </StatusMenu>
        :
        <React.Fragment>
          <StatusMenu solutionColor={solutionTitleFunc( 'color' , solutionMenu )}
                      style={{height: `${open ? '312px' : '104px'}`}}>
            <div className={'solution_title'}>
              <div>{solutionMenu}</div>
              <img src={iconHover ? hoverMapIcon : mapIcon} alt="" width={32} height={32}
                   onMouseEnter={() => setIconHover( true )}
                   onMouseOut={() => setIconHover( false )}
                   onClick={() => {
                     setSelectSolution( solutionMenu )
                     if( iconClick ){
                       iconClick()
                     }
                   }}
                   style={{position: 'absolute' , top: '8px' , right: '8px' , cursor: 'pointer'}}/>
              <div style={{
                margin: '4px 0' ,
                fontSize: solutionMenu === 'F-EMS' ? '12px' :
                  solutionMenu === 'A-BAS' ? '13px' : '14px'
              }}>{solutionTitleFunc( 'solution' , solutionMenu )}</div>
              <div>{solutionTitleFunc( 'ko' , solutionMenu )}</div>
              <div className={'solution_chevron'} onClick={() => setOpen( !open )}>
                <ArrowButton icon={Icons.LeftW} arrowDirection={open ? 'top' : 'bottom'}/>
              </div>
            </div>

            <div className={'solution_current_status'} style={{opacity: `${open ? 1 : 0}`, height: `${open ? '200px' : 0}`}}>
              <div style={{display: 'flex' , justifyContent: 'space-between' , position: 'relative'}}>
                <div className={'status_title_menu'}>{solutionMenu === 'F-EMS' ? '전체 등록 업체 수' : t( '전국 도입 활용 현황' )}</div>
                {// P-BAS에서만 사용되는 지역별 현황 체크 버튼
                  solutionMenu === 'P-BAS' &&
                    <div style={{display:'flex', position: 'absolute' , top: '-8px' , right: '-14px'}} onClick={()=>setSideOpen(!sideOpen)}>
                      {/*<div style={{fontSize:'12px',alignItems:'center',top:'6px', position:'relative', marginRight:'8px'}}>{'지역별 현황'}</div>*/}
                      <ArrowButton arrowDirection={'bottom'} />
                    </div>}
              </div>
              <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'stretch'}}>
                <div className={'count_totally'}>
                  <div>{numberFormat.format( dataValid( totalFacility , 0 ) )}</div>
                  <div>{solutionMenu === 'F-EMS' ? '개' : '대'}</div>
                </div>
                {registerFacility &&
                    <div style={{display: 'flex' , alignItems: 'flex-start' , flexDirection: 'column'}}>
                      <div style={{
                        color: '#5a607f' ,
                        fontSize: '12px' ,
                        margin: '0 0 2px 0'
                      }}>{t( '등록된 ' ) + t( solutionTitleFunc( 'menu' , solutionMenu ) )}</div>
                      <div style={{display: 'flex' , alignItems: 'center'}}>
                        <div style={{
                          fontSize: '20px' ,
                          fontWeight: 800
                        }}>{numberFormat.format( dataValid( registerFacility , 0 ) )}</div>
                        <div style={{
                          fontSize: '14px' ,
                          fontWeight: 800 ,
                          alignSelf: 'end' ,
                          lineHeight: '20px'
                        }}>{'대'}</div>
                      </div>
                    </div>}
              </div>

              <div className={'status_title_menu'}>
                {solutionMenu === 'F-EMS' ? 'F-EMS 구독 업체 수' : t( '금일 작동한 ' + solutionTitleFunc( 'menu' , solutionMenu ) )}</div>
              <div className={'count_individual'}>
                {validationValue( todayFacility , 0 )}
                <div>{solutionMenu === 'F-EMS' ? '개' : '대'}</div>
              </div>
              <div className={'status_title_menu'}>
                {solutionMenu === 'F-EMS' ? '한전 API 연동 업체' : t( '현재 접속중인 ' + solutionTitleFunc( 'menu' , solutionMenu ) )}
              </div>
              <div className={'count_individual'}>
                {validationValue( liveFacility , 0 )}
                <div>{solutionMenu === 'F-EMS' ? '개' : '대'}</div>
              </div>
              {solutionMenu !== 'F-EMS' &&
                  <div className={'compare_percent'}>
                    <div style={{
                      color: `${comparePercent ? '#3DD598' : '#F0142F'}` ,
                      fontWeight: 800
                    }}>{numberLocaleString( resultPercent , 2 ) + '%'}</div>
                    <img src={comparePercent ? arrowUp : arrowDown} alt="" width={16} height={16}
                         style={{padding: '0 5px'}}/>
                    <div style={{color: '#7e84a3' , fontSize: '12px' , lineHeight: '18px'}}>{t( '작년 대비' )}</div>
                  </div>}
            </div>

            {/*프레스 지역별 현황 체크*/}
            {solutionMenu === 'P-BAS' &&
                <OpenSubMenu isOpen={sideOpen}>
                  <div className={'status_title_menu'}>{'지역별 도입 현황'}</div>
                  <div>
                    <div style={{
                      display: 'grid' ,
                      gridTemplateColumns: '40px 1fr 1fr' ,
                      gap: '8px' ,
                      boxSizing: 'border-box' ,
                      fontSize: '13px' ,
                      fontWeight: 400 ,
                      alignItems: 'center' ,
                      color: '#5a607f' ,
                      margin: '14px 0 18px'
                    }}>
                      <div>{'지역'}</div>
                      <div style={{textAlign: 'center'}}>{`M2M 연결 /`}</div>
                      <div style={{textAlign: 'center'}}>{'네트워크 연결'}</div>
                    </div>
                    <div className={'facility-status'}>
                      {/*<div style={{margin:'6px 0'}}>{'전국'}</div>*/}
                      {/*<div style={{margin:'6px 0'}}>{numberLocaleString(dataValid(allPressCount?.centralM2M,0) + dataValid(allPressCount?.jeollaM2M,0) + dataValid(allPressCount?.gyeongsangM2M,0))}</div>*/}
                      {/*<div style={{margin:'6px 0'}}>{numberLocaleString(dataValid(totalFacility,0))}</div>*/}

                      <div>{'중부'}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.centralM2M , 0 ) )}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.central , 0 ) )}</div>

                      <div>{'전라'}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.jeollaM2M , 0 ) )}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.jeolla , 0 ) )}</div>

                      <div>{'경상'}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.gyeongsangM2M , 0 ) )}</div>
                      <div>{numberLocaleString( dataValid( allPressCount?.gyeongsang , 0 ) )}</div>
                    </div>
                  </div>
                  <div style={{
                    display: 'flex' ,
                    justifyContent: 'flex-end' ,
                    position: 'absolute' ,
                    top: '10px' ,
                    right: '10px'
                  }}>
                    {/*<div style={{fontSize:'12px',alignItems:'center',top:'6px', position:'relative', marginRight:'8px'}}>{'지역별 현황'}</div>*/}
                    <ArrowButton arrowDirection={sideOpen ? 'top' : 'bottom'}
                                 clickEvent={() => setSideOpen( !sideOpen )}/>
                  </div>
                </OpenSubMenu>}
          </StatusMenu>
          <RealtimeStatus style={{
            height: `${dataValid( realtimeAlarmCount , 0 ) === 0 ? '64px' :
              dataValid( realtimeAlarmCount , 0 ) === 1 ? '144px' : '248px'}`
          }} solutionColor={solutionTitleFunc( 'color' , solutionMenu )}>
            <div className={'square_title'}>
              <div>{t( '실시간 알림 현황' )}</div>
              {validationValue( realtimeAlarmCount , 0 )}
            </div>
            <div style={{display: 'flex' , flexDirection: 'column'}}>
              {realtimeAlarm?.slice( 0 , 2 ).map( ( v , i ) => (
                <AlarmContent key={i} onClick={() => {
                  if( buttonAuthValid( 'create' , '26' ) ){
                    setDataModal( {menu: 'warn' , message: '해당 아이디에 접근 권한이 없습니다.'} )
                  } else{
                    window.location.href = `/admin/pbas/company/${v.alarmNo}`
                  }
                }}>
                  <div className={'target_title'}>{v.companyName + ' ' + v.factoryName}</div>
                  <div className={'target_title'}>{v.facilityName}</div>
                  <div className={'target_sub_text'}>{v.manufacturingNumber}</div>
                  <div style={{display: 'flex' , justifyContent: 'flex-end'}}>
                    {selectLanguage.lang === 'en' ?
                      <TimeAgo className={'target_sub_text'} datetime={v.alarmTime} locale={'en'}/>
                      :
                      <TimeAgo className={'target_sub_text'} datetime={v.alarmTime} locale={'ko'}/>}
                    <div style={{display: 'flex' , marginLeft: '8px'}}>
                      {/*<AlarmStatusCircle alarmStatus={v.alarmState}/>*/}
                      <AlarmLogCircle alarmStatus={v.alarmState}/>
                    </div>
                  </div>
                </AlarmContent>
              ) )}
            </div>
          </RealtimeStatus>
          <SolutionRequest style={{height: `${dataValid( solutionAlarmCount , 0 ) === 0 ? '64px' : '112px'}`}}
                           solutionColor={solutionTitleFunc( 'color' , solutionMenu )}>
            <div className={'square_title'}>
              <div>{t( '솔루션 요청 현황' )}</div>
              {validationValue( solutionAlarmCount , 0 )}
            </div>
            {solutionAlarm?.map( ( v , i ) => (
              // 요청 대기중 이기 때문에 해당 세부 페이지 가 아닌, 목록 첫 부분 으로 이동 시킴.
              <SolutionContent key={i} style={{display: `${v.requestDate === '' ? 'none' : 'flex'}`}}
                               onClick={() => {
                                 if( buttonAuthValid( 'update' , '23' ) ){
                                   setDataModal( {menu: 'warn' , message: '해당 아이디에 접근 권한이 없습니다.'} )
                                 } else{
                                   window.location.href = `/admin/fas/solution/${v.solutionNo}`
                                 }
                               }}>
                <div className={'target_title'}>{v.companyName}</div>
                <div className={'target_sub_text'}>{v.requestDate}</div>
                {/*<TimeAgo className={'target_sub_text'} datetime={v.requestDate} locale={'ko'}/>*/}
              </SolutionContent>
            ) )}
          </SolutionRequest>


        </React.Fragment>
      }
    </SquareComponent>
  );
};
export default FasMenuSquare;
const SquareComponent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 248px;

  .square_title {
    display: flex;
    justify-content: space-between;
    color: #f5f6fa;
    padding: 8px 0 6px 8px;
    font-weight: 500;
    text-shadow: 0 3px 6px #00000029;
  }

  .target_title {
    color: #131523;
    font-size: 14px;
    font-weight: 600;
  }

  .target_sub_text {
    font-weight: 600;
    color: #101010;
    opacity: 0.5;
    font-size: 12px;
  }
`
const StatusMenu = styled.div<{ solutionColor: any }>`
  border-radius: 8px;
  background: ${props => props.solutionColor};
  height: 296px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s ease-in-out;

  .solution_title {
    box-sizing: border-box;
    padding: 17px 20px 0 24px;
    text-shadow: 0 3px 6px #00000029;
    position: relative;

    & > div:first-child {
      font-size: 32px;
      font-weight: 800;
      color: #fff;
    }

    & > div:not(:first-child) {
      color: #f5f6fa;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .solution_chevron {
    width: 48px;
    height: 24px;
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.29);
    background-color: ${props => props.solutionColor};
    position: absolute;
    bottom: -30px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 51;

  }


  .solution_current_status {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    background-color: #fff;
    height: 200px;
    box-shadow: 0 1px 4px #15223214;
    box-sizing: border-box;
    padding: 18px 24px 16px;
    transition: all 0.3s ease-in-out;

    .status_title_menu {
      font-size: 14px;
      font-weight: 400;
      color: #5A607F;
      padding-bottom: 4px;
      white-space: nowrap;
    }

    .count {
      &_totally {
        display: flex;
        color: #131523;
        font-weight: 800;
        padding-bottom: 8px;
        margin: 6px 0 6px 0;

        & > div:first-child {
          font-size: 28px;
        }

        & > div:last-child {
          font-size: 14px;
          align-self: end;
          padding-left: 8px;
          line-height: 22px;
        }
      }

      &_individual {
        display: flex;
        color: #131523;
        font-weight: 800;
        line-height: 14px;
        padding-bottom: 8px;

        & > div:first-child {
          font-size: 16px;
        }

        & > div:last-child {
          font-size: 14px;
          align-self: end;
          padding-left: 6px;
        }
      }
    }

    .compare_percent {
      display: flex;
    }
  }
`
const RealtimeStatus = styled.div<{ solutionColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${props => props.solutionColor};
  border-radius: 8px;
  box-shadow: 0 3px 6px #00000029;
  box-sizing: border-box;
  padding: 8px;
  margin: 8px 0;
`
const SolutionRequest = styled.div<{ solutionColor: any }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.solutionColor};
  border-radius: 8px;
  box-shadow: 0 3px 6px #00000029;
  box-sizing: border-box;
  padding: 8px;

`
const AlarmContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96px;
  border-radius: 6px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px 16px 10px;
  margin-bottom: 8px;
  cursor: pointer;
`
const SolutionContent = styled.div`
  flex-direction: column;
  justify-content: space-between;
  height: 64px;
  border-radius: 6px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 15px 16px 10px;
  cursor: pointer;
`
const OpenSubMenu = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  position: absolute;
  left: 0;
  top: 112px;
  z-index: 50;
  width: 248px;
  height: ${props => props.isOpen ? '200px' : '0px'};
  transition: ${props => props.isOpen ?
          'height 0.3s, padding-left 0s, padding-right 0s, padding-top 0s, padding-bottom 0s'
          :
          'height 0.3s, padding-left 0s 0.3s, padding-right 0s 0.3s, padding-top 0s 0.3s, padding-bottom 0s 0.3s;'};

  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 6px;
  box-sizing: border-box;
  padding: ${props => props.isOpen ? '18px 32px 16px 24px' : 0};


  .facility-status {
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    gap: 12px 8px;

    & > div:nth-child(-n+3) {
      //border-bottom: 2px solid black;
    }

    & > div:nth-child(3n-2) {
      font-weight: 700;
      font-size: 14px;
    }

    & > div:nth-child(3n-1) {
      font-weight: 800;
      text-align: right;
      font-size: 14px;

      &::after {
        content: '대';
        font-weight: 700;
        font-size: 14px;
        margin-left: 6px;
      }
    }

    & > div:nth-child(3n) {
      font-weight: 800;
      text-align: right;
      font-size: 14px;
      position: relative;

      &::before {
        content: '/';
        position: absolute;
        font-weight: 700;
        left: 6px;
        text-align: left;
        font-size: 14px;
      }

      &::after {
        content: '대';
        font-size: 14px;
        margin-left: 6px;
      }
    }
  }

  .status_title_menu {
    font-size: 14px;
    font-weight: 400;
    color: #5A607F;
    padding-bottom: 4px;
    white-space: nowrap;
    box-sizing: border-box;
  }

`

const Wrapper = styled.div <{ isOpen: boolean }>`

  height: 100%;
  transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};




`
