import React , {useEffect , useRef} from 'react';
import {ModalBackCss, ModalInnerFrame} from "../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import {authValid , useWindowResizeScale} from "../../function/function";

interface Props {
	modalContent: any
	transparent?: boolean
	clickEvent?: ()=>void
	zIndex?: number
	toastButton?:boolean
	cursorCus?:string
	top?:number
	left?:number
	bottom?:number
}

const ModalBase:React.FunctionComponent<Props> = ({top,left,cursorCus,modalContent,toastButton, transparent, clickEvent, zIndex,bottom}) => {

	const modalRef = useRef(null);
	// const { scale, ref } = useWindowResizeScale();
	//
	//
	// useEffect(() => {
	// 	if (modalRef.current) {
	// 		const inverseScale = 1 / scale; // 반대 스케일 계산
	// 		const element = modalRef.current as HTMLElement;
	// 		element.style.transform = `scale(${inverseScale})`;
	// 		element.style.transformOrigin = 'top left'; // 기준점 설정
	// 	}
	// }, [scale]);




	return (
		<ModalBackCss ref={modalRef}  notAllowed={cursorCus} top={top} left={left} bottom={bottom} onClick={clickEvent} toastButton={toastButton} style={{backgroundColor:`${transparent? 'transparent' : 'rgba(0, 0, 0, 0.5)'}`}} zIndex={zIndex}>
			<ModalInnerFrame authDivide={authValid()}>
				{modalContent}
			</ModalInnerFrame>
		</ModalBackCss>
	);
};

export default ModalBase;
