import React, {useEffect, useState} from "react";
import client from "./common/Config";
import {Authorization} from "./common/authorization";
import {ModalType} from "./types/Fundamental";
import {listLoadingCircle , loadingRemove , modalListLoading} from "./function/function";
import {useTranslation} from "react-i18next";


export function LoadDetailData(url:string, data:any) {
    const [detailData,setDetailData] = useState([]);

    const detailPartner = async () => {
        try {
            const response = await client.post(url,data,{headers: {Authorization}})
            setDetailData(response.data.data)
            if(window.localStorage.getItem('companyID') === response.data.data.id){
                window.localStorage.setItem('profile',response.data.data.imageUrl)
                window.localStorage.setItem('userName',response.data.data.president)
            }
        }catch (e){
            console.log(e)
        }
    }

    useEffect(()=>{
        detailPartner().then()
    },[url])

    return detailData
}

//목록 api 커스텀 훅스
export const ListCustomHooks =async (url:string,page:number,totalPage:Function,setBlock:Function,setDataState:Function,errState:Function,setSearchInput?:Function | any,setPrevInput?:Function | any,modal?:boolean)=>{

    try {
        modal ? modalListLoading() : listLoadingCircle()
        const response = await client.get(url,{headers:{Authorization}})
        const data = response.data.data
        const valid = response.data
        if((data.total_page || data.totalPage) < page){
            totalPage( 1 )
            setBlock(0)
        }
        setSearchInput('')
        setPrevInput('')
        setDataState( data.row )
        totalPage(data.total_page || data.totalPage)
        if(valid.status !== 200){
            errState({notice:valid.message,status:valid.status})
        }else{
            errState({notice:'* 등록된 기본정보가 없습니다.',status: valid.status})
        }
        loadingRemove()
    }catch (e) {
        console.log(e)
        errState({notice:'서버 에러입니다. 담당자한테 연락 부탁드립니다.',status:''})
        setSearchInput('')
        setPrevInput('')
        setDataState([])
        loadingRemove()
    }
}


export const ListSearchCustomHooks = async (url:string,page:number,totalPage:Function,setBlock:Function,setDataState:Function,errState:Function,modal?:boolean) =>{
    try {
        modal ? modalListLoading() : listLoadingCircle()
        const response = await client.get(url,{headers:{Authorization}})
        const data = response.data.data
        const valid = response.data
        if((data.total_page || data.totalPage) < page){
            totalPage( 1 )
            setBlock(0)
        }
        setDataState( data.row )
        // setTotalPage(data.total_page)
        totalPage(data.total_page || data.totalPage)
        setBlock(0)
        if(valid.status !== 200){
            errState({notice:valid.message,status:valid.status})
        }else{
            errState({notice:'* 검색결과가 없습니다.',status: valid.status})
        }
        loadingRemove()
    }catch (e) {
        console.log(e)
        errState({notice:'서버 에러입니다. 담당자한테 연락 부탁드립니다.',status:''})
        setDataState([])
        loadingRemove()
    }
}
//목록 검색 api 커스텀 훅스
export const ListSearchPostCustomHooks = async (url:string,page:number,totalPage:Function,setBlock:Function,setDataState:Function,errState:Function,parms?:any,modal?:boolean) =>{
    try {
        modal ? modalListLoading() : listLoadingCircle()
        const response = await client.post(url, parms,{headers: {Authorization}})
        const data = response.data.data
        const valid = response.data
        if((data.total_page || data.totalPage) < page){
            totalPage(1)
            setBlock(0)
        }
        setDataState( data.row )
        totalPage(data.total_page || data.totalPage)
        if(valid.status !== 200){
            errState({notice:valid.message,status:valid.status})
        }else{
            totalPage(data.total_page || data.totalPage)
            errState({notice:'* 검색결과가 없습니다.',status: valid.status})
        }
        loadingRemove()
    }catch ( e ) {
        console.log(e)
        errState({notice:'서버 에러입니다. 담당자한테 연락 부탁드립니다.',status:''})
        setDataState([])
        loadingRemove()
    }
}

export const useDebounce = <T> (func: () => void, delay: number, deps: T) => {
    const callback = React.useCallback(func, [deps]);

    useEffect(() => {
        const timer = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [callback, delay]);
}