import React, {useEffect, useState} from 'react';
import * as timeago from "timeago.js";
import en from "timeago.js/lib/lang/en_US";
import ko from 'timeago.js/lib/lang/ko';
import TimeAgo from "timeago-react";
import dayjs from "dayjs";

interface TimeProps {
    alarmedTime?:string
    selectLocale?:boolean
    timeTextSlice?:boolean
}

const TimeDayModule = ({alarmedTime,selectLocale,timeTextSlice}:TimeProps) => {

    let [realTime, setRealTime] = useState<string | dayjs.Dayjs>(dayjs().format("YYYY.MM.DD HH:mm:ss"))

    timeago.register('ko',ko)
    timeago.register('en',en)
    //알림시간
    const AlarmedTime= dayjs(alarmedTime,'YYYY-MM-DD HH:mm:ss');
    //현재시간
    realTime = dayjs();

    useEffect(()=>{
        const Interval =
            setInterval(()=>{
                setRealTime(dayjs().format("YYYY-MM-DD HH:mm:ss"))
            },60000)
        return ()=> clearInterval(Interval)
    },[realTime])

    return (
        <React.Fragment>
            {realTime.diff(AlarmedTime) <= 86400000 ?
                <TimeAgo style={{alignItems:'center',lineHeight:'46px', boxSizing:'border-box'}} datetime={AlarmedTime.format('YYYY-MM-DD HH:mm:ss')} locale={selectLocale ? 'en':'ko'}/>
                :
                <div style={{alignItems:'center',lineHeight:'46px', boxSizing:'border-box'}} >
                    {timeTextSlice ?
                        <div>
                            {AlarmedTime.format('YYYY-MM-DD HH:mm:ss').slice(10,20)}
                        </div>
                        :
                        <div>
                            {AlarmedTime.format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                    }
                </div>
            }
        </React.Fragment>
    );
};

export default TimeDayModule;
