import React, {useState} from 'react';
import {
    ContentFrame,
    GridTable,
    SectionFrame,

} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";

import {locationFilter} from "../../../../../function/function";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useParams} from "react-router-dom";
import {DailyInspectionRegType, FacilityDataType, ModalType, RawMaterialType} from "../../../../../types/Fundamental";

import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import RegisterInput from "../../../../../components/input/RegisterInput";
import DropMenu from "../../../../../components/sideMenu/DropMenu";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import ProductItemModal from "../../../../../components/modal/ProductItemModal";
import PreviewFormDailyModal from "../../../../../components/modal/PreviewFormDailyModal";


interface Props {
  facilityData: FacilityDataType
  closeEvent?:()=>void
}

const FacilityDailyInspectionReg:React.FunctionComponent<Props> = ({facilityData, closeEvent}) => {
    const { t }:any = useTranslation();
    const dailyFacilityInfoTitle = ['기종', '오버홀', '제조 연원일', '제조번호', '제조사', '설비명', 'ICT번호', 'HW', '버전', '최대톤수',
        '사용전압','업체명','지역','공장명','공장세분화','설비담당자','이미지','생산품목','일상점검']
    const params = useParams()
    const {facilityCode} = params
    // const [facilityDetailDataState, setFacilityDetailDataState] = useState<FacilityDataType>(facilityData)
    const [imageKeyState, setImageKeyState] = React.useState<string[]>([])
    const [modalState, setModalState] = React.useState<ModalType>({open : false, status: '', message:''})

    // const facilityDetailResponse = async (code: string) => {
    //
    //     try {
    //         const response = await client.post('/facility/detail',{
    //             facilityCode: facilityCode
    //         },{headers: {Authorization}})
    //         setFacilityDetailDataState(response.data.data)
    //         console.log(response.data.data)
    //     }catch (e) {
    //         console.log(e)
    //     }
    // }
    //
    // React.useEffect(()=>{
    //     facilityDetailResponse(facilityCode ?? '').then()
    // },[facilityCode])


    // 행추가 부분
    const [legendRowList, setLegendRowList] = React.useState<DailyInspectionRegType[]>([])
    const [inspectionRowList, setInspectionRowList] = React.useState<DailyInspectionRegType[]>([])
    const [idIndex, setIdIndex] = React.useState<number>(legendRowList.length+1 )
    const [inspectionIdIndex, setInspectionIdIndex] = React.useState<number>(inspectionRowList.length+1 )

    const addListRow = () => {
        setIdIndex(idIndex+1)
        const legendInput = {
            legend: '',
            description:''
        }

        setLegendRowList([...legendRowList, legendInput])
    }

    const addInspectionRow = () => {
        setInspectionIdIndex(inspectionIdIndex+1)
        const inspectionInput = {
            inspectionItem: '',
            inspectionStandard: '',
            inspectionMethod:'',
            recordMethod:'',
            memo:''
        }


        setInspectionRowList([...inspectionRowList, inspectionInput])
    }

    const onRemoveLegendRow = (index: number) => {
        const rows = [...legendRowList]
        rows.splice(index,1)
        setLegendRowList(rows)
    }

    const onRemoveInspectionRow = (index: number) => {
        const rows = [...inspectionRowList]
        rows.splice(index,1)
        setInspectionRowList(rows)
    }


    const onChange = (e: { target: { name: string; value: string } }) => {
           const {name, value} = e.target
           setLegendRowList(legendRowList.map((row: DailyInspectionRegType) => row.legend === name ? {...row, description: value} : row))
    }
    const onChangeInspection = (e: { target: { name: string; value: string } }) => {
        const {name, value} = e.target
        setInspectionRowList(inspectionRowList.map((row: DailyInspectionRegType) => row.inspectionItem === name ? {...row, inspectionStandard: value} : row))
    }

    const textSelectStyle = {
      textDecoration:'underline',
      textUnderlineOffset:'4px'
    }

    return (
      <React.Fragment>
        {modalState.open && modalState.status === 'item' &&
          <ProductItemModal closeEvent={()=>setModalState({open:false, status:'', message:''})}/>}
        {modalState.open && modalState.status === 'form' &&
            <PreviewFormDailyModal closeEvent={()=>setModalState({open:false, status:'', message:''})}/>
        }
        <SectionFrame>
          <MenuTitle title={'업체 관리> 기계 기본정보> 일상점검 등록'} unitCase={'MenuTitle'}/>
          <ContentFrame height={'208px'} margin={'0 0 16px 0'}>
            <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
              <MenuTitle title={'설비정보'} unitCase={'MenuCompTitle'}/>
              <MainButtons name={'목록'} width={'56px'} clickEvent={()=>window.history.back()}/>
            </div>
            <GridTable tempCol={'56px 40px 96px 160px 88px 130px 88px 88px 80px 56px 56px 130px 96px 96px 96px 96px 56px 96px 120px'} scrollActiveY={'hidden'}>
              <div className={'table_title'} style={{width:'2200px'}}>
                {dailyFacilityInfoTitle.map((v) => (
                  <div>{t(v)}</div>
                ))}
              </div>
              <div className={'table_list'} style={{width:'2200px'}}>
                <div>{facilityData.type}</div>
                <div>{facilityData.overhaul === undefined? '-' : facilityData.overhaul? '유' : '무'}</div>
                <div>{facilityData.productionDate !== undefined? facilityData.productionDate : '2023-02-03'}</div>
                <div>{facilityData.manufacturingNumber}</div>
                <div>{facilityData.maker}</div>
                <div>{facilityData.facilityName}</div>
                <div>{facilityData.ictNumber}</div>
                <div>{facilityData.hw}</div>
                <div>{facilityData.version}</div>
                <div>{facilityData.maxTon}</div>
                <div>{facilityData.voltage !== undefined? facilityData.voltage : '-'}</div>
                <div>{facilityData.companyName}</div>
                <div>{locationFilter(facilityData.location)}</div>
                <div>{facilityData.factoryName}</div>
                <div>{facilityData.factorySubdivision !== undefined? facilityData.factorySubdivision : '-'}</div>
                <div>{facilityData.manager !== undefined? facilityData.manager : '-'}</div>
                <div style={textSelectStyle}>{facilityData.image?.length === 0? '-' : '미리보기'}</div>
                <div style={textSelectStyle} onClick={()=>setModalState({open: true, status:'item', message:''})}>{'생산품목 보기'}</div>
                <div style={textSelectStyle}>{'일상점검 리스트'}</div>
              </div>
            </GridTable>
          </ContentFrame>


          <ContentFrame height={'500px'} justify={'column'}>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              <BaseComp>
                <div style={{width:'800px', display:'flex',flexDirection:'column'}}>
                  <div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
                    <MenuTitle title={'일상점검 등록'} unitCase={'MenuCompTitle'}/>
                    <div style={{display:'flex',gap:'16px'}}>
                      <MainButtons name={'양식 보기'} width={'88px'} clickEvent={()=>{setModalState({open: true, status:'form', message:''})}}/>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={()=>window.history.back()}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} clickEvent={()=>window.history.back()}/>
                    </div>
                  </div>
                  <div className={'scroll_comp'} style={{overflowX:'hidden',marginTop:'16px'}}>
                    <RegComp>
                      <div className={'daily'} style={{ borderTop:'1px solid #e5e5e5'}}>
                        <div style={{display:'inline-grid'}}>
                          <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                            <div className={'base_title'}>{'범례 1'}</div>
                            <RegisterInput type={'input'} name={'legend'} maxWidth={'200px'} value={''} onChange={onChange}/>
                          </div>
                        </div>
                        <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                          <div className={'base_title'} style={{backgroundColor:'#fff',width:'56px'}}>{'설명'}</div>
                          <RegisterInput type={'input'} name={'description'} maxWidth={'200px'} value={''} onChange={()=>''}/>
                          <MainButtons name={'추가'} width={'56px'} clickEvent={()=>addListRow()}/>
                        </div>
                      </div>
                    </RegComp>
                    {legendRowList.map((v, index) => (
                      <RegComp>
                        <div className={'daily'}>
                          <div style={{display:'inline-grid'}}>
                            <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                              <div className={'base_title'}>{'범례'+ (index+2)}</div>
                              <RegisterInput type={'input'} name={'legend'} maxWidth={'200px'} value={v.legend} onChange={()=>''}/>
                            </div>
                          </div>
                          <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                            <div className={'base_title'} style={{backgroundColor:'#fff',width:'56px'}}>{'설명'}</div>
                            <RegisterInput type={'input'} name={'description'} maxWidth={'200px'} value={v.description} onChange={()=>''}/>
                            <div className={'close'} onClick={()=>onRemoveLegendRow(index)} style={{transform:'rotate(90deg)',marginTop:'10px',cursor:'pointer'}}></div>
                          </div>
                        </div>
                      </RegComp>
                    ))}

                    <div style={{marginTop:'24px'}}>
                      <MenuTitle title={'점검항목'} unitCase={'MenuCompTitle'}/>
                      <div style={{display:'flex',flexDirection:'row',borderTop:'1px solid #e5e5e5'}}>
                        <div className={'base_title'} style={{borderBottom:'1px solid #e5e5e5',height:'98px',width:'104px'}}>{'점검01'}</div>
                        <RegComp>
                          <div className={'inspection'}>
                            <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                              <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'항목'}</div>
                              <RegisterInput type={'input'} name={'inspectionItem'} maxWidth={'200px'} value={''} onChange={()=>''}/>
                            </div>
                            <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                              <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'점검기준'}</div>
                              <ListInnerInput type={'drop'} name={'inspectionStandard'} width={'180px'} inputData={''} onChange={()=>''}/>
                              <MainButtons name={'추가'} width={'56px'} clickEvent={()=>addInspectionRow()}/>
                            </div>
                          </div>
                          <div className={'inspection'}>
                            <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                              <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'점검방법'}</div>
                              <RegisterInput type={'input'} name={'inspectionMethod'} maxWidth={'200px'} value={''} onChange={()=>''}/>
                            </div>
                            <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                              <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'기록방법'}</div>
                              <ListInnerInput type={'drop'} name={'recordMethod'} width={'180px'} inputData={''}  onChange={()=>''}/>
                            </div>
                          </div>
                        </RegComp>
                      </div>

                      {inspectionRowList.map((v, index) => (
                        <div style={{display:'flex',flexDirection:'row'}}>
                          <div className={'base_title'} style={{
                            borderBottom: '1px solid #e5e5e5',
                            height: '98px',
                            width: '104px',
                            boxSizing: 'border-box'}}>
                            {/*나중에 함수로 바꾸자 ..*/}
                            {'점검 '+'0'+(index + 2)}</div>
                          <RegComp>
                            <div className={'inspection'}>
                              <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                                <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'항목'}</div>
                                <RegisterInput type={'input'} name={'inspectionItem'} maxWidth={'200px'} value={''} onChange={()=>''}/>
                              </div>
                              <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                                <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'점검기준'}</div>
                                <ListInnerInput type={'drop'} name={'inspectionStandard'} width={'180px'} inputData={''}/>
                                <div className={'close'} onClick={()=>onRemoveInspectionRow(index)} style={{transform:'rotate(90deg)',marginTop:'10px',cursor:'pointer'}}></div>
                              </div>
                            </div>
                            <div className={'inspection'}>
                              <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                                <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'점검방법'}</div>
                                <RegisterInput type={'input'} name={'inspectionMethod'} maxWidth={'200px'} value={''} onChange={()=>''}/>
                              </div>
                              <div style={{display:'flex',alignItems:'center',gap:'16px'}}>
                                <div className={'base_title'} style={{backgroundColor:'#fff',width:'70px'}}>{'기록방법'}</div>
                                <ListInnerInput type={'drop'} name={'recordMethod'} width={'180px'} inputData={''}/>
                              </div>
                            </div>
                          </RegComp>
                        </div>
                      ))}
                      <RegComp>
                        <div className={'inspection'} style={{width:'800px', gridTemplateColumns:'168px 1fr'}}>
                          <div className={'base_title'} style={{height:'112px'}}>{'기타사항'}</div>
                          <div style={{padding:'8px 16px'}}>
                            <RegisterInput type={'textarea'} name={''} value={''} maxHeight={'96px'}/>
                          </div>
                        </div>
                      </RegComp>
                    </div>
                  </div>
                </div>
              </BaseComp>
              {/*라인*/}
              <div style={{display:'flex',backgroundColor:'#E6E9F4',width:'0.5px',height:'336px',margin:'0 24px'}}/>
              <div  style={{display:'flex',width:'407px', flexDirection:'column', overflowY:'auto',margin:'0 auto'}}>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <MenuTitle title={'점검항목 이미지'} unitCase={'MenuCompTitle'}/>
                               {/*<div style={{color:'#7E84A3',fontSize:'12px',lineHeight:'16px', height:'34px'}}>*/}
                               {/*    <div>{t('*최대 4개의 이미지 등록 가능')}</div>*/}
                               {/*    <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>*/}
                               {/*</div>*/}
                </div>

                <div style={{display:'flex', gap:'16px', flexWrap:'wrap',marginTop:'16px'}}>
                  <div>
                    <MenuTitle title={'점검항목 01'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={()=>''} profileSelect={false}/>
                  </div>
                </div>
              </div>
            </div>
          </ContentFrame>
        </SectionFrame>
      </React.Fragment>
    );
};

export default FacilityDailyInspectionReg;


const BaseComp = styled.div `
  
  .base_title {
    display: flex;
    width: 168px;
    height: 48px;
    align-items: center;
    line-height: 48px;
    padding-left: 16px;
    background-color: #F7F8FC;
    font-size: 12px;
    font-weight: 900;
    box-sizing: border-box;
  }

  .close {
    display: inline-block;
    width: 24px;
    height: 24px;


    &:after {
      display: inline-block;
      content: "\\00d7";
      font-size: 25px;
    }
  }
  
  .scroll_comp{
    overflow: auto;
    height: 400px;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background: #F5F5F5;
    }
  }
  `


const RegComp = styled.div `
  
  .daily {
    width: 800px;
    display:grid;
    gap: 30px;
    border-bottom:1px solid #E6E9F4;
    grid-template-columns: 410px 400px;
  }
  .inspection {
    width: 680px;
    display: grid;
 
    grid-template-columns: 340px 1fr;
    border-bottom: 1px solid #E6E9F4;
  
  }
 
`