import React from 'react';
import styled from 'styled-components';
import ModalBase from "./ModalBase";
import MainButtons from "../button/MainButtons";
import calendarIcon from '../../asset/image/ic-calendar.svg';
import sampleImage from '../../asset/image/180-Ton-C-.jpg'
import noImage from '../../asset/image/noImage.svg'

interface FormProps {
    closeEvent?:()=>void
    dailyDetailData?:any
}

const PreviewFormDailyModal = ({closeEvent,dailyDetailData}:FormProps) => {
    const checkData = [
        {id:1,title:'점검항목 부위01',image:sampleImage},
        {id:2,title:'점검항목 부위02',image:undefined},
        {id:3,title:'점검항목 부위03',image:undefined},
        {id:4,title:'점검항목 부위04',image:undefined},
        {id:5,title:'점검항목 부위05',image:undefined},
        {id:6,title:'점검항목 부위06',image:undefined},
        {id:7,title:'점검항목 부위07',image:undefined},
        {id:8,title:'점검항목 부위08',image:undefined},
        {id:9,title:'점검항목 부위09',image:undefined},
    ]

    const checkList = [
        {id:1,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:2,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:3,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:4,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:5,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:6,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:7,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:8,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
        {id:9,item:'광전자식 안전장치의 기타 사항 확인기타사항 확인',standard:'광전자식 안전장치의 기타 사항 확인기타사항 확인',method:'광전자식 안전장치의 기타 사항 확인기타사항 확인'},
    ]
    
    const matrixFunction = (m:any, n:any, initial:any) => {
        let a, i, j, mat = [];
        for (i = 0; i < m; i += 1) {
            a = [];
            for (j = 0; j < n; j += 1) {
                a[j] = initial;
            }
            mat[i] = a;
        }
        return mat;
    };

    return (
        <React.Fragment>
            <ModalBase modalContent={
                <Container>
                    <div className={'modal_title'}>{'일상점검 양식 보기'}</div>
                    <div className={'modal_content'}>
                        <ListBox>
                            <div className={'list_title'}>{'일상점검 리스트'}</div>

                            <div className={'list_tableTitle'}>
                                {['년,월','기종','제조번호','제조사','설비명','ICT번호'].map((v)=>(
                                    <div>{v}</div>
                                ))}
                            </div>

                            <div  className={'list_tableList'}>
                                <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
                                    <div>{dailyDetailData?.date  ?? '2022.01.01'}</div>
                                    <img src={calendarIcon} width={18} height={18} alt={''}/>
                                </div>
                                <div>{dailyDetailData?.type  ?? '프레스'}</div>
                                <div>{dailyDetailData?.manufacturingNumber  ?? 'PRESS-013-1111-276'}</div>
                                <div>{dailyDetailData?.maker  ?? 'ZESTECH'}</div>
                                <div>{dailyDetailData?.facility  ?? '프레스 400ton-1'}</div>
                                <div>{dailyDetailData?.icTNumber  ?? 'P-000001'}</div>
                            </div>

                            <div className={'box'}>
                                <div className={'box_image'}>
                                    <img src={sampleImage} alt="이미지없음" width={272} height={272}/>
                                </div>
                                <div className={'box_part'}>
                                    {checkData.map((v)=>(
                                        <div className={'box_part_image'} key={v.id}>
                                            <div>{v.title}</div>
                                            <img src={v.image === undefined ? noImage : v.image} alt={''} style={{objectFit:'contain'}} width={112} height={80}/>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={'check'}>
                                <div className={'check_title'}>
                                    {['NO.','점검항목','점검기준','점검 방법'].map((v)=>(
                                        <div>{v}</div>
                                    ))}
                                </div>

                                <div className={'check_day'}>
                                    {Array.from({length:31},(undefined:unknown, i) => i + 1).map((v)=>(
                                        <div key={v}>{v.toString().padStart(2,'0')}</div>
                                    ))}
                                </div>
                            </div>

                            <div className={'item'}>
                                <div>
                                    {checkList.map((v)=>(
                                        <div className={'item_list'} key={v.id}>
                                            <div>{v.id.toString().padStart(2,'0')}</div>
                                            <div>{v.item}</div>
                                            <div>{v.standard}</div>
                                            <div>{v.method}</div>
                                        </div>
                                    ))}
                                    <div>
                                        {['작성자','관지라확인','문제사항'].map((v)=>(
                                            <div key={v} className={'item_content'}>{v}</div>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    {matrixFunction(12,31,'-').map((v,index)=>(
                                        <div className={'item_list_day'} key={index}>
                                            {v.map((v,i)=>(
                                                <div key={i}>
                                                    {v}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </ListBox>
                        <MainButtons name={'확인'} buttonType={'cta'} width={'144px'} clickEvent={closeEvent}/>
                    </div>
                </Container>
            }/>
        </React.Fragment>
    );
};

export default PreviewFormDailyModal;

const Container = styled.div`
  width: 1032px;
  height: 728px;
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0 1px 4px #15223214;
  
  .modal{
    &_title{
      width: 1032px;
      height: 56px;
      box-shadow: 0 1px 4px #15223214;
      border-radius: 6px;
      color: #171725;
      background-color: #CFFA61;
      display: flex;
      align-items: center;
      padding: 0 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 18px;
    }
    &_content{
      padding: 16px 0 24px 16px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const ListBox = styled.div`
  width: 1000px;
  padding-right: 16px;
  height: 576px;
  
  
  border-left: 1px solid #D7DBEC;
  border-right: 1px solid transparent;
  margin-bottom: 24px;
  
  
  overflow-x: hidden;
  overflow-y: overlay;
  &::-webkit-scrollbar{
    display: block;
    width: 16px;
  }
  .list{
    display: flex;
    &_title{
      
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-top: 1px solid #D7DBEC;
      border-bottom: 1px solid #D7DBEC;
      border-right: 1px solid #D7DBEC;

      font-size: 18px;
      font-weight: 900;
    }
    &_tableTitle{
      display: grid;
      grid-template-columns: 128px 104px  192px 192px 192px 192px;
      line-height: 32px;
      font-size: 12px;
      font-weight: 900;
      background-color: #F5F6FA;
      border-bottom: 1px solid #D7DBEC;
      &>div{
        padding-left: 16px;
        border-right: 1px solid #D7DBEC;
      }
      
    }
    &_tableList{
      display: grid;
      grid-template-columns: 128px 104px  192px 192px 192px 192px;
      line-height: 32px;
      font-size: 14px;
      border-bottom: 1px solid #D7DBEC;
      &>div{
        padding-left: 16px;
        border-right: 1px solid #D7DBEC;
      }
      
    }
  }
  
  .box{
    display: flex;
    &_image{
      width: 424px;
      height: 288px;
      border-right: 1px solid #D7DBEC;
      border-bottom: 1px solid #D7DBEC;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_part{
      display: grid;
      grid-template-columns: 192px 192px 192px;
      box-sizing: border-box;
      font-size: 10px;
    }
    
    &_part_image{
      height: 96px;
      padding-top: 4px;
      box-sizing: border-box;
      border-right: 1px solid #D7DBEC;
      border-bottom: 1px solid #D7DBEC;
    }
  }
  
  .check{
    display: flex;
    border-bottom: 1px solid #D7DBEC;
    &_title{
      display: grid;
      grid-template-columns: 40px 152px 160px 152px;
      line-height: 32px;
      font-size: 12px;
      font-weight: 900;
      background-color: #F5F6FA;
      &>div:nth-child(1){
        padding-left: 9px;
        border-right: 1px solid #D7DBEC;
      }
      &>div{
        padding-left: 16px;
        border-right: 1px solid #D7DBEC;
      }
    }
    &_day{
      display: flex;
      font-size: 10px;
      font-weight: 900;
      
      &>div{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        box-sizing: border-box;
        border-right: 1px solid #D7DBEC;
        background-color: #F5F6FA;
      }
    }
  }
  
  .item{
    display: flex;
    &_list{
      display: grid;
      font-size: 10px;
      grid-template-columns: 40px 152px 160px 152px;
      height: 40px;
      box-sizing: border-box;
      &>div:nth-child(1){
        font-size: 12px;
      }
      &>div{
        border-right: 1px solid #D7DBEC;
        border-bottom: 1px solid #D7DBEC;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 0 12px;
      }
    }
    
    &_content{
      display: flex;
      flex-direction: column;
      font-size: 10px;
      height: 40px;
      width: 504px;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #D7DBEC;
      border-bottom: 1px solid #D7DBEC;
      box-sizing: border-box;
    }
    
    
    
    
    
    &_list_day{
      display: grid;
      font-size: 10px;
      grid-template-columns: repeat(31,16px);
      height: 40px;
      box-sizing: border-box;
      &>div{
        border-right: 1px solid #D7DBEC;
        border-bottom: 1px solid #D7DBEC;
        height: 40px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        justify-content: center;
      }
    }
  }
`

