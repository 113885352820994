import React,{useState} from 'react';
import styled from "styled-components";
import {
  ContentFrame ,
  helperModule ,
  NoData ,
  SectionFrame ,
} from "../../../style/StyleComponent";
import MenuTitle from "../../../components/title/MenuTitle";
import client from "../../../common/Config";
import {Authorization} from "../../../common/authorization";
import {ReactDiagram} from "gojs-react";
import * as go from 'gojs'
import {useTranslation} from "react-i18next";
import MainButtons from "../../../components/button/MainButtons";
import {useRecoilValue} from "recoil";
import {userPersistCompanyData, processSelectCompany} from "../../../common/StateManage";
import {buttonAuthValid , listLoadingCircle , loadingRemove} from "../../../function/function";
import {useLocation} from "react-router-dom";
import {gojsLicenseKey} from "../../../common/LicenseKey";
import {FullScreen , useFullScreenHandle} from 'react-full-screen'
import dayjs from 'dayjs'
import CalendarValidButton from '../../../components/button/CalendarValidButton'
import {FILE_ADDRESS} from "../../../api/endPoint";


const FullScreenProcess = () => {

  const location = useLocation()
  const authValid = location.pathname.includes('/admin')


  const companyData = useRecoilValue(authValid? processSelectCompany : userPersistCompanyData)
  const [nodeDataArray, setNodeDataArray] = useState<any>([])
  const [linkDataArray, setLinkDataArray] = useState<any>([])
  const [realtimeDataArray, setRealtimeDataArray] = useState<any>([])
  const [facilityArray, setFacilityArray] = useState<Array<{facilityCode:string, facilityName:string}>>([])
  const [realtimeCheck, setRealtimeCheck] = useState<boolean>(false)
  const [refreshDiagram, setRefreshDiagram] = useState<boolean>(false)
  const [intervalFacility, setIntervalFacility] = useState<any>()
  const [counter, setCounter] = useState<boolean>(false)
  const [fullScreenPage, setFullScreenPage] = useState<boolean>(false)
  const [productionData, setProductionData] = useState<any>([])
  const [selectedDate, setSelectedDate] = useState(dayjs().subtract(1,'day').format('YYYY-MM-DD'))
  const [productLoading, setProductLoading] = useState<boolean>(false)

  const { t }:any = useTranslation();

  const pressImage = `${FILE_ADDRESS}img/zes_20221019020220_867.jpeg`

  //
  const combinedArray = nodeDataArray?.map((node:any) => {
    const matchingFacility = realtimeDataArray.find((facility:any) => facility.facilityCode === node.facilityCode);
    return matchingFacility ? { ...node, ...matchingFacility } : node;
  });

  const productionNodeArray = nodeDataArray?.map((node:any) => {
    const matchingFacility = productionData.find((facility:any) => facility.facilityCode === node.facilityCode);
    return matchingFacility ? { ...node, ...matchingFacility } : node;
  });

  // 기존 동일한 키를 가진 설비 노드의 pressState를 기준으로 link의 색상이 반영되도록 함.
  // const matchingLinkFacilityCode = linkDataArray?.map((link:any) => {
  //   const matchingFacility = nodeDataArray.find((facility:any) => facility.key === (link.from || link.to));
  //   return matchingFacility ? { ...link, matchFacility: matchingFacility.facilityCode } : link;
  // });
  //
  // const combineLinkArray = matchingLinkFacilityCode?.map((node:any) => {
  //   const matchingFacility = realtimeDataArray.find((facility:any) => facility.facilityCode === node.matchFacility);
  //   return matchingFacility ? { ...node, pressState: matchingFacility.pressState, totalCounter: matchingFacility.totalCounter, wholeCounter: matchingFacility.wholeCounter} : node;
  // })

  // from, to 두 설비중 하나라도 off 인 경우. link가 off 상태로 보여지도록 변경 .
  const nodeKeyCombineData = realtimeDataArray?.map((node:any)=>{
    const matchingNode = nodeDataArray.find((facility:any)=>(facility.facilityCode === node.facilityCode))
    return matchingNode? {...node, key: matchingNode.key} : node
  })

  const matchingLinkFacilityCode = linkDataArray?.map((link:any) => {
    const matchingFacility = nodeKeyCombineData.find((facility:any) => facility.key === (link.from || link.to))
    const fromPressState = nodeKeyCombineData.find((facility:any) => facility.key ===  link.from)?.pressState
    const toPressState = nodeKeyCombineData.find((facility:any) => facility.key === link.to)?.pressState
    return matchingFacility ? { ...link, matchFacility: matchingFacility.facilityCode, pressState: (fromPressState === 'off' || toPressState === 'off')? 'off' : matchingFacility.pressState } : link;
  });

  const combineLinkArray = matchingLinkFacilityCode?.map((node:any) => {
    const matchingFacility = nodeKeyCombineData.find((facility:any) => facility.facilityCode === node.matchFacility);
    return matchingFacility ? { ...node, key: matchingFacility.key, totalCounter: matchingFacility.totalCounter, wholeCounter: matchingFacility.wholeCounter} : node;
  })



  let requestCount = 0
  const processStatusRes = async () => {
    requestCount++
    try {
      const response = await client.post('/process/real/time/facility',{
        facilityCodeList: nodeDataArray.map((v:any)=>(v.facilityCode))
      },{headers:{Authorization}})
      setRealtimeDataArray(response.data.data)
    }

    catch (e) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }

  function initDiagram ():any {

    go.Diagram.licenseKey = gojsLicenseKey
    const $= go.GraphObject.make

    const diagram = $(go.Diagram,{
      initialAutoScale: go.Diagram.Uniform,
      // "ChangeSelection": onSelectionChanged,
      maxSelectionCount: 1,
      isReadOnly: true,
      // isEnabled: false,
      // model: new go.GraphLinksModel({
      //                                 linkKeyProperty:'key'
      //                               }),
    })
    const model = diagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)
    model.linkKeyProperty = "facilityCode"
    diagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom

    diagram.nodeTemplate =
      $(go.Node,'Vertical',{
          locationObjectName:'Port',
          locationSpot: go.Spot.Top,
          // click: function (e, obj){facilityDataRes(obj.part?.data.facilityCode).then()},
          selectionAdornmentTemplate: $(go.Adornment,"Auto",
                                        $(go.Shape,"RoundedRectangle",{fill: null, stroke: "dodgerblue", strokeWidth:3}),
                                        $(go.Placeholder)
          ),
          // linkConnected: updatePortHeight,
          // linkDisconnected: updatePortHeight,
        },
        // new go.Binding('location','position',go.Point.parse).makeTwoWay(go.Point.stringify),
        new go.Binding('position', 'position', go.Point.parse).makeTwoWay((pos, node) => {
          return go.Point.stringify(pos);
        }),
        // $(go.Picture,{source: pressImage, width:50, height: 55,
        // 		name:'Port', cursor:'pointer',portId:'',
        // 		fromLinkable: true, toLinkable:true
        // 	},
        // 	new go.Binding('source', pressImage)),
        realtimeCheck?
          $(go.TextBlock, {margin: 8, editable: true, row: 1, column: 2, font: 'bold 22px Pretendard', stroke: '#0058ff'},
            new go.Binding('text', counter ? 'totalCounter' : 'presetCounter', function (value, data) {
              return (data?.part.data.pressState === 'off' || data?.part.data.pressState === 'empty')  ? '' : value
            }).makeTwoWay())
          :
          $(go.TextBlock, {margin: 8, editable: true, row: 1, column: 2, font: 'bold 22px Pretendard', stroke: '#0058ff'},
            new go.Binding('text', 'production', function (value) {
              return value
            }).makeTwoWay()),

        $(go.Panel,"Spot",{scale:1},
          $(go.Shape, 'RoundedRectangle',{width:50, height:60, strokeWidth:0,
              fill:  '#afd4fe',
              // name:'Port', cursor:'pointer',portId:'',
              // fromLinkable: true, toLinkable:true

            },
            //?? 이거 얻어걸렸는데. 해당 상태에 포함된 node만 확인할수 있음. err인 모든 애들만 보여짐
            // new go.Binding('visible', 'pressState', function(pressState) {
            // 	// 'off' 상태일 때만 텍스트 블록을 표시하지 않음
            // 	return pressState === 'off';
            // }),

            new go.Binding("fill", "pressState", function(key) {
                             return  (!realtimeCheck? "#afd4fe" :  key === "off" ? "gray" :  key === "err"? "red" : key === "empty"? "#afd4fe" : "#31FF00");
                           },
            )
          ),
          $(go.Picture,{source: pressImage, width:50, height: 55,
              name:'Port', cursor:'pointer',portId:'',
              fromLinkable: true, toLinkable:true
            },
            new go.Binding('source', pressImage)),


        ),


        // $(go.Panel,"Auto",
        // 	$(go.Shape,{fill:'#afd4fe', strokeWidth:0, width:40,height:20,
        // 		// name:'Port', cursor:'pointer',portId:'',
        // 		// fromLinkable: true, toLinkable:true
        // 	})),

        $(go.Panel,"Auto",
          // $(go.Shape,'RoundedRectangle',{fill:'lime',strokeWidth:0, width:160, height: 40},
          // 	new go.Binding('fill','color')),

          $(go.TextBlock,{margin:8, editable:true, row:1, column:2, font: '14px Nanum Gothic',},
            new go.Binding('text','facilityName').makeTwoWay(),
          ),

        ),
        // $(go.Shape,{fill:'lime', strokeWidth:0, width:40,height:60, position: new go.Point(0,0),
        // 	name:'Port', cursor:'pointer',portId:'',
        // 	fromLinkable: true, toLinkable:true
        // }),
      )


    diagram.linkTemplate =
      $(go.Link,
        {
          layerName: "Background",
          routing: go.Link.Orthogonal,
          corner: 15,
          reshapable: true,
          resegmentable: true,
          fromSpot: go.Spot.RightSide,
          toSpot: go.Spot.LeftSide
        },
        // make sure links come in from the proper direction and go out appropriately
        new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
        new go.Binding("toSpot", "toSpot", go.Spot.parse),
        // new go.Binding("points",'facilityCode').makeTwoWay(),
        // mark each Shape to get the link geometry with isPanelMain: true
        $(go.Shape, { isPanelMain: true, strokeWidth: 10 , stroke:'#afd4fe'
          },
          new go.Binding("stroke", "pressState", function(key) {
            return  (!realtimeCheck? "#afd4fe" :  key === "off" ? "gray" :  key === "err"? "red" : key === "empty"? "#afd4fe" : "#31FF00");
          }),

          // get the default stroke color from the fromNode
          new go.Binding("stroke", "fromNode"),
          // but use the link's data.color if it is set
          new go.Binding("stroke", "color")),
        $(go.Shape, { isPanelMain: true, stroke: "white", strokeWidth:3, name: "PIPE", strokeDashArray: [20, 40] },
          new go.Binding("stroke", "pressState", function(key) {
            return  (!realtimeCheck? '#fff':  key === "on" ? '#fff' : 'transparent');
          }),
        )
      );

    loop()

    // let opacity = 1;
    // let down = true;

    function loop() {
      let gram = diagram;
      setTimeout(() => {
        let oldskips = gram.skipsUndoManager;
        gram.skipsUndoManager = true;
        gram.links.each(link => {
          let shape = link.findObject("PIPE");
          //@ts-ignore
          let off = shape.strokeDashOffset - 3;
          //@ts-ignore
          shape.strokeDashOffset = (off <= 0) ? 60 : off;
          // if (down) opacity = opacity - 0.01;
          // else opacity = opacity + 0.003;
          // if (opacity <= 0) { down = !down; opacity = 0; }
          // if (opacity > 1) { down = !down; opacity = 1; }
          // //@ts-ignore
          // shape.opacity = opacity;
        });
        gram.skipsUndoManager = oldskips;
        loop();
      }, 60);
    }
    return diagram
  }

  const processDetailRes = async () => {
    setRefreshDiagram(false)
    try {
      listLoadingCircle()
      const response = await client.post('/process/detail',{
        companyCode: companyData.companyCode
      },{headers:{Authorization}})
      const data = response.data.data
      setLinkDataArray(data.processLink)
      setNodeDataArray(data.processNode)
      setFacilityArray(data.facilityInfo)
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
    setRefreshDiagram(true)
  }

  React.useEffect(()=>{
    processDetailRes().then()
  },[companyData.companyCode, realtimeCheck, counter])


  const startInterval = () => {

    const interval = setInterval(()=>{
      if(requestCount === 0){
        processStatusRes().then()
      }
    },1000)

    setIntervalFacility(interval)

  }

  const endInterval = () => {
    clearInterval(intervalFacility)
    setIntervalFacility(null)
  }

  // 해당 공정의 기계별 생산량값을 확인하는 요청api
  const processFacilityProductionRes = async () => {
    setProductLoading(true)
    try {
      const response = await client.post('/process/facility/production',{
        date: selectedDate,
        facilityCodeList: facilityArray?.map((v)=>(v.facilityCode))
      },{headers:{Authorization}})
      if(response.data.status === 200){
        setProductionData(response.data.data)
      }
      else{
        setProductionData([])
      }
    }
    catch (e) {
      console.log(e)
    }
    finally {
      setProductLoading(false)
    }
  }

  React.useEffect(()=>{
    if(companyData.companyCode && facilityArray.length > 0){
      processFacilityProductionRes().then()
    }
  },[companyData.companyCode, selectedDate, facilityArray])
  const diagramRef = React.useRef<any>(null);
  const zoomIn = () => {
    const diagram = diagramRef?.current.getDiagram();
    diagram.commandHandler.increaseZoom();
  };

  // Zoom Out 함수
  const zoomOut = () => {
    const diagram = diagramRef?.current.getDiagram();
    diagram.commandHandler.decreaseZoom();
  };

  const screenHandle = useFullScreenHandle()

  return (
    <React.Fragment>
      <FullScreen handle={screenHandle}>
        {fullScreenPage?
          <SectionFrame style={{width:'100vw'}}>
            <ProcessComponent>
              {/*{!realtimeCheck && authValid && <CompanySearchList companyDataReceive={(e)=> {*/}
              {/*  setCompanyData(e)*/}
              {/*  setRealtimeCheck(false)*/}
              {/*  setCounter(false)*/}
              {/*}}/>}*/}
              {/*{authValid && <SolutionCompanyFilterList receivedData={setCompanyData} onlyList={true}/>}*/}
              {/*<CompanyList companyDataReceive={(data)=>setCompanyData(data)} companyCodeReceive={(v)=>setCompanyCode(v)} companyNameReceive={(v)=>setCompanyName(v)}/>*/}
              <ContentFrame width={'100vw'} height={'100vh'} padding={'0'}>
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center',boxSizing:'border-box', padding:'12px 8px 0 24px'}}>
                  <div style={{display:'flex'}}>
                    <MenuTitle title={'공정 관리'} unitCase={'MenuCompTitle'}/>
                    {realtimeCheck &&
                      helperModule(
                        <div>
                          <div style={{fontSize:'14px', fontWeight:700}}>{'설비 상태'}</div>
                          <div style={{display:'flex',marginTop:'8px'}}>
                            <div style={{display:'flex', fontWeight:700}}>
                              <div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'#31FF00',marginRight:'8px'}}/>
                              <div>{'정상'}</div>
                            </div>
                            <div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>
                              <div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'gray',marginRight:'8px'}}/>
                              <div>{'전원 OFF'}</div>
                            </div>
                            <div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>
                              <div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'red',marginRight:'8px'}}/>
                              <div>{'에러'}</div>
                            </div>
                            <div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>
                              <div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'#afd4fe',marginRight:'8px'}}/>
                              <div>{'기계 데이터 없음'}</div>
                            </div>
                          </div>
                        </div>,'330px')}
                  </div>

                  <div style={{display:'flex'}}>

                    {realtimeCheck && <div style={{display: 'flex' , justifyContent: 'flex-end'}}>
                      <MainButtons name={'현재 카운터'} clickEvent={() => setCounter(false)} selected={!counter} margin={'0'}/>
                      <MainButtons name={'총 카운터'} clickEvent={() => setCounter(true)} selected={counter} margin={'0'}/>
                    </div>}

                    {!realtimeCheck && <CalendarValidButton loading={productLoading} buttonWidth={'115px'} bottom={'89%'} left={'103%'}
                                                            disabled={productLoading || ( nodeDataArray?.length === 0 || !nodeDataArray ) || ( realtimeCheck && realtimeDataArray?.length === 0 )}
                                                            receiveDate={(date) => setSelectedDate(dayjs(date).format('YYYY-MM-DD'))}/>}

                    {/*{realtimeCheck && <MainButtons name={counter ? '현재 카운터' : '총 카운터'} clickEvent={() => setCounter(!counter)}/>}*/}

                    <MainButtons name={realtimeCheck? '공정 보기' : '실시간 설비 상태'} width={'120px'} marginDis={true}
                                 loading={(realtimeCheck && realtimeDataArray?.length === 0)}
                                 disabled={(nodeDataArray?.length === 0 || !nodeDataArray) || (realtimeCheck && realtimeDataArray?.length === 0) || productLoading}
                                 clickEvent={()=>{
                                   setSelectedDate(dayjs().subtract(1,'day').format('YYYY-MM-DD'))
                                   setRealtimeCheck(!realtimeCheck)
                                   setRefreshDiagram(false)
                                   clearInterval(intervalFacility)
                                   if(!realtimeCheck){
                                     startInterval()
                                     // processStatusRes().then()
                                   }else{
                                     endInterval()
                                   }
                                 }} />
                    <MainButtons name={'목록으로 가기'} marginDis={true}
                                 clickEvent={()=>window.location.href = authValid? '/admin/fas/process' : '/fas/process'}/>
                  </div>
                </div>

                {!companyData.companyCode?
                  <NoData>{t('* 업체를 선택해 주세요.')}</NoData>
                  :
                  companyData.companyCode !== '' && !nodeDataArray &&
                    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'16px',marginTop:'24px', border:'1px solid #D7DBEC', borderRadius:'4px', height:'100%', boxShadow:'0 1px 4px #15223214'}}>
                      {facilityArray?.length !== 0 &&
                          <MainButtons name={'등록'} buttonType={'cta'} width={'80px'}
                                       disabled={window.location.pathname.includes('fas')? buttonAuthValid('create','12') : buttonAuthValid('create','67')}
                                       clickEvent={()=>window.location.href = `${location.pathname}/${companyData.companyCode}`}/>}
                      <div style={{opacity:0.5, fontSize:'14px',textAlign:'center',userSelect:'none'}}>
                        {`*${companyData.companyName}`+ (facilityArray?.length !== 0 ? t(` 공정정보를 등록해주세요.`) : t(`등록된 설비가 없습니다.`))}
                      </div>
                    </div>}

                {companyData.companyCode !== '' && nodeDataArray !== undefined && refreshDiagram && !productLoading &&

                    <div style={{display:'flex',justifyContent:'flex-end',gap:'6px',marginTop:'10px', height:'100%', position:'relative'}}>
                      <div className={'process_zoom_button'} style={{right: 0}}>
                        <div className={'zoom_button'} onClick={zoomIn} style={{marginBottom:'8px'}}>{'╋'}</div>
                        <div className={'zoom_button'} onClick={zoomOut} >{'━'}</div>
                      </div>

                      <ReactDiagram divClassName={'process_info_comp'}
                                    initDiagram={initDiagram} ref={diagramRef}
                                    // skipsDiagramUpdate={true}
                                    nodeDataArray={realtimeCheck? combinedArray : productionNodeArray}
                                    linkDataArray={realtimeCheck? combineLinkArray : linkDataArray}/>

                    </div>
                }
              </ContentFrame>
            </ProcessComponent>
          </SectionFrame>
          :
          <div onClick={()=> {screenHandle.enter(); setFullScreenPage(true)}}
               style={{backgroundColor:'#0c0f16', color:'#fff', fontSize:'4vw', cursor: 'pointer',
                 width:'100vw', height:'100vh', display:'flex', alignItems: 'center', userSelect:'none',
                 flexDirection:'column', justifyContent:'center'}}>{'화면을 클릭해 주세요.'}</div>}
      </FullScreen>
    </React.Fragment>

  );
};

export default FullScreenProcess;

const ProcessComponent = styled.div`
  display: flex;
  gap: 16px;
  canvas { outline: none; }

  .process_info_comp {
    width: 100%;
    height: 100%;
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    box-shadow: 0 1px 4px #15223214;
    background-color: #f4f4f4;
  }

  .process_zoom_button{
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding: 18px;
    z-index: 100;
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    background-color: #fff;
    .zoom_button{
      box-sizing: border-box;
      width: 48px;
      height: 48px;
      border: 1px solid #D7DBEC;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 800;
      font-family: Pretendard, sans-serif;
      color: #828282;
      &:hover{
        background-color: #0058ff;
        color: #fff;
      }
    }
  }
  
`
