import React , {useState, useEffect} from 'react';
import {
  ContentFrame ,
  SectionFrame ,
  helperModule
} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Authorization} from "../../../../common/authorization";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import dayjs from "dayjs";
import client from "../../../../common/Config";
import {Modal_View_Type} from "../../../../types/Fundamental";
import MainButtons from "../../../../components/button/MainButtons";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";

import {
  initModal , dataAccessLoading , loadingRemove , buttonAuthValid ,
} from "../../../../function/function";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import CompanySelectModal from "../../../../components/modal/CompanySelectModal";
import ToastPopup from "../../../../components/modal/ToastPopup";



const SolutionRequestRegister = () => {

  const {t}: any = useTranslation();

  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const solutionInput = {
    notice: '', type: '구독',
    representative: '', finishDate:'',
    companyName: '', solution:'',
  }
  const [inputState, setInputState] = useState<any>(solutionInput)
  const [companyData, setCompanyData] = useState<any>({})
  const totalSolution = inputState?.authorities?.split(',')

  const solutionType = (type:string) => {
    switch ( type ){
      case '구독' : return 'subscribe'
      case '구독해지' : return 'release'
    }
  }

  const onChangeValue = (e:any) => {
    const {value, name} = e.target

    setInputState({
                    ...inputState,
                    [name]: value
                  });
  }

  const solutionUpdateRes = async (statement: number) => {
    const solutionValue = (state: number) => {
      switch (state){
        case 0 : return 'run'
        case 1 : return 'finish'
        case 2 : return 'cancel'
      }
    }
    if(!companyData.companyCode){
      setDataModal({menu: 'confirm', message:'업체를 선택해 주세요.'})
    }
    else if(!inputState.representative){
      setDataModal({menu: 'confirm', message:'담당자를 입력해 주세요.'})
    }
    else if(!inputState.solution){
      setDataModal({menu: 'confirm', message:'솔루션을 선택해 주세요.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/solution/admin/register',{
          companyCode: inputState.companyCode,
          type: solutionType(inputState.type),
          solution: inputState.solution,
          statement: solutionValue(statement),
          email: inputState.email,
          notice: inputState.notice,
          representative: inputState.representative,
          finishDate: inputState.finishDate,
        },{headers:{Authorization}})

        if(response.data.status === 200){
          if(inputState.finishDate){
            const response = await client.post('/company/update',{
              ...companyData, phone_number: companyData.phoneNumber,
              authorities: (inputState?.type === '구독')?
                totalSolution?.concat(inputState?.solution) :
                totalSolution?.filter((v:any)=>(v !== inputState?.solution))
            },{headers:{Authorization}})
          }
          setTimeout(()=>{
            setDataModal({menu: 'success', message:'요청 정보가 등록 되었습니다.'})
            loadingRemove()
          },500)
        }else{
          setDataModal({menu: 'confirm', message:response.data.message})
        }
          loadingRemove()
      }catch (e:any) {
        setDataModal({menu: 'error', message:e.message})
        console.log(e)
        loadingRemove()
      }
    }
  }
  const companyDetailInfo = async () => {
    try {
      const response = await client.post('/company/detail',{
        companyCode: inputState?.companyCode
      },{headers:{Authorization}})
      const data = response.data.data
      setCompanyData(data)
    }catch ( e ) {
      console.log(e)
    }
  }

  useEffect(()=>{
    companyDetailInfo().then()
  },[inputState?.id])

  const solutionFinish = () => {
    if(!inputState.representative){
      window.location.href = '/admin/fas/solution'
    }
    setDataModal(initModal)
  }

  const solutionUpdate = () => {
    solutionUpdateRes(!inputState.finishDate? 0 : 1).then()
  }

  const companyDataSelect = (data:any) => {
    setInputState((prev:any)=>({...prev, ...data}))
    setDataModal(initModal)
  }

  useEffect(() => {
    const handleBeforeUnload = (event:BeforeUnloadEvent) => {
      if (dataModal.menu === '') {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dataModal.menu]);

  const solutionArrayCompare = ['P-BAS','C-BAS','R-BAS','A-BAS','F-EMS'].filter((v)=>(!inputState.authorities?.split(',').includes(v)))
  const releaseSolution = inputState.authorities?.split(',').filter((v:string)=>(!['P-MS','FAS'].includes(v)))

  const enterEvent = (e:any) => {
    if(e.key === 'Enter'){
      setDataModal({menu: 'search'})
    }
  }
  const [alertInput, setAlertInput] = React.useState<string>('')
  const alertPoint = (key: string) => {
    if( key === 'companyCode' ){
      return alertInput === '업체를 선택해 주세요.';
    } else if( key === 'representative' ){
      return alertInput === '담당자를 입력해 주세요.';
    }
  }

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{
          setDataModal(initModal)
          window.location.href = `/admin/fas/solution`}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}

      {dataModal.menu === 'confirm' &&  <ConfirmPopup clickEvent={solutionFinish} statusText={dataModal.message}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              clickEvent={()=>{window.location.href = `/admin/fas/solution`}}
                              viewType={true}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              noticeText={' * " 예 " 선택시 저장없이 이동합니다.'}/>
      }

      {dataModal.menu === 'search' &&
          <CompanySelectModal
              receivedCompany={companyDataSelect}
              closeButton={()=>setDataModal(initModal)}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              viewType={true}
                              clickEvent={()=>{ window.location.href = `/admin/fas/solution`}}
          />}
      <SectionFrame>
        <MenuTitle title={'솔루션 요청 관리 > 등록'} unitCase={'MenuTitle'}/>
        <ContentFrame flexDirection={'row'} justify={'flex-start'}>
          <React.Fragment>
            <ViewBox>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                <MenuTitle title={t('솔루션 요청 정보 등록')} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <MainButtons name={'취소'} width={'56px'}
                               clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                  <MainButtons name={'저장'} clickEvent={solutionUpdate} buttonType={'cta'} width={'56px'} marginDis={true} disabled={buttonAuthValid('update','23')}/>
                </div>
              </div>

              <RegisterComponent type={'textButton'} title={t('업체명')} name={'companyName'} error={alertPoint('companyCode')}
                                 placeholder={'검색'} buttonWidth={'72px'} required={true} clearEvent={()=>setAlertInput('')}
                                 value={inputState.companyName} onChange={onChangeValue} onKeyPress={enterEvent}
                                 buttonName={'검색'} clickEvent={()=>setDataModal({menu: 'search'})}/>
              <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>


                <RegisterComponent type={'text'} title={t('업체코드')} name={'companyCode'} value={inputState.companyCode} paddingRight={true}
                                   onChange={onChangeValue} placeholder={t('-')} width={'200px'} disabled={true}/>
                <RegisterComponent type={'text'} title={t('지역')} name={'companyCode'} value={inputState.address} paddingRight={true}
                                   onChange={onChangeValue} placeholder={t('-')}  disabled={true}/>
                <RegisterComponent type={'text'} title={t('전화번호')} name={'phoneNumber'} value={inputState.phoneNumber} paddingRight={true}
                                   onChange={onChangeValue} placeholder={t('-')} width={'200px'} disabled={true}/>
                <RegisterComponent type={'text'} title={t('이메일')} name={'email'} value={inputState.email}  paddingRight={true}
                                   onChange={onChangeValue} placeholder={t('-')} width={'200px'} disabled={true}/>
                <RegisterComponent type={'drop'} title={t('요청')}  dropWidth={'194px'} paddingRight={true} required={true}
                                   dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, type: v}))}
                                   name={'type'} value={inputState.type} disabled={!inputState.companyCode}
                                   dropArray={['구독', '구독해지']}/>
                <RegisterComponent type={'drop'} name={'solution'} title={t('요청 솔루션')} dropWidth={'194px'}
                                   required={true} paddingRight={true} disabled={!inputState.companyCode}
                                   dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, solution: v}))}
                                   dropArray={inputState.type === '구독'? solutionArrayCompare : releaseSolution}
                                   value={inputState.solution}/>
              </div>
              <RegisterComponent type={'text'} name={'representative'} value={inputState.representative} title={t('담당자')} required={true}
                                 onChange={onChangeValue} placeholder={t('담당자를 입력해주세요')}  paddingRight={true}/>
             <div style={{position:'relative'}}>
              <RegisterComponent type={'calendar'} name={'requestDate'} left={-100} top={96} selectDate={inputState.finishDate}
                                 value={inputState.finishDate}
                                 title={t('조치 날짜')}
                                 deleteEvent={()=>{setInputState((prev:any)=>({...prev, finishDate: ''}))}}
                                 onChange={(v)=> setInputState((prev:any)=>({...prev, finishDate: dayjs(v).format('YYYY.MM.DD')}))}
                                 placeholder={'조치 날짜'}/>
               {<div style={{position:'absolute',left: '48%', top:'7px'}}>
                 {helperModule( <div>{'조치 날짜를 지정하지 않으면 요청 상태가 진행중 으로 등록 됩니다.'}</div> )}
               </div>}
             </div>
              <RegisterComponent type={'textarea'} value={inputState?.notice} title={'비고'}
                                 name={'notice'}  onChange={onChangeValue} bottomBorder={true}/>
            </ViewBox>

            <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>

          </React.Fragment>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};

export default SolutionRequestRegister;

const ViewBox = styled.div`
  width: 784px;
  height: 600px;


  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }

`

//
// const Information = styled.div`
//   //border-top: 1px solid rgba(213, 215, 227, 0.5);
//   //border-bottom: 1px solid #F5F6FA;
//   display: grid;
//   box-sizing: border-box;]
//
//
//
//   input[type="date"]::-webkit-calendar-picker-indicator {
//     display: none;
//     -webkit-appearance: none;
//   }
//
//   .info,.action {
//     display: grid;
//     box-sizing: border-box;
//     border-top: 1px solid rgba(213, 215, 227, 0.5);
//
//     &-title {
//       padding: 16px;
//       width: 168px;
//       box-sizing: border-box;
//       display: flex;
//       align-items: center;
//       height: 48px;
//       background-color: #F5F6FA;
//       color:#131523;
//       font-size: 12px;
//       font-weight: 800;
//       border-bottom: 1px solid rgba(213, 215, 227, 0.5);
//
//     }
//
//     &-content {
//       display: flex;
//       padding: 0 0 0 16px;
//       box-sizing: border-box;
//       font-size: 14px;
//       height: 48px;
//       border-bottom: 1px solid rgba(213, 215, 227, 0.5);
//       align-items: center;
//       //border-top: 1px solid #F5F6FA;
//     }
//     &-content-req {
//       width: 600px;
//       height: 80px;
//       overflow-y: scroll;
//       overflow-x: hidden;
//       &::-webkit-scrollbar {
//         width: 15px;
//         display: block;
//       }
//       &::-webkit-scrollbar-thumb {
//         background-color: #D5D7E3;
//         border-radius: 10px;
//         background-clip: padding-box;
//         border: 6px solid transparent;
//       }
//       &::-webkit-scrollbar-track{
//         width: 21px;
//         background: transparent;
//       }
//     }
//
//
//
//
//   }
//   .scroll_func{
//     width: 784px;
//     height: 570px;
//     overflow-y: scroll;
//     overflow-x: hidden;
//     &::-webkit-scrollbar {
//       display: block;
//     }
//   }
// `
