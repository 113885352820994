import React , {useRef , useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  filterCheckedRow , handleRowDelete ,
  initModal , loadingRemove ,
  onMultiRegChangeArray ,
  singleCheckedFunction
} from "../../../../../function/function";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SingleListImageUploadModal from "../../../../../components/modal/SingleListImageUploadModal";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ExcelModal from '../../../../../components/modal/ExcelModal';
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const AccountMultipleReg = () => {
  const {t}: any = useTranslation();
  const customerTitle = ['거래처명' , '거래처구분' , '대표자명' , '담당자명' , '전화번호(거래처)' , '휴대폰번호(담당자)' , 'FAX' , '주소' , '상세주소' , '사업자번호' , '사업자 등록증' , '통장 사본'];
  const needValue = ['거래처명' , '대표자명' , '담당자명' , '휴대폰번호(담당자)' , '주소']
  const [checkedList , setCheckedList] = useState<any>(
    // Array(20).fill('').map((_,i)=>(i+1))
    []
  )
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [selectedID, setSelectedID] = useState<number>(0)
  const scrollRef = useRef<any>( null )

  const initialObject = {
    companyCode: selectedCompany.companyCode,
    customerName: '',
    president: '',
    managerName: '',
    phoneNumber: '',
    managerNumber: '',
    fax: '',
    address: '',
    detailAddress: '',
    businessNumber: '',
    businessRegistration: '',
    bankBook: '',
    bankBookUrl: '',
    businessRegistrationUrl: '',
    division: '',
  };
  const generateInitialRowList = Array.from( {length: 20} , ( _ , i ) => ( {
    id: i+1 ,
    companyCode: selectedCompany.companyCode ,
    customerName: '' ,
    president: '' ,
    managerName: '' ,
    phoneNumber: '' ,
    managerNumber: '' ,
    fax: '' ,
    address: '' ,
    detailAddress: '' ,
    businessNumber: '' ,
    businessRegistration: '' ,
    bankBook: '' ,
    bankBookUrl: '' ,
    businessRegistrationUrl: '' ,
    division: '' ,
  } ) );

  const [rowList , setRowList] = useState<any[]>( generateInitialRowList );

  const customerRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customerName.trim() ) ){
      setDataModal( {menu: 'confirm' , message: '거래처명을 입력해 주세요.'} )
    }
    else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.president.trim() ) ) ){
      setDataModal( {menu: 'confirm' , message: '대표자를 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.managerName.trim() ) ) ){
      setDataModal( {menu: 'confirm' , message: '담당자를 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.managerNumber ) ) ){
      setDataModal( {menu: 'confirm' , message: '담당자 번호를 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.address ) ) ){
      setDataModal( {menu: 'confirm' , message: '주소를 선택해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => ( ( v.businessNumber.length !== 12 ) && v.businessNumber ) ) ) ){
      setDataModal( {menu: 'confirm' , message: '사업자 번호 형식이 맞지 않습니다.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/customer/register' ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) ,
                                            {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          loadingRemove()
          setDataModal( {menu: 'warn' , message: data.message} )
        } else{
          setTimeout( () => {
            setCheckedList( [] )
            setRowList(generateInitialRowList)
            setDataModal( {menu: 'success' , message: '등록 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }

  const concatCheckedID = checkedList.includes(selectedID)

  const addressHandle = {

    selectAddress: ( data: any ) => {
      rowList[dataModal.index].address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
      rowList[dataModal.index].detailAddress = ''
      if(!concatCheckedID){
        setCheckedList(checkedList.concat(selectedID))
      }
        setSelectedID(0)
        setDataModal( initModal );
    } ,
  }
  const AddressStyle: React.CSSProperties | undefined = {
    display: 'block' ,
    width: 500 ,
    height: 600 ,
    position: 'absolute' ,
    boxShadow: '0 1px 7px #E6E9F4' ,
    borderRadius: '8px' ,
    backgroundColor: '#fff' ,
    padding: "7px" ,
    zIndex: 100 ,
  }

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => setDataModal( initModal )}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'business' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                          Object.assign( rowList[dataModal.index] , {
                                            businessRegistration: v.key ,
                                            businessRegistrationUrl: v.url
                                          } )
                                        if(!concatCheckedID){
                                          setCheckedList(checkedList.concat(selectedID))
                                        }
                                        setSelectedID(0)
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={rowList[dataModal.index].businessRegistration}
                                      receiveUrl={rowList[dataModal.index].businessRegistrationUrl}
          />}

      {dataModal.menu === 'bankBook' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        Object.assign( rowList[dataModal.index] , {
                                            bankBook: v.key ,
                                            bankBookUrl: v.url
                                          } )
                                        if(!concatCheckedID){
                                          setCheckedList(checkedList.concat(selectedID))
                                        }
                                        setSelectedID(0)
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={rowList[dataModal.index].bankBook}
                                      receiveUrl={rowList[dataModal.index].bankBookUrl}
          />}

      {dataModal.menu === 'post' &&
          <ModalBase clickEvent={() => setDataModal( initModal )}
                     modalContent={<DaumPostcode onComplete={addressHandle.selectAddress}
                                                 style={AddressStyle}
                                                 autoClose={false}/>}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 선택되지 않은 리스트는 저장 되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={customerRegisterRes}/>}


      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}}
                      excelContent={
                        <React.Fragment>
                          <ExcelApiButton category={'customer'} disabled={buttonAuthValid('create','4')} type={'format'} companyData={selectedCompany} />
                          <ExcelApiButton category={'customer'} disabled={buttonAuthValid('create','4')} type={'upload'} companyData={selectedCompany}/>
                        </React.Fragment>
                      }
          />
      }
      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}


      <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
        <MenuTitle title={'거래처 기본정보'} unitCase={'MenuCompTitle'}/>
        <div style={{display: 'flex'}}>

          <MainButtons name={'Excel'} width={'80px'}
                       disabled={buttonAuthValid( 'create' , '4' )}
                       clickEvent={() => setDataModal( {menu: 'excel'} )}/>
          <MainButtons name={'삭제'} width={'80px'}
                       disabled={
                         buttonAuthValid( 'rowDelete' , '4' ) ? rowList.length === 0
                           :
                           buttonAuthValid( 'delete' , '4' ) || checkedList.length === 0}
                       clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
          <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                       disabled={buttonAuthValid( 'create' , '4' ) || rowList.length === 0 || checkedList.length === 0}
                       clickEvent={() => {
                         setDataModal( {menu: 'register' , message: '등록을 진행 하시겠습니까?'} )
                       }}/>

        </div>
      </div>
      <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                          scrollWidth={2028}
                          scrollY_Hide={rowList?.length < 10}
                          height={538}
        // height={listHeightValid(authValid,{total: totalPage, page: page})}
                          tempCol={'40px repeat(7, 160px) 270px repeat(2, 160px) repeat(2, 140px)'}>

        <div className={'grid-title'}>
          <label className={'checkbox'}>
            <input type="checkbox"
                   onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                     dataList: rowList,
                     checkedList: checkedList ,
                     id: 'id' ,
                     setState: setCheckedList
                   } )}
                   checked={allCheckedState( {
                                               dataList: rowList ,
                                               checkedList: checkedList ,
                                               id: 'id'
                                             } )}/>
          </label>
          {customerTitle.map( ( v ) => (
            <TableValue title={v} key={v} viewType={rowList.length !== 0}
                        requiredValue={needValue}/> ) )}
        </div>

        {rowList.map( ( value , index ) =>
                        <GridTableSelect className={'grid-row-list'} checkboxNumber={index+1}>
                          <label className={'selectCheckbox'}>
                            <ListInnerInput type={'check'} name={'id'}
                                            onChange={( e: any ) => singleCheckedFunction( e.target.checked , value.id , setCheckedList , checkedList )}
                                            checked={checkedList.includes( value.id )}/>
                          </label>
                          <ListInnerInput type={'text'} name={'customerName'} placeholder={'거래처명'}
                                          inputData={value.customerName}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}/>
                          <ListInnerInput type={'text'} name={'division'} placeholder={'거래처구분'}
                                          inputData={value.division}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}/>
                          <ListInnerInput type={'text'} name={'president'} placeholder={'대표자명'}
                                          inputData={value.president}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}/>
                          <ListInnerInput type={'text'} name={'managerName'} placeholder={'담당자명'}
                                          inputData={value.managerName}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}/>
                          <ListInnerInput type={'phone'} name={'phoneNumber'} placeholder={'전화번호'}
                                          inputData={value.phoneNumber}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id, true )}/>
                          <ListInnerInput type={'phone'} name={'managerNumber'} placeholder={'휴대폰번호'}
                                          inputData={value.managerNumber}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id, true )}/>
                          <ListInnerInput type={'phone'} name={'fax'} placeholder={'FAX 번호'}
                                          inputData={value.fax}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id, true )}/>
                          <ListInnerInput type={'address'} name={'address'} placeholder={'-'}
                                          inputData={value.address}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}
                                          clickButton={() => {
                                            setDataModal( {menu: 'post', index: index} )
                                            setSelectedID(value.id)
                                          }}
                                          addressClick={() => {
                                            setDataModal( {menu: 'post', index: index} )
                                            setSelectedID(value.id)
                                          }}/>

                          <ListInnerInput type={'text'} name={'detailAddress'} placeholder={'상세주소'}
                                          inputData={value.detailAddress}
                                          onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList, checkedList, setCheckedList, value.id )}/>
                          <ListInnerInput type={'businessNumber'} name={'businessNumber'}
                                          placeholder={'사업자번호'} inputData={value.businessNumber}
                                          onChange={( e ) => {onMultiRegChangeArray( index , e , rowList , setRowList ,checkedList, setCheckedList, value.id, true )}}/>

                          <div>
                            <MainButtons name={`사업자 등록증 ${!value.businessRegistration ? '등록' : '변경'}`} width={'120px'}
                                         buttonType={!value.businessRegistration ? 'cta' : 'popupCta'}
                                         clickEvent={() => {
                                           setDataModal( {
                                                           menu: 'business' ,
                                                           index: index ,
                                                           view: false
                                                         } )
                                           setSelectedID(value.id)
                                         }} margin={'0'}/>
                          </div>
                          <div>
                            <MainButtons name={`통장 사본 ${!value.bankBook ? '등록' : '변경'}`} width={'120px'}
                                         buttonType={!value.bankBook ? 'cta' : 'popupCta'}
                                         clickEvent={() => {
                                           setDataModal( {
                                                           menu: 'bankBook' ,
                                                           index: index ,
                                                           view: false
                                                         } )
                                           setSelectedID(value.id)
                                         }} margin={'0'}/>
                          </div>
                        </GridTableSelect>
        )}

      </GridTableComponent>
      <div style={{display: 'flex' , justifyContent: 'flex-end' , marginTop: '10px'}}>
        <HorizontalMoveScrollButton
          leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
          rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
      </div>

    </React.Fragment>
  );
};
export default AccountMultipleReg;
