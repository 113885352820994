import React from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import SearchBar from "../input/SearchBar";
import {allCheckedFunction} from "../../function/function";
import {GridTable} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import {productSearchData} from "../../common/DataSet";

interface Props {
	closeEvent: ()=>void
}

const ProductItemModal:React.FunctionComponent<Props> = ({closeEvent}) => {
	return (
		<ModalBase modalContent={
			<BaseComponent>
				<div className={'modal'}>
					<div className={'modal_title'}>{'생산품목 보기'}</div>
				</div>
				<div className={'modal_content'}>
					<div>
						<SearchBar widthCus={'384px'}/>
						<GridTable tempCol={'128px 88px 160px 88px 88px 48px'}
						           scrollActiveY={'hidden'} overflow={'visible'}>
							<div className={'table_title'} style={{}}>
								{['거래처','모델','CODE','품명','품목 종류','단위'].map((v)=>(
									<div>{v}</div>
								))}
							</div>
							<div className={'scroll_func'} style={{height:'256px', overflowY:'scroll'}}>

								{productSearchData.map((v)=>(
									<div className={'table_list'}>
										<div>{v.account}</div>
										<div>{v.model}</div>
										<div>{v.code}</div>
										<div>{v.productName}</div>
										<div>{v.productType}</div>
										<div>{v.unit}</div>
									</div>
								))}
							</div>

						</GridTable>
					</div>
					<MainButtons name={'확인'} buttonType={'popupCta'} width={'144px'} clickEvent={closeEvent}/>
				</div>
			</BaseComponent>
		}/>
	);
};

export default ProductItemModal;

const BaseComponent = styled.div`
  width: 1032px;
  height: 512px;
  background-color: #ffffff;
  border-radius: 6px;
  box-sizing: border-box;
  user-select: none;
  .modal{
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    gap: 8px;
    cursor: move;
      &_title{
        font-size: 18px;
        font-weight: 900;
      }
      &_sub_title{
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
      }
  }
	.modal_content{
		display: flex;
		flex-direction: column;
		height: 456px;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 24px 16px;
	}
`