
import React , {useEffect} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SingleDrawingUploadBox from "../../../../../components/input/SingleDrawingUploadBox";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {Modal_View_Type , SearchCompanyType} from "../../../../../types/Fundamental";
import DaumPostcode from "react-daum-postcode";
import ModalBase from "../../../../../components/modal/ModalBase";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import FactorySegmentationModal from "../../../../../components/modal/FactorySegmentationModal";
import {
    phoneNumberFormat ,
    loadingRemove ,
    initModal , dataAccessLoading , dataValid
} from "../../../../../function/function";
import {useLocation} from "react-router-dom";
import {phoneReg} from "../../../../../ValidationCheck";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import CompanySelectModal from "../../../../../components/modal/CompanySelectModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import RegisterInput from "../../../../../components/input/RegisterInput";


const FactoryRegistration = () => {
    const location = useLocation()
    const authValid = location.pathname.includes('/admin')
    const { t }:any = useTranslation();
    const [imageKeyState, setImageKeyState] = React.useState<string[]>([]);
    const [openPostCode,setOpenPostCode] = React.useState<boolean>(false);
    const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)
    const [alertInput, setAlertInput] = React.useState<string>('')
    const factoryInput = {
        companyCode: authValid? '' : window.localStorage.getItem('companyCode'),
        companyName: authValid? '' : window.localStorage.getItem('company'),
        president: authValid? '' : window.localStorage.getItem('userName'),
        address:'',
        detailAddress :'',
        factoryDrawing: null,
        factoryDrawingUrl: null,
        factoryImage:[],
        factoryName:'',
        factoryNumber:'',
        managerNumber:'',
        factoryManager:'',
        factorySubdivision:[],
        notice:''
    }
    const [factoryRegister,setFactoryRegister] = React.useState<any>(factoryInput)
    const onFactoryChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement> , trim?: boolean) =>{
        const {name, value} = e.target;
        setFactoryRegister({
        ...factoryRegister,
        [name] : trim? value.trim() : value
        })
    }
    const imageValue = () => {
        if(imageKeyState !== null){
            return imageKeyState
        }else{
            return null
        }
    }


    const factoryRegisterResponse = async () =>{
        if(!factoryRegister.companyCode){
            setDataModal({menu:'confirm', message:'업체를 입력하지 않았습니다.'})
            setAlertInput('업체를 입력하지 않았습니다.')
        }
        else if(!factoryRegister.factoryName){
            setDataModal({menu:'confirm', message:'공장명을 입력하지 않았습니다.'})
            setAlertInput('공장명을 입력하지 않았습니다.')
        }
        else if(!factoryRegister.factoryManager){
            setDataModal({menu:'confirm', message:'공장 담당자를 입력하지 않았습니다.'})
            setAlertInput('공장 담당자를 입력하지 않았습니다.')
        }
        else if(!phoneReg.test(factoryRegister.factoryNumber.trim()) && factoryRegister.factoryNumber){
            setDataModal({menu:'confirm', message:'공장 전화번호 형식이 잘못 되었습니다.'})
            setAlertInput('공장 전화번호 형식이 잘못 되었습니다.')
        }
        else if(!factoryRegister.managerNumber){
            setDataModal({menu:'confirm', message:'담당자 전화번호를 입력하지 않았습니다.'})
            setAlertInput('담당자 전화번호를 입력하지 않았습니다.')
        }
        else{
            try{
                dataAccessLoading()
                const response  = await client.post('/factory/register',{
                    ...factoryRegister,
                    factoryNumber: factoryRegister.factoryNumber.trim(),
                    detailAddress: dataValid(factoryRegister.detailAddress,''),
                    // factoryDrawing:drawingState ?? null,
                    factoryImage: imageValue(),
                },{headers:{Authorization}})
                const data = response.data
                if(data.status !== 200) {
                    setAlertInput(data.message)
                    setDataModal({menu:'confirm', message:t(data.message)})
                    loadingRemove()
                }else{
                    setTimeout(()=>{
                        setDataModal({menu:'success', message:t('저장 되었습니다.')})
                        loadingRemove()
                    },500)
                }
            }catch (e:any){
                console.log(e)
                setDataModal({menu:'error', message:e.message})
                loadingRemove()
            }
        }

    }

    // const completeRegister = () =>{
    //     if(confirmMessageState === t('저장 되었습니다.')){
    //         setConfirm(false)
    //         window.location.reload();
    //     }else{
    //         setConfirm(false);
    //     }
    // }

    const selectCompanyData = (data: SearchCompanyType) => {
        // setSearch(data)

        setFactoryRegister({...factoryRegister,
              companyCode: data.companyCode,
              companyName: data.companyName,
              president:data.president,
              address:data.address,
              detailAddress: data.detailAddress,
              factoryManager:data.president,
              managerNumber:data.managerNumber
        }


            // Object.assign(factoryRegister,{
            //     companyCode:data.companyCode,
            //     companyName:data.companyName,
            //     president:data.president,
            //     address:data.address?.split('\n')[0],
            //     detailAddress:data.address?.split('\n').pop(),
            //     factoryManager:data.president,
            //     managerNumber:data.managerNumber
            // })
        )
        setDataModal(initModal)
    }

    const addressHandle = {
        clickBtn : () => {
            setOpenPostCode(current => !current);
            setAlertInput('')
        },
        selectAddress: (data: any) => {
            factoryRegister.address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
            setFactoryRegister({...factoryRegister,detailAddress:''})
            setOpenPostCode(false);
        },
    }

    const AddressStyle ={
        display:'block',
        width:500,
        height:600,
        position:'absolute',
        boxShadow:'0 1px 7px #E6E9F4',
        borderRadius:'8px',
        backgroundColor:'#fff',
        padding: "7px",
        zIndex: 100,
    }

    const segmentHandler = (segment: string[]) => {
        Object.assign(factoryRegister, {factorySubdivision : segment})
        setDataModal(initModal)
    }

    const cancelInputPrint = () => {
        setFactoryRegister({...factoryRegister, factoryDrawing: null, factoryDrawingUrl: null})
    }

    const alertPoint = (key: string) => {
        if(key === 'companyName'){
            return alertInput === '업체를 입력하지 않았습니다.';
        }
        if(key === 'factoryName'){
            return alertInput === '공장명을 입력하지 않았습니다.';
        }
        if(key === 'address'){
            return alertInput === '주소을 입력하지 않았습니다.';
        }
        if(key === 'factoryManager'){
            if(alertInput === '공장 담당자를 입력하지 않았습니다.'){
                return true
            }else return alertInput === '담당자명에 공백(스페이스)은 사용할 수 없습니다.';
        }
        if(key === 'factoryNumber'){
            if(alertInput === '전화번호 형식 다름.'){
                return true
            }
            else if(alertInput === '공장 전화번호 형식이 잘못 되었습니다.'){
                return true
            }
            else return alertInput === '전화번호에 공백(스페이스)은 사용할 수 없습니다.';
        }
        if(key === 'managerNumber'){
            if(alertInput === '담당자 전화번호 형식 다름.'){
                return true
            }
            else return alertInput === '전화번호에 공백(스페이스)은 사용할 수 없습니다.';
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (event:BeforeUnloadEvent) => {
            if (dataModal.menu === '') {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [dataModal.menu]);

    return (
        <SectionFrame>
            {dataModal.menu === 'success' ?
              <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{
                  setDataModal(initModal)
                  window.location.href = authValid ? `/admin/fas/factory` : '/fas/factory' }}/>
              : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
                : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}

            {dataModal.menu  === 'confirm' &&
              <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>}

            {dataModal.menu === 'segment' &&
                <FactorySegmentationModal factorySegment={factoryRegister.factorySubdivision} segmentMenu={'register'}
                  clickEvent={(v)=>segmentHandler(v)}
                                          factoryName={factoryRegister.factoryName === '' ? undefined  : factoryRegister.factoryName}
                  closeEvent={()=>setDataModal(initModal)}/>}

            {dataModal.menu === 'printView' &&
              <DetailImageView image={`${factoryRegister.factoryDrawingUrl}`} closeMenu={()=>setDataModal(initModal)}/>}
            {/*{confirm ? <ConfirmPopup clickEvent={completeRegister} statusText={confirmMessageState}/> : null}*/}
            {dataModal.menu === 'company' &&
              <CompanySelectModal receivedCompany={selectCompanyData}
                                  closeButton={()=>setDataModal(initModal)}/>}
            {openPostCode && <ModalBase clickEvent={()=>setOpenPostCode(false)} modalContent={
                <DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
                  //@ts-ignore
                              style={AddressStyle}/>}/>}
            {dataModal.menu === 'save' &&
              <ConfirmPopup statusText={dataModal.message}
                            clickEvent={()=>{
                                setDataModal(initModal)
                                window.location.href = authValid ? `/admin/fas/factory` : '/fas/factory'
                            }}/>
            }

            {dataModal.menu === 'cancel' &&
              <SelectConfirmModal statusText={dataModal.message}
                                  noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                                  cancelEvent={()=>{setDataModal(initModal)}}
                                  viewType={true}
                                  clickEvent={()=>{ window.location.href = authValid ? `/admin/fas/factory` : '/fas/factory'}}
              />
            }

            <MenuTitle title={(authValid? '업체/' : '')+'기본정보 관리 > 공장 기본정보 > 등록'} unitCase={'MenuTitle'}/>
            <ContentFrame>
                <div style={{display:'flex',userSelect:'none'}}>
                    <RegisterBox>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'16px'}}>
                            <MenuTitle title={'공장 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                            <div style={{display:'flex'}}>
                                <MainButtons name={'취소'} width={'56px'}
                                             clickEvent={()=>{setDataModal({menu:'cancel',message:t('작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?')})}}/>
                                <MainButtons buttonType={'cta'} width={'56px'} name={'저장'} marginDis={true}
                                             clickEvent={factoryRegisterResponse}/>
                            </div>
                        </div>

                        <div className={'scroll_func'} style={{width:'804px'}}>
                            {authValid &&
                            <React.Fragment>
                                <RegisterComponent type={'textButton'} onChange={(e)=>onFactoryChange(e, true)} title={t('업체명')} required={true}
                                                   error={alertPoint('companyName')} onKeyPress={()=>setDataModal({menu:'company',message:''})}
                                                   buttonName={'검색'} disabled={true}
                                                   clickEvent={()=>setDataModal({menu:'company', message:''})}
                                                   clearEvent={()=>setAlertInput('')}
                                                   value={factoryRegister.companyName} name={'companyName'} buttonWidth={'72px'}/>
                                <RegisterComponent type={'text'} onChange={onFactoryChange} title={t('대표')}
                                                   value={factoryRegister.president} name={'president'}/>
                            </React.Fragment>}

                            <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
                                <RegisterComponent type={'text'} onChange={(e)=>onFactoryChange(e, true)} title={t('공장명')} required={true}
                                                   error={alertPoint('factoryName')} paddingRight={true}
                                                   clickEvent={()=>setAlertInput('')}
                                                   value={factoryRegister.factoryName} name={'factoryName'}/>
                                <RegisterComponent type={'segment'} onChange={onFactoryChange} title={t('공장 세분화')} clickEvent={()=>setDataModal({menu:'segment', message:''})}
                                                   value={''} segmentAccess={factoryRegister?.factorySubdivision.length !== 0}
                                                   name={'factoryName'} buttonName={'세분화 등록'}/>
                            </div>

                            <RegisterComponent type={'address'} onChange={onFactoryChange} title={t('공장 주소')} width={'72px'} required={true}
                                               name={'address'} subName={'detailAddress'}
                                               subValue={factoryRegister.detailAddress} error={alertPoint('address')}
                                               clickEvent={addressHandle.clickBtn} value={factoryRegister.address}/>

                            <RegisterComponent type={'phone'} value={factoryRegister.factoryNumber} error={alertPoint('factoryNumber')} title={t('전화번호')}
                                               onChange={onFactoryChange} name={'factoryNumber'} clickEvent={()=>setAlertInput('')}
                                               numberFormat={factoryRegister.factoryNumber}/>

                            <RegisterComponent type={'text'} onChange={onFactoryChange} title={t('공장 담당자')}
                                               required={true} clickEvent={()=>setAlertInput('')}
                                               value={factoryRegister.factoryManager} name={'factoryManager'}
                                               error={alertPoint('factoryManager')}/>

                            <RegisterComponent type={'phone'} value={factoryRegister.managerNumber} clickEvent={()=>setAlertInput('')}
                                               error={alertPoint('managerNumber')} title={t('공장 담당자 전화번호')} required={true}
                                               onChange={onFactoryChange} name={'managerNumber'}
                                               numberFormat={factoryRegister.managerNumber}/>

                            <RegisterComponent type={'print'} title={'도면정보'} buttonName={'업로드'}
                                               clearEvent={cancelInputPrint} clickEvent={()=>setDataModal({menu:'printView', message:''})}
                                               name={'factoryDrawing'}
                                               printTag={<SingleDrawingUploadBox buttonWidth={'80px'}
                                               receiveData={(data)=>setFactoryRegister({...factoryRegister, factoryDrawing: data.imgKey, factoryDrawingUrl: data.imgUrl})}/>}
                                               width={'72px'} value={!factoryRegister.factoryDrawingUrl? t('도면을 등록해 주세요.') : factoryRegister.factoryDrawingUrl?.split('/').pop()}/>

                            <div style={{display:'grid',gridTemplateColumns:'168px 1fr',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>
                                <div className={'info-title'} style={{height: '112px'}}>{t('내용')}</div>
                                <div className={'info-content'} style={{height: '112px'}}>
                                    <RegisterInput type={'textarea'} name={'notice'} value={factoryRegister.notice}
                                                   maxHeight={'96px'} limit={2000}
                                                   onChange={onFactoryChange} placeholder={t('내1용을 입력해주세요.')}/>
                                </div>
                            </div>
                        </div>
                    </RegisterBox>

                    <div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>

                    <div style={{width:'370px',height:'34px',alignItems:'center'}}>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <MenuTitle title={'이미지 등록'} unitCase={'MenuCompTitle'}/>
                            <div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
                                <div>{t('*최대 4개의 이미지 등록 가능')}</div>
                                <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                            </div>
                        </div>

                        <div style={{marginTop:'16px'}}>
                            <TotalImageUploadBox imageKeyArray={(data)=>(setImageKeyState(data))}
                                                 profileSelect={false}/>
                        </div>
                    </div>
                </div>
            </ContentFrame>
        </SectionFrame>
    );
};

export default FactoryRegistration;

const RegisterBox = styled.div`
  width: 784px;
  height: 620px;
  .scroll_func{
    width: 810px;
    height: 560px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
    }
  }
  .input{
    &_title{
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }
    &_box{
      border-top: 1px solid #E6E9F4;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
    &_tel{
      width: 600px;
      height: 32px;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #D7DBEC;
      border-radius: 4px;
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      padding: 0 8px;
      &::placeholder{
        font-size: 14px;
        color: rgba(23,23,37,0.3);
        font-weight: normal;
        font-family: "NanumGothicOTF", sans-serif;
      }
        &:focus{
            border: 1px solid #0058ff;
        }
    }
  }

    .info, .reg {
        display: grid;
        box-sizing: border-box;

        &-title {
            padding: 16px;
            width: 168px;
            box-sizing: border-box;
            display: flex;
            //justify-content: space-between;
            align-items: center;
            height: 48px;
            background-color: #F5F6FA;
            color: #131523;
            font-size: 12px;
            font-weight: 800;
            border-bottom: 1px solid rgba(213, 215, 227, 0.5);

        }

        &-content {
            display: flex;
            padding-left: 16px;
            min-width: 224px;
            box-sizing: border-box;
            font-size: 14px;
            height: 48px;
            border-bottom: 1px solid rgba(213, 215, 227, 0.5);
            align-items: center;
        }
    }
`

