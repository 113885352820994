import React from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../components/title/MenuTitle";
import OnlineStatus from "../../../../components/display/OnlineStatus";
import UserRealTimeStatus from "../../../../components/square/UserRealTimeStatus";
import SystemLink from "../../../../components/display/SystemLink";
import {realTimeStatusCircle} from "../../../../common/DataSet";
import {ContentFrame, NoData, SectionFrame} from "../../../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import InfiniteScroll from "react-infinite-scroll-component";
import InfoNoticeViewModal from "../../../../components/modal/InfoNoticeViewModal";
import {useRecoilState} from "recoil";
import {sidePersistState} from "../../../../common/StateManage";
import PartnerRealTimeStatus from "../../../../components/square/PartnerRealTimeStatus";
import {buttonAuthValid , dataValid} from "../../../../function/function";
const OverviewContainer = () => {
	type solutionType = {
		"A-BAS":{solutionNo: string, statement: string}[],
		"C-BAS":{solutionNo: string, statement: string}[],
		"F-EMS":{solutionNo: string, statement: string}[],
		"P-BAS":{solutionNo: string, statement: string}[],
		"R-BAS":{solutionNo: string, statement: string}[],
	}

	const [eventTotalPage,setEventTotalPage] = React.useState<number>(1);
	const [eventListPageState,setEventListPageState] = React.useState<number>(1);
	const [eventList,setEventList] = React.useState<any>([]);
	const [alarmTotalPage,setAlarmTotalPage] = React.useState<number>(1);
	const [alarmListPage,setAlarmListPage] = React.useState<number>(1);
	const [alarmListData,setAlarmListData] = React.useState<any>([]);
	const [solutionState, setSolutionState] = React.useState<solutionType>()
	const [getInfoNumber,setGetInfoNumber] = React.useState<number>(0);
	const [openInfoNoticeModal,setOpenInfoNoticeModal] =React.useState<boolean>(false);
	const [infoDetailData, setInfoDetailData] = React.useState<{title: string, content:any}>()

	const { t }:any = useTranslation();


	const [,setIdxNumber] = useRecoilState<number>(sidePersistState);

	//협력사 공지사항 인피니트 스크롤
	const eventListRes  = async () => {
		try {
			const response = await client.get(`/infoNotice/list/${eventListPageState}/${10}`,
				{headers: {Authorization}})
			const data = response.data.data
			setEventTotalPage(data.total_page)
			if(data.total_page > 1 && eventListPageState > 1){
					setEventList((prev:any)=> prev.concat(data.row))
			}else{
				setEventList(data.row)
			}
		}catch (e) {
			console.log(e)
		}
	}
	//협력사 이벤트 공지사항 모달창 API
	const eventSearchRes = async () =>{
		try {
			const response = await client.post(`/infoNotice/detail`,{
				infoNum:getInfoNumber
			},{headers:{Authorization}})
			setInfoDetailData(response.data.data)
		}catch (e) {
			console.log(e)
		}
	}

	//실시간 알림 인피니트 스크롤
	// const realTimeUserNoticeRes = async ()=>{
	// 	try{
	// 		const response = await client.get(`/alarm/list/${alarmListPage}/companyCode/${window.localStorage.getItem('companyCode')}`,{
	// 			headers:{Authorization}
	// 		})
	// 		const data = response.data.data
	// 		setAlarmTotalPage(data.total_page)
	// 		if(data.total_page > 1 && alarmListPage > 1){
	// 			setAlarmListData((prev:any)=> prev.concat(data.row))
	// 		}else{
	// 			setAlarmListData(data.row)
	// 		}
	// 	}catch (e) {
	// 		console.log(e)
	// 	}
	// }

	const realTimeAffiliateNoticeRes = async () =>{
		try{
			const response = await client.get(`/alarm/list/${alarmListPage}/@/companyCode`,{
				headers:{Authorization}
			})
			const data = response.data.data
			setAlarmTotalPage(data.total_page)
			if(data.total_page > 1 && alarmListPage > 1){
				setAlarmListData((prev:any)=> prev.concat(data.row))
			}else{
				setAlarmListData(data.row)
			}
		}catch (e) {
			console.log(e)
		}
	}

	const solutionStateRes = async () => {
		try {
			const response = await client.get('/solution/state',{headers:{Authorization}})
			setSolutionState(response.data.data)
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		eventListRes().then()
	},[eventListPageState])

	// React.useEffect(()=>{
	// 	if(window.localStorage.getItem('auth') === 'affiliate'){
	// 		// realTimeAffiliateNoticeRes().then()
	// 	}else{
	// 		// realTimeUserNoticeRes().then()
	// 	}
	// },[alarmListPage])

	React.useEffect(()=>{
		if(window.localStorage.getItem('auth') === 'affiliate'){
			eventSearchRes().then()
		}
		solutionStateRes().then()
	},[getInfoNumber])

	const dateCut = (date:string) => {
		return eventList.modifiedDate = date.substring(0,10)
	}

	const moreEventScrollData = ()=> {setEventListPageState((prev)=> prev+1)}
	const moreAlarmScrollData = ()=> {setAlarmListPage((prev)=> prev+1)}

	const toLowerCaseOpen = (data:string) =>{
		return data === undefined ? '' : data.toLowerCase()
	}

	const sideMenuSelector = (menuTitle:string) =>{
		if(menuTitle === 'fas'){
			setIdxNumber(0)
		}else if(menuTitle === 'pbas'){
			setIdxNumber(1)
		}else if(menuTitle === 'fems'){
			setIdxNumber(6)
		}
	}
	// console.log(
	// 	window.localStorage.getItem('authoritiesList') === `[{\\"message\\":\\"권한 정보가 없습니다.\\"}]`
	// )

	return (
		<React.Fragment>
			<SectionFrame>
				{openInfoNoticeModal && <InfoNoticeViewModal
					noticeContent={infoDetailData?.content} noticeTitle={infoDetailData?.title}
					confirmEvent={()=>setOpenInfoNoticeModal(false)}/>}

				<MenuTitle title={'FAS 메인화면'} unitCase={'MenuTitle'}/>
				<div style={{display:'flex',justifyContent:'space-between'}}>
					<ContentFrame width={'840px'} height={'666px'}>
						<div style={{display:'flex',alignItems:'center',marginBottom:'30px',justifyContent:'space-between'}}>
							<MenuTitle title={'실시간 알림 현황'} unitCase={'MenuCompTitle'}/>
							<div style={{display:'flex'}}>
								{realTimeStatusCircle.map((value)=>(
									<OnlineStatus key={value.text} statusType={'noticeType'} statusColor={value.color}
												  title={t(value.text)}/>
								))}
							</div>
						</div>
						{window.localStorage.getItem('auth') !== 'affiliate' ?
							<React.Fragment>
								{alarmListData.length !== 0 ?
									<AlarmScrollFunc id={'alarm_scroll'}>
										<InfiniteScroll scrollableTarget={'alarm_scroll'} loader={<div>{''}</div>}
														next={moreAlarmScrollData} hasMore={alarmTotalPage > alarmListPage}
														dataLength={alarmListData?.length === undefined ? 0 : alarmListData?.length}>
											{alarmListData.map((v:any)=>(
												<UserRealTimeStatus key={v.alarmTime} iconType={v.solution.substring(1,-1)} facilityName={v.facilityName}
																	dateTime={v.alarmTime} makerNumber={v.manufacturingNumber === '' ? '없음' : v.manufacturingNumber}
																	plantName={v.factoryCode !== 'noCodeData' ? v.factoryName : '-등록안된 공장-'}
																	noticeContent={v.message} alarmState={v.alarmState} alarmTime={v.alarmTime}
																	clickEvent={()=>{window.location.href = `/${toLowerCaseOpen(v?.solution.replace('-',''))}/notification`;
																					 sideMenuSelector(toLowerCaseOpen(v?.solution.replace('-','')));}}/>
											))}
										</InfiniteScroll>
									</AlarmScrollFunc>
									:
									<NoData height={500}>{t('* 실시간 알림 현황이 없습니다.')}</NoData>
								}
							</React.Fragment>
							:
							<React.Fragment>
								{alarmListData.length !== 0 ?
									<AlarmScrollFunc id={'alarm_scroll'}>
										<InfiniteScroll scrollableTarget={'alarm_scroll'} loader={<div>{''}</div>}
														next={moreAlarmScrollData} hasMore={alarmTotalPage > alarmListPage}
														dataLength={alarmListData?.length === undefined ? 0 : alarmListData?.length}>
											{alarmListData.map((v:any)=>(
												<PartnerRealTimeStatus key={v.alarmTime} iconType={v.solution.substring(1,-1)} factoryName={v.factoryCode !== 'noCodeData' ? v.factoryName : '등록안된 공장'}
																	   companyName={v.companyName} dateTime={v.alarmTime} alarmState={v.alarmState}
																	   alarmMessage={v.message} makerNumber={v.manufacturingNumber} facilityName={v.facilityName}
																	   clickEvent={()=>{
																		   				window.location.href = `/${toLowerCaseOpen(v?.solution.replace('-',''))}/notification`;
																		   				sideMenuSelector(toLowerCaseOpen(v?.solution.replace('-','')));}}
																	   companyCode={v.companyCode}/>
											))}
										</InfiniteScroll>
									</AlarmScrollFunc>
									:
									<NoData height={500}>
										{'* 실시간 알림 현황이 없습니다.'}
									</NoData>
								}
							</React.Fragment>
						}
					</ContentFrame>

					<div style={{display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
						{window.localStorage.getItem('auth') !== 'affiliate' &&
							<ContentFrame width={'410px'} height={'440px'} padding={'30px 0 30px 30px'}>
								<MenuTitle title={'FAS 연동 솔루션'} unitCase={'MenuCompTitle'}/>
								<SolutionTable>
									{['P-BAS','R-BAS','C-BAS','V-MS','A-BAS','F-EMS'].map((data)=>(
											<div key={data} style={{boxSizing:'border-box', paddingRight:'18px'}}>
												{/*@ts-ignore*/}
												<SystemLink iconType={data} iconTitle={data.substring(1,-1)} statement={solutionState?.[data]?.[0]}/>
											</div>
									))}
								</SolutionTable>
							</ContentFrame>
						}

						<ContentFrame width={'410px'} height={window.localStorage.getItem('auth') === 'affiliate' ? '666px' : '206px'} justify={'space-between'}>
							<div>
								<div style={{display:"flex",alignItems:'center',justifyContent:"space-between"}}>
									<MenuTitle title={'이벤트&공지사항'} unitCase={'MenuCompTitle'}/>
									<MoreViewButton onClick={()=>window.location.href = `/fas/wiki`} disabled={buttonAuthValid('read','21')}>{'더 보기 >'}</MoreViewButton>
								</div>
								<EventTable>
									<div className={'table_title'}>
										{['NO.','제목','날짜'].map((v,i)=>(
											<div key={v}>{t(v)}</div>
										))}
									</div>
									{eventList?.length !== 0 ?
										<React.Fragment>
											{window.localStorage.getItem('auth') === 'affiliate'?
												<div className={'scroll_var'} id={'event_scroll'}>
													<InfiniteScroll scrollableTarget={'event_scroll'} loader={<div>{''}</div>}
																	next={moreEventScrollData} hasMore={eventTotalPage > eventListPageState}
																	dataLength={eventList?.length === undefined ? 0 : eventList?.length}>
														{eventList?.map((v:any, i:number)=>(
															<TableList key={'eventlist' + i} selected={getInfoNumber === v.infoNum}
																 onClick={()=>{setGetInfoNumber(v.infoNum);
																	 	 	   setOpenInfoNoticeModal(true);}}>
																<div>{v.no}</div>
																<div className={'title_hidden'}>{v.title}</div>
																<div>{dateCut(v.modifiedDate)}</div>
															</TableList>
														))}
													</InfiniteScroll>
												</div>
												:
												eventList?.slice(0,1).map((v:{infoNum:number,title:string,modifiedDate:string,no:any})=>(
													<div key={v.infoNum} className={'user_table_list'} style={{pointerEvents:buttonAuthValid('read','21') ? 'none' : 'auto',opacity:buttonAuthValid('read','21') ? 0.5 : 1}}
															 onClick={()=>window.location.href = `/fas/info/${v.infoNum}`}>
														<div>{v.no}</div>
														<div className={'title_hidden'}>{v.title}</div>
														<div>{dateCut(v.modifiedDate)}</div>
													</div>))
											}
										</React.Fragment>
										:
										<NoData height={55}>{t('* 등록된 공지사항이 없습니다.')}</NoData>
									}
								</EventTable>
							</div>
						</ContentFrame>
					</div>
				</div>
			</SectionFrame>
		</React.Fragment>
	);
};

export default OverviewContainer;

const SolutionTable = styled.div`
	overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 14px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #D5D7E3;
    border-radius: 7px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
`
const MoreViewButton = styled.div<{disabled?:boolean}>`
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
	font-size: 12px;
	font-weight: bold;
	color:#7E84A3;
	opacity: ${p=>p.disabled ? 0.5 : 1};
	pointer-events: ${p=>p.disabled ? 'none' : 'auto'};
		&:hover{
			text-underline-offset: 3px;
			text-decoration: 2px #7E84A3 underline;
		}
	`

const EventTable= styled.div`
	.table_title{
        margin-top: 16px;
		padding-left: 16px;
		font-size: 12px;
		color: #7E84A3;
		display: grid;
		grid-template-columns: 44px 200px 1fr;
		line-height: 48px;
		border-bottom: 1px solid #E6E9F4;
        user-select: none;
	}

	.user_table_list{
		padding-left: 16px;
		font-size: 14px;
		user-select: none;
		display: grid;
		grid-template-columns: 44px 200px 1fr;
		line-height: 48px;
		cursor: pointer;
		&:hover{
			color: #0058ff;
			font-weight: 700;
		}
	}
	.title_hidden{
		width: 170px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

  .scroll_var{
    width: 370px;
    height: 480px;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
	  
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
      background-color: #fff;
    }
	  
    &::-webkit-scrollbar-thumb {
      background-color: #E6E9F4;
      border-radius: 16px;
      background-clip: padding-box;
      border: 8px solid transparent;
    }
  }
`
const TableList = styled.div<{selected?:boolean,disabled?:boolean}>`
	padding-left: 16px;
	font-size: 14px;
	font-family: Poppins;
	display: grid;
	grid-template-columns: 44px 200px 1fr;
	line-height: 56px;
	cursor: pointer;
	transition: all 300ms;
	color: ${props => props.selected? '#0058ff' : '#171725'};
	opacity: ${p=>p.disabled ? 0.5 : 1};
	pointer-events: ${p=>p.disabled ? 'none' : 'auto'};
	background-color: ${props => props.selected? 'rgba(0,88,255,0.05)' : 'transparent'};
	border: 1px solid ${props => props.selected? '#0058ff' : 'transparent'};
	font-weight: ${props => props.selected? 700 : 400};
	&:hover{
		background-color:rgba(0,88,255,0.05);
		font-weight: 600;
		color: #0058FF;
	}
`

const AlarmScrollFunc = styled.div`
	overflow-y: overlay;
	height: 544px;
	width: 796px;
	&::-webkit-scrollbar{
		display: block;
		width: 16px;
	}
`