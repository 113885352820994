import React from 'react';
import {PageContainer} from "../../style/StyleComponent";
import IntegratedSideMenu from "../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../containers/common/SectionContainer";
import RankPageContainer from "../../containers/admin/fas/RankPageContainer";

const RankConnectPage = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<RankPageContainer/>}/>
    </PageContainer>
  );
};
export default RankConnectPage;
