import React , {useEffect , useRef , useState} from 'react';
import styled from "styled-components";
import {
	ContentFrame , NoData ,
	SectionFrame ,
	validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ReactDiagram} from "gojs-react";
import * as go from "gojs";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useLocation , useParams} from "react-router-dom";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {useRecoilValue} from "recoil";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {processSelectCompany , userPersistCompanyData} from "../../../../../common/StateManage";
import {
	authValid ,
	buttonAuthValid ,
	dataAccessLoading ,
	initModal ,
	listLoadingCircle ,
	loadingRemove
} from "../../../../../function/function";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ProcessFacilityCheck from "../../../../../components/modal/ProcessFacilityCheck";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import {FILE_ADDRESS} from "../../../../../api/endPoint";
import {gojsLicenseKey} from "../../../../../common/LicenseKey";

const ProcessRegister = () => {
	// const basicTitle = ['업체명','대표','주소','전화번호','담당자','담당자 전화번호','관리자 ID','업체코드','업체로고']
	const companyCode = useParams()
	const location = useLocation()
	let diagramRef = useRef(null)

	const pressImage = `${FILE_ADDRESS}img/zes_20221019020220_867.jpeg`

	const [nodeDataArray, setNodeDataArray] = useState<any>([])
	const [linkDataArray, setLinkDataArray] = useState<any>([])
	const [processNode,setProcessNode] = useState<any>([])
	const [skipsDiagramUpdate,setSkipsDiagramUpdate] = useState(false)
	const [clickNode, setClickNode] = useState<any>({type:'-', facilityName: '-', maker: '-', manufacturingNumber: '-', ictNumber: '-', hw: '-', profileImageUrl: '-'})
	// const companyData = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)

	const companyData = useRecoilValue<any> (authValid()? processSelectCompany : userPersistCompanyData)

	const [refreshNode, setRefreshNode] = useState<boolean>(false)
	const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)

	// const [companyInfoHidden , setCompanyInfoHidden] = useState<boolean>(true)

	const { t }:any = useTranslation();
	const facilityDataRes = async (code: string) => {
		try {
			const response = await client.post('/facility/detail',
				{facilityCode: code},{headers:{Authorization}})
			const data = response.data.data
			setClickNode(data.current)
		}catch (e) {
			console.log(e)
		}
	}

	function initDiagram ():any {
		go.Diagram.licenseKey = gojsLicenseKey
		const $= go.GraphObject.make
		const diagram = $(go.Diagram,{

			initialAutoScale: go.Diagram.Uniform,
			'undoManager.isEnabled':true,
			'draggingTool.gridSnapCellSize': new go.Size(10,10),
			'draggingTool.isGridSnapEnabled': true,
			//클릭시 새로운 노드 생성
			// 'clickCreatingTool.archetypeNodeData':{
			// 	facilityName:'프레스', color:'#afd4fe',source: pressImage
			// },

			// model: new go.GraphLinksModel({
			// 	//만약 새로 지정한다면 업체코드/공장코드/설비코드를 키값으로 하는듯;
			// 	linkKeyProperty:'facilityCode'
			// })
		},
		)

		const model = diagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)
		model.linkKeyProperty = "facilityCode"
		diagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom
		//링크 연결시 링크 갯수 만큼 높이 증가 / 제한 설정있음
 		//@ts-ignore
		// function updatePortHeight(node, link, port) {
		// 	let sideinputs = 0;
		// 	let sideoutputs = 0;
		// 	//@ts-ignore
		// 	node.findLinksConnected().each(l => {
		// 		if (l.toNode === node && l.toSpot === go.Spot.LeftSide) sideinputs++;
		// 		if (l.fromNode === node && l.fromSpot === go.Spot.RightSide) sideoutputs++;
		// 	});
		// 	let tot = Math.max(sideinputs, sideoutputs);
		// 	tot = Math.max(1, Math.min(tot, 2));
		// 	port.height = tot * (10+2) + 2;  // where 10 is the link path's strokeWidth
		// }


		diagram.nodeTemplate =
			$(go.Node,'Vertical',{
					locationObjectName:'Port',
					locationSpot: go.Spot.Top,
					click: function (e, obj){facilityDataRes(obj.part?.data.facilityCode).then()},
					selectionAdornmentTemplate: $(go.Adornment,"Auto",
						$(go.Shape,"RoundedRectangle",{fill: null, stroke: "dodgerblue", strokeWidth:3}),
					$(go.Placeholder),
																				$("Button",
																					{ width:20, height:20, alignment: go.Spot.TopRight, alignmentFocus: go.Spot.Left,
																						click: (e, obj)=>{
																						setNodeDataArray(nodeDataArray.filter((v:any)=>(v.facilityCode !== obj.part?.data.facilityCode)))
																								setLinkDataArray(
																									linkDataArray.filter((el:any)=>(
																										((obj.part?.data.key !== el.from) && (obj.part?.data.key !== el.to))
																									)
																								))
																					} },
																					$(go.TextBlock, "X",  // the Button content
																						{ font: "bold 10pt sans-serif" , stroke:'black'})
																				)


					)
					// linkConnected: updatePortHeight,
					// linkDisconnected: updatePortHeight,
				},
				// new go.Binding('location','position',go.Point.parse).makeTwoWay(go.Point.stringify),
				// new go.Binding('position', 'position', go.Point.parse).makeTwoWay(null),
				new go.Binding('position', 'position', go.Point.parse).makeTwoWay((pos) => {
					return go.Point.stringify(pos);
				}),
				$(go.Shape,'Circle',{
					width:10, strokeWidth:0, fill:'lime',margin: new go.Margin(0,0,10,0),
					cursor:'pointer',portId:'', fromLinkable: true, toLinkable: true}),

				$(go.Panel,"Spot",{scale:1},
					$(go.Shape, 'RoundedRectangle',{width:50, height:40, strokeWidth:0, fill:'#afd4fe'}),

					$(go.Picture,{source: pressImage, width:50, height: 55,	portId:''},
						new go.Binding('source', pressImage)),
					),

				$(go.Panel,"Auto",
					$(go.TextBlock,{margin:8, editable:false, row:1, column:2, font: '14px Nanum Gothic'},
						new go.Binding('text', 'facilityName').makeTwoWay())
				),
			)

		diagram.linkTemplate =
			$(go.Link,
				{
					layerName: "Background",
					routing: go.Link.Orthogonal,
					corner: 15,
					reshapable: true,
					resegmentable: true,
					fromSpot: go.Spot.RightSide,
					toSpot: go.Spot.LeftSide
				},
				// make sure links come in from the proper direction and go out appropriately
				new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
				new go.Binding("toSpot", "toSpot", go.Spot.parse),
				new go.Binding("points",).makeTwoWay(),
				// mark each Shape to get the link geometry with isPanelMain: true
				$(go.Shape, { isPanelMain: true, stroke: "#afd4fe", strokeWidth: 10 },
					// get the default stroke color from the fromNode
					new go.Binding("stroke", "facilityCode"),
					// but use the link's data.color if it is set
					new go.Binding("stroke", "color")),
				$(go.Shape, { isPanelMain: true, stroke: "white", strokeWidth: 3, name: "PIPE", strokeDashArray: [20, 40] })
			);

		return diagram
	}

	let mapNodeKeyIdx = new Map();
	let mapLinkKeyIdx = new Map();


	function refreshNodeIndex(nodeArr:any) {
		mapNodeKeyIdx.clear();
		nodeArr.forEach((n:any, idx:any) => {
			mapNodeKeyIdx.set(n.facilityCode, idx);
		});
	}

	function refreshLinkIndex(linkArr:any) {
		mapLinkKeyIdx.clear();
		linkArr.forEach((l:any, idx:any) => {
			mapLinkKeyIdx.set(l.facilityCode, idx);
		});
	}

	refreshNodeIndex(nodeDataArray);
	refreshLinkIndex(linkDataArray);
	let nodeArr = nodeDataArray.slice()
	let linkArr = linkDataArray.slice()

	const handleModelChange = (obj: go.IncrementalData) => {

		const insertedNodeKeys = obj.insertedNodeKeys;
		const modifiedNodeData = obj.modifiedNodeData;
		const removedNodeKeys = obj.removedNodeKeys;
		const insertedLinkKeys = obj.insertedLinkKeys;
		const modifiedLinkData = obj.modifiedLinkData;
		const removedLinkKeys = obj.removedLinkKeys;
		// const modifiedModelData = obj.modelData;
		const modifiedNodeMap = new Map<go.Key, go.ObjectData>()
		const modifiedLinkMap = new Map<go.Key, go.ObjectData>()
		let arrChanged = false;

		// handle node changes
		if (modifiedNodeData) {
			modifiedNodeData.forEach((nd:any) => {
				modifiedNodeMap.set(nd.facilityCode, nd);
				const idx = mapNodeKeyIdx.get(nd.facilityCode);

				if (idx !== undefined && idx >= 0) {
					nodeArr.splice(idx, 1, nd);
					arrChanged = true;
				}
			});
		}
		if (insertedNodeKeys) {
			insertedNodeKeys.map((key) => {
				const nd = modifiedNodeMap.get(key);
				const idx = mapNodeKeyIdx.get(key);

				if (nd && idx === undefined) {
					mapNodeKeyIdx.set(nd.key, nodeArr.length);
					nodeArr.push(nd);
					arrChanged = true;
				}
			});
		}
		if (removedNodeKeys) {
			nodeArr = nodeArr.filter((nd:any) => {
				if (removedNodeKeys.includes(nd.key)) {
					arrChanged = true;
					return false;
				}
				return true;
			});
			refreshNodeIndex(nodeArr);
		}
		// handle link changes
		if (modifiedLinkData) {
			modifiedLinkData.forEach((ld:any) => {
				// console.log(ld)
				modifiedLinkMap.set(ld.facilityCode, ld);
				const idx = mapLinkKeyIdx.get(ld.facilityCode);
				if (idx !== undefined && idx >= 0) {
					linkArr.splice(idx, 1, ld);
					arrChanged = true;
				}
			});
		}
		if (insertedLinkKeys) {
			insertedLinkKeys.forEach((key) => {
				const ld = modifiedLinkMap.get(key);
				const idx = mapLinkKeyIdx.get(key);
				// console.log(ld)
				if (ld && idx === undefined) {
					mapLinkKeyIdx.set(ld.key, linkArr.length);
					//@ts-ignore
					linkArr.push(ld);
					arrChanged = true;
				}
			});
		}
		if (removedLinkKeys) {
			linkArr = linkArr.filter((ld:any) => {
				//@ts-ignore
				if (removedLinkKeys.includes(ld.facilityCode)) {
					arrChanged = true;
					return false;
				}
				return true;
			});
			refreshLinkIndex(linkArr);
		}

		if (arrChanged) {
			setNodeDataArray(nodeArr);
			setLinkDataArray(linkArr);
			setSkipsDiagramUpdate(true);
		}
	}

	// const facilityDataRes = async (page:number) => {
	// 	try {
	// 		const response = await client.get(`/facility/admin/search/${pageNumber}/all/${companyCode}`,{
	// 			headers:{Authorization}
	// 		})
	//
	// 		if(nodeDataArray.length < 9){
	// 			setNodeDataArray(response.data.data.row)
	// 		}else{
	// 			// setNodeDataArray(nodeDataArray.push(response.data.data.row))
	// 			console.log(nodeDataArray.push(...response.data.data.row))
	// 			setNodeDataArray(nodeDataArray.push(...response.data.data.row))
	// 		}
	//
	// 	}catch (e) {
	// 		console.log(e)
	// 	}
	// 	setPageNumber(pageNumber+1)
	// }

	const processRegisterRes = async () => {
		try {
			dataAccessLoading()
			const response = await client.post('/process/register',{
				companyCode: companyCode.companyCode,
				processNode: nodeDataArray,
				processLink: linkDataArray
			},{headers:{Authorization}})
			const data = response.data
			if(data.status !== 200){
				setDataModal({menu:'confirm',message:data.message})
				loadingRemove()
			}else{
				setTimeout(()=>{
					setDataModal( {menu: 'success' , message:t('공정이 새로 등록 되었습니다.')})
					loadingRemove()
				},500)
			}
		}catch (e:any) {
			setDataModal({menu:'error',message:e.message})
			console.log(e)
			loadingRemove()
		}
	}


	const processDetailRes = async () => {
		try {
			listLoadingCircle()
			const response = await client.post('/process/detail',{
				companyCode: companyCode.companyCode
			},{headers:{Authorization}})
			const data = response.data.data
			setProcessNode(data.processNode)
			console.log(data)
			if(data.processNode !== undefined){
				setLinkDataArray(data.processLink)
				setNodeDataArray(data.processNode)
			}
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}
	// const companyDetail = async ()=>{
	// 	try {
	// 		const response = await client.post('/company/detail',{
	// 			id: companyData.companyId
	// 		},{headers:{Authorization}})
	// 		const data = response.data.data
	// 		setSelectCompany(data)
	//
	// 	}catch ( e ) {
	// 		console.log(e)
	// 	}
	// }
	//
	// React.useEffect(()=>{
	// 	companyDetail().then()
	// },[])

	React.useEffect(()=>{
		processDetailRes().then()
	},[])

	const processDeleteRes = async () => {
		try{
			setDataModal(initModal)
			dataAccessLoading()
			const response = await client.post('/process/delete',{
				companyCode: companyCode.companyCode
			},{headers:{Authorization}})

			const data = response.data
			if(data.status !== 200){
				setDataModal({menu:'confirm',message:data.message})
				loadingRemove()
			}else{
				setTimeout(()=>{
					setDataModal({menu:'success',message:'공정이 삭제 되었습니다.'})
					loadingRemove()
				},500)
			}
		}catch (e:any) {
			setDataModal({menu:'error',message:e.message})
			console.log(e)
			loadingRemove()
		}
	}

	React.useEffect(()=>{
		setRefreshNode(false)
		setTimeout(()=>{setRefreshNode(true)},100)
	},[nodeDataArray.length])

	useEffect(() => {
		const handleBeforeUnload = (event:BeforeUnloadEvent) => {
			if (dataModal.menu === '') {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [dataModal.menu]);

	return (
		<React.Fragment>
				{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} timeSet={700}

																										closeEvent={()=> window.location.href = authValid()? `${location.pathname.slice(0,18)}/${companyCode.companyCode}` : `${location.pathname.slice(0,12)}`}
																										// closeEvent={()=> setDataModal(initModal)}

					/>
					: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}
																										 closeEvent={()=> setDataModal(initModal)}/>
						: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}
																											closeEvent={()=> setDataModal(initModal)}/> : null}

				{dataModal.menu === 'confirm' &&
					<ConfirmPopup statusText={dataModal.message}
												clickEvent={()=>window.location.href = authValid()? location.pathname.slice(0,18) : location.pathname.slice(0,12)}/>
				}
				{dataModal.menu === 'select' &&
					<SelectConfirmModal statusText={dataModal.message}
															clickEvent={(()=>processDeleteRes())}
															noticeText={'"예" 클릭시 데이터는 복구 할 수 없습니다.'}
															cancelEvent={()=>setDataModal(initModal)}/>
				}

				{dataModal.menu === 'back' &&
					<SelectConfirmModal statusText={dataModal.message}
															viewType={true}
															noticeText={' * "예" 선택시 저장없이 이동합니다.'}
															clickEvent={()=> {window.location.href = authValid()? location.pathname.slice(0,18) : location.pathname.slice(0,12)}}
															cancelEvent={()=>{setDataModal(initModal)}}/>}

			{dataModal.menu === 'logoImage' &&
				<DetailImageView image={companyData?.logoImageUrl}
												 closeMenu={()=>setDataModal(initModal)}/>}

			{dataModal.menu === 'facility'&&
				<ProcessFacilityCheck companyData={companyData}
					cancelEvent={()=>setDataModal(initModal)}
					receivedData={(v)=> {
					setNodeDataArray(v)
					setLinkDataArray(linkDataArray.filter((el:any)=>(
						(v.some((data:any)=>(data?.key === (el.from))) && v.some((data:any)=>(data?.key === (el.to))))
					)))
				}}
				selectedFacility={nodeDataArray}/>}
			<SectionFrame>
				<div style={{display:'flex',justifyContent:'space-between',position:'relative'}}>
					<MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 공정 관리 > 등록'} unitCase={'MenuTitle'}/>
					<div style={{position:'absolute',right:0, top:'77px',display:'flex'}}>
						{/*{authValid && <MainButtons name={!companyInfoHidden ? '업체 정보 보기' : '업체 정보 감추기'} width={'120px'}*/}
						{/*							clickEvent={() => setCompanyInfoHidden( !companyInfoHidden )}/>}*/}
						<MainButtons name={'목록'} width={'56px'} marginDis={true}
												 clickEvent={()=>window.location.href = authValid()? location.pathname.slice(0,18) : location.pathname.slice(0,12)}
												 // clickEvent={()=> {setDataModal({menu:'back',message:'작성중인 내용이 저장되지 않습니다.\n"목록"으로 이동 하시겠습니까?'})}}
						/>
					</div>
				</div>
				<ProcessComponent>
					{/*{*/}
					{/*	(authValid && companyInfoHidden) && <ContentFrame height={'208px'}>*/}
					{/*	<MenuTitle title={'업체 기본정보'} unitCase={'MenuCompTitle'}/>*/}

					{/*	<GridTableComponent tempCol={'128px 88px 200px 120px 88px 120px 88px 120px 96px'}*/}
					{/*											scrollX_Hide={true}*/}
					{/*											scrollY_Hide={true}>*/}
					{/*		<div className={'grid-title-unchecked'}>*/}
					{/*			{basicTitle.map((v) => (*/}
					{/*				<div key={v}>{t(v)}</div>*/}
					{/*			))}*/}
					{/*		</div>*/}
					{/*		<GridTableSelect className={'grid-list-unchecked-no_icon'}>*/}
					{/*			{validationValue(companyData?.companyName)}*/}
					{/*			{validationValue(companyData?.president)}*/}
					{/*			{tooltipModule( dataValid(companyData?.address ) )}*/}
					{/*			{validationValue(companyData?.phoneNumber)}*/}
					{/*			{validationValue(companyData?.managerName)}*/}
					{/*			{validationValue(companyData?.managerNumber)}*/}
					{/*			{validationValue(companyData?.id)}*/}
					{/*			{validationValue(companyData?.companyCode)}*/}
					{/*			{companyData?.logoImageUrl ?*/}
					{/*				<ListInnerInput type={'clickText'} inputData={t('미리 보기')}*/}
					{/*												clickButton={()=>setDataModal({menu:'logoImage' ,message: ''})}/>*/}
					{/*				:*/}
					{/*				<div>{'-'}</div>*/}
					{/*			}*/}
					{/*		</GridTableSelect>*/}
					{/*	</GridTableComponent>*/}
					{/*</ContentFrame>*/}
					{/*}*/}
					<ContentFrame height={'680px'} padding={'16px 16px 8px 32px'}>
						<div style={{display:'flex',justifyContent:'space-between'}}>
							<div style={{display:'flex', gap:'28px',alignItems:'center'}}>
								<MenuTitle title={'공정 관리 등록'} unitCase={'MenuCompTitle'}/>
							</div>

							<div style={{display:'flex'}}>
									<MainButtons name={'설비 가져오기'}
															 width={'96px'} clickEvent={()=>setDataModal({menu:'facility'})}/>
								{/*{facilityInfo?.length !== nodeDataArray.length &&*/}
								{/*	<MainButtons name={'설비 가져오기'} width={'110px'} clickEvent={()=>setNodeDataArray(facilityInfo)}/>*/}
								{/*}*/}
								{processNode !== undefined &&
									<MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
															 disabled={window.location.pathname.includes('fas')? buttonAuthValid('delete','12') : buttonAuthValid('delete','67')}
															 clickEvent={()=> {
										setDataModal({menu:'select',message:'공정을 삭제 하시겠습니까?'})
									}}/>}
								<MainButtons name={'저장'} marginDis={true} buttonType={'cta'} width={'56px'} clickEvent={processRegisterRes}/>
							</div>
						</div>
						<div style={{display:'flex',justifyContent:'flex-end',gap:'6px',marginTop:'10px',height: '590px'}}>
							{refreshNode && nodeDataArray.length !== 0 ?
								<ReactDiagram initDiagram={initDiagram} nodeDataArray={nodeDataArray} linkDataArray={linkDataArray} ref={diagramRef}
															skipsDiagramUpdate={skipsDiagramUpdate} onModelChange={handleModelChange}
															divClassName={'process_comp'} />
								:
								<div style={{display:'flex',justifyContent:'center',width:'968px',border:'1px solid #D7DBEC',borderRadius:'4px'}}>
									<NoData height={350}>{'* 설비 가져오기 버튼을 클릭하여 설비를 선택해 주세요.'}</NoData>
								</div>
							}
							<NodeFacilityComponent thumbText={clickNode?.profileImageUrl === '-'? '* 설비를 선택해 주세요.' :
								!clickNode?.profileImageUrl? '이미지가 없습니다.' : ''}>
								<div className={'facility_thumb'}
									style={{backgroundImage:`url('${clickNode?.profileImageUrl}')`,
										backgroundSize:'cover', backgroundRepeat:'no-repeat',backgroundPosition:'center'}}
								/>
								<div className={'facility_info'}>
									<div>{'기종'}</div>
									{validationValue(clickNode.type)}
									<div>{'제조번호'}</div>
									{validationValue(clickNode.manufacturingNumber,'없음')}
									<div>{'제조사'}</div>
									{validationValue(clickNode.maker)}
									<div>{'설비명'}</div>
									{validationValue(clickNode.facilityName)}
									<div>{'ICT번호'}</div>
									{validationValue(clickNode.ictNumber,'할당 안됨')}
									<div>{'HW'}</div>
									{validationValue(clickNode.hw)}
								</div>
							</NodeFacilityComponent>
						</div>
					</ContentFrame>
				</ProcessComponent>
			</SectionFrame>
		</React.Fragment>

	);
};

export default ProcessRegister;

const ProcessComponent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 21px 16px;
	canvas { outline: none; }
	.process_comp{
		width: 968px;
		border: 1px solid #D7DBEC;
		border-radius: 4px;
		height: 590px;
		box-sizing: border-box;
	}
`

const NodeFacilityComponent = styled.div<{thumbText?: string}>`
	width: 248px;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: #F5F6FA;
	padding: 24px 16px 0;
	.facility_thumb{
		border: 1px solid #D7DBEC;
		border-radius: 4px;
		width: 100%;
		height: 104px;
		position: relative;

		&::before{
			position: absolute;
			content: '${props => props.thumbText}';
			width: 100%;
			height: 100%;
			opacity: 0.5;
			font-size: 12px;
			text-align: center;
			line-height: 104px;
		}
	}
	
	.facility_info{
		display: grid;
		grid-template-columns: 72px 128px;
		gap: 16px;
		margin-top: 16px;
		font-size: 12px;
		font-weight: 800;
		&>div:nth-child(2n){
			text-align: right;
			font-weight: 400;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
`