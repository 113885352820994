import React , {useState , useEffect} from 'react';
import styled from 'styled-components';
import {
  ContentFrame , FacebookCircularProgress ,
  GridTableComponent , GridTableSelect , listResultMessage ,
  validationValue
} from "../../style/StyleComponent";
import MenuTitle from "../title/MenuTitle";
import SearchBar from "../input/SearchBar";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {FacilityDataType , Modal_View_Type} from "../../types/Fundamental";
import CheckBox from "../input/CheckBox";
import {useLocation} from "react-router-dom";
import ConfirmPopup from "../modal/ConfirmPopup";
import SelectConfirmModal from "../modal/SelectConfirmModal";
import {useTranslation} from "react-i18next";
import exchangeUp from "../../asset/image/arrow_14784.svg";
import exchangeDown from "../../asset/image/arrow_14789.svg";
import MainButtons from "../button/MainButtons";
import SearchFilterButton from "../button/SearchFilterButton";
import {
  loadingRemove ,
  initModal , dataValid
} from "../../function/function";
import CompanySelectModal from "../modal/CompanySelectModal";

interface Props {
  setContainer: ( i: number ) => void
  companyName?: string
  companyId: string
}

const FacilityCheckList: React.FunctionComponent<Props> = ( {companyName , companyId} ) => {
  const [companyData , setCompanyData] = React.useState<any>( {} )
  const [facilityData , setFacilityData] = React.useState<Array<FacilityDataType>>( [] )
  const [selectedFacility , setSelectedFacility] = React.useState<any[]>( [] )
  const [, setExistingNumber] = React.useState<any[]>( [] )
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [searchInput , setSearchInput] = React.useState<{ input: string, value: string }>( {input: '' , value: ''} )
  const [currentSelected , setCurrentSelected] = React.useState<string>( '' )
  const [selectedPrev , setSelectedPrev] = React.useState<any[]>( [] )
  const [loading , setLoading] = useState<boolean>( false )
  // const [totalPage, setTotalPage] = useState<number>(1)
  const [hoverIndex , setHoverIndex] = useState<number>( - 1 )
  const {t}: any = useTranslation();
  const location = useLocation()
  const {pathname} = location
  const scrollRef = React.useRef<HTMLDivElement>( null )
  //등록할 전체 설비 데이터
  const totalFilterFacility = facilityData.filter( ( v ) => ( !selectedFacility.map( ( v ) => v.facilityCode ).includes( v.facilityCode ) ) )
  // const totalFacilityRes = async () => {
  // 	try {
  // 		const response = await client.get(`/facility/admin/row/search/${page}/${10}/@/${dataValid(searchInput.value,'@')}`,{
  // 			headers:{Authorization}
  // 		})
  // 		const data = response.data.data
  // 		setTotalPage(data.total_page)
  //
  // 		if(response.data.status !== 200){
  // 			setFacilityData([])
  // 		}else{
  // 			if(data.total_page > 1 && page > 1){
  // 				setFacilityData((prev)=> prev.concat(data.row))
  // 			}else{
  // 				setFacilityData(data.row)
  // 			}
  // 		}
  // 		if((data.total_page > 1 && page === 1)){
  // 			setPage(2)
  // 		}
  // 	}catch ( e ) {
  // 		console.log(e)
  // 	}
  // 	finally {
  // 		loadingRemove()
  // 	}
  // }
  // const selectCompanyFacilityRes = async () => {
  // 	try {
  // 		const response = await client.get(`/facility/admin/row/search/${page}/${10}/@/${dataValid(searchInput.value,'@')}/${companyData.companyCode}`,{
  // 			headers:{Authorization}
  // 		})
  // 		const data = response.data.data
  // 		setTotalPage(data.total_page)
  // 		if(response.data.status !== 200){
  // 			setFacilityData([])
  // 		}else{
  // 			if(data.total_page > 1 && page > 1){
  // 				setFacilityData((prev)=> prev.concat(data.row))
  // 			}else{
  // 				setFacilityData(data.row)
  // 			}
  // 		}
  // 		if(data.total_page > 1 && page === 1){
  // 			setPage(2)
  // 		}
  // 	}catch ( e ) {
  // 		console.log(e)
  // 	}
  // 	finally {
  // 		loadingRemove()
  // 	}
  // }
  const totalFacilityRes = async () => {
    setLoading( true )
    try {
      const response = await client.get( `/facility/infinite/facility/list/@/프레스/${dataValid( searchInput.value , '@' )}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      if( response.data.status !== 200 ){
        setFacilityData( [] )
      } else{
        setFacilityData( data.row )
      }
    } catch ( e ) {
      console.log( e )
    } finally {
      loadingRemove()
      setLoading( false )
    }
  }
  const selectCompanyFacilityRes = async () => {
    setLoading( true )
    try {
      const response = await client.get( `/facility/infinite/facility/list/${companyData.companyCode}/프레스/${dataValid( searchInput.value , '@' )}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      if( response.data.status !== 200 ){
        setFacilityData( [] )
      } else{
        // if(data.total_page > 1 && page > 1){
        // 	setFacilityData((prev)=> prev.concat(data.row))
        // }else{
        setFacilityData( data.row )
        // }
      }
      // if(data.total_page > 1 && page === 1){
      // 	setPage(2)
      // }
    } catch ( e ) {
      console.log( e )
    } finally {
      loadingRemove()
      setLoading( false )
    }
  }
  //등록된 계열사 설비 api 목록
  const affiliateListRes = async () => {
    try {
      const response = await client.get( `/facility/affiliate/facility/list/${location.pathname.split( '/' ).pop()}/@/@` , {
        headers: {Authorization}
      } )
      setSelectedFacility( response.data.data.row )
      setExistingNumber( response.data.data.row )
      setSelectedPrev( response.data.data.row )
    } catch ( e ) {
      console.log( e )
    }
  }
  React.useEffect( () => {
    affiliateListRes().then()
  } , [] )
  const affiliateRes = async () => {
    try {
      const response = await client.post( '/facility/affiliate/facility/register' , {
        facilityCodeSet: selectedFacility.map( ( v: any ) => ( v.facilityCode ) ) ,
        id: pathname.split( '/' ).pop()
      } , {headers: {Authorization}} )
      console.log( response.data )
      setDataModal( {menu: 'confirm' , message: t( '등록 완료 되었습니다.' )} )
      loadingRemove()
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: t( e.message )} )
      loadingRemove()
    }
  }
  React.useEffect( () => {
    if( !companyData.companyCode ){
      totalFacilityRes().then()
    } else{
      selectCompanyFacilityRes().then()
    }
  } , [companyData.companyCode , searchInput.value] )
  const onCheckedFacility = ( checked: any , item: any ) => {
    if( checked ){
      // @ts-ignore
      setSelectedFacility( [...selectedFacility , item] )
      // if(totalFilterFacility?.length <= 11 && totalPage > page){
      // 	setPage((prev)=> prev+1)
      // }
    } else{
      setSelectedFacility( selectedFacility.filter( el => el !== item ) );
    }
  };
  // useEffect(()=>{
  // 	if(totalFilterFacility?.length > 11 && page < totalPage){
  // 		setPage(prev => prev+1)
  // 	}
  // 	loadingRemove(200)
  // },[totalFilterFacility])
  const companyDataSelect = ( data: any ) => {
    setDataModal( initModal )
    setSearchInput( {input: '' , value: ''} )
    setCompanyData( data )
  }
  const saveValidate = () => {
    if( selectedPrev !== selectedFacility ){
      setDataModal( {menu: 'select' , message: `변경된 사항이 있습니다.\n저장 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'confirm' , message: '변경된 내용이 없습니다.'} )
    }
  }
  React.useEffect( () => {
    scrollRef?.current?.scrollTo( {top: scrollRef?.current?.scrollHeight} )
  } , [selectedFacility] )
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  return (
    <div style={{display: 'flex' , justifyContent: 'space-between'}}>
      {dataModal.menu === 'company' &&
          <CompanySelectModal receivedCompany={companyDataSelect}
                              closeButton={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message}
                                                     linkedText={{
                                                       title: `${dataModal.message === '등록 완료 되었습니다.' ? '* 문구 클릭시 상세보기 페이지로 이동 됩니다.' : ''}` ,
                                                       url: `/admin/fas/partners/${companyId}`
                                                     }}
                                                     clickEvent={() => {
                                                       // affiliateListRes().then();
                                                       window.location.reload();
                                                       setDataModal( initModal )
                                                     }}/>}
      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              clickEvent={() => {
                                affiliateRes().then();
                                setDataModal( initModal );
                              }}
                              noticeText={'* "예" 선택 시 저장 됩니다.'}
                              cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => {
                                window.location.href = `/admin/fas/partners/${companyId}`;
                                setDataModal( initModal )
                              }}
          />
      }

      <ContentFrame width={'600px'} justify={'space-between'}>
        <div>
          <div style={{display: 'flex' , alignItems: "center" , justifyContent: 'space-between'}}>
            <MenuTitle title={'전체 설비 리스트'} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex'}}>
              <SearchFilterButton name={!companyData.companyCode ? '업체 목록 보기' : companyData.companyName}
                                  disabled={loading}
                                  clickEvent={() => {setDataModal( {menu: 'company'} )}}
                                  cancelEvent={() => {
                                    setCompanyData( {} )
                                    setSearchInput( {input: '' , value: ''} )
                                  }} closeButton={!companyData.companyCode}/>
              <SearchBar disabled={loading}
                         clickEvent={() => {
                           setSearchInput( ( prev ) => ( {...prev , value: prev.input} ) );
                         }}
                         value={searchInput.input}
                         keyFunction={( e ) => setSearchInput( ( prev ) => ( {
                           ...prev ,
                           input: e.target.value.trimStart()
                         } ) )} margin={'0 0 0 8px'}/>
            </div>
          </div>

          {/*<InfiniteScroll scrollableTarget={'grid-scroll'} next={()=>setPage((prev)=> prev+1)}*/}
          {/*								loader={<div/>} hasMore={totalPage > page}*/}
          {/*								dataLength={dataValid(totalFilterFacility?.length ,0)}>*/}
          <GridTableComponent tempCol={'40px 56px 160px 88px 88px 128px 1fr'}
                              height={586}
                              scrollWidth={780} id={'grid-scroll'}>
            <div className={'grid-title-unchecked'}>
              {['NO.' , '기종' , '제조번호' , '제조사' , 'ICT번호' , '업체명' , '업체코드'].map( ( v ) => (
                <div key={v}>{t( v )}</div>
              ) )}
            </div>
            {
              loading ?
                <LoadingComponent style={{height: '500px'}}>
                  <FacebookCircularProgress/>
                </LoadingComponent>
                :
                facilityData.length === 0 ?
                  listResultMessage( true , '검색 결과가 없습니다.' , {admin: 487 , user: 487 , width: 540} )
                  :
                  totalFilterFacility?.length === 0 ?
                    listResultMessage( true , '모든 설비가 선택 되었습니다.' , {admin: 487 , user: 487 , width: 540} )
                    :
                    totalFilterFacility.map( ( v , i ) => (
                      <label>
                        <GridTableSelect className={'grid-list-unchecked-no_icon'}
                          //@ts-ignore
                                         onClick={() => setCurrentSelected( v.facilityCode )}>
                          <CheckBox clickEvent={( e: any ) => {onCheckedFacility( e.target.checked , v )}}
                                    checkStatus={selectedFacility.map( ( v ) => v.facilityCode ).includes( v.facilityCode )}
                                    name={'facilityCode'} hideCheckBox={true}/>
                          {/*<input type="checkbox" checked={receiveData.includes(v)}*/}
                          {/*/!*       onClick={(e:any)=>onCheckedFacility(e.target.checked, v)}/>*!/*/}
                          <div>{i + 1}</div>
                          <div>{v.type}</div>
                          {validationValue( v.manufacturingNumber )}
                          {validationValue( v.maker )}
                          <div>{!v.ictNumber ? '할당 안됨' : v.ictNumber === '00000' ? '할당 안됨' : v.ictNumber}</div>
                          <div>{v.companyName}</div>
                          <div>{v.facilityCode?.split( '_' )[0]}</div>
                        </GridTableSelect>
                      </label>
                    ) )
            }


          </GridTableComponent>
          {/*</InfiniteScroll>*/}
        </div>
        {/*<PaginationList page={page} setPage={setPage} counts={totalPage}*/}
        {/*                pageLimit={5} limit={9} setBlockNum={setPageBlock} blockNum={pageBlock} />*/}
        {/*<PaginationCus page={1} count={1} onchange={()=>''}/>*/}

      </ContentFrame>
      <div style={{
        display: 'grid' ,
        gridTemplateRows: '24px 24px' ,
        gap: '8px' ,
        alignSelf: 'center' ,
        justifyContent: 'center'
      }}>
        <img src={exchangeUp} alt="" height={24} style={{display: 'flex' , alignSelf: 'center'}}/>
        <img src={exchangeDown} alt="" height={24} style={{display: 'flex' , alignSelf: 'center'}}/>
      </div>

      <ContentFrame width={'600px'}>
        <div style={{marginBottom: '16px' , display: 'flex' , justifyContent: 'space-between'}}>
          <MenuTitle title={`등록 설비 리스트(${companyName})`} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'목록 초기화'} clickEvent={() => {setSelectedFacility( [] )}}/>
            <MainButtons name={'취소'} width={'56px'} clickEvent={() => {
              setDataModal( {
                              menu: 'cancel' ,
                              message: '등록 중인 설비가 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                            } )
            }}/>
            <MainButtons buttonType={'cta'} width={'56px'} name={'저장'}
                         clickEvent={saveValidate} marginDis={true}/>
          </div>
        </div>

        <GridTableComponent tempCol={'40px 56px 160px 88px 88px 128px 1fr'} scrollWidth={780} height={538}
                            ref={scrollRef}>
          <div className={'grid-title-unchecked'}>
            {['NO.' , '기종' , '제조번호' , '제조사' , 'ICT번호' , '업체명' , '업체코드'].map( ( v ) => (
              <div key={v}>{t( v )}</div>
            ) )}
          </div>
          {
            selectedFacility?.length === 0 ?
              listResultMessage( true , '등록된 설비가 없습니다.' , {admin: 480 , user: 480 , width: 540} )
              :
              selectedFacility?.map( ( v: any , i ) => (
                <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                 onMouseEnter={() => setHoverIndex( i )}
                                 onMouseLeave={() => setHoverIndex( - 1 )}
                                 selected={currentSelected === v.facilityCode}>
                  <HoverDeleteButton>
                    {i === hoverIndex ?
                      <div className={'facility_cancel'}
                           onClick={() => setSelectedFacility( selectedFacility.filter( el => el !== v ) )}>
                        <div>
                          {'╋'}
                        </div>
                      </div>
                      :
                      <div className={'facility_index'}>{i + 1}</div>
                    }
                  </HoverDeleteButton>
                  {validationValue( v.type )}
                  {validationValue( v.manufacturingNumber , '없음' )}
                  {validationValue( v.maker , '없음' )}
                  <div>{v.ictNumber === '' ? '할당 안됨' : v.ictNumber === '00000' ? '할당 안됨' : v.ictNumber}</div>
                  {validationValue( v.companyName )}
                  {validationValue( v.facilityCode.split( '_' )[0] )}
                </GridTableSelect> ) )
          }

        </GridTableComponent>
      </ContentFrame>
    </div>
  );
};
export default FacilityCheckList;
const HoverDeleteButton = styled.div`
  position: relative;

  .facility_index {
    opacity: 1;
  }

  .facility_cancel {
    width: 24px;
    height: 24px;
    border: 1px solid #d7dbec;
    border-radius: 4px;
    color: #5a607f;
    text-align: center;
    line-height: 24px;

    cursor: pointer;
    background-color: #fff;

    &:hover {
      background-color: #5a607f;
      color: #fff;
    }

    & > div {
      transform: rotateZ(45deg);
    }
  }
`
const LoadingComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;

  & > div:nth-child(1) {
    transform: translateX(0px);
  }

  svg {
    path.recharts-tooltip-cursor {
      stroke: transparent;
      stroke-width: 32px;
      stroke-opacity: 0.1;
    }
  }
`