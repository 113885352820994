import React from 'react';
import styled from "styled-components";
import {ListType} from "../../types/Fundamental";


const CheckBox = ({ clickEvent, checkStatus, name, hideCheckBox}:ListType) => {

	return (
		<BaseComponent style={{display:`${hideCheckBox? 'none' : 'block'}`}}>
			<input type="checkbox" checked={checkStatus} onChange={clickEvent} name={name}/>
		</BaseComponent>
	);
};

export default CheckBox;

const BaseComponent = styled.div`
    color: #fff;
    font-weight: 400;
    text-align: center;
    user-select: none;
    box-sizing: border-box;
		&>input{
			width: 18px;
			height: 18px;
			cursor: pointer;
			vertical-align: middle;
		}
	`