import React from 'react';
import styled from "styled-components";

interface Props {
	selected: boolean
	radioText: string
	clickEvent:()=>void
}

const RadioComponent:React.FunctionComponent<Props> = ({selected, radioText, clickEvent}) => {

	return (
		<RadioBase onClick={clickEvent}>
			<div className={'circle_radio'}>
				<div style={{backgroundColor:`${selected? '#0058ff' : '#fff'}`}} className={'inner_circle'}/>
			</div>
			<div style={{fontSize:'14px', marginLeft:'8px'}}>{radioText}</div>
		</RadioBase>
	);
};

export default RadioComponent;

const RadioBase = styled.label`
	display: flex;
	.circle_radio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 14px;
    height: 14px;
    border: 1px solid #D7DBEC;
    border-radius: 50%;
		cursor: pointer;
		&>div{
      width: 8px;
      height: 8px;
      box-sizing: border-box;
      border-radius: 50%;
      align-self: center;
		}
  }
	
`