import React,{useState, useEffect, useRef} from 'react';
import ModalBase from "./ModalBase";
import CheckDropFilter from "../check/CheckDropFilter";
import styled from "styled-components";
import {
  listResultMessage ,
  modalTitleBase , tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import {CompanyDataType} from "../../types/Fundamental";
import SearchBar from "../input/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import {dataValid , listLoadingCircle , loadingRemove} from "../../function/function";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {useRecoilState , useRecoilValue , useResetRecoilState} from "recoil";
import {
  companyAuthorities ,
  companySolutionFilter ,
  persistCompanyData ,
  persistProductData ,
} from "../../common/StateManage";
import {useTranslation} from "react-i18next";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";


interface Company_Modal_Types {
  receivedCompany:(data:any)=>void
  closeButton:()=>void
  closeEvent?:()=>void
  inputText?: string
  idType?: boolean
  mida?: boolean
  affiliate?:boolean
}

const CompanySelectModal:React.FunctionComponent<Company_Modal_Types> = ({affiliate,receivedCompany, closeButton,closeEvent, inputText, idType,mida}) => {
  const [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [searchInput, setSearchInput] = useState<string>(dataValid(inputText,''))
  const [companyList, setCompanyList] = useState<CompanyDataType[]>([])
  const [selectedCompany, setSelectedCompany] = useState<any>({})
  const [, setAuthorities] = useRecoilState<string>(companyAuthorities)

  // const [filterOptions, setFilterOptions] = useState([
  //                                                      { id: 1, label: '전체', checked: true },
  //                                                      { id: 2, label: '마이다 프레스 납풉업체', checked: false },
  //                                                    ]);

  const persistCompany = useRecoilValue(persistCompanyData)
  const resetProduct = useResetRecoilState(persistProductData)
  const solutionValue = useRecoilValue(companySolutionFilter)
  const [midaState, setMidaState] = useState<string>('@')
  const {t}:any = useTranslation()

  const scrollRef = useRef<any>(null)
  const scrollToTop = ()=> {scrollRef.current.scrollTop = 0}

  let requestCount = 1
  const solutionCheck = solutionValue === 'MES'? '@' : solutionValue === 'P-MS'? '@': solutionValue
  const companyListRes = async () => {
    requestCount++
    try {
      const response =
        idType ?
          await client.post(`/company/all/list`,{
            page:page ,
            size:10,
            type:midaState === 'mida' || 'affiliate' ? midaState : (solutionCheck === 'FAS' ? '@' : solutionCheck),keyword:'@'},{headers:{Authorization}})
          :
          await client.get( `/company/list/${page}/${10}/${solutionCheck}`,{headers:{Authorization}})

      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setCompanyList((prev:any)=> prev.concat(data.row))
      }else{
        setCompanyList(data.row)
      }
    }catch ( e ) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }

  const companyListSearchRes = async () => {
    requestCount++
    try {
      const response = await client.post(idType? `/company/all/list` : `/company/search`,{
        page:page,
        size:10,
        type:solutionCheck === 'FAS' ? '@' : solutionCheck,
        keyword:searchInput
      },{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setCompanyList((prev:any)=> prev.concat(data.row))
      }else{
        setCompanyList(data.row)
      }
    }catch ( e ) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }

  useEffect(()=>{
    if(requestCount === 1){
      listLoadingCircle()
      if(searchInput === ''){
        companyListRes().then(() => loadingRemove())
      }else{
        companyListSearchRes().then(() => loadingRemove())
      }
    }
  },[page, midaState])

  const searchFunction = () => {
    setCompanyList([])
    setPage(1)
    setTotalPage(1)
    scrollToTop()
    if(searchInput === ''){
      companyListRes().then()
    }else{
      companyListSearchRes().then()
    }
  }

  const addressCut = (address:string) => {
    if(address?.includes('충청북도')){
      return '충북'
    }
    if (address?.includes('충청남도')) {
      return '충남'
    }
    if (address?.includes('전라북도')) {
      return '전북'
    }
    if (address?.includes('전라남도')) {
      return '전남'
    }
    if (address?.includes('경상북도')) {
      return '경북'
    }
    if (address?.includes('경상남도')) {
      return '경남'
    } else {
      return address?.slice(0,2)
    }
  }
  const nameCut = (name:string) => {
    return name?.split(' ')[0]
  }
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle: t('업체명 검색')},)}
          <div className={'modal_content'}>
            <div>
              {mida?
                <div style={{display:'flex'}}>
                  <CheckDropFilter checkedData={(v)=>{
                    setMidaState(v)
                    setPage(1)
                  }} affiliateViewType={affiliate}/>
                  <SearchBar widthCus={'374px'} minWidth={'300px'} keyPressDisable={true}
                             keyFunction={(e)=>{setSearchInput(e.target.value.trimStart())}} value={searchInput}
                             clickEvent={searchFunction} focus={true}/>

                </div>
                :
                <SearchBar widthCus={'467px'} keyPressDisable={true} keyFunction={(e)=>{setSearchInput(e.target.value.trimStart())}} value={searchInput}
                            clickEvent={searchFunction} focus={true}/>
              }
              <InfiniteScroll scrollableTarget={'grid_scroll'}
                              hasMore={totalPage > page}
                              dataLength={dataValid(companyList?.length,0)}
                              next={()=>setPage((prev:any)=>prev+1)} loader={<div/>}>
                <GridTableComponent id={'grid_scroll'} tempCol={'160px 80px 128px 128px'} ref={scrollRef}
                                    height={288} scrollWidth={494}
                                    scrollX_Hide={true}>
                  <div className={'grid-title-unchecked'}>
                    {['업체명','지역','대표','업체코드'].map((v)=>(<div key={v}>{t(v)}</div>))}
                  </div>
                  {companyList?.length === 0?
                    listResultMessage(true,'검색 결과가 없습니다.',{width:374, admin:288, user:288})
                    :
                    companyList?.map((v)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       selected={(v.companyCode === (!selectedCompany.companyCode? persistCompany.companyCode : selectedCompany.companyCode))}
                                       key={v.companyCode} onMouseDown={()=>setSelectedCompany(v)}
                                       onDoubleClick={()=>{receivedCompany(selectedCompany);
                                         closeButton();
                                         setAuthorities(selectedCompany.authorities)
                                         resetProduct()}}>
                        {/*{validationValue(v.companyName)}*/}
                        {tooltipModule(v.companyName)}
                        {validationValue(addressCut(v.address))}
                        {validationValue(nameCut(v.president))}
                        {tooltipModule(v.companyCode)}
                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
              </InfiniteScroll>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MainButtons name={'취소'} clickEvent={()=>{
                if(closeEvent){
                  closeEvent()
                }else{
                  closeButton()
                }
              }} margin={'0 12px'} width={'144px'}/>
              <MainButtons name={'확인'} buttonType={'cta'} margin={'0 12px'} width={'144px'}
                           disabled={!selectedCompany.companyCode}
                           clickEvent={()=>{
                             receivedCompany(selectedCompany); closeButton();
                             setAuthorities(selectedCompany.authorities)
                             resetProduct()}}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default CompanySelectModal;

const BaseComponent = styled.div`
  position: absolute;
  width: 540px;
  height: 512px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: space-between;
  
  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
  
`
