import React , {useEffect , useState} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../components/title/MenuTitle";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import {ContentFrame , SectionFrame} from "../../../../style/StyleComponent";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {useParams} from "react-router-dom";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import SingleImageUploadBox from "../../../../components/input/SingleImageUploadBox";
import ModalBase from "../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../components/button/MainButtons";
import {
  buttonAuthValid ,
  dataAccessLoading , dataValid ,
  initModal , listLoadingCircle ,
  loadingRemove , phoneNumberFormat
} from "../../../../function/function";
import {Modal_View_Type} from "../../../../types/Fundamental";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import {
  emailReg ,
  onEmailValid ,
  passwordReg ,
  phoneReg, specialCharExcludeReg
} from "../../../../ValidationCheck";
import ToastPopup from "../../../../components/modal/ToastPopup";
import PasswordChangeModal from "../../../../components/modal/PasswordChangeModal";

export type Partner_Type = {
  adminName: string
  companyName: string
  president: string
  address: string
  detailAddress?: string
  phoneNumber: string
  email: string
  id: string
  password?: string
  passwordCheck?: string
  notice?: string
  authority: string
  imageUrl?: string
  logoImageUrl?: string
  latitude?: number | string
  longitude?: number | string
}
const PartnerModify = () => {
  const {t}: any = useTranslation();
  const locationID = useParams()
  // const [pwCheckState, setPwCheckState] = useState({text:t('* 비밀번호 확인을 해주세요.'), color:'#17172580'})
  const [pwCheckNormal ,] = useState<boolean>(false)
  const [emailCheck , setEmailCheck] = useState({text: t('* 올바른 형식의 이메일 입니다.') , color: '#0058FF'})
  const [dataModal , setDataModal] = useState<Modal_View_Type>(initModal)
  const [imageKeyState , setImageKeyState] = useState<string | null>(null)
  const [logoImageUrl , setLogoImageUrl] = useState<string | null>(null)
  const [openPostCode , setOpenPostCode] = useState<boolean>(false);
  const [partnerModify , setPartnerModify] = useState<Partner_Type | any>({});
  const [partnerAuth,setPartnerAuth] = React.useState<string>('');
  const [adminPassword , setAdminPassword] = React.useState<string>('');
  const handleClickButton = (radioButtonName: string) => {
    setPartnerModify((prev: any) => ( {...prev , authority: radioButtonName} ))
  }
  const onEditChange = (e: any , trim?: boolean) => {
    const {name , value} = e.target
    setPartnerModify({...partnerModify , [name]: trim ? value.trim() : value})
  }
  const noSpecialCharsPattern = /^[^\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]*$/

  const partnerUpdateFunction = async () => {
    if(!partnerModify.companyName){
      setDataModal({menu: 'confirm' , message: '지사/협력사 명을 입력해 주세요.'})
    } else if(specialCharExcludeReg.test(partnerModify.companyName)){
      setDataModal({menu: 'confirm' , message: '지사/협력사 명에 특수문자를 포함할 수 없습니다.'})
    }
    else if(!partnerModify.president){
      setDataModal({menu: 'confirm' , message: '대표자 명을 입력해 주세요.'})
    }
    else if(!partnerModify.phoneNumber){
      setDataModal({menu: 'confirm' , message: '전화번호를 입력해 주세요.'})
    }
    else if(!phoneReg.test(partnerModify.phoneNumber.trim())){
      setDataModal({menu: 'confirm' , message: '전화번호 형식이 맞지 않습니다.'})
    }
    else if(!partnerModify.address){
      setDataModal({menu: 'confirm' , message: '주소를 입력해 주세요.'})
    }
    else if(!partnerModify.email){
      setDataModal({menu: 'confirm' , message: '이메일을 입력해 주세요.'})
    }
    else if(!emailReg.test(partnerModify.email)){
      setDataModal({menu: 'confirm' , message: '이메일 형식이 맞지 않습니다.'})
    }
    else if(!passwordReg.test(dataValid(partnerModify?.password , '')) && partnerModify.password){
      setDataModal({menu: 'confirm' , message: '비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
    }
    else if(partnerModify.password && !partnerModify.passwordCheck){
      setDataModal({menu: 'confirm' , message: ' 비밀번호를 한번 더 입력해 주세요.'})
    }
    else if(partnerModify.password && !pwCheckNormal){
      setDataModal({menu: 'confirm' , message: ' 비밀번호가 일치한지 확인 해주세요.'})
    }
    else if(partnerModify.password && ( partnerModify.password !== partnerModify.passwordCheck )){
      setDataModal({menu: 'confirm' , message: ' 비밀번호가 일치하지 않습니다.'})
    }
    else{
      //password 입력시, password Key 가 생기면서 빈문자열 입력시, 등록 넘어가지 않는 이유로 추가
      if(!partnerModify.password){
        delete partnerModify.password
      }
      if(!partnerModify.passwordCheck){
        delete partnerModify.passwordCheck
      }
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post('/admin/update' , {
          ...partnerModify ,
          phoneNumber: partnerModify.phoneNumber.trim() ,
          adminName: partnerModify.companyName ,
          image: imageKeyState ?? null ,
          logoImage: logoImageUrl ?? null ,
          // id:partnerId
        } , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setDataModal({menu: 'success' , message: t('수정 되었습니다.')})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'confirm' , message: e.message})
        loadingRemove(300)
      }
      // finally {
      //     setTimeout(()=>{
      //         setDataModal(initModal)
      //         window.location.href = `/admin/fas/partners`
      //     },3000)
      // }
    }
  }
  // const onCheckPassword = (e: { target: { name: string; value: string } }) => {
  //
  //     const {name, value} = e.target
  //     setPartnerModify({
  //                          ...partnerModify,
  //                          [name]: value
  //                      })
  //     onPwCheckValid(value, setPwCheckState)
  // }
  const onCheckEmail = (e: {
    target: {
      name: string,
      value: string
    }
  }) => {
    const {name , value} = e.target;
    setPartnerModify({
                       ...partnerModify ,
                       [name]: value
                     })
    onEmailValid(value , setEmailCheck)
  }
  const partnerDeleteFunction = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post('/admin/delete' , {
        id: locationID.id
      } , {headers: {Authorization}})
      if(response.data.status !== 200){
        setDataModal({menu: 'confirm' , message: response.data.message})
        loadingRemove()
      } else{
        setTimeout(() => {
          loadingRemove()
          setDataModal({menu: 'success' , message: t('삭제 되었습니다.')})
        } , 500)
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'confirm' , message: e.message})
      loadingRemove()
    }
    // finally {
    //     setTimeout(()=>{
    //         setDataModal(initModal)
    //         window.location.href = `/admin/fas/partners`
    //     },3000)
    // }
  }
  const onDeleteFunc = () => {
    if(partnerModify.companyCode === window.localStorage.getItem('companyCode')){
      setDataModal({menu: 'confirm' , message: t('접속중인 아이디 입니다.')})
    } else{
      setDataModal({menu: 'select' , message: t('정말 삭제 하시겠습니까?')})
    }
  }
  const addressHandle = {
    clickBtn: () => setOpenPostCode(current => !current) ,
    selectAddress: (data: any) => {
      partnerModify.address = data.address + `${!data.buildingName ? '' : `(${data.buildingName})`}`
      setPartnerModify({...partnerModify , detailAddress: ''})
      setOpenPostCode(false);
    } ,
  }
  const AddressStyle: React.CSSProperties | undefined = {
    display: 'block' ,
    width: 500 ,
    height: 600 ,
    position: 'absolute' ,
    boxShadow: '0 1px 7px #E6E9F4' ,
    borderRadius: '8px' ,
    backgroundColor: '#fff' ,
    padding: "7px" ,
    zIndex: 100 ,
  }
  const partnerDetail = async () => {
    try {
      listLoadingCircle()
      const response = await client.post(`/admin/detail` ,
                                         {id: locationID.id} , {headers: {Authorization}})
      const data = response.data.data
      setPartnerModify({...data , authority: data.authority})
      setPartnerAuth(data.authority)
      // setRadioStatus(data.authority)
      if(window.localStorage.getItem('companyID') === data.id){
        window.localStorage.setItem('profile' , data.imageUrl)
        window.localStorage.setItem('userName' , data.president)
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const adminPasswordModifyRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post('/admin/update' , {
        ...partnerModify ,
        adminName: partnerModify.companyName ,
        image: imageKeyState ?? null ,
        logoImage: logoImageUrl ?? null ,
        password: adminPassword
      } , {headers: {Authorization}})
      const data = response.data
      if(data.message !== 'success'){
        setDataModal({menu: 'confirm' , message: data.message});
        loadingRemove()
      } else{
        setTimeout(() => {
          loadingRemove()
          setDataModal({menu: 'success' , message: t('비밀번호가 변경 되었습니다.')});
        } , 500)
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  useEffect(() => {
    partnerDetail().then()
  } , [locationID])
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(dataModal.menu === ''){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload);
    };
  } , [dataModal.menu])
  // const [emailStatus , setEmailStatus] = React.useState<string>('');
  // const [password , setPassword] = React.useState<boolean>(false);
  // //이메일보내는 테스트
  // const sendEmailRes = async () => {
  //   dataAccessLoading();
  //   setDataModal(initModal)
  //   // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
  //   setTimeout(() => {
  //     setPassword(true)
  //     loadingRemove();
  //     // 작업 완료 시 호출할 콜백 함수
  //   } , 1000);
  // };


  const {kakao}: any = window;
  const LocationPosition = () => {
    let geocoder = new kakao.maps.services.Geocoder();
    geocoder.addressSearch(partnerModify.address , function (result: any , status: any) {
      if(status === kakao.maps.services.Status.OK){
        let coords = new kakao.maps.LatLng(result[0].y , result[0].x);
        Object.assign(partnerModify , {latitude: coords.getLat() , longitude: coords.getLng()})
        // setGetPosition((prevState)=>(prevState.concat({lat:coords.getLat(),lng:coords.getLng()})))
      }
    })
  }
  useEffect(() => {
    LocationPosition()
  } , [partnerModify.address])
  console.log(partnerModify)

  return (
    <SectionFrame>
      <MenuTitle title={'지사/협력사 수정'} unitCase={'MenuTitle'}/>
      <ContentFrame>
        {dataModal.menu === 'success' ?
          <ToastPopup text={t(dataModal.message)} state={'success'} timeSet={800}
                      closeEvent={() => {
                        setDataModal(initModal)
                        if(dataModal.message === t('삭제 되었습니다.')){
                          window.location.href = `/admin/fas/partners`
                        }
                        if(dataModal.message === t('수정 되었습니다.')){
                          window.location.href = `/admin/fas/partners/${partnerModify.id}`
                        }
                      }}/>
          : dataModal.menu === 'error' ?
            <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={() => {setDataModal(initModal)}}/>
            : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}
                                                      closeEvent={() => {setDataModal(initModal)}}/> : null}

        {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={partnerModify} viewType={true}
                               receivePassword={(data) => {setAdminPassword(data)}}
                               clickEvent={adminPasswordModifyRes}
                               closeClick={() => {setDataModal(initModal)}}/>
        }

        {/*{dataModal.menu === 'findPw' &&*/}
        {/*  <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
        {/*}*/}
        {/*{password &&*/}
        {/*  <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
        {/*}*/}


        {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message}
                        clickEvent={() => {
                          setDataModal(initModal)
                          if(dataModal.message === t('삭제 되었습니다.')){
                            window.location.href = `/admin/fas/partners`
                          }
                          if(dataModal.message === t('수정 되었습니다.')){
                            window.location.href = `/admin/fas/partners/${partnerModify.id}`
                          }
                        }}/>}
        {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={t(' * 삭제시 데이터는 복구되지 않습니다.')}
                              clickEvent={partnerDeleteFunction}
                              cancelEvent={() => {setDataModal(initModal)}}/>}

        {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={t(' * "예" 선택시 변경전 데이터는 복구되지 않습니다.')}
                              clickEvent={partnerUpdateFunction}
                              cancelEvent={() => {setDataModal(initModal)}}/>}

        {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
                              cancelEvent={() => {setDataModal(initModal)}}
                              viewType={true}
                              clickEvent={() => {
                                window.location.href = `/admin/fas/partners/${partnerModify.id}`;
                                setDataModal(initModal)
                              }}
          />
        }

        {openPostCode && <ModalBase clickEvent={() => setOpenPostCode(false)} modalContent={
          <DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
                        style={AddressStyle}/>}/>}

        <div style={{display: 'flex' , userSelect: 'none'}}>
          <EditBox>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'지사/협력사 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'취소'} width={'56px'}
                             clickEvent={() => {setDataModal({menu: 'cancel' , message: t('작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?')})}}/>
                {partnerAuth === 'affiliate' &&
                    <MainButtons width={'56px'} buttonType={'delete'} name={'삭제'} clickEvent={onDeleteFunc}
                                 disabled={buttonAuthValid('delete' , '1')}/>
                }
                <MainButtons buttonType={'cta'} width={'56px'} name={'저장'} marginDis={true}
                             clickEvent={() => {setDataModal({menu: 'register' , message: t('이대로 수정하시겠습니까?')})}}/>
              </div>
            </div>
            <div className={'scroll_func'}>
              <RegisterComponent type={'text'} name={'companyName'} value={partnerModify.companyName} required={true}
                                 title={t('지사/협력사명')} onChange={(e) => onEditChange(e , true)}
                                 placeholder={t('지사 / 협력사명을 입력해주세요.')}/>

              <RegisterComponent type={'text'} name={'president'} value={partnerModify.president} required={true}
                                 title={t('대표')} onChange={onEditChange} placeholder={t('대표명을 입력해 주세요.')}/>

              <RegisterComponent type={'address'} onChange={onEditChange} title={t('주소')} width={'72px'} required={true}
                                 name={'address'} subName={'detailAddress'}
                                 subValue={partnerModify.detailAddress}
                                 clickEvent={addressHandle.clickBtn} value={partnerModify.address}/>

              <RegisterComponent type={'phone'} value={partnerModify.phoneNumber}
                                 error={false} title={t('전화번호')} required={true}
                                 onChange={onEditChange} name={'phoneNumber'}
                                 numberFormat={(partnerModify.phoneNumber)}/>

              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '72px'}}>
                  {t('이메일')}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{height: '72px'}}>
                  <div style={{maxWidth: '616px'}}>
                    <InputText type={'text'} name={'email'} value={partnerModify.email}
                               onChange={onCheckEmail} maxLength={30} height={'32px'}/>
                    <div className={'form_text'} style={{color: emailCheck.color}}>
                      {partnerModify.email?.length === 0 ?
                        <div className={'form_text'} style={{margin: '0'}}>
                          {t('*사용하실 Email을 입력해주세요.')}
                        </div>
                        :
                        <div>
                          {t(emailCheck.text)}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'}>
                  {t('관리자 권한')}
                  <div style={{fontSize: '12px' , color: 'red' , marginLeft: '4px'}}>{'*'}</div>
                </div>
                <div className={'input_box'} style={{display: 'flex'}}>
                  <label className={'radio_button'}>
                    <input type="radio"
                           checked={partnerModify.authority === 'admin'} name={'authority'}
                           onClick={() => ( handleClickButton('admin') )}/>
                    <div>{t('관리자')}</div>
                  </label>
                  <label className={'radio_button'}>
                    <input type="radio"
                           checked={partnerModify.authority === 'affiliate'} name={'authority'}
                           onClick={() => ( handleClickButton('affiliate') )}/>
                    <div>{t('협력사')}</div>
                  </label>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'}>{t('관리자 ID')}</div>
                <div className={'input_box'}>
                  <div style={{marginLeft: '8px'}}>{partnerModify.id}</div>
                </div>
              </div>

              <div style={{display: 'flex'}}>
                <div className={'input_title'} style={{height: '48px'}}>{'관리자 PW'}</div>
                <div className={'input_box'}
                     style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'center'}}>
                  <div
                    style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , width: '600px'}}>
                    <MainButtons name={'변경하기'} buttonType={'cta'} margin={'0'}
                                 clickEvent={() => {setDataModal({menu: 'pw'})}}/>
                    {/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
                    {/*    {'비밀번호 찾기'}*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>

              <div style={{display: 'flex' , borderBottom: '1px solid #E6E9F4' , boxSizing: 'border-box'}}>
                <div className={'input_title'} style={{height: '112px'}}>{t('비고')}</div>
                <div className={'input_box'} style={{height: '112px'}}>
                  <InputTextArea name={'notice'} value={partnerModify.notice}
                                 placeholder={t('내용을 입력해주세요.')} onChange={onEditChange}/>
                </div>
              </div>
            </div>

          </EditBox>

          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>

          <div style={{display: 'flex' , flexDirection: 'column' , width: '369px'}}>
            <div>
              <div
                style={{display: 'flex' , justifyContent: 'space-between' , height: '34px' , alignItems: 'center' , marginBottom: '16px'}}>
                <MenuTitle title={t('프로필 수정')} unitCase={'MenuCompTitle'}/>
                <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                  <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                </div>
              </div>
              <SingleImageUploadBox imageKey={(data) => setImageKeyState(data)} receiveKey={partnerModify.image}
                                    receiveUrl={partnerModify.imageUrl} inputSize={168} profileSelect={false}/>
            </div>
            {partnerModify.authority === 'affiliate' &&
              <div style={{marginTop: '80px'}}>
                <div
                  style={{display: 'flex' , justifyContent: 'space-between' , height: '34px' , alignItems: 'center' , marginBottom: '16px'}}>
                  <MenuTitle title={t('협력사 로고 수정')} unitCase={'MenuCompTitle'}/>
                  <div style={{fontSize: "12px" , textAlign: 'right' , color: '#7E84A3'}}>
                    <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                  </div>
                </div>
                <SingleImageUploadBox imageKey={(data) => setLogoImageUrl(data)} receiveKey={partnerModify.logoImage}
                                      receiveUrl={partnerModify.logoImageUrl} inputSize={168} profileSelect={false}/>
              </div>
            }
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default PartnerModify;
const EditBox = styled.div`
  width: 784px;
  height: 620px;

  .input {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }

    &_box {
      border-top: 1px solid #E6E9F4;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      width: 100%;
      align-items: center;
      padding-left: 16px;

      .form_text {
        height: 14px;
        font-size: 12px;
        color: rgba(23, 23, 37, 0.5);
        display: flex;
        white-space: nowrap;
        font-weight: normal;
        margin-top: 8px;
      }

      .radio_button {
        font-size: 14px;
        display: flex;
        margin-right: 6px;
        text-align: center;
        font-weight: normal;
        align-items: center;

        & > input {
          height: 40px;
          cursor: pointer;
          outline: none;
          margin-right: 6px;
        }
      }
    }

    &_tel {
      width: 600px;
      height: 32px;
      box-sizing: border-box;
      outline: none;
      border: 1px solid #D7DBEC;
      border-radius: 4px;
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      padding: 0 8px;

      &::placeholder {
        font-size: 14px;
        color: rgba(23, 23, 37, 0.3);
        font-weight: normal;
        font-family: "NanumGothicOTF", sans-serif;
      }
    }

    &_form {
      font-size: 12px;
      color: rgba(23, 23, 37, 0.5);
      display: flex;
      white-space: nowrap;
      font-weight: normal;
      margin-top: 8px;
    }
  }

  .scroll_func {
    width: 810px;
    height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: block;
    }
  }
`

interface inputProps {
  height?: string;
  width?: string;
}

const InputText = styled.input<inputProps>`
  width: ${props => props.width ? props.width : '600px'};
  height: ${props => props.height ? props.height : '32px'};
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  font-size: 14px;
  font-family: "NanumGothicOTF", sans-serif;
  padding: 0 8px;

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: normal;
    font-family: "NanumGothicOTF", sans-serif;
  }
`
const InputTextArea = styled.textarea`
  width: 600px;
  height: 96px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d7dbec;
  font-family: "NanumGothicOTF", sans-serif;
  //overflow-y: overlay;

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: normal;
    font-family: "NanumGothicOTF", sans-serif;
  }

  &::-webkit-scrollbar {
    width: 15px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`