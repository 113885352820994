import React from 'react';
import styled from 'styled-components';
import {Modal_View_Type , RegisterInputProps} from "../../types/Fundamental";
import MainButtons from "../button/MainButtons";
import {dataValid , initModal , loadingRemove , modalListLoading , uploadingLoading} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import SelectConfirmModal from "../modal/SelectConfirmModal";
import ConfirmPopup from "../modal/ConfirmPopup";

interface Props {
  imageKey?: string | null
  // receiveData:(data:any) => void
  giveFileName?:(data?:string) => void
  buttonWidth?: string
  margin?: string
  error?: boolean
  placeholder?: string
  onChange?: (data:any)=> void
  onKeyPress?: (e:any)=>void
  clickEvent?: ()=>void
  limit?: number
  disabled?: boolean
  fileKeyArray: (data:string[])=>void
  receiveKey?: string[]
  receiveUrl?: string[]
  receiveFileName?:string[]
  fileKeyLength?: number
}


const AttachMultipleUploadBox:React.FunctionComponent<Props> = ({  receiveFileName,fileKeyArray, receiveKey,receiveUrl,placeholder, fileKeyLength}) => {

  const [fileKeyState, setFileKeyState] = React.useState<any[]>(receiveKey ?? [])
  const [fileNameState, setFileUrlState] = React.useState<any[]>(receiveUrl ?? [])
  const [fileName,setFileName] = React.useState<any[]>(receiveFileName ?? [])
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
  const inputRef = React.useRef<any>(null)
  const extendReg = /(.*?)\.(jpg|jpeg|png|doc|docx|hwp|pdf|ppt|psd|txt|xls|xlsx|zip|hip)$/

  const attachFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
    uploadingLoading('파일 업로드 중 입니다...');
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', data);
    setFileName([...fileName, data.name]);

    try {
      const response = await client.post('/file/SDM/upload', formData, { headers: { Authorization } });
      const responseData = response.data.data;
      setFileKeyState([...fileKeyState, responseData.imgKey]);
      setFileUrlState([...fileNameState, responseData.imgUrl]);

    } catch (e) {
      console.log(e);
    }
    loadingRemove();
  };

  const deleteData = (i: number) => {
    setFileKeyState(fileKeyState.filter((_,data) => data !== i))
    setFileUrlState(fileNameState.filter((_,data) => data !== i))
    setFileName(fileName.filter((_,data) => data !== i))
  }


  React.useEffect(()=>{
    fileKeyArray(fileKeyState)
    if(fileKeyState.length === 0){
      //@ts-ignore
      fileKeyArray( null )
    }
  },[fileKeyState])

  React.useEffect(()=>{
    if(receiveUrl){
      setFileUrlState(receiveUrl)
    }
  },[receiveUrl])

  React.useEffect(()=>{
    if(receiveKey){
      setFileKeyState(receiveKey)
    }
  },[receiveKey])

  React.useEffect(()=>{
    if(receiveFileName){
      setFileName(receiveFileName)
    }
  },[receiveFileName])

  return (
    <>
      {dataModal.menu === 'delete' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true} transparent={true}
                              noticeText={' * "예" 선택시 해당 파일은 삭제됩니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              clickEvent={()=>{deleteData(dataModal.index); setDataModal(initModal)}}
          />
      }
      {/*{dataModal.menu === 'duplication' &&*/}
      {/*    <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal); deleteData(fileName?.length - 1)}}/>*/}
      {/*}*/}
      <Container>
        <div className={'register_title'}>{'첨부파일'}</div>
        <div style={{display:'flex',flexDirection:'column'}}>
          {fileNameState?.map((v:any,i:number)=>(
            <div style={{display:'flex'}} className={'register_box'} key={v}>
              <InputText style={{color:'#171725'}}>{fileName[i]}</InputText>
              <MainButtons name={'삭제'} clickEvent={()=>{setDataModal({menu:'delete',message:'해당파일을 삭제하시겠습니까?',index:i})}} width={'78px'}/>
            </div>
          ))}
          {(fileKeyState.length < dataValid(fileKeyLength, 4) )&&
              <div style={{display:'flex'}} className={'register_box'}>
                <InputText>{placeholder}</InputText>
                <label>
                  <input style={{display:'none'}} type="file" ref={inputRef} onChange={(e:any)=>{
                    if(inputRef.current.value.match(extendReg)){
                      if(e.target.files[0].size < 512*1024*1024){
                        attachFileUpload(e, e.target.files[0]).then()
                      }else{
                        setDataModal({menu:'file', message: '512MB 이하의 파일을 업로드 해주세요.'})
                        inputRef.current.value = ""
                      }
                    }else{
                      setDataModal({menu:'file', message: '잘못된 형식의 파일입니다.'})
                      inputRef.current.value = ""
                      // setUploadState({open: true, status:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})
                    }
                  }}/>
                  <MainButtons name={'업로드'} buttonType={'cta'} width={'78px'} marginDis={true}/>
                </label>
              </div>
          }
        </div>
      </Container>
    </>

  );
};
export default AttachMultipleUploadBox;

const Container = styled.div`
  display: flex;
  border-top: 1px solid #E6E9F4;
  
  & > div:first-child {
    min-height: 48px;
    box-sizing: border-box;
    padding-left: 16px;
    align-items: center;
    display: flex;
  }
  .register_title{
    width: 168px;
    min-width: 168px;
    background-color: #F5F6FA;
    font-size: 12px;
    font-weight: 800;
  }
  .register_box{
    padding: 8px 0 4px 16px;
    box-sizing: border-box;
    
  }
`

const InputText= styled.div<{ error?: boolean,margin?:string}>`
  width: 514px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  box-sizing: border-box;
  outline: none;
  border: 1px solid ${props => props.error ? '#F0142F' : '#D7DBEC'};
  border-radius: 4px;
  font-size: 14px;
  font-family: "NanumGothicOTF", sans-serif;
  color: rgba(23, 23, 37, 0.3);

  &::placeholder {
    font-family: "NanumGothicOTF", sans-serif;
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    box-sizing: border-box;
    font-weight: normal;
    padding-left: 8px;
  }
`
