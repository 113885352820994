import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import RegisterInput from "../input/RegisterInput";
import SelectEventButton from "../button/SelectEventButton";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {Modal_View_Type , ModalType} from "../../types/Fundamental";
import SelectConfirmModal from "./SelectConfirmModal";
import LoadingComponent from "./LoadingComponent";
import ConfirmPopup from "./ConfirmPopup";
import {useTranslation} from "react-i18next";
import {dataAccessLoading , initModal , loadingRemove} from "../../function/function";
import MainButtons from "../button/MainButtons";
import ToastPopup from "./ToastPopup";
interface Props{
	solution: string
	cancelEvent: ()=>void
	type?: boolean
	confirmEvent?: ()=>void
}

const SolutionRequestModal:React.FunctionComponent<Props> = ({solution, cancelEvent, type,confirmEvent}) => {

	const {t}:any = useTranslation()
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const [inputState, setInputState] = React.useState<{email: string, request: string}>({
		email: '', request: ''
	})
	const onChangeValue = (e:any) => {
		const {value, name} = e.target
		setInputState({
			...inputState,
			[name]: value
		});
	}

	const solutionRegisterRes = async (solution: string) => {
		setDataModal({menu:'select', message:t(`솔루션을 ${type? '해지 요청' : '요청'} 하시겠습니까?`)})
		try {
			dataAccessLoading()
			const response = await client.post('/solution/register',{
				companyCode: window.localStorage.getItem('companyCode'),
				email: inputState.email,
				request: inputState.request,
				solution: solution,
				statement:'wait',
				type:`${type? 'release' : 'subscribe'}`,
			},{headers:{Authorization}})
			if(response.data.status !== 200){
				setDataModal({menu:"confirm",message:response.data.message})
				loadingRemove()
			}else{
				setTimeout(()=>{
					loadingRemove()
					setDataModal({menu:'success', message:`솔루션 ${type? '해지 요청이' : '요청이'} 완료 되었습니다.`})
					confirmEvent && confirmEvent()
				},500)
			}

		}catch (e:any) {
			loadingRemove()
			setDataModal({menu:'error', message:e.message})
			console.log(e)
		}
	}

	const regEmail= /^[_a-z0-9-]+(.[_a-z0-9-]+)*@(?:\w+\.)+\w+$/

	const requestValidation = () => {
		if(!inputState.email){
			setDataModal({menu:'confirm', message:t('이메일을 입력해 주세요.')})
		}else{
			if(!regEmail.test(inputState.email)){
				setDataModal({menu:'confirm', message:t('이메일 형식이 맞지 않습니다.')})
			}else{
				setDataModal({menu:'select', message:t(`솔루션을 ${type? '해지 요청' : '요청'} 하시겠습니까?`)})
			}
		}
	}

	return (
		<React.Fragment>
			{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{	setDataModal( initModal )
				if(dataModal.message?.includes('해지')){
					window.location.reload() }}}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

			{dataModal.menu === 'confirm' &&
				<ConfirmPopup statusText={dataModal.message}
											clickEvent={()=> {setDataModal(initModal)}}/>
			}
			{dataModal.menu === 'select' &&
					<SelectConfirmModal statusText={dataModal.message}
					                    cancelEvent={()=>setDataModal(initModal)}
					                    clickEvent={()=>solutionRegisterRes(solution)}/>}
		<ModalBase zIndex={1400} modalContent={
			<ModalFrame>
				<div className={'title_component'} style={{backgroundColor:`${window.localStorage.getItem('auth')?.includes('admin')? '#CFFA61' : '#0058ff'}`}}>
					{`${solution} `+ t(`솔루션 ${type? '해지' : '구독'} 요청하기`)}
				</div>
				<div style={{display:'flex', flexDirection:'column',justifyContent:'space-between', boxSizing:'border-box', padding:'24px 0 32px'}}>
					<div className={'request'}>
						<div className={'request_title'} style={{height:'48px',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>{t('이메일')}</div>
						<div className={'request_input'} style={{height:'48px',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>
							{/*이메일 바인딩 필요, 현재 업체에서는 이메일 등록 사항 없음.*/}
							<RegisterInput value={inputState.email} name={'email'} placeholder={t('이메일을 입력해주세요.')}
							               onChange={onChangeValue} type={'input'} maxWidth={'264px'}/>
						</div>
						<div className={'request_title'} style={{height:'288px'}}>{`${type? t('해지 사유') : t('문의내용')}`}</div>
						<div className={'request_input'} style={{height:'288px'}}>
							<RegisterInput value={inputState.request} name={'request'} placeholder={t('내용을 입력해주세요.')}
							               onChange={onChangeValue} type={'textarea'} maxWidth={'264px'} maxHeight={'272px'}/>
						</div>
					</div>
					<div style={{display:'flex',marginTop:'30px',justifyContent:'center',gap:'16px'}}>
						<MainButtons name={'취소'} width={'144px'} clickEvent={cancelEvent}/>
						<MainButtons name={'요청 하기'} buttonType={'cta'} width={'144px'} clickEvent={requestValidation}/>
					</div>
				</div>
			</ModalFrame>}/>
		</React.Fragment>
	);
};

export default SolutionRequestModal;

const ModalFrame = styled.div`
  position: absolute;
  width: 416px;
  height: 512px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
	

  .title_component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 56px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0 16px;
  }
	.request{
		display: grid;
		grid-template-columns: 104px 280px;
		box-sizing: border-box;
		padding: 0 16px;
		&_title{
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			background-color: #F5F6FA;
			color: #131523;
			font-size: 12px;
			font-weight: 800;
     border-bottom: 1px solid rgba(213, 215, 227, 0.5);
			padding: 0 16px;
		}
			&_input{
		  display: flex;
				flex-direction: column;
		  justify-content: center;
				box-sizing: border-box;
				padding-left: 16px;
				align-self: center;
		
		  border-bottom: 1px solid rgba(213, 215, 227, 0.5);
		}
	}
	
`
