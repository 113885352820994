import React , { useState} from 'react';
import MenuTitle from "../../../../components/title/MenuTitle";
import SearchBar from "../../../../components/input/SearchBar";
import {
  autoTextValueValidation ,
  ContentFrame ,
  NoData ,
  SectionFrame ,
} from "../../../../style/StyleComponent";
import PaginationList from "../../../../components/pagination/PaginationList";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../components/button/MainButtons";
import {useRecoilState} from "recoil";
import {persistBlockPage, persistPage, searchInputState, selectedCode} from "../../../../common/StateManage";
import {buttonAuthValid , dataValid , initError} from "../../../../function/function";
import {Error_Type} from "../../../../types/Fundamental";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../CustomHooks";
import {GridTableComponent, GridTableSelect} from "../../../../style/StyleModule";



const PartnerManagement = () => {

  const { t }:any = useTranslation()
  const menuTitle = ['지사/협력사명', '대표', '주소', '전화번호', '이메일', '관리자 ID','권한']
  const [selectedPartner, setSelectedPartner] = useRecoilState<string>(selectedCode)
  const [pageState, setPageState] = useRecoilState<number>(persistPage);
  const [pageBlock,setPageBlock] = useRecoilState<number>(persistBlockPage);
  const [totalPageState,setTotalPageState] = useState<number>(0)
  const [partnerDataList, setPartnerDataList] = React.useState<any[]>([]);
  const [searchInput, setSearchInput] = useRecoilState<string>(searchInputState);
  const [prevSearch, setPrevSearch] = React.useState<string>(searchInput)
  const [errorState,setErrorState] = React.useState<Error_Type>(initError)

  const searchEventFunc = () => {
    setPrevSearch(searchInput)
    setPageState(1)
    setPageBlock(0)
  }

  const partnerListRes = ()=> ListCustomHooks(`/admin/list/${pageState}/10`,pageState,setTotalPageState,setPageBlock,setPartnerDataList,setErrorState,setSearchInput,setPrevSearch)
  const partnerSearchRes = () => ListSearchPostCustomHooks(`/admin/search`,pageState,setTotalPageState,setPageBlock,setPartnerDataList,setErrorState,{page:pageState,size:10,keyword:searchInput})

  React.useEffect(() => {
                    if (searchInput === '') {
                      partnerListRes().then()
                    } else {
                      partnerSearchRes().then()
                    }
                  }
    , [pageState, prevSearch])
  // console.log(errorState.notice)
  // console.log(!partnerDataList)
  return (
    <SectionFrame>
      <React.Fragment>
        <MenuTitle title={t('지사/협력사 관리')} unitCase={'MenuTitle'}/>
        <div style={{display:'flex', justifyContent:'center'}}>
          <ContentFrame justify={'space-between'}>
            <div>
              <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <MenuTitle title={t('지사/협력사 정보')} unitCase={'MenuCompTitle'}/>
                <div style={{display:'flex',alignItems:'center'}}>
                  <SearchBar keyFunction={(e)=>setSearchInput(e.target.value.trimStart())} keyPressDisable={true}
                             value={searchInput} clickEvent={searchEventFunc}
                             clearButton={partnerListRes} clearButtonAccess={searchInput !== ''}
                             placeholder={t('검색')}/>
                    <MainButtons buttonType={'cta'} disabled={buttonAuthValid('create','1')}
                                 width={'80px'} name={'등록'} marginDis={true}
                                 clickEvent={()=>window.location.href = `/admin/fas/partners/register`}/>
                </div>
              </div>
              <GridTableComponent tempCol={'208px 128px 268px 148px 200px 140px 1fr'} scrollY_Hide={true} scrollX_Hide={true} height={528}>
                <div className={'grid-title-unchecked'}>
                  {menuTitle?.map((name)=>(<div key={name}>{t((name))}</div>))}
                </div>
                {!partnerDataList || partnerDataList?.length === 0?
                  <NoData>{dataValid(errorState.notice,'검색 결과가 없습니다.')}</NoData>
                  :
                  partnerDataList?.map((v)=>(
                    <GridTableSelect key={v.id} className={'grid-list-unchecked-no_icon'}
                                     selected={selectedPartner === v.id}
                                     onDoubleClick={()=>{ window.location.href = `/admin/fas/partners/${v.id}`}}
                                     onClick={()=>{setSelectedPartner(v.id);}}>
                      {autoTextValueValidation({value:v.companyName})}
                      {autoTextValueValidation({value:v.president})}
                      {autoTextValueValidation({value:v.address})}
                      <div>{v.phoneNumber}</div>
                      {autoTextValueValidation({value:v.email})}
                      {autoTextValueValidation({value:v.id})}
                      <div>{v.authority === 'admin' ? t('관리자') : t("협력사")}</div>
                    </GridTableSelect>
                  ))
                }
              </GridTableComponent>
            </div>
            {partnerDataList?.length !== 0 &&
                <PaginationList limit={10} pageLimit={10} page={pageState} setPage={setPageState}
                                blockNum={pageBlock} setBlockNum={setPageBlock} counts={totalPageState}/>
            }
          </ContentFrame>
        </div>

      </React.Fragment>



    </SectionFrame>
  );
};

export default PartnerManagement;
