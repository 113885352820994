import React , {useEffect , useRef , useState} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {
  authValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
  locationFilter
} from "../../function/function";
import SearchBar from "../input/SearchBar";
import {GridTableComponent , GridTableSelect , NoData , validationValue} from "../../style/StyleComponent";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {CompanyDataType , Modal_View_Type} from "../../types/Fundamental";
import check from "../../asset/image/check_white.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import dayjs from "dayjs";
import ListInnerInput from "../input/ListInnerInput";
import MainButtons from "../button/MainButtons";
import SelectConfirmModal from "./SelectConfirmModal";
import BusinessWorkSelectModal from "./BusinessWorkSelectModal";
import ToastPopup from "./ToastPopup";
import {log} from "node:util";
import CheckDropFilter from "../check/CheckDropFilter";
import {useRecoilValue} from "recoil";
import {companySolutionFilter} from "../../common/StateManage";

interface Interface {
  closeEvent?:() => void
  receiveData:(data:any)=>void
  closePersistPopupEvent:() => void
  setData:any[]
  date?:any | Date
  inputText?:string
  idType?:boolean
}


const MultipleCompanySelectModal = ({idType,closeEvent,receiveData,setData,date,closePersistPopupEvent,inputText}:Interface) => {
  const [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [hoverIndex , setHoverIndex] = useState<number>(- 1)
  const [companyList, setCompanyList] = useState<CompanyDataType[]>([])
  const [searchInput,setSearchInput] = React.useState<{input:string,value:string}>({input:dataValid(inputText,''),value:''})
  const [selectedCompanyList,setSelectedCompanyList] = React.useState<any>(setData ?? []);
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
  const [openWorKerModal,setOpenWorKerModal] = React.useState<boolean>(false);
  // const [inputSearchValue, setInputSearchValue] = useState<string>(dataValid(inputText,''))
  const [filterState, setFilterState] = useState<string>('@')
  const scrollRef = useRef<any>(null)
  const scrollToTop = ()=> {scrollRef.current.scrollTop = 0}
  let requestCount = 1
  const solutionValue = useRecoilValue(companySolutionFilter)
  const solutionCheck = solutionValue === 'MES'? '@' : solutionValue === 'P-MS'? '@' : solutionValue


  const companyListRes = async () => {
    requestCount++
    try {
      const response =
        idType ?
          await client.post(`/company/all/list`,{
            page:page ,
            size:10,
            type:filterState === 'mida' || 'affiliate'? filterState : (solutionCheck === 'FAS' ? '@' : solutionCheck) ,
            keyword:dataValid(searchInput.value, '@')},
                            {headers:{Authorization}})
          :
          await client.get( `/company/list/${page}/${10}/${solutionCheck}`,{headers:{Authorization}})

      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setCompanyList((prev:any)=> prev.concat(data.row))
      }else{
        setCompanyList(data.row)
      }
    }catch ( e ) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }
  console.log(searchInput.value)

  // const companyListSearchRes = async () => {
  //   requestCount++
  //   try {
  //     const response = await client.post(idType? `/company/all/list` : `/company/search`,{
  //       page:page,
  //       size:10,
  //       type:solutionCheck === 'FAS' ? '@' : solutionCheck,
  //       keyword:searchInput.value
  //     },{
  //                                          headers:{Authorization}
  //                                        })
  //     const data = response.data.data
  //     setTotalPage(data.total_page)
  //     if(data.total_page > 1 && page > 1){
  //       setCompanyList((prev:any)=> prev.concat(data.row))
  //     }else{
  //       setCompanyList(data.row)
  //     }
  //   }catch ( e ) {
  //     console.log(e)
  //   }
  //   finally {
  //     requestCount--
  //   }
  // }



  //
  // useEffect(()=>{
  //   if(requestCount === 1){
  //     if(searchInput.value === ''){
  //       companyListRes().then()
  //     }else{
  //       companyListSearchRes().then()
  //     }
  //   }
  // },[page, searchInput.value])


  const handleClick = (item: any) => {
    const isItemSelected = selectedCompanyList.some(( selectedItem: { companyCode: any; }) => selectedItem.companyCode === item.companyCode);

    if (isItemSelected) {
      setSelectedCompanyList(( prevList: any[]) => prevList.filter(selectedItem => selectedItem.companyCode !== item.companyCode));
    } else {
      setSelectedCompanyList(( prevList: any) => [...prevList, item]);
    }
  };

  const calendarBusinessReg = async (item?:string[]) => {
    closePersistPopupEvent()
    dataAccessLoading()
    try {
      const response = await client.post(`/business/management/simple/register`,{
        companyCode:selectedCompanyList?.map((v:any)=>(v?.companyCode)),
        responsibility:dataValid(item,[]),
        title:'-',
        visitDate:dayjs(date.date).format('YYYY-MM-DD')
      },{headers:{Authorization}})
      const valid = response.data
      if(valid.status !== 200){
        setDataModal({menu:'warn',message:valid.messsage})
        loadingRemove()
      }else{
        setTimeout(()=>{
          loadingRemove()
          setDataModal({menu:'success',message:'영업관리가 등록 되었습니다.'})
        },500)
      }
    }catch (e:any) {
      console.log(e)
      setDataModal({menu:'warn',message:e.message})
      loadingRemove()
    }
  }

  const searchFunction = () => {
    setCompanyList([])
    setPage(1)
    setTotalPage(1)
    scrollToTop()
    companyListRes().then()
  }


  useEffect(()=>{
    if(requestCount === 1){
      companyListRes().then()
    }
  },[page, filterState,searchInput.value])

  return (
    <ModalBase transparent={openWorKerModal} modalContent={
      <React.Fragment>

        {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} closeEvent={()=> {setDataModal(initModal)}}/>
          : dataModal.menu === 'error' ? <ToastPopup text={dataModal.message} state={'error'} closeEvent={()=> {setDataModal(initModal)}}/>
            : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message} state={'warn'} closeEvent={()=> {setDataModal(initModal)}}/> : null}

        {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message} noticeText={'* 아니오 선택시 기본 담당자로 방문일정만 등록됩니다.'}
                              clickEvent={()=>{setDataModal(initModal); setOpenWorKerModal(true)}}
                              cancelEvent={calendarBusinessReg}
          />
        }
        {openWorKerModal &&
          <BusinessWorkSelectModal receiveData={(data)=>{calendarBusinessReg(data?.map((v:any)=>(v.id))).then()}}
                                   setData={[]} setCompanyData={selectedCompanyList}
                                   closeEvent={()=>{setOpenWorKerModal(false)}}
                                   date={date.date}/>
        }


        <BaseComponent>
          <div className={'modal'} style={{backgroundColor:authValid()  ? '#CFFA61' : '#0058ff',color: authValid()  ? '#171725' : '#fff',justifyContent:'space-between'}}>
            <div className={'modal_title'} >{'영업관리 > 등록'}</div>
            <div style={{fontSize:'14px',color:'#0058FF',fontWeight:900}}>{'방문 날짜 : ' + dayjs(date.date).format('YYYY년 MM월 DD일 (dddd)')}</div>
          </div>
          <div>
            <div style={{display:'grid', gridTemplateColumns:'396px 396px', gap:'16px',  boxSizing:'border-box', padding:'16px',justifyContent:'space-between'}}>
              <div>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                      <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>{'업체리스트'}</div>
                      <div style={{display:'flex'}}>
                        <CheckDropFilter checkedData={(v)=>{
                          setFilterState(v)
                          setPage(1)
                        }} affiliateViewType={true}/>
                        <SearchBar clickEvent={()=> {setSearchInput((prev) => ({...prev , value: prev.input})); setPage(1); }}
                                   width={'196px'} widthCus={'196px'}
                                   clearButton={()=>{setPage(1); setSearchInput({input:'',value:''}); scrollToTop()}}
                                   clearButtonAccess={searchInput.input !== ''} value={searchInput.input}
                                   keyFunction={(e)=> setSearchInput((prev)=>({...prev, input: e.target.value.trimStart()}))}/>
                      </div>
                    </div>

                  {/*<div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>{'업체리스트'}</div>*/}
                  {/*<SearchBar clickEvent={()=> {setSearchInput((prev) => ({...prev , value: prev.input})); setPage(1)}}*/}
                  {/*           clearButton={()=>{setPage(1); setSearchInput({input:'',value:''})}} clearButtonAccess={searchInput.input !== ''} value={searchInput.input}*/}
                  {/*           keyFunction={(e)=> setSearchInput((prev)=>({...prev, input: e.target.value.trimStart()}))}*/}
                  {/*/>*/}
                <InfiniteScroll scrollableTarget={'grid_scroll'}
                                hasMore={totalPage > page}
                                dataLength={dataValid(companyList?.length,0)}
                                next={()=>setPage((prev:any)=>prev+1)} loader={<div/>}>
                  <GridTableComponent tempCol={'112px 48px 56px 1fr'} id={'grid_scroll'}
                                      scrollWidth={386} scrollX_Hide={true} height={288}>
                    <div className={'grid-title-unchecked'}>
                      {  ['업체명','지역','대표자','업체코드'].map((v)=>(
                        <div key={v}>{v}</div>
                      ))}
                    </div>
                    {companyList?.length === 0?
                      <NoData height={232}>{'* 검색결과가 없습니다.'}</NoData>
                      :
                      companyList?.map((v,index)=>(
                        <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                         selected={selectedCompanyList.some(( selectedItem: { companyCode: any; }) => selectedItem.companyCode === v.companyCode)}
                                         onClick={()=>{handleClick(v);}}>
                          {validationValue(v.companyName)}
                          {validationValue(locationFilter(v.address))}
                          {validationValue(v.president)}
                          {validationValue(v.companyCode)}

                          {/*<div style={{display:selectedCompanyList.some(( selectedItem: { id: any; }) => selectedItem.id === v.id) ? 'block' : 'none'}}>*/}
                          {/*  <img src={check} width={16} height={16} style={{filter: 'brightness(0)'}}/>*/}
                          {/*</div>*/}

                        </GridTableSelect>
                      ))
                    }
                  </GridTableComponent>
                </InfiniteScroll>
              </div>
              <div>
                <div style={{display:"flex",justifyContent:'space-between',alignItems:'center'}}>
                  <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3',marginBottom:'8px'}}>
                    {'선택 리스트'}
                  </div>
                  <div style={{fontSize:'14px',lineHeight:'32px',textAlign:'end',color:'#0058FF',fontWeight:700}}>
                    { '선택 : ' + dataValid(selectedCompanyList?.length,'-')+ ' 개의 업체'}
                  </div>
                </div>

                <GridTableComponent tempCol={'112px 56px 56px 1fr'} id={'grid-scroll'}
                                    scrollWidth={386} scrollX_Hide={true} height={288}>
                  <div className={'grid-title-unchecked'}>
                    {  ['업체명','지역','대표자','업체코드'].map((v)=>(
                      <div key={v}>{v}</div>
                    ))}
                  </div>
                  {selectedCompanyList?.length === 0?
                    <NoData height={232}>{'* 선택된 유저가 없습니다.'}</NoData>
                    :
                    selectedCompanyList.map((v:any,index:number)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       onMouseEnter={() => setHoverIndex(index)}
                                       onMouseLeave={() => setHoverIndex(- 1)}
                      >
                        {validationValue(v.companyName)}
                        {validationValue(locationFilter(v.address))}
                        {validationValue(v.president)}
                        {validationValue(v.companyCode)}
                        {
                          index === hoverIndex &&
                            <HoverDeleteButton>
                              <div className={'facility_cancel'}
                                   onClick={() => {
                                     setHoverIndex(-1);
                                     setSelectedCompanyList(( prevList: any[]) => prevList.filter(selectedItem => selectedItem.companyCode !== v.companyCode));}}>
                                <div>
                                  {'╋'}
                                </div>
                              </div>
                            </HoverDeleteButton>
                        }

                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
              </div>
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'center'}}>
            <MainButtons name={'취소'} margin={'12px 16px 0 0'} width={'144px'} padding={'4px 32px'} height={'40px'}
                         clickEvent={closeEvent}/>
            <MainButtons name={'등록'} margin={'12px 0 0 0'} width={'144px'} padding={'4px 32px'} height={'40px'} buttonType={'cta'}
                         clickEvent={()=>{
                           setDataModal({menu:'select',message:'방문일정이 등록되었습니다.\n담당자를 추가 하시겠습니까 ?'})
                           receiveData(selectedCompanyList);
                         }}
                         disabled={selectedCompanyList?.length === 0}/>
          </div>
        </BaseComponent>
      </React.Fragment>
    }/>
      
  );
};
export default MultipleCompanySelectModal;

const BaseComponent = styled.div`
  width: 842px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  user-select: none;
  .modal{
    width: 842px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;
      &_title{
        font-size: 18px;
        font-weight: 900;
          margin-right: 8px;
      }
      &_sub_title{
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
      }
  }
`
const HoverDeleteButton = styled.div`
  position: absolute;
  user-select: none;
  right: 2%;
  .facility_index {
    opacity: 1;
  }

  .facility_cancel {
    width: 24px;
    height: 24px;
    border: 1px solid #0058FF;
    border-radius: 4px;
    color: #5a607f;
    text-align: center;
    line-height: 24px;

    cursor: pointer;
    background-color: #fff;

    &:hover {
      background-color: #0058FF;
      color: #fff;
      border: 1px solid #0058FF;
    }

    & > div {
      transform: rotateZ(45deg);
    }
  }
`



























