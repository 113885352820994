import React from 'react';
import {NoData} from "../../style/StyleComponent";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import MainButtons from "../button/MainButtons";
import RegisterInput from "../input/RegisterInput";
import deleteIcon from '../../asset/image/FAS_icon_x_close.svg'
import ModalBase from "./ModalBase";
import {useLocation} from "react-router-dom";

interface Props {
	factoryName?: string
	factorySegment?:string[]
	closeEvent?: ()=>void
	clickEvent?: (data:string[])=>void
	segmentMenu?: string

}

const FactorySegmentationModal:React.FunctionComponent<Props> = ({factoryName,
	                                                                 factorySegment,
	                                                                 closeEvent,
	                                                                 clickEvent, segmentMenu}) => {

	const { t }:any = useTranslation();
	const location = useLocation()
	const authValid = location.pathname.includes('/admin')
	const [addInput, setAddInput] = React.useState<string>('')
	const [segmentData, setSegmentData] = React.useState<string[]>(factorySegment?? [])

	const eventInput = (e:any) => {
		setAddInput(e.target.value)
	}

	const createSegment = () => {
		if(addInput !== ''){
			if(segmentData.some((v)=>(v === addInput))){
				// alert('아 중복입니다.')
			}else{
				setSegmentData(segmentData.concat(addInput))
				setAddInput('')
			}
		}
	}

	const removeSegment = (seg: string) => {
		setSegmentData(segmentData.filter((v)=>v !== seg))
	}

	const receiveSegment = () => {
		if (clickEvent) {
			clickEvent(segmentData)
		}
	}


	return (
		<ModalBase modalContent={
			<Container>
				<BaseComponent>
					{segmentMenu === 'register' &&
						<React.Fragment>
							<div className={'modal_title'} style={{backgroundColor: !authValid? '#0058ff' : '#CFFA61',color:!authValid? '#fff': '#000'
							,fontWeight:700}}>{'['+`${factoryName ?? t('공장명입력중')}`.slice(0,15)+']' + t(' 세분화 추가')}</div>
							<div className={'modal_list'} style={{display:'flex', flexDirection:'column', padding:'24px 16px',  justifyContent:'space-between'}}>
								<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
									<RegisterInput type={'input'} value={addInput} name={''} placeholder={'추가할 세분화명을 입력하세요.'} onChange={eventInput} onKeyPress={createSegment} />
									<MainButtons name={'추가'} clickEvent={createSegment} width={'72px'} fontWeight={'700'} marginDis={true}/>
								</div>
								<div>
									<div className={'table_title'}>
										<div>{t('세분화명')}</div>
									</div>
									<div className={'scroll_func'} style={{height:'264px'}}>
										{segmentData.length === 0?
											<NoData height={262}>{t('추가된 세분화 목록이 없습니다.')}</NoData>
											:
											segmentData.map((v,i)=>(
												<SegmentTableList select={false} onClick={()=>''}>
													<div>{v}</div>
													<img src={deleteIcon} alt="" onClick={()=>removeSegment(v)}/>
													{/*<div onClick={()=>removeSegment(v)}*/}
													{/*     style={{fontSize:'12px',fontWeight:800, userSelect:'none', transform:'rotateZ(45deg)'}}>{'╋'}</div>*/}
												</SegmentTableList>
											))}
									</div>
								</div>

								<div style={{display:'flex',justifyContent:'center'}}>

									<MainButtons name={'취소'} buttonType={'popupNormal'} width={'144px'} clickEvent={closeEvent} />
									<MainButtons name={'확인'} buttonType={'popupCta'} width={'144px'} clickEvent={receiveSegment}/>

								</div>
							</div>
						</React.Fragment>}
					{segmentMenu === 'detail' &&
						<React.Fragment>
							<div className={'modal_title'}  style={{backgroundColor: !authValid? '#0058ff' : '#CFFA61',color:!authValid? '#fff': '#000',fontWeight:700}}>{'['+`${factoryName ?? t('공장명입력중')}`.slice(0,15)+']' + t(' 세분화 보기')}</div>
							<div className={'modal_list'} style={{display:'flex', flexDirection:'column', padding:'16px', justifyContent:'space-between'}}>
								<div>
									<div className={'table_title'}>
										<div>{t('세분화명')}</div>
									</div>
									<div className={'scroll_func'} style={{height:'264px'}}>
										{segmentData.length === 0?
											<NoData height={262}>{t('추가된 세분화 목록이 없습니다.')}</NoData>
											:
											segmentData.map((v,i)=>(
												<SegmentTableList select={false} onClick={()=>''}>
													<div>{v}</div>
												</SegmentTableList>
											))}
									</div>
								</div>

								<div style={{display:'flex',justifyContent:'center'}}>
									<MainButtons name={'확인'} buttonType={'popupCta'} width={'144px'} clickEvent={receiveSegment} />
								</div>
							</div>
						</React.Fragment>
					}
				</BaseComponent>
			</Container>
		}/>
	);
};

export default FactorySegmentationModal;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #1315234D;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BaseComponent = styled.div`
  position: absolute;
  width: 416px;
  height: 512px;
  box-sizing: border-box;
	border-radius: 6px;
  
  .modal_title{
    height: 56px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 4px #15223214;
    display: flex;
    align-items: center;
    padding-left: 16px;
    box-sizing: border-box;
    font-weight: 800;
    font-size: 18px;
	  user-select: none;
	  
	  
  }
  
  .modal_list{
    box-sizing: border-box;
    background-color: #fff;
    height: 456px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 0 0 6px 6px;
  }
  
  .table_title{
    width: 384px;
    height: 48px;
    background-color: #F5F6FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 900;
    padding: 0 16px;
    box-sizing: border-box;
  }
  .table_list{
    display: flex;
    justify-content: space-between;
    padding:0 16px;
    line-height: 48px;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid transparent;
    &:hover{
      color: #0058ff;
      font-weight: 700;
      background-color: rgba(0,88,255,0.05);
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid #0058ff;
    }
  }
  .scroll_func{
    height:216px;
    border: 1px solid #E6E9F4;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 17px;
      display: block;
    }
		&::-webkit-scrollbar-track {
			width: 10px;
			height: 8px;
			border-radius: 10px;
			background: transparent;
		}
  }
`

const SegmentTableList = styled.div<{select: boolean}>`
	display: flex;
	justify-content: space-between;
	padding:0 16px;
	line-height: 46px;
	font-size: 14px;
	box-sizing: border-box;
	border: 1px solid ${p => p.select? '#0058ff' : 'transparent'};
	color: ${p => p.select? '#0058ff' : 'default'};
	font-weight: ${p => p.select? 700 : 400};
	background-color: ${p => p.select? 'rgba(0,88,255,0.05)' : '#fff'};
  
	//&>div:last-child{
	//	font-weight: 700;
	//}
	
	&:hover{
		color: #0058ff;
		font-weight: 700;
		background-color: rgba(0,88,255,0.05);
		cursor: pointer;
		box-sizing: border-box;
		border: 1px solid #0058ff;
	}
`

const AddInputBox = styled.input`
  width: 304px;
  height: 32px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid  #E6E9F4;
  border-radius: 4px;
`