import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import StandardDocManagement from "../../../containers/admin/fas/document/StandardDocManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasDocument = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<StandardDocManagement/>}/>
    </PageContainer>
  );
};

export default FasDocument;