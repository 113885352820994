import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import PeripheralsManagement from "../../../containers/admin/fas/company/peripherals/PeripheralsManagement";
import {useLocation} from "react-router-dom";
import PeripheralsDetail from "../../../containers/admin/fas/company/peripherals/PeripheralsDetail";
import PeripheralsModify from "../../../containers/admin/fas/company/peripherals/PeripheralsModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import PeripheralsRegistration from "../../../containers/admin/fas/company/peripherals/PeripheralsRegistration";

const FasPeripherals = () => {
  const location = useLocation();
  const {pathname} = location
    return (
        <PageContainer>
            <IntegratedSideMenu/>
            <SectionContainer
              contentContainer={
                pathname.length === (location.pathname.includes('/admin')? 22 : 16)?
                <PeripheralsManagement/>
                :
                location.pathname.includes('register') ?
                  <PeripheralsRegistration/>
                  :
                location.pathname.includes('modify') ?
                  <PeripheralsModify/>
                  :
                <PeripheralsDetail/>

            }/>
        </PageContainer>
    );
};

export default FasPeripherals;