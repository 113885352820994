import React from 'react';
import {
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  validationValue
} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import SearchBar from "../../../../components/input/SearchBar";
import SelectEventButton from "../../../../components/button/SelectEventButton";
import {useTranslation} from "react-i18next";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Error_Type , solutionDataType} from "../../../../types/Fundamental";
import PaginationList from "../../../../components/pagination/PaginationList";
import MainButtons from "../../../../components/button/MainButtons";
import {useRecoilState} from "recoil";
import {
  persistBlockPage ,
  persistPage ,
  searchInputState ,
  selectedCode , selectedListCountValue , solutionTypeState
} from "../../../../common/StateManage";
import {
  buttonAuthValid ,
  dataValid ,
  initError ,
  listLoadingCircle ,
  loadingRemove ,
} from "../../../../function/function";
import {GridTableComponent, GridTableSelect} from "../../../../style/StyleModule";

const SolutionManagement = () => {
  const menuList = ['업체명','업체코드','요청 날짜','솔루션','요청','상태','담당자']

  const [selectEvent, setSelectEvent] = useRecoilState(solutionTypeState)
  const [selectSolutionNo, setSelectSolutionNo] = useRecoilState(selectedCode)
  const [solutionReqList, setSolutionReqList] = React.useState<solutionDataType[]>()
  const [totalPage, setTotalPage] = React.useState<number>(1)
  const [page, setPage] = useRecoilState<number>(persistPage)
  const [pageBlock, setPageBlock] = useRecoilState<number>(persistBlockPage)
  const [searchInput, setSearchInput] = useRecoilState<string>(searchInputState)
  const [prevInput, setPrevInput] = React.useState<string>(searchInput)

  const [errorState,setErrorState] = React.useState<Error_Type> (initError)
  const [, setSelectedCount] = useRecoilState(selectedListCountValue)

  const {t}:any = useTranslation();

  const solutionListRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.get(`/solution/list/${page}/${10}`,{headers:{Authorization}})
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setSolutionReqList([])
      }else{
        setErrorState({notice:'* 요청된 솔루션이 없습니다.',status:valid.status})
        setTotalPage(data.total_page)
        setSolutionReqList(data.row)
      }
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }

  const solutionSearchRes = async () => {
    const stateValue = (state: number) => {
      switch (state) {
        case 0 : return '@'
        case 1 : return 'wait'
        case 2 : return 'run'
        case 3 : return 'cancel'
        case 4 : return 'finish'
      }
    }

    try {
      listLoadingCircle()
      const response = await client.get(`/solution/search/${page}/${10}/${stateValue(selectEvent.index)}/${searchInput === ''? '@' : searchInput}`,{
        headers:{Authorization}
      })
      const valid = response.data
      const data = response.data.data
      setTotalPage(data.total_page)

      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setSolutionReqList([])
      }else{
        setErrorState({notice:'* 요청된 솔루션이 없습니다.',status:valid.status})
        setTotalPage(data.total_page)
        setSolutionReqList(data.row)
      }
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }

  const searchEventFunc = () => {
    setPrevInput(searchInput)
    setPage(1)
    setPageBlock(0)
  }

  const searchInitFunc = () => {
    setSearchInput('')
    setPrevInput('')
    setPage(1)
    setPageBlock(0)
  }

  React.useEffect(()=>{
    if(selectEvent.index === 0 && searchInput === ''){
      solutionListRes().then()
    }else{
      solutionSearchRes().then()
    }
  },[page, selectEvent.index, prevInput])

  const solutionStatusValue = (statement: string, finishDate?: string) => {
    switch (statement) {
      case 'wait': return '대기'
      case 'run' : return '진행중'
      case 'cancel' : return `취소(${finishDate})`
      case 'finish' : return `완료(${finishDate})`
      case 'release' : return '해지 요청'
    }
  }

  return (
    <React.Fragment>
        <SectionFrame>
          <MenuTitle title={t('솔루션 요청관리')} unitCase={'MenuTitle'}/>
          <ContentFrame justify={'space-between'}>
            <div>
              <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', position:'relative'}}>
                <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gap:'16px', alignItems:'center'}}>
                  <MenuTitle title={t('솔루션 요청 리스트')} unitCase={'MenuCompTitle'}/>
                  <div style={{display:'flex',alignItems:'center'}}>
                    {['전체','대기','진행중'].map((v,i)=>(
                      <SelectEventButton buttonStatus={selectEvent.index === i}
                                         buttonBorder={true} buttonName={v} buttonEvent={()=> {
                        setPage(1)
                        setPageBlock(0)
                        setSelectEvent({index: i, value: v})
                      }}/>
                    ))}
                  </div>
                </div>
                <div style={{display:'flex',alignItems:'center'}}>
                  <div style={{alignItems:'center',position:'relative'}}>
                    {/*<DropMenu menuArray={['전체','업체명','요청 솔루션']}*/}
                    {/*          clickEvent={(data)=>{setSearchType(data);setInputState(data==='전체'? '' : inputState)}}*/}
                    {/*          top={0} left={'-175px'}/>*/}
                    {/*<div style={{pointerEvents:`${searchType === '전체'? 'none':'auto'}`}}>*/}

                    <SearchBar clickEvent={searchEventFunc} value={searchInput} placeholder={'검색'}
                               keyFunction={(e)=>setSearchInput(e.target.value.trimStart())} disabled={errorState.status !== 200}
                               clearButton={searchInitFunc} clearButtonAccess={searchInput !== ''}/>
                  </div>
                  <MainButtons name={'등록'} buttonType={'cta'}  width={'80px'} marginDis={true} disabled={buttonAuthValid('create','23')}
                               clickEvent={()=>window.location.href =`/admin/fas/solution/register`}/>
                </div>
              </div>
              <GridTableComponent tempCol={'258px 168px 148px 128px 148px 160px 200px'} scrollX_Hide={true} scrollY_Hide={true}>
                <div className={'grid-title-unchecked'}>
                  {menuList.map((data)=>(
                    <div key={data}>{t(data)}</div>
                  ))}
                </div>
                {solutionReqList?.length === 0 ?  prevInput === ''?
                  listResultMessage(true, errorState.notice,{admin:487, user:487, width:1200 })
                  :
                  listResultMessage(true, '* 검색결과가 없습니다.',{admin:487, user:487, width:1200 })
                  :
                  solutionReqList?.map((v)=>(
                  <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                   style={{pointerEvents: v.statement === 'cancel'? 'none' : 'auto'}}
                                   onMouseDown={()=> {
                                     setSelectSolutionNo(v.solutionNo)
                                     setSelectedCount(v.count)
                                   }}
                                   onDoubleClick={()=> {
                                     window.location.href = `/admin/fas/solution/${v.solutionNo}`
                                   }}
                                   selected={v.solutionNo === selectSolutionNo}
                                   logCenterLine={v.statement === 'cancel'} >
                    {v.count === '0' &&
                      <div style={{
                        position: 'absolute' , top: 0 , left: 0 , width: '8px' , boxSizing:'border-box', margin:'4px',
                        height: '8px' , backgroundColor: '#F0142F', borderRadius:'50%'}}/>}
                    {validationValue(v.companyName)}
                    {validationValue(v.companyCode)}
                    {validationValue((v.requestDate).split(' ')[0])}
                    {validationValue(v.solution)}
                    <div>{v.type ==='subscribe'? '구독' : '구독해지'}</div>
                    <div>{solutionStatusValue(v.statement, dataValid(v.finishDate?.split(' ')[0]))}</div>
                    {validationValue(v.representative)}
                  </GridTableSelect>
                ))
                }
              </GridTableComponent>
            </div>
            {solutionReqList?.length !== 0 &&
                <PaginationList page={page} setPage={setPage}
                                blockNum={pageBlock} setBlockNum={setPageBlock}
                                counts={totalPage} limit={10} pageLimit={10}/>
            }
          </ContentFrame>
        </SectionFrame>
    </React.Fragment>

  )
};

export default SolutionManagement;
