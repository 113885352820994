import React, {useEffect} from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import OverviewContainer from "../../../containers/user/fas/main/OverviewContainer";
import {MobilePageContainer , PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useMediaQuery} from "react-responsive";
import MobileMainContainer from "../../../containers/admin/mobile/fas/MobileMainContainer";
import client from "../../../common/Config";
import {Authorization} from "../../../common/authorization";
import {dataValid} from "../../../function/function";
import {setToken} from "../../../common/tokenManager";
import {useParams} from "react-router-dom";

const FasMain = () => {

	// const params = useParams()
	const isDesktop = useMediaQuery({
																		query : "(min-width:1024px)"
																	})

	const isMobile = useMediaQuery({
																	 query : "(max-width:1023px)"
																 });

	// const loginCheck = async ()=> {
	// 	try {
	// 		const response = await client.post('/login/check',{id: params.id},{headers:{Authorization}})
	// 		console.log(response.data.data)
	// 		const data = response.data.data
	// 		if(data?.loginStatus){
	// 			window.localStorage.setItem( 'companyCode' , data.company_code )
	// 			window.localStorage.setItem( 'profile' , data.image_url )
	// 			window.localStorage.setItem( 'auth' , data.authority )
	// 			window.localStorage.setItem( 'userName' , data.user_name )
	// 			window.localStorage.setItem( 'companyID' , data.id)
	// 			window.localStorage.setItem( 'authoritiesList' , dataValid( JSON.stringify( data.authoritiesList ) , '' ) )
	// 			window.localStorage.setItem( 'loginStatus', data.loginStatus)
	// 			if( !data.authority?.includes( 'admin' ) ){
	// 				window.localStorage.setItem( 'solution' , data.solution )
	// 				window.localStorage.setItem( 'logo' , data.logo_image_url )
	// 				window.localStorage.setItem( 'company' , data.company_name )
	// 				window.localStorage.setItem( 'address' , data.address )
	// 				window.localStorage.setItem( 'alarmCount' , data.alarmCount )
	// 				// window.localStorage.setItem('authoritiesList',JSON.stringify(data.authoritiesList))
	// 			}
	// 			setToken( {
	// 				token: response.data.data.token
	// 			} )
	// 		}
	// 	}
	// 	catch ( e ) {
	// 		console.log(e)
	// 	}
	// }
	//
	// useEffect(()=>{
	// 	if(!window.localStorage.getItem('loginStatus')){
	// 		loginCheck().then()
	// 	}
	// },[])


	return (
		<React.Fragment>
			{
				isMobile &&
				<MobilePageContainer>
					<SectionContainer contentContainer={<MobileMainContainer/>} gradeDisabled={true} responsiveMobile={true}/>
				</MobilePageContainer>}
			{
				isDesktop &&
				<PageContainer>
					<IntegratedSideMenu/>
					<SectionContainer contentContainer={<OverviewContainer/>}/>
				</PageContainer>
			}
		</React.Fragment>

	);
};

export default FasMain;
