import React from 'react';
import styled from "styled-components";
import arrow from '../../asset/image/chevron-left.svg'

interface Props {
	menuArray: {factoryAddress?:string, factoryCode?: string, factoryName?: string}[]
	fontWeight?: string
	clickEvent?: (data:{factoryAddress?:string, factoryCode?: string, factoryName?: string})=>void
	clickIndex?: (i:number)=>void
	currentIndex?: number
	selectCode?: string

}

const FactoryDropMenu:React.FunctionComponent<Props> = ({menuArray=[], fontWeight, clickEvent, selectCode, clickIndex}) => {
	const dropMenuRef = React.useRef<HTMLDivElement>(null)
	const [openMenu, setOpenMenu] = React.useState(false)
	const [listIndex, setListIndex] = React.useState<number>(0)

	React.useEffect(()=>{
			const handleClickOutside = (e:any) => {
				if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
					setOpenMenu(false)
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
		,[dropMenuRef])


	const factoryDataArray = JSON.parse(JSON.stringify(menuArray)) ? JSON.parse(JSON.stringify(menuArray)) : []

	factoryDataArray.unshift({factoryCode:'', factoryName:'선택 없음'})

	React.useEffect(()=>{
		setListIndex(factoryDataArray.findIndex((v:any)=> v.factoryCode === selectCode))
	},[menuArray])


// 	<BaseComponent ref={dropMenuRef} onClick={()=>setOpenMenu(!openMenu)} style={{height: `${openMenu? ((menuArray.length+1)*32)+'px': '32px'}`, maxHeight:'160px'}}>
// <div style={{display:'flex',justifyContent:'space-between',paddingRight:'8px'}}>
// <div style={{fontSize:'14px',fontWeight:fontWeight, paddingLeft:'8px', display:'flex'}}>
// 	{menuArray?.length !== 0?
// 		<div style={{minWidth:'120px'}}>{clickDropValue === -1? '공장이 선택되지 않았습니다.' : menuArray[clickDropValue]?.factoryName}</div>
// 		:
// 		<div style={{minWidth:'120px'}}>{'해당 업체의 공장이 등록되지 않았습니다.'}</div>}
// 	<div>{menuArray[clickDropValue]?.factoryAddress}</div>
// </div>
// 	<img style={{transform:`rotateZ(${openMenu? 90 : 270}deg)`, display:`${menuArray?.length !== 0? 'block' : 'none'}`}} src={arrow} alt=""/>
// </div>
// 	<div className={'spread_menu'}>
//
// 		{menuArray.map((value,index)=>(
// 			<div onClick={()=> {
// 				setClickDropValue(index)
// 				//@ts-ignore
// 				clickEvent(value)
// 			}} style={{fontSize:'14px',fontWeight:fontWeight, display:'flex'}}>
// 				<div style={{minWidth:'120px'}}>{value.factoryName}</div>
// 				<div>{value.factoryAddress}</div>
// 			</div>
// 		))}
// 	</div>
// </BaseComponent>


	return (
		<BaseComponent ref={dropMenuRef} onClick={()=>setOpenMenu(!openMenu)}
					   style={{border:`1px solid ${openMenu? '#0058FF' : '#D7DBEC'}`,
						   height: `${openMenu? ((factoryDataArray.length+1)*32)+'px': '32px'}`, maxHeight:'160px'}}>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{fontSize:'14px',fontWeight:`${openMenu? 700 : 400}`, paddingLeft:'8px', display:'flex'}}>
					{factoryDataArray?.length !== 0?
						<div>{listIndex === -1? '공장을 선택해주세요.' : factoryDataArray[listIndex]?.factoryName}</div>
						:
						<div>{'등록된 공장이 없습니다.'}</div>}
					{/*<div>{menuArray[clickDropValue]?.factoryAddress}</div>*/}
				</div>
				<div style={{paddingRight:'8px',alignSelf:'center'}}>
					<img style={{transition:'transform 0.25s', transform:`rotateZ(270deg) rotateY(${openMenu? 180 : 0}deg)`,
						display:`${factoryDataArray?.length !== 0? 'block' : 'none'}`}} src={arrow} alt=""/>
				</div>
			</div>
			<div className={'spread_menu'}>

				{factoryDataArray.map((value:any, index:number)=>(
					<div onClick={()=> {
						setListIndex(index)
						//@ts-ignore
						clickEvent(value);
						if (clickIndex) {
							clickIndex(index)
						}
					}} style={{fontSize:'14px',fontWeight:fontWeight, display:'flex'}}>
						<div style={{}}>{value.factoryName}</div>
						{/*<div>{value.factoryAddress}</div>*/}
					</div>
				))}
			</div>
		</BaseComponent>
	);
};

export default FactoryDropMenu;

const BaseComponent = styled.div`
		//width: 100%;
    min-width: 192px;
		max-width: 600px;
    //border: 1px solid #D7DBEC;
    border-radius: 4px;
    min-height: 32px;
    line-height: 32px;
    align-items: center;
    box-sizing: border-box;
    //padding: 0 8px;
    align-self: center;
    background-color: #fff;
    top: 8px;
    left: 16px;
    position: absolute;
    overflow: hidden;
    z-index: 50;
    font-weight: bold;
    user-select: none;
    transition: all 0.25s;
    img {
      cursor: pointer;
    }

    .spread_menu {
	    height: 128px;
      font-weight: 400;
	    overflow-y: overlay;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 24px;
        display: block;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #E6E9F4;
        border-radius: 16px;
        background-clip: padding-box;
        border: 8px solid transparent;
      }
	    
      & > div {
        box-sizing: border-box;
        height: 32px;
        cursor: pointer;
        
	      padding-left: 8px;

        &:hover {
          background-color: rgba(0, 88, 255, 0.05);
          //border-bottom: 1px solid #d7dbec;
          color: #0058ff;
          font-weight: 700;
        }
      }
    }
	`