import React , {useState, CSSProperties} from 'react';
import styled from "styled-components";
import ModalBase from "../../ModalBase";
import {modalTitleBase} from "../../../../style/StyleComponent";
import ListInnerInput from "../../../input/ListInnerInput";
import MainButtons from "../../../button/MainButtons";
import {dataValid , initModal , numberLocaleString} from "../../../../function/function";
import {Modal_View_Type} from "../../../../types/Fundamental";
import SelectConfirmModal from "../../SelectConfirmModal";

interface RequireModalType {
  receivedEvent:(data:any)=>void
  productSerial?: string
  productUnit?: string
  selectBom?: {unit: string, serialCode: string, usage: string|number}
  closeEvent:()=>void
}
const RequiredAmountCalcModal:React.FunctionComponent<RequireModalType> = ({productSerial, productUnit, selectBom, receivedEvent, closeEvent}) => {

  const CalcCompStyle:CSSProperties = {height:'48px', fontWeight:700, fontSize:'12px', display:'flex', alignItems:'center', boxSizing:'border-box', padding:'0 30px 0 16px'}
  const CodeCompStyle:CSSProperties = {fontSize:'12px', fontWeight:700, padding:'4px 0'}

  const [countState, setCountState] = useState({productCount:0, usageCount:0})
  const [countCalcUse, setCountCalcUse] = useState<boolean>(false)
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  // selectBom?.usage
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {dataModal.menu === 'select' &&
              <SelectConfirmModal statusText={dataModal.message} noticeText={`사용량 : ${selectBom?.usage} -> ${selectBom?.unit === 'ea'? dataValid(Math.ceil((Number(countState.productCount)/ Number(countState.usageCount))),0) :  dataValid(Number((Number(countState.productCount)/ Number(countState.usageCount)).toFixed(5)),0)}`}
                                  clickEvent={()=>{
                                    receivedEvent(
                                                     selectBom?.unit === 'ea'? Math.ceil((Number(countState.productCount)/ Number(countState.usageCount))).toString() :
                                                       (Number(countState.productCount)/ Number(countState.usageCount)).toFixed(5))
                                  }}
                                  cancelEvent={()=> {
                                    setCountCalcUse( false )
                                    setCountState({productCount:0, usageCount:0})
                                    setDataModal( initModal )
                                  }}
              />}
          {modalTitleBase({mainTitle:'소요량 계산'})}
          <div className={'modal_content'}>
            <div>
              <div style={{...CodeCompStyle}}>
                <span>{'생산 품목 CODE : '}</span>
                <span style={{color:'#0058ff', marginLeft:'4px'}}>{dataValid(productSerial)}</span>
              </div>
              <div style={{...CodeCompStyle}}>
                <span>{'소요 품목 CODE : '}</span>
                <span style={{color:'#0058ff', marginLeft:'4px'}}>{dataValid(selectBom?.serialCode)}</span>
              </div>
            </div>

            <div style={{marginTop:'16px'}}>
              <CountCalcComponent>
                <div style={{display:'flex', backgroundColor:'#F5F6FA'}}>
                  <div style={{...CalcCompStyle}}>{'표준 생산량'}</div>
                  <div style={{display:'flex'}}>
                    <ListInnerInput type={'text'} width={'80px'} limit={6} disabled={countCalcUse}
                                    onChange={(e)=> setCountState((prev:any)=>({...prev, productCount: e.target.value.replace(/[^0-9]/g, '').trim()}))}
                                    inputData={numberLocaleString(countState.productCount)}/>
                    <span style={{alignSelf:'center', marginLeft:'8px',fontSize:'12px', fontWeight:700, opacity:0.5}}>{dataValid(productUnit,'ea')}</span>
                  </div>
                </div>
                <div style={{display:'flex'}}>
                  <div style={{...CalcCompStyle}}>{'표준 소요량'}</div>
                  <div style={{display:'flex'}}>
                    <ListInnerInput type={'text'} width={'80px'} limit={6} disabled={countCalcUse}
                                    onChange={(e)=> setCountState((prev:any)=>({...prev, usageCount: e.target.value.replace(/[^0-9]/g, '').trim()}))}
                                    inputData={numberLocaleString(countState.usageCount)}/>
                    <span style={{alignSelf:'center', marginLeft:'8px',fontSize:'12px', fontWeight:700, opacity:0.5}}>{dataValid(selectBom?.unit,'ea')}</span>
                  </div>
                </div>
              </CountCalcComponent>
            </div>
            {/*<div style={{boxSizing:'border-box', padding:'8px', display:'flex', justifyContent:'right'}}>*/}
            {/*  <MainButtons name={'계산하기'} width={'120px'} buttonType={'cta'} margin={'0'}/>*/}
            {/*</div>*/}

            <div style={{margin:'24px 0', fontSize:'12px', textAlign:'center', fontWeight:700}}>{'소요량 단위가 ea 인 경우 소수점이 나타나지 않습니다.'}</div>
            <div style={{display:'flex', borderTop:'1px solid #D7DBEC', borderBottom:'1px solid #D7DBEC', marginBottom:'60px'}}>
              <div style={{...CalcCompStyle}}>{'개별 소요량'}</div>
              <div style={{display:'flex', marginRight:'40px'}}>
                <div style={{width:'80px', alignItems:'center', display:'flex', fontSize:'14px'}}>{
                  countCalcUse? selectBom?.unit === 'ea'? dataValid(Math.ceil((Number(countState.productCount)/ Number(countState.usageCount))),0) :  dataValid(Number((Number(countState.productCount)/ Number(countState.usageCount)).toFixed(5)),0)
                    : 0}</div>
                <span style={{alignSelf:'center', marginLeft:'8px',fontSize:'12px', fontWeight:700, opacity:0.5}}>{dataValid(selectBom?.unit,'ea')}</span>
              </div>
              <MainButtons name={countCalcUse? '다시 계산하기' : '계산하기'} buttonType={'cta'} width={'120px'}
                           disabled={!countState.productCount || !countState.usageCount}
                           clickEvent={()=> {
                             setCountCalcUse( !countCalcUse )
                             if(countCalcUse){
                               setCountState({productCount:0, usageCount:0})
                             }
                           }}/>
            </div>

            <MainButtons name={'확인'} buttonType={'popupNormal'} clickEvent={()=>{
              if(!countCalcUse){closeEvent()}
              else{
                setDataModal({menu:'select' , message:'계산한 생산량으로 변경 하시겠습니까?'})
              }

            }} width={'120px'}/>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default RequiredAmountCalcModal;

const CountCalcComponent = styled.div`
  border: 1px solid #D7DBEC;
`

const BaseComponent = styled.div`
  width: 416px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    user-select: none;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`