import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import RawMaterials from "../../../containers/admin/fas/company/materials/RawMaterials";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useLocation} from "react-router-dom";
import RawMaterialsRegistration from "../../../containers/admin/fas/company/materials/RawMaterialsRegistration";
import RawMaterialsDetail from "../../../containers/admin/fas/company/materials/RawMaterialsDetail";

const FasRawMaterial = () => {
  const location = useLocation()
  const {pathname} = location
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {pathname.length === (location.pathname.includes('/admin') ? 22 : 16) ?
      <SectionContainer contentContainer={<RawMaterials/>}/>
        :
        location.pathname.includes('register') ?
        <SectionContainer contentContainer={<RawMaterialsRegistration/>}/>
          :
          <SectionContainer contentContainer={<RawMaterialsDetail/>}/>
      }
    </PageContainer>
  );
};

export default FasRawMaterial;