import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import {useLocation} from "react-router-dom";
import FacilityList from "../../../containers/admin/fas/company/facility/FacilityList";
import FacilityDetailList from "../../../containers/admin/fas/company/facility/FacilityDetailList";
import FacilityRegister from "../../../containers/admin/fas/company/facility/FacilityRegister";
import FacilityModify from "../../../containers/admin/fas/company/facility/FacilityModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasFacility = () => {

	const location = useLocation()
	const {pathname} = location
	return (
		<PageContainer>
			<IntegratedSideMenu/>
				<SectionContainer contentContainer={
					pathname.length === (location.pathname.includes('/admin')? 19 : 13)?
					<FacilityList/>
						:
						location.pathname.includes('register') ?
							<FacilityRegister/>
							:
							location.pathname.includes('modify') ?
								<FacilityModify/>
								:
						<FacilityDetailList/>
				}/>
		</PageContainer>
	);
};

export default FasFacility;
