import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import PartnerManagement from "../../../containers/admin/fas/partner/PartnerManagement";
import PartnerDetail from "../../../containers/admin/fas/partner/PartnerDetail";
import {useLocation} from "react-router-dom";
import PartnerRegistration from "../../../containers/admin/fas/partner/PartnerRegistration";
import PartnerModify from "../../../containers/admin/fas/partner/PartnerModify";
import PartnerFacility from "../../../containers/admin/fas/partner/PartnerFacility";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import PartnerUserManagement from "../../../containers/admin/fas/partner/user/PartnerUserManagement";

const AdminFasPartners = () => {

	const location = useLocation()
	return (
		<PageContainer>
			<IntegratedSideMenu/>
			{location.pathname.length === 19 ?
				<SectionContainer contentContainer={<PartnerManagement/>}/>
				:
				location.pathname.includes('register') ?
					<SectionContainer contentContainer={<PartnerRegistration/>}/>
					:
					location.pathname.includes('modify')?
						<SectionContainer contentContainer={<PartnerModify/>}/>
					:
						location.pathname.includes('reg_facility')?
							<SectionContainer contentContainer={<PartnerFacility/>}/>
							:
						<SectionContainer contentContainer={<PartnerDetail/>}/>
			}
		</PageContainer>
	);
};

export default AdminFasPartners;