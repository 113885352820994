import React , {createContext} from 'react';
import MainButtons from "./MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {dataValid , initModal , loadingRemove , modalListLoading} from "../../function/function";
import FileUploadPop from "../modal/FileUploadPop";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "../modal/ConfirmPopup";
import dayjs from "dayjs";
import ToastPopup from "../modal/ToastPopup";

interface Excel_Type {
  type: string
  category: string
  urlStyle?: boolean
  companyData?: any
  disabled?: boolean
  noticeModal?: string
  moldCode?: string
  facilityCode?: string
  formatText?: string
  dateSelect?: string
  method?: string
  params?: any
  defaultStyle?: boolean
  customButtonTitle?: string
  noDateUrl?: boolean
  padding?: string
}


export const ExcelContext = createContext<any>( {} )

const ExcelApiButton: React.FunctionComponent<Excel_Type> = ( {
                                                                type , category , dateSelect , defaultStyle,
                                                                urlStyle , companyData , facilityCode , moldCode , padding,
                                                                noticeModal , disabled , formatText , method , params , customButtonTitle, noDateUrl
                                                              } ) => {
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [loading , setLoading] = React.useState( false )
  const realTime = dayjs().format( 'YYYYMMDD_HHmmss' )
  const getQueryString = ( params: { [x: string]: undefined; } ) => {
    return Object.keys( params ).map( key => {
      const value = key === 'isMaster' ? !!params[key] : params[key]
      return params[key] !== undefined ? `${key}=${value}` : ""
    } ).join( "&" )
  }


   const categoryFormatter = ( category: string ) => {
    switch ( category ) {
      case 'product' :
        return '품목_기본정보'
      case 'peripheral' :
        return '주변장치_기본정보'
      case 'customer' :
        return '거래처기본_관리'
      case 'customerModel' :
        return '거래처모델_관리'
      case 'rawMaterial' :
        return '원자재_기본정보'
      case 'subMaterial' :
        return '부자재_기본정보'
      case 'mold' :
        return '금형_기본정보'
      case 'process/type' :
        return '공정_종류_관리'
      case 'facilityManagement/request' :
        return '설비수리요청'
      case 'facilityManagement/complete' :
        return '설비수리완료'
      case 'facilityProblem/type' :
        return '설비문제유형'
      case 'facilityProblem/type/request' :
        return '설비문제유형'
      case 'moldManagement/request' :
        return '금형수리요청'
      case 'moldManagement/complete' :
        return '금형수리완료'
      case 'moldProblem/type' :
        return '금형문제유형'
      case 'moldProblem/type/request' :
        return '금형문제유형'
      case 'rawMaterial/inventory' :
        return '원자재_재고'
      case 'subMaterial/inventory' :
        return '부자재_재고'
      case 'orders' :
        return '수주_정보'
      case 'deliver' :
        return '출하_정보'
      case 'workOrder' :
        return '작업지시서'
      case 'workHistory' :
        return '작업이력'
      case 'workOrder/complete' :
        return '작업완료'
      case 'product/inventory/status' :
        return '품목 재고 현황'
      case 'rawMaterial/inventory/status' :
        return '원자재 재고 현황'
      case 'subMaterial/inventory/status' :
        return '부자재 재고 현황'
      case 'workHistory/defect' :
        return '불량 이력'
      case 'stock' :
        return '생산_출하_현황'
      case 'vms/excel/inspection' :
        return '비전기 검사 이력' + `(${params?.startDate})`
      case 'vms/excel/statistics/production' :
        return '품목별 통계 및 분석' + `(${params?.range})`
      case 'facility' :
        return '기계_기본정보'
      case 'pms/hwashin/log' :
        return '일일 로드톤 로그'
    }
  }
  const excelFormatDownload = async ( category: string , companyCode?: string ) => {
    setLoading( true )
    try {
      const response = await client.get( urlStyle ? `/${category}/excelFormat/${companyCode}` : `/${category}/excelFormat` ,
                                         ( companyCode !== undefined && !urlStyle ) ? {
                                           params: {companyCode: companyCode} ,
                                           headers: {Authorization} ,
                                           responseType: 'blob'
                                         } : {headers: {Authorization} , responseType: 'blob'}
      )
      const data = response.data
      const blob = new Blob( [data] , {type: 'application/vnd.ms-excel'} )
      const url = window.URL.createObjectURL( blob )
      const link = document.createElement( 'a' )
      link.href = url
      link.setAttribute( 'download' , `${companyData.companyName}(${companyData.companyCode})_${categoryFormatter( category )}_양식_${realTime}.xlsx` )
      document.body.appendChild( link )
      link.click()
      link.parentNode?.removeChild( link )
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: '양식을 다운로드 할 수 없습니다.'} )
    } finally {
      setLoading( false )
    }
  }
  const excelUpload = async ( data: any , category: string , companyCode: string ) => {
    modalListLoading()
    const formData = new FormData()
    formData.append( 'file' , data )
    formData.append( 'companyCode' , companyCode )
    if(category.includes('rawMaterial') ||category.includes('subMaterial')){
      formData.append('saveType','@')
    }
    //설비코드 or 금형코드 가 있는 경우 formData 에 추가됨
    if( facilityCode ){
      formData.append( 'facilityCode' , facilityCode )
    }
    if( moldCode ){
      formData.append( 'moldCode' , moldCode )
    }
    try {
      const response = await client.post( `/${category}/excelUpload` , formData , {
        headers: {Authorization}
      } )
      if( response.data.status === 200 ){
        setTimeout( () => {
          setDataModal( {menu: 'success' , message: '엑셀 데이터가 등록 되었습니다.'} )
          loadingRemove()
          setTimeout( () => {
            setDataModal( initModal )
            window.location.reload()
          } , 1000 )
        } , 500 )
      } else{
        setDataModal( {menu: 'notUpload' , message: response.data.message} )
        loadingRemove()
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: '엑셀 양식을 확인해 주세요.'} )
      loadingRemove()
    }
  }
  const excelDataDownload = async ( category: string , companyCode?: string ) => {
    setLoading( true )
    try {
      const config: any = {headers: {Authorization} , responseType: 'blob'}
      const response = method === 'post' ? await client.post( `/${category}/excelDownload` , params , config )
        : await client.get( !dateSelect ? params ? `/${category}/excelDownload?${getQueryString( params )}` : `/${category}/excelDownload/${companyCode}` : `/${category}/excelDownload/${companyCode}/${dateSelect}` ,
                            config )
      const data = response.data
      const blob = new Blob( [data] , {type: 'application/vnd.ms-excel'} )
      const url = window.URL.createObjectURL( blob )
      const link = document.createElement( 'a' )
      link.href = url
      link.setAttribute( 'download' , (!dateSelect || noDateUrl)? `${companyData.companyName}(${companyData.companyCode})_${categoryFormatter( category )}_목록_${realTime}.xlsx` : `${companyData.companyName}(${companyData.companyCode})_${categoryFormatter( category )}/(${dateSelect})_목록_${realTime}.xlsx` )
      document.body.appendChild( link )
      link.click()
      link.parentNode?.removeChild( link )
      if(noticeModal){
        setDataModal( {menu: 'success' , message: noticeModal} )
      }
    } catch ( e ) {
      console.log( e )
      // setDataModal( {menu: 'error' , message: '엑셀 데이터를 다운로드 할 수 없습니다.'} )
    } finally {
      setLoading( false )
    }
  }
  const formatMessage = () => {
    setDataModal( {menu: 'confirm' , message: '양식 파일명을 변경할 경우 \n 정상적으로 등록되지 않을 수 있습니다.'} )
    excelFormatDownload( category , companyData.companyCode ).then()
    setTimeout( () => {
      setDataModal( initModal )
    } , 3000 )
  }
  const excelApi = ( excelType: string ) => {
    switch ( excelType ) {
      case 'format' :
        return (
          //버튼 새로운 스타일 추가 (추후 전체 변경)
          <MainButtons name={customButtonTitle ?? 'Excel 양식'} width={'120px'} height={defaultStyle? '32px' : '72px'} buttonType={defaultStyle? '' : 'excel'}
                       padding={defaultStyle? '0' : '20px'}
                       clickEvent={() => {
                         noticeModal ? setDataModal( {menu: 'confirm' , message: noticeModal} )
                           :
                           formatMessage()
                       }} disabled={!companyData.companyCode || disabled || loading} loading={loading}/>
          // <MainButtons name={'Excel 양식'} width={'80px'} clickEvent={()=> {
          //   noticeModal? setDataModal({menu:'confirm', message: noticeModal})
          //     :
          //     formatMessage()
          // }} disabled={!companyData.companyCode || disabled || loading} loading={loading}/>
        )
      case 'upload' :
        return (
          //버튼 새로운 스타일 추가 (추후 전체 변경)
          <MainButtons name={customButtonTitle ?? 'Excel 등록'} width={'120px'} height={defaultStyle? '32px' : '72px'} buttonType={defaultStyle? '' : 'excel'}
                       padding={defaultStyle? '0' : '20px'}
                       clickEvent={() => {
                         setDataModal( {menu: 'excel'} )
                       }} disabled={( !companyData.companyCode || disabled )}/>
          // <MainButtons name={'Excel 등록'} width={'80px'} clickEvent={()=> {
          //   setDataModal({menu:'excel'})
          // }} disabled={(!companyData.companyCode || disabled)}/>
        )
      case 'download' :
        return (
          //버튼 새로운 스타일 추가 (추후 전체 변경)
          <MainButtons name={customButtonTitle ?? 'Excel로 받기'} width={'120px'} height={defaultStyle? '32px' : '72px'} buttonType={defaultStyle? '' : 'excel'}
                       padding={!padding? defaultStyle? '0' : '20px' :  padding}
                       clickEvent={() => {
                         excelDataDownload( category , companyData.companyCode ).then()
                       }} disabled={!companyData.companyCode || disabled || loading} loading={loading}/>
          // <MainButtons name={'Excel로 받기'} width={'96px'} clickEvent={()=> {
          //   excelDataDownload( category , companyData.companyCode).then()
          // }} disabled={!companyData.companyCode || disabled || loading} loading={loading}/>
        )
    }
  }
  return (
    // <ExcelContext.Provider value={{categoryFormatter,excelFormatDownload,excelUpload,excelDataDownload,category}}>
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} timeSet={3000}
                                                  closeEvent={() => setDataModal( initModal )}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={dataModal.message} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ?
            <ToastPopup text={dataModal.message} state={'warn'} closeEvent={() => setDataModal( initModal )}
                        timeSet={1000}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} noticeText={formatText}
                        clickEvent={() => {
                          if( dataModal.message === '엑셀 데이터가 등록 되었습니다.' ){
                            window.location.reload()
                          }
                          setDataModal( initModal )
                        }}/>}

      {dataModal.menu === 'notUpload' &&
          <ConfirmPopup statusText={dataModal.message} noticeText={'엑셀을 등록할 수 없습니다.'} buttonDelay={true}
                        clickEvent={() => {setDataModal( initModal )}}/>}


      {dataModal.menu === 'excel' &&
          <FileUploadPop uploadEvent={( v ) => excelUpload( v , category , ( dataValid( companyData.companyCode ) )
          )} closeEvent={() => setDataModal( initModal )} category={category}/>}
      {excelApi( type )}
    </React.Fragment>


  );
};
export default ExcelApiButton;
