import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import Header from "../../components/header/Header";
import DevelopGrade from "../../components/display/DevelopGrade";
import MobileSideMenu from "../../components/sideMenu/MobileSideMenu";
import {useLocation , useParams} from "react-router-dom";

interface Container_Props {
	contentContainer : any
	gradeDisabled?: boolean
	responsiveMobile?: boolean
	gradeLevel?:number
}

const SectionContainer:React.FunctionComponent<Container_Props> = ({contentContainer, gradeDisabled, responsiveMobile, gradeLevel}) => {
	// const isDesktop = useMediaQuery({
	// 																	query : "(min-width:768px)"
	// 																})
	//
	//
	// const isMobile = useMediaQuery({
	// 																 query : "(max-width:767px)"
	// 															 });


	const [headerDisabled, setIsHeaderDisabled] = useState(false);
	const location = useLocation();

	useEffect(() => {
	 
		const checkDisableHeader = () => {
			const disableHeaderRegex = [
				/\/admin\/fas\/process/,
				/\/admin\/fas\/drawing/,
				/\/admin\/fas\/wiki/,
				/\/admin\/fas\/solution/,
				/\/admin\/fas\/partners/,
				/\/admin\/fas\/business/,
				/\/admin\/fas\/info/,
				/\/admin\/fas\/basic\/(?!auth$|user$)[^\/]+/,
				/\/admin\/fas\/factory\/[^\/]+/,
				/\/admin\/fas\/account\/[^\/]+/,
				/\/admin\/fas\/model\/[^\/]+/,
				/\/admin\/fas\/facility\/[^\/]+/,
				// /\/admin\/fas\/facility/,
				/\/admin\/fas\/peripherals\/[^\/]+/,
				/\/admin\/fas\/product\/[^\/]+/,
				/\/admin\/fas\/rawmaterial\/[^\/]+/,
				/\/admin\/fas\/submaterial\/[^\/]+/,
				/\/admin\/fas\/mold\/[^\/]+/,
				/\/admin\/pbas\/main/,
        /\/admin\/pbas\/managelog/,
				/\/admin\/pbas\/unit/,
				/\/admin\/mes\/process/,
				/\/admin\/mes\/shipment_integrate_reg/,
				/\/admin\/mes\/shipment_indi_reg/,
				/\/admin\/mes\/raw_reg/,
				/\/admin\/mes\/raw_reg_dispense/,
				/\/admin\/mes\/work_history\/[^\/]+/,
			];
			return disableHeaderRegex.some(regex => regex.test(location.pathname));
		};

		setIsHeaderDisabled(checkDisableHeader());
	}, [location]);


	const DesktopContainer = () => {
		return (
			<Container style={{minWidth: '1350px'}}>
				<Header disabled={headerDisabled}/>
				<ContentSection>
					{contentContainer}
					{!gradeDisabled && <DevelopGrade gradeLevel={gradeLevel}/>}
				</ContentSection>
			</Container>
		)
	}

	const MobileContainer = () => {
		return (
			<Container style={{minWidth: '100vw'}}>
				<Header responsiveMobile={true}/>
				{/*<MobileSideMenu/>*/}
				<ContentSection>
					{contentContainer}
					{!gradeDisabled && <DevelopGrade gradeLevel={gradeLevel}/>}
				</ContentSection>
			</Container>
		)
	}

	return (
		<React.Fragment>
			{responsiveMobile?
				MobileContainer()
				:
				DesktopContainer()
			}
			{/*{isDesktop &&*/}
			{/*	<Container style={{minWidth: '1350px'}}>*/}
			{/*		<Header/>*/}
			{/*		/!*{isMobile && <MobileSideMenu/>}*!/*/}
			{/*		<ContentSection>*/}
			{/*			{contentContainer}*/}
			{/*			{!gradeDisabled && <DevelopGrade/>}*/}
			{/*		</ContentSection>*/}
			{/*	</Container>}*/}
			{/*{isMobile &&*/}
			{/*	<Container style={{minWidth: '100vw'}}>*/}
			{/*		<Header/>*/}
			{/*		{isMobile && <MobileSideMenu/>}*/}
			{/*		<ContentSection>*/}
			{/*			{contentContainer}*/}
			{/*			{!gradeDisabled && <DevelopGrade/>}*/}
			{/*		</ContentSection>*/}
			{/*	</Container>*/}
			{/*}*/}
		</React.Fragment>
	);
};

export default SectionContainer;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	overflow: hidden;
`
const ContentSection = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #f4f7fc;
	width: 100%;
	height: 100%;
	position: relative;
	justify-content: space-between;
`