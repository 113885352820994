import React , {useEffect , useRef , useState} from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import {CalendarOptions} from "@fullcalendar/core";
import dayjs from "dayjs";
import styled from "styled-components";
import MainButtons from "../../../../components/button/MainButtons";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  listLoadingCircle ,
  loadingRemove ,
  locationFilter
} from "../../../../function/function";
import SearchBar from "../../../../components/input/SearchBar";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Modal_View_Type} from "../../../../types/Fundamental";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../components/modal/ToastPopup";
import {useRecoilState} from "recoil";
import {contactDataPanel , searchPersistState} from "../../../../common/StateManage";
import MultipleCompanySelectModal from "../../../../components/modal/MultipleCompanySelectModal";

interface BizCalendarProps {
  registerEvent?:()=>void
  containerMove:string
}


const BizCalendar: React.FC<BizCalendarProps> = ({registerEvent,containerMove}) => {
  const [currentDate , setCurrentDate] = useState( dayjs().format( 'YYYY년 MM월' ) );
  const [events,setEvents] = React.useState<any>()
  const [popupInfo , setPopupInfo] = useState<any>( null );
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
  const [searchState,setSearchState] = React.useState<{input:string, value:string}>({input:'', value:''})
  const [selectDate,setSelectDate] = React.useState<any | Date | undefined>();
  // const [searchState, setSearchState] = useRecoilState<{input:string, value:string}>(searchPersistState)

  const calendarRef = useRef<FullCalendar | null>(null);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const [dateValue, setDateValue] = useState(dayjs().format('YYYY-MM'));
  console.log(dateValue)
  const options: CalendarOptions = {
    plugins: [dayGridPlugin] ,
    initialView: 'dayGridMonth' ,
    height: 620 ,
    contentHeight:'620',
    allDayClassNames: "allDay" ,
    nowIndicator: true ,
    locale: 'ko' ,
    dayMaxEventRows: 3,
    // rerenderDelay:500,
    moreLinkText: function(num) {
      return `+ ${num} 더보기`;
    },
    headerToolbar: {
      left: 'title' ,
      center: '' ,
      right: '' ,
    },
    customButtons: {
      customTodayButton: {
        text: '오늘' ,
        click: function () {
          // @ts-ignore
          const calendarApi = calendarRef.current.getApi();
          calendarApi.today();
        }
      }
    },
  };

  let requestCount = 0

  const businessListRes = async () =>{
    requestCount++
    listLoadingCircle()
    try {
      const response = await client.get(`/business/management/calendar/${dateValue}/${dataValid(searchState.value,'@')}`,{
        headers:{Authorization}
      })
      const data = response.data.data
        // setPushValue('')
        // setSearchValue('')
        setEvents(data)
      loadingRemove(500)
    }catch ( e:any ) {
      console.log(e)
      loadingRemove()
    }finally {
        requestCount--
    }
  }

  // const businessSearchListRes = async () =>{
  //   listLoadingCircle()
  //   try {
  //     const response = await client.get(`/business/management/search/${1}/${10000}/${'@'}/@/${dataValid(searchValue,'@')}`,{
  //       headers:{Authorization}
  //     })
  //     const data = response.data.data
  //     const valid = response.data
  //     if(valid.status !== 200){
  //       setEvents([])
  //     }else{
  //         setEvents(data.row)
  //     }
  //     loadingRemove()
  //   }catch ( e:any ) {
  //     console.log(e)
  //     loadingRemove()
  //   }
  // }

  const businessDeleteRes = async (code:string) =>{
    dataAccessLoading()
    try {
      const response = await client.post('/business/management/delete',{
        businessManagementCode:code
      }, {headers: {Authorization}})
      if(response.data.status !== 200){
        setDataModal({menu:'confirm',message:response.data.message})
        loadingRemove()
      }else{
        setTimeout(()=>{
          setDataModal({menu:'success',message:'삭제 되었습니다.'})
          loadingRemove()
        },500)
      }
    }catch (e:any){
      console.log(e)
      setDataModal({menu:'error',message:e.message})
      loadingRemove()
    }
  }

  const maintenanceDeleteRes = async (code:string) =>{
      try {
        dataAccessLoading()
        const response = await client.post(`/maintenance/action/delete`,{
          maintenanceHistoryCode:code,
        },{headers: {Authorization}})
        if(response.data.status === 200){
          setTimeout(()=>{
            setDataModal({menu:'success',message:'삭제가 완료되었습니다.'})
            loadingRemove()
          },500)
        }else{
          setDataModal({menu:'warn',message:response.data.message})
          loadingRemove()
        }
      }catch ( e ) {
        console.log(e)
        setDataModal({menu:'error',message:'서버에러 입니다. 관리자에게 문의해주세요.'})
        loadingRemove()
      }
  }


  const renderEventContent = ( eventInfo: any ) => {
    const companyName = eventInfo.event.extendedProps.companyName;
    const division = eventInfo.event.extendedProps.division;
    const businessCode = eventInfo.event.extendedProps.businessManagementCode;
    const asCode =  eventInfo.event.extendedProps.maintenanceHistoryCode;
    const visitDate = new Date(eventInfo.event.extendedProps.visitDate);
    const eventDate = dayjs( eventInfo.event.start );
    const currentMonthInView = dayjs( eventInfo.view.currentStart );
    if( currentMonthInView.format( 'M' ) !== eventDate.format( 'M' ) ){
      return (
        <div style={{display: 'flex' , alignItems: 'center' , padding: '4px' ,opacity: 0.5}}> {/* opacity를 사용하여 투명도를 줄임 */}
          <div className="fc-event-dot" style={{backgroundColor: '#828282'}}></div>
          <div className="fc-event-title">{`[` +division+ `] ` + dataValid(companyName)} </div>
        </div>
      );
    } else{
      return (
          <div style={{display: 'flex' , alignItems: 'center' , padding: '4px',
            backgroundColor:
              (new Date() < visitDate && businessCode === popupInfo?.extendedProps?.businessManagementCode)
                ? 'rgba(249,150,0,0.3)' :
              (division === '영업' && businessCode === popupInfo?.extendedProps?.businessManagementCode)
              ? 'rgba(33, 213, 155, 0.3)' :
              (division === 'A/S' && asCode === popupInfo?.extendedProps?.maintenanceHistoryCode)
                ? 'rgba(0, 88, 255, 0.3)' : ''

          }}>
            <div className="fc-event-dot" style={{backgroundColor: new Date() < visitDate ? 'rgb(249,150,0)' : division === '영업' ? '#21D59B' : '#0058FF'}}></div>
            <div className="fc-event-title">{`[` +division+ `] ` + dataValid(companyName)}</div>
          </div>
      );
    }
  };
  const handleEventClick = ( clickInfo: any ) => {

    const clickedDate = dayjs(clickInfo.event.start);
    const currentMonthInView = dayjs(clickInfo.view.currentStart);
    const eventMonth = clickedDate.startOf('month');
    if (!eventMonth.isSame(currentMonthInView, 'month')){
      //@ts-ignore
      const calendarApi = calendarRef.current.getApi();
      const popover:any = document.querySelector('.fc-popover');
      if (eventMonth.isAfter(currentMonthInView)) {
        calendarApi.next();
        popover.style.display = 'none';
      } else if (eventMonth.isBefore(currentMonthInView)) {
        calendarApi.prev();
        popover.style.display = 'none';
      }
      return
    }

    setPopupInfo( clickInfo.event )

    const rect = clickInfo.el.getBoundingClientRect();

    setPosition({
                  top: (rect.top + window.pageYOffset) / window.innerHeight * 100,
                  left: (rect.left + window.pageXOffset + rect.width) / window.innerWidth * 100,
                });
  }
  document.documentElement.style.setProperty('--fc-border-color', 'none');
  document.documentElement.style.setProperty('--fc-event-selected-overlay-color', 'none');

  React.useEffect(()=>{
      businessListRes().then()
  },[containerMove,searchState.value, dateValue])

  const EventModal: React.FC< {event:any}> = ({ event }) => {
    const division = event?.extendedProps.division;
    const companyName = event?.extendedProps.companyName;
    const address = event?.extendedProps.address;
    const registrationDate = event?.extendedProps.registrationDate;
    const visitDate = new Date(event?.extendedProps.visitDate);
    const writer = event?.extendedProps.writer;
    const businessManagementCode = event?.extendedProps?.businessManagementCode;
    const maintenanceHistoryCode = event?.extendedProps?.maintenanceHistoryCode;
    return (
      //?
      <EventPop header={new Date < visitDate ? 'rgb(249,150,0)' :  division === '영업' ? `#21D59B` : '#0058FF'} ref={popupRef} style={{top:`${(position.top - 0.5) > 80 ? 80 : (position.top - 0.5)}%`,left:`${position.left > 83? 83 : position.left}%`}}>
        <div className={'header'}/>
        <div style={{ display: 'flex', alignItems: 'center',boxSizing:'border-box', flexDirection: 'column',padding:'24px 20px 0px 20px'}}>
          <div className={'content'}
               style={{ display: 'flex',  flexDirection:'column',justifyContent: 'space-between', width: '100%'}}
               onClick={() => {
                  window.location.href = division === '영업'? `/admin/fas/business/${businessManagementCode}` : `/admin/pbas/managelog/detail/${maintenanceHistoryCode}`
               }}>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
              <div style={{fontWeight:'700',fontSize:'16px'}}>{companyName}</div>
              <div>{address}</div>
            </div>
            <div style={{display:'flex',flexDirection:'column',alignItems:'start',marginTop:'10px'}}>
              <div>{registrationDate}</div>
              <div style={{marginTop:'10px'}}>{writer}</div>
            </div>
          </div>
          <div className={'horizontalLine'}></div>
          <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%',marginTop:'10px'}}>
            <div className={'linkText'} style={{
              opacity:buttonAuthValid('update','22')  ? 0.5 : 1,
              pointerEvents:buttonAuthValid('update','22') ? 'none':'auto'}}
                 onClick={()=>{window.location.href = division === '영업' ? `/admin/fas/business/modify/${businessManagementCode}` : `/admin/pbas/managelog/modify/${maintenanceHistoryCode}`}}>
              {'수정'}
            </div>

            <div className={'verticalLine'}/>
            <div className={'linkText'} style={{
              opacity:buttonAuthValid('delete','22') ? 0.5 : 1,
              pointerEvents:buttonAuthValid('delete','22') ? 'none':'auto'}}
                 onClick={()=>{setDataModal({menu:'select',specificWord:division,index:division === '영업' ? businessManagementCode : maintenanceHistoryCode,message:'삭제 하시겠습니까?'})}}>{'삭제'}</div>
          </div>
          {/*<MainButtons name={'닫기'} buttonType={''} width={'80px'} marginDis={true} clickEvent={() => setPopupInfo(null)} />*/}
        </div>
      </EventPop>
    );
  };

  // useEffect(() => {
  //   const handleClickOutside = (e: any) => {
  //     if (popupRef.current && !popupRef.current.contains(e.target)) {
  //       setPopupInfo(null);
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [setPopupInfo, popupRef]);

  //
  // useEffect( () => {
  //   //달 이동 해도 색상유지하게 하기
  //
  //   const update = () => {
  //     const today = dayjs().format( 'YYYY-MM-DD' );
  //     const todayElement = document.querySelector( `[data-date="${today}"]` );
  //     if( todayElement ){
  //       todayElement.classList.add( 'fc-today' );
  //     }
  //   }
  //
  //
  //
  //   const sunday = document.querySelectorAll( '.fc-day-sun' );
  //   sunday.forEach( ( item ) => {
  //     const number = item.querySelector( '.fc-daygrid-day-number' );
  //     if( number ){
  //       // @ts-ignore
  //       number.style.color = '#F0142F';
  //     }
  //   } );
  // } , [] );
  return (
    <CalendarStyle onClick={()=>{setPopupInfo(null)}}>
      {popupInfo && <EventModal event={popupInfo}/>}
      {dataModal.menu === 'confirm' && <MultipleCompanySelectModal setData={[]} receiveData={()=>''} closeEvent={()=>{setDataModal(initModal)}}
                                                                   date={selectDate} idType={true}
                                                                   closePersistPopupEvent={()=>{
                                                                     setDataModal(initModal);
                                                                     setTimeout(()=>{
                                                                       setDataModal({menu:'success',message:'등록 되었습니다.'})
                                                                     },1000)
                                                                   }}/>}

      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={' * "예" 선택시 모든데이터는 삭제됩니다.'}
                                                          clickEvent={()=>{
                                                            if(dataModal.specificWord === '영업'){
                                                              businessDeleteRes(dataModal.index).then();
                                                            }else{
                                                              maintenanceDeleteRes(dataModal.index).then();
                                                            }
                                                            setDataModal(initModal)}}
                                                          cancelEvent={()=>(setDataModal(initModal))}/>}
      {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} closeEvent={()=>{businessListRes().then(); setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={dataModal.message} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

      <div style={{display:'flex',alignItems:'center',position:'absolute',left:'120px',top:'-5px',justifyContent:'space-between'}}>
        <MainButtons name={'<'} buttonType={''} width={'42px'} marginDis={true} margin={'0 8px'}
                     clickEvent={() => {
                       setPopupInfo(null)
                       //@ts-ignore
                       const calendarApi = calendarRef.current.getApi();
                       calendarApi.prev();
                     }
                     }/>
        <MainButtons name={'>'} buttonType={''} width={'42px'} marginDis={true}
                     clickEvent={() => {
                       setPopupInfo(null)
                       //@ts-ignore
                       const calendarApi = calendarRef.current.getApi();
                       calendarApi.next();
                     }
                     }/>
        <MainButtons name={'오늘'} buttonType={'cta'} width={'56px'} marginDis={true}
                     clickEvent={() => {
                       setPopupInfo(null)
                       //@ts-ignore
                       const calendarApi = calendarRef.current.getApi();
                       calendarApi.today();
                      }
                     }/>
      </div>
      <div style={{display:'flex',alignItems:'center',position:'absolute',right:'0',top:'-5px',justifyContent:'space-between'}}>
        <SearchBar placeholder={'검색'} keyFunction={(e)=>{setSearchState((prev)=>({...prev, input: e.target.value.trimStart()}))}}
                   clearButtonAccess={searchState.input !== ''} clearButton={()=>{setSearchState({input:'',value:''})}}
                   clickEvent={()=>{setSearchState((prev)=>({...prev, value: prev.input}));}} value={searchState.input}/>

        <MainButtons name={'등록'} buttonType={'cta'} marginDis={true} clickEvent={registerEvent} disabled={buttonAuthValid('create','22')}
        />
      </div>
        <FullCalendar {...options}
                      datesSet={(dateInfo)=>{
                        // setCurrentDate( dateInfo.view.title)/**/
                        setDateValue(dayjs(dateInfo.view.currentEnd).subtract(1,'month').format('YYYY-MM'))
                      }}
                      // moreLinkClick={(data)=>{console.log(data.view)}}
                      events={events?.map((v:any,index:number)=>(
                        { businessManagementCode:v.businessManagementCode,
                          maintenanceHistoryCode:v.maintenanceHistoryCode,
                          companyName:v.companyName,
                          division:v.division,
                          address:locationFilter(v.address),
                          start:dayjs(v.visitDate).format('YYYY-MM-DD'),
                          writer:v.writer,
                          registrationDate:dayjs(v.registrationDate).format('YYYY-MM-DD'),
                          visitDate:dayjs(v.visitDate).format('YYYY-MM-DD'),
                          // backgroundColor:'transparent'
                          // backgroundColor:(v.businessManagementCode === popupInfo?.extendedProps?.businessManagementCode) && (v.maintenanceHistoryCode === popupInfo?.extendedProps?.maintenanceHistoryCode)  ?  '' : '',
                        }
                      ))}
                      dayCellContent={(date)=>{return (
                        <div style={{width:'156px',pointerEvents:'initial',userSelect:'none'}}
                          //@ts-ignore
                             onDoubleClick={()=>{setDataModal({menu:'confirm'}); setSelectDate(date)}}>
                          {date.dayNumberText}
                        </div>
                      )}}
                      eventClick={handleEventClick}
                      eventContent={renderEventContent}
                      ref={calendarRef}
                      />
    </CalendarStyle>

  );
};
export default BizCalendar;
const CalendarStyle = styled.div`
  position: relative;

  .fc-daygrid-day-number{
    font-weight: 600;
  }

  .fc-popover-title{
    font-size: 12px;
    font-weight: bold;
  }
  .fc,.fc-popover{
    z-index: 99;
  }
     //.fc-daygrid-day-frame, .fc-scrollgrid-sync-inner{
     //  cursor: pointer;
     //  &:hover{
     //    background: rgba(33, 213, 155, 0.3);
     //  }
     //}
     //
     //.fc-highlight{
     //  background: rgba(33, 213, 155, 0.3);
     //}

  
  .fc-popover-header{
    background: rgb(207, 250, 97)
  }
  .fc-more-popover-misc{
    display: none;
  }
  .fc-popover-body{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .fc-header-toolbar {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 20px;
    padding: 0;
  }

  //월 표시
  .fc-toolbar-title {
    font-size: 18px;
    font-weight: 800;
    color: #000000;
  }
  .fc-toolbar-chunk {
    margin-right: 10px;
  }
  
//요일 
  .fc-col-header{
    margin-bottom: 8px;
  }
  .fc-col-header-cell.fc-day {
    font-size: 12px;
    text-align: left;
  }
  //일요일
  .fc-col-header-cell.fc-day.fc-day-sun {
    color :#F0142F;
  }
  .fc-day-sun a {
    color: #F0142F;
  }

  //날짜 
  .fc-daygrid-day-number {
    font-size: 12px;
    color: #000000;
    padding: 0;
    margin: 4px 0;
    &:hover {
      cursor: pointer;
      color: #0058FF;
    }
  }
  //이벤트 더보기 
  .fc-daygrid-more-link.fc-more-link{
    align-items: center;
    font-size: 12px;
    color:  #000000;
    font-weight: bold;
    opacity: 0.5;
    &:hover{
      text-decoration: underline;
      text-underline-offset: 3px;
      background-color: #f1f6fe;
      border: none;
    }
  }
  
  //이벤트
  .fc-event {
    width: 148px;
    border: none;
    cursor: pointer;
    background: transparent;
    &:hover {
      background-color: #f1f6fe;
      border: none;
    }
    //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }

  
  //이벤트 텍스트 
  .fc-event-title {
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    color: #131523;
  }
  
  //이벤트 점
  .fc-event-dot {
    width: 6px;
    height: 6px;
    margin-right: 5px;
    border-radius: 50%;
    box-sizing: border-box;
  }
;
// 전체 그리드의 선 스타일 바꾸기 
   .fc-scrollgrid-sync-table {
      border-collapse:  separate;
   }
  
  .fc-daygrid-day {
    width: 168px;
    height: 96px;
    background-color: #fff;
    border: 1px solid #D5D7E3;
    border-radius: 6px !important;
    padding: 8px;
  }
  .fc-daygrid-day-top {
    flex-direction: row;
  }
.
  /* 오늘의 날짜 커스텀 */


  & .fc-daygrid-day {
    &.fc-day-today {
      background-color: #FFF;
    }
  }
  .fc-daygrid-day.fc-day-today {
    background: #F5F6FA;
    border: 1px solid #0058FF;
  }
`

const EventPop = styled.div<{header?:string}>`
  position: fixed;
  box-shadow: 0 3px 6px #00000029;
  box-sizing: border-box;
  width: 312px;
  height: 176px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #fff;
  z-index: 100;

  
  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #131523;
    &:hover {
      cursor: pointer;
      color: #0058FF;
    }
  }
  
   .header{
    width: 100%;
    height:8px;
   background-color: ${props=>props.header};
    box-sizing: border-box;
    border-radius: 6px 6px 0 0;
     //font-size: 16px;
     //font-weight: bold;
     display: flex;
     align-items: center;
  }
  .horizontalLine {
    width: 270px;
    height: 1px;
    margin-top:20px;
    background-color: #D7DBEC;
  }
  .verticalLine {
    width: 1px;
    height: 24px;
    background-color: #D7DBEC;
  }
  .linkText {
    width: 120px;
    box-sizing: border-box;
    font-size: 12px;
    color: #7E84A3;
    font-weight: 600;
    text-align: center;
    &:hover {
      cursor: pointer;
      color: #0058FF;
    }
  }
  &::before, &::after {
    content: "";
    position: absolute;
    top: 10%;
    left: -15px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    transform: translateY(-50%) rotate(90deg);
  }

  &::before {
    border-top: 10px solid rgba(0, 0, 0, 0.1);  
    z-index: -1;  
    left: -16px;  
  }
  &::after {
    border-top: 10px solid #fff; // 실제 삼각형의 색상
  }



`
