import cookie from "react-cookies";

function setToken(userInfo: any) {
	// @ts-ignore
	// client.defaults.headers.Authorization = userInfo.token;
	const expires = new Date()

	expires.setHours(expires.getHours() + 10)
	const getTimestamp = expires.getTime()

		//@ts-ignore
	window.localStorage.setItem('timestamp', getTimestamp)

	cookie.save(
		'userInfo'
		, userInfo
		, {
			path: '/'
			, expires
			, httpOnly: false
		}
	);
}

export { setToken };
