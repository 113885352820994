import React,{useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../components/title/MenuTitle";

import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {
	mainAlarmDataType , mainSolutionAlarmDataType ,
	OverviewMainPressDataType ,
} from "../../../../types/Fundamental";
import FasMenuSquare from "../../../../components/square/FasMenuSquare";
import {dataValid} from "../../../../function/function";
import MainButtons from "../../../../components/button/MainButtons";
import {useRecoilState , useResetRecoilState} from "recoil";
import {companySolutionFilter , persistCompanyData} from "../../../../common/StateManage";
import FasMapOverviewContainer from "./FasMapOverviewContainer";

interface Main_Props{
	mapState:(state:boolean)=>void
}
const MainContainer:React.FunctionComponent<Main_Props> = ({mapState}) => {

	const [overviewData, setOverviewData] = useState<OverviewMainPressDataType>()
	const [mainAlarmState, setMainAlarmState] = useState<mainAlarmDataType>()
	const [solutionState, setSolutionState] = useState<mainSolutionAlarmDataType>()
	const [femsInfoData, setFemsInfoData] = useState<{companyCount: string|number, kepCoApiCount: string|number, "F-EMSCount": string|number}>()
	const resetCompany = useResetRecoilState(persistCompanyData)

	const [mapOpen, setMapOpen] = useState(false)
	const [,setSelectSolution] = useRecoilState<string>(companySolutionFilter)
	const pressInfoRes = async () => {
		try {
			const response = await client.post('/main/info/press',{
			},{headers: {Authorization}})
			setOverviewData(response.data.data)
		}catch (e) {
			console.log(e)
		}
	}

	const alarmCountRes = async () => {
		try {
			const response = await client.get('/main/alarm',{headers:{Authorization}})
			setMainAlarmState(response.data.data)
		}catch (e) {
			console.log(e)
		}
	}

	const solutionResponse = async () => {
		try {
			const response = await client.get('/main/solution',{headers:{Authorization}})
			const data = response.data.data
			setSolutionState(data)
		}catch (e) {
			console.log(e)
		}
	}

	const femsInfoRes = async () => {
		try {
			const response = await client.get('/main/info/F-EMS',
			{headers: {Authorization}})
			const data = response.data.data
			setFemsInfoData(data)
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		femsInfoRes().then()
		pressInfoRes().then()
		alarmCountRes().then()
		solutionResponse().then()
		let interval = setInterval(()=>{
			pressInfoRes().then()
			alarmCountRes().then()
			solutionResponse().then()
		},5000);
		return ()=> clearInterval(interval)
	},[])

	// resultPercent={percentGradeCalc(overviewData?.robotThisYear === undefined? 0 : overviewData?.robotThisYear,overviewData?.robotLastYear === undefined? 0 : overviewData?.robotLastYear)}
	// comparePercent={((overviewData?.robotThisYear === undefined? 0 : overviewData?.robotThisYear) - (overviewData?.robotLastYear === undefined? 0 : overviewData?.robotLastYear))>=0}/>

	const percentCalc = (thisYear: number, lastYear: number) => {
		return (((lastYear + thisYear) - lastYear) / lastYear*100)
	}

	const percentGradeCalc = (thisYear: number, lastYear: number) => {
		if(thisYear === 0 && lastYear === 0){
			return 0
		}else{
			//현재 -인 경우에 0으로 받도록 추가함.
			if(percentCalc(thisYear, lastYear) === Infinity || NaN || (percentCalc(thisYear, lastYear) < 0)){
				return 0
			}else{
				// console.log(Math.abs(percentCalc(thisYear, lastYear)).toPrecision(lastYear.toString().length))
				return (Math.abs(percentCalc(thisYear, lastYear)))
			}
		}
	}

	// -값을 양수로 변경해둠.
	// console.log(Math.abs(((overviewData?.pbasThisYear === undefined? 0 : overviewData?.pbasThisYear) - (overviewData?.pbasLastYear === undefined? 0 : overviewData?.pbasLastYear))))

	return (
		<React.Fragment>
			{mapOpen?
				<FasMapOverviewContainer closeMap={()=> {
					mapState(true)
					setMapOpen( false )
				}}/>
				:
				<Container>
					<div style={{display:'flex',justifyContent:'space-between'}}>
						<MenuTitle title={'FAS'} unitCase={'MenuTitle'}/>
						<MainButtons clickEvent={()=> {
							setSelectSolution('@')
							resetCompany()
							mapState(false)
							setMapOpen( true )}} name={'지도보기'} width={'70px'} marginDis={true}/>
					</div>
					<div style={{display:'grid', gridGap:'8px', gridTemplateColumns:'repeat(5,1fr)'}}> {/*현재 전체 설비는 등록된 프레스 기준 값 넣어둠*/}
						<FasMenuSquare solutionMenu={'P-BAS'} liveFacility={overviewData?.livePress} todayFacility={overviewData?.todayPress}
													 totalFacility={overviewData?.totalPress} registerFacility={overviewData?.registerPress} allPressCount={overviewData}
													 realtimeAlarm={mainAlarmState?.["P-BAS"]} realtimeAlarmCount={mainAlarmState?.["P-BASAlarmCount"]}
													 solutionAlarm={solutionState?.["P-BAS"]} solutionAlarmCount={solutionState?.["P-BASRequestCount"]}
													 resultPercent={percentGradeCalc(dataValid(overviewData?.pbasThisYear,0), dataValid(overviewData?.pbasLastYear,0))}
													 comparePercent={Math.abs((dataValid(overviewData?.pbasThisYear,0) - dataValid(overviewData?.pbasLastYear,0)))>=0}
													 iconClick={()=> {setMapOpen( true ); resetCompany()}}/>

						<FasMenuSquare solutionMenu={'R-BAS'}
													 realtimeAlarm={mainAlarmState?.["R-BAS"]} realtimeAlarmCount={mainAlarmState?.["R-BASAlarmCount"]}
													 solutionAlarm={solutionState?.["R-BAS"]} solutionAlarmCount={solutionState?.["R-BASRequestCount"]}
													 resultPercent={percentGradeCalc(dataValid(overviewData?.robotThisYear, 0), dataValid(overviewData?.robotLastYear, 0))}
													 comparePercent={Math.abs((dataValid(overviewData?.robotThisYear, 0) - dataValid(overviewData?.robotLastYear, 0)))>=0}
													 iconClick={()=> {setMapOpen( true ); resetCompany()}}/>

						<FasMenuSquare solutionMenu={'C-BAS'}
													 realtimeAlarm={mainAlarmState?.["C-BAS"]} realtimeAlarmCount={mainAlarmState?.["C-BASAlarmCount"]}
													 solutionAlarm={solutionState?.["C-BAS"]} solutionAlarmCount={solutionState?.["C-BASRequestCount"]}
													 resultPercent={percentGradeCalc(dataValid(overviewData?.cncThisYear, 0), dataValid(overviewData?.cncLastYear, 0))}
													 comparePercent={Math.abs((dataValid(overviewData?.cncThisYear, 0) - dataValid(overviewData?.cncLastYear, 0)))>=0}
													 iconClick={()=> {setMapOpen( true ); resetCompany()}}/>

						<FasMenuSquare solutionMenu={'A-BAS'}
													 realtimeAlarm={mainAlarmState?.["A-BAS"]} realtimeAlarmCount={mainAlarmState?.["A-BASAlarmCount"]}
													 solutionAlarm={solutionState?.["A-BAS"]} solutionAlarmCount={solutionState?.["A-BASRequestCount"]}
													 resultPercent={percentGradeCalc(dataValid(overviewData?.abasThisYear, 0), dataValid(overviewData?.abasLastYear, 0))}
													 comparePercent={Math.abs((dataValid(overviewData?.abasThisYear, 0) - dataValid(overviewData?.abasLastYear, 0)))>=0}
													 iconClick={()=> {setMapOpen( true ); resetCompany()}}/>

						<FasMenuSquare solutionMenu={'F-EMS'}
													 totalFacility={Number(femsInfoData?.companyCount)} todayFacility={Number(femsInfoData?.["F-EMSCount"])}
													 liveFacility={Number(femsInfoData?.kepCoApiCount)}
													 realtimeAlarm={mainAlarmState?.["F-EMS"]} realtimeAlarmCount={mainAlarmState?.["F-EMSAlarmCount"]}
													 solutionAlarm={solutionState?.["F-EMS"]} solutionAlarmCount={solutionState?.["F-EMSRequestCount"]}
													 resultPercent={percentGradeCalc(dataValid(overviewData?.femsThisYear, 0) , dataValid(overviewData?.femsLastYear, 0))}
													 comparePercent={Math.abs((dataValid(overviewData?.femsThisYear, 0) - dataValid(overviewData?.femsLastYear, 0)))>=0}
													 iconClick={()=> {setMapOpen( true ); resetCompany()}}/>
						{/*<FasMenuSquare solutionMenu={'P-MS'}/>*/}
						{/*<FasMenuSquare solutionMenu={'R-MS'}/>*/}
						{/*<FasMenuSquare solutionMenu={'C-MS'}/>*/}
						{/*<FasMenuSquare solutionMenu={'A-MS'}/>*/}

						{/*<div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>*/}
						{/*	<FasMenuSquare solutionMenu={'V-MS'}/>*/}
						{/*	<FasMenuSquare solutionMenu={'MES'}/>*/}
						{/*	<FasMenuSquare solutionMenu={'i-MES'}/>*/}
						{/*	<FasMenuSquare solutionMenu={'i-BAS'}/>*/}
						{/*</div>*/}
					</div>
				</Container>
			}
		</React.Fragment>
	);
};

export default MainContainer;

const Container = styled.div`
    margin: 0 auto;
		box-sizing: border-box;
    width: 1270px;
	`
