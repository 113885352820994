import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import {useLocation} from "react-router-dom";
import UserWikiList from "../../../containers/user/fas/wiki/UserWikiList";
import UserWikiDetail from "../../../containers/user/fas/wiki/UserWikiDetail";
import UserWikiRegister from "../../../containers/user/fas/wiki/UserWikiRegister";
import UserWikiModify from "../../../containers/user/fas/wiki/UserWikiModify";
import UserInfoDetail from "../../../containers/user/fas/wiki/UserInfoDetail";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
const FasWiki = () => {

	const location = useLocation()
	const register = location.pathname.includes('register')
	const modify = location.pathname.includes('modify')
	const info = location.pathname.includes('info')
	return (
		<PageContainer>
			<IntegratedSideMenu/>
			{location.pathname.length === 9?
				<SectionContainer contentContainer={<UserWikiList/>}/>
				:
				register ?
					<SectionContainer contentContainer={<UserWikiRegister/>}/>
					:
					modify ?
						<SectionContainer contentContainer={<UserWikiModify/>}/>
						:
						info?
							<SectionContainer contentContainer={<UserInfoDetail/>}/>
							:
							<SectionContainer contentContainer={<UserWikiDetail/>}/>
			}
		</PageContainer>
	);
};

export default FasWiki;
