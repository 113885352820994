import React , {useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  authValid ,
  dataAccessLoading ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import styled from "styled-components";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {useTranslation} from "react-i18next";
import ModalBase from "../../../../../components/modal/ModalBase";
import DaumPostcode from "react-daum-postcode";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import AccountMultipleReg from "./AccountMultipleReg";

const AccountRegistration = () => {
  const {t}:any = useTranslation();
  const [multipleReg,setMultipleReg] = useState(false);
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
  const [accountRegister, setAccountRegister] = useState({
    companyCode: selectedCompany?.companyCode,
    customerName: '',
    division: '',
    president: '',
    managerName: '',
    phoneNumber: '',
    managerNumber: '',
    fax: '',
    address: '',
    detailAddress: '',
    businessNumber: '',
    businessRegistration: '',
    bankBook: '',
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, trim?: boolean) => {
    const { name, value } = e.target;
    setAccountRegister({
      ...accountRegister,
      [name]: trim? value.trim() : value,
    });
  }


  const AccountRegisterRes = async () => {

    if(accountRegister.customerName.trim() === ''){
      setDataModal({menu:'confirm',message:'거래처명을 입력해 주세요.'})
    }
    else if(accountRegister.president.trim() === ''){
      setDataModal({menu:'confirm', message:'대표자를 입력해 주세요.'})
    }
    else if(accountRegister.managerName.trim() === ''){
      setDataModal({menu:'confirm', message:'담당자를 입력해 주세요.'})
    }
    else if(accountRegister.managerNumber.trim() === ''){
      setDataModal({menu:'confirm', message:'담당자 번호를 입력해 주세요.'})
    }
    else if(accountRegister.address.trim() === ''){
      setDataModal({menu:'confirm', message:'주소를 선택해 주세요.'})
    }
    else if(accountRegister.businessNumber && accountRegister.businessNumber.trim().length !== 12){
      setDataModal({menu:'confirm', message:'사업자 번호 형식이 맞지 않습니다.'})
    }
    else{
      try {
        dataAccessLoading()
      const response = await client.post( '/customer/register' , [{
        ...accountRegister ,
        businessRegistration: !accountRegister.businessRegistration ? '' : accountRegister.businessRegistration ,
        bankBook: !accountRegister.bankBook ? '' : accountRegister.bankBook ,
      }] , {headers: {Authorization}} )

        if (response.data.status !== 200) {
          setDataModal({menu:'confirm',message:t(response.data.message)});
          loadingRemove()
        } else {
          setTimeout(()=>{
            setDataModal({menu:'success',message:t('저장 되었습니다.')});
            loadingRemove()
          },500)
          setTimeout(()=>{
            window.location.href = authValid()? '/admin/fas/account' : '/fas/account'
          },1000)
        }
      } catch(e:any){
        console.log(e)
        setDataModal({menu:'error',message:e.message})
        loadingRemove()
      }
    }
  }




  const AddressStyle:React.CSSProperties|undefined ={
    display:'block',
    width:500,
    height:600,
    position:'absolute',
    boxShadow:'0 1px 7px #E6E9F4',
    borderRadius:'8px',
    backgroundColor:'#fff',
    padding: "7px",
    zIndex: 100,
  }

  const addressHandle = {
    clickBtn : () => setDataModal({menu:'address',message:'주소를 입력해 주세요.'}),
    selectAddress: (data: any) => {
      accountRegister.address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
      setAccountRegister({...accountRegister,detailAddress:''})
      setDataModal(initModal);
    },
  }

  // @ts-ignore
  return (
   <SectionFrame>
       <React.Fragment>
       {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              viewType={true}
                              clickEvent={()=>{ window.location.href = authValid()? '/admin/fas/account' : '/fas/account'}}
          />
      }
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}
      {dataModal.menu === 'address' &&
          <ModalBase clickEvent={() => setDataModal( initModal )}
                     modalContent={<DaumPostcode onComplete={addressHandle.selectAddress}
                                                  style={AddressStyle}
                                                 autoClose={false}/>}/>}

       <div style={{display:'flex',justifyContent:'space-between'}}>
            <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 거래처 기본정보 > 등록'+(multipleReg?'(다중)':'')}
                       unitCase={'MenuTitle'}/>
         {multipleReg?
             <div style={{display:'flex'}}>
               <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                            clickEvent={()=>{setDataModal({menu:'cancel',message:'목록으로 이동 하시겠습니까?'})}} />
               <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                            clickEvent={()=>setMultipleReg(false)} />
             </div>
             :
             <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                          clickEvent={()=>setMultipleReg(true)} />
         }
       </div>

            <ContentFrame height={'680px'} padding={multipleReg? '30px 30px 18px' : '30px'}>
              {multipleReg ?
                <AccountMultipleReg/>
                :
              <div style={{display: 'flex' , height: '620px'}}>
                <RegisterComp>
                  <div style={{
                    display: 'flex' ,
                    justifyContent: 'space-between' ,
                    alignItems: 'center' ,
                    marginBottom: '16px'
                  }}>
                    <MenuTitle title={'거래처 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                    <div style={{display: 'flex'}}>
                      <div style={{display: 'flex'}}>
                        <MainButtons name={'취소'} width={'56px'}
                                     clickEvent={() => {
                                       setDataModal( {
                                                       menu: 'cancel' ,
                                                       message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                     })
                                     }}/>
                        <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                     clickEvent={AccountRegisterRes}/>
                      </div>
                    </div>
                  </div>
                  <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                    <RegisterComponent type={'text'} title={'거래처명'} name={'customerName'} required={true}
                                       value={accountRegister.customerName} paddingRight={true}
                                       onChange={onChange}/>
                    <RegisterComponent type={'text'} title={'거래처 구분'} name={'division'} value={accountRegister.division}
                                       onChange={onChange}/>
                    <RegisterComponent type={'text'} title={'대표자명'} name={'president'} value={accountRegister.president}
                                       required={true} paddingRight={true}
                                       onChange={onChange}/>
                    <RegisterComponent type={'text'} title={'담당자명'} name={'managerName'}
                                       value={accountRegister.managerName} required={true}
                                       onChange={onChange}/>
                    <RegisterComponent type={'phone'} title={'전화번호(거래처)'} name={'phoneNumber'}
                                       value={accountRegister.phoneNumber} paddingRight={true}
                                       numberFormat={accountRegister.phoneNumber}
                                       onChange={(e)=>onChange(e,true)}/>
                    <RegisterComponent type={'phone'} title={'휴대폰번호(담당자)'} name={'managerNumber'} required={true}
                                       value={accountRegister.managerNumber}
                                       numberFormat={accountRegister.managerNumber}
                                       onChange={(e)=>onChange(e,true)}/>
                  </div>
                    <RegisterComponent type={'phone'} title={'FAX'} name={'fax'} value={accountRegister.fax}
                                       numberFormat={accountRegister.fax}
                                       onChange={(e)=>onChange(e,true)}/>
                    <RegisterComponent type={'address'} title={'주소'} name={'address'} subName={'detailAddress'}
                                       value={accountRegister.address} subValue={accountRegister.detailAddress}
                                       required={true}
                                       onChange={onChange} clickEvent={addressHandle.clickBtn}/>
                    <RegisterComponent type={'bussinessNumber'} title={'사업자 번호'} name={'businessNumber'}
                                       value={accountRegister.businessNumber}
                                       onChange={onChange} bottomBorder={true}/>
                </RegisterComp>
                <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'}>
                <div style={{display: 'flex' , flexDirection: 'column' , gap: '30px' , marginTop: '20px'}}>
                  <div style={{display: 'flex' , flexDirection: 'column' , gap: '10px'}}>
                    <MenuTitle title={'사업자 등록증'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={(v)=>setAccountRegister((prev:any)=>({...prev, businessRegistration:v}))} profileSelect={false}/>
                  </div>
                  <div style={{display: 'flex' , flexDirection: 'column' , gap: '10px'}}>
                    <MenuTitle title={'통장 사본'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={(v)=>setAccountRegister((prev:any)=>({...prev, bankBook:v}))} profileSelect={false}/>
                  </div>
                </div>
              </div>
            </div>}
          </ContentFrame>
       </React.Fragment>
    </SectionFrame>
  );
};
export default AccountRegistration;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
 `
