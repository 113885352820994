import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import SubMaterials from "../../../containers/admin/fas/company/materials/SubMaterials";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useLocation} from "react-router-dom";
import SubMaterialsRegistration from "../../../containers/admin/fas/company/materials/SubMaterialsRegistration";
import SubMaterialsDetail from "../../../containers/admin/fas/company/materials/SubMaterialsDetail";

const FasSubMaterial = () => {
  const location = useLocation()
  const {pathname} = location

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {pathname.length === (location.pathname.includes('/admin') ? 22 : 16) ?
      <SectionContainer contentContainer={<SubMaterials/>}/>
      :
      location.pathname.includes('register') ?
      <SectionContainer contentContainer={<SubMaterialsRegistration/>}/>
        :
        <SectionContainer contentContainer={<SubMaterialsDetail/>}/>
      }
    </PageContainer>
  );
};

export default FasSubMaterial;