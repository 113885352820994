import React,{useState, useEffect, useRef} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {
  listResultMessage ,
  modalTitleBase , tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import {CompanyDataType} from "../../types/Fundamental";
import SearchBar from "../input/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import {dataValid , loadingRemove , modalListLoading} from "../../function/function";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  companyAuthorities ,
  persistPartnersData ,
} from "../../common/StateManage";
import {useTranslation} from "react-i18next";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface Company_Modal_Types {
  receivedCompany:(data:any)=>void
  closeButton:()=>void
  closeEvent?:()=>void
  inputText?: string
  idType?: boolean
}

const PartnerSelectModal:React.FunctionComponent<Company_Modal_Types> = ({receivedCompany, closeButton,closeEvent, inputText, idType}) => {
  const [page, setPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [pageBlock,setPageBlock] = useState<number>(0)
  const [searchInput, setSearchInput] = useState<string>(dataValid(inputText,''))
  const [partnerData, setPartnerData] = useState<CompanyDataType[]>([])
  const [selectPartnerData, setSelectPartnerData] = useState<any>({})
  const [, setAuthorities] = useRecoilState<string>(companyAuthorities)
  const persistCompany = useRecoilValue(persistPartnersData)
  const {t}:any = useTranslation()
  const scrollRef = useRef<any>(null)
  const scrollToTop = ()=> {scrollRef.current.scrollTop = 0}


  let requestCount = 1
  //const partnerListRes = ()=> ListCustomHooks(`/admin/list/${page}/10`,page,setTotalPage,setPageBlock,setCompanyList,setErrorState,setSearchInput,setPrevSearch)
  //   const partnerSearchListRes = () => ListSearchCustomHooks(`/admin/search/${page}/10/${searchInput}`,page,setTotalPage,setPageBlock,setCompanyList,setErrorState)

  const partnerListRes = async () => {
    requestCount++
    try {
      modalListLoading()
      const response = await client.get(`/admin/list/${page}/10`,{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setPartnerData((prev:any)=> prev.concat(data.row))
      }else{
        setPartnerData(data.row)
      }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
    finally {
      requestCount--
    }
  }

  const partnerSearchListRes = async () => {
    requestCount++
    try {
      modalListLoading()
      const response = await client.post(`/admin/search`,{
        page:page,
        size:10,
        keyword:searchInput
      },{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setPartnerData((prev:any)=> prev.concat(data.row))
      }else{
        setPartnerData(data.row)
      }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
    finally {
      requestCount--
    }
  }

  useEffect(()=>{
    if(requestCount === 1){
      if(searchInput === ''){
        partnerListRes().then()
      }else{
        partnerSearchListRes().then()
      }
    }
  },[page])

  const searchFunction = () => {
    setPartnerData([])
    setPage(1)
    setTotalPage(1)
    scrollToTop()
    if(searchInput === ''){
      partnerListRes().then()
    }else{
      partnerSearchListRes().then()
    }
  }

  const addressCut = (address:string) => {
    if(address?.includes('충청북도')){
      return '충북'
    }
    if (address?.includes('충청남도')) {
      return '충남'
    }
    if (address?.includes('전라북도')) {
      return '전북'
    }
    if (address?.includes('전라남도')) {
      return '전남'
    }
    if (address?.includes('경상북도')) {
      return '경북'
    }
    if (address?.includes('경상남도')) {
      return '경남'
    } else {
      return address?.slice(0,2)
    }
  }
  const nameCut = (name:string) => {
    return name?.split(' ')[0]
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle: t('지사/협력사 검색')},)}
          <div className={'modal_content'}>
            <div>
              <SearchBar widthCus={'467px'} keyFunction={(e)=>{setSearchInput(e.target.value.trimStart())}} value={searchInput}
                         clickEvent={searchFunction}/>
              <InfiniteScroll scrollableTarget={'grid_scroll'}
                              hasMore={totalPage > page}
                              dataLength={dataValid(partnerData?.length,0)}
                              next={()=>setPage((prev:any)=>prev+1)} loader={<div/>}>
                <GridTableComponent id={'grid_scroll'} tempCol={'160px 80px 128px 128px'} ref={scrollRef}
                                    height={288} scrollWidth={494}
                                    scrollX_Hide={true}>
                  <div className={'grid-title-unchecked'}>
                    {['업체명','지역','대표','업체코드'].map((v)=>(<div key={v}>{t(v)}</div>))}
                  </div>
                  {partnerData?.length === 0?
                    listResultMessage(true,'검색 결과가 없습니다.',{width:374, admin:288, user:288})
                    :
                    partnerData?.map((v)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'} key={v.companyCode + '_' + v.id}
                                       selected={(v.companyCode === (!selectPartnerData.companyCode? persistCompany.companyCode : selectPartnerData.companyCode))}
                                        onMouseDown={()=>setSelectPartnerData(v)}
                                       onDoubleClick={()=>{receivedCompany(selectPartnerData);
                                         closeButton();
                                         setAuthorities(selectPartnerData.authorities)}}>
                        {/*{validationValue(v.companyName)}*/}
                        {tooltipModule(v.companyName)}
                        {validationValue(addressCut(v.address))}
                        {validationValue(nameCut(v.president))}
                        {tooltipModule(v.companyCode)}
                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
              </InfiniteScroll>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MainButtons name={'취소'} clickEvent={()=>{
                if(closeEvent){
                  closeEvent()
                }else{
                  closeButton()
                }
              }} margin={'0 12px'} width={'144px'}/>
              <MainButtons name={'확인'} buttonType={'cta'} margin={'0 12px'} width={'144px'}
                           disabled={!selectPartnerData.companyCode}
                           clickEvent={()=>{
                             receivedCompany(selectPartnerData); closeButton();
                             setAuthorities(selectPartnerData.authorities)}}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default PartnerSelectModal;

const BaseComponent = styled.div`
  position: absolute;
  width: 540px;
  height: 512px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: space-between;
  
  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`
