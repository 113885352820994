import React , {useEffect , useState} from 'react';
import {
  SectionFrame , ContentFrame ,
  listResultMessage , validationValue , autoTextValueValidation
} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import MainButtons from "../../../../components/button/MainButtons";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading , initModal , loadingRemove , menuVisitLogRegister ,
} from "../../../../function/function";
import ListInnerInput from "../../../../components/input/ListInnerInput";
import {Modal_View_Type} from "../../../../types/Fundamental";
import FilePopup from "../../../../components/modal/FilePopup";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import DocumentLogModal from "../../../../components/modal/DocumentLogModal";
import {useRecoilState , useRecoilValue , useResetRecoilState} from "recoil";
import {
  documentSDMCode ,
  documentSDMList ,
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../common/StateManage";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import FileUploadButton from "../../../../components/button/FileUploadButton";
import DocumentFileMove from "../../../../components/modal/DocumentFileMove";
import styled from "styled-components";
import TableValue from "../../../../style/TableValue";
import {useTranslation} from "react-i18next";
import ToastPopup from "../../../../components/modal/ToastPopup";
import {DOWNLOAD_ADDRESS} from "../../../../api/endPoint";
import {GridTableComponent, GridTableSelect} from "../../../../style/StyleModule";

type DocumentType = {
  companyCode: string
  SDMTopCode: string
  SDMCode: string | null
  name: string
  fileName?: string
  modifiedDate: string
  register: string
  type: string
  uuidFileName?: string
}
const StandardDocManagement = () => {
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const {t}: any = useTranslation()
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [rowList , setRowList] = useState<DocumentType[]>( [] )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [selectSDMCode , setSelectSDMCode] = useRecoilState<string | null>( documentSDMCode )
  const [folderList , setFolderList] = useRecoilState<{ name: string, SDMCode: string | null }[]>( documentSDMList )
  const [openEllipsisFolder , setOpenEllipsisFolder] = useState<boolean>( false )
  const [selectFolder , setSelectFolder] = useState<string | null>( null )

  const resetSDMCode = useResetRecoilState( documentSDMCode )
  const resetSDMList = useResetRecoilState( documentSDMList )
  //폴더 목록 가져오기
  const documentFolderListRes = async () => {
    try {
      const response = await client.post( '/SDM/list' ,
                                          {companyCode: selectedCompany.companyCode , SDMCode: selectSDMCode} ,
                                          {headers: {Authorization}} )
      const data = response.data
      setRowList( data.data.row )
    } catch ( e ) {
      console.log( e )
    }
  }
  //폴더 삭제
  const documentFolderDelete = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.get( `/SDM/delete/${checkedList[0]}` , {headers: {Authorization}} )
      if( response.data.status === 200 ){
        setTimeout( () => {
          setDataModal( {menu: 'success' , message: t( '삭제 되었습니다.' )} )
          setRowList( rowList.filter( ( v ) => v.SDMCode !== checkedList[0] ) )
        } , 500 )
        loadingRemove()
      } else{
        setDataModal( {menu: 'confirm' , message: response.data.message} )
        loadingRemove()
      }
      setCheckedList( [] )
    } catch ( e ) {
      loadingRemove()
      console.log( e )
    }
  }
  React.useEffect( () => {
    documentFolderListRes().then()
  } , [selectedCompany , selectSDMCode] )

  React.useEffect(()=>{
    resetSDMCode()
    resetSDMList()
  },[selectedCompany.companyCode])

  const lastFolderList = folderList.slice( - 1 )[0]
  const ellipsisBetweenFolder = folderList.slice( 1 , - 1 )

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'표준문서 관리').then()
    }
  },[])

  const folderCheckedFunction = ( checked: boolean , sdmCode: string | null , setCheckedList: React.Dispatch<React.SetStateAction<string[]>> , checkedList: string[] ) => {
    if (checked) {
      // @ts-ignore
      setCheckedList([sdmCode])
    } else {
      setCheckedList([]);
    }
  };



  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={() => setDataModal( initModal )}/>}
      {dataModal.menu === 'delete' &&
          <SelectConfirmModal statusText={'해당 파일을 삭제 하시겠습니까?'} buttonText={'delete'}
                              noticeText={'* ‘폴더’ 삭제시 폴더 안의 파일이 함께 삭제 됩니다.'}
                              clickEvent={documentFolderDelete}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'create' &&
          <FilePopup type={'create'} companyCode={selectedCompany.companyCode} SDMCode={selectSDMCode} onFolderCreated={() => {
            documentFolderListRes().then()}}
                     cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'move' &&
          <DocumentFileMove currentPosition={lastFolderList.name}
                            fileName={rowList.find( ( v ) => v.SDMCode === checkedList[0] )?.fileName}
                            companyCode={selectedCompany.companyCode} SDMCode={checkedList[0]}
                            cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'log' &&
          <DocumentLogModal companyCode={selectedCompany.companyCode} authValid={authValid()}
                            confirmEvent={() => {setDataModal( initModal )}}/>}
      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 표준문서 관리'} unitCase={'MenuTitle'}/>
          </div>
        </div>
        <ContentFrame height={'680px'}>
          <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
            <FolderDepthList listLength={folderList.length}>
              {folderList.length > 4 ?
                <React.Fragment>
                  <FolderTitleComponent nextArray={true} onClick={() => {
                    setSelectSDMCode( folderList[0].SDMCode )
                    setFolderList( folderList.slice( 0 , 1 ) )
                    if( folderList[0].SDMCode !== selectSDMCode ){
                      setCheckedList( [] )
                    }
                  }}>{folderList[0].name}
                  </FolderTitleComponent>
                  <FolderTitleComponent open={openEllipsisFolder}
                                        onClick={() => setOpenEllipsisFolder( !openEllipsisFolder )}
                                        className={'ellipsis'}>
                    {'...'}
                    <FolderEllipsisList scrollLength={ellipsisBetweenFolder.length} open={openEllipsisFolder}>
                      {ellipsisBetweenFolder.map( ( v , i ) => (
                        <div onClick={() => {
                          setSelectSDMCode( v.SDMCode )
                          setFolderList( folderList.slice( 0 , i + 2 ) )
                          if( v.SDMCode !== selectSDMCode ){
                            setCheckedList( [] )
                          }
                        }}>{v.name}</div>
                      ) )}
                    </FolderEllipsisList>
                  </FolderTitleComponent>
                  <FolderTitleComponent onClick={() => {
                    setSelectSDMCode( lastFolderList.SDMCode )
                    setFolderList( folderList.slice( 0 , folderList.length ) )
                    if( lastFolderList.SDMCode !== selectSDMCode ){
                      setCheckedList( [] )
                    }
                  }}>{lastFolderList.name}</FolderTitleComponent>
                </React.Fragment>
                :
                folderList.map( ( v , i ) => (
                  <FolderTitleComponent nextArray={folderList.length > i + 1} onClick={() => {
                    setSelectSDMCode( v.SDMCode )
                    setFolderList( folderList.slice( 0 , i + 1 ) )
                    if( v.SDMCode !== selectSDMCode ){
                      setCheckedList( [] )
                    }
                  }}>{t( v.name )}</FolderTitleComponent>
                ) )}
            </FolderDepthList>

            <div style={{display: 'flex'}}>
              <MainButtons name={'폴더 생성'} width={'80px'}
                           disabled={buttonAuthValid( 'create' , '19' ) || !selectedCompany.companyCode}
                           clickEvent={() => {setDataModal( {menu: 'create'} )}}/>
              <FileUploadButton companyCode={selectedCompany.companyCode} SDMCode={selectSDMCode} onUploadSuccess={() => {
                documentFolderListRes().then()
              }}
                                disabled={buttonAuthValid( 'create' , '19' ) || selectedCompany.companyCode === undefined}/>
              <MainButtons name={'다운로드'} width={'80px'}
                           disabled={checkedList.length !== 1 || rowList.find( ( v ) => v.SDMCode === checkedList[0] )?.type === '폴더'}
                           clickEvent={() => {
                             const uuidFileName = rowList.find( ( v ) => v.SDMCode === checkedList[0] )?.uuidFileName;
                             if( uuidFileName ){
                               setDataModal( {menu: 'success' , message: t( '파일을 다운로드합니다.' )} );
                               window.location.href = `${DOWNLOAD_ADDRESS}${uuidFileName}`;
                             }
                           }}/>
              <MainButtons name={'문서로그'} width={'80px'} disabled={!selectedCompany.companyCode}
                           clickEvent={() => {setDataModal( {menu: 'log'} )}}/>
              <MainButtons name={'파일 이동'} width={'80px'}
                           disabled={buttonAuthValid( 'update' , '19' ) || ( checkedList.length !== 1 || rowList.find( ( v ) => v.SDMCode === checkedList[0] )?.type === '폴더' )}
                           clickEvent={() => {setDataModal( {menu: 'move'} )}}/>
              <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                           disabled={buttonAuthValid( 'delete' , '19' ) || checkedList.length !== 1} marginDis={true}
                           clickEvent={() => {setDataModal( {menu: 'delete'} )}}/>
            </div>
          </div>
          <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                              height={538}
                              scrollWidth={1200}
                              tempCol={'40px 1fr 140px 220px'}>
            <div className={'grid-title'}>
              <label className={'checkbox'}>
                {/*<input type="checkbox" checked={checkedList.length === checkboxValid().list.length} style={{pointerEvents:`${iconSelect === -1 ? 'auto' : 'none'}`,opacity:`${iconSelect === -1 ? 1 : 0.5}`}}*/}
                {/*       onChange={(e) => allCheckedFunction(e.target.checked, checkboxValid().list, checkboxValid().id, setCheckedList)}/>*/}
              </label>
              {['이름' , '종류' , '마지막 수정날짜'].map( ( v ) => (
                <TableValue title={t( v )} requiredValue={['']}/>
              ) )}
            </div>

            {!selectedCompany.companyCode ?
              listResultMessage( authValid() , t( '* 업체를 선택해 주세요.' ) , {
                width: 1210 ,
                admin: 480 ,
                user: 480
              } )
              :
              rowList?.length === 0 ?
                listResultMessage( authValid() , t( '* 문서를 등록해 주세요.' ) , {
                  width: 1210 ,
                  admin: 480 ,
                  user: 480
                } )
                :
                rowList.map( ( v , i) => (
                  <GridTableSelect className={'grid-list'}
                                   checkboxNumber={i + 1}
                                   selected={selectFolder === v.SDMCode}
                                   onClick={() => {
                                     setSelectFolder( v.SDMCode )
                                     if( !v.type.includes( '폴더' ) ){
                                       folderCheckedFunction( !checkedList.includes( v.SDMCode ) , v.SDMCode , setCheckedList , checkedList )
                                     }
                                   }}
                                   onDoubleClick={() => {
                                     if( v.type.includes( '폴더' ) ){
                                       setSelectSDMCode( v.SDMCode )
                                       setFolderList( ( prev ) => ( [...prev , {name: v.name , SDMCode: v.SDMCode}] ) )
                                       setCheckedList( [] )
                                     } else{
                                       folderCheckedFunction( !checkedList.includes( v.SDMCode ) , v.SDMCode , setCheckedList , checkedList )
                                     }
                                   }} key={v.SDMCode}>
                    <div className={'selectCheckbox'}>
                      <ListInnerInput type={'check'} name={'docName'}
                                      onChange={( e: any ) =>  v.SDMCode && folderCheckedFunction( e.target.checked , v.SDMCode , setCheckedList , checkedList )}
                                      checked={checkedList.includes( v.SDMCode )}/>
                    </div>
                    {autoTextValueValidation( {value: v.name || v.fileName} )}
                    {validationValue( t( v.type ) )}
                    {validationValue( v.modifiedDate )}
                  </GridTableSelect>
                ) )}
          </GridTableComponent>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default StandardDocManagement;
const FolderDepthList = styled.div<{ listLength: number }>`
  display: flex;
  font-size: 18px;
  font-weight: 800;
  line-height: 32px;
  max-width: 600px;

  & > div {
    cursor: ${props => props.listLength > 1 ? 'pointer' : 'default'};

    &:hover {
      &:not(.ellipsis) {
        color: ${props => props.listLength > 1 ? '#0058ff' : '#171725'};
        text-decoration: ${props => props.listLength > 1 ? 'underline' : 'none'};
        text-underline-offset: 4px;
      }
    }
  }

  & > div:last-child {
    color: ${props => props.listLength > 1 ? '#0058ff' : '#171725'};
  }
`
const FolderTitleComponent = styled.div<{ open?: boolean, nextArray?: boolean }>`
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 16px;
  box-sizing: border-box;
  margin-right: 8px;

  &::after {
    content: '>';
    opacity: ${props => props.nextArray ? 1 : 0};
    position: absolute;
    right: 0;
  }

  &.ellipsis {
    width: 32px;
    border-radius: 50%;
    padding: 0;
    text-align: center;
    position: relative;
    overflow: visible;

    background-color: ${props => props.open ? 'rgba(0, 88, 255, 0.05)' : '#fff'};
    color: ${props => props.open ? '#0058ff' : '#171725'};

    &:hover {
      background-color: rgba(0, 88, 255, 0.05);

      &.ellipsis {
        color: #0058ff;
      }
    }
  }
`
const FolderEllipsisList = styled.div<{ open: boolean, scrollLength: number }>`
  position: absolute;
  top: 40px;
  width: 140px;
  height: ${props => props.open ? props.scrollLength * 32 : 0}px;
  max-height: 160px;
  left: -50px;
  border: ${props => props.open ? 1 : 0}px solid #5a607f;
  border-radius: 6px;
  overflow-y: ${props => props.scrollLength > 5 ? 'scroll' : 'hidden'};
  overflow-x: hidden;
  color: #171715;
  z-index: 49;

  &::-webkit-scrollbar {
    display: block;
    width: 14px;
  }

  &::-webkit-scrollbar-track {

    border-radius: 6px;
    background-color: #f5f6fa;
  }

  & > div {
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    position: relative;
    background-color: #fff;
    box-sizing: border-box;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      color: #0058ff;
      background-color: #f1f6fe
    }
  }
`
