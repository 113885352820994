import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import {useLocation} from "react-router-dom";
import ProductManagement from "../../../containers/admin/fas/company/product/ProductManagement";
import ProductDetail from "../../../containers/admin/fas/company/product/ProductDetail";
import InspectionItemRegister from "../../../containers/admin/fas/company/product/InspectionItemRegister";
import ProductModify from "../../../containers/admin/fas/company/product/ProductModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import ProductRegistration from "../../../containers/admin/fas/company/product/ProductRegistration";
import ProductProcessWaveContainer from "../../../containers/admin/fas/company/product/ProductProcessWaveContainer";
const FasProduct = () => {

	const location = useLocation()
	const {pathname} = location

	const inspection = pathname.includes('inspection')
	const modify = pathname.includes('modify')
	const register = pathname.includes('register')
	const bom = pathname.includes('bom')

	return (
		<PageContainer>
			<IntegratedSideMenu/>
			{pathname.length === (location.pathname.includes('/admin')? 18 : 12)?
				<SectionContainer contentContainer={<ProductManagement/>}/>
				:
				register?
					<SectionContainer contentContainer={<ProductRegistration/>}/>
					:
				inspection?
				<SectionContainer contentContainer={<InspectionItemRegister/>}/>
					:
					bom?
						<SectionContainer contentContainer={ <ProductProcessWaveContainer/>}/>
						:
					modify?
						<SectionContainer contentContainer={<ProductModify/>}/>
						:
						<SectionContainer contentContainer={<ProductDetail/>}/>
			}
		</PageContainer>
	);
};

export default FasProduct;
