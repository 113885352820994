import React from 'react';
import styled from 'styled-components'

interface Move_Scroll_Type {
  leftClick:()=>void
  rightClick:()=>void
}
const HorizontalMoveScrollButton:React.FunctionComponent<Move_Scroll_Type> = ({leftClick, rightClick}) => {

  let intervalId:any

  // 버튼을 누르고 있을때 0.1초마다 해당 기능이 작동되도록 하기 위한 interval 함수
  const intervalScrollMove = (func:Function) => {
    intervalId = setInterval(() => {
      func()
    }, 100);
  };

  // 마우스를 떼거나, 커서가 버튼을 벗어난 경우 interval 함수가 clear되도록 하기 위함
  const stopScrollMove = () => {
    clearInterval(intervalId);
  };

  return (
    <HorizontalMoveScroll>
      <button className={'move_previous'}
              onMouseDown={()=>intervalScrollMove(leftClick)}
              onMouseUp={stopScrollMove}
              onMouseOut={stopScrollMove}
              onClick={leftClick}>
        {'<'}
      </button>
      <button className={'move_next'}
              onMouseDown={()=>intervalScrollMove(rightClick)}
              onMouseUp={stopScrollMove}
              onMouseOut={stopScrollMove}
              onClick={rightClick}>
        {'>'}
      </button>
    </HorizontalMoveScroll>
  );
};
export default HorizontalMoveScrollButton;



const HorizontalMoveScroll = styled.div`
  display: grid;
  border-radius: 4px;
  grid-template-columns: 80px 80px;
  grid-gap: 16px;
  .move{
    &_next, &_previous {
      width: 80px;
      height: 36px;
      background-color: #E6E9F4;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
      color: #7E84A3;
      border-radius: 4px;
      transition: all 300ms;
      user-select: none;
      font-family: "Nanum Gothic", sans-serif;
      box-sizing: border-box;
      border: none ;
      outline: none;
      box-shadow: 0px 3px 6px #00000029;
      &:hover {
        background-color: #0058FF;
        color: #fff;
        cursor: pointer;
      }
    }
  }
`