import React , {useEffect , useState} from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import BasicManagement from "../../../containers/admin/fas/company/basic/BasicManagement";
import {useLocation} from "react-router-dom";
import BasicDetail from "../../../containers/admin/fas/company/basic/BasicDetail";
import BasicRegistration from "../../../containers/admin/fas/company/basic/BasicRegistration";
import BasicModify from "../../../containers/admin/fas/company/basic/BasicModify";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useWindowResizeScale} from "../../../function/function";
import CompanyUserManagement from "../../../containers/admin/fas/company/basic/user/CompanyUserManagement";

const AdminFasCompanyBasic = () => {
	const location = useLocation()
	const {pathname} = location
	const { scale, ref } = useWindowResizeScale();




	return (

		<PageContainer ref={ref}>
		<IntegratedSideMenu/>
			{pathname.length === 16?
				<SectionContainer contentContainer={
					<BasicManagement/>}/>
				:
				location.pathname.includes('register') ?
					<SectionContainer contentContainer={<BasicRegistration/>}/>
					:
					location.pathname.includes('modify')?
						<SectionContainer contentContainer={<BasicModify/>}/>
						:
						<SectionContainer contentContainer={<BasicDetail/>}/>
			}
		</PageContainer>
	);
};
export default AdminFasCompanyBasic;