import React , {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid , buttonAuthValid ,
  dataAccessLoading , duplicateInDoubleCheck , filterCheckedRow , handleRowDelete ,
  initModal ,
  loadingRemove , onMultiRegChangeArray , singleCheckedFunction
} from "../../../../../function/function";
import {
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import {
  validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const AccountModelMultipleReg = () => {
  const {t}: any = useTranslation()
  const [selectedID, setSelectedID] = useState<number>(0)
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )

  const generateInitialRowList =
    Array.from( {length: 20} , ( _ , i ) => (
      {
        id: i+1 ,
        companyCode: selectedCompany.companyCode ,
        businessNumber: '' ,
        customerCode: '' ,
        customerModelCode: '' ,
        customerModelName: '' ,
        customerName: ''
      }
    ) );

  const initialObject = {
    companyCode: selectedCompany.companyCode ,
    businessNumber: '' ,
    customerCode: '' ,
    customerModelCode: '' ,
    customerModelName: '' ,
    customerName: ''
  }
  const [rowList , setRowList] = useState<any[]>( generateInitialRowList );
  const [checkedList , setCheckedList] = useState<any>( [] )
  //동적 체크박스 함
  //등록 API
  const accountModelRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customerName ) ){
      setDataModal( {menu: 'confirm' , message: '거래처명을 검색하여 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customerModelName.trim() ) ) ){
      setDataModal( {menu: 'confirm' , message: '모델명을 입력해 주세요.'} )
    } else if( duplicateInDoubleCheck( rowList , 'customerCode' , 'customerModelName' , checkedList ) ){
      setDataModal( {menu: 'confirm' , message: '거래처 하나에 같은 모델명을 \n 입력할 수 없습니다.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/customerModel/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {
                                              headers: {Authorization}
                                            } )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( [] )
            setCheckedList( [] )
            // updateFunction()
            setDataModal( {menu: 'success' , message: '거래처 모델 정보가 등록 되었습니다.'} );
            setRowList( generateInitialRowList )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }

  const concatCheckedID = checkedList.includes(selectedID)

  return (
    <React.Fragment>
      {/*거래처 검색창*/}
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'} timeSet={1000}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}


      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={accountModelRegisterRes}/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} modalCase={'customer'}
                              companyCode={selectedCompany.companyCode} authValid={authValid()}
                              cancelEvent={() => {
                                setDataModal(initModal);
                              }}
                              confirmEvent={() => {
                                  Object.assign( rowList[dataModal.index] ,
                                                 {
                                                   customerName: accountCode.customerName ,
                                                   businessNumber: accountCode.businessNumber ,
                                                   customerCode: accountCode.customerCode
                                                 } )
                                if(!concatCheckedID){
                                  setCheckedList(checkedList.concat(selectedID))
                                }
                                setSelectedID(0)
                                setDataModal(initModal);
                              }} receiveData={( v ) => ( setAccountCode( v ) )}/>
      }

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}}
                      excelContent={
                        <React.Fragment>
                          <ExcelApiButton category={'customerModel'} disabled={buttonAuthValid('create','4')} type={'format'} companyData={selectedCompany} />
                          <ExcelApiButton category={'customerModel'} disabled={buttonAuthValid('create','4')} type={'upload'} companyData={selectedCompany}/>
                        </React.Fragment>
                      }
          />
      }

      <div>
        <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <MenuTitle title={'거래처 모델정보'} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'Excel'} width={'80px'}
                         disabled={buttonAuthValid( 'create' , '4' )}
                         clickEvent={() => setDataModal( {menu: 'excel'} )}/>
            <MainButtons name={'삭제'} width={'80px'}
                         disabled={checkedList.length === 0}
                         clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
            <MainButtons name={'저장'} width={'80px'} buttonType={'cta'} marginDis={true}
                         disabled={buttonAuthValid( 'create' , '5' ) || rowList.length === 0 || checkedList.length === 0}
                         clickEvent={() => {
                           if( checkedList.length === rowList.length ){
                             accountModelRegisterRes().then()
                           } else{
                             setDataModal( {menu: 'register' , message: '등록을 진행 하시겠습니까?'} )
                           }
                         }}/>
          </div>
        </div>
        <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                            scrollWidth={1200}
                            scrollY_Hide={rowList?.length < 10}
                            height={528}
                            tempCol={'40px repeat(2, 240px) 1fr'}>
          <div className={'grid-title'}>
            <label className={'checkbox'}>
              <input type="checkbox"
                     onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                       dataList: rowList ,
                       checkedList: checkedList ,
                       id: 'id' ,
                       setState: setCheckedList
                     } )}
                     checked={allCheckedState( {
                                                 dataList: rowList ,
                                                 checkedList: checkedList ,
                                                 id: 'id'
                                               } )}/>
            </label>
            {['거래처' , '사업자 번호' , '모델'].map( ( v ) => (
              <TableValue title={v} key={v} viewType={ rowList.length !== 0}
                          requiredValue={['거래처' , '모델']}/> ) )}
          </div>

          {rowList.map( ( v , i ) =>
                          <GridTableSelect className={'grid-row-list'} checkboxNumber={i + 1}>
                            <label className={'selectCheckbox'}>
                              <ListInnerInput type={'check'} name={'id'}
                                              onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                              checked={checkedList.includes( v.id )}/>
                            </label>
                            <ListInnerInput type={'search'} name={'customerName'} placeholder={'거래처'} tabIndex={- 1}
                                            clickButton={() => {
                                              setDataModal( {menu: 'account' , index: i} )
                                              setSelectedID(v.id)
                                            }}
                                            inputData={v.customerName}/>
                            {validationValue( v.businessNumber )}
                            <ListInnerInput type={'text'} name={'customerModelName'} placeholder={'모델명'}
                                            inputData={v.customerModelName} width={'100%'}
                                            onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.id )}}/>
                          </GridTableSelect>
          )}
        </GridTableComponent>

        {/*</InfiniteScroll>*/}
      </div>
    </React.Fragment>
  );
};
export default AccountModelMultipleReg;
