import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import EventDetail from "../../../containers/admin/fas/notice/eventnotice/EventDetail";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasEventNotice = () => {

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<EventDetail/>}/>
    </PageContainer>
  );
};

export default FasEventNotice;
