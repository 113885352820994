import React from 'react';
import styled from "styled-components";
import {ButtonStatus} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";

interface Props {
	buttonStatus: boolean
	buttonName: string
	buttonEvent: ()=>void
	buttonBorder?: boolean
	buttonValue?:(data:string)=>void
	width?:string
	height?:string
	disabled?: boolean
	padding?:string
	alarmPoint?: boolean
}

const SelectEventButton:React.FunctionComponent<Props> = ({buttonName,padding,
	                                                          buttonEvent, disabled, alarmPoint,
	                                                          buttonStatus,width,height,
	                                                          buttonBorder, buttonValue}) => {
	const buttonData = () => {
		buttonEvent()
		if (buttonValue) {
			buttonValue(buttonName)
		}
	}
	const { t }:any = useTranslation();

	return (
		<BaseComponent backColor={buttonStatus? '#0058ff' : '#fff'} alarmPoint={alarmPoint}
		               fontColor={buttonStatus? '#fff' : '#000'}
		               borderStatus={buttonBorder} paddingCus={padding}
		               onClick={buttonData} width={width} height={height} style={{opacity:`${disabled? 0.3 : 1}`, pointerEvents:`${disabled? 'none' : 'auto'}`}}>
			{t(buttonName)}
		</BaseComponent>
	);
};

export default SelectEventButton;

	const BaseComponent = styled.div<ButtonStatus>`
		
		border-top: 1px solid #d7dbec;
		border-bottom: 1px solid #d7dbec;
		border-left: ${ props => props.borderStatus? '1px' : '0'} solid #d7dbec;
		border-right: ${ props => props.borderStatus? '1px' : '0'} solid #d7dbec;
		width: ${props=>props.width? props.width : 'auto'};
		min-width: 64px;
		height: ${props=>props.height};
		padding :${p=>p.paddingCus ?? '10px 11px'};
		
		font-size: 13px;
		border-radius: 4px;
		cursor: pointer;
		user-select: none;
		box-sizing: border-box;
		font-weight: 700;
		background-color: ${ props => props.backColor };
		color: ${ props => props.fontColor };
		transition: background-color 0.3s, color 0.3s;
		text-align: center;
		position: relative;
		
		&::before{
			position: absolute;
			content: '';
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: ${ props => props.alarmPoint? '#F0142F' : 'transparent'};
			top: 2px;
			left: 2px;
		}
		
		&:hover{
			color: #fff;
			background-color: #0058ff;
		}
	`