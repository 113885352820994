import React , {CSSProperties , UIEventHandler} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {CircularProgress} from '@mui/material'
import {Colors} from "../../style/StyleComponent";

interface Props {
  name: string;
  clickEvent?: () => void;
  padding?: string;
  margin?: string;
  width?: string;
  height?: string;
  buttonType?: string;
  fontSize?: string;
  fontWeight?: string | number;
  selected?: boolean;
  disabled?: boolean
  shadow?: boolean
  clickDown?: () => void
  marginDis?: boolean
  scroll?: UIEventHandler<HTMLDivElement> | undefined
  withIcon?: boolean
  icon?: any
  iconPositionBack?: boolean
  enterKeyEvent?: ( e: any ) => void
  focusButton?: ( e: any ) => void
  tabIndex?: number
  handleRef?: ( ( instance: ( HTMLDivElement | null ) ) => void ) | React.RefObject<HTMLDivElement> | null | undefined
  loading?: boolean
  deleteButton?: () => void | any
  useDelete?: boolean
  iconStyle?: React.CSSProperties

}

const MainButtons: React.FC<Props> = ( {
                                         name , clickEvent , padding , margin , clickDown , scroll ,
                                         width , useDelete , deleteButton , height , buttonType , shadow , loading ,
                                         fontWeight , fontSize , selected , disabled , marginDis
                                         , tabIndex , handleRef , withIcon , icon, iconPositionBack ,iconStyle, enterKeyEvent , focusButton
                                       } ) => {
  const {t}: any = useTranslation();
  const getButtonStyles = (): React.CSSProperties => {
    switch ( buttonType ) {
      case 'cta':
        return {
          color: selected ? "#fff" : '#0058FF' ,
          border: '1px solid #0058FF' ,
          backgroundColor: selected ? '#0058FF' : '#FFF'
        };
      case 'popupNormal':
        return {
          color: '#131523' ,
          border: '1px solid #D7DBEC' ,
          backgroundColor: '#fff'
        };
      case 'popupCta':
        return {
          color: '#131523' ,
          border: '1px solid #0058FF' ,
          backgroundColor: '#fff'
        }
      case 'solution':
        return {
          color: selected ? '#fff' : '#5A607F' ,
          border: '1px solid #D7DBEC' ,
          backgroundColor: selected ? '#0058ff' : '#fff'
        }
      case 'drag':
        return {
          color: selected ? '#0058FF' : '#131523' ,
          border: selected ? '1px solid #0058FF' : 'none' ,
          backgroundColor: selected ? '#fff' : '#fff' ,
        }
      case 'pms':
        return {
          color: selected ? '#fff' : '#5A607F' ,
          border: '1px solid #D7DBEC' ,
          backgroundColor: selected ? '#0058ff' : '#fff' ,
          whiteSpace: 'pre-wrap' ,
          boxShadow: shadow ? '0 1px 8px #15223214' : 'none'
        }
      case 'play':
        return {
          color: '#5A607F' ,
          border: '1px solid #D7DBEC' ,
          backgroundColor: '#fff'
        }
      case 'visionPause':
        return {
          color: '#fff',
          border: '1px solid #D7DBEC',
          backgroundColor: selected ? Colors.BgRedHalf : Colors.Red,
          borderRadius: '8px'
        }
      case 'visionReset':
        return {
          color: '#fff',
          border: '1px solid #D7DBEC',
          backgroundColor: selected ? Colors.BgGreen : Colors.Green,
          borderRadius: '8px'
        }
      case 'listButton':
        return {
          color: selected? '#fff' : '#131523',
          border: '1px solid #D7DBEC' ,
          backgroundColor: selected? '#0058ff' : '#fff'
        };
      case 'excel':
        return{
          color: '#131523',
          border:selected? '1px solid #0058ff':'' ,
          backgroundColor: '#F2F6FF',
        }
      case 'delete' :
        return {
          color: selected ? '#FFF' : '#F0142F' ,
          border: '1px solid #F0142F' ,
          backgroundColor: selected ? '#F0142F' : '#fff'
        }

      default:
        return {
          color: selected ? '#FFF' : '#5A607F' ,
          border: '1px solid #D7DBEC' ,
          backgroundColor: selected ? '#5A607F' : '#fff'
        };
    }
  };
  const hoverEvent = () => {
    switch ( buttonType ) {
      case 'cta':
        return {
          backgroundColor: '#0058FF' ,
          color: '#fff'
        };
      case 'popupNormal':
        return {
          backgroundColor: '#0058FF' ,
          color: '#fff'
        }
      case 'popupCta':
        return {
          backgroundColor: '#0058FF' ,
          color: '#fff'
        };
      case 'solution':
        return {
          backgroundColor: '#0058FF' ,
          color: '#fff'
        };
      case 'drag':
        return {
          backgroundColor: '#F5F6FA' ,
          color: '#131523' ,
          fontWeight: 700 ,
        }
      case 'play':
        return {
          color: '#5A607F' ,
          backgroundColor: '#F2F7FF'
        }
      case 'visionPause':
        return {
          backgroundColor: Colors.BgRedHalf ,
        }
      case 'listButton':
        return {
          backgroundColor: '#0058FF' ,
          color: '#fff'
        }
      case 'excel':
        return{
          border:'1px solid #0058ff',
          backgroundColor: '#F2F6FF'
        }
      case 'delete':
        return {
          backgroundColor: '#F0142F',
          color:'#fff'
        }
      default:
        return {
          backgroundColor: '#5A607F' ,
          color: '#fff'
        };
    }
  };
  return (
    <BaseComp style={{
      textAlign: 'center' , alignItems:'center',
      display: ( withIcon || buttonType === 'drag' || buttonType === 'popupNormal' || buttonType === 'popupCta' || buttonType === 'cta' ) ? 'flex' : 'block'
    }}
              tabIndex={tabIndex}
              onFocus={focusButton}
              padding={padding}
              margin={margin}
              width={width}
              height={height}
              fontWeight={fontWeight}
              fontSize={fontSize}
              disabled={disabled}
              shadow={shadow}
              onClick={( e ) => {
                e.stopPropagation()
                if( clickEvent ){
                  clickEvent()
                }
              }}
              ref={handleRef}
              styles={getButtonStyles()}
              hoverEvent={hoverEvent()}
              onMouseDown={clickDown}
              onScroll={scroll}
              onKeyDown={enterKeyEvent}
              marginDis={marginDis}>
      {(withIcon && !iconPositionBack) && <div style={iconStyle}><img src={icon} alt=""/></div>}
      <div style={{display: 'flex' , alignItems: 'center' , justifyContent: 'center'}}>
        <div style={{
          lineHeight: ( withIcon || buttonType === 'drag' || buttonType === 'popupNormal' || buttonType === 'popupCta' ) ? 'auto' : buttonType === 'pms' ? 'none' : '32px' ,
          textOverflow: 'ellipsis' , whiteSpace: ( buttonType === 'drag' || buttonType === 'pms'
          ) ? 'pre-line' : 'nowrap' , overflow: (buttonType === 'excel')? '' : 'hidden' , marginRight: useDelete ? 'auto' : ''
        }}>
          {loading ?
            <div style={{
              display: 'flex' ,
              justifyContent: 'center' ,
              alignItems: 'center' ,
              flexDirection: 'column' ,
              height: height ?? '32px'
            }}>
              <CircularProgress size={20}/>
            </div>
            :
            t( name )}
        </div>
        {/*@ts-ignore*/}
        <div onClick={( e ) => {
          e.stopPropagation();
          deleteButton && deleteButton()
        }}
             style={{
               display: useDelete ? 'flex' : 'none' ,
               fontSize: '16px' ,
               lineHeight: height ?? '32px' ,
               marginLeft: '8px' ,
               fontWeight: 'normal'
             }}>
          {'✕'}
        </div>
      </div>
      {(withIcon && iconPositionBack) && <img src={icon} alt="" style={{marginLeft:'8px',...iconStyle}}/>}
    </BaseComp>
  );
};
export default MainButtons;

interface StyleProps {
  width?: any;
  height?: string;
  margin?: string | number;
  padding?: any;
  border?: string;
  backgroundColor?: string;
  color?: string;
  styles?: React.CSSProperties;
  hoverEvent?: any;
  fontWeight?: string | number;
  fontSize?: string;
  disabled?: boolean
  shadow?: boolean
  marginDis?: boolean
}

const BaseComp = styled.div<StyleProps>`
  outline: none;
    //line-height: ${( props ) => props.height ?? '32px'};
  width: ${props => props.width ? props.width : 'auto'};
  font-size: ${props => props.fontSize ? props.fontSize : '14px'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'bold'};
  border-radius: 4px;
  white-space: ${props => props.styles?.whiteSpace ?? 'nowrap'};
  align-items: center;
  align-self: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, opacity 0.3s, border 0.3s;
  user-select: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${props => props.padding ?? 0};
  color: ${( props ) => props.styles?.color};
  height: ${( props ) => props.height ?? '32px'};
  min-width: ${( props ) => props.width ?? '96px'};
  margin: ${( props ) => props.margin ?? '0 8px'};
  margin-right: ${props => props.marginDis ? 0 : props.margin ?? 8}px;
  border: ${( props ) => props.styles?.border};
  box-shadow: ${props => props.shadow ? '0 1px 4px #15223214' : 'none'};
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'inherit'};
  background-color: ${( props ) => props.styles?.backgroundColor};
  &:hover {
    background-color: ${( props ) => props.hoverEvent.backgroundColor};
    color: ${( props ) => props.hoverEvent.color};
    font-weight: ${( props ) => props.hoverEvent.fontWeight ?? 700};
    border: ${( props ) => props.hoverEvent.border};
  }
`;
