import React,{useState} from 'react';
import ModalBase from "./ModalBase";
import Draggable from "react-draggable";
import styled from "styled-components";
import MenuTitle from "../title/MenuTitle";
import SearchBar from "../input/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import {NoData , validationValue} from "../../style/StyleComponent";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {useLocation} from "react-router-dom";

import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import {AccountInfoType , Modal_View_Type} from "../../types/Fundamental";
import {dataValid , initModal} from "../../function/function";
import MainButtons from "../button/MainButtons";
import ToastPopup from "./ToastPopup";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface Account_Select_Type{
  receivedData:(data:any)=>void
  closeModal:()=>void
  initCustomerData:any[]
  initModelData:any[]
}
const AccountSelectModal:React.FunctionComponent<Account_Select_Type> = ({receivedData, closeModal, initCustomerData,initModelData}) => {
  const location = useLocation()
  const authValid = location.pathname.includes('/admin')
  const selectedCompany = useRecoilValue<any>(authValid? persistCompanyData : userPersistCompanyData)

  const [searchState, setSearchState] = useState({input:'', value:''})

  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [customerPage, setCustomerPage] = useState<number>(1)
  const [customerTotal, setCustomerTotal] = useState<number>(1)
  const [modelPage, setModelPage] = useState<number>(1)
  const [modelTotal, setModelTotal] = useState<number>(1)

  const [customerList, setCustomerList] = useState<AccountInfoType[]>([])
  const [modelList, setModelList] = useState<AccountInfoType[]>([])
  const [selectedCustomer, setSelectedCustomer] = useState<any[]>(initCustomerData)
  const [selectedModel, setSelectedModel] = useState<any[]>(initModelData)

  const customerCount = (customer:any) => customer.filter((v:any,i:number,arr:any)=> arr.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)

  const customerListRes = async () => {
    try {
      const response = await client.get(`/customer/list/${customerPage}/10/${selectedCompany.companyCode}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      setCustomerTotal(data.total_page)
      if(data.total_page > 1 && customerPage > 1){
        setCustomerList((prev:any)=> prev.concat(data.row))
      }else{
        setCustomerList(data.row)
      }
    }catch (e) {
      console.log(e)
    }
  }

  const customerSearchListRes = async () => {
    try {
      const response = await client.post(`/customer/search`,{
        page:customerPage,
        size:10,
        companyCode:selectedCompany.companyCode,
        keyword:searchState.value
      },{
        headers:{Authorization}
      })
      const data = response.data.data
      setCustomerTotal(data.total_page)
      if(data.total_page > 1 && customerPage > 1){
        setCustomerList((prev:any)=> prev.concat(data.row))
      }else{
        setCustomerList(data.row)
      }
    }catch (e) {
      console.log(e)
    }
  }


  const customerModelListRes = async () => {
    try {
      const response = await client.post(`/customerModel/search/${modelPage}/10`,{
        customerCode: customerCount(selectedCustomer).map((v:any)=>v.customerCode)
      },{
                                           headers:{Authorization}
                                         })
      const data = response.data.data
      setModelTotal(data.total_page)
      if(data.total_page > 1 && modelPage > 1){
        setModelList((prev:any)=> prev.concat(data.row))
      }else{
        setModelList(data.row)
      }
    }catch (e) {
      console.log(e)
    }
  }


  React.useEffect(()=>{
    if(searchState.value === ''){
      customerListRes().then()
    }else{
      customerSearchListRes().then()
      setSelectedCustomer([])
      setSelectedModel([])
    }
  },[customerPage, searchState.value])

  React.useEffect(()=>{
    customerModelListRes().then()
  },[selectedCustomer, modelPage])

  const selectedValue = selectedCustomer?.filter((v)=>(selectedModel?.some((el)=> el?.customerCode === v?.customerCode)))

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {dataModal.menu === 'success' && <ToastPopup text={dataModal.message}
                                                    state={'success'}
                                                    closeEvent={()=>setDataModal(initModal)}/>}
          {dataModal.menu === 'warn' && <ToastPopup text={dataModal.message}
                                                    state={'warn'}
                                                    closeEvent={()=>setDataModal(initModal)}/>}
          <Draggable>
            <BaseComponent>
              <div className={'modal'} style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff',color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'}}>
                <div className={'modal_title'} >{('거래처 통합 등록')}</div>
              </div>
              <div onMouseDown={(e)=>e.stopPropagation()}>
                <div style={{display:'grid', gridTemplateColumns:'472px 1fr', gap:'16px',  boxSizing:'border-box',
                  padding:'16px',justifyContent:'space-between',borderBottom:'1px solid #d7d7d7'}}>
                  <div>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                      <MenuTitle title={'거래처 리스트'} unitCase={'MenuCompTitle'}/>
                      <SearchBar keyFunction={(e)=>setSearchState((prev)=>({...prev, input: e.target.value}))}
                                 keyPressDisable={true}
                                 clickEvent={()=> {
                                   setCustomerPage(1)
                                   setModelPage(1)
                                   setSearchState( ( prev ) => ( {...prev , value: prev.input} ) )}}
                                 margin={'0 0 0 16px'}/>
                    </div>
                    <InfiniteScroll scrollableTarget={'customer-scroll'}
                                    hasMore={customerTotal > customerPage} dataLength={dataValid(customerList?.length ,0)}
                                    loader={<div/>} next={()=>setCustomerPage((prev)=> prev+1)}>
                      <GridTableComponent tempCol={'334px 128px'} id={'customer-scroll'}
                                          scrollWidth={462} scrollX_Hide={true} height={336}>
                        <div className={'grid-title-unchecked'}>
                          <div>{'거래처명'}</div>
                          <div>{'대표자명'}</div>
                        </div>
                        {customerList?.length === 0?
                          <NoData height={260}>{'등록된 거래처가 없습니다.'}</NoData>
                          :
                          customerList.map((v)=>(
                            <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                             selected={selectedCustomer.map((code)=>(code.customerCode)).includes(v.customerCode)}
                                             onClick={()=> {
                                               setModelPage(1)
                                               if(selectedCustomer.some((el)=> el.customerCode === v.customerCode)){
                                                 setSelectedCustomer((prev:any)=>(prev.filter((el:any)=>(el.customerCode !== v.customerCode))))
                                                 setSelectedModel((prev:any)=>(prev.filter((el:any)=>(el.customerCode !== v.customerCode))))
                                               }else{
                                                 setSelectedCustomer((prev:any)=>([...prev, {customerName: v.customerName, customerCode: v.customerCode}]))
                                               }}}>
                              {validationValue(v.customerName)}
                              {validationValue(v.managerName)}
                            </GridTableSelect>
                          ))}
                      </GridTableComponent>
                    </InfiniteScroll>
                  </div>
                  <div>
                    <div style={{display:'flex'}}>
                      <MenuTitle title={'모델 리스트'} unitCase={'MenuCompTitle'}/>
                      {/*<SearchBar margin={'0 0 0 16px'}/>*/}
                    </div>
                    <InfiniteScroll scrollableTarget={'model-scroll'}
                                    hasMore={modelTotal > modelPage} dataLength={dataValid(modelList?.length, 0)}
                                    loader={<div/>} next={()=>setModelPage((prev)=> prev+1)}>
                      <GridTableComponent tempCol={'1fr 186px'} id={'model-scroll'}
                                          scrollWidth={502} scrollX_Hide={true} height={336}>
                        <div className={'grid-title-unchecked'}>
                          <div>{'거래처명'}</div>
                          <div>{'모델'}</div>
                        </div>
                        {selectedCustomer.length === 0?
                          <NoData height={260}>{'거래처를 선택해 주세요.'}</NoData>
                          :
                          modelList?.length === 0?
                            <NoData height={260}>{'선택한 거래처의 모델이 없습니다.'}</NoData>
                            :
                            modelList.map((v)=>(
                              <GridTableSelect key={v.customerModelCode} className={'grid-list-unchecked-no_icon'}
                                               selected={selectedModel.map((code)=>(code.customerModelCode)).includes(v.customerModelCode)}
                                               onClick={()=> {
                                                 if(selectedModel.some((el)=> el.customerModelCode === v.customerModelCode)){
                                                   setSelectedModel((prev:any)=>(prev.filter((el:any)=>(el.customerModelCode !== v.customerModelCode))))
                                                 }else{
                                                   setSelectedModel((prev:any)=>([...prev, {...v}]))
                                                 }}}>
                                {validationValue(v.customerName)}
                                {validationValue(v.customerModelName)}
                              </GridTableSelect>
                            ))
                        }
                      </GridTableComponent>
                    </InfiniteScroll>
                  </div>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginTop:'24px'}}>
                  <MainButtons name={'취소'} width={'124px'} clickEvent={closeModal}/>
                  <MainButtons name={'확인'} buttonType={'popupCta'} width={'124px'}
                               clickEvent={()=> {
                                 if(selectedCustomer?.length === 0){
                                   setDataModal({menu:'warn', message:'거래처를 선택해 주세요.'})
                                 }
                                 else if(selectedModel?.length === 0){
                                   setDataModal({menu:'warn', message:'모델을 선택해 주세요.'})
                                 }
                                 else if(selectedCustomer?.length !== selectedValue?.length){
                                   setDataModal({menu:'warn', message:'선택한 거래처 중 선택하지 않은 모델이 존재합니다.'})
                                 }
                                 else{
                                   receivedData(selectedModel)
                                   // setDataModal({menu:'success', message:'거래처와 모델이 선택 되었습니다.'})
                                   // setTimeout(()=>{
                                   closeModal()
                                   // },1000)
                                 }}
                  }/>
                </div>
              </div>
            </BaseComponent>
          </Draggable>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default AccountSelectModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 562px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  user-select: none;
  .modal{
    width: 1032px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;
      &_title{
        font-size: 18px;
        font-weight: 900;
          margin-right: 8px;
      }
      &_sub_title{
        font-size: 14px;
        font-weight: bold;
        opacity: 0.5;
      }
  }
`
