import React , {useState, useEffect} from 'react';
import {ContentFrame, SectionFrame} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Authorization} from "../../../../common/authorization";
import {useParams} from "react-router-dom";

import ConfirmPopup from "../../../../components/modal/ConfirmPopup";

import dayjs from "dayjs";
import client from "../../../../common/Config";
import {Modal_View_Type , solutionDetailDataType} from "../../../../types/Fundamental";
import RadioComponent from "../../../../components/radio/RadioComponent";
import MainButtons from "../../../../components/button/MainButtons";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
} from "../../../../function/function";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import ToastPopup from "../../../../components/modal/ToastPopup";
import {useRecoilValue , useResetRecoilState} from "recoil";
import {selectedCode , selectedListCountValue} from "../../../../common/StateManage";

const SolutionRequestDetail = () => {

  const {t}: any = useTranslation();
  const solutionID = useParams()
  const resetSelectCode = useResetRecoilState(selectedCode)
  const [solutionDetailData, setSolutionDetailData] = useState<solutionDetailDataType>()
  const [solutionState, setSolutionState] = useState<number>(0)
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [companyInfo, setCompanyInfo] = useState<any>()
  const [inputState, setInputState] = useState<{notice?:string, representative?: string, requestDate?:string, finishDate?:string|null }>({
    notice: '',
    representative: '',
    requestDate:'',
    finishDate:''
  })
  const selectedCount = useRecoilValue(selectedListCountValue)

  React.useEffect(()=>{
    setInputState(
      {
        notice: solutionDetailData?.notice,
        representative: solutionDetailData?.representative,
        requestDate: solutionDetailData?.requestDate,
        finishDate: solutionDetailData?.finishDate? dayjs(solutionDetailData?.finishDate).format('YYYY.MM.DD') : dayjs().format('YYYY.MM.DD')
      }
    )
  },[solutionDetailData])
  const onChangeValue = (e:any) => {
    const {value, name} = e.target

    setInputState({
      ...inputState,
      [name]: value
    });
  }

  const solutionValue = (state: number) => {
    switch (state){
      case 0 : return 'run'
      case 1 : return 'finish'
      case 2 : return 'cancel'
    }
  }
  const solutionDetailRes = async () => {
      try {
        const response= await client.post(`/solution/detail`,{
          solutionNo: solutionID.id,
          count: `${Number(selectedCount)+1}`
        },{headers:{Authorization}})
        setSolutionDetailData(response.data.data)

        switch (response.data.data.statement){
          case 'finish' : return  setSolutionState(1)
          case 'cancel' : return  setSolutionState(2)
          case 'wait'||'run': return setSolutionState(0)
        }
      }catch (e) {
        console.log(e)
      }
  }
  const solutionUpdateRes = async (statement: number) => {

    if((!inputState.representative || inputState.representative === '-')){
      setDataModal({menu: 'confirm', message:'담당자를 입력해 주세요.'})
    }else{
      try {
        dataAccessLoading()
        const response = await client.post('/solution/update',{
          notice: inputState.notice,
          representative: inputState.representative,
          solutionNo: solutionID.id,
          statement: solutionValue(statement),
          finishDate: inputState.finishDate
        },{headers:{Authorization}})
        const valid = response.data
        if(valid.status !== 200){
          setDataModal( {menu: 'warn' , message: valid.message})
          loadingRemove()
        }else{
          setTimeout(()=>{
            setDataModal({menu: 'success', message:'솔루션 요청 사항이 저장 되었습니다.'})
            loadingRemove()
          },500)
          if(solutionState === 1){
            const response = await client.post('/company/update',{
              ...companyInfo, phone_number: companyInfo.phoneNumber,
              authorities: (solutionDetailData?.type === 'subscribe')?
                totalSolution?.concat(solutionDetailData?.solution) :
                totalSolution?.filter((v:any)=>(v !== solutionDetailData?.solution))
            },{headers:{Authorization}})
          }
        }
      }catch (e:any) {
        console.log(e)
        setDataModal({menu:'warn',message:e.message})
        loadingRemove()
      }
    }
  }

  const totalSolution = companyInfo?.authorities?.split(',')

  const companyDetailInfo = async () => {
    try {
      const response = await client.post('/company/detail',{
        companyCode: solutionDetailData?.companyCode
    },{headers:{Authorization}})
      const data = response.data.data
      setCompanyInfo(data)
    }catch ( e ) {
      console.log(e)
    }
  }

  React.useEffect(()=>{
    solutionDetailRes().then()
  },[])

  React.useEffect(()=>{
    companyDetailInfo().then()
  },[solutionDetailData])

    const noticeStyle = {
        color: 'rgba(0, 0, 0, 0.5)',
        fontSize: '12px'
    }


  const solutionUpdate = () => {
    if (!inputState.representative || inputState.representative === '-') {
      setDataModal({menu: 'confirm', message: '담당자를 입력해 주세요.'});
    } else {
      if (solutionState === 2) {
        setDataModal({menu: 'select', message: '해당 요청을 취소 하시겠습니까?'});
        resetSelectCode();
      } else {
        solutionUpdateRes(solutionState).then();
        resetSelectCode();
      }
    }
  }


  const statementReturn = (statement?: string) => {
    switch (statement) {
      case 'wait': return '대기'
      case 'run': return '진행중'
      case 'finish': return '완료'
      case 'cancel': return '취소'
    }
  }




  useEffect(() => {
    const handleBeforeUnload = (event:BeforeUnloadEvent) => {
      if (dataModal.menu === '') {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dataModal.menu]);

  const solutionInfo =(title:string,value:any) => {
    return (
      <React.Fragment>
        <div className={'info-title'}>{title}</div>
        <div className={'info-content'}>{value}</div>
      </React.Fragment>
    )
  }

  return (
        <SectionFrame>
          {dataModal.menu === 'success' ?
            <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{
              setDataModal(initModal); window.location.href = `/admin/fas/solution` }}/>
            : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}


            {dataModal.menu  === 'confirm' &&  <ConfirmPopup clickEvent={()=>{
              if((!inputState.representative || inputState.representative ==='-')){
                setDataModal(initModal)
              }
            }} statusText={dataModal.message}/>}

            {dataModal.menu  === 'select' &&
              <SelectConfirmModal statusText={dataModal.message}
                                  clickEvent={()=> {solutionUpdateRes( 2 ).then(); setDataModal(initModal)}}
                                  noticeText={'*’예’ 선택 시 해당 요청 재변경은 불가합니다.'}
                                  cancelEvent={()=>setDataModal(initModal)}/>}

          {dataModal.menu === 'cancel' &&
              <SelectConfirmModal statusText={dataModal.message}
                                  noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                                  cancelEvent={()=>{setDataModal(initModal)}}
                                  viewType={true}
                                  clickEvent={()=>{ window.location.href = `/admin/fas/solution`; setDataModal(initModal)}}
              />}

          <MenuTitle title={'솔루션 요청 관리 > 조회'} unitCase={'MenuTitle'}/>
          <ContentFrame flexDirection={'row'}>
            <React.Fragment>
              <ViewBox>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
                  <MenuTitle title={t('솔루션 요청 정보')} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <MainButtons width={'56px'} name={'취소'}
                                 clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                    <MainButtons name={'저장'} clickEvent={solutionUpdate} marginDis={true}  disabled={buttonAuthValid('create','23')}
                                 buttonType={'cta'} width={'56px'}/>
                  </div>
                </div>
                 <div className={'scroll_func'}>
                  <Information>
                    <div className={'info'} style={{display:'grid',gridTemplateColumns:'168px 234px 168px 234px'}}>
                      {solutionInfo(t('업체명'),solutionDetailData?.companyName)}
                      {solutionInfo(t('업체코드'),solutionDetailData?.companyCode)}
                      {solutionInfo(t('요청 솔루션'),solutionDetailData?.solution)}
                      {solutionInfo(t('요청 날짜'),solutionDetailData?.requestDate)}
                      {solutionInfo(t('요청'),solutionDetailData?.type === 'subscribe'? '구독' : '구독해지')}
                      {solutionInfo(t('상태'),statementReturn(solutionDetailData?.statement))}
                    </div>
                    <div className={'info'} style={{gridTemplateColumns:'168px 1fr',border:'none'}}>
                      <div className={'info-title'} style={{height:'112px'}}>{t('문의내용')}</div>
                      <div className={'info-content'} style={{height:'112px'}}>
                        <div className={'info-content-req'}>{dataValid(solutionDetailData?.request)}</div>
                      </div>
                    </div>
                  </Information>

                 {/*조치내용*/}
                   <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px',marginTop:'45px'}}>
                    <MenuTitle title={t('조치 내용')} unitCase={'MenuCompTitle'}/>
                    <div style={{...noticeStyle, marginLeft:'24px'}}>{t('*저장버튼으로 내용작성을 완료해주세요.')}</div>
                  </div>
                  {/*<ControlButton name={'해당 요청 취소'} clickEvent={()=>solutionUpdateRes('cancel')}/>*/}
                  <Information>
                    <RegisterComponent type={'text'} title={t('담당자')} name={'representative'} value={inputState.representative} paddingRight={true}
                                         onChange={onChangeValue} placeholder={t('담당자')}  disabled={false}/>
                    <div className={'action'} style={{gridTemplateColumns:'168px 1fr',borderBottom:'none'}}>
                      <div className={'action-title'} style={{borderBottom:'none'}}>
                        {t('상태 변경')}
                        <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>
                      </div>
                      <div className={'action-content'} style={{display:'grid', textAlign:'right', gridTemplateColumns:'70px 55px 90px',gap:'10px',borderBottom:'none'}}>
                        {['진행중','완료','요청취소'].map((v,i)=>(
                          <RadioComponent radioText={v} selected={solutionState === i} clickEvent={()=>setSolutionState(i)}/>
                        ))}
                      </div>
                    </div>
                    <div style={{position:'relative'}}>
                    <RegisterComponent type={'calendar'} name={'requestDate'} selectDate={dataValid(inputState?.finishDate, dayjs().format('YYYY.MM.DD'))}
                                       value={dataValid(inputState?.finishDate,'12')} required={true}
                                       title={t('조치 날짜')} left={150} top={-5} useDeleteButton={true}
                                       onChange={(v)=> setInputState((prev:any)=>({...prev, finishDate: dayjs(v).format('YYYY.MM.DD')}))}
                                       placeholder={dayjs().format('YYYY.MM.DD')}/>
                    {/*<div style={{...noticeStyle,position:'absolute',top:'20px',right:'220px'}}>{t('*날짜 선택 시 "완료"로 변경됩니다.')}</div>*/}
                    </div>
                    <RegisterComponent type={'textarea'} value={inputState?.notice} title={'비고'}
                                       name={'notice'}  onChange={onChangeValue} bottomBorder={true}/>
                  </Information>
                  </div>
              </ViewBox>
              <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 30px'}}/>
            </React.Fragment>
            </ContentFrame>
        </SectionFrame>
    );
};

export default SolutionRequestDetail;

const ViewBox = styled.div`
  width: 784px;
  height: 600px;
   

  .scroll_func{
    width: 800px;
    height: 540px;
    overflow-x: hidden;
    overflow-y: scroll;
     
    &::-webkit-scrollbar {
      display: block;
    }
  }
  
`


const Information = styled.div`
  //border-top: 1px solid rgba(213, 215, 227, 0.5);
  //border-bottom: 1px solid #F5F6FA;
  display: grid;
  box-sizing: border-box;
  width: 784px;
    
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  
    .info,.action {
      display: grid;
      box-sizing: border-box;
      width: 784px;
      border-top: 1px solid rgba(213, 215, 227, 0.5);
      
      &-title {
        padding: 16px;
        width: 168px;
        box-sizing: border-box;
        display: flex;
        //justify-content: space-between;
        align-items: center;
        height: 48px;
        background-color: #F5F6FA;
        color:#131523;
        font-size: 12px;
        font-weight: 800;
        border-bottom: 1px solid rgba(213, 215, 227, 0.5);
        
      }
      
        &-content {
          display: flex;
          padding: 16px;
          box-sizing: border-box;
          font-size: 14px;
          height: 48px;
          border-bottom: 1px solid rgba(213, 215, 227, 0.5);
          align-items: center;
        }
      
        &-content-req {
          width: 600px;
          height: 80px;
          overflow-y: scroll;
          overflow-x: hidden;
          &::-webkit-scrollbar {
            width: 15px;
            display: block;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #D5D7E3;
            border-radius: 10px;
            background-clip: padding-box;
            border: 6px solid transparent;
          }
          &::-webkit-scrollbar-track{
            width: 21px;
            background: transparent;
          }
        }
    }
  .scroll_func{
    width: 810px;
    height: 570px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: block;
    }
  }
`
