import React from 'react';
import {NoData , tooltipModule , validationValue} from "../../style/StyleComponent";
import styled from "styled-components";
import ModalBase from "./ModalBase";
import MainButtons from "../button/MainButtons";
import {numberLocaleString} from "../../function/function";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface Props {
	arrayData?: any[]
	closeButton?:()=>void
}

const ProductMoldView:React.FunctionComponent<Props> = ({arrayData, closeButton}) => {

	const [product, setProduct] = React.useState<any>()
	const customerCount = (customer:any) => customer?.filter((v:any,i:number,arr:any)=> arr?.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)

	return (
		<ModalBase zIndex={101} modalContent={
			<BaseComponent>
				<div style={{display:'flex', gap:'16px',position:'relative'}}>
					<div className={'modal'}
							 style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff',color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'}}>
						{'품목 보기'}
					</div>
				</div>
				<div style={{padding:'24px 16px 16px 16px'}}>
					<GridTableComponent tempCol={'repeat(2, 148px) repeat(2, 188px) 88px 140px 1fr'} scrollWidth={990} scrollX_Hide={true}>
						<div className={'grid-title-unchecked'}>
							{['거래처','모델','CODE','품명','품목 종류','UPH','단위'].map((v)=>(
								<div key={v}>{v}</div>
							))}
						</div>
						{/*<InfiniteScroll scrollableTarget={'scroll_func'}*/}
						{/*                loader={<div></div>}*/}
						{/*                hasMore={totalPage > page}*/}
						{/*                dataLength={modalDataList?.length === undefined? 0 : modalDataList?.length}*/}
						{/*                next={()=>setPage((prev)=>prev+1)}>*/}
							<div className={'scroll_func'}>
								{arrayData?.length !== undefined ?
									<React.Fragment>
										{arrayData?.map((v:any)=>(
											<GridTableSelect selected={v?.productCode === product?.productCode} className={'grid-list-unchecked'}
											           onClick={()=>setProduct(v)} key={v?.productCode}>
												{tooltipModule(customerCount(v?.customer)?.map((v:any)=>(v?.customerName)).join(','),0,validationValue(customerCount(v?.customer)[0].customerName + ((customerCount(v?.customer).length -1) !== 0 ?` 외 ${customerCount(v.customer).length-1}` : '')))}
												{tooltipModule(v?.customer?.map((v:any)=>(v?.customerModelName)).join(','),0,validationValue(v?.customer[0].customerModelName + ((v?.customer.length -1) !== 0 ?` 외 ${v?.customer.length-1}` : '')))}
												{validationValue(v?.serialCode)}
												{validationValue(v?.productName)}
												{validationValue(v?.type)}
												<div>
													{numberLocaleString(v?.uph)}
												</div>
												{validationValue(v?.unit,'ea')}
											</GridTableSelect>
										))}
									</React.Fragment>
									:
									<NoData height={240}>
										{'등록된 품목이 없습니다.'}
									</NoData>
								}
							</div>
						{/*</InfiniteScroll>*/}
					</GridTableComponent>
				</div>
				<MainButtons name={'확인'} buttonType={'cta'} clickEvent={()=> {
					if (closeButton) {
						closeButton()
					}}
				} width={'144px'}/>
			</BaseComponent>
		}/>
	);
};

export default ProductMoldView;

const BaseComponent = styled.div<{rightCus?:boolean,topCus?:boolean,topBackColor?:string}>`
  width: 1032px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  right: ${p=>p.rightCus ? 21.5 : 'auto'}%;
  top:${p=>p.topCus ? 22.5 : 'auto'}%;
  
  .modal{
    width: 1032px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 900;
  }
  .scroll_func{
    height: 292px;
    overflow: hidden;
  }
`
// const Container = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   border: 1px solid #D7DBEC;
//   border-radius: 4px;
//   height: 32px;
//   width: 384px;
//   align-items: center;
//
//   input{
//     width:100%;
//     padding: 0 4px 0 8px;
//     outline: none;
//     border: none;
//     font-family: "Nanum Gothic", sans-serif;
//     &::placeholder{
//       font-size: 14px;
//       color: rgba(90,96,127,0.3);
//     }
//   }
//   .search_button{
//     width: 24px;
//     height: 24px;
//     margin-right: 4px;
//     cursor: pointer;
//     background-color: transparent;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//   }
// `
