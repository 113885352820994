import React , {useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {useParams} from "react-router-dom";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import noImage from "../../../../../asset/image/noImage.svg";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  ContentFrame ,
  multipleImageGridStyle ,
  NoData ,
  SectionFrame ,
  tooltipModule , validationValue
} from "../../../../../style/StyleComponent";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import {Modal_View_Type , ProductListType , QualityInspectionItem} from "../../../../../types/Fundamental";

import {
  authValid ,
  buttonAuthValid ,
  dataValid , initModal ,
  loadingRemove ,
  locationFilter ,
  modalListLoading , numberLocaleString
} from "../../../../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import InspectionFormModal from "../../../../../components/modal/InspectionFormModal";
import FacilityRegisterModal from "../../../../../components/modal/FacilityRegisterModal";
import MoldRegisterModal from "../../../../../components/modal/MoldRegisterModal";
import PreviewImage from "../../../../../components/display/PreviewImage";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import BomViewerModal from "../../../../../components/modal/fas/product/BomViewerModal";

const ProductDetail = () => {
  const selectCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [detailDataState , setDetailDataState] = useState<ProductListType>( {customer: []} )
  const [inspectionData , setInspectionData] = useState<QualityInspectionItem | any>();
  const [isOpened , setIsOpened] = React.useState<boolean>( false );
  const params = useParams()
  const {productCode} = params
  const detailProductRes = async () => {
    try {
      const response = await client.post( '/product/detail' , {
        productCode: productCode
      } , {headers: {Authorization}} )
      setDetailDataState( response.data.data )
    } catch ( e ) {
      console.log( e )
    }
  }
  const inspectionItemDataRes = async () => {
    modalListLoading()
    try {
      const response = await client.post( `/quality/test/read` , {
        productCode: productCode
      } , {headers: {Authorization}} )
      const data = response.data.data;
      // console.log(data)
      setInspectionData( data )
      loadingRemove()
    } catch ( e ) {
      console.log( e )
      loadingRemove()
    }
  }
  React.useEffect( () => {
    detailProductRes().then()
    inspectionItemDataRes().then()
  } , [] )
  const goProductManagement = () => {
    window.location.href = `${authValid() ? '/admin/fas/product' : '/fas/product'}`
  }
  const {t}: any = useTranslation();
  const customerCount = ( customer: any ) => customer.filter( ( v: any , i: number , arr: any ) => arr.findIndex( ( el: any ) => ( el.customerCode === v.customerCode ) ) === i )
  // const [selectImage, setSelectImage] = React.useState<string>('')
  // const imageChange = (index:number) => {
  //     // @ts-ignore
  //     setSelectImage(detailDataState.imageUrl[index])
  //     setDataModal({menu:'imageDetail'})
  // }
  const formData = inspectionData?.inspectionItems?.map( ( v: any , index: number ) => ( Object.assign( inspectionData?.inspectionCriteria?.map( ( data: any ) => ( Object.assign( {} , {inspectionCriteria: data} ) ) )[index] , {inspectionItems: v} ) ) )
  // 임시 데이터
  return (
    <React.Fragment>
      {dataModal.menu === 'bomReg' &&
          <BomViewerModal bomDetailData={detailDataState}
                          // manageButton={true}
                          closeEvent={()=>setDataModal(initModal)}/>
          // <BillOfMaterialModal bomDetailData={detailDataState} bomView={true} authValid={authValid()}
          //                      closeEvent={() => setDataModal( initModal )}/>
      }

      {/*{dataModal.menu === 'facility' &&*/}
      {/*  <FacilitySearchSelectModal*/}
      {/*    selectedFacility={detailDataState?.facilityCode}*/}
      {/*    selectedData={(v)=>setDetailDataState((prev:any)=>({...prev, facilityCode: v}))}*/}
      {/*    companyCode={selectCompany.companyCode} modalView={true}*/}
      {/*    closeButton={()=>setDataModal(initModal)}/>}*/}


      {/*{modalState.open && modalState.status === 'imageDetail' &&*/}
      {/*    <ImageDetailPreview image={selectImage}*/}
      {/*                        closeEvent={()=> setModalState({open: false, status:'', message:''})}/>}*/}

      {dataModal.menu === 'facility' &&
          <FacilityRegisterModal companyCode={selectCompany.companyCode} viewType={true}
                                 receivedFacility={() => {}}
                                 selectProduct={detailDataState}
                                 selectedFacility={detailDataState?.facilityCode}
                                 cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'mold' &&
          <MoldRegisterModal selectProduct={detailDataState}
                             companyCode={selectCompany.companyCode}
                             receivedMold={() => {}}
                             viewType={true}
                             selectedMold={detailDataState?.moldCode}
                             cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'insReg' &&
          <InspectionFormModal productData={detailDataState} getData={formData} inspectionLength={formData?.length <= 8 ? 8 : formData?.length}
                               openEvent={() => {setIsOpened( !isOpened )}} isOpenedProps={isOpened}
                               cancelEvent={() => {setDataModal( initModal )}} imageUrlList={detailDataState?.imageUrl}
                               sampleIdx={Number( inspectionData.inspectionSample )}/>
      }

      {dataModal.menu === 'preview' &&
        //@ts-ignore
          <ImageDetailPreview profile={detailDataState?.imageUrl[dataModal.index]}
                              imageUrl={detailDataState?.imageUrl}
                              clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'workPreview' &&
        //@ts-ignore
          <ImageDetailPreview profile={detailDataState?.workStandardImgUrl[dataModal.index]}
                              imageUrl={detailDataState?.workStandardImgUrl}
                              clickEvent={() => {setDataModal( initModal )}}/>}

      <SectionFrame>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 품목 기본정보 > 조회'} unitCase={'MenuTitle'}/>
        <ContentFrame width={'1272px'} height={'680px'} flexDirection={'row'}>
          <div className={'scroll_box'} style={{width: '784px'}}>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'품목 기본정보 조회'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'목록'} width={'56px'} clickEvent={() => {goProductManagement()}}/>
                <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                             disabled={buttonAuthValid( 'update' , '8' )}
                             clickEvent={() => {window.location.href = authValid() ? `/admin/fas/product/modify/${productCode}` : `/fas/product/modify/${productCode}`}}/>
              </div>
            </div>
            <DetailListInfo>
              {authValid() &&
                  <ListInfoComponent title={t( '업체명' )} value={detailDataState?.companyName} bottomBorder={false}/>}
              <div style={{display: 'grid' , gridTemplateColumns: '1fr 1fr'}}>
                {authValid() &&
                    <React.Fragment>
                      <ListInfoComponent title={t( '대표자' )} value={dataValid( detailDataState?.president )}
                                         bottomBorder={false}/>
                      <ListInfoComponent title={t( '지역' )}
                                         value={dataValid( locationFilter( detailDataState?.address ) )}
                                         bottomBorder={false}/>
                    </React.Fragment>}
                <ListInfoComponent title={t( '거래처' )}
                                   value={tooltipModule( customerCount( detailDataState?.customer ).map( ( v: any ) => ( v?.customerName ) ).join( ',' ) , 0 , validationValue( customerCount( detailDataState?.customer )[0]?.customerName + ( ( customerCount( detailDataState?.customer )?.length - 1 ) !== 0 ? ` 외 ${customerCount( detailDataState?.customer )?.length - 1}` : '' ) ) )}
                                   bottomBorder={false}/>

                <ListInfoComponent title={t( '모델' )} //@ts-ignore
                                   value={tooltipModule( detailDataState?.customer?.map( ( v: any ) => ( v?.customerModelName ) ).join( ',' ) , 0 , validationValue( detailDataState?.customer[0]?.customerModelName + ( ( detailDataState?.customer?.length - 1 ) !== 0 ? ` 외 ${detailDataState?.customer?.length - 1}` : '' ) ) )}
                                   bottomBorder={false}/>
                <ListInfoComponent title={t( 'CODE' )} value={dataValid( detailDataState?.serialCode )}
                                   bottomBorder={false}/>
                <ListInfoComponent title={t( '품명' )} value={dataValid( detailDataState?.productName )}
                                   bottomBorder={false}/>
                <ListInfoComponent type={'unit'} title={t( '캐비티' )} value={dataValid( detailDataState?.cavity )}
                                   bottomBorder={false} unit={'ea'}/>

                <ListInfoComponent type={'unit'} title={t( '품목 중량' )} value={dataValid( detailDataState?.weight,'0' )}
                                   bottomBorder={false} unit={dataValid( detailDataState?.weightUnit , 'g' )}/>

                <ListInfoComponent type={'text'} title={'-'} value={'-'}
                                   bottomBorder={false}/>

                <ListInfoComponent type={'unit'} title={t( '세로(Feeder)')} value={dataValid( numberLocaleString(detailDataState?.length) )}
                                   bottomBorder={false} unit={'mm'}/>

                <ListInfoComponent title={t( '품목 종류' )} value={dataValid( detailDataState?.type )}
                                   bottomBorder={false}/>

                <ListInfoComponent title={t( '단위' )} value={dataValid( detailDataState?.unit , 'ea' )}
                                   bottomBorder={false}/>
                <ListInfoComponent title={t( '생산공정' )} value={dataValid( detailDataState?.processName )}
                                   bottomBorder={false}/>

                <ListInfoComponent type={!detailDataState?.facilityCode ? 'button' : 'textClick'} title={t( '기계' )}
                                   buttonName={'등록된 기계 없음'} contentText={'기계 보기'}
                                   clickEvent={() => setDataModal( {menu: 'facility'} )}
                                   disabled={!detailDataState?.facilityCode}
                                   bottomBorder={false}/>

                <ListInfoComponent type={'unit'} title={t( '기준 UPH' )}
                                   value={dataValid( numberLocaleString( detailDataState?.uph ) , 0 )}
                                   unit={dataValid( detailDataState?.unit , 'ea' )} bottomBorder={false}/>

                <ListInfoComponent type={detailDataState?.bom === 'empty' ? 'button' : 'textClick'} title={'BOM'}
                                   contentText={t( 'BOM 보기' )}
                                   buttonName={t( '등록된 BOM 없음' )}
                                   disabled={detailDataState?.bom === 'empty'}
                                   clickEvent={() => setDataModal( {menu: 'bomReg'} )}
                                   bottomBorder={false}/>

                <ListInfoComponent type={!inspectionData ? 'button' : 'textClick'} title={t( '초·중·종 검사' )}
                                   contentText={t( '검사양식 보기' )} disabled={buttonAuthValid( 'create' , '79' )}
                                   buttonName={t( '검사항목 등록' )}
                                   clickEvent={() => {
                                     if( !inspectionData ){
                                       window.location.href = authValid() ? `/admin/fas/product/inspection/${productCode}` :
                                         `/fas/product/inspection/${productCode}`
                                     } else{
                                       setDataModal( {menu: 'insReg'} )
                                     }
                                   }}
                                   bottomBorder={false}/>

                <ListInfoComponent type={!detailDataState?.moldCode ? 'button' : 'textClick'} title={t( '금형' )}
                                   buttonName={'등록된 금형 없음'} contentText={'금형 보기'}
                                   clickEvent={() => setDataModal( {menu: 'mold'} )}
                                   disabled={!detailDataState?.moldCode}
                                   bottomBorder={false}/>
              </div>
              <ListInfoComponent title={t( '비고' )} type={'textarea'} height={authValid() ? '156px' : '254px'}
                                 value={dataValid( detailDataState?.notice ,
                                   <NoData height={authValid() ? 136 : 234}>{t( '* 작성하신 내용이 없습니다.' )}</NoData> )}
                                 bottomBorder={true}/>
            </DetailListInfo>
          </div>

          <div style={{width: '1px' , height: '549px' , backgroundColor: '#E6E9F4' , margin: '30px'}}/>

          <div className={'scroll_comp'} style={{width: '377px' , overflowY: 'scroll' , height: '600px'}}>
            <MenuTitle title={'제품 이미지'} unitCase={'MenuCompTitle'}/>
            <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
              {detailDataState?.imageUrl?.length !== 0 ?
                detailDataState?.imageUrl?.map( ( item , index ) => (
                  <PreviewImage imageUrl={item} key={index} noneDelete={true}
                                clickEvent={() => setDataModal( {menu: 'preview' , index: index} )}/>
                ) )
                :
                <PreviewImage imageUrl={noImage} noneDelete={true}/>
              }
            </div>

            <div style={{display: 'flex' , alignItems: 'center' , marginTop: '24px'}}>
              <MenuTitle title={'작업 표준서'} unitCase={'MenuCompTitle'}/>
            </div>
            <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
              {detailDataState?.workStandardImgUrl?.length !== 0 ?
                detailDataState?.workStandardImgUrl?.map( ( item , index ) => {
                                                            return (
                                                              <PreviewImage imageUrl={item} key={index} noneDelete={true}
                                                                            clickEvent={() => setDataModal( {menu: 'workPreview' , index: index} )}/>
                                                            )
                                                          }
                ) :
                <PreviewImage imageUrl={noImage} noneDelete={true}/>
              }
            </div>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default ProductDetail;
const DetailListInfo = styled.div`
  height: 570px;
  width: 784px;
  overflow-x: hidden;
  overflow-y: scroll;

  & > div {
    //user-select: none;
    display: flex;
    min-height: 48px;
    line-height: 48px;
    box-sizing: border-box;
  }

`

