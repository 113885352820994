import React, {useEffect} from 'react';
import styled from 'styled-components';
import ModalBase from "./ModalBase";
import {NoData, tooltipModule} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {useTranslation} from "react-i18next";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";


interface Props {
	companyCode: string
	keyFunction?: (e: React.ChangeEvent<HTMLInputElement>) => void
	receiveData?: (object: any) => void
	confirmEvent?: () => void
	clickEvent?: () => void
	authValid: boolean
}

type Document_Log_Type = {
	companyCode: string
	detail: string
	modifiedDate: string
	type: string
}

const DocumentLogModal = ({keyFunction, confirmEvent, receiveData, clickEvent, companyCode, authValid}: Props) => {
	const [rowList, setRowList] = React.useState<Document_Log_Type[]>([]);
	const {t}: any = useTranslation()
	const documentLogListRes = async () => {
		try {
			const response = await client.get(`/SDM/documentLog/${companyCode}`, {headers: {Authorization}})
			const data = response.data
			setRowList(data.data.row)
		} catch (e) {
			console.log(e)
		}
	}

	React.useEffect(() => {
		documentLogListRes().then()
	}, [companyCode])

	return (
		<ModalBase modalContent={
			<BaseComponent authValid={authValid}>
				<div className={'modal_title'}>{t('문서 로그')}</div>
				<div className={'modal_list'}>
					<div style={{marginBottom: '24px'}}>
						{/*<SearchBar widthCus={'384px'}*/}
						{/*           value={''} keyFunction={keyFunction} clickEvent={clickEvent} />*/}
						<GridTableComponent tempCol={'1fr 96px 208px'} height={346} scrollWidth={974} scrollX_Hide={true} >
							<div className={'grid-title-unchecked'}>
								{['변경사항', '종류', '변경 시간'].map((value, index) => (
									<div>{t(value)}</div>
								))}
							</div>
							{rowList.length !== 0 ?

									rowList.map((v, index) => (
										<GridTableSelect className={'grid-list-unchecked'}
										                 key={index} onClick={() => {
											if (receiveData) {
												receiveData(v)
											}
										}}>
											{tooltipModule(v.detail)}
											<div>{t(v.type)}</div>
											<div>{v.modifiedDate}</div>
										</GridTableSelect>
									))

								:
								<NoData height={266}>{t('* 문서로그가 없습니다.')}</NoData>
							}
						</GridTableComponent>
					</div>
					<MainButtons name={'확인'} buttonType={'cta'} width={'144px'}
					             clickEvent={() => {
						             if (confirmEvent) {
							             confirmEvent()
						             }
					             }}
					/>
				</div>
			</BaseComponent>
		}/>
	);
};

export default DocumentLogModal;

const BaseComponent = styled.div<{ authValid: boolean }>`
  position: absolute;
  width: 1032px;
  box-sizing: border-box;

  .scroll_func {
    overflow-x: hidden;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      display: block;
      width: 18px;
    }
  }


  .modal_title {
    height: 56px;
    color: ${props => props.authValid ? '#171725' : '#fff'};
    background-color: ${props => props.authValid ? '#CFFA61' : '#0058ff'};
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 4px #15223214;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 18px;
    justify-content: space-between;
    user-select: none;
  }

  .modal_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    background-color: #fff;
    height: 468px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 0 0 6px 6px;
  }

`
