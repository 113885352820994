import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import ProcessTypeManagement from "../../../containers/admin/fas/company/processtype/ProcessTypeManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasTypeProcess = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<ProcessTypeManagement/>}/>
    </PageContainer>
  );
};

export default FasTypeProcess;