import axios from "axios";
import {SERVER_ADDRESS, BASED_ADDRESS, TEST_ADDRESS, JS_ADDRESS, CM_ADDRESS} from "../api/endPoint";

const client = axios.create()

client.defaults.baseURL = TEST_ADDRESS

// export const cancelTokenSource = axios.CancelToken.source()

export default client;
