import React , {useEffect , useRef , useState} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import erroWarn from '../../asset/image/toasticons/to_err_warn.svg';
import err from '../../asset/image/toasticons/to_err.svg';
import warn from '../../asset/image/toasticons/to_warn.svg';
import success from '../../asset/image/toasticons/to_res.svg';
import sucWarn from '../../asset/image/toasticons/to_res_warn.svg';
import question from '../../asset/image/toasticons/to_qmark.svg';
import ModalBase from './ModalBase';
import {ModalBackCss} from "../../style/StyleComponent";
import {useWindowResizeScale} from "../../function/function";

interface Props {
  state?:  'errorWarn' | 'error' | 'warn' | 'success' | 'mobileSuccess' | 'mobileWarn' | 'mobileError' | 'successWarn' | 'question' | 'deleteCase';
  text?: string;
  closeEvent?: ()=> void;
  timeSet?: number;
  transparent?: boolean,
  // 삭제 시 알림 전용
  deleteCase?: any[]
}

const ToastPopup: React.FC<Props> = ({ state, text,closeEvent,timeSet , transparent,deleteCase}) => {
  const { t }: any = useTranslation();


  const statusChanger = (status: any) => {
    switch (status) {
      case 'errorWarn':
        return (
          <ToastFrame backColor="#f9e1e5" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={erroWarn} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'error':
        return (
          <ToastFrame backColor="#f9e1e5" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={err} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'mobileError':
        return(
          <MobileToastFrame onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: "#F0142F", fontSize: '14px'}}>{t(text)}</div>
            </div>
          </MobileToastFrame>
        )
      case 'warn':
        return (
          <ToastFrame backColor="#ffeed5" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={warn} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'mobileWarn':
        return(
          <MobileToastFrame onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: "#ff4d00", fontSize: '14px'}}>{t(text)}</div>
            </div>
          </MobileToastFrame>
        )
      case 'success':
        return (
          <ToastFrame backColor="#e8eaf3" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={success} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'mobileSuccess':
        return(
          <MobileToastFrame onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: '#0058FF', fontSize: '14px'}}>{t(text)}</div>
            </div>
          </MobileToastFrame>
        )
      case 'successWarn':
        return (
          <ToastFrame backColor="#e8eaf3" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={sucWarn} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'question':
        return (
          <ToastFrame backColor="#f9e1e5" onClick={closeParent}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={question} alt="icon" style={{ marginRight: '16px' }} />
              <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
            </div>
          </ToastFrame>
        );
      case 'deleteCase':
        return (
          <React.Fragment>
            {deleteCase?.map((v)=>(
              <ToastFrame backColor="#ffeed5" style={{margin:'2px 0', height:'64px', width:'auto'}} onClick={closeParent}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'flex-start', width:'100%'}}>
                  <img src={warn} alt="icon" style={{marginRight:'16px'}} />
                  <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{v.code + v.case}</div>
                </div>
              </ToastFrame>
            ))}
            <CloseButton onClick={closeParent}>{'닫기'}</CloseButton>
          </React.Fragment>
        );
      default:
        return (
          <ToastFrame backColor="#f9e1e5" onClick={()=>closeParent()}>
            <img src={err} alt="icon" style={{ marginRight: '24px' }}/>
            <div style={{ color: '#000', fontSize: '14px', fontWeight: '700' }}>{t(text)}</div>
          </ToastFrame>
        );
    }
  };

  const closeParent = () => {
    closeEvent && closeEvent()
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      closeParent();
    }, timeSet ?? 1700);
    return () => {
      clearTimeout(timer);
    };
  }
  ,[]);

  return (
    <React.Fragment>
      <ModalBase modalContent={statusChanger(state)} transparent={transparent}/>
    </React.Fragment>
  );
};

export default ToastPopup;

const ToastFrame = styled.div<{ backColor?: string }>`
  width:auto;
  min-width: 336px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  border-radius: 40px;
  height: 72px;
  padding: 32px;
  background-color: ${(props) => props.backColor ?? 'rgba(215, 219, 236, 0.5)'};
  opacity: 1;
  box-shadow: 0 1px 4px rgba(21, 34, 50, 0.08);
  animation: fadeInOut 0.5s ease-out;
  animation-fill-mode: forwards;
  visibility: visible;
  cursor: pointer;
  user-select: none;

  &>div{
    &>img{
      min-width: 42px;
    }
  }
  
  @keyframes fadeInOut {
    0% {
      visibility: hidden;
    }
  
    100% {
      visibility: visible;
    }
  }
`;

const MobileToastFrame = styled.div`
  width:auto;
  min-width: 312px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  border-radius: 80px;
  height: 80px;
  padding: 32px;
  opacity: 0.9;
  background-color: #F2F2F2;
  box-shadow: 0px 3px 6px #00000029;
  animation: fadeInOut 0.5s ease-out;
  animation-fill-mode: forwards;
  visibility: visible;
  cursor: pointer;
  user-select: none;
  @keyframes fadeInOut {
    0% {
      visibility: hidden;
    }

    100% {
      visibility: visible;
    }
  }
`

const CloseButton = styled.div`
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  background-color: #e8eaf3;
  color: #171725;
  height: 32px;
  line-height: 32px;
  border-radius: 6px;
  user-select: none;
  transition: all 0.2s;
  font-size: 14px;
  font-weight: 700;
  width: 52px;
  margin-top: 6px;
  &:hover{
    background-color: #0058ff;
    color: #fff;
  }
`