import React , {useEffect , useRef , useState} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  GridTableComponent ,
  GridTableSelect , NoData ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {
  authValid ,
  dataValid , decimalNumberValid ,
  formatNumber ,
  initModal , listLoadingCircle , loadingRemove ,
} from "../../../../../function/function";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";

import TESTInnerInput from "../../../../../components/input/TESTInnerInput";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import AccountSelectModal from "../../../../../components/modal/AccountSelectModal";
import {useRecoilValue , useResetRecoilState} from "recoil";
import {bomProductState , persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import ProcessProductFilterModal from "../../../../../components/modal/processwave/ProcessProductFilterModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";

import top from '../../../../../asset/image/buttonTriangleTop.svg'
import bottom from '../../../../../asset/image/buttonTriangleBottom.svg'
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import RequiredAmountCalcModal from "../../../../../components/modal/fas/product/RequiredAmountCalcModal";
import {AutoTextSize} from "auto-text-size";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";

const ProductProcessWaveContainer = () => {
  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
  const productBasicTitle = ['Lv.', '거래처', '모델', 'CODE(품번)', '품명', '품목 종류', '단위', '생산공정', 'BOM']
  const productTypeTitle = ['Lv.', 'CODE(품번)', '품명', '품목 종류', '단위', '생산공정', 'BOM', '사용량 / 소요량', '캐비티']
  const needType = ['CODE(품번)', '품명', '품목 종류', '단위', '생산공정', '사용량 / 소요량']
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const bomProduct = useRecoilValue(bomProductState)
  const resetBomProduct = useResetRecoilState(bomProductState)
  const [registerProduct, setRegisterProduct] = useState<any>({...bomProduct, codeDoubleCheck: false})

  const [processPage, ] = useState<number>(1)
  const [, setProcessTotal] = useState<number>(1)
  const [processList, setProcessList] = useState<any>([])
  //현재 불러오는 방식이 아닌 BOM등록시 새로 품목을 등록하여 BOM에 넣을때 사용되던 state / 현재 사용되지 않음
  const [codeAutoCreate, ] = useState<boolean>(false)
  const [finishedState, setFinishedState] = useState<boolean>(false)

  const [rowCount, setRowCount] = useState<number>(0)
  const [selectedLevel, setSelectedLevel] = useState<string>('')
  const [duplicateCount, setDuplicateCount] = useState<number>(0)

  const [checkLevelPosition, setCheckLevelPosition] = useState<string[]>([])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, doubleCheck?: boolean) => {
    const { name, value } = e.target;
    if(doubleCheck && registerProduct.importProduct){
      setDataModal({menu:'select', message:'새로운 품목을 등록 하시겠습니까?'})
    }
    else{
      setRegisterProduct((prev: any) => ({ ...prev, [name]: value ,
        codeDoubleCheck: doubleCheck ? false : prev.codeDoubleCheck}));
    }
  };

  const [rowList, setRowList] = useState<any[]>([])

  const registerFinished = async () => {
    if(!registerProduct.customer[0]?.customerCode){
      setDataModal( {menu: 'warn' , message: '거래처와 모델을 선택해 주세요.'} )
    }
    else if(!registerProduct.serialCode){
      setDataModal( {menu: 'warn' , message: '품목 코드를 입력해 주세요.'} )
    }
    else if(!registerProduct.productName){
      setDataModal( {menu: 'warn' , message: '품목명을 입력해 주세요.'} )
    }
    else if(!registerProduct.type){
      setDataModal( {menu: 'warn' , message: '품목 종류를 선택해 주세요.'} )
    }
    else if(!registerProduct.processCode){
      setDataModal( {menu: 'warn' , message: '생산 공정을 선택해 주세요.'} )
    }
    else if(!registerProduct?.codeDoubleCheck){
      setDataModal( {menu: 'warn' , message: '시리얼CODE 중복확인을 해 주세요.'} )
    }
    else{
      listLoadingCircle()
      try {
        const response = await client.post('/product/register',[{
          ...registerProduct,
          companyCode: selectedCompany.companyCode,
          notice: '' ,
          facilityCode: [] ,
          moldCode: [] ,
          uph: '0' ,
          image: [] ,
          workStandardImg: [] ,
          cavity: '1' ,
        }],{headers:{Authorization}})
        if(response.data.status === 200){
          setTimeout(()=>{
            setDataModal({menu:'success', message:'품목이 등록 되었습니다.'})
            setFinishedState(true)
            setRegisterProduct((prev:any)=>({...prev, productCode: response.data.data.productCode}))
            loadingRemove()
          },300)
        }
        else{
          setDataModal({menu:'error', message: response.data.message})
          loadingRemove()
        }
      }
      catch ( e:any ) {
        console.log(e)
        setDataModal({menu:'error', message: e.message})
        loadingRemove()
      }
    }
  }

  const processListRes = async () => {
    try {
      const response = await client.get( `/process/type/list/${processPage}/10/${selectedCompany.companyCode}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      setProcessTotal( data.total_page )
      if( data.total_page > 1 && processPage > 1 ){
        setProcessList( ( prev: any ) => prev.concat( data.row.map((v:any)=>({processName: v.processName, processCode: v.processCode})) ) )
      }
      else{
        setProcessList( data.row.map((v:any)=>({processName: v.processName, processCode: v.processCode})) )
      }
    } catch ( e ) {
      console.log( e )
    }
  }

  useEffect( () => {
    processListRes().then()
  } , [selectedCompany.companyCode , processPage] )

  const serialDoubleCheck = async () => {
    try {
      const response = await client.post('/product/serial/check',{
        companyCode: selectedCompany.companyCode,
        serialCode: registerProduct?.serialCode,
      },{headers:{Authorization}})

      const duplicateCount = response.data.data
      setDuplicateCount(response.data.data[0])
      if(duplicateCount[0] === 0){
        setRegisterProduct((prev:any)=>({...prev, codeDoubleCheck: true}))
        setDataModal({menu:'success', status: 200, message: '중복되지 않은 시리얼CODE 입니다.'})
      }else{
        setDataModal({menu: 'doubleCheck', status: 201, message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'})
      }
    }catch ( e ) {
      console.log(e)
      setDataModal({menu:'confirm', message:' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
    }
  }

  const openRow = (selectedItem: any) => {
    const toggleOpenState = (item: any) => {
      if (item === selectedItem) {
        return { ...item, open: !item.open, }
      }
      else if (item.bomInfo) {
        return { ...item, bomInfo: item.bomInfo.map(toggleOpenState) }
      }
      return item
    }

    setRowList(prevRowData => {
      return prevRowData.map( toggleOpenState )
    })
  }

  const addDataDynamically = (parentCode: string) => {
    setRowList((prevRowData) => {
      const updateItem = (item: any) => {
        if (item.levelPosition === parentCode) {
          // 선택한 객체에 inner 배열이 없으면 inner 배열을 만들고 새로운 객체 추가
          return { ...item, companyCode: selectedCompany.companyCode, open: true, bomInfo: [...(item.bomInfo || []), { levelPosition: `${parentCode}-${dataValid(item?.bomInfo?.length,0) + 1}`, depthLevel: parentCode?.split('-').length + 1, companyCode: selectedCompany.companyCode, usage:'1', requirementFormula:'1',  productSubCode:'', processName:'', processCode:'', type:'', unit:'', bomInfo:[]}]}
        }
        else if (item.bomInfo) {
          // bomInfo 배열이 존재하면 재귀적으로 내부 항목들도 업데이트
          return { ...item, companyCode: selectedCompany.companyCode, bomInfo: item.bomInfo.map(updateItem)}
        }
        return item
      }
      return prevRowData.map( updateItem )
    })
  }

  // const updateSerialValue = (targetCode: string, serialValue: string) => {
  //   setRowList((prevRowData) => {
  //     const updateItem = (item: any) => {
  //       if (item.code === targetCode) {
  //         // 선택한 객체의 serial 속성을 추가 또는 업데이트
  //         return { ...item, serial: serialValue };
  //       } else if (item.bomInfo) {
  //         // bomInfo 배열이 존재하면 재귀적으로 내부 항목들도 업데이트
  //         return { ...item, bomInfo: item.bomInfo.map(updateItem) };
  //       }
  //       return item;
  //     };
  //
  //     const updatedData = prevRowData.map(updateItem);
  //     return updatedData;
  //   });
  // };

  // bomInfo 안에 있는 object
  const replaceList = rowList.reduce((acc, item) => {
    const processItem = (obj:any) => {
      //가져올 필요 없는 값들 제외하기 위해 해당 key를 입력한 가져올 값들만 spread해둠
      const {open, requirementFormula, productSubCode, usage, ...data} = obj

      acc.push({ ...data, productCode: productSubCode });
      // if (obj.bomInfo) obj.bomInfo.forEach(processItem);
      // else if (obj.depthLevel) acc.push({ ...data });
      if (obj.bomInfo) {
        obj.bomInfo.forEach(processItem);
      } else if (obj.depthLevel) {
        acc.push({ ...obj });
      }
    };

    processItem(item);
    return acc;
  }, []);

  const uniqueSet = new Set()
  const uniqueArray = replaceList.filter((item:any)=>{
    const isDuplicate = uniqueSet.has(item.levelPosition)
    uniqueSet.add(item.levelPosition)
    return !isDuplicate
  })

  // depth가 0 -> 완제품의 bom 정보를 가져오기 위한 list
  const zeroDepthBomList = rowList.reduce((acc, item) => {
    const processItem = (obj:any) => {
      const {open, ...data} = obj

      acc.push({ ...data });
      // if (obj.bomInfo) obj.bomInfo.forEach(processItem);
      // else if (obj.depthLevel) acc.push({ ...data });
      if (obj.bomInfo) {
        obj.bomInfo.forEach(processItem);
      } else if (obj.depthLevel) {
        acc.push({ ...obj });
      }
    };
    processItem(item);
    return acc;
  }, []);

  // 모든 BOM을 levelPosition 별로 나열하는 배열 함수
  const zeroDepthSet = new Set()
  const zeroDepthBomCheck = zeroDepthBomList.filter((item:any)=>{
    const isDuplicate = zeroDepthSet.has(item.levelPosition)
    zeroDepthSet.add(item.levelPosition)
    return !isDuplicate
  })

  // const filteringValue = (obj:any) => {
  //   const {customer, ...data} = obj
  //   return data
  // }

  // console.log([{levelPosiiton:'0', depthLevel:0, bomInfo: zeroDepthBomCheck.filter((el:any)=>(el.depthLevel === 1))},...uniqueArray])
  const bomProcessReceiveData = ([{ ...registerProduct, levelPosiiton:'0', depthLevel:0, bomInfo: zeroDepthBomCheck.filter((el:any)=>(el.depthLevel === 1))},...uniqueArray].map((v:any)=>{

    if(!v.serialCode && !v.productName && codeAutoCreate ){
      return {...v, serialCode: registerProduct.serialCode, productName: registerProduct.productName}
    }
    else if(!v.serialCode && codeAutoCreate){
      return {...v, serialCode: registerProduct.serialCode}
    }
    else if(!v.productName && codeAutoCreate){
      return {...v, productName: registerProduct.productName}
    }
    else{
      return {...v}
    }
  }))

  const productProcessReg = async () => {
    if(bomProcessReceiveData.some((v:any)=>(!v.serialCode))){
      setDataModal({menu:'warn', message: '비어있는 BOM이 있습니다.'
                     // '입력되지 않은 시리얼CODE가 있습니다.'
                   })
    }
    else if(bomProcessReceiveData.some((v:any)=>(!v.productName))){
      setDataModal({menu:'warn', message:'입력되지 않은 품명이 있습니다..'})
    }
    else if(bomProcessReceiveData.some((v:any)=>(!v.type))){
      setDataModal({menu:'warn', message:'선택되지 않은 품목 종류가 있습니다.'})
    }
    else if(bomProcessReceiveData.some((v:any)=>(!v.unit))){
      setDataModal({menu:'warn', message:'선택되지 않은 단위값이 있습니다.'})
    }
      // else if(bomProcessReceiveData.some((v:any)=>(!v.processCode))){
      //   setDataModal({menu:'warn', message:'선택되지 않은 생산공정이 있습니다.'})
      // }
      // else{
      //   setDataModal({menu:'success', message:'정상적으로 등록 되었습니다.'})
    // }
    else{
      listLoadingCircle()
      try {

        const response = await client.post('/product/boms/register',

                                           {
                                             cavity: '1',
                                             companyCode: selectedCompany.companyCode,
                                             processCode: registerProduct?.processCode,
                                             processName: registerProduct?.processName,
                                             productCode: registerProduct?.productCode,
                                             productName: registerProduct?.productName,
                                             serialCode: registerProduct?.serialCode,
                                             type: registerProduct?.type,
                                             customer: registerProduct?.customer,
                                             // customerCode: registerProduct?.customer[0].customerCode,
                                             // customerModelCode: registerProduct?.customer[0].customerModelCode, indexNo:'0',
                                             levelPosition:'0', depthLevel:0, bomInfo: [...rowList]}


                                           // [{
                                           //   cavity: '1',
                                           //   companyCode: selectedCompany.companyCode,
                                           //   processCode: registerProduct?.processCode,
                                           //   processName: registerProduct?.processName,
                                           //   productCode: registerProduct?.productCode,
                                           //   productName: registerProduct?.productName,
                                           //   serialCode: registerProduct?.serialCode,
                                           //   type: registerProduct?.type,
                                           //   customer: registerProduct?.customer,
                                           //   // customerCode: registerProduct?.customer[0].customerCode,
                                           //   // customerModelCode: registerProduct?.customer[0].customerModelCode, indexNo:'0',
                                           //   levelPosition:'0', depthLevel:0, bomInfo: zeroDepthBomCheck?.map((v:any)=>{
                                           //     const deleteBomInfo = {...v, productSubCode: dataValid(v.productSubCode,'')}
                                           //     delete deleteBomInfo.bomInfo
                                           //     return deleteBomInfo
                                           //   }).filter((el:any)=>(el.depthLevel === 1))},...deleteBomInfoUniqueArray]

                                           // .map((v:any)=>{
                                           //   if(!v.serialCode && !v.productName && codeAutoCreate ){
                                           //     return {...v, serialCode: registerProduct.serialCode, productName: registerProduct.productName}
                                           //   }
                                           //   else if(!v.serialCode && codeAutoCreate){
                                           //     return {...v, serialCode: registerProduct.serialCode}
                                           //   }
                                           //   else if(!v.productName && codeAutoCreate){
                                           //     return {...v, productName: registerProduct.productName}
                                           //   }
                                           //   else{
                                           //     return {...v}
                                           //   }
                                           // })
          ,{headers:{Authorization}})
        const data = response.data
        if(data.status === 200){
          setTimeout(()=>{
            loadingRemove()
            setDataModal({menu:'finish', message:'BOM정보가 변경 되었습니다. \n 목록으로 이동 하시겠습니까?'})
          },500)
        }
        else{
          loadingRemove()
          setDataModal({menu:'bomLoop', message:data.message})
        }
      }

      catch ( e:any ) {
        loadingRemove()
        setDataModal({menu:'error', message:e.message})
        console.log(e)
      }
    }
  }

  const updateBomValue = (targetCode: string, serialValue: any) => {
    setRowList((prevRowData) => {
      const updateItem = (item: any) => {
        if (item.levelPosition === targetCode) {
          // 선택한 객체의 serial 속성을 추가 또는 업데이트
          return { ...item, ...serialValue}
        } else if (item.bomInfo) {
          // bomInfo 배열이 존재하면 재귀적으로 내부 항목들도 업데이트
          return { ...item, bomInfo: item.bomInfo.map(updateItem) }
        }
        return item
      }

      return prevRowData.map( updateItem )
    })
  }


  // 불러온 bomInfo 목록의 levelPosition값을 구한 뒤 가져온 bom값을 추가하는 함수 - 해당 object의 하위 bomInfo 포함.
  const updateLevelPositions = (serialValues:any, parentLevelPosition:string) => {
    let highestChildLevel = 0

    return serialValues.map((serialValue:any, index:number) => {
      const newChildLevel = highestChildLevel + index + 1
      const newLevelPosition = `${parentLevelPosition}-${newChildLevel}`
      const newDepthLevel = newLevelPosition.split('-').length;
      const updatedSerialValue = { ...serialValue, levelPosition: newLevelPosition, depthLevel: newDepthLevel}

      if (updatedSerialValue.bomInfo && updatedSerialValue.bomInfo.length > 0) {
        updatedSerialValue.bomInfo = updateLevelPositions(updatedSerialValue.bomInfo, newLevelPosition)
      }

      return updatedSerialValue
    })
  }

  const updateAddBom = (targetCode:string, serialValues:any, bomData:any) => {
    setRowList((prevRowData) => {
      const updateItem = (item:any) => {
        if (item.levelPosition === targetCode) {
          let highestChildLevel = 0
          if (item.bomInfo) {
            item.bomInfo.forEach((bom:any) => {
              const childLevel = parseInt(bom.levelPosition.split('-').pop())
              if (childLevel > highestChildLevel) {
                highestChildLevel = childLevel
              }
            })
          }

          const updatedBomInfo = updateLevelPositions(serialValues, targetCode)

          return {
            ...bomData, usage:'1',
            levelPosition: item.levelPosition,
            depthLevel: item.levelPosition.split('-').length,
            bomInfo:
            // item.bomInfo ? [...item.bomInfo, ...updatedBomInfo] :
            updatedBomInfo
          }
        } else if (item.bomInfo) {
          return { ...item, bomInfo: item.bomInfo.map(updateItem) }
        }
        return item
      }

      return prevRowData.map(updateItem)
    })
  }

  // rowList에서 선택된 code와 일치한 object를 가져오는 함수
  // const findObjectByCode = (codeToFind: string, data: any[]) => {
  //   let foundObject = null
  //
  //   const findItem = (item: any) => {
  //     if (item.levelPosition === codeToFind) {
  //       foundObject = item
  //       return
  //     } else if (item.bomInfo) {
  //       item.bomInfo.forEach(findItem);
  //     }
  //   }
  //
  //   data.forEach(findItem);
  //
  //   return foundObject;
  // }

  // const deleteItemByCode = (codeToDelete: string) => {
  //   setRowList((prevRowData) => {
  //     const deleteItem = (item: any, parentCode: string = "") => {
  //       if (item.levelPosition === codeToDelete) {
  //         // 해당 객체를 삭제하고 null 반환
  //         return null;
  //       } else if (item.bomInfo) {
  //         // inner 배열이 존재하면 재귀적으로 내부 항목들도 검사
  //         const updatedInner = item.bomInfo.map((innerItem: any, i: number) => {
  //           const newCode = parentCode ? `${parentCode}-${i + 1}` : `${i + 1}`;
  //           return deleteItem(innerItem, newCode);
  //         }).filter(Boolean);
  //
  //         return { ...item, bomInfo: updatedInner.length > 0 ? updatedInner : [] };
  //       }
  //       return item;
  //     };
  //
  //     //@ts-ignore
  //     return prevRowData.map( deleteItem ).filter( Boolean );
  //   });
  // };

  const removeAndAdjust = (
    obj: any,
    removedLevels: string[],
    currentDepth: number = 1
  ): any | null => {
    if (removedLevels.includes(obj.levelPosition)) {
      return null;
    }

    let updatedBomInfo: any[] | undefined = undefined;

    if (obj.bomInfo) {
      updatedBomInfo = obj.bomInfo
                           .map((bom: any) => removeAndAdjust(bom, removedLevels, currentDepth + 1))
                           .filter((bom: any) => bom !== null)
                           .map((bom: any, index: number) => ({ ...bom, levelPosition: `${obj.levelPosition}-${index + 1}` }));
    }

    return { ...obj, bomInfo: updatedBomInfo };
  };

  const adjustLevelPosition = (
    obj: any,
    removedLevels: string[]
  ): any | null => {
    if (removedLevels.includes(obj.levelPosition)) {
      return null;
    }

    let updatedBomInfo: any[] | undefined = undefined;

    if (obj.bomInfo) {
      updatedBomInfo = obj.bomInfo
                           .map((bom: any) => adjustLevelPosition(bom, removedLevels))
                           .filter((bom: any) => bom !== null)
                           .map((bom: any, index: number) => ({
        ...bom,
        levelPosition: `${obj.levelPosition}-${index + 1}`,
      }));
    }

    return { ...obj, bomInfo: updatedBomInfo};
  };

  const handleRemove = () => {
    const updatedData = rowList
    .map((obj: any) => adjustLevelPosition(obj, checkLevelPosition))
     .filter((obj: any) => obj !== null);

    let currentIndex = 1;
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i] === null) continue; // 제거된 객체는 건너뜀
      updatedData[i].levelPosition = String(currentIndex); // levelPosition 업데이트
      currentIndex++;
    }

    setRowList(updatedData);
    setCheckLevelPosition([]);
    setDeleteDepthCount(prev => prev+1)
    setRowCount(updatedData?.length)
  };


  const emptyBomExcept = () => {
    const updatedData = rowList
    .map((obj: any) => adjustLevelPosition(obj, objectsWithoutProductSubCode))
     .filter((obj: any) => obj !== null);

    let currentIndex = 1;
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i] === null) continue; // 제거된 객체는 건너뜀
      updatedData[i].levelPosition = String(currentIndex); // levelPosition 업데이트
      currentIndex++;
    }

    setRowList(updatedData);
    setCheckLevelPosition([])
    setDeleteDepthCount(prev => prev+1)
    setRowCount(updatedData?.length)
  };


  // levelPosition 값을 update하는 기능
  const handleLevelPositionChange = () => {
    const updatedData = rowList.map((obj: any) =>
                                      adjustLevelPosition(obj, checkLevelPosition)
    );
    setRowList(updatedData);
  };

  // 해당 state로 depth 만큼 update가 반복적으로 이루어 지도록 함.
  const [deleteDepthCount, setDeleteDepthCount] = useState<number>(1)
  const [selectedBom, setSelectedBom] = useState<any>({})

  const scrollRef = useRef<any>(null)

  //rowList의 최대 depthLevel값을 찾는 기능
  const getMaxDepthLevel = (item:any) => {
    let maxDepth = item.depthLevel || 0; // 현재 객체의 depthLevel, 기본값은 0
    if (item.bomInfo) {
      // bomInfo 배열이 있다면
      for (const bomItem of item.bomInfo) {
        // 각 bomItem에 대해 재귀적으로 getMaxDepthLevel 호출하여 최댓값 찾기
        const bomMaxDepth = getMaxDepthLevel(bomItem);
        maxDepth = Math.max(maxDepth, bomMaxDepth); // 최댓값 업데이트
      }
    }
    return maxDepth; // 최종적으로 선택된 최댓값 반환
  };

// rowList 배열에서 bomInfo 내부의 모든 depthLevel을 고려하여 최댓값 찾기
  const maxDepthLevel = Math.max(
    ...rowList.map((item) => getMaxDepthLevel(item))
  );
  // console.log(deleteDepthCount + '/' + maxDepthLevel)
  useEffect(()=>{
    if(deleteDepthCount < maxDepthLevel){
      setDeleteDepthCount(prev => prev+1)
      handleLevelPositionChange()
    }
  },[deleteDepthCount, maxDepthLevel])

  const bomTreeListRes = async () => {
    try {
      const response = await client.get(`/product/bom/tree/list/${registerProduct?.productCode}`,{headers:{Authorization}})
      const data = response.data.data
      if(response.data.status === 200){
        setRowList(data)
        setRowCount(data.length)
        setFinishedState(true)
      }
      else{
        setDataModal({menu:'warn', message:response.data.message})
      }
    }
    catch ( e:any ) {
      console.log(e)
      setDataModal({menu:'warn', message:e.message})
    }
  }

  useEffect(()=>{
    if(bomProduct.bomAuto){
      bomTreeListRes().then()
    }
  },[])



  //BOM 하위 목록을 가져오기 위해 호출하는 api
  const addBomDataRes = async (subData:any) => {
    try {
      const response = await client.get(`/product/bom/tree/list/${subData.productSubCode}`,{headers:{Authorization}})
      const data = response.data.data
      if(response.data.status === 200){
        // console.log(JSON.stringify(data))
        // serialCode: (e.serialCode || e.rawMaterialSerialCode || e.subMaterialSerialCode),
        // productName: (e.productName || e.rawMaterialName || e.subMaterialName),
        // productSubCode: e.productCode || e.rawMaterialCode || e.subMaterialCode,
        // type: e.type || e.rawMaterialType || e.subMaterialType,
        // unit: e.unit || e.rawMaterialUnit || e.subMaterialUnit,
        // processName: e.processName,
        // processCode: e.processCode,
        // importProduct: true, importType: (e.type === '원자재'? 'rawMaterial' : '부자재'? 'subMaterial' : 'product')

        updateAddBom(selectedLevel, data, subData)
      }
      else{
        setDataModal({menu:'warn', message:response.data.message})
      }
    }
    catch ( e:any ) {
      console.log(e)
      setDataModal({menu:'error', message:e.message})
    }
  }

  // 선택한 levelPosition 포함 하위 level까지 선택되도록 함
  const handleItemClick = (item:any) => {
    const positions = getAllLevelPositions(item)
    setCheckLevelPosition([...checkLevelPosition, ...positions])
  }

  // 선택한 levelPosition 포함 하위 level 전부 해제되도록 함
  const handleRemoveItemClick = (item:any) => {
    const positionsToRemove = getAllLevelPositions(item)
    const updatedPositions = checkLevelPosition.filter(position => !positionsToRemove.includes(position))
    setCheckLevelPosition(updatedPositions)
  }


  const getAllLevelPositions = (item:any) => {
    let positions:any = []
    positions.push(item.levelPosition)

    if (item.bomInfo && item.bomInfo.length > 0) {
      item.bomInfo.forEach((subItem:any) => {
        if (subItem.levelPosition.startsWith(item.levelPosition + "-")) {
          const subPositions = getAllLevelPositions(subItem)
          positions = positions.concat(subPositions)
        }
      })
    }
    return positions
  }


  // 내가 선택한 levelPosition 값의 상위 productSubCode를 가져오기 위한 함수
  const getProductSubCodes = (data: any[], levelPosition: string): string[] => {
    let result: string[] = []
    for (let item of data) {
      if (item.levelPosition === levelPosition) {
        // result.push(item.productSubCode ?? '');
        result.push('')
        return result
      } else if (item.bomInfo) {
        const found = getProductSubCodes(item.bomInfo, levelPosition)
        if (found.length > 0) {
          result.push(item.productSubCode ?? '')
          result = result.concat(found)
          return result?.filter(subCode => (subCode !== ''))
        }
      }
    }
    return result
  }
  // BOM등록시 상위 포함된 productSubCode가 선택되지 않도록 하기 위해 받는 productSubCode 배열 값
  // bomInfo라는 key로 값을 찾기 때문에 rowList 최상위의 경우 bomInfo로 씌워져 있지 않아 가져올 수 없으므로, 한단계 다 씌워 기능이 작동하도록 함.
  const productSubCodes = getProductSubCodes([{bomInfo: rowList}], selectedLevel)

  // 내가 선택한 levelPosition 값의 같은 depth의 bomInfo 배열에 있는 productSubCode를 가져오기 위한 함수
  const getParentObjectByLevelPosition = (data: any[], targetLevelPosition: string): any | null => {
    let parentObject: any | null = null

    const findParentObject = (data: any[], targetLevelPosition: string): void => {
      data.forEach((item: any) => {
        if (item.bomInfo && item.bomInfo.length > 0) {
          item.bomInfo.forEach((child: any) => {
            if (child.levelPosition === targetLevelPosition) {
              parentObject = item
            }
          })
          if (!parentObject) {
            findParentObject(item.bomInfo, targetLevelPosition)
          }
        }
      })
    }

    findParentObject(data, targetLevelPosition)
    // 내가 선택한 levelPosition의 상위 object를 가져온 뒤 해당 object의 bomInfo의 productSubCode를 나열하여 return
    return parentObject?.bomInfo?.map((el:any)=>(el.productSubCode))?.filter((subCode:any) => subCode !== '')
  }

  // bomInfo라는 key로 값을 찾기 때문에 rowList 최상위의 경우 bomInfo로 씌워져 있지 않아 가져올 수 없으므로, 한단계 다 씌워 기능이 작동하도록 함.
  const parentObjectBomSubCodes: any | null = getParentObjectByLevelPosition( [{bomInfo: rowList}], selectedLevel)

  //값이 비어있는 모든 object를 선택하는 기능
  const findObjectsWithoutProductSubCode = (array:any) => {
    const result:any = []

    const checkObjects = (items:any) => {
      items.forEach((item:any) => {
        if (!item.productSubCode) {
          result.push(item.levelPosition)
        }
        if (item.bomInfo && item.bomInfo.length > 0) {
          checkObjects(item.bomInfo)
        }
      })
    }

    checkObjects(array)
    return result
  }

  const objectsWithoutProductSubCode = findObjectsWithoutProductSubCode(rowList)

//   const findLevelPositionsByProductSubCode = (array:any[], targetSubCode:string) => {
//     const result:any[] = [];
//
//     const checkObjects = (items:any) => {
//       items.forEach((item:any) => {
//         if (item.productSubCode === targetSubCode) {
//           result.push(item.levelPosition);
//         }
//         if (item.bomInfo && item.bomInfo.length > 0) {
//           checkObjects(item.bomInfo);
//         }
//       });
//     };
//
//     checkObjects(array);
//     return result;
//   };
//
// // 특정 productSubCode를 여기에 입력합니다.
//   const levelPositions = findLevelPositionsByProductSubCode(rowList, '7TJ05GF61P_product_26');
//
//   console.log(levelPositions);


  const findLevelPositionsByProductSubCode = (array: any[], targetProductSubCode: string): string[] => {
    const result: string[] = [];

    const checkObjects = (items: any) => {
      items.forEach((item: any) => {
        if (item.productSubCode === targetProductSubCode) {
          result.push(item.levelPosition)
        }
        if (item.bomInfo && item.bomInfo.length > 0) {
          checkObjects(item.bomInfo)
        }
      })
    }

    checkObjects(array)
    return result
  }

// Usage example
//   const targetProductSubCode = '7TJ05GF61P_rawMaterial_26';
//   const levelPositions = findLevelPositionsByProductSubCode(rowList, targetProductSubCode);

  const customerCount = ( customer: any ) => customer.filter( ( v: any , i: number , arr: any ) => arr.findIndex( ( el: any ) => ( el.customerCode === v.customerCode ) ) === i )
  // BOM 리스트
  const lowerBomListOpen = (bomList:any[], parentCode: string = "") => {
    return (
      <React.Fragment>
        {bomList?.map((data:any,i:any)=>(
          <React.Fragment>
            <GridTableSelect style={{backgroundColor:
                `rgba(0,88,255,${0.06 * data.depthLevel})` }}
                             onMouseDown={()=>setSelectedLevel(data.levelPosition)}
                             onDoubleClick={()=>{
                               // openRow(data)
                             }} className={'grid-row-list'}>
              <label className={'checkbox'}>
                {
                  <ListInnerInput type={'check'} name={'id'} inputData={data.levelPosition}
                                  onChange={(e) => {
                                    if(e.target.checked){
                                      handleItemClick(data)
                                      // setCheckLevelPosition([...checkLevelPosition, data.levelPosition, ...data.bomInfo?.map((el:any)=>(el.levelPosition)),
                                      //                       // data.bomInfo?.map((el:any)=>( el.bomInfo?.map((sub:any)=>(sub.levelPosition))))
                                      //                       ])
                                    }
                                    else{
                                      handleRemoveItemClick(data)
                                      // setCheckLevelPosition(checkLevelPosition.filter((el:any)=>(el !== data.levelPosition)))
                                    }
                                  }}
                                  checked={checkLevelPosition.some((v)=>(v === data.levelPosition))}/>
                }

              </label>
              <div style={{marginLeft: `${(data.depthLevel <= 2)? '0' :  (data.depthLevel - 1) * 8}px` , display:'flex'}}>
                <span style={{marginRight:'4px', fontWeight:800, fontSize:'16px'}}>{(data.depthLevel !== 1? '┗ ' : '')}</span>
                <div style={{width:'60px'}}>
                  <AutoTextSize maxFontSizePx={14} minFontSizePx={10}>
                    {data.levelPosition}
                  </AutoTextSize>
                </div>
              </div>

              <TESTInnerInput
                type={'search'} clickInput={() => setDataModal({menu: 'integrated', view: data?.bomInfo.length === 0})}
                clickButton={() => setDataModal({menu: 'integrated', view: data?.bomInfo.length === 0})} readonly={true}
                onChange={(e)=>updateBomValue(data.levelPosition, {serialCode: e.target.value})}
                placeholder={codeAutoCreate? '자동 생성' : '품목을 선택해 주세요.'} border={!data?.productSubCode? '1px solid #f0142f' : ''}
                name={'serialCode'} inputData={(!data?.serialCode && codeAutoCreate)? '' : dataValid(data?.serialCode,'')}
                // disabled={true}
                width={'120px'}
                tempState={data.importProduct}
              />
              <TESTInnerInput
                type={'search'} clickInput={() => setDataModal({menu: 'integrated', view: data?.bomInfo.length === 0})}
                clickButton={() => setDataModal({menu: 'integrated', view: data?.bomInfo.length === 0})} readonly={true}
                placeholder={codeAutoCreate? '자동 생성' : '품목을 선택해 주세요.'} border={!data?.productSubCode? '1px solid #f0142f' : ''}
                onChange={(e)=>updateBomValue(data.levelPosition, {productName: e.target.value})}
                name={'serialCode'} inputData={(!data?.productName && codeAutoCreate)? '' : dataValid(data?.productName,'')}
                // disabled={true}
                width={'120px'}
                tempState={data.importProduct}
              />

              {validationValue(data?.type)}
              {validationValue(data?.unit)}
              {validationValue(data?.processName)}

              {/*{data.importProduct?*/}
              {/*  validationValue(data?.type)*/}
              {/*  :*/}
              {/*  <ListInnerInput type={'drop'} name={'type'} dropMenuList={ ['완제품' , '반제품' , '재공품'] }*/}
              {/*                  selectDrop={(v)=>updateBomValue(data.levelPosition, {type: v})}*/}
              {/*                  border={!data.type? '1px solid red' : '1px solid #0058ff'} inputDataAutoUpdate={true}*/}
              {/*                  inputData={dataValid(data?.type,'')}/>*/}
              {/*}*/}
              {/*{data.importProduct?*/}
              {/*  validationValue(data?.unit)*/}
              {/*  :*/}
              {/*  <ListInnerInput type={'drop'} name={'unit'} inputDataAutoUpdate={true}*/}
              {/*                  border={!data.unit? '1px solid red' : '1px solid #0058ff'}*/}
              {/*                  selectDrop={(v)=>updateBomValue(data.levelPosition, {unit: v})}*/}
              {/*                  inputData={dataValid(data?.unit,'')}*/}
              {/*                  dropMenuList={['ea' , 'g' , 'kg', 'ton']}/>*/}
              {/*}*/}
              {/*{data.importProduct?*/}
              {/*  validationValue(data?.processName)*/}
              {/*  :*/}
              {/*  <ListInnerInput type={'objectDrop'} name={'processName'} inputDataAutoUpdate={true}*/}
              {/*                  border={!data.processName? '1px solid red' : '1px solid #0058ff'}*/}
              {/*                  dropKey={'processName'} inputData={dataValid(data?.processName,'')}*/}
              {/*                  selectDrop={(v)=>updateBomValue(data.levelPosition, {processName: v.processName, processCode: v.processCode})}*/}
              {/*                  dropMenuList={processList}/>*/}
              {/*}*/}


              {
                (data?.type === '원자재' || data?.type === '부자재')?
                  <div>{'-'}</div>
                  :
                  <div style={{display:'flex'}}>
                    {
                      (data?.open && data?.bomInfo?.length !== 0) && <MainButtons name={'추가'} buttonType={'cta'}
                                                                                  clickEvent={()=> {
                                                                                    if(!data?.serialCode){
                                                                                      setDataModal({menu:'warn', message:'BOM을 먼저 선택해 주세요.'})
                                                                                    }
                                                                                    else{
                                                                                      addDataDynamically( data?.levelPosition )
                                                                                    }
                                                                                  }}
                                                                                  width={'54px'} margin={'0'}/>}
                    <MainButtons name={data?.bomInfo?.length === 0? 'BOM 등록' :  data.open? '닫기' : 'BOM 열기'}
                                 disabled={(data?.type === '원자재' || data?.type === '부자재')}
                                 buttonType={'cta'} withIcon={data?.bomInfo?.length !== 0} iconPositionBack={true} icon={data?.open? bottom : top}
                                 margin={'0'} width={(data.open && data?.bomInfo?.length !== 0)? '54px' : '108px'} clickEvent={()=> {
                      if(data?.bomInfo?.length === 0){
                        if(!data?.serialCode){
                          setDataModal({menu:'warn', message:'BOM을 먼저 선택해 주세요.'})
                        }
                        else{
                          addDataDynamically( data?.levelPosition )
                        }
                      }
                      else{
                        openRow(data)
                      }
                    }}/>

                  </div>
              }


              <ListInnerInput type={'withButtonUnit'} width={'80px'} name={'usage'} inputData={decimalNumberValid(data?.usage)}
                              limit={10} inputDisabled={!data?.unit} disabled={!data?.serialCode}
                              buttonText={'소요량계산'} buttonWidth={'62px'} buttonFontSize={'12px'}
                              clickButton={()=>{
                                setSelectedBom(data)
                                setDataModal({menu:'require'})
                              }}
                              onChange={(e)=>{
                                updateBomValue(data.levelPosition,{usage: data.unit === 'ea'? e.target.value.replace(/[^0-9]/g, '').trim() : e.target.value.includes('.')? formatNumber(e.target.value).replace(',','') : e.target.value.replace(/[^0-9]/g, '').trim()})
                              }}
                              unit={dataValid(data.unit , 'ea')}/>
              <MainButtons name={data.requirementFormula === '2' ? '적용됨' : '캐비티'} width={'48px'}
                           clickEvent={()=>{
                             if(data.requirementFormula === '1'){
                               updateBomValue(data.levelPosition,{requirementFormula:'2'})
                             }
                             else{
                               updateBomValue(data.levelPosition,{requirementFormula:'1'})
                             }
                           }}
                           buttonType={'cta'} margin={'0'}/>
            </GridTableSelect>
            {data?.open && lowerBomListOpen(data?.bomInfo, parentCode + (i + 1) + "-")}
          </React.Fragment>
        ))}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>

      {dataModal.menu === 'success' ?
        <ToastPopup text={( dataModal.message )} state={'success'}
                    closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'bomLoop' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>setDataModal(initModal)}/>}

      {dataModal.menu === 'require' &&
          <RequiredAmountCalcModal selectBom={selectedBom}
                                   productUnit={registerProduct?.unit} productSerial={registerProduct?.serialCode}
                                   closeEvent={()=>setDataModal(initModal)}
                                   receivedEvent={(e)=> {
                                     updateBomValue(selectedBom.levelPosition, {usage: e} )
                                     setDataModal( initModal )
                                   }}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message} buttonTitle={{confirm:'예', cancel:'아니오'}}
                              clickEvent={()=> {
                                setDataModal(initModal)
                                setRegisterProduct( {
                                                      customer: [] ,
                                                      type: '완제품' ,
                                                      unit: 'ea' ,
                                                      codeDoubleCheck: false, serialCode:''
                                                    } )
                              }}
                              cancelEvent={()=>setDataModal(initModal)} />
      }

      {dataModal.menu === 'finish' &&
          <SelectConfirmModal statusText={dataModal.message} buttonTitle={{confirm:'예', cancel:'아니오'}}
                              clickEvent={()=> {
                                setDataModal(initModal)
                                window.location.href = authValid()? '/admin/fas/product' : '/fas/product'
                              }}
                              cancelEvent={()=>setDataModal(initModal)} />
      }

      {dataModal.menu === 'delete' &&
          <SelectConfirmModal statusText={dataModal.message} buttonTitle={{confirm:'예', cancel:'아니오'}}
                              clickEvent={()=> {
                                setDataModal(initModal)
                                setDeleteDepthCount(0)
                                handleRemove()
                                setCheckLevelPosition([])
                              }}
                              cancelEvent={()=>setDataModal(initModal)} />
      }

      {dataModal.menu === 'return' &&
          <SelectConfirmModal statusText={dataModal.message} buttonTitle={{confirm:'예', cancel:'아니오'}}
                              clickEvent={()=> {
                                setDataModal(initModal)
                                bomTreeListRes().then()
                              }}
                              cancelEvent={()=>setDataModal(initModal)} />
      }

      {dataModal.menu === 'customer' &&
          <AccountSelectModal
              receivedData={(e) => {
                setRegisterProduct((prev:any)=>({...prev, customer: e}))
                // updateBomValue(selectedLevel,{customer: e, importCustomer: true})
                // setTempValueTypeState('search')
              }}
              closeModal={() => setDataModal(initModal)}
              initModelData={registerProduct?.customer}
              initCustomerData={registerProduct?.customer}


            // 아래의 코드는 BOM 등록 시 해당 BOM에 거래처 값을 넣기 위해 존재하는 기능(현재 기획에서 사라짐)
            // //@ts-ignore
            // initModelData={dataValid(findObjectByCode(selectedLevel, rowList)?.customer, [])}
            // //@ts-ignore
            // initCustomerData={dataValid(findObjectByCode(selectedLevel, rowList)?.customer, [])}
          />
      }

      {dataModal.menu === 'integrated' &&
          <ProcessProductFilterModal menuArrayType={dataModal?.view? '' : 'product'}
                                     mainProductCode={ registerProduct?.productCode }
                                     filterArray={ !finishedState? [] : registerProduct?.productCode ? [registerProduct?.productCode, ...productSubCodes, ...parentObjectBomSubCodes] : []}
                                     receiveData={(e)=>{
                                       finishedState?
                                         addBomDataRes({
                                                         serialCode: (e.serialCode || e.rawMaterialSerialCode || e.subMaterialSerialCode),
                                                         productName: (e.productName || e.rawMaterialName || e.subMaterialName),
                                                         productSubCode: e.productCode || e.rawMaterialCode || e.subMaterialCode,
                                                         type: e.type || e.rawMaterialType || e.subMaterialType,
                                                         unit: e.unit || e.rawMaterialUnit || e.subMaterialUnit,
                                                         processName: e.processName,
                                                         processCode: e.processCode,
                                                         requirementFormula: '1',
                                                         importProduct: true, importType: (e.type === '원자재'? 'rawMaterial' : '부자재'? 'subMaterial' : 'product')
                                                       }).then()
                                         // updateBomValue(selectedLevel,{
                                         //   serialCode: (e.serialCode || e.rawMaterialSerialCode || e.subMaterialSerialCode),
                                         //   productName: (e.productName || e.rawMaterialName || e.subMaterialName),
                                         //   productSubCode: e.productCode || e.rawMaterialCode || e.subMaterialCode,
                                         //   type: e.type || e.rawMaterialType || e.subMaterialType,
                                         //   unit: e.unit || e.rawMaterialUnit || e.subMaterialUnit,
                                         //   processName: e.processName,
                                         //   processCode: e.processCode,
                                         //   importProduct: true, importType: (e.type === '원자재'? 'rawMaterial' : '부자재'? 'subMaterial' : 'product')
                                         // })
                                         :
                                         setRegisterProduct((prev:any)=>({...prev, ...e, importProduct: true}))
                                     }}
                                     closeEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={()=>{
                             if(dataModal.status === 200){
                               setRegisterProduct((prev:any)=>({...prev, codeDoubleCheck: true}))
                             }else{
                               setRegisterProduct((prev:any)=>({...prev, serialCode: registerProduct?.serialCode + ` (${duplicateCount})`, codeDoubleCheck: true}))
                             }
                             setDataModal(initModal)
                           }
                           } cancelEvent={()=>setDataModal(initModal)} buttonWidth={'124px'} buttonFontSize={'12px'}/>}


      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 품목 기본정보'} unitCase={'MenuTitle'}/>

            {/*<div style={{display: 'flex'}}>*/}
            {/*    <span*/}
            {/*      style={{*/}
            {/*        margin: '8px 8px 0' ,*/}
            {/*        alignSelf: 'center' ,*/}
            {/*        fontSize: '24px' ,*/}
            {/*        fontWeight: 800*/}
            {/*      }}>{'>'}</span>*/}
            {/*  <span*/}
            {/*    style={{*/}
            {/*      alignItems: 'center' ,*/}
            {/*      alignSelf: 'center' ,*/}
            {/*      fontSize: '18px' ,*/}
            {/*      marginTop: '8px' ,*/}
            {/*      fontWeight: 700 ,*/}
            {/*      color: 'red'*/}
            {/*    }}>{'현재 개발중인 페이지 입니다. 등록되지 않습니다.'}</span>*/}
            {/*</div>*/}
          </div>
        </div>
        <ContentFrame height={'680px'}
                      padding={'30px 30px 20px'}>
          <div>
            {/*해당 품목 등록창*/}
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{display:'flex'}}>
                <MenuTitle title={'품목 기본정보 : BOM 관리'} unitCase={'MenuCompTitle'}/>
                <span style={{marginLeft:'24px', fontSize:'12px', alignSelf:'center'}}>{'* 이미 등록된 품목의 BOM을 변경하시려면, CODE(품번)를 검색해 주세요.  '}</span>
              </div>

              <div style={{display:'flex'}}>
                <MainButtons name={'목록'} width={'80px'} clickEvent={()=>{
                  resetBomProduct()
                  setTimeout(()=>{
                    window.location.href = authValid()? '/admin/fas/product' : '/fas/product'
                  },100)
                }} />
                {!finishedState? <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                                              clickDown={handleLevelPositionChange}
                                              clickEvent={() => {
                                                if( registerProduct?.importProduct ){
                                                  bomTreeListRes().then()
                                                } else{
                                                  registerFinished().then()
                                                }
                                              }} marginDis={true}/>
                  :
                  <MainButtons name={'되돌리기'} width={'80px'} disabled={!finishedState} marginDis={finishedState}
                               clickEvent={() => setDataModal( {
                                                                 menu: 'return' ,
                                                                 message: '해당 품목의 BOM정보를 \n되돌리시겠습니까?'
                                                               } )}/>
                }
              </div>
            </div>
            <GridTableComponent tempCol={'40px repeat(2, 120px) 180px 120px repeat(2, 80px) 96px 108px'} gridGap={8}
                                scrollX_Hide={true} scrollY_Hide={true} scrollWidth={1210} height={300}>
              <div className={'grid-title-unchecked'}>
                {productBasicTitle.map((v)=>(
                  <TableValue title={v} requiredValue={finishedState? [] : ['거래처','모델','CODE(품번)','품명','품목 종류','단위', '생산공정']} viewType={true}/>
                ))}
              </div>
              <GridTableSelect className={'grid-list-unchecked-no_icon'} style={{
                backgroundColor: finishedState? '#f5f6fa' : '',
                border: finishedState? '1px solid #f5f6fa' : '',
                color: finishedState? '#171725' : '',
                fontWeight: finishedState? 400 : ''
              }}>
                <div>{'0'}</div>
                {finishedState?
                  validationValue(dataValid( customerCount( registerProduct.customer )[0]?.customerName ) + ( dataValid( ( customerCount( registerProduct.customer ).length - 1 ) , 0 ) > 0 ? ` 외 ${customerCount( registerProduct.customer ).length - 1}` : '' ))
                  :
                  registerProduct?.importProduct?
                    validationValue(dataValid( customerCount( registerProduct.customer )[0]?.customerName ) + ( dataValid( ( customerCount( registerProduct.customer ).length - 1 ) , 0 ) > 0 ? ` 외 ${customerCount( registerProduct.customer ).length - 1}` : '' ))
                    :
                    <ListInnerInput type={'accountSearch'} name={'customerName'} disabled={registerProduct?.importProduct}
                                    hoverText={customerCount( registerProduct.customer ).map( ( v: any ) => ( v.customerName ) ).join( ',' )}
                                    inputData={dataValid( customerCount( registerProduct.customer )[0]?.customerName ) + ( dataValid( ( customerCount( registerProduct.customer ).length - 1 ) , 0 ) > 0 ? ` 외 ${customerCount( registerProduct.customer ).length - 1}` : '' )}
                                    placeholder={'거래처'}
                                    clickButton={() => {setDataModal( {menu: 'customer'})}}/>
                }

                {
                  finishedState?
                    validationValue(dataValid( registerProduct.customer[0]?.customerModelName ) + (dataValid((registerProduct.customer.length - 1 ) , 0 ) > 0 ? ` 외 ${registerProduct.customer.length - 1}` : '' ))
                    :
                    registerProduct?.importProduct?
                      validationValue(dataValid( registerProduct.customer[0]?.customerModelName ) + (dataValid((registerProduct.customer.length - 1 ) , 0 ) > 0 ? ` 외 ${registerProduct.customer.length - 1}` : '' ))

                      :
                      <ListInnerInput type={'accountSearch'} name={'customerModelName'} disabled={registerProduct?.importProduct}
                                      hoverText={registerProduct.customer.map( ( v: any ) => ( v.customerModelName ) ).join( ',' )}
                                      inputData={dataValid( registerProduct.customer[0]?.customerModelName ) + (dataValid((registerProduct.customer.length - 1 ) , 0 ) > 0 ? ` 외 ${registerProduct.customer.length - 1}` : '' )}
                                      placeholder={'모델'}
                                      clickButton={() => {
                                        setDataModal( {menu: 'customer'} )
                                      }}/>
                }

                {finishedState?
                  autoTextValueValidation({value: registerProduct.serialCode})
                  :
                  <ListInnerInput type={'searchWithButton'} name={'serialCode'}
                                  inputData={registerProduct?.serialCode}
                                  placeholder={'CODE'} buttonText={registerProduct?.codeDoubleCheck? '확인됨' : '중복확인'} buttonWidth={'60px'}
                                  clickSearchIcon={()=>setDataModal({menu:'integrated', view: false})}
                                  onChange={(e)=>onChange(e,true)}
                                  disabled={registerProduct?.codeDoubleCheck || !registerProduct?.serialCode || registerProduct?.importProduct}
                                  clickButton={serialDoubleCheck}/>
                  // <SearchBar name={'serialCode'} value={(registerProduct?.serialCode)}
                  //            placeholder={'CODE'} keyPressDisable={true}
                  //            keyFunction={onChange} minWidth={'80px'} margin={'0'}
                  //            clickEvent={()=>setDataModal({menu:'integrated'})}
                  // />

                  // <ListInnerInput type={'search'} name={'serialCode'}
                  //                 inputData={dataValid(registerProduct?.serialCode)}
                  //                 placeholder={'CODE'} onChange={onChange}
                  //                 clickButton={() => {
                  //                   setDataModal( {menu: 'integrated'} )
                  //                 }}/>
                  // <ListInnerInput type={'text'} name={'serialCode'} onChange={onChange}/>
                }
                {finishedState?
                  autoTextValueValidation({value: registerProduct.productName})
                  :
                  registerProduct?.importProduct?
                    autoTextValueValidation({value: registerProduct.productName})
                    :
                    <ListInnerInput type={'text'} name={'productName'} inputData={(registerProduct?.productName)} placeholder={'품명'}
                                    onChange={onChange} disabled={registerProduct?.importProduct}/>
                }

                {finishedState?
                  validationValue(registerProduct.type)
                  :
                  registerProduct?.importProduct?
                    validationValue(registerProduct?.type)
                    :
                    <ListInnerInput type={'drop'} name={'type'} inputData={registerProduct?.type}
                                    selectDrop={(v)=>setRegisterProduct((prev:any)=>({...prev, type: v}))}
                                    dropMenuList={['완제품' , '반제품' , '재공품']}/>

                }

                {finishedState?
                  validationValue(registerProduct.unit)
                  :
                  registerProduct?.importProduct?
                    validationValue(registerProduct?.unit)
                    :
                    <ListInnerInput type={'drop'} name={'unit'} inputData={registerProduct?.unit}
                                    selectDrop={(v)=>setRegisterProduct((prev:any)=>({...prev, unit: v}))}
                                    dropMenuList={['ea' , 'g' , 'kg', 'ton']}/>
                }

                {
                  finishedState?
                    validationValue(registerProduct.processName)
                    :
                    registerProduct?.importProduct?
                      validationValue(registerProduct?.processName)
                      :
                      <ListInnerInput type={'objectDrop'} name={'processName'} inputData={registerProduct.processName}
                                      selectDrop={(v)=>setRegisterProduct((prev:any)=>({...prev, processName: v.processName, processCode: v.processCode}))}
                                      dropKey={'processName'}
                                      dropMenuList={processList}/>
                }

                <MainButtons name={rowList?.length === 0? 'BOM 등록' : 'BOM 추가'} disabled={(!finishedState || rowList?.length === 0)}
                             clickEvent={()=> {
                               setRowList( ( prev: any ) => ( [...prev , {
                                 companyCode: selectedCompany.companyCode,
                                 levelPosition: String( rowList.length + 1 ) ,
                                 depthLevel: 1 ,
                                 requirementFormula: '1' , usage: '1' , productSubCode: '' ,
                                 processName:'', processCode:'', type:'', unit:'',bomInfo: []
                               }]))
                               setRowCount((prev)=>Number(prev)+1)

                               setTimeout(()=>{
                                 scrollRef.current.scrollTo({behavior: 'smooth',
                                                              top: scrollRef.current?.scrollHeight + 48,})
                               },100)
                             }} buttonType={'cta'} margin={'0'} width={'96px'}/>
              </GridTableSelect>
            </GridTableComponent>
          </div>
          <div style={{marginTop:'16px', transform:'translateY(-200px)', opacity: finishedState? 1: 0.3, pointerEvents: finishedState? 'auto': 'none'}}>

            {/*해당 품목 등록창*/}
            <div style={{display:'flex', justifyContent:'space-between'}}>
              <div style={{display:'flex', alignItems:'center'}}>
                {/*<div onClick={handleChange}>{'TEST'}</div>*/}
                <MenuTitle title={'BOM 등록'} unitCase={'MenuCompTitle'}/>
                {/*<label style={{display:'flex', alignItems:'center', marginLeft:'16px', cursor:'pointer'}}>*/}
                {/*  <CheckBox type={'checkbox'} checked={codeAutoCreate} onChange={(e)=>{*/}
                {/*    if(e.target.checked){*/}
                {/*      setCodeAutoCreate(true)*/}
                {/*    }*/}
                {/*    else{*/}
                {/*      setCodeAutoCreate(false)*/}
                {/*    }*/}
                {/*  }}/>*/}
                {/*  <div style={{color:'#7E84A3', fontSize:'12px'}}>{'CODE, 품명 자동생성'}</div>*/}
                {/*</label>*/}
              </div>
              <div style={{display:'flex'}}>
                {(objectsWithoutProductSubCode?.length > 0) &&
                    <MainButtons name={'빈 BOM 제거'}
                                 clickEvent={()=>{
                                   emptyBomExcept()
                                   setDeleteDepthCount(0)
                                 }
                                 }/>}
                <div style={{display:'flex', alignItems:'center', marginRight:'8px'}}>
                  <div style={{color:'#7E84A3', fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'BOM : '}</div>
                  <ListInnerInput type={'withButtonUnit'} inputData={rowCount === 0? '' : rowCount}
                                  onChange={(e)=>{setRowCount(e.target.value.replace(/[^0-9]/g, '').trim())}}
                                  disabled={rowList?.length !== 0 || !rowCount} inputDisabled={rowList?.length !== 0}
                                  clickButton={()=> {
                                    setRowList( Array( Number( rowCount ) ).fill( {levelPosition:'0'} ).map( ( v: any , i: number ) => ( {...v , levelPosition: String(i+1), depthLevel: 1, requirementFormula:'1', usage:'1', bomInfo:[]
                                    })))
                                    setDeleteDepthCount(1)
                                  }}
                                  buttonText={'확인'} width={'100px'}
                                  limit={2} unit={!rowCount? '' : '개'}
                                  buttonWidth={'50px'} placeholder={'공정 수 입력'}/>
                </div>
                <MainButtons name={'저장'} width={'80px'} disabled={rowList.length === 0}
                             buttonType={'cta'} clickEvent={productProcessReg}/>
                <MainButtons name={'삭제'} width={'80px'} disabled={checkLevelPosition.length === 0}
                             clickEvent={()=>setDataModal({menu:'delete', message:'선택한 행을 제거 하시겠습니까?'})}
                             marginDis={true} />
              </div>
            </div>

            <GridTableComponent tempCol={'40px 120px repeat(2, 180px) repeat(3, 80px) 128px 168px 50px'}
                                height={418} gridGap={8} marginCus={8} ref={scrollRef}
                                scrollX_Hide={true} scrollWidth={1200}>
              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  {/*<input type="checkbox"*/}
                  {/*       onChange={()=>''}*/}
                  {/*       checked={true}/>*/}
                </label>
                {productTypeTitle.map((v)=>(
                  <TableValue title={v} requiredValue={needType} viewType={true}/>
                ))}
              </div>
              {rowList?.length === 0?
                <NoData height={360}>{'* 공정 수를 입력해 주세요.'}</NoData>
                :
                lowerBomListOpen(rowList)
              }
            </GridTableComponent>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default ProductProcessWaveContainer;
