import React , {useEffect , useRef} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import Draggable from "react-draggable";
import RegisterInput from "../input/RegisterInput";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {
	dataAccessLoading ,
	dataValid ,
	initModal , isTouchDevice ,
	loadingRemove ,
	modalListLoading
} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import ConfirmPopup from "./ConfirmPopup";
import arrow from "../../asset/image/chevron-left.svg";
import {useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ToastPopup from "./ToastPopup";

interface Props {
	type:string
	fileName?:any
	cancelEvent?: () => void
	companyCode: string
	SDMCode: string|null
	onFolderCreated?: (newFolder: any) => void
}

const FilePopup:React.FunctionComponent<Props> = ({type, companyCode, SDMCode,
																										fileName, cancelEvent,onFolderCreated}) => {

	const location = useLocation()
	const authValid = location.pathname.includes('/admin')
	const modalRef = useRef(null);
	const [folderName, setFolderName] = React.useState<string>('')
	const [dataModal, setDataModal] = React.useState<Modal_View_Type>(initModal)
	const [folderState, setFolderState] = React.useState<boolean>(false)
	const [openMenu, setOpenMenu] = React.useState(false)
	const [selectSDMCode, setSelectSDMCode] = React.useState<{SDMTopCode: string|null, name: string}>({SDMTopCode: null, name:'표준문서 관리'})
	const [,setAddArray] = React.useState<boolean>(false)
	const [totalDocument, setTotalDocument] = React.useState<any[]>([])
	const [hoverDocument, setHoverDocument] = React.useState<string|null>(null)
	const {t}:any = useTranslation()

	const dropMenuRef = React.useRef<any>(null)

	useEffect(()=>{
							const handleClickOutside = (e:any) => {
								if (dropMenuRef.current && !dropMenuRef.current.contains(e.target)) {
									setOpenMenu(false)
								}
							}
							document.addEventListener("mousedown", handleClickOutside);
							return () => {
								document.removeEventListener("mousedown", handleClickOutside);
							};
						}
		,[dropMenuRef])


	const pressEnter = (e:any) => {
		if(e.key === 'Enter'){
			if(folderName === ''){
				setFolderState(true)
			}else{
				documentFolderRegister().then()
			}
		}
	}

	const documentFolderListRes = async (folderSDMCode: string|null, selectItem:any|null) => {
		try {
		modalListLoading()
			const response = await client.post('/SDM/list',
																				 {companyCode: companyCode, SDMCode: folderSDMCode},
																				 {headers:{Authorization}})
			const data = response.data
			if(folderSDMCode === null){
				setTotalDocument(([{name:'표준문서 관리', SDMCode:null, SDMTopCode:null, lowerFolder: data.data.row.filter((item:any) => item.type === '폴더') }]))
			}else{
				if(Object.keys(selectItem).includes('lowerFolder')){
					delete selectItem.lowerFolder
				}else{
					Object.assign(selectItem, {lowerFolder: data.data.row.filter((item:any) => item.type === '폴더')})
				}
			}
			//사실상 쓰이진 않는데, 상태변화가 변경되면서, object 목록이 새로 렌더링 되는 효과가 있음.
			setAddArray(false)
			loadingRemove()
		}catch ( e ) {
			console.log(e)
			loadingRemove()
		}
	}

	React.useEffect(()=>{
		documentFolderListRes(null,null).then()
	},[])

	const documentFolderRegister = async () => {
		try {
			dataAccessLoading();
			const response = await client.post('/SDM/folder/register', {
				SDMTopCode: SDMCode,
				companyCode: companyCode,
				fileCode: null,
				name: folderName,
				type: '폴더'
			}, {headers: {Authorization}});
			if (response.data.status === 200) {
				loadingRemove(500);
				setDataModal({menu: 'success', message: t('폴더를 생성하였습니다.')});

				if (onFolderCreated) {
					onFolderCreated(response.data.data); // 폴더 생성 후 콜백 호출
				}

				setTimeout(() => {
					setDataModal(initModal); // 모달 상태 초기화
					cancelEvent && cancelEvent(); // 모달 닫기
				}, 1000);
			} else {
				setDataModal({menu: 'confirm', message: response.data.message});
			}
		} catch (e) {
			console.log(e);
		}
	};
	const fileMoveRes = async () => {
		try {
			dataAccessLoading()
			const response = await client.get(`/SDM/moveFile/${dataValid(selectSDMCode.SDMTopCode, 'topLevel')}/${SDMCode}`,
																				{headers:{Authorization}})
			if(response.data.status === 200){
				setTimeout(()=>{
					setDataModal({menu:'confirm', message:t('선택한 파일이') + `${selectSDMCode.name}`+ t('폴더로 이동 했습니다.')})
					loadingRemove()
				},500)
			}else{
				loadingRemove()
				setDataModal({menu:'confirm', message: response.data.message})
			}

		}catch ( e ){
			loadingRemove()
			console.log(e)
		}
	}
	const lowerDocumentOpen = (lowerFolder:any[], grade: number) => {
		return (
			<React.Fragment>
				{lowerFolder?.map((item:any)=> (
					<div style={{boxSizing:'border-box'}}>
						<div style={{backgroundColor:`${item.SDMCode === hoverDocument? 'rgba(0, 88, 255, 0.05)' : '#fff'}`,textIndent:`${8*grade}px`,
							color:`${item.SDMCode === hoverDocument? '#0058ff' : '#171725'}`, fontWeight: `${item.SDMCode === hoverDocument? 700 : 400}`}}
								 onMouseOver={()=>setHoverDocument(item.SDMCode)} onClick={(e)=>{
							e.stopPropagation()
							documentFolderListRes(item.SDMCode, item).then()
							setSelectSDMCode({SDMTopCode: item.SDMCode, name: item.name})
							setAddArray(true)}}>{item.name}
						</div>
						<div style={{textIndent:`${8*grade}px`}}>
							{lowerDocumentOpen(item?.lowerFolder, grade+1)}
						</div>
					</div>))
				}
			</React.Fragment>
		)
	}

	const filePopupType = (type: string) => {
		switch (type) {
			case 'create':
				return (
					<React.Fragment>
						{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal(initModal)}}/>
							: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'}/>
								: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}/> : null}

						{dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>setDataModal(initModal)}/>}

						<ModalBase zIndex={100}  modalContent={
							<Draggable ref={modalRef} disabled={isTouchDevice}>
								<BaseComponent authValid={authValid}>
									<div className={'modal'}>{t('문서 폴더 생성')}</div>
									<div className={'content'} onMouseDown={(e)=>{e.stopPropagation()}}>
										<div className={'content_subTitle'} style={{alignSelf:'flex-start'}}>{t('폴더 이름')}</div>
										<RegisterInput type={'input'} width={'384px'}
																	 onChange={(e)=> {setFolderName( e.target.value)
																		 if(folderName === ''){setFolderState(false)}
																	 }} error={folderState} name={'folder'} value={folderName}
																	 placeholder={!folderState? t('폴더명 입력') : t('등록할 폴더명을 입력해 주세요.')} onKeyPress={pressEnter}/>

										<div style={{display:'flex',gap:'24px',justifyContent:'content-end',marginTop:'40px'}}>
											<MainButtons name={'취소'} width={'144px'} height={'34px'} buttonType={'popupNormal'} clickEvent={cancelEvent}/>
											<MainButtons name={'생성'} width={'144px'} height={'34px'} buttonType={'popupCta'}
																	 clickEvent={()=>{
																		 if(!folderName.trim()){
																			 setFolderState(true)
																			 setFolderName('')
																		 }else{
																			 documentFolderRegister().then()
																		 }
																	 }}/>
										</div>
									</div>
								</BaseComponent>
							</Draggable>
						}/>
					</React.Fragment>
				);

				// 이건 사용안함
			case 'move':
				return (
					<React.Fragment>
						{dataModal.menu === 'confirm' &&
							<ConfirmPopup statusText={dataModal.message}
														clickEvent={()=> {
															if(dataModal.message?.includes('이동')){
																// setPersistSDMCode(selectSDMCode.SDMTopCode)
																if( cancelEvent ){
																	cancelEvent()
																}
																window.location.reload()
															}
															setDataModal(initModal)
														}}/>}
						<ModalBase zIndex={100} modalContent={
							<Draggable ref={modalRef} disabled={isTouchDevice}>
								<BaseComponent authValid={authValid}>
									<div className={'modal'}>{'파일 이동'}</div>
									<div className={'content'} onMouseDown={(e)=>{e.stopPropagation()}}>
										<div className={'content_move'} style={{alignItems:'center'}}>
											<div className={'content_subTitle'}>{'선택 파일'}</div>
											<RegisterInput type={'input'} width={'304px'} disabled={true}
																		 name={'file'} value={fileName} placeholder={'파일이름'} onKeyPress={pressEnter}/>
										</div>
										<div className={'content_move'} style={{alignItems:'center'}}>
											<div className={'content_subTitle'} style={{position:'relative'}}>{'위치 선택'}</div>
											<div style={{position:'absolute',left:'74px',top:'120px'}}>
												<DropBase ref={dropMenuRef} onClick={()=> {if(!openMenu){setOpenMenu( true )}}}
																	style={{height: `${openMenu? 'auto' : '32px'}`
																		,border:`1px solid ${openMenu? '#0058FF' : '#D7DBEC'}`,
																		zIndex: openMenu? 60 : 49,  minWidth:'160px'}}>
													<div style={{display:'flex',justifyContent:'space-between'}}>
														<div style={{fontSize:'14px',fontWeight:`${openMenu? 700 : 400}`,paddingLeft:'8px'}}>{selectSDMCode.name}</div>
														<div onClick={()=> {setOpenMenu(!openMenu)}} style={{paddingRight:'8px', display:'flex',flexDirection:'column',justifyContent:'center'}}>
															<img style={{transform:`rotateZ(270deg) rotateY(${openMenu? 180 : 0}deg)`, transition: 'transform 0.25s', alignSelf:'center'}} src={arrow} alt=""/>
														</div>
													</div>
													{
														<div className={'spread_menu'}>
															{totalDocument?.map((v:any)=>(
																<React.Fragment>
																	<div onMouseOver={()=>setHoverDocument(null)}
																			 onClick={(e)=>{
																				 e.stopPropagation()
																				 documentFolderListRes(v.SDMCode, v).then()
																				 setSelectSDMCode({SDMTopCode: v.SDMCode, name: v.name})
																				 setAddArray(true)
																			 }}>{v.name}</div>
																	{lowerDocumentOpen(v?.lowerFolder,2)}
																</React.Fragment>
															))}
														</div>}
												</DropBase>
											</div>
										</div>
										<div style={{display:'flex',gap:'24px',justifyContent:'content-end',marginTop:'40px'}}>
											<MainButtons name={'취소'} width={'144px'} height={'34px'} buttonType={'popupNormal'}
																	 clickEvent={cancelEvent}/>
											<MainButtons name={'이동'} width={'144px'} height={'34px'} buttonType={'popupCta'}
																	 clickEvent={fileMoveRes}/>
										</div>
									</div>
								</BaseComponent>
							</Draggable>
						}/>
					</React.Fragment>
				);
		}
	}

	return (
		<React.Fragment>
			{filePopupType(type)}
		</React.Fragment>
	)
};



export default FilePopup;


const BaseComponent = styled.div<{authValid: boolean}>`
  width: 416px;
  height: 264px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;
	
  .modal{
    width: 416px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color:  ${props => props.authValid? '#171725' : '#fff'};
    background-color:  ${props => props.authValid? '#CFFA61' : '#0058ff'};
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    gap: 8px;
    cursor: move;
		font-size: 18px;
		font-weight: 900;
  }

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 416px;
		height: 208px;
		box-sizing: border-box;
		padding: 24px 16px 16px;
		gap: 16px;
		
		&_subTitle {
			display: flex;
			font-size: 14px;
			font-weight: 900;
			white-space: nowrap;
		}
		
		&_move {
			display: flex;
			align-items: center;
			width: 384px;
			height: 34px;
			gap: 16px;
			
		}
		
	}
`

const DropBase = styled.div<{top?:string|number, left?: string|number, height?: number}>`
  width: 310px;
  border-radius: 4px;
	min-height: 32px;
	line-height: 32px;
  // min-height: ${props => props.height ?? 32}px;
  // line-height: ${props => props.height ?? 32}px;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  align-self: center;
  background-color: #fff;
  top: ${props => props.top ?? '8px'};
  left: ${props => props.left ?? '16px'};
  position: absolute;
  overflow: hidden;
  font-weight: bold;
  user-select: none;
  transition: all 0.25s;
 

  img {
    cursor: pointer;
  }

  .spread_menu {
    font-weight: 400;
	  overflow-y: scroll;
	  //max-height: 200px;
		max-height: 98px;
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
			display: block;
		}

		&::-webkit-scrollbar-thumb {
			border: 2px solid transparent;
		}

		&::-webkit-scrollbar-track {
			width: 10px;
			height: 8px;
			border-radius: 10px;
			background: ${props => props.height ? '#F5F5F5' : 'transparent'};
		}
		
		
    & > div{
      position: relative;
      z-index: 90;
      box-sizing: border-box;
			font-size: 14px;
      cursor: pointer;
			text-indent: 8px;
	    //padding: 0 8px;
      &:hover:first-child{
        background-color: rgba(0, 88, 255, 0.05);
        //border-bottom: 1px solid #d7dbec;
        color: #0058ff;
	      font-weight: 700;
      }
			}
  }
`
