import React, { useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  scroll?: any;
  showMore?: boolean;
  height?:number;
  width?:number;
  horizonType:boolean;
  right?:number;
  top?:number;

}

const ShowMore:React.FC<Props> = ( {showMore,height,width,horizonType,right,top}) => {

  const [shadow, setShadow] = useState(true)

  return (
    <React.Fragment>
      <ShowMoreComp onMouseEnter={()=>setShadow(false)}
        horizonType={horizonType}
        top={top}
        right={right}
        width={shadow? width : 0}
        height={height}
        opacity={(shadow && showMore) ? 0.8 : 0}
      />
    </React.Fragment>

  );
};

export default ShowMore;


const ShowMoreComp = styled.div<{ opacity?: number,horizonType?:boolean, height?:number,width?:any,right?:number,top?:number}>`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  right: ${props => props.horizonType ? props.right?? 0: 'none'}px;
  top: ${props => props.horizonType ? props.top?? 0: 'none'}px;
  opacity: ${props => props.opacity};
  z-index: 100;
  transition: all 0.1s ease-out;
  //pointer-events:  none;
  background-image: ${(props) =>
  (props.horizonType ?
    'linear-gradient(to right, transparent 0%, #FFFFFF00 10%, rgba(255, 255, 255, 0.8) 60% )' :
    'linear-gradient(to bottom, transparent 0%, #FFFFFF00 50%, #FFF 150%)')};

  //rgba(255, 255, 255, 0.8)
 
  box-shadow: ${(props) =>
  (props.horizonType ?
    'inset -20px 0 20px -10px #FFFFFF00' :
    'inset 0 -10px 10px -10px #fff')};
 
 
  

  
  //         (props.horizonType ? 
  //                 'linear-gradient(to left, transparent 8px, #ccc 200%, #fff 180%)' :
  //         'linear-gradient(to bottom, transparent 8px, #ccc 200%, transparent 180%)')};
 
 ////세로형
 //  background-image: linear-gradient(to bottom, transparent 8px, #ccc 200%, transparent 100%);
 //  mask-image: linear-gradient(to bottom, transparent 8px, #ccc 200%, transparent 180%);
 // 
   
 
  
`;