import React from 'react';
import styled from "styled-components";
import client from "../../common/Config";
import PreviewImage from "../display/PreviewImage";
import ConfirmPopup from "../modal/ConfirmPopup";

import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../types/Fundamental";
import {initModal , loadingRemove , uploadingLoading} from "../../function/function";
import DetailImageView from "../modal/DetailImageView";
import camera from '../../asset/image/mobile_camera_button.svg'

interface Props {
	imageKeyArray: (data:string[])=>void
	imageUrlArray?: (data:string[])=>void

	receiveKey?: string[]
	receiveUrl?: string[]
	receiveProfileKey?: (data?:string)=>void
	imageLength?: number
	inputSize?: number
	radiusSelect?: boolean
	profileKey?: string
	profileSelect: boolean
	disableDelete?: boolean

	onClick?:(i:number)=>void
	mobileType?:boolean
	verticalMode?: boolean
	noModal?: boolean
	title?:string
	itemName?:string
}

const TotalImageUploadBox:React.FunctionComponent<Props> = ({
																imageLength,onClick, imageUrlArray,title,itemName,
																imageKeyArray, radiusSelect, disableDelete,mobileType,
																inputSize, profileSelect, noModal,
																receiveKey, receiveProfileKey,
																receiveUrl, profileKey, verticalMode
															}) => {

	const uploadRef = React.useRef<any>(null)

	const [imageKeyArrayState, setImageKeyArrayState] = React.useState<any[]>(receiveKey ?? [])
	const [imagePreviewState, setImagePreviewState] = React.useState<any[]>(receiveUrl ?? [])

	const [profileKeyState, setProfileKeyState] = React.useState<any>(profileKey)
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data:any) => {
		e.preventDefault()
		uploadingLoading('업로드 중 입니다...')
		const formData = new FormData()
		formData.append('file',data)
		try {
			const response = await client.post('/file/upload',
				formData
				// ,{headers:{ "Content-Type": "multipart/form-data" }}
			)

			// console.log(response.data)
			const data = response.data.data

			setImageKeyArrayState([...imageKeyArrayState, data.imgKey])
			setImagePreviewState([...imagePreviewState, data.imgUrl])
			uploadRef.current.value = ""
			// imageKeyArray(imageKeyArrayState[0])
			if(imageKeyArrayState.length === 0){
				setProfileKeyState(data.imgKey)
			}

		}catch (e) {
			console.log(e)
		}
		loadingRemove()
	}

	const deleteData = (i: number) => {
		setImageKeyArrayState(imageKeyArrayState.filter((_,data) => data !== i))
		setImagePreviewState(imagePreviewState.filter((_,data) => data !== i))
		// receiveProfileData(null)
		// if(profileKeyState === imageKeyArrayState[0]){
		// 	receiveProfileData(imageKeyArrayState[1])
		// }

		if(profileKeyState === imageKeyArrayState[0]){
			receiveProfileData(imageKeyArrayState[1])
		}
		else if(profileKeyState === imageKeyArrayState[i]){
			receiveProfileData(imageKeyArrayState[0])
		}else{
			//@ts-ignore
			receiveProfileData(profileKey)
		}
	}

	React.useEffect(()=>{
		if(receiveUrl){
			setImagePreviewState(receiveUrl)
		}
	},[receiveUrl])

	React.useEffect(()=>{
		if(receiveKey){
			setImageKeyArrayState(receiveKey)
		}
	},[receiveKey])

	React.useEffect(()=>{
		if(profileKey){
			setProfileKeyState(profileKey)
		}
	},[profileKey])


	React.useEffect(()=>{
		imageKeyArray(imageKeyArrayState)
		if(imageUrlArray){
			imageUrlArray(imagePreviewState)
		}
		if(imageKeyArrayState.length === 0){
			//@ts-ignore
			imageKeyArray( null )
			//@ts-ignore
			receiveProfileData( null )
		}
		if(imageKeyArrayState.length === 1 && profileSelect){
			receiveProfileData(imageKeyArrayState[0])
		}
	},[imageKeyArrayState, profileKeyState])

	const receiveProfileData = (data:string) => {
		setProfileKeyState(data)
		if(profileSelect){
		//@ts-ignore
			receiveProfileKey(data)
		}
	}

	const extendReg = /(.*?)\.(jpg|jpeg|png)$/
	const { t }:any = useTranslation();
	// console.log(imageKeyArrayState.length <( imageLength ?? 4))

	return (
				<div style={{display:'grid', gridTemplateColumns:mobileType ? '':`repeat(${verticalMode? 1 : 2}, 168px)`,gap:'16px'}}>
					{dataModal.menu === 'upload' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>setDataModal(initModal)}/>}
					{dataModal.menu === 'image' && <DetailImageView itemName={itemName} title={title} mobileView={mobileType}
																													image={imagePreviewState[dataModal.index]} closeMenu={()=>{setDataModal(initModal)}}/>}

					{imagePreviewState?.map((v,i)=>(
						<PreviewImage imageUrl={v} deleteImg={()=>deleteData(i)}
													radioActive={profileSelect} key={v}
													clickEvent={()=> {
														if(noModal){
															if( onClick ){
																onClick( i )
															}
														}else{
															setDataModal({menu:'image',index:i})
														}}}
													radioClick={(key:string)=>receiveProfileData(key)} radiusCircle={radiusSelect}
													profileChecked={profileKeyState === imageKeyArrayState[i]} profileIndex={imageKeyArrayState[i]}
													previewSize={inputSize} noneDelete={disableDelete}/>
					))}

					{imageKeyArrayState.length < (imageLength ?? 4) &&
						//@ts-ignore
						<AddImageBox mobileType={mobileType} style={{fontSize:`${mobileType ? 8 : inputSize/100}vw`,width: `${inputSize}px`, height: `${inputSize}px`,
							borderRadius:`${radiusSelect? '50%' : mobileType ? '16px' : '4px'}`,   lineHeight:`${inputSize}px`,display:mobileType ? 'flex' : '',alignItems: mobileType ? 'center' : '',justifyContent:mobileType ? 'center' : '',
							marginTop:`${profileSelect? '26px' : 0}`, userSelect:'none'}}>
							{mobileType ? <img src={camera}/>:'┼'}
							<input type={'file'} style={{display:'none'}} ref={uploadRef} accept={'image/*'}
										 onChange={(e:any)=> {
											 if(uploadRef.current.value.match(extendReg)){
												 if(e.target.files[0].size < 10*1024*1024){
													 fileUpload(e, e.target.files[0]).then()
												 }else{
													 setDataModal({menu:'upload', message: t('10MB 이하의 파일을 업로드 해주세요.')})
													 uploadRef.current.value = ""
												 }
											 }else{
												 setDataModal({menu:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})
											 }
										 }}/>
						</AddImageBox>}
				</div>
	);
}

export default TotalImageUploadBox;

const AddImageBox = styled.label<{mobileType?:boolean}>`
    width: 168px;
    height: 168px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D7DBEC;
    color: #a1a1a1;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 168px;
    user-select: none;
    cursor: pointer;
   `

















