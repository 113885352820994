import React , {useEffect , useRef , useState} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  tooltipModule ,
  validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";
import {Error_Type , Modal_View_Type} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  decimalNumberValid ,
  filterCheckedRow ,
  handleDecimalChange ,
  initError ,
  initModal ,
  loadingRemove , menuVisitLogRegister ,
  numberLocaleString ,
  onChangeArray ,
  onChangeCheck ,
  onChangeNumberArray ,
  removeCheckedFunction ,
  rowListDropDirection ,
  singleCheckedFunction ,
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState , useRecoilValue} from "recoil";
import {persistBlockPage , persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import FilterWithSearchBar from "../../../../../components/input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const RawMaterials = () => {
  const {t}: any = useTranslation();
  const materialTitle = ['원자재 CODE' , '원자재 품명' , '재질' , '재질 종류' ,'규격', '두께' , '가로' , '세로' ,'피더', '안전 재고량' , '단위' , '거래처' , '사용 기준일'];
  const needValue = ['원자재 CODE' , '원자재 품명' , '단위']
  const [rowList , setRowList] = useState<any[]>( [] )
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [idIndex , setIdIndex] = useState<number>( 1 )
  const [hoverIcon , setHoverIcon] = useState<string>( modify )
  const [iconSelect , setIconSelect] = useState<number>( - 1 )
  const [focusList , setFocusList] = useState<number>( - 1 )
  const [materialList , setMaterialList] = useState<any>( [] );
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [accountSearchModal , setAccountSearchModal] = useState<boolean>( false );
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [selectRowIndex , setSelectRowIndex] = useState<number>( 0 )
  const [page , setPage] = useState<number>( 1 )
  const [totalPage , setTotalPage] = useState<number>( 1 )
  const [searchInput , setSearchInput] = useState<string>( '' )
  const [pushInput , setPushInput] = useState<string>( '' )
  const [selectCode , setSelectCode] = useState<string>( '' )
  const [selectRawMaterialData , setSelectRawMaterialData] = useState<any>( {} );
  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [errorState , setErrorState] = useState<Error_Type>( initError )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage );
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const scrollRef = useRef<any>( null )
  const scrollToStart = () => {
    scrollRef.current.scrollLeft = 0
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    setIdIndex( idIndex + 1 )
    const rowInput = {
      id: idIndex ,
      companyCode: selectedCompany.companyCode ,
      rawMaterialSerialCode: '' ,
      rawMaterialName: '' ,
      material: '' ,
      thickness: '' ,
      width: '' ,
      length: '' ,
      rawMaterialUnit: '' ,
      materialType: '' ,
      rawMaterialStock: '' ,
      customer: '' ,
      standardDate: '' ,
      codeDoubleCheck: false
    }
    setRowList( [...rowList , rowInput] )
    setCheckedList( [...checkedList , rowInput.id] )
    scrollToStart()
  }
  const updateFunction = () => {
    if( page === 1 ){
      if( pushInput === '' ){
        rawMaterialListRes().then()
      } else{
        rawMaterialSearchListRes().then()
      }
    } else{
      setPage( 1 )
    }
  }
  const rawMaterialListRes = () => ListCustomHooks( `/rawMaterial/list/${page}/${10}/${selectedCompany.companyCode}` , page , setTotalPage , setPageBlock , setMaterialList , setErrorState , setSearchInput , setPushInput )
  const rawMaterialSearchListRes = () => ListSearchPostCustomHooks( `/rawMaterial/search` , page , setTotalPage , setPageBlock , setMaterialList , setErrorState , {
    page: page ,
    size: 10 ,
    keyword: searchInput ,
    companyCode: selectedCompany.companyCode,
    filter:categorySelect.type
  } )
  //리스트
  // const rawMaterialListRes = async () => {
  //     try {
  //         listLoadingCircle()
  //         const response = await client.get(`/rawMaterial/list/${page}/${10}/${selectedCompany.companyCode}`,{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         const valid = response.data
  //         if(valid.status !== 200){
  //             setErrorState({notice:valid.message,status:valid.status})
  //             setMaterialList([]);
  //         }else{
  //             setErrorState({notice:t('* 등록된 원자재 정보가 없습니다. 등록해 주세요.'),status:valid.status})
  //             setTotalPage(data.total_page)
  //             setPushInput('')
  //             setSearchInput('')
  //             setMaterialList(data.row)
  //             // if(data.total_page > 1 && page > 1){
  //             //     setMaterialList((prev:any)=> prev.concat(data.row))
  //             // }else{
  //             //     setMaterialList(data.row)
  //             // }
  //         }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //
  // //검색
  // const rawMaterialSearchListRes = async () => {
  //     try {
  //         listLoadingCircle()
  //         const response = await client.post(`/rawMaterial/search`,{
  //         //    /${page}/${10}/${selectedCompany.companyCode}/${pushInput}
  //             page:page,
  //             size:10,
  //             companyCode:selectedCompany.companyCode,
  //             keyword:pushInput
  //         },{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         console.log(data.total_page)
  //         setTotalPage(data.total_page)
  //         setMaterialList(data.row)
  //         setPageBlock(0)
  //         // if(data.total_page > 1 && page > 1){
  //         //     setMaterialList((prev:any)=> prev.concat(data.row))
  //         // }else{
  //         //     setMaterialList(data.row)
  //         // }
  //         loadingRemove()
  //     }catch (e) {
  //
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //등록
  const rawMaterialRegister = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialSerialCode ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 코드를 입력해 주세요.'} )
    }

    else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 코드 중복 확인을 해주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialName ) ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 품명을 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialUnit ) ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 단위를 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/rawMaterial/register' , filterCheckedRow( rowList , checkedList , 'id' )
          , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( [] )
            setCheckedList( [] )
            updateFunction()
            setDataModal( {menu: 'success' , message: '원자재 정보가 등록 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  const rawMaterialDelete = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( '/rawMaterial/delete' , [...checkedList] , {headers: {Authorization}} )
      const data = response.data
      const bomCaseArray = data.data?.bom?.map( ( v: any ) => ( {
        code: dataValid( v.code , v ) ,
        case: dataValid( v.used , '' ) + '하위 BOM으로 등록 되어 있습니다.'
      } ) )
      const inventoryCaseArray = data.data?.inventory?.map( ( v: any ) => ( {code: v , case: '원자재 재고가 존재합니다.'} ) )
      const caseArray = [bomCaseArray , inventoryCaseArray].flat()
      if( data.status === 200 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setIconSelect( - 1 )
          setDataModal( {menu: 'success' , message: '선택한 원자재 정보가 삭제 되었습니다.'} )
          removeCheckedFunction( setMaterialList , materialList , setCheckedList , checkedList , 'rawMaterialCode' )
          scrollToStart()
          if( page === 1 ){
            if( pushInput === '' ){
              rawMaterialListRes().then()
            } else{
              rawMaterialSearchListRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setDataModal( {menu: 'deleteCase'} )
          setDeleteCaseArray( caseArray )
          setCheckedList( checkedList.filter( ( v: string ) => !data.data.success.includes( v ) ) )
          setMaterialList( materialList.filter( ( v: any ) => !data.data.success.includes( v['rawMaterialCode'] ) ) )
          scrollToStart()
          if( page === 1 ){
            if( pushInput === '' ){
              rawMaterialListRes().then()
            } else{
              rawMaterialSearchListRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  //수정
  const rawMaterialUpdateRes = async () => {
    if( !materialList[iconSelect].rawMaterialSerialCode ){
      setDataModal( {menu: 'confirm' , message: '원자재 CODE를 입력하지 않았습니다.'} )
    } else if( !materialList[iconSelect].rawMaterialName ){
      setDataModal( {menu: 'confirm' , message: '원자재 품명을 입력하지 않았습니다.'} )
    } else{
      try {
        setDataModal( initModal )
        dataAccessLoading()
        const response = await client.post( '/rawMaterial/update' , {
          companyCode: selectedCompany.companyCode ,
          customer: materialList[iconSelect].customerName ,
          ...materialList[iconSelect]
        } , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            updateFunction()
            setCheckedList( [] )
            setIconSelect( - 1 )
            setDataModal( {menu: 'success' , message: '수정 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //원자재 시리얼코드 중복체크
  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = rowList.filter( ( v ) => ( v.rawMaterialSerialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/rawMaterial/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        rawMaterialSerialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        rawMaterialSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }
      if( rowList.length !== 0 ){
        if( duplicateCount[0] === 0 ){
          if( regCount !== 0 ){
            setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
            setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 원자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
          } else{
            setRowList( copyRowList )
            setDataModal( {menu: 'confirm' , message: '중복되지 않은 원자재CODE 입니다.'} )
          }
        } else{
          if( duplicateCount.length === 1 ){
            setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
          } else{
            if( regCount < duplicateCount.length ){
              setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
            } else{
              setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
            }
          }
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 원자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      } else{
        if( duplicateCount[0] === 0 ){
          const copyMaterialList = [...materialList]
          copyMaterialList[index] = {
            ...copyMaterialList[index] , codeDoubleCheck: true ,
            rawMaterialSerialCode: serialCode
          }
          setMaterialList( copyMaterialList )
          setDataModal( {menu: 'confirm' , message: '중복되지 않은 원자재CODE 입니다.'} )
        } else{
          setInputSerialCode( serialCode + `(${( duplicateCount[0] )})` )
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 원자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: '* 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }
  const checkboxValid = () => {
    if( rowList.length !== 0 ){
      return {list: rowList , id: 'id'}
    } else{
      return {list: materialList , id: 'rawMaterialCode'}
    }
  }
  const deleteRowEvent = () => {
    if( rowList.length === 0 ){
      setDataModal( {menu: 'select' , message: `${checkedList.length}개의 원자재를 삭제 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'back' , message: '선택된 행의 정보를 삭제하시겠습니까?'} )
    }
  }
  React.useEffect( () => {
    if( selectedCompany.companyCode !== undefined ){
      if( pushInput === '' ){
        rawMaterialListRes().then()
      } else{
        rawMaterialSearchListRes().then()
      }
    }
  } , [pushInput , page , selectedCompany,categorySelect.type] )
  const onDataResetFunc = () => {
    setAccountCode( {} );
    setRowList( [] );
    setCheckedList( [] );
    setMaterialList( [] )
    setSearchInput( '' );
    setIconSelect( - 1 );
    setPushInput( '' );
    setPage( 1 );
    setPageBlock( 0 );
    setTotalPage( 1 )
    setErrorState( initError )
  }
  React.useEffect( () => {
    onDataResetFunc()
  } , [selectedCompany.companyCode] )
  const cancelFuc = () => {
    setHoverIcon( modify );
    setIconSelect( - 1 );
    setCheckedList( [] )
    setDataModal( initModal )
    if( pushInput === '' ){
      setMaterialList( materialList.map( ( v: any ) => {return v.rawMaterialCode === selectCode ? {...v , ...selectRawMaterialData} : v} ) )
    } else{
      rawMaterialSearchListRes().then()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( rowList.length > 0 || iconSelect !== - 1 ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [rowList.length , iconSelect] );
  // const [isScrolling, setIsScrolling] = useState<boolean>(true);
  //
  // const handleScroll = () => {
  //     //확인된 최대값 숫자 넣기
  //     if(scrollRef?.current?.scrollLeft === 408){
  //         setIsScrolling(false)
  //     }else{
  //         setIsScrolling(true)
  //     }
  // };

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'원자재 기본정보').then()
    }
  },[])

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'원자재 CODE'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             if( rowList.length !== 0 ){
                               const copyRowList = [...rowList]
                               copyRowList[checkIndex] = {
                                 ...copyRowList[checkIndex] ,
                                 codeDoubleCheck: true ,
                                 rawMaterialSerialCode: inputSerialCode
                               }
                               setRowList( copyRowList )
                             } else{
                               const copyMaterialList = [...materialList]
                               copyMaterialList[checkIndex] = {
                                 ...copyMaterialList[checkIndex] , codeDoubleCheck: true ,
                                 rawMaterialSerialCode: inputSerialCode
                               }
                               setMaterialList( copyMaterialList )
                             }
                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {accountSearchModal &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {
                                if( rowList.length !== 0 ){
                                  Object.assign( rowList[selectRowIndex] , {
                                    customer: accountCode.customerName ,
                                    customerName: accountCode.customerName
                                  } )
                                } else{
                                  Object.assign( materialList[iconSelect] , {
                                    customer: accountCode.customerName ,
                                    customerName: accountCode.customerName
                                  } )
                                }
                                setAccountSearchModal( false )
                              }}
                              cancelEvent={() => {
                                setAccountSearchModal( false );
                                setAccountCode( {} )
                              }}
                              receiveData={setAccountCode}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )}
                        clickEvent={() => {
                          setDataModal( initModal )
                        }}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                rawMaterialDelete().then();
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={rawMaterialRegister}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
                                  setSelectRawMaterialData( {} )
                                  setAccountCode( {} )
                                  setDataModal( initModal )
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={rawMaterialUpdateRes}/>}
      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'rawMaterial'} type={'download'}
                              companyData={selectedCompany} disabled={materialList?.length === 0}/>
              <ExcelApiButton category={'rawMaterial'} type={'format'} disabled={buttonAuthValid( 'create' , '9' )}
                              companyData={selectedCompany} urlStyle={true}/>
              <ExcelApiButton category={'rawMaterial'} type={'upload'} disabled={buttonAuthValid( 'create' , '9' )}
                              companyData={selectedCompany}/>
            </React.Fragment>
          }/>
      }

      <div style={{display: 'flex' , justifyContent: 'space-between'}}>
        <div style={{display: 'flex'}}>
          <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 원자재 기본정보'} unitCase={'MenuTitle'}/>
        </div>
        {rowList?.length !== 0 &&
            <MainButtons name={'목록'} width={'56px'}
                         clickEvent={() => {
                           window.location.href = authValid() ? '/admin/fas/rawmaterial' : '/fas/rawmaterial'
                         }}/>}
      </div>

      {/*원자재 기본정보 */}
      <ContentFrame height={'680px'}
                    justify={'space-between'}
                    padding={'30px 30px 20px'}>
        <div>
          <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
            <MenuTitle title={'원자재 기본정보'} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex'}}>
              {/*<SearchBar clickEvent={() => {*/}
              {/*  setPushInput( searchInput );*/}
              {/*  setPage( 1 );*/}
              {/*  setPageBlock( 0 )*/}
              {/*}} value={searchInput} keyPressDisable={true}*/}
              {/*           clearButtonAccess={searchInput !== ''}*/}
              {/*           keyFunction={( e ) => setSearchInput( e.target.value.trimStart() )}*/}
              {/*           clearButton={() => {*/}
              {/*             rawMaterialListRes().then();*/}
              {/*             setPage( 1 )*/}
              {/*           }}*/}
              {/*           disabled={( materialList.length === 0 && pushInput === '' ) || rowList.length !== 0 || iconSelect !== - 1}/>*/}

              <FilterWithSearchBar clickEvent={() => {
                                    setPushInput( searchInput );
                                    setPage( 1 );
                                    setPageBlock( 0 )
                                  }} value={searchInput} keyPressDisable={true}
                                   clearButtonAccess={searchInput !== ''}
                                   keyFunction={( e ) => setSearchInput( e.target.value.trimStart() )}
                                   clearButton={() => {rawMaterialListRes().then(); setPage( 1 )}}
                                   disabled={( materialList.length === 0 && pushInput === '' ) || rowList.length !== 0 || iconSelect !== - 1}
                                   margin={'0'} selectKeyword={categorySelect.value}
                                   categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                   categoryTypeArray={['@','raw_material_serial_code','raw_material_name','material','material_type','customer_name']}
                                   categoryValueArray={['전체','원자재 CODE','원자재 품명','재질','재질종류','거래처']}
                                   placeholder={t('검색')}
              />

              <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>

              {/*{selectedCompany.companyCode !== undefined &&*/}
              {/*    <MainButtons name={'행추가'} width={'80px'}*/}
              {/*                 disabled={buttonAuthValid( 'create' , '9' ) || ( iconSelect !== - 1 || pushInput !== '' || checkedList[0]?.length > 1 )}*/}
              {/*                 clickEvent={addListRow}/>}*/}

              {checkedList?.length !== 0 && <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                            marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
                            disabled={
                              buttonAuthValid( 'rowDelete' , '9' ) ?
                                rowList.length === 0
                                :
                                ( buttonAuthValid( 'update' , '9' ) || ( checkedList.length === 0 ) )}
                            clickEvent={deleteRowEvent}/>}

              {rowList?.length !== 0 &&
                    <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                                 disabled={buttonAuthValid( 'create' , '9' ) || ( rowList.length === 0 || checkedList.length === 0 )}
                                 clickEvent={() => {
                                   if( checkedList.length === rowList.length ){
                                     rowList.map( ( v ) => ( v.rawMaterialStock.replace( /,/g , '' ) ) )
                                     rawMaterialRegister().then()
                                   } else{
                                     setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'} )
                                   }
                                 }}/>
                  }
              {( rowList?.length === 0 && iconSelect === - 1 && checkedList?.length === 0 ) &&
                  <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                               marginDis={true}
                               disabled={buttonAuthValid( 'create' , '9' ) || ( !selectedCompany.companyCode )}
                               clickEvent={() => window.location.href = authValid() ? '/admin/fas/rawmaterial/register' : '/fas/rawmaterial/register'}/>}
            </div>
          </div>
          {/*{iconSelect === -1 &&*/}
          {/*  <ShowMore showMore={isScrolling} width={30} height={authValid? 335 : 530} right={40} top={78} horizonType={true}/>}*/}
          {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
          {/*                next={()=>setPage((prev)=> prev+1)}*/}
          {/*                hasMore={totalPage > page} dataLength={dataValid(materialList?.length, 0)}*/}
          {/*                loader={<div/>}>*/}
          <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                              scrollWidth={2020}
                              scrollY_Hide={rowList?.length < 10}
            // height={listHeightValid(authValid,{total: totalPage, page: page})
                              height={538}
                              tempCol={'40px 172px repeat(2, 160px) 80px 80px repeat(5, 188px) 80px 144px 164px'}>

            <div className={'grid-title'}>
              <label className={'checkbox'}>
                <input type="checkbox"
                       style={{
                         pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` ,
                         opacity: `${iconSelect === - 1 ? 1 : 0.5}`
                       }}
                       onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                         dataList: checkboxValid().list ,
                         checkedList: checkedList ,
                         id: checkboxValid().id ,
                         setState: setCheckedList
                       } )}
                       checked={allCheckedState( {
                                                   dataList: checkboxValid().list ,
                                                   checkedList: checkedList ,
                                                   id: checkboxValid().id
                                                 } )}/>
              </label>
              {materialTitle.map( ( v ) => (
                <TableValue title={v} key={v} viewType={iconSelect !== - 1 || rowList.length !== 0}
                            requiredValue={needValue}/> ) )}
            </div>

            {rowList.length === 0 && materialList.length === 0 ? pushInput === '' ? errorState.status !== 200 ?
                  listResultMessage( authValid() , dataValid( errorState.notice , '* 업체를 선택해 주세요.' ) ,
                                     {width: 1210 , admin: 480 , user: 480} )
                  :
                  listResultMessage( authValid() , dataValid( errorState.notice , '' ) ,
                                     {
                                       width: 1210 ,
                                       admin: 480 ,
                                       user: 480
                                     } , () => {window.location.href = authValid() ? '/admin/fas/rawmaterial/register' : '/fas/rawmaterial/register'} , buttonAuthValid( 'create' , '9' ),'등록' )
                :
                listResultMessage( authValid() , '*검색 결과가 없습니다.' , {width: 1210 , admin: 480 , user: 480} )
              :
              rowList.map( ( value , index , arr ) => (
                <div className={'grid-row-list'}>
                  <label className={'checkbox'}>
                    <ListInnerInput type={'check'} name={'id'}
                                    onChange={( e: any ) => singleCheckedFunction( e.target.checked , value.id , setCheckedList , checkedList )}
                                    checked={checkedList.includes( value.id )}/>
                  </label>
                  <ListInnerInput type={'withButton'} width={'120px'} name={'rawMaterialSerialCode'}
                                  placeholder={'-'}
                                  inputData={value.rawMaterialSerialCode} inputDisabled={value.codeDoubleCheck}
                                  onChange={( e ) => {
                                    onChangeArray( index , e , rowList , setRowList , true )
                                    // onChangeCheck(index, rowList, setRowList)
                                  }}
                                  clickButton={() => {
                                    serialDoubleCheck( value.rawMaterialSerialCode , index ).then()
                                  }}
                                  buttonText={value.codeDoubleCheck ? '확인됨' : '중복확인'}
                                  buttonWidth={'60px'}
                                  disabled={( value.codeDoubleCheck || !value.rawMaterialSerialCode )}/>

                  <ListInnerInput type={'text'} width={'120px'} name={'rawMaterialName'} placeholder={'-'}
                                  inputData={value.rawMaterialName}
                                  onChange={( e ) => onChangeArray( index , e , rowList , setRowList , true )}/>
                  <ListInnerInput type={'text'} width={'120px'} name={'material'} placeholder={'-'}
                                  inputData={value.material}
                                  onChange={( e ) => onChangeArray( index , e , rowList , setRowList , true )}/>

                  <ListInnerInput type={'drop'} name={'materialType'} inputData={value.materialType}
                                  dropMenuList={['COIL' , 'SHEET','소재','절단']}
                                  tabIndex={- 1} dropAddNoneSelect={true}
                                  dropTopDirection={rowListDropDirection( arr , index , materialList )}
                                  selectDrop={( v: string ) => {
                                    setRowList( ( prev: any ) => {
                                      return prev.map( ( item: any , i: number ) => {
                                        if( i === index ){
                                          return {
                                            ...item ,
                                            materialType: v ,
                                            rawMaterialUnit: v === 'COIL' || v === 'SHEET' ? 'kg' : 'ea' ,
                                            rawMaterialStock: '' ,
                                            length: ''
                                          };
                                        }
                                        return item;
                                      } );
                                    } );
                                    // setRowList((prev: any) => ([{ ...prev[index], materialType: v, rawMaterialUnit: v === 'COIL' ? 'kg' : 'ea', rawMaterialStock:''}]));
                                    // Object.assign(rowList[index] , {materialType: v, rawMaterialUnit: v === 'COIL' ? 'kg' : 'ea', rawMaterialStock:''});
                                  }}/>

                  <ListInnerInput type={'unit'} width={'140px'} unit={'T'} name={'thickness'} placeholder={'-'}
                                  inputData={decimalNumberValid( value.thickness )} limit={15}
                                  onChange={( e ) => {handleDecimalChange( index , e , rowList , setRowList , '' )}}/>
                  <ListInnerInput type={'unit'} width={'140px'} unit={'mm'} name={'width'} placeholder={'-'}
                                  inputData={decimalNumberValid( value.width )} limit={15}
                                  onChange={( e ) => {handleDecimalChange( index , e , rowList , setRowList , '' )}}/>
                  <ListInnerInput type={'unit'} width={'140px'} unit={'mm'} name={'length'} placeholder={'-'}
                                  inputData={decimalNumberValid( value.length )} limit={15}
                    // disabled={value.materialType === 'COIL'}
                                  onChange={( e ) => {handleDecimalChange( index , e , rowList , setRowList , '' )}}/>
                  <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={decimalNumberValid( value.feeder )}/>

                  <ListInnerInput type={'unit'} width={'96px'} unit={dataValid( value.rawMaterialUnit , 'ea' )}
                                  limit={15} name={'rawMaterialStock'} placeholder={'-'}
                                  inputData={value.rawMaterialStock.includes( '.' ) ? value.rawMaterialStock : numberLocaleString( value.rawMaterialStock )}
                                  onChange={( e ) => {handleDecimalChange( index , e , rowList , setRowList , value.rawMaterialUnit )}}/>
                  <ListInnerInput type={'drop'} name={'unit'} inputData={dataValid( value.rawMaterialUnit , 'ea' )}
                                  tabIndex={- 1}
                                  dropTopDirection={rowListDropDirection( arr , index , materialList )}
                                  selectDrop={( v ) => {
                                    setSelectCode( v.id )
                                    setRowList( rowList.map( ( v ) => {return v.id === selectCode ? {...v , ...rowList[index]} : v} ) )
                                    Object.assign( rowList[index] , {rawMaterialUnit: v , rawMaterialStock: ''} )
                                  }}
                                  dropMenuList={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>
                  <ListInnerInput type={'search'} name={'customerName'} inputData={dataValid( value.customerName )}
                                  placeholder={'검색'} tabIndex={- 1}
                                  clickButton={() => {
                                    setAccountSearchModal( true )
                                    setSelectRowIndex( index )
                                  }}/>
                  <ListInnerInput type={'unit'} unit={'일'} name={'standardDate'} width={'120px'}
                                  inputData={numberLocaleString( value.standardDate )} placeholder={'-'}
                                  onChange={( e ) => onChangeNumberArray( index , e , rowList , setRowList )}
                                  limit={11}/>
                </div>
              ) )}

            {rowList?.length === 0 &&
              materialList.map( ( value: any , index: number) => (
                <React.Fragment>
                  {
                    <GridTableSelect selected={value.rawMaterialCode === selectCode}
                                     disabled={rowList.length !== 0 || iconSelect !== - 1}
                                     checkboxNumber={( ( index + 1 ) + page * 10 ) - 10}
                                     onDoubleClick={() => {
                                       window.location.href = `${authValid() ? `/admin/fas/rawmaterial/${value?.rawMaterialCode}` : `/fas/rawmaterial/${value?.rawMaterialCode}`}`
                                     }}
                                     className={'grid-list'} onClick={() => setSelectCode( value.rawMaterialCode )}
                                     onMouseOut={() => setFocusList( - 1 )} onMouseOver={() => setFocusList( index )}>
                      <label className={'selectCheckbox'}>
                        <ListInnerInput type={'check'} name={'rawMaterialCode'}
                                        onChange={( e: any ) => singleCheckedFunction( e.target.checked , value.rawMaterialCode , setCheckedList , checkedList )}
                                        checked={checkedList.includes( value.rawMaterialCode )}/>
                      </label>
                      {/*{tooltipModule( dataValid( value.rawMaterialSerialCode ) , 10 )}*/}
                      {/*{tooltipModule( dataValid( value.rawMaterialName ) , 11 )}*/}
                      {/*{tooltipModule( dataValid( value.material ) , 10 )}*/}

                      {autoTextValueValidation({value: value.rawMaterialSerialCode})}
                      {autoTextValueValidation({value: value.rawMaterialName})}
                      {autoTextValueValidation({value: value.material})}

                      {validationValue( value.materialType )}
                      {validationValue( value.standard )}
                      {/*<div style={{fontSize: !value.standard? '14px' : '20px', fontWeight: 800}}>*/}
                      {/*  {dataValid(value.standard)}*/}
                      {/*</div>*/}
                      <ListInnerInput type={'noneInputUnit'} unit={'T'}
                                      inputData={decimalNumberValid( value.thickness )}/>
                      <ListInnerInput type={'noneInputUnit'} unit={'mm'}
                                      inputData={decimalNumberValid( value.width )}/>
                      <ListInnerInput type={'noneInputUnit'} unit={'mm'}
                                      inputData={autoTextValueValidation({value:decimalNumberValid( value.length)})}/>
                      <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={decimalNumberValid( dataValid(value.feeder,'0') )}/>

                      <ListInnerInput type={'noneInputUnit'}
                                      unit={dataValid( value.rawMaterialUnit , 'ea' )}
                                      inputData={autoTextValueValidation({value:numberLocaleString( value.rawMaterialStock )})}/>
                      {validationValue( value.rawMaterialUnit , 'ea' )}
                      {tooltipModule( dataValid( value.customerName ) , 6 )}
                      <ListInnerInput type={'noneInputUnit'} unit={'일'}
                                      inputData={numberLocaleString( value.standardDate )}/>
                    </GridTableSelect>
                  }
                </React.Fragment>
              ) )}
          </GridTableComponent>
          {/*</InfiniteScroll>*/}
        </div>

        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div>
            {rowList.length === 0 &&
                <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                blockNum={pageBlock} setBlockNum={setPageBlock}
                                counts={totalPage} disabled={iconSelect !== - 1}/>}
          </div>
          <HorizontalMoveScrollButton
            leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
            rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default RawMaterials;

