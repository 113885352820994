import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {
	ContentFrame ,
	GridTableComponent , GridTableSelect ,
	NoData , SectionFrame , validationValue
} from "../../../../../style/StyleComponent";
import checked from "../../../../../asset/image/check-icon.svg";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectEventButton from "../../../../../components/button/SelectEventButton";
import {useTranslation} from "react-i18next";
import {useRecoilState , useResetRecoilState} from "recoil";
import {
	calendarRangeEndDate ,
	calendarRangeStartDate ,
	persistCompanyData ,
} from "../../../../../common/StateManage";
import {
	listLoadingCircle ,
	loadingRemove
} from "../../../../../function/function";
import MainButtons from "../../../../../components/button/MainButtons";
import ArrowButton from "../../../../../components/button/ArrowButton";
import TableValue from "../../../../../style/TableValue";
import CalendarRangePicker from "../../../../../components/calendar/CalendarRangePicker";
import CompanySearchList from "../../../../../components/list/CompanySearchList";




const CustomerNotice = () => {
	const solutionData = ['P-BAS','P-MS','R-BAS','C-BAS','A-BAS','F-EMS']

	const [noticeData,setNoticeData] = React.useState<any[]>([]);
	const [checkedList,setCheckedList] = React.useState<string[]>(['P-BAS','F-EMS']);
	const [dateSearch,setDateSearch] = React.useState<boolean>(false);
	const [checkBoxDrop,setCheckBoxDrop] = React.useState<boolean>(false);
	const [selectedCompany,setSelectedCompany] = useRecoilState(persistCompanyData)
	const [selectIdx,setSelectIdx] = React.useState<number>(-1);

	const [startDate, setStartDate] = useRecoilState(calendarRangeStartDate)
	const [endDate, setEndDate] = useRecoilState(calendarRangeEndDate)
	const resetStartDate = useResetRecoilState(calendarRangeStartDate)
	const resetEndDate = useResetRecoilState(calendarRangeEndDate)

	const {t}:any = useTranslation();

	const onCheckedType = (checked:any, item:any) => {
		if (checked) {
			setCheckedList([...checkedList, item]);
		} else if (!checked) {
			setCheckedList(checkedList.filter(el => el !== item));
		}
	};

	const totalAlarmInfo = async () =>{
		try {
			listLoadingCircle()
			const response = await client.post(`/alarm/list/admin`,{
				code:selectedCompany.companyCode,
				date:`${startDate + '-' + endDate}`,
				solutionList:checkedList,
			},{headers:{Authorization}})
			console.log(response.data.data)
			const data = response.data.data
			setNoticeData(data.row)
			loadingRemove()
		}catch ( e ) {
			console.log(e)
			loadingRemove()
		}
	}
	React.useEffect(()=>{
		if(selectedCompany.companyCode){
			if(checkedList.length > 0){
				totalAlarmInfo().then()
				const timerAlarm = setInterval(()=>{
					totalAlarmInfo().then()
				},60000)
				return () => clearInterval(timerAlarm)
			}
		}
	},[startDate, endDate, selectedCompany.companyCode])


	const [currentTime, setCurrentTime] = useState(new Date());

	useEffect(() => {
		const timerID = setInterval(() => {
			setCurrentTime(new Date());
		}, 1000);
		return () => {
			clearInterval(timerID);
		};
	}, []);

	const getElapsedTime = (targetTime:any) => {
		const elapsedTime = currentTime.getTime() - targetTime.getTime();
		return Math.max(0, Math.floor(elapsedTime / 1000));
	};

	const timeCounter = (pastTime:any) =>{
		const targetTime = new Date(pastTime);
		const elapsedTime = getElapsedTime(targetTime);
		const hours = Math.floor(elapsedTime / 3600);
		const minutes = Math.floor((elapsedTime % 3600) / 60);
		if(hours === 0 && minutes !== 0){
			return minutes + '분 전'
		}else if(hours >= 24){
			return pastTime
		}else if(hours === 0 && minutes === 0){
			return '방금 전'
		}else{
			return hours  + '시간 전'
		}
	}

	return (

		<SectionFrame>
			<div style={{display:'flex',justifyContent:'space-between',alignItems:'baseline'}}>
				<MenuTitle title={'업체/기본정보 관리 > 업체별 알림'} unitCase={'MenuTitle'} />
				<div style={{display: 'flex',alignItems:'center'}}>
					<div style={{display: 'flex',marginRight:dateSearch ? "20px" : '0'}}>
						<SelectEventButton height={'34px'} buttonName={'실시간'}
															 buttonEvent={() => {setDateSearch( false ); setSelectIdx(-1); resetStartDate(); resetEndDate();}}
															 buttonStatus={!dateSearch} buttonBorder={true}/>
						<SelectEventButton height={'34px'} buttonName={'날짜검색'} buttonEvent={() => setDateSearch( true )}
															 buttonStatus={dateSearch} buttonBorder={true}/>
					</div>
					{dateSearch &&
						<CalendarRangePicker receiveDate={(v)=>
						{setStartDate(v.start); setEndDate(v.end)}} calendarName={'날짜검색'} disabled={!selectedCompany.companyCode}
																 currentSelectDate={{start:startDate, end:endDate}}/>
					}
				</div>
			</div>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<CompanySearchList companyDataReceive={(data)=>{setSelectedCompany(data); setSelectIdx(-1)}}/>
				<ContentFrame width={'840px'} height={'680px'} justify={'space-between'}>
					<div>
						<div style={{display:'flex',justifyContent:'space-between'}}>
							<MenuTitle title={selectedCompany.companyCode ? t('알림') + `(${selectedCompany.companyName})` : t('알림')} unitCase={'MenuCompTitle'}/>
							<div style={{display:'flex',alignItems:'center'}}>
								<MainButtons name={'필터 적용'} clickEvent={totalAlarmInfo} disabled={checkedList.length === 0}/>
								<InnerBox onClick={()=>{setCheckBoxDrop(!checkBoxDrop)}}>
									<ArrowButton arrowDirection={!checkBoxDrop ? 'bottom' :'top'}/>
								</InnerBox>
							</div>
						</div>
						<div style={{marginTop:'16px',pointerEvents:`${noticeData.length === 0 ? 'none' : 'auto'}`}}>
							<CheckBox isOpened={checkBoxDrop}>
								<div className={'category_title'}>{t('솔루션')}</div>
								<div className={'category_list'}>
									{solutionData.map((v:any)=>(
										<div style={{minWidth:'96px'}}>
											<label style={{display:'flex',opacity:`${v === 'F-EMS' ? 1 : v === 'P-BAS' ? 1: 0.3}`,pointerEvents:`${v === 'F-EMS' ? 'auto' : v === 'P-BAS' ? 'auto': 'none'}`
												,alignItems:'center',gap:'8px'}}>
												{/*@ts-ignore*/}
												{/*<input type="checkbox" defaultCheck`e`d={v === 'F-EMS'}/>*/}
												<input type="checkbox" value={v} checked={checkedList.includes(v)}
															 onChange={(event)=>onCheckedType(event.target.checked,event.target.value)}/>
												<div>{v}</div>
											</label>
										</div>
									))}
								</div>
							</CheckBox>
						</div>

						<GridTableComponent id={'grid-scroll'}
																titleCheckDisabled={true} height={510}
																scrollWidth={980} marginCus={0}
																tempCol={'48px 128px 128px 88px 144px 128px 1fr'}>
							<div className={'grid-title-unchecked'}>
								{['솔루션','제조사','설비명','ICT번호','알림','소모품 종류','알림일시'].map((name)=>
																																				<TableValue title={t(name)} requiredValue={[]} key={name}/>
								)}
							</div>
							{!selectedCompany.companyCode ?
								<NoData>{t('* 업체를 선택해 주세요.')}</NoData>
								:
								noticeData.length !== 0 ?
									noticeData?.map((v:any,i:number)=>(
										<GridTableSelect  className={'grid-list-unchecked-no_icon'}
																			selected={i === selectIdx} onDoubleClick={()=>{window.location.href = `/admin/fems/alarm`}}
																			onClick={()=>{setSelectIdx(i)}}>
											{validationValue(v.solution)}
											{validationValue(v.maker)}
											{validationValue(v.facilityName)}
											{validationValue(v.ictNumber)}
											{validationValue(v.message)}
											{validationValue(v.alarmState === 'check' && '에러')}
											{validationValue(timeCounter(v.alarmTime))}
										</GridTableSelect>
									))
									:
									<NoData>{t('* 해당 업체에 알림정보가 없습니다.')}</NoData>
							}
						</GridTableComponent>
					</div>
				</ContentFrame>
			</div>
		</SectionFrame>
	);
};

export default CustomerNotice;

const CheckBox = styled.div<{isOpened?:boolean}>`
   display: flex;
   width: 778px;
   border-right: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
   border-left: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
   border-top: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
   border-bottom: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};

   user-select: none;
   transition: all 400ms;

  .category_title{
      min-width: 96px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      transition: all 400ms;
      //line-height: 48px;
      height: ${p=>p.isOpened ? 0 :'48px'};
      //min-height: 48px;
      opacity: ${p=>p.isOpened ? 0 : 1};
      overflow: ${p=>p.isOpened? 'hidden' : 'visible'};
      box-sizing: border-box;
      border-right: 1px solid ${p=>p.isOpened ? 'transparent' : '#E6E9F4'};
  }
  .category_list{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      transition: all 400ms;
      white-space: pre-wrap;
      //line-height: 48px;
      //min-height: 48px;
      height: ${p=>p.isOpened ? 0 :'48px'};
      opacity: ${p=>p.isOpened ? 0 : 1};
      overflow: ${p=>p.isOpened? 'hidden' : 'visible'};
      font-size: 12px;
      font-weight: 300;
      color: #7E84A3;
      padding-left: 16px;
      box-sizing: border-box;
      
    input[type='checkbox'] {
      appearance: none;
      cursor: pointer;
      width: 16px;
      height: 16px;
      border: 1px solid #7E84A3;
    }

    input[type='checkbox']:checked {
      background-color: #0058FF;
      background-image: url(${checked});
      background-position: center;
      background-repeat: no-repeat;
      border: none;
    }
  }
`

const InnerBox = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #D7DBEC;
  cursor: pointer;
  &:hover{
    background-color: #F5F6FA;
  }
`