import React , {useState} from 'react';
import styled from 'styled-components';
import {ContentFrame , SectionFrame , validationValue} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  authValid ,
  buttonAuthValid , dataAccessLoading ,
  dataValid ,
  initModal , loadingRemove ,
  phoneNumberFormat
} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState} from "recoil";
import {persistPartnersData} from "../../../../../common/StateManage";
import PartnerSelectModal from "../../../../../components/modal/PartnerSelectModal";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {
  emailReg ,
  IdDuplicate ,
  onIdCheckValid ,
  onPwCheckValid ,
  PasswordCheck ,
  passwordReg
} from "../../../../../ValidationCheck";
import RegisterInput from "../../../../../components/input/RegisterInput";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import PartnerUserMultipleReg from "./PartnerUserMultipleReg";
import ToastPopup from "../../../../../components/modal/ToastPopup";

const PartnerUserRegistration = () => {


  const [selectedPartnerData, setSelectedPartnerData] = useRecoilState(persistPartnersData)
  const [multipleReg,setMultipleReg] = useState(false);
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [authoritiesData,setAuthoritiesData] = React.useState<any>([]);
  const [userRegister,setUserRegister] = React.useState<any>({
                                                               companyCode:selectedPartnerData?.companyCode,
                                                               userName:'',
                                                               userPosition:'',
                                                               employeeNumber:'',
                                                               phone_number:'',
                                                               authorityName:'',
                                                               authorityCode:'',
                                                               id:'',
                                                               password:'',
                                                               passwordCheck:'',
                                                               email:'',
                                                               notice:''});
  const [profileImg,setProfileImg] = React.useState<string[]>([]);
  const [password, setPassword] = React.useState({text:'*비밀번호 확인을 해주세요.' , color:''});
  const [idCheck, setIdCheck] = React.useState({text:'*ID 중복확인을 해주세요.', color:''});
  const [idCheckNormal, setIdCheckNormal] = React.useState<boolean>(false);
  const [pwCheckNormal, setPwCheckNormal] = React.useState<boolean>(false);


  const onUserChange = (e: { target: { name: string; value: string } }, trim?: boolean) => {
    const {name, value} = e.target
    setUserRegister({
                      ...userRegister,
                      [name]: trim? value.trim() : value
                    })
  }

  const onCheckId = (e: { target:{name: string, value:string }}) =>{
    const {name,value} = e.target;
    setUserRegister({
                      ...userRegister,
                      [name]:value.trim()
                    })
    onIdCheckValid(value,setIdCheck)
  }

  const onCheckPassword = (e: { target: { name: string; value: string } }) => {
    const {name, value} = e.target
    setUserRegister({
                      ...userRegister,
                      [name]: value.trim()
                    })
    onPwCheckValid(value,setPassword)
  }

  const onIdCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) =>{
    if(e.key === 'Enter'){
      IdDuplicate(userRegister,setIdCheck,setIdCheckNormal).then()
    }
  }
  const onPwCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) =>{
    if(e.key === 'Enter'){
      PasswordCheck(userRegister,setPassword,setPwCheckNormal);
    }
  }



  const authoritiesListRes = async () => {
    try {
      const response = await client.get(`/authorities/list/${selectedPartnerData.companyCode}`,{
        headers: {Authorization}
      })
      const data = response.data.data
      setAuthoritiesData(data.row)
    }catch (e) {
      console.log(e)
    }
  }

  const partnerRegisterRes = async () =>{
    if(!userRegister.userName){
      setDataModal({menu:'confirm', message:'성명을 입력해 주세요.'})
    }
      // else if(!userRegister.authorityName){
      //   setDataModal({menu:'confirm', message:'권한을 설정해주세요.'})
    // }
    else if(!idCheckNormal || idCheck.text.includes('중복확인을')){
      setDataModal({menu:'confirm', message:'아이디 중복확인을 해주세요.'})
    }
    else if(!userRegister.password){
      setDataModal({menu:'confirm', message:' 비밀번호를 입력해 주세요.'})
    }
    else if(!passwordReg.test(userRegister.password)){
      setDataModal({menu:'confirm', message:'비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
    }
    else if(!userRegister.passwordCheck){
      setDataModal({menu:'confirm', message:' 비밀번호를 한번 더 입력해 주세요.'})
    }
    else if(!pwCheckNormal){
      setDataModal({menu:'confirm', message:' 비밀번호가 일치한 지 확인 해주세요.'})
    }
    else if(userRegister.password !== userRegister.passwordCheck){
      setDataModal({menu:'confirm', message:' 비밀번호가 일치하지 않습니다.'})
    }
    else if(!userRegister.email){
      setDataModal({menu:'confirm', message:'이메일을 입력해 주세요.'})
    }
    else if(!emailReg.test(userRegister.email)){
      setDataModal({menu:'confirm', message:'이메일 형식이 맞지 않습니다.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/system/user/signup',[{
          ...userRegister,
          profileImage:!profileImg ? null : profileImg[0],
          authority: selectedPartnerData.authority === 'admin' ? 'adminUser' : 'affiliateUser'
        }],{headers: {Authorization}})
        if (response.data.status !== 200) {
          setDataModal({menu:'confirm',message:response.data.message});
          loadingRemove()
        } else {
          setTimeout(()=>{
            setDataModal({menu:'success',message:'저장 되었습니다.'})
            loadingRemove()
          },500)
        }
      }catch ( e:any ) {
        console.log(e)
        setDataModal({menu:'error',message:e.message})
        loadingRemove()
      }
    }
  }


  React.useEffect(()=>{
    authoritiesListRes().then()
  },[selectedPartnerData.companyCode])


  return (
    <SectionFrame>

      {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} closeEvent={() => {setDataModal( initModal ); window.location.href = `/admin/fas/partners/user`}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={dataModal.message } state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message } state={'warn'} timeSet={1000} closeEvent={() => {setDataModal( initModal )}}/> : null}


      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = '/admin/fas/partners/user'}}
          />
      }

      {/*{dataModal.menu === 'cancel' &&*/}
      {/*    <SelectConfirmModal statusText={dataModal.message}*/}
      {/*                        noticeText={' * "예" 선택시 저장없이 이동합니다.'}*/}
      {/*                        cancelEvent={()=>{setDataModal(initModal)}}*/}
      {/*                        viewType={true}*/}
      {/*                        clickEvent={()=>{ window.location.href = '/admin/fas/partners/user'}}*/}
      {/*    />*/}
      {/*}*/}
      {/*{dataModal.menu === 'searchPartner' &&*/}
      {/*    <PartnerSelectModal receivedCompany={(data)=>{setSelectedPartnerData(data)}} closeButton={()=>{setDataModal(initModal)}}/>*/}
      {/*}*/}

      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
        <MenuTitle title={'지사/협력사 관리 > 지사/협력사 유저관리 > 등록'+(multipleReg?'(다중)':'')}
                   unitCase={'MenuTitle'}/>
        {multipleReg?
          <div style={{display:'flex'}}>
            <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                         clickEvent={()=>{setDataModal({menu:'cancel',message:'목록으로 이동 하시겠습니까?'})}} />
            <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                         clickEvent={()=>setMultipleReg(false)} />
          </div>
          :
          <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                       clickEvent={()=>setMultipleReg(true)} />
        }
      </div>
      {multipleReg ?
        <ContentFrame>
          <PartnerUserMultipleReg/>
        </ContentFrame>
        :
        <ContentFrame flexDirection={'row'} justify={'flex-start'}>
          <ViewBox>
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'8px'}}>
              <MenuTitle title={'지사/협력사 정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display:'flex',alignItems:'center'}}>
                {/*<MainButtons name={'지사/협력사 선택'} width={'120px'} marginDis={true}*/}
                {/*             clickEvent={()=>{setDataModal({menu:'searchPartner'});}}/>*/}
                <MainButtons name={'취소'} width={'80px'} marginDis={true}
                             clickEvent={()=>{
                               setDataModal( {menu: 'cancel' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )
                             }} />
                <MainButtons name={'저장'} width={'80px'} buttonType={'cta'} clickEvent={partnerRegisterRes} marginDis={true}/>
              </div>
            </div>
            <div className={'scroll_func'}>

              <div style={{borderTop:'1px solid #E6E9F4'}}>
                <div className={'info'} style={{display: 'grid', gridTemplateColumns: '168px 224px 168px 224px'}}>
                  <div className={'reg-title'}>{'업체명'}</div>
                  <div className={'reg-content'} style={{display: 'flex'}}>
                    {validationValue(selectedPartnerData?.companyName)}
                  </div><div className={'reg-title'}>{'업체코드'}</div>
                  <div className={'reg-content'} style={{display: 'flex'}}>
                    {validationValue(selectedPartnerData?.companyCode)}
                  </div>
                  <div className={'reg-title'}>{'대표'}</div>
                  <div className={'reg-content'} style={{display: 'flex'}}>
                    {validationValue(selectedPartnerData?.president)}
                  </div>

                  <div className={'reg-title'}>{'-'}</div>
                  <div className={'reg-content'} style={{display: 'flex'}}>
                    {validationValue('')}
                  </div>
                </div>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 0'}}>
                <MenuTitle title={'유저 정보'} unitCase={'MenuCompTitle'}/>
              </div>
              <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
                <RegisterComponent type={'text'} name={'userName'} value={userRegister.userName} paddingRight={true} required={true}
                                   title={'성명'} onChange={onUserChange}/>
                <RegisterComponent type={'text'} name={'userPosition'} value={userRegister.userPosition} paddingRight={true}
                                   title={'직책'} onChange={onUserChange}/>
                <RegisterComponent type={'text'} name={'employeeNumber'} value={userRegister.employeeNumber} paddingRight={true}
                                   title={'사원번호'} onChange={onUserChange}/>


                <RegisterComponent type={'drop'} title={'권한'} dropWidth={'192px'}
                                   dropClickEvent={(v)=>{
                                     setUserRegister((prev:any)=>({...prev, authorityName: v, authorityCode:authoritiesData.find(( item: { authoritiesName: any; }) => item.authoritiesName === v)?.authoritiesCode}))
                                   }}
                                   name={'authorityName'} value={userRegister.authorityName} onChange={onUserChange} dropAddNoneSelect={false}
                                   dropArray={authoritiesData.map((v:any,index:any)=>(v.authoritiesName))}/>
              </div>

              <RegisterComponent type={'phone'} value={userRegister.phone_number} error={false} title={'전화번호'}
                                 onChange={onUserChange} name={'phone_number'} required={true}
                                 numberFormat={dataValid(userRegister.phone_number,'')}/>

              <RegisterComponent type={'text'} value={userRegister.email} required={true} title={'이메일'} placeholder={'이메일을 입력해 주세요.'}
                                 onChange={onUserChange} name={'email'} numberFormat={phoneNumberFormat(userRegister.email)} bottomBorder={true}/>
              <div style={{display:'flex'}}>
                <div className={'indexBox'} style={{height:'72px'}}>
                  {'ID'}
                  <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>
                </div>
                <div className={'inputWrap'} style={{height:'72px'}}>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <InputComponent type="text" name={'id'} value={userRegister.id} onChange={onCheckId} maxLength={30}
                                    width={'520px'} onKeyDown={onIdCheckEnter}/>
                    <MainButtons buttonType={'cta'} name={'중복확인'} marginDis={true}
                                 clickEvent={()=>{IdDuplicate( userRegister , setIdCheck , setIdCheckNormal ).then()}} width={'72px'} />
                  </div>
                  <div className={'formText'} style={{color: idCheck.color}}>{idCheck.text}</div>
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={'indexBox'} style={{height:'72px'}}>
                  {'PW'}
                  <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>
                </div>
                <div className={'inputWrap'} style={{height:'72px'}}>
                  <InputComponent type="password" name={'password'} maxLength={30} value={userRegister.password} onChange={onCheckPassword}/>
                  <div className={'formText'}>{'*8글자 이상 영문,숫자(특수문자 혼용가능)를 사용해주세요.'}</div>
                </div>
              </div>
              <div style={{display:'flex'}}>
                <div className={'indexBox'} >
                  {'PW 확인'}
                  <div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>
                </div>
                <div className={'inputWrap'} style={{height:'72px'}}>
                  <div style={{display:'flex',alignItems:'baseline'}}>

                    <InputComponent type="password" name={'passwordCheck'} maxLength={30}
                                    value={userRegister.passwordCheck}
                                    onChange={(e)=>onUserChange(e, true)} width={'520px'} onKeyPress={onPwCheckEnter}/>
                    <MainButtons buttonType={'cta'} name={'확인'} marginDis={true}
                                 clickEvent={()=>{PasswordCheck(userRegister, setPassword, setPwCheckNormal)}} width={'72px'} margin={'0 0 0 8px'}/>
                  </div>
                  <div className={'formText'} style={{color: password.color}}>{password.text}</div>
                </div>
              </div>
              <div style={{display:'grid',gridTemplateColumns:'168px 1fr'}}>
                <div className={'info-title'} style={{height: '88px'}}>{'비고'}</div>
                <div className={'info-content'} style={{height: '88px'}}>
                  <RegisterInput type={'textarea'} name={'notice'} value={userRegister.notice}
                                 maxHeight={'72px'} limit={1000}
                                 onChange={onUserChange} placeholder={'내용을 입력해주세요.'}/>
                </div>
              </div>
            </div>

          </ViewBox>
          <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
          <div style={{width: '377px'}}>
            <div style={{height:'34px',display:'flex',alignItems:'center',justifyContent:'space-between', marginBottom:'20px'}}>
              <MenuTitle title={'프로필 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
                <div>{'*jpg, png 10mb 이하의 파일 등록 가능'}</div>
              </div>
            </div>
            <div>
              <TotalImageUploadBox imageKeyArray={(data)=>setProfileImg(data)} inputSize={168} imageLength={1} profileSelect={false}/>
            </div>
          </div>
        </ContentFrame>
      }
    </SectionFrame>
  );
};
export default PartnerUserRegistration;

const ViewBox = styled.div<{ disabled?:boolean }>`
  width: 784px;
  height: 584px;


  .scroll_func{
    width: 810px;
    height: 584px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: grid;
    }
  }

  .formText{
    font-size: 12px;
    color: rgba(23,23,37,0.5);
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    margin-left: 8px;

  }

  .indexBox{
    width: 168px;
    line-height: 30px;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 800;
    border-bottom: 1px solid #e7e9ee;
  }
  .inputWrap{
    width: 616px;
    border-bottom: 1px solid #e7e9ee;
    box-sizing: border-box;
    padding: 8px 0 8px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .input_tel{
    width: 600px;
    height: 32px;
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    font-size: 14px;
    color: #171725;
    font-weight: normal;
    padding: 0 8px;
    font-family: 'NanumGothicOTF', sans-serif;
    &::placeholder{
      font-size: 14px;
      font-weight: 400;
      color: rgba(23,23,37,0.3);
      font-family: 'NanumGothicOTF', sans-serif;
    }
    &:focus{
      border: 1px solid #0058ff;
    }
  }

  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }

`

const InputComponent = styled.input<{   height?: string,width?: string}>`
	width: ${props => props.width ? props.width : '600px'};
	height: ${props => props.height ? props.height : '32px'};
    
	padding: 8px;
	border: 1px solid #D7DBEC;
	border-radius: 4px;
	outline: none;
	box-sizing: border-box;
	font-size: 14px;
	color: #171725;
	font-weight: normal;
	font-family: 'NanumGothicOTF', sans-serif;
	
  &:focus{
    border: 1px solid #0058ff;
  }
	
		&::placeholder{
			font-size: 14px;
			font-weight: normal;
			color: rgba(23,23,37,0.3);
			font-family: 'NanumGothicOTF', sans-serif;
		}
	`

