import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import PartnerUserManagement from "../../../containers/admin/fas/partner/user/PartnerUserManagement";
import {useLocation} from "react-router-dom";
import PartnerUserDetail from "../../../containers/admin/fas/partner/user/PartnerUserDetail";
import PartnerUserModify from "../../../containers/admin/fas/partner/user/PartnerUserModify";
import PartnerUserRegistration from "../../../containers/admin/fas/partner/user/PartnerUserRegistration";

const AdminFasPartnerUser = () => {
  const location = useLocation();
  const modify = location.pathname.includes('modify')
  const detail = location.pathname.includes('detail')
  const register = location.pathname.includes('register')

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {detail ?
        <SectionContainer contentContainer={<PartnerUserDetail/>}/>
        :
        modify ?
          <SectionContainer contentContainer={<PartnerUserModify/>}/>
          :
          register?
            <SectionContainer contentContainer={<PartnerUserRegistration/>}/>
          :
        <SectionContainer contentContainer={<PartnerUserManagement/>}/>
      }
    </PageContainer>
  );
};
export default AdminFasPartnerUser;