import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
  GridTableComponent , GridTableSelect , listResultMessage ,
  orderComponent ,
  validationValue
} from "../../style/StyleComponent";
import {PeripheralType} from "../../types/Fundamental";
import {dataValid , loadingRemove , modalListLoading , numberLocaleString} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import MainButtons from "../button/MainButtons";
import TableValue from "../../style/TableValue";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBar from "../input/SearchBar";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

interface PeripheralProps {
  companyCode: string
  viewType?: boolean
  cancelEvent: ()=>void
  selectFacility: any
  selectedPeripheral?: string[]
  receivedPeripheral:(data:any[])=>void
}

const PeripheralRegisterModal:React.FunctionComponent<PeripheralProps> = ({companyCode, selectedPeripheral, receivedPeripheral,
                                                                            selectFacility, cancelEvent, viewType}) => {
  const [page, setPage] = React.useState<number>(1)
  const [totalPage, setTotalPage] = React.useState<number>(1)
  const [peripheralListData, setPeripheralListData] = React.useState<Readonly<PeripheralType[]>>([])
  const [selectPeripheralData, setSelectPeripheralData] = React.useState<string[]>(JSON.parse(JSON.stringify(selectedPeripheral))??[]);
  const [checkedList, setCheckedList] = React.useState<string[]>(JSON.parse(JSON.stringify(selectedPeripheral))??[])
  const [searchInput, setSearchInput] = React.useState<{input: string, value:string}>({input:'', value:''})
  const {t}:any = useTranslation()
  const location = useLocation()
  const authValid = location.pathname.includes('/admin')
  const onSelectedList = (select:boolean, value:any) =>{
    if(select){
      setSelectPeripheralData([...selectPeripheralData, value.peripheralCode])
      setCheckedList((prev)=>([...prev, value.peripheralCode]))
    }else{
      setSelectPeripheralData(selectPeripheralData.filter(el=>el !== value.peripheralCode));
      setCheckedList(checkedList.filter(el => el !== value.peripheralCode))
    }
  }

  const peripheralListRes = async () => {
    try {
    modalListLoading()
      const response = await client.get(`/peripheral/list/${page}/${10}/${companyCode}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setPeripheralListData((prev:any)=> prev.concat(data.row))
      }else{
        setPeripheralListData(data.row)
      }
      viewType && setPage(page+1)
      loadingRemove()
    }catch (e) {
      loadingRemove()
      console.log(e)
    }
  }

  React.useEffect(()=>{
    if(viewType){
      if(totalPage >= page){
        !searchInput.value?
        peripheralListRes().then() : peripheralListSearchRes().then()
      }
    }else{
      !searchInput.value?
        peripheralListRes().then() : peripheralListSearchRes().then()
    }
  },[page, searchInput.value])

  const peripheralListSearchRes = async () => {
    try {
      modalListLoading()
      const response = await client.post(`/peripheral/search`,{
        page:page,
        size:10,
        companyCode:companyCode,
        keyword:searchInput.value
      },{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setPeripheralListData((prev:any)=> prev.concat(data.row))
      }else{
        setPeripheralListData(data.row)
      }
      viewType && setPage(page+1)
      loadingRemove()
    }catch (e) {
      loadingRemove()
      console.log(e)
    }
  }
  console.log(searchInput)

  return (
    <React.Fragment>
      <ModalBase modalContent={
          <BaseComponent>
            <div className={'modal_title'} style={{backgroundColor:authValid? '#CFFA61' : '#0058FF'}}>
              <div>{viewType?t('주변장치 보기') : t('주변장치 등록')}</div>
              {!viewType && <div style={{color:authValid?  '#171725' : '#fff'}}>{t('(해당 기계와 연결되어 사용하는 주변장치를 등록해주세요.)')}</div>}
            </div>
            <div className={'modal_content'}>
              <ModalInfoTable>
                {orderComponent(t('기종'),selectFacility.type)}
                {orderComponent(t('제조번호'), selectFacility.manufacturingNumber)}
                {orderComponent(t('제조사'), selectFacility.maker)}
                {orderComponent(t('기계명'), selectFacility.facilityName)}
                {orderComponent(t('ICT번호'), selectFacility.ictNumber)}
                {orderComponent('HW', selectFacility.hw)}
                {orderComponent(t('버전'), selectFacility.version)}
                <React.Fragment>
                  <div className={'order_title'}>{t('최대톤수')}</div>
                  <div style={{display:'flex',justifyContent:'space-between'}} className={'order_content'}>
                    {numberLocaleString(selectFacility.maxTon)}
                    <div style={{color:'rgba(23,23,21,0.5)', fontSize:'12px',paddingRight:'8px'}}>{'ton'}</div>
                  </div>
                </React.Fragment>
                {orderComponent(t('오버홀'), selectFacility.overhaul)}
                {orderComponent('-', '-')}
                {orderComponent('-', '-')}
                {orderComponent('-', '-')}
              </ModalInfoTable>
              <div style={{display:'flex', justifyContent:'space-between',marginTop:'16px'}}>
                <div style={{display:'flex', gap:'8px', alignItems:'center'}}>
                  <div style={{fontSize:'18px', fontWeight:800}}>{t('주변장치 리스트')}</div>
                  {!viewType &&
                      <div style={{display:'flex', gap:'4px', fontSize:'14px', fontWeight:800, alignItems:'center'}}>
                        {t('(선택된 주변장치 수:')}
                        <div style={{color:'#0058ff', textDecoration:'underline', textUnderlineOffset:'3px'}}>{selectPeripheralData.length}</div>
                        {t('개)')}
                      </div>
                  }
                </div>
                <div style={{display:'flex'}}>
                  <SearchBar
                    clickEvent={()=> {setSearchInput((prev) => ({...prev , value: prev.input})); setPage(1)}}
                    keyFunction={(e)=> setSearchInput((prev)=>({...prev, input: e.target.value.trimStart()}))}
                  />
                  {/*{!viewType && <MainButtons name={'선택 초기화'} marginDis={true}*/}
                  {/*                           clickEvent={()=> {*/}
                  {/*                             setSelectPeripheralData([])*/}
                  {/*                             setCheckedList([])}}/>}*/}
                </div>
              </div>
              <InfiniteScroll scrollableTarget={'grid-scroll'}
                              next={()=>setPage((prev)=> prev+1)}
                              hasMore={totalPage > page}
                              dataLength={dataValid(peripheralListData?.length,0)}
                              loader={<div/>}>
                <GridTableComponent tempCol={'40px repeat(7, 120px) 104px'} scrollWidth={1128}
                                    height={250} id={'grid-scroll'}>
                  <div className={'grid-title-unchecked'}>
                    {['NO.','장치 종류','장치 제조번호','장치 제조사','장치 이름','제조 연월일','담당자','공장명','공장 세분화명'].map((v,i)=>(
                      <TableValue title={v} key={i} requiredValue={['']}/>
                    ))}
                  </div>
                  {
                    viewType?
                      //@ts-ignore
                      peripheralListData.filter((v)=>(selectedPeripheral?.includes(v.peripheralCode))).length === 0?
                        listResultMessage(true, t('* 등록된 정보가 없습니다.'),{width:1000, admin:200, user:250})
                        :
                        // 주변장치 보기
                        //@ts-ignore
                        peripheralListData.filter((v)=>(selectedPeripheral?.includes(v.peripheralCode))).
                                           map((value,index)=>(
                                             <GridTableSelect selected={false}
                                                        className={'grid-list-unchecked-no_icon'} key={value.peripheralCode}>
                                               <div>{index+1}</div>
                                               {validationValue(value.peripheralType)}
                                               {validationValue(value.peripheralManufacturer)}
                                               {validationValue(value.peripheralCode)}
                                               {validationValue(value.peripheralName)}
                                               {validationValue(value.manufactureDate)}
                                               {validationValue(value.managerName)}
                                               {validationValue(value.factoryName)}
                                               {validationValue(value.factorySubDivision)}
                                             </GridTableSelect>
                                           ))
                      :
                      //주변장치 선택(등록)
                      peripheralListData.length === 0?
                        listResultMessage(true, t('* 등록된 정보가 없습니다.'),{width:1000, admin:193, user:250})
                        :
                        peripheralListData.map((value,index)=>(
                          //@ts-ignore
                          <GridTableSelect selected={selectPeripheralData.map((v)=>(v)).includes(value.peripheralCode)}
                            //@ts-ignore
                                     onClick={()=>{onSelectedList(!selectPeripheralData.map((v)=>(v)).includes(value.peripheralCode), value)}}
                                           className={'grid-list-unchecked-no_icon'} key={value.peripheralCode}>
                            <div>{index+1}</div>
                            {validationValue(value.peripheralType)}
                            {validationValue(value.peripheralManufacturer)}
                            {validationValue(value.peripheralCode)}
                            {validationValue(value.peripheralName)}
                            {validationValue(value.manufactureDate)}
                            {validationValue(value.managerName)}
                            {validationValue(value.factoryName)}
                            {validationValue(value.factorySubDivision)}
                          </GridTableSelect>
                        ))
                  }
                </GridTableComponent>
              </InfiniteScroll>
              <div style={{display:'flex',justifyContent:'center',marginTop:'5px'}}>
                {!viewType?
                  <React.Fragment>
                    <MainButtons name={'취소'} width={'144px'} clickEvent={cancelEvent}/>
                    <MainButtons name={'선택'} width={'144px'} clickEvent={()=> {
                      receivedPeripheral(checkedList);cancelEvent()}} buttonType={'popupCta'}/>
                  </React.Fragment>
                  :
                  <MainButtons name={'확인'} width={'144px'} buttonType={'popupCta'} clickEvent={cancelEvent}/>}
              </div>
            </div>
          </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default PeripheralRegisterModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 536px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;

  .modal_title {
    display: flex;
    gap: 8px;
    background-color: #CFFA61;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    &>div:nth-child(1) {
      font-size: 18px;
      font-weight: 800;
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      color: rgba(23, 23, 37, 0.5);
    }
  }
  
  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
  }
`

const ModalInfoTable =  styled.div`
    border-bottom: 1px solid #E6E9F4;
    display: grid;
    grid-template-columns: repeat(4, 104px 146px);
    align-items: center;
    box-sizing: border-box;
    .order{
        &_title{
            height: 32px;
            background-color: #F5F6FA;
            display: flex;
            align-items: center;
            padding-left: 16px;
            font-weight: bold;
            font-size: 12px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
        }
        &_content{
            font-size: 14px;
            font-weight: normal;
            color: #171725;
            display: flex;
            align-items: center;
            height: 32px;
            padding-left: 16px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
            white-space: pre;
        }
    }
`

