import React , {useEffect , useState} from 'react';
import {ContentFrame , helperModule , SectionFrame , validationValue} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import MainButtons from "../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../types/Fundamental";
import {
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
  uploadingLoading
} from "../../../../function/function";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import dayjs from "dayjs";
import SingleDrawingUploadBox from "../../../../components/input/SingleDrawingUploadBox";
import ListInnerInput from "../../../../components/input/ListInnerInput";
import RegisterInput from "../../../../components/input/RegisterInput";
import TotalImageUploadBox from "../../../../components/input/TotalImageUploadBox";
import MobileButton from "../../../../components/mobile/button/MobileButton";
import BusinessVisitHistoryModal from "../../../../components/modal/BusinessVisitHistoryModal";
import {useRecoilState} from "recoil";
import {persistCompanyData} from "../../../../common/StateManage";
import CompanySelectModal from "../../../../components/modal/CompanySelectModal";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import ToastPopup from "../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import AttachMultipleUploadBox from "../../../../components/input/AttachMultipleUploadBox";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import WorkSelectModal from "../../../../components/modal/WorkSelectModal";

const BusinessRegistration = () => {
  const {t}:any = useTranslation()
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [selectedCompany,setSelectedCompany] = useRecoilState(persistCompanyData)
  const [imageKeyState, setImageKeyState] = React.useState<string[]>([]);
  const [fileKey,setFileKey] = React.useState<any[]>([]);
  const [inputState , setInputState] = useState<any>( {
                                                        visitDate: dayjs().format( 'YYYY-MM-DD' ) ,
                                                        title: '' ,
                                                        contents: '',
                                                      } )
  const [receiveWorker,setReceiveWorker] = React.useState<any[]>([])


  const changeValue = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement> , trim?: boolean) =>{
    const {name, value} = e.target;
    setInputState({
                    ...inputState,
                    [name] : trim? value.trim() : value
                  })
  }
  const businessRegRes = async () =>{
    if(!selectedCompany.companyCode){
      setDataModal({menu:'confirm',message:'업체를 선택해주세요.'})
    }else if(!inputState.title){
      setDataModal({menu:'confirm',message:'제목을 입력해주세요.'})
    }else{
      dataAccessLoading()
      try {
        const response = await client.post(`/business/management/register`,{
          ...inputState,
          companyName:selectedCompany.companyName,
          companyCode:selectedCompany.companyCode,
          image:dataValid(imageKeyState,[]),
          file:dataValid(fileKey,[]),
          responsibility:receiveWorker.map((v:any)=>(v.id)),
          // file:fileKey[0],
        },{headers: {Authorization}})
        const data = response.data
        if(data.status !== 200) {
          setDataModal({menu:'confirm', message:t(data.message)})
          loadingRemove()
        }else{
          setTimeout(()=>{
            setDataModal({menu:'success', message:t('저장 되었습니다.')})
            loadingRemove()
          },500)
        }
      }catch (e:any){
        console.log(e)
        setDataModal({menu:'error', message:e.message})
        loadingRemove()
      }
    }
  }

  useEffect(() => {
    const handleBeforeUnload = (event:BeforeUnloadEvent) => {
      if (dataModal.menu === '') {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dataModal.menu]);


  // const [fileName,setFileName] = React.useState<any>({});
  // const inputRef = React.useRef<any>(null)
  // const extendReg = /(.*?)\.(jpg|jpeg|png|doc|docx|hwp|pdf|ppt|psd|txt|xls|xlsx|zip|hip)$/
  // const fileUpload = async (e: React.ChangeEvent<HTMLInputElement>, data: any) => {
  //   uploadingLoading('파일 업로드 중 입니다...');
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('file', data)
  //   formData.append('container', 'zestech')
  //   formData.append('savePath','test\\')
  //   setFileName(data);
  //   try {
  //     const response = await client.post('/azure/file/upload',
  //                                        formData,
  //                                        { headers: { Authorization } });
  //     const responseData = response.data.data;
  //     console.log(responseData)
  //     // setFileKeyState(responseData.imgKey);
  //     // setFileUrlState(responseData.imgUrl);
  //   } catch (e) {
  //     console.log(e);
  //   }
  //   loadingRemove();
  // };
  // const [fileList,setFileList] = React.useState<any[]>([]);
  // const containerListRes = async () =>{
  //   try {
  //     const response = await client.post(`/azure/file/list`,
  //                                        {container:'zestech'},
  //                                        {headers:{Authorization}})
  //     const data = response.data.data
  //     setFileList(data)
  //     console.log(response)
  //   }catch ( e:any ) {
  //     console.log(e)
  //   }
  // }
  // React.useEffect(()=>{
  //   containerListRes().then()
  // },[])
  //
  // const containerFileDownRes = async (fileName:string) =>{
  //   try {
  //     const response = await client.post(`/azure/file/download`,{
  //       container: "zestech",
  //       filePath: fileName
  //     },{
  //       headers:{Authorization}
  //     })
  //     const data = response.data
  //     const blob = new Blob([data], {type:'application/octet-stream'})
  //     const url = window.URL.createObjectURL(blob)
  //     const link = document.createElement('a')
  //     link.href = url
  //     link.setAttribute('download', `${fileName}`)
  //     document.body.appendChild(link)
  //     link.click()
  //     link.parentNode?.removeChild(link)
  //     console.log(response)
  //   }catch ( e ) {
  //     console.log(e)
  //   }
  // }
  return (
    <React.Fragment>
      <SectionFrame>
        {dataModal.menu === 'visitHistory' &&
            <BusinessVisitHistoryModal closeEvent={()=>{setDataModal(initModal)}} companyData={selectedCompany}/>
        }

        {dataModal.menu === 'cancel' &&
            <SelectConfirmModal statusText={t(dataModal.message)}
                                noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
                                cancelEvent={()=>{setDataModal(initModal)}}
                                viewType={true}
                                clickEvent={()=>{ window.location.href = `/admin/fas/business`; setDataModal(initModal)}}
            />
        }


        {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal); window.location.href = '/admin/fas/business' }}/>
          : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
            : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

        {dataModal.menu  === 'confirm' &&
            <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>}

        {dataModal.menu === 'company' &&
            <CompanySelectModal receivedCompany={setSelectedCompany} idType={true} mida={true} affiliate={true}
                                closeButton={()=>setDataModal(initModal)}/>}

        {dataModal.menu === 'worker' &&
            <WorkSelectModal closeEvent={()=>{setDataModal(initModal)}}
                             setData={receiveWorker}
                             receiveData={(data)=>{setReceiveWorker(data); setDataModal(initModal)}}/>
        }
        <MenuTitle title={t('영업관리 > 등록')} unitCase={'MenuTitle'}/>
        {/*  {fileList.map((value,index)=>(*/}
        {/*    <div style={{cursor:'pointer'}} onClick={()=>{containerFileDownRes(value.replace(/\//g, '\\')).then()}}>{value}</div>*/}
        {/*  ))}*/}
        {/*<form  action="/upload" method="post" encType="multipart/form-data">*/}
        {/*  <input style={{display:'block'}} type="file" ref={inputRef} formEncType={'multipart/form-data'}*/}
        {/*         onChange={(e:any)=>{*/}
        {/*           if(inputRef.current.value.match(extendReg)){*/}
        {/*             if(e.target.files[0].size < 512*1024*1024){*/}
        {/*               fileUpload(e, e.target.files[0]).then()*/}
        {/*             }else{*/}
        {/*               setDataModal({menu:'upload', message: '512MB 이하의 파일을 업로드 해주세요.'})*/}
        {/*               inputRef.current.value = ""*/}
        {/*             }*/}
        {/*           }else{*/}
        {/*             setDataModal({menu:'confirm', message: '잘못된 형식의 파일입니다.'})*/}
        {/*             inputRef.current.value = ""*/}
        {/*             // setUploadState({open: true, status:'upload', message: t('jpg, png 파일을 업로드 해주세요.')})*/}
        {/*           }}}*/}
        {/*  />*/}
        {/*</form>*/}

        <ContentFrame flexDirection={'row'} justify={'flex-start'}>

          <ViewBox disabled={!selectedCompany.companyCode}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
              <MenuTitle title={t('영업관리 등록')} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex',alignItems:'center'}}>
                <div className={'click_text'} onClick={()=>{setDataModal({menu:'visitHistory'})}}>{'방문이력 보기'}</div>
                <MainButtons name={'취소'} width={'56px'}
                             clickEvent={()=>{
                               setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                <MainButtons name={'저장'} clickEvent={businessRegRes} buttonType={'cta'} width={'56px'} marginDis={true}/>
              </div>
            </div>
            <RegisterComponent type={'textButton'} onChange={(e)=>changeValue(e, true)} title={t('업체명')} required={true}
                               onKeyPress={()=>setDataModal({menu:'company'})}
                               buttonName={'검색'} disabled={true}
                               clickEvent={()=>setDataModal({menu:'company'})}
                               value={selectedCompany.companyName} name={'companyName'} buttonWidth={'72px'}/>

            <div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
              <RegisterComponent type={'empty'} title={'작성자'} value={dataValid(selectedCompany.managerName)} name={'managerName'}/>
              <RegisterComponent type={'calendar'} title={t('방문날짜')} left={42} top={-60} maxDate={Date}
                                 onChange={(v)=> setInputState((prev:any)=>({...prev, visitDate: dayjs(v).format('YYYY.MM.DD')}))}
                                 name={'visitDate'} value={inputState.visitDate} selectDate={inputState.visitDate}
                                 required={true} useDeleteButton={true}/>
            </div>
            <RegisterComponent type={'textButton'} onChange={()=>''} title={t('담당자 추가')}
                               onKeyPress={()=>setDataModal({menu:'worker'})}
                               buttonName={'검색'} disabled={true}
                               clickEvent={()=>setDataModal({menu:'worker'})}
                               value={receiveWorker?.map((value,index)=>(value.userName))} name={'responsibility'}
                               buttonWidth={'72px'}/>

            <RegisterComponent type={'text'} limit={60} name={'title'} value={inputState.title} title={t('제목')} required={true}
                               onChange={changeValue} placeholder={t('제목을 입력해주세요.')} width={'610px'}/>

            <AttachMultipleUploadBox fileKeyArray={(data)=>{setFileKey(data)}}
                                     placeholder={'* 업로드 버튼을 클릭하여 파일을 업로드해주세요.'}/>

            <div style={{display:'grid',gridTemplateColumns:'168px 1fr',borderTop:'1px solid rgba(213, 215, 227, 0.5)'}}>
              <div className={'info-title'} style={{height: '308px'}}>{t('내용')}</div>
              <div className={'info-content'} style={{height: '308px'}}>
                <RegisterInput type={'textarea'} name={'contents'} value={inputState.contents}
                               maxHeight={'296px'} limit={4000}
                               onChange={changeValue} placeholder={t('내용을 입력해주세요.')}/>
              </div>
            </div>
          </ViewBox>
          <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
          <div style={{width: '377px'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <MenuTitle title={'이미지 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{color: '#7E84A3', fontSize: '12px', textAlign: 'right', lineHeight: '16px', height: '34px'}}>
                <div>{t('*최대 4개의 이미지 등록 가능')}</div>
                <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
              </div>
            </div>
            <div style={{marginTop:'16px'}}>
              <TotalImageUploadBox imageKeyArray={(data)=>(setImageKeyState(data))}
                                   profileSelect={false}/>
            </div>
          </div>
        </ContentFrame>
      </SectionFrame>

    </React.Fragment>
  );
};
export default BusinessRegistration;

const ViewBox = styled.div<{ disabled?:boolean }>`
  width: 784px;
  height: 584px;
  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }
  
  .click_text{
    color:#7E84A3;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 12px;
    text-decoration: underline 1px #7E84A3;
    opacity: ${p=>p.disabled ? 0.3 :1};
    pointer-events:  ${p=>p.disabled ? 'none' : 'auto'};
    &:hover{
      text-underline-offset: 3px;
    }
  }
`




















