import { DefaultTheme } from 'styled-components';

interface ColorScheme {
  mainColor: string;
  tableTitleColor: string,
  tableSelectHoverColor: string,
  tableHoverColor: string,
  sectionBackground:string,
}

interface ColorsType {
  primary: {
    white: string;
    black: string;
    textBlack: string;
    paleGrey: string;
    grey: string;
    textGrey: string;
    lineGrey: string;
    boxShadow: string;
    checkboxAreaColor: string
    buttonDefault: string
    error: string
  };
  private: ColorScheme
  [key: string]: any; // 인덱스 시그니처 추가
}
export const Colors: ColorsType = {
  primary: {
    white: '#fff',
    black: '#000',
    textBlack: '#171725',
    paleGrey: '#f0f0f0',
    grey: '#B5B5B5',
    textGrey: '#7E84A3',
    lineGrey: '#D7DBEC',
    boxShadow: 'rgba(0, 0, 0, 0.1)',
    checkboxAreaColor: '#F5F6FA',
    buttonDefault: '#5A607F',
    error: '#F0142F',
  },

  private:
    {
      mainColor: '#0058FF',
      tableTitleColor:'#F4F6F9',
      tableSelectHoverColor:'#B',
      tableHoverColor:'#EDF3FF',
      sectionBackground:'#F4F7FC'
    },
};

export const lightTheme: DefaultTheme = {
  background: '#FCFCF4',
  color: '#000000',
};

export const darkTheme: DefaultTheme = {
  background: '#000000',
  color: '#FFFFFF',
};
