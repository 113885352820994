import React, {useState} from 'react';
import styled from "styled-components";
import left from '../../asset/image/chevron-left.svg'

import {useLocation} from "react-router-dom";
import {categoryMenu , userCategory} from "../../common/DataSet";
import ArrowButton from "../button/ArrowButton";
import {useRecoilState , useRecoilValue , useResetRecoilState} from "recoil";
import {
  calendarAfterDatePersist ,
  calendarDatePersist ,
  calendarRangeEndDate , calendarRangeStartDate ,
  companyAuthorities ,
  documentSDMCode ,
  documentSDMList ,
  moldRequestReg ,
  orderSelectReg ,
  persistBlockPage , persistCompanyData ,
  persistFacilityData ,
  persistMoldData ,
  persistPage ,
  persistProblemTypeData ,
  persistProductData ,
  searchInputState ,
  selectedCode ,
  selectViewType , statistics_calendar_button
} from "../../common/StateManage";
import {dataValid , initModal} from "../../function/function";
import {Modal_View_Type} from '../../types/Fundamental'
import ToastPopup from '../modal/ToastPopup'

interface MenuTitleProps {
  menu?:string
  sub?:string
  buttonContent?:any
  clickEvent?:()=>void
}
const MobileSideMenu = ({menu,sub,clickEvent,buttonContent}:MenuTitleProps) => {
  const location = useLocation()
  const authValid = location.pathname.includes('/admin')

  const resetPage = useResetRecoilState(persistPage)
  const resetPageBlock = useResetRecoilState(persistBlockPage)
  const resetSelectedCode = useResetRecoilState(selectedCode)
  const resetSelectViewType = useResetRecoilState(selectViewType)
  const resetMoldReqData = useResetRecoilState(moldRequestReg)
  const resetFacilityData = useResetRecoilState(persistFacilityData)
  const resetProductData = useResetRecoilState(persistProductData)
  const resetMoldData = useResetRecoilState(persistMoldData)
  const resetMoldProblemType = useResetRecoilState(persistProblemTypeData)
  const resetCalendarData = useResetRecoilState(calendarDatePersist)
  const resetAfterCalendarData = useResetRecoilState(calendarAfterDatePersist)
  const resetSolutionType = useResetRecoilState(persistProblemTypeData)
  const resetSelectSDMCode = useResetRecoilState(documentSDMCode)
  const resetFolderList = useResetRecoilState(documentSDMList)
  const resetOrderReg = useResetRecoilState(orderSelectReg)
  const resetRangeStartDate = useResetRecoilState(calendarRangeStartDate)
  const resetRangeEndDate = useResetRecoilState(calendarRangeEndDate)
  const resetCalenderButton = useResetRecoilState(statistics_calendar_button)
  const [,setSearchInput] = useRecoilState(searchInputState)
  const resetSelectedCompany = useResetRecoilState(persistCompanyData)

  const resetRecoilState = () => {
    setSearchInput('')
    resetPage()
    resetMoldReqData()
    resetPageBlock()
    resetSelectedCode()
    resetSelectViewType()
    resetSelectedCode()
    resetCalendarData()
    resetAfterCalendarData()
    resetSolutionType()
    resetSelectSDMCode()
    resetFolderList()
    resetFacilityData()
    resetProductData()
    resetMoldData()
    resetMoldProblemType()
    resetOrderReg()
    resetCalenderButton()
    resetRangeStartDate()
    resetRangeEndDate()
  }

  const [isOpen, setIsOpen] = useState(false)
  const [selectSolution, setSelectSolution] = useState<string>('')
  const [selectSubMenu, setSelectSubMenu] = useState<{title:string, length:number}>({title:'', length:0})
  const [, setMenuObject] = useState<any>({title:'', link:''})

  const menuData = authValid? categoryMenu : userCategory
  const solutionFilter = dataValid(window.localStorage.getItem('solution'))
  const accountAuth = dataValid(window.localStorage.getItem('auth'))
  const solutionAuthCheck = (accountAuth.includes('company') || accountAuth.includes('system'))
  const systemAuthCheck = !(accountAuth.includes('company') || accountAuth === 'admin' || accountAuth.includes('affiliate'))
  const authorities = useRecoilValue(companyAuthorities)
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const storedData = window.localStorage.getItem('authoritiesList')!;
  const menuItem =  storedData ? JSON.parse(storedData) : null;
  const menuNoItem = menuItem?.map((v:any)=>(v.menu))

  const afterServiceTeamID = ['dnjsgh4546','ksj3191','ksj4420','minsoo1234','limchulkyun','dbwlgns777','zesone','xiaonian']

  const pathRoute = (link?: string) => {

    if(link){
      if(link === 'not'){
        setDataModal({menu:'mobileWarn', message:'서비스 준비 중인 메뉴 입니다.'})
      }
      else{
        resetRecoilState()
        if(!(authorities?.includes(selectSolution) || selectSolution === 'MES')){
          resetSelectedCompany()
        }
        if((afterServiceTeamID.some((v)=> window.localStorage.getItem('companyID') === v))){
          if((link === '/admin/pbas/managelog' || link === '/admin/main' || link === '/admin/fas/facility' ||
            link === '/admin/fas/business' || link === '/admin/fas/basic' || link === '/admin/pms/monitoring_system' ||
            link === '/admin/pms/operate' || link === '/admin/pms/loadton' || link === '/admin/pms/error' ||
            link === '/admin/pms/parameter' || link === '/admin/pms/cam' || link === '/admin/pms/clutch_brake' ||
            link === '/admin/pms/repair_reg_facility' || link === '/admin/pms/repair_list_facility' ||
            link === '/admin/pms/facility_complete' || link === '/admin/pms/cancel_facility_complete_admin' ||
            link === '/admin/pms/facility_problem' || link === '/admin/pms/press_monitor' || link === '/pms/press_monitor'
          )){
            window.location.href = link
          }
          else{
            setDataModal({menu:'mobileWarn', message:'현재 접속중인 기기에서는 해당 기능을 사용할 수 없습니다.'})
          }
        }
        else{
          if((link === '/admin/pbas/managelog' || link === '/admin/main' || link === '/admin/fas/business' || link === '/admin/pms/monitoring_system' ||
            link === '/pms/monitoring_system' || link === '/admin/pms/press_monitor' || link === '/pms/press_monitor' || link === '/fas/main'
          )){
            window.location.href = link
          }
          else{
            setDataModal({menu:'mobileWarn', message:'현재 접속중인 기기에서는 해당 기능을 사용할 수 없습니다.'})
          }
        }
      }
    }
  }


  React.useEffect(()=>{
    menuData.forEach((item) => {
      if (item.subTitle) {
        item.subTitle.forEach((subItem) => {
          if (subItem.subMenu) {
            subItem.subMenu.forEach((subMenuItem) => {
              if (subMenuItem.link === location.pathname) {
                setMenuObject(subMenuItem)
              }
            });
          } else if ( location.pathname.includes(subItem.link)) {
            setMenuObject(subItem)
          }
        });
      }
    });
  },[])
  return (
    <React.Fragment>
      {dataModal.menu === 'mobileWarn' && <ToastPopup text={dataModal.message}
                                                      state={dataModal.menu}
                                                      closeEvent={()=>setDataModal(initModal)}/>}
      <MobileSideComp>
        <div>
          <div className={'header_menu'}>
            {/*@ts-ignore*/}
            <div style={{fontWeight:700, fontSize:'14px'}}>{menu}</div>
            <Hamburger onClick={()=>setIsOpen(!isOpen)}>
              <div/>
              <div/>
              <div/>
              <div/>
            </Hamburger>
          </div>
          {sub ?
            <div style={{fontWeight:700, fontSize:'14px',height:'56px',padding:'0 24px',background:'#fff',display:'flex',alignItems:'center',justifyContent:'center',position:"relative"}}>
              <img src={left} style={{position:'absolute',left:'24px'}} onClick={clickEvent} alt={''}/>
              <div>{sub}</div>
              {buttonContent && buttonContent}
            </div>
            :
            null
          }
        </div>

        <SideMenu isOpened={isOpen}>
          {/*상단 타이틀*/}
          <div onTouchMove={()=>setIsOpen(false)}
               style={{display:'flex', backgroundColor:'#fff', minHeight:'56px', width:'inherit', justifyContent:'space-between', alignItems:'center'}}>
            <XmarkButton onClick={()=>setIsOpen(!isOpen)}>
              <div/>
              <div/>
            </XmarkButton>
            <div style={{paddingRight:'24px',textAlign:'right', whiteSpace:'nowrap', fontSize:'14px', fontWeight:700}}>{window.localStorage.getItem('userName')}</div>
          </div>
          {/*솔루션 메뉴*/}
          <div className={'solution-menu'} onChange={(e)=>e.preventDefault()}>
            {menuData.map((v)=>(
              <div style={{backgroundColor:selectSolution === v.title? 'rgba(0,88,255,0.1)' : '#fff'}}>
                <MainSolution className={'main-solution'}
                              disabled={solutionAuthCheck? (!(solutionFilter?.includes(v.title) || v.title === 'MES') || !v.subTitle) : !v.subTitle}
                              onMouseDown={()=> {
                                setSelectSolution( selectSolution === v.title ? '' : v.title )
                                setSelectSubMenu(selectSolution === v.title? selectSubMenu : {title:'', length:0})
                              }}>
                  <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                    <img style={{padding:'0 16px 0 8px'}} src={v.icon} className={'logo'} alt={''}/>
                    <div>{v.title}</div>
                  </div>
                  <ArrowButton arrowDirection={selectSolution === v.title? 'top' : 'bottom'}/>
                </MainSolution>
                <div style={{transition:'all 0.3s', height: `${selectSolution === v.title? (Number(v.subTitle?.length) + selectSubMenu.length)*52 : 0}px`, overflow:'hidden'}}>
                  {v.subTitle?.map((sub)=>(
                    <div style={{backgroundColor:selectSubMenu.title === sub.title? 'rgba(0,88,255,0.1)' : 'transparent'}}>
                      <SubMenuTitle className={'subMenu-title'}
                        // onClick={()=> {pathRoute(sub.link)}}
                                    disabled={
                                      (systemAuthCheck &&
                                        (!sub.subMenu? ((!menuNoItem?.includes(sub.menuNo))) : !sub.subMenu?.some((menu:any)=>(menuNoItem?.includes(menu.menuNo)))))}
                                    onMouseDown={()=> {
                                      setSelectSubMenu(selectSubMenu.title === sub.title ? {title: '' , length: 0} : {title: sub.title , length: dataValid(Number(sub.subMenu?.length),0)})
                                      pathRoute(sub.link)
                                    }}>
                        <div>{sub.title}</div>
                        {sub.subMenu && <ArrowButton arrowDirection={sub.title === selectSubMenu.title? 'top' : 'bottom'}/>}
                      </SubMenuTitle>
                      <div style={{height: `${sub.title === selectSubMenu.title? (selectSubMenu.length*52) : 0}px`, overflow:'hidden',transition:'all 0.3s'}}>
                        {sub.subMenu?.map((detail)=>(
                          <DetailMenuTitle
                            disabled={systemAuthCheck && !menuNoItem?.includes(detail.menuNo)}
                            onClick={()=>pathRoute(detail.link)} className={'detailMenu-title'}>
                            {detail.title}
                          </DetailMenuTitle>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </SideMenu>
      </MobileSideComp>
    </React.Fragment>
  );
};
export default MobileSideMenu;

const MobileSideComp = styled.div`
  position: relative;
  user-select: none;
  box-shadow: 0 1px 6px #00000029;

  .header_menu {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 56px;
    box-sizing: border-box;
    padding: 0 24px;
    position: relative;
  }
`

const SideMenu = styled.div<{isOpened?: boolean}>`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
    //max-width: ${props => props.isOpened ? '85%' : '0'};
  max-width: 85%;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 3px 6px #00000029;
  align-items: center;
  align-self: center;
  box-sizing: border-box;
  overflow: hidden;
  height: calc(100vh - 56px);
  background-color: #fff;
  transform: translateX(${props => props.isOpened ? 0 : 100}%);
  z-index: 1000;
  white-space: nowrap;

  .solution-menu {
    overflow-y: scroll;
    width: inherit;
    font-weight: 700;
    font-size: 14px;
    .main-solution {
      &:hover {
        background-color: rgba(0, 88, 255, 0.1);
        color: #0058ff;
      }
    }

    .subMenu-title {
      &:hover {
        background-color: rgba(0, 88, 255, 0.1);
        color: #0058ff;
      }
    }

    .detailMenu-title {
      &:hover {
        background-color: rgba(0, 88, 255, 0.1);
        color: #0058ff;
      }
    }

  }


`

const MainSolution = styled.div<{disabled?: boolean}>`
  display: flex;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box;
  padding-right: 20px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow: hidden;
  //border-bottom: 1px solid #d5d7e3;
  
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  
  &::before{
    content:'';
    position: absolute;
    left: 0;
    top: 0;
    height: 1px;
    width: calc(100% - 8px);
    background-color: #d5d7e3;
  }
`
const SubMenuTitle = styled.div<{disabled?:boolean}>`
  display: flex;
  height: 52px;
  box-sizing: border-box;
  padding: 0 48px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
`

const DetailMenuTitle = styled.div<{disabled?:boolean, selected?:boolean}>`
  display: flex;
  height: 52px;
  box-sizing: border-box;
  padding: 0 72px;
  align-items: center;
  cursor: pointer;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
`

const Hamburger = styled.div<{isOpened?:boolean}>`
  display: block;
  cursor: pointer;
  position: absolute;
  right: 24px;
  width: 25px;
  height: 36px;

  div {
    width: 100%;
    max-width: 18px;
    padding: 1px;
    border-radius: 15px;
    background-color: #949494;
    position: absolute;
    transition: all 0.3s;

    &:nth-child(1) {
      top: 13px;
      transform: scale(100%);
    }

    &:nth-child(2) {
      top: 20px;
      transform: rotate(0deg);
    }

    &:nth-child(3) {
      top: 20px;
      transform: rotate(0deg);
    }

    &:nth-child(4) {
      top: 27px;
      transform: scale(100%);
    }
  }
`

const XmarkButton = styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  min-width: 25px;
  box-sizing: border-box;
  padding: 0 36px 0 8px;
  div {
    width: 100%;
    max-width: 18px;
    padding: 1px;
    border-radius: 15px;
    background-color: #949494;
    transition: all 0.3s;
    position: absolute;
    &:nth-child(1) {

      transform: rotate(45deg);
    }
    &:nth-child(2) {

      transform: rotate(-45deg);
    }
  }
`