import React,{useState} from 'react';
import {
	CheckBox ,
	ContentFrame ,
	Icons , NoData ,
	SectionFrame ,
	tooltipModule ,
	validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import PaginationList from "../../../../../components/pagination/PaginationList";
import SearchBar from "../../../../../components/input/SearchBar";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {Error_Type} from "../../../../../types/Fundamental";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {
	persistBlockPage ,
	persistPage ,
	searchInputState ,
	selectedCode , selectedListCountValue , wikiAuthTypeState , wikiTypeFilter
} from "../../../../../common/StateManage";
import {dataValid , initError , listLoadingCircle , loadingRemove} from "../../../../../function/function";
import MainButtons from "../../../../../components/button/MainButtons";
import SelectEventButton from "../../../../../components/button/SelectEventButton";
import dayjs from "dayjs";
import DropMenu from '../../../../../components/sideMenu/DropMenu'
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const WikiList = () => {
	const tableTitle = ['게시글 No.','솔루션','유형','업체명','제목','작성자','답변','등록일','조회수'];
	const [viewComplete,setViewComplete] = React.useState<boolean>(false);
	const [pageState, setPageState] = useRecoilState(persistPage)
	const [pageBlock, setPageBlock] = useRecoilState(persistBlockPage)
	const [totalPage, setTotalPage] = useState(1)
	const [selectedWiki, setSelectedWiki] = useRecoilState(selectedCode)

	const [wikiList, setWikiList] = useState<any[]>([])
	const [selectSolution, setSelectSolution] = useRecoilState<string>(wikiTypeFilter)
	const [searchInput, setSearchInput] = useRecoilState<string>(searchInputState)
	const [prevSearch, setPrevSearch] = React.useState<string>(searchInput)
	const [errorState,setErrorState] = React.useState<Error_Type>(initError)
	const [authType, setAuthType] = useRecoilState<string>(wikiAuthTypeState)
	const [, setSelectedCount] = useRecoilState(selectedListCountValue)
	const { t }:any = useTranslation();

	const wikiListRes = async () => {
		try {
			listLoadingCircle()
			const response = await client.get(`/wiki/list/${pageState}/10/${authType}/${dataValid(selectSolution,'@')}/${viewComplete}`,{
				headers:{Authorization}
			})
			const data = response.data.data
			const valid = response.data

			if( data.total_page < pageState ){
				setPageState( 1 )
				setPageBlock( 0 )
			}
			setWikiList(data.row)
			setTotalPage(data.total_page)
			if( valid.status !== 200 ){
				setErrorState( {notice: valid.message , status: valid.status} )
			} else{
				setErrorState( {notice: ' * 등록된 글이 없습니다.' , status: valid.status} )
			}
			loadingRemove()
		}catch (e) {
			loadingRemove()
		}
	}

	const searchWikiListRes = async () => {
		try {
			listLoadingCircle()
			const response = await client.get(`/wiki/search/${pageState}/10/${authType}/${dataValid(selectSolution,'@')}/${searchInput}/${viewComplete}`
				,{headers:{Authorization}})
			const data = response.data.data
				setWikiList(data.row)
				setTotalPage(data.total_page)
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	const searchEventFunc = () => {
		setPrevSearch(searchInput)
		setPageState(1)
		setPageBlock(0)
	}

	const searchInitFunc = () => {
		setSearchInput('')
		setPrevSearch('')
		setPageState(1)
		setPageBlock(0)
	}

	React.useEffect(()=>{
		if(searchInput === ''){
			wikiListRes().then()
		}else{
			searchWikiListRes().then()
		}
	},[pageState, prevSearch, selectSolution, authType,viewComplete])

	return (
		<SectionFrame>
			<div style={{display:'flex',justifyContent:'space-between', position:'relative'}}>
					<MenuTitle title={'게시판 관리 > WIKI 관리'} unitCase={'MenuTitle'}/>

				<div style={{display:'flex', alignItems:'center', position:'relative', marginRight:'120px'}}>
					{['업체','제스텍'].map((v)=>(
						<SelectEventButton buttonName={v} key={v} alarmPoint={false}
															 buttonEvent={()=> {
																 setAuthType(v === '제스텍'? 'admin' : 'company')
																 setPageState(1)
																 setPageBlock(0)
																 // setSelectSolution('@')
															 }}
															 buttonStatus={authType === 'admin'?  v ==='제스텍' : v === '업체'} buttonBorder={true}/>
					))}
				</div>
					<DropMenu menuArray={['전체','문의','기획 회의','개발중','개발완료','보류']} style={{zIndex:-1}}
										selectValue={selectSolution === '@'? '전체' : selectSolution} clickEvent={(v)=>{
											setSelectSolution( ( v === '전체' ) ? '@' : v )
											setPageState(1)
										}}
										left={'91.8%'} top={'44px'} width={'105px'}/>
				{/*<div style={{display:'flex', alignItems:'center', position:'relative'}}>*/}
				{/*	{['전체','에러','요청','문의','기획 회의','개발중','개발완료','보류'].map((v)=>(*/}
				{/*		<SelectEventButton buttonName={v} key={v}*/}
				{/*											 buttonEvent={()=> {*/}
				{/*												 setSelectSolution( ( v === '전체' ) ? '@' : v )*/}
				{/*												 setPageState(1)*/}
				{/*											 }}*/}
				{/*											 buttonStatus={selectSolution === '@' ? v === '전체' : selectSolution === v} buttonBorder={true}/>*/}
				{/*	))}*/}
				{/*</div>*/}
			</div>

			<ContentFrame justify={'space-between'}>
				<div>
					<div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
						<div style={{display:'flex',alignItems:'center'}}>
							<MenuTitle title={'WIKI'} unitCase={'MenuCompTitle'}/>
							<label style={{
								display: 'flex' , alignItems: 'center' , marginLeft: '16px' , cursor: 'pointer' ,
								pointerEvents:selectSolution === '개발완료' ? 'none' : 'auto',opacity:selectSolution === '개발완료' ? 0.5 :1}}>
								<CheckBox type={'checkbox'} icons={`url(${Icons.Check})`} checked={viewComplete} onClick={()=>{setViewComplete(!viewComplete)}}/>
								<div style={{color: '#5A607F' , fontSize: '12px',userSelect:'none'}}>{'개발완료 보기'}</div>
							</label>
						</div>
						<div style={{display:'flex', alignItems:'center'}}>
							<SearchBar keyFunction={(e)=>setSearchInput(e.target.value.trimStart())}
												 value={searchInput} margin={0}
							           clickEvent={searchEventFunc} clearButton={searchInitFunc} clearButtonAccess={searchInput !== ''}/>
							<MainButtons name={'등록'} width={'56px'} buttonType={'cta'} marginDis={true}
													 clickEvent={()=>{window.location.href = `/admin/fas/wiki/register`}}/>
						</div>
					</div>
					<GridTableComponent tempCol={'repeat(2, 80px) 96px 144px 424px 128px 66px 114px 1fr'}
															scrollWidth={1210}
															scrollX_Hide={true} scrollY_Hide={true} >
						<div className={'grid-title-unchecked'}>
									{tableTitle.map((v)=>(
										<div key={v}>{t(v)}</div>
									))}
						</div>
						{
							wikiList.length === 0 ? prevSearch ==='' ? errorState.status !== 200 ?
									<NoData>{errorState.notice}</NoData>
									:
									<NoData>{errorState.notice}</NoData>
								:
								<NoData>{' * 검색 결과가 없습니다.'}</NoData>
							:
							<React.Fragment>
								{wikiList.map((v)=>(
									<GridTableSelect key={v.wikiCode} className={'grid-list-unchecked-no_icon'}
																	selected={selectedWiki === v.wikiCode}
																	onMouseDown={()=> {setSelectedWiki(v.wikiCode); setSelectedCount(v.count)}}
																	onDoubleClick={()=>window.location.href = `/admin/fas/wiki/modify/${v.wikiCode}`}>
										{// 새로 작성된 wiki 목록 - 카운트 수가 0인경우 보여지도록 함.
											v.count === '0' &&
											<div style={{
												position: 'absolute' , top: 0 , left: 0 , width: '8px' , boxSizing:'border-box', margin:'4px',
												height: '8px' , backgroundColor: '#F0142F', borderRadius:'50%'}}/>}
										{validationValue(v.no)}
										{validationValue(t(v.solution))}
										{validationValue(t(v.wikiType))}
										{tooltipModule(dataValid((v.companyName)),7)}
										{/*{validationValue(v.companyCode)}*/}
										<div style={{flexDirection:'row', textOverflow:'ellipsis',overflow:'hidden', whiteSpace:'nowrap',position:'relative', alignItems:'center', justifyContent:'flex-start'}}>{dataValid(v.wikiTitle)}
											<span style={{fontWeight:700}}>{' ('+ dataValid(v.replyCount, 0)+')'}</span>
										</div>
										{/*{tooltipModule(dataValid((v.wikiTitle)),30)}*/}
										{validationValue(v.writer)}
										<div>{v?.wikiFinish === '0' ? '-':'완료'}</div>
										{validationValue(dayjs(v.registrationDate).format('YYYY.MM.DD'))}
										{validationValue(v.count)}
									</GridTableSelect>
								))}
							</React.Fragment>
						}
					</GridTableComponent>
				</div>
				{wikiList.length !== 0 &&
					<PaginationList limit={10} pageLimit={10} page={pageState} setPage={setPageState}
													blockNum={pageBlock} setBlockNum={setPageBlock} counts={totalPage}/>
				}
			</ContentFrame>
		</SectionFrame>
	);
};

export default WikiList;
