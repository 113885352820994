import React , {useEffect , useRef , useState} from "react";
import styled from "styled-components";
import {BaseContentProps , CircleIconType , GridColumnProps} from "../types/Fundamental";
import calender from "../asset/image/ic-calendar.svg";
import left from "../asset/image/chevron-left.svg";
import left_w from "../asset/image/w-chevron-left.svg";
import right from "../asset/image/chevron-right.svg";
import right_w from "../asset/image/w-chevron-right.svg";
import checked from "../asset/image/check-icon.svg";
import {dataValid} from "../function/function";
import {CircularProgress , circularProgressClasses , CircularProgressProps , Tooltip , Zoom} from "@mui/material";
import MainButtons from "../components/button/MainButtons";
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import barcodeB from "../asset/image/barcode_b.png";
import barcodeW from "../asset/image/barcode_w.png";
import noImage from '../asset/image/noImage.svg';
import work from "../asset/image/imes/work.json";
import aiProduct from "../asset/image/imes/ai_production.json";
import order from "../asset/image/imes/order.json";
import orderProduct from "../asset/image/imes/workorder_production.json";
import box from "../asset/image/fire/fire_box.svg";
import part from "../asset/image/fire/fire_part.svg";
import press from "../asset/image/fire/fire_press.svg";
import assembly from "../asset/image/fire/Group 19079.svg";
import {AutoTextSize} from "auto-text-size";

export const Colors =
  {
    BgBlue: 'rgba( 0,88,255, 0.1)',
    BgGreen: 'rgb(33, 213, 155, 0.1)',
    Green: '#21D59B',
    BgRed: 'rgb(240, 20, 47, 0.1)',
    BgRedHalf: 'rgb(240, 20, 47, 0.5)',
    Red: '#FF0000',
    Black: '#000000',
    White: '#FFFFFF'
  }

export const Icons =

  {
    Left: left,
    LeftW: left_w,
    Right: right,
    RightW: right_w,
    Check: checked,
    Calender: calender,
    barcodeB: barcodeB,
    barcodeW: barcodeW,
    noImage: noImage,
  }


export const IconFileArray = {
  ai: work,
  aiProduct: aiProduct ,
  order: order,
  orderProduct: orderProduct
};

export const IconFirePrev ={
  box: box,
  part: part,
  press:press,
  assembly:assembly
};


export const PageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  height: 100%;
  box-sizing: border-box;
`

export const MobilePageContainer = styled.div`
  width: 100%;
  min-width: 320px;
  display: flex;
  height:100%;
  box-sizing: border-box;
`


export const SectionFrame = styled.div`
  margin: 0 auto;
  width: 1270px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const MobileContentFrame = styled.div<BaseContentProps>`
  display: flex;
  height: 100%;
  background-color: #ffffff;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  justify-content: ${props => props.justify};
  padding: ${props => props.padding ?? '16px 16px 0 16px'};
`

export const ContentFrame = styled.div<BaseContentProps>`
  //1270px , 66.1vw
  width: ${props => props.width ?? '1270px'};
  //680px, 35.4vw
  height: ${props => props.height ?? '680px'};
  margin: ${props => props.margin};
  padding: ${props => props.padding ?? '30px'};
  display: flex;
  flex-direction: ${props => props.flexDirection ?? 'column'};
  justify-content: ${props => props.justify};
  //transition: all 0.5s;
  box-shadow: ${props => props.shadowStyleChange? '0 3px 6px #00000029' : '0 1px 4px #15223214'};
  background-color: ${props => props.backgroundColor ?? '#fff'};
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;

  .scroll_comp {

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background: #F5F5F5;
    }
  }

  .scroll_var {
    height: 300px;
    overflow-y: overlay;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: block;
      width: 18px;
    }
  }
`
export const TitleListComp = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  color: #131523;
  background-color: #F5F6FA;
  font-size: 12px;
  font-weight: 800;

  & > div:first-child {
    margin-left: 16px;
  }

  & > div {
    margin-left: 24px;
  }
`

export const MobileSectionFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  height: calc(100vh - 112px);
  box-shadow: 0 0 4px #00000029;
  overflow-y: scroll;
`

export const ContentListComp = styled.div<{ select?: boolean }>`
  display: flex;
  height: 48px;
  align-items: center;
  font-size: 14px;

  font-weight: 700;
  user-select: none;
  cursor: pointer;
  //transition: all 0.1s;
  box-sizing: border-box;
  border: 1px solid ${props => props.select ? '#0058ff' : 'white'};
  background-color: ${props => props.select ? 'rgba(0,98,255,0.05)' : 'white'};
  color: ${props => props.select ? '#0058ff' : '#171725'};

  &:hover {
    background-color: rgba(0, 98, 255, 0.05);
    border: 1px solid ${props => props.select ? 'rgba(0,98,255,1)' : 'rgba(0,98,255,0.05)'};
    color: #0058ff;
  }

  & > div:first-child {
    margin-left: 16px;
  }

  & > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 24px;
  }
`
export const GridTable = styled.div<GridColumnProps>`
  width: ${props => props.width ?? 'auto'};
  height: ${props => props.height ?? 'auto'};
  overflow-x: ${props => props.scrollActiveX ?? 'auto'};
  overflow-y: ${props => props.scrollActiveY ?? 'auto'};

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-track {
    width: 10px;
    height: 8px;
    border-radius: 10px;
    background: #F5F5F5;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D7DBEC;
    outline: none;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
    cursor: pointer;

    &:checked {
      border: transparent;
      background-color: #0058Ff;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  //.label_position {
  //   display: flex;
  //   align-items: center;
  //   position: absolute;
  //   right: 20px;
  //   font-size: 14px;
  //}

  .scroll_wrap {

    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background: ${props => props.height ? '#F5F5F5' : 'transparent'};
    }
  }

  .table {
    &_title {
      user-select: none;
      margin-top: ${props => props.marginCus ? '0' : '16px'};
      box-sizing: border-box;
      padding-left: 16px;
      background: #F5F6FA;
      line-height: 46px;
      font-weight: 900;
      font-size: 12px;
      display: grid;
      grid-gap: ${props => props.titleGap ?? 24}px;
      grid-template-columns: ${props => props.tempCol};
      white-space: nowrap;
    }


    &_list {
      //overflow-y: scroll;
      user-select: none;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: normal;
      padding-left: 16px;
      box-sizing: border-box;
      display: grid;
      line-height: 46px;
      height: 48px;
      cursor: pointer;
      grid-gap: ${props => props.gridGap ?? 24}px;
      grid-template-columns: ${props => props.tempCol};
      background-color: ${props => props.selected ? 'rgba(0,88,255,0.05)' : 'transparent'};
      border: 1px solid ${props => props.selected ? '#0058ff' : 'transparent'};

      .adornment {
        display: flex;
        justify-content: flex-end;
        padding: 0 16px;
        font-family: "NanumGothicOTF", sans-serif;
        font-size: 12px;
        font-weight: normal;
        color: #D7DBEC;
      }


      .previewButton {
        justify-self: center;
        font-weight: 700;
        align-self: center;
        width: 77px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #D7DBEC;
        border-radius: 4px;
        color: #5A607F;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
      }

      & > div {
        cursor: pointer;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;

        overflow: ${props => props.overflow ?? 'hidden'};

      }

      &:hover {
        border: 1px solid rgba(0, 88, 255, 0.05);
        background-color: rgba(0, 88, 255, 0.05);
        color: #0058FF;
        font-weight: bold;
      }
    }
  }
`
export const OutputDeliveryGrid = styled.div<GridColumnProps>`
  width: ${props => props.width ?? 'auto'};
  height: ${props => props.height ?? '48px'};
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  padding-left: 16px;
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  line-height: 48px;
  cursor: pointer;
  position: relative;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: ${props => props.selected ? '#0058ff' : '#171725'};
  background-color: ${props => props.selected ? 'rgba(0,88,255,0.05)' : 'transparent'};
  border: 1px solid ${props => props.selected ? '#0058ff' : 'transparent'};

  & > div {
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    box-sizing: border-box;
  }

  .deliveryDiv {
    background-color: #F5F6FA;
  }

  .checkbox {
    display: flex;
    justify-content: center;
    width: 41px;
    height: 48px;
    box-sizing: border-box;
    position: absolute;
    background-color: #fff;
  }


  &::before {
    content: '';
    position: absolute;
    height: ${props => props.cancel ? '1px' : 0};
    width: 100%;
    top: 24px;
    background-color: #7E84A3;
  }
`
export const SelectGridList = styled.div<GridColumnProps>`
  width: ${props => props.width ?? 'auto'};
  height: ${props => props.height ?? '48px'};
  user-select: none;
  font-size: 14px;
  font-weight: ${props => props.selected ? 700 : props => props.fontWeight ?? 700};
  padding-left: 16px;
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  line-height: 46px;
  cursor: pointer;
  position: ${props => props.popover ? 'static' : 'relative'};
  grid-gap: ${props => props.gridGap ?? 24}px;
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: ${props => props.selected? '#0058ff' : '#171725'};
  background-color: ${props => props.selected? 'rgba(0,88,255,0.05)' : 'transparent'};
  border: 1px solid ${props => props.selected? '#0058ff' : 'transparent'};
  &>div{
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .checkbox{
    display: flex;
    justify-content: center;
    width: 41px;
    height: 46px;
    box-sizing: border-box;
    position: absolute;
    background-color: ${props => props.selected? '#f1f6fe' : '#fff'};
    border-left: 1px solid ${props => props.selected? '#0058ff' : '#fff'};
    //background-color: #fff;
    //border: 1px solid rgba(0,88,255,0.05);
  }
  &:hover{
    border: 1px solid ${props => props.selected? '#0058ff' : 'rgba(0,88,255,0.05)'};
    background-color: rgba(0,88,255,0.05);
    color: #0058FF;
    font-weight: bold;

    .checkbox {
      background-color: #f5f6fa;
      border-left: 1px solid ${props => props.selected? '#0058ff' : '#f5f6fa'};
    }

    .hover_cus {
      color: #171725;
      font-weight: normal;
    }
  }
  //.end_overlay{
  //  width: 100%;
  //  height: 100%;
  //  position: absolute;
  //  //z-index:2;
  //  top:0;
  //  right:200px;
  //
  //  background-clip: text;
  //  //background-image: -webkit-linear-gradient(270deg, rgba(240, 240, 240, 0.2) 20%, rgba(0, 0, 0, 1) 95%);
  //  background-image: linear-gradient(to right, transparent 10%, rgba(255,255,255,1) 80%);
  //}

  &::before {
    content: '';
    position: absolute;
    height: ${props => props.cancel ? '1px' : 0};
    width: 100%;
    top: 24px;
    background-color: #7E84A3;
  }
`
export const TableList = styled.div<{ selected: boolean, disabled?: boolean }>`
  align-items: center;

  &.table_list {
    color: ${props => props.selected ? '#0058ff' : '#171725'};
    border: 1px solid ${props => props.selected ? '#0058ff' : '#fff'};
    font-weight: ${props => props.selected ? 700 : 400};
    background-color: ${props => props.selected ? 'rgba(0,88,255,0.05)' : '#fff'};
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    .modify_control {
      display: none;
    }

    &:hover {
      border: 1px solid ${props => props.selected ? '#0058ff' : 'rgba(0,88,255,0.05)'};

      .modify_control {
        display: block;
      }
    }
  }
`
export const RegisterButton = styled.button<{buttonDisabled?:boolean,buttonNormal?:boolean}>`
  font-size: 13px;
  font-weight: 600;
  color: #131523;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid ${p=>p.buttonNormal ? '#D7DBEC' : '#0058FF'};
  box-sizing: border-box;
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  width: 144px;
  outline: none;
  font-family: 'Nanum Gothic', sans-serif;
  opacity: ${props => props.buttonDisabled? 0.3 : 1};
  pointer-events: ${props => props.buttonDisabled? 'none' : 'auto'};
  &:hover {
    background-color: #0058FF;
    color: #fff;
  }
`
export const ModalBackCss = styled.div<{top?:number,left?:number,bottom?:number ,zIndex?: number, notAllowed?: string, toastButton?: boolean }>`
  width: 100%;
  height: 100%;
  position: fixed;
  top: ${p=>p.top ?? 0}px;
  left: ${p=>p.left ?? 0}px;
  bottom: ${p=>p.bottom ?? 0}px;
  z-index: ${props => props.zIndex ?? 1500};
  transition: opacity ${p => p.toastButton ? '1000ms' : '0ms'};
  opacity: ${p => p.toastButton ? 0 : 1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  cursor: ${p => p.notAllowed === 'Move' ? 'move' :
          p.notAllowed === 'Pointer' ? 'pointer' :
                  p.notAllowed === 'notAllow' ? 'not-allowed' : 'default'};
`

export const ModalInnerFrame = styled.div<{authDivide: boolean, backgroundColor?: string, color?:string}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 900px;

  .modal_title{
    background-color: ${props => props.backgroundColor ? props.backgroundColor : (props.authDivide ? '#CFFA61' : '#0058FF')};
    color: ${props => props.color? props.color :(props => props.authDivide ? '#171725' : '#fff')};

    &>div:nth-child(1) {
      font-size: 18px;
      font-weight: ${props => props.authDivide ? 800 : 700};
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: ${props => props.authDivide ? 700 : 400};
      opacity: ${props => props.authDivide ? 0.5 : 0.8};
    }
  }
`

export const modalTitleBase = (modalTitle: {mainTitle: string, subTitle?: string, backgroundColor?: string, color?:string}, children?: any) => {
  return (
    <div className={'modal_title'} style={{backgroundColor: modalTitle.backgroundColor, color:modalTitle.color}}>
      {validationValue(modalTitle.mainTitle, '')}
      {validationValue(modalTitle.subTitle, '')}
      {children}
    </div>
  )
}




export const ModalLabel = styled.div<{ location: boolean }>`
  color: ${props => props.location ? '#171725' : '#fff'};
  font-weight: 800;
  font-size: 18px;
  height: 52px;
  line-height: 56px;
  box-sizing: border-box;
  padding-left: 16px;

  background-color: ${props => props.location ? '#CFFA61' : '#0058FF'};

`
export const NoResult = styled.div<{ marginCus?: string }>`
  display: flex;
  align-self: center;
  font-size: 24px;
  font-weight: 700;
  width: 100%;

  justify-content: center;
  margin-top: ${props => props.marginCus ?? '150px'};
`
export const SquareTable = styled.div`
  display: flex;
  height: 48px;
  line-height: 48px;
  width: 100%;
  border-bottom: 1px solid #E6E9F4;

  & > div {
    box-sizing: border-box;
  }

  & > div:first-child {
    width: 168px;
    padding-left: 16px;
    color: #131523;
    font-size: 12px;
    font-weight: 800;
    background-color: #F5F6FA;
    user-select: none;
  }

  & > div:last-child {
    font-size: 14px;
    font-weight: 400;
    padding-left: 24px;
  }
`
export const AlarmLogCircle = styled.div<CircleIconType>`
  display: flex;
  align-items: center;
  width: ${props => props.width ?? '16px'};
  height: ${props => props.height ?? '16px'};
  margin-right: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  border: ${props => props.alarmStatus? '' :' 1px solid #D5D7E3'};
  background-color: ${props => props.alarmStatus === 'check' ? '#FFC700' :
          props.alarmStatus === 'replace' ? '#F99600' : props.alarmStatus === 'timeout' ? '#F0142F' : ''};
`

export const EnergyCircle = styled.div<CircleIconType>`
  display: flex;
  align-items: center;
  width: ${props => props.width ?? '16px'};
  height: ${props => props.height ?? '16px'};
  border-radius: 50%;
  background-color: ${props => props.alarmColor ?? ''};

`
export const femsInfoStyle = {
  display: 'flex' ,
  fontSize: '12px' ,
  fontWeight: 'bold' ,
  color: '#131523' ,
  marginRight:'16px'
}
export const noticeTitleStyle = {
  fontSize: '12px' ,
  fontWeight: 'bold' ,
  color: 'rgba(23, 23, 37, 0.5)'


}
export const modalOpenTextStyle = {
  display: 'inline-block' ,
  alignItems: 'center' ,
  // justifyContent: 'center',
  fontSize: '12px' ,
  fontWeight: 'bold' ,
  color: '#7E84A3' ,
  textDecoration: 'underline' ,
  textUnderlineOffset: '3px' ,
  cursor: 'pointer' ,
  // position: 'absolute',
}

export const ModalOpenText = styled.div`
  display: inline-block;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #7E84A3;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;

  &:hover {
    color: #a1a7c5;
  }
`

export const InputDate = styled.input`
  width: 160px;
  height: 32px;
  margin: 0;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  outline: none;
  display: flex;
  box-sizing: border-box;
  padding-left: 10px;
  font-size: 14px;
  font-family: "Nanum Gothic", sans-serif;
  color: #171725;
  font-weight: normal;

  &::after {
    content: '';
    display: flex;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-left: 1px solid #E6E9F4;
    cursor: pointer;
    background-image: url(${calender});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

`
// 길이가 스크롤 길이 만큼 긴 경우 상위에 'relative 주고'
// position:'absolute',padding:'0 양옆 중앙만큼 패딩으로 채우기',width:'auto'
export const NoData = styled.div<{ height?: number }>`
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(23, 23, 37, 0.5);
  display: flex;
  white-space: pre-wrap;
  flex-direction: column;
  user-select: none;
  justify-content: center;
  height: ${props => props.height ?? 450}px;
`


export const orderComponent = ( title: string | any , value: any , validation?: string | number ) => {
  return (
    <React.Fragment>
      <div className={'order_title'}>{title}</div>
      <div className={'order_content'}>{tooltipModule(dataValid( value , validation ))}</div>
    </React.Fragment>
  )
}
export const toolTopOrderComponent = (value:{title: string, value: any , validation?: string | number, limit?: number, tooltip?: string} ) => {
  return (
    <React.Fragment>
      {tooltipModule(value.tooltip, 0 , <div style={{cursor: value.tooltip? 'help' : 'auto'}} className={'order_title'}>{value.title}</div>)}
      <div style={{cursor:'help'}} className={'order_content'}>{tooltipModule(dataValid( value.value , value.validation), value.limit ?? 0)}</div>
    </React.Fragment>
  )
}
export const validationValue = ( value: any , validation?: string | number ) => {
  return <div>{!value ? validation ?? '-' : value}</div>
}

export const autoTextValueValidation = ( v: {value: any, validation?: string|number, fontSizeMax?: number, fontSizeMin?:number, fontWeight?: number } )  => {
  return (
    <div style={{fontWeight: v.fontWeight ?? 400}}>
      <AutoTextSize minFontSizePx={v?.fontSizeMin ?? 12} maxFontSizePx={v?.fontSizeMax ?? 14}>
        {!v.value ? v?.validation ?? '-' : v.value}
      </AutoTextSize>
    </div>
  )
}
export const tooltipModule = ( element: any , limit?:number, children?: any ) => {
  return (
    <Tooltip children={children ?? <div style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{element}</div>} componentsProps={{
      tooltip: {
        sx: {
          backgroundColor: '#F5F6FA' ,
          color: '#171725' ,
          display: `${Number( element?.length ) > ( limit ?? 0 ) ? 'block' : 'none'}`,
          boxShadow:'0 3px 6px #00000029',
          border:'1px solid #D7DBEC',
          borderRadius:'8px'
        }
      }
    }}
             title={<div style={{
               fontSize: '14px' ,
               fontWeight: 700 ,
               fontFamily: 'Nanum Gothic' ,
               padding: '6px' ,
               boxSizing: 'border-box',
             }}>{element}</div>}
             TransitionComponent={Zoom} enterDelay={300} placement={'bottom'} followCursor/>
  )
}
export const ScrollMore = styled.div`
  display: block;
  width: 28px;
  min-height: 50px;
  border: 2px solid #aaaaaa;
  border-radius: 16px;
  cursor: pointer;
  margin: 0 auto;

  .wheel {
    width: 3px;
    height: 10px;
    border-radius: 10px;
    background-color: #aaaaaa;
    margin: 0 auto;
    opacity: 0;
    animation-name: scrolling;
    animation-play-state: running;
    animation-timing-function: linear;
    animation-duration: 1s;
    animation-iteration-count: infinite;


    @keyframes scrolling {
      25% {
        transform: translateY(6px);
        opacity: 0.3;
      }
      50% {
        transform: translateY(12px);
        opacity: 1;
      }
      75% {
        transform: translateY(18px);
        opacity: 1;
      }
      100% {
        transform: translateY(24px);
        opacity: 0.3;
      }
    }
  }
`
export const OriginalPikerComp = styled.div<{authValid?:boolean, height?: number}>`
  display: flex;
  position: relative;
  user-select: none;
  font-family: "Nanum Gothic", sans-serif;
  .react-datepicker__triangle{
    display: none;
  }
  .react-datepicker__day-names{
    background-color: #fff;
  }

  .react-datepicker__tab-loop{
    position: relative;
    z-index: 100;
  }

  .react-datepicker{
    border: none;
  }

  .react-datepicker__month-container{
    height: ${props => props.height ?? 330}px;
    max-width: 240px;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 4px;

    .react-datepicker__header,.react-datepicker__header--custom{
      z-index: 100;
      border-bottom:none;
      font-weight: 600;

    }
    .react-datepicker__header{
      padding: 0;
      background-color: ${p=>p.authValid ? 'rgb(207, 250, 97)' : '#0058FF'};
      font-family: "Nanum Gothic", sans-serif;

    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; { /*<img src={calendar} onClick={clickEvent} alt="" style={{position:'absolute', right: '24px', cursor:'pointer'}}/>*/
  } -webkit-appearance: none;
  }
  .react-datepicker-wrapper{
    width: 0;
    position: relative;
  }

  .react-datepicker__year-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 56px);
    align-items: center;
    grid-gap: 4px;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: #0058FF;
    border-radius: 4px;
    color: #fff;
    font-family: 'Nanum Gothic OTF',sans-serif;
  }

  .react-datepicker__week{
    box-sizing: border-box;
    line-height: 32px;
    font-weight: 400;

    .react-datepicker__day--selected{
      background-color: #0058FF;
      font-weight: 400;
      color: #fff;
      outline: none;
    }
    .react-datepicker__day--today{
      font-weight: 400;
      position: relative;
      outline: none;
      &::before{
        content:'';
        height: calc(100% - 2px);
        width: calc(100% - 2px);
        border: 1px solid #21D59B;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
      }
    }
    .react-datepicker__day--outside-month{
      visibility: hidden;
      outline: none;
    }
    .react-datepicker__day--keyboard-selected{
      background-color: #fff;
      border-radius: 4px;
      outline: none;
      color: #171725;
    }

    .react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{
      position: relative;
      color: #ccc;
      &::after{
        content: '';
        width: 3px;
        height: 3px;
        position: absolute;
        top:80%;
        border-radius: 50%;
        left:50%;
        transform: translate(-50%, -50%);
        bottom: 0;
        background-color: transparent;
      } }
  }
}

.react-datepicker__day{
  position: relative;
}

.react-datepicker__navigation--previous {
  cursor: pointer;
  border: none;
  display: flex;
  width: 20px;
  height: 20px;
  //padding-left: 16px;
  box-sizing: border-box;
  //background-color: #fff;
  background-image: ${props => props.authValid ? `url(${left})` : `url(${left_w})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}
.react-datepicker__navigation--next {
  cursor: pointer;
  display: flex;
  border: none;
  width: 20px;
  height: 20px;
  //padding-right: 16px;
  box-sizing: border-box;
  //background-color: #fff;
  background-image: ${props => props.authValid ? `url(${right})` : `url(${right_w})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.react-datepicker__navigation--previous--year {
  cursor: pointer;
  border: none;
  display: flex;
  width: 24px;
  height: 24px;
  padding-left: 16px;
  box-sizing: border-box;
  //background-color: #fff;
  background-image: ${props => props.authValid ? `url(${left})` : `url(${left_w})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.react-datepicker__navigation--next--year {
  cursor: pointer;
  display: flex;
  border: none;
  width: 24px;
  height: 24px;
  padding-right: 16px;
  box-sizing: border-box;
  //background-color: #fff;
  background-image: ${props => props.authValid ? `url(${right})` : `url(${right_w})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
`

export const DatePickerComp= styled.div<{auth?:boolean}>`
  width: 240px;
  display: flex;
  font-family: "Nanum Gothic", sans-serif;
  justify-content: center;
  z-index: 100;


  .react-datepicker__input-time-container {
    width: auto;
  }


  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; { /*<img src={calendar} onClick={clickEvent} alt="" style={{position:'absolute', right: '24px', cursor:'pointer'}}/>*/
  } -webkit-appearance: none;
  }

  .react-datepicker {
    display: flex;
    font-family: 'NanumGothic', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #D7DBEC;
    flex-wrap: wrap;
    max-width: 330px;
  }

  .react-datepicker__year-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 56px);
    align-items: center;
    grid-gap: 8px;
  }

  .react-datepicker__year-text {
    font-size: 12px;
    font-weight: 700;
    width: 56px;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;

    &:hover {
      color: #0058ff;
      border-radius: 4px;
      background-color: rgba(0, 88, 255, 0.3);
    }
  }

  .react-datepicker__year-text--keyboard-selected {
    background-color: transparent;
  }

  .react-datepicker__year-text--selected {
    color: #fff;
    background-color: #0058ff;
  }

  .react-datepicker__year-text--today {
    //color: #fff;
    //border-radius: 4px;
    //background-color: #0058ff;
  }


  .react-datepicker__monthPicker {
    display: grid;
    align-items: center;
    grid-gap: 10px;
  }

  .react-datepicker__month-text {
    font-size: 12px;
    font-weight: 700;
    width: 56px;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;

    &:hover {
      color: #0058ff;
      border-radius: 4px;
      background-color: rgba(0, 88, 255, 0.3);

    }
  }

  .react-datepicker__month-text--selected {
    color: #0058ff;
    background-color: transparent;
  }

  .react-datepicker__month-text--today {
    //color: #fff;
    //border-radius: 4px;
    //background-color: #0058ff;
  }

  .react-datepicker__month-text--keyboard-selected {
    color: #fff;
    background-color: #0058ff;
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    border: none;
    width: 240px;
  }

  .react-datepicker__monthPicker {
    display: grid;
    align-items: center;
    grid-gap: 10px;
  }

  .react-datepicker__month-text {
    font-size: 12px;
    font-weight: 700;
    width: 56px;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;

    &:hover {
      color: #0058ff;
      border-radius: 4px;
      background-color: rgba(0, 88, 255, 0.3);
    }
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column;
    border: none;

  }

  .react-datepicker__year-select {
    width: 72px;
    height: 30px;
    border: none;
    border-radius: 4px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 14px;
    cursor: pointer;
    outline: none;

    button {
      display: none;
    }
  }

  .react-datepicker__current-month {
    display: flex;
    justify-content: center;
    //달력 문구 색상
  }

  .react-datepicker__current-year {
    display: flex;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 14px;
  }

  .react-datepicker__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    //margin-outside: 8px;
    padding: 0 0 8px 0;
    font-family: 'NanumGothicOTF', sans-serif;
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: 700;
    z-index: 100;
  }

  .react-datepicker-time__input {
    outline: none;

    input[type="time"] {
      border: none;
      color: #2a2c2d;
      font-size: 14px;
      width: 170px;
    }

    /* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */

    input[type=time]::-webkit-datetime-edit-fields-wrapper {
      display: flex;
    }

    /* The space between the fields - between hour and minute, the minute and 
    second, second and am/pm */

    input[type=time]::-webkit-datetime-edit-text {
      padding: 8px 4px;
    }

    /* The naming convention for the hour, minute, second, and am/pm field is
    \`-webkit-datetime-edit-{field}-field\` */

    /* Hour */

    input[type=time]::-webkit-datetime-edit-hour-field {
      background-color: #f2f4f5;
      border-radius: 15%;
      padding: 8px;
    }

    /* Minute */

    input[type=time]::-webkit-datetime-edit-minute-field {
      background-color: #f2f4f5;
      border-radius: 15%;
      padding: 8px;
    }

    /* AM/PM */

    input[type=time]::-webkit-datetime-edit-ampm-field {
      background-color: rgba(0, 88, 255, 0.9);
      border-radius: 15%;
      color: #fff;
      padding: 8px;
    }

    /* 'X' button for resetting/clearing time */

    input[type=time]::-webkit-clear-button {
      display: none;
    }

    /* Up/Down arrows for incrementing/decrementing the value */

    input[type=time]::-webkit-inner-spin-button {
      display: none;
    }


  }

  //.date_picker_header{
  //  display: flex;
  //  font-family: 'NanumGothic' ,san serif;
  //}


  .react-datepicker-popper {
    z-index: 50;
  }

  .react-datepicker-popper[data-placement^=top]
  .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^=bottom]
  .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^=top]
  .react-datepicker__triangle::after,
  .react-datepicker-popper[data-placement^=bottom]
  .react-datepicker__triangle::after {
    border: none;
  }

  .react-datepicker__navigation--previous {
    cursor: pointer;
    border: none;
    display: flex;
    width: 20px;
    height: 20px;
    //padding-left: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: url(${left});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }

  .react-datepicker__navigation--next {
    cursor: pointer;
    display: flex;
    border: none;
    width: 20px;
    height: 20px;
    //padding-right: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: url(${right});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }

  .react-datepicker__navigation--previous--year {
    cursor: pointer;
    border: none;
    display: flex;
    width: 24px;
    height: 24px;
    padding-left: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: url(${left});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }

  .react-datepicker__navigation--next--year {
    cursor: pointer;
    display: flex;
    border: none;
    width: 24px;
    height: 24px;
    padding-right: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: url(${right});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .react-datepicker__week {
    display: flex;
    font-size: 14px;
    font-weight: normal;
    font-family: "Nanum Gothic", sans-serif;
  }

  .date_select {
    width: 72px;
    height: 20px;
    border: none;
    border-radius: 4px;
    font-family: "Nanum Gothic", sans-serif;
    font-size: 14px;

    cursor: pointer;
    outline: none;


    option {
      font-family: "Nanum Gothic", sans-serif;
      font-size: 14px;
      outline: none;
      border: none;

    }


  }

  .react-datepicker__time-container {

    display: flex;
    flex-direction: column;

    border-radius: 6px;
    box-sizing: border-box;
    border: none;
    width: 90px;
    height: 100%;
    padding: 0;

  }

  .react-datepicker__header--time {
    padding: 0;
    height: 45px;
    background-color: ${props => props.auth ? '#cffa61' : '#f5f6fa'};

  }

  .react-datepicker-time__header {
    padding: 8px 0;
    //margin-top: 8px;
  }

  .react-datepicker-time__header {
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    font-family: "Nanum Gothic", sans-serif;
    color: #000000;
    width: 85px;
    height: 32px;
    line-height: 32px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }


  .react-datepicker__time-box {
    display: flex;
    width: 90px;
    height: 100%;
    border: none;
    font-family: "Nanum Gothic", sans-serif;
    font-weight: normal;
    cursor: pointer;
    outline: none;
    text-align: center;
    align-items: center;
  }

  .react-datepicker__time-list {
    &::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 8px;
      height: 8px;
      border-radius: 2px;

    }
  }

  .react-datepicker__time-list-item {
    align-items: center;
    display: flex;
    justify-content: center;
  }


  .react-datepicker__time-box--selected {
    background-color: #0058Ff;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #0058Ff;
  }


  .react-datepicker__day--selected {
    background-color: #0058Ff;
    outline: none;
  }

  .react-datepicker__day--selected:hover {
    background-color: #0058Ff;
  }

  .react-datepicker__day--selected.react-datepicker__day--in-selecting-range {
    background-color: #0058Ff;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: #0058Ff;
  }
`
export const AlarmCircle = styled.div<{ status?: string, size?: number }>`
  width: ${props => props.size ?? 8}px;
  height: ${props => props.size ?? 8}px;
  border-radius: 50%;
  background-color: ${
          props => props.status === 'check' ? '#FFC700' :
                  props => props.status === 'timeout' ? '#F0142F' : ''};
`
export const BaseCard = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 1px 4px #15223214;
  padding: 16px 16px 13px 16px;
  color: #171725;
  overflow: hidden;
  font-family: 'Nanum Gothic', sans-serif;
  position: relative;


  .card {
    &_title {
      font-size: 14px;
      font-weight: bold;
      color: #171725;
      font-family: 'Nanum Gothic', sans-serif;
      white-space: break-spaces;
    }

    &_sub {
      font-size: 12px;
      font-weight: normal;
      color: #171725;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &_data {
      font-size: 16px;
      font-weight: 800;
      white-space: nowrap;
    }

    &_status {
      font-size: 24px;
      font-weight: 800;
    }

    &_unit {
      font-size: 10px;
      font-weight: normal;
      color: rgba(19, 21, 35, 0.5);
      margin-left: 4px;
    }
    &_content{
      &::-webkit-scrollbar {
        display: block;
        width: 15px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }
      &::-webkit-scrollbar-track{
        width: 21px;
        background: transparent;
      }
    }
  }
  .xButton{
    width:40px;
    height:40px;
    cursor:pointer;
    position:absolute;
    box-sizing: border-box;


    &:hover{
      border-radius: 6px;
      border: 1px solid #0058FF;
    }
  }
`

export const multipleImageGridStyle = {display:'grid', gridTemplateColumns:'repeat(2, 168px)',gap:'16px'}

export type GridTableProps = {
  tempCol?: string
  gridGap?:number
  selected?: boolean
  height?: number
  scrollWidth?: number
  disabled?: boolean
  scrollX_Hide?:boolean
  scrollY_Hide?:boolean
  titleCheckDisabled?: boolean
  logCenterLine?: boolean
  visible?: boolean
  errorState?: boolean
  marginCus?:number
  showMore?: boolean
  showMoreDisabled?: boolean
  mergeTempCol?: string
  checkboxNumber?: string|number
  bottomLine?: boolean
}


export const CheckboxStyle = styled.div`
  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D7DBEC;
    outline: none;
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    align-self: center;

    &:checked {
      border: transparent;
      background-color: #0058Ff;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-position: center;
    }
`


export const GridTableComponent = styled.div<GridTableProps>`
  overflow-x: ${props => props.scrollX_Hide ? 'hidden' : 'scroll'};
  overflow-y: ${props => props.scrollY_Hide ? 'hidden' : 'scroll'};
  height: ${props => props.height}px;
  margin-top: ${props => props.marginCus ?? 16}px;
};

&::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  display: block;
  background-color: #fff;
}

&::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  visibility: ${props => props.visible ? 'hidden' : 'visible'};
}

&::-webkit-scrollbar-track {
  width: 10px;
  height: 8px;
  border-radius: 10px;
  background-color: #f5f6fa;
  visibility: ${props => props.visible ? 'hidden' : 'visible'};
}

input[type='checkbox'] {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #D7DBEC;
  outline: none;
  box-sizing: border-box;
  background-color: ${props => props.titleCheckDisabled ? '#f5f6fa' : '#fff'};
  cursor: pointer;
  align-self: center;

  &:checked {
    border: transparent;
    background-color: #0058Ff;
    background-image: url(${checked});
    background-repeat: no-repeat;
    background-position: center;
  }
}

.grid-title {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1200}px;
  gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  background-color: #f5f6fa;
  font-weight: 800;
  align-items: center;
  box-sizing: border-box;
  user-select: none;
  position: sticky;
  top: 0;
  z-index: 48;

  & > div {
    height: 100%;
    background-color: #f5f6fa;
  }

  .checkbox {
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f6fa;
    z-index: 45;
    pointer-events: ${props => props.titleCheckDisabled ? 'none' : 'inherit'};
  }

  .sticky {
    position: sticky;
    right: 0;
    background-color: #D7DBEC;
    z-index: 45;
    align-items: center;
    display: flex;
    height: 48px;
    box-sizing: border-box;
  }

}

.grid-list, .grid-row-list {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  gap: ${props => props.gridGap ?? 16}px;
  width: ${props => props.scrollWidth ?? 1200}px;
  height: 48px;
  font-size: 14px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 14px);
  box-sizing: border-box;
  position: relative;

  & > div {
    overflow: hidden;
    word-break: keep-all;
    white-space: pre-line;
    align-self: center;
  }

  //&::after{
  //  content: '';
  //  height: 100%;
  //  width:1px;
  //  right:0;
  //  position: absolute;
  //  background-color: #0058ff;
  //}
  .checkbox {
    position: sticky;
    left: 0;
    background-color: #f5f6fa;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    z-index: 45;
  }

  .selectCheckbox {
    position: sticky;
    left: 0;
    background-color: #f5f6fa;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    z-index: 45;
  }

  .pencil-icon {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;
    padding-right: 12px;
    //z-index테스트
    z-index: 45;
    & > img {
      background-color: #fff;
    }
  }

  .select-icon {
    display: grid;
    grid-template-columns: repeat(2, 24px);
    gap: 10px;
    justify-content: flex-end;
    padding-right: 12px;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;

    & > img {
      background-color: #fff;
    }
  }

  &.grid-row-list {
    .checkbox {
      border-left: 1px solid #f5f6fa;
    }
  }

  &:hover {
    &.grid-row-list {
      border-right: 1px solid #0058FF;
      background-color: #f1f6fe;
    }

    border-top: 1px solid #0058FF;
    border-bottom: 1px solid #0058FF;
    //background-color: #f1f6fe;
    color: #0058FF;
    font-weight: bold;
    cursor: pointer;

    .checkbox {
      border-left: 1px solid #0058FF;
    }
  }
}

.grid-title-unchecked {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1200}px;
  gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  background-color: #f5f6fa;
  font-weight: 800;
  box-sizing: border-box;
  align-items: center;
  position: sticky;
  top: 0;
  padding-left: 16px;
  z-index: 48;
  & > div {
    background-color: #f5f6fa;
  }

  .sticky_modal {
    position: sticky;
    right: 0;
    background-color: #D7DBEC;
    z-index: 45;
    align-items: center;
    display: flex;
    height: 48px;
    box-sizing: border-box;
  }

  .sticky {
    position: sticky;
    right: 0;
    background-color: #D7DBEC;
    z-index: 45;
    align-items: center;
    display: flex;
    width: 220px;
    height: 100%;
    padding-left: 16px;
    box-sizing: border-box;
  }
}

.grid-title-unchecked-merge {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  width: ${props => props.scrollWidth ?? 1200}px;
  gap: ${props => props.gridGap ?? 16}px;
  height: 48px;
  font-size: 12px;
  //min-height: 16px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 12px);
  background-color: #f5f6fa;
  font-weight: 800;
  box-sizing: border-box;
  align-items: center;
  position: sticky;
  top: 0;
  padding-left: 16px;
  z-index: 48;
  & > div {
    background-color: #f5f6fa;
  }

  .merge-title{
    display: grid;
    grid-template-columns: ${props => props.mergeTempCol};
  }

}


.grid-list-unchecked, .grid-list-unchecked-no_icon, .grid-list-unchecked-merge {
  display: grid;
  grid-template-columns: ${props => props.tempCol};
  gap: ${props => props.gridGap ?? 16}px;
  width: ${props => props.scrollWidth ?? 1200}px;
  height: 48px;
  font-size: 14px;
  //min-height: 12px;
  //height: 2.5vw;
  //max-height: 48px;
  //font-size: clamp(10px, 1vw, 14px);
  box-sizing: border-box;
  position: relative;
  padding-left: 16px;
  border-left: 1px solid #fff;

  & > div {
    //overflow: hidden;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    //align-self: center;

    overflow: hidden;
    word-break: break-word;
    white-space: pre-line;
    align-self: center;
  }

  &.grid-list-unchecked-merge{
    box-sizing: border-box;
    height: auto;
    min-height: 48px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    &:hover{
      border-top: 1px solid #0058ff;
      border-bottom: 1px solid #0058ff;
    }
    .merge-inner{
      display: grid;
      grid-template-columns: ${props => props.mergeTempCol};
      &>div{
        min-height: 48px;
        align-items: center;
        align-self: center;
        line-height: 48px;
      }
    }
  }

  .pencil-icon {
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;
    padding-right: 12px;

    & > img {
      background-color: #fff;
      z-index: 45;
    }
  }

  .select-icon {
    display: grid;
    grid-template-columns: repeat(2, 24px);
    gap: 10px;
    justify-content: flex-end;
    padding-right: 12px;
    box-sizing: border-box;
    position: sticky;
    right: 0;
    height: 100%;
    align-items: center;


    & > img {
      background-color: #fff;
    }
  }

  &:hover {
    &.grid-list-unchecked-no_icon, &.grid-list-unchecked-merge {
      border-right: 1px solid #0058FF;
    }

    border-left: 1px solid #0058FF;
    //background-color: #f1f6fe;
    color: #0058FF;
    font-weight: bold;
    cursor: pointer;
  }
}
`

export const GridTableSelect = styled.div<GridTableProps>`
  opacity: ${props => props.disabled ? 0.5 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'inherit'};
  color: ${props => props.selected? '#0058ff' : '#171725'};
  font-weight: ${props => props.selected? 700 : 400};
  background-color: ${props => props.selected? '#f1f6fe' : props.errorState? 'rgb(240, 20, 47)' : 'auto'};
  border-top: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
  border-bottom: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
  //user-select: none;
    // z-index: ${props => props.selected? 47 : 'none'};
  .selectCheckbox {
    border-left: 1px solid ${props => props.selected? '#0058ff' : '#f5f6fa'};
  }
  &.grid-list-unchecked , &.grid-list-unchecked-no_icon{
    border-left: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
    color: ${ props => props.logCenterLine? '#7e84a3' :  props.selected? '#0058ff'  : props.errorState? '#fff' : '#171725'};
    user-select: ${props => props.logCenterLine? 'none' : 'auto'};
    &::before{
      position: absolute;
      content: '';
      height: ${props => props.logCenterLine? 1 : 0}px;
      top: 24px;
      width: 100%;
      background-color: #7E84A3;
    }

    &:hover{
      background-color: ${props => props.selected? '#cddffd' : '#f1f6fe'};
    }
  }

  &.grid-list-unchecked-no_icon{
    border-right: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
    color: ${ props => props.selected? '#0058ff' :  'auto'};
  }
  &.grid-row-list{
    border-left: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
    border-right: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
    color: ${ props => props.logCenterLine? '#7e84a3' :  props.selected? '#0058ff': props.errorState? '#fff' : '#171725'};
    user-select: ${props => props.logCenterLine? 'none' : 'auto'};

    &:hover{
      background-color: ${props => props.selected? '#cddffd' : '#f1f6fe'};
    }
  }
  .select-icon, .pencil-icon {
    border-right: 1px solid ${props => props.selected? '#0058ff' : '#fff'}
  }

  .sticky {
    position: sticky;
    right: 0;
    background-color: ${props => props.selected? '#f1f6fe' : '#fff'};
    border-right: 1px solid ${props => props.selected? '#0058ff' : 'auto'};
    z-index: 45;
    align-items: center;
    display: flex;
    width: auto;
    height: 100%;
    padding-left: 16px;
    box-sizing: border-box;


    &:hover {
      background-color: #f1f6fe;
      border-right: 1px solid #0058ff;
      //border-top : 1px solid #0058ff;
      //border-bottom:  1px solid #0058ff;
    }
  }

  &:hover {
    border-top: 1px solid #0058FF;
    border-bottom: 1px solid #0058FF;
    background-color: #f1f6fe;
    color: #0058FF;
    font-weight: bold;
    cursor: pointer;
    .selectCheckbox{
      border-left: 1px solid #0058FF;
    }

    .pencil-icon, .select-icon {
      border-right: 1px solid #0058ff;
    }
  }
`

export const listResultMessage = (auth: boolean, message:string, size: {width: number, admin:number, user:number}, addRow?:()=>void , disabled?:boolean, buttonName?: string) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', position:'absolute',width:`${size.width}px`,
      height: `${auth? size.admin: size.user}px`}}>
      {addRow && <MainButtons name={buttonName ?? '행추가'} disabled={disabled} clickEvent={addRow}/>}
      <NoData height={60}>{message}</NoData>
    </div>
  )
}

export const monitoringTable = styled.div`
  display: grid;
  grid-template-rows:repeat(20, 21px);
  grid-auto-flow: column;
`

export const FacebookCircularProgress = (props: CircularProgressProps) => {
  return (
    <div>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={80}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={80}
        thickness={4}
        {...props}
      />
    </div>
  );
}

export const createLegend = (text:string, borderColor:string, borderType?:string) => {
  return (
    <div style={{display: 'flex' , alignItems: 'center' , margin: '0 10px'}}>
      <div style={{
        borderStyle: borderType ,
        borderWidth: '1px' ,
        borderColor: borderColor ,
        width: '16px' ,
        marginRight: '4px'
      }}/>
      <div style={{fontSize: '12px' , color: 'rgba(126, 132, 163, 1)'}}>{text}</div>
    </div>
  )
}

export const createBoard = (column?:string,title?:string, value?:any,unit?:string, color?:string, line?:boolean,fontSize?:string,subColor?:string,subTitle?:string,subValue?:any,) => {
  switch (column) {
    // case 'threeCol':
    //   return (
    //     <div>
    //       <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr',boxSizing:'border-box',width:'248px',alignItems:'center',gridGap:'10px',padding:'0 8px',height:'42px'}}>
    //        <div style={{display:'grid',gridTemplateRows:'1fr 1fr',boxSizing:'border-box',gridRowGap:'10px'}}>
    //          <div className={'card_sub'} style={{fontSize:'14px'}}>{(title)}</div>
    //          <div className={'card_sub'} style={{fontSize:'20px',fontWeight:'700',color:color,whiteSpace:'nowrap'}}>{(value)}</div>
    //        </div>
    //       </div>
    //     </div>
    //   )
    case 'twoCol':
      return (
        <div>
          <div style={{display: 'grid', gridTemplateColumns:'1fr 1fr', boxSizing:'border-box',width:'276px',alignItems:'center',gridGap:'10px',padding:'0 8px',height:'42px'}}>
            <div style={{display: 'grid', gridTemplateColumns:'1fr 1fr',boxSizing:'border-box'}}>
              <div className={'card_sub'} style={{fontSize:'14px'}}>{(title)}</div>
              <div className={'card_sub'} style={{fontSize:'14px',fontWeight:'700',color:color,whiteSpace:'nowrap'}}>{(value)}</div>
            </div>
            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr' ,boxSizing:'border-box'}}>
              <div className={'card_sub'} style={{fontSize:'14px'}}>{(subTitle)}</div>
              <div className={'card_sub' } style={{fontSize:'14px',fontWeight:'700',color:subColor,whiteSpace:'nowrap'}}>{(subValue)}</div>
            </div>
          </div>
          {line && <div style={{marginTop:'8px',display:'flex',width:'248px',height:'1px',boxSizing:'border-box',backgroundColor:'#E2E2E2'}}/>}
        </div>
      )
    case 'oneCol':
      return (
        <div>
          <div style={{display: 'grid', gridTemplateColumns:unit?'1fr 2fr':'1fr 1fr',boxSizing:'border-box',alignItems:'center',width:'248px'}}>
            <div className={'card_sub'} style={{fontSize:'14px'}}>{(title)}</div>

            <div style={{display:'flex',justifySelf:'flex-end',alignItems:'center'}}>
              <div className={'card_sub'} style={{justifySelf:'flex-end',fontSize:fontSize??'20px',fontWeight:'800',color:color,whiteSpace:'nowrap'}}>{(value)}</div>
              {unit ?
                <div className={'card_sub'} style={{marginLeft:'4px',fontSize:'14px',color:'rgba(0, 0, 0, 0.5)',whiteSpace:'nowrap'}}>{(unit)}</div>
                : null}
            </div>
          </div>
          {line && <div style={{marginTop:'8px',display:'flex',width:'248px',height:'1px',boxSizing:'border-box',backgroundColor:'#E2E2E2'}}/>}
        </div>
      )
    default:
      return (
        <div>
        </div>
      )
  }
}
export const helperModule = (children: React.ReactNode,width?:string,margin?:string,top?:string,left?:string) => {
  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const nextSibling = e.currentTarget.nextSibling as HTMLElement;
    if (children && nextSibling) {
      nextSibling.style.visibility = 'visible';
      nextSibling.style.opacity = '1';
    }
  };
  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const nextSibling = e.currentTarget.nextSibling as HTMLElement;
    if (nextSibling) {
      nextSibling.style.visibility = 'hidden';
      nextSibling.style.opacity = '0';
    }
  };
  return (
    <div style={{position: 'relative', userSelect:'none'}}>
      <HelperIcon onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} margin={margin}>
        {'i'}
      </HelperIcon>
      {children && (
        <HelperContent style={{visibility: 'hidden', opacity: 0}} top={top} left={left} width={width}>
          {children}
        </HelperContent>
      )}
    </div>
  );
};

export const HelperIcon = styled.div<{margin?:string}>`
  margin: ${props => props.margin ?? '8px 0 0 8px'};
  position: relative;
  line-height: 18px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #5A607F;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #5A607F;
  padding-top: 2px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  user-select: none;


  &:hover {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: #0058ff;
    border: 1px solid #0058ff;
    color: #fff;
  }
`

const HelperContent = styled.div<{width?: string,top?:string,left?:string}>`
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: ${props => props.top ?? '-8px'};
  left: ${props => props.left ?? '34px'};
  width: ${props => props.width ?? 'auto'};
  min-width: 200px;
  height: auto;
  background-color: #F5F6FA;
  border-radius: 6px;
  box-shadow: 0 1px 4px #15223214;
  padding: 16px;
  border: 1px solid #E6E9F4;
  color: #171725;
  font-size: 12px;
  z-index: 999;
  white-space: pre-line;
  line-height: 1.5;

`;

export const CheckBoxComp= styled.div<{checked: any, textColor: string}>`
  display: flex;
  align-items: center;
  height: 30px;

  .checkBox{
    width: 16px;
    height: 16px;
    border: 1px solid ${ props => props.checked? '#0058FF' : '#7E84A3'};
    gap: 8px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    &::before{
      display: ${ props => props.checked? '' : 'none'};
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #0058FF;
      background-image: url(${checked});
      background-position: center;
      background-repeat: no-repeat;
    }

  }

  .optionText{
    margin-left: 8px;
    font-weight: 400;
    font-size: 12px;
    color: ${ props => props.textColor};
    line-height: 20px;
    user-select: none;
  }

`

export const KioskButtonBase = styled.div<{backColor?:string}>`
  font-size: 1.6667vw;
  background-color: ${props => props.backColor? props.backColor : '#32384E'};
  color: #D7DBEC;
  border-radius: 0.83334vw;
  user-select: none;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 11.6667vw;
  height: 4.58334vw;
  padding: 0 1.875vw;
  justify-content: center;
  font-family: Pretendard, -apple-system, sans-serif;
  text-align: center;
`

const ToggleButtonContainer = styled.div`
  position: relative;
  width: 11.6667vw;
  height: 4.58334vw;
`;

const KioskButton1 = styled(KioskButtonBase)`
  background-color: ${props => props.backColor ? props.backColor : '#32384E'};
`;

const KioskButton2 = styled(KioskButtonBase)`
  background-color: ${props => props.backColor ? props.backColor : '#FF5733'};
`;



export const CheckBox = styled.input<{ titleCheckDisabled?: boolean, icons?: string }>`
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #D7DBEC;
  outline: none;
  box-sizing: border-box;
  background-color: ${props => props.titleCheckDisabled ? '#f5f6fa' : '#fff'};
  cursor: pointer;
  align-self: center;
  margin-right: 8px;

  &:checked {
    border: transparent;
    background-color: #0058Ff;
    background-image: ${props => props.icons};
    background-repeat: no-repeat;
    background-position: center;
  }
}
`

export const ViewImageText = styled.div`
  font-size: 12px;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #7E84A3;
  pointer-events: initial;

  &:hover {
    color: #0058FF;
  }
`


