import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
	GridTableComponent , GridTableSelect ,
	NoData ,
	tooltipModule , validationValue
} from "../../style/StyleComponent";
import SearchBar from "../input/SearchBar";
import {useTranslation} from "react-i18next";
import {dataValid , initModal , loadingRemove , modalListLoading} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import ConfirmPopup from "./ConfirmPopup";
import SelectConfirmModal from "./SelectConfirmModal";

interface Props {
	closeButton:()=>void
	accessButton?:(v:any)=>void
	companyName?: string
}

const KepcoNOSearchModal:React.FunctionComponent<Props> = ({closeButton, companyName,
																														 accessButton }) => {

	const [searchInputState , setSearchInputState] = React.useState<{input:string, value: string}>( {input:'', value:''})
	const [kepcoTempData, setKepcoTempData] = useState([])

	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)

	const scrollRef = React.useRef<any>(null)
	const [selectCompany,setSelectCompany]= useState<any> ([])
	const onChangeInput = (e:any) =>{
		setSearchInputState((prev)=>({...prev, input:e.target.value.replace(/[^0-9]/g, '').trimStart()}))
	}
	const { t }:any = useTranslation();

	const customerNumberCheckRes = async ()=>{
		setKepcoTempData([])
		try {
			modalListLoading()
			const response = await client.get(`/company/search/${dataValid(searchInputState.value,'@')}`,{headers:{Authorization}})
			console.log(response.data)
			const data = response.data.data
			setKepcoTempData(data.row)
			loadingRemove()
		}catch (e) {
			loadingRemove()
			console.log(e)
		}
	}
	useEffect(()=>{
		customerNumberCheckRes().then()
	},[searchInputState.value])
	console.log(selectCompany.companyName !== companyName)
	return (
		<ModalBase modalContent={
			<React.Fragment>
				{dataModal.menu === 'confirm' &&
					<ConfirmPopup statusText={dataModal.message}
												clickEvent={()=>setDataModal(initModal)}/>}

				{dataModal.menu === 'select' &&
					<SelectConfirmModal statusText={dataModal.message}
															noticeText={'선택한 고객번호로 업체 정보를 수정합니다.'}
															cancelEvent={()=>setDataModal(initModal)}
															clickEvent={()=>{
																if( accessButton ){
																	accessButton( selectCompany )
																}
															}}/>}
				<BaseComponent>
					<div style={{backgroundColor:`${window.localStorage.getItem('auth')?.includes('admin')? '#CFFA61' : '#D7DBEC'}`}}
							 className={'modal_title'}>{t('한전 고객번호 검색')}</div>
					<div className={'modal_list'}>
						<div>

							<div style={{alignItems:'center',display:'grid',gridTemplateColumns:'50px 1fr',gap:'4px',width:'400px'}}>
								<div style={{fontSize:'12px',fontWeight:'bold'}}>{t('고객번호')}</div>
								<SearchBar widthCus={'300px'} placeholder={t('고객번호를 입력해 주세요.')}
													 value={searchInputState.input} clearButton={()=>{setSearchInputState({input:'', value:''})}}
													 keyFunction={onChangeInput} limit={10} clearButtonAccess={searchInputState.input !== ''}
													 clickEvent={()=>setSearchInputState((prev)=>({...prev, value: prev.input}))}/>
							</div>
							<GridTableComponent id={'grid-scroll'} ref={scrollRef}
																	titleCheckDisabled={true} scrollWidth={358} scrollX_Hide={true}
																	height={288}
																	tempCol={'120px 120px 1fr'}>
								<div className={'grid-title-unchecked'} >
									{['고객번호','업체명','F-EMS'].map((name) =>
																								<div>{t(name)}</div>
									)}
								</div>
								{kepcoTempData.length !== 0?
									kepcoTempData.map((v:any, i:number)=>(
										<GridTableSelect className={'grid-list-unchecked-no_icon'}
																		 selected={v.custNo === selectCompany.custNo}
																		 onDoubleClick={()=> {
																			 if(selectCompany.custCheck === 'use'){
																				 setDataModal({menu:'confirm', message:'이미 F-EMS 에 등록된 업체입니다.'})
																			 }else{
																				 if(selectCompany.companyName !== companyName){
																					 setDataModal({menu:'select', message:'선택한 업체와의 업체명이 다릅니다. \n 등록을 진행할까요?'})
																				 }else{
																					 setDataModal({menu:'select', message:'선택한 업체의 고객번호가 맞습니까?'})
																				 }
																			 }
																		 }}
																		 onMouseDown={()=>{setSelectCompany(v)}} key={i}>
											{tooltipModule(validationValue(v.custNo), 10)}
											{tooltipModule(validationValue(v.companyName), 10)}
											{validationValue(v.custCheck === 'use'? t('연결') : '-')}
										</GridTableSelect>
									))
									:
									<NoData height={240}>{t('일치하는 검색결과가 없습니다.')}</NoData>}
							</GridTableComponent>
						</div>

						<div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
							<MainButtons name={t('취소')} width={'144px'}
													 buttonType={'popupNormal'} clickEvent={closeButton}/>
							<MainButtons name={t('선택')} width={'144px'}
													 buttonType={'popupCta'} clickEvent={()=>{
								if(selectCompany.custCheck === 'use'){
									setDataModal({menu:'confirm', message:'이미 F-EMS 에 등록된 업체입니다.'})
								}else{
									if(selectCompany.companyName !== companyName){
										setDataModal({menu:'select', message:'선택한 업체와의 업체명이 다릅니다. \n 등록을 진행할까요?'})
									}else{
										setDataModal({menu:'select', message:'선택한 업체의 고객번호가 맞습니까?'})
									}
								}
							}
							}/>
						</div>
					</div>
				</BaseComponent>
			</React.Fragment>

		}/>
	);
};

export default KepcoNOSearchModal;

const BaseComponent = styled.div`
  position: absolute;
  width: 416px;
  height: 512px;
  box-sizing: border-box;
  
  .modal_title{
    height: 56px;
    background-color: #CFFA61;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 4px #15223214;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    font-weight: 900;
    font-size: 18px;
	  justify-content: space-between;
	  user-select: none;
  }
  
  .modal_list{
    display: flex;
    flex-direction: column;
		justify-content: space-between;
		width: auto;
	  position: relative;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    background-color: #fff;
    height: 456px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 0 0 6px 6px;
  }
  
  .table_title{
    background-color: #F5F6FA;
    line-height: 48px;
    padding-left: 16px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 120px 64px 64px 88px;
    font-size: 12px;
    font-weight: 900;
  }
  
  .scroll_var{
	  width: 392px;
	  bottom: 96px;
	  height: 240px;
	  position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;

	  &>div>div{
		  white-space: pre;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  margin: 0;
	  }
    &::-webkit-scrollbar {
      width: 24px;
			display: block;
	    background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E6E9F4;
      border-radius: 16px;
      background-clip: padding-box;
      border: 8px solid transparent;
    }
  }
`
