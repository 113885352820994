import React, {useState} from 'react';
import MenuTitle from "../../../components/title/MenuTitle";
import {
  ContentFrame ,
  NoData ,
  SectionFrame ,
  validationValue ,
} from "../../../style/StyleComponent";
import PaginationList from "../../../components/pagination/PaginationList";
import {useTranslation} from "react-i18next";
import {authValid , dataValid , initError} from "../../../function/function";
import {Error_Type} from "../../../types/Fundamental";
import client from '../../../common/Config'
import {Authorization} from '../../../common/authorization'
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../common/StateManage";
import CalendarValidButton from "../../../components/button/CalendarValidButton";
import dayjs from "dayjs";
import {GridTableComponent, GridTableSelect} from "../../../style/StyleModule";


interface LogDataType {
  activity?: string,
  ip?: string,
  companyName?: string,
  modifiedDate?: string,
  id?: string,
  menu?: string,
  no?: string
}

const SmartFactoryLogContainer = () => {

  const [page, setPage] = useState<number>(1)
  const [pageBlock,setPageBlock] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [errorState,setErrorState] = useState<Error_Type>(initError)
  const [logDataList, setLogDataList] = useState<LogDataType[]>([])
  const [dateState, setDateState] = useState(dayjs().toDate())
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )

  const { t }:any = useTranslation();
  const logMenu = ['로그No.','업체명','아이디','IP주소','메뉴명','기능','해당 일시']

  const conversionMenu = (menu: string) => {
    switch (menu){
      case 'logIn' : return '로그인'
      case 'logOut' : return '로그아웃'
      case 'rawMaterial' : return '원자재'
      case 'subMaterial' : return '부자재'
      case 'workOrder' : return '작업지시서'
      case 'workHistory' : return '작업이력'
      case 'orders' : return '수주'
      case 'delivery' : return '출하'
      case 'KPIProduction' : return 'KPI생산지수'
      case 'KPIDefectRate' : return 'KPI품질지수'
      case 'KPIManHours' : return 'KPI원가지수'
      case 'KPIOrdersDelivery' : return 'KPI납기지수'
      case 'KPIElectricConsumption' : return 'KPI에너지지수'
      case 'KPIProductionByYear' : return 'KPI생산지수 리포트'
      default : return dataValid(menu)
    }
  }

  const dataLogListRes = async ()=> {
    try {
      const response = await client.get(`/data/log/list/${page}/10/${dataValid(selectedCompany.companyCode,'@')}/${dayjs(dateState).format('YYYY-MM')}`,{headers:{Authorization}})
      const data = response.data.data
      setTotalPage(data.total_page)

      if(response.data.status === 200){
        setLogDataList(data.row)
        setErrorState({notice:'로그 정보가 없습니다.'})
      }
      else{
        setErrorState({notice:'로그 정보를 가져올 수 없습니다.'})
        setLogDataList([])
      }
    }
    catch (e) {
      setErrorState({notice:'* 서버 에러 입니다. 관리자에게 문의해 주세요.'})
      console.log(e)
    }
  }
  React.useEffect(()=>{
    dataLogListRes().then()
  },[page, selectedCompany.companyCode, dateState])

  return (
    <React.Fragment>
      <SectionFrame>
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <MenuTitle title={'스마트 공장 로그'} unitCase={'MenuTitle'}/>
        </div>
        <ContentFrame padding={'30px 30px 20px 30px'} justify={'space-between'}>
          <div style={{overflow:'hidden'}}>
            <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
              <MenuTitle title={'스마트 공장 로그'} unitCase={'MenuCompTitle'}/>
              <div style={{display:'flex',alignItems:'center'}}>
                <CalendarValidButton selectMonth={true} inputValue={dateState} receiveDate={setDateState}/>
              </div>
            </div>
            <GridTableComponent tempCol={'88px repeat(3, 168px) 1fr 108px 188px'}
                                scrollY_Hide={true} scrollX_Hide={true}
                                scrollWidth={1210}
                                height={538}>
              <div className={'grid-title-unchecked'}>
                {logMenu.map((v)=>(
                  <div key={v}>{t(v)}</div>))}
              </div>
              {(logDataList?.length === 0 || !logDataList)?
                <NoData>{dataValid(errorState.notice,'목록을 가져오는 중 입니다.')}</NoData>
                :
                logDataList?.map((v:any)=> (
                  <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                    {validationValue(v.no)}
                    {validationValue(v.companyName)}
                    {validationValue(v.id)}
                    {validationValue(v.ip)}
                    <div>{conversionMenu(v.menu)}</div>
                    {validationValue(v.activity)}
                    {validationValue(v.modifiedDate)}
                  </GridTableSelect> ))
              }
            </GridTableComponent>
          </div>
          {logDataList?.length !== 0 &&
            <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                            blockNum={pageBlock} setBlockNum={setPageBlock}
                            counts={totalPage}/>}
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  )
};

export default SmartFactoryLogContainer;
