import React from 'react';
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import CompanyUserManagement from "../../../containers/admin/fas/company/basic/user/CompanyUserManagement";
import {useLocation} from "react-router-dom";
import CompanyUserModify from "../../../containers/admin/fas/company/basic/user/CompanyUserModify";
import CompanyUserDetail from "../../../containers/admin/fas/company/basic/user/CompanyUserDetail";
// import CompanyUserMultipleReg from "../../../containers/admin/fas/company/basic/user/CompanyUserMultipleReg";
import UserRegistration from "../../../containers/admin/fas/hr/user/UserRegistration";

const AdminFasCompanyBasicUser = () => {
  const location = useLocation();
  const modify = location.pathname.includes('modify')
  const detail = location.pathname.includes('detail')
  const register = location.pathname.includes('register')

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {detail ?
        <SectionContainer contentContainer={<CompanyUserDetail/>}/>
        :
        modify ?
          <SectionContainer contentContainer={<CompanyUserModify/>}/>
          :
          register?
            <SectionContainer contentContainer={<UserRegistration/>}/>
            :
          <SectionContainer contentContainer={<CompanyUserManagement/>}/>
      }
    </PageContainer>
  );
};
export default AdminFasCompanyBasicUser;
