import React from 'react';
import styled from "styled-components";
import noImage from '../../asset/image/noImage.svg';
import {useTranslation} from "react-i18next";
import {dataValid} from "../../function/function";

interface Props {
	imageUrl: string
	deleteImg?: ()=>void
	noneDelete?: boolean
	radioActive?: boolean
	radioClick?: any
	profileIndex?: string
	profileChecked?: boolean
	previewSize?: number
	radiusCircle?: boolean
	clickEvent?:() => void
	margin?: number
	mobileType?:boolean
}

const PreviewImage:React.FunctionComponent<Props> = ({imageUrl, deleteImg, noneDelete,
	                                                     radioClick, profileIndex, radiusCircle,
																											 margin,mobileType,
	                                                     radioActive,clickEvent,
																											 profileChecked, previewSize}) => {

	//background 이미지로 넣을때 , src 값을 두개 넣으면 되는데 투명 배경인 경우 error 이미지가 보임.
	// img 태그로 변경함.

	const { t }:any = useTranslation();
	return (
		<div style={{marginTop: `${margin}px`}}>
			{radioActive &&
				<div
					style={{display:'flex',marginBottom:'10px',alignItems:'center',userSelect:'none'}}>
					<input type={'radio'} style={{width:'16px', height:'16px',margin:0}} name={'images'} value={profileIndex}
					       onChange={(e:any)=>radioClick(e.target.value)} checked={profileChecked} />
					<div style={{color:'rgba(17,19,25,0.3)', fontSize:'14px', fontWeight:400, marginLeft:'8px'}}>{t('대표이미지 선택')}</div>
				</div>}

			<div
					 style={{position:'relative', maxWidth: `${previewSize}px` ??'168px', width:'100%', maxHeight:'168px', margin:0, borderRadius:'6px',boxSizing:'border-box'}}>
				<BaseComponent src={dataValid(imageUrl,'')} onClick={clickEvent}
											 style={{width: `${previewSize}px`, height: `${previewSize}px`,
												 borderRadius:`${radiusCircle? '50%' : '6px'}`,
												 cursor: !imageUrl? 'default' : 'pointer',
												 pointerEvents: !imageUrl? 'none' : 'auto'}}
				               onError={(e:React.ChangeEvent<HTMLImageElement>)=>e.target.src = noImage}/>

				{!noneDelete &&
					<MobileDeleteButton onClick={deleteImg}>
						<div>
						{'╋'}
						</div>
					</MobileDeleteButton>
				}
			</div>
		</div>
	);
};

export default PreviewImage;


const BaseComponent = styled.img`
  width: 168px;
  height: 168px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #D7DBEC;
	object-fit: cover;
`
const DeleteButton = styled.div`
	position: absolute;
	font-size: 16px;
	box-shadow: rgb(21 51 52 / 14%) 0 1px 4px;
	border: 1px solid #D7DBEC;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	color: #5a607f;
	font-weight: 800;
	background-color: #F5F6FA;
	right: 4px;
	top: 4px;
	border-radius: 4px;
	transition: all 0.5s;
	user-select: none;
	&>div{
	transform: rotateZ(45deg);
	}
	&:hover {
		color: #fff;
		background-color: #979797;
	}
`
const MobileDeleteButton = styled.div`
	position: absolute;
	font-size: 16px;
	color:#fff;
	font-weight: normal;
	text-align: center;
	background-color: #7E84A3;
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 4px;
	right: 1px;
	top: 1px;
	&>div{
		transform: rotateZ(45deg);
	}
`