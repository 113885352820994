import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import client from "../../../../../common/Config";
import checked from '../../../../../asset/image/check-icon.svg';
import {ContentFrame, SectionFrame,femsInfoStyle} from "../../../../../style/StyleComponent";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {Authorization} from "../../../../../common/authorization";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import DaumPostcode from "react-daum-postcode";
import ModalBase from "../../../../../components/modal/ModalBase";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {
	dataAccessLoading , dataValid ,
	initModal , loadingRemove, phoneNumberFormat
} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {
	emailReg ,
	IdDuplicate ,
	idReg ,	onIdCheckValid , onPwCheckValid ,
	PasswordCheck ,
	passwordReg ,
	phoneReg,
	specialCharExcludeReg
} from "../../../../../ValidationCheck";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import KepcoNOSearchModal from "../../../../../components/modal/KepcoNOSearchModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";

const BasicRegistration = () => {

	const solutionArray = ['P-BAS','P-MS','R-BAS','C-BAS','C-MS','V-MS','A-BAS','F-EMS']
	const [password, setPassword] = React.useState({text:'*비밀번호 확인을 해주세요.' , color:''});
	const [idCheck, setIdCheck] = React.useState({text:'*ID 중복확인을 해주세요.', color:''});
	const [idCheckNormal, setIdCheckNormal] = React.useState<boolean>(false);
	const [pwCheckNormal, setPwCheckNormal] = React.useState<boolean>(false);
	const [imageState,setImageState] = React.useState<string[]>([]);
	const [logoState,setLogoState] = React.useState<string[]>([]);
	const [modalState, setModalState] = React.useState<boolean>(false)
	const [authorizationArray, setAuthorizationArray] = useState(['FAS'])
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const { t }:any = useTranslation();

	const [customerRegister, setCustomerRegister] = useState<any>(
		{
			companyName: '',
			president: '',
			address:'',
			detailAddress:'',
			managerName: '',
			managerNumber: '',
			phone_number: '',
			email:'',
			id: '',
			password: '',
			latitude:'',
			longitude:'',
			passwordCheck: '',
			notice: '', custNo:'',
			midaPress: '0',
		})
	const onCheckedAuthorization = (checked:any, item:any) =>{
		if(checked){
			// @ts-ignore
			setAuthorizationArray([...authorizationArray,item])
		}else{
			setAuthorizationArray(authorizationArray.filter(el=>el !== item));
		}
	};

	const onCheckedMidaPress = (checked:any) =>{
		if(checked){
			setCustomerRegister({...customerRegister, midaPress: '1'})
		} else{
			setCustomerRegister({...customerRegister, midaPress: '0'})
		}

	}

	const [femsInfo, setFemsInfo] = useState<boolean>(false)
	useEffect(()=>{
		if(authorizationArray.includes('F-EMS')){
			setFemsInfo(true)
		}else{
			setFemsInfo(false)
		}
	} ,[authorizationArray])

	const onChange = (e: { target: { name: string; value: string } }, trim?: boolean) => {
		const {name, value} = e.target
		setCustomerRegister({
			...customerRegister,
			[name]: trim? value.trim() : value
		})
	}


	const onCheckId = (e: { target:{name: string, value:string }}) =>{
		const {name,value} = e.target;
		setCustomerRegister({
													...customerRegister,
													[name]:value.trim()
												})
		onIdCheckValid(value,setIdCheck)
		setIdCheckNormal(false)
	}


	const onCheckPassword = (e: { target: { name: string; value: string } }) => {
		const {name, value} = e.target
		setCustomerRegister({
												 ...customerRegister,
												 [name]: value.trim()
											 })
		onPwCheckValid(value,setPassword)
		setPwCheckNormal(false)
	}

	const imageDataValue = (data: string[]) => {
		if(data === null){
			return null
		}else{
			return data[0]
		}
	}

	const customerSignUp = async () => {
		// if(!customerRegister.id){
		// 	setDataModal({menu:'confirm', message:'아이디를 입력해 주세요.'})
		// }
		// else if(!idReg.test(customerRegister.id)){
		// 	setDataModal({menu:'confirm', message:'아이디 형식은 4~20자 \n 영문, 영문+숫자 입력만 가능합니다.'})
		// }
		// else if(!idCheckNormal || idCheck.text.includes('중복확인을')){
		// 	setDataModal({menu:'confirm', message:'아이디 중복확인을 해주세요.'})
		// }
		// else if(!customerRegister.password){
		// 	setDataModal({menu:'confirm', message:' 비밀번호를 입력해 주세요.'})
		// }
		// else if(!passwordReg.test(customerRegister.password)){
		// 	setDataModal({menu:'confirm', message:'비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
		// }
		// else if(!customerRegister.passwordCheck){
		// 	setDataModal({menu:'confirm', message:' 비밀번호를 한번 더 입력해 주세요.'})
		// }
		// else if(!pwCheckNormal){
		// 	setDataModal({menu:'confirm', message:' 비밀번호가 일치한 지 확인 해주세요.'})
		// }
		// else if(customerRegister.password !== customerRegister.passwordCheck){
		// 	setDataModal({menu:'confirm', message:' 비밀번호가 일치하지 않습니다.'})
		// }


		// 아이디,비밀번호, 권한 필수 입력 제외
		if(customerRegister.id && !idReg.test(customerRegister.id)){
			setDataModal({menu:'confirm', message:'아이디 형식은 4~20자 \n 영문, 영문+숫자 입력만 가능합니다.'})
		}
		else if(customerRegister.id && (!idCheckNormal || idCheck.text.includes('중복확인을'))){
			setDataModal({menu:'confirm', message:'아이디 중복확인을 해주세요.'})
		}
		else if(customerRegister.id && !customerRegister.password){
			setDataModal({menu:'confirm', message:' 비밀번호를 입력해 주세요.'})
		}
		else if(customerRegister.password && !passwordReg.test(customerRegister.password)){
			setDataModal({menu:'confirm', message:'비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.'})
		}
		else if(customerRegister.password && !customerRegister.passwordCheck){
			setDataModal({menu:'confirm', message:' 비밀번호를 한번 더 입력해 주세요.'})
		}
		else if(customerRegister.password && !pwCheckNormal){
			setDataModal({menu:'confirm', message:' 비밀번호가 일치한 지 확인 해주세요.'})
		}
		else if(customerRegister.password && (customerRegister.password !== customerRegister.passwordCheck)){
			setDataModal({menu:'confirm', message:' 비밀번호가 일치하지 않습니다.'})
		}
		else if(!customerRegister.companyName){
			setDataModal({menu:'confirm', message:'업체명을 입력해 주세요.'})
		}
		else if(specialCharExcludeReg.test(customerRegister.companyName)){
			setDataModal({menu:'confirm', message:'업체명에 특수 문자를 입력할 수 없습니다.'})
		}
		else if(!customerRegister.president){
			setDataModal({menu:'confirm', message:'대표자명을 입력해 주세요.'})
		}
		else if(!customerRegister.managerName){
			setDataModal({menu:'confirm', message:'담당자를 입력해 주세요.'})
		}
		// else if(!customerRegister.phoneNumber){
		// 	setDataModal({menu:'confirm', message:'전화번호를 입력해 주세요.'})
		// }
		else if(!customerRegister.phone_number){
			setDataModal({menu:'confirm', message:'업체 전화번호를 입력해 주세요.'})
		}
		else if(!phoneReg.test(customerRegister.phone_number.trim()) && customerRegister.phone_number){
			setDataModal({menu:'confirm', message:'업체 전화번호 형식이 맞지 않습니다.'})
		}
		else if(!customerRegister.managerNumber){
			setDataModal({menu:'confirm', message:'담당자 전화번호를 입력해 주세요.'})
		}
		else if(!phoneReg.test(customerRegister.managerNumber.trim()) && customerRegister.managerNumber){
			setDataModal({menu:'confirm', message:'담당자 전화번호 형식이 맞지 않습니다.'})
		}
		else if(!customerRegister.address){
			setDataModal({menu:'confirm', message:'주소를 입력해 주세요.'})
		}
		else if(!customerRegister.email){
			setDataModal({menu:'confirm', message:'이메일을 입력해 주세요.'})
		}
		else if(!emailReg.test(customerRegister.email)){
			setDataModal({menu:'confirm', message:'이메일 형식이 맞지 않습니다.'})
		}
		else{
			// setDataModal({menu:'confirm', message:'업체 등록이 됩니다'})
			try {
				dataAccessLoading()
				const response = await client.post('/company/signup', {
					...customerRegister,
					phone_number: customerRegister.phone_number.trim(),
					managerNumber: customerRegister.managerNumber.trim(),
					image: imageDataValue(imageState),
					logoImage: imageDataValue(logoState),
					authorities: !customerRegister.id? ['FAS'] : authorizationArray,
				}, {headers: {Authorization}})

				if (response.data.status !== 200) {
					setDataModal({menu:'confirm',message:t(response.data.message)});
					loadingRemove()
				} else {
					setTimeout(()=>{
						setDataModal({menu:'success',message:t('저장 되었습니다.')});
						loadingRemove()
					},500)
				}
			} catch(e:any){
				console.log(e)
				setDataModal({menu:'error',message:e.message})
				loadingRemove()
			}
		}
	}

	const addressHandle = {
		clickBtn : () => setModalState(current => !current),

		selectAddress: (data: any) => {
			customerRegister.address = data.address + (data.buildingName !== ''? ` (${data.buildingName})`:'')
			setCustomerRegister({...customerRegister,detailAddress:''})
			setModalState(false);
		},
	}

	const AddressStyle ={
		display:'block',
		width:500,
		height:600,
		position:'absolute',
		boxShadow:'0 1px 7px #E6E9F4',
		borderRadius:'8px',
		backgroundColor:'#fff',
		padding: "7px",
		zIndex: 100,
	}

	const {kakao}:any = window;

	const LocationPosition = () =>{
		//@ts-ignore
		let geocoder = new kakao.maps.services.Geocoder()
		//
		geocoder.addressSearch(customerRegister.address,function (result:any,status:any) {
			if(status === kakao.maps.services.Status.OK){
				let coords = new kakao.maps.LatLng(result[0].y , result[0].x);
				Object.assign(customerRegister, {latitude:coords.getLat(),longitude:coords.getLng()})
			}
		})
	}

	useEffect(()=>{
		LocationPosition()
	},[customerRegister.address])

	const onPwCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			PasswordCheck(customerRegister,setPassword,setPwCheckNormal);
		}
	}
	const onIdCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
		if(e.key === 'Enter'){
			IdDuplicate(customerRegister,setIdCheck,setIdCheckNormal).then()
		}
	}

	useEffect(() => {
		const handleBeforeUnload = (event:BeforeUnloadEvent) => {
			if (dataModal.menu === '') {
				event.preventDefault();
				event.returnValue = '';
			}
		};
		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [dataModal.menu]);

	return (
		<SectionFrame>
			{dataModal.menu === 'success' ?
				<ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{
					setDataModal(initModal)
					window.location.href = `/admin/fas/basic` }}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}
			{dataModal.menu  === 'confirm' &&
				<ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}

			{dataModal.menu === 'cancel' &&
				<SelectConfirmModal statusText={t(dataModal.message)}
														noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
														cancelEvent={()=>{setDataModal(initModal)}}
														viewType={true}
														clickEvent={()=>{ window.location.href = `/admin/fas/basic`}}
				/>
			}

			{modalState && <ModalBase clickEvent={()=>setModalState(false)} modalContent={
				<DaumPostcode onComplete={addressHandle.selectAddress} autoClose={false}
					//@ts-ignore
							  style={AddressStyle}/>}/>}
			{dataModal.menu === 'search' &&
				<KepcoNOSearchModal
					companyName={customerRegister.companyName}
					accessButton={(v)=> {
						Object.assign(customerRegister,{custNo: v.custNo})
						setDataModal(initModal)
					}}
					closeButton={()=>setDataModal(initModal)}/>}

			<MenuTitle title={'업체/기본정보 관리 > 업체 정보 > 등록'} unitCase={'MenuTitle'}/>
			<ContentFrame width={'1272px'} height={'680px'} padding={'30px'} flexDirection={'row'}>
			<div style={{boxSizing:'border-box', minHeight:'666px',flexDirection:'column'}}>
				<div style={{display:'flex', alignItems:'center', justifyContent:'space-between', width:'800px',marginBottom:'16px'}}>
					<MenuTitle title={'업체 정보 등록'} unitCase={'MenuCompTitle'}/>
					<div style={{display:'flex', alignItems:'center'}}>
						<MainButtons name={'취소'} width={'56px'}
												 clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
						<MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'} clickEvent={customerSignUp}/>
					</div>
				</div>
				<InputContainer>
					<div className={'scroll_func'}>
						<RegisterComponent type={'text'} name={'companyName'} value={customerRegister.companyName} required={true}
															 title={t('업체명')} onChange={(e)=>onChange(e, true)} placeholder={t('업체명을 입력해 주세요.')}/>

						<RegisterComponent type={'text'} name={'president'} value={customerRegister.president} required={true}
															 title={t('대표')} onChange={onChange} placeholder={t('대표명을 입력해 주세요.')}/>

						<RegisterComponent type={'address'} onChange={onChange} title={t('주소')} width={'72px'} required={true}
															 name={'address'} subName={'detailAddress'}
															 subValue={customerRegister.detailAddress}
															 clickEvent={addressHandle.clickBtn} value={customerRegister.address}/>

					<RegisterComponent type={'phone'} value={customerRegister.phone_number} error={false} title={t('전화번호')}
														 onChange={onChange} name={'phone_number'} required={true}
														 numberFormat={customerRegister.phone_number}/>

					<RegisterComponent type={'text'} name={'managerName'} value={customerRegister.managerName} required={true}
														 title={t('담당자')} onChange={onChange} placeholder={t('담당자명을 입력해 주세요.')}/>

						<RegisterComponent type={'phone'} value={customerRegister.managerNumber} error={false} title={t('담당자 전화번호')}
															 onChange={(e)=>{onChange(e,true)}} name={'managerNumber'} required={true}
															 numberFormat={customerRegister.managerNumber}/>

						<RegisterComponent type={'text'} value={customerRegister.email} required={true} title={t('담당자 이메일')} placeholder={t('이메일을 입력해 주세요.')}
															 onChange={onChange} name={'email'} bottomBorder={true}/>


						<div style={{display:'flex'}}>
						<div className={'indexBox'} style={{border:'none'}}>{
							t('솔루션 권한')}
							{/*<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
						</div>
						<div className={'inputWrap'} style={{height:'80px',border:'none'}}>
						<div className={'check_box'}>
								<div style={{display:'flex',alignItems:'center',userSelect:'none'}}>
									<label style={{display:'flex',alignItems:'center'}}>
										<input type="checkbox" name={'authorities'} checked={true}
													 style={{cursor:'default',marginRight:'8px'}}/>
										<div>{'FAS'}</div>
									</label>
								</div>
								{solutionArray.map((v) => {
									return (
										<div style={{display:'flex',alignItems:'center'}}>
											<label style={{display:'flex',alignItems:'center',cursor:'pointer',userSelect:'none'}}>
												<input type="checkbox" value={v} name={'authorities'} style={{marginRight:'8px'}}
													   onChange={(e)=>(onCheckedAuthorization(e.target.checked,e.target.value))}
													// @ts-ignore
													   checked={authorizationArray.includes(v)}/>
												<div>{v}</div>
											</label>
										</div>
									)})}
						</div>
						</div>
					</div>

						{/*마이다 프레스 확인 */}
						<RegisterComponent type={'emptyWithChild'} value={customerRegister.mida}  title={t('마이다 프레스')}
															 childComponent={
																 <label style={{display:'flex',alignItems:'center',cursor:'pointer',userSelect:'none'}}>
																	 <input type="checkbox" name={'midaPress'} checked={customerRegister.midaPress === '1'}
																					onChange={(e)=>onCheckedMidaPress(e.target.checked)}
																					style={{marginRight:'8px'}}/>
																	 <div style={{fontSize:'12px'}}>{t('마이다 프레스 납품업체')}</div>
																 </label>

															 } name={'midasPress'} bottomBorder={true}/>

					<div style={{display:'flex'}}>
						<div className={'indexBox'} style={{height:'72px'}}>
							{t('관리자 ID')}
							{/*<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
						</div>
						<div className={'inputWrap'} style={{height:'72px'}}>
							<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
								<InputComponent type="text" name={'id'} value={customerRegister.id} onChange={onCheckId}
																maxLength={30}
																width={'520px'} onKeyDown={onIdCheckEnter}/>
								<MainButtons buttonType={'cta'} name={idCheckNormal? '사용가능' : '중복확인'} disabled={idCheckNormal} marginDis={true}
														 clickEvent={()=>{IdDuplicate( customerRegister , setIdCheck , setIdCheckNormal ).then()}} width={'72px'} />
							</div>
							<div className={'formText'} style={{color: idCheck.color}}>{t(idCheck.text)}</div>
						</div>
					</div>
					<div style={{display:'flex'}}>
						<div className={'indexBox'} style={{height:'72px'}}>
							{t('관리자 PW')}
							{/*<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
						</div>
						<div className={'inputWrap'} style={{height:'72px'}}>
							<InputComponent type="password" name={'password'} maxLength={30}
															value={customerRegister.password} onChange={onCheckPassword}/>
							<div className={'formText'}>{t('*8글자 이상 영문,숫자(특수문자 혼용가능)를 사용해주세요.')}</div>
						</div>
					</div>
					<div style={{display:'flex'}}>
						<div className={'indexBox'} >
							{t('관리자 PW 확인')}
							{/*<div style={{fontSize:'12px',color:'red',marginLeft:'4px'}}>{'*'}</div>*/}
						</div>
						<div className={'inputWrap'} style={{height:'72px'}}>
							<div style={{display:'flex',alignItems:'baseline'}}>

								<InputComponent type="password" name={'passwordCheck'} maxLength={30}
																value={customerRegister.passwordCheck} autoComplete={'off'}
												onChange={(e)=> {
													onChange(e , true);
													setPwCheckNormal(false)
													onPwCheckValid(e.target.value, setPassword)
												}} width={'520px'} onKeyDown={onPwCheckEnter}/>
								<MainButtons buttonType={'cta'} name={pwCheckNormal? '확인됨' : '확인'} disabled={pwCheckNormal}
														 marginDis={true}
														 clickEvent={()=>{PasswordCheck(customerRegister, setPassword, setPwCheckNormal)}} width={'72px'} margin={'0 0 0 8px'}/>
							</div>
							<div className={'formText'} style={{color: password.color}}>{t(password.text)}</div>
						</div>
					</div>

						{femsInfo &&
							<div style={{display:'flex'}}>
								<div className={'indexBox'} >{t('F-EMS 고객정보')}</div>
								<div className={'inputWrap'}>
									<div style={{display:'flex',alignItems:'baseline',justifyContent:'space-between'}}>
									   <div style={{display:'flex',alignItems:'baseline'}}
													onClick={()=> {
														if(customerRegister.companyName){
															setDataModal( {menu: 'search'} )
														}else{
															setDataModal({menu:'confirm',message:t('업체명을 입력해 주세요.')})
														}
													}}>
											<div style={{...femsInfoStyle}}> {t('고객번호')}</div>
											<InputComponent type="text" name={'custNo'} value={customerRegister.custNo} disabled={true}
														onChange={onChange} width={'416px'} placeholder={'0000000000'} maxLength={10}/>
											 <MainButtons name={'고객번호 검색'} buttonType={'cta'} width={'112px'} margin={'0 0 0 8px'}
																		clickEvent={()=>{

																			if(customerRegister.companyName){
																				setDataModal( {menu: 'search'} )
																			}else{
																				setDataModal({menu:'confirm',message:t('업체명을 입력해 주세요.')})
																			}
																		}}/>
									   </div>
									</div>
								</div>
							</div>
						}
					<div style={{display:'flex'}}>
						<div className={'indexBox'}>{t('비고')}</div>
						<div className={'inputWrap'} style={{height:'112px',}}>
							{/*textarea 입력창으로 변경하기*/}
							<InputTextBox name={'notice'} value={customerRegister.notice} cols={78} rows={16} onChange={onChange} placeholder={t('내용을 입력해 주세요.')}/>
						</div>
					</div>
					</div>
				</InputContainer>
			</div>
			<div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>

				<div style={{width:'100%'}}>
					<div style={{display:'flex',flexDirection:'column'}}>
						<div>
							<div style={{height:'34px',display:'flex',alignItems:'center',justifyContent:'space-between', marginBottom:'20px'}}>
								<MenuTitle title={'프로필 등록'} unitCase={'MenuCompTitle'}/>
								<div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
									<div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
								</div>
							</div>
							<div>
								<TotalImageUploadBox imageKeyArray={(data)=>setImageState(data)} inputSize={168} imageLength={1} profileSelect={false}/>
							</div>
						</div>

						<div style={{marginTop:'80px'}}>
							<div style={{height:'34px',display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'20px'}}>
								<MenuTitle title={'로고 등록'} unitCase={'MenuCompTitle'}/>
								<div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
									<div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
								</div>
							</div>
							<div>
								<TotalImageUploadBox imageKeyArray={(data)=>setLogoState(data)} inputSize={168} imageLength={1} profileSelect={false}/>
							</div>
						</div>

					</div>
				</div>

			</ContentFrame>
		</SectionFrame>
	);
};


export default BasicRegistration;

const InputContainer = styled.div`
	user-select: none;
	width: 784px;
	height: 570px;
	display: grid;
	grid-template-columns: 168px 568px;
	margin-top: 10px;

	
	.scroll_func{
		width: 810px;
		height: 584px;
		overflow-y: scroll;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			display: grid;
		}
	}
 
		.formText{
			font-size: 12px;
			color: rgba(23,23,37,0.5);
			display: flex;
			white-space: nowrap;
			font-weight: 400;
			margin-left: 8px;
			 
		}
		
		.indexBox{
			width: 168px;
			line-height: 30px; 
			background-color: #F5F6FA;
            box-sizing: border-box;
			padding: 10px 16px;
			font-size: 12px;
			display: flex;
			align-items: center;
			font-weight: 800;
			border-bottom: 1px solid #e7e9ee;
		}
		.inputWrap{
			width: 616px;
			border-bottom: 1px solid #e7e9ee;
			box-sizing: border-box;
			padding: 8px 0 8px 16px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			 
		}
		.input_tel{
			width: 600px;
			height: 32px;
			border: 1px solid #D7DBEC;
			border-radius: 4px;
			outline: none;
			box-sizing: border-box;
			font-size: 14px;
			color: #171725;
			font-weight: normal;
			padding: 0 8px;
			font-family: 'NanumGothicOTF', sans-serif;
			&::placeholder{
				font-size: 14px;
				font-weight: 400;
				color: rgba(23,23,37,0.3);
				font-family: 'NanumGothicOTF', sans-serif;
			}
      &:focus{
        border: 1px solid #0058ff;
      }
		}
	}
	

	
	.check_box{
		width: 616px;
		box-sizing: border-box;
		//border-bottom: 1px solid #e7e9ee;
		font-size: 12px;
		font-family: 'NanumGothicOTF', sans-serif;
		font-weight: normal;
		color: #171725;
		display: grid;
		grid-template-columns:repeat(6,90px);
		grid-template-rows: repeat(2,30px);
	}
	input[type='checkbox']{
		
		appearance: none;
		cursor: pointer;
		width: 16px;
		height: 16px;
		border: 1px solid #7E84A3;
	}
	input[type='checkbox']:checked{
		background-color: #0058FF;
		background-image: url(${checked});
		background-position: center;
		background-repeat: no-repeat;
		border: none;
	}
 		
`

interface inputProps {
	height?: string;
	width?: string;
}

const InputComponent = styled.input<inputProps>`
	width: ${props => props.width ? props.width : '600px'};
	height: ${props => props.height ? props.height : '32px'};
    
	padding: 8px;
	border: 1px solid #D7DBEC;
	border-radius: 4px;
	outline: none;
	box-sizing: border-box;
	font-size: 14px;
	color: #171725;
	font-weight: normal;
	font-family: 'NanumGothicOTF', sans-serif;
	
  &:focus{
    border: 1px solid #0058ff;
  }
	
		&::placeholder{
			font-size: 14px;
			font-weight: normal;
			color: rgba(23,23,37,0.3);
			font-family: 'NanumGothicOTF', sans-serif;
		}
	`


const InputTextBox = styled.textarea`
	    width: 600px;
	    height: 100px;
		outline: none;
		box-sizing: border-box;
	    padding: 8px;
	    
		border-radius: 4px;
		border: 1px solid #d7dbec;
		resize: none;
		font-size: 14px;
		font-family: 'NanumGothicOTF', sans-serif;
		
	overflow-x: hidden;
	overflow-y: scroll;
		&::-webkit-scrollbar {
				width: 15px;
				display: block;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #D5D7E3;
				border-radius: 10px;
				background-clip: padding-box;
				border: 6px solid transparent;
			}
			&::-webkit-scrollbar-track{
				width: 21px;
				background: transparent;
			}
  &:focus{
    border: 1px solid #0058ff;
  }
	
		&::placeholder{
			 
			font-size: 14px;
			font-weight: normal;
			color: rgba(23,23,37,0.3);
			font-family: 'NanumGothicOTF', sans-serif;
		}
	`