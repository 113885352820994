import React from 'react';
import {ContentFrame, SectionFrame} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import {useLocation} from "react-router-dom";

const UserBarcodeManagement = () => {

  const location = useLocation()

    return (
        <SectionFrame>
          <MenuTitle title={`${location.pathname.includes('/admin')? '업체/기본정보 관리 ' : '기본정보 관리 '}> 바코드 관리`} unitCase={'MenuTitle'}/>
            <ContentFrame justify={'center'} style={{alignItems:'center'}}>
                <MenuTitle title={'제공하지 않는 기능입니다.'} unitCase={'MenuTitle'}/>
            </ContentFrame>
        </SectionFrame>
    );
};

export default UserBarcodeManagement;