import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {ContentFrame} from "../../style/StyleComponent";
import ControlButton from "../button/ControlButton";
import MenuTitle from "../title/MenuTitle";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";

const ImageDetailPreview = ({imageUrl, clickEvent,profile}:{imageUrl?: string[], clickEvent:()=>void, profile?: string}) => {

	const [view, setView] = React.useState(5)
	const onMouseMove = (e: React.MouseEvent) => {};

	// const handleScroll = () =>{
	// 	console.log(window.scrollX);
	// 	if(window.scrollX > 0){
	// 		targetRef.current.style.position = 'fixed'
	// 	}
	// }
	// console.log(initialScaleNumber)
	// console.log(view)
	console.log(profile)
	return (
		<React.Fragment>
			<ModalBase modalContent={
				<ContentFrame justify={'space-between'} width={'1000px'}>
					<TransformWrapper>
						{({resetTransform}) => (
							<div style={{display:'flex',gap:'24px'}}>
								<div style={{border:'1px solid #d5d7e3', overflow:'hidden', cursor:'move'}}>
									<TransformComponent>
										<MainImage style={{backgroundImage:`url(${view === 5? profile : imageUrl === undefined? '' : imageUrl[view]})`,
											backgroundSize:'contain', backgroundPosition:'center',backgroundRepeat:'no-repeat'}} onMouseMove={onMouseMove}/>
									</TransformComponent>
								</div>
								<div style={{display:'flex',flexDirection:'column',gap:'24px',borderLeft:'1px solid #d5d7e3',paddingLeft:'24px'}}>
									<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
										<MenuTitle title={'이미지 목록'} unitCase={'MenuCompTitle'}/>
										<ControlButton name={'닫기'} clickEvent={clickEvent}/>
									</div>
									<TotalImageList style={{width:'176px',display:'flex',flexDirection:'column',gap:'24px'}}>
										{imageUrl?.map((v,i)=>(
											<SelectImage className={'selectImage'} onClick={()=>{setView(i); resetTransform()}}
																	 style={{backgroundImage:`url(${v})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}}>
											</SelectImage>
										))}
									</TotalImageList>
									{/*{imageUrl?.filter((v,i)=>(v !== imageUrl[view])).map((v,i)=>(*/}
									{/*		<SelectImage onClick={()=>setView(i)}*/}
									{/*	             style={{backgroundImage:`url(${v})`,backgroundSize:'cover', backgroundPosition:'center'}}>*/}
									{/*		</SelectImage>*/}
									{/*))}*/}
								</div>
							</div>
						)}
					</TransformWrapper>
				</ContentFrame>
			}/>
		</React.Fragment>
	);
};

export default ImageDetailPreview;

const MainImage = styled.div`
	width: 720px;
	height: 620px;
	box-sizing: border-box;
	transition: all 0.5s;
	background-color: rgba(0,88,255,0.05);
	//border: 1px solid #d5d7e3;
	//&:hover{
	//	transform: scale(1.35);
	//}
`
const TotalImageList = styled.div`
	width: 100%;
	height:562px;
	overflow-x: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar{
		display: block;
		width: 16px;
	}

`

const SelectImage = styled.div`
	width: 160px;
	height: 120px;
	cursor: pointer;
	box-sizing: border-box;
		border: 1px solid #d5d7e3;

`

