import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import left from "../../asset/image/chevron-left.svg";
import left_w from "../../asset/image/w-chevron-left.svg";
import right from "../../asset/image/chevron-right.svg";
import right_w from "../../asset/image/w-chevron-right.svg";

import CalendarRangeHeader from "./CalendarRangeHeader";
import { ko } from "date-fns/esm/locale";
import styled from "styled-components";
import MainButtons from "../button/MainButtons";
import dayjs from "dayjs";
import calendar from "../../asset/image/ic-calendar.svg";
import { ButtonBase } from "@mui/material";
import { dataValid } from "../../function/function";
import { useLocation } from "react-router-dom";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

interface Range_Calendar_Types {
  receiveDate: (date: { start: string, end: string }) => void;
  currentSelectDate: { start?: string, end?: string };
  calendarName?: string;
  disabled?: boolean;
  viewType?:boolean
}

const CalendarRangePicker: React.FunctionComponent<Range_Calendar_Types> = ({viewType, receiveDate, currentSelectDate, calendarName, disabled }) => {
  // dayjs 객체를 안전하게 Date 객체로 변환하는 함수
  const safeDateConversion = (dateString:any) => {
    return dayjs(dateString, 'YYYY.MM.DD').isValid() ? dayjs(dateString, 'YYYY.MM.DD').toDate() : null;
  };

  const firstDayOfMonth = safeDateConversion(dayjs().startOf('month').format('YYYY.MM.DD'));
  const lastDayOfMonth = safeDateConversion(dayjs().endOf('month').format('YYYY.MM.DD'));

  const location = useLocation();
  const authValid = location.pathname.includes('admin');

  const [startDate, setStartDate] = useState(safeDateConversion(currentSelectDate.start));
  const [endDate, setEndDate] = useState(safeDateConversion(currentSelectDate.end));
  const [openCalendar, setOpenCalendar] = useState(false);

  useEffect(() => {
    setStartDate(safeDateConversion(currentSelectDate.start));
    setEndDate(safeDateConversion(currentSelectDate.end));
  }, [currentSelectDate.start, currentSelectDate.end,viewType]);


  const wrapperRef = React.useRef<any>(null);
  // 달력을 제외한 div태그의 범위를 지정해서,포함하면 true 포함하지않으면 false를 주는 함수임 즉 달력 모달을 제외한 다른걸 클릭하면 닫힘
  const handleClickOutside = (event: { target: any; }) => {
    // Close calendar if clicked outside
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <TotalComponent style={{ opacity: disabled ? 0.6 : 1 }} ref={wrapperRef}>
        <ButtonBase onClick={() => setOpenCalendar(!openCalendar)} className={'calendar_out_button'} disabled={disabled}
                    style={{ border: '1px solid #d7dbec', fontFamily: 'Nanum Gothic', height: '32px', borderRadius: '4px' }}>
          <img src={calendar} width={24} alt="" style={{ cursor: 'pointer' }} />
          <div style={{ fontWeight: 700, margin: '0 16px 0 8px', userSelect: 'none' }}>{dataValid(calendarName)}</div>
          <div style={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
            <div>{dayjs(startDate).format('YYYY.MM.DD')}</div>
            <div style={{ fontFamily: 'Poppins', margin: '4px 8px 0 8px' }}>{'-'}</div>
            <div>{dayjs(endDate).format('YYYY.MM.DD')}</div>
          </div>
        </ButtonBase>
        {openCalendar && <BaseCalendar authType={authValid}>
          <div style={{ display: 'flex' }}>
            <DatePicker selected={startDate}
                        onChange={date => {
                          setStartDate( date );
                          if(viewType) {
                            setEndDate(safeDateConversion(dayjs(date).format('YYYY.MM.DD')))
                          }
                        }}
                        inline monthsShown={1}
                        onClickOutside={(e)=>{
                          e.stopPropagation()
                        }}
                        showYearDropdown={true}
                        maxDate={viewType ? undefined : endDate}
                        startDate={startDate}
                        endDate={endDate}
                        // minDate={viewType ? new Date(dayjs(endDate).subtract(1, 'month').format('YYYY-MM-DD')) : undefined}
                        locale={ko}
                        renderCustomHeader={( { date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled }) =>
                          ( <CalendarRangeHeader startDate={startDate} endDate={endDate} authType={authValid}
                                                 data={{ date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled }}/> )}
            />
            <DatePicker selected={endDate}
                        onChange={date => setEndDate(date)}
                        inline monthsShown={1}
                        onClickOutside={(e)=>{e.stopPropagation()}}
                        showYearDropdown={true}
                        minDate={startDate}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={viewType ? new Date(dayjs(startDate).add(1, 'month').format('YYYY-MM-DD')) : undefined}
                        locale={ko}
                        renderCustomHeader={( { date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled }) =>
                          ( <CalendarRangeHeader startDate={startDate} endDate={endDate} authType={authValid} endPosition={true}
                                                 data={{ date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled }}/> )}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '16px' }}>
            <MainButtons name={'취소'} clickEvent={() => {
              setOpenCalendar(false);
              setStartDate(!currentSelectDate.start ? firstDayOfMonth : safeDateConversion(currentSelectDate.start));
              setEndDate(!currentSelectDate.end ? lastDayOfMonth : safeDateConversion(currentSelectDate.end));
            }} width={'80px'} />
            <MainButtons name={'적용'} disabled={!endDate} clickEvent={() => {
              receiveDate({ start: dayjs(startDate).format('YYYY.MM.DD'), end: dayjs(endDate).format('YYYY.MM.DD') });
              setOpenCalendar(false);
            }} buttonType={'cta'} width={'80px'} />
          </div>
        </BaseCalendar>}

    </TotalComponent>
  );
};

export default CalendarRangePicker;

const TotalComponent = styled.div`
  position: relative;
  box-sizing: border-box;
  .calendar_out_button{
    color: #5a607f;
    padding: 0 16px;
    font-size: 14px;
    background-color: #fff;
    &:hover{
      color: #fff;
      background-color: #5a607f;
    }
  }
`


const BaseCalendar = styled.div<{authType?:boolean}>`
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px #00000029;
  border-radius: 4px;
  background-color: #fff;
  position: absolute;
  top:48px;
  z-index: 101;
  .react-datepicker__triangle{
    display: none;
  }
  .react-datepicker__day-names{
    background-color: #fff;
  }


  .react-datepicker{
    border: none;
  }
  .react-datepicker__month-container{
    height: 245px;
    width: 200px;
    //position: relative;
    //box-shadow: 0 3px 6px #00000029;
    //border-radius: 4px;

    .react-datepicker__header, react-datepicker__header--custom{
      border-bottom:none;
      font-weight: 600;
    }
    .react-datepicker__header{
      padding: 0;
      background-color: ${p=>p.authType ? 'rgb(207, 250, 97)':'#0058FF'};
      font-family: "Nanum Gothic", sans-serif;
    }
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none; { /*<img src={calendar} onClick={clickEvent} alt="" style={{position:'absolute', right: '24px', cursor:'pointer'}}/>*/
  } -webkit-appearance: none;
  }
  
  .react-datepicker__day-names{
    padding: 10px 0;
    .react-datepicker__day-name{
      width: 24px;
      height: 20px;
      margin: 0;
    }
  }

  .react-datepicker__year-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 56px);
    align-items: center;
    grid-gap: 4px;
  }

  .react-datepicker__day--keyboard-selected{
    background-color: #fff;
    border-radius: 4px;
    color: #171725;
  }

  .react-datepicker__week{
    box-sizing: border-box;
    .react-datepicker__day--selected{
      background-color: #0058FF;
      font-weight: 400;
      color: #fff;
      outline: none;
    }
    .react-datepicker__day--today{
      font-weight: 400;
      position: relative;
      margin: 0 0 4px 0;
      box-sizing: border-box;
      &::before{
        content:'';
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #21D59B;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
      }
    }
    .react-datepicker__day--outside-month{
      visibility: hidden;
      outline: none;
    }
    .react-datepicker__day{
      position: relative;
      width: 24px;
      height: 24px;
      font-size: 10px;
      margin: 0 0 4px 0;
      box-sizing: border-box;
      font-family: 'NanumGothicOTF';
      //&::after{
      //  content: '';
      //  width: 3px;
      //  height: 3px;
      //  position: absolute;
      //  top:80%;
      //  border-radius: 50%;
      //  left:50%;
      //  transform: translate(-50%, -50%);
      //  bottom: 0;
      //  background-color: #21D59B;
      //}
    }
  }

  .react-datepicker__navigation--previous {
    cursor: pointer;
    border: none;
    display: flex;
    width: 20px;
    height: 20px;
    //padding-left: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: ${p=>p.authType ? `url(${left})`:`url(${left_w})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }
  .react-datepicker__navigation--next {
    cursor: pointer;
    display: flex;
    border: none;
    width: 20px;
    height: 20px;
    //padding-right: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: ${p=>p.authType ? `url(${right})`:`url(${right_w})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .react-datepicker__navigation--previous--year {
    cursor: pointer;
    border: none;
    display: flex;
    width: 24px;
    height: 24px;
    padding-left: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: ${p=>p.authType ? `url(${left})`:`url(${left_w})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

  }

  .react-datepicker__navigation--next--year {
    cursor: pointer;
    display: flex;
    border: none;
    width: 24px;
    height: 24px;
    padding-right: 16px;
    box-sizing: border-box;
    //background-color: #fff;
    background-image: ${p=>p.authType ? `url(${right})`:`url(${right_w})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .react-datepicker__day--in-range{
    background-color: aliceblue;
    color: #171725;
  }
`
