import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../components/title/MenuTitle";
import MainButtons from "../../../../components/button/MainButtons";
import {ContentFrame , SectionFrame , validationValue} from "../../../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import TotalImageUploadBox from "../../../../components/input/TotalImageUploadBox";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import SingleDrawingUploadBox from "../../../../components/input/SingleDrawingUploadBox";
import RegisterInput from "../../../../components/input/RegisterInput";
import {Modal_View_Type} from "../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../../../function/function";
import BusinessVisitHistoryModal from "../../../../components/modal/BusinessVisitHistoryModal";
import client from "../../../../common/Config";
import header from "../../../../components/header/Header";
import {Authorization} from "../../../../common/authorization";
import SelectConfirmModal from "../../../../components/modal/SelectConfirmModal";
import {useParams} from "react-router-dom";
import ToastPopup from "../../../../components/modal/ToastPopup";
import dayjs from "dayjs";
import AttachMultipleUploadBox from "../../../../components/input/AttachMultipleUploadBox";
import WorkSelectModal from "../../../../components/modal/WorkSelectModal";

const BusinessModify = () => {
  const {t}:any = useTranslation()
  const params = useParams()
  const {id} = params
  const [modifyState, setModifyState] = useState<any>({})
  const [imageKeyState, setImageKeyState] = React.useState<string[]>([]);
  const [receiveFileData,setReceiveFileData] = React.useState<any>({fileKey:[],fileName:[],fileUrl:[]})
  const [fileKeyState,setFileKeyState] = React.useState<string[]>([]);
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [receiveWorker,setReceiveWorker] = React.useState<any[]>([])

  const changeValue = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement> , trim?: boolean) =>{
    const {name, value} = e.target;
    setModifyState({
                    ...modifyState,
                    [name] : trim? value.trim() : value
                  })
  }


  const detailBusinessRes = async () =>{
    try{
      const response = await client.post('/business/management/detail',
                                         {businessManagementCode:id},
                                         {headers:{Authorization}})
      const data=response.data.data
      setModifyState(data)
      //api수정되면 바로 ㄱㄱ
      setReceiveFileData({fileKey:data.fileCodeList,fileName:data.fileNameList,fileUrl:data.fileUrlList})
      setReceiveWorker(data.responsibility)
    } catch (e:any){
      console.log(e)
      setDataModal({menu:'confirm',message:e.meesage})
    }
  }

  const businessDeleteRes = async () =>{
    dataAccessLoading()
    try {
      const response = await client.post('/business/management/delete',{
        businessManagementCode:id
      }, {headers: {Authorization}})
      if(response.data.status !== 200){
        setDataModal({menu:'confirm',message:response.data.message})
        loadingRemove()
      }else{
        setTimeout(()=>{
          setDataModal({menu:'success',message:'삭제 되었습니다.'})
          loadingRemove()
        },500)
      }

    }catch (e:any){
      console.log(e)
      setDataModal({menu:'error',message:e.message})
      loadingRemove()
    }
  }

  const businessUpdateRes = async () =>{
    try {
      dataAccessLoading()
      const response = await client.post(`/business/management/update`,{
        businessManagementCode:id,
        contents:modifyState.contents,
        image:imageKeyState,
        file:fileKeyState,
        title:modifyState.title,
        visitDate:modifyState.visitDate,
        responsibility:dataValid(receiveWorker.map((v:any)=>(v.id)),[]),
      },{headers: {Authorization}})
      const data = response.data
      if(data.status !== 200){
        setDataModal({menu:'confirm',message:t(data.message)})
        loadingRemove()
      }else{
        setTimeout(()=>{
          setDataModal({menu:'success',message:t('수정 되었습니다.')})
          loadingRemove()
        },500)
      }
    }catch ( e ) {
      console.log(e)
    }
  }

  const closeModify = () =>{
    setDataModal(initModal)
    if(dataModal.message === t('삭제 되었습니다.')){
      window.location.href = `/admin/fas/business`
    }else if(dataModal.message === t('수정 되었습니다.')){
      window.location.href = `/admin/fas/business/${id}`
    }
  }

  useEffect(()=>{
    detailBusinessRes().then()
  },[])

  useEffect(() => {
    const handleBeforeUnload = (event:BeforeUnloadEvent) => {
      if (dataModal.menu === '') {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dataModal.menu])

  return (
    <SectionFrame>
      {dataModal.menu === 'visitHistory' &&
          <BusinessVisitHistoryModal closeEvent={()=>{setDataModal(initModal)}} companyData={modifyState}/>
      }
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={closeModify}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}


      {dataModal.menu === 'select' && <SelectConfirmModal statusText={t(dataModal.message)}
                                                          noticeText={t(' * "예" 선택시 모든데이터는 삭제됩니다.')}
                                                          clickEvent={()=>{businessDeleteRes().then(); setDataModal(initModal)}}
                                                          cancelEvent={()=>(setDataModal(initModal))}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={businessUpdateRes}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              clickEvent={()=>{window.location.href =`/admin/fas/business/${modifyState?.businessManagementCode}`}}
          />
      }

      {dataModal.menu === 'worker' &&
          <WorkSelectModal closeEvent={()=>{setDataModal(initModal)}}
                           setData={receiveWorker}
                           receiveData={(data)=>{setReceiveWorker(data); setDataModal(initModal)}}/>
      }



      <MenuTitle title={t('영업관리 > 수정')} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'} justify={'flex-start'}>
        <ViewBox>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
            <MenuTitle title={t('영업관리 수정')} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex',alignItems:'center'}}>
              <div className={'click_text'} onClick={()=>{setDataModal({menu:'visitHistory'})}}>{'방문이력 보기'}</div>
              <MainButtons name={'취소'} width={'56px'} clickEvent={()=>setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}/>
              <MainButtons name={'삭제'} disabled={buttonAuthValid('delete','22')} width={'56px'} clickEvent={()=>{setDataModal({menu:'select',message:'삭제 하시겠습니까?'})}}/>
              <MainButtons name={'저장'} disabled={buttonAuthValid('update','22')} clickEvent={()=>{setDataModal({menu:'register',message:'이대로 수정하시겠습니까?'})}} buttonType={'cta'} width={'56px'} marginDis={true}/>
            </div>
          </div>
          <div className={'info'} style={{display: 'grid', gridTemplateColumns: '168px 224px 168px 224px'}}>
            <div className={'reg-title'}>{t('업체명')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(modifyState?.companyName)}
            </div>
            <div className={'reg-title'}>{t('작성자')}</div>
            <div className={'reg-content'} style={{display: 'flex',borderBottom:'1px solid rgba(213, 215, 227, 0.5)'}}>
              {validationValue(modifyState?.writer)}
            </div>
            <div className={'reg-title'}>{t('담당자')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(modifyState?.managerName)}
            </div>
            <RegisterComponent type={'calendar'} name={'requestDate'} left={90} top={-60} selectDate={modifyState?.visitDate}
                               value={modifyState?.visitDate} useDeleteButton={true} required={true} title={'방문 날짜'} maxDate={Date}
                               onChange={(v)=> setModifyState((prev:any)=>({...prev, visitDate: dayjs(v).format('YYYY-MM-DD')}))}
            />

          </div>
          <div style={{display:"grid",gridTemplateColumns:'168px 224px 168px 224px'}}>
            <div className={'reg-title'}>{t('등록날짜')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(dayjs(modifyState?.registrationDate).format('YYYY.MM.DD'))}
            </div>
            <div className={'reg-title'}>{t('마지막 수정날짜')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {modifyState?.modifiedDate ?
                validationValue(dayjs(modifyState?.modifiedDate).format('YYYY.MM.DD'))
                :
                '-'
              }
            </div>
          </div>

          <RegisterComponent type={'textButton'} onChange={()=>''} title={'담당자 추가'}
                             onKeyPress={()=>setDataModal({menu:'worker'})}
                             buttonName={'검색'} disabled={true}
                             clickEvent={()=>setDataModal({menu:'worker'})}
                             value={receiveWorker?.map((value: { userName: any; } , index: any)=>(value?.userName))} name={'responsibility'} buttonWidth={'72px'}/>

          <RegisterComponent type={'text'} name={'title'} limit={60} value={modifyState?.title} title={t('제목')} required={true}
                             onChange={(e)=>changeValue(e, true)} placeholder={t('담당자를 입력해주세요')} width={'610px'}/>

          <AttachMultipleUploadBox fileKeyArray={(data)=>{setFileKeyState(data)}}
                                   receiveKey={dataValid(receiveFileData.fileKey,[])}
                                   receiveUrl={dataValid(receiveFileData.fileUrl,[])}
                                   receiveFileName={dataValid(receiveFileData.fileName,[])}
                                   placeholder={'* 업로드 버튼을 클릭하여 파일을 등록 해주세요.'}/>

          <div style={{display:'grid',gridTemplateColumns:'168px 1fr',borderBottom:'1px solid rgba(213, 215, 227, 0.5)'}}>
            <div className={'info-title'} style={{height: '240px'}}>{t('내용')}</div>
            <div className={'info-content'} style={{height: '240px'}}>
              <RegisterInput type={'textarea'} name={'contents'} value={modifyState?.contents}
                             maxHeight={'224px'} limit={2000}
                             onChange={(e)=>changeValue(e, false)} placeholder={t('내용을 입력해주세요.')}/>
            </div>
          </div>
        </ViewBox>
        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
        <div style={{width:'370px',height:'34px',alignItems:'center'}}>
          <div style={{display:'flex',justifyContent:'space-between',marginBottom:'16px'}}>
            <MenuTitle title={'영업 이미지 수정'} unitCase={'MenuCompTitle'}/>
            <div style={{fontSize:"12px", textAlign:'right',color:'#7E84A3'}}>
              <div>{t('*최대 4개의 이미지 등록 가능')}</div>
              <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
            </div>
          </div>
          <TotalImageUploadBox imageKeyArray={(data)=>setImageKeyState(data)} profileSelect={false}
                               receiveUrl={dataValid(modifyState?.imagesUrlList,[])} receiveKey={dataValid(modifyState?.imagesCodeList,[])}/>
        </div>
      </ContentFrame>
    </SectionFrame>



  );
};
export default BusinessModify;

const ViewBox = styled.div`
  width: 784px;
  height: 584px;

  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-top: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-top: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }

  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }

  .click_text{
    color:#7E84A3;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline 1px #7E84A3;
    margin-right: 12px;
    &:hover{
      text-underline-offset: 3px;
    }
  }
`