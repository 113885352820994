import React from 'react';
import styled from "styled-components";
import SolutionIcon from "../icon/SolutionIcon";
import OnlineStatus from "../display/OnlineStatus";

import {UserNoticeType} from "../../types/Fundamental";



const UserRealTimeStatus:React.FunctionComponent<UserNoticeType> = ({  		iconType,clickEvent,
	                                                                        facilityName,makerNumber,dateTime,
	                                                                        plantName,alarmState,
	                                                                        noticeContent,alarmTime}) => {

		return (
		<BaseComponent onClick={clickEvent}>
			<div className={'solutionCircle'}>
				<SolutionIcon iconType={0} title={iconType}/>
			</div>
			<div className={'inContent'}>
				<div>
					<div style={{display:'flex', justifyContent:'space-between',marginBottom:'10px'}}>
						<div style={{fontWeight:700, fontSize:'14px', color:'#101010'}}>{plantName}</div>
					</div>
					<MainNoticeCont>
						<div style={{display:'flex',gap:'8px'}}>
							<div>{facilityName}</div>
							<div>({makerNumber})</div>
						</div>
						<div>{noticeContent}</div>
					</MainNoticeCont>
				</div>
				<div style={{display:'flex',flexDirection:'column', justifyContent:'space-between'}}>
					<OnlineStatus statusType={'lapseData'} title={dateTime}
								  statusColor={alarmState === 'check' ? '#FFC700' :
									  		   alarmState === 'error' ? '#FFA900' :
											   alarmState === 'emergency' ? '#F0142F' :
											   alarmState === 'dailyCheck' ? '#D5D7E3' : ''}/>
					<div style={{color:'#101010',textAlign:'right', opacity:0.5, fontSize:'10px', fontWeight:400}}>
						{alarmTime.slice(10,20)}
					</div>
				</div>
			</div>
		</BaseComponent>
	);
};

export default UserRealTimeStatus;

	const BaseComponent = styled.div`
		display: flex;
		max-width: 775px;
		box-sizing: border-box;
		height: 120px;
		border-radius: 8px;
		justify-content: space-between;
		position: relative;
		margin-bottom: 8px;
		margin-left: 5px;
		.solutionCircle{
      padding: 16px 0 0 10px;
      &>div>div:nth-child(2){
        display: none;
		  transition: all 300ms;
	  }
		}
		
		
		&:hover{
			box-shadow: 0 3px 6px #00000029;
			background-color: #F5F6FA;
			cursor: pointer;
			transition: all 150ms;
			.inContent{
				transition: all 150ms;
				box-shadow: none;
				width: 740px;
        		padding: 21px 24px 8px 6px;
				&>div:nth-child(1)>div:nth-child(1){
					visibility: hidden;
				}
				&>div:nth-child(2){
					transition: all 150ms;

				}
			}
			.solutionCircle{
	      &>div>div:nth-child(2){
	        display: block;
			  transition: all 300ms;
		  }
			}
		}
		
		.inContent{
			width: 696px;
			height: inherit;
			display: flex;
			justify-content: space-between;
      box-shadow: 0 3px 6px #00000029;
			box-sizing: border-box;
			border-radius: 8px;
			position: absolute;
			padding: 21px 24px 8px 16px;
			right: 0;
		}
	`

	const MainNoticeCont = styled.div`
			font-size: 14px;
			color: #101010;
		&>div:nth-child(1){
			font-weight: 700;
			margin-bottom: 4px;
		}
		&>div:nth-child(2){
			font-weight: 400;
			line-height: 20px;
		}
	`