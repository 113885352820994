import React , {useEffect , useState} from 'react';
import {
	CheckBox ,
	ContentFrame ,
	Icons ,
	NoData ,
	SectionFrame ,
	validationValue
} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import SearchBar from "../../../../components/input/SearchBar";
import {Authorization} from "../../../../common/authorization";
import client from "../../../../common/Config";
import {Error_Type} from "../../../../types/Fundamental";
import PaginationList from "../../../../components/pagination/PaginationList";
import {useTranslation} from "react-i18next";
import {useRecoilState} from "recoil";
import {
	searchInputState ,
	selectedCode ,
	selectedListCountValue ,
	wikiTypeFilter
} from "../../../../common/StateManage";
import {
	affiliateAuth ,
	buttonAuthValid ,
	dataValid ,
	initError ,
	listLoadingCircle ,
	loadingRemove , menuVisitLogRegister
} from "../../../../function/function";
import MainButtons from "../../../../components/button/MainButtons";
import dayjs from "dayjs";
import {GridTableComponent, GridTableSelect} from "../../../../style/StyleModule";

const UserWikiList = () => {
	const [viewComplete,setViewComplete] = React.useState<boolean>(false);
	const [wikiListData, setWikiListData] = useState<any[]>([])
	const [wikiPage, setWikiPage] = useState(1)
	const [wikiTotalPage, setWikiTotalPage] = useState(1)
	const [wikiBlockPage, setWikiBlockPage] = useState(0)
	const [selectSolution, ] = useRecoilState<string>(wikiTypeFilter)
	const [infoErrorState,setInfoErrorState] = useState<Error_Type>(initError)
	const [wikiErrorState,setWikiErrorState] = useState<Error_Type>(initError)

	const [wikiSearchValue, setWikiSearchValue] = useRecoilState<string>(searchInputState);
	const [wikiPreviousSearch, setWikiPreviousSearch] = useState<string>(wikiSearchValue);

	const [infoSearchValue, setInfoSearchValue] = useState<string>('');
	const [infoPreviousSearch,setInfoPreviousSearch] = useState<string>(infoSearchValue);


	const [infoTotalList, setInfoTotalList] = useState<any>([])
	const [infoPage, setInfoPage] = useState(1)
	const [infoTotalPage, setInfoTotalPage] = useState(1)
	const [infoBlockPage, setInfoBlockPage] = useState(0)

	const [wikiSelectedCode, setWikiSelectedCode] = useRecoilState(selectedCode)
	const [, setSelectedCount] = useRecoilState(selectedListCountValue)
	const { t }:any = useTranslation();

	//게시판 리스트 API
	const wikiListRes = async () => {
		try {
			listLoadingCircle()
			const response = await client.get(`/wiki/list/${wikiPage}/10/company/${dataValid(selectSolution,'@')}/${viewComplete}`,{headers:{Authorization}})
			const data = response.data.data
			const valid = response.data
			if( data.total_page < wikiPage ){
				setWikiPage( 1 )
				setWikiBlockPage( 0 )
			}
			setWikiListData(data.row)
			setWikiTotalPage(data.total_page)
			if( valid.status !== 200 ){
				setWikiErrorState({notice:valid.message,status:valid.status})
				setWikiListData([])
			} else{
				setWikiErrorState({notice:t('* 등록된 게시글이 없습니다.'),status:valid.status})
			}
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	//게시판 리스트 검색 API
	const wikiSearchRes = async () =>{
		try{
			listLoadingCircle()
			const response = await client.get(`wiki/search/${wikiPage}/10/company/${dataValid(selectSolution,'@')}/${wikiSearchValue}/${viewComplete}`,{
				headers:{Authorization}
			})
			const data = response.data.data
			setWikiListData(data.row)
			setWikiTotalPage(data.total_page)
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	React.useEffect(()=>{
		if (wikiPreviousSearch === '') {
			wikiListRes().then()
		}else{
			wikiSearchRes().then()
		}
	},[wikiPage,wikiPreviousSearch,selectSolution,viewComplete])

	const searchEventFunc = () => {
		setWikiPreviousSearch(wikiSearchValue)
		setWikiPage(1)
		setWikiBlockPage(0)
	}

	const searchInitFunc = () => {
		setWikiSearchValue('')
		setWikiPreviousSearch('')
		setWikiPage(1)
		setWikiBlockPage(0)
	}

	//공지 이벤트 리스트 API
	const infoListRes  = async () => {
		try {
			listLoadingCircle()
			const response = await client.get(`/infoNotice/list/${infoPage}/10`,
																				{headers: {Authorization}})
			const data = response.data.data
			const valid = response.data
			if(valid.status !== 200){
				setInfoErrorState({notice:valid.message,status:valid.status})
				setInfoTotalList([])
				loadingRemove()
			}else{
				setInfoTotalList(data.row)
				setInfoTotalPage(data.total_page)
				setInfoErrorState({notice:'* 공지 & 이벤트가 없습니다.',status:valid.status})
				loadingRemove()
			}
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	//공지 이벤트 검색API
	const infoSearchRes = async () => {
		try {
			listLoadingCircle()
			const response = await client.get(`/infoNotice/search/${infoPage}/10/${infoSearchValue}`,{
				headers: {Authorization}
			})
			const data = response.data.data
			setInfoTotalPage(data.total_page)
			setInfoTotalList(data.row)
			loadingRemove()
		}
		catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	const searchInfoEventFunc = () => {
		setInfoPreviousSearch(infoSearchValue)
		setInfoPage(1)
		setInfoBlockPage(0)
	}

	const searchInfoInitFunc = () => {
		setInfoSearchValue('')
		setInfoPreviousSearch('')
		setInfoPage(1)
		setInfoBlockPage(0)
	}

	React.useEffect(()=>{
		if (infoPreviousSearch === '') {
			infoListRes().then()
		}else{
			infoSearchRes().then()
		}
	},[infoPage,infoPreviousSearch]);

	useEffect(()=>{
		if(!window.localStorage.getItem('auth')!?.includes('admin')){
			menuVisitLogRegister(window.localStorage.getItem('companyID'),'WIKI 게시판').then()
		}
	},[])

	return (
		<SectionFrame>
			<div style={{display:'flex',justifyContent:'space-between', position:'relative'}}>
				<MenuTitle title={'게시판'} unitCase={'MenuTitle'}/>

				{/*현재 유저 wiki목록에서 wikiType은 항상 '문의' 로 고정되어서 제외함. */}
				{/*<DropMenu menuArray={['전체','에러','요청','문의']}*/}
				{/*					selectValue={selectSolution === '@'? '전체' : selectSolution} clickEvent={(v)=>{*/}
				{/*	setSelectSolution( ( v === '전체' ) ? '@' : v )*/}
				{/*	setWikiPage(1)*/}
				{/*}} left={'91.8%'} top={'44px'} width={'105px'}/>*/}

				{/*드롭메뉴 이전, 버튼 나열 형식*/}
				{/*<div style={{display:'flex', alignItems:'center'}}>*/}
				{/*	{['전체','에러','요청','문의'].map((v)=>(*/}
				{/*		<SelectEventButton buttonName={v} key={v}*/}
				{/*											 buttonEvent={()=> {*/}
				{/*												 setSelectSolution( ( v === '전체' ) ? '@' : v )*/}
				{/*												 setWikiPage(1)*/}
				{/*											 }}*/}
				{/*											 buttonStatus={selectSolution === '@' ? v === '전체' : selectSolution === v} buttonBorder={true}/>*/}
				{/*	))}*/}
				{/*</div>*/}
			</div>


			<div style={{display:'flex', gap:'16px'}}>
				<ContentFrame width={'840px'} justify={'space-between'}>
					<div>
						<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
							<div style={{display:'flex',alignItems:'center'}}>
								<MenuTitle title={'WIKI'} unitCase={'MenuCompTitle'}/>
								<label style={{
									display: 'flex' , alignItems: 'center' , marginLeft: '16px' , cursor: 'pointer' ,
									pointerEvents:selectSolution === '개발완료' ? 'none' : 'auto',opacity:selectSolution === '개발완료' ? 0.5 :1}}>
									<CheckBox type={'checkbox'} icons={`url(${Icons.Check})`} checked={viewComplete} onClick={()=>{setViewComplete(!viewComplete)}}/>
									<div style={{color: '#5A607F' , fontSize: '12px',userSelect:'none'}}>{'개발완료 보기'}</div>
								</label>
							</div>
							<div style={{display:'flex',alignItems:'center'}}>
								<SearchBar value={wikiSearchValue} keyFunction={(e)=>setWikiSearchValue(e.target.value.trimStart())}
													 clickEvent={searchEventFunc} clearButton={searchInitFunc} clearButtonAccess={wikiSearchValue !== ''}
													 placeholder={t('검색')} disabled={wikiErrorState.status!==200}/>
								{!affiliateAuth() && <MainButtons name={'등록'} width={'56px'}
																									disabled={buttonAuthValid('create' , '2') || wikiErrorState.status !== 200}
																									marginDis={true}
																									clickEvent={() => {window.location.href = `/fas/wiki/register`}}/>}
							</div>
						</div>
						<GridTableComponent tempCol={'60px 80px 80px 1fr 64px 128px'} scrollX_Hide={true} scrollY_Hide={true} scrollWidth={780} >
							<div className={'grid-title-unchecked'}>
								{['No.','솔루션','유형','제목','답변','등록일'].map((v)=>(
									<div key={v}>{t(v)}</div>
								))}
							</div>
							{wikiListData?.length !== 0 ?
								<React.Fragment>
									{wikiListData?.map((v)=>(
										<GridTableSelect
											selected={v.wikiCode === wikiSelectedCode}
											className={'grid-list-unchecked-no_icon'}
											onMouseDown={()=>{
												setWikiSelectedCode(v.wikiCode)
												setSelectedCount(v.count)
											}}
											onDoubleClick={()=>{window.location.href = `/fas/wiki/${v.wikiCode}`}}>
											{validationValue(v.no)}
											{validationValue(v.solution)}
											{validationValue(t(v.wikiType))}
											{/*{validationValue(v.wikiTitle)}*/}
											<div style={{flexDirection:'row', justifyContent:'flex-start', alignItems:'center', textOverflow:'ellipsis',overflow:'hidden', whiteSpace:'nowrap',position:'relative'}}>{dataValid(v.wikiTitle)}
												<span style={{fontWeight:700}}>{' ('+ dataValid(v.replyCount, 0)+')'}</span>
											</div>
											{validationValue(v.wikiFinish === '0'? '-' : '완료')}
											{validationValue(dayjs(v.registrationDate).format('YYYY.MM.DD'))}
										</GridTableSelect>
									))}
								</React.Fragment>
								:
								<NoData>{wikiErrorState.notice}</NoData>
							}
						</GridTableComponent>


					</div>
					{wikiListData?.length !== 0 &&
						<PaginationList page={wikiPage} setPage={setWikiPage} pageLimit={10} limit={9}
														counts={wikiTotalPage} blockNum={wikiBlockPage} setBlockNum={setWikiBlockPage}/>
					}
				</ContentFrame>
				<ContentFrame width={'410px'} justify={'space-between'}>
					<div>
						<div style={{display:'flex', justifyContent:'space-between',alignItems:'center'}}>
							<MenuTitle title={'공지사항'} unitCase={'MenuCompTitle'}/>
							<SearchBar value={infoSearchValue} disabled={infoErrorState.status !== 200}
												 keyFunction={(e)=>setInfoSearchValue(e.target.value.trimStart())} placeholder={t('검색')}
												 clearButtonAccess={infoSearchValue !== ''} clickEvent={searchInfoEventFunc} clearButton={searchInfoInitFunc}/>
						</div>
						{/*이벤트 & 공지사항*/}
						<GridTableComponent  tempCol={'60px 1fr 128px'}
																 scrollWidth={350} scrollY_Hide={true} scrollX_Hide={true}>
							<div className={'grid-title-unchecked'}>
								{['No.','제목','등록일'].map((v)=>(
									<div key={v}>{t(v)}</div>
								))}
							</div>
							{
								infoTotalList.length !== 0 ?
									<React.Fragment>
										{infoTotalList?.map((v:any)=>(
											<GridTableSelect
												selected={v.infoNum === wikiSelectedCode} className={'grid-list-unchecked-no_icon'}
												onClick={()=>{setWikiSelectedCode(v.infoNum)}}
												onDoubleClick={()=>{window.location.href = `/fas/info/${v.infoNum}`}}>
												{validationValue(v.no)}
												<div style={{whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>{dataValid(v.title)}</div>
												{validationValue(dayjs(v.registrationDate).format('YYYY.MM.DD'))}
											</GridTableSelect>
										))}
									</React.Fragment>
									:
									<NoData>{infoErrorState.notice}</NoData>
							}
						</GridTableComponent>
					</div>
					{infoTotalList?.length !== 0  &&
						<PaginationList limit={10} pageLimit={5} page={infoPage} setPage={setInfoPage}
														blockNum={infoBlockPage} setBlockNum={setInfoBlockPage}
														counts={infoTotalPage}/>}
				</ContentFrame>
			</div>
		</SectionFrame>
	);
};

export default UserWikiList;
