import React from 'react';
import {ContentFrame , NoData , SectionFrame} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import styled from "styled-components";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {useParams} from "react-router-dom";
import PreviewImage from "../../../../components/display/PreviewImage";
import {useTranslation} from "react-i18next";
import noImage from "../../../../asset/image/noImage.svg";
import ImageDetailPreview from "../../../../components/modal/ImageDetailPreview";
import MainButtons from "../../../../components/button/MainButtons";
import {buttonAuthValid , dataValid , initModal} from "../../../../function/function";
import RegisterComponent from "../../../../components/input/RegisterComponent";
import dayjs from "dayjs";
import WiKiComment from '../../../../components/input/WiKiComment'
import {useRecoilValue} from 'recoil'
import {selectedListCountValue} from '../../../../common/StateManage'
import {DOWNLOAD_ADDRESS} from "../../../../api/endPoint";
import {Modal_View_Type} from "../../../../types/Fundamental";
import DetailImageView from "../../../../components/modal/DetailImageView";

const UserWikiDetail = () => {

	const [wikiData, setWikiData] = React.useState<any>({})
	const [imageModalOpen,setImageModalOpen] = React.useState(false);
	const [selectImage, setSelectImage] = React.useState<string>('');
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const selectedCount = useRecoilValue(selectedListCountValue)
	const imageChange = (index:number) => {
		// @ts-ignore
		setSelectImage(wikiData.wikiImageUrl[index])
		setImageModalOpen(true)
	}
	const location = useParams()

	const { t }:any = useTranslation();

	const wikiDetailRes = async () => {
		try {
			const response = await client.post('/wiki/detail',{
				wikiCode: location.wikiCode,
				count: `${Number(selectedCount)+1}`
			},{headers:{Authorization}})
			const data = response.data.data
			setWikiData(data)
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		wikiDetailRes().then()
	},[])

	return (
			<SectionFrame>
				{imageModalOpen &&
					<ImageDetailPreview
					profile={selectImage}
					imageUrl={wikiData?.wikiImageUrl} clickEvent={()=>setImageModalOpen(false)}/>}

				{/*@ts-ignore*/}
				{dataModal.menu === 'attach' && <DetailImageView image={dataModal.message}
																												 closeMenu={()=>{setDataModal(initModal)}}
																												 downloadEvent={()=>{
																													 const parts = dataModal.message?.split('/');
																													 // @ts-ignore
																													 window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts.length - 1]}`
																												 }}
																												 imgDownLoadType={true}/>
				}

				<div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
					<MenuTitle title={'WIKI > 자세히보기'} unitCase={'MenuTitle'}/>
				</div>
				<ContentFrame>
					<div style={{display:'flex',userSelect:'none'}}>
						<ViewBox>
							<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
								<MenuTitle title={'WIKI 자세히보기'} unitCase={'MenuCompTitle'}/>
								<div style={{display:'flex',alignItems:'center'}}>
									<MainButtons name={'목록'} clickEvent={()=>window.location.href = '/fas/wiki'} marginDis={true} width={'56px'}/>
										<MainButtons name={'수정'} disabled={buttonAuthValid('update','2')} buttonType={'cta'} marginDis={true} width={'56px'}
																	 clickEvent={()=>{window.location.href =`/fas/wiki/modify/${location.wikiCode}`}}/>
								</div>
							</div>

							<div className={'detail_scroll'}>
								<div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
									<RegisterComponent type={'empty'} name={'solution'} value={dataValid(wikiData?.solution)} title={t('솔루션')}/>
									<RegisterComponent type={'empty'} name={'wikiTitle'} value={dataValid(wikiData?.wikiType)} title={t('유형')}/>
									<RegisterComponent type={'empty'} name={'registrationDate'} value={dayjs(wikiData?.registrationDate).format('YYYY년MM월DD일')} title={t('등록날짜')}/>
									<RegisterComponent type={'empty'} name={'modifiedDate'} value={wikiData?.modifiedDate ? dayjs(wikiData?.modifiedDate).format('YYYY년MM월DD일') : '-'} title={t('마지막 수정날짜')}/>
								</div>

								<div style={{display:'grid',gridTemplateColumns:'168px 1fr'}}>
									<div className={'detail_title'} style={{height:`${wikiData?.wikiFileName?.length !== 0 ? 32 * (wikiData?.wikiFileName?.length + 1) : 48}px`}}>
										{'첨부파일'}
										{/*{helperModule('','16px','16px','24px','100px')}*/}
									</div>
									<div className={'detail_content'} style={{display: 'flex',padding:'8px 16px',height:`${wikiData?.wikiFileName?.length !== 0  ? 32 * (wikiData?.wikiFileName?.length + 1) : 48}px`}}>
										<div>
											{wikiData?.wikiFileName?.length !== 0 ?
												wikiData?.wikiFileName?.map((v:any,index:number)=>(
													<DownloadText onClick={()=>{
														const parts = wikiData?.wikiFileUrl[index]?.split('/');
														const expandName = parts[parts?.length-1]?.split('.').pop();
														if(/(jpg|jpeg|png)$/i?.test(expandName)){
															setDataModal({menu:'attach',message:wikiData?.wikiFileUrl[index]})
														}else{
															window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts?.length-1]}`
														}
													}}>
														{`${index + 1}. ` +  v}
													</DownloadText>
												))
												:
												<NoData height={32}>{'* 첨부된 파일이 없습니다.'}</NoData>
											}
										</div>
									</div>
								</div>

								<RegisterComponent type={'empty'} name={'wikiTitle'} value={wikiData?.wikiTitle} title={t('제목')}/>

								<div style={{display:'flex'}}>
									<div className={'detail_title'} style={{height:'auto', minHeight: '364px'}}>{t('내용')}</div>
									<div className={'detail_notice'} style={{whiteSpace:'pre-line',lineHeight:'24px'}}>{wikiData?.wikiQuestion}</div>
								</div>

								{wikiData?.wikiAnswer?
									<div style={{display:'flex',borderBottom:"1px solid #E6E9F4"}}>
										<div className={'detail_title'} style={{height:'auto', minHeight: '364px'}}>{t('답변')}</div>
										<div className={"detail_notice"} style={{whiteSpace:'pre-line',lineHeight:'24px'}}>
											{wikiData?.wikiAnswer}
										</div>
									</div>
									:
									<div style={{display:'flex',color:'#7E84A3', borderTop:'1px solid #E6E9F4', fontSize:'14px',
										fontWeight:400, padding:'25px 0',
										justifyContent:'center',alignItems:'center'}}>{t('*답변 대기중 입니다..')}</div>}
							<WiKiComment/>
							</div>
						</ViewBox>

						<div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 27px'}}/>

						<div>
							<div style={{display:'flex',justifyContent:'space-between',width:'370px',alignItems:'center',marginBottom:'16px'}}>
								<MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
							</div>
							{wikiData?.wikiImageUrl?.length !== 0 ?
								<div style={{display:'flex', flexWrap:'wrap', gap:'16px',cursor:'pointer'}}>
									{wikiData?.wikiImageUrl?.map((v:any,i:number)=>(
										<PreviewImage imageUrl={v} noneDelete={true} clickEvent={()=>(imageChange(i))}/>
									))}
								</div>
								:
								<div style={{width:'168px', height:'168px', borderRadius:'4px', border:'1px solid #E0E0E0'}}>
									<img src={noImage} style={{objectFit:'contain'}} width={168} height={168} alt={''}/>
								</div>
							}
						</div>
					</div>
				</ContentFrame>
			</SectionFrame>
	);
};

export default UserWikiDetail;


const ViewBox = styled.div`
  width: 784px;
  height: 620px;

  .detail {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      width: 168px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }

    &_content {
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
			padding: 8px 0 8px 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }

    &_notice {
      height: 364px;
      width: 100%;
			padding: 8px 0 8px 16px;
      box-sizing: border-box;
      line-height: 30px;
      border-top: 1px solid #E6E9F4;
      font-size: 14px;
			overflow-y: scroll;

      &::-webkit-scrollbar {
        display: block;
        width: 15px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        width: 21px;
        background: transparent;
      }
    }

    &_scroll{
      width: 800px;
      height: 570px;
      overflow-x: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: block;
      }
    }
  }
`

const DownloadText = styled.div`
  line-height: 32px;
  &:hover{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`
