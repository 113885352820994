import React , {useEffect , useState} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import SearchBar from "../../../../../components/input/SearchBar";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {AccountModelType , Error_Type , Modal_View_Type} from "../../../../../types/Fundamental";
import {
  allCheckedState , allPersistCheckedFunction , authValid , buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  duplicateInDoubleCheck ,
  filterCheckedRow ,
  initError ,
  initModal ,
  listLoadingCircle ,
  loadingRemove , menuVisitLogRegister ,
  removeCheckedFunction ,
  singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  persistBlockPage ,
  persistCompanyData ,
  selectedCode ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import {useTranslation} from 'react-i18next';
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const AccountModelManagement = () => {
  const {t}: any = useTranslation()
  const [rowList , setRowList] = useState<AccountModelType[]>( [] )
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [idIndex , setIdIndex] = useState<number>( 1 )
  const [hoverIcon , setHoverIcon] = useState<string>( modify )
  const [iconSelect , setIconSelect] = useState<number>( - 1 )
  const [focusList , setFocusList] = useState<number>( - 1 )
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [customerModelList , setCustomerModelList] = React.useState<any[]>( [] );
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [errorState , setErrorState] = useState<Error_Type>( initError )
  const [page , setPage] = useState<number>( 1 );
  const [totalPage , setTotalPage] = useState<number>( 1 );
  const [selectCode , setSelectCode] = useRecoilState( selectedCode )
  const [selectModelData , setSelectModalData] = useState<any>( {} );
  const [searchValue , setSearchValue] = useState<string>( '' );
  const [pushValue , setPushValue] = useState<string>( '' );
  const [customerCount , setCustomerCount] = useState<number>( 0 )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage );
  const scrollRef = React.useRef<any>( null )
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    setIdIndex( idIndex + 1 )
    const rowInput = {
      id: idIndex ,
      companyCode: selectedCompany.companyCode ,
      businessNumber: '' ,
      customerCode: "" ,
      customerModelCode: "" ,
      customerModelName: "" ,
      customerName: ""
    }
    setRowList( [...rowList , rowInput] )
    setCheckedList( [...checkedList , rowInput.id] )
    scrollToStart()
  }
  const updateFunction = () => {
    if( page === 1 ){
      if( pushValue === '' ){
        accountModelListRes().then()
      } else{
        accountModelSearchRes().then()
      }
    } else{
      setPage( 1 )
    }
  }
  const onChangeArray = ( index: number , e: React.ChangeEvent<HTMLInputElement> , state: any , setState: Function ) => {
    const {value , name} = e.target
    const rowsInput = [...state]
    // @ts-ignore
    rowsInput[index][name] = value
    setState( rowsInput )
  }
  //동적 체크박스 함수
  const checkboxValid = () => {
    if( rowList.length !== 0 ){
      return {list: rowList , id: 'id'}
    } else{
      return {list: customerModelList , id: 'customerModelCode'}
    }
  }
  // 수정함수
  const accountModelUpdateRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( `/customerModel/update` , {
        ...customerModelList[iconSelect]
      } )
      const data = response.data
      if( data.status !== 200 ){
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      } else{
        setTimeout( () => {
          updateFunction()
          setIconSelect( - 1 )
          setDataModal( {menu: 'success' , message: '수정 되었습니다.'} )
          loadingRemove()
        } , 500 )
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: e.message} )
      loadingRemove()
    }
  }
  //리스트 호출 API
  const accountModelListRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.get( `/customerModel/list/${page}/${10}/${selectedCompany.companyCode}` , {
        headers: {Authorization}
      } )
      const data = response.data.data
      const valid = response.data
      if( valid.status !== 200 ){
        setErrorState( {notice: valid.message , status: valid.status} )
        setCustomerModelList( [] )
      } else{
        setTotalPage( data.total_page );
        setCustomerCount( data.customer )
        setErrorState( {status: valid.status , notice: t( '* 등록된 거래처 모델이 없습니다. 거래처 모델을 등록해 주세요.' )} )
        setPushValue( '' )
        setSearchValue( '' )
        setCustomerModelList( data.row )
        // if(data.total_page > 1 && page > 1){
        //     setCustomerModelList((prev:any)=> prev.concat(data.row))
        // }else{
        //     setCustomerModelList(data.row)
        // }
      }
      loadingRemove()
    } catch ( e: any ) {
      console.log( e )
      loadingRemove()
    }
  }
  //등록 API
  const accountModelRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customerName ) ){
      setDataModal( {menu: 'confirm' , message: '거래처명을 검색하여 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customerModelName.trim() ) ) ){
      setDataModal( {menu: 'confirm' , message: '모델명을 입력해 주세요.'} )
    } else if( duplicateInDoubleCheck( rowList , 'customerCode' , 'customerModelName' , checkedList ) ){
      setDataModal( {menu: 'confirm' , message: '거래처 하나에 같은 모델명을 \n 입력할 수 없습니다.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/customerModel/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {
                                              headers: {Authorization}
                                            } )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( [] )
            setCheckedList( [] )
            updateFunction()
            setDataModal( {menu: 'success' , message: '거래처 모델 정보가 등록 되었습니다.'} );
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //삭제 API
  const accountModelDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( `/customerModel/delete` , [
        ...checkedList
      ] , {headers: {Authorization}} )
      const data = response.data
      const caseArray = data.data?.product?.map( ( v: any ) => ( {code: v , case: '해당 모델을 사용하는 품목이 존재합니다.'} ) )
      if( data.status === 200 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          removeCheckedFunction( setCustomerModelList , customerModelList , setCheckedList , checkedList , 'customerModelCode' )
          setDataModal( {menu: 'success' , message: '거래처 모델이 삭제 되었습니다.'} )
          scrollToStart()
          if( page === 1 ){
            if( pushValue === '' ){
              accountModelListRes().then()
            } else{
              accountModelSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setIconSelect( - 1 )
          setDeleteCaseArray( caseArray )
          setDataModal( {menu: 'deleteCase'} )
          setCheckedList( checkedList.filter( ( v: string ) => !data.data.success.includes( v ) ) )
          setCustomerModelList( customerModelList.filter( ( v: any ) => !data.data.success.includes( v['customerModelCode'] ) ) )
          scrollToStart()
          if( page === 1 ){
            if( pushValue === '' ){
              accountModelListRes().then()
            } else{
              accountModelSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'warn' , message: response.data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  //삭제 VALIDATION FUNCTION
  const onDeleteFunc = () => {
    if( rowList.length === 0 ){
      setDataModal( {menu: 'select' , message: `${checkedList.length}개의 모델을 삭제 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'back' , message: '선택된 행의 정보를 삭제하시겠습니까?'} )
    }
  }
  //검색
  const accountModelSearchRes = async () => {
    try {
      listLoadingCircle()
      const response = await client.post( `/customerModel/search` , {
        ///${page}/${10}/${selectedCompany.companyCode}/${pushValue}
        page: page ,
        size: 10 ,
        companyCode: selectedCompany.companyCode ,
        keyword: pushValue
      } , {
                                            headers: {Authorization}
                                          } )
      const data = response.data.data
      setTotalPage( data.total_page )
      setCustomerModelList( data.row )
      // if(data.total_page > 1 && page > 1){
      //     setCustomerModelList((prev:any)=> prev.concat(data.row))
      // }else{
      //     setCustomerCount(data.customer)
      //     setCustomerModelList(data.row)
      // }
      loadingRemove()
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: e.message} )
      loadingRemove()
    }
  }
  React.useEffect( () => {
    if( selectedCompany.companyCode !== undefined ){
      if( pushValue === '' ){
        accountModelListRes().then()
      } else{
        accountModelSearchRes().then()
      }
    }
  } , [pushValue , page , selectedCompany] )
  const onDataResetFunc = () => {
    setIconSelect( - 1 )
    setRowList( [] )
    setCheckedList( [] )
    setCustomerModelList( [] )
    setSearchValue( '' )
    setPushValue( '' )
    setAccountCode( {} )
    setSelectModalData( {} )
    setPage( 1 )
    setPageBlock( 0 )
    setTotalPage( 1 )
    setErrorState( initError )
  }
  React.useEffect( () => {
    onDataResetFunc()
  } , [selectedCompany.companyCode] )
  const cancelFuc = () => {
    setHoverIcon( modify );
    setIconSelect( - 1 );
    setCheckedList( [] )
    setDataModal( initModal )
    if( pushValue === '' ){
      setCustomerModelList( customerModelList.map( ( v: any ) => {return v.customerModelCode === selectCode ? {...v , ...selectModelData} : v} ) )
    } else{
      accountModelSearchRes().then()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( rowList.length > 0 || iconSelect !== - 1 ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [rowList.length , iconSelect] )

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'거래처 모델 관리').then()
    }
  },[])


  // const handleClick = () => {
  //   // 삭제할 항목들을 제거
  //   const updatedRowList = rowList.filter(item => !checkedList.includes(item.id));
  //
  //   // 삭제된 항목들을 다시 생성하여 추가
  //   checkedList.forEach(( deletedId: number | undefined) => {
  //     const deletedItemIndex = rowList.findIndex(item => item.id === deletedId);
  //     if (deletedItemIndex !== -1) {
  //       updatedRowList.push(rowList[deletedItemIndex]);
  //     }
  //   });
  //
  //   // 상태 업데이트
  //   setRowList(updatedRowList);
  //   setCheckedList([]);
  // };
  //
  //
  // console.log(rowList)


  const [customerCodeArray, setCustomerCodeArray] = useState<string[]>([])

  const codeDuplicateExcept: string[] = Array.from(new Set(customerCodeArray));
  const  arrayCodeUrlConvent = JSON.stringify(codeDuplicateExcept).replace(/[\[\]'"]/g, '')

  return (
    <React.Fragment>
      {/*거래처 검색창*/}
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'} timeSet={1000}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'모델'} caseArray={deleteCaseArray} closeEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'*삭제된 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                accountModelDeleteRes().then();
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={accountModelRegisterRes}/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} modalCase={'customer'}
                              companyCode={selectedCompany.companyCode} authValid={authValid()}
                              cancelEvent={() => {
                                setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                                setAccountCode( {} )
                              }}
                              confirmEvent={() => {
                                if( rowList.length !== 0 ){
                                  Object.assign( rowList[dataModal.index] ,
                                                 {
                                                   customerName: accountCode.customerName ,
                                                   businessNumber: accountCode.businessNumber ,
                                                   customerCode: accountCode.customerCode
                                                 } )
                                } else{
                                  setCustomerModelList(
                                    customerModelList.map( ( v ) => {
                                      return v.customerModelCode === selectCode ? {
                                        ...v , customerName: accountCode.customerName ,
                                        businessNumber: accountCode.businessNumber , customerModelName: '' ,
                                        customerCode: accountCode.customerCode
                                      } : v
                                    } ) )
                                }
                                setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                              }} receiveData={( v ) => ( setAccountCode( v ) )}/>
      }

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
                                  setAccountCode( {} );
                                  setDataModal( initModal )
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={accountModelUpdateRes}/>}
      {dataModal.menu === 'excel' &&
        <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
          <React.Fragment>
            <ExcelApiButton category={'customerModel'} type={'download'} companyData={selectedCompany}
                            customButtonTitle={'Excel 받기(개별)'} noDateUrl={true} noticeModal={'선택한 거래처의 전체 모델을 가져오는 중 입니다.'}
                            disabled={codeDuplicateExcept.length === 0 && pushValue === ''}
                            dateSelect={arrayCodeUrlConvent}/>
            <ExcelApiButton category={'customerModel'} type={'download'} companyData={selectedCompany}
                            dateSelect={'@'} noDateUrl={true}
                            disabled={customerModelList.length === 0 && pushValue === ''}/>
            <ExcelApiButton category={'customerModel'} type={'format'} companyData={selectedCompany} disabled={buttonAuthValid( 'create' , '5' )}
                            noticeModal={( customerCount === 0 ) ? '등록된 거래처가 없습니다. \n거래처를 등록해 주세요.' : ''}/>
            <ExcelApiButton category={'customerModel'} type={'upload'} companyData={selectedCompany} disabled={buttonAuthValid( 'create' , '5' )}/>
          </React.Fragment>
        }/>
      }

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 거래처 모델 관리'} unitCase={'MenuTitle'}/>
          </div>
          {rowList?.length !== 0 &&
              <MainButtons name={'목록'} width={'56px'}
                           clickEvent={() => {
                             window.location.href = `${authValid() ? '/admin/fas/model' : '/fas/model'}`
                           }}/>}
        </div>

        {/*거래처 모델관리 */}
        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'거래처 모델정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <SearchBar clickEvent={() => {
                  setPushValue( searchValue );
                  setPage( 1 )
                }} keyPressDisable={true} value={searchValue} clearButtonAccess={searchValue !== ''}
                           keyFunction={( e ) => setSearchValue( e.target.value.trimStart() )} clearButton={() => {
                  accountModelListRes().then();
                  setPage( 1 )
                }}
                           disabled={( customerModelList.length === 0 && pushValue === '' ) || rowList.length !== 0 || iconSelect !== - 1}/>
                <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>

                {/*{selectedCompany.companyCode !== undefined &&*/}
                {/*    <MainButtons name={'행추가'} width={'80px'} clickEvent={addListRow}*/}
                {/*                 disabled={buttonAuthValid( 'create' , '5' ) || iconSelect !== - 1 || pushValue !== '' || checkedList[0]?.length > 1 || errorState.status !== 200}/>}*/}
                {checkedList?.length !== 0 && <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                              disabled={
                                buttonAuthValid( 'rowDelete' , '5' ) ?
                                  rowList.length === 0
                                  :
                                  buttonAuthValid( 'delete' , '5' ) ||
                                  checkedList.length === 0}
                              marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
                              clickEvent={onDeleteFunc}/>}
                {rowList?.length !== 0 &&
                      <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                                   disabled={buttonAuthValid( 'create' , '5' ) || rowList.length === 0 || checkedList.length === 0}
                                   clickEvent={() => {
                                     if( checkedList.length === rowList.length ){
                                       accountModelRegisterRes().then()
                                     } else{
                                       setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'} )
                                     }
                                   }}/>
                }
                {( rowList?.length === 0 && iconSelect === - 1 && checkedList?.length === 0 ) &&
                    <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                                 marginDis={true}
                                 disabled={buttonAuthValid( 'create' , '5' ) || ( !selectedCompany.companyCode )}
                                 clickEvent={() => window.location.href = authValid() ? '/admin/fas/model/register' : '/fas/model/register'}/>}
              </div>
            </div>
            {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
            {/*                next={()=>setPage((prev)=> prev+1)}*/}
            {/*                hasMore={totalPage > page} dataLength={dataValid(customerModelList?.length, 0)}*/}
            {/*                loader={<div/>}>*/}
            <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                                titleCheckDisabled={iconSelect !== - 1}
                                ref={scrollRef} scrollWidth={1210}
                                scrollY_Hide={rowList?.length < 10}
                                height={528}
                                tempCol={'40px repeat(2, 240px) 1fr'}>
              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox"
                         style={{
                           pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` ,
                           opacity: `${iconSelect === - 1 ? 1 : 0.5}`
                         }}
                         onChange={( e ) => {
                           allPersistCheckedFunction( e.target.checked , {
                             dataList: checkboxValid().list ,
                             checkedList: checkedList ,
                             id: checkboxValid().id ,
                             setState: setCheckedList
                           } )
                           allPersistCheckedFunction(e.target.checked,
                                                     {dataList: checkboxValid().list,
                                                       checkedList: customerCodeArray,
                                                       id: 'customerCode' ,
                                                       setState: setCustomerCodeArray
                                                     })
                         }}
                         checked={allCheckedState( {
                                                     dataList: checkboxValid().list ,
                                                     checkedList: checkedList ,
                                                     id: checkboxValid().id
                                                   } )}/>
                </label>
                {['거래처' , '사업자 번호' , '모델'].map( ( v ) => (
                  <TableValue title={v} key={v} viewType={iconSelect !== - 1 || rowList.length !== 0}
                              requiredValue={['거래처' , '모델']}/> ) )}
              </div>

              {rowList.length === 0 && customerModelList.length === 0 ? pushValue === '' ? errorState.status !== 200 ?
                    listResultMessage( authValid() , dataValid( errorState.notice , '* 업체를 선택해 주세요.' ) , {
                      width: 1210 ,
                      admin: 480 ,
                      user: 480
                    } )
                    :
                    listResultMessage( authValid() , dataValid( errorState.notice , '' ) ,
                                       {
                                         width: 1210 ,
                                         admin: 480 ,
                                         user: 480
                                       } , ()=>window.location.href = authValid() ? '/admin/fas/model/register' : '/fas/model/register' , buttonAuthValid( 'create' , '5' ),'등록')
                  :
                  listResultMessage( authValid() , '*검색 결과가 없습니다.' , {
                    width: 1210 ,
                    admin: 480 ,
                    user: 480
                  } )
                :
                rowList.map( ( v , i ) => (
                  <div className={'grid-row-list'}>
                    <label className={'checkbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                      checked={checkedList.includes( v.id )}/>
                    </label>
                    <ListInnerInput type={'search'} name={'customerName'} placeholder={'거래처'} tabIndex={- 1}
                                    clickButton={() => setDataModal( {menu: 'account' , index: i} )}
                                    inputData={v.customerName}/>
                    {validationValue( v.businessNumber )}
                    <ListInnerInput type={'text'} name={'customerModelName'} placeholder={'모델명'}
                                    inputData={v.customerModelName}
                                    onChange={( e ) => {onChangeArray( i , e , rowList , setRowList )}}/>
                  </div>
                ) )}

              {rowList.length === 0 &&
                customerModelList.map( ( v: any , i: number ) => (
                  <React.Fragment>
                    {
                      <GridTableSelect selected={v.customerModelCode === selectCode}
                                       disabled={rowList.length !== 0 || iconSelect !== - 1}
                                       className={'grid-list'} onClick={() => setSelectCode( v.customerModelCode )}
                                       checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                       onDoubleClick={() => {
                                         window.location.href = `${authValid() ? `/admin/fas/model/${v?.customerModelCode}` : `/fas/model/${v?.customerModelCode}`}`
                                       }}
                                       onMouseOut={() => setFocusList( - 1 )} onMouseOver={() => setFocusList( i )}>
                        <label className={'selectCheckbox'}>
                          <ListInnerInput type={'check'} name={'customerModelCode'}
                                          onChange={( e: any ) => {
                                            singleCheckedFunction( e.target.checked , v.customerModelCode , setCheckedList , checkedList )
                                            singleCheckedFunction( e.target.checked , v.customerCode , setCustomerCodeArray , customerCodeArray )
                                          }}
                                          checked={checkedList.includes( v.customerModelCode )}/>
                        </label>
                        {autoTextValueValidation({value: v.customerName})}
                        {validationValue( v.businessNumber )}
                        {autoTextValueValidation({value: v.customerModelName})}
                      </GridTableSelect>
                    }
                  </React.Fragment>
                ) )}
            </GridTableComponent>
            {/*</InfiniteScroll>*/}
          </div>
          {rowList.length === 0 &&
              <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                              blockNum={pageBlock} setBlockNum={setPageBlock}
                              counts={totalPage} disabled={iconSelect !== - 1}/>}
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default AccountModelManagement;
