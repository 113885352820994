import React from 'react';
import styled from "styled-components";

import {ActivateStatus} from "../../types/Fundamental";
import {useRecoilState} from "recoil";
import {DevelopState} from "../../common/StateManage";
import {dataValid} from "../../function/function";

const DevelopGrade:React.FunctionComponent<{gradeLevel?: number}> = ({gradeLevel}) => {

	const gradeLevelArray = ['Level 0', 'Level 1', 'Level 2', 'Level 3','Level 4', 'Level 5']
	// const [allStatus] = useRecoilState(DevelopState)
	return (
		<BaseComponent>
			{gradeLevelArray.map((value, index)=>(
				<DevelopGradeComp key={value}
				                  // onClick={()=>setAllStatus(index)}
					statusColor={dataValid(gradeLevel,0) >= index? '#0058FF' : '#D7DBEC' }>{value}</DevelopGradeComp>
			))}
		</BaseComponent>
	);
};

export default DevelopGrade;

const BaseComponent = styled.div`
	display: flex;
	margin: 0 auto;
	//position: absolute;
	//bottom: 0;
`

const DevelopGradeComp = styled.div<ActivateStatus>`
    width: 212px;
    font-size: 12px;
    text-align: center;
    color: #F5F6FA;
    font-weight: 400;
		background-color: ${ props => props.statusColor};
		transition: background-color 0.3s;
		user-select: none;
		//cursor: pointer;
		//&:hover{
		//	background-color: #0058ff;
		//}
	`

