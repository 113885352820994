import React , {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  authValid ,
  dataAccessLoading ,
  formatNumber ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import styled from "styled-components";
import SubMaterialsMultipleReg from "./SubMaterialsMultipleReg";

const SubMaterialsRegistration = () => {
  const {t}:any = useTranslation()
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [accountCode , setAccountCode] = useState<any>({})
  const selectedCompany = useRecoilValue<any>(authValid()? persistCompanyData : userPersistCompanyData)
  const [subMaterialState, setSubMaterialState] = useState<any>({
                                                                  customer: '' ,
                                                                  subMaterialName: '' ,
                                                                  subMaterialSerialCode: '' ,
                                                                  subMaterialStock: '' ,
                                                                  subMaterialUnit: 'ea' ,
                                                                  codeDoubleCheck: false,
                                                                })
  const [multipleReg,setMultipleReg] = useState<boolean>(false)
  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string) => {
    const {name, value} = e.target
    setSubMaterialState({...subMaterialState,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? formatNumber(value) :
          value.trim()})
  }

  const serialDoubleCheck = async (serialCode: string) => {
    if (!serialCode) {
      setDataModal({ menu: 'confirm', message: '부자재 CODE를 입력해 주세요.' });
      return;
    }

    try {
      const response = await client.post('/subMaterial/serial/check', {
        companyCode: selectedCompany.companyCode,
        subMaterialSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 부자재 CODE 입니다.' });
        setSubMaterialState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
      }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 부자재 CODE 입니다.' });
        setSubMaterialState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      serialDoubleCheck(subMaterialState.subMaterialSerialCode).then()
    }
  }

  const subMaterialRegisterRes = async () => {
    if(!subMaterialState.subMaterialSerialCode){
      setDataModal( {menu: 'confirm' , message: '부자재 CODE를 입력해 주세요.'} )
    } else if(!subMaterialState.codeDoubleCheck){
      setDataModal( {menu: 'confirm' , message: '부자재 CODE 중복확인을 해주세요.'} )
    } else if(!subMaterialState.subMaterialName){
      setDataModal( {menu: 'confirm' , message: '부자재 품명을 입력해 주세요.'} )
    } else if (!subMaterialState.subMaterialUnit){
      setDataModal( {menu: 'confirm' , message: '부자재 단위를 선택해 주세요.'} )
    }
     else{
       try {
         dataAccessLoading()
         const response = await client.post(`/subMaterial/register` ,[{
           ...subMaterialState,
            companyCode: selectedCompany.companyCode,
         }], {headers: {Authorization}})
         const data = response.data;
         if(data.status !== 200){
           setDataModal({menu: 'confirm' , message: data.message})
           loadingRemove()
         } else{
           setTimeout(() => {
             setDataModal({menu: 'success' , message: '부자재 정보가 등록 되었습니다.'})
             loadingRemove()
           } , 500)
           setTimeout(() => {
              window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'

           } , 1000)
          }
        }
        catch (e: any) {
          console.log(e)
          setDataModal({menu: 'error' , message: e.message})
          loadingRemove()
        }
      }
    }
  return (
    <SectionFrame>
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'}}
          />
      }
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => setDataModal( initModal )}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {
                                if( dataModal.index === 0 ){
                                  Object.assign( subMaterialState ,
                                                 {
                                                   customer: accountCode.customerName ,
                                                   customerName: accountCode.customerName
                                                 } )
                                } else{
                                  Object.assign( subMaterialState , {
                                    customer: accountCode.customerName ,
                                    customerName: accountCode.customerName
                                  } )
                                }
                                setDataModal( initModal )
                              }}
                              cancelEvent={() => {
                                setDataModal( initModal )
                                setAccountCode( {} )
                              }}
                              receiveData={setAccountCode}/>}
      <div style={{display: 'flex' , justifyContent: 'space-between'}}>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 부자재 기본정보 > 등록' + ( multipleReg ? ' (다중)' : '' )}
                   unitCase={'MenuTitle'}/>
        {multipleReg ?
          <div style={{display: 'flex'}}>
            <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                         clickEvent={() => {
                           setDataModal( {
                                           menu: 'cancel' ,
                                           message: '목록으로 이동 하시겠습니까?'
                                         } )
                         }}/>
            <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                         clickEvent={() => setMultipleReg( false )}/>
          </div>
          :
          <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                       clickEvent={() => setMultipleReg( true )}/>
        }

      </div>
        <ContentFrame height={'680px'}>
          {multipleReg ?
            <SubMaterialsMultipleReg/>
            :
          <div style={{display: 'flex' , height: '620px'}}>
            <RegisterComp>
              <div style={{
                display: 'flex' ,
                justifyContent: 'space-between' ,
                alignItems: 'center' ,
                marginBottom: '16px'
              }}>
                <MenuTitle title={'부자재 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <div style={{display: 'flex'}}>
                    <MainButtons name={'취소'} width={'56px'}
                                 clickEvent={() => {
                                   setDataModal( {
                                                   menu: 'cancel' ,
                                                   message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                 } )
                                 }}/>
                    <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                 clickEvent={subMaterialRegisterRes}/>
                  </div>
                </div>
              </div>

              <RegisterComponent type={'doubleCheck'} onKeyPress={onKeyPress}
                                 title={'부자재 CODE'} name={'rawMaterialSerialCode'}
                                 buttonName={subMaterialState.codeDoubleCheck ? '확인됨' : '중복확인'}
                                 disabled={( !subMaterialState.subMaterialSerialCode || subMaterialState.codeDoubleCheck )}
                                 value={subMaterialState.subMaterialSerialCode}
                                 required={true}
                                 onChange={( e ) => setSubMaterialState( ( prev: any ) => ( {
                                   ...prev ,
                                   subMaterialSerialCode: e.target.value ,
                                   codeDoubleCheck: false
                                 } ) )}
                                 clickEvent={() => {serialDoubleCheck( subMaterialState.subMaterialSerialCode ).then()}}/>

              <RegisterComponent type={'text'} title={'부자재 품명'} name={'subMaterialName'}
                                 value={subMaterialState.subMaterialName}
                                 required={true} onChange={onChange}/>
              <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                <RegisterComponent type={'drop'} title={'단위'} name={'subMaterialUnit'}
                                   value={subMaterialState.subMaterialUnit}
                                   required={true}
                                   onChange={( e: any ) => {
                                     setSubMaterialState( ( prevState: any ) => ( {
                                       ...prevState ,
                                       subMaterialUnit: e.target.value
                                     } ) )
                                   }}
                                   dropClickEvent={( v ) => {
                                     setSubMaterialState( ( prev: any ) => ( {
                                       ...prev ,
                                       subMaterialUnit: v ,
                                       subMaterialStock: ''
                                     } ) )
                                   }}
                                   dropArray={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>
                <RegisterComponent type={'unit'} title={'안전 재고량'} name={'subMaterialStock'}
                                   value={subMaterialState.subMaterialStock}
                                   unit={subMaterialState.subMaterialUnit}
                                   onChange={( e: any ) => {onChange( e , 'decimal' )}
                                   }/>
              </div>
              <RegisterComponent type={'search'} title={'거래처명'} name={'customerName'} value={subMaterialState.customer}
                                 bottomBorder={true}
                                 onChange={() => {}} clickEvent={() => {
                setDataModal( {menu: 'account' , index: 0} )
              }}/>
            </RegisterComp>
            <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
            <div className={'scroll_comp'} style={{width: '379px' , height: '600px'}}></div>
          </div>}
        </ContentFrame>
    </SectionFrame>
);
};
export default SubMaterialsRegistration;


const RegisterComp = styled.div`
  width: 784px;
  height: 100%;

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
 `
