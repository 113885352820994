import React, {useEffect} from 'react';
import styled from "styled-components";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {DatePickerComp} from "../../style/StyleComponent";



interface CalenderProps {
  data: {
    date: Date,
    decreaseMonth(): void,
    decreaseYear(): void
    increaseMonth(): void,
    increaseYear():void
    prevMonthButtonDisabled: boolean,
    nextMonthButtonDisabled: boolean,
  };
  monthType?: boolean;
  yearType?: boolean;
  femsUse?:boolean
  authType?:boolean
}



const DatePickerCustom = ({data,monthType,yearType,femsUse,authType}: CalenderProps) => {
  const {t}:any = useTranslation();

  const formatDate = (d: Date): string => {
   if (monthType){
      const date = dayjs(d);
      const monthIndex = date.month();

      const year = date.format(t('YYYY년'));
      const month = date.format('MMMM');

      return  year + ' ' + month;

    }else if(yearType){
      const date = dayjs(d);
      const year = date.format(t('YYYY년'));
      return  year;
    } else {
      const date = dayjs(d);
      const year = date.format(t('YYYY'));
      const month = date.format(t('MM'));
      const day = date.format(t('DD'));
      return  year + '.'+ month + '.' + day;
   }
  };

  const currentTime = dayjs().year() === dayjs(data.date).year()
  return (
      <DatePickerComp>
        {monthType ?
          <div style={{display:'flex',width:'200px',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear}>
                <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
              </div>

            </div>
            <div style={{display:'flex',justifySelf:'center',color:authType?'#000':'#000'}} className={'react-datepicker__current-month'} >{formatDate(data.date)}</div>
            <div style={{display:'flex',alignItems:'center'}}>
              <div className={'react-datepicker__navigation--next--year'} onClick={data.increaseYear}>
                <button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
              </div>
            </div>
          </div>
        :
        yearType ?
          <div style={{display:'flex',width:'200px',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
            <div style={{display:'flex',width:'200px',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
              <div style={{display:'flex',alignItems:'center'}}>
                <div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear}>
                  <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
                </div>
              </div>
              <div style={{display:'flex',justifySelf:'center',color:authType?'#000':'#000'}} className={'react-datepicker__current-year'}>{formatDate(data.date)}</div>
              <div style={{display:'flex',alignItems:'center'}}>
                <div className={'react-datepicker__navigation--next--year'} onClick={data.increaseYear}
                     style={{pointerEvents: currentTime? 'none' : 'auto',opacity: currentTime? 0.3 : 1}}>
                  <button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>
              </div>
            </div>
          </div>
            :
          <div style={{display:'flex',width:'100%',height:'45px', justifyContent:'space-evenly', alignItems:'center',
            backgroundColor: authType? '#cffa61' : '#f5f6fa'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              <div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear}>
                <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
              </div>
              <div className={'react-datepicker__navigation--previous'} onClick={data.decreaseMonth}>
                <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
              </div>
            </div>
            <div style={{display:'flex',justifySelf:'center',color:authType?'#000':'#000'}} className={'react-datepicker__current-month'} >{formatDate(data.date)}</div>

            <div style={{display:'flex',alignItems:'center'}}>
              <div className={'react-datepicker__navigation--next'} onClick={data.increaseMonth}>
                <button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
              </div>

              {femsUse?
                <div className={'react-datepicker__navigation--next--year'}
                     style={{pointerEvents: currentTime? 'none' : 'auto',opacity: currentTime? 0.3 : 1}}
                     onClick={data.increaseYear}>
                  <button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>
                :
                <div className={'react-datepicker__navigation--next--year'}
                     onClick={data.increaseYear}>
                  <button  disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>
              }
            </div>
          </div>}
      </DatePickerComp>
  );
};

export default DatePickerCustom;

