import React,{useState} from 'react';
import styled from 'styled-components'
import ModalBase from "./ModalBase";
import {
  GridTableComponent , GridTableSelect ,
  NoData ,
  tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {customerCount , initError , loadingRemove , modalListLoading} from "../../function/function";
import {Error_Type} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";
import SearchBar from "../input/SearchBar";

interface Interface {
  facilityDetail:any
  closeEvent?:()=>void
}
const ProductionItemModal = ({facilityDetail,closeEvent}:Interface) => {

  const [searchState, setSearchState] = useState({input:'', value:''})
  const [productionList,setProductionList] = React.useState<any[]>([])
  const [errorState,setErrorState] = React.useState<Error_Type>(initError);
  const {t}:any = useTranslation()

  const productionListRes = async () =>{
    try {
      modalListLoading()
      const response = await client.post(`/facility/product/list`,{
        facilityCode:facilityDetail?.current?.facilityCode,
      },{headers:{Authorization}})
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setProductionList([])
      }else{
        setErrorState({notice:'',status:valid.status})
        setProductionList(data.product)
      }
      loadingRemove()
    }catch (e) {
      console.log(e)
      setErrorState({notice:t('서버 에러입니다. 담당자한테 연락 부탁드립니다.'),status:''})
      setProductionList([])
      loadingRemove()
    }
  }

  const productionSearchRes = async () =>{
    try {
      modalListLoading()
      const response = await client.get(`/facility/product/list/${facilityDetail?.current?.facilityCode}/${searchState.value}`,{headers:{Authorization}})
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:'* 관련된 품목이 없습니다.',status:valid.status})
        setProductionList([])
      }else{
        setErrorState({notice:'',status:valid.status})
        setProductionList(data.product)
      }
      loadingRemove()
    }catch (e) {
      console.log(e)
      setErrorState({notice:'서버 에러입니다. 담당자한테 연락 부탁드립니다.',status:''})
      setProductionList([])
      loadingRemove()
    }
  }

  React.useEffect(()=>{
    if(searchState.value === ''){
      productionListRes().then()
    }else{
      productionSearchRes().then()
    }
  },[searchState.value])

  return (
    <ModalBase modalContent={
      <BaseComponent>
        <div>
          <div className={'modal'} style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff', color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'}}>
            {t(`생산품목 보기 (`)+ `${facilityDetail?.current?.ictNumber})`}
          </div>

          <div style={{padding:'24px 16px 8px 16px'}}>
            <SearchBar keyFunction={(e)=>setSearchState((prev)=>({...prev, input:e.target.value}))}
                       clickEvent={()=>setSearchState((prev)=>({...prev, value:prev.input}))} margin={'0'}/>

            <GridTableComponent tempCol={'128px 88px 160px 118px 88px 1fr'}
                                scrollX_Hide={true} height={300}>
              <div className={'grid-title-unchecked'}>
                {['거래처','모델','CODE','품명','품목 종류','단위'].map((v)=>(
                  <div key={v}>{t(v)}</div>
                ))}
              </div>
              {productionList?.length === 0?
                <NoData height={240}>{t(errorState.notice)}</NoData>
                :
                productionList?.map((v)=>(
                  <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                    {(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :
                      tooltipModule(customerCount(v.customer).map((el:any)=>(el.customerName)).join(','),0,validationValue(customerCount(v.customer)[0].customerName + ((customerCount(v.customer).length -1) !== 0 ?` 외 ${customerCount(v.customer).length-1}` : '')))}
                    {(v.customer.length === 0 || !v.customer)? <div>{'-'}</div> :
                      tooltipModule(v.customer.map((el:any)=>(el.customerModelName)).join(','),0,validationValue(v.customer[0].customerModelName + ((v.customer.length -1) !== 0 ?` 외 ${v.customer.length-1}` : '')))}
                    {validationValue(v.serialCode)}
                    {validationValue(v.productName)}
                    {validationValue(v.type)}
                    {validationValue(v.unit, 'ea')}
                  </GridTableSelect>
                ))}
            </GridTableComponent>
          </div>
        </div>
        <div style={{display:'flex',alignSelf:'center',paddingBottom:'24px'}}>
          <MainButtons name={'확인'} buttonType={'cta'} width={'144px'} clickEvent={closeEvent} height={'40px'}/>
        </div>
      </BaseComponent>
    }/>
  );
};
export default ProductionItemModal;

const BaseComponent = styled.div`
  width: 1032px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  margin:auto;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;
  .modal{
    width: 1032px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    gap: 8px;
    font-size: 18px;
    font-weight: 900;
  }
`