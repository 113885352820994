import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import UserBarcodeManagement from "../../../containers/user/fas/barcode/UserBarcodeManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasBarcode = () => {

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<UserBarcodeManagement/>}/>
    </PageContainer>
  );
};

export default FasBarcode;