import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import UserNoticeContainer from "../../../containers/user/fas/notice/UserNoticeContainer";
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const FasNotice = () => {

	return (
		<PageContainer>
			<IntegratedSideMenu/>
			<SectionContainer contentContainer={<UserNoticeContainer/>}/>
		</PageContainer>
	);
};

export default FasNotice;
