import React  from 'react';
import styled from 'styled-components';
import {ContentFrame , NoData , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";

import {useParams} from "react-router-dom";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import PreviewImage from "../../../../../components/display/PreviewImage";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useTranslation} from "react-i18next";
import noImage from "../../../../../asset/image/noImage.svg";
import MainButtons from "../../../../../components/button/MainButtons";
import {
	buttonAuthValid ,
	dataAccessLoading , dataValid ,
	initModal ,
	loadingRemove
} from "../../../../../function/function";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import dayjs from "dayjs";
import RegisterInput from "../../../../../components/input/RegisterInput";
import WiKiComment from '../../../../../components/input/WiKiComment'
import TotalImageUploadBox from '../../../../../components/input/TotalImageUploadBox'
import {useRecoilValue} from 'recoil'
import {selectedListCountValue} from '../../../../../common/StateManage'
import {DOWNLOAD_ADDRESS} from "../../../../../api/endPoint";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import AttachMultipleUploadBox from "../../../../../components/input/AttachMultipleUploadBox";

const WikiDetail:React.FunctionComponent = () => {

	const params = useParams()
	const {wikiCode} = params

	const [wikiData, setWikiData] = React.useState<any>({})
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
	const [receiveFileData,setReceiveFileData] = React.useState<any>({fileKey:[],fileName:[],fileUrl:[]})
	const [fileKeyState,setFileKeyState] = React.useState<string[]>([]);
	const [updateMode, setUpdateMode] = React.useState<boolean>(false)
	const selectedCount = useRecoilValue(selectedListCountValue)
	const { t }:any = useTranslation();

	const wikiDetailRes = async () => {
		try {
			const response = await client.post('/wiki/detail',{
				wikiCode: wikiCode,
				count: `${Number(selectedCount)+1}`
			},{headers:{Authorization}})
			const data = response.data.data
			setReceiveFileData({fileKey:data.wikiFile, fileName:data.wikiFileName, fileUrl:data.wikiFileUrl})
			setWikiData(data)
		}catch (e) {
			console.log(e)
		}
	}

	const wikiAnswerRes = async () => {
		if(!wikiData.wikiType){
			setDataModal({menu:'warn', message:'유형을 선택해 주세요.'})
		}else{
			try {
				dataAccessLoading()
				const response = await client.post( '/wiki/update' , {
					...wikiData,
					wikiFile:dataValid(fileKeyState,[]),
					wikiFinish: !wikiData.wikiAnswer? '0' : '1'
				} , {headers: {Authorization}} )
				const data = response.data
				if( data.status !== 200 ){
					setDataModal( {menu: 'warn' , message: data.message} )
					loadingRemove()
				} else{
					setTimeout( () => {
						loadingRemove()
						// setDataModal( {menu: 'success' , message: '답변이 등록 되었습니다.'} )
						setDataModal( {menu: 'success' , message: 'WIKI 내용이 수정 되었습니다.'} )
					} , 500 )
				}
			} catch (e: any) {
				console.log(e)
				setDataModal( {menu: 'error' , message: e.message} )
				loadingRemove()
			}
		}
	}


	// const wikiFinishCheck = async () => {
	// 	try {
	// 		await client.post( '/wiki/update' , {
	// 			...wikiData,
	// 			wikiFinish: '1'
	// 		} , {headers: {Authorization}} )
	// 	}
	// 	catch (e) {
	// 		console.log(e)
	// 	}
	// 	finally {
	// 		window.location.href = '/admin/fas/wiki'
	// 	}
	// }

	const wikiDeleteRes = async () => {
		try {
			dataAccessLoading()
			const response = await client.post('/wiki/delete',{
				wikiCode: wikiCode
			},{headers:{Authorization}})
			const data = response.data
			if( data.status !== 200 ){
				setDataModal( {menu: 'confirm' , message: data.message} )
				loadingRemove()
			} else{
				setTimeout( () => {
					loadingRemove()
					setDataModal( {menu: 'success' , message: '삭제 되었습니다.'} )
				} , 500 )
			}
		}catch (e:any) {
			console.log(e)
			setDataModal({menu:'error',message:e.message})
			loadingRemove()
		}
	}

	const onChangeValue = (e:any,trim?:boolean) => {
		const {value, name} = e.target
		setWikiData({
									...wikiData,
									[name]: trim ? value.trim() : value
								});
	}

	React.useEffect(()=>{
		wikiDetailRes().then()
	},[])

	// const closePopup = () => {
	// 	if(dataModal.message === t('답변이 등록 되었습니다.')){
	// 		window.location.href = '/admin/fas/wiki'
	// 	}
	// 	else if(dataModal.message === t('삭제 되었습니다.')){
	// 		window.location.href = '/admin/fas/wiki'
	// 	}
	// 	else{
	// 		setDataModal(initModal)
	// 	}
	// }

	// useEffect(() => {
	// 	const handleBeforeUnload = (event:BeforeUnloadEvent) => {
	// 		if (dataModal.menu === '') {
	// 			event.preventDefault();
	// 			event.returnValue = '';
	// 		}
	// 	};
	// 	window.addEventListener('beforeunload', handleBeforeUnload);
	//
	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload);
	// 	};
	// }, [dataModal.menu]);

	return (
		<SectionFrame>
			{dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'}
																									closeEvent={()=> {
																										if(dataModal.message?.includes('삭제')){
																											window.location.href = `/admin/fas/wiki`
																										}
																										if(dataModal.message?.includes('수정')){
																											window.location.reload();
																										}
																										setDataModal(initModal)
																										setUpdateMode(false)}
																									}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>{setDataModal(initModal)}}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>{setDataModal(initModal)}}/> : null}

			{dataModal.menu === 'preview' &&
				<ImageDetailPreview profile={wikiData?.wikiImageUrl[dataModal.index]}
														imageUrl={wikiData?.wikiImageUrl}
														clickEvent={() => {setDataModal(initModal);}}/>}

			{/*{dataModal.menu === 'confirm' && <ConfirmPopup clickEvent={closePopup} statusText={dataModal.message}/>	}*/}

			{dataModal.menu ===  'select' &&
				<SelectConfirmModal statusText={t(dataModal.message)}
														noticeText={t('* 삭제된 데이터는 복구되지 않습니다.')}
														cancelEvent={()=>setDataModal(initModal)}
														clickEvent={()=>{wikiDeleteRes().then(); setDataModal(initModal)}}/>}

			{dataModal.menu === 'cancel' &&
				<SelectConfirmModal statusText={t(dataModal.message)}
														noticeText={t(' * "예" 선택시 저장없이 이동합니다.')}
														cancelEvent={()=>{setDataModal(initModal)}}
														viewType={true}
														clickEvent={()=>{ window.location.href = `/admin/fas/wiki`}}
				/>
			}
			{/*@ts-ignore*/}
			{dataModal.menu === 'attach' && <DetailImageView image={dataModal.message}
																											 closeMenu={()=>{setDataModal(initModal)}}
																											 downloadEvent={()=>{
																												 const parts = dataModal.message?.split('/');
																												 // @ts-ignore
																												 window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts.length - 1]}`
																											 }}
																											 imgDownLoadType={true}/>
			}

			<MenuTitle title={'게시판 관리 > WIKI 관리 > 자세히 보기'} unitCase={'MenuTitle'}/>
			<ContentFrame>
				<div style={{display:'flex'}}>
					<ViewBox>
						<div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
							<MenuTitle title={'WIKI 자세히 보기'} unitCase={'MenuCompTitle'}/>
							{updateMode?
								<div style={{display:'flex'}}>
									<MainButtons name={'취소'} width={'56px'}
															 clickEvent={()=> {
																 wikiDetailRes().then()
																 setUpdateMode(false)
															 }}/>
									<MainButtons name={'저장'} disabled={buttonAuthValid('create','21')} buttonType={'cta'} width={'56px'} marginDis={true} clickEvent={wikiAnswerRes}/>
								</div>
								:
								<div style={{display: 'flex'}}>
									{/*<MainButtons name={'목록'} width={'56px'}*/}
									{/*						 clickEvent={() => {setDataModal({menu: 'cancel' , message: '답변이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>*/}
									<MainButtons name={'목록'} width={'56px'}
															 clickEvent={()=>window.location.href = '/admin/fas/wiki'}/>
									<MainButtons name={'삭제'} disabled={buttonAuthValid('delete' , '21')} width={'56px'}
															 clickEvent={() => setDataModal({menu: 'select' , message: t('정말 삭제 하시겠습니까?')})}/>
									{/*<MainButtons name={'저장'} disabled={buttonAuthValid('create','21')} buttonType={'cta'} width={'56px'} marginDis={true} clickEvent={wikiAnswerRes}/>*/}
									<MainButtons name={'수정'} disabled={buttonAuthValid('create' , '21')} buttonType={'cta'} width={'56px'}
															 marginDis={true} clickEvent={() => setUpdateMode(true)}/>
								</div>}
						</div>
						<div className={'scroll_func'}>

							<div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
								<RegisterComponent type={'empty'} name={'companyName'} value={wikiData?.companyName} title={t('업체명')}/>
								<RegisterComponent type={'empty'} name={'companyCode'} value={wikiData?.companyCode} title={t('업체코드')}/>
								<RegisterComponent type={'empty'} name={'registrationDate'} value={dayjs(wikiData?.registrationDate).format('YYYY년MM월DD일')} title={t('등록날짜')}/>
								<RegisterComponent type={'empty'} name={'modifiedDate'} value={wikiData?.modifiedDate ? dayjs(wikiData?.modifiedDate).format('YYYY년MM월DD일') : '-'} title={t('마지막 수정날짜')}/>

								{updateMode?
									<RegisterComponent type={'drop'} title={'솔루션'} dropWidth={'192px'}
																		 dropClickEvent={(v) => setWikiData((prev: any) => ( {...prev , solution: v} ))}
																		 name={'solution'} value={wikiData?.solution} onChange={onChangeValue}
																		 dropAddNoneSelect={true}
																		 dropArray={['FAS' , 'P-BAS' , 'P-MS' , 'C-MS','F-EMS' , 'MES']}/>
									:
									<RegisterComponent type={'empty'} name={'solution'} value={dataValid(wikiData?.solution)}
																		 title={t('솔루션')}/>}


								{updateMode?
									<RegisterComponent type={'drop'} title={'유형'} dropWidth={'192px'} required={true}
																		 dropClickEvent={(v) => setWikiData((prev: any) => ( {...prev , wikiType: v} ))}
																		 name={'wikiType'} value={wikiData?.wikiType} onChange={onChangeValue}
																		 dropAddNoneSelect={true}
																		 dropArray={['기획 회의' , '개발중' , '개발완료' , '보류']}/>
									:
									<RegisterComponent type={'empty'} name={'wikiType'} value={wikiData?.wikiType} title={'유형'}/>
								}

								{updateMode?<RegisterComponent type={'drop'} title={'프론트 담당자'} dropWidth={'192px'}
																							 dropClickEvent={(v) => setWikiData((prev: any) => ( {...prev , frontManager: v} ))}
																							 name={'frontManager'} value={wikiData?.frontManager} onChange={onChangeValue}
																							 dropAddNoneSelect={true}
																							 dropArray={['송재훈' , '박서후' , '양소윤']}/>
									:
									<RegisterComponent type={'empty'} name={'frontManager'} value={dataValid(wikiData?.frontManager)}
																		 title={'프론트 담당자'}/>
								}

								{updateMode?<RegisterComponent type={'drop'} title={'백엔드 담당자'} dropWidth={'192px'}
																							 dropClickEvent={(v) => setWikiData((prev: any) => ( {...prev , backManager: v} ))}
																							 name={'backManager'} value={wikiData?.backManager} onChange={onChangeValue}
																							 dropAddNoneSelect={true}
																							 dropArray={['박창민' , '최지수']}/>
									:
									<RegisterComponent type={'empty'} name={'backManager'} value={dataValid(wikiData?.backManager)}
																		 title={'백엔드 담당자'}/>
								}
							</div>

							{updateMode ?
								<AttachMultipleUploadBox fileKeyArray={(data)=>{setFileKeyState(data)}}
																				 receiveKey={dataValid(receiveFileData.fileKey,[])}
																				 receiveUrl={dataValid(receiveFileData.fileUrl,[])}
																				 receiveFileName={dataValid(receiveFileData.fileName,[])}
																				 placeholder={'* 업로드 버튼을 클릭하여 파일을 등록 해주세요.'}/>
								:
								<div style={{display:'grid',gridTemplateColumns:'168px 1fr'}}>
									<div className={'reg-title'} style={{height:'100%'}}>
										{'첨부파일'}
										{/*{helperModule('','16px','16px','24px','100px')}*/}
									</div>
									<div className={'reg-content'} style={{display: 'flex',overflow:'hidden',padding:'8px 16px', height:'100%' }}>
										<div>
											{wikiData?.wikiFileName?.length !== 0 ?
												wikiData?.wikiFileName?.map((v:any,index:number)=>(
													<DownloadText onClick={()=>{
														const parts = wikiData?.wikiFileUrl[index]?.split('/');
														const expandName = parts[parts?.length-1]?.split('.').pop();
														if(/(jpg|jpeg|png)$/i?.test(expandName)){
															setDataModal({menu:'attach',message:wikiData?.wikiFileUrl[index]})
														}else{
															window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts?.length-1]}`
														}
													}}>
														{`${index + 1}. ` +  v}
													</DownloadText>
												))
												:
												<NoData height={32}>{'* 첨부된 파일이 없습니다.'}</NoData>
											}
										</div>
									</div>
								</div>
							}

							{updateMode?
								<RegisterComponent type={'text'} value={wikiData?.wikiTitle} limit={100}
																	 required={true} title={t('제목')} placeholder={t('제목을 입력해 주세요.')}
																	 onChange={(e) => {onChangeValue(e)}} name={'wikiTitle'}
								/>:

								<RegisterComponent type={'empty'} name={'wikiTitle'} value={wikiData?.wikiTitle} title={t('제목')}/>
							}


							{updateMode?
								<div style={{display: 'grid' , gridTemplateColumns: '168px 1fr'}}>
									<div className={'info-title'} style={{height: 'auto', minHeight:'264px'}}>{t('내용')}</div>
									<div className={'info-content'} style={{minHeight:'264px'}}>
										<RegisterInput type={'textarea'} name={'wikiQuestion'} value={wikiData?.wikiQuestion}
																	 maxHeight={'248px'} limit={10000}
																	 onChange={onChangeValue} placeholder={t('내용을 입력해주세요.')}/>
									</div>
								</div>
								:
								<div style={{display:'flex'}}>
									<div className={'detail_title'} style={{height:'auto',minHeight:'264px'}}>{t('내용')}</div>
									<div className={'detail_notice'} style={{whiteSpace:'pre-line',lineHeight:'24px'}}>{wikiData?.wikiQuestion}</div>
								</div>
							}

							{updateMode?
								<div style={{display: 'grid' , gridTemplateColumns: '168px 1fr'}}>
									<div className={'info-title'} style={{minHeight: '264px'}}>{t('답변')}</div>
									<div className={'info-content'} style={{minHeight:'264px'}}>
										<RegisterInput type={'textarea'} name={'wikiAnswer'} value={dataValid(wikiData?.wikiAnswer , '')}
																	 maxHeight={'248px'} limit={10000}
																	 onChange={onChangeValue} placeholder={t('내용을 입력해주세요.')}/>
									</div>
								</div>
								:
								<div style={{display: 'grid' , gridTemplateColumns: '168px 1fr'}}>
									<div className={'info-title'} style={{height:'auto', minHeight: '264px'}}>{t('답변')}</div>
									<div className={'detail_notice'} style={{whiteSpace:'pre-line',
										color: wikiData?.wikiAnswer? '#171725' : '#7E84A3', lineHeight: '24px'}}>{dataValid(wikiData?.wikiAnswer,' * 답변이 없습니다.')}</div>
								</div>
							}

							<WiKiComment/>
						</div>

					</ViewBox>
					<div style={{marginLeft:'24px',paddingLeft:'24px',borderLeft:'1px solid #e0e0e0'}}>
						<div style={{display:'flex',justifyContent:'space-between',width:'370px',alignItems:'center',marginBottom:'16px'}}>
							<MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
						</div>
						{
							updateMode?
							<TotalImageUploadBox receiveKey={wikiData?.wikiImage} profileSelect={false}
																	 receiveUrl={wikiData?.wikiImageUrl}
																	 imageKeyArray={(data) => setWikiData((prev: any) => ( {...prev , wikiImage: data} ))}/>
							:
							wikiData?.wikiImageUrl?.length !== 0  ?
								<div style={{display:'flex', gap:'16px', flexWrap:'wrap'}}>
									{wikiData?.wikiImageUrl?.map((v: any , i: number) => (
										<PreviewImage imageUrl={v} noneDelete={true}
																	clickEvent={() => {setDataModal({menu: 'preview' , index: i})}}/>
									))}
								</div>
								:
								<div style={{width: '168px' , height: '168px' , borderRadius: '4px' , border: '1px solid #E0E0E0'}}>
									<img src={noImage} style={{objectFit: 'contain'}} width={168} height={168} alt={''}/>
								</div>
						}
					</div>
				</div>
			</ContentFrame>
		</SectionFrame>
	);
};

export default WikiDetail;

const ViewBox = styled.div`
	width: 784px;
	height: 584px;

	.detail {
		&_title {
			display: flex;
			align-items: center;
			height: 48px;
			width: 168px;
			padding-left: 16px;
			box-sizing: border-box;
			font-weight: 900;
			font-size: 12px;
			min-width: 168px;
			background-color: #F5F6FA;
			border-top: 1px solid #E6E9F4;
		}
		&_notice {
			height: 264px;
			width: 100%;
			padding: 8px 0 8px 16px;
			box-sizing: border-box;
			line-height: 24px;
			border-top: 1px solid #E6E9F4;
			font-size: 14px;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				width: 15px;
				display: block;
			}
		}
	}
	
	.info, .reg {
		display: grid;
		box-sizing: border-box;

		&-title {
			padding: 16px;
			width: 168px;
			box-sizing: border-box;
			display: flex;
			//justify-content: space-between;
			align-items: center;
			height: 48px;
			background-color: #F5F6FA;
			color: #131523;
			font-size: 12px;
			font-weight: 800;
			border-top: 1px solid rgba(213, 215, 227, 0.5);

		}

		&-content {
			display: flex;
			padding-left: 16px;
			min-width: 224px;
			box-sizing: border-box;
			font-size: 14px;
			height: 48px;
			border-top: 1px solid rgba(213, 215, 227, 0.5);
			align-items: center;
		}
	}

	.scroll_func{
		width: 804px;
		height: 584px;
		overflow-x: hidden;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 20px;
			display: block;
		}
	}

	.click_text{
		color:#7E84A3;
		font-size: 14px;
		font-weight: bold;
		cursor: pointer;
		text-decoration: underline 1px #7E84A3;
		margin-right: 12px;
		&:hover{
			text-underline-offset: 3px;
		}
	}
`

const DownloadText = styled.div`
  line-height: 24px;
  &:hover{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`