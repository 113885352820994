import React, {useState, useRef} from 'react';
import ModalBase from "./ModalBase";
import Draggable from "react-draggable";
import {
	NoData,
	orderComponent,
	tooltipModule,
	validationValue
} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import styled from "styled-components";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import InfiniteScroll from "react-infinite-scroll-component";
import MenuTitle from "../title/MenuTitle";
import {dataValid, isTouchDevice, loadingRemove, modalListLoading} from "../../function/function";
import {useLocation} from "react-router-dom";
import SearchBar from "../input/SearchBar";
import {useTranslation} from "react-i18next";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface FactoryRegisterModalProps {
	DataDetail?: any | undefined
	//첫번째 테이블 객체값
	inputMaterialClickEvent?: () => void
	facilityClickEvent?: () => void
	confirmEvent?: (object: any) => void
	cancelEvent?: () => void
	companyCode: string
}

const FactoryRegisterModal = ({companyCode, DataDetail, confirmEvent, cancelEvent}: FactoryRegisterModalProps) => {
	const location = useLocation()
	const authValid = location.pathname.includes('/admin')
	const modalRef = useRef(null);
	const {t}: any = useTranslation();
	const [factoryDataList, setFactoryDataList] = useState<any[]>([]);
	const [selectFactory, setSelectFactory] = useState<any>(DataDetail);

	const [pageState, setPageState] = useState<number>(1);
	const [totalPageState, setTotalPageState] = useState<number>(1);
	const [subDivFactoryList, setSubDivFactoryList] = useState<any[]>([]);
	const [selectedSubFactoryName, setSelectedSubFactoryName] = useState<string>(DataDetail.factorySubDivision);
	const [searchInput, setSearchInput] = useState<string>('')
	const [pushInput, setPushInput] = useState<string>('')

	const factoryListRes = async (companyCode: string) => {
		try {
			modalListLoading()
			const response = authValid ?
				await client.post(`/factory/user/search`,
					{page: pageState, size: 10, companyCode: companyCode, keyword: '@'}, {headers: {Authorization}})
				:
				await client.get(`/factory/list/${pageState}/10/${companyCode}`, {headers: {Authorization}})
			const data = response.data.data
			setTotalPageState(data.total_page)
			setSearchInput('');
			setPushInput('');
			if (data.total_page > 1 && pageState > 1) {
				setFactoryDataList((prev: any) => prev.concat(data.row))
			} else {
				setFactoryDataList(data.row)
			}
			loadingRemove()
		} catch (e) {
			loadingRemove()
			console.log(e)
		}
	}

	const factorySearchRes = async (companyCode: string) => {
		try {
			modalListLoading()
			const response = await client.post(authValid ?
					`/factory/admin/search`
					:
					`/factory/user/search`,
				{page: pageState, size: 10, companyCode: companyCode, keyword: searchInput},
				{headers: {Authorization}})
			const data = response.data.data
			setTotalPageState(data?.total_page)
			if (data.total_page > 1 && pageState > 1) {
				setFactoryDataList((prev: any) => prev.concat(data.row))
			} else {
				setFactoryDataList(data.row)
			}
			loadingRemove()
			console.log(data)
		} catch (e) {
			console.log(e)
			loadingRemove()
		}
	}

	const subDivFactoryRes = async () => {
		modalListLoading()
		try {
			const response = await client.post(`/factory/subdivision/list`, {
				factoryCode: selectFactory.factoryCode
			}, {headers: {Authorization}})
			const data = response.data.data
			setSubDivFactoryList(data.subdivision)
		} catch (e) {
			console.log(e)
		}
		loadingRemove()
	}

	React.useEffect(() => {
		if (pushInput === '') {
			factoryListRes(companyCode).then()
		} else {
			factorySearchRes(companyCode).then()
		}
	}, [pageState, pushInput, companyCode])

	React.useEffect(() => {
		subDivFactoryRes().then();
	}, [selectFactory.factoryCode])

	return (
		<ModalBase zIndex={101} modalContent={
			<Draggable ref={modalRef} disabled={isTouchDevice}>
				<BaseComponent>
					<div className={'modal'} style={{
						backgroundColor: window.localStorage.getItem('auth')?.includes('admin') ? '#CFFA61' : '#0058ff',
						color: window.localStorage.getItem('auth')?.includes('admin') ? '#171725' : '#fff'
					}}>
						<div className={'modal_title'}>{t('공장 등록')}</div>
						<div className={'modal_sub_title'}>{t('(해당 주변장치의 위치정보를 등록해주세요.)')}</div>
					</div>
					<div style={{width: '1032px', height: "512px"}} onMouseDown={(e) => {
						e.stopPropagation()
					}}>
						<TableDefaultOption>
							{orderComponent(t('장치 종류'), DataDetail?.peripheralType)}
							{orderComponent(t('장치 제조번호'), DataDetail?.peripheralNumber)}
							{orderComponent(t('장치 제조사'), DataDetail?.peripheralManufacturer)}
							{orderComponent(t('장치 이름'), DataDetail?.peripheralName)}
							{orderComponent(t('제조 연월일'), DataDetail?.manufactureDate)}
							{orderComponent(t('담당자'), DataDetail?.managerName)}
							{orderComponent('-', '-')}
							{orderComponent('-', '-')}
						</TableDefaultOption>
						<div style={{
							width: '1032px',
							display: 'flex',
							padding: '0 16px',
							boxSizing: 'border-box',
							justifyContent: 'space-between'
						}}>
							<ListBox>
								<div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '4px'}}>
									<MenuTitle title={'공장 리스트'} unitCase={'MenuCompTitle'}/>
									<SearchBar clickEvent={() => {
										setPushInput(searchInput);
										setPageState(1)
									}} clearButtonAccess={searchInput !== ''}
									           keyFunction={(e) => (setSearchInput(e.target.value.trimStart()))} value={searchInput}
									           clearButton={() => {
										           factoryListRes(companyCode).then();
										           setPageState(1)
									           }}
									           disabled={(factoryDataList?.length === 0 && pushInput === '')}/>
								</div>
								<GridTableComponent tempCol={'120px 304px 120px'} scrollWidth={545} scrollX_Hide={true} scrollY_Hide={true}>
									<div className={'grid-title-unchecked'} style={{marginTop: 0}}>
										{['공장명', '공장 주소', '담당자'].map((v) => (
											<div>{t(v)}</div>))}</div>
									<InfiniteScroll scrollableTarget={'scroll_div'}
									                next={() => setPageState((prev) => prev + 1)}
									                hasMore={totalPageState > pageState}
									                dataLength={dataValid(factoryDataList?.length, 0)}
									                loader={<div/>}>
										<div className={'scroll_bar'} id={'scroll_div'}
										     style={{height: '192px'}}>
											{factoryDataList?.length !== 0 ?
												<React.Fragment>
													<GridTableSelect selected={selectFactory.factoryName === ''} className={'grid-list-unchecked'}
													                onClick={() => {
														                setSelectFactory({
															                factoryName: '',
															                factoryCode: '',
															                factorySubDivision: ''
														                });
														                setSelectedSubFactoryName('')
													                }}>
														<div/>
														<div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>{t('선택없음')}</div>
														<div/>
													</GridTableSelect>

													{factoryDataList?.map((v) => (
														<GridTableSelect selected={selectFactory.factoryName === v.factoryName} className={'grid-list-unchecked'}
														                onClick={() => {
															                setSelectFactory(v);
															                subDivFactoryRes().then()
														                }}>
															{validationValue(v.factoryName)}
															{tooltipModule(dataValid(v.address))}
															{validationValue(v.factoryManager)}
														</GridTableSelect>
													))}
												</React.Fragment>
												:
												<NoData height={192}>{t("* 해당 데이터가 없습니다.")}</NoData>
											}
										</div>
									</InfiniteScroll>
								</GridTableComponent>
							</ListBox>

							<ListBox style={{width: '436px'}}>
								<div style={{marginBottom: '4px'}}>
									<MenuTitle
										title={!selectFactory.factoryName ? t('세분화 공장명') : t('세분화 공장명 (') + `${selectFactory.factoryName})`}
										unitCase={'MenuCompTitle'}/>
								</div>
								<GridTableComponent tempCol={'1fr'} scrollWidth={426} scrollX_Hide={true}>
									<div className={'grid-title-unchecked'}>
										<div>
											{t('세분화명')}
										</div>
									</div>
									{!selectFactory.factoryCode ? factoryDataList?.length ?
											<NoData height={192}>{t('* 공장을 선택해 주세요.')}</NoData>
											:
											<NoData height={192}>{t('* 등록된 공장이 없습니다.')}</NoData>
										:
										<React.Fragment>
											{subDivFactoryList !== undefined &&
													<React.Fragment>
														{subDivFactoryList[0] !== null ? factoryDataList?.length ?
																<div className={'scroll_bar'}
																     style={{height: '192px'}}>
																	{/*@ts-ignore*/}
																	<React.Fragment>
																		<GridTableSelect selected={selectedSubFactoryName === ''} className={'grid-list-unchecked'}
																		                onClick={() => {
																			                setSelectedSubFactoryName('')
																		                }}>
																			<div style={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>{t('선택없음')}</div>
																		</GridTableSelect>
																		{subDivFactoryList?.map((v) => (
																			<GridTableSelect selected={selectedSubFactoryName === v} className={'grid-list-unchecked'}
																			                tempCol={'1fr'}
																			                onClick={() => {
																				                setSelectedSubFactoryName(v)
																			                }}>
																				<div>{v}</div>
																			</GridTableSelect>

																		))}
																	</React.Fragment>
																</div>
																:
																<NoData height={192}>{t("검색 결과가 없습니다.")}</NoData>
															:
															<NoData height={192}>{t('*등록된 세분화 공장이 없습니다.')}</NoData>

														}
													</React.Fragment>
											}
										</React.Fragment>
									}
								</GridTableComponent>
							</ListBox>
						</div>
						<div style={{display: 'flex', justifyContent: 'center'}}>
							<div style={{display: 'flex', justifyContent: 'center', marginBottom: '24px'}}>
								<MainButtons name={'취소'} width={'144px'} margin={'0 12px'}
								             buttonType={'popupNormal'} clickEvent={cancelEvent}/>
								<MainButtons name={'선택 완료'} width={'144px'} margin={'0 12px'}
								             buttonType={'popupCta'} clickEvent={() => {
									if (confirmEvent) {
										confirmEvent({
											factoryName: selectFactory.factoryName,
											factoryCode: selectFactory.factoryCode,
											factorySubDivision: selectedSubFactoryName
										})
									}
									if (cancelEvent) {
										cancelEvent()
									}
								}
								}/>
							</div>
						</div>
					</div>


				</BaseComponent>
			</Draggable>
		}/>
	);
};
export default FactoryRegisterModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;

  .modal {
    width: 1032px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;

    &_title {
      font-size: 18px;
      font-weight: 900;
      margin-right: 8px;
    }

    &_sub_title {
      font-size: 14px;
      font-weight: bold;
      opacity: 0.5;
    }
  }
`

const TableDefaultOption = styled.div`
  margin: 16px 16px 24px 16px;
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns: repeat(4, 104px 146px);
  align-items: center;
  box-sizing: border-box;

  .order {
    &_title {
      height: 32px;
      background-color: #F5F6FA;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }

    &_content {
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }
  }
`

const ListBox = styled.div`
  height: 300px;

  .scroll_bar {
    height: 200px;
    overflow-x: hidden;
    overflow-y: overlay;

    &::-webkit-scrollbar {
      width: 18px;
      display: block;
    }
  }
`
