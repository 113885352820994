import React from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , multipleImageGridStyle , NoData , SectionFrame} from "../../../../../style/StyleComponent";
import noImage from "../../../../../asset/image/noImage.svg";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useTranslation} from "react-i18next";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import {buttonAuthValid , initModal} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import PreviewImage from "../../../../../components/display/PreviewImage";
import {useLocation} from "react-router-dom";
import MainButtons from "../../../../../components/button/MainButtons";
import {DOWNLOAD_ADDRESS} from "../../../../../api/endPoint";
import DetailImageView from "../../../../../components/modal/DetailImageView";

const EventDetail = () => {
  const params = useParams()
  const {id} = params
  const authValid = useLocation().pathname.includes( '/admin' )
  const {t}: any = useTranslation()
  const [detailData , setDetailData] = React.useState<{
    infoNum: string,
    modifiedDate: string,
    registrationDate: string,
    title: string,
    content: any,
    image: string,
    imageUrl: string[]
    fileUrl?: any[]
    file?: any[]
    fileName?: any[]
  }>()

  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const detailInfo = async () => {
    try {
      const response = await client.post( '/infoNotice/detail' , {
        infoNum: id
      } , {
                                            headers: {Authorization}
                                          } )
      setDetailData( response.data.data )
    } catch ( e ) {
      console.log( e )
    }
  }
  React.useEffect( () => {
    detailInfo().then()
  } , [] )
  const urlRegex = /(https?:\/\/\S+)/g;
  const content = detailData?.content.replace( urlRegex , function ( url: string ) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  } );

//@ts-ignore
  const fileData = detailData?.file.map((file, i)=>{
    // @ts-ignore
    const fileUrl = detailData?.fileUrl[i]
    // @ts-ignore
    const fileName = detailData?.fileName[i]
    return {file, fileUrl, fileName}
  })
  return (
    <SectionFrame>
      <React.Fragment>
        {dataModal.menu === 'preview' &&
            <ImageDetailPreview profile={detailData?.imageUrl[dataModal.index]}
                                imageUrl={detailData?.imageUrl}
                                clickEvent={() => {setDataModal( initModal )}}/>}
        {authValid ?
          <MenuTitle title={'게시판 관리 > 이벤트&공지 관리 > 조회'} unitCase={'MenuTitle'}/> :
          <MenuTitle title={'이벤트&공지 조회'} unitCase={'MenuTitle'}/>}

        {/*@ts-ignore*/}
        {dataModal.menu === 'attach' && <DetailImageView image={dataModal.message}
                                                         closeMenu={()=>{setDataModal(initModal)}}
                                                         downloadEvent={()=>{
                                                           const parts = dataModal.message?.split('/');
                                                           // @ts-ignore
                                                           window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts.length - 1]}`
                                                         }}
                                                         imgDownLoadType={true}/>
        }

        <ContentFrame>
          <React.Fragment>
            <div style={{display: 'flex' , userSelect: 'none'}}>

              <ViewBox>
                <div style={{
                  display: 'flex' ,
                  alignItems: 'center' ,
                  justifyContent: 'space-between' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'이벤트&공지 조회'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <React.Fragment>
                      <MainButtons name={'목록'} width={'56px'} marginDis={true}
                                   clickEvent={() => {window.location.href = authValid ? `/admin/fas/info` : '/fas/wiki'}}/>
                      {authValid &&
                          <MainButtons name={'수정'} disabled={buttonAuthValid( 'update' , '20' )} width={'56px'}
                                       marginDis={true}
                                       clickEvent={() => {window.location.href = `/admin/fas/info/modify/${detailData?.infoNum}`}}/>
                      }
                    </React.Fragment>
                  </div>
                </div>
                <div style={{overflowY:'scroll'}}>
                  <div style={{display: 'flex'}}>
                    <div className={'detail_title'}>{t( '게시글 No.' )}</div>
                    <div className={'detail_content'}>{detailData?.infoNum}</div>
                    <div className={'detail_title'}>{t( '등록일' )}</div>
                    <div className={'detail_content'}>{detailData?.registrationDate}</div>
                  </div>

                  <div style={{display: "flex"}}>
                    <div className={'detail_title'}>{t( '제목' )}</div>
                    <div className={'detail_content'} style={{width: '100%'}}>{detailData?.title}</div>
                  </div>
                  <div style={{display:'grid', gridTemplateColumns:'168px 1fr'}}>
                    <div className={'detail_title'} style={{height:'100%', minHeight:'48px'}}>
                      {'첨부파일'}
                    </div>
                    <div className={'detail_content'} style={{display: 'flex',overflow:'hidden',padding:'8px 16px', width:'100%', height:'100%'}}>
                      <div>
                        {fileData?.length !== 0 ?
                          fileData?.map((v:any,index:number)=>(
                            <DownloadText onClick={()=>{
                              //@ts-ignore
                              const parts = v?.fileUrl?.split('/');
                              const expandName = parts[parts?.length-1]?.split('.').pop();
                              if(/(jpg|jpeg|png)$/i?.test(expandName)){
                                //@ts-ignore
                                setDataModal({menu:'attach',message:v?.fileUrl})
                              }else{
                                window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts?.length-1]}`
                              }
                            }}>
                              {`${index + 1}. ` +  v.fileName}
                            </DownloadText>
                          ))
                          :
                          <NoData height={32}>{'* 첨부된 파일이 없습니다.'}</NoData>
                        }
                      </div>
                    </div>
                  </div>

                  <div style={{display: 'flex' , borderBottom: '1px solid #E6E9F4'}}>
                    <div className={'detail_title'} style={{height: '336px'}}>{t( '내용' )}</div>
                    <div className={'detail_notice'} style={{userSelect: 'text' , lineHeight: '16px'}}>
                      <div dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                  </div>
                </div>

              </ViewBox>

              <div style={{width: '1px' , backgroundColor: '#e0e0e0' , marginRight: '28px'}}/>

              <div style={{width: '377px' , boxSizing: 'border-box'}}>
                <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                <div style={{...multipleImageGridStyle , marginTop: '20px'}}>
                  {detailData?.imageUrl.length !== 0 ?
                    detailData?.imageUrl.map( ( item: any , index: number ) => {
                                                return (
                                                  <PreviewImage imageUrl={item} noneDelete={true}
                                                                clickEvent={() => setDataModal( {menu: 'preview' , index: index} )}/>
                                                )
                                              }
                    ) :
                    <PreviewImage imageUrl={noImage} noneDelete={true}/>
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
        </ContentFrame>
      </React.Fragment>
    </SectionFrame>
  );
};
export default EventDetail;
const ViewBox = styled.div`
  width: 784px;
  height: 620px;
  padding-right: 28px;

  .detail {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      width: 168px;
      padding-left: 16px;
      box-sizing: border-box;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
      font-weight: 900;
    }

    &_content {
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      width: 224px;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;

    }


    &_notice {
      height: 336px;
      width: 100%;
      padding: 8px 16px;
      box-sizing: border-box;
      line-height: 30px;
      border-top: 1px solid #E6E9F4;
      font-size: 14px;
      white-space: pre-wrap;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        width: 21px;
        background: transparent;
      }
    }

    &_answer {
      color: #7E84A3;
      display: flex;
      justify-content: center;
    }
  }
`

const DownloadText = styled.div`
  line-height: 24px;
  &:hover{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`

// const PreviewImage = styled.div`
//   width: 100%;
//   height: 100%;
//   position: relative;
//   display: inline-block;
//   box-sizing: border-box;
//   border-radius: 4px;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//
//
//   .delete_button {
//     position: absolute;
//     box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.05);
//     border-radius: 50%;
//     width: 24px;
//     height: 24px;
//     text-align: center;
//     cursor: pointer;
//     background-color: #ffffff;
//     right: 4px;
//     top: 4px;
//   }
// `
