import React , {useState} from 'react';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AccountModelType , Modal_View_Type} from "../../../../../types/Fundamental";
import {
  authValid ,
  dataAccessLoading ,
  initModal , loadingRemove
} from "../../../../../function/function";
import {useRecoilState} from "recoil";
import {persistCompanyData , selectedCode , userPersistCompanyData} from "../../../../../common/StateManage";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import styled from "styled-components";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import AccountModelMultipleReg from "./AccountModelMultipleReg";

const AccountModelRegistration = () => {
  const location = useLocation();
  const authValid = location.pathname.includes( '/admin' );
  const {t}: any = useTranslation()
  const [multipleReg , setMultipleReg] = useState( false );
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [selectedCompany , setSelectedCompany] = useRecoilState<any>( authValid ? persistCompanyData : userPersistCompanyData )
  const [modelRegister , setModelRegister] = useState<AccountModelType | any>( {} )
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [customerModelList , setCustomerModelList] = React.useState<any[]>( [] )
  // const [selectCode, setSelectCode] = useRecoilState(selectedCode)
  const onChange = ( e: React.ChangeEvent<HTMLInputElement> ) => {
    const {name , value} = e.target
    setModelRegister( ( prev: any ) => ( {...prev , [name]: value} ) )
  }
  const accountModelRegisterRes = async () => {
    if( !modelRegister.customerName ){
      setDataModal( {menu: 'confirm' , message: '거래처명을 검색하여 입력해 주세요.'} )
    } else if( !modelRegister.customerModelName ){
      setDataModal( {menu: 'confirm' , message: '모델명을 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/customerModel/register` , [{
                                              ...modelRegister ,
                                              companyCode: selectedCompany.companyCode ,
                                              customerCode: accountCode.customerCode ,
                                              customerModelName: modelRegister.customerModelName ,
                                              businessNumber: accountCode.businessNumber ,
                                              customerName: accountCode.customerName
                                            }]
          , {headers: {'Authorization': Authorization}} )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: '거래처 모델 정보가 등록 되었습니다.'} );
            loadingRemove()
          } , 500 )
          setTimeout( () => {
            window.location.href = authValid ? '/admin/fas/model' : '/fas/model'
          } , 1000 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  return (
    <SectionFrame>
        <React.Fragment>
          {dataModal.menu === 'cancel' &&
              <SelectConfirmModal statusText={dataModal.message}
                                  noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                                  cancelEvent={() => {setDataModal( initModal )}}
                                  viewType={true}
                                  clickEvent={() => { window.location.href = authValid ? '/admin/fas/model' : '/fas/model'}}
              />
          }
          {dataModal.menu === 'success' ?
            <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => setDataModal( initModal )}/>
            : dataModal.menu === 'error' ?
              <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                        closeEvent={() => setDataModal( initModal )}/> : null}

          {dataModal.menu === 'confirm' &&
              <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

          {dataModal.menu === 'account' &&
              <AccountSearchModal selectCode={accountCode.customerCode} modalCase={'customer'}
                                  companyCode={selectedCompany.companyCode} authValid={authValid}
                                  cancelEvent={() => {
                                    setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                                    setAccountCode( {} )
                                  }}
                                  confirmEvent={() => {
                                    setModelRegister( ( prev: any ) => ( {
                                      ...prev ,
                                      customerName: accountCode.customerName ,
                                      businessNumber: accountCode.businessNumber ,
                                      customerCode: accountCode.customerCode
                                    } ) )
                                    if( customerModelList.length !== 0 ){
                                      Object.assign( customerModelList[dataModal.index] ,
                                                     {
                                                       customerName: accountCode.customerName ,
                                                       businessNumber: accountCode.businessNumber ,
                                                       customerCode: accountCode.customerCode
                                                     } )
                                    }
                                    setDataModal( ( prev ) => ( {...prev , menu: ''} ) );
                                  }} receiveData={( v ) => ( setAccountCode( v ) )}/>}
          <div style={{display: 'flex' , justifyContent: 'space-between'}}>
            <MenuTitle title={( authValid ? '업체/' : '' ) + '기본정보 관리 > 거래처 모델 관리 > 등록'+ (multipleReg?'(다중)':'')}
                       unitCase={'MenuTitle'}/>
            {
              multipleReg?
                <div style={{display:'flex'}}>
                  <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                               clickEvent={()=>{setDataModal({menu:'cancel',message:'목록으로 이동 하시겠습니까?'})}} />
                  <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                               clickEvent={()=>setMultipleReg(false)} />
                </div>
                :
                <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                             clickEvent={()=>setMultipleReg(true)} />
            }
          </div>
          <ContentFrame height={'680px'}>

            {multipleReg?
                //다중 등록
                <AccountModelMultipleReg/>
                :
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'거래처 모델 등록'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'}
                                   clickEvent={() => {
                                     setDataModal( {
                                                     menu: 'cancel' ,
                                                     message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                   } )
                                   }}/>
                      <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                   clickEvent={accountModelRegisterRes}/>
                    </div>
                  </div>
                </div>
                <RegisterComponent type={'search'} title={'거래처'} name={'customerName'}
                                   value={modelRegister.customerName}
                                   onChange={() => {}} required={true} clickEvent={() => {
                  setDataModal( {menu: 'account' , index: 0} )
                }}/>
                <RegisterComponent type={'text'} title={'사업자 번호'} name={'businessNumber'}
                                   value={modelRegister.businessNumber}
                                   onChange={onChange}/>
                <RegisterComponent type={'text'} title={'모델'} name={'customerModelName'}
                                   value={modelRegister.customerModelName} required={true} bottomBorder={true}
                                   onChange={onChange}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              <div className={'scroll_comp'} style={{width: '379px' , height: '600px'}}>
                {/*이미지는 없잖냐 */}
              </div>
            </div>}
          </ContentFrame>
        </React.Fragment>
    </SectionFrame>
  );
};
export default AccountModelRegistration;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  
  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`
