import React from 'react';
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import {PageContainer} from "../../../style/StyleComponent";
import CompanyPermissionManagement from "../../../containers/admin/fas/company/basic/auth/CompanyPermissionManagement";

const AdminFasCompanyBasicPermission = () => {
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<CompanyPermissionManagement/>}/>
    </PageContainer>
  );
};
export default AdminFasCompanyBasicPermission;