import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import ProcessList from "../../../containers/admin/fas/company/process/ProcessList";
import {useLocation} from "react-router-dom";
import ProcessRegister from "../../../containers/admin/fas/company/process/ProcessRegister";

const FasProcess = () => {
	const location = useLocation();
	const {pathname} = location
	return (
		<PageContainer>
			<IntegratedSideMenu/>
			<SectionContainer
				contentContainer={
					location.pathname.includes('/admin')?
						<ProcessList/>
						:
						location.pathname.length === 12?
							<ProcessList/>: <ProcessRegister/>
			}/>
		</PageContainer>
	);
};

export default FasProcess;
