import React from 'react';
import styled from 'styled-components';
import client from "../../common/Config";
import MainButtons from "../button/MainButtons";
import {initModal , loadingRemove , uploadingLoading} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";
import ConfirmPopup from "../modal/ConfirmPopup";

interface Props {
    imageKey?: string | null
    receiveData:(data:{imgKey:string|null, imgUrl: string|null}) => void
    giveFileName?:(data?:string) => void
    buttonWidth?: string
}

const SingleDrawingUploadBox = ({imageKey,giveFileName, buttonWidth, receiveData}:Props) => {

    const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal)
    const inputRef = React.useRef<any>(null)
    const {t}: any = useTranslation()

    const fileUpload = async (e:React.ChangeEvent<HTMLInputElement>,data: any) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append('file',data)

        try {
            uploadingLoading('업로드 중 입니다...')
            const response = await client.post('/file/upload',
                                               formData
            )
            const data = response.data.data
            receiveData({imgKey: data.imgKey, imgUrl: data.imgUrl})
            loadingRemove()
        }catch (e) {
            console.log(e)
            loadingRemove()
        }
    }
    const extendReg = /(.*?)\.(jpg|jpeg|png)$/

    return (
      <Container>
          {dataModal.menu === 'file' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>{setDataModal(initModal)}}/>}
          <label>
              <input type={'file'} style={{display:'none'}} ref={inputRef} accept={'image/*'}
                     onChange={
                         (e:any)=>{
                             if(inputRef.current.value.match(extendReg)){
                                 if(e.target.files[0].size < 10*1024*1024){
                                     fileUpload(e, e.target.files[0]).then();
                                     if (giveFileName) {
                                         giveFileName(e.target.files[0].name)
                                     }
                                 }else{
                                     setDataModal({menu:'file', message: t('10MB 이하의 파일을 업로드 해주세요.')})
                                     inputRef.current.value = ""
                                 }
                             }else{
                                 setDataModal({menu:'file', message: t('jpg, png 파일을 업로드 해주세요.')})
                             }
                         }}/>
              <MainButtons name={'업로드'} buttonType={'cta'} width={buttonWidth ?? '72px'} marginDis={true}/>
          </label>
      </Container>
    );
};

export default SingleDrawingUploadBox;

const Container = styled.div`
  display: flex;
`
