import React , {useState} from 'react';
import {useRecoilState} from "recoil";
import {persistPartnersData} from "../../../../../common/StateManage";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  buttonAuthValid , dataAccessLoading , filterCheckedRow ,
  initModal , loadingRemove , onChangeArray , onMultiRegChangeArray , rowListDropDirection , singleCheckedFunction
} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {GridTableComponent , GridTableSelect} from "../../../../../style/StyleComponent";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {passwordReg} from "../../../../../ValidationCheck";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import PartnerSelectModal from "../../../../../components/modal/PartnerSelectModal";

const PartnerUserMultipleReg = () => {
  const titleArray = ['성명','직책','전화번호','Email','권한','아이디','비밀번호', '비밀번호 확인']
  const needValue = ['성명','전화번호','아이디','비밀번호','비밀번호 확인','Email']

  const [selectedPartnerData, setSelectedPartnerData] = useRecoilState(persistPartnersData)

  const generateInitialRowList =
    Array.from( {length: 20} , ( _ , i ) => (
      {
        check: i+1 ,
        companyCode: selectedPartnerData.companyCode,
        userName: '',
        userPosition: '',
        phone_number: '',
        email: '',
        id:'',
        password: '',
        passwordCheck:'',
        authorityCode:'',
        authority:selectedPartnerData.authority === 'admin' ? 'adminUser' : 'affiliateUser',
        duplicateCheck: false
      }
    ) );
  const initialObject = {
    companyCode: selectedPartnerData.companyCode,
    userName: '',
    userPosition: '',
    phone_number: '',
    email: '',
    id:'',
    password: '',
    passwordCheck:'',
    authorityCode:'',
    authority:selectedPartnerData.authority === 'admin' ? 'adminUser' : 'affiliateUser',
    duplicateCheck: false
  }

  const [rowList , setRowList] = useState<any[]>( generateInitialRowList );
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [userList, setUserList] = useState<any[]>([])
  const [authList,setAuthList] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(-1)


  const idReg = /^(?=.*[a-zA-Z])(?=.*\d|.*[a-zA-Z])[a-zA-Z\d]{4,20}$/;
  const emailReg= /^[_a-z0-9-]+(.[_a-z0-9-]+)*@(?:\w+\.)+\w+$/;
  const phoneReg = /^\d{2,3}-?\d{3,4}-?\d{4}$/;

  const userSignUpRes = async () => {
    const checkedRows = filterCheckedRow(rowList, checkedList, 'check');
    for (let row of checkedRows) {
      if (!row.id) {
          setDataModal({ menu: 'warn', message: '아이디를 입력해 주세요.' });
          return;
        }
        if (!idReg.test(row.id)) {
          setDataModal({ menu: 'warn', message: '아이디 형식을 4~20자 \n 영문, 영문+숫자를 입력해 주세요.' });
          return;
        }
        if (!row.duplicateCheck) {
          setDataModal({ menu: 'warn', message: '아이디 중복확인을 해주세요.' });
          return;
        }
        if (!row.password) {
          setDataModal({ menu: 'warn', message: '비밀번호를 입력해 주세요.' });
          return;
        }
        if (!passwordReg.test(row.password)) {
          setDataModal({ menu: 'warn', message: '비밀번호 형식은 8자 이상 \n 영문,숫자가 혼용되어야 합니다.' });
          return;
        }
        if (!row.passwordCheck) {
          setDataModal({ menu: 'warn', message: '비밀번호를 한번 더 입력해 주세요.' });
          return;
        }
        if (row.password !== row.passwordCheck) {
          setDataModal({ menu: 'warn', message: '비밀번호가 일치하지 않습니다.' });
          return;
        }
        if (!row.email) {
          setDataModal({ menu: 'warn', message: '이메일을 입력해 주세요.' });
          return;
        }
        if (!emailReg.test(row.email)) {
          setDataModal({ menu: 'warn', message: '이메일 형식이 잘못 되었습니다.' });
          return;
        }
        if (!row.phone_number) {
          setDataModal({ menu: 'warn', message: '전화번호를 입력해 주세요.' });
          return;
        }
        if (!phoneReg.test(row.phone_number)) {
          setDataModal({ menu: 'warn', message: '전화번호 형식이 잘못 되었습니다.' });
          return;
        }
        if (!row.userName) {
          setDataModal({ menu: 'warn', message: '성함을 입력해 주세요.' });
          return;
        }
      }

      try {
        dataAccessLoading()
        const response = await client.post('/system/user/signup',
                                           filterCheckedRow(rowList, checkedList, 'check'),
                                           {headers:{Authorization}})
        const data = response.data
        if (data.status === 200) {
          setCheckedList([]);
          setRowList(generateInitialRowList);
          setDataModal({ menu: 'success', message: '계정이 등록 되었습니다.' });
          loadingRemove();

          setTimeout(() => {
            window.location.href = '/admin/fas/partners/user';
          }, 2000);  // 2초 후 페이지 이동
        }else{
          loadingRemove()
          setDataModal({menu:'confirm', message:data.message})
        }
      }catch (e:any) {
        setDataModal({menu:'error', message:e.message})
        console.log(e)
        loadingRemove()
      }
    }



  const idDuplicateCheck = async (id: string) => {
    try {
      const response = await client.post('/duplicate',{
        id: id
      },{headers: {Authorization}})
      const data = response.data

      if(data.status === 200){
        setDataModal({menu:'success', message:'사용 가능한 아이디 입니다.'})
        setRowList(rowList.map((el) => {return el.check === selectedRow ? {...el , duplicateCheck: true} : el}))
      }else{
        setDataModal({menu:'warn', message: '아이디 형식을 4~20자 \n 영문, 영문+숫자를 입력해 주세요.'})
      }

    }catch (e:any) {
      console.log(e)
      setDataModal({menu:'warn', message: e.message})
    }
  }

  const onChangeIDArray = (index: number, e: React.ChangeEvent<HTMLInputElement>,check:string) => {
    const {value, name} = e.target
    const rowsInput: any = [...rowList]
    rowsInput[index][name] = value.trim()
    rowsInput[index]['duplicateCheck'] = false
    setRowList(rowsInput)
    if(!checkedList.includes(check)){
      setCheckedList(checkedList.concat(check))
    }
  }

  const handleRowDelete = (rowList:any[], setRowList:Function, checkedList:number[], setCheckedList:Function, initialObject:any) => {
    const updatedRowList = rowList.filter(item => !checkedList.includes(item.check));
    // 삭제된 항목들을 다시 생성하여 추가
    checkedList.forEach(( deletedId: number | undefined) => {
      const deletedItemIndex = rowList.findIndex(item => item.check === deletedId);
      if (deletedItemIndex !== -1) {
        updatedRowList.push( {...rowList[deletedItemIndex], ...initialObject});
      }
    });
    // 상태 업데이트
    setRowList(updatedRowList);
    setCheckedList([]);
  }



  const authoritiesListRes = async () => {
    try {
      const response = await client.get(`/authorities/list/${selectedPartnerData.companyCode}`,{
        headers: {Authorization}
      })
      const data = response.data.data
      setAuthList(data.row)
    }catch (e) {
      console.log(e)
    }
  }

  React.useEffect(()=>{
    authoritiesListRes().then()
  },[selectedPartnerData])

  console.log(rowList)
  console.log(checkedList)
  return (
    <React.Fragment>

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={""}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={userSignUpRes}/>}

      {dataModal.menu === 'success' ? <ToastPopup text={dataModal.message} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={dataModal.message } state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message } state={'warn'} timeSet={1000} closeEvent={() => {setDataModal( initModal )}}/> : null}

      {/*{dataModal.menu === 'searchPartner' &&*/}
      {/*    <PartnerSelectModal receivedCompany={(data)=>{setSelectedPartnerData(data)}} closeButton={()=>{setDataModal(initModal)}}/>*/}
      {/*}*/}

      <div>
        <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <MenuTitle title={'유저 관리'} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                         disabled={checkedList.length === 0}
                         clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
            <MainButtons name={'저장'} width={'80px'} buttonType={'cta'} marginDis={true}
                         disabled={buttonAuthValid( 'create' , '92' ) || rowList.length === 0 || checkedList.length === 0}
                         clickEvent={() => {setDataModal( {menu: 'register' , message: '등록을 진행 하시겠습니까?'} )}}/>
          </div>
        </div>
        <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                            scrollWidth={1210}
                            scrollY_Hide={rowList?.length < 10}
                            height={572}
                            tempCol={'40px 96px 96px 120px 144px 108px 164px 148px 148px'}>

          <div className={'grid-title'}>
            <label className={'checkbox'}>
              <input type="checkbox"
                     onChange={(e)=>allPersistCheckedFunction(e.target.checked,{dataList: rowList, checkedList: checkedList, id:'check', setState: setCheckedList})}
                     checked={allCheckedState({dataList: rowList, checkedList: checkedList, id: 'check'})}/>
            </label>
            {titleArray.map((v)=>(
              <TableValue title={v} key={v} requiredValue={needValue} viewType={true}/>
            ))}
          </div>
          {rowList.map((v,i,arr)=>(
            <div className={'grid-row-list'} key={v.check} onMouseDown={()=>setSelectedRow(v.check)}>
              <label className={'checkbox'}>
                <ListInnerInput type={'check'} name={'check'}
                                onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.check , setCheckedList , checkedList )}
                                checked={checkedList.includes( v.check )}/>
              </label>
              <ListInnerInput type={'text'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}} placeholder={'이름'}
                              name={'userName'} inputData={v.userName}/>
              <ListInnerInput type={'text'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}} placeholder={'직책'}
                              name={'userPosition'} inputData={v.userPosition}/>
              <ListInnerInput type={'phone'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}}
                              name={'phone_number'} inputData={v.phone_number} placeholder={'번호 입력'}/>
              <ListInnerInput type={'text'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}}
                              name={'email'} inputData={v.email}/>
              <ListInnerInput type={'objectDrop'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}}
                              name={'authoritiesName'} inputData={v.authoritiesName} dropKey={'authoritiesName'}
                              selectDrop={(v)=>Object.assign(rowList[i], {authorityCode:v?.authoritiesCode, authorityName:v?.authoritiesName})}
                              dropTopDirection={rowListDropDirection(arr,i,userList)}
                              dropMenuList={authList}/>
              <ListInnerInput type={'withButton'} name={'id'} inputData={v.id}
                              buttonText={v.duplicateCheck? '확인됨' : '중복확인'}
                              buttonWidth={'60px'} checked={v.duplicateCheck} disabled={v.duplicateCheck}
                              clickButton={()=> {
                                idDuplicateCheck(v.id).then()
                              }}
                              onChange={(e)=>onChangeIDArray(i,e,v.check)} />

              <ListInnerInput type={'password'} inputType={'password'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}}
                              name={'password'} inputData={v.password} placeholder={'비밀번호를 입력해 주세요.'}/>

              <ListInnerInput type={'password'} onChange={( e ) => {onMultiRegChangeArray( i , e , rowList , setRowList, checkedList, setCheckedList, v.check )}}
                              name={'passwordCheck'} inputData={v.passwordCheck} placeholder={'비밀번호를 한번 더 입력해 주세요.'}/>
            </div>
          ))}

        </GridTableComponent>
      </div>
    </React.Fragment>
  );
};
export default PartnerUserMultipleReg;
