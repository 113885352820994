import React , {createRef} from 'react';
import styled from "styled-components";
import {ModalLabel} from "../../style/StyleComponent";
import {ConfirmProps} from "../../types/Fundamental";
import ModalBase from "./ModalBase";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MainButtons from "../button/MainButtons";
import checked from "../../asset/image/check-icon.svg";

const SelectConfirmModal:React.FunctionComponent<ConfirmProps> = ({statusText, buttonText,fontSize,transparent,specificText, loading,
																																		clickEvent,statusContent,viewType, cancelEvent,noticeText,buttonCustom, thirdText, thirdEvent, onCheck, checked}) => {

	const location = useLocation()
	const { t }:any = useTranslation();
	const inButtonText = buttonCustom ?? ['' , '']

	const drawerRef = createRef<any>();

	React.useEffect(() => {
		if (drawerRef && clickEvent) {
			drawerRef.current.focus();
		}
	}, [drawerRef, clickEvent]);

	const handleKeyDown = React.useCallback(
		(e:any) => {
			if (e.key === "Enter") {
				if( clickEvent ){
					clickEvent()
				}
			}
		},
		[clickEvent]
	);

	return (
			<ModalBase transparent={transparent} modalContent={
				<ModalFrame>
					<ModalLabel location={location.pathname.slice(1,6) === 'admin'}/>
					<div style={{display:'flex',flexDirection:'column', justifyContent:'space-between', height:'210px', padding:'60px 30px 24px', boxSizing:'border-box', userSelect:'none'}}>
							<div style={{textAlign:'center', color:'#171725', fontSize:`${fontSize ?? 20}px`, fontWeight:'700',whiteSpace:'pre-line', lineHeight:'25px'}}>
								{statusText ?
									<>
										<span style={{color:'#0058FF'}}>{t(specificText)}</span>{t(statusText)}
									</>
									:
									statusContent
								}

							</div>

						{t(noticeText) &&
						<div style={{display:'flex',justifyContent:'center',fontSize:'12px' ,color:'#F0142F',margin:'9px 0',whiteSpace:"pre-wrap"}}>
							{!t(noticeText) ? t('* 등록된 정보가 모두 삭제됩니다') : t(noticeText)}</div>}
						{onCheck &&
							<label className={'checkbox'}>
								<input type="checkbox"
											 onChange={( e ) => onCheck(e)}
											 checked={checked}
								/>
								초기값으로 저장
							</label>
						}
						<div style={{display: 'flex' , justifyContent: 'center'}}>
							{thirdText &&
								<MainButtons buttonType={''} width={!thirdText ? '144px' : '96px'}
														 name={thirdText}
														 clickEvent={thirdEvent} height={'34px'}/>}

							<MainButtons buttonType={'popupNormal'} width={!thirdText ? '144px' : '96px'}
													 name={inButtonText[0] === '' ? buttonText ? '취소' : '아니오' : inButtonText[0]}
													 clickEvent={cancelEvent} height={'34px'}/>
							<MainButtons buttonType={viewType ? 'popupNormal' : 'cta'}
													 width={!thirdText ? '144px' : '96px'}
													 name={inButtonText[1] === '' ? buttonText ? '삭제' : '예' : inButtonText[1]}
													 loading={loading} disabled={loading}
													 clickEvent={clickEvent} enterKeyEvent={handleKeyDown} height={'34px'} tabIndex={- 1}
													 focusButton={( e: any ) => e.target.focus()} handleRef={drawerRef}/>
						</div>
					</div>
				</ModalFrame>
			}/>
	);
};
export default SelectConfirmModal;
const ModalFrame = styled.div`
	position: absolute;
	width: 416px;
	height: 262px;
	border-radius: 6px;
	background-color: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 3px 6px #15223214;

	.checkbox {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
	}
	
	input[type='checkbox'] {
		-webkit-appearance: none;
		width: 16px;
		height: 16px;
		border: 1px solid #D7DBEC;
		outline: none;
		box-sizing: border-box;
		background-color: #fff;
		cursor: pointer;

		&:checked {
			border: transparent;
			background-color: #0058Ff;
			background-image: url(${checked});
			background-repeat: no-repeat;
			background-position: center;
		}
`