import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import MyPage from "../../../containers/admin/fas/my/MyPage";
import {useLocation} from "react-router-dom";
import MyPageModify from "../../../containers/admin/fas/my/MyPageModify";

const FasMyPage = () => {
  const location = useLocation()
  const authValid = location.pathname.includes('admin')

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {location.pathname.includes('modify') ?
        <SectionContainer contentContainer={<MyPageModify/>}/>
        :
        <SectionContainer contentContainer={<MyPage/>}/>
      }
    </PageContainer>
  );
};
export default FasMyPage;