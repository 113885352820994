import React, {useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import SearchBar from "../../../../../components/input/SearchBar";
import {useRecoilState} from "recoil";
import {
	persistPage,
	persistBlockPage,
	selectedCode,
	searchInputState,
	companySolutionFilter
} from "../../../../../common/StateManage";
import {
	autoTextValueValidation,
	ContentFrame,
	NoData,
	SectionFrame,
	tooltipModule,
	validationValue,
} from "../../../../../style/StyleComponent";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {buttonAuthValid, dataValid, initError, initModal} from "../../../../../function/function";
import {Error_Type, Modal_View_Type} from "../../../../../types/Fundamental";
import {ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import SelectEventButton from "../../../../../components/button/SelectEventButton";
import styled from "styled-components";
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const BasicManagement = () => {

	const [page, setPage] = useRecoilState<number>(persistPage)
	const [pageBlock, setPageBlock] = useRecoilState<number>(persistBlockPage)
	const [totalPage, setTotalPage] = useState<number>(0)

	const [companyDataList, setCompanyDataList] = useState<any>([])
	const [selectedCompany, setSelectedCompany] = useRecoilState<string>(selectedCode)
	const [searchInput, setSearchInput] = useRecoilState<string>(searchInputState);
	const [prevSearch, setPrevSearch] = useState<string>(searchInput)
	const [errorState, setErrorState] = useState<Error_Type>(initError)
	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
	const [selectSolution, setSelectSolution] = useRecoilState<string>(companySolutionFilter)
	const {t}: any = useTranslation();
	const companyList = ['업체명', '대표', '주소', '전화번호', '담당자', '담당자 전화번호', '담당자 이메일', '관리자 ID', '업체코드', '업체로고'];
	// const [isScrolling, setIsScrolling] = useState<boolean>(true);
	const scrollRef = React.useRef<any>(null);

	// const handleScroll = (e:any) => {
	//     //확인된 최대값 숫자 넣기
	//     if(scrollRef?.current?.scrollLeft === 176){
	//         setIsScrolling(false)
	//     }else{
	//         setIsScrolling(true)
	//     }
	// };

	// const companyListRes = ()=> ListCustomHooks(`/company/list/${page}/10/${selectSolution === 'FAS' ? '@' : selectSolution}`,page,setTotalPage,setPageBlock,setCompanyDataList,setErrorState,setSearchInput,setPrevSearch)
	// const companyListRes = ()=> ListCustomHooks(`/company/all/list/${page}/10/${selectSolution === 'FAS' ? '@' : selectSolution}/@`,page,setTotalPage,setPageBlock,setCompanyDataList,setErrorState,setSearchInput,setPrevSearch)
	const companyListRes = () => ListSearchPostCustomHooks(`/company/all/list`, page, setTotalPage, setPageBlock, setCompanyDataList, setErrorState, {
		page: page,
		size: 10,
		type: selectSolution === 'FAS' ? '@' : selectSolution,
		keyword: '@'
	})
	// const companySearchRes = () => ListSearchCustomHooks(`/company/search/${page}/10/${searchInput}/${selectSolution === 'FAS' ? '@' : selectSolution}`,page,setTotalPage,setPageBlock,setCompanyDataList,setErrorState)
	// const companySearchRes = () => ListSearchCustomHooks(`/company/all/list/${page}/10/${selectSolution === 'FAS' ? '@' : selectSolution}/${searchInput}`,page,setTotalPage,setPageBlock,setCompanyDataList,setErrorState)
	const companySearchRes = () => ListSearchPostCustomHooks(`/company/all/list`, page, setTotalPage, setPageBlock, setCompanyDataList, setErrorState, {
		page: page,
		size: 10,
		type: selectSolution === 'FAS' ? '@' : selectSolution,
		keyword: searchInput
	})
	const searchEventFunc = () => {
		setPrevSearch(searchInput)
		setPage(1)
		setPageBlock(0)
	}
	React.useEffect(() => {
			if (searchInput === '') {
				companyListRes().then()
			} else {
				companySearchRes().then()
			}
		}
		, [page, prevSearch, selectSolution])

	return (
		<React.Fragment>
			{dataModal.menu === 'logoImage' &&
					<DetailImageView image={companyDataList[dataModal.index].logoImageUrl}
					                 closeMenu={() => setDataModal(initModal)}/>}
			<SectionFrame>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<MenuTitle title={'업체/기본정보 관리 > 업체 정보'} unitCase={'MenuTitle'}/>
					<div style={{display: 'flex', alignItems: 'center'}}>
						{['전체', 'P-BAS', 'R-BAS', 'C-BAS', 'A-BAS', 'F-EMS'].map((v) => (
							<SelectEventButton buttonName={v} key={v}
							                   buttonEvent={() => {
								                   setSelectSolution((v === '전체') ? '@' : v)
								                   setPage(1)
								                   setPageBlock(0)
							                   }}
							                   buttonStatus={(selectSolution === '@' || selectSolution === 'FAS') ? v === '전체' : selectSolution === v}
							                   buttonBorder={true}/>
						))}
					</div>
				</div>

				<ContentFrame padding={'30px 30px 20px 30px'} justify={'space-between'}>
					<div style={{overflow: 'hidden'}}>
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
							<MenuTitle title={'업체 정보'} unitCase={'MenuCompTitle'}/>
							<div style={{display: 'flex', alignItems: 'center'}}>
								<SearchBar keyFunction={(e) => setSearchInput(e.target.value.trimStart())} keyPressDisable={true}
								           value={searchInput} clickEvent={searchEventFunc}
								           clearButton={() => {
									           setPage(1);
									           setSearchInput('');
									           setPrevSearch('')
								           }} clearButtonAccess={searchInput !== ''}
								           placeholder={t('검색')}/>

								<MainButtons buttonType={'cta'} name={'등록'} marginDis={true} disabled={buttonAuthValid('create', '2')}
								             clickEvent={() => window.location.href = `/admin/fas/basic/register`} width={'80px'}/>
								{/*<ControlButton name={'등록'} margin={'0 0 0 16px'} padding={'9px 26px'} clickEvent={() => setShowContainer(true)}/>*/}
								{/*<ControlButton name={'전체 보기'} margin={'0 10px 0 0'} padding={'9px 26px'} clickEvent={() =>companyList()}/>*/}
							</div>
						</div>

						{/*<ShowMore showMore={isScrolling} width={70} height={528} right={30} top={78} horizonType={true}/>*/}

						<GridTableComponent tempCol={'148px 108px 252px 140px 108px 140px 160px 108px 128px 96px'}
							// onScroll={handleScroll}
							                  ref={scrollRef} scrollY_Hide={true}
							                  scrollWidth={1388} height={538}>

							<div className={'grid-title-unchecked'}>
								{companyList?.map((data) => (
									<div key={data}>{t(data)}</div>))}
							</div>
							{companyDataList?.length === 0 ?
								<NoData>{errorState.notice}</NoData>
								:
								companyDataList?.map((v: any, i: number) =>
									<GridTableSelect className={'grid-list-unchecked-no_icon'}
									                 selected={v.companyCode === selectedCompany}
									                 onDoubleClick={() => window.location.href = `/admin/fas/basic/${v.companyCode}`}
									                 onClick={() => {
										                 setSelectedCompany(v.companyCode)
									                 }}>
										{autoTextValueValidation({value: v.companyName})}
										{autoTextValueValidation({value: v.president})}
										{autoTextValueValidation({value: v.address})}
										{validationValue(v.phoneNumber)}
										{autoTextValueValidation({value: v.managerName})}
										{validationValue(v.managerNumber)}
										{autoTextValueValidation({value: v.email})}
										{tooltipModule(dataValid(v.id), 12)}
										{tooltipModule(dataValid(v.companyCode), 9)}
										{!v.logoImageUrl ?
											<div style={{cursor: 'default'}}>{'-'}</div>
											:
											<ImageView onClick={(e) => {
												e.stopPropagation();
												setSelectedCompany(v.companyCode)
												setDataModal({menu: 'logoImage', index: i})
											}}
											>{t('미리보기')}</ImageView>
										}
									</GridTableSelect>
								)
							}
						</GridTableComponent>
					</div>

					<div style={{display: 'flex', justifyContent: 'space-between'}}>
						<PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
						                blockNum={pageBlock} setBlockNum={setPageBlock}
						                counts={totalPage}/>

						<HorizontalMoveScrollButton
							leftClick={() => scrollRef.current.scrollLeft = (scrollRef.current.scrollLeft - 120)}
							rightClick={() => scrollRef.current.scrollLeft = (scrollRef.current.scrollLeft + 120)}/>
					</div>

				</ContentFrame>
			</SectionFrame>
		</React.Fragment>
	)
};

export default BasicManagement;

const ImageView = styled.div`
  text-decoration: underline;
  text-underline-offset: 3px;

  &:hover {
    text-decoration: none;
    text-underline-offset: 0;
  }
`

