import React from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {modalTitleBase} from "../../style/StyleComponent";
import {ConfirmProps} from "../../types/Fundamental";
import MainButtons from "../button/MainButtons";
import {dataValid} from "../../function/function";

const SelectCaseModal:React.FunctionComponent<ConfirmProps> = ({ statusText,
                                                                 noticeText,
                                                                 fontSize,
                                                                 buttonWidth,
                                                                 buttonFontSize,
                                                                 buttonTitle, clickEvent, cancelEvent}) => {
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle:''})}
          <div className={'modal_content'}>
              <div style={{textAlign:'center', color:'#171725', fontSize:`${fontSize ?? 20}px`, fontWeight:'700',whiteSpace:'pre-line', lineHeight:'25px'}}>
                {dataValid(statusText)}
              </div>
            <div>
              <div style={{display:'flex',justifyContent:'center',fontSize:'12px', marginBottom:'24px',
                color:'#F0142F',whiteSpace:'pre-wrap'}}>{dataValid(noticeText,'')}</div>
              <div style={{display:'flex', justifyContent:'center'}}>
                <MainButtons name={dataValid(buttonTitle?.confirm,'확인')} buttonType={'popupCta'} clickEvent={clickEvent}
                             margin={'0 12px'} fontSize={dataValid(buttonFontSize,'14px')}
                             width={dataValid(buttonWidth,'96px')}/>
                <MainButtons name={dataValid(buttonTitle?.cancel,'취소')}clickEvent={cancelEvent}
                             margin={'0 12px'} buttonType={'popupNormal'} fontSize={dataValid(buttonFontSize,'14px')}
                             width={dataValid(buttonWidth,'96px')}/>
              </div>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default SelectCaseModal;

const BaseComponent = styled.div`
  width: 416px;
  height: 262px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;
  transition: height 0.3s;
  background-color: #fff;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }
  
  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 48px 24px 16px 24px;
    justify-content: space-between;
  }
  
`