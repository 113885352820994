import React , {useEffect , useState} from 'react';
import {
  ContentFrame ,
  NoData ,
  tooltipModule ,
  validationValue
} from "../../style/StyleComponent";
import MenuTitle from "../title/MenuTitle";
import SearchBar from "../input/SearchBar";
import {CompanyDataType , Error_Type} from "../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {
  persistCompanyData , processSelectCompany , userPersistCompanyData ,
} from "../../common/StateManage";
import {authValid , dataValid , initError , listLoadingCircle , loadingRemove} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import InfiniteScroll from "react-infinite-scroll-component";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface ListProps {
  stateChange?: boolean
  companyDataReceive: (data:any)=>void
  needCode?:boolean
  viewType?:boolean
  onDoubleClick?:()=>void
}

const CompanySearchList = ({companyDataReceive,viewType,onDoubleClick}:ListProps) => {
  const [companyData,setCompanyData] = React.useState<CompanyDataType[]>([])
  const [page, setPage] = useState<number>(1);
  const [totalPage,setTotalPage] = useState<number>(0);
  const [searchValue,setSearchValue] = React.useState<string>('');
  const [pushValue, setPushValue] = React.useState<string>('');
  const [errorState,setErrorState] = React.useState<Error_Type>(initError)
  const processSelectedCompany = useRecoilValue<any>(processSelectCompany)

  const companyInfo = useRecoilValue(authValid() ? persistCompanyData : userPersistCompanyData)
  const [selectData, setSelectData] = React.useState<any>({
                                                            companyCode:
                                                              !processSelectedCompany.companyCode?
                                                            companyInfo.companyCode : processSelectedCompany.companyCode
  })

  const tableRef = React.useRef<HTMLDivElement>(null)
  const companyListCompRes = 	async () => {
    try{
      listLoadingCircle()
      const response = await client.get(`/company/list/${page}/${10}/@`,
                                        {headers: {Authorization}})
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setCompanyData([])

      }else{
        setTotalPage(data.total_page);
        setErrorState({status:valid.status,notice:('* 검색결과가 없습니다.')})
        setPushValue('')
        setSearchValue('')
        if(data.total_page > 1 && page > 1){
          setCompanyData((prev:any)=> prev.concat(data.row.filter((el:any)=>(el.companyCode !== companyInfo.companyCode))))
        }else{
          setCompanyData(data.row.filter((el:any)=>(el.companyCode !== companyInfo.companyCode)))
        }
      }
      loadingRemove()
    }
    catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const companyListSearch = async () => {
    try {
      listLoadingCircle()
      const response = await client.post(`/company/search`,{
        page:page,
        size:10,
        keyword:searchValue,
        type:'@'
      },{
        headers: {Authorization}})
      const data = response.data.data
      setCompanyData(data.row.filter((el:any)=>(el.companyCode !== companyInfo.companyCode)))
      setTotalPage(data.total_page)
      if(data.total_page > 1 && page > 1){
        setCompanyData((prev:any)=> prev.concat(data.row.filter((el:any)=>(el.companyCode !== companyInfo.companyCode))))
      }else{
        setCompanyData(data.row)
      }
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }

  useEffect(()=>{
    if(pushValue === ''){
      companyListCompRes().then()
    }else{
      companyListSearch().then()
    }
  },[pushValue, page])

  return (
    <ContentFrame width={'410px'} height={'680px'} padding={'32px 16px 16px 24px'}>
      <div style={{display:'flex', justifyContent:'space-between',alignItems:'center', whiteSpace:"nowrap"}}>
        <MenuTitle title={'업체 리스트'} unitCase={'MenuCompTitle'}/>
        <SearchBar placeholder={'검색'}
                   keyFunction={(e)=>{setSearchValue(e.target.value.trimStart())}}
                   keyPressDisable={true}
                   clearButtonAccess={searchValue !== ''} clearButton={companyListCompRes} disabled={errorState.status !== 200}
                   clickEvent={()=>{setPushValue(searchValue); setPage(1)}}
                   value={searchValue}/>
      </div>
      <InfiniteScroll scrollableTarget={'grid-scroll'}
                      next={()=>setPage((prev)=> prev+1)}
                      hasMore={totalPage > page} dataLength={dataValid(companyData?.length, 0)}
                      loader={<div/>}>
        {/*<GridTableComponent id={'grid-scroll'} tempCol={'140px 1fr 40px'}  scrollWidth={550} height={532} style={{marginTop:16}}>*/}
          <GridTableComponent id={'grid-scroll'}
                              scrollWidth={550} height={532}
                              tempCol={'168px 1fr'}>

          <div className={'grid-title-unchecked'} style={{zIndex:49}}>
            {['업체명','주소'].map((v:string, i:number)=>(
              <div style={{justifyContent: i === 1? 'flex-start' : '', paddingLeft: i === 1 ? '96px' : ''}}>{v}</div>
            ))}
          </div>
           {viewType ?
             companyData.length === 0 && pushValue !== '' ? errorState.status !== 200 ?
                 <NoData>{errorState.notice}</NoData>
                 :
                 <NoData>{' * 검색 결과가 없습니다.'}</NoData>
               :

               <React.Fragment>
                 {companyInfo.companyCode &&
                   <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                    selected={selectData.companyCode === companyInfo.companyCode}
                                   onDoubleClick={onDoubleClick}
                                   style={{zIndex: 0}}
                                    onClick={() => {
                                      companyDataReceive( companyInfo );
                                      setSelectData(companyInfo)
                                    }}
                                   ref={tableRef}>
                   {validationValue( companyInfo.companyName )}
                   {tooltipModule( dataValid( companyInfo.address , '-' ) , 10 )}
                 </GridTableSelect>}

                 {companyData.map( ( value: any ) =>
                                     <GridTableSelect className={'grid-list-unchecked-no_icon'} key={value.companyCode}
                                                      selected={value.companyCode === selectData.companyCode}
                                                      onDoubleClick={onDoubleClick}
                                                      style={{zIndex: 0}}
                                                      ref={tableRef}
                                                      onClick={() => {
                                                        companyDataReceive( value );
                                                        setSelectData( value );
                                                      }}>
                                        {validationValue( value.companyName )}
                                        {tooltipModule( dataValid( value.address , '-' ) , 10 )}
                                     </GridTableSelect> )}
               </React.Fragment>
             :

             companyData.length === 0 && pushValue !== '' ? errorState.status !== 200 ?
                 <NoData>{errorState.notice}</NoData>
                 :
                 <NoData>{' * 검색 결과가 없습니다.'}</NoData>
               :

               <React.Fragment>

                 {(companyInfo.companyCode && pushValue === '') &&
                     <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                      selected={selectData.companyCode === companyInfo.companyCode}
                                      onDoubleClick={onDoubleClick}
                                      style={{zIndex: 0}}
                                      onClick={() => {
                                        companyDataReceive( companyInfo );
                                        setSelectData( companyInfo );
                                      }}
                                      ref={tableRef}>
                       {validationValue( companyInfo.companyName )}
                       {tooltipModule( dataValid( companyInfo.address , '-' ) , 10 )}
                     </GridTableSelect>}
                 {companyData.map( ( value: any ) =>
                                     <GridTableSelect className={'grid-list-unchecked-no_icon'} key={value.companyCode}
                                                      selected={value.companyCode === selectData.companyCode}
                                                      onDoubleClick={onDoubleClick}
                                                      style={{zIndex: 0}}
                                                      onClick={() => {
                                                        companyDataReceive( value );
                                                        setSelectData( value )
                                                      }}>
                                       {validationValue( value.companyName )}
                                       {tooltipModule( dataValid( value.address , '-' ) , 10 )}
                                     </GridTableSelect> )}

               </React.Fragment>
           }
        </GridTableComponent>
      </InfiniteScroll>
    </ContentFrame>
  );
};
export default CompanySearchList;











































