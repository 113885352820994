import React from 'react';
import styled from "styled-components";
import MainButtons from "../button/MainButtons";
import {dataValid} from "../../function/function";
import {useTranslation} from "react-i18next";
import {validationValue} from "../../style/StyleComponent";

interface Props {
	title?: string
	value?: string | any
	type?: string
	contentText?: string
	buttonName?: string
	unit?: string
	bottomBorder?: boolean
	clickEvent?:()=>void
	height?:string
	disabled?: boolean
	width?: string
	titleColor?: string
	color?: string
	titleWidth?: string
	arrayData?:any[]
	lineHeight?:number
}

const ListInfoComponent:React.FunctionComponent<Props> = ({title, titleWidth, height, width,unit,value,type, disabled, color,
																														lineHeight,contentText,arrayData, bottomBorder,buttonName, clickEvent,titleColor}) => {
 const {t}:any = useTranslation();

	const detailCase = (type?:string) => {
		switch (type){
			default : return (<div style={{width: width,  lineHeight:'14px', display:'flex', justifyContent:'flex-start', alignItems:'center',
				textOverflow:'ellipsis', whiteSpace:'pre-wrap', overflow:'hidden', color: color ?? ''}}>{value}</div>)

			case 'textClick' : return (
				<div className={'textClick'} style={{fontSize:'12px', opacity: disabled? 0.7 : 1,
					textDecoration: disabled? 'none' : 'underline', pointerEvents: disabled? 'none' : 'auto'}}
						 onClick={clickEvent}>
					{t(contentText)}
				</div>)
			case 'textarea' : return (
				<div style={{height:height??'112px',padding: '8px 0 8px 24px'}}>
					<div style={{whiteSpace:'break-spaces',lineHeight:'16px', height:'100%',overflowY:'scroll'}}>
					{value}
					</div>
				</div>)
			case 'unit':
				return(
					<div style={{display:'flex',justifyContent:'space-between'}}>
						<div>{value} </div>
						<div style={{fontSize:'14px', fontWeight:400, opacity:0.5}}>{unit}</div>
					</div>
				)
			case 'button':
				return (
					<div style={{alignSelf:'center'}}>
						<MainButtons name={dataValid(buttonName)} margin={'0'} disabled={disabled}
												 clickEvent={clickEvent} width={'112px'} fontSize={'12px'} />
					</div>
				)
			case 'addition':
				return(
					<div style={{display:'flex',flexDirection:'column',height:height,alignItems:'start'}}>
						{arrayData?.map((v:any,index:number)=>(
							<div>
								{(index + 1)+ '. ' + v}
							</div> ))
						}
					</div>
				)
		}
	}

	return (
		<BaseComponent bottomBorder={bottomBorder} titleColor={titleColor} lineHeight={lineHeight}>
			<div style={{minWidth: titleWidth ?? '', width: titleWidth ?? ''}}>{t(title)}</div>
			{detailCase(type ?? '')}
		</BaseComponent>
	);
};

export default ListInfoComponent;

const BaseComponent = styled.div<{ disabled? : boolean , bottomBorder?: boolean, view?: boolean,titleColor?:string,lineHeight?:number}>`
  display: flex;
  border-top: 1px solid #E6E9F4;
	border-bottom : ${props => props.bottomBorder? 1 : 0}px solid #E6E9F4;
  //user-select: none;
  pointer-events: ${ props => props.disabled? 'none' : 'auto'};
  color: rgba(19, 21, 35, ${props => props.disabled? 0.3 : 1});
  & > div {
    box-sizing: border-box;
    padding: 0 16px;
    align-items: center;
    display: flex;
	  line-height: ${props => props.lineHeight ?? 48}px;
  }

  & > div:first-child {
    width: 168px;
    min-width: 168px;
    background-color: ${props => props.titleColor?? '#F7F8FC'};
    font-size: 12px;
    font-weight: 800;
  }

  & > div:last-child {
	  display: inline-block;
	  font-size: 14px;
    width: 100%;
    max-width: 616px;
    padding: 0 16px;
    //max-height: 48px;
    position: relative;
	  white-space: nowrap;
	  text-overflow: ellipsis;
	  overflow: hidden;
	  box-sizing: border-box;

	  &>div{
	    &::-webkit-scrollbar {
	      width: 15px;
	      display: block;
	    }
	
	    &::-webkit-scrollbar-thumb {
	      background-color: #E6E9F4;
	      border-radius: 10px;
	      background-clip: padding-box;
	      border: 6px solid transparent;
	    }
	  }
  }
	
	
  .textClick{
    color: #5A607F;
		cursor: pointer;
		width: auto;
		text-underline-offset: 4px;
    &:hover{
			font-weight: bold;
      text-decoration: underline;
      color: #5A607F
    }
  }
`
