export const SERVER_ADDRESS = 'https://api.z-fas.com:5500'

export const BASED_ADDRESS = 'https://api.z-fas.com:5500/api/v1'

export const TEST_ADDRESS = 'https://test.z-fas.com:5500/api/v1'

export const JS_ADDRESS = 'http://121.179.72.13:5500/api/v1'
// export const JS_ADDRESS = 'http://218.53.231.186:5500/api/v1'
// export const JS_ADDRESS = 'http://220.90.38.232:5500/api/v1'
// export const JS_ADDRESS = 'http://192.168.0.108:5500/api/v1'
// export const JS_ADDRESS = 'http://1.249.38.132:5500/api/v1'

export const CM_ADDRESS = 'http://121.179.72.70:1105/api/v1'

export const FILE_ADDRESS = 'https://file.z-fas.com:8222/api/v1/file/'

export const DOWNLOAD_ADDRESS = 'https://file.z-fas.com:8222/api/v1/file/download/'
