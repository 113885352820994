import React,{useState} from 'react';
import styled from "styled-components";
import ProfileSample from '../../asset/image/man.png'
import search from '../../asset/image/ic-search.svg'
import dayjs from "dayjs";
import 'dayjs/locale/ko'
import SelectConfirmModal from "../modal/SelectConfirmModal";
import LanguageSelector from "../modal/LanguageSelector";
import ArrowButton from "../button/ArrowButton";
import i18n from "../../locale/i18n";
import {useRecoilState , useRecoilValue} from "recoil";
import {LanguageAtom , persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import {useTranslation} from "react-i18next";
import {authValid , dataValid , initModal} from "../../function/function";
import {Modal_View_Type} from "../../types/Fundamental";
import {useMediaQuery} from "react-responsive";
import client from '../../common/Config'
import {Authorization} from '../../common/authorization'
import PasswordCheckModal from "../modal/PasswordCheckModal";
import {useLocation} from "react-router-dom";
import CompanySelectModal from "../modal/CompanySelectModal";
import SelectedCompanyInfoModal from "../modal/common/SelectedCompanyInfoModal";

export const selectCompanyFunction = ( func: (data?:any)=>void) => {
	func()
}
const Header:React.FunctionComponent<{responsiveMobile?: boolean, disabled?:boolean }> = ({responsiveMobile,disabled}) => {

	const selectLanguage = useRecoilValue(LanguageAtom)
	dayjs.locale(selectLanguage.lang)
	const location = useLocation()
	const popupRef = React.useRef<HTMLDivElement>(null);
	const { t }:any = useTranslation();
	const [realTime, setRealTime] = useState<string>(dayjs().format("YYYY.MM.DD dddd HH:mm:ss"))
	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
	const [placeOpen ,setPlaceOpen] = React.useState<boolean>(false)
  const [selectedCompany,setSelectedCompany] = useRecoilState(authValid() ? persistCompanyData : userPersistCompanyData)


	const onChangeLang = () => {
		setDataModal(initModal)
		window.localStorage.setItem('lang',selectLanguage.lang)
		selectLanguage.lang === "ko" ? i18n.changeLanguage('ko') : i18n.changeLanguage('en');
		setPlaceOpen(false)
	};

	React.useMemo(()=>{
		const intervalTime = setInterval(()=> timeTick(),1000)
		return ()=> clearInterval(intervalTime)
	},[realTime])

	const timeTick = () => {
		setRealTime(dayjs().format("YYYY.MM.DD dddd HH:mm:ss"))
	}

	const logoutClick = async () => {
		if(window.localStorage.getItem('auth') === 'company' || window.localStorage.getItem('auth') === 'system'){
			try {
				const response = await client.post('/logOut',{
					id: window.localStorage.getItem('companyID'),
					password:'@'
				},{headers:{Authorization}})
				console.log(response.data)
			}catch (e) {
				console.log(e)
			}
			finally {
				// window.location.href='/signin'
				window.localStorage.clear()
				setTimeout(()=>{
					window.location.replace('https://test.z-fas.com')
					// window.location.replace('http://localhost:3000')
				},500)
			}
		}else{
			// window.location.href='/signin'
			window.localStorage.clear()
			setTimeout(()=>{
				window.location.replace('https://test.z-fas.com')
				// window.location.replace('http://localhost:3000')
			},500)
		}
	}
	React.useEffect(() => {
		const handleClickOutside = (e: any) => {
			// @ts-ignore
			if(placeOpen && !popupRef.current.contains(e.target)){
				setPlaceOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [placeOpen]);

	// const isDesktop = useMediaQuery({
	//                                                    query : "(min-width:1025px)"
	//                                                 })
	const isMobile = useMediaQuery({
																	 query : "(max-width:1023px)"
																 });

	return (
		<React.Fragment>

			{dataModal.menu  === 'companyInfo' && <SelectedCompanyInfoModal closeEvent={()=>setDataModal(initModal)}/>}

			{dataModal.menu === 'company' &&
				<CompanySelectModal receivedCompany={(data)=>{selectCompanyFunction(()=>setSelectedCompany(data))}}
														closeButton={()=>setDataModal(initModal)}/>}

			{dataModal.menu === 'logout' &&
				<SelectConfirmModal clickEvent={logoutClick} viewType={true}
														noticeText={'* 로그아웃시 활성화 된 모든 창에서 로그아웃됩니다.'}
														statusText={t('로그아웃 하시겠습니까?')}
														cancelEvent={()=>setDataModal(initModal)}/>}
			{dataModal.menu === 'lang' &&
				<LanguageSelector closeModal={onChangeLang}/>}

			{dataModal.menu === 'check' &&
				<PasswordCheckModal detailInfo={{id:window.localStorage.getItem('companyID')}}
														cancelEvent={()=>{setDataModal(initModal)}} viewType={true}
														movePage={authValid() ? `/admin/myPage/${window.localStorage.getItem('companyID')}` : `/myPage/${window.localStorage.getItem('companyID')}`}
														noticeMessage={'* 마이페이지 조회를 위해 비밀번호를 확인해주세요.'}/>
			}
			{responsiveMobile ? isMobile &&
				<React.Fragment>
					<Container style={{width:'100vw', justifyContent:'space-between', boxSizing:'border-box', padding:'0 24px', minHeight:'56px',
						backgroundColor:`${authValid() ? '#CFFA61' : '#0039a4'}`}}>

							<div style={{color:authValid() ? 'rgb(0, 57, 164)' : '#FFF', fontSize:'20px', fontWeight:800}}>{authValid() ? 'ADMIN MODE' : selectedCompany.companyName}</div>
						<img src={`${window.localStorage.getItem('profile')}` ?? `${ProfileSample}`} alt="" width={36} height={36}
								 style={{borderRadius:'50%', backgroundColor:'#e0e0e0',display:'block',objectFit:'cover'}}
								 onClick={()=>setPlaceOpen(!placeOpen)}
								 onError={(e:React.ChangeEvent<HTMLImageElement>)=>e.target.src = ProfileSample}/>
					</Container>
					<EventMenu open={placeOpen} style={{zIndex:1001, right:'8px', top:'60px'}}>
						{/*<div onClick={()=> window.location.href=''}>{'마이 페이지'} </div>*/}
						<div style={{display:'flex'}} onClick={()=>setDataModal({menu:'lang'})}>
							<div>{selectLanguage.lang === 'ko'? '언어':'Language'}</div>
							<div style={{color:'rgba(0,0,0,0.5)',marginLeft:'8px'}}>{`(${selectLanguage.text})`}</div>
						</div>
						<div onClick={()=>setDataModal({menu:'logout'})}>{
							selectLanguage.lang === 'ko'? '로그 아웃' : 'Log Out'
						}</div>
					</EventMenu>
				</React.Fragment>

				:

				<Container style={{backgroundColor:`${authValid()? '#CFFA61' : '#0039a4'}`,zIndex:2,
					justifyContent:'flex-end', minHeight:'68px'}}>
					<div style={{display:'flex',alignItems:'center',justifyContent:'space-between', margin:'0 auto', minWidth:'1270px'}}>
						<div style={{display:'flex'}}>
							{
								authValid() &&
								<React.Fragment>
									<SearchButton disabled={disabled || !!location.pathname.match(/\/basic(\/(?!user|auth).*)?$/)}
									onClick={() => setDataModal( {menu: 'company'} )}>
											{dataValid( selectedCompany.companyName , '업체선택' )}
											<span className={'search_icon'} style={{backgroundImage: `url('${search}')`}}/>
										</SearchButton>
										{
											selectedCompany.companyCode &&
											<CompanyStateButton>
												<div className={'company_name'}
														 onClick={() => setDataModal( {menu: 'companyInfo'} )}
														 style={{margin: '0 auto'}}>

														{selectedCompany.companyName}

														</div>
												<div className={'x-mark'}  style={{display: disabled ? 'none' : 'block'}}
														 onClick={() => setSelectedCompany( {} )}>
													{'╋'}
												</div>
											</CompanyStateButton>
										}

								</React.Fragment>
							}
						</div>
						<div style={{display: 'flex' , alignItems: 'center'}}>
						<div style={{color: authValid()? '#171725' : '#fff', fontWeight: authValid()? 700 : 400}}>{realTime}</div>
							<ProfileInfo>
								<img src={`${window.localStorage.getItem('profile')}` ?? `${ProfileSample}`} alt="" width={36} height={36}
										 style={{borderRadius:'50%', backgroundColor:'#0039a4',display:'block',objectFit:'cover'}}
									// onClick={()=> {setCompanyData({}); window.location.reload()}}
										 onError={(e:React.ChangeEvent<HTMLImageElement>)=>e.target.src = ProfileSample}/>
								<div onClick={()=>setPlaceOpen(!placeOpen)} style={{display:'flex',alignItems:'center',cursor:'pointer'}}>
									<div style={{margin:'0 10px', color: authValid()? '#171725' : '#fff', fontWeight: authValid()? 700 : 400}}>{window.localStorage.getItem('userName')}</div>
									<ArrowButton arrowDirection={!placeOpen? 'bottom' : 'top'}/>
								</div>
							</ProfileInfo>
							<EventMenu open={placeOpen} ref={popupRef}>
								<div onClick={() => {setDataModal({menu: 'check'})}}
										 style={{opacity: location.pathname.includes('myPage') ? 0.5 : 1 ,
											 pointerEvents: location.pathname.includes('myPage') ? 'none' : 'auto'}}>
									{'마이 페이지'}
								</div>
								<div style={{display:'flex'}} onClick={()=>setDataModal({menu:'lang'})}>
									<div>{selectLanguage.lang === 'ko'? '언어':'Language'}</div>
									<div style={{color:'rgba(0,0,0,0.5)',marginLeft:'8px'}}>{`(${selectLanguage.text})`}</div>
								</div>
								<div onClick={()=>setDataModal({menu:'logout'})}>{
									selectLanguage.lang === 'ko'? '로그 아웃' : 'Log Out'
								}</div>
							</EventMenu>
						</div>
					</div>
				</Container>
			}
		</React.Fragment>
	);
};

export default Header;

const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-weight: 700;
	box-sizing: border-box;
	z-index: 1;
	user-select: none;
	font-size: 14px;
	box-shadow: 0 1px 4px #15223214;
	//min-width: 320px;
`

const ProfileInfo = styled.div`
	display: flex;
	align-items: center;
	margin-left: 16px;
	font-weight: 400;
`

const EventMenu = styled.div<{open: boolean}>`
	display: ${props => props.open? 'block' : 'none'};
	position: absolute;
	width: 160px;
	border: 1px solid #0058ff;
	color: #171725;
	font-size: 12px;
	box-sizing: border-box;
	border-radius: 8px;
	background-color: #fff;
	padding: 8px 0;
	top: 76px;
	margin-left: 158px;
	&>div{
		box-sizing: border-box;
		padding-left: 24px;
		cursor: pointer;
		height: 40px;
		line-height: 40px;
		font-weight: 400;
		&:hover{
			background-color: rgba(0,88,255,0.05);
		}
	}
`

const SearchButton = styled.div<{disabled?: boolean}>`
	display: flex;
	box-sizing: border-box;
	border: 1px solid #d7dbec;
	border-radius: 4px;
	height: 32px;
	opacity: ${props=> props.disabled ? 0.5 : 1};
	background-color: ${props => props.disabled ? '#f4f7fc' : '#fff'};
	align-items: center;
	user-select: none;
	color: #5A607F;
	font-size: 14px;
	font-weight: 400;
	padding: 0 4px 0 8px;
	width: 192px;
	justify-content: space-between;
	cursor: ${props => props.disabled ? 'default' : 'pointer'};
	pointer-events: ${props => props.disabled ? 'none' : 'auto'};

	.search_icon{
		width: 24px;
		height: 24px;
		cursor: pointer;
		background-color: transparent;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
`

const CompanyStateButton = styled.div<{disabled?: boolean}>`
	display: flex;
	background-color:#fff;
	border-radius: 4px;
	height: 32px;
	opacity: ${props => props.disabled ? 0.5 : 1};
	min-width: 132px;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	padding: 0 8px;
	margin-left: 8px;
	cursor: pointer;

	.company_name {
		color: ${props => props.disabled ? '#5A607F' : '#0058ff'};
		
		&:hover {
			text-decoration: underline;
			text-underline-offset: 4px;
		}
	}


	.x-mark {
		display: ${props => props.disabled ? 'none' : 'block'};
		color: #5A607F;
		user-select: none;
		font-weight: 800;
		font-size: 18px;
		transform: rotate(45deg);
		margin-left: 8px;
		cursor: pointer;
	 

		&:hover {
			color: #0058ff;
		}
	}
`
