import React from 'react';
import styled from "styled-components";
import {FacebookCircularProgress} from "../../style/StyleComponent";

interface Load_Props {
  height?: number
}
const LoadingProgressCircle:React.FunctionComponent<Load_Props> = ( {height}) => {
  return (
    <LoadingComponent style={{height: `${height ?? 240}px`}}>
      <FacebookCircularProgress/>
    </LoadingComponent>
  );
};
export default LoadingProgressCircle;

const LoadingComponent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  
    &>div:nth-child(1){
        transform: translateX(0px);
    }
    svg {
        path.recharts-tooltip-cursor {
            stroke: transparent;
            stroke-width: 32px;
            stroke-opacity: 0.1;
        }
    }
`

