import React , {ChangeEvent , useEffect , useState} from 'react';
import {useLocation} from "react-router-dom";
import {Modal_View_Type , PeripheralType , SearchCompanyType} from "../../../../../types/Fundamental";
import {
  dataAccessLoading ,
  dataValid ,
  initModal , loadingRemove ,
} from "../../../../../function/function";
import {ContentFrame , multipleImageGridStyle , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import FactoryDropMenu from "../../../../../components/sideMenu/FactoryDropMenu";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import dayjs from "dayjs";
import RegisterInput from "../../../../../components/input/RegisterInput";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import styled from "styled-components";
import {useRecoilState} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {useTranslation} from "react-i18next";
import PeripheralsMultipleReg from "./PeripheralsMultipleReg";

const PeripheralsRegistration = () => {
  const {t}:any = useTranslation()
  const location = useLocation()
  const authValid = location.pathname.includes('/admin')
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [peripheralRegister, setPeripheralRegister] = useState<PeripheralType|any>([])
  const [factoryDataList, setFactoryDataList] = useState<SearchCompanyType[]>([])
  const [factorySubName, setFactorySubName] = useState<string[]>(['미등록'])
  const selectType = ['미스피드 검출장치' , '하사점 검출장치' , '로드모니터' , '앵글시퀀서' , '엔코더' , '통관센서' , '유틸리티센서' , '기타']
  const [imageKeyState,setImageKeyState] = useState<any[]|null>([]);
  const [specImageKey,setSpecImageKey] = useState<string|null>(null);
  const [abilityImageKey,setAbilityImageKey] = useState<string|null>(null);
  const [manualImageKey,setManualImageKey] = useState<string|null>(null);
  const [selectedCompany, setSelectedCompany] = useRecoilState<any>(authValid? persistCompanyData : userPersistCompanyData)
  const [factorySegment, setFactorySegment] = useState<string[]>([])
  const [multipleReg, setMultipleReg] = useState<boolean>(false)
  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, trim?:boolean) =>{
    const  { name , value } = e.target
    setPeripheralRegister({
                     ...peripheralRegister,
                     [name] : trim? value.trim() : value
                   })
  }

  const serialDoubleCheck = async (serialCode: string, type: string) => {
    try {
      const response = await client.post('/peripheral/serial/check',{
        companyCode: selectedCompany?.companyCode,
        peripheralNumber: serialCode,
        peripheralType: type
      },{headers:{Authorization}})
      if(response.data.status === 200){
        setPeripheralRegister((prev:any)=>({...prev, codeDoubleCheck: true}))
        setDataModal({menu:'confirm', message: response.data.message})
      }else{
        setDataModal({menu:'confirm', message: response.data.message})
      }

    }catch ( e ) {
      console.log(e)
      setDataModal({menu:'confirm', message:' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
    }
  }


  const peripheralsRegisterRes = async () => {
    if(!peripheralRegister?.peripheralType){
      setDataModal({menu: 'confirm' , message: '장치 종류를 선택해 주세요.'})
    } else if(!peripheralRegister?.peripheralManufacturer){
      setDataModal({menu: 'confirm' , message: '장치 제조사를 입력해 주세요.'})
    } else if(!peripheralRegister?.peripheralName){
      setDataModal({menu: 'confirm' , message: '장치 이름을 입력해 주세요.'})
    } else if(!peripheralRegister?.peripheralNumber){
      setDataModal({menu: 'confirm' , message: '장치 제조번호 중복 확인을 해주세요.'})
    } else{
      try {
        dataAccessLoading()
        const response = await client.post(`/peripheral/register` ,[{
          ...peripheralRegister,
          companyCode: selectedCompany?.companyCode,
          peripheralImgCode: imageKeyState,
          peripheralImg: imageKeyState,
          specImgCode: specImageKey,
          specImg: specImageKey,
          abilityImgCode: abilityImageKey,
          abilityImg: abilityImageKey,
          manualImgCode: manualImageKey,
          manualImg: manualImageKey,
        }]
          ,{headers:{Authorization}})

        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            loadingRemove()
            setDataModal({menu: 'success' , message: '주변장치 정보가 등록 되었습니다.'})
          } , 500)
          setDataModal(initModal)
          setTimeout(() => {
            window.location.href = `/admin/fas/peripherals`
          } , 1000)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }

  const factoryListRes = async  (companyCode?: string) => {
    try {
      const response = 	authValid ?
        await client.post( `/factory/admin/search`,{page:1, size:10, keyword:companyCode},
                           {headers:{Authorization}})
        :
        await client.get( `/factory/list/${1}/${10}/${companyCode}`, {headers:{Authorization}})
      const data = response.data.data
      setFactoryDataList(data.row)
      const segment = await client.post('/factory/subdivision/list',{
        factoryCode: peripheralRegister.factoryCode
      },{headers:{Authorization}})
      const segmentData = segment.data.data.subdivision
      if(!segmentData[0]){
        setFactorySubName(['미등록'])
      }else{
        setFactorySubName(segmentData)
      }
    }catch (e) {
      console.log(e)
    }
  }

  const factoryDivision = async ( factoryCode: string | undefined ) => {
    try {
      const response = await client.post('/factory/subdivision/list',{
        factoryCode: factoryCode
      },{headers:{Authorization}})
      const segmentData = response.data.data.subdivision
      //@ts-ignore
      if(!segmentData[0]){
        setFactorySubName(['미등록'])
      }else{
        setFactorySubName(segmentData)
      }
    }catch (e) {
      console.log(e)
    }
  }
  useEffect( () => {
    factoryListRes( selectedCompany?.companyCode )
    setPeripheralRegister( ( prev: PeripheralType ) => ( {...prev , companyCode: selectedCompany?.companyCode} ) )
    setPeripheralRegister( ( prev: PeripheralType ) => ( {...prev , factoryCode: ''} ) )
    setPeripheralRegister( ( prev: PeripheralType ) => ( {...prev , factorySubDivision: ''} ) )
  }
    , [selectedCompany] )



  return (
    <SectionFrame>


      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = authValid ? `/admin/fas/peripherals` : '/fas/peripherals'}}
          />
      }
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => setDataModal( initModal )}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}
      <div style={{display: 'flex' , justifyContent: 'space-between'}}>
        <MenuTitle title={( authValid ? '업체/' : '' ) + '기본정보 관리 > 주변장치 기본정보 > 등록' + ( multipleReg ? '(다중)' : '' )}
                   unitCase={'MenuTitle'}/>
        {multipleReg ?
          <div style={{display: 'flex'}}>
            <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                         clickEvent={() => {
                           setDataModal( {
                                           menu: 'cancel' ,
                                           message: '목록으로 이동 하시겠습니까?'
                                         } )
                         }}/>
            <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                         clickEvent={() => setMultipleReg( false )}/>
          </div>
          :
          <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                       clickEvent={() => setMultipleReg( true )}/>
        }
      </div>
        <ContentFrame height={'680px'}>
          {multipleReg ?
            <PeripheralsMultipleReg/>
            :
            <div style={{display: 'flex' , marginBottom: '16px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'주변장치 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <MainButtons name={'취소'} width={'56px'}
                                 clickEvent={() => {
                                   setDataModal( {
                                                   menu: 'cancel' ,
                                                   message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                 } )
                                 }}/>
                    <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                 clickEvent={peripheralsRegisterRes}/>
                  </div>
                </div>
                {/*{authValid &&*/}
                {/*    <>*/}
                {/*      <ListInfoComponent title={'업체명'} value={peripheralRegister?.companyName} bottomBorder={false}/>*/}
                {/*      <ListInfoComponent title={'지역'} value={locationFilter(peripheralRegister?.companyAddress?.split('/').pop())} bottomBorder={false}/>*/}
                {/*    </>}*/}
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 1fr)'}}>

                  <ModifyOption>
                    <div>{'공장명'}</div>
                    <div style={{position: 'relative' , display: 'flex'}}>
                      <FactoryDropMenu clickEvent={( data ) => {
                        setPeripheralRegister( ( prev: PeripheralType ) => ( {
                          ...prev ,
                          factoryCode: data.factoryCode
                        } ) )
                        factoryDivision( data.factoryCode )
                      }} selectCode={dataValid( peripheralRegister?.factoryCode , '' )}
                                       menuArray={factoryDataList?.map( ( v ) => ( {
                                         factoryAddress: v.factoryAddress ,
                                         factoryCode: v.factoryCode ,
                                         factoryName: v.factoryName
                                       } ) )}/>
                    </div>
                  </ModifyOption>


                  <RegisterComponent type={'drop'} title={'공장 세분화'} name={'factorySubDivision'} dropWidth={'208px'}
                                     value={peripheralRegister?.factorySubDivision} onChange={onChange}
                                     dropArray={factorySubName}
                                     dropAddNoneSelect={factorySubName[0] !== '미등록'}
                                     dropClickEvent={( v ) => {
                                       if( v === '미등록' ){
                                         setPeripheralRegister( ( prev: PeripheralType ) => ( {
                                           ...prev ,
                                           factorySubDivision: ''
                                         } ) )
                                       } else{
                                         setPeripheralRegister( ( prev: PeripheralType ) => ( {
                                           ...prev ,
                                           factorySubDivision: v
                                         } ) )
                                       }
                                     }}/>

                  <RegisterComponent type={'drop'} title={'장치 종류'} name={'peripheralType'} required={true}
                                     dropClickEvent={( v ) => setPeripheralRegister( ( prev: PeripheralType ) => ( {
                                       ...prev ,
                                       peripheralType: v
                                     } ) )}
                                     value={peripheralRegister?.peripheralType} dropArray={selectType}
                                     onChange={onChange}/>

                  <RegisterComponent type={'text'} title={'담당자'} name={'managerName'}
                                     value={peripheralRegister?.managerName} onChange={( e ) => onChange( e , true )}/>

                  {/*<div style={{display:'flex',alignItems:'center',borderTop:'1px solid #E6E9F4'}}>*/}
                  {/*    <div className={'calender_wrap'}>{'제조 연월일'}</div>*/}
                  {/*    <div style={{width:'190px',paddingLeft:'16px'}}>*/}
                  {/*        <ListInnerInput type={'calendar'} name={'manufactureDate'} inputData={modifyState?.manufactureDate}*/}
                  {/*                        onChange={(v)=>setModifyState((prev:PeripheralType)=>({...prev, manufactureDate: dayjs(v).format('YYYY.MM.DD')}))}*/}
                  {/*                        placeholder={'날짜 선택'} style={{cursor:'pointer'}}/>*/}
                  {/*    </div>*/}
                  {/*</div>*/}

                  <RegisterComponent type={'calendar'} name={'manufactureDate'}
                                     value={peripheralRegister?.manufactureDate}
                                     selectDate={peripheralRegister?.manufactureDate}
                                     title={'제조 연월일'} left={- 100} top={45}
                                     deleteEvent={() => setPeripheralRegister( ( prev: any ) => ( {
                                       ...prev ,
                                       manufactureDate: ''
                                     } ) )}
                                     onChange={( v ) => setPeripheralRegister( ( prev: any ) => ( {
                                       ...prev ,
                                       manufactureDate: dayjs( v ).format( 'YYYY.MM.DD' )
                                     } ) )}
                                     placeholder={'날짜 선택'}/>
                  <RegisterComponent type={'doubleCheck'} title={'장치 제조번호'}
                                     name={'peripheralNumber'} value={peripheralRegister?.peripheralNumber}
                                     disabled={peripheralRegister.codeDoubleCheck || !peripheralRegister.peripheralNumber}
                                     clickEvent={() => serialDoubleCheck( peripheralRegister?.peripheralNumber , peripheralRegister?.peripheralType ).then()}
                                     onChange={( e ) => ( setPeripheralRegister( ( prev: any ) => ( {
                                       ...prev ,
                                       peripheralNumber: e.target.value.trim() ,
                                       codeDoubleCheck: false
                                     } ) ) )}/>
                  <RegisterComponent type={'text'} title={'장치 제조사'} name={'peripheralManufacturer'} paddingRight={true}
                                     value={peripheralRegister?.peripheralManufacturer}
                                     onChange={( e ) => onChange( e , true )} required={true}/>
                  <RegisterComponent type={'text'} title={'장치 이름'} name={'peripheralName'}
                                     value={peripheralRegister?.peripheralName} onChange={( e ) => onChange( e , true )}
                                     required={true}/>
                </div>
                <div style={{
                  display: 'grid' ,
                  gridTemplateColumns: '168px 1fr' ,
                  borderBottom: '1px solid rgba(213, 215, 227, 0.5)'
                }}>
                  <div className={'info-title'} style={{height: '240px'}}>{'비고'}</div>
                  <div className={'info-content'} style={{height: '240px'}}>
                    <RegisterInput type={'textarea'} name={'contents'} value={peripheralRegister?.note}
                                   maxHeight={'224px'} limit={2000}
                                   onChange={onChange} placeholder={'내용을 입력해주세요.'}/>
                  </div>
                </div>
              </RegisterComp>

              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'} style={{width: '379px' , height: '600px' , overflowY: 'scroll'}}>
                <div style={{display: 'flex' , flexWrap: 'wrap'}}>
                  <div
                    style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , width: '100%'}}>
                    <MenuTitle title={'장치 이미지 등록'} unitCase={'MenuCompTitle'}/>
                    <div style={{
                      color: '#7E84A3' ,
                      textAlign: 'right' ,
                      fontSize: '12px' ,
                      lineHeight: '16px' ,
                      height: '34px'
                    }}>
                      <div>{'*최대 4개의 이미지 등록 가능'}</div>
                      <div>{'*jpg, png 10mb 이하의 파일 등록 가능'}</div>
                    </div>
                  </div>
                  <div style={{marginTop: '12px'}}>
                    {/*@ts-ignore*/}
                    <TotalImageUploadBox  imageKeyArray={setImageKeyState}
                                         profileSelect={false} imageLength={2}/>
                  </div>


                  <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
                    <div>
                      <MenuTitle title={'스펙 명판'} unitCase={'MenuCompTitle'}/>
                      <SingleImageUploadBox imageKey={( data ) => setSpecImageKey( data )}
                                              inputSize={168}
                                            profileSelect={false}/>
                    </div>
                    <div>
                      <MenuTitle title={'능력 명판'} unitCase={'MenuCompTitle'}/>
                      <SingleImageUploadBox imageKey={( data ) => setAbilityImageKey( data )}
                                              inputSize={168}
                                            profileSelect={false}/>
                    </div>
                    <div>
                      <MenuTitle title={'사용 설명서'} unitCase={'MenuCompTitle'}/>
                      <SingleImageUploadBox imageKey={( data ) => setManualImageKey( data )}
                                              inputSize={168}
                                            profileSelect={false}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
        </ContentFrame>
    </SectionFrame>
);
};
export default PeripheralsRegistration;


const RegisterComp = styled.div`
        width: 784px;
        height: 100%; 
        //.register_notice{
        //	min-height: 112px;
        //	height: 112px;
          //	}
    
        .info, .reg {
            display: grid;
        box-sizing: border-box;

        &-title {
            padding: 16px;
            width: 168px;
            box-sizing: border-box;
            display: flex;
            //justify-content: space-between;
            align-items: center;
            height: 48px;
            background-color: #F5F6FA;
            color: #131523;
            font-size: 12px;
            font-weight: 800;
            border-top: 1px solid rgba(213, 215, 227, 0.5);

        }

        &-content {
            display: flex;
            padding-left: 16px;
            min-width: 224px;
            box-sizing: border-box;
            font-size: 14px;
            height: 48px;
            border-top: 1px solid rgba(213, 215, 227, 0.5);
            align-items: center;
        }
    }

    
    .scroll_func{
			width: 804px;
			height: 570px;
			overflow-x: hidden;
			overflow-y: scroll;
			&::-webkit-scrollbar {
				width: 20px;
				display: block;
			}
		}
  
    .calender_wrap{
      width: 168px;
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      align-items: center;
      font-weight: bold;
      font-size: 12px;
      display: flex;
      background-color: #F5F6FA;
    }
	`
const ModifyOption = styled.div`
  display: flex;
  border-top: 1px solid #E6E9F4;
  user-select: none;
  &>div{
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    padding-left: 16px;
    align-items: center;
    display: flex;
  }
  &>div:first-child{
    width: 168px;
    min-width: 168px;
    color: #131523;
    background-color: #F5F6FA;
    font-size: 12px;
    font-weight: 800;
  }
  &>div:last-child{
    width: 100%;
    max-width: 616px;
    max-height: 48px;
  }
`
