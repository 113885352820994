import React , {useRef , useState} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {modalTitleBase , NoData , orderComponent} from "../../style/StyleComponent";
import up from '../../asset/image/Polygon_up.svg'
import down from '../../asset/image/Polygon_down.svg'
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {useLocation , useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {MoldInfoType} from "../../types/Fundamental";
import MainButtons from "../button/MainButtons";
import dayjs from "dayjs";
import {dataValid , numberLocaleString} from "../../function/function";

interface Props {
  cancelEvent:()=>void
  moldAssetDocData:any
  imageUrlArray?:any
  detailViewType?:boolean
  facilityViewType?:boolean

}
const MoldAssetDocModal:React.FC<Props> = ({cancelEvent,moldAssetDocData,imageUrlArray,detailViewType,facilityViewType}) => {
  const {t}:any =useTranslation()
  // console.log(imageUrlArray.disassemblyImagesUrl,'dis')
  // console.log(imageUrlArray.assemblyImagesUrl,'noneDis')
  // console.log(moldAssetDocData)

  const printRef = useRef<any>(null)
  return (
    <ModalBase modalContent={
      <React.Fragment>
        <BaseComponent>
          <div className={'print_hide'}>
            {modalTitleBase( {mainTitle: (detailViewType ?  '금형 관리 대장'  : '금형 관리 대장(미리보기)')},
              <div style={{display:detailViewType ? 'flex' : 'none',justifyContent:'right',width:'872px'}}>
                <MainButtons name={'인쇄 하기'} clickEvent={()=>{window.print()}}  marginDis={true}/>
              </div>)}
          </div>

          <div className={'modal_content'} ref={printRef}>
            <div className={'scroll_func'}>
                <div style={{display: 'flex' , justifyContent: 'center' , fontSize: '20px' , fontWeight: '800'}}>{t( '금형 관리 내역서' )}</div>
              <TableDefaultOption>
                <div style={{display: 'grid' , gridTemplateColumns: '132px 1fr 132px 1fr' , alignItems: 'center'}}>
                  {orderComponent( t( '제작처' ) , moldAssetDocData?.manufacturer )}
                  {orderComponent( t( '제작일' ) , moldAssetDocData?.productionDate ? dayjs(moldAssetDocData?.productionDate).format('YYYY.MM.DD') : '-')}
                  {orderComponent( t( '모델' ) , moldAssetDocData?.customer?.map( ( v: any ) => ( v.customerModelName ) ).join( ',' ))}
                  {orderComponent( t( '자산구분' ) , moldAssetDocData?.assetType )}
                  {orderComponent( t( '금형 CODE' ) , moldAssetDocData?.moldSerialCode )}
                  {orderComponent( t( '규격' ) , moldAssetDocData?.specification )}
                  {orderComponent( t( '중량(Kg)' ) , numberLocaleString(moldAssetDocData?.weight) + '    Kg')}
                  {orderComponent( t( '캐비티(ea)' ) , numberLocaleString(moldAssetDocData?.cavity)  + '    ea')}
                  {orderComponent( t( '생산공정' ) , moldAssetDocData?.processName)}
                  {orderComponent( t( '설비' ) , moldAssetDocData?.facility?.map( ( v: any ) => ( v.facilityName) ).join( ',' ))}
                </div>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)' , alignItems: 'center'}}>
                  <div className={'order_title'} style={{justifyContent: 'center' , borderRight: '0.5px solid transparent'}}>{t( '상하형 조립(전체 사진)' )}</div>
                  <div className={'order_title'} style={{justifyContent: 'center'}}>{t( '상하형 분리(내부 사진)' )}</div>
                  <div className={'order_content'} style={{padding:'8px 0',display:'flex',flexDirection:'column',gap:'8px',justifyContent:'center',minHeight: '416px' , borderLeft: '1px solid #cacdd5'}}>
                    {imageUrlArray?.assemblyImagesUrl?.length !== 0 ?
                      imageUrlArray?.assemblyImagesUrl.map((v:string)=>(
                      <img src={v} height={196} style={{objectFit:'contain',width:'95%'}}/> ))
                      :
                      <NoData>{'* 현재 등록되어있는 상하형 조립(전체) 사진이 없습니다.'}</NoData>
                    }
                  </div>

                  <div className={'order_content'} style={{padding:'8px 0',display:'flex',flexDirection:'column',gap:'8px',justifyContent:'center',minHeight: '416px' , borderRight: '1px solid #cacdd5', borderLeft: '0.5px solid #cacdd5'}}>
                    {imageUrlArray?.disassemblyImagesUrl?.length !== 0 ?
                      imageUrlArray?.disassemblyImagesUrl.map((v:string)=>(
                        <img src={v} height={196} style={{objectFit:'contain',width:'95%'}}/> ))
                      :
                      <NoData>{'* 현재 등록되어있는 상하형 분리(내부) 사진이 없습니다.'}</NoData>
                    }
                  </div>
                </div>

              </TableDefaultOption>
              <div className={'print_hide'}>
                <MainButtons name={'확인'} width={'144px'} clickEvent={cancelEvent} margin={'16px auto 0 auto'}/>
              </div>
            </div>
          </div>
        </BaseComponent>
      </React.Fragment>
    }/>
  );
};
export default MoldAssetDocModal;
const BaseComponent = styled.div`
  @media print{
    size: A4;
    scale: 150%;
    margin-top: 100mm;
    padding-bottom: 80mm;
    -webkit-print-color-adjust: exact;
    .print_hide{
      display: none;
    }
    @page {
      size : landscape;
      margin: 0;
    }
    body {
      margin: 1.6cm;
      height:100%;
      overflow: hidden;
    }
  }
  position: absolute;
  width: 1032px;
  height: 796px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: space-between;
  
  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .modal_content {
    display: flex;
    height: 796px;
    flex-direction: column;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: flex-start;
    
    //.scroll_func {
    //  overflow-y: scroll;
    //  background-color: #fff;
    //  &::-webkit-scrollbar{
    //    display: block;
    //  }
    //}
  }

  `

const TableDefaultOption =  styled.div`
  margin: 16px 16px 0 16px;
  border-bottom: 1px solid #cacdd5;
  display: grid;
  //grid-template-columns: 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr 0.7fr 1fr;
  align-items: center;
  box-sizing: border-box;

  & > div:nth-child(1) {
    border-right: 1px solid #cacdd5;
  }

  .order {
    &_title {
      height: 32px;
      background-color: #ededed;
      z-index: 100;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #cacdd5;
      border-right: 1px solid #cacdd5;
      border-left: 1px solid #cacdd5;

    }

    &_content {
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 16px;
      box-sizing: border-box;
      border-top: 1px solid #cacdd5;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`

