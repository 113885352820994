import React , {useContext} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import MainButtons from "../button/MainButtons";
import ExcelApiButton , {ExcelContext} from '../button/ExcelApiButton';
import {authValid , buttonAuthValid} from "../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";

interface Props {
  closeEvent: () => void
  excelContent: React.ReactNode
}

const ExcelModal: React.FC<Props> = ( {closeEvent  ,excelContent} ) => {
  return (
    <ModalBase modalContent={
      <BaseComponent>
        <div className={'modal'} style={{backgroundColor: authValid() ? '#CFFA61' : '#0058ff' , color: authValid() ? '#171725' : '#fff'}}>
          <div className={'modal_title'}>{'Excel'}</div>
        </div>

        <div style={{display: 'flex' , flexDirection: 'column' , justifyContent: 'space-between'}}>
          <div style={{display:'flex',justifyContent:'center', marginTop: '20px' , marginBottom: '32px'}}>
            {excelContent}
          </div>
          <MainButtons name={'닫기'} width={'144px'} height={'40px'} clickEvent={closeEvent} padding={'4px 16px'}/>
        </div>
      </BaseComponent>
    }/>
  );
};
export default ExcelModal;
const BaseComponent = styled.div`
  min-width: 416px;
  height: 244px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  user-select: none;

  .modal {
    min-width: 416px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;

    &_title {
      font-size: 18px;
      font-weight: 900;
      margin-right: 8px;
    }

    &_sub_title {
      font-size: 14px;
      font-weight: bold;
      opacity: 0.5;
    }
  }
`


