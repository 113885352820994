import React , {useState} from 'react';
import {useParams} from "react-router-dom";
import {Modal_View_Type , MoldInfoType , ProductInfoType} from "../../../../../types/Fundamental";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading , dataValid ,
  initModal ,
  loadingRemove , modalListLoading , numberLocaleString
} from "../../../../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {
  ContentFrame ,
  NoData ,
  SectionFrame ,
  tooltipModule ,
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import ProductMoldView from "../../../../../components/modal/ProductMoldView";
import PreviewImage from "../../../../../components/display/PreviewImage";
import MoldAssetDocModal from "../../../../../components/modal/MoldAssetDocModal";
import dayjs from "dayjs";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import ImageDetailPreview from "../../../../../components/modal/ImageDetailPreview";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import noImage from "../../../../../asset/image/noImage.svg";
import IntegratedSearchModal from "../../../../../components/modal/IntegratedSearchModal";
import FacilityRegisterModal from "../../../../../components/modal/FacilityRegisterModal";

const MoldDetail = () => {
  const {t}:any = useTranslation()
  const params = useParams()
  const {moldCode} = params
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [selectPage, setSelectPage] = useState<number>(0)
  const [moldDetail , setMoldDetail ] = useState<MoldInfoType | any>( {customer:[]} )
  const [moldInit , setMoldInit] = useState<MoldInfoType | any>( {} )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>([])
  const [moreProduct , setMoreProduct] = useState<any>([])
  const [selectProductData,setSelectProductData] = React.useState<ProductInfoType | any>({customer:[]})
  const [imageKeyState,setImageKeyState] = React.useState<{assemblyImages:string[],disassemblyImages:string[],assemblyImagesUrl:string[],disassemblyImagesUrl:string[]}>({assemblyImages:[],disassemblyImages:[],assemblyImagesUrl:[],disassemblyImagesUrl:[]})
  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string,trim?:boolean) => {
    const {name, value} = e.target
    setMoldDetail({...moldDetail,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? value.replace(/[^0-9.]/g, '') : trim ? value : value.trim()})
  }

  const moldDetailRes = async () => {
    try{
      const response = await client.get(  `/mold/detail/${moldCode}`, {headers: {Authorization}})
      const data = response.data;
      setMoldDetail( data.data )
      setSelectProductData((prev:any)=>({...prev, productName: data.data.productName}))
      setMoldInit( data.data)
    } catch( error ){
      console.log( `error` , error )
    }
  }
  React.useEffect(() => {
    moldDetailRes().then()
  }, [])


  const serialDoubleCheck = async (serialCode: string) => {
    if(!moldDetail.moldSerialCode){
      setDataModal( {menu: 'confirm' , message: '금형 CODE를 입력해 주세요.'} )
      return
    }
    try {
      const response = await client.post('/mold/serial/check', {
        companyCode: selectedCompany.companyCode,
        moldSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 금형 CODE 입니다.' });
        setMoldDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
      }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 금형 CODE 입니다.' });
        setMoldDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };


  //삭제
  const moldDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/mold/delete` , [moldCode] , {headers: {Authorization}})
      const data = response.data
      const repairCaseArray = data.data?.moldManagement?.map((v: any) => ( {code: v , case: '금형 수리요청에 등록되어 있습니다.'} ))
      const productCaseArray = data.data?.product?.map((v: any) => ( {code: v , case: '품목에 등록된 금형입니다.'} ))
      const caseArray = [repairCaseArray , productCaseArray].flat().filter((v) => ( v ))
      if(data.status === 200){
        setTimeout(() => {
          setDataModal({menu: 'success' , message: '선택한 금형이 삭제 되었습니다.'})
          loadingRemove()
        } , 500)
      } else if(data.status === 601){
        setTimeout(() => {
          setDataModal({menu: 'warn' , message: '삭제할 수 없는 금형이 있습니다.'})
          setDataModal({menu: 'deleteCase'})
          setDeleteCaseArray(caseArray)
          loadingRemove()
        } , 500)
      } else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }

  const moldUpdateRes = async () => {
    if(!moldDetail.moldSerialCode){
      setDataModal({menu: 'confirm' , message: '금형 CODE가 없습니다.'})
    } else if(!moldDetail.moldName){
      setDataModal({menu: 'confirm' , message: '금형명이 없습니다.'})
    } else if(!moldDetail.cavity){
      setDataModal({menu: 'confirm' , message: '캐비티 지수를 입력 해야 합니다.'})
    } else if(!moldDetail.codeDoubleCheck){
      setDataModal({menu: 'confirm' , message: '금형CODE 중복 확인을 해주세요.'})
    } else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post(`/mold/update` ,
                                           {
                                             ...moldDetail,
                                             assemblyImages:imageKeyState.assemblyImages,
                                             disassemblyImages:imageKeyState.disassemblyImages,
                                           }
          , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setDataModal({menu: 'success' , message: '수정 되었습니다.'})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  const moldProductViewRes = async (moldCode: string) => {
    modalListLoading()
    try {
      const response = await client.get(`/mold/product/view/${moldCode}` , {
        headers: {Authorization}
      })
      console.log(response.data.data)
      setMoreProduct(response.data.data.row)
      loadingRemove()
    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {
        setDataModal( initModal )
        if(dataModal.message?.includes('삭제')){
          window.location.href = authValid() ? `/admin/fas/mold` : `/fas/mold`
        }else if(dataModal.message?.includes('수정')){
          window.location.href = authValid() ? `/admin/fas/mold/${moldCode}` : `/fas/mold/${moldCode}`
        }else{
          return
        }
      }}/> :
        dataModal.menu === 'error' ? <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/> :
          dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'} closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={moldDetail}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 저장없이 이동합니다.'}
                              viewType={true}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                setMoldDetail( moldInit )
                                setDataModal( initModal )
                                setSelectPage( 0 )
                              }
                              }/>}
      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'금형 CODE'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'previewAssembly' &&
          <ImageDetailPreview profile={moldDetail?.assemblyImagesUrl[dataModal.index]}
                              imageUrl={moldDetail?.assemblyImagesUrl}
                              clickEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'previewDisAssembly' &&
          <ImageDetailPreview profile={moldDetail?.disassemblyImagesUrl[dataModal.index]}
                              imageUrl={moldDetail?.disassemblyImagesUrl}
                              clickEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={moldDetail}/>}

      {dataModal.menu === 'delete' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 클릭시 복구 할 수 없습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={moldDeleteRes}/>}

      {dataModal.menu === 'product' &&
          <ProductMoldView arrayData={moreProduct}
                           closeButton={() => setDataModal(initModal)}/>}


      {dataModal.menu === 'moldAsset' &&
        <MoldAssetDocModal cancelEvent={() => setDataModal( initModal )} detailViewType={selectPage === 0}
                           facilityViewType={selectPage !== 0}
                           moldAssetDocData={moldDetail} imageUrlArray={selectPage === 0 ? moldDetail : imageKeyState}/>}

      {dataModal.menu === 'productSelect' &&
          <IntegratedSearchModal authValid={authValid()}
                                 menuArrayType={'onlyProduct'}
                                 receiveData={(data)=>{
                                   setMoldDetail((prev:any) => ({
                                     ...prev ,
                                     productCode: data.productCode ,
                                     customer: data.customer ,
                                     facility: [{facilityName:data.facilityName}] ,
                                     processName: data.processName}));
                                   setSelectProductData(data)}}
                                 closeEvent={()=>setDataModal(initModal)}/>}
      {dataModal.menu === 'facility' &&
        <FacilityRegisterModal companyCode={moldDetail?.companyCode} viewType={true}
                                       cancelEvent={()=>{setDataModal(initModal)}}
                                       selectProduct={moldDetail}
                                       selectedFacility={moldDetail?.facility?.map((v:any)=>(v.facilityCode))}
                                       receivedFacility={(data)=>{console.log(data)}}/>
      }

      {selectPage === 0 ?
        <React.Fragment>
          <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 금형 기본정보 > 조회'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
                  <MenuTitle title={'금형 기본정보 조회'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'관리대장 보기'} width={'136px'} clickEvent={() => {setDataModal( {menu: 'moldAsset'} )}}/>
                      <MainButtons name={'목록'} width={'56px'} clickEvent={() => {window.location.href = `${authValid() ? '/admin/fas/mold' : '/fas/mold'}`}}/>
                      <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   disabled={buttonAuthValid( 'update' , '7' )}
                                   clickEvent={() => {setSelectPage( 1 ); Object.assign(moldDetail , {codeDoubleCheck: true})}}/>
                    </div>
                  </div>
                </div>
                {/*<ListInfoComponent title={'업체명'} value={selectedCompany.companyName} bottomBorder={false}/>*/}
                <div className={'scroll_func'}>
                  <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 392px)'}}>
                    <ListInfoComponent title={'제작처'} value={dataValid(moldDetail?.manufacturer)} bottomBorder={false}/>
                    <ListInfoComponent title={'제작일'} value={!moldDetail?.productionDate ? '-' : dayjs(moldDetail?.productionDate).format('YYYY.MM.DD')} bottomBorder={false}/>
                    <ListInfoComponent title={'금형 CODE'} value={dataValid(moldDetail?.moldSerialCode)} bottomBorder={false}/>
                    <ListInfoComponent title={'품목명'} value={dataValid(moldDetail?.productName)} bottomBorder={false}/>
                    <ListInfoComponent title={'거래처'} type={'addition'} lineHeight={!moldDetail?.customer ?  48 : 24}
                                       arrayData={Array.from(new Set(moldDetail?.customer?.map(( customer: { customerName: any; }) => customer?.customerName)))} bottomBorder={false}/>
                    <ListInfoComponent title={'모델'} type={'addition'} lineHeight={!moldDetail?.customer ?  48 : 24}
                                       arrayData={Array.from(new Set(moldDetail?.customer?.map(( customer: { customerModelName: any; }) => customer?.customerModelName)))} bottomBorder={false}/>
                    <ListInfoComponent title={'자산구분'} value={dataValid(moldDetail?.assetType)} bottomBorder={false}/>
                    <ListInfoComponent title={'금형명'} value={tooltipModule(dataValid(moldDetail?.moldName))} bottomBorder={false}/>
                    <ListInfoComponent title={'규격'} value={dataValid(moldDetail?.specification)} bottomBorder={false}/>
                    <ListInfoComponent type={'unit'} title={'중량'} unit={'Kg'} value={dataValid(numberLocaleString(moldDetail?.weight))} bottomBorder={false}/>
                    <ListInfoComponent title={'재질'} value={dataValid(moldDetail?.material)} bottomBorder={false}/>
                    <ListInfoComponent type={'unit'} title={'캐비티'} value={dataValid(moldDetail?.cavity)} unit={'ea'} bottomBorder={false}/>
                    <ListInfoComponent title={'생산공정'} value={dataValid(moldDetail?.processName)} bottomBorder={false}/>

                    <ListInfoComponent type={'textClick'}
                                       title={t( '설비' )} value={t( '설비 보기' )}
                                       contentText={!moldDetail?.facility ? '-' : '설비 보기 '}
                                       disabled={!moldDetail?.facility}
                                       clickEvent={() => {setDataModal({menu:'facility'})}}
                                       bottomBorder={false}/>

                    <ListInfoComponent title={'공증유무'} value={dataValid(moldDetail?.certification)} bottomBorder={false}/>
                    <ListInfoComponent type={'unit'} title={'SPM'} value={dataValid(numberLocaleString(moldDetail?.spm))} bottomBorder={false} unit={'타'}/>
                    <ListInfoComponent title={'금액'} value={dataValid(numberLocaleString(moldDetail?.amount)) + '원'} bottomBorder={false}/>
                    <ListInfoComponent type={'unit'} title={'슬라이드 위치'} value={dataValid(moldDetail?.slideCounter)} bottomBorder={false} unit={'mm'}/>
                    <ListInfoComponent type={'unit'} title={'점검 타수'} value={dataValid(numberLocaleString(moldDetail?.checkStrokes))} bottomBorder={false} unit={'타'}/>
                    <ListInfoComponent type={'unit'} title={'점검 주기'} value={dataValid(numberLocaleString(moldDetail?.checkCycle))} bottomBorder={false} unit={'일'}/>
                    <ListInfoComponent type={'unit'} title={'최대 타수'} value={dataValid(numberLocaleString(moldDetail?.maxStrokes))} bottomBorder={false} unit={'타'}/>
                    <ListInfoComponent type={'unit'} title={'현재 타수'} value={dataValid(numberLocaleString(moldDetail?.currentStrokes))} bottomBorder={false} unit={'타'}/>
                    <ListInfoComponent type={'textClick'}
                                       title={t( '생산 품목' )} value={t( '생산 품목' )}
                                       contentText={!moldDetail?.productCode ? '-' : '품목 보기'}
                                       disabled={!moldDetail?.productCode}
                                       clickEvent={() => {
                                         moldProductViewRes(moldDetail?.moldCode).then()
                                         setDataModal( {menu: 'product'} )
                                       }}
                                       bottomBorder={false}/>
                  </div>
                  <ListInfoComponent type={'textarea'} height={'240px'} title={'비고'} value={dataValid(moldDetail?.notice, <NoData height={220}>{' * 작성된 내용이 없습니다.'}</NoData>)} bottomBorder={true}/>

                </div>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              <ImageContent>
                <div style={{display:'flex',justifyContent:'space-between'}}>
                  <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                  <div style={{color: '#7E84A3' , fontSize: '12px' , textAlign: 'right' , lineHeight: '16px' , height: '34px'}}>
                    <div>{t( '*최대 4개의 이미지 등록 가능' )}</div>
                    <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
                  </div>
                </div>

                <div style={{display: 'flex' , flexDirection: 'column' , margin: '20px 0'}}>
                  <div style={{fontSize: '14px' , fontWeight: '800' , marginBottom: '8px' , color: '#7E84A3'}}>{'상하형 조립 (전체사진)'}</div>
                  <div style={{display:"grid",gridTemplateColumns:'1fr 1fr'}}>
                    {moldDetail?.assemblyImagesUrl?.length !== 0 ?
                      moldDetail?.assemblyImagesUrl?.map((value:any,i:number)=>(
                        <PreviewImage imageUrl={value}
                                      clickEvent={()=> value && setDataModal({menu:'previewAssembly',index:i})}
                                      noneDelete={true} margin={16}/>
                      ))
                      :
                      <PreviewImage imageUrl={noImage} noneDelete={true}/>
                    }
                  </div>
                </div>
                <div style={{display: 'flex' , flexDirection: 'column' , margin: '20px 0'}}>
                  <div style={{fontSize: '14px' , fontWeight: '800' , marginBottom: '8px' , color: '#7E84A3'}}>{'상하형 분리 (내부사진)'}</div>
                  <div style={{display:"grid",gridTemplateColumns:'1fr 1fr'}}>
                    {moldDetail?.disassemblyImagesUrl?.length !== 0 ?
                      moldDetail?.disassemblyImagesUrl?.map((value:any,i:number)=>(
                        <PreviewImage imageUrl={value}
                                      clickEvent={()=> value && setDataModal({menu:'previewDisAssembly',index:i})}
                                      noneDelete={true} margin={16}/>
                      ))
                      :
                      <PreviewImage imageUrl={noImage} noneDelete={true}/>
                    }
                  </div>
                </div>
              </ImageContent>
            </div>


          </ContentFrame>
        </React.Fragment>
        :
        // 수정 페이지
        <React.Fragment>
          <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 금형 기본정보 > 수정'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'금형 기본정보 수정'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'관리대장 미리보기'} width={'136px'} clickEvent={() => {setDataModal( {menu: 'moldAsset'} )}}/>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={() => {setDataModal( {menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )}}/>
                      <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'} clickEvent={() => {setDataModal( {menu: 'delete' , message: '선택한 금형을 삭제하시겠습니까?'} )}}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   clickEvent={moldUpdateRes}/>
                    </div>
                  </div>
                </div>
                <div className={'scroll_func'}>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 392px)'}}>
                  <RegisterComponent type={'text'} title={'제작처'} name={'manufacturer'} value={moldDetail?.manufacturer}
                                     paddingRight={true} required={false} onChange={onChange} placeholder={'제작처를 입력해주세요.'}/>
                  <RegisterComponent type={'calendar'} name={'productionDate'} selectDate={moldDetail?.productionDate}
                                     value={moldDetail?.productionDate} title={'제작일'} top={-200} tabIndex={-1}
                                     onChange={(v)=> setMoldDetail((prev:any)=>({...prev, productionDate: dayjs(v).format('YYYY-MM-DD')}))}
                  />
                  <RegisterComponent type={'doubleCheck'} title={'금형 CODE'} name={'moldSerialCode'}
                                     value={moldDetail.moldSerialCode} required={true} buttonName={moldDetail?.codeDoubleCheck ? '확인됨' : '중복확인'}
                                     paddingRight={true} disabled={moldDetail.codeDoubleCheck}
                                     clickEvent={() => {serialDoubleCheck( moldDetail.moldSerialCode ).then()}}
                                     onChange={(e)=>setMoldDetail((prev:any)=>({...prev, moldSerialCode: e.target.value.trim(), codeDoubleCheck: false}))}
                  />

                  <RegisterComponent type={'textButton'} name={'productName'} value={dataValid(selectProductData.productName)} width={'132px'}
                                     title={t('품목명')} placeholder={'품목을 선택해주세요.'} buttonWidth={'60px'} buttonMargin={'0'}
                                     clickEvent={()=>{setDataModal({menu:'productSelect'})}}/>

                  <ListInfoComponent type={'addition'} width={'192px'} title={'거래처'}
                                     lineHeight={(!moldDetail?.customer || moldDetail?.customer?.length === 1) ?  48 : 24}
                                     arrayData={Array.from(new Set(moldDetail.customer?.map(( customer: { customerName: any; }) => customer.customerName)))}
                                     value={''}
                  />
                  <ListInfoComponent type={'addition'} width={'192px'} title={'모델'}
                                     lineHeight={(!moldDetail?.customer || moldDetail?.customer?.length === 1) ?  48 : 24}
                                     arrayData={Array.from(new Set(moldDetail.customer?.map(( customer: { customerModelName: any; }) => customer.customerModelName)))}
                                     value={''}
                  />

                  <RegisterComponent type={'text'} title={'자산구분'} name={'assetType'} value={moldDetail?.assetType} width={'192px'}
                                     required={false} onChange={onChange} placeholder={'자산구분을 입력해주세요.'}/>
                  <RegisterComponent type={'text'} title={'금형명'} name={'moldName'} value={moldDetail?.moldName} limit={100}
                                     paddingRight={true} required={true} onChange={onChange} placeholder={'금형명을 입력해주세요.'}/>
                  <RegisterComponent type={'text'} title={'규격'} name={'specification'} value={moldDetail?.specification}
                                     required={false} onChange={onChange} width={'192px'} placeholder={'규격을 입력해주세요.'}/>

                  <RegisterComponent type={'unit'} name={'weight'} title={'중량'} paddingRight={true} value={numberLocaleString(moldDetail?.weight)}
                                     unit={'Kg'}  limit={11} onChange={(e)=>{onChange(e,'number')}}/>
                  <RegisterComponent type={'text'} title={'재질'} name={'material'} value={moldDetail?.material}
                                     paddingRight={true} placeholder={'재질을 입력해주세요.'}
                                     required={false} onChange={onChange}/>


                  <RegisterComponent type={'unit'} title={'캐비티'} name={'cavity'} unit={'ea'} value={numberLocaleString(moldDetail?.cavity)}
                                     paddingRight={true} limit={11} onChange={(e)=>{onChange(e,'number')}} required={true}/>
                  {/*추후에 들어갈예정*/}
                  <ListInfoComponent title={'생산 공정'} value={dataValid(moldDetail?.processName)} bottomBorder={false}/>
                  {/*<RegisterComponent type={'search'} title={'생산 공정'} name={'process'} value={moldDetail?.processName} paddingRight={true}*/}
                  {/*                   disabled={true} clickEvent={() => {setDataModal( {menu: 'process'} )}}/>*/}
                  {/*추후에 들어갈예정*/}

                  <ListInfoComponent type={'textClick'} title={t( '설비' )} value={t( '설비 보기' )} contentText={!moldDetail?.facility ? '-' : '설비 보기'} disabled={true}
                                     bottomBorder={false}/>

                  {/*<ListInfoComponent title={'설비'} value={'-'} bottomBorder={false}/>*/}
                  {/*<RegisterComponent type={'search'} title={'설비'} name={'facility'} value={moldDetail?.facility}*/}
                  {/*                   width={'192px'} paddingRight={true} disabled={true} clickEvent={() => {setDataModal( {menu: 'facility'} )}}/>*/}

                  <RegisterComponent type={'drop'} title={'공증유무'} name={'certification'} value={moldDetail?.certification}
                                     paddingRight={true} dropWidth={'192px'}
                                     dropClickEvent={( v ) => {setMoldDetail((prev:any) => ({...prev, certification:v}))}}
                                     dropArray={['선택 없음' , '유' , '무']}/>
                  <RegisterComponent type={'unit'} name={'spm'} title={'SPM'} paddingRight={true} value={numberLocaleString(moldDetail?.spm)}
                                     unit={'타'}  limit={11} onChange={(e)=>{onChange(e,'number')}}/>
                  <RegisterComponent type={'unit'} title={'금액'} name={'amount'} unit={'원'} value={numberLocaleString(moldDetail?.amount)}
                                     paddingRight={true} limit={11} onChange={(e)=>{onChange(e,'number')}}/>

                  <RegisterComponent type={'unit'} title={'슬라이드 위치'} name={'slideCounter'} placeholder={'-'}
                                     unit={'mm'} value={moldDetail?.slideCounter} paddingRight={true} limit={15}
                                     onChange={(e)=>{onChange(e,'decimal')}}/>


                  <RegisterComponent type={'unit'} title={'점검 타수'} name={'checkStrokes'} unit={'타'} value={numberLocaleString(moldDetail?.checkStrokes)}
                                     paddingRight={true} limit={11} onChange={(e)=>{onChange(e,'number')}}/>
                  <RegisterComponent type={'unit'} title={'최대 타수'} name={'maxStrokes'} unit={'타'} value={numberLocaleString(moldDetail?.maxStrokes)}
                                     paddingRight={true} limit={11} onChange={(e)=>{onChange(e,'number')}}/>
                  <RegisterComponent type={'unit'} title={'점검 주기'} name={'checkCycle'} unit={'일'} value={numberLocaleString(moldDetail?.checkCycle)}
                                     paddingRight={true} limit={5} onChange={(e)=>{onChange(e,'number')}}/>
                  <RegisterComponent type={'unit'} title={'현재 타수'} name={'currentStrokes'} unit={'타'} value={numberLocaleString(moldDetail?.currentStrokes)}
                                     paddingRight={true} limit={11} onChange={(e)=>{onChange(e,'number')}}/>
                  <ListInfoComponent type={'textClick'} width={'192px'}
                                     title={t( '생산 품목' )} value={t( '생산 품목' )} disabled={!moldDetail?.productCode || selectPage === 1}
                                     buttonName={t( '생산품목 등록' )}
                                     contentText={!moldDetail?.productCode ? t( '-' ) : '품목 보기'}
                                     clickEvent={() => {
                                       setDataModal( {menu: 'product' , index: 0} )
                                     }}/>
                </div>
                  <RegisterComponent type={'textarea'} title={'비고'} name={'notice'} value={moldDetail?.notice}
                                     bottomBorder={true} limit={2000} onChange={(e)=>{onChange(e,'' ,true)}}/>
                </div>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
                <ImageContent>
                  <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                    <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                    <div style={{color: '#7E84A3' , fontSize: '12px' , textAlign: 'right' , lineHeight: '16px' , height: '34px'}}>
                      <div>{t( '*최대 2개의 이미지 등록 가능' )}</div>
                      <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
                    </div>
                  </div>
                  <div style={{display: 'flex' , flexDirection: 'column' , margin: '20px 0'}}>
                    <div style={{fontSize: '14px' , fontWeight: '800' , marginBottom: '8px' , color: '#7E84A3'}}>{'상하형 조립 (전체사진)'}</div>
                    <TotalImageUploadBox imageKeyArray={(data)=>(setImageKeyState((prev:any) => ({...prev, assemblyImages:data})))}
                                         imageUrlArray={(data)=>(setImageKeyState((prev:any) => ({...prev, assemblyImagesUrl:data})))}
                                         imageLength={2}
                                         profileSelect={false} receiveUrl={dataValid(moldDetail?.assemblyImagesUrl,[])}
                                         receiveKey={dataValid(moldDetail?.assemblyImages,[])}/>
                  </div>
                  <div style={{display: 'flex' , flexDirection: 'column' , margin: '40px 0px'}}>
                    <div style={{fontSize: '14px' , fontWeight: '800' , marginBottom: '8px' , color: '#7E84A3'}}>{'상하형 분리 (내부사진)'}</div>
                    <TotalImageUploadBox imageKeyArray={(data)=>(setImageKeyState((prev:any) => ({...prev, disassemblyImages:data})))}
                                         imageUrlArray={(data)=>(setImageKeyState((prev:any) => ({...prev, disassemblyImagesUrl:data})))}
                                         imageLength={2} profileSelect={false}
                                         receiveUrl={dataValid(moldDetail?.disassemblyImagesUrl,[])} receiveKey={dataValid(moldDetail?.disassemblyImages,[])}/>
                  </div>
                </ImageContent>
            </div>
          </ContentFrame>
        </React.Fragment>}


    </SectionFrame>
  );
};
export default MoldDetail;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}


  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`

const ImageContent = styled.div`
    width: 100%;
    height:620px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      display: block;
      width: 16px;
    }

  .maintenanceItem{
    display: flex;
    align-items:center;
    margin-bottom: 16px;
    height:32px;
    font-weight: bold;
    margin-top: 32px;
  }
`
