import React , {useEffect} from 'react';
import ModalBase from "./ModalBase";
import styled from "styled-components";
import {TransformComponent , TransformWrapper} from "react-zoom-pan-pinch";
import MainButtons from "../button/MainButtons";
import TotalImageUploadBox from "../input/TotalImageUploadBox";
import {NoData} from "../../style/StyleComponent";
import client from "../../common/Config";
import {
  buttonAuthValid ,
  closeModal ,
  dataValid ,
  initModal ,
  listLoadingCircle ,
  loadingRemove
} from "../../function/function";
import {ModalType} from "../../types/Fundamental";
import SelectConfirmModal from "./SelectConfirmModal";
import {Authorization} from "../../common/authorization";
import ConfirmPopup from "./ConfirmPopup";
import {useTranslation} from "react-i18next";
import ToastPopup from "./ToastPopup";

interface Props {
  authValid: boolean
  clickEvent:(data:{key: string[], url:string[]})=>void

  cancelEvent: ()=>void
  receiveKey?: string[]
  receiveUrl?: string[]
  viewType?: boolean
  //현재 작업표준서 리스트 에서만 구분지음
  register?: boolean
  productCode?: string
  // 이미지 팝업 타이틀
  title?: string
  workListDeleteView?:boolean
  workListUpdateView?:boolean
  workImgLength?:boolean
}

const ListImageUploadModal:React.FunctionComponent<Props> = ({ authValid, register,productCode,
                                                               receiveKey, viewType, title,workListUpdateView,workListDeleteView,
                                                               receiveUrl,clickEvent, cancelEvent,workImgLength}) => {

  const [imageKey, setImageKey] = React.useState<string[]>(receiveKey??[])
  const [imageUrl, setImageUrl] = React.useState<string[]>(receiveUrl??[])
  const [selectImage, setSelectImage] = React.useState<number>(0)
  const [modalState, setModalState] = React.useState<ModalType>(closeModal)
  const [viewTypeChange, setViewTypeChange] = React.useState<boolean>(viewType ?? false)
  const {t}:any= useTranslation()
  const validTitleStyle = {
    backgroundColor : authValid? '#CFFA61' : '#0058ff',
    color : authValid? '#171725' : '#fff',
    fontWeight : 700,
    lineHeight:'56px',
    fontSize:'18px',
    paddingLeft:'16px',
  }

  //삭제 API 불안정함. 현재 사용못함.
  const workStandardApi = async (type: string, update?: boolean) => {
    setModalState(closeModal)
    listLoadingCircle()

    const typeText = (type:string) => {
      switch (type){
        case 'register': return '등록'
        // case 'update': return '수정'
        case 'delete': return '삭제'
      }
    }

    try {
      const response = await client.post(type === 'delete'? `/workStandard/${type}/${productCode}` : `/workStandard/${type}`,
                                         type === 'delete'? {productCode: productCode} :
                                           {workStandardImg: dataValid(imageKey,[]), productCode: productCode},
                                         {headers:{Authorization}})
      if(response.data.status === 200){
        loadingRemove(200)
        setTimeout(()=>{
          setModalState({open: true, status:'success', message:`작업표준서가 ${update? '수정' : typeText(type)} 되었습니다.`})
        },500)
      }else{
        setModalState({open: true, status:'confirm', message:response.data.message})
        loadingRemove()
      }
    }catch (e:any) {
      setModalState({open: true, status:'confirm', message:e.message})
      loadingRemove(300)
    }
  }
  // image 빈값으로 넣어 덮음으로써 삭제기능과 동일하도록 추가.
  const workStandardDelete = async () => {
    setModalState(closeModal)
    listLoadingCircle()

    try {
      const response = await client.post(`/workStandard/register`, {
        workStandardImg: [], productCode: productCode}, {
        headers:{Authorization}})
      if(response.data.status === 200){
        loadingRemove(200)
        setTimeout(()=>{
          setModalState({open: true, status:'success', message:`작업표준서가 삭제 되었습니다.`})
        },500)
      }else{
        setModalState({open: true, status:'confirm', message:response.data.message})
        loadingRemove()
      }
    }catch (e:any) {
      console.log(e)
      setModalState({open: true, status:'confirm', message:e.message})
      loadingRemove(300)
    }
  }

  // useEffect(() => {
  //   const preventClose = (e: BeforeUnloadEvent) => {
  //     e.preventDefault();
  //     e.returnValue = true;
  //   };
  //   window.addEventListener(  "beforeunload" , preventClose );
  //   return () => {
  //     window.removeEventListener( "beforeunload" , preventClose );
  //   };
  // }, []);
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {modalState.status === 'success' ? <ToastPopup text={t(modalState.message)} state={'success'} closeEvent={()=>{
            if(modalState.message.includes('작업표준서가')){
              // window.location.reload()
              setViewTypeChange(true)
            }
            if(modalState.message.includes('등록')){
              window.location.reload()
            }
            if(modalState.message.includes('삭제')){
              window.location.reload()
            }
            setModalState( closeModal )}}/>
            : modalState.status === 'error' ? <ToastPopup text={t(modalState.message)} state={'error'} closeEvent={()=>{setModalState(closeModal)}}/>
              : modalState.status === 'warn' ? <ToastPopup text={t(modalState.message)} state={'warn'} closeEvent={()=>{setModalState(closeModal)}}/> : null}
          {modalState.status === 'confirm' &&
              <ConfirmPopup statusText={modalState.message}
                            clickEvent={()=> {
                              if(modalState.message.includes('작업표준서가')){
                                // window.location.reload()
                                setViewTypeChange(true)
                              }
                              if(modalState.message.includes('등록')){
                                window.location.reload()
                              }
                              if(modalState.message.includes('삭제')){
                                window.location.reload()
                              }
                              setModalState( closeModal )}}/>}
          {modalState.status === 'select' &&
              <SelectConfirmModal statusText={modalState.message}
                                  cancelEvent={()=>setModalState(closeModal)}
                                  clickEvent={()=>workStandardDelete().then()}
                                  noticeText={'*삭제된 데이터는 복구 되지 않습니다.'}/>}

          {modalState.status === 'empty' &&
              <SelectConfirmModal statusText={modalState.message}
                                  cancelEvent={()=>setModalState(closeModal)}
                                  clickEvent={()=>{
                                    clickEvent( {key: [] , url: []});
                                    cancelEvent();}}
                                  noticeText={''}/>}

          <BaseComponent scrollView={imageUrl.length > 0}>
            <div style={{...validTitleStyle, boxSizing:'border-box',}} className={'modal_title'}>
              {productCode ? viewTypeChange ? t('작업표준서 미리보기') : t('작업표준서 등록하기') : dataValid(t(title), t('이미지 등록하기'))}
            </div>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between', backgroundColor:'#fff', height:'672px',boxSizing:'border-box', padding:'16px 16px 24px'}}>

             <div style={{display:'flex'}}>
                <div>
                  {productCode &&
                  <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'8px'}}>

                    {viewTypeChange && (receiveKey?.length !== 0 ?
                    <MainButtons name={'수정'} disabled={workListUpdateView} clickEvent={()=>
                      setViewTypeChange(false)
                    }/>
                      :
                    <MainButtons name={'등록'} disabled={dataValid(imageKey?.length, 0) === 0} marginDis={true}
                                 clickEvent={()=>workStandardApi('register').then()}/>)}


                    <MainButtons name={'삭제'} buttonType={'cta'} marginDis={true}
                                 disabled={workListDeleteView ||dataValid(imageKey?.length, 0) === 0}
                                 clickEvent={()=>setModalState({open:true, status:'select',message:'작업표준서를 전부 삭제 하시겠습니까?'})}/>
                  </div>
                  }
                  <TransformWrapper>
                    <TransformComponent wrapperStyle={{border:'1px solid #d5d7e3', borderRadius:'6px'}}>
                      {imageUrl.length === 0?
                      <NoData style={{width:'800px', height:'542px'}}>{t('* 이미지를 등록해 주세요.')}</NoData>
                        :
                      <img className={'img_select'} src={imageUrl[selectImage]} width={800} height={542}
                           style={{objectFit: 'contain'}} alt={''}/>}
                    </TransformComponent>
                  </TransformWrapper>
                </div>
                <div className={'imageList'}>
                  <div style={{fontSize:'14px', fontWeight:800}}>{t(title)?.includes('작업표준서') || productCode? t('작업표준서') : t('이미지 목록')}</div>
                  <div style={{marginTop:'8px'}}>
                    <TotalImageUploadBox imageKeyArray={setImageKey} imageUrlArray={setImageUrl} verticalMode={true}
                                         receiveKey={imageKey} receiveUrl={imageUrl} disableDelete={viewTypeChange}
                                         imageLength={viewTypeChange ? 0 : workImgLength ? 6 : 4} noModal={true}
                                         profileSelect={false} onClick={setSelectImage} />
                  </div>
                </div>
             </div>


              <div style={{display:'flex', justifyContent:'center'}}>
                {/*{productCode? <MainButtons buttonType={'popupCta'} width={'114px'} name={'확인'} clickEvent={cancelEvent}/> :*/}
                {register ?
                  <React.Fragment>
                    <MainButtons width={'114px'} name={'닫기'}
                                 clickEvent={cancelEvent}/>
                    <MainButtons buttonType={'popupCta'} width={'114px'} name={'확인'} disabled={dataValid( imageKey?.length , 0 ) === 0 }
                                           clickEvent={()=>workStandardApi('register')}/>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <MainButtons width={'114px'} name={viewTypeChange ? '닫기' : '취소'}
                                 clickEvent={viewTypeChange ? ()=>
                                 {clickEvent( dataValid( imageKey?.length , 0 ) !== 0 ?{key: viewType ? imageKey : imageKey?.filter( Boolean ) , url: imageUrl} : {key: [] , url: []} );
                                   cancelEvent();} : !viewType? cancelEvent : () => setViewTypeChange( true )}/>

                    {!viewTypeChange && productCode ? <MainButtons buttonType={'popupCta'} width={'114px'} name={'확인'}
                                                                   clickEvent={()=>workStandardApi('register',true)}/> :
                      !viewTypeChange &&
                        <MainButtons buttonType={'cta'} width={'114px'} name={'확인'} clickEvent={() => {
                          if( dataValid( imageKey?.length , 0 ) === 0 ){
                            setModalState( {open: true , status: 'empty' , message: '등록된 이미지가 없습니다. \n 계속 진행 하시겠습니까?'} )
                          } else{
                            clickEvent( {key: imageKey.filter( Boolean ) , url: imageUrl} );
                            cancelEvent();
                          }
                        }}/>}
                  </React.Fragment>
                }

              </div>

            </div>
          </BaseComponent>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default ListImageUploadModal;

const BaseComponent = styled.div<{scrollView: boolean}>`
  width: 1032px;
  height: 728px;
  border-radius: 6px;
  overflow: hidden;
  
  .imageList{
    margin-left: 16px;
    max-height: 560px;
    min-width: 178px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: ${props => props.scrollView? 'block' : 'none' };
    }

    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-track {
      width: 10px;
      height: 8px;
      border-radius: 10px;
      background: #F5F5F5;
    }
  }
  
  
`