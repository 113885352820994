import React,{useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
  listResultMessage ,
  orderComponent , tooltipModule ,
} from "../../style/StyleComponent";
import {MoldInfoType} from "../../types/Fundamental";
import {
  authValid ,
  dataValid ,
  loadingRemove ,
  locationFilter ,
  modalListLoading ,
  numberLocaleString
} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import MainButtons from "../button/MainButtons";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import TableValue from "../../style/TableValue";
import { useTranslation } from 'react-i18next';
import ListInnerInput from "../input/ListInnerInput";
import PaginationList from "../pagination/PaginationList";
import {AutoTextSize} from "auto-text-size";
import FilterWithSearchBar from "../input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface MoldModalProps {
  companyCode: string
  viewType?: boolean
  cancelEvent: ()=>void
  selectProduct: any
  selectedMold?: string[]
  receivedMold:(data:any[])=>void
  includedSerialArray?: any[]
  receiveRequireSerial?: boolean
  modified?: boolean
  moldFirstCavity?: (cavity:number)=>void
  allUsed?: boolean
}

const MoldRegisterModal:React.FunctionComponent<MoldModalProps> = ({companyCode, selectedMold, receivedMold, receiveRequireSerial,includedSerialArray,
                                                                     selectProduct, cancelEvent, viewType, modified,allUsed}) => {
  const [page, setPage] = useState<number>(1)
  const [pageBlock, setPageBlock] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [moldListData, setMoldListData] = useState<Readonly<MoldInfoType[]>>([])
  const [checkedList, setCheckedList] = useState<string[]>(JSON.parse(JSON.stringify(selectedMold))? JSON.parse(JSON.stringify(selectedMold)) : [])
  const [selectMoldData, setSelectMoldData] = useState<any[]>(dataValid(includedSerialArray,[]))
  const [searchInput,setSearchInput] = useState<{input:string,value:string}>({input:'',value:''})
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const selectCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  const {t}:any =useTranslation()

  const onSelectedList = (select:boolean, value:any) =>{
    if(select){
      if(receiveRequireSerial){
        setSelectMoldData([...selectMoldData, {moldCode: value.moldCode, moldSerialCode: value.moldSerialCode}])
      }
      else{
        setCheckedList((prev)=>([...prev, value.moldCode]))
      }
    }else{
      if(receiveRequireSerial){
        setSelectMoldData(selectMoldData.filter(el=> el.moldCode !== value.moldCode));
      }
      else{
        setCheckedList(checkedList.filter(el => el !== value.moldCode))
      }
    }
  }

  const moldListRes = async () => {
    try {
      modalListLoading()
      const response = await client.get(`/mold/list/product/${page}/5/${companyCode}/${!viewType? '@' : dataValid(selectProduct.productCode, '@')}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setMoldListData((prev:any)=> prev.concat(data.row))
      // }else{
      //   setMoldListData(data.row)
      // }
      setMoldListData(data.row)
      // viewType && setPage(page+1)
      loadingRemove()
    }catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  // const moldSearchRes = () => ListSearchPostCustomHooks(`/mold/search` , page , setTotalPage , setPageBlock , setMoldDataList , setErrorState , {page: page , size: 10 , keyword: pushValue , companyCode: selectedCompany.companyCode})
  const moldSearchList = async () => {
    try {
      modalListLoading()
      const response = await client.post(`/mold/search`,{
        companyCode:companyCode,
        filter:categorySelect.type,
        keyword:dataValid(searchInput.value,'@'),
        size: 5,
        page: page,
      },{headers: {Authorization}})
      const data = response.data.data
      setTotalPage(data.total_page)
      setMoldListData(data.row)
      // if(data.total_page > 1 && page > 1){
      //   setMoldListData((prev:any)=> prev.concat(data.row))
      // }else{
      // }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  React.useEffect(()=>{
    if(viewType){
      if(totalPage >= page){
        moldListRes().then()}
    }else{
      if(searchInput.value === ''){
        moldListRes().then()
      }else{
        moldSearchList().then()
      }
    }
  },[page,searchInput.value,categorySelect.type])

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          <BaseComponent>
            <div style={{backgroundColor:window.localStorage.getItem('auth')?.includes('admin')  ? '#CFFA61' : '#0058ff',
              color: window.localStorage.getItem('auth')?.includes('admin')  ? '#171725' : '#fff'
            }} className={'modal_title'}>
              <div>{t(viewType)?t('금형 보기') : t('금형 등록')}</div>
              {!viewType && <div>{t('(해당 제품 생산시 사용하는 금형를 등록해주세요.)')}</div>}
            </div>
            <div className={'modal_content'}>
              <div>
                {
                  authValid()?
                    <ModalInfoTable>
                      {orderComponent(t('업체명'),selectCompany.companyName)}
                      {orderComponent(t('지역'),locationFilter(selectCompany.address))}
                      {orderComponent(t('업체코드'),selectCompany.companyCode)}
                      {orderComponent(t('단위'),selectProduct.unit)}
                      {orderComponent(t('모델'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerModelName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                      {orderComponent('CODE',tooltipModule(dataValid(selectProduct.serialCode || selectProduct.productSerialCode)))}
                      {orderComponent(t('품명'),tooltipModule(dataValid(selectProduct.productName)))}
                      {orderComponent(t('품목 종류'),selectProduct.productType || selectProduct.type)}
                    </ModalInfoTable>
                    :
                    <ModalInfoTable>
                      {orderComponent(t('거래처'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                      {orderComponent(t('모델'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerModelName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                      {orderComponent('CODE',tooltipModule(dataValid(selectProduct.serialCode || selectProduct.productSerialCode)))}
                      {orderComponent(t('품명'),tooltipModule(dataValid(selectProduct.productName)))}
                      {orderComponent(t('단위'),selectProduct.unit)}
                      {orderComponent(t('품목 종류'),selectProduct.productType || selectProduct.type)}
                      {orderComponent(t('생산 공정'),selectProduct.processName)}
                      {orderComponent(t('캐비티'),selectProduct.cavity)}
                    </ModalInfoTable>
                }

                <div style={{display:'flex', justifyContent:'space-between',marginTop:'16px',width:'1000px'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{fontSize:'18px', fontWeight:800, marginRight:'8px'}}>{t('금형 리스트')}</div>
                    {!viewType &&
                        <div style={{display:'flex', fontSize:'14px', fontWeight:800, alignItems:'center'}}>
                          {t('(선택된 금형 수:')}
                          <div style={{color:'#0058ff', textDecoration:'underline', textUnderlineOffset:'3px', margin:'0 4px'}}>
                            {receiveRequireSerial? (selectMoldData?.length) : (checkedList.length)}
                          </div>
                          {t('개)')}
                        </div>
                    }
                  </div>


                  {!viewType &&
                      <FilterWithSearchBar margin={'0'} keyFunction={(e)=> setSearchInput((prev)=> ({...prev, input: e.target.value}))}
                                           clearButtonAccess={searchInput.input !== ''}
                                           clearButton={()=>{setSearchInput({value:'',input:''})}}
                                           value={searchInput.input}
                                           clickEvent={()=> {setSearchInput((prev) => ( {...prev , value: prev.input} )); setPage(1)}}
                                           selectKeyword={categorySelect.value}
                                           categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                           categoryTypeArray={['@','mold_serial_code','mold_name']}
                                           categoryValueArray={['전체','금형 CODE','금형명']}
                                           placeholder={t('검색')}/>
                  }

                  {/*<SearchBar margin={0} disabled={true}/>*/}
                  {/*{!viewType && <MainButtons name={'선택 초기화'} marginDis={true}*/}
                  {/*                           clickEvent={()=> {*/}
                  {/*                             setSelectMoldData([])*/}
                  {/*                             setCheckedList([])}}/>}*/}
                </div>

                {/*<InfiniteScroll scrollableTarget={'grid-scroll'}*/}
                {/*                next={()=>setPage((prev)=> prev+1)}*/}
                {/*                hasMore={totalPage > page}*/}
                {/*                dataLength={dataValid(moldListData?.length,0)}*/}
                {/*                loader={<div/>}>*/}
                <GridTableComponent tempCol={'60px 130px 1fr repeat(4, 130px)'} scrollWidth={1000}
                                    height={290} scrollY_Hide={true}
                                    scrollX_Hide={true} id={'grid-scroll'}>
                  <div className={'grid-title-unchecked'}>
                    {['NO.','금형 CODE','금형명','캐비티','SPM','슬라이드 위치','현재타수'].map((v,i)=>(
                      <TableValue title={v} key={i} requiredValue={['']}/>
                    ))}
                  </div>
                  {
                    viewType?
                      //@ts-ignore
                      moldListData?.filter((v)=>(selectedMold?.includes(v.moldCode)))?.length === 0?
                        listResultMessage(true, '* 등록된 정보가 없습니다.',{width:1000, admin:192, user:192})
                        :
                        // 금형 보기
                        //@ts-ignore
                        moldListData?.filter((v)=>(selectedMold?.includes(v.moldCode)))?.map((value,index)=>(
                          <GridTableSelect selected={false}
                                           className={'grid-list-unchecked-no_icon'} key={value.moldCode}>
                            <div>{index+1}</div>

                            <div>
                              <AutoTextSize maxFontSizePx={14} minFontSizePx={10}>
                                {dataValid(value.moldSerialCode)}
                              </AutoTextSize>
                            </div>
                            {/*<div>*/}
                            {/*  <AutoTextSize maxFontSizePx={14} minFontSizePx={12}>*/}
                            {/*    {dataValid(value.moldName)}*/}
                            {/*  </AutoTextSize>*/}
                            {/*</div>*/}
                            {tooltipModule( dataValid( value.moldName ) , 10 )}
                            <ListInnerInput type={'noneInputUnit'} unit={'ea'} inputData={numberLocaleString(value.cavity)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'타'} inputData={numberLocaleString(value.spm)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={numberLocaleString(value.slideCounter)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'타'} inputData={numberLocaleString(value.currentStrokes)}/>
                          </GridTableSelect>
                        ))
                      :
                      //금형 선택(등록)
                      moldListData?.length === 0?
                        listResultMessage(true, '* 등록된 정보가 없습니다.',{width:1000, admin:192, user:192})
                        :
                        moldListData?.map((value,index)=>(
                          <GridTableSelect selected={
                            receiveRequireSerial?
                              selectMoldData?.map((v:any)=>(v.moldCode))?.includes(value.moldCode)
                              :
                            checkedList?.map((v:any)=>(v))?.includes(value.moldCode)
                          }
                            // style={{transform: `translateY(${-index}px)`}}
                                           disabled={allUsed? false :
                                             modified? dataValid(selectProduct?.moldCode,[])?.map((v:any)=>(v))?.includes(value?.moldCode)? false : !value.use : !value.use}
                                           onClick={()=>{
                                             onSelectedList(
                                               receiveRequireSerial?
                                                 !selectMoldData?.map((v:any)=>(v.moldCode))?.includes(value.moldCode)
                                                 :
                                                 !checkedList?.map((v:any)=>(v))?.includes(value.moldCode)
                                               , value)}}
                                           className={'grid-list-unchecked-no_icon'} key={value.moldCode}>
                            {/*<div>{index+1}</div>*/}
                            <div>{( ( index + 1 ) + page * 5 ) - 5}</div>
                            {/*{validationValue(value.moldSerialCode)}*/}
                            <div>
                              <AutoTextSize maxFontSizePx={14} minFontSizePx={10}>
                                {dataValid(value.moldSerialCode)}
                              </AutoTextSize>
                            </div>
                            <div>
                              <AutoTextSize maxFontSizePx={14} minFontSizePx={12}>
                                {dataValid(value.moldName)}
                              </AutoTextSize>
                            </div>
                            <ListInnerInput type={'noneInputUnit'} unit={'ea'} inputData={numberLocaleString(value.cavity)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'타'} inputData={numberLocaleString(value.spm)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={numberLocaleString(value.slideCounter)}/>
                            <ListInnerInput type={'noneInputUnit'} unit={'타'} inputData={numberLocaleString(value.currentStrokes)}/>
                          </GridTableSelect>
                        ))
                  }
                </GridTableComponent>
                {/*</InfiniteScroll>*/}
                <div style={{padding:'8px 0 16px', borderTop:'1px solid #d7dbec'}}>
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage} justify={'center'}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage}/>
                </div>
              </div>

              <div style={{display:'flex', justifyContent:'center',marginTop:'16px'}}>
                {!viewType?
                  <React.Fragment>
                    <MainButtons name={'취소'} width={'144px'} margin={'0 12px'} clickEvent={cancelEvent}/>
                    <MainButtons name={'선택'} width={'144px'} margin={'0 12px'}
                                 // disabled={
                                   // !receiveRequireSerial? (selectMoldData?.length === 0) : (selectMoldData?.length === 0)
                                 // }
                                 clickEvent={()=> {
                      if(receiveRequireSerial){
                        receivedMold(selectMoldData)
                      }
                      else{
                        receivedMold(checkedList);
                      }
                      cancelEvent()}} buttonType={'popupCta'}/>
                  </React.Fragment>
                  :
                  <MainButtons name={'확인'} width={'144px'} buttonType={'popupCta'} clickEvent={cancelEvent}/>}
              </div>
            </div>
          </BaseComponent>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default MoldRegisterModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 632px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;

  .modal_title {
    display: flex;
    gap: 8px;
    background-color: #CFFA61;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    &>div:nth-child(1) {
      font-size: 18px;
      font-weight: 800;
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      color: rgba(23, 23, 37, 0.5);
    }
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
  }
`

const ModalInfoTable =  styled.div`
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns: repeat(4, 104px 146px);
  align-items: center;
  box-sizing: border-box;
  .order{
    &_title{
      height: 32px;
      background-color: #F5F6FA;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }
    &_content{
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 32px;
      padding-left: 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
      white-space: pre;
    }
  }
`

