import React from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import MenuTitle from "../../../../components/title/MenuTitle";
import {ContentFrame , NoData , SectionFrame} from "../../../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import PartnerFacility from "./PartnerFacility";
import {LoadDetailData} from "../../../../CustomHooks";
import MainButtons from "../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove ,
  modalListLoading
} from "../../../../function/function";
import DetailImageView from "../../../../components/modal/DetailImageView";
import PreviewImage from "../../../../components/display/PreviewImage";
import PasswordChangeModal from "../../../../components/modal/PasswordChangeModal";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import ToastPopup from "../../../../components/modal/ToastPopup";
import PasswordCheckModal from "../../../../components/modal/PasswordCheckModal";
import FindPasswordModal from "../../../../components/modal/FindPasswordModal";
const PartnerDetail = () => {

  const locationID = useParams()
  const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
  const detailData:any = LoadDetailData('/admin/detail',{id:locationID.id})
  const [adminPassword,setAdminPassword] = React.useState<string>('');
  const [emailStatus,setEmailStatus] = React.useState<string>('');

  const [password,setPassword] = React.useState<boolean>(false);
  const { t }:any = useTranslation();

  const detailComponent = (title: string, content: string|number|any) => {
    return (
      <div style={{display:'flex'}}>
        <div className={'detail_title'}>{title}</div>
        <div className={'detail_content'}>{dataValid(content)}</div>
      </div>
    )
  }
  const adminPasswordModifyRes = async () =>{
    try {
      dataAccessLoading()
      const response = await client.post('/admin/update',{
        ...detailData,
        adminName:detailData.companyName,
        password:adminPassword
      },{headers:{Authorization}})
      const data = response.data
      console.log('완료')
      if(data.message !== 'success'){
        setDataModal({menu:'confirm',message:data.message});
        loadingRemove()
      } else{
        setTimeout(()=>{
          loadingRemove()
          setDataModal({menu:'success',message:t('비밀번호가 변경 되었습니다.')});
        },500)
      }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  //이메일보내는 테스트
  const sendEmailRes = async () => {
    dataAccessLoading();
    setDataModal(initModal)
    // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
    setTimeout(() => {
      setPassword(true)
      loadingRemove();
      // 작업 완료 시 호출할 콜백 함수
    }, 1000);
  };


  return (
    <SectionFrame>
          <React.Fragment>

            {dataModal.menu === 'success' ?
              <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal)}}/>
              : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
                : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}



            {dataModal.menu === 'profile' && <DetailImageView image={detailData.imageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}
            {dataModal.menu === 'logo' && <DetailImageView image={detailData.logoImageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}
            {dataModal.menu === 'pw' &&
                <PasswordChangeModal detailInfo={detailData} viewType={true}
                                     receivePassword={(data)=>{setAdminPassword(data)}}
                                     clickEvent={adminPasswordModifyRes}
                                     closeClick={()=>{setDataModal(initModal)}}/>
            }
            {dataModal.menu === 'check' &&
                  <PasswordCheckModal detailInfo={detailData} cancelEvent={()=>{setDataModal(initModal)}}
                                      viewType={true}
                                      movePage={`/admin/fas/partners/modify/${detailData.id}`}
                                      noticeMessage={'* 회원정보 수정을 위해 비밀번호를 확인해주세요.'}/>
            }

            {/*{dataModal.menu === 'findPw' &&*/}
            {/*    <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
            {/*}*/}
            {/*{password &&*/}
            {/*    <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
            {/*}*/}

            <MenuTitle title={'지사/협력사 관리 > 조회'} unitCase={'MenuTitle'}/>
            <ContentFrame>
              <div style={{display:'flex',userSelect:'none'}}>
                <ViewBox>
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginBottom:'16px'}}>
                    <MenuTitle title={t('지사/협력사 조회')} unitCase={'MenuCompTitle'}/>
                    <div style={{display:'flex'}}>
                      {detailData?.authority !== 'admin' &&
                          <MainButtons name={'기계 등록'} clickEvent={()=>window.location.href = `/admin/fas/partners/reg_facility/${detailData.id}`} disabled={buttonAuthValid('create','1')}/>
                      }
                      <MainButtons name={'목록'} width={'56px'} clickEvent={()=>window.location.href = `/admin/fas/partners`}/>
                      {/*<MainButtons name={'수정'} width={'56px'} marginDis={true} clickEvent={()=>window.location.href = `/admin/fas/partners/modify/${detailData.id}`}/>*/}
                      <MainButtons name={'수정'} width={'56px'} marginDis={true} clickEvent={()=>{setDataModal({menu:'check'})}}  disabled={buttonAuthValid('update','1')}/>

                    </div>
                  </div>

                  <div className={'scroll_func'}>
                    {detailComponent(t('지사/협력사명'),detailData?.companyName)}
                    {detailComponent(t('대표'),detailData?.president)}
                    {detailComponent(t('주소'),
                      <React.Fragment>
                        <div style={{marginRight:'8px'}}>{detailData?.address}</div>
                        <div>{detailData?.detailAddress}</div>
                      </React.Fragment> )}

                    {detailComponent(t('전화번호'),detailData?.phoneNumber)}
                    {detailComponent(t('이메일'),detailData?.email)}
                    {detailComponent(t('관리자 권한'),detailData?.authority === 'admin' ? t("관리자") : t("협력사"))}
                    {detailComponent(t('관리자 ID'),detailData?.id)}
                    {detailComponent(t('관리자 PW'),
                      <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'572px',opacity:!detailData.id ? 0.5 : 1,pointerEvents:!detailData.id ? 'none' : 'auto'}}>
                        <MainButtons name={'변경하기'} disabled={buttonAuthValid('update','1')} buttonType={'cta'} margin={'0'} clickEvent={()=>{setDataModal({menu:'pw'})}}/>
                        {/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
                        {/*  {'비밀번호 찾기'}*/}
                        {/*</div>*/}
                      </div>
                    )}

                    <div style={{display:'flex',borderBottom:'1px solid #E6E9F4',boxSizing:'border-box'}}>
                      <div className={'detail_title'} style={{height:'234px'}}>{t('비고')}</div>
                      <div className={'detail_content'}>
                        <div className={'detail_notice'}>
                          {dataValid(detailData?.notice,<NoData height={196}>{'* 작성된 내용이 없습니다.'}</NoData>)}
                        </div>
                      </div>
                    </div>

                  </div>
                </ViewBox>

                <div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>

                <div style={{display:'flex', flexDirection:'column'}}>
                  <div>
                    <MenuTitle title={'프로필'} unitCase={'MenuCompTitle'}/>
                    <PreviewImage imageUrl={detailData?.imageUrl}
                                  clickEvent={()=> detailData?.imageUrl && setDataModal({menu:'profile'})}
                                  noneDelete={true} margin={16}/>
                  </div>

                  {detailData?.authority !== 'admin' &&
                      <div style={{marginTop:'80px'}}>
                        <MenuTitle title={'협력사 로고'} unitCase={'MenuCompTitle'}/>
                        <PreviewImage imageUrl={detailData?.logoImageUrl}
                                      clickEvent={()=> detailData?.logoImageUrl && setDataModal({menu:'logo'})}
                                      noneDelete={true} margin={16}/>
                      </div>}
                </div>
              </div>
            </ContentFrame>
          </React.Fragment>
    </SectionFrame>
  );
};

export default PartnerDetail;


const ViewBox = styled.div`
  width: 784px;
  height: 620px;
  
  .detail{
    &_title{
        display: flex;
        align-items: center;
        height: 48px;
        padding-left: 16px;
        box-sizing: border-box;
        font-weight: 900;
        font-size: 12px;
        min-width: 168px;
        background-color: #F5F6FA;
        border-top: 1px solid #E6E9F4;
    }
    &_content{
      width: 616px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 24px;
      border-top: 1px solid #E6E9F4;
    }

    &_notice{
      width: 100%;
      box-sizing: border-box;
      //display: flex;
      margin: 18px 0;
      height: 196px;
      align-self: start;
      word-break: break-all;
      line-height: 30px;
      letter-spacing: 0.5px;
      white-space: pre-wrap;
      overflow-x: hidden;
      overflow-y: scroll;
      
      &::-webkit-scrollbar {
        display: block;
        width: 15px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }
      &::-webkit-scrollbar-track{
        width: 21px;
        background: transparent;
      }
    }
  }

  .scroll_func{
    width: 800px;
    height: 540px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
    }
  }
`