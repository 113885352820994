import React , {useEffect , useState} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation , useParams} from "react-router-dom";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import FindPasswordModal from "../../../../../components/modal/FindPasswordModal";
import PasswordChangeModal from "../../../../../components/modal/PasswordChangeModal";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , NoData , SectionFrame , validationValue} from "../../../../../style/StyleComponent";
import MainButtons from "../../../../../components/button/MainButtons";
import noImage from "../../../../../asset/image/noImage.svg";
import styled from "styled-components";
import PasswordCheckModal from "../../../../../components/modal/PasswordCheckModal";

const PartnerUserDetail = () => {
  const {t}:any = useTranslation()
  const detailId = useParams()
  const location = useLocation()
  const authValid = location.pathname.includes('admin')
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [userData,setUserData] = React.useState<any>({imagesUrlList:[]})
  const [adminPassword,setAdminPassword] = React.useState<string>('');
  const [emailStatus,setEmailStatus] = React.useState<string>('');
  const [password,setPassword] = React.useState<boolean>(false);


  const sendEmailRes = async () => {
    dataAccessLoading();
    setDataModal(initModal)
    // 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
    setTimeout(() => {
      setPassword(true)
      loadingRemove();
      // 작업 완료 시 호출할 콜백 함수
    }, 1000);
  };

  const userPasswordModifyRes = async () =>{
    try {
      dataAccessLoading()
      const response = await client.post('/system/user/update',{
        ...userData,
        phone_number:userData.phone_number,
        password:adminPassword
      },{headers:{Authorization}})
      const data = response.data
      if(data.message !== 'success'){
        setDataModal({menu:'confirm',message:data.message});
        loadingRemove()
      } else{
        setTimeout(()=>{
          loadingRemove()
          setDataModal({menu:'success',message:t('비밀번호가 변경 되었습니다.')});
        },500)
      }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }

  const userDetailRes = async () => {
    try {
      const response = await client.post('/system/user/detail',{
        id: detailId.id
      },{
                                           headers: {Authorization}})
      console.log(response.data)
      setUserData(response.data.data)
    }catch (e) {
      console.log(e)
    }
  }
  useEffect(()=>{
    userDetailRes().then()
  },[])

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {dataModal.menu ==='profile' && <DetailImageView image={userData.profileImageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'findPw' &&
          <FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>
      }
      {dataModal.menu === 'pw' &&
          <PasswordChangeModal detailInfo={userData} viewType={true}
                               receivePassword={(data)=>{setAdminPassword(data)}}
                               clickEvent={userPasswordModifyRes} findEvent={()=>{setDataModal({menu:'findPw'})}}
                               closeClick={()=>{setDataModal(initModal)}}/>
      }

      {dataModal.menu === 'check' &&
          <PasswordCheckModal detailInfo={userData} cancelEvent={()=>{setDataModal(initModal)}}
                              viewType={true}
                              movePage={`/admin/fas/partners/user/modify/${userData?.id}`}
                              noticeMessage={'* 회원정보 수정을 위해 비밀번호를 확인해주세요.'}/>
      }

      {password &&
          <FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>
      }

      <MenuTitle title={'지사/협력사 관리 > 지사/협력사 유저관리 > 자세히 보기'} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'} justify={'flex-start'}>
        <ViewBox>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
            <MenuTitle title={'업체 정보'} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex',alignItems:'center'}}>
              <MainButtons name={'목록'} width={'56px'} clickEvent={()=>{ window.location.href = `/admin/fas/partners/user`}}/>
              <MainButtons name={'수정'} width={'56px'} disabled={buttonAuthValid('update','92')} marginDis={true}
                           // clickEvent={()=>{setDataModal({menu:'check'})}}
                           clickEvent={()=>{window.location.href = `/admin/fas/partners/user/modify/${userData?.id}`}}
              />
            </div>
          </div>
          <div style={{borderTop:'1px solid #E6E9F4'}}>
            <div className={'info'} style={{display: 'grid', gridTemplateColumns: '168px 224px 168px 224px'}}>
              <div className={'reg-title'}>{t('업체명')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.companyName)}
              </div><div className={'reg-title'}>{t('업체코드')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.companyCode)}
              </div>
              <div className={'reg-title'}>{t('대표')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.president)}
              </div>

              <div className={'reg-title'}>{t('-')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue('')}
              </div>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '16px 0'}}>
            <MenuTitle title={'유저 정보'} unitCase={'MenuCompTitle'}/>
          </div>
          <div style={{borderTop:'1px solid #E6E9F4'}}>
            <div className={'info'} style={{display: 'grid', gridTemplateColumns: '168px 224px 168px 224px'}}>
              <div className={'reg-title'}>{t('성명')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.userName)}
              </div>
              <div className={'reg-title'}>{t('직책')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.userPosition)}
              </div>
              <div className={'reg-title'}>{t('사원번호')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.employeeNumber)}
              </div>
              <div className={'reg-title'}>{t('권한')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(userData?.authorityName)}
              </div>
            </div>
          </div>

          <div className={'reg'} style={{display: 'grid', gridTemplateColumns: '168px 616px'}}>
            <div className={'reg-title'}>{t('전화번호')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(userData?.phone_number)}
            </div>
            <div className={'reg-title'}>{t('이메일')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(userData?.email)}
            </div>
            <div className={'reg-title'}>{t('ID')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(userData?.id)}
            </div>
            <div className={'reg-title'}>{t('PW')}</div>
            <div className={'reg-content'} style={{display: 'flex',padding:'2px 0 0 8px'}}>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'572px'}}>
                <MainButtons name={'변경하기'} disabled={buttonAuthValid('update','92')} buttonType={'cta'} margin={'0'} clickEvent={()=>{setDataModal({menu:'pw'})}}/>
                {/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
                {/*  {'비밀번호 찾기'}*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={'info-title'} style={{height: '116px'}}>{t('비고')}</div>
            <div className={'info-content'} style={{height: '116px'}}>
              {dataValid(userData?.notice,<NoData height={96}>{' * 등록된 내용이 없습니다.'}</NoData>)}
            </div>
          </div>
        </ViewBox>
        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
        <div style={{width:'370px',height:'34px',alignItems:'center'}}>

          <MenuTitle title={'프로필'} unitCase={'MenuCompTitle'}/>
          <div>
            <div style={{display:'flex', width:'168px',height:'168px',border:'1px solid #D7DBEC',borderRadius:'6px',overflow:'hidden',marginTop:'20px'}}>
              {!userData.profileImageUrl?
                <div style={{backgroundImage:`url(${noImage})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px'}}/>
                :
                <div style={{backgroundImage:`url(${userData.profileImageUrl})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px',cursor:'pointer'}}
                     onClick={()=>{setDataModal({menu:'profile'})}}/>
              }
            </div>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default PartnerUserDetail;


const ViewBox = styled.div<{ disabled?:boolean }>`
  width: 784px;
  height: 584px;

  .info, .reg {
    display: grid;
    box-sizing: border-box;
    //border-top: 1px solid rgba(213, 215, 227, 0.5);

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding: 16px 0 0 16px;
      box-sizing: border-box;
      font-size: 14px;
      min-height: 48px;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      white-space: pre-wrap;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }
    }

    &-content-reg {
      width: 600px;
      height: 80px;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        width: 21px;
        background: transparent;
      }
    }
  }

  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
  
`