import React , {useState , useRef , useEffect} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import MenuTitle from "../../../../../components/title/MenuTitle";
import SearchBar from "../../../../../components/input/SearchBar";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  allCheckedState , allPersistCheckedFunction , authValid , buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  filterCheckedRow ,
  initError ,
  initModal ,
  loadingRemove , menuVisitLogRegister , onChangeArray , onChangeCheck ,
  removeCheckedFunction , rowListDropDirection ,
  singleCheckedFunction
} from "../../../../../function/function";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {useTranslation} from "react-i18next";
import {Error_Type , Modal_View_Type , PeripheralType} from "../../../../../types/Fundamental";
import FactoryRegisterModal from "../../../../../components/modal/FactoryRegisterModal";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import dayjs from "dayjs";
import styled from 'styled-components';
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  persistBlockPage ,
  persistCompanyData , persistPage ,
  selectedCode ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import ListImageUploadModal from "../../../../../components/modal/ListImageUploadModal";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import SingleListImageUploadModal from "../../../../../components/modal/SingleListImageUploadModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from '../../../../../components/pagination/PaginationList'
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import HorizontalMoveScrollButton from '../../../../../components/button/HorizontalMoveScrollButton'
import ExcelModal from "../../../../../components/modal/ExcelModal";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const PeripheralsManagement = () => {
  const {t}: any = useTranslation();
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [rowList , setRowList] = useState<PeripheralType[]>( [] )
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [page , setPage] = useRecoilState<number>( persistPage );
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage );
  const [selectCode , setSelectCode] = useRecoilState( selectedCode )
  const [totalPage , setTotalPage] = useState<number>( 1 );
  const [peripheralDataList , setPeripheralDataList] = useState<PeripheralType[]>( [] );
  const [idIndex , setIdIndex] = useState<number>( 1 )
  const [searchInput , setSearchInput] = useState<string>( '' )
  const [pushInput , setPushInput] = useState<string>( '' )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [errorState , setErrorState] = useState<Error_Type>( initError )
  const [hoverIcon , setHoverIcon] = useState<string>( modify )
  const [iconSelect , setIconSelect] = useState<number>( - 1 )
  const [focusList , setFocusList] = useState<number>( - 1 )
  const [selectPeripheral , setSelectPeripheralObject] = useState<any>( {} )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const menuTitle = ['장치 종류' , '장치 제조번호' , '장치 제조사' , '장치 이름' , '제조 연월일' , '담당자' , '공장명' ,
    '공장 세분화명' , '장치 사진' , '스펙 명판사진' , '능력 명판 사진' , '사용 설명서']
  const needValue = ['장치 종류' , '장치 제조사' , '장치 이름']
  const selectType = ['미스피드 검출장치' , '하사점 검출장치' , '로드모니터' , '앵글시퀀서' , '엔코더' , '통관센서' , '유틸리티센서' , '기타']
  const scrollRef = useRef<any>( null )
  const scrollToStart = () => {
    scrollRef.current.scrollLeft = 0
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    setIdIndex( idIndex + 1 )
    const rowInput = {
      id: idIndex ,
      companyCode: selectedCompany.companyCode ,
      factoryCode: '' ,//공장 코드
      factoryName: '' , //공장 이름
      factorySubDivision: '' , //공장 세분화 이름
      managerName: '' , //담당자
      manufactureDate: '' , //제조일
      peripheralManufacturer: '' , //장치 제조사
      peripheralName: '' , //장치 이름
      peripheralNumber: '' , //장치 제조번호
      peripheralType: '미스피드 검출장치' , //장치 종류
      peripheralImg: [] ,
      peripheralImgUrl: [] ,
      specImg: '' , //스펙 이미지
      specImgUrl: '' ,
      abilityImg: '' , //능력 명판 사진
      abilityImgUrl: '' ,
      manualImg: '' , //설명서 이미지
      manualImgUrl: '' ,
      codeDoubleCheck: false
    }
    setRowList( [...rowList , rowInput] )
    setCheckedList( [...checkedList , rowInput.id] )
    scrollToStart()
    if( rowList.length === 0 ){
      setIdIndex( idIndex + 1 );
    }
  }
  const updateFunction = () => {
    if( page === 1 ){
      if( pushInput === '' ){
        peripheralsListRes().then()
      } else{
        peripheralSearchRes().then()
      }
    } else{
      setPage( 1 )
    }
  }
  const checkboxValid = () => {
    if( rowList.length !== 0 ){
      return {list: rowList , id: 'id'}
    } else{
      return {list: peripheralDataList , id: 'peripheralCode'}
    }
  }
  const deleteRowEvent = () => {
    if( rowList.length === 0 ){
      setDataModal( {menu: 'select' , message: `${checkedList.length}개의 주변장치를 삭제 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'back' , message: '선택된 행의 정보를 삭제하시겠습니까?'} )
    }
  }
  const filteredData = rowList.filter( ( item: any ) => {
    return item.peripheralNumber !== '' && item.codeDoubleCheck === false;
  } );
  const peripheralsRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralType ) ){
      setDataModal( {menu: 'confirm' , message: '장치 종류를 선택해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralManufacturer ) ) ){
      setDataModal( {menu: 'confirm' , message: '장치 제조사를 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralName ) ) ){
      setDataModal( {menu: 'confirm' , message: '장치 이름을 입력해 주세요.'} )
    } else if( filterCheckedRow( filteredData , checkedList , 'id' ).length !== 0 ){
      setDataModal( {menu: 'confirm' , message: '장치 제조번호 중복 확인을 해주세요.'} )
    }else{
      try {
        dataAccessLoading()
        const response = await client.post( `/peripheral/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {
                                              headers: {Authorization}
                                            } )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            loadingRemove()
            setRowList( [] )
            setCheckedList( [] )
            updateFunction()
            setDataModal( {menu: 'success' , message: '주변장치 정보가 등록 되었습니다.'} )
          } , 500 )
          setDataModal( initModal )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //목록
  //삭제
  const peripheralsDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( `/peripheral/delete` , [...checkedList] , {
        headers: {Authorization}
      } )
      const data = response.data
      const caseArray = data.data?.facility?.map( ( v: any ) => ( {code: v , case: '기계에 등록된 주변장치 입니다.'} ) )
      if( data.status === 200 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setPageBlock( 0 )
          setIconSelect( - 1 )
          setDataModal( {menu: 'success' , message: '선택한 주변장치가 삭제 되었습니다.'} )
          removeCheckedFunction( setPeripheralDataList , peripheralDataList , setCheckedList , checkedList , 'peripheralCode' )
          scrollToStart()
          if( page === 1 ){
            if( pushInput === '' ){
              peripheralsListRes().then()
            } else{
              peripheralSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setDataModal( {menu: 'deleteCase'} )
          setDeleteCaseArray( caseArray )
          setCheckedList( checkedList.filter( ( v: string ) => !data.data.success.includes( v ) ) )
          setPeripheralDataList( peripheralDataList.filter( ( v: any ) => !data.data.success.includes( v['peripheralCode'] ) ) )
          scrollToStart()
          if( page === 1 ){
            if( pushInput === '' ){
              peripheralsListRes().then()
            } else{
              peripheralSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  //수정
  const peripheralsUpdateRes = async () => {
    if( !peripheralDataList[iconSelect].peripheralName ){
      setDataModal( {menu: 'confirm' , message: '장치 이름이 없습니다.'} )
    } else if( !peripheralDataList[iconSelect].peripheralManufacturer ){
      setDataModal( {menu: 'confirm' , message: '장치 제조사가 없습니다.'} )
    } else if( ( peripheralDataList[iconSelect].peripheralNumber ) && ( !peripheralDataList[iconSelect].codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '장치 제조번호 중복 확인을 해주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/peripheral/update` , {
          companyCode: selectedCompany.companyCode ,
          ...peripheralDataList[iconSelect]
        } , {
                                              headers: {Authorization}
                                            } )
        const data = response.data
        if( response.data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            loadingRemove()
            setIconSelect( - 1 )
            updateFunction()
            setCheckedList( [] )
            setDataModal( {menu: 'success' , message: '수정 되었습니다.'} )
          } , 500 )
          setDataModal( initModal )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  const peripheralsListRes = () => ListCustomHooks( `/peripheral/list/${page}/10/${selectedCompany.companyCode}` , page , setTotalPage , setPageBlock , setPeripheralDataList , setErrorState , setPushInput , setSearchInput )
  const peripheralSearchRes = () => ListSearchPostCustomHooks( `/peripheral/search` , page , setTotalPage , setPageBlock , setPeripheralDataList , setErrorState , {
    page: page ,
    size: 10 ,
    keyword: searchInput ,
    companyCode: selectedCompany.companyCode
  } )
  // const peripheralsListRes = async () =>{
  //     listLoadingCircle()
  //     try {
  //         const response = await client.get(`/peripheral/list/${page}/10/${selectedCompany.companyCode}`,{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         const valid = response.data
  //         if(valid.status !== 200){
  //             setErrorState({notice:valid.message,status:valid.status})
  //             setPeripheralDataList([])
  //         }else{
  //             setTotalPage(data.total_page);
  //             setErrorState({status:valid.status,notice:t('* 등록된 주변장치가 없습니다. 주변장치를 등록해 주세요.')})
  //             setPushInput('')
  //             setSearchInput('')
  //             setPeripheralDataList(data.row)
  //             // if(data.total_page > 1 && page > 1){
  //             //     setPeripheralDataList((prev:any)=> prev.concat(data.row))
  //             // }else{
  //             //     setPeripheralDataList(data.row)
  //             // }
  //         }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //
  // //검색
  // const peripheralSearchRes = async () =>{
  //     listLoadingCircle()
  //     try {
  //         const response = await client.post(`/peripheral/search`,{
  //             ///${page}/10/${selectedCompany.companyCode}/${pushInput}
  //             page:page,
  //             size:10,
  //             companyCode:selectedCompany.companyCode,
  //             keyword:pushInput
  //         },{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         setPageBlock(0)
  //         setTotalPage(data.total_page)
  //         setPeripheralDataList(data.row)
  //         // if(data.total_page > 1 && page > 1){
  //         //     setPeripheralDataList((prev:any)=> prev.concat(data.row))
  //         // }else{
  //         //     setPeripheralDataList(data.row)
  //         // }
  //         loadingRemove()
  //     }catch (e){
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  // const rowCheckCode = checkedList.filter( ( id: any ) => ( rowList.some( ( v: any ) => ( v.id === id && v.peripheralNumber !== '' ) ) ) )
  const serialDoubleCheck = async ( serialCode: string , type: string , index: number ) => {
      try {
        const response = await client.post( '/peripheral/serial/check' , {
          companyCode: selectedCompany.companyCode ,
          peripheralNumber: serialCode ,
          peripheralType: type
        } , {headers: {Authorization}} )
        if( response.data.status === 200 ){
          if( rowList.length !== 0 ){
            // if( duplicateInDoubleCheck( rowList.filter( ( v ) => ( v.peripheralNumber ) ) , 'peripheralType' , 'peripheralNumber' , rowCheckCode ) ){
            //
            // } else{
            const copyRowList = [...rowList]
            copyRowList[index] = {...copyRowList[index] , codeDoubleCheck: true}
            setRowList( copyRowList )
            setDataModal( {menu: 'confirm' , message: response.data.message} )
            // }
          } else{
            const copyPeripheralDataList = [...peripheralDataList]
            copyPeripheralDataList[index] = {
              ...copyPeripheralDataList[index] , codeDoubleCheck: true ,
              peripheralNumber: serialCode
            }
            setPeripheralDataList( copyPeripheralDataList )
            setDataModal( {menu: 'confirm' , message: response.data.message} )
          }
        } else{
          setDataModal( {menu: 'confirm' , message: response.data.message} )
        }
      } catch ( e ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
      }
  }
  React.useEffect( () => {
    if( selectedCompany.companyCode !== undefined ){
      if( pushInput === '' ){
        peripheralsListRes().then()
      } else{
        peripheralSearchRes().then()
      }
    }
  } , [pushInput , page , selectedCompany] )
  const onDataResetFunc = () => {
    setRowList( [] );
    setCheckedList( [] );
    setPeripheralDataList( [] )
    setSearchInput( '' );
    setPushInput( '' );
    setIconSelect( - 1 );
    setPage( 1 );
    setPageBlock( 0 );
    setTotalPage( 1 )
    setErrorState( initError )
  }
  React.useEffect( () => {
    onDataResetFunc()
  } , [selectedCompany.companyCode] )
  const cancelFuc = () => {
    setHoverIcon( modify );
    setIconSelect( - 1 );
    setCheckedList( [] )
    setDataModal( initModal )
    if( pushInput === '' ){
      setPeripheralDataList( peripheralDataList.map( ( v: any ) => {return v.peripheralCode === selectCode ? {...v , ...selectPeripheral} : v} ) )
    } else{
      peripheralsListRes().then()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( rowList.length > 0 || iconSelect !== - 1 ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [rowList.length , iconSelect] );
  const handleSelectValue = ( i: number , v: any ) => {
    const updateRow = [...rowList]
    updateRow[i].peripheralType = v
    updateRow[i].codeDoubleCheck = false
    setRowList( updateRow )
  }
  const [, setIsScrolling] = useState<boolean>( true );
  const handleScroll = () => {
    //확인된 최대값 숫자 넣기
    if( scrollRef?.current?.scrollLeft === 600 ){
      setIsScrolling( false )
    } else{
      setIsScrolling( true )
    }
  };

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'주변장치 기본정보').then()
    }
  },[])

  const filteredList = rowList?.filter(item => checkedList?.includes(item?.id))
  console.log(filteredList)
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} timeSet={800}
                                                    state={'warn'} closeEvent={() => {setDataModal( initModal )}}/>
            : null}
      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'장치 이름'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )}
                        clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'peripheral' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view}
                                title={dataModal.view ? '주변장치 보기' : '주변장치 등록'}
                                receiveKey={rowList.length === 0 ? peripheralDataList[dataModal.index].peripheralImg : rowList[dataModal.index].peripheralImg}
                                receiveUrl={rowList.length === 0 ? peripheralDataList[dataModal.index].peripheralImgUrl : rowList[dataModal.index].peripheralImgUrl}
                                clickEvent={( v ) => {
                                  if( rowList.length === 0 ){
                                    Object.assign( peripheralDataList[dataModal.index] , {
                                      peripheralImg: v.key ,
                                      peripheralImgUrl: v.url
                                    } );
                                  } else{
                                    Object.assign( rowList[dataModal.index] , {
                                      peripheralImg: v.key ,
                                      peripheralImgUrl: v.url
                                    } )
                                  }
                                }}
                                cancelEvent={() => setDataModal( initModal )}/>}
      {/*이미지 동적 으로 바꾸자*/}
      {dataModal.menu === 'spec' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        rowList.length !== 0 ?
                                          Object.assign( rowList[dataModal.index] , {
                                            specImg: v.key ,
                                            specImgUrl: v.url
                                          } )
                                          :
                                          Object.assign( peripheralDataList[dataModal.index] , {
                                            specImg: v.key ,
                                            specImgUrl: v.url
                                          } );
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={( rowList.length !== 0 ) ? rowList[dataModal.index].specImg : peripheralDataList[dataModal.index].specImg}
                                      receiveUrl={( rowList.length !== 0 ) ? rowList[dataModal.index].specImgUrl : peripheralDataList[dataModal.index].specImgUrl}
          />
      }
      {dataModal.menu === 'ability' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        rowList.length !== 0 ? Object.assign( rowList[dataModal.index] , {
                                            abilityImg: v.key ,
                                            abilityImgUrl: v.url
                                          } )
                                          :
                                          Object.assign( peripheralDataList[dataModal.index] , {
                                            abilityImg: v.key ,
                                            abilityImgUrl: v.url
                                          } );
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={( rowList.length !== 0 ) ? rowList[dataModal.index].abilityImg : peripheralDataList[dataModal.index].abilityImg}
                                      receiveUrl={( rowList.length !== 0 ) ? rowList[dataModal.index].abilityImgUrl : peripheralDataList[dataModal.index].abilityImgUrl}
          />}

      {dataModal.menu === 'manual' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        rowList.length !== 0 ? Object.assign( rowList[dataModal.index] , {
                                            manualImg: v.key ,
                                            manualImgUrl: v.url
                                          } )
                                          :
                                          Object.assign( peripheralDataList[dataModal.index] , {
                                            manualImg: v.key ,
                                            manualImgUrl: v.url
                                          } );
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={( rowList.length !== 0 ) ? rowList[dataModal.index].manualImg : peripheralDataList[dataModal.index].manualImg}
                                      receiveUrl={( rowList.length !== 0 ) ? rowList[dataModal.index].manualImgUrl : peripheralDataList[dataModal.index].manualImgUrl}
          />}

      {dataModal.menu === 'factory' &&
          <FactoryRegisterModal companyCode={selectedCompany.companyCode}
                                DataDetail={rowList.length === 0 ? peripheralDataList[dataModal.index] : rowList[dataModal.index]}
                                cancelEvent={() => {setDataModal( initModal )}}
                                confirmEvent={( v ) => {
                                  if( rowList.length === 0 ){
                                    Object.assign( peripheralDataList[dataModal.index] , v )
                                  } else{
                                    Object.assign( rowList[dataModal.index] , v )
                                  }
                                }}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                peripheralsDeleteRes().then();
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 선택되지 않은 리스트는 저장 되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={peripheralsRegisterRes}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
                                  setDataModal( initModal )
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}
      {dataModal.menu === 'excel' &&
        <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
          <React.Fragment>
            <ExcelApiButton type={'download'} companyData={selectedCompany} category={'peripheral'} disabled={peripheralDataList.length === 0 && pushInput === ''}/>
            <ExcelApiButton type={'format'} companyData={selectedCompany} category={'peripheral'} disabled={buttonAuthValid( 'create' , '7' )}/>
            <ExcelApiButton type={'upload'} companyData={selectedCompany} category={'peripheral'} disabled={buttonAuthValid( 'create' , '7' )}/>
          </React.Fragment>
        }/>
      }


      <SectionFrame>
        {/*<div style={{display:'flex', justifyContent:'space-between'}}>*/}
        {/*    <div style={{display:'flex'}}>*/}
        {/*      <MenuTitle title={(authValid? '업체/' : '')+'기본정보 관리 > 주변장치 기본정보'} unitCase={'MenuTitle'}/>*/}
        {/*        {companyInfoHidden &&*/}
        {/*          <div style={{display:'flex'}}>*/}
        {/*              <span style={{margin:'8px 8px 0',alignSelf:'center', fontSize:'24px', fontWeight:800}}>{'>'}</span>*/}
        {/*              <span style={{alignItems:'center', alignSelf:'center', fontSize:'18px', marginTop:'8px', fontWeight:700, color:'#0058ff'}}>{selectedCompany?.companyName}</span>*/}
        {/*          </div>}*/}
        {/*    </div>*/}
        {/*    <MainButtons name={companyInfoHidden? '업체 정보 보기' : '업체 정보 감추기'} width={'120px'}*/}
        {/*                 clickEvent={()=>setCompanyInfoHidden(!companyInfoHidden)}/>*/}
        {/*</div>*/}


        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 주변장치 기본정보'} unitCase={'MenuTitle'}/>
          </div>
          {rowList?.length !== 0 &&
              <MainButtons name={'목록'} width={'56px'}
                           clickEvent={() => {
                             window.location.href = authValid() ? '/admin/fas/peripherals' : '/fas/peripherals'
                           }}/>}
        </div>

        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'주변장치 기본정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <SearchBar keyPressDisable={true} clickEvent={() => {
                  setPushInput( searchInput );
                  setPage( 1 )
                }} clearButtonAccess={searchInput !== ''}
                           keyFunction={( e ) => ( setSearchInput( e.target.value.trimStart() ) )} value={searchInput}
                           clearButton={() => {
                             peripheralsListRes().then();
                             setPage( 1 )
                           }}
                           disabled={( peripheralDataList.length === 0 && pushInput === '' ) || rowList.length !== 0 || iconSelect !== - 1}/>
                <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
                {/*{selectedCompany.companyCode !== undefined &&*/}
                {/*    <MainButtons name={'행추가'} width={'80px'} clickEvent={addListRow}*/}
                {/*                 disabled={buttonAuthValid( 'create' , '7' ) || iconSelect !== - 1 || pushInput !== '' || checkedList[0]?.length > 1 || errorState.status !== 200}/>}*/}

                {(checkedList?.length !== 0) &&
                  <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                              disabled={
                                buttonAuthValid( 'rowDelete' , '7' ) ? rowList.length === 0
                                  :
                                  buttonAuthValid( 'delete' , '7' ) || checkedList.length === 0}
                              clickEvent={deleteRowEvent}
                              marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}/>
                }

                {rowList?.length !== 0 &&
                      <MainButtons name={'저장'} width={'80px'}
                                   disabled={buttonAuthValid( 'create' , '7' ) || rowList.length === 0 || checkedList.length === 0}
                                   buttonType={'cta'}
                                   clickEvent={() => {
                                     if( checkedList.length === rowList.length ){
                                       peripheralsRegisterRes().then()
                                     } else{
                                       setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'} )
                                     }
                                   }}/>
                }
                {( rowList?.length === 0 && iconSelect === - 1 && checkedList?.length === 0 ) &&
                    <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                                 marginDis={true}
                                 disabled={buttonAuthValid( 'create' , '7' ) || ( !selectedCompany.companyCode )}
                                 clickEvent={() => window.location.href = authValid() ? '/admin/fas/peripherals/register' : '/fas/peripherals/register'}/>}
              </div>
            </div>
            {/*{iconSelect === -1 &&*/}
            {/*  <ShowMore showMore={isScrolling} width={60} height={authValid? 335 : 535} right={40} top={78} horizonType={true}/>}*/}
            {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
            {/*                next={()=>setPage((prev)=> prev+1)}*/}
            {/*                hasMore={totalPage > page} dataLength={dataValid(peripheralDataList?.length, 0)}*/}
            {/*                loader={<div/>}>*/}
            <GridTableComponent id={'grid-scroll'} ref={scrollRef} onScroll={handleScroll}
                                titleCheckDisabled={iconSelect !== - 1}
                                scrollWidth={1927} scrollY_Hide={rowList?.length < 10}
                                height={538}
              // height={listHeightValid(authValid,{total: totalPage, page: page})}
                                tempCol={'40px repeat(8, 176px) repeat(5, 120px)'}>

              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox"
                         style={{
                           pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` ,
                           opacity: `${iconSelect === - 1 ? 1 : 0.5}`
                         }}
                         onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                           dataList: checkboxValid().list ,
                           checkedList: checkedList ,
                           id: checkboxValid().id ,
                           setState: setCheckedList
                         } )}
                         checked={allCheckedState( {
                                                     dataList: checkboxValid().list ,
                                                     checkedList: checkedList ,
                                                     id: checkboxValid().id
                                                   } )}/>
                </label>
                {menuTitle.map( ( v ) => (
                  <TableValue title={v} key={v} viewType={iconSelect !== - 1 || rowList.length !== 0}
                              requiredValue={needValue}/> ) )}
              </div>

              {rowList.length === 0 && peripheralDataList.length === 0 ? pushInput === '' ? errorState.status !== 200 ?
                    listResultMessage( authValid() , dataValid( errorState.notice , t( '* 업체를 선택해 주세요.' ) ) , {
                      width: 1210 ,
                      admin: 480 ,
                      user: 480
                    } )
                    :
                    listResultMessage( authValid() , dataValid( errorState.notice , '' ) ,
                                       {width: 1210 , admin: 480 , user: 480} , ()=>window.location.href = authValid() ? '/admin/fas/peripherals/register' : '/fas/peripherals/register' ,buttonAuthValid('create','7'),'등록')
                  :
                  listResultMessage( authValid() , t( '*검색 결과가 없습니다.' ) , {width: 1210 , admin: 480 , user: 480} )
                :
                rowList.map( ( v , i , arr ) => (
                  <div className={'grid-row-list'}>
                    <label className={'checkbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                      checked={checkedList.includes( v.id )}/>
                    </label>
                    <ListInnerInput type={'drop'} placeholder={'장치종류'} name={'peripheralType'}
                                    inputData={dataValid( v.peripheralType , selectType[0] )}
                                    dropTopDirection={rowListDropDirection( arr , i , peripheralDataList )}
                                    dropMenuList={selectType}
                      // selectDrop={(v)=>Object.assign(rowList[i], {peripheralType : v, codeDoubleCheck: false})}
                                    selectDrop={( data ) => handleSelectValue( i , data )}

                    />
                    {/*<ListInnerInput type={'text'} placeholder={'장치 제조번호'} name={'peripheralNumber'} inputData={v.peripheralNumber} onChange={(e)=>onChangeArray(i,e,rowList,setRowList)}/>*/}
                    <ListInnerInput type={'withButton'} width={'120px'} name={'peripheralNumber'}
                                    placeholder={'-'} inputData={v.peripheralNumber}
                                    disabled={v.codeDoubleCheck || !v.peripheralNumber}
                                    onChange={( e ) => {
                                      onChangeArray( i , e , rowList , setRowList , false )
                                      onChangeCheck( i , rowList , setRowList )
                                    }}
                                    clickButton={() => {
                                      // checkedList?.includes(item?.id))
                                      if(v.peripheralType === v.peripheralNumber){
                                        setDataModal( {menu: 'warn' , message: '장치 종류와 장치 제조번호를 \n중복해서 입력할 수 없습니다.'} )
                                      }else if(rowList?.filter(item => [v.peripheralNumber]?.includes(item.peripheralNumber))?.length > 1){
                                        setDataModal( {menu: 'warn' , message: '추가된 행에 중복된 제조번호가 존재합니다.'} )
                                      }else{
                                        serialDoubleCheck( dataValid( v.peripheralNumber , '' ) , dataValid( v.peripheralType , '' ) , i ).then()
                                      }
                                    }}
                                    buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'} buttonWidth={'60px'}/>

                    <ListInnerInput type={'text'} placeholder={'장치 제조사'} name={'peripheralManufacturer'}
                                    inputData={v.peripheralManufacturer}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList , true )}/>
                    <ListInnerInput type={'text'} placeholder={'장치 이름'} name={'peripheralName'}
                                    inputData={v.peripheralName}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList , true )}/>
                    <ListInnerInput type={'calendar'} name={'manufactureDate'} inputData={v.manufactureDate}
                                    placeholder={'제조 연월일'}
                                    useDeleteButton={true}
                                    onChange={( e ) => Object.assign( rowList[i] , {manufactureDate: dayjs( e ).format( 'YYYY.MM.DD' )} )}/>
                    <ListInnerInput type={'text'} placeholder={'담당자'} name={'managerName'} inputData={v.managerName}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList , true )}/>
                    <ListInnerInput type={'search'} placeholder={'공장명'} name={'factoryName'} tabIndex={- 1}
                                    inputData={v.factoryName}
                                    clickButton={() => {setDataModal( {menu: 'factory' , index: i} )}}/>

                    <ListInnerInput type={'text'} placeholder={'공장 세분화명'} disabled={true}
                                    name={'factorySubDivision'} inputData={v.factorySubDivision}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList )}/>
                    {/*장치 사진*/}
                    <MainButtons name={!v.peripheralImg[0] ? '장치사진 등록' : '장치사진 변경'}
                                 buttonType={!v.peripheralImg[0] ? 'cta' : 'popupCta'} margin={'0'}
                                 width={'120px'}
                                 clickEvent={() => setDataModal( {menu: 'peripheral' , index: i , view: false} )}/>

                    {/*스펙 명판 사진*/}
                    <MainButtons name={`스펙 이미지 ${!v.specImg ? '등록' : '변경'}`} width={'120px'}
                                 buttonType={!v.specImg ? 'cta' : 'popupCta'} margin={'0'}
                                 clickEvent={() => setDataModal( {menu: 'spec' , index: i , view: false} )}/>

                    {/*능력 명판사진*/}
                    <MainButtons name={`명판 이미지 ${!v.abilityImg ? '등록' : '변경'}`} width={'120px'}
                                 buttonType={!v.abilityImg ? 'cta' : 'popupCta'} margin={'0'}
                                 clickEvent={() => setDataModal( {menu: 'ability' , index: i , view: false} )}/>

                    {/*설명서 사진 */}
                    <MainButtons name={`설명서 이미지 ${!v.manualImg ? '등록' : '변경'}`} width={'120px'}
                                 buttonType={!v.manualImg ? 'cta' : 'popupCta'} margin={'0'}
                                 clickEvent={() => setDataModal( {menu: 'manual' , index: i , view: false} )}/>
                  </div>
                ) )}

              {rowList?.length === 0 &&
                peripheralDataList.map( ( v: any , i: number , arr ) => (
                  <React.Fragment>
                    {
                      <GridTableSelect selected={v.peripheralCode === selectCode}
                                       disabled={rowList.length !== 0 || iconSelect !== - 1}
                                       className={'grid-list'}
                                       checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                       onDoubleClick={() => {
                                         window.location.href = `${authValid() ? `/admin/fas/peripherals/${v?.peripheralCode}` : `/fas/peripherals/${v?.peripheralCode}`}`
                                       }}
                                       onClick={() => {setSelectCode( v?.peripheralCode );}}
                                       onMouseOut={() => setFocusList( - 1 )} onMouseOver={() => setFocusList( i )}>
                        <label className={'selectCheckbox'} onClick={( e ) => e.stopPropagation()}>
                          <ListInnerInput type={'check'} name={'peripheralCode'}
                                          onChange={( e: any ) => singleCheckedFunction( e.target.checked , v?.peripheralCode , setCheckedList , checkedList )}
                                          checked={checkedList.includes( v?.peripheralCode )}/>
                        </label>
                        {/*{validationValue((i+1),0)}*/}
                        {validationValue( v.peripheralType )}
                        {validationValue( v.peripheralNumber )}
                        {validationValue( v.peripheralManufacturer )}
                        {autoTextValueValidation({value: v.peripheralName})}
                        {validationValue( v.manufactureDate )}
                        {validationValue( v.managerName )}
                        {validationValue( v.factoryName )}
                        {validationValue( v.factorySubDivision )}
                        {!v.peripheralImgUrl[0] ?
                          <div>{'-'}</div>
                          :
                          <ListInnerInput type={'clickText'} disabled={rowList.length !== 0}
                                          inputData={'주변장치 보기'} width={'120px'}
                                          clickButton={( e ) => {
                                            e.stopPropagation();
                                            setDataModal( {menu: 'peripheral' , index: i , view: true} )
                                          }}/>
                        }
                        {!v.specImg ?
                          <div>{'-'}</div>
                          :
                          <ViewImageText disabled={rowList.length !== 0}
                                         onClick={( e ) => {
                                           e.stopPropagation();
                                           setDataModal( {menu: 'spec' , index: i , view: true} )
                                         }}>{'스펙 명판 보기'}</ViewImageText>
                        }
                        {!v.abilityImg ?
                          <div>{'-'}</div>
                          :
                          <ViewImageText disabled={rowList.length !== 0}
                                         onClick={( e ) => {
                                           e.stopPropagation();
                                           setDataModal( {menu: 'ability' , index: i , view: true} )
                                         }}>{'능력 명판 보기'}</ViewImageText>
                        }
                        {!v.manualImg ?
                          <div>{'-'}</div>
                          :
                          <ViewImageText disabled={rowList.length !== 0}
                                         onClick={( e ) => {
                                           e.stopPropagation();
                                           setDataModal( {menu: 'manual' , index: i , view: true} )
                                         }}>{'사용설명서 보기'}</ViewImageText>
                        }
                      </GridTableSelect>
                    }
                  </React.Fragment>
                ) )}
            </GridTableComponent>
          </div>
          <div style={{display: 'flex' , justifyContent: 'space-between'}}>
            <div>
              {rowList.length === 0 &&
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                  blockNum={pageBlock} setBlockNum={setPageBlock}
                                  counts={totalPage} disabled={iconSelect !== - 1}/>}
            </div>
            <HorizontalMoveScrollButton
              leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
              rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
          </div>

          {/*</InfiniteScroll>*/}
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default PeripheralsManagement;
const ViewImageText = styled.div<{
  disabled: boolean
}>`
  font-size: 12px;
  text-decoration: underline;
  text-underline-offset: 3px;
  color: #7E84A3;
  pointer-events: ${props => props.disabled ? 'none' : 'inherit'};

  &:hover {
    color: #0058FF;
  }
`
