import React from 'react';
import styled from 'styled-components';
import {useLocation} from "react-router-dom";
import MenuTitle from "../../../../components/title/MenuTitle";
import ControlButton from "../../../../components/button/ControlButton";
import {ContentFrame, SectionFrame} from "../../../../style/StyleComponent";
import noImage from "../../../../asset/image/noImage.svg";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {useTranslation} from "react-i18next";
import ImageDetailPreview from "../../../../components/modal/ImageDetailPreview";


const UserInfoDetail = () => {

    const location = useLocation()
    const {pathname} = location

    const [detailData, setDetailData] = React.useState<{
        infoNum: string,
        modifiedDate: string,
        title: string,
        content:any,
        image:string,
        imageUrl:string[]}>()
    const { t }:any = useTranslation();
    const [imageModalOpen,setImageModalOpen] = React.useState(false);
    const [selectImage, setSelectImage] = React.useState<string>('')

    const imageChange = (index:number) => {
        // @ts-ignore
        setSelectImage(detailData.imageUrl[index])
        setImageModalOpen(true)
    }


    const detailInfo = async () => {
        try {
            const response = await client.post('/infoNotice/detail',{
                infoNum: pathname.split('/').pop(),
            },{
                headers: {Authorization}})
            console.log(response.data)
            setDetailData(response.data.data)
        }catch (e) {
            console.log(e)
        }
    }


    React.useEffect(()=>{
        detailInfo().then()
    },[])


    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const content = detailData?.content.replace(urlRegex, function(url: string) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';

    }
    );

    return (
        <SectionFrame>
            {imageModalOpen &&
                <ImageDetailPreview
                    profile={selectImage}
                    imageUrl={detailData?.imageUrl} clickEvent={()=>setImageModalOpen(false)}/>}
            <MenuTitle title={'공지 & 이벤트'} unitCase={'MenuTitle'}/>
            <ContentFrame>
                <div style={{display:'flex',userSelect:'none'}}>
                    <ViewBox>
                        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'16px'}}>
                            <MenuTitle title={'공지 & 이벤트 자세히보기'} unitCase={'MenuCompTitle'}/>
                            <div style={{display:'flex',gap:'16px'}}>
                                <ControlButton name={'목록'} clickEvent={()=>{window.location.href = `/fas/wiki`}}/>
                            </div>
                        </div>
                        <div style={{display:'flex'}}>
                            <div className={'detail_title'}>{t('게시글 No.')}</div>
                            <div className={'detail_content'}>{detailData?.infoNum}</div>
                            <div className={'detail_title'}>{t('등록일')}</div>
                            <div className={'detail_content'}>{detailData?.modifiedDate}</div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div className={'detail_title'} >{t('제목')}</div>
                            <div className={'detail_content'} style={{width:'100%'}}>{detailData?.title}</div>
                        </div>
                        <div style={{display:'flex',borderBottom:'1px solid #E6E9F4'}}>
                            <div className={'detail_title'} style={{height:'336px'}}>{t('내용')}</div>
                            <div className={'detail_notice'} style={{userSelect:'text',lineHeight:'16px'}}>
                                <div dangerouslySetInnerHTML={{__html: content}}/>
                            </div>
                        </div>
                    </ViewBox>
                    <div style={{width:'1px',backgroundColor:'#e0e0e0',marginRight:'28px'}}/>
                    <div style={{width:'377px', boxSizing:'border-box'}}>
                        <div style={{display:'flex',gap:'64px', alignItems:'center'}}>
                            <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                        </div>
                        <div style={{marginTop:'28px'}}>
                            <div style={{display:'flex', gap:'16px',  flexWrap:'wrap'}}>
                                {detailData?.imageUrl.length !== 0 ?
                                    detailData?.imageUrl.map((item:any, index:number) => {
                                        return(
                                            <div style={{width:'168px', height:'168px', borderRadius:'4px', border:'1px solid #E0E0E0'}}>
                                                <PreviewImage style={{backgroundImage:`url(${item})`}} key={index} onClick={()=>imageChange(index)}/>
                                            </div>
                                        )}
                                    ):
                                    <div style={{width:'168px', height:'168px', borderRadius:'4px', border:'1px solid #E0E0E0'}}>
                                        <PreviewImage style={{backgroundImage:`url(${noImage})`}}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ContentFrame>
        </SectionFrame>
    );
};

export default UserInfoDetail;

const ViewBox = styled.div`
  width: 784px;
  height: 620px;
  padding-right: 28px;
  
  .detail {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      width: 168px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
      font-family: "NanumGothicOTF", sans-serif;
    }

    &_content {
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      width: 224px;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
       
    }
    
 

    &_notice {
      height: 336px;
      width: 100%;
      padding: 8px 16px;
      box-sizing: border-box;
      line-height: 30px;
      border-top: 1px solid #E6E9F4;
      font-size: 14px;
      white-space: pre-wrap;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        width: 21px;
        background: transparent;
      }
    }
    &_answer{
      color:#7E84A3;
      display: flex;
      justify-content: center;
      
    }
  }
    
    
`

const PreviewImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  .delete_button {
    position: absolute;
    box-shadow: 2px -2px 10px rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
    background-color: #ffffff;
    right: 4px;
    top: 4px;
  }
`
