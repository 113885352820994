import React from 'react';
import {DatePickerComp} from "../../style/StyleComponent";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";


interface CalenderProps {
  data: {
    date: Date,
    decreaseMonth(): void,
    decreaseYear(): void
    increaseMonth(): void,
    increaseYear():void
    prevMonthButtonDisabled: boolean,
    nextMonthButtonDisabled: boolean,
  };
  startDate?: any
  endDate?:any
  endPosition?: boolean
  authType?:boolean
}
const CalendarRangeHeader:React.FunctionComponent<CalenderProps> = ({data, startDate, endDate, endPosition,authType}) => {
  const {t}:any = useTranslation();

  const formatDate = (d: Date): string => {
    // if (monthType){
    //   const date = dayjs(d);
    //   const monthIndex = date.month();
    //
    //   const year = date.format(t('YYYY년'));
    //   const month = date.format('MMMM');
    //
    //   return  year + ' ' + month;
    //
    // }else if(yearType){
    //   const date = dayjs(d);
    //   const year = date.format(t('YYYY년'));
    //   return  year;
    // } else {
    const date = dayjs( d );
    const year = date.format( t( 'YYYY' ) );
    const month = date.format( t( 'MM' ) );
    // const day = date.format( t( 'DD' ) );
    return year + '년 ' + month + '월';
  // }
  };

  const currentTime = dayjs().year() === dayjs(data.date).year()
  // const startMonth = dayjs(startDate).month()
  // const endMonth = dayjs(endDate).month()
  return (
    <div>
      {
          <div style={{display:'flex',width:'100%',height:'45px', justifyContent:'space-evenly', alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
              {endPosition? <div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear}
                   style={{pointerEvents: currentTime? 'none' : 'auto',opacity: currentTime? 0.3 : 1}}>
                <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
              </div>
              :
              <div className={'react-datepicker__navigation--previous--year'} onClick={data.decreaseYear}>
                <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
              </div>
              }

              {endPosition?
                <div className={'react-datepicker__navigation--previous'} onClick={data.decreaseMonth}>
                  <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
                </div>
                :
                <div className={'react-datepicker__navigation--previous'} onClick={data.decreaseMonth}>
                  <button disabled={data.prevMonthButtonDisabled} style={{display:'none'}}/>
                </div>}

            </div>
            <div style={{display:'flex',justifySelf:'center',color:authType ? '#000':'#fff'}} className={'react-datepicker__current-month'} >{formatDate(data.date)}</div>

            <div style={{display:'flex',alignItems:'center'}}>
              {endPosition?
                <div className={'react-datepicker__navigation--next'} onClick={data.increaseMonth}>
                  <button disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>
                :
                <div className={'react-datepicker__navigation--next'} onClick={data.increaseMonth}>
                  <button disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>}

              {endPosition?
                <div className={'react-datepicker__navigation--next--year'} onClick={data.increaseYear}>
                  <button disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>
                :
                <div className={'react-datepicker__navigation--next--year'} onClick={data.increaseYear}
                     style={{pointerEvents: currentTime? 'none' : 'auto',opacity: currentTime? 0.3 : 1}}>
                  <button disabled={data.nextMonthButtonDisabled} style={{display:'none'}}/>
                </div>}
            </div>
          </div>}
    </div>
  );
};
export default CalendarRangeHeader;
