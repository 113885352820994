import React , {useRef} from 'react';
import {
	ContentFrame ,
	RegisterButton ,
	validationValue , GridTableComponent , GridTableSelect , tooltipModule
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import {LogDataProps} from "../../../../../types/Fundamental";
import {useTranslation} from "react-i18next";
import {dataValid , locationFilter , numberLocaleString} from "../../../../../function/function";
import TableValue from "../../../../../style/TableValue";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";

const FacilityLog:React.FunctionComponent<LogDataProps> = ({logData, closeModal, height,
																														 buttonActive, authValid}) => {
	const { t }:any = useTranslation();
	const facilityLogTitle= authValid? ['제조번호','제조사','ICT번호','HW','버전','네트워크','최대톤수','업체명','공장명','지역','변경날짜'] :
		['제조번호','제조사','HW','버전','네트워크','최대톤수','공장명','지역','변경날짜']

	const logRef = useRef<any>(null)

	return (
		<React.Fragment>
			<ContentFrame height={height} justify={'space-between'} style={{position:'relative'}}>
			{buttonActive &&
				<div style={{ height:'56px', backgroundColor:`${authValid? '#CFFA61' : '#0058ff'}`,
					color:`${authValid? '#171725' : '#fff'}`, fontWeight:800, fontSize:'18px',
					width:'100%', maxWidth:'1270px', flexDirection:'column', justifyContent:'center',
					borderTopLeftRadius:'6px', borderTopRightRadius:'6px',display:'flex',boxSizing:'border-box',
					padding:'0 30px',position:'absolute',top:0, left:0,marginBottom:'30px'}}>
					{t('변경 이력')}
				</div>}
				<div>
					<div style={{visibility: `${buttonActive? 'hidden' : 'visible'}`}}>
						<MenuTitle title={t('변경 이력')} unitCase={'MenuCompTitle'}/>
					</div>
					<GridTableComponent tempCol={authValid? '160px repeat(2, 88px) 144px 144px 144px repeat(5, 88px)' : '160px 88px repeat(3, 174px) 88px 128px repeat(2, 88px)'}
															height={buttonActive? 394 : 346} scrollWidth={1385} ref={logRef}>
						<div className={'grid-title-unchecked'}>
							{facilityLogTitle.map((v,i)=>(
								<TableValue title={v} key={i} requiredValue={['']}/>
							))}
						</div>
						{logData?.map((v,i)=>(
							<GridTableSelect style={{pointerEvents: i !== 0? 'none' : 'auto'}} logCenterLine={i !== 0}
								className={'grid-list-unchecked-no_icon'}>
								{tooltipModule(dataValid(v.manufacturingNumber,'없음'),18)}
								{tooltipModule(dataValid(v.maker,'없음'),8)}
								{authValid && <div>{(v.ictNumber === '00000' || !v.ictNumber)? '할당 안됨' : v.ictNumber}</div>}
								{/*<div>{v.ictNumber === '00000'? t('할당 안됨' ):  v.ictNumber === ''?  t('할당 안됨') : v.ictNumber}</div>*/}
								<div style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{dataValid(v.hw)}</div>
								{validationValue(v.version)}
								<div style={{overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{dataValid(v.network)}</div>
								{validationValue(numberLocaleString(v.maxTon))}
								{authValid && tooltipModule(dataValid(v.companyName),8)}
								<div>{v.factoryName}</div>
								<div>{locationFilter(v.location)}</div>
								<div>{v.modifiedDate?.split(' ')[0]}</div>
							</GridTableSelect>
						))}
					</GridTableComponent>
				</div>
				{buttonActive && <RegisterButton onClick={closeModal} style={{margin:'0 auto'}}>{t('확인')}</RegisterButton>}
				<div style={{position:'absolute', right: '30px', bottom: buttonActive? '30px' : 'unset', top: buttonActive? 'unset' : '24px' }}>
					<HorizontalMoveScrollButton rightClick={()=> logRef.current.scrollLeft = ( logRef.current.scrollLeft + 120 )} leftClick={()=> logRef.current.scrollLeft = ( logRef.current.scrollLeft - 120 )}/>
				</div>
			</ContentFrame>
		</React.Fragment>
	);
};

export default FacilityLog;