import React from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import CustomerLayoutCont from "../../../containers/admin/fas/company/print/CustomerLayoutCont";
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";

const AdminFasLayOut = () => {
	return (
		<PageContainer>
			<IntegratedSideMenu/>
			<SectionContainer contentContainer={<CustomerLayoutCont/>}/>
		</PageContainer>
	);
};

export default AdminFasLayOut;