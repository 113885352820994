import Notiflix from "notiflix";
import React , {useEffect , useRef , useState} from "react";
import {timeDataSet} from "../common/DataSet";
import dayjs from "dayjs";
import MobileDetect from "mobile-detect";
import {useLocation} from "react-router-dom";
import client from "../common/Config";
import {Authorization} from "../common/authorization";

const upperLocation = ['의정부시','양주시','동두천시','고양시','구리시','남양주시','파주시','포천시','연천군','가평군']

export const closeModal = {open: false, status:'', message:''}

export const initModal = {
	menu: '', index: -1, view: false,specificWord:''
}
export const initError = {
	notice:'',status:-1
}
export const authValid = () =>{
	const location = window.location.href;
	return location.includes('admin')
}

export const affiliateAuth = () =>{
	// @ts-ignore
	return window.localStorage.getItem('auth')?.includes("affiliate")
}

export const locationFilter = (address?: string) => {
	if(address?.includes('서울')){
		return '서울'
	}
	else if(address?.includes('경기')){
		if(upperLocation.some((v)=>(address?.includes(v)))){
			return '경기도 북부'
		}else{
			return '경기도 남부'
		}
	}
	else if(['충청북도','충북'].some((v)=>(address?.includes(v)))){
		return '충청북도'
	}
	else if(['충청남도','충남'].some((v)=>(address?.includes(v)))){
		return '충청남도'
	}
	else if(address?.includes('대전')){
		return '대전광역시'
	}
	else if(address?.includes('인천')){
		return '인천광역시'
	}
	else if(address?.includes('광주')){
		return '광주광역시'
	}
	else if(address?.includes('부산')){
		return '부산광역시'
	}
	else if(['대구광역시','대구'].some((v)=>(address?.includes(v)))){
		return '대구광역시'
	}
	else if(address?.includes('울산')){
		return '울산광역시'
	}
	else if(address?.includes('세종특별자치시')){
		return '세종특별자치시'
	}
	else if(['전라남도','전남'].some((v)=>(address?.includes(v)))){
		return '전라남도'
	}
	else if(['전라북도','전북'].some((v)=>(address?.includes(v)))){
		return '전라북도'
	}
	else if(['경상북도','경북'].some((v)=>(address?.includes(v)))){
		return '경상북도'
	}
	else if(['경상남도','경남'].some((v)=>(address?.includes(v)))){
		return '경상남도'
	}
	else if(address?.includes('제주특별자치도')){
		return '제주도'
	}
	else if(address?.includes('강원')){
		return '강원도'
	}
	else{
		return '지역 미선택'
	}
}
export const solutionTitleFunc = (type:string, solutionMenu: string) => {
	switch (type) {
		case 'solution' : return (solutionMenu === 'P-BAS' && 'Press-Before After Service') ||
			(solutionMenu === 'R-BAS' && 'Robot-Before After Service') ||
			(solutionMenu === 'C-BAS' && 'CNC-Before After Service') ||
			(solutionMenu === 'V-MS' && 'Vision Monitoring System') ||
			(solutionMenu === 'A-BAS' && 'Automation-Before After Service') ||
			(solutionMenu === 'F-EMS' && 'Factory-Energy Management System') ||
			(solutionMenu === 'P-MS' && 'Press-Monitoring System') ||
			(solutionMenu === 'R-MS' && 'Robot Monitoring System') ||
			(solutionMenu === 'C-MS' && 'CNC Monitoring System') ||
			(solutionMenu === 'A-MS' && 'Automation Monitoring System') ||
			(solutionMenu === 'MES' && 'Manufacturing Execution System') ||
			(solutionMenu === 'i-MES' && 'AI Manufacturing Execution System') ||
			(solutionMenu === 'i-BAS' && 'AI Before/After System')

		case 'ko' : return (solutionMenu === 'P-BAS' && '프레스 예지보전 서비스') ||
			(solutionMenu === 'R-BAS' && '로봇 예지보전 서비스') ||
			(solutionMenu === 'C-BAS' && 'CNC 예지보전 서비스') ||
			(solutionMenu === 'A-BAS' && '자동화 설비 예지보전 서비스') ||
			(solutionMenu === 'F-EMS' && '공장 에너지관리 시스템') ||
			(solutionMenu === 'P-MS' && '프레스 모니터링 시스템') ||
			(solutionMenu === 'R-MS' && '로봇 모니터링 시스템') ||
			(solutionMenu === 'C-MS' && 'CNC 모니터링 시스템') ||
			(solutionMenu === 'A-MS' && '자동화 설비 모니터링 시스템') ||
			(solutionMenu === 'V-MS' && '비전 모니터링 시스템') ||
			(solutionMenu === 'MES' && '생산관리 시스템') ||
			(solutionMenu === 'i-MES' && 'AI 생산관리 시스템') ||
			(solutionMenu === 'i-BAS' && 'AI 예지보전 서비스')

		case 'color' : return (solutionMenu === 'P-BAS' && '#54BEEA') ||
			(solutionMenu === 'R-BAS' && '#4AA261') ||
			(solutionMenu === 'C-BAS' && '#E79F3C') ||
			(solutionMenu === 'A-BAS' && '#6963B0') ||
			(solutionMenu === 'F-EMS' && '#CC5642') ||
			(solutionMenu === 'P-MS' && '#0058FF') ||
			(solutionMenu === 'R-MS' && '#005D18') ||
			(solutionMenu === 'C-MS' && '#84520E') ||
			(solutionMenu === 'A-MS' && '#080067') ||
			(solutionMenu === 'V-MS' && '#DD33BC') ||
			(solutionMenu === 'MES' && '#000000') ||
			(solutionMenu === 'i-MES' && 'linear-gradient(117deg, #000000 0%, #B9B6B6 100%)') ||
			(solutionMenu === 'i-BAS' && 'linear-gradient(117deg, #021436 0%, #0058FF 100%)')


		case 'menu' : return (solutionMenu === 'P-BAS' && '프레스') ||
			(solutionMenu === 'R-BAS' && '로봇') ||
			(solutionMenu === 'C-BAS' && 'CNC') ||
			(solutionMenu === 'A-BAS' && '자동화 설비') ||
			(solutionMenu === 'F-EMS' && '에너지 관리 시스템') ||
			(solutionMenu === 'P-MS' && '')
	}
}



// //취소시
// export const popupRegisterFunction = ( existingState:any, changeState:any, setState:Function, pageUrl:string |any,imageState?:boolean,elseImageState?:boolean) => {
// 	if(JSON.stringify(existingState).length === JSON.stringify(changeState).length && !imageState && !elseImageState){
// 		window.location.href = pageUrl
// 	}else{
// 		setState({menu:'select',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})
// 	}
// }
//
// export function PageBackLimitFunction(dataModal:Function,title:string){
// 	const handleEvent = () => {
// 		window.history.pushState(null, '',window.location.href)
// 		dataModal({menu:title,message:'작성중인 내용이 저장되지 않았습니다.\n"뒤로가기"를 하시겠습니까?'})
// 	}
// 	//@ts-ignore
// 	useEffect(() => {
// 		window.history.pushState(null, '',window.location.href)
// 		window.addEventListener('popstate', handleEvent)
// 		return () => {
// 			window.addEventListener('popstate', handleEvent)
// 		}
// 	}, [])
// }
//
// //리스트 행 뒤로가기
// export function PageRowRegisterBlock( state:number, iconSelect:number,dataModal:Function, title:string){
// 	const preventGoBack = () => {
// 		if(state === 0 && iconSelect === -1){
// 			window.history.back()
// 		}else{
// 			window.history.pushState(0, ``, document.referrer);
// 			dataModal({menu:title,message:'작성중인 내용이 저장되지 않았습니다.\n"뒤로가기"를 하시겠습니까?'})
// 		}
// 	};
// 	useEffect(() => {
// 		window.history.pushState(null, '', window.location.href);
// 		window.addEventListener('popstate', preventGoBack);
// 		return () => window.removeEventListener('popstate', preventGoBack);
// 	}, [state,iconSelect]);
// }
//
// export function ModalPageBackFunction(dataModal:Function,title:string){
// 	const handleEvent = () => {
// 		window.history.pushState(null, '',document.referrer)
// 		dataModal({menu:title,message:'작성중인 내용이 저장되지 않았습니다.\n"뒤로가기"를 하시겠습니까?'})
// 	}
// 	//@ts-ignore
// 	useEffect(() => {
// 		window.history.pushState(null, '',window.location.href)
// 		window.addEventListener('popstate', handleEvent)
// 		return () => {
// 			window.addEventListener('popstate', handleEvent)
// 		}},[])
// }

// export const BeforeUnloadFunction = ( modalState: boolean | undefined , closeState?: string | undefined , ) =>{
// 	React.useEffect(()=>{
// 		const preventClose = (e:BeforeUnloadEvent)=>{
// 			e.preventDefault();
// 			if(closeState){
// 				return
// 			}
// 			e.returnValue = true;
// 		}
// 		window.addEventListener(  "beforeunload" , preventClose );
// 		return () => {
// 			window.removeEventListener( "beforeunload" , preventClose );
// 		};
// 	},[modalState])
// }

export const inputOnChange = (e:any, state:any, setState:any) => {
	const {value, name} = e.target
	setState({
						 ...state,
						 [name]: value
					 });
}

/** checked : 체크박스 활성화 event //
 * arrayData : 현재 목록 데이터 //
 * id: 비교가능한 중복되지 않은 유일한 값 //
 * setState : 체크 비교할 state 함수 //
 */
export const allCheckedFunction = (checked: boolean, arrayData: any[], id:any, setState: Function ) => {
	if(checked){
		const idArray: string[] = []
		arrayData.forEach((el)=> idArray.push(el[id]))
		setState(idArray)
	}else{
		setState([])
	}
}

// checked : 체크박스 활성화 event // dataList : 현재 목록 데이터 // checkedList: 체크목록 // id: 비교가능한 중복되지 않은 유일한 값 // setState : 체크 비교할 state 함수 //


export const allPersistCheckedFunction = (checked: boolean, state:{dataList: any[] ,checkedList:any[], id: string, setState: Function}) => {
	const filteredID = state.dataList.map((el)=>(el[state.id])).filter(value => !state.checkedList.includes(value))
	const exceptedID = state.checkedList.filter(value => !state.dataList.map((el)=>(el[state.id])).includes(value))
	if(checked){
		state.setState([...state.checkedList, ...filteredID])
	}
	else{
		state.setState(exceptedID)
	}
}

export const allCheckedState = (state:{dataList:any[], checkedList:any[], id: string}) => {
	return state.dataList.map((el)=>(el[state.id])).every((el) => state.checkedList.includes(el))
}

export const KepcoTimeResetFunc =(setTime:Function)=>{
	if(timeDataSet.firstTime.includes(`${dayjs().locale('ko').format('mm')}`)){
		setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:00"));
	}else if(timeDataSet.secondTime.includes(`${dayjs().locale('ko').format('mm')}`)){
		setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:15"));
	}else if(timeDataSet.thirdTime.includes(`${dayjs().locale('ko').format('mm')}`)){
		setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:30"));
	}else if(timeDataSet.forthTime.includes(`${dayjs().locale('ko').format('mm')}`)){
		setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:45"));
	}

	const clockTimer = setInterval(()=>{
		if(timeDataSet.firstTime.includes(`${dayjs().locale('ko').format('mm')}`)){
			setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:00"));
		}else if(timeDataSet.secondTime.includes(`${dayjs().locale('ko').format('mm')}`)){
			setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:15"));
		}else if(timeDataSet.thirdTime.includes(`${dayjs().locale('ko').format('mm')}`)){
			setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:30"));
		}else if(timeDataSet.forthTime.includes(`${dayjs().locale('ko').format('mm')}`)){
			setTime(dayjs().locale('ko').format("YYYY년 MM월 DD일 (ddd) HH:45"));
		}
	},60000)
	return ()=> clearInterval(clockTimer)
}


/**
 * checked : 체크박스 활성화 event //
 * id: 비교가능한 중복되지 않은 유일한 값 //
 * setState : 체크 비교할 state 함수 //
 * state : 해당 state 값 //
 */

export const singleCheckedFunction = (checked:boolean, id: any, setState: Function, state: any) => {
	if(checked){
		setState((prev:any) => [...prev, id])
	}else{
		setState(state.filter((el:any)=>(el !== id)))
	}
}



export const removeCheckedFunction = (setState:Function, state:any, setSelectState: Function, selectState:any, id:any) => {
	setState(state.filter((v:any)=> !selectState.includes(v[id])))
	setSelectState([])
}

export const phoneNumberFormat = (numberValue: string) => {

	if(numberValue?.substring(0,2) === '01'){
		return '###-####-####'
	}
	else{
		if(numberValue?.replace(/[-\s]/g, '').length >= 12){
			return '####-####-####'
		}
		if(numberValue?.replace(/[-\s]/g, '').length  === 11){
			return '###-####-#####'
		}
		if(numberValue?.replace(/[-\s]/g, '').length === 10){
			return '###-###-#####'
		}
		if(numberValue?.replace(/[-\s]/g, '').length === 9){
			return '##-###-#####'
		}
		// if(numberValue?.substring(0,2) === '02'){
		// 	return '##-###-####'
		// }else{
		// 	return '###-###-####'
		// }
	}
}

// export const phoneNumberFormat = (numberValue: string) => {
// 	if(numberValue?.substring(0,2) === '01'){
// 		return '###-####-####'
// 	}else{
// 		if(numberValue?.substring(0,2) === '02'){
// 			return '##-###-####'
// 		}else{
// 			return '###-###-####'
// 		}
// 	}
// }

export const workStateValue = (state: string) => {
	switch (state ) {
		case 'before': return '작업 전'
		case 'working': return '작업 중'
		case 'activing': return '작업 중'
		case 'pause' : return '일시 정지'
		case 'end': return '종료 됨'
	}
}

export const filterCheckedRow = (rowState: any[], checkList: any[], id: string|number) => {
	return rowState.filter((v)=> checkList.find((el)=> v[id] === el))
}

export const listLoadingCircle = (title?: string) => Notiflix.Loading.circle(
	title,{backgroundColor: 'rgba(255,255,255,0.3)',svgSize:'128px', messageColor:'rgba(23,23,37,1)',
		messageFontSize:'14px',   svgColor:'rgba(0,88,255,0.8)',})

export const dataAccessLoading = (title?: string) => Notiflix.Loading.dots(
	title,{backgroundColor: 'rgba(255,255,255,0.3)',svgSize:'144px', messageColor:'rgba(23,23,37,1)',
		messageFontSize:'14px',   svgColor:'rgba(0,88,255,0.8)'})

export const  uploadingLoading = (title?: string) => Notiflix.Loading.standard(
	title,{backgroundColor: 'rgba(255,255,255,0.3)',svgSize:'128px', messageColor:'rgba(23,23,37,1)',
		messageFontSize:'14px',   svgColor:'rgba(0,88,255,0.8)'})

export const modalListLoading = (title?: string) => Notiflix.Loading.pulse(title,{backgroundColor:'rgba(255,255,255,0.03)', svgColor:'#cffa61'})

export const loadingRemove = (setTime?: number) => {
	Notiflix.Loading.remove(setTime)
}

export const dataValid = (value: string|number|any, validation?: string|number|string[]|number[]|any) => {
	return !value ? validation ?? '-' : value
}

export const buttonAuthValid = (type:string,menu:string,childButton?:any) => {
	const storedData = window.localStorage.getItem('authoritiesList');
	const menuItem =  storedData ? JSON.parse(storedData)?.find(( item: { menu: string; }) => item.menu === menu) : null;
	if(window.localStorage.getItem('auth') === 'adminUser' || window.localStorage.getItem('auth') === 'system'){
		if (menuItem) {
			if(type === 'create'){
				return menuItem.create === 'impossible';
			}else if(type === 'update'){
				return menuItem.update === 'impossible';
			}else if(type === 'delete'){
				return menuItem.delete === 'impossible';
			}else if(type === 'rowDelete'){
				return menuItem.create === 'possible' && menuItem.delete === 'impossible';
			}else if(type === 'read'){
				return false
			}
		}else{
			return true
		}
	}else{
		return false
	}
}

export const decimalValid =(value:string | number |undefined, fixedType?:boolean)=>{
	//@ts-ignore
	return value.toLocaleString(undefined, {
		minimumFractionDigits: fixedType ? 1 : 2,
		maximumFractionDigits: fixedType ? 1 : 2
	}).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const onChangeArray = (index: number, e: React.ChangeEvent<HTMLInputElement>, state: any, setState: Function, trim?:boolean) => {
	const {value, name} = e.target
	const rowsInput: any = [...state]
	rowsInput[index][name] = trim? value.trim() : value
	setState(rowsInput)
}

export const onMultiRegChangeArray = (index: number, e: React.ChangeEvent<HTMLInputElement>, state: any, setState: Function, checkedState:any, setCheckedState:Function, id:string|number, trim?:boolean) => {
	const {value, name} = e.target
	const rowsInput: any = [...state]
	rowsInput[index][name] = trim? value.trim() : value
	setState(rowsInput)
	if(!checkedState.includes(id)){
		setCheckedState(checkedState.concat(id))
	}
}
export const onChangeNumberArray = (index: number, e: React.ChangeEvent<HTMLInputElement>, state: any, setState: Function) => {
	const {value, name} = e.target
	const rowsInput: any = [...state]
	rowsInput[index][name] = Number(value.replace(/[^0-9]/g, '').trim()).toString()
	setState(rowsInput)
}

export const onChangeObjectNumberArray = (index: number, e: React.ChangeEvent<HTMLInputElement>, state: any, setState: Function, key:string) => {
	const {value, name} = e.target
	const rowsInput: any = [...state]

	if(name.includes(key)){
		const splitInput = name.split('.')[1]
		rowsInput[index][key][splitInput] = value.replace(/[^0-9]/g, '')
	}
	else{
		rowsInput[index][name] = value.replace(/[^0-9]/g, '')
	}
	setState(rowsInput)
}

//    const handleChange = (e:any) => {
//         const rawValue = e.target.value; // 입력된 원시 값 (숫자)
//         const formattedValue = formatNumber(rawValue); // 콤마와 소수점이 추가된 형식의 값
//         setInputValue(formattedValue);
//     };
//
export const formatNumber = (value:any) => {
	let cleanedValue = value.replace(/[^\d.]/g, '');
	let parts = cleanedValue.split('.');
	// 0을 처음에 입력하지 못하도록 처리
	if (parts[0] === '.') {
		parts.unshift('0');
	} else if (parts[0] === '') {
		parts[0] = '0';
	}
	if (parts[0] !== '' && parts[0] !== '-') {
		parts[0] = parseInt(parts[0], 10).toString(); // 숫자로 변환하여 다시 문자열로 저장
	}

	parts[0] = parts[0]?.slice(0, 9);
	parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (parts.length > 1) {
		parts[1] = parts[1]?.slice(0, 5);
	}

	const formattedParts = parts.slice(0, 2);
	return formattedParts.join('.');
};


export const handleDecimalChange = (index:number,e:React.ChangeEvent<HTMLInputElement>,state:any,setState:Function,unit:string)=>{
	const { value, name } = e.target;
	let formattedValue = formatNumber(value);
	if (unit === 'ea') {
		formattedValue = formattedValue.split('.')[0];
	}
	const rowsInput = [...state];
	rowsInput[index][name] = formattedValue.replace(/,/g,'');
	setState(rowsInput);
}

export const decimalFormat = (value:any) =>{
	if(typeof value === 'string'){
		return dataValid(value,'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	}else if(typeof value === 'number'){
		return dataValid(value,'').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}

export const onChangeDecimalArray = (index: number, e: React.ChangeEvent<HTMLInputElement>, state: any, setState: Function) => {
	const {value, name} = e.target
	const rowsInput: any = [...state]
	// 정규식 수정 - 숫자와 '.' 만 입력이 가능하고, 다른 값을 입력 시 빈문자열 처리,
	// .이 입력되기 전 4자리수 까지 입력이 가능, 숫자를 입력하기 전에는 . 을 입력할 수 없도록 함.
	// 0이 먼저 입력되고 그다음 숫자가 입력되면 앞에 0이 지워지도록 함. ex(01 -> 1, 00 -> 0)
	// 소수점이 입력되고 최대 2자리수 까지 숫자만 입력이 되도록 함.
	rowsInput[index][name] = value.replace(/^\.$|^(?!\d)|[^0-9.]/g, '').replace(/^0+(?=\d)/, '').replace(/^(\d{0,9})(\.\d{0,5})?.*$/g, '$1$2')
	setState(rowsInput)
}

// 키값은 'codeDoubleCheck' 로 해야함, 만약 변경시, 다른 기본정보에서 사용중인 키값도 변경해야함.
export const onChangeCheck = (index: number, state: any, setState: Function) => {
	const rowsInput: any = [...state]
	rowsInput[index]['codeDoubleCheck'] = false
	setState(rowsInput)
}

export const numberLocaleString = (value: string|number|undefined, limit?:number) => {
	return Number(dataValid(value,0)).toLocaleString('ko-KR',{maximumFractionDigits: limit ?? 5})
}

export const numberFormatScale = (value: string|number|undefined) => {
	return Number(dataValid(value,0)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
// console.log(new Set(rowList.filter((v) => v.customerName).map((v)=>v.customerName)))

export const hasDuplicate = (list:any[],propsKey:string) => {
	const moldProblemTypes = new Set();
	for (const item of list) {
		// moldProblemType이 비어있지 않은 경우에만 중복을 확인
		if (item[propsKey] && moldProblemTypes.has(item[propsKey])) {
			return true; // 중복이 있으면 true 반환
		} else {
			moldProblemTypes.add(item[propsKey]);
		}
	}
	return false; // 중복이 없으면 false 반환
};

// export const duplicateValueCheck = (array: any[], key: string, checkList:any[]) => {
// 	return new Set(array.filter((v)=>v[key]).map((v)=>v[key])).size !== checkList.length
// }
//compareKey : 입력하는 값
export const duplicateInDoubleCheck = (array: any[], key: string, compareKey:string, checkList:any[]) => {
	return new Set(array.filter((v)=>v[key]).map((v)=>(v[key] + v[compareKey]))).size !== checkList.length
}

export const rowListDropDirection = (arr:any[], index:number, dataArr?:any[]) => {
	if(dataArr){
		if(
			(dataArr?.length === 0 && arr.length === 4) ||
			(dataArr?.length === 1 && arr.length === 4) ||
			(dataArr?.length === 2 && arr.length === 4) ||
			(dataArr?.length === 2 && arr.length === 5) ||
			(dataArr?.length === 2 && arr.length >= 6)
		){
			return (arr.length -1) === index
		}
		else if(
			(dataArr?.length === 0 && arr.length === 5) ||
			(dataArr?.length === 1 && arr.length === 5) ||
			(dataArr?.length === 1 && arr.length >= 6)
		){
			return (arr.length -2) === index ||  (arr.length -1) === index
		}
		else if(dataArr?.length === 0 && arr.length >= 6){
			return (arr.length -3) === index || (arr.length -2) === index || (arr.length -1) === index
		}
	}else{
		if(arr.length >= 6){
			return (arr.length -3) === index || (arr.length -2) === index || (arr.length -1) === index
		}
		else if(arr.length === 5){
			return (arr.length -2) === index || (arr.length -1) === index
		}
		else if(arr.length === 4){
			return (arr.length -1) === index
		}
	}
}

export const billOfMaterialsDropDirection = (arr:any[], index:number, compareArr: any[], register?:boolean) => {
	if(register){
		//등록 행 추가
		if(
			(compareArr.length === 1 && arr.length === 2) ||
			(compareArr.length >= 2 && arr.length === 1)
		){
			return (arr.length -1) === index
		}else if(
			(compareArr.length >= 2 && arr.length >= 2) || (compareArr.length === 1 && arr.length >= 3) ||
			(compareArr.length === 0 && arr.length >= 4)
		){
			return (arr.length -2) === index ||  (arr.length -1) === index
		}
	}else{
		// 등록된 행이 있는 경우
		if((arr.length >= 4 && compareArr.length === 0)){
			return (arr.length -2) === index ||  (arr.length -1) === index
		}
		else if(
			(arr.length >= 3 && compareArr.length === 1)
		){
			return (arr.length -1) === index
		}
	}
}


export const customerCount = (customer:any) => customer?.filter((v:any,i:number,arr:any)=> arr?.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)
// authValid? 346 : totalPage <= 1? 538 : page > 1 ? 538 : 534
export const listHeightValid = (auth:boolean, page:{total: number, page: number}, noneScrollX_Exist?: boolean) => {
	return auth? noneScrollX_Exist? 336 : 346 : page.total <= 1? noneScrollX_Exist? 528 : 538 : page.page > 1? noneScrollX_Exist? 528 : 538 : noneScrollX_Exist? 524 : 534
}

export const decimalNumberValid = (value: string | any) => {
	return value?.includes('.')? value : numberLocaleString(value)
}

//임시 토스트 팝업
export const toastNotify = (text:string) => {
	Notiflix.Notify.init( {
													width: '336px' ,
													position: 'center-center' ,
													opacity: 1 ,
													borderRadius: '40px' ,
													rtl: false ,
													timeout: 3000 ,
													messageMaxLength: 200 ,
													backOverlay: false ,
													showOnlyTheLastOne: false ,
													clickToClose: false ,
													ID: 'NotiflixNotify' ,
													className: 'notiflix-notify' ,
													zindex: 4001 ,
													fontFamily: 'Nanum Gothic' ,
													fontSize: '13px' ,
													cssAnimation: true ,
													cssAnimationDuration: 400 ,
													cssAnimationStyle: 'fade' ,
													closeButton: false ,
													useIcon: true ,
													useFontAwesome: false ,
													fontAwesomeIconStyle: 'basic' ,
													fontAwesomeIconSize: '40px' ,
													success: {
														background: '#e8eaf3' ,
														textColor: '#000' ,
														childClassName: 'notiflix-notify-success' ,
														notiflixIconColor: '#0058ff' ,
													} ,
													failure: {
														background: '#f9e1e5' ,
														textColor: '#000' ,
														childClassName: 'notiflix-notify-failure' ,
														notiflixIconColor: '#ff0000'
													} ,
													warning: {
														background: '#ffeed5' ,
														textColor: '#000' ,
														childClassName: 'notiflix-notify-warning' ,
														notiflixIconColor: '#f99e27' ,
													} ,
													info: {
														background: '#e8eaf3' ,
														textColor: '#000' ,
														childClassName: 'notiflix-notify-info' ,
														notiflixIconColor: '#0058ff' ,
													} ,
												} );
	Notiflix.Notify.success( text )
	Notiflix.Notify.failure( text )
	Notiflix.Notify.warning( text )
	Notiflix.Notify.info( text )
}


export function useWindowResizeScale(baseWidth: number = 1600, minWidth: number = 425) {
	const [scale, setScale] = useState(1);
	const componentRef = useRef(null);

	useEffect(() => {
		const updateScale = () => {
			const width = Math.max(window.innerWidth, minWidth);

			if (width < baseWidth) {
				const scaleWidth = width / baseWidth;
				setScale(scaleWidth);

				if (componentRef.current) {
					// @ts-ignore
					componentRef.current.style.transform = `scale(${scaleWidth})`;
					// @ts-ignore
					componentRef.current.style.transformOrigin = 'top left';
				}
			} else {
				setScale(1);

				if (componentRef.current) {
					// @ts-ignore
					componentRef.current.style.transform = 'none';
				}
			}
		};

		window.addEventListener('resize', updateScale);
		updateScale();

		return () => {
			window.removeEventListener('resize', updateScale);
		};
	}, [baseWidth, minWidth, componentRef]);

	return { scale, ref: componentRef };
}


// 접속 대상 기기가 터치디바이스인지 확인하는 js함수
export const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

export const isMobileDevice = () =>{
		const detectFun = new MobileDetect(window.navigator.userAgent)
		const ua = window.navigator.userAgent;
		if(window.screen.width >= 1920){
			return false
		}else{
			if(detectFun.mobile()){
				return true
			}else{
				if(ua.indexOf('Safari') > 0){
					return window.screen.width < 1024;
				}
			}
		}
}

export const DateFormatter = (date:Date, delimiter: string) => {
	return date.getFullYear() +
		delimiter + ( (date.getMonth()+1) < 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1) ) +
		delimiter + ( (date.getDate()) < 9 ? "0" + (date.getDate()) : (date.getDate()) );
}

export const menuVisitLogRegister = async ( id: string | null , menu: string  ) => {
	try {
		const response = await client.post('/data/log/register',{
			id: id,
			activity:'조회',
			menu: menu
		},{headers:{Authorization}})
		console.log(response.data)
	}
	catch ( e ) {
		console.log(e)
	}
}


//다중 등록 내에서 입력된 정보를 제거할때, 제거시킬 행에 담긴 정보를 초기화 한 뒤 마지막 index 부분으로 보내는 기능
// initialObject = 초기화 하여 넣을 값 적용
export const handleRowDelete = (rowList:any[], setRowList:Function, checkedList:number[], setCheckedList:Function, initialObject:any) => {
	const updatedRowList = rowList.filter(item => !checkedList.includes(item.id));
	// 삭제된 항목들을 다시 생성하여 추가
	checkedList.forEach(( deletedId: number | undefined) => {
		const deletedItemIndex = rowList.findIndex(item => item.id === deletedId);
		if (deletedItemIndex !== -1) {
			updatedRowList.push( {...rowList[deletedItemIndex], ...initialObject});
		}
	});
	// 상태 업데이트
	setRowList(updatedRowList);
	setCheckedList([]);
}































