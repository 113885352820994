import React , {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Modal_View_Type , MoldInfoType , ProductInfoType} from "../../../../../types/Fundamental";
import {
  authValid ,
  dataAccessLoading , dataValid ,
  initModal ,
  loadingRemove ,
  numberLocaleString
} from "../../../../../function/function";
import {useRecoilState , useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import styled from "styled-components";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import dayjs from "dayjs";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import IntegratedSearchModal from "../../../../../components/modal/IntegratedSearchModal";
import MoldAssetDocModal from "../../../../../components/modal/MoldAssetDocModal";
import MoldMultipleReg from "./MoldMultipleReg";

const MoldRegistration = () => {
  const {t}:any = useTranslation()
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData);
  // const [selectedCompany, setSelectedCompany] = useRecoilState<any>()
  const [imageKeyState,setImageKeyState] = React.useState<{assemblyImages:string[],disassemblyImages:string[],assemblyImagesUrl:string[],disassemblyImagesUrl:string[]}>({assemblyImages:[],disassemblyImages:[],assemblyImagesUrl:[],disassemblyImagesUrl:[]})
  const [selectProductData,setSelectProductData] = React.useState<ProductInfoType | any>({customer:[]})
  const [moldRegState, setMoldRegState] = useState<any>({
                                                                   companyCode: selectedCompany.companyCode , manufacturer:'',productionDate:null,
                                                                   moldCode: '' ,assetType:'',specification:'',weight:'',material:'',
                                                                   moldSerialCode: '' ,certification:'',
                                                                   moldName : '' ,amount:'',
                                                                   cavity: '' ,
                                                                   spm: '' ,notice:'',
                                                                   checkStrokes: '' ,
                                                                   slideCounter: '' ,
                                                                   checkCycle:'' ,
                                                                   currentStrokes: '' ,
                                                                   productCode:dataValid(selectProductData.productCode,'') ,
                                                                   maxStrokes:'' ,
                                                                   codeDoubleCheck: false
                                                                 })
  // const [moreProduct , setMoreProduct] = useState<MoldInfoType[]>([])

  const [multipleReg,setMultipleReg] = useState<boolean>(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string,trim?:boolean) => {
    const {name, value} = e.target
    setMoldRegState({...moldRegState,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? value.replace(/[^0-9.]/g, '') : trim ? value : value.trim()})
  }

  const serialDoubleCheck = async (serialCode: string) => {
    try {
      const response = await client.post('/mold/serial/check', {
        companyCode: selectedCompany.companyCode,
        moldSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 금형 CODE 입니다.' });
        setMoldRegState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
      }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 금형 CODE 입니다.' });
        setMoldRegState((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };
  // const moldProductViewRes = async (moldCode: string) => {
  //   modalListLoading()
  //   try {
  //     const response = await client.get(`/mold/product/view/${moldCode}` , {
  //       headers: {Authorization}
  //     })
  //     console.log(response.data.data)
  //     setMoreProduct(response.data.data.row)
  //     loadingRemove()
  //   }
  //   catch (e) {
  //     console.log(e)
  //   }
  // }

  const MoldRegisterRes = async () => {
    if(!moldRegState.moldSerialCode){
      setDataModal( {menu: 'confirm' , message: '금형 CODE를 입력해 주세요.'} )
    } else if(!moldRegState.codeDoubleCheck){
      setDataModal( {menu: 'confirm' , message: '금형 CODE 중복확인을 해주세요.'} )
    } else if(!moldRegState.moldName){
      setDataModal( {menu: 'confirm' , message: '금형명을 입력해 주세요.'} )
    } else if(!moldRegState.cavity || moldRegState.cavity === '0'){
      setDataModal( {menu: 'confirm' , message: '캐비티를 입력해 주세요.'} )
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post( `/mold/register` , [{
          ...moldRegState,
          assemblyImages:dataValid(imageKeyState.assemblyImages,[]),
          disassemblyImages:dataValid(imageKeyState.disassemblyImages,[]),
        }] , {
                                              headers: {Authorization}
                                            } )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'warn' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message:'등록 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      }
      catch (e: any) {
        console.log ( e )
        setDataModal( {menu: 'warn' , message: e.message} )
      }
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = authValid() ? '/admin/fas/mold' : '/fas/mold'}}/>}

      {dataModal.menu === 'success' ? <ToastPopup text={t( dataModal.message )} state={'success'}
                                                  closeEvent={() => {
                                                    setDataModal( initModal );
                                                    if( dataModal.message?.includes( '등록' ) ){
                                                      window.location.href = authValid() ? '/admin/fas/mold' : '/fas/mold'
                                                    }
                                                  }}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => setDataModal( initModal )}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => setDataModal( initModal )}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'productSelect' &&
          <IntegratedSearchModal authValid={authValid()}
                                 menuArrayType={'onlyProduct'}
                                 receiveData={( data ) => {
                                   setMoldRegState( ( prev: any ) => ( {
                                     ...prev ,
                                     productCode: data.productCode ,
                                     customer: data.customer ,
                                     facility: [{facilityName: data.facilityName}] ,
                                     processName: data.processName
                                   } ) );
                                   setSelectProductData( data )
                                 }}
                                 closeEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'moldAsset' &&
          <MoldAssetDocModal
              facilityViewType={true}
              cancelEvent={() => setDataModal( initModal )}
              imageUrlArray={imageKeyState}
              moldAssetDocData={moldRegState}/>}

      <div style={{display: 'flex' , justifyContent: 'space-between'}}>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 금형 기본정보 > 등록' + ( multipleReg ? ' (다중)' : '' )}
                   unitCase={'MenuTitle'}/>
        {multipleReg ?
          <div style={{display: 'flex'}}>
            <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                         clickEvent={() => {
                           setDataModal( {
                                           menu: 'cancel' ,
                                           message: '목록으로 이동 하시겠습니까?'
                                         } )
                         }}/>
            <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                         clickEvent={() => setMultipleReg( false )}/>
          </div>
          :
          <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                       clickEvent={() => setMultipleReg( true )}/>
        }
      </div>
        <ContentFrame height={'680px'}>
          {multipleReg? <MoldMultipleReg/>
            :
          <div style={{display: 'flex' , height: '620px'}}>
            <RegisterComp>
              <div style={{
                display: 'flex' ,
                justifyContent: 'space-between' ,
                alignItems: 'center' ,
                marginBottom: '16px'
              }}>
                <MenuTitle title={'금형 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <div style={{display: 'flex'}}>
                    <MainButtons name={'관리대장 미리보기'} width={'136px'}
                                 clickEvent={() => {setDataModal( {menu: 'moldAsset'} )}}/>
                    <MainButtons name={'취소'} width={'56px'}
                                 clickEvent={() => {
                                   setDataModal( {
                                                   menu: 'cancel' ,
                                                   message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                                 } )
                                 }}/>
                    <MainButtons name={'저장'} buttonType={'cta'} marginDis={true} width={'56px'}
                                 clickEvent={MoldRegisterRes}/>
                  </div>
                </div>
              </div>
              <div className={'scroll_func'}>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 392px)'}}>
                  <RegisterComponent type={'text'} title={'제작처'} name={'manufacturer'}
                                     value={moldRegState?.manufacturer}
                                     paddingRight={true} required={false} onChange={onChange}
                                     placeholder={'제작처를 입력해주세요.'}/>
                  <RegisterComponent type={'calendar'} name={'productionDate'} selectDate={moldRegState?.productionDate}
                                     value={moldRegState?.productionDate} title={'제작일'} top={- 200} tabIndex={- 1}
                                     onChange={( v ) => setMoldRegState( ( prev: any ) => ( {
                                       ...prev ,
                                       productionDate: dayjs( v ).format( 'YYYY-MM-DD' )
                                     } ) )}
                  />
                  <RegisterComponent type={'doubleCheck'} name={'moldSerialCode'} value={moldRegState.moldSerialCode}
                                     width={'208px'}
                                     title={t( '금형 CODE' )} placeholder={'금형코드를 입력해주세요.'}
                                     buttonName={moldRegState.codeDoubleCheck ? '확인됨' : '중복확인'}
                                     onChange={( e ) => setMoldRegState( ( prev: any ) => ( {
                                       ...prev ,
                                       moldSerialCode: e.target.value ,
                                       codeDoubleCheck: false
                                     } ) )}
                                     clickEvent={() => {serialDoubleCheck( moldRegState.moldSerialCode ).then()}}
                                     required={true}
                                     disabled={( !moldRegState.moldSerialCode || moldRegState.codeDoubleCheck )}/>

                  <RegisterComponent type={'textButton'} name={'productName'} value={selectProductData.productName}
                                     width={'132px'}
                                     title={t( '품목명' )} placeholder={'품목을 선택해주세요.'} buttonWidth={'60px'}
                                     buttonMargin={'0'}
                                     clickEvent={() => {setDataModal( {menu: 'productSelect'} )}}/>

                  {/*추후에 들어갈예정*/}
                  {!selectProductData?.productCode ?
                    <RegisterComponent type={'empty'} width={'192px'} title={'거래처'} name={'customerName'}
                                       value={'-'}
                    />
                    :
                    <ListInfoComponent type={'addition'} width={'192px'} title={'거래처'}
                                       lineHeight={selectProductData?.customer?.length === 1 ? 48 : 24}
                                       arrayData={Array.from( new Set( selectProductData.customer.map( ( customer: {
                                         customerName: any;
                                       } ) => customer.customerName ) ) )}
                                       value={''}
                    />
                  }

                  {!selectProductData?.productCode ?
                    <RegisterComponent type={'empty'} width={'192px'} title={'거래처'} name={'customerName'}
                                       value={'-'}
                    />
                    :
                    <ListInfoComponent type={'addition'} width={'192px'} title={'모델'}
                                       lineHeight={selectProductData?.customer?.length === 1 ? 48 : 24}
                                       arrayData={Array.from( new Set( selectProductData.customer.map( ( customer: {
                                         customerModelName: any;
                                       } ) => customer.customerModelName ) ) )}
                                       value={''}
                    />
                  }


                  {/*<RegisterComponent type={'text'} title={'모델'} name={'model'} value={moldRegState?.model} disabled={true}*/}
                  {/*                   paddingRight={true} required={false} onChange={onChange} width={'192px'} placeholder={'-'}/>*/}


                  <RegisterComponent type={'text'} title={'자산구분'} name={'assetType'} value={moldRegState?.assetType}
                                     width={'192px'}
                                     required={false} onChange={onChange} placeholder={'자산구분을 입력해주세요.'}/>

                  <RegisterComponent type={'text'} title={'규격'} name={'specification'}
                                     value={moldRegState?.specification}
                                     required={false} onChange={onChange} width={'192px'} placeholder={'규격을 입력해주세요.'}/>
                  <RegisterComponent type={'text'} title={'금형명'} name={'moldName'} value={moldRegState?.moldName}
                                     paddingRight={true} required={true} onChange={onChange} limit={100}
                                     placeholder={'금형명을 입력해주세요.'}/>

                  <RegisterComponent type={'unit'} title={'중량'} name={'weight'} placeholder={'-'}
                                     unit={'kg'} value={moldRegState?.weight} paddingRight={true} limit={15}
                                     onChange={( e ) => {onChange( e , 'decimal' )}}/>

                  <RegisterComponent type={'text'} title={'재질'} name={'material'} value={moldRegState?.material}
                                     paddingRight={true} placeholder={'재질을 입력해주세요.'}
                                     required={false} onChange={onChange}/>

                  <RegisterComponent type={'unit'} title={'캐비티'} name={'cavity'} unit={'ea'}
                                     value={numberLocaleString( moldRegState?.cavity )}
                                     paddingRight={true} limit={11} onChange={( e ) => {onChange( e , 'number' )}}
                                     required={true}/>
                  {/*추후에 들어갈예정*/}
                  <ListInfoComponent type={'empty'} width={'192px'} title={'생산 공정'}
                                     value={dataValid( selectProductData?.processName )}/>

                  {/*<RegisterComponent type={'search'} title={'생산 공정'} name={'process'} value={moldRegState?.process} paddingRight={true}*/}
                  {/*                   disabled={true} clickEvent={() => {setDataModal( {menu: 'process'} )}}/>*/}
                  {/*추후에 들어갈예정*/}
                  <ListInfoComponent type={'empty'} width={'192px'} title={'설비'} value={'-'}/>
                  {/*<RegisterComponent type={'search'} title={'설비'} name={'facility'} value={moldRegState?.facility}*/}
                  {/*                   width={'192px'} paddingRight={true} disabled={true} clickEvent={() => {setDataModal( {menu: 'facility'} )}}/>*/}

                  <RegisterComponent type={'drop'} title={'공증유무'} name={'certification'}
                                     value={moldRegState?.certification}
                                     paddingRight={true} dropWidth={'192px'}
                                     dropClickEvent={( v ) => {
                                       setMoldRegState( ( prev: any ) => ( {
                                         ...prev ,
                                         certification: v
                                       } ) )
                                     }}
                                     dropArray={['선택 없음' , '유' , '무']}/>
                  <RegisterComponent type={'unit'} name={'spm'} title={'SPM'} paddingRight={true}
                                     value={numberLocaleString( moldRegState?.spm )}
                                     unit={'타'} limit={11} onChange={( e ) => {onChange( e , 'number' )}}/>
                  <RegisterComponent type={'unit'} title={'금액'} name={'amount'} unit={'원'}
                                     value={numberLocaleString( moldRegState?.amount )}
                                     paddingRight={true} limit={11} onChange={( e ) => {onChange( e , 'number' )}}/>
                  <RegisterComponent type={'unit'} title={'점검 타수'} name={'checkStrokes'} unit={'타'}
                                     value={numberLocaleString( moldRegState?.checkStrokes )}
                                     paddingRight={true} limit={11} onChange={( e ) => {onChange( e , 'number' )}}/>
                  <RegisterComponent type={'unit'} title={'최대 타수'} name={'maxStrokes'} unit={'타'}
                                     value={numberLocaleString( moldRegState?.maxStrokes )}
                                     paddingRight={true} limit={11} onChange={( e ) => {onChange( e , 'number' )}}/>
                  <RegisterComponent type={'unit'} title={'점검 주기'} name={'checkCycle'} unit={'일'}
                                     value={numberLocaleString( moldRegState?.checkCycle )}
                                     paddingRight={true} limit={5} onChange={( e ) => {onChange( e , 'number' )}}/>
                  <RegisterComponent type={'unit'} title={'현재 타수'} name={'currentStrokes'} unit={'타'}
                                     value={numberLocaleString( moldRegState?.currentStrokes )}
                                     paddingRight={true} limit={11} onChange={( e ) => {onChange( e , 'number' )}}/>

                  <ListInfoComponent type={'empty'} width={'192px'} title={'생산 품목'} value={'-'}/>
                </div>
                <RegisterComponent type={'textarea'} title={'비고'} name={'notice'} value={moldRegState?.notice}
                                   bottomBorder={true} limit={2000} onChange={( e ) => {onChange( e , '' , true )}}/>

              </div>
            </RegisterComp>
            <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
            <ImageContent>
              <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
                <div style={{
                  color: '#7E84A3' ,
                  fontSize: '12px' ,
                  textAlign: 'right' ,
                  lineHeight: '16px' ,
                  height: '34px'
                }}>
                  <div>{t( '*최대 2개의 이미지 등록 가능' )}</div>
                  <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
                </div>
              </div>

              <div style={{display: 'flex' , flexDirection: 'column' , margin: '20px 0'}}>
                <div style={{
                  fontSize: '14px' ,
                  fontWeight: '800' ,
                  marginBottom: '8px' ,
                  color: '#7E84A3'
                }}>{'상하형 조립 (전체사진)'}</div>
                <div style={{marginTop: '16px'}}>
                  <TotalImageUploadBox imageKeyArray={( data ) => ( setImageKeyState( ( prev: any ) => ( {
                    ...prev ,
                    assemblyImages: data
                  } ) ) )}
                                       imageUrlArray={( data ) => ( setImageKeyState( ( prev: any ) => ( {
                                         ...prev ,
                                         assemblyImagesUrl: data
                                       } ) ) )}
                                       profileSelect={false} imageLength={2}/>
                </div>
              </div>
              <div style={{display: 'flex' , flexDirection: 'column' , margin: '40px 0px'}}>
                <div style={{
                  fontSize: '14px' ,
                  fontWeight: '800' ,
                  marginBottom: '8px' ,
                  color: '#7E84A3'
                }}>{'상하형 분리 (내부사진)'}</div>
                <div style={{display: 'flex' , gap: '10px'}}>
                  <div style={{marginTop: '16px'}}>
                    <TotalImageUploadBox imageKeyArray={( data ) => ( setImageKeyState( ( prev: any ) => ( {
                      ...prev ,
                      disassemblyImages: data
                    } ) ) )}
                                         imageUrlArray={( data ) => ( setImageKeyState( ( prev: any ) => ( {
                                           ...prev ,
                                           disassemblyImagesUrl: data
                                         } ) ) )}
                                         profileSelect={false} imageLength={2}/>
                  </div>
                  {/*<SingleImageUploadBox imageKey={( data ) => setImageKey( data )}*/}
                  {/*                      receiveKey={moldRegState?.moldImgCode}*/}
                  {/*                      receiveUrl={moldRegState?.moldSerialCode} inputSize={168}*/}
                  {/*                      profileSelect={false}/>*/}
                </div>
              </div>
            </ImageContent>
          </div>}
        </ContentFrame>
    </SectionFrame>
);
}
export default MoldRegistration;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  
  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }

`

const ImageContent = styled.div`
  width: 100%;
  height:620px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar{
    display: block;
    width: 16px;
  }


  .maintenanceItem{
    // style={{display:'flex',alignItems:'center',marginBottom:'16px',height:'32px',fontWeight:'bold'}}
    display: flex;
    align-items:center;
    margin-bottom: 16px;
    height:32px;
    font-weight: bold;
    margin-top: 32px;
  }
`
