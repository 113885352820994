import React , {useEffect} from 'react';
import {SectionFrame} from "../../../../style/StyleComponent";
import FacilityCheckList from "../../../../components/list/FacilityCheckList";
import MenuTitle from "../../../../components/title/MenuTitle";
import {listLoadingCircle , loadingRemove} from "../../../../function/function";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Partner_Type} from "./PartnerModify";
import {useParams} from "react-router-dom";


const PartnerFacility = () => {

	const locationID = useParams()
	const [partnerDetail,setPartnerDetail] = React.useState<Partner_Type | any>({});

	const partnerDetailRes = async () => {
		try {
			const response = await client.post(`/admin/detail`,
																				 {id:locationID.id},{headers: {Authorization}})
			const data = response.data.data
			setPartnerDetail(data)
			if(window.localStorage.getItem('companyID') === data.id){
				window.localStorage.setItem('profile',data.imageUrl)
			}
		}catch (e){
			console.log(e)
		}
	}
	useEffect(()=>{
		partnerDetailRes().then()
	},[locationID])

	return (
		<SectionFrame>
			<MenuTitle title={`지사/협력사 관리 > ${partnerDetail.companyName} > 기계 등록`} unitCase={'MenuTitle'}/>
			<FacilityCheckList setContainer={()=>{window.location.href = `/admin/fas/partners/${partnerDetail.id}`}}
												 companyName={partnerDetail.companyName} companyId={partnerDetail.id}/>
		</SectionFrame>
	);
};

export default PartnerFacility;
