import React from 'react';
import styled from "styled-components";
import {ModalLabel , RegisterButton} from "../../style/StyleComponent";
import {ConfirmProps} from "../../types/Fundamental";
import ModalBase from "./ModalBase";
import {useTranslation} from "react-i18next";
import {authValid , dataValid} from "../../function/function";

const ConfirmPopup:React.FunctionComponent<ConfirmProps> = ({buttonText,modalTitle,statusContent,statusText,buttonType, clickEvent, noticeText,color, linkedText,transparent, buttonDelay}) => {
	const { t }:any = useTranslation();

	const focusRef = React.useRef<any>(null)
	const [delay, setDelay] = React.useState(2)

	React.useEffect(()=>{
		const interval = setInterval(()=>{
			if(delay >= 1){
				setDelay(delay-1)
			}
		},1000)
		return ()=>clearInterval(interval)
	},[delay])

	return (
		<ModalBase transparent={transparent}  modalContent={
			<ModalFrame>
				<ModalLabel location={authValid()}>{modalTitle}</ModalLabel>
				<div style={{display:'flex',flexDirection:'column', justifyContent:'space-between', minHeight:'206px',
					padding:'59px 30px 38px', boxSizing:'border-box', userSelect:'none'}}>

					{statusText ?
							<div style={{textAlign:'center', color:'#171725', fontSize: dataValid(t(statusText)?.length,0) > 15? '18px' : '20px', fontWeight:'700',whiteSpace:'pre', wordBreak:'break-word'}}>{t(statusText)}</div>

						:
						statusContent
					}

					{t(noticeText) && <div style={{display:'flex',justifyContent:'center',fontSize:'12px', textAlign:'center', whiteSpace:'pre-line',
						color: color?? '#F0142F', marginTop:'8px'}}>
								{t(noticeText)}</div>}
					{linkedText?.title &&
						<div className={'link_text'} onClick={()=>window.location.href = linkedText?.url}
									style={{display: 'flex', justifyContent: 'center', fontSize: '12px', textDecoration: 'underline',
									textUnderlineOffset: '4px', cursor: 'pointer', color: color ?? 'rgba(0,88,255,0.7)'}}>
									{linkedText?.title}
						</div>}
					<div style={{margin:'16px auto 0'}}>
						{(buttonDelay && delay !== 0)?
							<RegisterButton buttonNormal={buttonType} buttonDisabled={true}>{delay.toString()}</RegisterButton>
							:
							<RegisterButton buttonNormal={buttonType} onClick={clickEvent} ref={focusRef}
														autoFocus={true}>{buttonText ?? t('확인')}</RegisterButton>}
					</div>
				</div>
			</ModalFrame>
		}/>
	);
};

export default ConfirmPopup;

const ModalFrame = styled.div`
   position: absolute;
   min-width: 416px;
   min-height: 262px;
	border-radius: 6px;
	background-color: #fff;
	overflow: hidden;
	white-space: pre-wrap;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 1px 4px #15223214;
	.link_text{
		&:hover{
			font-weight: 700;
		}
	}
	
`