import React , {useState} from 'react';
import ProfileSample from '../../asset/image/man.png'
import dayjs from 'dayjs'
import {initModal , listLoadingCircle , loadingRemove} from '../../function/function'
import MainButtons from '../button/MainButtons'
import styled from 'styled-components'
import client from '../../common/Config'
import {Authorization} from '../../common/authorization'
import {Modal_View_Type} from '../../types/Fundamental'
import {useRecoilState} from 'recoil'
import {selectedCommentCodeState} from '../../common/StateManage'
import ToastPopup from '../modal/ToastPopup'
import SelectConfirmModal from '../modal/SelectConfirmModal'

interface CommentDetailType {
  listApi:()=>any
  cancelEvent: ()=>void
  replyData: any
}

const WikiCommentDetail:React.FunctionComponent<CommentDetailType> = ({replyData, listApi, cancelEvent }) => {
  const [commentValue, setCommentValue] = useState<string>('')
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [selectedComment, setSelectedComment] = useRecoilState<string>(selectedCommentCodeState)
  const [inputLoading, setInputLoading] = useState<boolean>(false)

  const [replyState, setReplyState] = useState<string>('')
  const [openReplyState, setOpenReplyState] = useState({reply: false, list: true})

  const wikiCommentReg = async () => {
    if(!commentValue){
      setDataModal({menu:'warn', message:'작성된 내용이 없습니다.'})
    }
    else{
      setInputLoading(true)
      try {
        const response = await client.post('/wiki/comment/register',{
          comment: commentValue,
          parentCode: replyState
        },{headers:{Authorization}})

        if(response.data.status === 200){
          setCommentValue('')
          setReplyState('')
          setOpenReplyState({list: true, reply: false})
          if(replyState !== ''){
            commentRef.current.style.height = '48px'
          }
          listApi()
        }
        else{
          setDataModal({menu:'error', message: response.data.message})
        }
      }
      catch (e:any) {
        console.log(e)
        setDataModal({menu:'error', message: e.message})
      }
      finally {
        setInputLoading(false)
      }
    }
  }

  const wikiCommentUpdate = async () => {
    if(!commentValue){
      setDataModal({menu:'warn', message:'작성된 내용이 없습니다.'})
    }
    else{
      listLoadingCircle()
      try {
        const response = await client.post('/wiki/comment/update',{
          comment: commentValue,
          commentCode: selectedComment
        },{headers:{Authorization}})

        if(response.data.status === 200){
          listApi().then(()=>{
            setCommentValue('')
            setSelectedComment('')})
          loadingRemove()
        }

        else{
          setDataModal({menu:'error', message: response.data.message})
          loadingRemove()
        }
      }
      catch (e:any) {
        console.log(e)
        setDataModal({menu:'error', message: e.message})
        loadingRemove()
      }
      finally {
        setInputLoading(false)
      }
    }
  }


  const wikiCommentDelete = async () => {
    listLoadingCircle()
    setDataModal(initModal)
    try {
      const response = await client.post('/wiki/comment/delete',{
        commentCode: selectedComment
      },{headers:{Authorization}})

      if(response.data.status === 200){
        listApi().then(()=>{
          setCommentValue('')
          setSelectedComment('')
          setDataModal({menu:'success', message:'댓글이 삭제 되었습니다.'})
          loadingRemove()
        })
      }
      else{
        setDataModal({menu:'error', message: response.data.message})
        loadingRemove()
      }
    }
    catch (e:any) {
      console.log(e)
      setDataModal({menu:'error', message: e.message})
      loadingRemove()
    }
    finally {
      setInputLoading(false)
    }
  }


  // const textareaRef = React.useRef<any>(null)
  const commentRef = React.useRef<any>(null)
  // const modifiedRef = React.useRef<any>(null)

  const autoResize = (ref:any) => {

    const textarea = ref.current
    textarea.style.height = 'auto'

    // const contentHeight = textarea.scrollHeight + 8;
    const contentHeight = textarea.scrollHeight
    textarea.style.height = contentHeight + 'px'

    // 커서를 항상 맨 아래에 위치하도록 조절
    textarea.scrollTop = textarea.scrollHeight;

    // 내부 padding을 8px로 설정 ------------- 기획 디자인 변경으로 사용안함
    // textarea.style.paddingTop = '8px';
    // textarea.style.paddingBottom = '8px';
  }

  // const autoModifiedResize = () => {
  //
  //   const textarea = modifiedRef.current
  //
  //   textarea.style.height = 'auto'
  //   // const contentHeight = textarea.scrollHeight + 8;
  //   const contentHeight = textarea.scrollHeight
  //   textarea.style.height = contentHeight + 'px'
  //
  //   // 커서를 항상 맨 아래에 위치하도록 조절
  //   textarea.scrollTop = textarea.scrollHeight
  //
  //   // 내부 padding을 8px로 설정 ------------- 기획 디자인 변경으로 사용안함
  //   // textarea.style.paddingTop = '8px';
  //   // textarea.style.paddingBottom = '8px';
  // }

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={(dataModal.message)} state={'success'} closeEvent={()=> {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ? <ToastPopup text={(dataModal.message)} state={'error'} closeEvent={()=> {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={(dataModal.message)} state={'warn'} closeEvent={()=> {setDataModal( initModal )}}/>: null}

      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'삭제된 댓글은 복구할 수 없습니다.'}
                                                          clickEvent={wikiCommentDelete}
                                                          cancelEvent={()=> {
                                                            setCommentValue('')
                                                            setSelectedComment('')
                                                            setDataModal(initModal)
                                                          }}/>}
      {/*목록 부분 */}
        <React.Fragment>
          <div key={replyData.commentCode} style={{ display: 'flex', width: '100%', margin: '10px 8px',justifyContent:'space-between'}}>
            <div style={{display:'flex'}}>
              <img src={`${replyData.profileImageUrl}` ?? `${ProfileSample}`} alt="" width={38} height={38}
                   style={{borderRadius:'50%', border :'1px solid #e0e0e0', boxSizing:'border-box', marginRight:'10px',
                     display:'block',objectFit:'cover'}}
                   onError={(e:React.ChangeEvent<HTMLImageElement>)=>e.target.src = ProfileSample}/>
              <div style={{ display: 'flex', flexDirection: 'column', width: '646px', boxSizing:'border-box'}}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{ fontWeight: 'bold', fontSize: '12px', marginRight:'10px'}}>{replyData.writer + ((replyData.authority === 'admin' || replyData.authority === 'adminUser')? ' (관리자)' : '')}
                      {replyData.id === window.localStorage.getItem('companyID') &&
                          <span style={{marginLeft: '12px' , fontSize: '10px' , color: '#0058ff' , fontWeight: 400}}>{'작성자'}</span>}
                    </div>
                    {/*<div style={{display:'flex'}}>*/}
                    {/*  <div style={{ fontSize: '10px', color: '#828282' }}>{*/}
                    {/*    dayjs().diff(dayjs(replyData.registrationDate),'day') > 0?*/}
                    {/*      replyData.registrationDate :*/}
                    {/*      dayjs(replyData.registrationDate).fromNow()}*/}
                    {/*  </div>*/}
                    {/*  <div style={{ fontSize: '10px', color: '#828282', marginLeft:'4px'}}>{!replyData.modifiedDate? '' : '(수정 됨)'}</div>*/}
                    {/*</div>*/}
                  </div>
                  {/*<div style={{display:'flex'}}>*/}
                  {/*  /!*<span style={{color:'#828282', fontSize:'12px'}}>{v.reply.length === 0? '' : `댓글 ${v.reply.length}개`}</span>*!/*/}
                  {/*  <div onClick={()=> {*/}
                  {/*    // setOpenReplyState((prev:any)=>({list: true, reply: false}))*/}
                  {/*    setReplyState('')*/}
                  {/*    setOpenReplyState((prev:any)=>({list: !prev.list, reply: false}))*/}
                  {/*  }}*/}
                  {/*       style={{pointerEvents: selectedComment? 'none' : 'auto', opacity: selectedComment? 0.5 : 1}}*/}
                  {/*       className={'reply-button'}>*/}
                  {/*    /!*{v.commentCode === replyState? '댓글 접기' : v.reply.length === 0? '댓글 쓰기' : '댓글 펼치기'}*!/*/}
                  {/*    {openReplyState.list?  replyData.reply.length === 0? '' : '댓글 접기' : replyData.reply.length === 0? '' : `댓글 ${replyData.reply.length}개`}*/}
                  {/*  </div>*/}

                  {/*  {*/}
                  {/*    // replyData.statement === 'active' &&*/}
                  {/*    <div className={'reply-button'} onClick={()=> {setReplyState(replyState === '' ? replyData.commentCode : '')*/}
                  {/*      if(openReplyState.list){*/}
                  {/*        setOpenReplyState(({list: openReplyState.list, reply: !openReplyState.reply}))*/}
                  {/*      }else{*/}
                  {/*        setOpenReplyState(({list: !openReplyState.list, reply: !openReplyState.reply}))*/}
                  {/*      }*/}
                  {/*    }}>{'댓글 쓰기'}</div>*/}
                  {/*  }*/}
                  {/*</div>*/}
                </div>

                {
                  // 삭제 및 수정 버튼으로 해당 댓글 코드가 선택됬을때 변경되는 인풋창. 현재 삭제기능만 되므로 비활성화
                  // (replyData.commentCode === selectedComment)?
                  //   <InputTextBox selected={true}>
                  //         <textarea className={'auto-resize-input'}
                  //                   value={commentValue}
                  //                   ref={modifiedRef} onInput={autoModifiedResize} onClick={autoModifiedResize}
                  //                   rows={dataValid(commentValue.match(/\n/g)?.length, 0) + 1 <= 2 ? 2 : (dataValid(commentValue.match(/\n/g)?.length,0) + 1)}
                  //                   style={{marginTop:'6px'}}
                  //                   placeholder={''} spellCheck={false}
                  //                   onChange={(e)=>setCommentValue(e.target.value)}/>
                  //   </InputTextBox>
                  //   :
                    replyData.statement === 'dead'?
                      <div style={{fontSize:'12px', color:'#828282', margin:'8px 16px'}}>{'* 삭제된 메시지 입니다.'}</div>
                      :
                    <InputTextBox readonly={true}>
                          <span style={{marginTop:'6px',
                            wordBreak:'break-word', boxSizing:'border-box', border:'none', padding: 0}}
                                className={'auto-resize-input'}>
                            {replyData.comment}
                          </span>
                    </InputTextBox>
                  // <InputTextBox readonly={true}>
                  //   <textarea className={'auto-resize-input'}
                  //             rows={dataValid(v.comment.match(/\n/g)?.length, 0) + 1 <= 2 ? 2 : (dataValid(v.comment.match(/\n/g)?.length,0) + 1)}
                  //             defaultValue={v.comment} readOnly={true}
                  //     // ref={modifiedRef} onInput={autoModifiedResize} onClick={autoModifiedResize}
                  //             style={{marginTop:'6px', lineHeight:'20px'}}
                  //             placeholder={''} spellCheck={false}/>
                  // </InputTextBox>
                }
              </div>
            </div>
            <div style={{display:'flex',alignItems:'center', marginTop:'20px'}}>
              {
                ((replyData.id === window.localStorage.getItem('companyID')) || (window.localStorage.getItem('auth') === 'admin' || window.localStorage.getItem('auth') === 'adminUser')) &&
                  <React.Fragment>
                    {
                      ((replyData.commentCode === selectedComment) && dataModal.menu !== 'select')?
                      <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <ModifyText onClick={()=> {
                          setCommentValue('')
                          setSelectedComment('')
                          cancelEvent()
                          // setCommentList([])
                          // wikiCommentListRes().then()
                          // 댓글의 취소, 저장의 내용 텍스트 제거하면서 클릭 할 수 없도록 함.
                        }}>{''}</ModifyText>
                        <ModifyText style={{color:'#0058ff'}} onClick={wikiCommentUpdate}>{''}</ModifyText>
                      </div>
                      :
                      <React.Fragment>
                        {
                          (!selectedComment && !replyState && (replyData.statement === 'active')) &&
                            <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                              {/*{( replyData.id === window.localStorage.getItem('companyID') ) &&*/}
                              {/*    <ModifyText*/}
                              {/*        onClick={() => {*/}
                              {/*          setSelectedComment(replyData.commentCode)*/}
                              {/*          setCommentValue(replyData.comment)*/}
                              {/*        }}>{'수정'}</ModifyText>}*/}
                              {
                                ((replyData.id === window.localStorage.getItem('companyID') ) || ( replyData.authority !== 'admin' && replyData.authority !== 'adminUser' ) ) &&
                                  <ModifyText
                                      onClick={() => {
                                        setSelectedComment(replyData.commentCode)
                                        setCommentValue(replyData.comment)
                                        setDataModal({menu: 'select' , message: '댓글을 삭제 하시겠습니까?'})
                                      }}>{'삭제'}</ModifyText>
                              }
                            </div>
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
              }
            </div>
          </div>

          {/*댓글 입력 시간 및 답글쓰기 기능이 댓글 하단으로 내려옴.*/}
          <div style={{display:'flex', alignItems:'center', margin:'0 0 10px 56px'}}>
            <div style={{ fontSize: '10px', color: '#7E84A3' }}>{
              dayjs().diff(dayjs(replyData.registrationDate),'hour') > 20?
                replyData.registrationDate :
                dayjs(replyData.registrationDate).fromNow()}
            </div>
            <div style={{ fontSize: '10px', color: '#7E84A3', marginLeft:'4px'}}>{!replyData.modifiedDate? '' : '(수정 됨)'}</div>
            <div style={{display:'flex'}}>
              {
                replyData.statement === 'active' &&
                <div className={'reply-button'} onClick={()=> {setReplyState(replyState === '' ? replyData.commentCode : '')
                  if(openReplyState.list){
                    setOpenReplyState(({list: openReplyState.list, reply: !openReplyState.reply}))
                  }else{
                    setOpenReplyState(({list: !openReplyState.list, reply: !openReplyState.reply}))
                  }
                }}>{'댓글 쓰기'}</div>
              }
            </div>
          </div>

          {/*댓글의 댓글 부분*/}
          {
              <React.Fragment>
                {/*댓글의 댓글 답변 창*/}
                  {openReplyState.reply &&
                    // <div style={{display: 'flex' , alignItems: 'flex-start' , width: '100%' , margin: '20px 8px 20px 38px' , gap: '10px',
                    //   opacity: selectedComment? 0.1 : 1, pointerEvents: selectedComment? 'none' : 'auto'}}>
                    //   <img src={`${window.localStorage.getItem('profile')}` ?? `${ProfileSample}`} alt="" width={38} height={38}
                    //        style={{
                    //          borderRadius: '50%' , border: '1px solid #e0e0e0' , boxSizing: 'border-box' ,
                    //          display: 'block' , objectFit: 'cover'
                    //        }}
                    //        onError={(e: React.ChangeEvent<HTMLImageElement>) => e.target.src = ProfileSample}/>
                    //
                    //   <InputTextBox style={{maxWidth:'620px'}}>
                    //         <textarea className={'auto-resize-input'}
                    //                   value={selectedComment? '' : commentValue}
                    //                   ref={commentRef} onInput={()=>autoResize(commentRef)}
                    //                   placeholder={'댓글을 입력해 주세요.'} spellCheck={false}
                    //                   onChange={(e) => (setCommentValue(e.target.value))}/>
                    //   </InputTextBox>
                    //   <MainButtons name={'등록'} buttonType={'cta'}
                    //                loading={inputLoading} disabled={selectedComment !== '' || (!commentValue || inputLoading)}
                    //                width={'56px'} margin={'0 0 0 0px'}
                    //                clickEvent={wikiCommentReg}/>
                    // </div>


                      <div style={{display: 'flex' , alignItems: 'flex-start' ,minHeight:'72px', marginLeft:'48px', width: 'calc(100% - 48px)',
                        opacity: selectedComment? 0.1 : 1, pointerEvents: selectedComment? 'none' : 'auto', padding:'8px 16px',
                        boxSizing:'border-box', border:'1px solid #D7DBEC', borderRadius:'4px'}}>
                        <InputTextBox style={{boxSizing:'border-box'}}>
                          <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div style={{color:'#171725', fontSize:'12px', fontWeight:700}}>{window.localStorage.getItem('userName')}</div>
                            <div style={{color:'#7E84A3', fontSize:'10px'}}>{commentValue.length + '/' + '3000'}</div>
                          </div>
                          <div style={{display:'flex', justifyContent:'space-between', marginTop:'8px'}}>
                            <textarea className={'auto-resize-input'} maxLength={3000}
                                        value={selectedComment? '' : commentValue} autoFocus={true}
                                        ref={commentRef} onInput={()=>autoResize(commentRef)}
                                        placeholder={'댓글을 남겨보세요.'} spellCheck={false}
                                        onChange={(e) => (setCommentValue(e.target.value))}/>
                            <MainButtons name={'등록'} buttonType={'cta'}
                                         loading={inputLoading} disabled={selectedComment !== '' || (!commentValue || inputLoading)}
                                         width={'56px'} margin={'0 0 0 0px'}
                                         clickEvent={wikiCommentReg}/>
                          </div>
                        </InputTextBox>
                      </div>
                  }

                {openReplyState.list  &&
                  <div style={{boxSizing:'border-box', marginLeft:'48px', width:'calc(100% - 48px)'}}>
                    {
                      replyData.reply?.map((item:any) => (
                      <React.Fragment>
                        <div key={item.commentCode} style={{ display: 'flex', width: '100%', margin: '10px 8px', justifyContent:'space-between'}}>
                          <div style={{display:'flex'}}>
                            <img src={`${item.profileImageUrl}` ?? `${ProfileSample}`} alt="" width={38} height={38}
                                 style={{borderRadius:'50%', border :'1px solid #e0e0e0', boxSizing:'border-box',
                                   display:'block',objectFit:'cover', marginRight:'10px'}}
                                 onError={(e:React.ChangeEvent<HTMLImageElement>)=>e.target.src = ProfileSample}/>
                            <div style={{display: 'flex', flexDirection: 'column', width: '598px', boxSizing:'border-box'}}>
                              <div style={{display: 'flex', alignItems: 'center', justifyContent:'space-between'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                  <div style={{fontWeight: 'bold', fontSize: '12px', marginRight:'10px'}}>{item.writer + ((item.authority === 'admin' || item.authority === 'adminUser')? ' (관리자)' : '')}
                                    {item.id === window.localStorage.getItem('companyID') &&
                                      <span style={{marginLeft: '12px' , fontSize: '10px' , color: '#0058ff' , fontWeight: 400}}>{'작성자'}</span>}
                                  </div>
                                  {/*<div style={{display:'flex'}}>*/}
                                  {/*  <div style={{ fontSize: '10px', color: '#828282' }}>{*/}
                                  {/*    dayjs().diff(dayjs(item.registrationDate),'day') > 0?*/}
                                  {/*      item.registrationDate :*/}
                                  {/*      dayjs(item.registrationDate).fromNow()*/}
                                  {/*  }</div>*/}
                                  {/*  <div style={{ fontSize: '10px', color: '#828282', marginLeft:'4px'}}>{!item.modifiedDate? '' : '(수정 됨)'}</div>*/}
                                  {/*</div>*/}
                                </div>
                              </div>
                              {
                                // 삭제 및 수정 버튼으로 해당 댓글 코드가 선택됬을때 변경되는 인풋창. 현재 삭제기능만 되므로 비활성화
                                // (item.commentCode === selectedComment)?
                                //   <InputTextBox selected={true}>
                                //         <textarea className={'auto-resize-input'}
                                //                   value={commentValue} ref={modifiedRef} onInput={autoModifiedResize} onClick={autoModifiedResize}
                                //                   style={{marginTop:'6px'}}
                                //                   rows={dataValid(commentValue.match(/\n/g)?.length, 0) + 1 <= 2 ? 2 : (dataValid(commentValue.match(/\n/g)?.length,0) + 1)}
                                //                   placeholder={''} spellCheck={false}
                                //                   onChange={(e)=>setCommentValue(e.target.value)}/>
                                //   </InputTextBox>
                                //   :
                                  item.statement === 'dead'?
                                    <div style={{fontSize:'12px', color:'#828282', margin:'8px 16px'}}>{'* 삭제된 메시지 입니다.'}</div>
                                    :
                                  <InputTextBox readonly={true}>
                                        <span style={{marginTop:'6px', border:'none', padding:0,
                                          wordBreak:'break-word', boxSizing:'border-box'}}
                                              className={'auto-resize-input'}>
                                          {item.comment}
                                        </span>
                                  </InputTextBox>
                                // <InputTextBox readonly={true}>
                                //   <textarea className={'auto-resize-input'}
                                //             value={item.comment} readOnly={true}
                                //             rows={dataValid(item.comment.match(/\n/g)?.length, 0) + 1 <= 2 ? 2 : (dataValid(item.comment.match(/\n/g)?.length,0) + 1)}
                                //     // ref={modifiedRef} onInput={autoModifiedResize} onClick={autoModifiedResize}
                                //             style={{marginTop:'6px', lineHeight:'20px'}}
                                //             placeholder={''} spellCheck={false}/>
                                // </InputTextBox>
                              }
                            </div>
                          </div>

                          <div style={{display:'flex',alignItems:'center', marginTop:'20px'}}>
                            {
                              ((item.id === window.localStorage.getItem('companyID')) || (window.localStorage.getItem('auth') === 'admin' && window.localStorage.getItem('auth') === 'adminUser')) &&
                                <React.Fragment>
                                  {((item.commentCode === selectedComment) && dataModal.menu !== 'select')?
                                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                                      <ModifyText onClick={()=> {
                                        setCommentValue('')
                                        setSelectedComment('')
                                        cancelEvent()
                                        // setCommentList([])
                                        // wikiCommentListRes().then()
                                      }}>{''}</ModifyText>
                                      <ModifyText style={{color:'#0058ff'}} onClick={wikiCommentUpdate}>{''}</ModifyText>
                                    </div>
                                    :
                                    <React.Fragment>
                                      {
                                        (!selectedComment && item.statement === 'active') &&
                                          <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                                            {/*{( item.id === window.localStorage.getItem('companyID') ) &&*/}
                                            {/*    <ModifyText*/}
                                            {/*      // onMouseDown={autoModifiedResize}*/}
                                            {/*        onClick={() => {*/}
                                            {/*          setSelectedComment(item.commentCode)*/}
                                            {/*          setCommentValue(item.comment)*/}
                                            {/*        }}>{'수정'}</ModifyText>}*/}
                                            {((item.id === window.localStorage.getItem('companyID')) || ( item.authority !== 'admin' || item.authority !== 'adminUser')) &&
                                                <ModifyText
                                                    onClick={() => {
                                                      setSelectedComment(item.commentCode)
                                                      setCommentValue(item.comment)
                                                      setDataModal({menu: 'select' , message: '댓글을 삭제 하시겠습니까?'})
                                                    }}>{'삭제'}</ModifyText>}
                                          </div>}
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                            }
                          </div>
                        </div>
                        <div style={{display:'flex', alignItems:'center', margin:'0 0 10px 56px'}}>
                          <div style={{ fontSize: '10px', color: '#7E84A3' }}>{
                            dayjs().diff(dayjs(item.registrationDate),'day') > 0?
                              item.registrationDate :
                              dayjs(item.registrationDate).fromNow()}
                          </div>
                          <div style={{ fontSize: '10px', color: '#7E84A3', marginLeft:'4px'}}>{!item.modifiedDate? '' : '(수정 됨)'}</div>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                }
              </React.Fragment>
          }
        </React.Fragment>
    </React.Fragment>
  );
};
export default WikiCommentDetail;

const InputTextBox = styled.div<{error?: boolean, selected?: boolean, readonly?: boolean}>`
  width: 100%;
  //max-width: 650px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  
  .auto-resize-input{
    width: 100%;
    height: auto;
    outline: none;
    box-sizing: border-box;
    //padding: 8px;
    padding: 0;
    border-radius: 4px;
    border: none;
    //border:  ${props => props.readonly? '1px solid #d7dbec' : '2px solid rgba(0,88,255,0.85)'};
    resize: none;
    font-size: 12px;
    font-family: Pretendard, sans-serif;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
    margin-right: 48px;
    // &:focus{
    //   border: ${props => props.readonly? '1px solid #d7dbec' : props => props.selected? 2 : 1}px solid ${props => props.error? '#F0142F' : '#0058ff'};
    //   font-weight: 400;
    // }

    &::placeholder{
      font-size: 12px;
      font-weight: normal;
      color: rgba(23, 23, 37, 0.3);
      font-family: Pretendard, sans-serif;
    }

    &::-webkit-scrollbar {
      width: 15px;
      display: block;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #E6E9F4;
      border-radius: 10px;
      background-clip: padding-box;
      border: 6px solid transparent;
    }
  }
`

const ModifyText = styled.div`
  margin-right: 8px;
  cursor: pointer;
  color: #7E84A3;
  font-size: 12px;
  text-decoration: underline;
  text-underline-offset: 4px;
  &:hover{
    font-weight: 700;
  }
`
