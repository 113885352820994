import React , {useEffect , useState} from 'react';
import SectionContainer from "../../../containers/common/SectionContainer";
import MainContainer from "../../../containers/admin/fas/main/MainContainer";
import {MobilePageContainer , PageContainer} from "../../../style/StyleComponent";

import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useMediaQuery} from "react-responsive";
import MobileMainContainer from "../../../containers/admin/mobile/fas/MobileMainContainer";
import {authValid} from "../../../function/function";

const AdminFasMain = () => {
	const [mapState, setMapState] = React.useState(true)
	const isDesktop = useMediaQuery({
																		query : "(min-width:1024px)"
																	})

	const isMobile = useMediaQuery({
																	 query : "(max-width:1023px)"
																 });
	// const [scale, setScale] = useState(1); // 확대/축소 배율 상태

	// useEffect(() => {
	// 	const ro = new ResizeObserver(entries => {
	// 		for (let entry of entries) {
	// 			const { width,height } = entry.contentRect; // 가로 길이 가져오기
	// 			if (width <1660) {
	// 				const scaleWidth = width / 1600; // 가로 기준 배율 계산
	// 				const scaleHeight = height / window.outerHeight; // 세로 기준 배율 계산
	// 				const scaleFactor = Math.min(scaleWidth, scaleHeight);
	// 				setScale(scaleFactor); // 배율 설정
	// 			} else {
	// 				setScale(1); // 1920 픽셀 초과일 때는 배율 1로 설정
	// 			}
	// 		}
	// 	});
	//
	// 	ro.observe(document.body); // body의 크기 변화를 감시
	//
	// 	return () => {
	// 		ro.disconnect(); // 컴포넌트 언마운트 시 연결 해제
	// 	};
	// }, []);
	return (
		<React.Fragment>
			{
				isMobile &&
				<MobilePageContainer>
					<SectionContainer contentContainer={<MobileMainContainer/>} gradeDisabled={true} responsiveMobile={true}/>
				</MobilePageContainer>}
			{
				isDesktop &&
				<PageContainer style={{transformOrigin: 'top left'}}>
					<IntegratedSideMenu/>
					<SectionContainer contentContainer={<MainContainer mapState={setMapState}/>} gradeDisabled={!mapState}/>
				</PageContainer>}
		</React.Fragment>
	);
};

export default AdminFasMain;