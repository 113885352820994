import React , {CSSProperties , useState} from 'react';
import styled from "styled-components";
import {
	SectionFrame ,
	ContentFrame ,
	multipleImageGridStyle ,
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import RegisterInput from "../../../../../components/input/RegisterInput";
import client from "../../../../../common/Config";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {Authorization} from "../../../../../common/authorization";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {useTranslation} from "react-i18next";
import {FacilityDataType , Modal_View_Type , SearchCompanyType} from "../../../../../types/Fundamental";
import {useRecoilValue , useResetRecoilState} from "recoil";
import {persistBlockPage , userPersistCompanyData} from "../../../../../common/StateManage";
import FactoryDropMenu from "../../../../../components/sideMenu/FactoryDropMenu";
import MainButtons from "../../../../../components/button/MainButtons";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {
	locationFilter ,
	dataAccessLoading ,
	loadingRemove ,
	initModal , dataValid , numberLocaleString , authValid
} from "../../../../../function/function";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import dayjs from "dayjs";
import {useLocation} from "react-router-dom";
import PeripheralRegisterModal from "../../../../../components/modal/PeripheralRegisterModal";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import CompanySelectModal from "../../../../../components/modal/CompanySelectModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";

const FacilityRegister = () => {
	const userCompanyData = useRecoilValue(userPersistCompanyData)
	const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
	const [imageKeyState, setImageKeyState] = useState<string[]>([])
	const [factoryList, setFactoryList] = useState<SearchCompanyType[]>([])
	const resetBlockPage = useResetRecoilState(persistBlockPage)

	const [inputState, setInputState] = React.useState<FacilityDataType|any>({
																																						 //@ts-ignore
																																						 companyCode: authValid()? 'noCodeData' : userCompanyData.companyCode,
																																						 factoryCode: 'noCodeData',
																																						 factorySubdivision: '',
																																						 facilityName:'',
																																						 hw: '',
																																						 ictNumber:'',
																																						 location: authValid()? '' :  window.localStorage.getItem('address'),
																																						 manufacturingNumber:'',
																																						 maker:'',
																																						 notice:'',
																																						 type:'프레스',
																																						 version:'',
																																						 network:'',
																																						 companyName: authValid()? '' : window.localStorage.getItem('company'),
																																						 maxTon:'0',
																																						 manager:'',
																																						 overhaul: '유',
																																						 voltage:'',
																																						 productionDate: '', device: 'ZESTECH',
																																						 weldingType:'', codeDoubleCheck: false,
																																					 })

	const [alertInput, setAlertInput] = React.useState<string>('')
	const [factorySegment, setFactorySegment] = React.useState<string[]>(['미등록'])

	const { t }:any = useTranslation();

	// const imageKeyFunc = () => {
	// 	if(!imageKeyState){
	// 		return imageKeyState[0]
	// 	}else{
	// 		return ''
	// 	}
	// }
	const facilityRegisterResponse = async () => {
		if(inputState.companyCode === 'noCodeData'){
			setDataModal({menu:'confirm', message:'업체를 선택해 주세요.'})
			setAlertInput('업체를 선택해 주세요.')
		}
		else if(!inputState.facilityName){
			setDataModal({menu:'confirm', message:'기계명을 입력해 주세요.'})
			setAlertInput('기계명을 입력해 주세요.')
		}
		else if(!inputState.maker){
			setDataModal({menu:'confirm', message:'제조사를 입력해 주세요.'})
			setAlertInput('제조사를 입력해 주세요.')
		}
			// else if(inputState.ictNumber && inputState.ictNumber.length < 5){
			// 	setDataModal({menu:'confirm', message:'ICT번호 5자리를 입력해 주세요.'})
			// 	setAlertInput('ICT번호 5자리를 입력해 주세요.')
			// }
			// else if(!inputState.ictNumber){
			// 	setDataModal({menu:'confirm', message:'ICT번호를 생성해 주세요.'})
			// 	setAlertInput('ICT번호를 생성해 주세요.')
		// }
		else if((!inputState.maxTon || inputState.maxTon === '0') && inputState.type === '프레스'){
			setDataModal({menu:'confirm', message:'프레스의 최대 톤수를 입력해 주세요.'})
			setAlertInput('프레스의 최대 톤수를 입력해 주세요.')
		}
		else if(inputState.manufacturingNumber && !inputState.codeDoubleCheck){
			setDataModal({menu:'confirm', message:'기계 제조번호를 확인해 주세요.'})
			setAlertInput('기계 제조번호를 확인해 주세요.')
		}
		else{
			try {
				setDataModal(initModal)
				dataAccessLoading()
				const response = await client.post('/facility/register',{
																						 ...inputState,
																						 factoryCode: dataValid(inputState.factoryCode, 'noCodeData'),
																						 ictNumber: inputState?.ictNumber === 'C'? '00000' : dataValid(inputState?.ictNumber, '00000'),
																						 image: dataValid(imageKeyState,[]),
																						 facilityManualImg: dataValid(inputState.facilityManualImg, ''),
																						 facilitySpecImg: dataValid(inputState.facilitySpecImg,''),
																						 facilityAbilityImg: dataValid(inputState.facilityAbilityImg,''),
																						 profileImage:  dataValid(inputState?.profileImage, ''),
																					 }
					,{headers:{Authorization}})
				const data = response.data
				if(data.status !== 200){
					setDataModal({menu: 'confirm', message: t(data.message)})
					setAlertInput(data.message)
					loadingRemove()
				}else{
					setTimeout(()=>{
						setDataModal({menu: 'success', message: t('저장 되었습니다.')})
						resetBlockPage()
						loadingRemove()
					},500)
				}
			}catch (e){
				loadingRemove()
				console.log(e)
			}
		}
	}

	const factoryListRes = async  (companyCode?: string) => {
		try {
			const response = 	authValid() ?
				await client.post( `/factory/admin/search`,{page:1, size:10, keyword:companyCode},
													 {headers:{Authorization}})
				:
				await client.get( `/factory/list/1/10/${companyCode}`, {headers:{Authorization}})
			const data = response.data.data
			setFactoryList(data.row)
			setInputState((prev:any)=>({...prev, factoryCode: data.row[0]?.factoryCode}))

			const segment = await client.post('/factory/subdivision/list',{
				factoryCode: data.row[0]?.factoryCode
			},{headers:{Authorization}})
			const segmentData = segment.data.data.subdivision
			if(!segmentData[0]){
				setFactorySegment(['미등록'])
			}else{
				setFactorySegment(segmentData)
			}
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(() => {
		if(!authValid()){
			factoryListRes(userCompanyData.companyCode).then()
		}
	},[])

	const factoryDivision = async (factoryCode: string) => {
		try {
			const response = await client.post('/factory/subdivision/list',{
				factoryCode: factoryCode
			},{headers:{Authorization}})
			const segmentData = response.data.data.subdivision
			if(!segmentData[0]){
				setFactorySegment(['미등록'])
			}else{
				setFactorySegment(segmentData)
			}
		}catch (e) {
			console.log(e)
		}
	}

	const generateIctNumber = async () => {
		try {
			const response = await client.post('/facility/ictNumber',{
				location: inputState.location
			},{headers:{Authorization}})
			const data = response.data.data
			setInputState((prev:any)=>({...prev, ictNumber: data.ictNumber}))
		}catch ( e ) {
			console.log(e)
			setDataModal({menu:'confirm', message:'ICT번호를 생성할 수 없습니다. \n 관리자에게 문의해 주세요.'})
		}
	}

	const serialDoubleCheck = async () => {
		try {
			const response = await client.post('/facility/serial/check',{
				manufacturingNumber: inputState.manufacturingNumber
			},{headers:{Authorization}})
			if(response.data.status === 200){
				setInputState((prev:any)=>({...prev, codeDoubleCheck: true}))
				setDataModal({menu:'confirm', message: response.data.message})
			}else{
				setDataModal({menu:'confirm', message: response.data.message})
			}
		}catch ( e ) {
			console.log(e)
			setDataModal({menu:'confirm', message:' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
		}
	}

	const onChangeValue = (e:any, valid?:{number?:boolean, trim?: boolean}) => {
		const {value, name} = e.target
		setInputState({
										...inputState,
										[name]: valid?.number ? value.replace( /[^0-9]/g, '') : valid?.trim? value.trim() : value
									});
	}

	const onChangeICT = (e:any) => {
		const {value, name} = e.target
		const prefixMap: any = {
			'CNC': 'C',
			'비전기': 'V'
		};

		const prefix = prefixMap[inputState.type] || '';
		setInputState({
										...inputState,
										[name]:  value.replace( /[^0-9]/g, '').trim() === ''? prefix : prefix + value.replace( /[^0-9]/g, '').trim()
									});
	}

	const enterEvent = (e:any) => {
		if(e.key === 'Enter'){
			setDataModal({menu: 'search'})
		}
	}
	//입력 후 문제 있는 인풋창 하이라이트 되도록 하는 기능
	const alertPoint = (key: string) => {
		if(key === 'companyCode'){
			return alertInput === '업체를 선택해 주세요.';
		}
		if(key === 'facilityName'){
			return alertInput === '기계명을 입력해 주세요.';
		}
		// if(key === 'ictNumber'){
		// 	if(alertInput === 'ICT번호 5자리를 입력해 주세요.'){
		// 		return true;
		// 	}
		// 	else return alertInput === '이미 사용중인 ICT 번호 입니다.';
		// }
		// if(key === 'ictNumber'){
		// 	return alertInput === 'ICT번호를 생성해 주세요.';
		// }
		if(key === 'maker'){
			return alertInput === '제조사를 입력해 주세요.';
		}
		if(key === 'maxTon'){
			return alertInput === '프레스의 최대 톤수를 입력해 주세요.';
		}
		if(key === 'manufacturingNumber'){
			return alertInput === '기계 제조번호를 확인해 주세요.';
		}
	}
	// useEffect(() => {
	// 	const preventClose = (e: BeforeUnloadEvent) => {
	// 		e.preventDefault();
	// 		if (dataModal.message?.includes('저장')) {
	// 			return;
	// 		}
	// 		e.returnValue = true;
	// 	};
	// 	window.addEventListener(  "beforeunload" , preventClose );
	// 	return () => {
	// 		window.removeEventListener( "beforeunload" , preventClose );
	// 	};
	// }, [dataModal.message]);

	const connentCompany = [
		{company:'제스텍', device:'ZESTECH'},
		{company:'심팩', device:'SIMPAC'},
		{company:'마이다', device:'MIDA'},
		{company:'화일', device:'HIM'},
		{company:'국일', device:'KUKIL'},
		{company:'JNH', device:'JNH'},
		{company:'기타', device:'기타'},
	]
	console.log(inputState)

	return (
		<SectionFrame>
			{dataModal.menu === 'success' ?
				<ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{
					setDataModal(initModal)
					window.location.href = authValid() ? `/admin/fas/facility` : '/fas/facility'}}/>
				: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
					: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/>
						: null}

			{dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>setDataModal(initModal)}/>}

			{dataModal.menu === 'search' &&
				<CompanySelectModal closeButton={()=>setDataModal(initModal)}
														receivedCompany={(v)=> {
															factoryListRes(v.companyCode).then()
															setInputState( (prev: any) => ({
																...prev,
																companyName: v.companyName,
																companyCode: v.companyCode,
																location: locationFilter(v.address),
																ictNumber: ''}))}}/>}

			{dataModal.menu === 'peripheral' &&
				<PeripheralRegisterModal companyCode={dataValid(inputState.companyCode)}
																 selectFacility={inputState}
																 cancelEvent={()=>setDataModal(initModal)} viewType={false}
																 selectedPeripheral={dataValid(inputState?.peripheralCode,[])}
																 receivedPeripheral={(v)=>setInputState((prev:any)=>({...prev, peripheralCode: v}))}/>}

			{dataModal.menu === 'cancel' &&
				<SelectConfirmModal statusText={dataModal.message}
														noticeText={' * "예" 선택시 저장없이 이동합니다.'}
														cancelEvent={()=>{setDataModal(initModal)}}
														viewType={true}
														clickEvent={()=>{ window.location.href = authValid() ? `/admin/fas/facility` : '/fas/facility'}}/>}

			{dataModal.menu === 'save' &&
				<ConfirmPopup statusText={dataModal.message}
											clickEvent={()=>{
												setDataModal(initModal)
												window.location.href = authValid() ? `/admin/fas/facility` : '/fas/facility'
											}}/>}

			<MenuTitle title={t((authValid()? '업체/' : '')+'기본정보 관리 > 기계 기본정보 > 등록')} unitCase={'MenuTitle'}/>
			<ContentFrame flexDirection={'row'} padding={'30px 20px 20px 30px'}>
				<RegisterComp>
					<div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
						<MenuTitle title={t('기계 기본정보 등록')} unitCase={'MenuCompTitle'}/>
						<div style={{display:'flex'}}>
							<MainButtons name={'취소'} width={'56px'}
													 clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
							<MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
													 clickEvent={facilityRegisterResponse}/>
						</div>
					</div>
					<div style={{marginTop:'16px'}} className={'scroll_func'}>
						{authValid() &&
							<React.Fragment>
								<RegisterComponent type={'textButton'} title={t('업체명')} disabled={true}
																	 name={'companyName'} error={alertPoint('companyCode')}
																	 placeholder={'검색'} buttonWidth={'72px'} required={true} clearEvent={()=>setAlertInput('')}
																	 value={inputState.companyName} onChange={onChangeValue} onKeyPress={enterEvent}
																	 buttonName={'검색'} clickEvent={()=>{
									setAlertInput('')
									setDataModal({menu: 'search'})}}/>
								<RegisterComponent type={'text'} name={'location'} value={inputState.location} placeholder={'업체를 선택해 주세요.'}
																	 disabled={true} title={t('지역')} onChange={onChangeValue}/>
							</React.Fragment>}

						<div style={{display:'grid',gridTemplateColumns:'repeat(2, 392px)'}}>
							<RegisterOption>
								<div>{t('공장명')}</div>
								<div style={{position:'relative',display:'flex',
									pointerEvents:`${!inputState.companyName? 'none' : 'auto'}`,
									opacity:`${!inputState.companyName? 0.5 : 1}`
								}}>
									{inputState.companyCode === 'noCodeData'?
										<FactoryDropMenu
											clickEvent={(data)=>{setInputState((prev:any)=>({...prev, ...data}))}}
											selectCode={''}
											menuArray={[{factoryName:'업체를 선택해 주세요.'}]}/>
										:
										<FactoryDropMenu clickEvent={(data)=>{
											if(data.factoryName === '선택 없음'){
												setFactorySegment(['미등록'])
											}
											setInputState((prev:any)=>({...prev, ...data}))
											if(data.factoryCode){
												factoryDivision(dataValid(data.factoryCode,'')).then()
											}
										}} selectCode={dataValid(inputState.factoryCode,'')}
											//@ts-ignore
																		 menuArray={factoryList?.map((v)=>({
																			 factoryAddress: v.factoryAddress,
																			 factoryCode: v.factoryCode,
																			 factoryName: v.factoryName
																		 }))}/>
									}
								</div>
							</RegisterOption>

							<RegisterComponent type={'drop'} title={t('공장 세분화')} dropWidth={'208px'}
																 disabled={!inputState.companyName}
																 name={'factorySubdivision'} value={inputState.factorySubdivision} onChange={()=>''}
																 dropAddNoneSelect={factorySegment[0] !== '미등록'}
																 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, factorySubdivision: v}))}
																 dropArray={factorySegment}/>

							<RegisterComponent type={'drop'} title={t('기종')} name={'type'} value={inputState.type} required={true}
																 dropClickEvent={(v) =>{
																	 if(v === 'CNC'){
																		 setInputState((prev:any) => ({...prev, type: v, ictNumber:''}))
																	 }
																	 else if(v !== '프레스'){
																		 setInputState((prev:any) => ({...prev, type: v, maxTon:'0', ictNumber:''}))
																	 }
																	 else if(v !== '용접기'){
																		 setInputState((prev:any) => ({...prev, type: v, weldingType:'', ictNumber:''}))
																	 }
																	 else{
																		 setInputState((prev:any) => ({...prev, type: v, ictNumber:''}))
																	 }}}
																 dropArray={[t('프레스'),t('로봇'),t('CNC'),t('용접기'),t('밀링'),t('선반'),t('탭핑기'),t('비전기')]}
																 onChange={onChangeValue}/>
							<RegisterComponent type={'drop'} title={t('용접 종류')} disabled={inputState.type !== '용접기'} dropWidth={'208px'}
																 titleDisabled={inputState.type !== '용접기'} dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, weldingType: v}))}
																 name={'weldingType'} value={inputState.weldingType} onChange={onChangeValue} dropAddNoneSelect={true}
																 dropArray={['아르곤', '스팟', '통합']}/>

							<RegisterComponent type={'text'} name={'manager'} value={inputState.manager} paddingRight={true}
																 title={t('기계 담당자')} onChange={onChangeValue}/>
							<RegisterComponent type={'drop'} name={'overhaul'} value={inputState.overhaul}
																 dropWidth={'208px'}
																 dropClickEvent={(v)=>setInputState((prev:any)=>({...prev, overhaul:v}))}
																 title={t('오버홀')} dropArray={['유','무']}/>
							<RegisterComponent type={'calendar'} name={'productionDate'} left={150}
																 value={inputState.productionDate}
																 deleteEvent={()=>{setInputState((prev:any)=>({...prev, productionDate: ''}))}}
																 title={t('제조 연월일')}
																 onChange={(v)=> setInputState((prev:any)=>({...prev, productionDate: dayjs(v).format('YYYY.MM.DD')}))}
																 placeholder={'제조 연월일'}/>

							{/*<RegisterComponent type={'text'} name={'manufacturingNumber'} value={inputState.manufacturingNumber}*/}
							{/*									 title={t('제조번호')} onChange={onChangeValue}/>*/}
							<RegisterComponent type={'doubleCheck'} name={'manufacturingNumber'} value={inputState.manufacturingNumber}
																 title={t('제조번호')} onChange={(e)=>setInputState((prev:any)=>({...prev, manufacturingNumber: e.target.value, codeDoubleCheck:false}))}
																 clickEvent={serialDoubleCheck} error={alertPoint('manufacturingNumber')}
																 disabled={(!inputState.manufacturingNumber || inputState.codeDoubleCheck)}/>
							<RegisterComponent type={'text'} name={'maker'} value={inputState.maker}
																 required={true} error={alertPoint('maker')} paddingRight={true}
																 title={t('제조사')} onChange={(e)=>onChangeValue(e, {trim:true})}/>
							<RegisterComponent type={'text'} name={'facilityName'} value={inputState.facilityName} clickEvent={()=>setAlertInput('')}
																 title={t('기계명')} onChange={(e)=>onChangeValue(e, {trim:true})} required={true}
																 error={alertPoint('facilityName')} />


							{
								!window.localStorage.getItem('auth')?.includes('admin')?
									<RegisterComponent type={'text'} name={'ictNumber'} value={''} disabled={true} titleDisabled={true}
																		 title={'ICT 번호'} paddingRight={true} placeholder={'관리자 페이지에서 사용하는 메뉴 입니다.'}/>
									:

									(inputState.type === 'CNC' || inputState.type === '비전기') ?
								<RegisterComponent type={'unit'} name={'ictNumber'} paddingRight={true}
																	 value={inputState.ictNumber} unit={''} limit={8}
																	 error={alertPoint('ictNumber')} clickEvent={()=>setAlertInput('')}
																	 title={t('ICT 번호')} onChange={onChangeICT} placeholder={'숫자 7자리를 입력해 주세요.'}/>
								:

								<RegisterComponent type={'ict'} name={'ictNumber'} value={inputState.ictNumber}
																	 placeholder={'ICT번호를 발급해 주세요.'}
									// error={alertPoint('ictNumber')}
																	 clickEvent={()=> {
																		 setAlertInput('')
																		 generateIctNumber().then()
																		 // setInputState((prev:any)=>({...prev, ictNumber:'P16'+generateRandomCount()}))
																	 }}
																	 title={t('ICT 번호')} onChange={(e)=>onChangeValue(e,{number: true})}
																	 paddingRight={true} disabled={!inputState.companyName}
																	 limit={5}/>}

							<RegisterComponent type={'unit'} name={'maxTon'} value={numberLocaleString(inputState.maxTon)} unit={'ton'}
																 titleDisabled={inputState.type !== '프레스'} required={inputState.type === '프레스'}
																 limit={11} disabled={inputState.type !== '프레스'}
																 error={alertPoint('maxTon')} clickEvent={()=>setAlertInput('')}
																 title={t('최대 톤수')} onChange={(e)=>onChangeValue(e,{number: true})} placeholder={'-'}/>
							<RegisterComponent type={'text'} name={'hw'} value={inputState.hw} paddingRight={true}
																 title={'HW'} onChange={onChangeValue}/>
							<RegisterComponent type={'text'} name={'version'} value={inputState.version}
																 title={t('버전')} onChange={onChangeValue}/>

							<RegisterComponent type={'text'} name={'network'} value={inputState.network} paddingRight={true}
																 title={t('네트워크')} onChange={onChangeValue}/>

							<RegisterComponent type={'unit'} name={'voltage'} value={numberLocaleString(inputState.voltage)} unit={'V'} limit={11}
																 title={t('사용전압')} onChange={(e)=>onChangeValue(e,{number: true})} placeholder={'-'}/>
							<RegisterComponent type={'textClick'} name={'생산품목'} disabled={true}
																 buttonName={t('생산품목 보기')} value={''}
																 title={t('생산품목')} onChange={onChangeValue} placeholder={'-'}/>
							<RegisterComponent type={'button'} name={'peripheralCode'} value={''} left={15}
																 clickEvent={()=> {
																	 if(!inputState.companyCode || inputState.companyCode ==='noCodeData'){
																		 setDataModal({menu:'confirm', message:'업체를 먼저 선택해 주세요.'})
																	 }else{
																		 setDataModal( {menu: 'peripheral'} )
																	 }
																 }}
																 buttonName={(!inputState.peripheralCode || inputState.peripheralCode.length === 0)? '주변장치 등록' : '주변장치 변경'} width={'114px'}
																 title={t('주변장치')}/>



							{/*<RegisterComponent type={'empty'} name={'-'} value={'-'}*/}
							{/*									 title={t('-')} onChange={onChangeValue}/>*/}

							{/*<RegisterComponent type={'button'}  name={''}*/}
							{/*				   clickEvent={()=>window.location.href = '/admin/fas/facility/daily'}*/}
							{/*				   value={''} onChange={()=>''}*/}
							{/*                   title={t('일상점검')} buttonName={'일상점검 등록'} width={'112px'}/>*/}

							{/*<RegisterComponent type={'button'}  name={''}*/}
							{/*				   clickEvent={()=>window.location.href = '/admin/fas/facility/daily'}*/}
							{/*				   value={''} onChange={()=>''}*/}
							{/*                   title={t('주변장치')} buttonName={'주변장치 등록'} width={'112px'}/>*/}
						</div>
							<RegisterComponent type={''} name={''} value={''}
																 component={
								<div style={{display:'flex'}}>
									{connentCompany.map((v)=>(
										<label style={{display:'flex', alignItems:'center', cursor:'pointer', userSelect:'none'}}>
											<input type="radio" name={'connent-company'} style={{margin:0}} checked={v.device === inputState.device}
														 value={inputState.device}
														 onChange={()=>setInputState((prev:any)=>({...prev, device: v.device}))}/>
											<div style={{userSelect:'none', fontSize:'12px',
												fontWeight: v.device === inputState.device? 700 : 400,
												margin:'0 8px 0 4px'}}>{v.company}</div>
										</label>
									))}
								</div>
							} title={'연결된 디바이스'}/>

						<RegisterOption style={{borderBottom:'1px solid #E6E9F4', height:'112px'}}>
							<div style={{height:'112px', lineHeight:'112px'}}>{t('비고')}</div>
							<div className={'register_notice'}>
								<RegisterInput type={'textarea'} name={'notice'} value={inputState.notice}
															 onChange={onChangeValue}/>
							</div>
						</RegisterOption>
					</div>
				</RegisterComp>

				<div style={{width:'1px',backgroundColor:'#e0e0e0',margin:'0 28px'}}/>

				<div className={'scroll_comp'} style={{width:'379px', overflowY:'scroll'}}>
					<div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
						<MenuTitle title={'기계 이미지 등록'} unitCase={'MenuCompTitle'}/>
						<div style={{color:'#7E84A3',fontSize:'12px',lineHeight:'16px', height:'34px'}}>
							<div>{t('*최대 4개의 이미지 등록 가능')}</div>
							<div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
						</div>
					</div>
					<TotalImageUploadBox imageKeyArray={setImageKeyState}
															 profileKey={inputState?.profileImage}
															 receiveProfileKey={(v: any) => setInputState((prev:any)=>({...prev, profileImage: v}))}
															 profileSelect={true}/>
					<div style={{...multipleImageGridStyle, marginTop:'16px'}}>
						<div>
							<MenuTitle title={'스펙 명판'} unitCase={'MenuCompTitle'}/>
							<SingleImageUploadBox imageKey={(v)=>setInputState((prev:any)=>({...prev, facilitySpecImg:v}))} profileSelect={false}/>
						</div>
						<div>
							<MenuTitle title={'능력 명판'} unitCase={'MenuCompTitle'}/>
							<SingleImageUploadBox imageKey={(v)=>setInputState((prev:any)=>({...prev, facilityAbilityImg:v}))} profileSelect={false}/>
						</div>
						<div>
							<MenuTitle title={'사용 설명서'} unitCase={'MenuCompTitle'}/>
							<SingleImageUploadBox imageKey={(v)=>setInputState((prev:any)=>({...prev, facilityManualImg:v}))} profileSelect={false}/>
						</div>
					</div>
				</div>
			</ContentFrame>
		</SectionFrame>
	);
};


export default FacilityRegister;

const RegisterComp = styled.div`
	width: 784px;
	height: 100%;
	
	.register_notice {
		min-height: 112px;
		height: 112px;
	}
	
	.scroll_func {
		width: 804px;
		height: 589px;
		overflow-x: hidden;
		overflow-y: scroll;
		
		&::-webkit-scrollbar {
			width: 20px;
			display: block;
		} 
	}
`

const RegisterOption = styled.div`
		display: flex;
		border-top: 1px solid #E6E9F4;
		user-select: none;

		&>div{
			height: 48px;
			line-height: 48px;
			box-sizing: border-box;
			padding:0 16px;
			align-items: center;
			display: flex;
		}
		&>div:first-child{
			width: 168px;
			min-width: 168px;
			color: #131523;
			background-color: #F5F6FA;
			font-size: 12px;
			font-weight: 800;
		}
		&>div:last-child{
			width: 100%;
			max-width: 616px;
			max-height: 48px;
		}
	`
