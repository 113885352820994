import React , {useEffect , useState} from 'react';
import styled from "styled-components";
import {
	CheckboxStyle ,
	ContentFrame ,
	NoData ,
	SectionFrame ,
	validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {ReactDiagram} from "gojs-react";
import * as go from 'gojs'
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {useRecoilState , useRecoilValue} from "recoil";
import {persistCompanyData , processSelectCompany , userPersistCompanyData} from "../../../../../common/StateManage";
import {
	authValid ,
	buttonAuthValid ,
	listLoadingCircle ,
	loadingRemove ,
	menuVisitLogRegister
} from "../../../../../function/function";
import {useLocation} from "react-router-dom";
import {gojsLicenseKey} from "../../../../../common/LicenseKey";
import CompanySearchList from "../../../../../components/list/CompanySearchList";
import CalendarValidButton from '../../../../../components/button/CalendarValidButton'
import dayjs from 'dayjs'
import {FILE_ADDRESS} from "../../../../../api/endPoint";

const ProcessList = () => {

	const location = useLocation()
	const companyInfo = useRecoilValue<any>(authValid() ? persistCompanyData : userPersistCompanyData)

	const [selectedCompany, setSelectedCompany] = useRecoilState<any> (processSelectCompany)
	React.useEffect(()=>{
		setSelectedCompany( !selectedCompany.companyCode? {...companyInfo} : {...selectedCompany})
	},[])
	const [nodeDataArray, setNodeDataArray] = useState<any>([])
	const [linkDataArray, setLinkDataArray] = useState<any>([])
	const [realtimeDataArray, setRealtimeDataArray] = useState<any>([])
	const [facilityArray, setFacilityArray] = useState<Array<{facilityCode:string, facilityName:string}>>([])
	const [realtimeCheck, setRealtimeCheck] = useState<boolean>(false)
	const [refreshDiagram, setRefreshDiagram] = useState<boolean>(false)
	const [intervalFacility, setIntervalFacility] = useState<any>()
	const [counter, setCounter] = useState<boolean>(false)
	const [productionData, setProductionData] = useState<any>([])
	const [selectedDate, setSelectedDate] = useState(dayjs().subtract(1,'day').format('YYYY-MM-DD'))
	const [productLoading, setProductLoading] = useState<boolean>(false)
	const [clickNode, setClickNode] = useState<any>({type:'-', facilityName: '-', maker: '-', manufacturingNumber: '-', ictNumber: '-', hw: '-', profileImageUrl: '-'})
	const [expansionProcess, setExpansionProcess] = useState(false)
	const { t }:any = useTranslation();

	const pressImage = `${FILE_ADDRESS}img/zes_20221019020220_867.jpeg`

	// 공정 내에 선택된 기계의 세부 정보를 확인하는 요청 api
	const facilityDataRes = async (code: string) => {
		try {
			const response = await client.post('/facility/detail',
																				 {facilityCode: code},{headers:{Authorization}})
			const data = response.data.data
			setClickNode(data.current)
		}catch (e) {
			console.log(e)
		}
	}

	const combinedArray = nodeDataArray?.map((node:any) => {
		const matchingFacility = realtimeDataArray.find((facility:any) => facility.facilityCode === node.facilityCode);
		return matchingFacility ? { ...node, ...matchingFacility } : node;
	});

	const productionNodeArray = nodeDataArray?.map((node:any) => {
		const matchingFacility = productionData.find((facility:any) => facility.facilityCode === node.facilityCode);
		return matchingFacility ? { ...node, ...matchingFacility } : node;
	});

	const nodeKeyCombineData = realtimeDataArray?.map((node:any)=>{
		const matchingNode = nodeDataArray?.find((facility:any)=>(facility.facilityCode === node.facilityCode))
		return matchingNode? {...node, key: matchingNode.key} : node
	})

	const matchingLinkFacilityCode = linkDataArray?.map((link:any) => {
		const matchingFacility = nodeKeyCombineData.find((facility:any) => facility.key === (link.from || link.to))
		const fromPressState = nodeKeyCombineData.find((facility:any) => facility.key ===  link.from)?.pressState
		const toPressState = nodeKeyCombineData.find((facility:any) => facility.key === link.to)?.pressState
		return matchingFacility ? { ...link, matchFacility: matchingFacility.facilityCode, pressState: (fromPressState === 'off' || toPressState === 'off')? 'off' : matchingFacility.pressState } : link;
	});

	const combineLinkArray = matchingLinkFacilityCode?.map((node:any) => {
		const matchingFacility = nodeKeyCombineData.find((facility:any) => facility.facilityCode === node.matchFacility);
		return matchingFacility ? { ...node, key: matchingFacility.key, totalCounter: matchingFacility.totalCounter, wholeCounter: matchingFacility.wholeCounter} : node;
	})

	let requestCount = 0
	//해당 공정에 배치된 기계의 실시간 상태 정보를 가져오기 위한 api
	const processStatusRes = async () => {
		requestCount++
		try {
			const response = await client.post('/process/real/time/facility',{
				facilityCodeList: nodeDataArray.map((v:any)=>(v.facilityCode))
			},{headers:{Authorization}})
			setRealtimeDataArray(response.data.data)
		}

		catch (e) {
			console.log(e)
		}
		finally {
			requestCount--
		}
	}

	useEffect(()=>{
		if(!window.localStorage.getItem('auth')!?.includes('admin')){
			menuVisitLogRegister(window.localStorage.getItem('companyID'),'공정 관리').then()
		}
	},[])

	const diagramRef = React.useRef<any>(null);
	const initDiagram = () => {
		go.Diagram.licenseKey = gojsLicenseKey
		const $= go.GraphObject.make

		const diagram = $(go.Diagram,{

			initialAutoScale: go.Diagram.Uniform,
			// "ChangeSelection": onSelectionChanged,
			maxSelectionCount: 1, allowZoom: true,
			isReadOnly: true,
			// isEnabled: false,
			// model: new go.GraphLinksModel({
			// 	linkKeyProperty:'key'
			// 	}),
		})

	const model = diagram.model = new go.GraphLinksModel(nodeDataArray, linkDataArray)
		model.linkKeyProperty = "facilityCode"
		diagram.toolManager.mouseWheelBehavior = go.ToolManager.WheelZoom

		diagram.nodeTemplate =
			$(go.Node,'Vertical',{
					locationObjectName:'Port',
					locationSpot: go.Spot.Top,
					click: function (e, obj){facilityDataRes(obj.part?.data.facilityCode).then()},
					selectionAdornmentTemplate: $(go.Adornment,"Auto",
						$(go.Shape,"RoundedRectangle",{fill: null, stroke: "dodgerblue", strokeWidth:3}),
						$(go.Placeholder)
						),
					// linkConnected: updatePortHeight,
					// linkDisconnected: updatePortHeight,
				},
				// new go.Binding('location','position',go.Point.parse).makeTwoWay(go.Point.stringify),
				new go.Binding('position', 'position', go.Point.parse).makeTwoWay((pos) => {
					return go.Point.stringify(pos);
				}),
				// $(go.Picture,{source: pressImage, width:50, height: 55,
				// 		name:'Port', cursor:'pointer',portId:'',
				// 		fromLinkable: true, toLinkable:true
				// 	},
				// 	new go.Binding('source', pressImage)),
				realtimeCheck?
					$(go.TextBlock, {margin: 8, editable: true, row: 1, column: 2, font: 'bold 22px Pretendard', stroke: '#0058ff'},
						new go.Binding('text', counter ? 'totalCounter' : 'presetCounter', function (value, data) {
							return (data?.part.data.pressState === 'off' || data?.part.data.pressState === 'empty')  ? '' : value
						}).makeTwoWay())
					:
					$(go.TextBlock, {margin: 8, editable: true, row: 1, column: 2, font: 'bold 22px Pretendard', stroke: '#0058ff'},
						new go.Binding('text', 'production', function (value) {
							return value
						}).makeTwoWay()),

				$(go.Panel,"Spot",{scale:1},
					$(go.Shape, 'RoundedRectangle',{width:50, height:60, strokeWidth:0,
						fill:  '#afd4fe',
						// name:'Port', cursor:'pointer',portId:'',
						// fromLinkable: true, toLinkable:true

					},
						//?? 이거 얻어걸렸는데. 해당 상태에 포함된 node만 확인할수 있음. err인 모든 애들만 보여짐
						// new go.Binding('visible', 'pressState', function(pressState) {
						// 	// 'off' 상태일 때만 텍스트 블록을 표시하지 않음
						// 	return pressState === 'off';
						// }),

						new go.Binding("fill", "pressState", function(key) {
							return  (!realtimeCheck? "#afd4fe" :  key === "off" ? "gray" :  key === "err"? "red" : key === "empty"? "#afd4fe" : "#31FF00");
						},
						)
					),
					$(go.Picture,{source: pressImage, width:50, height: 55,
							name:'Port', cursor:'pointer',portId:'',
							fromLinkable: true, toLinkable:true
						},
						new go.Binding('source', pressImage)),
				),

				// $(go.Panel,"Auto",
				// 	$(go.Shape,{fill:'#afd4fe', strokeWidth:0, width:40,height:20,
				// 		// name:'Port', cursor:'pointer',portId:'',
				// 		// fromLinkable: true, toLinkable:true
				// 	})),

				$(go.Panel,"Auto",
					// $(go.Shape,'RoundedRectangle',{fill:'lime',strokeWidth:0, width:160, height: 40},
					// 	new go.Binding('fill','color')),

					$(go.TextBlock,{margin:8, editable:true, row:1, column:2, font: '14px Nanum Gothic',},
						new go.Binding('text','facilityName').makeTwoWay(),
					),
				),
				// $(go.Shape,{fill:'lime', strokeWidth:0, width:40,height:60, position: new go.Point(0,0),
				// 	name:'Port', cursor:'pointer',portId:'',
				// 	fromLinkable: true, toLinkable:true
				// }),
			)

		diagram.linkTemplate =
			$(go.Link,
				{
					layerName: "Background",
					routing: go.Link.Orthogonal,
					corner: 15,
					reshapable: true,
					resegmentable: true,
					fromSpot: go.Spot.RightSide,
					toSpot: go.Spot.LeftSide
				},
				// make sure links come in from the proper direction and go out appropriately
				new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
				new go.Binding("toSpot", "toSpot", go.Spot.parse),
				// new go.Binding("points",'facilityCode').makeTwoWay(),
				// mark each Shape to get the link geometry with isPanelMain: true
				$(go.Shape, { isPanelMain: true, strokeWidth: 10 , stroke:'#afd4fe'
					},
					new go.Binding("stroke", "pressState", function(key) {
						return  (!realtimeCheck? "#afd4fe" :  key === "off" ? "gray" :  key === "err"? "red" : key === "empty"? "#afd4fe" : "#31FF00");
					}),
					// get the default stroke color from the fromNode
					new go.Binding("stroke", "fromNode"),
					// but use the link's data.color if it is set
					new go.Binding("stroke", "color")),
				$(go.Shape, { isPanelMain: true, stroke: "white", strokeWidth:3, name: "PIPE", strokeDashArray: [20, 40] },
					new go.Binding("stroke", "pressState", function(key) {
						return  (!realtimeCheck? '#fff':  key === "on" ? '#fff' : 'transparent');
					}),
					)
			);

		loop()

		// let opacity = 1;
		// let down = true;

		function loop() {
			let gram = diagram;
			setTimeout(() => {
				let oldskips = gram.skipsUndoManager;
				gram.skipsUndoManager = true;
				gram.links.each(link => {
					let shape = link.findObject("PIPE");
					//@ts-ignore
					let off = shape.strokeDashOffset - 3;
					//@ts-ignore
					shape.strokeDashOffset = (off <= 0) ? 60 : off;
					// if (down) opacity = opacity - 0.01;
					// else opacity = opacity + 0.003;
					// if (opacity <= 0) { down = !down; opacity = 0; }
					// if (opacity > 1) { down = !down; opacity = 1; }
					// //@ts-ignore
					// shape.opacity = opacity;
				});
				gram.skipsUndoManager = oldskips;
				loop();
			}, 60);
		}
		return diagram
	}


	// React.useEffect(()=>{
	// 	initDiagram()
	// },[realtimeCheck, counter])

	// 선택된 업체의 세부 공정 데이터를 가져오는 요청 api
	const processDetailRes = async () => {
		setRefreshDiagram(false)
		try {
			listLoadingCircle()
			const response = await client.post('/process/detail',{
				companyCode: selectedCompany.companyCode
			},{headers:{Authorization}})
			const data = response.data.data
			setLinkDataArray(data.processLink)
			setNodeDataArray(data.processNode)
			setFacilityArray(data.facilityInfo)
			loadingRemove()
		}catch (e) {
			console.log(e)
			loadingRemove()
		}
		setRefreshDiagram(true)
	}

	const startInterval = () => {
		const interval = setInterval(()=>{
			if(requestCount === 0){
				processStatusRes().then()
			}
		},1000)
		setIntervalFacility(interval)
	}

	const endInterval = () => {
		clearInterval(intervalFacility)
		setIntervalFacility(null)
	}


	React.useEffect(()=>{
		endInterval()
		setRealtimeCheck(false)
	},[selectedCompany.companyCode])

	React.useEffect(()=>{
		processDetailRes().then()
	},[selectedCompany.companyCode, realtimeCheck, counter,])

	// 해당 공정의 기계별 생산량값을 확인하는 요청api
	const processFacilityProductionRes = async () => {
		setProductLoading(true)
		try {
			const response = await client.post('/process/facility/production',{
				date: selectedDate,
				facilityCodeList: facilityArray?.map((v)=>(v.facilityCode))
			},{headers:{Authorization}})
			if(response.data.status === 200){
				setProductionData(response.data.data)
			}
			else{
				setProductionData([])
			}
		}
		catch (e) {
			console.log(e)
		}
		finally {
			setProductLoading(false)
		}
	}

	React.useEffect(()=>{
		if(selectedCompany.companyCode && facilityArray?.length > 0){
			processFacilityProductionRes().then()
		}
	},[selectedCompany.companyCode, selectedDate, facilityArray])

	// Zoom In 함수
	const zoomIn = () => {
		const diagram = diagramRef?.current.getDiagram();
		diagram.commandHandler.increaseZoom();
	};

	// Zoom Out 함수
	const zoomOut = () => {
		const diagram = diagramRef?.current.getDiagram();
		diagram.commandHandler.decreaseZoom();
	};

	return (
		<SectionFrame>
			<div style={{display:'flex',justifyContent:'space-between'}}>
				<div style={{display:'flex'}}>
					<MenuTitle title={location.pathname.includes('fas')? '업체/기본정보 관리 > 공정 관리' : '공정 관리 > 공정정보 관리'} unitCase={'MenuTitle'}/>
					{realtimeCheck &&
						<div style={{display:'flex'}}>
							<span style={{margin:'8px 8px 0',alignSelf:'center', fontSize:'24px', fontWeight:800}}>{'>'}</span>
							<span style={{alignItems:'center', alignSelf:'center', fontSize:'18px', marginTop:'8px', fontWeight:700, color:'#0058ff'}}>{selectedCompany?.companyName}</span>
						</div>}
				</div>
				<div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
					{/*<CheckboxStyle style={{marginLeft: '16px' , alignItems: 'center' , display: 'flex', opacity: !companyData.companyCode? 0.3 : 1}}>*/}
					{/*	<span style={{fontSize: '12px' , alignSelf: 'center' , marginRight: '6px', }}>{'사용 가능한 재고만 보기'}</span>*/}
					{/*	<input type="checkbox" checked={expansionProcess} disabled={!companyData.companyCode}*/}
					{/*				 onChange={(e)=>{*/}
					{/*					 if(e.target.checked){*/}
					{/*						 setExpansionProcess(true)*/}
					{/*					 }*/}
					{/*					 else{*/}
					{/*						 setExpansionProcess(false)*/}
					{/*					 }*/}
					{/*				 }}/>*/}
					{/*</CheckboxStyle>*/}
					<MainButtons name={'전체 화면'} marginDis={true} disabled={(nodeDataArray?.length === 0 || !nodeDataArray) || (realtimeCheck && realtimeDataArray?.length === 0) || !selectedCompany.companyCode}
											 clickEvent={()=> window.location.href = authValid()? '/admin/fas/full_process' : '/fas/full_process'}/>
					{authValid() && <CheckboxStyle
						style={{marginTop: '8px' , alignItems: 'center' , display: 'flex' , opacity: !selectedCompany.companyCode ? 0.3 : 1}}>
						<span style={{fontSize: '12px' , alignSelf: 'center' , marginRight: '6px' ,}}>{'업체목록 감추기'}</span>
						<input type="checkbox" checked={expansionProcess} disabled={!selectedCompany.companyCode}
									 onChange={(e) => {
										 if(e.target.checked){
											 setExpansionProcess(true)
										 } else{
											 setExpansionProcess(false)
										 }
									 }}/>
					</CheckboxStyle>}
				</div>
			</div>
			<ProcessComponent>
				{!expansionProcess && authValid() &&
					<CompanySearchList
						onDoubleClick={()=>
							window.location.href = authValid()? `/admin/fas/process/${selectedCompany.companyCode}` : `/fas/process/${selectedCompany.companyCode}`}
						companyDataReceive={(data)=> {
							setSelectedCompany(data)
							setRealtimeCheck(false)
							setCounter(false)
				}}/>}
				{/*{authValid() && <SolutionCompanyFilterList receivedData={selectedCompany} */}
				{/*																					 onlyList={true}/>}*/}
				{/*<CompanyList companyDataReceive={(data)=>setCompanyData(data)} companyCodeReceive={(v)=>setCompanyCode(v)} companyNameReceive={(v)=>setCompanyName(v)}/>*/}
				<ContentFrame width= {'1270px'}>
					<div style={{display:'flex',justifyContent:'space-between', alignItems:'center'}}>
						<div style={{display:'flex'}}>
							<MenuTitle title={'공정 관리'} unitCase={'MenuCompTitle'}/>
							{/*{authValid() && <CheckboxStyle*/}
							{/*	style={{marginLeft: '16px' , alignItems: 'center' , display: 'flex' , opacity: !selectedCompany.companyCode ? 0.3 : 1}}>*/}
							{/*	<span style={{fontSize: '12px' , alignSelf: 'center' , marginRight: '6px' ,}}>{'업체목록 감추기'}</span>*/}
							{/*	<input type="checkbox" checked={expansionProcess} disabled={!selectedCompany.companyCode}*/}
							{/*				 onChange={(e) => {*/}
							{/*					 if(e.target.checked){*/}
							{/*						 setExpansionProcess(true)*/}
							{/*					 } else{*/}
							{/*						 setExpansionProcess(false)*/}
							{/*					 }*/}
							{/*				 }}/>*/}
							{/*</CheckboxStyle>}*/}
							{/*{realtimeCheck &&*/}
							{/*	helperModule(*/}
							{/*	<div>*/}
							{/*		<div style={{fontSize:'14px', fontWeight:700}}>{'설비 상태'}</div>*/}
							{/*		<div style={{display:'flex',marginTop:'8px'}}>*/}
							{/*			<div style={{display:'flex', fontWeight:700}}>*/}
							{/*				<div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'#31FF00',marginRight:'8px'}}/>*/}
							{/*				<div>{'정상'}</div>*/}
							{/*			</div>*/}
							{/*			<div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>*/}
							{/*				<div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'gray',marginRight:'8px'}}/>*/}
							{/*				<div>{'전원 OFF'}</div>*/}
							{/*			</div>*/}
							{/*			<div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>*/}
							{/*				<div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'red',marginRight:'8px'}}/>*/}
							{/*				<div>{'에러'}</div>*/}
							{/*			</div>*/}
							{/*			<div style={{display:'flex', fontWeight:700, marginLeft:'16px'}}>*/}
							{/*				<div style={{width:'16px', height:'16px', borderRadius:'50%', backgroundColor:'#afd4fe',marginRight:'8px'}}/>*/}
							{/*				<div>{'기계 데이터 없음'}</div>*/}
							{/*			</div>*/}
							{/*		</div>*/}
							{/*	</div>,'330px')}*/}
						</div>

						<div style={{display:'flex'}}>

							{realtimeCheck && <div style={{display: 'flex' , justifyContent: 'flex-end'}}>
								<MainButtons name={'현재 카운터'} clickEvent={() => setCounter(false)} selected={!counter} margin={'0'}/>
								<MainButtons name={'총 카운터'} clickEvent={() => setCounter(true)} selected={counter} margin={'0'}/>
							</div>}

							{!realtimeCheck && <CalendarValidButton loading={productLoading} buttonWidth={'115px'}
																		disabled={productLoading || ( nodeDataArray?.length === 0 || !nodeDataArray ) || ( realtimeCheck && realtimeDataArray?.length === 0 )}
																		receiveDate={(date) => setSelectedDate(dayjs(date).format('YYYY-MM-DD'))}/>}

							<MainButtons name={realtimeCheck? '설비별 생산량' : '실시간 설비 상태'} width={'120px'} marginDis={true}
													 loading={(realtimeCheck && realtimeDataArray?.length === 0)}
													 disabled={(nodeDataArray?.length === 0 || !nodeDataArray) || (realtimeCheck && realtimeDataArray?.length === 0) || productLoading}
													 clickEvent={()=>{
														 setSelectedDate(dayjs().subtract(1,'day').format('YYYY-MM-DD'))
														 setClickNode({type:'-', facilityName: '-', maker: '-', manufacturingNumber: '-', ictNumber: '-', hw: '-', profileImageUrl: '-'})
														 setRealtimeCheck(!realtimeCheck)
														 setRefreshDiagram(false)
														 clearInterval(intervalFacility)
								if(!realtimeCheck){
									startInterval()
								}else{
									endInterval()
								}
							}} />

							{nodeDataArray !== undefined &&
								<MainButtons name={'수정'} width={'56px'} marginDis={true}
														 disabled={window.location.pathname.includes('fas')? buttonAuthValid('update','12') : buttonAuthValid('update','67')}
														 clickEvent={()=>window.location.href = authValid()? `${location.pathname}/${selectedCompany.companyCode}` : `${location.pathname}/${selectedCompany.companyCode}`}/>
							}
						</div>
					</div>

					{!selectedCompany.companyCode?
							<NoData height={580}>{t('* 업체를 선택해 주세요.')}</NoData>
						:
					selectedCompany.companyCode !== '' && !nodeDataArray &&
							<div style={{display:'flex',flexDirection:'column',justifyContent:'center',gap:'16px',marginTop:'24px', border:'1px solid #D7DBEC', borderRadius:'4px', height:'100%', boxShadow:'0 1px 4px #15223214'}}>
								{facilityArray?.length !== 0 &&
									<MainButtons name={'등록'} buttonType={'cta'} width={'80px'}
															 disabled={window.location.pathname.includes('fas')? buttonAuthValid('create','12') : buttonAuthValid('create','67')}
															 clickEvent={()=>window.location.href = `${location.pathname}/${selectedCompany.companyCode}`}/>}
								<div style={{opacity:0.5, fontSize:'14px',textAlign:'center',userSelect:'none'}}>
									{`*${selectedCompany.companyName}`+ (facilityArray?.length !== 0 ? t(` 공정정보를 등록해주세요.`) : t(` 등록된 설비가 없습니다.`))}
								</div>
							</div>}

					{selectedCompany.companyCode !== '' && nodeDataArray !== undefined && refreshDiagram && !productLoading &&
						<div style={{display:'flex',justifyContent:'flex-end',gap:'6px',marginTop:'10px',height: authValid()?'350px' : '590px', position:'relative'}}>
							<div className={'process_zoom_button'} style={{right: authValid()? !expansionProcess? 0 : '254px' : '254px'}}>
								<div className={'zoom_button'} onClick={zoomIn} style={{marginBottom:'8px'}}>{'╋'}</div>
								<div className={'zoom_button'} onClick={zoomOut} >{'━'}</div>
							</div>

							<ReactDiagram divClassName={'process_info_comp'}
														 initDiagram={initDiagram} ref={diagramRef}
								// skipsDiagramUpdate={true}
														 nodeDataArray={realtimeCheck ? combinedArray : productionNodeArray}
														 linkDataArray={realtimeCheck ? combineLinkArray : linkDataArray}/>

							{
								(authValid()? expansionProcess : !expansionProcess) &&
								<NodeFacilityComponent thumbText={clickNode?.profileImageUrl === '-' ? '* 설비를 선택해 주세요.' :
								!clickNode?.profileImageUrl ? '이미지가 없습니다.' : ''}>
								<div className={'facility_thumb'}
										 style={{
											 backgroundImage: `url('${clickNode?.profileImageUrl}')`,
											 backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
										 }}/>
								<div className={'facility_info'}>
									<div>{'기종'}</div>
									{validationValue(clickNode.type)}
									<div>{'제조번호'}</div>
									{validationValue(clickNode.manufacturingNumber, '없음')}
									<div>{'제조사'}</div>
									{validationValue(clickNode.maker)}
									<div>{'설비명'}</div>
									{validationValue(clickNode.facilityName)}
									<div>{'ICT번호'}</div>
									{validationValue(clickNode.ictNumber, '할당 안됨')}
									<div>{'HW'}</div>
									{validationValue(clickNode.hw)}
								</div>
							</NodeFacilityComponent>
							}
						</div>
					}
					{realtimeCheck &&
						<div className={'process_status_mark'}>
						<div style={{display: 'flex' , fontWeight: 700}}>
							<div style={{
								width: '12px' ,
								height: '12px' ,
								borderRadius: '50%' ,
								backgroundColor: '#31FF00' ,
								marginRight: '8px'
							}}/>
							<div>{'정상'}</div>
						</div>
						<div style={{display: 'flex' , fontWeight: 700 , marginLeft: '16px'}}>
							<div style={{
								width: '12px' ,
								height: '12px' ,
								borderRadius: '50%' ,
								backgroundColor: 'gray' ,
								marginRight: '8px'
							}}/>
							<div>{'전원 OFF'}</div>
						</div>
						<div style={{display: 'flex' , fontWeight: 700 , marginLeft: '16px'}}>
							<div style={{
								width: '12px' ,
								height: '12px' ,
								borderRadius: '50%' ,
								backgroundColor: 'red' ,
								marginRight: '8px'
							}}/>
							<div>{'에러'}</div>
						</div>
						<div style={{display: 'flex' , fontWeight: 700 , marginLeft: '16px'}}>
							<div style={{
								width: '12px' ,
								height: '12px' ,
								borderRadius: '50%' ,
								backgroundColor: '#afd4fe' ,
								marginRight: '8px'
							}}/>
							<div>{'기계 데이터 없음'}</div>
						</div>
					</div>}
				</ContentFrame>
			</ProcessComponent>
		</SectionFrame>
	);
};

export default ProcessList;

	const ProcessComponent = styled.div`
		display: flex;
		gap: 16px;
		canvas { outline: none; }
		.process_info_comp{
			width: 100%;
			height: 574px;
			border: 1px solid #D7DBEC;
			border-radius: 4px;
			box-shadow: 0 1px 4px #15223214;
		}
		
		.process_zoom_button{
			position: absolute;
			top: 0;
			box-sizing: border-box;
			padding: 8px;
			z-index: 100;
			border: 1px solid #D7DBEC;
			border-radius: 4px;
			background-color: #fff;
			.zoom_button{
				box-sizing: border-box;
				width: 24px;
				height: 24px;
				border: 1px solid #D7DBEC;
				border-radius: 4px;
				cursor: pointer;
				user-select: none;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				font-weight: 800;
				font-family: Pretendard, sans-serif;
				color: #828282;
				&:hover{
					background-color: #0058ff;
					color: #fff;
				}
			}
		}
		
		.process_status_mark{
			display: flex;
			position: absolute;
			z-index: 100;
			border: 1px solid #D7DBEC;
			border-radius: 4px;
			background-color: #fff;
			left: 30px;
			bottom: 32px;
			font-size: 12px;
			padding: 8px;
			//margin: 0 0 8px 30px;
		}
		
	`

const NodeFacilityComponent = styled.div<{thumbText?: string}>`
	width: 248px;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: #F5F6FA;
	padding: 24px 16px 0;
	.facility_thumb{
		border: 1px solid #D7DBEC;
		border-radius: 4px;
		width: 100%;
		height: 104px;
		position: relative;

		&::before{
			position: absolute;
			content: '${props => props.thumbText}';
			width: 100%;
			height: 100%;
			opacity: 0.5;
			font-size: 12px;
			text-align: center;
			line-height: 104px;
		}
	}
	
	.facility_info{
		display: grid;
		grid-template-columns: 72px 128px;
		gap: 16px;
		margin-top: 16px;
		font-size: 12px;
		font-weight: 800;
		&>div:nth-child(2n){
			text-align: right;
			font-weight: 400;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}
`