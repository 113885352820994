import React , {useRef , useState} from 'react';
import ToastPopup from "../../../../../components/modal/ToastPopup";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid , dataAccessLoading , dataValid , filterCheckedRow , handleRowDelete ,
  initModal , loadingRemove , onChangeCheck , onMultiRegChangeArray ,
  singleCheckedFunction
} from "../../../../../function/function";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import ListImageUploadModal from "../../../../../components/modal/ListImageUploadModal";
import SingleListImageUploadModal from "../../../../../components/modal/SingleListImageUploadModal";
import FactoryRegisterModal from "../../../../../components/modal/FactoryRegisterModal";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import dayjs from "dayjs";
import MainButtons from "../../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {useTranslation} from "react-i18next";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import MenuTitle from "../../../../../components/title/MenuTitle";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const PeripheralsMultipleReg = () => {
  const {t} :any = useTranslation()
  const selectedCompany = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const menuTitle = ['장치 종류' , '장치 제조번호' , '장치 제조사' , '장치 이름' , '제조 연월일' , '담당자' , '공장명' ,
    '공장 세분화명' , '장치 사진' , '스펙 명판사진' , '능력 명판 사진' , '사용 설명서']
  const needValue = ['장치 종류' , '장치 제조사' , '장치 이름']
  const selectType = ['미스피드 검출장치' , '하사점 검출장치' , '로드모니터' , '앵글시퀀서' , '엔코더' , '통관센서' , '유틸리티센서' , '기타']
  const scrollRef = useRef<any>( null )

  const initialObject = {
    companyCode: selectedCompany.companyCode ,
    factoryCode: '' ,
    factoryName: '' ,
    factorySubDivision: '' ,
    managerName: '' ,
    manufactureDate: '' ,
    peripheralManufacturer: '' ,
    peripheralName: '' ,
    peripheralNumber: '' ,
    peripheralType: '미스피드 검출장치' ,
    peripheralImg: [] ,
    peripheralImgUrl: [] ,
    specImg: '' ,
    specImgUrl: '' ,
    abilityImg: '' ,
    abilityImgUrl: '' ,
    manualImg: '' ,
    manualImgUrl: '' ,
    codeDoubleCheck: false
  }

  const generateInitialRowList = Array.from( {length: 20} , ( _ , i ) => (
      {
    id: i + 1 ,
    companyCode: selectedCompany.companyCode ,
    factoryCode: '' ,
    factoryName: '' , //공장 이름
    factorySubDivision: '' , //공장 세분화 이름
    managerName: '' , //담당자
    manufactureDate: '' , //제조일
    peripheralManufacturer: '' , //장치 제조사
    peripheralName: '' , //장치 이름
    peripheralNumber: '' , //장치 제조번호
    peripheralType: '미스피드 검출장치' , //장치 종류
    peripheralImg: [] ,
    peripheralImgUrl: [] ,
    specImg: '' , //스펙 이미지
    specImgUrl: '' ,
    abilityImg: '' , //능력 명판 사진
    abilityImgUrl: '' ,
    manualImg: '' , //설명서 이미지
    manualImgUrl: '' ,
    codeDoubleCheck: false
  }
  ) );

  const [rowList,setRowList] = useState<any []>(generateInitialRowList)
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [selectedID, setSelectedID] = useState<number>(0)
  const filteredData = rowList.filter( ( item: any ) => {
    return item.peripheralNumber !== '' && item.codeDoubleCheck === false;
  } );
  const peripheralsRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralType ) ){
      setDataModal( {menu: 'confirm' , message: '장치 종류를 선택해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralManufacturer ) ) ){
      setDataModal( {menu: 'confirm' , message: '장치 제조사를 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.peripheralName ) ) ){
      setDataModal( {menu: 'confirm' , message: '장치 이름을 입력해 주세요.'} )
    } else if( filterCheckedRow( filteredData , checkedList , 'id' ).length !== 0 ){
      setDataModal( {menu: 'confirm' , message: '장치 제조번호 중복 확인을 해주세요.'} )
    }else{
      try {
        dataAccessLoading()
        const response = await client.post( `/peripheral/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {
                                              headers: {Authorization}
                                            } )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            loadingRemove()
            setRowList( [] )
            setCheckedList( [] )
            setDataModal( {menu: 'success' , message: '주변장치 정보가 등록 되었습니다.'} )
            setRowList( generateInitialRowList )
          } , 500 )
          setDataModal( initModal )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  const removeValueData = () => {
    const deleteData = rowList.map(v => {
      if(checkedList.includes(v.id)){
        return {
          ...initialObject, id: v.id
        }
      }
      return v
    })
    setRowList(deleteData)
  }
  const checkboxValid = () => {
      return {list: rowList , id: 'id'}

  }

  const handleSelectValue = ( i: number , v: any ) => {
    const updateRow = [...rowList]
    updateRow[i].peripheralType = v
    updateRow[i].codeDoubleCheck = false
    setRowList( updateRow )
  }



  const serialDoubleCheck = async ( serialCode: string , type: string , index: number ) => {
    try {
      const response = await client.post( '/peripheral/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        peripheralNumber: serialCode ,
        peripheralType: type
      } , {headers: {Authorization}} )
      if( response.data.status === 200 ){

          const copyRowList = [...rowList]
          copyRowList[index] = {...copyRowList[index] , codeDoubleCheck: true}
          setRowList( copyRowList )
          setDataModal( {menu: 'confirm' , message: response.data.message} )
          // }
      } else{
        setDataModal( {menu: 'confirm' , message: response.data.message} )
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }

  const concatCheckedID = checkedList.includes(selectedID)

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} timeSet={800}
                                                    state={'warn'} closeEvent={() => {setDataModal( initModal )}}/>
            : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )}
                        clickEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'peripheral' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view}
                                title={dataModal.view ? '주변장치 보기' : '주변장치 등록'}
                                receiveKey={rowList[dataModal.index].peripheralImg}
                                receiveUrl={rowList[dataModal.index].peripheralImgUrl}
                                clickEvent={( v ) => {
                                  Object.assign( rowList[dataModal.index] , {
                                    peripheralImg: v.key ,
                                    peripheralImgUrl: v.url
                                  } )
                                  if(!concatCheckedID){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                  setSelectedID(0)
                                }
                                }
                                cancelEvent={() => setDataModal( initModal )}/>}
      {/*이미지 동적 으로 바꾸자*/}
      {dataModal.menu === 'spec' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        Object.assign( rowList[dataModal.index] , {
                                          specImg: v.key ,
                                          specImgUrl: v.url
                                        } )
                                        if(!concatCheckedID){
                                          setCheckedList(checkedList.concat(selectedID))
                                        }
                                        setSelectedID(0)
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={rowList[dataModal.index].specImg}
                                      receiveUrl={rowList[dataModal.index].specImgUrl}
          />}

      {dataModal.menu === 'ability' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        Object.assign( rowList[dataModal.index] , {
                                          abilityImg: v.key ,
                                          abilityImgUrl: v.url
                                        } )
                                        if(!concatCheckedID){
                                          setCheckedList(checkedList.concat(selectedID))
                                        }
                                        setSelectedID(0)
                                      }
                                      }
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={rowList[dataModal.index].abilityImg}
                                      receiveUrl={rowList[dataModal.index].abilityImgUrl}
          />}

      {dataModal.menu === 'manual' &&
          <SingleListImageUploadModal viewType={dataModal.view}
                                      clickEvent={( v ) => {
                                        Object.assign( rowList[dataModal.index] , {
                                          manualImg: v.key ,
                                          manualImgUrl: v.url
                                        } )
                                        if(!concatCheckedID){
                                          setCheckedList(checkedList.concat(selectedID))
                                        }
                                        setSelectedID(0)
                                      }}
                                      cancelEvent={() => setDataModal( initModal )} authValid={authValid()}
                                      receiveKey={rowList[dataModal.index].manualImg}
                                      receiveUrl={rowList[dataModal.index].manualImgUrl}
          />}

      {dataModal.menu === 'factory' &&
          <FactoryRegisterModal companyCode={selectedCompany.companyCode}
                                DataDetail={rowList[dataModal.index]}
                                cancelEvent={() => {setDataModal( initModal )}}
                                confirmEvent={( v ) => {
                                  Object.assign( rowList[dataModal.index] , v )
                                  if(!concatCheckedID){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                  setSelectedID(0)
                                }
                                }/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 선택되지 않은 리스트는 저장 되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={peripheralsRegisterRes}/>}


      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={() => {setDataModal( initModal )}} excelContent={
            <React.Fragment>
              <ExcelApiButton type={'format'} companyData={selectedCompany} category={'peripheral'}
                              disabled={buttonAuthValid( 'create' , '7' )}/>
              <ExcelApiButton type={'upload'} companyData={selectedCompany} category={'peripheral'}
                              disabled={buttonAuthValid( 'create' , '7' )}/>
            </React.Fragment>
          }/>
      }
      <div>
        <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <MenuTitle title={'주변장치 기본정보'} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'Excel'} width={'80px'}
                         disabled={buttonAuthValid( 'create' , '4' )}
                         clickEvent={() => setDataModal( {menu: 'excel'} )}/>
            <MainButtons name={'삭제'} width={'80px'} buttonType={'delete'}
                         disabled={checkedList.length === 0}
                         clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
            <MainButtons name={'저장'} width={'80px'} buttonType={'cta'} marginDis={true}
                         disabled={buttonAuthValid( 'create' , '5' ) || rowList.length === 0 || checkedList.length === 0}
                         clickEvent={() => {
                           setDataModal( {menu: 'register' , message: '등록을 진행 하시겠습니까?'} )
                         }}/>
          </div>
        </div>
        <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                            scrollWidth={2030} scrollY_Hide={rowList?.length < 10}
                            height={538}
          // height={listHeightValid(authValid,{total: totalPage, page: page})}
                            tempCol={'40px 190px repeat(6, 180px) 144px repeat(4, 144px)'}>
          <div className={'grid-title'}>
            <label className={'checkbox'}>
              <input type="checkbox"
                     onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                       dataList: checkboxValid().list ,
                       checkedList: checkedList ,
                       id: checkboxValid().id ,
                       setState: setCheckedList
                     } )}
                     checked={allCheckedState( {
                                                 dataList: checkboxValid().list ,
                                                 checkedList: checkedList ,
                                                 id: checkboxValid().id
                                               } )}/>
            </label>
            {menuTitle.map( ( v ) => (
              <TableValue title={v} key={v} viewType={rowList.length !== 0}
                          requiredValue={needValue}/> ) )}
          </div>

          {
            rowList.map( ( v , i  ) => (
              <GridTableSelect className={'grid-row-list'} checkboxNumber={i + 1}>
                <label className={'selectCheckbox'}>
                  <ListInnerInput type={'check'} name={'id'}
                                  onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                  checked={checkedList.includes( v.id )}/>
                </label>
                <ListInnerInput type={'drop'} placeholder={'장치종류'} name={'peripheralType'}
                                inputData={dataValid( v.peripheralType , selectType[0] )}
                                dropTopDirection={i >= 16}
                                dropMenuList={selectType} clickButton={()=>setSelectedID(v.id)}
                  // selectDrop={(v)=>Object.assign(rowList[i], {peripheralType : v, codeDoubleCheck: false})}
                                selectDrop={( data ) => {
                                  handleSelectValue( i , data )
                                  if(!concatCheckedID){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                }}

                />
                {/*<ListInnerInput type={'text'} placeholder={'장치 제조번호'} name={'peripheralNumber'} inputData={v.peripheralNumber} onChange={(e)=>onChangeArray(i,e,rowList,setRowList)}/>*/}
                <ListInnerInput type={'withButton'} width={'120px'} name={'peripheralNumber'}
                                placeholder={'-'} inputData={v.peripheralNumber}
                                disabled={v.codeDoubleCheck || !v.peripheralNumber}
                                onChange={( e ) => {
                                  onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id )
                                  onChangeCheck( i , rowList , setRowList )
                                  if(!concatCheckedID){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                }}
                                clickButton={() => {
                                  // checkedList?.includes(item?.id))
                                  if( v.peripheralType === v.peripheralNumber ){
                                    setDataModal( {menu: 'warn' , message: '장치 종류와 장치 제조번호를 \n중복해서 입력할 수 없습니다.'} )
                                  } else if( rowList?.filter( item => [v.peripheralNumber]?.includes( item.peripheralNumber ) )?.length > 1 ){
                                    setDataModal( {menu: 'warn' , message: '추가된 행에 중복된 제조번호가 존재합니다.'} )
                                  } else{
                                    serialDoubleCheck( dataValid( v.peripheralNumber , '' ) , dataValid( v.peripheralType , '' ) , i ).then()
                                  }
                                }}
                                buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'} buttonWidth={'60px'}/>

                <ListInnerInput type={'text'} placeholder={'장치 제조사'} name={'peripheralManufacturer'}
                                inputData={v.peripheralManufacturer}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id, true )}/>
                <ListInnerInput type={'text'} placeholder={'장치 이름'} name={'peripheralName'}
                                inputData={v.peripheralName}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id, true )}/>
                <ListInnerInput type={'calendar'} name={'manufactureDate'} inputData={v.manufactureDate}
                                placeholder={'제조 연월일'}
                                useDeleteButton={true} selectDate={!v.manufactureDate? dayjs().toDate() : dayjs(v.manufactureDate).toDate()}
                                calendarApplyAddEvent={()=>{
                                  if(!concatCheckedID){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                }}
                                calendarOpenAddEvent={()=>setSelectedID(v.id)}
                                onChange={( e ) => {
                                  Object.assign( rowList[i] , {manufactureDate: dayjs( e ).format( 'YYYY.MM.DD' )} )}}/>
                <ListInnerInput type={'text'} placeholder={'담당자'} name={'managerName'} inputData={v.managerName}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id, true )}/>
                <ListInnerInput type={'search'} placeholder={'공장명'} name={'factoryName'} tabIndex={- 1}
                                inputData={v.factoryName}
                                clickButton={() => {
                                  setSelectedID(v.id)
                                  setDataModal( {menu: 'factory' , index: i} )
                                }}/>
                <ListInnerInput type={'text'} placeholder={'공장을 선택해 주세요'} disabled={true}
                                name={'factorySubDivision'} inputData={v.factorySubDivision}
                                onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id )}/>
                {/*장치 사진*/}
                <div>
                  <MainButtons name={!v.peripheralImg[0] ? '장치사진 등록' : '장치사진 변경'}
                               buttonType={!v.peripheralImg[0] ? 'cta' : 'popupCta'} margin={'0'}
                               width={'120px'}
                               clickEvent={() => {
                                 setSelectedID(v.id)
                                 setDataModal( {menu: 'peripheral' , index: i , view: false} )}}/>
                </div>

                {/*스펙 명판 사진*/}
                <div>
                  <MainButtons name={`스펙 이미지 ${!v.specImg ? '등록' : '변경'}`} width={'120px'}
                               buttonType={!v.specImg ? 'cta' : 'popupCta'} margin={'0'}
                               clickEvent={() => {
                                 setSelectedID(v.id)
                                 setDataModal( {menu: 'spec' , index: i , view: false} )}}/>
                </div>

                {/*능력 명판사진*/}
                <div>
                  <MainButtons name={`명판 이미지 ${!v.abilityImg ? '등록' : '변경'}`} width={'120px'}
                               buttonType={!v.abilityImg ? 'cta' : 'popupCta'} margin={'0'}
                               clickEvent={() => {
                                 setSelectedID(v.id)
                                 setDataModal( {menu: 'ability' , index: i , view: false} )}}/>
                </div>

                {/*설명서 사진 */}
                <div>
                  <MainButtons name={`설명서 이미지 ${!v.manualImg ? '등록' : '변경'}`} width={'120px'}
                               buttonType={!v.manualImg ? 'cta' : 'popupCta'} margin={'0'}
                               clickEvent={() => {
                                 setSelectedID(v.id)
                                 setDataModal( {menu: 'manual' , index: i , view: false} )}
                  }/>
                </div>
              </GridTableSelect>
            ) )}

        </GridTableComponent>

      </div>
      <div style={{display: 'flex' , justifyContent: 'flex-end' , marginTop: '10px'}}>
        <HorizontalMoveScrollButton
          leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
          rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
      </div>

    </React.Fragment>
  );
};
export default PeripheralsMultipleReg;
