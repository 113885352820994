import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import UserBarcodeManagement from "../../../containers/user/fas/barcode/UserBarcodeManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import CustomerLayoutCont from "../../../containers/admin/fas/company/print/CustomerLayoutCont";

const FasLayout = () => {

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      <SectionContainer contentContainer={<CustomerLayoutCont/>}/>
    </PageContainer>
  );
};

export default FasLayout;