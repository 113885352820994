import React , {useEffect , useRef , useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid , buttonAuthValid , dataAccessLoading ,
  dataValid , filterCheckedRow , handleDecimalChange , handleRowDelete , hasDuplicate , initModal , loadingRemove ,
  numberLocaleString ,
  onChangeNumberArray , onMultiRegChangeArray ,
  rowListDropDirection , singleCheckedFunction
} from "../../../../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import AccountSelectModal from "../../../../../components/modal/AccountSelectModal";
import MoldRegisterModal from "../../../../../components/modal/MoldRegisterModal";
import FacilityProductAutoCheckModal from "../../../../../components/modal/FacilityProductAutoCheckModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ListImageUploadModal from "../../../../../components/modal/ListImageUploadModal";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const ProductRegistrationMultiple = () => {

  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  const [selectCode, setSelectCode] = useState<string>('')
  const productTitle = ['거래처' , '모델' , 'CODE(품번)' , '품명' , '품목 종류' , '단위' ,'품목 중량', 'BOM' , '생산공정' , '캐비티', '세로(Feeder)', '기계' , '기준 UPH' , '금형' , '작업 표준서' , '초·중·종 검사' , '품목 이미지']
  const needValue = ['거래처' , '모델' , 'CODE(품번)' , '품명' , '품목 종류' , '단위' , '생산공정'];
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [processList , setProcessList] = useState<any[]>([])
  const [processPage , setProcessPage] = useState<number>(1)
  const [processTotal , setProcessTotal] = useState<number>(1)
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )
  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )

  const initRegistArray = Array(20).fill('').map((_,i)=>(
    {
      id: i+1,
      companyCode: selectedCompany.companyCode ,
      customer: [] ,
      notice: '' ,
      processCode: '' ,
      processName: '' ,
      productName: '' ,
      serialCode: '' ,
      facilityCode: [] ,
      moldCode: [] ,
      type: '' ,
      uph: '0' ,
      unit: 'ea' ,
      image: [] ,
      workStandardImg: [] ,
      codeDoubleCheck: false ,
      cavity: '1',
      weight:'0', weightUnit:'g', length:'0'
    }
  ))

  const [rowList , setRowList] = useState<any[]>(initRegistArray)
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [selectedID, setSelectedID] = useState<number>(0)
  const scrollRef = useRef<any>( null )

  const customerCount = (customer:any) => customer.filter((v:any,i:number,arr:any)=> arr.findIndex((el:any)=>(el.customerCode === v.customerCode)) === i)
  const productRegisterRes = async () => {
    if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.customer[0]?.customerCode ) ) ){
      setDataModal( {menu: 'warn' , message: '거래처와 모델을 선택해 주세요.'} )
    }
      // else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.customer[0]?.customerModelCode))){
      // 	setDataModal({menu:'confirm', message:'거래처 모델을 선택해 주세요.'})
    // }
    else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.serialCode ) ) ){
      setDataModal( {menu: 'warn' , message: '품목 코드를 입력해 주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'warn' , message: '품목 코드 중복 확인을 해주세요.'} )
    }
    else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.productName ) ) ){
      setDataModal( {menu: 'warn' , message: '품목명을 입력해 주세요.'} )
    } else if( hasDuplicate(rowList.filter(item => checkedList.includes(item.id)),'productName')){
      setDataModal( {menu: 'warn' , message: '중복 되는 품명이 입력 되었습니다.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.type ) ) ){
      setDataModal( {menu: 'warn' , message: '폼목 종류를 선택해 주세요.'} )
    }
      // else if((filterCheckedRow(rowList, checkedList,'id').find((v)=>!v.unit))){
      // 	setDataModal({menu:'confirm', message:'단위를 선택해 주세요.'})
    // }
    else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.processCode ) ) ){
      setDataModal( {menu: 'warn' , message: '생산 공정을 선택해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/product/register' ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) ,
                                            {headers: {Authorization}} )
        const data = response.data
        if( response.data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: ( data.message ) , status: data.status} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: ( '품목이 등록 되었습니다.' )} )
            setRowList( initRegistArray )
            setCheckedList([])
            loadingRemove()
          } , 500 )
          setDataModal( initModal )
        }
      } catch ( e ) {
        console.log( e )
        //@ts-ignore
        setDataModal( {menu: 'error' , message: ( '등록할 수 없습니다. 관리자에게 문의해 주세요.' )} )
        loadingRemove()
      }
    }
  }

  const processListRes = async () => {
    try {
      const response = await client.get(`/process/type/list/${processPage}/10/${selectedCompany.companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      setProcessTotal(data.total_page)
      if(data.total_page > 1 && processPage > 1){
        setProcessList((prev: any) => prev.concat(data.row))
      } else{
        setProcessList(data.row)
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = rowList.filter( ( v ) => ( v.serialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/product/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        serialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        serialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }

      if( duplicateCount[0] === 0 ){
        if( regCount !== 0 ){
          setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
          setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
        } else{
          setRowList( copyRowList )
          setDataModal( {menu: 'success' , message: '중복되지 않은 시리얼CODE 입니다.'} )
        }
      } else{
        if( duplicateCount.length === 1 ){
          setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
        } else{
          if( regCount < duplicateCount.length ){
            setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
          } else{
            setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
          }
        }
        setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'} )
      }

    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }

  useEffect(() => {
    processListRes().then();
  }, []);

// 초기값 객체
  const initialObject = {
    companyCode: selectedCompany.companyCode,
    customer: [],
    notice: '',
    processCode: '',
    processName: '',
    productName: '',
    serialCode: '',
    facilityCode: [],
    moldCode: [],
    type: '',
    uph: '0',
    unit: 'ea',
    image: [],
    workStandardImg: [],
    codeDoubleCheck: false,
    cavity: '1',
    weight:'0', weightUnit:'g', length:'0'
  };

  // const removeValueData = () => {
  //   const deleteData = rowList.map(v => {
  //     if(checkedList.includes(v.id)){
  //       return {
  //         ...initialObject, id: v.id
  //       }
  //     }
  //     return v
  //   })
  //   setRowList(deleteData)
  // }

  const concatCheckedID = checkedList.includes(selectedID)
  console.log(rowList)
  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={dataModal.message} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={dataModal.message} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={dataModal.message} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'*선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={productRegisterRes}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             const copyRowList = [...rowList]
                               copyRowList[checkIndex] = {
                                 ...copyRowList[checkIndex] ,
                                 codeDoubleCheck: true ,
                                 serialCode: inputSerialCode
                               }
                               setRowList( copyRowList )
                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}


      {dataModal.menu === 'image' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view}
                                title={dataModal.view ? '품목 이미지 보기' : '품목 이미지 등록'}
                                receiveKey={rowList[dataModal.index].image}
                                receiveUrl={rowList[dataModal.index].imageUrl}
                                clickEvent={( v ) => {
                                  Object.assign( rowList[dataModal.index] , {image: v.key , imageUrl: v.url} );
                                  if(!checkedList.includes(selectedID)){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                }}
                                cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'workStandard' &&
          <ListImageUploadModal authValid={authValid()} viewType={dataModal.view} workImgLength={true}
                                title={dataModal.view ? '작업표준서 보기' : '작업표준서 등록'}
                                receiveKey={rowList[dataModal.index].workStandardImg}
                                receiveUrl={rowList[dataModal.index].workStandardImgUrl}
                                clickEvent={( v ) => {
                                  {
                                    Object.assign( rowList[dataModal.index] , {
                                      workStandardImg: v.key ,
                                      workStandardImgUrl: v.url
                                    } )
                                  }
                                  if(!checkedList.includes(selectedID)){
                                    setCheckedList(checkedList.concat(selectedID))
                                  }
                                }}
                                cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'preview' &&
          <DetailImageView image={rowList[dataModal.index].imageUrl[0]}
                           closeMenu={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'process' &&
        ( buttonAuthValid( 'create' , '13' ) ?
            <ConfirmPopup statusText={'등록된 생산 공정이 없습니다.'}
                          noticeText={'* 공정 선택 없이 품목을 등록 할 수 없습니다.'}
                          clickEvent={() => {
                            setDataModal( initModal )
                          }
                          }/>
            :
            <SelectConfirmModal statusText={dataModal.message} noticeText={'* 공정 선택 없이 품목을 등록 할 수 없습니다.'}
                                clickEvent={() => window.location.href = authValid() ? '/admin/fas/type_process' : '/fas/type_process'}
                                cancelEvent={() => {
                                  setDataModal( initModal )
                                }
                                }/>
        )
      }


      {dataModal.menu === 'customer' &&
          <AccountSelectModal initCustomerData={
              rowList.find( ( v , i ) => i === dataModal.index ).customer.map( ( el: any ) => ( {
                customerName: el.customerName ,
                customerCode: el.customerCode
              } ) )}
                              initModelData={
                                  rowList.find( ( v , i ) => i === dataModal.index ).customer.map( ( el: any ) => ( {
                                    customerName: el.customerName ,
                                    customerCode: el.customerCode ,
                                    customerModelName: el.customerModelName ,
                                    customerModelCode: el.customerModelCode
                                  } ) )}
                              receivedData={( e ) => {
                                  setRowList( rowList.map( ( v: any , i: number ) => {
                                    return i === dataModal.index ? {
                                      ...v ,
                                      customer: e
                                    } : v
                                  } ) )
                                if(!checkedList.includes(selectedID)){
                                  setCheckedList(checkedList.concat(selectedID))
                                }
                                }
                              } closeModal={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'mold' &&
          <MoldRegisterModal
              selectProduct={rowList[dataModal.index]}
              companyCode={selectedCompany.companyCode} modified={dataModal.index !== -1}
              receivedMold={( e ) => {
                  setRowList( rowList.map( ( v: any , i: number ) => {
                    return i === dataModal.index ? {
                      ...v ,
                      moldCode: e
                    } : v
                  } ) )
                if(!checkedList.includes(selectedID)){
                  setCheckedList(checkedList.concat(selectedID))
                }
                }
              }
              viewType={dataModal.view}
              cancelEvent={() => setDataModal( initModal )}
              selectedMold={rowList[dataModal.index]?.moldCode}/>}

      {dataModal.menu === 'facility' &&
          <FacilityProductAutoCheckModal companyCode={selectedCompany.companyCode} viewType={dataModal.view}
                                         receivedFacility={( e ) => {
                                             setRowList( rowList.map( ( v: any , i: number ) => {
                                               return i === dataModal.index ? {
                                                 ...v ,
                                                 facilityCode: e.facilityCode ,
                                                 autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                                 autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                                 loadTonRange: e.loadTonRange ,
                                                 slideCountRange: e.slideCountRange ,
                                                 countType: e.countType ,
                                                 facilityStatus: e.facilityStatus
                                               } : v
                                             } ) )
                                           if(!checkedList.includes(selectedID)){
                                             setCheckedList(checkedList.concat(selectedID))
                                           }
                                         }}
                                         selectProduct={rowList[dataModal.index]}
                                         cancelEvent={() => setDataModal( initModal )}
                                         selectedFacility={rowList[dataModal.index]?.facilityCode}/>
      }

    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
        <MenuTitle title={'품목 기본정보'} unitCase={'MenuCompTitle'}/>
        <div style={{display:'flex'}}>
          <MainButtons name={'삭제'} width={'80px'}
                       disabled={checkedList.length === 0}
                       clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
          <MainButtons name={'저장'} buttonType={'cta'} width={'80px'} marginDis={true}
                       disabled={checkedList.length === 0}
                       clickEvent={()=>{setDataModal({menu:'register',message:'품목을 등록 하시겠습니까?'})}}/>
        </div>
      </div>
      <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                          scrollY_Hide={rowList?.length < 10}
                          scrollWidth={2534}
                          height={538}
                          tempCol={'40px repeat(2, 180px) 220px 180px repeat(2, 128px) 202px 108px 172px 128px 168px 108px 140px 108px 128px repeat(2, 108px)'}>
        <div className={'grid-title'}>
          <label className={'checkbox'}>
            <input type="checkbox"
                   onChange={(e)=>allPersistCheckedFunction(e.target.checked, {dataList: rowList, checkedList: checkedList, id: 'id', setState: setCheckedList})}
                   checked={allCheckedState({dataList: rowList, checkedList: checkedList, id: 'id'})}/>
          </label>
          {productTitle.map( ( v ) => (
            <TableValue title={v} key={v} viewType={true}
                        requiredValue={needValue}/> ) )}
        </div>

        {
          rowList.map( ( v , i , arr ) => (
            <GridTableSelect className={'grid-row-list'} checkboxNumber={i + 1}>
              <label className={'selectCheckbox'}>
                <ListInnerInput type={'check'} name={'id'}
                                onChange={(e)=>singleCheckedFunction(e.target.checked, v.id, setCheckedList, checkedList)}
                                checked={checkedList.includes(v.id)}/>
              </label>
              <ListInnerInput type={'accountSearch'} name={'customerName'}
                              hoverText={customerCount( v.customer ).map( ( v: any ) => ( v.customerName ) ).join( ',' )}
                              inputData={dataValid( customerCount( v.customer )[0]?.customerName ) + ( dataValid( ( customerCount( v.customer ).length - 1 ) , 0 ) > 0 ? ` 외 ${customerCount( v.customer ).length - 1}` : '' )}
                              placeholder={'거래처'}
                              onChange={( e: any ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id )}
                              clickButton={() => {
                                setSelectedID(v.id)
                                setDataModal( {menu: 'customer' , index: i} )}}/>
              <ListInnerInput type={'accountSearch'} name={'customerModelName'}
                              hoverText={v.customer.map( ( v: any ) => ( v.customerModelName ) ).join( ',' )}
                              inputData={dataValid( v.customer[0]?.customerModelName ) + ( dataValid( ( v.customer.length - 1 ) , 0 ) > 0 ? ` 외 ${v.customer.length - 1}` : '' )}
                              placeholder={'모델'}
                              onChange={( e: any ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id )}
                              clickButton={() => {
                                setSelectedID(v.id)
                                setDataModal( {menu: 'customer' , index: i} )
                              }}/>

              <ListInnerInput type={'withButton'} width={'120px'}
                              name={'serialCode'} placeholder={'-'}
                              inputData={v.serialCode} inputDisabled={v.codeDoubleCheck}
                              onChange={( e ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id, true )}
                              clickButton={() => {serialDoubleCheck( v.serialCode , i ).then()}}
                              buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                              buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.serialCode}/>


              <ListInnerInput type={'text'} name={'productName'} inputData={v.productName} placeholder={'품목명'}
                              onChange={( e: any ) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id )}/>
              <ListInnerInput type={'drop'} name={'type'} inputData={v.type}
                              selectDrop={( el ) => {
                                setRowList( rowList.map( ( v ) => {return v.id === selectCode ? {...v , ...rowList[i]} : v} ) )
                                Object.assign( rowList[i] , {type: el} )
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}
                              dropTopDirection={i > (rowList.length - 4)}
                              dropMenuList={['완제품' , '반제품' , '재공품']}/>
              <ListInnerInput type={'drop'} name={'unit'} inputData={v.unit}
                              dropTopDirection={i > (rowList.length - 4)}
                              selectDrop={( el ) => {
                                setRowList( rowList.map( ( v ) => {return v.id === selectCode ? {...v , ...rowList[i]} : v} ) )
                                Object.assign( rowList[i] , {unit: el , uph: ''} )
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}
                              dropMenuList={['ea' , 'g' , 'kg' , 'Ton']}/>
              <span style={{display:'grid', gridTemplateColumns:'1fr 1fr' }}>
                        <ListInnerInput type={'unit'} name={'weight'} placeholder={'-'} width={'110'}
                                        unit={dataValid( v.weightUnit , 'g' )}
                                        inputData={v.weight.includes( '.' ) ? v.weight : numberLocaleString( v.weight )} limit={15}
                                        onChange={( e ) => {
                                          handleDecimalChange( i , e , rowList , setRowList , dataValid( v.weightUnit , 'g' ) )
                                          if(!checkedList.includes(v.id)){
                                            setCheckedList(checkedList.concat(v.id))
                                          }
                                        }}/>

                          <ListInnerInput type={'drop'} inputData={dataValid( v.weightUnit , 'g' )} width={'60'}
                                          dropTopDirection={rowListDropDirection( arr , i )}
                                          selectDrop={( el ) => {
                                            setSelectedID( v.id )
                                            // setRowList( rowList.map( ( v ) => {return v.id === selectedID ? {...v , ...rowList[i]} : v} ) )
                                            Object.assign( rowList[i] , {weightUnit: el} )
                                            if(!checkedList.includes(v.id)){
                                              setCheckedList(checkedList.concat(v.id))
                                            }
                                          }}
                                          dropMenuList={['ea' , 'g' , 'kg' , 'Ton']}/>
                        </span>
              <div>
                <MainButtons name={'BOM 등록'} buttonType={'cta'} disabled={true} margin={'0'}
                             clickEvent={() => ''}/>
              </div>

              <ListInnerInput type={'objectDrop'} name={'processName'}
                              inputData={v.processName} dropKey={'processName'}
                              dropScrollHas={processTotal > processPage}
                              dropScrollEvent={() => setProcessPage( ( prev ) => prev + 1 )}
                              dropMenuList={processList}
                              dropTopDirection={i >= 16}
                              selectDrop={( el ) => {
                                Object.assign( rowList[i] ,
                                               {processCode: el?.processCode , processName: el?.processName} )
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}/>
              <ListInnerInput type={'unit'} unit={'ea'} name={'cavity'}
                              inputData={numberLocaleString( v.cavity )}
                              limit={6}
                              onChange={( e: any ) => {
                                onChangeNumberArray( i , e , rowList , setRowList )
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}/>
              <ListInnerInput type={'unit'} unit={'mm'} name={'length'}
                              placeholder={'-'}
                              inputData={dataValid( v.length?.includes( '.' ) ? v.length : numberLocaleString( v.length ) , 0 )}
                              limit={15}
                              onChange={( e ) => {
                                handleDecimalChange( i , e , rowList , setRowList , 'mm')
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}/>
              <div>
                <MainButtons name={( !v.facilityCode || !v.facilityCode[0] ) ? '기계 선택' : '기계 선택됨'} margin={'0'}
                             buttonType={( !v.facilityCode || !v.facilityCode[0] ) ? 'cta' : 'popupCta'}
                             clickEvent={() => {
                               setSelectedID(v.id)
                               setDataModal( {menu: 'facility' , index: i , view: false} )}}/>
              </div>

              <ListInnerInput type={'unit'} name={'uph'} placeholder={'-'}
                              unit={dataValid( v.unit , 'ea' )}
                              inputData={v.uph.includes( '.' ) ? v.uph : numberLocaleString( v.uph )} limit={15}
                              onChange={( e ) => {
                                handleDecimalChange( i , e , rowList , setRowList , dataValid( v.unit , 'ea' ) )
                                if(!checkedList.includes(v.id)){
                                  setCheckedList(checkedList.concat(v.id))
                                }
                              }}/>

              <div>
                <MainButtons name={( !v.moldCode || !v.moldCode[0] ) ? '금형 선택' : '금형 선택됨'} margin={'0'}
                             buttonType={( !v.moldCode || !v.moldCode[0] ) ? 'cta' : 'popupCta'}
                             clickEvent={() => {
                               setSelectedID(v.id)
                               setDataModal( {menu: 'mold' , index: i , view: false} )}}/>
              </div>

              <div>
                <MainButtons name={!v.workStandardImg[0] ? '작업표준서 등록' : '작업표준서 변경'} margin={'0'}
                             buttonType={!v.workStandardImg[0] ? 'cta' : 'popupCta'}
                             width={'114px'}
                             clickEvent={() => {
                               setSelectedID(v.id)
                               setDataModal( {menu: 'workStandard' , index: i , view: false} )}}/>
              </div>

              <div>
                <MainButtons name={'검사항목 등록'} buttonType={'cta'} margin={'0'} disabled={true}/>
              </div>

              <div>
                <MainButtons name={!v.image[0] ? '이미지 등록' : '이미지 변경'} margin={'0'}
                             buttonType={!v.image[0] ? 'cta' : 'popupCta'}
                             clickEvent={() => {
                               setSelectedID(v.id)
                               setDataModal( {menu: 'image' , index: i , view: false} )}}/>
              </div>
            </GridTableSelect>
          ) )}

      </GridTableComponent>
      <div style={{display:'flex', justifyContent:'flex-end', marginTop:'10px'}}>
        <HorizontalMoveScrollButton
          leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
          rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
      </div>

    </div>
    </React.Fragment>

  );
};
export default ProductRegistrationMultiple;
