import React , {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  decimalNumberValid ,
  filterCheckedRow ,
  handleDecimalChange , handleRowDelete ,
  initModal ,
  loadingRemove ,
  numberLocaleString ,
  onChangeNumberArray , onMultiRegChangeArray ,
  singleCheckedFunction
} from "../../../../../function/function";
import {
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";
import styled from "styled-components";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const MoldMultipleReg = () => {
  const {t}: any = useTranslation();

  const moldTitle = ['금형 CODE' , '금형명' , '캐비티' , 'SPM' , '슬라이드 위치' , '점검타수' , '최대타수' , '점검주기' , '현재타수'];
  const needValue = ['금형 CODE' , '금형명' , '캐비티']

  const selectedCompany= useRecoilValue<any>(authValid() ? persistCompanyData : userPersistCompanyData)
  const [dataModal , setDataModal] = useState<Modal_View_Type>(initModal)
  const [inputSerialCode , setInputSerialCode] = useState<string>('')
  const [checkIndex , setCheckIndex] = useState<number>(- 1)
  const scrollRef = React.useRef<any>(null)

  const initialObject = {
    companyCode: selectedCompany.companyCode , currentStrokes: '' ,
    moldSerialCode: '' , moldName: '' , cavity: '' , spm: '' , maxStrokes: '' ,
    slideCounter: '' , checkCycle: '' , checkStrokes: '' , productCode:''  , codeDoubleCheck: false
  }

  const generateInitialRowList = Array.from( {length: 20} , ( _ , i ) => ( {
    ...initialObject ,
    id: i + 1
  } ) )

  const [rowList , setRowList] = useState<any[]>( generateInitialRowList )
  const [checkedList , setCheckedList] = useState<any>( [] )

  const moldRegisterRes = async () => {
    if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.moldSerialCode)){
      setDataModal({menu: 'confirm' , message: '금형 CODE를 입력해 주세요.'})
    }
    else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.codeDoubleCheck)){
      setDataModal({menu: 'confirm' , message: '금형 코드 중복확인을 해주세요.'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.moldName)){
      setDataModal({menu: 'confirm' , message: '금형 품명을 입력해 주세요.'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.cavity)){
      setDataModal({menu: 'confirm' , message: '캐비티를 입력해 주세요._1'})
    } else if(filterCheckedRow(rowList , checkedList , 'id').find((v) => v.cavity === '0')){
      setDataModal({menu: 'confirm' , message: '캐비티를 입력해 주세요.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post(`/mold/register` ,
                                           filterCheckedRow(rowList , checkedList , 'id')
          , {headers: {Authorization}})
        const data = response.data;
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setRowList(generateInitialRowList)
            setCheckedList([])
            setDataModal({menu: 'success' , message: '등록 되었습니다.'})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  const serialDoubleCheck = async (serialCode: string , index: number) => {
    setCheckIndex(index)
    const regCount = rowList.filter((v) => ( v.moldSerialCode.includes(serialCode) && v.codeDoubleCheck )).length
    try {
      const response = await client.post('/mold/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        moldSerialCode: serialCode
      } , {headers: {Authorization}})
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        moldSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }
        if(duplicateCount[0] === 0){
          if(regCount !== 0){
            setInputSerialCode(regCount === 0 ? serialCode : serialCode + `(${regCount})`)
            setDataModal({menu: 'doubleCheck' , message: '중복 입력된 금형CODE 입니다. \n 중복사용 하시겠습니까?'})
          } else{
            setRowList(copyRowList)
            setDataModal({menu: 'confirm' , message: '중복되지 않은 금형CODE 입니다.'})
          }
        } else{
          if(duplicateCount.length === 1){
            setInputSerialCode(serialCode + `(${duplicateCount[0] + regCount})`)
          } else{
            if(regCount < duplicateCount.length){
              setInputSerialCode(serialCode + `(${duplicateCount[regCount]})`)
            } else{
              setInputSerialCode(serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})`)
            }
          }
          setDataModal({menu: 'doubleCheck' , message: '이미 사용중인 금형CODE 입니다. \n 중복사용 하시겠습니까?'})
        }

    }
    catch (e) {
      console.log(e)
      setDataModal({menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
    }
  }

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={() => {
        setDataModal(initModal)
      }}/> : dataModal.menu === 'error' ?
        <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={() => setDataModal(initModal)}/>
        : dataModal.menu === 'warn' ?
          <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={() => setDataModal(initModal)}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={() => {
            setDataModal(initModal)
          }}/>}


      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                               const copyRowList = [...rowList]
                               copyRowList[checkIndex] = {...copyRowList[checkIndex] , codeDoubleCheck: true , moldSerialCode: inputSerialCode}
                               setRowList(copyRowList)

                             setDataModal(initModal)
                           }}
                           cancelEvent={() => setDataModal(initModal)}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal(initModal)}
                              clickEvent={moldRegisterRes}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'mold'} type={'format'} companyData={selectedCompany}
                              disabled={buttonAuthValid('create' , '14')}/>
              <ExcelApiButton category={'mold'} type={'upload'} companyData={selectedCompany}
                              disabled={buttonAuthValid('create' , '14')}/>
            </React.Fragment>
          }/>
      }
      <div>
        <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
          <MenuTitle title={'금형 기본정보'} unitCase={'MenuCompTitle'}/>
          <div style={{display: 'flex'}}>
            <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
            <MainButtons name={'삭제'} width={'80px'}
                         disabled={
                           buttonAuthValid('rowDelete' , '14') ?
                             rowList.length === 0
                             :
                             buttonAuthValid('delete' , '14') || ( checkedList.length === 0)}
                         clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
            <MainButtons name={'저장'} width={'80px'} buttonType={'cta'} marginDis={true}
                         disabled={buttonAuthValid('create' , '14') || ( rowList.length === 0 || checkedList.length === 0 )}
                         clickEvent={() => {
                           if(checkedList.length === rowList.length){
                             moldRegisterRes().then()
                           } else{
                             setDataModal({menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'})
                           }
                         }}/>
          </div>
        </div>

        <GridTableComponent id={'grid-scroll'} scrollWidth={1464}
                            height={538}
                            ref={scrollRef} scrollY_Hide={rowList?.length < 10}
                            tempCol={'40px 180px 144px repeat(5, 160px) 140px 160px'}>
          <div className={'grid-title'}>
            <label className={'checkbox'}>
              <input type="checkbox"
                     onChange={(e) => allPersistCheckedFunction(e.target.checked , {dataList: rowList, checkedList: checkedList , id: 'id' , setState: setCheckedList})}
                     checked={allCheckedState({dataList: rowList , checkedList: checkedList , id: 'id'})}/>
            </label>
            {moldTitle.map((v) => (
              <TableValue title={v} key={v} viewType={rowList.length !== 0}
                          requiredValue={needValue}/> ))}
          </div>

          {rowList.map((v , i) => (
              <GridTableSelect className={'grid-row-list'} checkboxNumber={i + 1}>
                <label className={'selectCheckbox'}>
                  <ListInnerInput type={'check'} name={'id'}
                                  onChange={(e: any) => singleCheckedFunction(e.target.checked , v.id , setCheckedList , checkedList)}
                                  checked={checkedList.includes(v.id)}/>
                </label>
                <ListInnerInput type={'withButton'}
                                name={'moldSerialCode'} placeholder={'-'}
                                inputData={v.moldSerialCode} inputDisabled={v.codeDoubleCheck}
                                onChange={(e) => onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id,true )}
                                clickButton={() => {serialDoubleCheck(v.moldSerialCode , i).then()}}
                                buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                                buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.moldSerialCode}/>

                <ListInnerInput type={'text'} placeholder={'금형 품명'} inputData={v.moldName} name={'moldName'} limit={100}
                                onChange={(e) => { onMultiRegChangeArray( i , e , rowList , setRowList , checkedList, setCheckedList, v.id,true )}}/>

                <ListInnerInput type={'unit'} unit={'ea'} name={'cavity'} placeholder={'0'}
                                inputData={numberLocaleString(v.cavity)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , 'ea')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
                <ListInnerInput type={'unit'} unit={'타'} name={'spm'} placeholder={'0'}
                                inputData={numberLocaleString(v.spm)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , 'ea')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }
                }/>
                <ListInnerInput type={'unit'} unit={'mm'} name={'slideCounter'} placeholder={'0'}
                                inputData={decimalNumberValid(v.slideCounter)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , '')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
                <ListInnerInput type={'unit'} unit={'타'} name={'checkStrokes'} placeholder={'0'}
                                inputData={numberLocaleString(v.checkStrokes)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , 'ea')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
                <ListInnerInput type={'unit'} unit={'타'} name={'maxStrokes'} placeholder={'0'}
                                inputData={numberLocaleString(v.maxStrokes)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , 'ea')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
                <ListInnerInput type={'unit'} unit={'일'} placeholder={'-'} limit={6}
                                inputData={numberLocaleString(v.checkCycle)} name={'checkCycle'}
                                onChange={(e) => {
                                  onChangeNumberArray(i , e , rowList , setRowList)
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
                <ListInnerInput type={'unit'} unit={'타'} name={'currentStrokes'} placeholder={'0'}
                                inputData={numberLocaleString(v.currentStrokes)} limit={15}
                                onChange={(e) => {
                                  handleDecimalChange(i , e , rowList , setRowList , 'ea')
                                  if(!checkedList.includes(v.id)){
                                    setCheckedList(checkedList.concat(v.id))
                                  }
                                }}/>
              </GridTableSelect>
            ))}
        </GridTableComponent>
      </div>
      <div style={{display:'flex',alignSelf:'flex-end'}}>
      <HorizontalMoveScrollButton
        leftClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
        rightClick={()=>scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
      </div>

    </React.Fragment>
  );
};
export default MoldMultipleReg;
