import React , {useEffect , useState} from 'react';
import MenuTitle from "../../../../components/title/MenuTitle";
import {
  ContentFrame ,
  multipleImageGridStyle , NoData ,
  SectionFrame ,
  validationValue
} from "../../../../style/StyleComponent";
import styled from "styled-components";
import MainButtons from '../../../../components/button/MainButtons';
import {Modal_View_Type} from "../../../../types/Fundamental";
import {buttonAuthValid , dataValid , initModal} from "../../../../function/function";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import PreviewImage from "../../../../components/display/PreviewImage";
import BusinessVisitHistoryModal from "../../../../components/modal/BusinessVisitHistoryModal";
import dayjs from "dayjs";
import ImageDetailPreview from "../../../../components/modal/ImageDetailPreview";
import DetailImageView from "../../../../components/modal/DetailImageView";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {DOWNLOAD_ADDRESS} from "../../../../api/endPoint";
import RegisterComponent from "../../../../components/input/RegisterComponent";

interface Interface {
  detailData?:any
}
const BusinessDetail = ({}:Interface) => {
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const [businessData,setBusinessData] = React.useState<any>({fileNameList:[]});
  const {t}:any = useTranslation()
  const locationID = useParams()

  const detailBusinessRes = async () => {
    try {
      const response = await client.post('/business/management/detail',{
        businessManagementCode: locationID.id
      },{
                                           headers: {Authorization}})
      setBusinessData(response.data.data)
    }catch (e) {
      console.log(e)
    }
  }
  useEffect(()=>{
    detailBusinessRes().then()
  },[])

  return (
    <SectionFrame>
      {dataModal.menu === 'visitHistory' &&
          <BusinessVisitHistoryModal closeEvent={()=>{setDataModal(initModal)}} companyData={businessData}/>
      }
      {dataModal.menu === 'preview' &&
          <ImageDetailPreview profile={businessData?.imagesUrlList[dataModal.index]}
                              imageUrl={dataValid(businessData?.imagesUrlList,[])}
                              clickEvent={() => {setDataModal(initModal)}}/>}

      {/*@ts-ignore*/}
      {dataModal.menu === 'attach' && <DetailImageView image={dataModal.message}
                                                       closeMenu={()=>{setDataModal(initModal)}}
                                                       downloadEvent={()=>{
                                                         const parts = dataModal.message?.split('/');
                                                         // @ts-ignore
                                                         window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts.length - 1]}`
                                                         }}
                                                       imgDownLoadType={true}/>
      }


      <MenuTitle title={t('영업관리 > 조회')} unitCase={'MenuTitle'}/>
      <ContentFrame flexDirection={'row'} justify={'flex-start'}>
        <ViewBox>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
            <MenuTitle title={t('영업관리 조회')} unitCase={'MenuCompTitle'}/>
            <div style={{display: 'flex',alignItems:'center'}}>
              <div className={'click_text'} onClick={()=>{setDataModal({menu:'visitHistory'})}}>{'방문이력 보기'}</div>
              <MainButtons name={'목록'} width={'56px'} clickEvent={()=>window.location.href =`/admin/fas/business`}/>
              <MainButtons name={'수정'} disabled={buttonAuthValid('update','22')} clickEvent={()=>window.location.href =`/admin/fas/business/modify/${locationID.id}`} buttonType={'cta'}
                           width={'56px'} marginDis={true}/>
            </div>
          </div>
          <div style={{borderTop:'1px solid #E6E9F4'}}>
            <div className={'info'} style={{display: 'grid', gridTemplateColumns: '168px 224px 168px 224px'}}>
              <div className={'reg-title'}>{t('업체명')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(businessData?.companyName)}
              </div>
              <div className={'reg-title'}>{t('작성자')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(businessData?.writer)}
              </div>
              <div className={'reg-title'}>{t('담당자')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(businessData?.managerName)}
              </div>
              <div className={'reg-title'}>{t('방문날짜')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(dayjs(businessData?.visitDate).format('YYYY.MM.DD'))}
              </div>
              <div className={'reg-title'}>{t('등록날짜')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {validationValue(dayjs(businessData?.registrationDate).format('YYYY.MM.DD'))}
              </div>
              <div className={'reg-title'}>{t('마지막 수정날짜')}</div>
              <div className={'reg-content'} style={{display: 'flex'}}>
                {businessData?.modifiedDate ?
                  validationValue(dayjs(businessData?.modifiedDate).format('YYYY.MM.DD'))
                  :
                  '-'
                }
              </div>
            </div>
          </div>

          <div className={'reg'} style={{display: 'grid', gridTemplateColumns: '168px 616px '}}>
            <div className={'reg-title'}>{t('추가 담당자')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {dataValid(businessData?.responsibility?.map((value:any,index:number)=>(value.userName))?.join(','))}
            </div>
            <div className={'reg-title'}>{t('제목')}</div>
            <div className={'reg-content'} style={{display: 'flex'}}>
              {validationValue(businessData?.title)}
            </div>
            <div className={'reg-title'}>
              {'첨부파일'}
              {/*{helperModule('','16px','16px','24px','100px')}*/}
            </div>
            <div className={'reg-content'} style={{display: 'flex',overflow:'hidden',padding:'8px 16px'}}>
              <div>
                {businessData?.fileNameList.length !== 0 ?
                  businessData?.fileNameList?.map((v:any,index:number)=>(
                  <DownloadText onClick={()=>{
                    const parts = businessData?.fileUrlList[index]?.split('/');
                    const expandName = parts[parts?.length-1]?.split('.').pop();
                      if(/(jpg|jpeg|png)$/i.test(expandName)){
                        setDataModal({menu:'attach',message:businessData?.fileUrlList[index]})
                      }else{
                        window.location.href = `${DOWNLOAD_ADDRESS}${parts[parts?.length-1]}`
                      }
                    }}>
                    {`${index + 1}. ` +  v}
                  </DownloadText>
                ))
                :
                  <NoData height={32}>{'* 첨부된 파일이 없습니다.'}</NoData>
                }
              </div>
            </div>
            <div className={'info-title'} style={{height: '280px'}}>{t('내용')}</div>
            <div className={'info-content'} style={{height: '280px',lineHeight:'1.8'}}>
              {dataValid(businessData?.contents,<NoData height={242}>{'* 등록된 내용이 없습니다.'}</NoData>)}
            </div>
          </div>
        </ViewBox>
        <div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 24px'}}/>
        <div style={{width:'370px',height:'34px',alignItems:'center'}}>

          <MenuTitle title={'이미지'} unitCase={'MenuCompTitle'}/>
          <div style={{...multipleImageGridStyle, marginTop:'16px'}}>
            {businessData?.imagesUrlList?.length === 0 ?
              <PreviewImage imageUrl={''} noneDelete={true}/>
              :
              businessData?.imagesUrlList?.map((v:any,i:number)=>(
              <PreviewImage imageUrl={v} noneDelete={true} clickEvent={()=>{setDataModal({menu:'preview',index:i})}}/>
              ))
            }
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};

export default BusinessDetail;

const ViewBox = styled.div`
  width: 784px;
  height: 584px;

  .info, .reg {
    display: grid;
    box-sizing: border-box;
    //border-top: 1px solid rgba(213, 215, 227, 0.5);

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);

    }

    &-content {
      display: flex;
      padding: 16px 0 0 16px;
      box-sizing: border-box;
      font-size: 14px;
      min-height: 48px;
      
      border-bottom: 1px solid rgba(213, 215, 227, 0.5);
      white-space: pre-wrap;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }
    }

    &-content-reg {
      width: 600px;
      height: 80px;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 15px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #D5D7E3;
        border-radius: 10px;
        background-clip: padding-box;
        border: 6px solid transparent;
      }

      &::-webkit-scrollbar-track {
        width: 21px;
        background: transparent;
      }
    }
  }
  
  .scroll_func{
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
  
  .click_text{
    color:#7E84A3;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline 1px #7E84A3;
    margin-right: 12px;
    &:hover{
      text-underline-offset: 3px;
    }
  }
`

const DownloadText = styled.div`
  line-height: 24px;
  &:hover{
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`