import React from 'react';
import ModalBase from './ModalBase'
import {modalTitleBase} from '../../style/StyleComponent'
import MainButtons from '../button/MainButtons'
import styled from 'styled-components'
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {Error_Type} from "../../types/Fundamental";
import {initError} from "../../function/function";
import {onPwCheckValid , PasswordCheck} from "../../ValidationCheck";

interface PasswordProps {
  clickEvent?:()=>void
  findEvent?:()=>void
  closeClick?:()=>void
  detailInfo?:any
  receivePassword?:(data:string)=>void
  viewType?:boolean
  skipPasswordCheck?: boolean
}
const PasswordChangeModal = ({clickEvent,findEvent,closeClick,detailInfo,receivePassword,viewType, skipPasswordCheck}:PasswordProps) => {
  const [inputType, setInputType] = React.useState(false)
  const [inputLogin, setInputLogin] = React.useState({id:detailInfo.id, pw:''})
  const [errorState, setErrorState] = React.useState<Error_Type>(skipPasswordCheck? {notice:'', status: 200} : initError)

  const [password, setPassword] = React.useState({text:'* 비밀번호 확인을 해주세요.' , color:''});
  const [infoPassword,setInfoPassword] = React.useState<any>({password: '', passwordCheck: ''})
  const [pwCheckNormal, setPwCheckNormal] = React.useState<boolean>(false);

  const loginOnchange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setInputLogin({...inputLogin,
                    [name]:value
                  })
  }
  const pwOnchange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target
    setInfoPassword({...infoPassword,
                    [name]:value
                  })
  }

  const onPwCheckEnter = (e:React.KeyboardEvent<HTMLInputElement>) =>{
    if(e.key === 'Enter'){
      if( receivePassword ){
        receivePassword( infoPassword.passwordCheck )
      }
      PasswordCheck(infoPassword,setPassword,setPwCheckNormal);
    }
  }

  const onCheckPassword = (e: { target: { name: string; value: string } }) => {
    const {name, value} = e.target
    setInfoPassword({
                         ...infoPassword,
                         [name]: value
                       })
    onPwCheckValid(value,setPassword)
  }

  const passwordCheckRes = async ()=>{
    try {
      const response = await client.post('/login',{
        id: inputLogin.id.trim(),
        password: inputLogin.pw.trim()
      },)
      if(response.data.status !== 200){
        setErrorState({notice:response.data.message,status:response.data.status})
      }else{
        setErrorState({notice:'',status:response.data.status})
      }
    }catch ( e ){
      console.log(e)
    }
  }
  const onKeyPress = (e:any) => {
    if(e.key === 'Enter') {
      passwordCheckRes().then()
    }
  }

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle: '비밀번호 변경'},)}
          <ContentPopup>
            <div className={'content-value'}>
              <div>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:"12px"}}>
                  <div className={'menu-text'}>{'기존 비밀번호'}</div>
                  {!viewType &&
                      <div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={findEvent}>{'비밀번호 찾기'}</div>
                  }
                </div>
                <div style={{color:'#F0142F', fontSize:'12px'}}>{errorState.notice}</div>
                <div style={{display:'grid', gridTemplateColumns:'1fr 112px', gap:'8px',opacity:errorState.status !== 200 ? 1:0.5,pointerEvents:errorState.status !== 200 ? 'auto':'none'}}>
                  <div style={{position:'relative'}}>
                    <input type={inputType ? 'text':'password'}
                                placeholder={'비밀번호를 입력해주세요.'} name={'pw'} className={'input-style'} readOnly={errorState.status === 200}
                                value={inputLogin.pw.trim()} onChange={loginOnchange} onKeyDown={onKeyPress}
                                style={{margin:'8px 0', width:'264px',boxSizing:'border-box'}}/>
                    <div style={{position:'absolute',right:0, top:16, fontSize:'12px', color:'#7e84a3', cursor:'pointer', boxSizing:'border-box',paddingRight:'8px'}}
                         onClick={()=>setInputType(!inputType)}>
                      {inputType ? 'HIDE' : 'SHOW'}
                    </div>
                  </div>
                  <MainButtons name={'비밀번호 확인'} buttonType={'cta'} width={'112px'} margin={'0'} clickEvent={passwordCheckRes}/>
                </div>
              </div>

              <div style={{opacity:errorState.status === 200 ? 1:0.5, pointerEvents:errorState.status === 200 ? 'auto':'none'}}>
                <div className={'menu-text'}>
                  {'새 비밀번호'}
                </div>
                <InputText type="password" onChange={onCheckPassword} name={'password'}
                           value={infoPassword.password}/>
                <div style={{color:'rgba(23,23,37,0.5)', fontSize:'12px', marginTop:'8px'}}>{'*6글자 이상 영문,숫자 또는 특수문자를 혼용하여 사용해주세요.'}</div>
              </div>

              <div style={{opacity:errorState.status === 200 ? 1:0.5,pointerEvents:errorState.status === 200 ? 'auto':'none'}}>
                <div className={'menu-text'}>
                  {'새 비밀번호 확인'}
                </div>
                <div style={{display:'flex'}}>
                  <InputText type="password" name={'passwordCheck'} value={infoPassword.passwordCheck} style={{width:'304px'}}
                             onChange={pwOnchange} onKeyDown={onPwCheckEnter}/>
                  <MainButtons name={'확인'} buttonType={'cta'} width={'72px'} marginDis={true}
                               clickEvent={()=> {
                                 if( receivePassword ){
                                   receivePassword( infoPassword.passwordCheck )
                                 }
                                 PasswordCheck(infoPassword,setPassword,setPwCheckNormal)}
                                }/>
                </div>
                <div className={'form_text'} style={{color: password.color}}>{password.text}</div>
              </div>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MainButtons name={'취소'} width={'144px'} buttonType={'popupNormal'} clickEvent={closeClick}/>
              <MainButtons name={'변경'} width={'144px'} buttonType={'popupCta'} disabled={password.text !== '비밀번호가 일치합니다.'} clickEvent={clickEvent}/>
            </div>
          </ContentPopup>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default PasswordChangeModal;

const BaseComponent = styled.div`
  position: absolute;
  width: 416px;
  height: 512px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  box-shadow: 0px 1px 4px #15223214;
  
  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`

const ContentPopup = styled.div`
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-value{
    &>div{
      margin-bottom: 16px;
    }
  }
  
  .menu-text{
    color: #7E84A3;
    font-size: 12px;
    font-weight: 700;
    &::after{
      content:'*';
      margin-left: 4px;
      color: #F0142F;
    }
  }
  
  .input-style{
    border: 1px solid #D7DBEC;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    padding: 0 8px;
    height: 32px;
    &:focus-within{
      border: 1px solid #0058ff;
    }
  }
  .form_text{
    height: 14px;
    font-size: 12px;
    color: rgba(23, 23, 37, 0.5);
    display: flex;
    white-space: nowrap;
    margin-top: 8px;
  }
`

const InputText = styled.input`
  width: 384px;
  height: 32px;
  background-color: #ffffff;
  box-sizing: border-box;
  outline: none;
  border: 1px solid #D7DBEC;
  border-radius: 4px;
  font-size: 14px;
  white-space: pre-wrap;
  padding: 0 8px;
  
  &:focus{
    border: 1px solid #0058ff;
  }
  
  &::placeholder{
    font-size: 14px;
    font-weight: normal;
    color: rgba(23,23,37,0.3);
    font-family: 'NanumGothicOTF', sans-serif;
  }
`