import React from 'react';
import ModalBase from "./ModalBase";
import styled from 'styled-components'
import Draggable from "react-draggable";
import {
  dataValid ,
  initError ,
  loadingRemove ,
  modalListLoading
} from "../../function/function";
import {GridTableComponent , GridTableSelect , NoData , validationValue} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {Error_Type , Modal_View_Type} from "../../types/Fundamental";
import dayjs from "dayjs";
import PreviewImage from "../display/PreviewImage";
import DetailImageView from "./DetailImageView";

interface Interface {
  companyData?:any
  closeEvent?:() => void
}
const BusinessVisitHistoryModal = ({companyData,closeEvent}:Interface) => {
  const [businessData,setBusinessData] = React.useState<any[]>([]);
  const [errorState,setErrorState] = React.useState<Error_Type>(initError)
  const [selectBusinessDetail,setSelectBusinessDetail] = React.useState<any>({})
  const [previewImg,setPreviewImg] = React.useState<{menu:string,url:string}>({menu:'',url:''})

  const visitListRes = async () =>{
    modalListLoading()
    try {
      const response = await client.get(`/business/management/visit/history/${dataValid(companyData?.companyCode)}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setBusinessData([])
      }else{
        setErrorState({status:valid.status,notice:''})
        setBusinessData(data)
      }
      loadingRemove()
    }catch ( e ) {
      console.log(e)
      loadingRemove()
    }
  }


  React.useEffect(()=>{
    visitListRes().then()
  },[companyData?.companyCode])



  return (
    <ModalBase modalContent={
      <React.Fragment>
        {previewImg.menu === 'preview' && <DetailImageView image={previewImg.url} closeMenu={()=>{setPreviewImg({menu:'',url:''})}}/>}
        {previewImg.menu === 'sign' && <DetailImageView image={previewImg.url} closeMenu={()=>{setPreviewImg({menu:'',url:''})}}/>}

        <Draggable>
          <Container>
            <div className={'modal'} style={{backgroundColor:'#CFFA61',color:'#171725'}}>
              <div className={'modal_title'} >{'방문이력: ' + `${dataValid(companyData?.companyName)}`}</div>
            </div>
            <div onMouseDown={(e)=>e.stopPropagation()}>
              <div style={{display:'grid', gridTemplateColumns:'384px 1fr', gap:'16px',  boxSizing:'border-box',
                padding:'16px',justifyContent:'space-between'}}>
                <div>
                  <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>
                    {'방문이력'}
                  </div>
                  <GridTableComponent tempCol={'120px 120px 80px'} id={'grid-scroll'}
                                      scrollWidth={374} scrollX_Hide={true} height={312}>
                    <div className={'grid-title-unchecked'}>
                      <div>{'구분'}</div>
                      <div>{'작성자'}</div>
                      <div>{'방문날짜'}</div>
                    </div>
                    {businessData?.length === 0?
                      <NoData height={260}>{'* 등록된 방문이력이 없습니다.'}</NoData>
                      :
                      businessData.map((v,index)=>(
                        <GridTableSelect selected={index === selectBusinessDetail.idx} className={'grid-list-unchecked-no_icon'}
                                         onClick={()=>{setSelectBusinessDetail((prev:any)=>({...v, idx:index}))}}>
                          {validationValue(v.division)}
                          {validationValue(v.writer)}
                          {validationValue(dayjs(v.visitDate).format('YYYY.MM.DD'))}
                        </GridTableSelect>
                      ))
                    }
                  </GridTableComponent>

                </div>
                <div>
                  <div style={{fontSize:'12px',fontWeight:'bold',color:'#7E84A3'}}>
                    {'자세히보기'}
                  </div>

                  {selectBusinessDetail.idx === undefined ?
                    <div style={{display:'grid',gridTemplateColumns:'64px 1fr',marginTop:'16px'}}>
                      <div className={'reg_title'}>{'제목'}</div>
                      <div className={'reg_content'}>{'-'}</div>
                      <div className={'reg_title'} style={{height:'264px'}}>{'내용'}</div>
                      <div className={'reg_content'} style={{height:'264px'}}>
                        <NoData height={244}>{'* 방문이력 리스트를 클릭해주세요.'}</NoData>
                      </div>
                    </div>
                    :
                    selectBusinessDetail?.division === '영업' ?
                      <div style={{display:'grid',gridTemplateColumns:'64px 1fr',marginTop:'16px'}}>
                        <div className={'reg_title'}>{'제목'}</div>
                        <div className={'reg_content'} style={{height:'48px',display:'flex',alignItems:'center',overflow:'hidden'}}>{
                          dataValid(selectBusinessDetail?.title)}
                        </div>
                        <div className={'reg_title'} style={{height:'264px'}}>{'내용'}</div>
                        <div className={'reg_content'} style={{height:'264px'}}>
                          {businessData?.length === 0?
                            <NoData height={244}>{'* 등록된 방문이력이 없습니다.'}</NoData>
                            :
                            dataValid(selectBusinessDetail?.contents,<NoData height={244}>{'* 작성된 내용이 없습니다.'}</NoData>)
                          }
                        </div>
                      </div>
                      :
                      <div>
                        <div style={{display:'grid',gridTemplateColumns:'205px 205px',marginTop:'16px'}}>
                          <div style={{display:'grid',gridTemplateColumns:'88px 117px',borderTop:'1px solid #D5D7E37F'}}>
                            <div className={'reg_title_consumable'} style={{backgroundColor:'#D7DBEC'}}>{'구분'}</div>
                            <div className={'reg_content'} style={{height:'48px',display:'flex',alignItems:'center',overflow:'hidden'}}>{dataValid(selectBusinessDetail.division)}</div>
                          </div>
                          <div style={{display:'grid',gridTemplateColumns:'88px 117px',borderTop:'1px solid #D5D7E37F'}}>
                            <div className={'reg_title_consumable'} style={{backgroundColor:'#D7DBEC'}}>{'고객서명'}</div>
                            <div className={'reg_content'} style={{height:'48px',display:'flex',alignItems:'center',overflow:'hidden',color:selectBusinessDetail.customerSignature ? '#0058FF' : 'red',textDecoration:'1px underline',cursor:selectBusinessDetail.customerSignature && 'pointer'}}
                                 onClick={()=>{selectBusinessDetail.customerSignature && setPreviewImg({menu:'sign',url:selectBusinessDetail.customerSignature})}}>
                              {dataValid(selectBusinessDetail.customerSignature ? '완료' : '미등록')}
                            </div>
                          </div>
                        </div>
                        <div style={{display:'grid',gridTemplateColumns:'88px 322px'}}>
                          <div className={'reg_title_consumable'} style={{backgroundColor:'#D7DBEC'}}>{'요청사항'}</div>
                          <div className={'reg_content'} style={{height:'48px',display:'flex',alignItems:'center',overflow:'hidden'}}>{
                            dataValid(selectBusinessDetail?.requestedContents)}
                          </div>
                        </div>

                        <div className={'reg_content_scroll'}>
                          {selectBusinessDetail?.managementRow?.map((item:any,i:number)=>(
                            <div style={{width:'410px',marginBottom:'16px'}}>
                              <div style={{display:'grid',gridTemplateColumns:'88px 1fr'}}>
                                <div className={'reg_title_consumable'} style={{backgroundColor:'#F5F6FA'}}>{'ICT 번호'}</div>
                                <div className={'reg_container_consumable'}  style={{backgroundColor:'#F5F6FA'}}>{dataValid(item.ictNumber)}</div>
                              </div>
                              {item.main.map((subItem:any,index:number)=>(
                                <div style={{display:'grid',gridTemplateColumns:'88px 1fr',alignItems:'center',}}>
                                  <div className={'reg_title_consumable'} style={{backgroundColor:'#F5F6FA'}}>{dataValid(subItem.consumableName)}</div>
                                  <div className={'reg_container_consumable'} style={{justifyContent:'space-between'}}>
                                    {dataValid(subItem.actionType === 'check'  ? '점검' : subItem.actionType === 'replace' ? '교체' : '교체,점검')}
                                    <div style={{display:'flex',gap:'8px'}}>
                                      <PreviewImage imageUrl={subItem.beforeImage} mobileType={true} previewSize={40} radioActive={false} margin={2}
                                                    clickEvent={()=>{setPreviewImg({menu:'preview',url:subItem.beforeImage})}}
                                                    noneDelete={true}/>
                                      <PreviewImage imageUrl={subItem.afterImage} mobileType={true} previewSize={40} radioActive={false} margin={2}
                                                    clickEvent={()=>{setPreviewImg({menu:'preview',url:subItem.afterImage})}}
                                                    noneDelete={true}/>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))
                          }
                        </div>
                      </div>
                  }
                </div>
              </div>
            </div>
            <MainButtons name={'확인'} margin={'12px 0 0 0'} width={'144px'} padding={'4px 32px'} height={'40px'} buttonType={'cta'}
                         clickEvent={closeEvent}/>
          </Container>
        </Draggable>
      </React.Fragment>

    }/>
  );
};
export default BusinessVisitHistoryModal;

const Container = styled.div`
  width: 842px;
  height: 512px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  user-select: none;
  .reg_title{
    padding: 16px;
    width: 64px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    //min-height: 48px;
    background-color: #F5F6FA;
    color: #131523;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 1px solid rgba(213, 215, 227, 0.5);
  }
  .reg_content{
    display: flex;
    padding: 4px 0 4px 16px;
    box-sizing: border-box;
    font-size: 14px;
    min-height: 48px;
    max-width: 346px;
    border-bottom: 1px solid rgba(213, 215, 227, 0.5);
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: scroll;
    align-self: start;
    word-break: break-all;
    letter-spacing: 0.5px;
    white-space: pre-wrap;
    line-height: 28px;
    &::-webkit-scrollbar {
      width: 15px;
      display: block;
    }
  }
  .reg_title_consumable{
    padding: 16px;
    width: 88px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    //min-height: 48px;
    color: #131523;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 1px solid rgba(213, 215, 227, 0.5);
  }
  .reg_container_consumable{
    padding: 0 16px 0 8px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 13px;
    min-height: 48px;
    border-bottom: 1px solid rgba(213, 215, 227, 0.5);
  }
  .reg_content_scroll{
    max-width: 426px;
    height: 212px;
    overflow-y: overlay;
    &::-webkit-scrollbar {
      width: 15px;
      display: block;
    }
  }
  .modal{
    width: 842px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    color: #171725;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    cursor: move;
    &_title{
      font-size: 18px;
      font-weight: 900;
      margin-right: 8px;
    }
    &_sub_title{
      font-size: 14px;
      font-weight: bold;
      opacity: 0.5;
    }
  }
`