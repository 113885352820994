import React from 'react';
import styled from "styled-components";
import arrow from '../../asset/image/chevron-left.svg'
import {useEffect} from "react";
import {dataValid} from "../../function/function";
import ArrowButton from "../button/ArrowButton";

interface Props {
  persistWeight?: number
  menuArray: string[] | any[]
  fontWeight?: string | number
  clickEvent?: ( data: string ,index?:number) => void
  top?: string | number
  left?: string | number
  style?: any
  width?: string
  height?: number
  disabled?: boolean
  menuName?: string
  addNoneSelectMenu?: boolean
  selectValue?: string | number
  type?: string
  maxWidth?: string
}

const DropMenu: React.FunctionComponent<Props> = ( {
                                                     menuArray ,
                                                     fontWeight ,
                                                     top ,
                                                     left ,
                                                     height ,
                                                     clickEvent ,
                                                     style ,
                                                     persistWeight ,
                                                     width ,
                                                     disabled ,
                                                     addNoneSelectMenu ,
                                                     selectValue ,
                                                     type ,
                                                     maxWidth
                                                   } ) => {
  const [openMenu , setOpenMenu] = React.useState( false )
  const [listIndex , setListIndex] = React.useState<number>( 0 )
  const dropMenuRef = React.useRef<any>( null )
  const dropMenuArray = JSON.parse( JSON.stringify( menuArray ) )
  if( addNoneSelectMenu ){
    dropMenuArray.unshift( '선택 없음' )
  }
  React.useEffect( () => {
    setListIndex( dropMenuArray.findIndex( ( v: any ) => v === selectValue ) )
  } , [menuArray] )
  useEffect( () => {
               const handleClickOutside = ( e: any ) => {
                 if( dropMenuRef.current && !dropMenuRef.current.contains( e.target ) ){
                   setOpenMenu( false )
                 }
               }
               document.addEventListener( "mousedown" , handleClickOutside );
               return () => {
                 document.removeEventListener( "mousedown" , handleClickOutside );
               };
             }
    , [dropMenuRef] )
  const renderDropMenu = () => {
    return (
      <BaseComponent ref={dropMenuRef} onClick={() => setOpenMenu( !openMenu )}
                     onMouseDown={( e ) => e.stopPropagation()}
                     top={top} left={left} height={height}
                     style={{
                       ...style ,
                       border: `1px solid ${openMenu ? '#0058FF' : '#D7DBEC'}` ,
                       opacity: disabled ? 0.5 : 1 ,
                       pointerEvents: disabled ? 'none' : 'auto' ,
                       zIndex: openMenu ? 60 : 49 ,
                       height: `${!height ? openMenu ? ( ( dropMenuArray.length + 1 ) * 32 ) + 'px' : '32px' : openMenu ? ( ( dropMenuArray.length + 1 ) * height ) + 'px' : height + 'px'}` ,
                       minWidth: width ?? '160px' ,
                       maxWidth: maxWidth
                     }}>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{
            fontSize: '14px' ,
            fontWeight: `${persistWeight ?? openMenu ? 700 : 400}` ,
            paddingLeft: '8px'
          }}>{dataValid( dropMenuArray[listIndex] , '선택 없음' )}</div>
          <div style={{paddingRight: '8px' , display: 'flex' , flexDirection: 'column' , justifyContent: 'center'}}>
            <img style={{
              transform: `rotateZ(270deg) rotateY(${openMenu ? 180 : 0}deg)` ,
              transition: 'transform 0.25s' ,
              alignSelf: 'center'
            }} src={arrow} alt=""/>
          </div>
        </div>
        <div className={'spread_menu'}>
          {dropMenuArray.map( ( value: string , index: number ) => (
            <div key={value} onClick={() => {
              setListIndex( index )
              //@ts-ignore
              clickEvent( value === '선택 없음' ? '' : value )
            }} style={{fontSize: '14px' , fontWeight: fontWeight , height: `${height ?? 32}px`}}>{value}</div>
          ) )}
        </div>
      </BaseComponent>
    )
  }
  const renderProcessWave = () => {
    return (
      <BaseComponent ref={dropMenuRef} onClick={() => setOpenMenu( !openMenu )} backgroundColor={'#FFF'}
                     onMouseDown={( e ) => e.stopPropagation()}
                     top={top} left={left} height={height}
                     style={{
                       ...style ,
                       border: `1px solid ${openMenu ? '#0058FF' : '#D7DBEC'}` ,
                       opacity: disabled ? 0.5 : 1 ,
                       pointerEvents: disabled ? 'none' : 'auto' ,
                       zIndex: openMenu ? 60 : 49 ,
                       height: `${!height ? openMenu ? ( ( dropMenuArray.length + 1 ) * 32 ) + 'px' : '32px' : openMenu ? ( ( dropMenuArray.length + 1 ) * height ) + 'px' : height + 'px'}` ,
                       minWidth: width ?? '160px' ,
                       maxWidth: maxWidth
                     }}>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{
            fontSize: '14px' ,
            fontWeight: `${persistWeight ?? openMenu ? 700 : 400}` ,
            paddingLeft: '8px'
          }}>{dataValid( dropMenuArray[listIndex] , '선택 없음' )}</div>
          <div style={{display:'flex',width:'30px',height:'30px',background:'#D7DBEC',border:'1px solid #D7DBEC',alignItems:'center',justifyContent:'center',borderRadius:'4px'}}>
            <ArrowButton arrowDirection={!openMenu ?'bottom' : 'top'}/>
          </div>
          {/*<div style={{paddingRight: '4px' , display: 'flex' , flexDirection: 'column' , justifyContent: 'center'}}>*/}
          {/*  <img style={{*/}
          {/*    transform: `rotateZ(270deg) rotateY(${openMenu ? 180 : 0}deg)` ,*/}
          {/*    transition: 'transform 0.25s' ,*/}
          {/*    alignSelf: 'center' ,*/}
          {/*    border: '1px solid #D7DBEC' ,*/}
          {/*    borderRadius: '4px' ,*/}
          {/*    width: '24px' ,*/}
          {/*    height: '24px' ,*/}
          {/*    backgroundColor: '#fff'*/}
          {/*  }} src={arrow} alt=""/>*/}
          {/*</div>*/}
        </div>
        <div className={'spread_menu'}>
          {dropMenuArray.map( ( value: string , index: number ) => (
            <div key={value} onClick={() => {
              setListIndex( index )
              //@ts-ignore
              clickEvent( value === '선택 없음' ? '' : value ,index)
            }} style={{fontSize: '14px' , fontWeight: fontWeight , height: `${height ?? 32}px`}}>{value}</div>
          ) )}
        </div>
      </BaseComponent>
    );
  }
  const renderFilterMenu = () => {
    return (
      <BaseComponent ref={dropMenuRef} onClick={() => setOpenMenu( !openMenu )}
                     onMouseDown={( e ) => e.stopPropagation()}
                     top={top} left={left} height={height}
                     style={{
                       ...style ,
                       border: '1px sold #D7DBEC' ,
                       opacity: disabled ? 0.5 : 1 ,
                       pointerEvents: disabled ? 'none' : 'auto' ,
                       zIndex: openMenu ? 60 : 49 ,
                       height: `${!height ? openMenu ? ( ( dropMenuArray.length + 1 ) * 32 ) + 'px' : '32px' : openMenu ? ( ( dropMenuArray.length + 1 ) * height ) + 'px' : height + 'px'}` ,
                       minWidth: width ?? '160px' ,
                       maxWidth: maxWidth
                     }}>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{
            fontSize: '14px' ,
            fontWeight: `${persistWeight ?? openMenu ? 700 : 400}` ,
            paddingLeft: '8px'
          }}>{dataValid( dropMenuArray[listIndex] , '선택 없음' )}</div>
          <div style={{
            display: 'flex' , width: '32px' , height: '32px' ,
            flexDirection: 'column' , alignItems: 'center' , justifyContent: 'center' , backgroundColor: '#D7DBEC'
          }}>
            <img style={{
              transform: `rotateZ(270deg) rotateY(${!openMenu ? 180 : 0}deg)` ,
              transition: 'transform 0.25s' ,
              alignSelf: 'center' ,
            }} src={arrow} alt=""/>
          </div>
        </div>
        <div className={'spread_menu'}>
          {dropMenuArray.map( ( value: string , index: number ) => (
            <div key={value} onClick={() => {
              setListIndex( index )
              //@ts-ignore
              clickEvent( value === '선택 없음' ? '' : value )
            }} style={{
              borderTop: '1px solid #D7DBEC' ,
              fontSize: '14px' , fontWeight: fontWeight , height: `${height ?? 32}px`
            }}>{value}</div>
          ) )}
        </div>
      </BaseComponent>
    )
  }
  return (
    <>
      {type === 'processWave'
        ?
        renderProcessWave()
        :
        type === 'filter'
          ?
          renderFilterMenu()
          :
          renderDropMenu()
      }
    </>
  );
};
export default DropMenu;
const BaseComponent = styled.div<{
  top?: string | number,
  left?: string | number,
  height?: number,
  backgroundColor?: string,
  customStyle?: any
}>`
  //border: 1px solid #D7DBEC;
  min-height: ${props => props.height ?? 32}px;
  line-height: ${props => props.height ?? 32}px;
  background-color: ${props => props.backgroundColor ?? '#fff'};
  ${props => props.customStyle ?? {}}
  top: ${props => props.top ?? '8px'};
  left: ${props => props.left ?? '16px'};
  border-radius: 4px;
  //max-height: 200px;
  align-items: center;
  box-sizing: border-box;
  max-width: 200px;
  padding: 0;
  align-self: center;
  position: absolute;
  overflow: hidden;
  font-weight: bold;
  user-select: none;
  transition: all 0.25s;
  cursor: pointer;

  img {
    cursor: pointer;
    user-select: none;
  }

  .spread_menu {
    font-weight: 400;
    overflow-y: scroll;

    //max-height: 200px;

    & > div {
      position: relative;
      z-index: 90;
      box-sizing: border-box;
      cursor: pointer;
      padding: 0 8px;

      &:hover {
        background-color: rgba(0, 88, 255, 0.05);
        //border-bottom: 1px solid #d7dbec;
        color: #0058ff;
        font-weight: 700;
      }
    }
  }
`