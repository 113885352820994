import React from 'react';
import styled from 'styled-components';
import checked from '../../asset/image/check-icon.svg';
import {useTranslation} from "react-i18next";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";

import {PatternFormat} from "react-number-format";
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from "date-fns/esm/locale";

import DatePickerCustom from "../modal/DatePickerCustom";
import {DatePickerComp, InputDate} from "../../style/StyleComponent";

interface Props {
  receiveData: (data:any)=>void
}

const ActionContentInputBox:React.FunctionComponent<Props> = ({receiveData}) => {
  const { t }:any = useTranslation();
  const [inputValueState, setInputValueState] = React.useState({
                                                                 type:'check',
                                                                 responsibility:'',
                                                                 actionDate: `${dayjs().format('YYYY-MM-DD')}`,
                                                                 actionHour: `${dayjs().format('HH:mm:ss')}`,
                                                                 consumableName:'',
                                                                 notice:'', currentDate: true,
                                                               })

  const handleCheckedChange = (typeCase: string) => {
    if(typeCase === 'check'){
      if(!inputValueState.type){
        setInputValueState((prev:any)=>({...prev, type: 'check'}))
      }
      else{
        if(inputValueState.type.includes('replace')){
          if(inputValueState.type.includes('check')){
            setInputValueState((prev:any)=>({...prev, type: 'replace'}))
          }else{
            setInputValueState((prev:any)=>({...prev, type: 'check,replace'}))
          }
        }else{
          setInputValueState((prev:any)=>({...prev, type: ''}))
        }
      }
    }
    else{
      if(!inputValueState.type){
        setInputValueState((prev:any)=>({...prev, type: 'replace'}))
      }
      else{
        if(inputValueState.type.includes('check')){
          if(inputValueState.type.includes('replace')){
            setInputValueState((prev:any)=>({...prev, type: 'check'}))
          }else{
            setInputValueState((prev:any)=>({...prev, type: 'check,replace'}))
          }
        }else{
          setInputValueState((prev:any)=>({...prev, type: ''}))
        }
      }
    }
  }

  const inputOnChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    const {name, value} = e.target
    setInputValueState({
                         ...inputValueState,
                         [name]: value
                       });
  }

  React.useEffect(()=>{
    if(inputValueState.currentDate){
      setInputValueState((prev)=>({...prev, actionHour: dayjs().format('HH:mm:ss')}))
    }
  },[])


  React.useEffect(()=>{
    receiveData(inputValueState)
  },[inputValueState])

  return (
    <React.Fragment>
      <RegisterBox>
        <div className={'unit_title'}>{t('조치 타입')}</div>
        <div className={'unit_box'}>
          <div style={{display:'flex',padding:'10px',gap:'30px',fontSize:'12px'}}>
            <div style={{display:'flex', alignItems:'center',gap:'8px'}}>
              <input type="checkbox" id={'inspection'} onChange={()=>handleCheckedChange('check')}
                     checked={inputValueState.type.includes('check')}/>
              <label htmlFor="inspection">{t('점검')}</label>
            </div>
            <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
              <input type="checkbox" id={'substitute'} onChange={()=>handleCheckedChange('replace')}
                     checked={inputValueState.type.includes('replace')}/>
              <label htmlFor="substitute">{t('교체')}</label>
            </div>
          </div>
        </div>

        {inputValueState.type.includes('replace') ?
          <React.Fragment>
            <div className={'unit_title'}>{t('소모품명')}</div>
            <div className={'unit_box'}>
              <InputText type="text" placeholder={t('소모품명을 입력하세요.')} name={'consumableName'} onChange={inputOnChange}/>
            </div>
          </React.Fragment>
          :
          <React.Fragment>
            <div className={'unit_disabled'}>{t('소모품명')}</div>
            <div className={'unit_box'}> </div>
          </React.Fragment>
        }

        <div className={'unit_title'}>{t('담당자')}</div>
        <div className={'unit_box'}>
          <InputText type="text" placeholder={t('담당자명을 입력하세요.')} name={'responsibility'} onChange={inputOnChange}/>
        </div>

        <div className={'unit_title '}>{t('조치 일시')}</div>
        <div className={'unit_box'} style={{position:'relative'}}>

          <DatePickerComp style={{opacity: inputValueState.currentDate ? 0.3 : 1}}>
            <ReactDatePicker onChange={(v)=>setInputValueState((prev:any)=>({...prev, actionDate: dayjs(v).format('YYYY-MM-DD')}))}
                             disabled={inputValueState.currentDate}
                             dateFormat={'yyyy-MM-dd'}
                             selected={dayjs(inputValueState.actionDate).toDate()}
                             locale={t('lang') === 'ko' ? ko : undefined}
                             minDate={new Date(dayjs().subtract(1, 'year').format('YYYY-MM-DD'))}
                             popperPlacement={"right"}
                             customInput={
                               <InputDate type={'date'}/>}
                             renderCustomHeader= {({date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled,}
                             ) => (<DatePickerCustom data={{date, increaseMonth, decreaseMonth, increaseYear, decreaseYear, prevMonthButtonDisabled, nextMonthButtonDisabled,}}/>)}/>
          </DatePickerComp>
          <div style={{display:'flex',alignItems:'center',gap:'150px',position:'absolute', left:'190px'}}>
            <PatternFormat style={{opacity: inputValueState.currentDate ? 0.3 : 1, pointerEvents: inputValueState.currentDate ? 'none' :'auto', fontWeight: 400}}
                           className={'unit_time'} format={'##:##:##'} type={'tel'} name={'actionHour'}
                           onChange={inputOnChange} value={inputValueState?.actionHour}/>
            <div style={{display:'flex', fontSize:'12px'}}>
              <div style={{display:'flex',alignItems:'center',gap:'8px'}}>
                <input type="checkbox" id={'now'} checked={inputValueState.currentDate}
                       onChange={()=>setInputValueState((prev)=> (
                         {...prev, currentDate: !prev.currentDate,
                           actionDate: !prev.currentDate? dayjs().format('YYYY-MM-DD') : prev.actionDate ,
                           actionHour: !prev.currentDate? dayjs().format('HH:mm:ss') : prev.actionHour }))}/>
                <label htmlFor="now">{t('현재시간 등록')}</label>
              </div>
            </div>
          </div>
        </div>
        <div className={'unit_title'} style={{height:'120px'}}>{'비고'}</div>
        <div className={'unit_box'} style={{height:'120px'}}>
          <InputTextBox name={'notice'} onChange={inputOnChange} placeholder={t('내용을 입력해주세요.')}/>
        </div>
      </RegisterBox>
    </React.Fragment>
  );
}


export default ActionContentInputBox;

const RegisterBox = styled.div`
  width: 784px;
  display: grid;
  grid-template-columns: 168px 616px;
  border-top: 1px solid #E6E9F4;
  box-sizing: border-box;
  
  
 
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  
 
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  
  input[type='checkbox'] {

    cursor: pointer;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D7DBEC;
    outline: none;
    box-sizing: border-box;

    &:checked {
      border: transparent;
      background-color: #0058Ff;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-position: center;
    }

    .label_position {
      display: flex;
      align-items: center;
      position: absolute;
      right: 20px;
      font-size: 14px;
    }
  }
  
 
  
  .unit{
    &_title{
      width: 168px;
      height: 48px;
      font-size: 12px;
      display: flex;
      font-weight: 900;
      align-items: center;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: #F5F6FA;
      border-bottom: 1px solid #E6E9F4;
    }
    
    &_disabled{
      width: 168px;
      height: 48px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 0 16px;
      box-sizing: border-box;
      background-color: #E6E9F4;
      color:rgba(19, 21, 35, 0.5);
      border-bottom: 1px solid #E6E9F4;
    }

    &_box{
      display: flex;
      height: 48px;
      align-items: center;
      padding: 4px 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #E6E9F4;
    }
    &_time{
      width: 160px;
      height: 32px;
      margin: 0;
      border: 1px solid #E6E9Ea;
      border-radius: 4px;
      outline: none;
      display: flex;
      box-sizing: border-box;
      padding-left: 10px;
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      font-weight: bold;
      &::placeholder{
        font-size: 14px;
        font-family: "NanumGothicOTF", sans-serif;
        color: rgba(23,23,37,0.3);
        font-weight: bold;
      }
    }
  }
`


const InputText = styled.input`
  font-size: 14px;
  font-family: 'NanumGothicOTF', sans-serif;
  height: 32px;
  width: 600px;
  border:1px solid #E6E9Ea;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  outline: none;
  padding-left: 10px;
  &::placeholder{
    font-size: 14px;
    font-weight: 400;
    color: rgba(23,23,37,0.3);
    font-family: 'NanumGothicOTF', sans-serif;
  }
  `


const InputTextBox = styled.textarea`
  width: 600px;
  height: 100px;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d7dbec;
  resize: none;	  
  font-size: 14px;
  font-family: 'NanumGothicOTF',sans-serif;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth; 
  &::-webkit-scrollbar {
    width: 18px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #D5D7E3;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }	 
  &::-webkit-scrollbar-track{
    width: 21px;
    background: transparent;
  }
  
  &::placeholder{
    font-size: 14px;
    font-weight: 400;
    color: rgba(23,23,37,0.3);
    font-family: 'NanumGothicOTF' ,sans-serif;
  }
`