import React from 'react';
import {PageContainer} from "../../../style/StyleComponent";
import SectionContainer from "../../../containers/common/SectionContainer";
import AccountModelManagement from "../../../containers/admin/fas/company/account/AccountModelManagement";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import {useLocation} from "react-router-dom";
import AccountModelRegistration from "../../../containers/admin/fas/company/account/AccountModelRegistration";
import AccountModelDetail from "../../../containers/admin/fas/company/account/AccountModelDetail";
import AccountModelMultipleReg from "../../../containers/admin/fas/company/account/AccountModelMultipleReg";

const FasAccountModel = () => {
  const location = useLocation()
  const {pathname} = location
  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {pathname.length === ( location.pathname.includes( '/admin' ) ? 16 : 10 ) ?
        <SectionContainer contentContainer={<AccountModelManagement/>}/>
        : location.pathname.includes( 'register' ) ?
          <SectionContainer contentContainer={<AccountModelRegistration/>}/>
            :
            <SectionContainer contentContainer={<AccountModelDetail/>}/>
      }
    </PageContainer>
  );
};
export default FasAccountModel;
