import React from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {helperModule} from "./StyleComponent";

interface Interface {
  title:string;
  requiredValue:string[];
  textRequiredValue?:string[] | any;
  viewType?:boolean;
  helperView?:boolean
  helperText?:string
  helperWidth?:string
}


const TableValue = ({title,requiredValue,viewType,helperText,helperView,helperWidth,textRequiredValue}:Interface) => {
  const interactions = requiredValue.includes(title);
  const textInteractions = textRequiredValue?.includes(title);
  const {t}:any = useTranslation();


  return (
    <Container>
      <div style={{color:textInteractions ? '#0058FF' : '#171725'}}>{t(title)}</div>
      {viewType &&
          <div className={'required_value'}>{interactions && '*'}</div>
      }
      {helperView && helperModule(helperText,helperWidth,'0 0 2px 0')}
    </Container>
  );
};
export default TableValue;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  
  .required_value{
    color: #F0142F;
    font-size: 12px;
  }
`