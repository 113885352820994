import React, {useEffect} from 'react';
import styled from "styled-components";
import {DataInfoProps} from "../../types/Fundamental";
import {useLocation} from "react-router-dom";
import noImage from '../../asset/image/noImage.svg'
import {useTranslation} from "react-i18next";
import {LazyLoadImage} from 'react-lazy-load-image-component'

const FacilityContentBox:React.FunctionComponent<DataInfoProps> = ({
	                                                                   profileImageUrl, imageList,
	                                                                   switchType, clickEvent, doubleClickEvent,
	                                                                   objectType,selectedBox, imageClickEvent
	                                                                   }) => {
	const location = useLocation()
	const {pathname} = location
	const { t }:any = useTranslation();

	const imageValidate = () => {
		if(!profileImageUrl || !profileImageUrl[0]){
			return noImage
		} else {
			return profileImageUrl
		}
	}
	const imageArrayValidate = () => {
		if(imageList === undefined){
			return noImage
		} else {
			return imageList
		}
	}
	//LazyLoadImage박스 부분 window 이벤트 막기
	useEffect(() => {
	 		const lazyLoadImageBox = document.querySelectorAll('.lazy-load-image-background')
			lazyLoadImageBox.forEach((item) => {
				item.addEventListener('click', (e) => {
					e.preventDefault()
				})
			}
			)
		}
		, [])
	// const profileUrl = React.useMemo(()=>imageValidate(),[profileImageUrl])
	// @ts-ignore
	return (
		<BaseComponent  contentGap={pathname.split('/')[1] === 'admin'} selectedBox={selectedBox}>
			{!switchType ?
				// <div style={{backgroundImage: `url(${imageValidate()})`}}/>
				<LazyLoadImage src={`${imageValidate()}`} height={128} width={128}
							   style={{objectFit:'cover', objectPosition:'center'}}
							   onClick={imageClickEvent}/>
				:
				<LazyLoadImage src={`${imageArrayValidate()}`}
							   onClick={imageClickEvent}
							   height={128} width={128}
							   style={{objectFit:'cover',objectPosition:'center'}}/>
			}
			<div className={'thumb_data'} onDoubleClick={doubleClickEvent} onClick={selectedBox? doubleClickEvent : clickEvent}>
				{objectType?.map((v)=>(
					<div>
						<div>{t(v.title)}</div>
						<div>{v.data}</div>
					</div>
				))}
			</div>
		</BaseComponent>
	);
};


export default FacilityContentBox;

	const BaseComponent = styled.div<{contentGap: boolean,selectedBox?:boolean}>`
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
		width: 384px;
		height: 144px;
		background-color: ${p => p.selectedBox ? 'rgba(215, 219, 236, 0.7)' : '#F5F6FA'};
		border-radius: 6px;
		box-sizing: border-box;
		padding: 9px 16px 7px 8px;
		transition: background-color 0.05s;
		cursor: pointer;
		border: 1px solid ${props => props.selectedBox? '#0058ff' : '#d7dbec'};

		&:hover {
			background-color: rgba(215, 219, 236, 0.7);
		}

		& > div:first-child {
			width: 128px;
			height: 128px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			background-color: #fff;
		}

		.thumb_data {
			//&>div:last-child{
			display: grid;
			color: #171725;
			grid-template-rows: repeat(${props => props.contentGap ? 5 : 4}, 16px);
			justify-content: center;
			gap: ${props => props.contentGap ? '10px' : '17px'};
			font-weight: 700;
			font-size: 12px;
			user-select: none;
			position: relative;
			align-content: center;

			& > div {
				display: grid;
				grid-template-columns: 1fr 1fr;
				justify-content: space-between;
				height: 16px;
				line-height: 16px;
				gap: 16px;

				& > div:first-child {
					width: 90px;
				}

				& > div:last-child {
					width: 110px;
					text-align: right;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	`