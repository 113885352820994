import React,{useState} from 'react';
import ModalBase from "./ModalBase";
import Draggable from "react-draggable";
import styled from "styled-components";
import {
  modalTitleBase , validationValue ,
} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import {dataValid , isTouchDevice , loadingRemove , modalListLoading} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface facilityInfoPropsType{
  allCount?:number|string
  inputMaterialClickEvent?:()=>void
  facilityClickEvent?:()=>void
  receivedData?:(data:any)=>void
  cancelEvent?:()=>void
  selectedFacility?: {facilityCode:string, facilityName:string}[]
  companyData?:any
}
const ProcessFacilityCheck:React.FunctionComponent<facilityInfoPropsType> = ({companyData, selectedFacility, cancelEvent, receivedData}) => {

  const location = useLocation()
  const authValid = location.pathname.includes('/admin')
  const selectedCompany = useRecoilValue(authValid? persistCompanyData : userPersistCompanyData)
  const [facilityList, setFacilityList] = useState([])
  const [checkedList, setCheckedList] = React.useState<any[]>(selectedFacility ?? [])
  const facilityListRes = async () => {
    try {
      modalListLoading()
      const response = await client.get(`/facility/infinite/facility/list/${!companyData.companyCode? selectedCompany.companyCode : companyData.companyCode}/프레스/@`,{
        headers:{Authorization}
      })
      setFacilityList(response.data.data.row)
      loadingRemove()

    }catch (e) {
      loadingRemove()
      console.log(e)
    }
  }

  React.useEffect(()=>{
    facilityListRes().then()
  },[])

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          <Draggable disabled={isTouchDevice}>
            <BaseComponent authDivide={true}>
              {modalTitleBase({mainTitle:'공정 설비 등록'})}
              <ContentFrame>
                <div style={{display:'flex',justifyContent:'space-between',margin:'16px 20px 0',alignItems:'center'}}>
                  <div style={{display:'flex'}}>
                    <div style={{fontSize:'14px',fontWeight:'bold'}}>{'선택 가능 기계 리스트'}</div>
                    <div style={{display:'flex', fontSize:'14px', fontWeight:800, alignItems:'center',marginLeft:'16px'}}>
                      {'(선택된 기계 수:'}
                      <div style={{color:'#0058ff', textDecoration:'underline', textUnderlineOffset:'3px', margin:'0 4px'}}>{dataValid(checkedList?.length,0)}</div>
                      {'개)'}
                    </div>
                  </div>
                  <MainButtons name={checkedList.length === facilityList.length? '전체 설비 비우기' : '전체 설비 가져오기'}
                               padding={'0 8px'}
                               clickEvent={()=>{checkedList.length === facilityList.length? setCheckedList([]) :
                                 setCheckedList(()=>([...dataValid(selectedFacility,[]), ...facilityList.filter((v:any)=>(!dataValid(selectedFacility,[]).map((data:any)=>(data.facilityCode)).includes(v.facilityCode)))])) } }/>
                </div>
                <div style={{padding:'0 16px 16px'}}>
                  <GridTableComponent tempCol={'40px 80px repeat(2, 180px) 114px'}
                                      height={480} scrollWidth={596}
                                      scrollX_Hide={true} >
                    <div className={'grid-title-unchecked'}>
                      {['No.','기종','제조 번호','기계명','ICT번호'].map((v)=>(
                        <div key={v}>{v}</div>))}</div>
                    {facilityList?.map((v:any,i)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       selected={checkedList.some((el)=>(el.facilityCode === v.facilityCode))}
                                       onClick={()=> {
                                         if(checkedList.some((el)=> el.facilityCode === v.facilityCode)){
                                           setCheckedList((prev:any)=>(prev.filter((el:any)=>(el.facilityCode !== v.facilityCode))))
                                         }else{
                                           setCheckedList((prev:any)=>([...prev, {facilityCode: v.facilityCode, facilityName: v.facilityName}]))
                                         }
                                       }}>
                        <div>{i+1}</div>
                        {validationValue(v.type)}
                        {validationValue(v.manufacturingNumber)}
                        {validationValue(v.facilityName)}
                        {validationValue(v.ictNumber, '할당 안됨')}
                      </GridTableSelect>
                    ))}
                  </GridTableComponent>
                  <div style={{display:'flex',marginTop:'16px',justifyContent:'center'}}>
                    <MainButtons name={'취소'} clickEvent={cancelEvent}/>
                    <MainButtons name={'선택 하기'} buttonType={'cta'}
                                 clickEvent={()=>{
                                  receivedData && receivedData(checkedList);
                                  if(cancelEvent){
                                    cancelEvent()
                                  }
                                 }}
                    />
                  </div>
                </div>
              </ContentFrame>
            </BaseComponent>
          </Draggable>
        </React.Fragment>}/>
    </React.Fragment>
  );
};
export default ProcessFacilityCheck;

const BaseComponent = styled.div<{authDivide:boolean}>`
  width: 638px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  .modal_title{
    width: 638px;
    height: 56px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    gap: 8px;
    cursor: move;
    font-size: 18px;
    font-weight: 900;
  }
`

const ContentFrame = styled.div`
  background-color: #fff;
`
