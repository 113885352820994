import React , {useEffect , useState} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {
  ContentFrame ,
  listResultMessage ,
  SectionFrame , tooltipModule , validationValue ,
} from "../../../../../style/StyleComponent";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";
import {Modal_View_Type , PauseKindsType} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedFunction , authValid , buttonAuthValid ,
  dataAccessLoading ,
  dataValid  ,
  filterCheckedRow , hasDuplicate ,
  initModal ,
  listLoadingCircle ,
  loadingRemove , menuVisitLogRegister ,
  onChangeArray ,
  removeCheckedFunction ,
  singleCheckedFunction
} from "../../../../../function/function";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";

import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import TableValue from "../../../../../style/TableValue";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import styled from "styled-components";
import {cloneDeep} from "lodash";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const PauseTypeManagement = () => {
  const {t}: any = useTranslation();
  const [rowList , setRowList] = React.useState<PauseKindsType[]>([]);
  const [checkedList , setCheckedList] = React.useState<any>([])
  const [selectedPauseType , setSelectedPauseType] = React.useState<any>({})
  const [selectedPauseCode , setSelectedPauseCode] = React.useState<string>('')
  const selectedCompany = useRecoilValue(authValid() ? persistCompanyData : userPersistCompanyData)
  const [iconSelect , setIconSelect] = React.useState<number>(- 1)
  const [focusList , setFocusList] = React.useState<number>(- 1)
  const [page , setPage] = React.useState<number>(1);
  const [pageBlock , setPageBlock] = React.useState<number>(0);
  const [totalPage , setTotalPage] = React.useState<number>(1);
  const [pauseTypeList , setPauseTypeList] = React.useState<any[]>([]);
  const [pauseList , setPauseList] = React.useState<any[]>([]);
  const [idIndex , setIdIndex] = React.useState<number>(1)
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>(initModal)
  const [processIndex , setProcessIndex] = React.useState<number>(0)
  const [hoverIcon , setHoverIcon] = React.useState<string>(modify)
  const [selectPauseData , setSelectPauseData] = React.useState<any>({})
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>([])

  const scrollRef = React.useRef<any>(null)
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    setIdIndex(idIndex + 1)
    setBindIndex(-1)
    const rowInput = {
      id: idIndex ,
      processIndex: idIndex.toString() ,
      processPauseName: '' ,
      companyCode: selectedCompany.companyCode ,
      processCode: selectedPauseType.processCode
    }
    setRowList([...rowList , rowInput])
    setCheckedList([...checkedList , rowInput.id])
    scrollToStart()
  }
  const checkboxValid = () => {
    if(rowList.length !== 0){
      return {list: rowList , id: 'id'}
    } else{
      return {list: pauseList , id: 'processPauseCode'}
    }
  }
  //공정 리스트
  const processTypeListRes = async (companyCode: string) => {
    try {
      listLoadingCircle()
      const response = await client.get(`/process/type/list/${page}/10/${companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      // if(data.total_page > 1 && page > 1){
      //   setPauseTypeList((prev: any) => prev.concat(data.row))
      // } else{
      //   setPauseTypeList(data.row)
      // }
      setPauseTypeList(data.row)
      loadingRemove()
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }

  const pauseTypeRegisterRes = async () => {
    if(filterCheckedRow(rowList , checkedList , 'id').find((v) => !v.processPauseName.trim())){
      setDataModal({menu: 'confirm' , message: t('일시정지 유형명을 입력해 주세요.')})
    } else if(hasDuplicate(rowList.filter(item => checkedList.includes(item.id)),'processPauseName')){
      setDataModal({menu: 'confirm' , message: t('입력한 유형이 중복 되었습니다.')})
    } else{
      try {
        dataAccessLoading()
        const response = await client.post(`/process/pause/register` ,
                                           filterCheckedRow(rowList , checkedList , 'id').map((pi:any,i:number)=>({...pi, processIndex: (pauseList?.length + 1) + i}))
          , {headers: {Authorization}})
        const data = response.data;
        if(data.status !== 200){
          loadingRemove()
          setDataModal({menu: 'confirm' , message: t(data.message)})
        } else{
          setTimeout(() => {
            setRowList([]);
            setCheckedList([]);
            setDataModal({menu: 'success' , message: t('등록 되었습니다.')})
            pauseTypeListRes(selectedPauseType.processCode).then()
            loadingRemove()
          } , 500)
        }
      }
      catch (e) {
        console.log(e)
        loadingRemove()
      }
    }
  }

  //일시정지 유형 리스트
  const pauseTypeListRes = async (processCode: string) => {
    try {
      listLoadingCircle()
      const response = await client.get(`/process/pause/list/${selectedCompany.companyCode}/${processCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      loadingRemove()
      setPauseList(data.row)
      // setIdIndex(data.row.length + 1)
    }
    catch (e) {
      loadingRemove()
      console.log(e)
    }
  }

  const exceptPermanentProcess = checkedList?.filter((el:any)=>{
    const findPermanentProcess = pauseList?.find((process:any)=>(process.processPauseName === '일시정지'))
    return el !== findPermanentProcess?.processPauseCode
  })

  const currentTypeArrayIndexChange = pauseList.filter((el:any)=>(!exceptPermanentProcess.includes(el.processPauseCode))).map((v:any, i:number)=>({...v, processIndex: i+1}))

  //일시정지 유형 삭제
  const pauseTypeDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/process/pause/delete` , exceptPermanentProcess , {headers: {Authorization}})
      const data = response.data
      const caseArray = data.data?.workHistory?.map((v: any) => ( {code: v , case: '작업이력에 등록된 품목에서 해당 유형이 사용중 입니다.'} ))
      if(data.status === 200){
        setTimeout(() => {
          setIconSelect(- 1)
          setDataModal({menu: 'success' , message: '선택한 일시정지 유형이 삭제 되었습니다.'})
          removeCheckedFunction(setPauseList , pauseList , setCheckedList , filteredCheckList , 'processPauseCode')

          client.post(`/process/pause/index/update` ,
                      currentTypeArrayIndexChange
            , {headers: {Authorization}})

          scrollToStart()
          loadingRemove()
        } , 500)
      } else if(data.status === 601){
        setTimeout(() => {
          setDataModal({menu: 'deleteCase'})
          setDeleteCaseArray(caseArray)
          setCheckedList(checkedList.filter((v: string) => !data.data.success.includes(v)))
          setPauseList(pauseList.filter((v: any) => !data.data.success.includes(v['processPauseCode'])))
          scrollToStart()
          loadingRemove()
        } , 500)
      }
      else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e) {
      loadingRemove()
      console.log(e)
    }
  }
  const pauseTypeUpdate = async () => {
    if(!pauseList[iconSelect].processPauseName.trim()){
      setDataModal({menu: 'confirm' , message: t('일시정지 유형이 없습니다.')})
    } else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post(`/process/pause/update` , {
          companyCode: selectedCompany.companyCode ,
          processPauseCode: pauseList[iconSelect].processPauseCode ,
          processPauseName: pauseList[iconSelect].processPauseName ,
          processIndex: pauseList[iconSelect].processIndex ,
        } , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: t(data.message)})
          loadingRemove()
        } else{
          setTimeout(() => {
            setCheckedList([]);
            setIconSelect(- 1);
            setDataModal({menu: 'success' , message: t('수정 되었습니다.')})
            loadingRemove()
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }

  const pauseTypeIndexUpdate = async () => {
    setChangeIndex(false)
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/process/pause/index/update` ,
                                         copyPauseList.map((el:any, i:number)=>({processPauseCode: el.processPauseCode, processIndex: i+1})), {headers: {Authorization}})
      const data = response.data
      if(data.status !== 200){
        setDataModal({menu: 'warn' , message: data.message})
        loadingRemove()
      } else{
        setTimeout(() => {
          setDataModal({menu: 'success' , message: '유형 순서가 고정 되었습니다.'})
          loadingRemove()
        } , 500)
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }

  const deleteRowEvent = () => {
    if(rowList.length === 0){
      if(exceptPermanentProcess?.length !== 0){
        setDataModal({menu: 'select' , message: t('삭제 하시겠습니까?')})
      }
      else{
        setDataModal({menu:'warn', message:'선택된 유형이 없습니다.'})
      }
    } else{
      setDataModal({menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"삭제" 하시겠습니까?'})
    }
  }
  React.useEffect(() => {
    if(selectedCompany.companyCode !== undefined){
      processTypeListRes(selectedCompany.companyCode).then()
    }
  } , [page])
  React.useEffect(() => {
    if(selectedPauseType.processCode){
      pauseTypeListRes(selectedPauseType.processCode).then()
    }
  } , [selectedPauseType , page])
  const resetFunc = () => {
    processTypeListRes(selectedCompany.companyCode).then()
    setCheckedList([])
    setIdIndex(1)
    setBindIndex(-1)
    setPauseList([])
    setPauseTypeList([])
    setPageBlock(0)
    setTotalPage(1)
    setPage(1)
    setRowList([])
  }

  React.useEffect(()=>{
    resetFunc()
  },[selectedCompany.companyCode])

  const processSelect = () => {
    setCheckedList([])
    setRowList([])
    setIconSelect(- 1)
    setBindIndex(-1)
    setIdIndex(1)
    setChangeIndex(false)
    setSelectedPauseType(pauseTypeList[processIndex]);
    setDataModal(initModal)
  }
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if(rowList.length > 0 || iconSelect !== - 1){
        event.preventDefault()
        event.returnValue = ''
      }
    };
    window.addEventListener('beforeunload' , handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload' , handleBeforeUnload)
    }
  } , [rowList.length , iconSelect])

  const excludedCodes = pauseList
  .filter(item => item.processPauseName === "일시정지")
   .map(item => item.processPauseCode);

  const filteredCheckList = checkedList.filter((v:string)=>(!excludedCodes.includes(v)))

  const [bindIndex , setBindIndex] = useState<number>( - 1 )
  const [changeIndex, setChangeIndex] = useState<boolean>(false)
  const copyPauseList = cloneDeep(pauseList)


  const positionChangeList = ( index: number , direction: string ) => {
    setChangeIndex(true)
    if( direction === 'top' && index !== 0 ){
      [copyPauseList[index] , copyPauseList[index - 1]] = [copyPauseList[index - 1] , copyPauseList[index]]
      setBindIndex( ( prev: number ) => ( prev - 1 ) )
    }
    if( direction === 'bottom' && index !== copyPauseList.length - 1 ){
      [copyPauseList[index] , copyPauseList[index + 1]] = [copyPauseList[index + 1] , copyPauseList[index]]
      setBindIndex( ( prev: number ) => ( prev + 1 ) )
    }
    setPauseList(copyPauseList)
  }

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'일시정지 유형 관리').then()
    }
  },[])

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={() => {setDataModal(initModal)}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={() => {setDataModal(initModal)}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'}
                                                    closeEvent={() => {setDataModal(initModal)}}/> : null}

      {dataModal.menu === 'deleteCase' &&
        <DeleteCaseModal targetName={'일시정지 유형'} caseArray={deleteCaseArray}
                         closeEvent={() => setDataModal(initModal)}/>}

      {dataModal.menu === 'confirm' &&
        <ConfirmPopup statusText={t(dataModal.message)} clickEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'select' &&
        <SelectConfirmModal statusText={dataModal.message}
                            noticeText={' * 삭제된 데이터는 복구되지 않습니다.'}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={pauseTypeDeleteRes}/>}

      {dataModal.menu === 'register' &&
        <SelectConfirmModal statusText={dataModal.message}
                            noticeText={' * 선택되지 않은 리스트는 저장 되지 않습니다'}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={pauseTypeRegisterRes}/>}

      {dataModal.menu === 'alert' &&
        <SelectConfirmModal statusText={dataModal.message}
                            noticeText={'"예" 선택시 변경 사항이 저장 되지 않습니다'}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={processSelect}/>}

      {dataModal.menu === 'back' &&
        <SelectConfirmModal statusText={dataModal.message}
                            viewType={true}
                            noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                            clickEvent={() => {
                              removeCheckedFunction(setRowList , rowList , setCheckedList , checkedList , 'id')
                              setDataModal(initModal)
                              setIconSelect(- 1)
                              //바인딩할때 삭제해야함
                              setPauseList(pauseList.map((v: any) => {return v.processPauseCode === selectedPauseCode ? {...v , ...selectPauseData} : v}))
                            }}
                            cancelEvent={() => {setDataModal(initModal)}}/>}

      {dataModal.menu === 'modify' &&
        <SelectConfirmModal statusText={dataModal.message}
                            noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                            cancelEvent={() => setDataModal(initModal)}
                            clickEvent={pauseTypeUpdate}/>}

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 일시정지 유형 관리'} unitCase={'MenuTitle'}/>
          </div>
        </div>

        {/*공정리스트 ,일시정지 유형 관리 */}
        <ContentFrame flexDirection={'row'} height={'680px'}>
          <div style={{display: 'flex' , width: '360px' , flexDirection: 'column'}}>
            <MenuTitle title={'공정 리스트'} unitCase={'MenuCompTitle'}/>
            {/*<InfiniteScroll next={() => setPage((prev) => prev + 1)} scrollThreshold={'50px'}*/}
            {/*                hasMore={totalPage > page} scrollableTarget={'grid-scroll'}*/}
            {/*                dataLength={dataValid(pauseTypeList?.length , 0)}*/}
            {/*                loader={<div/>}>*/}
              <GridTableComponent tempCol={'80px 1fr'} scrollWidth={350} id={'grid-scroll'}
                                  height={538}
                // height={listHeightValid(authValid,{total: totalPage, page: page},true)}
                                  scrollX_Hide={true}>
                <div className={'grid-title-unchecked'}>
                  {['No.' , '공정명'].map((name) => (
                    <div key={name}>{t(name)}</div> ))}
                </div>

                {!selectedCompany.companyCode ?
                  listResultMessage(authValid() , '* 업체를 선택해 주세요.' , {width: 350 , admin: 480 , user: 480})
                  :
                  pauseTypeList?.length === 0 ?
                    listResultMessage(authValid() , ' * 공정 종류를 등록해 주세요.' , {width: 350 , admin: 480 , user: 480})
                    :
                    buttonAuthValid('read' , '13') ?
                      listResultMessage(authValid() , ' * 해당 아이디에 접근 권한이 없습니다.' , {width: 350 , admin: 480 , user: 480})
                      :
                      pauseTypeList.map((v , i) => (
                        <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                         selected={v.processName === selectedPauseType.processName}
                                         onClick={() => {
                                           if(rowList.length >= 1 || iconSelect !== - 1){
                                             setProcessIndex(i)
                                             setDataModal({menu: 'alert' , message: t('유형을 등록 중 입니다. \n 다른 공정을 선택 하시겠습니까?')})
                                           }
                                           else if(changeIndex){
                                             setProcessIndex(i)
                                             setDataModal({menu: 'alert' , message: '유형 순서가 변경 되었습니다. \n 다른 공정을 선택 하시겠습니까?'})
                                           }
                                           else{
                                             setSelectedPauseType(v);
                                             setSelectedPauseCode('')
                                             setRowList([])
                                             setCheckedList([])
                                             setBindIndex(-1)
                                             pauseTypeListRes(v.processCode).then();
                                           }
                                         }
                                         }>
                          {validationValue(( ( i + 1 ) + page * 10 ) - 10 , 0)}
                          {tooltipModule(dataValid(v.processName) , 15)}
                        </GridTableSelect>
                      ))}
              </GridTableComponent>
            {/*</InfiniteScroll>*/}

            <PaginationList limit={10} setBlockNum={setPageBlock} blockNum={pageBlock} pageLimit={10}
                            setPage={setPage} counts={totalPage} page={page} disabled={iconSelect !== - 1}/>
          </div>

          <div
            style={{width: '1px' , backgroundColor: '#E6E9F4' , height: '570px', margin: '48px 20px 32px 20px'}}/>

          <div style={{display: 'flex' , width: '808px' , flexDirection: 'column'}}>
            <div
              style={{display: 'flex' , flexDirection: 'row' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <div style={{display: 'flex' , alignItems: 'center'}}>
                <MenuTitle title={'일시정지 유형'} unitCase={'MenuCompTitle'}/>
                <div
                  style={{width: '410px' , overflow: 'hidden' , textOverflow: 'ellipsis' , whiteSpace: 'nowrap' , fontSize: '18px' , fontWeight: 700 , marginLeft: '8px'}}>
                  {tooltipModule(!selectedPauseType.processName ? '' : `(${selectedPauseType.processName})` , 15)}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <MainButtons name={'행추가'} width={'80px'}
                             disabled={buttonAuthValid('create' , '15') || selectedPauseType.processCode === undefined || ( rowList.length === 0 && checkedList.length >= 1 )}
                             clickEvent={addListRow}/>
                <MainButtons name={'저장'} width={'80px'} buttonType={'cta'}
                             disabled={buttonAuthValid('create' , '15') || rowList.length === 0 || checkedList.length === 0}
                             clickEvent={() => {
                               if(checkedList.length === rowList.length){
                                 pauseTypeRegisterRes().then()
                               } else{
                                 setDataModal({menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'})
                               }
                             }}/>
                <MainButtons name={'삭제'} width={'80px'} marginDis={true} buttonType={'delete'}
                             disabled={
                               buttonAuthValid('rowDelete' , '15') ?
                                 rowList.length === 0
                                 :
                                 buttonAuthValid('delete' , '15') ||
                                 exceptPermanentProcess?.length === 0}
                             clickEvent={deleteRowEvent}/>
              </div>
            </div>

            <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                                ref={scrollRef} scrollWidth={798}
                                height={538}
                                tempCol={'40px 60px 480px 1fr'}>
              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox" checked={checkedList.length === checkboxValid().list.length}
                         style={{pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` , opacity: `${iconSelect === - 1 ? 1 : 0.5}`}}
                         onChange={(e) => allCheckedFunction(e.target.checked , checkboxValid().list , checkboxValid().id , setCheckedList)}/>
                </label>
                {['순서' , '일시정지 유형'].map((name) => (
                  <TableValue title={name} viewType={rowList.length !== 0 || iconSelect !== - 1}
                              requiredValue={['일시정지 유형']}/>
                ))}
                <div style={{position:'absolute', display:'flex', alignItems:'center', top:0, right:0}}>
                  <ArrowButton disabled={iconSelect !== -1 || rowList?.length > 0 || pauseList?.length <= 1 || bindIndex === -1}
                    onClick={() => positionChangeList( bindIndex , 'bottom' )}>
                    {'>'}
                  </ArrowButton>
                  <ArrowButton disabled={iconSelect !== -1 || rowList?.length > 0 || pauseList?.length <= 1|| bindIndex === -1}
                    onClick={() => positionChangeList( bindIndex , 'top' )}>
                    {'<'}
                  </ArrowButton>
                  <MainButtons name={'순서 고정'} disabled={!changeIndex}
                               clickEvent={pauseTypeIndexUpdate}/>
                  <div style={{
                    fontSize: '12px' , margin:'0 8px',
                    color: 'rgba(23, 23, 37, 0.5)' , lineHeight: '48px'
                  }}>{t('* 각 공정별 저장 필수')}
                  </div>
                </div>
              </div>

              {!selectedCompany.companyCode ?
                listResultMessage(authValid() , t('* 업체를 선택해 주세요.') , {width: 798 , admin: 480 , user: 480})
                :
                rowList.length === 0 && !selectedPauseType.processCode ?
                  listResultMessage(authValid() , t('* 공정을 선택해 주세요.') , {width: 798 , admin: 480 , user: 480})
                  :
                  rowList.length === 0 && pauseList.length === 0 ?
                    listResultMessage(authValid() , t('* 일시정지 유형을 등록해 주세요.') ,
                                      {width: 798 , admin: 300 , user: 480} , addListRow , buttonAuthValid('create' , '15'))
                    :
                    rowList.map((v , i) => (
                      <div className={'grid-row-list'}>
                        <label className={'checkbox'}>
                          <ListInnerInput type={'check'} name={'id'}
                                          onChange={(e: any) => singleCheckedFunction(e.target.checked , v.id , setCheckedList , checkedList)}
                                          checked={checkedList.includes(v.id)}/>
                        </label>
                        {validationValue(i + 1 , 0)}
                        <ListInnerInput type={'text'} width={'400px'} placeholder={'일시정지 유형'}
                                        inputData={v.processPauseName}
                                        name={'processPauseName'}
                                        onChange={(e) => {
                                          onChangeArray(i , e , rowList , setRowList)
                                          if(!checkedList.includes(v.id)){
                                            setCheckedList(checkedList.concat(v.id))
                                          }
                                        }}/>
                      </div>
                    ))}

              {pauseList.map((v: any , i: number) => (
                <React.Fragment>
                  {iconSelect !== i ?
                    <GridTableSelect selected={v.processPauseCode === selectedPauseCode}
                                     disabled={rowList.length !== 0 || iconSelect !== - 1}
                                     checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                     className={'grid-list'} onClick={() => {
                                        setSelectedPauseCode( v.processPauseCode )
                                        setBindIndex( i )
                                     }}
                                     onMouseOut={() => setFocusList(- 1)} onMouseOver={() => setFocusList(i)}>
                      <label className={'selectCheckbox'}>
                        <ListInnerInput type={'check'} name={'processPauseCode'} style={{opacity: v.processPauseName === '일시정지'? 0.5 : 1 }}
                                        onChange={(e: any) => {
                                          v.processPauseName === '일시정지'?
                                          setDataModal( {menu:'warn' , message: '"일시정지" 유형명은 기본 유형이므로 삭제 할 수 없습니다.'})
                                          :
                                          singleCheckedFunction( e.target.checked , v.processPauseCode , setCheckedList , checkedList )
                                        }}
                                        checked={v.processPauseName === '일시정지'? false : checkedList.includes(v.processPauseCode)}/>
                      </label>
                      {validationValue(i + dataValid(rowList?.length,0) + 1 , 0)}
                      {v.processPauseName === '일시정지'?
                        <div style={{display:'flex',  alignItems:'center', flexDirection:'row', justifyContent:'space-between'}}>
                          {'일시정지'}
                          <span style={{marginLeft:'8px', fontSize:'12px', color:'#F0142F'}}>{'* 일시정지 기본 유형명 입니다.'}</span>
                        </div>
                        :
                        validationValue(v.processPauseName)}
                      {
                        <div style={{backgroundColor: v.processPauseName === '일시정지' ? '#F0F0F0' : ''}}>
                          <div className={'pencil-icon'}>
                          {(i === focusList && v.processPauseName !== '일시정지') &&
                              <img src={hoverIcon} width={buttonAuthValid( 'update' , '15' ) ? 0 : 24} alt=""
                                   onClick={( e ) => {
                                     e.stopPropagation();
                                     setSelectedPauseCode( v.processPauseCode )
                                     setIconSelect( i );
                                     setCheckedList( [v.processPauseCode] )
                                     setSelectPauseData( {...v} )
                                   }}
                                   onMouseOut={() => setHoverIcon( modify )}
                                   onMouseOver={() => setHoverIcon( modifyHover )}/>
                          }
                          </div>
                        </div>
                      }
                    </GridTableSelect>
                    :
                    <GridTableSelect className={'grid-list'} selected={true} checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}>
                      <label className={'selectCheckbox'}>
                        <ListInnerInput type={'check'} name={'processPauseCode'}
                                        onChange={(e: any) => singleCheckedFunction(e.target.checked , v.processPauseCode , setCheckedList , checkedList)}
                                        checked={checkedList.includes(v.processPauseCode)}/>
                      </label>
                      {validationValue(i + 1 , 0)}
                      <ListInnerInput type={'text'} width={'400px'} placeholder={'일시정지 유형'}
                                      inputData={v.processPauseName}
                                      name={'processPauseName'}
                                      onChange={(e) => {onChangeArray(iconSelect , e , pauseList , setPauseList)}}/>
                      <div className={'select-icon'} onClick={(e) => e.stopPropagation()}>
                        <img src={cancelIcon} width={24} alt=""
                             onClick={() => {setDataModal({menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                        <img src={checkIcon} width={24}
                             onClick={() => {
                               if(!v.processPauseName){
                                 setDataModal({menu: 'confirm' , message: t('일시정지 유형명을 입력해 주세요.')})
                               } else{
                                 setDataModal({menu: 'modify' , message: '이대로 수정하시겠습니까?'})
                               }
                             }} alt=""/>
                      </div>
                    </GridTableSelect>
                  }
                </React.Fragment>
              ))}
            </GridTableComponent>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default PauseTypeManagement;


const ArrowButton = styled.div<{disabled?: boolean}>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;
  border: 1px solid #D7DBEC;
  text-align: center;
  transform: rotate(90deg);
  font-weight: 700;
  cursor: pointer;
  margin: 0 8px;
  color: #5A607F;
  background-color: #fff;
  opacity: ${props => props.disabled? 0.5 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  &:hover {
    border: 1px solid #0058ff;
    color: #0058ff;
    background-color: rgba(0, 88, 255, 0.1);
  }
`
