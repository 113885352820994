import React , {useState} from 'react';
import {useRecoilState , useRecoilValue , useResetRecoilState} from "recoil";
import {
  calendarAfterDatePersist ,
  calendarDatePersist ,
  calendarRangeEndDate ,
  calendarRangeStartDate , clonePersistCompany ,
  companyAuthorities ,
  documentSDMCode ,
  documentSDMList , menuSelectedSolution , menuSelectedSubMenu ,
  moldRequestReg ,
  orderSelectReg ,
  persistBlockPage ,
  persistCompanyData , persistContainerMove ,
  persistFacilityData ,
  persistMoldData ,
  persistPage ,
  persistProblemTypeData ,
  persistProductData , processSelectCompany , scrollToPosition ,
  searchInputState , searchPersistState ,
  selectedCode ,
  selectViewType ,
  statistics_calendar_button
} from "../../common/StateManage";
import {affiliateMenu , categoryMenu , userCategory} from "../../common/DataSet";
import {affiliateAuth , authValid , dataValid , initModal} from "../../function/function";
import ArrowButton from "../button/ArrowButton";
import styled from "styled-components";
import setting from "../../asset/image/ic_admin_setting.svg";
import noImage from "../../asset/image/noImage.svg";
import {Modal_View_Type} from '../../types/Fundamental'
import ToastPopup from '../modal/ToastPopup'
import client from '../../common/Config'
import {Authorization} from '../../common/authorization'
import {useLocation} from 'react-router-dom'

const IntegratedSideMenu = () => {
  const resetPage = useResetRecoilState(persistPage)
  const resetPageBlock = useResetRecoilState(persistBlockPage)
  const resetSelectedCode = useResetRecoilState(selectedCode)
  const resetClone = useResetRecoilState(clonePersistCompany)
  const resetSelectViewType = useResetRecoilState(selectViewType)
  const resetMoldReqData = useResetRecoilState(moldRequestReg)
  const resetFacilityData = useResetRecoilState(persistFacilityData)
  const resetProductData = useResetRecoilState(persistProductData)
  const resetMoldData = useResetRecoilState(persistMoldData)
  const resetMoldProblemType = useResetRecoilState(persistProblemTypeData)
  const resetCalendarData = useResetRecoilState(calendarDatePersist)
  const resetAfterCalendarData = useResetRecoilState(calendarAfterDatePersist)
  const resetSolutionType = useResetRecoilState(persistProblemTypeData)
  const resetSelectSDMCode = useResetRecoilState(documentSDMCode)
  const resetFolderList = useResetRecoilState(documentSDMList)
  const resetOrderReg = useResetRecoilState(orderSelectReg)
  const resetRangeStartDate = useResetRecoilState(calendarRangeStartDate)
  const resetRangeEndDate = useResetRecoilState(calendarRangeEndDate)
  const resetCalenderButton = useResetRecoilState(statistics_calendar_button)
  const [,setSearchInput] = useRecoilState(searchInputState)
  const resetSelectedCompany = useResetRecoilState(persistCompanyData)
  const resetContainerMove = useResetRecoilState(persistContainerMove)
  const resetSearchPersistState = useResetRecoilState(searchPersistState)
  const resetSelectedProcessCompany = useResetRecoilState(processSelectCompany)

  const resetRecoilState = () => {
    setSearchInput('')
    resetPage()
    resetMoldReqData()
    resetPageBlock()
    resetSelectedCode()
    resetSelectViewType()
    resetSelectedCode()
    resetCalendarData()
    resetAfterCalendarData()
    resetSolutionType()
    resetSelectSDMCode()
    resetFolderList()
    resetFacilityData()
    resetProductData()
    resetClone()
    resetMoldData()
    resetMoldProblemType()
    resetOrderReg()
    resetCalenderButton()
    resetRangeStartDate()
    resetRangeEndDate()
    resetContainerMove()
    resetSearchPersistState()
    resetSelectedProcessCompany()
  }

  const [selectSolution, setSelectSolution] = useRecoilState<string>(menuSelectedSolution)
  const [selectSubMenu, setSelectSubMenu] = useRecoilState<{title:string, length:number}>(menuSelectedSubMenu)
  const [scrollPosition, setScrollPosition] = useRecoilState<number>(scrollToPosition)

  const location = useLocation()
  const menuData = affiliateAuth() ? affiliateMenu : authValid() ? categoryMenu : userCategory
  const solutionFilter = dataValid(window.localStorage.getItem('solution'))
  const accountAuth = dataValid(window.localStorage.getItem('auth'))
  // 메인화면, Home은 기본 체크됨
  // const systemPermissions = dataValid(window.localStorage.getItem('system')+ ',메인화면,Home').split(',')
  const storedData = window.localStorage.getItem('authoritiesList')!;
  const menuItem =  storedData ? JSON.parse(storedData) : null;
  const menuNoItem = menuItem?.map((v:any)=>(v.menu))

  const solutionAuthCheck = (accountAuth.includes('company') || accountAuth.includes('system'))
  const systemAuthCheck = !(accountAuth.includes('company') || accountAuth === 'admin' || accountAuth === 'affiliate')
  const authorities = useRecoilValue(companyAuthorities)
  const menuRef = React.useRef<any>(null)
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [alarmState, setAlarmState] = useState<{solution:number, wiki:number}>({solution:0, wiki:0})

  const afterServiceTeamID = ['dnjsgh4546','ksj3191','ksj4420','minsoo1234','limchulkyun','dbwlgns777','zesone','xiaonian']

  // wiki, solution의 새로운 카운트 알람을 가져오기 위한 기능
  let requestCount = 0
  const noticeAlarmRes = async () => {
    try {
      requestCount++
      const response = await client.get('/alarm/notice/count',{headers:{Authorization}})
      setAlarmState(response.data.data)
    }
    catch (e) {
      console.log(e)
    }
    finally {
      requestCount--
    }
  }

  React.useEffect(()=>{
    noticeAlarmRes().then(()=>{
      if(requestCount === 0){
        const refresh = setInterval(()=>{
          noticeAlarmRes().then()
        },10000)
        return ()=>{clearInterval(refresh)}
      }
    })
  },[])

  const pathRoute = (link?: string) => {
    setScrollPosition(menuRef.current.scrollTop)
    if(link){
      if(link === 'not'){
        setDataModal({menu:'warn', message:'서비스 준비 중인 메뉴 입니다.'})
      }
      else{
        resetRecoilState()
        if(!(authorities?.includes(selectSolution) || selectSolution === 'MES' || selectSolution === 'V-MS')){
          resetSelectedCompany()
        }
        if(window.screen.width <= 1024){
          if(window.innerWidth >= 1024){
            window.location.href = link
          }
          else{
            if((afterServiceTeamID.some((v)=> window.localStorage.getItem('companyID') === v))){
              if((link === '/admin/pbas/managelog' || link === '/admin/main' || link === '/admin/fas/facility' ||
                link === '/admin/fas/business' || link === '/admin/fas/basic' || link === '/admin/pms/monitoring_system' ||
                link === '/admin/pms/operate' || link === '/admin/pms/loadton' || link === '/admin/pms/error' ||
                link === '/admin/pms/parameter' || link === '/admin/pms/cam' || link === '/admin/pms/clutch_brake' ||
                link === '/admin/pms/repair_reg_facility' || link === '/admin/pms/repair_list_facility' ||
                link === '/admin/pms/facility_complete' || link === '/admin/pms/cancel_facility_complete_admin' ||
                link === '/admin/pms/facility_problem'
              )){
                window.location.href = link
              }
              else{
                setDataModal({menu:'warn', message:'현재 접속중인 기기에서는 해당 기능을 사용할 수 없습니다.'})
              }
            }
            else{
              if((link === '/admin/pbas/managelog' || link === '/admin/fas/facility' || link === '/admin/main' ||
                link === '/admin/fas/business' || link === '/admin/fas/basic' || link === '/admin/pms/monitoring_system')){
                window.location.href = link
              }
              else{
                setDataModal({menu:'warn', message:'현재 접속중인 기기에서는 해당 기능을 사용할 수 없습니다.'})
              }
            }
          }
        }
        else{
          window.location.href = link
        }
      }
    }
  }

  // 현재 스크롤의 위치 고정 시킴
  React.useEffect(()=>{
    menuRef.current.scrollTop = scrollPosition
  },[])

  return (
    <React.Fragment>
      {
        dataModal.menu === 'warn' &&
        <ToastPopup text={dataModal.message} state={dataModal.menu}
                    closeEvent={()=>setDataModal(initModal)}/>
      }

      <SideMenu>
        {
          authValid()?
            <div className={'adminLogo'} >
              <img src={setting} alt="" width={25} height={25} style={{cursor:'pointer'}}/>
              <div style={{fontSize:'20px', fontWeight:800, color:'#0039A4',marginLeft:'9px'}}>{'ADMIN MODE'}</div>
            </div>
            :
            <div className={'companyLogo'}>
              <div className={'logoImage'}
                   style={{backgroundImage: `url(${!window.localStorage.getItem('logo') ? noImage : window.localStorage.getItem('logo')})`,}}/>
              <div style={{fontWeight:400}}>{window.localStorage.getItem('company')}</div>
            </div>
        }
        <div className={'solution-menu'} ref={menuRef}
             onChange={(e)=>e.preventDefault()}>
          {menuData.map((v)=>(
            <div key={v.title} style={{backgroundColor:selectSolution === v.title? 'rgba(0,88,255,0.05)' : '#fff'}}>
              <MainSolution className={'main-solution'}
                            alarm={authValid() && ((v.title === 'FAS') && (alarmState.wiki !== 0 || alarmState.solution !== 0))}
                // disabled={!systemAuthCheck? (!(solutionFilter.includes(v.title) || v.title === 'MES') || !v.subTitle) : !v.subTitle}
                            disabled={solutionAuthCheck? (!(solutionFilter?.includes(v.title) || v.title === 'MES') || !v.subTitle) : !v.subTitle}
                            onMouseDown={()=> {
                              setSelectSolution( selectSolution === v.title ? '' : v.title )
                              setSelectSubMenu(selectSolution === v.title ? selectSubMenu : {title:'', length:0})
                            }}>
                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                  <img style={{padding: v.title === 'I-MES' ? '0 12px' : '0 16px'}} src={v.icon} className={'logo'} alt={''}/>
                  <div>{v.title}</div>
                </div>
                <ArrowButton arrowDirection={selectSolution === v.title? 'top' : 'bottom'}/>
              </MainSolution>
              <div style={{transition:'all 0.3s', height: `${selectSolution === v.title? (Number(v.subTitle?.length) + selectSubMenu.length)*52 : 0}px`, overflow:'hidden'}}>
                {v.subTitle?.map((sub:any)=>(
                  <div key={sub.title + sub.link} style={{backgroundColor:selectSubMenu.title === sub.title? 'rgba(0,88,255,0.05)' : 'transparent'}}>
                    <SubMenuTitle className={'subMenu-title'} style={{color: location.pathname === sub.link? '#0058ff' : '#171725'}}
                                  alarm={
                                    // 지정된 타이틀에서만 alarmState의 조건이 들어가도록 함.
                                    authValid() && (sub.title === '게시판 관리' ? alarmState.wiki !== 0 :
                                      sub.title === '솔루션 요청관리'? alarmState.solution !== 0 : false)}
                                  disabled={
                                    (systemAuthCheck &&
                                      (!sub.subMenu? ((!menuNoItem?.includes(sub.menuNo))) : !sub.subMenu?.some((menu:any)=>(menuNoItem?.includes(menu.menuNo)))))}
                      // onClick={()=> pathRoute( sub.link )}
                                  onMouseDown={()=> {
                                    setSelectSubMenu( selectSubMenu.title === sub.title ? {title: '' , length: 0} : {title: sub.title , length: dataValid( Number( sub.subMenu?.length ) , 0 )} )
                                    pathRoute( sub.link )
                                  }}>
                      <div>{sub.title}</div>
                      {sub.subMenu && <ArrowButton arrowDirection={sub.title === selectSubMenu.title? 'top' : 'bottom'}/>}
                    </SubMenuTitle>
                    <div style={{height: `${sub.title === selectSubMenu.title? (selectSubMenu.length*52) : 0}px`, overflow:'hidden',transition:'all 0.3s'}}>
                      {sub.subMenu?.map((detail:any)=>(
                        <DetailMenuTitle key={detail.title + detail.link}
                                         alarm={authValid() && (detail.title === 'WIKI 관리'? alarmState.wiki !== 0 : false)}
                                         onClick={()=>pathRoute(detail.link)} selected={detail.link === location.pathname}
                                         disabled={systemAuthCheck && !menuNoItem?.includes(detail.menuNo)}
                                         className={'detailMenu-title'}>{'- '+detail.title}</DetailMenuTitle>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {
            authValid() &&
            <div style={{backgroundColor: location.pathname === '/admin/smartlog'? 'rgba(0,88,255,0.05)' : '#fff'}}>
              <MainSolution className={'main-solution'}
                            onClick={()=>window.location.href = '/admin/smartlog'}>
                <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                  <img style={{padding:'0 16px'}} src={setting} className={'logo'} alt={''}/>
                  <div>{'스마트 공장 로그'}</div>
                </div>
              </MainSolution>
            </div>
          }
          {
            authValid() &&
              <div style={{backgroundColor: location.pathname === '/admin/rankpage'? 'rgba(0,88,255,0.05)' : '#fff'}}>
                <MainSolution className={'main-solution'}
                              onClick={()=>window.location.href = '/admin/rankpage'}>
                  <div style={{display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                    <img style={{padding:'0 16px'}} src={setting} className={'logo'} alt={''}/>
                    <div>{'금주 접속페이지 순위'}</div>
                  </div>
                </MainSolution>
              </div>
          }
        </div>
      </SideMenu>
    </React.Fragment>
  );
};
export default IntegratedSideMenu;

const SideMenu = styled.div`

  min-width: 250px;
  box-sizing: border-box;
  //padding-right: 20px;
  user-select: none;
  .adminLogo{
    width: 250px;
    height: 68px;
    box-shadow: 0 1px 4px #15223214;
    background-color: #CFFA61;
    display: flex;
    align-items: center;
    padding: 25px;
    box-sizing: border-box;
  }

  .companyLogo{
    display: flex;
    height: 68px;
    background-color: #0039a4;
    .logoImage{
      display: block;
      width: 50px;
      height: 32px;
      margin: 10px;
      align-self: center;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &>div:nth-child(2){
      letter-spacing: 0.07px;
      font-size: 18px;
      color: #fff;
      line-height: 68px;
      font-weight: 800;
      margin: 0 3px;
    }
  }

  .solution-menu {
    overflow-y: scroll;
    max-height: calc(100vh - 68px);
    width: inherit;
    font-weight: 700;
    font-size: 14px;
    box-sizing: border-box;
    padding-right: 16px;
    .main-solution {
      &:hover {
        background-color: rgba(0, 88, 255, 0.05);
        color: #0058ff;
      }
    }

    .subMenu-title {
      &:hover {
        background-color: rgba(0, 88, 255, 0.05);
        color: #0058ff;
      }
    }

    .detailMenu-title {
      &:hover {
        background-color: rgba(0, 88, 255, 0.05);
        color: #0058ff;
      }
    }
  }
`

const MainSolution = styled.div<{disabled?: boolean, alarm?: boolean}>`
  display: flex;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  font-size: 14px;
  box-sizing: border-box;
  padding-right: 20px;
  font-weight: 700;
  user-select: none;
  overflow: hidden;
  //border-bottom: 1px solid #d5d7e3;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  cursor: pointer;
  position: relative;
  &::before{
    content:'';
    position: absolute;
    top: calc(50% - 4px);
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.alarm? '#F0142F' : 'transparent'};
  }
`
const SubMenuTitle = styled.div<{disabled?:boolean, alarm?: boolean}>`
  display: flex;
  height: 52px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  position: relative;
  &::before{
    content:'';
    position: absolute;
    top: calc(50% - 4px);
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.alarm? '#F0142F' : 'transparent'};
  }
`

const DetailMenuTitle = styled.div<{disabled?:boolean, selected?:boolean, alarm?: boolean}>`
  display: flex;
  height: 52px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  padding-left: 24px;
  opacity: ${props => props.disabled? 0.3 : 1};
  pointer-events: ${props => props.disabled? 'none' : 'auto'};
  color: ${props => props.selected? '#0058ff' : '#171725'};
  background-color: ${props => props.selected? 'rgba(0,88,255,0.05)' : 'transparent'};
  position: relative;
  &::before{
    content:'';
    position: absolute;
    top: calc(50% - 4px);
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${props => props.alarm? '#F0142F' : 'transparent'};
  }
`
