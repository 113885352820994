import React , {useRef , useState} from 'react';
import {
  ContentFrame ,
  NoData ,
  SectionFrame , validationValue
} from "../../../../style/StyleComponent";
import MenuTitle from "../../../../components/title/MenuTitle";
import {useTranslation} from "react-i18next";
import SearchBar from "../../../../components/input/SearchBar";
import MainButtons from "../../../../components/button/MainButtons";
import checked from '../../../../asset/image/check-icon.svg';
import SelectEventButton from "../../../../components/button/SelectEventButton";
import styled from 'styled-components'
import {
  authValid ,
  buttonAuthValid ,
  dataValid ,
  initError ,
  listLoadingCircle ,
  loadingRemove ,
} from "../../../../function/function";
import client from "../../../../common/Config";
import {Authorization} from "../../../../common/authorization";
import {Error_Type} from "../../../../types/Fundamental";
import {
  persistBlockPage ,
  persistCompanyData ,
  persistContainerMove , persistPage , searchPersistState ,
  selectedCode
} from "../../../../common/StateManage";
import dayjs from "dayjs";
import SolutionCompanyFilterList from "../../../../components/modal/SolutionCompanyFilterList";
import {useRecoilState} from "recoil";

import BizCalendar  from "./BizCalendar";
import PaginationList from "../../../../components/pagination/PaginationList";
import {GridTableComponent, GridTableSelect} from "../../../../style/StyleModule";

const BusinessManagement = () => {
  const {t}:any = useTranslation();
  const [containerMove,setContainerMove] = useRecoilState(persistContainerMove);
  const [selectCompany,setSelectCompany] = useRecoilState(persistCompanyData);
  const [selectCode, setSelectCode] = useRecoilState(selectedCode)
  const [businessData,setBusinessData] = useState<any[]>([]);
  const [page,setPage] = useRecoilState<number>(persistPage);
  const [pageBlock, setPageBlock] = useRecoilState(persistBlockPage)
  const [totalPage,setTotalPage] = useState<number>(0);
  // 검색어 입력과 적용을 하나의 object로 적용한 state, 페이지 이동후에도 유지 되어야 하기 때문에 recoilState 사용
  const [searchState, setSearchState] = useRecoilState<{input:string, value:string}>(searchPersistState)
  // const [searchValue,setSearchValue] = useState<string>('');
  // const [pushValue, setPushValue] = useState<string>('');

  const [errorState,setErrorState] = useState<Error_Type>(initError)
  const allMenuList = ['NO.','업체명','담당자','제목','작성자','방문날짜','등록날짜']
  const companyMenuList = ['NO.','제목','작성자','방문날짜','등록날짜']
  const scrollRef = useRef<HTMLDivElement>(null);

  const businessListRes = async () =>{
    listLoadingCircle()
    try {
      const response = await client.get(`/business/management/list/${page}/10/${dataValid(containerMove === '전체' ? '@' : dataValid(selectCompany.companyCode,'@'))}/@`,{
        headers:{Authorization}
      })
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setBusinessData([])
      }else{
        setTotalPage(data.total_page);
        setErrorState({status:valid.status,notice:''})
        setSearchState({input:'', value:''})
        setBusinessData(data.row)
        // if(data.total_page > 1 && page > 1){
        //   setBusinessData((prev:any)=> prev.concat(data.row))
        // }else{
        //   setBusinessData(data.row)
        // }
      }
      loadingRemove()
    }catch ( e:any ) {
      console.log(e)
      setErrorState({status:e.status,notice:'* 관리자에게 문의해주세요.'})
      loadingRemove()
    }
  }

  const businessSearchListRes = async () =>{
    listLoadingCircle()
    try {
      const response = await client.get(`/business/management/search/${page}/10/${dataValid(containerMove === '전체' ? '@' : selectCompany.companyCode,'@')}/@/${dataValid(searchState.value,'@')}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      const valid = response.data
      if(valid.status !== 200){
        setErrorState({notice:valid.message,status:valid.status})
        setBusinessData([])
      }else{
        setTotalPage(data.total_page);
        setErrorState({status:valid.status,notice:''})
        setBusinessData(data.row)
        // setPage(1)
        // setPageBlock(0)
        // if(data.total_page > 1 && page > 1){
        //   setBusinessData((prev:any)=> prev.concat(data.row))
        // }else{
        //   setBusinessData(data.row)
        // }
      }
      loadingRemove()
    }catch ( e:any ) {
      console.log(e)
      setErrorState({status:e.status,notice:'* 관리자에게 문의해주세요.'})
      loadingRemove()
    }
  }

  React.useEffect(()=>{
    if(containerMove !== '달력'){
      if(!searchState.value){
        businessListRes().then()
      }else{
        businessSearchListRes().then()
      }
    }
  },[containerMove, searchState.value, page, selectCompany])

  const listResetFunc = () => {
    setSearchState({input:'', value:''})
    setPageBlock(0)
    setPage(1)
  }
  return (
    <React.Fragment>
      <SectionFrame>
        <div style={{display:"flex",justifyContent:'space-between'}}>
          <InnerContainer style={{display:'flex',alignItems:'center'}}>
            <MenuTitle title={t('영업관리')} unitCase={'MenuTitle'}/>
            {/*{containerMove !== '전체' &&*/}
            {/*    <div style={{display:'flex',alignItems:'center',pointerEvents:'none',opacity:0.5,margin:'47px 0 35px 28px'}}>*/}
            {/*      <input type="checkbox" style={{cursor: 'pointer'}} id={'responsibilities'} onChange={()=>{''}}/>*/}
            {/*      <label style={{userSelect:'none',fontSize:'14px'}} htmlFor={'responsibilities'}>{'나의 담당업체만 보기'}</label>*/}
            {/*    </div>*/}
            {/*}*/}
          </InnerContainer>
          <div style={{display:'flex', alignItems:'center'}}>
            {['전체','업체별','달력'].map((v)=>(
              <SelectEventButton buttonName={v} key={v}
                                 buttonEvent={()=> {setContainerMove(v);
                                   setPage(1);
                                   setPageBlock(0)
                                   if(v === '달력'){
                                     setSelectCompany({})
                                     setSearchState({input:'', value:''})
                                     // setPushValue('')
                                     // setSearchValue('')
                                   }
                                   // if(searchValue === ''){
                                   //   setPushValue('')
                                   // }
                                 }}
                                 buttonStatus={containerMove === v} buttonBorder={true}/>
            ))}
          </div>
        </div>
        {containerMove.includes('전체') &&

            <ContentFrame>
              {/*//상단 타이틀*/}
              <InnerContainer>
                <MenuTitle title={t('영업관리 리스트')} unitCase={'MenuCompTitle'}/>
                <div style={{display:'flex',alignItems:'center'}}>
                  {/*<div style={{display:'flex',alignItems:'center',pointerEvents:'none',opacity:0.5,marginRight:'8px'}}>*/}
                  {/*  <input type="checkbox" style={{cursor: 'pointer'}} id={'responsibilities'} onChange={()=>{''}}/>*/}
                  {/*  <label style={{userSelect:'none',fontSize:'14px'}} htmlFor={'responsibilities'}>{'나의 담당업체만 보기'}</label>*/}
                  {/*</div>*/}
                  <SearchBar placeholder={'검색'} keyFunction={(e)=>{setSearchState((prev)=>({...prev, input: e.target.value.trimStart()}))}}
                             clearButtonAccess={searchState.input !== ''} clearButton={listResetFunc} disabled={errorState.status !== 200}
                             clickEvent={()=>{
                               setSearchState((prev)=>({...prev, value: prev.input}));
                               setPage(1); setPageBlock(0)}} value={searchState.input}/>
                  <MainButtons name={'등록'} buttonType={'cta'}  width={'80px'} marginDis={true} disabled={buttonAuthValid('create','22')}
                               clickEvent={()=>{window.location.href =`/admin/fas/business/register`; setSelectCompany({})}}/>
                </div>
              </InnerContainer>
              {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
              {/*                next={()=>setPage((prev)=> prev+1)}*/}
              {/*                hasMore={totalPage > page} dataLength={dataValid(businessData?.length, 0)}*/}
              {/*                loader={<div/>}>*/}
              <GridTableComponent id={'grid-scroll'} tempCol={'80px 178px 128px 440px repeat(3, 128px)'} ref={scrollRef}
                                  scrollY_Hide={true}
                                  height={572} scrollWidth={1210} scrollX_Hide={true}>
                <div className={'grid-title-unchecked'}>{allMenuList.map((v)=>( <div key={v}>{t(v)}</div> ))}</div>
                {/*{businessData?.length === 0  ? pushValue !== '' ? errorState.status !== 200 ?*/}
                {/*    <NoData>{dataValid(errorState.notice,'* 등록된 영업이력이 없습니다.')}</NoData>*/}
                {/*    :*/}
                {/*    <NoData>{' * 검색 결과가 없습니다.'}</NoData>*/}
                {/*  :*/}
                {businessData?.length === 0 ?
                  <NoData>{dataValid(errorState.notice,'* 등록된 영업이력이 없습니다.')}</NoData>
                  :
                  <React.Fragment>
                    {businessData?.map((v:any,index:number)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       style={{pointerEvents: v.statement === 'cancel'? 'none' : 'auto'}}
                                       onClick={()=>{setSelectCode(v.businessManagementCode)}}
                                       selected={v.businessManagementCode === selectCode}
                                       onDoubleClick={()=> {
                                         window.location.href = `/admin/fas/business/${v.businessManagementCode}`
                                       }}>
                        {validationValue(v.no,index + 1)}
                        {validationValue(v.companyName)}
                        {validationValue(v.managerName)}
                        {validationValue(v.title)}
                        {validationValue(v.writer)}
                        {validationValue(dayjs(v.visitDate).format('YYYY.MM.DD'))}
                        {validationValue(dayjs(v.registrationDate).format('YYYY.MM.DD'))}
                        {/*<MainButtons name={'A/S요청'} marginDis={true} disabled={true}/>*/}
                      </GridTableSelect>
                    ))}
                  </React.Fragment>
                }

                {/*}*/}
              </GridTableComponent>
              {/*</InfiniteScroll>*/}
              {businessData?.length !== 0 &&
                  <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                  blockNum={pageBlock} setBlockNum={setPageBlock} counts={totalPage}/>
              }
            </ContentFrame>
        }
        {containerMove.includes('업체별') &&
            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr',gap:'16px'}}>
              <SolutionCompanyFilterList receivedData={(data)=>{setSelectCompany(data); setPage(1); setPageBlock(0)}}
                                         onlyList={true} requireCompanyData={false} affiliate={true}/>
              {/*<CompanySearchList companyDataReceive={setSelectCompany} viewType={true}/>*/}
              <ContentFrame width={'840px'}>
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <MenuTitle title={t('영업관리 리스트')} unitCase={'MenuCompTitle'}/>
                    {selectCompany && <div style={{color:'#0058FF',marginLeft:'8px'}}>{!selectCompany.companyCode ?  '(전체)' : '('+ selectCompany.companyName + ')'}</div>}
                  </div>
                  <div style={{display:'flex'}}>
                    <SearchBar placeholder={'검색'} keyFunction={(e)=>{setSearchState((prev)=>({...prev, input: e.target.value.trimStart()}))}}
                               clearButtonAccess={searchState.input !== ''} clearButton={listResetFunc} disabled={errorState.status !== 200}
                               clickEvent={()=>{
                                 setSearchState((prev)=>({...prev, value: prev.input}));
                                 setPage(1); setPageBlock(0)}} value={searchState.input}/>
                    {/*<SearchBar placeholder={'검색'} keyFunction={(e)=>{setSearchValue(e.target.value.trimStart())}}*/}
                    {/*           clearButtonAccess={searchValue !== ''} clearButton={businessListRes} disabled={errorState.status !== 200}*/}
                    {/*           clickEvent={()=>{setPushValue(searchValue); setPage(1)}} value={searchValue}/>*/}
                    <MainButtons name={'등록'} buttonType={'cta'} width={'80px'} marginDis={true} disabled={buttonAuthValid('create','22')}
                                 clickEvent={()=>window.location.href =`/admin/fas/business/register`}/>
                  </div>
                </div>
                {/*<InfiniteScroll scrollableTarget={'list_scroll'} scrollThreshold={'50px'}*/}
                {/*                next={()=>setPage((prev)=> prev+1)}*/}
                {/*                hasMore={totalPage > page} dataLength={dataValid(businessData?.length, 0)}*/}
                {/*                loader={<div/>}>*/}
                <GridTableComponent id={'list_scroll'} tempCol={'40px 336px repeat(3, 88px)'} ref={scrollRef}
                                    height={572}
                                    scrollWidth={770} scrollX_Hide={true}
                                    scrollY_Hide={true}>
                  <div className={'grid-title-unchecked'}>{companyMenuList.map((v)=>( <div key={v}>{t(v)}</div> ))}</div>
                  <React.Fragment>
                    {businessData?.length === 0 ? searchState.value === '' ? errorState.status !== 200 ?
                          <NoData>{errorState.notice}</NoData>
                          :
                          <NoData>{dataValid(errorState.notice,'* 등록된 영업이력이 없습니다.')}</NoData>
                        :
                        <NoData>{' * 검색 결과가 없습니다.'}</NoData>
                      :
                      businessData?.map((v:any,index:number)=>(
                        <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                         style={{pointerEvents: v.statement === 'cancel'? 'none' : 'auto'}}
                                         onClick={()=>{setSelectCode(v.businessManagementCode)}}
                                         selected={v.businessManagementCode === selectCode}
                                         onDoubleClick={()=> {
                                           window.location.href = `/admin/fas/business/${v.businessManagementCode}`
                                         }}>
                          {validationValue(v.no,index + 1)}
                          {validationValue(v.title)}
                          {validationValue(v.writer)}
                          {validationValue(dayjs(v.visitDate).format('YYYY.MM.DD'))}
                          {validationValue(dayjs(v.registrationDate).format('YYYY.MM.DD'))}
                        </GridTableSelect>
                      ))
                    }
                  </React.Fragment>
                </GridTableComponent>
                {/*</InfiniteScroll>*/}
                {businessData?.length !== 0 &&
                    <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                                    blockNum={pageBlock} setBlockNum={setPageBlock} counts={totalPage}/>
                }
              </ContentFrame>
            </div>
        }
        {containerMove.includes('달력') &&
            <ContentFrame width={'1270px'} height={'680px'}>
              <BizCalendar
                  containerMove={containerMove}
                  registerEvent={()=>{window.location.href =`/admin/fas/business/register`; setSelectCompany({})}}
              />
            </ContentFrame>
        }
      </SectionFrame>
    </React.Fragment>
  );
};
export default BusinessManagement;


const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  input[type='checkbox']{
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #D7DBEC;
    outline: none;
    box-sizing: border-box;
    margin-right: 8px;
    &:checked{
      border: transparent;
      background-color: #0058Ff;
      background-image: url(${checked});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`
