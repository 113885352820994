import React , {useRef , useState} from 'react';
import {useTranslation} from "react-i18next";
import {useRecoilValue} from "recoil";
import {
  allCheckedState ,
  allPersistCheckedFunction ,
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  decimalNumberValid ,
  filterCheckedRow ,
  handleDecimalChange , handleRowDelete ,
  initModal ,
  loadingRemove ,
  numberLocaleString ,
  onChangeNumberArray , onMultiRegChangeArray ,
  singleCheckedFunction
} from "../../../../../function/function";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import TableValue from "../../../../../style/TableValue";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import HorizontalMoveScrollButton from "../../../../../components/button/HorizontalMoveScrollButton";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const RawMaterialMultipleReg = () => {
  const {t}: any = useTranslation();
  const materialTitle = ['원자재 CODE' , '원자재 품명' , '재질' , '재질 종류' , '규격', '두께' , '가로(Coil 폭)' , '세로(Feeder)' , '안전 재고량' , '단위' , '거래처' , '사용 기준일'];
  const needValue = ['원자재 CODE' , '원자재 품명' , '단위']

  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [accountSearchModal , setAccountSearchModal] = useState<boolean>( false );
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [selectRowIndex , setSelectRowIndex] = useState<number>( 0 )


  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [selectedID, setSelectedID] = useState<number>(0)

  const scrollRef = useRef<any>( null )

  const initialObject = {
    companyCode: selectedCompany.companyCode ,
    rawMaterialSerialCode: '' ,
    rawMaterialName: '' ,
    material: '' ,
    thickness: '' ,
    width: '' ,
    length: '' ,
    rawMaterialUnit: '' ,
    materialType: '' ,
    rawMaterialStock: '' ,
    customer: '' ,
    customerName:'',
    standardDate: '' ,
    standard:'',
    codeDoubleCheck: false
  }


  const generateInitialRowList = Array.from( {length: 20} , ( _ , i ) => ( {
    ...initialObject ,
     id: i + 1
  } ) )


  const [rowList , setRowList] = useState<any[]>( generateInitialRowList )
  const [checkedList , setCheckedList] = useState<any>( [] )


  const rawMaterialRegister = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialSerialCode ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 코드를 입력해 주세요.'} )
    }

    else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 코드 중복 확인을 해주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialName ) ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 품명을 입력해 주세요.'} )
    } else if( ( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.rawMaterialUnit ) ) ){
      setDataModal( {menu: 'confirm' , message: '원자재 단위를 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/rawMaterial/register' , filterCheckedRow( rowList , checkedList , 'id' )
          , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: '원자재 정보가 등록 되었습니다.'} )
            loadingRemove()
            setRowList( generateInitialRowList)
            setCheckedList([])
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //원자재 시리얼코드 중복체크
  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = rowList.filter( ( v ) => ( v.rawMaterialSerialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/rawMaterial/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        rawMaterialSerialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        rawMaterialSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }

        if( duplicateCount[0] === 0 ){
          if( regCount !== 0 ){
            setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
            setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 원자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
          } else{
            setRowList( copyRowList )
            setDataModal( {menu: 'confirm' , message: '중복되지 않은 원자재CODE 입니다.'} )
          }
        } else{
          if( duplicateCount.length === 1 ){
            setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
          } else{
            if( regCount < duplicateCount.length ){
              setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
            } else{
              setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
            }
          }
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 원자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }

    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: '* 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }

  const concatCheckedID = checkedList.includes(selectedID)

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             const copyRowList = [...rowList]
                             copyRowList[checkIndex] = {
                               ...copyRowList[checkIndex] ,
                               codeDoubleCheck: true ,
                               rawMaterialSerialCode: inputSerialCode
                             }
                             setRowList( copyRowList )

                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {accountSearchModal &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {

                                  Object.assign( rowList[selectRowIndex] , {
                                    customer: accountCode.customerName ,
                                    customerName: accountCode.customerName
                                  } )
                                if(!concatCheckedID){
                                  setCheckedList(checkedList.concat(selectedID))
                                }
                                setAccountSearchModal( false )
                              }}
                              cancelEvent={() => {
                                setAccountSearchModal( false );
                                setAccountCode( {} )
                              }}
                              receiveData={setAccountCode}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )}
                        clickEvent={() => {
                          setDataModal( initModal )
                        }}/>}


      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={rawMaterialRegister}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={''}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                handleRowDelete(rowList, setRowList, checkedList, setCheckedList, initialObject)
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={() => {setDataModal( initModal )}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'rawMaterial'} type={'format'} disabled={buttonAuthValid( 'create' , '9' )}
                              companyData={selectedCompany} urlStyle={true}/>
              <ExcelApiButton category={'rawMaterial'} type={'upload'} disabled={buttonAuthValid( 'create' , '9' )}
                              companyData={selectedCompany}/>
            </React.Fragment>
          }/>
      }

      <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' }}>
        <MenuTitle title={'원자재 기본정보'} unitCase={'MenuCompTitle'}/>
        <div style={{display: 'flex'}}>
          <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>
          <MainButtons name={'삭제'} width={'80px'}
                       disabled={checkedList.length === 0}
                       clickEvent={()=>setDataModal({menu:'select', message:'선택한 행의 입력된 정보를\n 지우시겠습니까?'})}/>
          <MainButtons name={'저장'} buttonType={'cta'} width={'80px'} marginDis={true}
                       disabled={checkedList.length === 0}
                       clickEvent={() => {setDataModal( {menu: 'register' , message: '등록을 진행 하시겠습니까?'} )}}/>
        </div>
      </div>
      <GridTableComponent id={'grid-scroll'} ref={scrollRef}
                          scrollWidth={2016}
                          scrollY_Hide={rowList?.length < 10}
                          height={538}
                          tempCol={'40px 240px repeat(2, 180px) repeat(2, 120px) repeat(4, 180px) 108px 160px 148px'}>

        <div className={'grid-title'}>
          <label className={'checkbox'}>
            <input type="checkbox"
                   onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                     dataList: rowList,
                     checkedList: checkedList ,
                     id: 'id',
                     setState: setCheckedList
                   } )}
                   checked={allCheckedState( {
                                               dataList: rowList,
                                               checkedList: checkedList ,
                                               id: 'id'
                                             } )}/>
          </label>
          {materialTitle.map( ( v ) => (
            <TableValue title={v} key={v} viewType={true}
                        requiredValue={needValue}/> ) )}
        </div>

        {rowList.map( ( value , index ) => (
          <GridTableSelect className={'grid-row-list'} checkboxNumber={index + 1}>
            <label className={'selectCheckbox'}>
              <ListInnerInput type={'check'} name={'id'}
                              onChange={( e: any ) => singleCheckedFunction( e.target.checked , value.id , setCheckedList , checkedList )}
                              checked={checkedList.includes( value.id )}/>
            </label>
            <ListInnerInput type={'withButton'} width={'120px'} name={'rawMaterialSerialCode'}
                            placeholder={'-'}
                            inputData={value.rawMaterialSerialCode} inputDisabled={value.codeDoubleCheck}
                            onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList , checkedList, setCheckedList, value.id,true )}
                            clickButton={() => {
                              serialDoubleCheck( value.rawMaterialSerialCode , index ).then()
                            }}
                            buttonText={value.codeDoubleCheck ? '확인됨' : '중복확인'}
                            buttonWidth={'60px'}
                            disabled={( value.codeDoubleCheck || !value.rawMaterialSerialCode )}/>

            <ListInnerInput type={'text'} name={'rawMaterialName'} placeholder={'-'}
                            inputData={value.rawMaterialName}
                            onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList , checkedList, setCheckedList, value.id,true )}/>
            <ListInnerInput type={'text'} name={'material'} placeholder={'-'}
                            inputData={value.material}
                            onChange={( e ) => onMultiRegChangeArray( index , e , rowList , setRowList , checkedList, setCheckedList, value.id,true )}/>
            <ListInnerInput type={'drop'} name={'materialType'} inputData={value.materialType}
                            dropMenuList={['COIL' , 'SHEET','소재','절단']}
                            tabIndex={- 1} dropAddNoneSelect={true}
                            dropTopDirection={index >= 16}
                            selectDrop={( v: string ) => {
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                              setRowList( ( prev: any ) => {
                                return prev.map( ( item: any , i: number ) => {
                                  if( i === index ){
                                    return {
                                      ...item ,
                                      materialType: v ,
                                      rawMaterialUnit: v === 'COIL' || v === 'SHEET' ? 'kg' : 'ea' ,
                                      rawMaterialStock: '' ,
                                      length: '',
                                      standard: (v === '소재' || v === '절단')? 'ø' : '',
                                    };
                                  }
                                  return item;
                                } );
                              } );
                              // setRowList((prev: any) => ([{ ...prev[index], materialType: v, rawMaterialUnit: v === 'COIL' ? 'kg' : 'ea', rawMaterialStock:''}]));
                              // Object.assign(rowList[index] , {materialType: v, rawMaterialUnit: v === 'COIL' ? 'kg' : 'ea', rawMaterialStock:''});
                            }}/>

            <ListInnerInput type={'drop'} name={'standard'} inputData={value.standard}
                            style={{fontFamily: !value.standard? 'NanumGothicOTF' : 'Noto Sans KR', fontSize: !value.standard? '12px' : '16px'}}
                            dropFontSize={'20px'} inputDataAutoUpdate={true}
                            disabled={!(value.materialType === '소재' || value.materialType === '절단')}
                            dropMenuList={['ø','六','□']}
                            selectDrop={( v ) => {
                              setRowList( ( prev: any ) => {
                                return prev.map( ( item: any , i: number ) => {
                                  if( i === index ){
                                    return {
                                      ...item,
                                      standard: v,
                                    }
                                  }
                                  return item
                                } )
                              } )
                            }}
            />

            <ListInnerInput type={'unit'} unit={'T'} name={'thickness'} placeholder={'-'}
                            inputData={decimalNumberValid( value.thickness )} limit={15}
                            onChange={( e ) => {
                              handleDecimalChange( index , e , rowList , setRowList , '' )
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                            }}/>
            <ListInnerInput type={'unit'} unit={'mm'} name={'width'} placeholder={'-'}
                            inputData={decimalNumberValid( value.width )} limit={15}
                            onChange={( e ) => {
                              handleDecimalChange( index , e , rowList , setRowList , '' )
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                            }}/>
            <ListInnerInput type={'unit'} unit={'mm'} name={'length'} placeholder={'-'}
                            inputData={decimalNumberValid( value.length )} limit={15}
              // disabled={value.materialType === 'COIL'}
                            onChange={( e ) => {
                              handleDecimalChange( index , e , rowList , setRowList , '' )
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                            }}/>
            <ListInnerInput type={'unit'} unit={dataValid( value.rawMaterialUnit , 'ea' )}
                            limit={15} name={'rawMaterialStock'} placeholder={'-'}
                            inputData={value.rawMaterialStock.includes( '.' ) ? value.rawMaterialStock : numberLocaleString( value.rawMaterialStock )}
                            onChange={( e ) => {
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                              handleDecimalChange( index , e , rowList , setRowList , value.rawMaterialUnit )}
            }/>
            <ListInnerInput type={'drop'} name={'unit'} inputData={dataValid( value.rawMaterialUnit , 'ea' )}
                            tabIndex={- 1}
                            dropTopDirection={index >= 16}
                            selectDrop={( v ) => {
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                              setRowList( rowList.map( ( v ) => {return v.id === selectedID ? {...v , ...rowList[index]} : v} ) )
                              Object.assign( rowList[index] , {rawMaterialUnit: v , rawMaterialStock: ''} )
                            }}
                            dropMenuList={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>
            <ListInnerInput type={'search'} name={'customer'} inputData={dataValid( value.customerName )}
                            placeholder={'검색'} tabIndex={- 1}
                            clickButton={() => {
                              setAccountSearchModal( true )
                              setSelectRowIndex( index )
                              setSelectedID(value.id)
                            }}/>
            <ListInnerInput type={'unit'} unit={'일'} name={'standardDate'} width={'120px'}
                            inputData={numberLocaleString( value.standardDate )} placeholder={'-'}
                            onChange={( e ) => {
                              if(!checkedList.includes(value.id)){
                                setCheckedList(checkedList.concat(value.id))
                              }
                              onChangeNumberArray( index , e , rowList , setRowList )
                            }}
                            limit={11}/>
          </GridTableSelect>
        ) )}
      </GridTableComponent>
      <div style={{display: 'flex' , justifyContent: 'flex-end' , marginTop: '10px'}}>
        <HorizontalMoveScrollButton
          leftClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft - 120 )}
          rightClick={() => scrollRef.current.scrollLeft = ( scrollRef.current.scrollLeft + 120 )}/>
      </div>
    </React.Fragment>
  );
};
export default RawMaterialMultipleReg;
