import React from 'react';
import styled from "styled-components";
import {UnitProps} from "../../types/Fundamental";
import {useTranslation} from "react-i18next";

const MenuTitle:React.FunctionComponent<UnitProps> = ({title, unitCase, fontColor, stateText,margin}) => {
	const { t }:any = useTranslation();

	const createTitle = (titleCase: string, title: string, color?: string) => {
		switch (titleCase){
			case 'MenuTitle': return(
				<BaseComponent style={{margin: margin ?? '47px 0 35px'}}>
					{t(title)}
				</BaseComponent>
			)
			case 'SubContentTitle': return(
				<div style={{userSelect:'none',color: color, fontWeight:'800'}}>
					{t(title)}
				</div>
			)
			case 'MenuCompTitle': return(
				<div style={{userSelect:'none',margin:margin,fontSize: '18px', fontWeight: '800', letterSpacing: t? 0:'0.11px', lineHeight:'32px', color: color, whiteSpace:'nowrap',}}>
					{t(title)}
				</div>
			)
			case 'StateMenuTitle': return(
				<div style={{display:'flex'}}>
					<BaseComponent style={{opacity: 0.3}}>
						{t(stateText)}
					</BaseComponent>
					<BaseComponent style={{textIndent:'8px'}}>
						{t(title)}
					</BaseComponent>
				</div>
			)
			case 'kpiTitle' : return (
				<div style={{whiteSpace:'pre-line', fontSize:'18px', fontWeight:800, lineHeight:'19px'}}>
					{t(title)}
				</div>
			)
			case 'mobileTitle' : return (
				<div style={{fontWeight:800,fontSize:'14px',margin: margin ?? '32px 0 10px 0'}}>
					{t(title)}
				</div>
			)
			case 'mobileCompTitle' : return (
				<div style={{fontWeight:800, fontSize:'12px',marginTop:margin ?? '32px',display:'flex',maxWidth:'320px'}}>
					{t(title)}
				</div>
			)
		}
	}

	return (
		<React.Fragment>
			{createTitle(unitCase, title, fontColor)}
		</React.Fragment>
	);
};

export default MenuTitle;

const BaseComponent = styled.div`
	//margin: 2.45vw 0 1.83vw;
	//font-size: clamp(14px, 1.25vw, 24px);
	margin: 47px 0 35px;
	font-size: 24px;
	font-weight: 800;
	line-height: 36px;
	letter-spacing: 0.1px;
	user-select: none;
	white-space: nowrap;
`

