import React from 'react';
import {atom} from "recoil";
import {recoilPersist} from "recoil-persist";
import dayjs from "dayjs";

const {persistAtom} = recoilPersist()

// 간단한 atomFamily 사용 예시
// export const sideMenuState = atomFamily({
// 	key:'sideMenuState',
// 	default: true,
// 	effects_UNSTABLE: [persistAtom]
// })

export const DevelopState = atom({
																	 key: 'Develop_State',
																	 default: 0,
																	 effects_UNSTABLE: [persistAtom]
																 })

//필수
export const persistCompanyData = atom({
																				 key:'persist_companyData',
																				 default: {},
																				 effects_UNSTABLE: [persistAtom]
																			 })
//필수
export const persistPartnersData = atom({
																					key:'persist_partnersData',
																					default: {},
																					effects_UNSTABLE: [persistAtom]
																				})


//필수
export const userPersistCompanyData = atom({
																						 key:'user_persist_companyData',
																						 default: {companyCode: window.localStorage.getItem('companyCode'),companyName:window.localStorage.getItem('company')},
																						 effects_UNSTABLE: [persistAtom]
																					 })

export const clonePersistCompany = atom({
																						 key:'clone_persist_company',
																						 default: {},
																						 effects_UNSTABLE: [persistAtom]
																					 })

export const menuSelectedSolution = atom({
	key:'menu_selected_solution',
	default:'',
	effects_UNSTABLE: [persistAtom]
																				 })

export const menuSelectedSubMenu = atom({
	key:'menu_selected_subMenu',
	default:{title:'', length:0},
	effects_UNSTABLE: [persistAtom]
																				})

export const scrollToPosition = atom({
	key:'scroll_to_position',
	default:0,
	effects_UNSTABLE: [persistAtom]
																		 })

//실제 사용안함.
export const sidePersistState = atom({
																			 key: 'side_persist_state',
																			 default: 999,
																			 effects_UNSTABLE: [persistAtom]
																		 })

//실제 사용안함.
export const selectSidePersistState = atom({
																						 key:'select_side_persist',
																						 default: 999,
																						 effects_UNSTABLE:[persistAtom]
																					 })


export const persistProblemTypeData = atom({
																						 key:'persist_problemTypeData',
																						 default: [],
																						 effects_UNSTABLE: [persistAtom]
																					 })

export const persistProductData = atom({
																				 key:'persist_productData',
																				 default:{},
																				 effects_UNSTABLE:[persistAtom]
																			 })
export const persistMoldData = atom({
																			key:'persist_MoldData',
																			default:{},
																			effects_UNSTABLE:[persistAtom]
																		})
export const persistFacilityData = atom({
																					key:'persist_facilityData',
																					default:{},
																					effects_UNSTABLE:[persistAtom]
																				})
export const searchInputState = atom({
																			 key: 'search_input_state',
																			 default: '',
																			 effects_UNSTABLE: [persistAtom]
																		 })

export const solutionTypeState = atom({
																				key:'solution_type_state',
																				default: {index: 0, value: '@'},
																				effects_UNSTABLE: [persistAtom]
																			})

export const facilityTypeState = atom({
																				key:'facility_type_state',
																				default: '전체',
																				effects_UNSTABLE: [persistAtom]
																			})


export const LanguageAtom = atom({
																	 key: 'language_atom',
																	 default: {lang: 'ko', text: '한국어', index: 1},
																	 effects_UNSTABLE: [persistAtom]
																 })

// P-BAS에서 설비 등록시 메뉴 뒤로 갈때 서로 다른 URL이므로 다른 상태값으로 나눔
export const facilityRoute = atom({
																		key: 'facility_route',
																		default: false,
																		effects_UNSTABLE: [persistAtom]
																	})

export const selectViewType = atom({
																		 key: 'select_view_type',
																		 default:{index: 0, value: '리스트'},
																		 effects_UNSTABLE: [persistAtom]
																	 })

// 설비등록 때 세부사항으로 들어간 뒤에도 목록에 상태값 남김.
export const selectedCode = atom({
																	 key:'selected_code',
																	 default:'',
																	 effects_UNSTABLE:[persistAtom]
																 })

export const persistContainerMove = atom({
																		key:'container_move',
																		default:'전체',
																		effects_UNSTABLE:[persistAtom]
																	})

//페이지 네이션 있는 페이지에서 필수
export const persistPage = atom({
																	key:'persist_page',
																	default: 1,
																	effects_UNSTABLE:[persistAtom]
																})

//페이지 네이션 있는 페이지에서 필수
export const persistBlockPage = atom({
																			 key:'persist_block_page',
																			 default:0,
																			 effects_UNSTABLE:[persistAtom]
																		 })


export const unitManagement = atom({
																		 key:'unit_management_modal',
																		 default:false,
																		 effects_UNSTABLE:[persistAtom]
																	 })

export const selectAlarmNo = atom({
																		key:'select_focus_alarm_no',
																		default:'',
																		effects_UNSTABLE:[persistAtom]
																	})

//실제 사용안함
export const accessCardData = atom({
	 key:'access_card_data',
	 default:[0,1,2,3,4,5]
 })
// [dayjs().format('YYYY.MM.DD'), dayjs().add(1,'day').format('YYYY.MM.DD')]
export const accessCardState = atom({
	 key:'access_card_state',
	 default:[]
 })

export const contactDataPanel = atom({
	key:'contact_data_panel',
	default:[]
																		 })

export const contactLoadingState = atom({
	key:'contact_loading_state',
	default: false
																	 })

export const panelSingleContact = atom({
	key:'panel_single_contact',
	default:''
																			 })

export const divisionContactState = atom({
	key:'division_contact_state',
	default: false
																				 })

export const timelineZoomCount = atom({
	key: 'timeline_zoom_count',
	default : 0
																			})

export const timelineDateState = atom({
	key:'timeline_date_state',
	default: dayjs().subtract(1,'day').format('YYYY-MM-DD')
																 })

export const timelineBarState = atom({
																				 key:'timeline_bar_state',
																				 default: {input: '12:00:00', value:'12:00:00'}
})

export const timeHourRangeState = atom({
	key:'time_hour_range_state',
	default: '6시 ~ 12시'
																			 })

export const editAreaCheck = atom({
	key:'edit_area_check',
	default: false
})


export const layoutData = atom({
	key:'layout_data',
	default:[]
})

//실제 사용안함
export const calendarDatePersist = atom({
	key:'calendar_date_persist',
	default:[dayjs().subtract(1,'month').format('YYYY.MM.DD'), dayjs().format('YYYY.MM.DD')],
	effects_UNSTABLE:[persistAtom]
})

export const calendarRangeStartDate = atom({
	key:'calendar_range_startDate_persist',
	default: dayjs().startOf('month').format('YYYY.MM.DD'),
	effects_UNSTABLE:[persistAtom]
})

export const calendarRangeEndDate = atom({
	key:'calendar_range_endDate_persist',
	default: dayjs().endOf('month').format('YYYY.MM.DD'),
	effects_UNSTABLE:[persistAtom]
})


//실제 사용안함
export const calendarAfterDatePersist = atom({
																					key:'calendar_date_persist_after',
																					default:[dayjs().format('YYYY.MM.DD'), dayjs().add(1,'month').format('YYYY.MM.DD')],
																					effects_UNSTABLE:[persistAtom]
																				})

export const orderSelectReg = atom({
																		 key:'order_select_reg',
																		 default: {ordersCode: null},
																		 effects_UNSTABLE:[persistAtom]
																	 })

export const moldRequestReg = atom({
																		 key:'mold_request_reg',
																		 default:{},
																		 effects_UNSTABLE:[persistAtom]
																	 })

//LOTOUT 실제 사용안함.
export const firstLotOut = atom({
																	key:'first_lotOut',
																	default:[],
																	effects_UNSTABLE:[persistAtom]

																})
export const secondLotOut = atom({
																	 key:'second_lotOut',
																	 default:[],
																	 effects_UNSTABLE:[persistAtom]
																 })

export const thirdLotOut = atom({
																	key:'third_lotOut',
																	default:[],
																	effects_UNSTABLE:[persistAtom]
																})

export const documentSDMCode = atom({
																			key:'document_sdm_code',
																			default: null,
																			effects_UNSTABLE:[persistAtom]
																		})

export const documentSDMList = atom({
																			key:'document_sdm_list',
																			default:[{name: '표준문서 관리', SDMCode: null}],
																			effects_UNSTABLE:[persistAtom]
																		})

//실제 사용안함
export const selectMenuValue = atom({
																			key:'select_menu_value',
																			default: 'notSelect',
																			effects_UNSTABLE:[persistAtom]
																		})

export const f_ems_graphDateState = atom({
	key:'f-ems_graph_date',
	default: new Date()
																				 })

export const f_ems_calendar_date = atom({
																					 key:'f-ems_calendar_date',
																					 default: dayjs().subtract(1,'day').toDate()
																				 })
export const f_ems_calendar_early_date = atom({
																					key:'f-ems_calendar_early_date',
																					default: dayjs().toDate()
																				})
export const statistics_calendar_button = atom({
																								 key:'statistics_calendar_button',
																								 default: false,
																								 effects_UNSTABLE:[persistAtom]
																							 })

export const report_loading_state = atom({
	key:'report_loading',
	default: false
																				 })

export const loadtonWave_loading_state = atom({
																					 key:'loadtonWave_loading',
																					 default: false
																				 })

export const loadtonWave_time_state = atom({
																								key:'loadtonWave_time',
																								default: 24
																							})

export const companySolutionFilter = atom({
	key:'company_solution_filter',
	default: '@',
	effects_UNSTABLE:[persistAtom]
																					})

export const wikiTypeFilter = atom({
																		 key:'wiki_type_solution',
																		 default: '@',
																		 effects_UNSTABLE:[persistAtom]
																	 })

export const wikiAuthTypeState = atom({
	key:'wiki_auth_type_state',
	default: 'admin',
	effects_UNSTABLE:[persistAtom]
																			})


// 들어간 목록 내부의 접속 수,(게시물에 들어간 수량) 을 체크하기 위한
export const selectedListCountValue = atom({
	key:'selected_list_count_value',
	default:'0',
	effects_UNSTABLE:[persistAtom]
																					 })

//실제 사용 안함
export const companyAuthorities = atom({
	key:'company_Authorities',
	default:'',
	effects_UNSTABLE:[persistAtom]
																			 })

export const kioskMenuPosition = atom({
	key:'kiosk_menu_position',
	default: 0,
	effects_UNSTABLE:[persistAtom]
																			})

export const kioskWorkOrder = atom({
	key:'kiosk_work_order',
	default: null,
	effects_UNSTABLE:[persistAtom]
																	 })


export const kioskHistoryMenu = atom({
	key:'kiosk_history_menu',
	default: [0],
	effects_UNSTABLE:[persistAtom]
																		})

export const selectKioskWorkHistory = atom({
																		key:'select_kiosk_work_history',
																		default: null,
																		effects_UNSTABLE:[persistAtom]
																	 })

export const kioskProcessCode = atom({
	key:'kiosk_process_code',
	default:'',
	effects_UNSTABLE:[persistAtom]
																		 })

export const productSearchLog = atom({
	key:'product_search_log',
	default:[]
																		 })

export const facilitySearchLog = atom({
																				key:'facility_search_log',
																			 default:[]
																		 })

export const dashboardSelectType = atom({
	key:'dashboard_select_type',
	default:0
																				})

export const kioskTokenManager = atom({
	key:'kiosk_token_manager',
	default:null,
	effects_UNSTABLE:[persistAtom]
																			})

export const operationPressStateNumber = atom({
	key:'operation_press_state_number',
	default: 3,
	effects_UNSTABLE:[persistAtom]
																							})

export const operationPressStateKey = atom({
	key:'operation_press_state_key',
	default: 'error',
	effects_UNSTABLE:[persistAtom]
																							})


export const selectedCommentCodeState = atom({
	key:'selected_comment_code_state',
	default:''
																						 })

export const companyInfoHideState = atom({
	key:'company_info_hide_state',
	default: false,
	effects_UNSTABLE:[persistAtom]
																				 })

export const searchPersistState = atom({
	key:'search_persist_state',
	default: {input:'', value:''},
	effects_UNSTABLE:[persistAtom]
																			 })

export const selectedWorkOrderState = atom({
	key:'selected_work_order_state',
	default: {},
	effects_UNSTABLE:[persistAtom]
																					 })

export const pressMonitoringContainerState = atom({
	key:'press_monitoring_container_state',
	default: '전체'
})

export const pressLineSettingState = atom({
	key: 'press_line_setting_state',
	default: false
})

export const pressLineTypeTotalState = atom({
	key:'press_line_type_total_state',
	default: []
																						})

export const processSelectCompany = atom({
	key:'process_select_company',
	default:{companyCode:'', companyName:''},
	effects_UNSTABLE:[persistAtom]
																				 })

export const bomProductState = atom({
	key:'bom_product_state',
	default:{
		customer:[], serialCode: '' , unit: '' , productName: '' ,
		type: '' , productCode: '' , uph: '' , processName: ''
	},
	effects_UNSTABLE:[persistAtom]
																		})