import React , {useState , useRef , useEffect} from 'react';
import {
  autoTextValueValidation ,
  ContentFrame ,
  listResultMessage ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {useTranslation} from "react-i18next";
import {Error_Type , Modal_View_Type , SubMaterialType} from "../../../../../types/Fundamental";
import ListInnerInput from "../../../../../components/input/ListInnerInput";
import {
  allCheckedState ,
  allPersistCheckedFunction , authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  filterCheckedRow , handleDecimalChange ,
  initError ,
  initModal ,
  loadingRemove , menuVisitLogRegister , numberLocaleString , onChangeArray , onChangeCheck ,
  removeCheckedFunction , rowListDropDirection ,
  singleCheckedFunction
} from "../../../../../function/function";
import modify from "../../../../../asset/image/FAS_list_icon_modify.svg";
import modifyHover from "../../../../../asset/image/FAS_list_icon_modify_hover.svg";
import cancelIcon from "../../../../../asset/image/FAS_list_icon_cancle.svg";
import checkIcon from "../../../../../asset/image/FAS_list_icon_save.svg";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  persistBlockPage ,
  persistCompanyData ,
  userPersistCompanyData
} from "../../../../../common/StateManage";
import ExcelApiButton from "../../../../../components/button/ExcelApiButton";
import TableValue from "../../../../../style/TableValue";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import DeleteCaseModal from "../../../../../components/modal/DeleteCaseModal";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import ExcelModal from "../../../../../components/modal/ExcelModal";
import FilterWithSearchBar from "../../../../../components/input/FilterWithSearchBar";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const SubMaterials = () => {
  const {t}: any = useTranslation();
  const subMaterialTitle = ['부자재 CODE' , '부자재 품명' , '단위' , '안전 재고량' , '거래처'];
  const needValue = ['부자재 CODE' , '부자재 품명' , '단위']
  const [checkedList , setCheckedList] = useState<any>( [] )
  const [hoverIcon , setHoverIcon] = useState<string>( modify )
  const [iconSelect , setIconSelect] = useState<number>( - 1 )
  const [focusList , setFocusList] = useState<number>( - 1 )
  const [accountSearchModal , setAccountSearchModal] = useState<{
    open: boolean,
    idx: number
  }>( {open: false , idx: - 1} );
  const [accountCode , setAccountCode] = useState<any>( {} )
  const [rowList , setRowList] = useState<SubMaterialType[]>( [] )
  const [idIndex , setIdIndex] = useState<number>( 1 )
  const [subMaterialList , setSubMaterialList] = useState<SubMaterialType[]>( [] );
  const [selectCode , setSelectCode] = useState<string | number>( '' )
  const selectedCompany = useRecoilValue( authValid() ? persistCompanyData : userPersistCompanyData )
  const [searchValue , setSearchValue] = useState<string>( '' );
  const [pushValue , setPushValue] = useState<string>( '' );
  const [page , setPage] = useState<number>( 1 )
  const [totalPage , setTotalPage] = useState<number>( 1 )
  const [errorState , setErrorState] = useState<Error_Type>( initError )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal );
  const [selectSubMaterialData , setSelectSubMaterialData] = useState<any>( {} );
  const [inputSerialCode , setInputSerialCode] = useState<string>( '' )
  const [checkIndex , setCheckIndex] = useState<number>( - 1 )
  const [deleteCaseArray , setDeleteCaseArray] = useState<any>( [] )
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage );
  const [categorySelect , setCategorySelect] = useState<{value:string,type:string|any}>({value:'전체',type:'@'})

  const scrollRef = useRef<any>( null )
  const scrollToStart = () => {
    scrollRef.current.scrollTop = 0
  }
  const addListRow = () => {
    setIdIndex( idIndex + 1 )
    const rowsInput = {
      id: idIndex ,
      companyCode: selectedCompany.companyCode ,
      customer: '' ,
      subMaterialName: '' ,
      subMaterialSerialCode: '' ,
      subMaterialStock: '' ,
      subMaterialUnit: 'ea' ,
      codeDoubleCheck: false
    }
    scrollToStart()
    setRowList( [...rowList , rowsInput] )
    setCheckedList( [...checkedList , rowsInput.id] )
  }
  const updateFunction = () => {
    if( page === 1 ){
      if( pushValue === '' ){
        subMaterialListRes().then()
      } else{
        subSearchRes().then()
      }
    } else{
      setPage( 1 )
    }
  }
  const subMaterialListRes = () => ListCustomHooks( `/subMaterial/list/${page}/${10}/${selectedCompany.companyCode}` , page , setTotalPage , setPageBlock , setSubMaterialList , setErrorState , setSearchValue , setPushValue )
  const subSearchRes = () => ListSearchPostCustomHooks( `/subMaterial/search` , page , setTotalPage , setPageBlock , setSubMaterialList , setErrorState , {
    page: page ,
    size: 10 ,
    keyword: pushValue ,
    companyCode: selectedCompany.companyCode,
    filter:categorySelect.type
  } )
  //리스트 함수 <무한 스크롤 필요>
  // const subMaterialListRes = async () =>{
  //     try{
  //         listLoadingCircle()
  //         const response = await client.get(`/subMaterial/list/${page}/${10}/${selectedCompany.companyCode}`,{
  //             headers:{Authorization}
  //         })
  //         const data = response.data.data
  //         const valid = response.data
  //
  //         if(valid.status !== 200){
  //             setErrorState({notice:valid.message,status:valid.status})
  //             setSubMaterialList([])
  //         }else{
  //             setTotalPage(data.total_page)
  //             setSearchValue('')
  //             setPushValue('')
  //             setErrorState({notice:t('* 등록된 부자재 정보가 없습니다. 등록 해주세요.'),status:valid.status})
  //             setSubMaterialList(data.row)
  //             // if(data.total_page > 1 && page > 1){
  //             //     setSubMaterialList((prev:any)=> prev.concat(data.row))
  //             // }else{
  //             //     setSubMaterialList(data.row)
  //             // }
  //         }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //검색 함수
  // const subSearchRes = async () => {
  //     listLoadingCircle()
  //     try {
  //         const response = await client.post(`/subMaterial/search`,{
  //             // /${page}/${10}/${selectedCompany.companyCode}/${pushValue}
  //             page:page,
  //             size:10,
  //             companyCode:selectedCompany.companyCode,
  //             keyword:pushValue
  //         },{
  //                                                headers:{Authorization}
  //                                            })
  //         const data = response.data.data
  //         setTotalPage(data.total_page)
  //         setSubMaterialList(data.row)
  //         // if(data.total_page > 1 && page > 1){
  //         //     setSubMaterialList((prev:any)=> prev.concat(data.row))
  //         // }else{
  //         //     setSubMaterialList(data.row)
  //         // }
  //         loadingRemove()
  //     }catch (e) {
  //         console.log(e)
  //         loadingRemove()
  //     }
  // }
  //등록 함수 OK
  const subMaterialRegisterRes = async () => {
    if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialSerialCode ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 코드를 입력해 주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.codeDoubleCheck ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 코드 중복 확인을 해주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialName ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 품명을 입력해 주세요.'} )
    } else if( filterCheckedRow( rowList , checkedList , 'id' ).find( ( v ) => !v.subMaterialUnit ) ){
      setDataModal( {menu: 'confirm' , message: '부자재 단위를 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/subMaterial/register` ,
                                            filterCheckedRow( rowList , checkedList , 'id' ) , {headers: {Authorization}} )
        const data = response.data;
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setRowList( [] )
            setCheckedList( [] )
            updateFunction()
            setDataModal( {menu: 'success' , message: '부자재 정보가 등록 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //삭제 함수 OK
  const subDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( `/subMaterial/delete` ,
                                          [...checkedList] , {headers: {Authorization}} )
      const data = response.data
      const bomCaseArray = data.data?.bom?.map( ( v: any ) => ( {code: v , case: '하위 BOM으로 등록 되어 있습니다.'} ) )
      const inventoryCaseArray = data.data?.inventory?.map( ( v: any ) => ( {code: v , case: '부자재 재고가 존재합니다.'} ) )
      const caseArray = [bomCaseArray , inventoryCaseArray].flat()
      if( data.status === 200 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setIconSelect( - 1 )
          setDataModal( {menu: 'success' , message: '선택한 부자재 정보가 삭제 되었습니다.'} )
          removeCheckedFunction( setSubMaterialList , subMaterialList , setCheckedList , checkedList , 'subMaterialCode' )
          scrollToStart()
          if( page === 1 ){
            if( pushValue === '' ){
              subMaterialListRes().then()
            } else{
              subSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else if( data.status === 601 ){
        setTimeout( () => {
          setPage( 1 )
          setTotalPage( 1 )
          setDataModal( {menu: 'deleteCase'} )
          setDeleteCaseArray( caseArray )
          setCheckedList( checkedList.filter( ( v: string ) => !data.data.success.includes( v ) ) )
          setSubMaterialList( subMaterialList.filter( ( v: any ) => !data.data.success.includes( v['subMaterialCode'] ) ) )
          scrollToStart()
          if( page === 1 ){
            if( pushValue === '' ){
              subMaterialListRes().then()
            } else{
              subSearchRes().then()
            }
          }
          loadingRemove()
        } , 500 )
      } else{
        setDataModal( {menu: 'error' , message: data.message} )
        loadingRemove()
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  //수정 함수 OK
  const subUpdateRes = async () => {
    if( !subMaterialList[iconSelect].subMaterialSerialCode ){
      setDataModal( {menu: 'confirm' , message: '부자재 CODE를 입력하지 않았습니다.'} )
    } else if( !subMaterialList[iconSelect].subMaterialName ){
      setDataModal( {menu: 'confirm' , message: '부자재 품명을 입력하지 않았습니다.'} )
    } else{
      try {
        setDataModal( initModal )
        dataAccessLoading()
        const response = await client.post( `/subMaterial/update` , {
          companyCode: selectedCompany?.companyCode ,
          ...subMaterialList[iconSelect]
        } , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          loadingRemove()
          setDataModal( {menu: 'confirm' , message: data.message} )
        } else{
          setTimeout( () => {
            updateFunction()
            setIconSelect( - 1 )
            setDataModal( {menu: 'success' , message: '수정 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  //부자재 시리얼코드 중복체크
  const serialDoubleCheck = async ( serialCode: string , index: number ) => {
    setCheckIndex( index )
    const regCount = rowList.filter( ( v ) => ( v.subMaterialSerialCode.includes( serialCode ) && v.codeDoubleCheck ) ).length
    try {
      const response = await client.post( '/subMaterial/serial/check' , {
        companyCode: selectedCompany.companyCode ,
        subMaterialSerialCode: serialCode
      } , {headers: {Authorization}} )
      const duplicateCount = response.data.data
      const copyRowList = [...rowList]
      copyRowList[index] = {
        ...copyRowList[index] , codeDoubleCheck: true ,
        subMaterialSerialCode: regCount === 0 ? serialCode : serialCode + `(${regCount})`
      }
      if( rowList.length !== 0 ){
        if( duplicateCount[0] === 0 ){
          if( regCount !== 0 ){
            setInputSerialCode( regCount === 0 ? serialCode : serialCode + `(${regCount})` )
            setDataModal( {menu: 'doubleCheck' , message: '중복 입력된 부자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
          } else{
            setRowList( copyRowList )
            setDataModal( {menu: 'confirm' , message: '중복되지 않은 부자재CODE 입니다.'} )
          }
        } else{
          if( duplicateCount.length === 1 ){
            setInputSerialCode( serialCode + `(${duplicateCount[0] + regCount})` )
          } else{
            if( regCount < duplicateCount.length ){
              setInputSerialCode( serialCode + `(${duplicateCount[regCount]})` )
            } else{
              setInputSerialCode( serialCode + `(${duplicateCount[duplicateCount.length - 1] + ( regCount - 1 )})` )
            }
          }
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 부자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      } else{
        if( duplicateCount[0] === 0 ){
          const copyMaterialList = [...subMaterialList]
          copyMaterialList[index] = {
            ...copyMaterialList[index] , codeDoubleCheck: true ,
            subMaterialSerialCode: serialCode
          }
          setSubMaterialList( copyMaterialList )
          setDataModal( {menu: 'confirm' , message: '중복되지 않은 부자재CODE 입니다.'} )
        } else{
          setInputSerialCode( serialCode + `(${duplicateCount[0]})` )
          setDataModal( {menu: 'doubleCheck' , message: '이미 사용중인 부자재CODE 입니다. \n 중복사용 하시겠습니까?'} )
        }
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }
  //동적 체크박스 함수
  const checkboxValid = () => {
    if( rowList.length !== 0 ){
      return {list: rowList , id: 'id'}
    } else{
      return {list: subMaterialList , id: 'subMaterialCode'}
    }
  }
  const SubMaterialDeleteRes = () => {
    if( rowList.length === 0 ){
      setDataModal( {menu: 'select' , message: `${checkedList.length}개의 부자재를 삭제 하시겠습니까?`} )
    } else{
      setDataModal( {menu: 'back' , message: '선택된 행의 정보를 삭제하시겠습니까?'} )
    }
  }
  React.useEffect( () => {
    if( selectedCompany.companyCode !== undefined ){
      if( pushValue === '' ){
        subMaterialListRes().then()
      } else{
        subSearchRes().then()
      }
    }
  } , [pushValue , page , selectedCompany,categorySelect.type] )
  const onDataResetFunc = () => {
    setAccountCode( {} );
    setRowList( [] );
    setCheckedList( [] );
    setSubMaterialList( [] )
    setSearchValue( '' );
    setPushValue( '' );
    setIconSelect( - 1 );
    setPage( 1 );
    setPageBlock( 0 )
    setTotalPage( 1 )
    setErrorState( initError )
  }
  React.useEffect( () => {
    onDataResetFunc()
  } , [selectedCompany.companyCode] )
  const cancelFuc = () => {
    setHoverIcon( modify );
    setIconSelect( - 1 );
    setCheckedList( [] )
    setDataModal( initModal )
    if( pushValue === '' ){
      setSubMaterialList( subMaterialList.map( ( v: any ) => {return v.subMaterialCode === selectCode ? {...v , ...selectSubMaterialData} : v} ) )
    } else{
      subSearchRes().then()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( rowList.length > 0 || iconSelect !== - 1 ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [rowList.length , iconSelect] );

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'부자재 기본정보').then()
    }
  },[])

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ?
        <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => {setDataModal( initModal )}}/> : null}

      {dataModal.menu === 'deleteCase' &&
          <DeleteCaseModal targetName={'부자재 CODE'} caseArray={deleteCaseArray}
                           closeEvent={() => setDataModal( initModal )}/>}

      {/*거래처 검색창*/}
      {accountSearchModal.open &&
          <AccountSearchModal receiveData={( value ) => {setAccountCode( value )}}
                              companyCode={selectedCompany.companyCode}
                              selectCode={accountCode.customerCode} modalCase={'customer'} authValid={authValid()}
                              cancelEvent={() => {
                                setAccountSearchModal( {open: false , idx: - 1} );
                                setAccountCode( {} )
                              }}
                              confirmEvent={() => {
                                if( rowList.length !== 0 ){
                                  Object.assign( rowList[accountSearchModal.idx] , {customer: accountCode.customerName} )
                                } else{
                                  Object.assign( subMaterialList[iconSelect] , {customer: accountCode.customerName} )
                                }
                                setAccountSearchModal( {open: false , idx: - 1} );
                              }}/>}
      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={() => {
                             if( rowList.length !== 0 ){
                               const copyRowList = [...rowList]
                               copyRowList[checkIndex] = {
                                 ...copyRowList[checkIndex] ,
                                 codeDoubleCheck: true ,
                                 subMaterialSerialCode: inputSerialCode
                               }
                               setRowList( copyRowList )
                             } else{
                               const copyMaterialList = [...subMaterialList]
                               copyMaterialList[checkIndex] = {
                                 ...copyMaterialList[checkIndex] , codeDoubleCheck: true ,
                                 subMaterialSerialCode: inputSerialCode
                               }
                               setSubMaterialList( copyMaterialList )
                             }
                             setDataModal( initModal )
                           }}
                           cancelEvent={() => setDataModal( initModal )}
                           buttonWidth={'124px'} buttonFontSize={'12px'}/>}


      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'*삭제된 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={() => {
                                subDeleteRes().then();
                                setDataModal( initModal )
                              }}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t( dataModal.message )} clickEvent={() => {setDataModal( initModal )}}/>}


      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'선택되지 않은 리스트는 저장 되지 않습니다'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={subMaterialRegisterRes}/>}

      {dataModal.menu === 'back' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={() => {
                                if( rowList.length !== 0 ){
                                  removeCheckedFunction( setRowList , rowList , setCheckedList , checkedList , 'id' )
                                  setSelectSubMaterialData( {} )
                                  setAccountCode( {} )
                                  setDataModal( initModal )
                                } else{
                                  cancelFuc()
                                }
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal( initModal )}
                              clickEvent={subUpdateRes}/>}

      {dataModal.menu === 'excel' &&
          <ExcelModal closeEvent={()=>{setDataModal(initModal)}} excelContent={
            <React.Fragment>
              <ExcelApiButton category={'subMaterial'} type={'download'} disabled={subMaterialList.length === 0}
                              companyData={selectedCompany}/>
              <ExcelApiButton category={'subMaterial'} type={'format'} disabled={buttonAuthValid( 'create' , '10' )}
                              companyData={selectedCompany}/>
              <ExcelApiButton category={'subMaterial'} type={'upload'} disabled={buttonAuthValid( 'create' , '10' )}
                              companyData={selectedCompany}/>
            </React.Fragment>
          }/>
      }

      <SectionFrame>
        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
          <div style={{display: 'flex'}}>
            <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 부자재 기본정보'} unitCase={'MenuTitle'}/>
          </div>
          {rowList?.length !== 0 &&
              <MainButtons name={'목록'} width={'56px'}
                           clickEvent={() => {window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'}}/>}
        </div>
        {/*부자재 기본정보 */}
        <ContentFrame height={'680px'}
                      justify={'space-between'}
                      padding={'30px 30px 20px'}>
          <div>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'부자재 기본정보'} unitCase={'MenuCompTitle'}/>

              <div style={{display: 'flex'}}>
                {/*<SearchBar keyFunction={( e ) => setSearchValue( e.target.value.trimStart() )} value={searchValue}*/}
                {/*           keyPressDisable={true}*/}
                {/*           clearButtonAccess={searchValue !== ''} clearButton={() => {*/}
                {/*  subMaterialListRes().then();*/}
                {/*  setPage( 1 );*/}
                {/*  setPageBlock( 0 )*/}
                {/*}}*/}
                {/*           disabled={( subMaterialList.length === 0 && pushValue === '' ) || rowList.length !== 0 || iconSelect !== - 1}*/}
                {/*           clickEvent={() => {*/}
                {/*             setPushValue( searchValue );*/}
                {/*             setPage( 1 )*/}
                {/*           }}/>*/}

                <FilterWithSearchBar keyFunction={( e ) => setSearchValue( e.target.value.trimStart() )} value={searchValue}
                                     keyPressDisable={true}
                                     clearButtonAccess={searchValue !== ''} clearButton={() => {
                                      subMaterialListRes().then();
                                      setPage( 1 );
                                      setPageBlock( 0 )
                                     }}
                                     disabled={( subMaterialList.length === 0 && pushValue === '' ) || rowList.length !== 0 || iconSelect !== - 1}
                                     clickEvent={() => {
                                       setPushValue( searchValue );
                                       setPage( 1 )
                                     }}
                                     margin={'0'}
                                     selectKeyword={categorySelect.value}
                                     categorySelectEvent={(value,type)=>{setCategorySelect({value:value,type:type})}}
                                     categoryTypeArray={['@','sub_material_serial_code','sub_material_name','customer_name']}
                                     categoryValueArray={['전체','부자재 CODE','부자재 품명','거래처']}
                                     placeholder={t('검색')}
                />

                <MainButtons name={'Excel'} clickEvent={()=>{setDataModal({menu:'excel'})}}/>

                {/*{selectedCompany.companyCode !== undefined &&*/}
                {/*    <MainButtons name={'행추가'} width={'80px'}*/}
                {/*                 disabled={buttonAuthValid( 'create' , '10' ) || ( iconSelect !== - 1 || pushValue !== '' || checkedList[0]?.length > 1 || errorState.status !== 200 )}*/}
                {/*                 clickEvent={addListRow}/>}*/}
                {checkedList?.length !== 0 && <MainButtons name={'삭제'} buttonType={'delete'}
                              disabled={buttonAuthValid( 'rowDelete' , '10' ) ?
                                rowList.length === 0
                                :
                                buttonAuthValid( 'delete' , '10' ) || ( checkedList.length === 0 || iconSelect !== - 1 )}
                              width={'80px'}
                              marginDis={( rowList?.length !== 0 || iconSelect !== - 1 || checkedList?.length !== 0 )}
                              clickEvent={SubMaterialDeleteRes}/>}

                {rowList?.length !== 0 &&
                      <MainButtons name={'저장'}
                                   disabled={buttonAuthValid( 'create' , '10' ) || ( selectedCompany.companyCode === undefined || iconSelect !== - 1 || rowList.length === 0 || checkedList.length === 0 )}
                                   width={'80px'} buttonType={'cta'}
                                   clickEvent={() => {
                                     if( checkedList.length === rowList.length ){
                                       subMaterialRegisterRes().then()
                                     } else{
                                       setDataModal( {menu: 'register' , message: '선택되지 않은 리스트가 있습니다. 등록을 진행 하시겠습니까?'} )
                                     }
                                   }}/>
                    }


                {( rowList?.length === 0 && iconSelect === - 1 && checkedList?.length === 0 ) &&
                    <MainButtons name={'등록'} width={'80px'} buttonType={'cta'}
                                 marginDis={true}
                                 disabled={buttonAuthValid( 'create' , '10' ) || ( !selectedCompany.companyCode )}
                                 clickEvent={() => window.location.href = authValid()? '/admin/fas/submaterial/register' :  '/fas/submaterial/register'}/>}

              </div>
            </div>
            {/*<InfiniteScroll scrollableTarget={'grid-scroll'} scrollThreshold={'50px'}*/}
            {/*                next={()=>setPage((prev)=> prev+1)}*/}
            {/*                hasMore={totalPage > page} dataLength={dataValid(subMaterialList?.length, 0)}*/}
            {/*                loader={<div/>}>*/}
            <GridTableComponent id={'grid-scroll'} scrollX_Hide={true}
                                ref={scrollRef} titleCheckDisabled={iconSelect !== - 1}
                                scrollWidth={1210}
                                height={528}
                                scrollY_Hide={rowList?.length < 10}
              // height={listHeightValid(authValid,{total: totalPage, page: page},true)}
                                tempCol={'40px 328px 248px 122px 188px 284px'}>
              <div className={'grid-title'}>
                <label className={'checkbox'}>
                  <input type="checkbox"
                         style={{
                           pointerEvents: `${iconSelect === - 1 ? 'auto' : 'none'}` ,
                           opacity: `${iconSelect === - 1 ? 1 : 0.5}`
                         }}
                         onChange={( e ) => allPersistCheckedFunction( e.target.checked , {
                           dataList: checkboxValid().list ,
                           checkedList: checkedList ,
                           id: checkboxValid().id ,
                           setState: setCheckedList
                         } )}
                         checked={allCheckedState( {
                                                     dataList: checkboxValid().list ,
                                                     checkedList: checkedList ,
                                                     id: checkboxValid().id
                                                   } )}/>
                </label>
                {subMaterialTitle.map( ( v ) => (
                  <TableValue title={v} key={v} viewType={iconSelect !== - 1 || rowList.length !== 0}
                              requiredValue={needValue}/> ) )}
              </div>

              {rowList.length === 0 && subMaterialList.length === 0 ? pushValue === '' ? errorState.status !== 200 ?
                    listResultMessage( authValid() , dataValid( errorState.notice , '* 업체를 선택해 주세요.' ) ,
                                       {width: 1210 , admin: 480 , user: 480} )
                    :
                    listResultMessage( authValid() , dataValid( errorState.notice , '' ) ,
                                       {
                                         width: 1210 ,
                                         admin: 480 ,
                                         user: 480
                                       } , () => {window.location.href = authValid() ? '/admin/fas/submaterial/register' : '/fas/submaterial/register'} , buttonAuthValid( 'create' , '10' ),'등록')
                  :
                  listResultMessage( authValid() , '*검색 결과가 없습니다.' , {width: 1210 , admin: 480 , user: 480} )
                :
                rowList.map( ( v , i , arr ) => (
                  <div className={'grid-row-list'}>
                    <label className={'checkbox'}>
                      <ListInnerInput type={'check'} name={'id'}
                                      onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.id , setCheckedList , checkedList )}
                                      checked={checkedList.includes( v.id )}/>
                    </label>
                    {/*<ListInnerInput type={'text'} name={'subMaterialSerialCode'} placeholder={'부자재 CODE'}*/}
                    {/*                inputData={v.subMaterialSerialCode} onChange={(e) => onChangeArray(i, e,rowList,setRowList)}/>*/}

                    <ListInnerInput type={'withButton'} width={'120px'}
                                    name={'subMaterialSerialCode'} placeholder={'-'}
                                    inputData={v.subMaterialSerialCode} inputDisabled={v.codeDoubleCheck}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList , true )}
                                    clickButton={() => {serialDoubleCheck( v.subMaterialSerialCode , i ).then()}}
                                    buttonText={v.codeDoubleCheck ? '확인됨' : '중복확인'}
                                    buttonWidth={'60px'} disabled={v.codeDoubleCheck || !v.subMaterialSerialCode}/>

                    <ListInnerInput type={'text'} width={'120px'} name={'subMaterialName'} placeholder={'부자재 품명'}
                                    inputData={v.subMaterialName}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList , true )}/>
                    <ListInnerInput type={'drop'} inputData={v.subMaterialUnit}
                                    dropTopDirection={rowListDropDirection( arr , i , subMaterialList )}
                                    onChange={( e ) => onChangeArray( i , e , rowList , setRowList )} tabIndex={- 1}
                                    selectDrop={( v ) => {
                                      setSelectCode( v.id )
                                      setRowList( rowList.map( ( v ) => {return v.id === selectCode ? {...v , ...rowList[i]} : v} ) )
                                      Object.assign( rowList[i] , {subMaterialUnit: v , subMaterialStock: ''} )
                                    }}
                                    name={'subMaterialUnit'} dropMenuList={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>
                    <ListInnerInput type={'unit'} width={'140px'} unit={dataValid( rowList[i].subMaterialUnit , 'ea' )}
                                    name={'subMaterialStock'}
                                    placeholder={'-'}
                                    inputData={v.subMaterialStock?.includes( '.' ) ? v.subMaterialStock : numberLocaleString( v.subMaterialStock )}
                                    limit={15}
                                    onChange={( e ) => {handleDecimalChange( i , e , rowList , setRowList , v.subMaterialUnit )}}/>
                    <ListInnerInput type={'search'} name={'customer'} placeholder={'검색'} tabIndex={- 1}
                                    inputData={v.customer}
                                    clickButton={() => {setAccountSearchModal( {open: true , idx: i} );}}/>

                  </div>
                ) )}

              {rowList.length === 0 &&
                subMaterialList.map( ( v: any , i: number , arr ) => (
                  <React.Fragment>
                    {
                      <GridTableSelect selected={v.subMaterialCode === selectCode}
                                       disabled={rowList.length !== 0 || iconSelect !== - 1}
                                       checkboxNumber={( ( i + 1 ) + page * 10 ) - 10}
                                       className={'grid-list'} onClick={() => setSelectCode( v.subMaterialCode )}
                                       onDoubleClick={() => {
                                         window.location.href = `${authValid() ? `/admin/fas/submaterial/${v?.subMaterialCode}` : `/fas/submaterial/${v?.subMaterialCode}`}`
                                       }}
                                       onMouseOut={() => setFocusList( - 1 )} onMouseOver={() => setFocusList( i )}>
                        <label className={'selectCheckbox'}>
                          <ListInnerInput type={'check'} name={'subMaterialCode'}
                                          onChange={( e: any ) => singleCheckedFunction( e.target.checked , v.subMaterialCode , setCheckedList , checkedList )}
                                          checked={checkedList.includes( v.subMaterialCode )}/>
                        </label>
                        {autoTextValueValidation({value: v.subMaterialSerialCode})}
                        {autoTextValueValidation({value: v.subMaterialName })}
                        {validationValue( v.subMaterialUnit )}
                        <ListInnerInput type={'noneInputUnit'} unit={dataValid( v.subMaterialUnit , 'ea' )}
                                        inputData={numberLocaleString( v.subMaterialStock )}/>
                        {validationValue( v.customer )}
                      </GridTableSelect>
                    }
                  </React.Fragment>
                ) )}
            </GridTableComponent>
            {/*</InfiniteScroll>*/}
          </div>
          {rowList.length === 0 &&
              <PaginationList limit={10} pageLimit={10} page={page} setPage={setPage}
                              blockNum={pageBlock} setBlockNum={setPageBlock}
                              counts={totalPage} disabled={iconSelect !== - 1}/>}

        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default SubMaterials;



