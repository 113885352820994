import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import MenuTitle from "../../../../../components/title/MenuTitle";
import checked from "../../../../../asset/image/check-icon.svg";
import client from "../../../../../common/Config";
import {useParams} from "react-router-dom";

import {
	ContentFrame ,
	femsInfoStyle ,
	NoData ,
	SectionFrame ,
	validationValue
} from "../../../../../style/StyleComponent";
import {Authorization} from "../../../../../common/authorization";

import noImage from '../../../../../asset/image/noImage.svg'
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {
	buttonAuthValid ,
	dataAccessLoading ,
	dataValid ,
	initModal ,
	loadingRemove
} from "../../../../../function/function";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import DetailImageView from "../../../../../components/modal/DetailImageView";
import PasswordChangeModal from "../../../../../components/modal/PasswordChangeModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import PasswordCheckModal from "../../../../../components/modal/PasswordCheckModal";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Notiflix from "notiflix";


const BasicDetail = () => {

	const companyID = useParams()
	const [detailData, setDetailData] = React.useState<any>({})
	const [dataModal,setDataModal] = React.useState<Modal_View_Type>(initModal);
	const [adminPassword,setAdminPassword] = React.useState<string>('');
	const [password,setPassword] = React.useState<boolean>(false);

	const { t }:any = useTranslation();

	const detailCompanyData = async () => {
		try {
			const response = await client.post('/company/detail',{
				companyCode: companyID.id
			},{
				headers: {Authorization}})
			setDetailData(response.data.data)
		}catch (e) {
			console.log(e)
		}
	}

	React.useEffect(()=>{
		detailCompanyData().then()
	},[])

	const solutionArray = ['FAS','P-BAS','P-MS','R-BAS','C-BAS','C-MS','V-MS','A-BAS','F-EMS','산업안전 모니터링'];

	const solutionCheck = (solutionArray: string[], detailData: any) => {
		let checkArray: boolean[] = []
		for (let i = 0; i < solutionArray.length; i++) {
			if (detailData.authorities?.includes(solutionArray[i])) {
				checkArray.push(true)
			} else {
				checkArray.push(false)
			}
		}
		return checkArray
	}

	const [femsInfo, setFemsInfo] = useState<boolean>(false)
	useEffect(()=>{
		if(detailData.authorities?.includes('F-EMS')){
			setFemsInfo(true)
		}
	},[detailData])

	const detailComponent = (title: string, content: string|number|any) => {
		return (
			<div style={{display:'flex'}}>
				<div className={'detail_title'}>{title}</div>
				<div className={'detail_content'}>{dataValid(content)}</div>
			</div>
		)
	}

	const companyPasswordModifyRes = async () =>{
		try {
			dataAccessLoading()
			const response = await client.post('/company/update',{
				...detailData,
				authorities:detailData.authorities.split(','),
				phone_number:detailData.phoneNumber,
				password: adminPassword
			},{headers:{Authorization}})
			const data = response.data
			console.log('완료')
			if(data.message !== 'success'){
				setDataModal({menu:'confirm',message:data.message});
				loadingRemove()
			} else{
				setTimeout(()=>{
					loadingRemove()
					setDataModal({menu:'success',message:t('비밀번호가 변경 되었습니다.')});
				},500)
			}
			loadingRemove()
		}catch ( e ) {
			console.log(e)
			loadingRemove()
		}
	}

	//이메일보내는 테스트
	const sendEmailRes = async () => {
		dataAccessLoading();
		setDataModal(initModal)
		// 비동기 작업을 수행한 후 작업 완료 시 콜백 함수 실행
		setTimeout(() => {
			setPassword(true)
			loadingRemove();
			// 작업 완료 시 호출할 콜백 함수
		}, 1000);
	};

	return (
		<React.Fragment>
				<SectionFrame>

					{dataModal.menu === 'success' ?
						<ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>{setDataModal(initModal)}}/>
						: dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
							: dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

					{dataModal.menu ==='profile' && <DetailImageView image={detailData.imageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}
					{dataModal.menu ==='logo' && <DetailImageView image={detailData.logoImageUrl} closeMenu={()=>{setDataModal(initModal)}}/>}

					{dataModal.menu === 'pw' &&
						<PasswordChangeModal detailInfo={detailData} viewType={true}
																 receivePassword={(data)=>{setAdminPassword(data)}}
																 clickEvent={companyPasswordModifyRes}
																 closeClick={()=>{setDataModal(initModal)}}/>
					}

					{dataModal.menu === 'check' &&
						<PasswordCheckModal detailInfo={detailData} cancelEvent={()=>{setDataModal(initModal)}} viewType={true}
																movePage={`/admin/fas/basic/modify/${detailData.companyCode}`}
																noticeMessage={'* 회원정보 수정을 위해 비밀번호를 확인해주세요.'}
						/>
					}

					{/*{dataModal.menu === 'findPw' &&*/}
					{/*	<FindPasswordModal cancelEvent={()=>{setDataModal(initModal)}} receiveEmail={(data)=>{setEmailStatus(data)}} clickEvent={sendEmailRes}/>*/}
					{/*}*/}
					{/*{password &&*/}
					{/*	<FindPasswordModal viewType={true} clickEvent={sendEmailRes} emailStatus={emailStatus} cancelEvent={()=>{setPassword(false)}}/>*/}
					{/*}*/}

					<MenuTitle title={'업체/기본정보 관리 > 업체 정보 > 조회'} unitCase={'MenuTitle'}/>
					<ContentFrame flexDirection={'row'}>

						<React.Fragment>
							<ViewBox>
								<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
									<MenuTitle title={'업체 정보 조회'} unitCase={'MenuCompTitle'}/>
									<div style={{display: 'flex'}}>
										<MainButtons name={'목록'} width={'56px'} clickEvent={() => {window.location.replace('/admin/fas/basic')}}/>
										<MainButtons name={'수정'} width={'56px'} marginDis={true} disabled={buttonAuthValid('update','2')}
																 clickEvent={() => {
																	 if( detailData.id ){
																		 setDataModal( {menu: 'check'} )
																	 } else{
																		 window.location.href = `/admin/fas/basic/modify/${detailData.companyCode}`
																	 }
																 }}/>
									</div>
								</div>

								<div className={'scroll_func'}>
									{detailComponent(t('업체명'),detailData.companyName)}
									{detailComponent(t('대표'),detailData.president)}
									{detailComponent(t('주소'),
										<React.Fragment>
											<div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
												<div style={{display:'flex',alignItems:'center'}}>
													<div style={{marginRight:'8px'}}>{detailData.address}</div>
													<div>{detailData.detailAddress}</div>
												</div>
												<CopyToClipboard text={detailData.address}
																				 onCopy={()=>Notiflix.Notify.info('주소를 복사하였습니다.')}>
													<ButtonStyle>{'주소 복사'}</ButtonStyle>
												</CopyToClipboard>
											</div>
										</React.Fragment> )}
									{detailComponent(t('전화번호'),detailData.phoneNumber)}
									{detailComponent(t('담당자'),detailData.managerName)}
									{detailComponent(t('담당자 전화번호'),detailData.managerNumber)}
									{detailComponent(t('담당자 이메일'),detailData.email)}
									<div style={{display:'flex'}}>
										<div className={'detail_title'} style={{height:'80px'}}>{t('솔루션 권한')}</div>
										<div className={'detail_content'}>
											<div style={{display:'grid', gridTemplateColumns:'repeat(6,1fr)'}}>
												{solutionCheck(solutionArray, detailData).map((v, i,) => {
													return (
														<CheckBoxComp textColor={v? '#131523' : '#999999'} checked={v}>
															<div className={'checkBox'}
																 style={{backgroundColor: v ? '#F5F6FA':'#fff', border: v ? '1px solid #7E84A3' : '1px solid #F5F6FA',
																	 cursor: v ? 'pointer' : 'default'}}/>
															<div className={'optionText'}>{solutionArray[i]}</div>
														</CheckBoxComp>
													)
												})}
											</div>
										</div>
									</div>
									{detailComponent(t('마이다 프레스'),
										<CheckBoxComp textColor={detailData.midaPress === '1'? '#131523' : '#999999'} checked={detailData.midaPress === '1'}>
											<div className={'checkBox'}
													 style={{backgroundColor: detailData.midaPress === '1'  ? '#F5F6FA':'#fff', border: detailData.midaPress === '1'  ? '1px solid #7E84A3' : '1px solid #F5F6FA',
														 cursor: detailData.midaPress  === '1' ? 'pointer' : 'default'}}/>
											<div className={'optionText'}>{'마이다 프레스 납품업체'}</div>
										</CheckBoxComp>
									)}
									{detailComponent(t('업체 ID'),
																	 (!detailData.id? <NoData height={48}>{'* 현재 등록된 아이디가 없습니다.'}</NoData> :
																		 <div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:'center'}}>
																			 <div>{detailData.id}</div>
																			 <CopyToClipboard text={detailData.id}
																												onCopy={()=>Notiflix.Notify.info('아이디를 복사하였습니다.')}>
																				 <ButtonStyle>{'아이디 복사'}</ButtonStyle>
																			 </CopyToClipboard>
																		 </div>
																	 )
									)}
									{detailComponent(t('업체 PW'),
										<div style={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'572px',opacity:!detailData.id ? 0.5 : 1,pointerEvents:!detailData.id ? 'none' : 'auto'}}>
											<MainButtons name={'변경하기'} disabled={buttonAuthValid('update','2')} buttonType={'cta'} margin={'0'} clickEvent={()=>{setDataModal({menu:'pw'})}}/>
											{/*<div style={{color:'#7E84A3',fontSize:'12px',fontWeight:'bold',textDecoration:'underline',cursor:'pointer'}} onClick={()=>{setDataModal({menu:'findPw'})}}>*/}
											{/*	{'비밀번호 찾기'}*/}
											{/*</div>*/}
										</div>
									)}
									{detailComponent(t('업체코드'),detailData.companyCode)}

									{femsInfo &&
										<div style={{display:'flex'}}>
											<div className={'detail_title'}>{t('F-EMS 고객정보')}</div>
											<div className={'detail_content'}>
													<div style={{display:'flex',alignItems:'center',marginRight:'220px'}}>
														<div style={{...femsInfoStyle}}> {t('고객번호')}</div>
														{validationValue(detailData.custNo)}
													</div>
											</div>
										</div>}


									<div style={{display:'flex',borderBottom:'1px solid #E6E9F4',boxSizing:'border-box',height:'150px'}}>
										<div className={'detail_title'} style={{height:'150px'}}>{t('비고')}</div>
										<div className={'detail_content'}>
											<div className={'detail_notice'}>
												{dataValid(detailData.notice,<NoData height={120}>{t('* 작성된 내용이 없습니다.')}</NoData>)}
											</div>
										</div>
									</div>
								</div>
							</ViewBox>


							{/*이미지영역 */}
							<div style={{width: '1px', backgroundColor: '#e0e0e0', margin: '0 30px'}}/>
							<div style={{width:'100%'}}>
								<div style={{display:'flex',flexDirection:'column'}}>

									<div>
										<MenuTitle title={'프로필'} unitCase={'MenuCompTitle'}/>
										<div>
											<div style={{display:'flex', width:'168px',height:'168px',border:'1px solid #D7DBEC',borderRadius:'6px',overflow:'hidden',marginTop:'20px'}}>
												{!detailData.imageUrl?
													<div style={{backgroundImage:`url(${noImage})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px'}}/>
													:
													<div style={{backgroundImage:`url(${detailData.imageUrl})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px'}} onClick={()=>{setDataModal({menu:'profile'})}}/>}
											</div>
										</div>
									</div>

									<div style={{marginTop:'80px'}}>
										<MenuTitle title={'로고'} unitCase={'MenuCompTitle'}/>
										<div>
											<div style={{display:'flex', width:'168px',height:'168px',border:'1px solid #D7DBEC',borderRadius:'6px',overflow:'hidden',marginTop:'20px'}}>
												{!detailData.logoImageUrl?
													<div style={{backgroundImage:`url(${noImage})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px'}}/>
													:
													<div style={{backgroundImage:`url(${detailData.logoImageUrl})`,backgroundSize:'cover',backgroundPosition:'center',width:'168px',height:'168px'}}  onClick={()=>{setDataModal({menu:'logo'})}}/>}
											</div>
										</div>
									</div>

								</div>
							</div>
						</React.Fragment>
					</ContentFrame>
				</SectionFrame>
		</React.Fragment>


	);
};

export default BasicDetail;

interface CheckBoxProps {
	checked: boolean;
	textColor: string;
}


const ButtonStyle = styled.div`
	display: flex;
	align-items: center;
	height: 32px;
	font-size: 14px;
	transition: all 0.3s;
	border-radius: 4px;
	cursor: pointer;
	width: 80px;
	border: 1px solid #d7dbec;
	justify-content: center;
	font-weight: 700;
	color: #5A607F;
	&:hover{
		background-color: #5A607F;
		color: #fff;
		border: 1px solid #5A607F;
	}
`


const CheckBoxComp= styled.div<CheckBoxProps>`
		 display: flex;
		 align-items: center;
		 height: 30px;
	    
	     .checkBox{
			width: 16px;
			height: 16px;
			border: 1px solid ${ props => props.checked? '#0058FF' : '#7E84A3'};
		    
			box-sizing: border-box;
			position: relative;
			cursor: pointer;
			&::before{
				display: ${ props => props.checked? '' : 'none'};
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: #0058FF;
				background-image: url(${checked});
				background-position: center;
				background-repeat: no-repeat;
			}
			
		}
		
		.optionText{
			margin-left: 8px;
			font-weight: 400;
			font-size: 12px;
			color: ${ props => props.textColor};
			line-height: 20px;
			user-select: none;
			white-space: nowrap;
		}
		
	`

const ViewBox = styled.div`
  width: 784px;
  height: 600px;
  
  .detail{
    &_title{
        display: flex;
        align-items: center;
        height: 48px;
        padding-left: 16px;
        box-sizing: border-box;
        font-weight: 900;
        font-size: 12px;
        min-width: 168px;
        background-color: #F5F6FA;
        border-top: 1px solid #E6E9F4;
    }
	  
    &_content{
      width: 600px;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 24px;
      border-top: 1px solid #E6E9F4;
    }

	  &_notice {
		  width: 600px;
		  box-sizing: border-box;
		  //display: flex;
		  margin: 16px 0;
		  height: 120px;
		  align-self: start;
		  word-break: break-all;
		  line-height: 30px;
		  letter-spacing: 0.5px;
		  white-space: pre-wrap;
		  overflow-x: hidden;
		  overflow-y: scroll;

		  &::-webkit-scrollbar {
			  display: block;
			  width: 15px;
		  }

		  &::-webkit-scrollbar-thumb {
			  background-color: #D5D7E3;
			  border-radius: 10px;
			  background-clip: padding-box;
			  border: 6px solid transparent;
		  }

		  &::-webkit-scrollbar-track {
			  width: 21px;
			  background: transparent;
		  }
	  }
  }

  .scroll_func{
    width: 800px;
    height: 540px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: block;
    }
  }
`
