import React , {useEffect , useState} from 'react';
import {useParams} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import {Modal_View_Type , ProductInfoType} from "../../../../../types/Fundamental";
import {
  authValid ,
  dataAccessLoading ,
  dataValid , formatNumber ,
  initModal , loadingRemove , locationFilter ,
  numberLocaleString ,
} from "../../../../../function/function";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import SelectCaseModal from "../../../../../components/modal/SelectCaseModal";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import FacilityRegisterModal from "../../../../../components/modal/FacilityRegisterModal";
// import InspectionFormModal from "../../../../../components/modal/InspectionFormModal";
import MoldRegisterModal from "../../../../../components/modal/MoldRegisterModal";
import AccountSelectModal from "../../../../../components/modal/AccountSelectModal";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import ProductRegistrationMultiple from "./ProductRegistrationMultiple";
import FacilityProductAutoCheckModal from "../../../../../components/modal/FacilityProductAutoCheckModal";

const ProductRegistration = () => {
  const params = useParams()
  const {productCode} = params
  const selectedCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  const [productState, setProductState] = useState<any>({
    companyCode: selectedCompany.companyCode ,
    customer: [] ,
    notice: '' ,
    processCode: '' ,
    processName: '' ,
    productName: '' ,
    serialCode: '' ,
    facilityCode: [] ,
    moldCode: [] ,
    type: '' ,
    uph: '0' ,
    unit: 'ea' ,
    image: [] ,
    workStandardImg: [] ,
    codeDoubleCheck: false ,
    cavity: '1',
    weight:'0',
    weightUnit:'g',
    length:'0'
  })

  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const {t}:any= useTranslation()
  const [duplicateCount, setDuplicateCount] = useState(0)
  const initProductData = {
    customerName: '' , customerModelName: '' , serialCode: '' , unit: '' , productName: '' ,
    type: '' , productCode: '' , uph: '' , processName: ''
  }
  const [bomRegister , setBomRegister] = useState<ProductInfoType>(initProductData)
  // 품목에 등록 가능한 공정종류 리스트와 페이지 state
  const [processList , setProcessList] = useState<any[]>([])
  const [processPage , _] = useState<number>(1)
  const [ , setProcessTotal] = useState<number>(1)
  const [multipleReg, setMultipleReg] = useState<boolean>(false)
  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string) => {
    const {name, value} = e.target
    setProductState({...productState,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? formatNumber(value) :
          value.trim()})
  }
  const closeModify = () => {
    if(dataModal.message?.includes('삭제')){
      window.location.href =authValid() ? `/admin/fas/product` : `/fas/product`
      setDataModal(initModal)
    }else{
      setDataModal(initModal)
    }

    if(dataModal.message === t('품목이 등록 되었습니다.')){
      window.location.href = authValid() ? `/admin/fas/product/${productCode}` : `/fas/product/${productCode}`
      setDataModal(initModal)
    }else{
      setDataModal(initModal)
    }
  }

  const productRegisterRes = async () => {
    if(!productState?.customer){
      setDataModal({menu: 'confirm' , message: '거래처와 모델을 선택해 주세요.'})
    }
    else if(!productState?.serialCode){
      setDataModal({menu: 'confirm' , message: '품목 코드를 입력해 주세요.'})
    }
    else if(!productState?.codeDoubleCheck){
      setDataModal({menu: 'confirm' , message: '품목 코드 중복 확인을 해주세요.'})
    }
    else if(!productState?.productName){
      setDataModal({menu: 'confirm' , message: '품목명을 입력해 주세요.'})
    }
    else if(!productState?.type){
      setDataModal({menu: 'confirm' , message: '품목 종류를 선택해 주세요.'})
    }
    else if(!productState?.unit){
      setDataModal({menu: 'confirm' , message: '폼목 단위를 선택해 주세요.'})
    }
    else if(!productState?.processCode){
      setDataModal({menu: 'confirm' , message: '생산 공정을 선택해 주세요.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/product/register' , [{...productState,
          image: dataValid(productState.image, []),
          workStandardImg: dataValid(productState.workStandardImg,[]),
          autoWorkHistoryCheck: Array(productState?.facilityCode.length).fill(""),
          autoWorkOrderCheck: Array(productState?.facilityCode.length).fill(""),
          countType: Array(productState?.facilityCode.length).fill(""),
          slideCountRange: Array(productState?.facilityCode.length).fill(""),
          facilityStatus: Array(productState?.facilityCode.length).fill("impossible"),
        }] , {headers: {Authorization}})
        const data = response.data
        if(response.data.status !== 200){
          setDataModal({menu: 'confirm' , message: t(data.message) , status: data.status})
          loadingRemove()
        } else{
          setTimeout(() => {
            setDataModal( {menu: 'success' , message: t( '품목이 등록 되었습니다.' )} )
            loadingRemove()
          } , 500)
        }
      }
      catch(error){
        console.log(error)
      }
    }
  }

  const serialDoubleCheck = async () => {
    try {
      const response = await client.post('/product/serial/check',{
        companyCode: selectedCompany.companyCode,
        serialCode: productState?.serialCode,
      },{headers:{Authorization}})

      const duplicateCount = response.data.data
      setDuplicateCount(response.data.data[0])
      if(duplicateCount[0] === 0){
        setProductState((prev:any)=>({...prev, codeDoubleCheck: true}))
        setDataModal({menu:'confirm', status: 200, message: '중복되지 않은 시리얼CODE 입니다.'})
      }else{
        setDataModal({menu: 'doubleCheck', status: 201, message: '이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'})
      }
    }catch ( e ) {
      console.log(e)
      setDataModal({menu:'confirm', message:' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'})
    }
  }

  const processListRes = async () => {
    try {
      const response = await client.get(`/process/type/list/${processPage}/30/${selectedCompany.companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      setProcessTotal(data.total_page)
      if(data.total_page > 1 && processPage > 1){
        setProcessList((prev: any) => prev.concat(data.row))
      }
      else{
        setProcessList(data.row)
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    processListRes().then();
  }, []);

  return (
    <React.Fragment>
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'}
                                                  closeEvent={()=> {
                                                    setDataModal( initModal )
                                                    window.location.href = authValid() ? `/admin/fas/product` : `/fas/product`
                                                  }}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=> {setDataModal( initModal )}}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=> {setDataModal( initModal )}}/>: null}

      {dataModal.menu === 'doubleCheck' &&
          <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                           statusText={dataModal.message}
                           noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                           clickEvent={()=>{
                             if(dataModal.status === 200){
                               setProductState((prev:any)=>({...prev, codeDoubleCheck: true}))
                             }else{
                               setProductState((prev:any)=>({...prev, serialCode: productState?.serialCode + ` (${duplicateCount})`, codeDoubleCheck: true}))
                             }
                             setDataModal(initModal)
                           }
                           } cancelEvent={()=>setDataModal(initModal)} buttonWidth={'124px'} buttonFontSize={'12px'}/>}

      {dataModal.menu === 'select' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* 작성중이신 항목은 저장되지 않습니다.'}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{window.location.href = authValid()? `/admin/fas/product/inspection/${productCode}` : `/fas/product/inspection/${productCode}`}}/>}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message} clickEvent={closeModify}/>}


      {dataModal.menu === 'facility' &&

          <FacilityProductAutoCheckModal companyCode={selectedCompany.companyCode}
                                         receivedFacility={( e ) => {
                                           setProductState((prev:any)=>({...prev,
                                             facilityCode: e.facilityCode ,
                                             autoWorkOrderCheck: e.autoWorkOrderCheck ,
                                             autoWorkHistoryCheck: e.autoWorkHistoryCheck ,
                                             loadTonRange: e.loadTonRange ,
                                             slideCountRange: e.slideCountRange ,
                                             countType: e.countType ,
                                             facilityStatus: e.facilityStatus,
                                             channel: e.channel
                                           }))
                                         }}
                                         selectProduct={productState}
                                         cancelEvent={() => setDataModal( initModal )}
                                         selectedFacility={productState.facilityCode}/>

          // <FacilityRegisterModal companyCode={selectedCompany.companyCode}
          //                        receivedFacility={(v)=> {
          //                          Object.assign(productState, {facilityCode: v})
          //                        }}
          //                        selectProduct={productState}
          //                        selectedFacility={dataValid(productState?.facilityCode,[])}
          //                        cancelEvent={()=>setDataModal(initModal)}/>

      }

      {dataModal.menu === 'mold' &&
          <MoldRegisterModal selectProduct={productState}
                             companyCode={selectedCompany.companyCode}
                             receivedMold={(v)=>{
                               Object.assign(productState, {moldCode: v})
                             }}
                             selectedMold={dataValid(productState?.moldCode,[])}
                             cancelEvent={()=>setDataModal(initModal)}/>}

      {/*{modalState.status === 'inspectionReg' &&*/}
      {/*    <ProductInspectionReg cancelEvent={()=> setModalState({open: false, status:'', message:''})}*/}
      {/*                          confirmEvent={()=> setModalState({open: false, status:'', message:''})}/>}*/}
      {/*{modalState.status === 'inspectionInfo' &&*/}
      {/*    <ProductInspectionResult  confirmEvent={()=> setModalState({open: false, status:'', message:''})*/}
      {/*    }/>}*/}

      {dataModal.menu === 'customer' &&
          <AccountSelectModal initCustomerData={productState?.customer.map((v:any)=>({customerName: v.customerName, customerCode: v.customerCode}))}
                              initModelData={productState?.customer.map((v:any)=>({customerName: v.customerName, customerCode: v.customerCode, customerModelName: v.customerModelName, customerModelCode: v.customerModelCode}))}
                              receivedData={(e)=>{
                                setProductState((prev:any)=> ({...prev, customer: e}))
                              }} closeModal={()=>setDataModal(initModal)}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              clickEvent={()=>{window.location.href = authValid() ? `/admin/fas/product` : `/fas/product`}}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={productRegisterRes}
                              cancelEvent={()=>{setDataModal(initModal)}}/>}


      <SectionFrame>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 품목 기본정보 > 등록' + (multipleReg?'(다중)':'')}
                     unitCase={'MenuTitle'}/>

          {
            multipleReg?
              <div style={{display:'flex'}}>
                <MainButtons name={'목록'} width={'80px'} margin={'48px 8px 0 0'}
                             clickEvent={()=>{setDataModal({menu:'cancel',message:'목록으로 이동 하시겠습니까?'})}} />
                <MainButtons name={'개별등록'} width={'80px'} margin={'48px 0 0 8px'}
                             clickEvent={()=>setMultipleReg(false)} />
              </div>
              :
              <MainButtons name={'다중등록'} width={'80px'} margin={'48px 0 0 0'}
                           clickEvent={()=>setMultipleReg(true)} />
          }
        </div>
        <ContentFrame width={'1272px'} height={'680px'} flexDirection={multipleReg?'column':'row'} justify={'flex-start'}>

          {
            multipleReg?
              //다중 등록
              <ProductRegistrationMultiple/>
              :

              //개별 등록(기존 등록 페이지)
          <React.Fragment>
            <div className={'scroll_box'} style={{width:'784px'}}>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px'}}>
                <MenuTitle title={'품목 기본정보 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{display:'flex'}}>
                  <MainButtons width={'56px'} name={'취소'}
                               clickEvent={()=>{setDataModal({menu:'cancel',message:'작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'})}}/>
                  <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                               clickEvent={()=>{setDataModal({menu:'register',message:'품목을 등록 하시겠습니까?'})}}/>
                </div>
              </div>

              <InputContainer>
                {authValid() && <ListInfoComponent title={t('업체명')} value={selectedCompany?.companyName} bottomBorder={false}/>}

                <div style={{display:'grid', gridTemplateColumns: '1fr 1fr'}}>
                  {authValid() &&
                      <React.Fragment>
                        <ListInfoComponent title={t('대표자')} value={dataValid(selectedCompany?.president)} bottomBorder={false}/>
                        <ListInfoComponent title={t('지역')} value={dataValid(locationFilter(selectedCompany?.address))} bottomBorder={false}/>
                      </React.Fragment>}
                  <RegisterComponent type={'textButton'} title={t('거래처')} name={'customerName'}
                                     disabled={productState?.customer?.length !== 0} buttonWidth={'40px'}
                                     value={productState?.customer?.length > 0 ? dataValid(productState?.customer[0]?.customerName + ((productState?.customer?.length -1) !== 0 ?` 외 ${productState?.customer?.length-1}` : ''),'') : ''}
                                     required={true} buttonMargin={'0'}
                                     clickEvent={ () => setDataModal({menu:'customer'})} paddingRight={true}
                                     placeholder={t('거래처')}/>

                  <RegisterComponent type={'textButton'} title={t('모델')} name={'customerModelName'}
                                     disabled={productState?.customer?.length !== 0} buttonWidth={'40px'}
                                     value={productState?.customer?.length > 0 ? dataValid(productState?.customer[0]?.customerModelName + ((productState?.customer?.length -1) !== 0 ?` 외 ${productState?.customer?.length-1}` : ''),'') : ''}
                                     required={true} buttonMargin={'0'}
                                     clickEvent={ () => setDataModal({menu:'customer'})}
                                     placeholder={t('모델')}/>

                  {/*<RegisterComponent type={'search'} title={t('모델')} name={'customerModelName'}*/}
                  {/*                   value={productState?.customer?.length > 0 ? dataValid(productState?.customer[0]?.customerModelName + ((productState?.customer?.length -1) !== 0 ?` 외 ${productState?.customer?.length-1}` : ''),'') : ''}*/}
                  {/*                   onChange={onChange} required={true}*/}
                  {/*                   clickEvent={ () => {*/}
                  {/*                     setDataModal( {menu: 'customer'} )*/}
                  {/*                   }}*/}
                  {/*                   placeholder={'모델'}/>*/}
                  <RegisterComponent type={'doubleCheck'} title={'CODE(품번)'} name={'serialCode'}
                                     value={productState?.serialCode} required={true}
                                     paddingRight={true} disabled={!productState?.serialCode || productState?.codeDoubleCheck }
                                     clickEvent={serialDoubleCheck}
                                     onChange={(e)=>setProductState((prev:any)=>({...prev, serialCode: e.target.value.trim(), codeDoubleCheck: false}))}/>
                  <RegisterComponent type={'text'} title={'품명'} name={'productName'} value={productState?.productName} required={true}
                                     onChange={onChange}/>

                  <RegisterComponent type={'unit'} title={'캐비티'} name={'cavity'} value={numberLocaleString(productState?.cavity)} limit={11}
                                     onChange={(e)=>onChange(e,'number')} unit={'ea'} paddingRight={true} />

                  <RegisterComponent type={'inputWidthDrop'} title={'품목중량'} name={'weight'} dropWidth={'86px'}
                                     dropArray={['ea','kg','g','Ton']} dropValue={productState?.weightUnit}
                                     value={productState?.weight} limit={11}
                                     onChange={(e)=>onChange(e,'decimal')}
                                     dropClickEvent={(v)=>setProductState((prev:any)=>({...prev, weightUnit: v}))}
                                     paddingRight={true} />

                  <RegisterComponent type={'empty'} title={'-'} name={''} value={''}/>

                  <RegisterComponent type={'unit'} title={'세로(Feeder)'} name={'length'}
                                     unit={'mm'}
                                     value={productState?.length} limit={11}
                                     onChange={(e)=>onChange(e,'decimal')}/>

                  <RegisterComponent type={'drop'} title={'품목 종류'} required={true}
                                     name={'type'} value={productState?.type} onChange={onChange}
                                     dropClickEvent={(v)=>setProductState((prev:any)=>({...prev, type: v}))}
                                     dropArray={['완제품','반제품','재공품']}/>

                  <RegisterComponent type={'drop'} title={'단위'} required={true} dropWidth={'208px'}
                                     name={'unit'} value={dataValid(productState?.unit, 'ea')}
                                     onChange={onChange} dropClickEvent={(v)=>setProductState((prev:any)=>({...prev, unit: v, uph:''}))}
                                     dropArray={['ea','kg','g','Ton']}/>
                  <RegisterComponent type={'drop'} title={'생산 공정'} required={true}
                                     name={'processName'} value={productState?.processName}
                                     onChange={onChange}
                                     dropClickEvent={(v)=>setProductState((prev:any)=>({...prev, processName: v, processCode: processList.filter((data:any)=>data.processName === v)[0].processCode}))}
                                     dropArray={processList.map((v:any)=>v.processName)}/>
                  <ListInfoComponent type={'button'} title={'기계'} buttonName={productState?.facilityCode?.length === 0 ? '기계 등록' : '기계 수정'}
                                     clickEvent={()=>setDataModal({menu:'facility'})}
                                     bottomBorder={false}/>
                  <RegisterComponent type={'unit'} title={'기준 UPH'} name={'uph'} unit={dataValid(productState?.unit, 'ea')}
                                     value={productState?.unit === 'ea'? numberLocaleString(productState?.uph) : productState?.uph}
                                     limit={productState?.unit === 'ea'? 11 : 15} paddingRight={true}
                                     onChange={(e)=>{productState?.unit === 'ea'? onChange(e,'number') : onChange(e,'decimal')}}/>
                  <ListInfoComponent type={'button'} title={'BOM'}
                                     disabled={true} buttonName={'BOM 등록'}
                                     bottomBorder={false}/>
                  <ListInfoComponent type={'button'} title={t('초·중·종 검사')}
                                     buttonName={ t('검사항목 등록')} contentText={t('검사양식 보기')} disabled={true}
                                     bottomBorder={false}/>
                  <ListInfoComponent type={'button'} title={'금형'} buttonName={'금형 등록'}
                                     clickEvent={()=>setDataModal({menu:'mold'})}
                                     bottomBorder={false}/>
                  {/*<ListInfoComponent type={'button'} title={'초·중·종 검사'}*/}
                  {/*                   buttonName={'검사항목 등록'}*/}
                  {/*                   clickEvent={()=> {*/}
                  {/*                       setDataModal({menu:'select',message:'등록 페이지로 이동 하시겠습니까?'})*/}
                  {/*                   }}*/}
                  {/*                   bottomBorder={false}/>*/}
                </div>

                <RegisterComponent title={'비고'} type={'textarea'} name={'notice'}
                                   onChange={onChange}
                                   value={productState?.notice} bottomBorder={true}/>
              </InputContainer>
            </div>

            <div style={{width: '1px', height:'549px', backgroundColor: '#E6E9F4', margin: '30px'}}/>
            <div className={'scroll_comp'} style={{width:'377px',overflowY:'scroll'}}>
              <div style={{display:'flex', alignItems:'center',justifyContent:'space-between',marginBottom:'16px'}}>
                <MenuTitle title={'품목 이미지 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{color:'#7E84A3',fontSize:'12px',lineHeight:'16px', height:'34px'}}>
                  <div>{t('*최대 4개의 이미지 등록 가능')}</div>
                  <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                </div>
              </div>
              <TotalImageUploadBox receiveKey={productState?.image}
                                   receiveUrl={productState?.imageUrl} imageLength={4}
                                   imageKeyArray={(data)=>{setProductState((prev:any) => ({...prev, image:data}))}}
                                   profileSelect={false}/>

              <div style={{display:'flex',justifyContent:'space-between', alignItems:'center',margin:'24px 0 16px'}}>
                <MenuTitle title={'작업표준서 등록'} unitCase={'MenuCompTitle'}/>
                <div style={{color:'#7E84A3',fontSize:'12px',lineHeight:'16px', height:'34px'}}>
                  <div>{t('*최대 4개의 이미지 등록 가능')}</div>
                  <div>{t('*jpg, png 10mb 이하의 파일 등록 가능')}</div>
                </div>
              </div>

              <TotalImageUploadBox receiveKey={productState?.workStandardImg}
                                   receiveUrl={productState?.workStandardImgUrl} imageLength={4}
                                   imageKeyArray={(data)=>{setProductState((prev:any) => ({...prev, workStandardImg:data}))}}
                                   profileSelect={false}/>
            </div>
          </React.Fragment>
          }
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default ProductRegistration;



const InputContainer = styled.div`

  height: 570px;
  width: 784px;
  overflow-x: hidden;
  overflow-y: scroll;

  & > div {
    user-select: none;
    display: flex;
    min-height: 48px;
    line-height: 48px;
    box-sizing: border-box;
  }

  //.scroll_func{
  //  width: 810px;
  //  height: 584px;
  //  overflow-y: scroll;
  //  overflow-x: hidden;
  //  &::-webkit-scrollbar {
  //    display: block;
  //  }
  //}


  .formText {
    font-size: 12px;
    color: #171725;
    opacity: 0.5;
    display: flex;
    white-space: nowrap;
    font-weight: 400;
    margin-left: 8px;
  }

  .indexBox {

    line-height: 30px;
    background-color: #F5F6FA;
    box-sizing: border-box;
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 800;
    border-bottom: 1px solid #e7e9ee;
  }

  .inputWrap {
    width: 616px;
    border-bottom: 1px solid #e7e9ee;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 16px;
     
  }
`
