import React , {useEffect , useState} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  initModal ,
  loadingRemove ,
} from "../../../../../function/function";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import AttachMultipleUploadBox from "../../../../../components/input/AttachMultipleUploadBox";

const EventRegistration = () => {
  const {t}: any = useTranslation()
  const [dataModal , setDataModal] = React.useState<Modal_View_Type>( initModal )
  const [imageKeyState , setImageKeyState] = React.useState<string[]>( [] )
  const [inputState , setInputState] = useState( {
                                                   content: '' ,
                                                   title: '' ,
                                                   image: '' ,
                                                 } )
  const [fileKey,setFileKey] = React.useState<any[]>([]);
  const onChangeValue = ( e: any ) => {
    const {value , name} = e.target
    setInputState( {
                     ...inputState ,
                     [name]: value
                   } );
  }
  const infoRegisterRes = async () => {
    if( inputState.title === '' ){
      setDataModal( {menu: 'confirm' , message: '제목을 입력해 주세요.'} )
    } else if( inputState.content === '' ){
      setDataModal( {menu: 'confirm' , message: '내용을 입력해 주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( '/infoNotice/register' , {
          ...inputState ,
          image: imageKeyState ,
          file: fileKey
        } , {headers: {Authorization}} )
        const data = response.data
        if( data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            setDataModal( {menu: 'success' , message: '저장 되었습니다.'} )
            loadingRemove()
          } , 500 )
        }
      } catch ( e: any ) {
        console.log( e )
        setDataModal( {menu: 'error' , message: e.message} )
        loadingRemove()
      }
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {
          if( dataModal.message?.includes( '저장' ) ){
            window.location.href = `/admin/fas/info`
          }
          setDataModal( initModal )
        }}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t( dataModal.message )} state={'error'}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup
              clickEvent={() => {
                if( dataModal.message?.includes( '저장' ) ){
                  window.location.href = `/admin/fas/info`
                }
                setDataModal( initModal )
              }} statusText={t( dataModal.message )}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={t( dataModal.message )}
                              viewType={true}
                              clickEvent={() => {window.location.href = `/admin/fas/info`}}
                              cancelEvent={() => {setDataModal( initModal )}}
                              noticeText={t( ' * " 예 " 선택시 저장없이 이동합니다.' )}/>
      }


      <MenuTitle title={'게시판 관리 > 이벤트&공지 관리 > 등록'} unitCase={'MenuTitle'}/>
      <ContentFrame>
        <div style={{display: 'flex' , userSelect: 'none'}}>

          <RegisterBox>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'이벤트&공지 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons name={'취소'} width={'56px'}
                             clickEvent={() => {
                               setDataModal( {
                                               menu: 'cancel' ,
                                               message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                             } )
                             }}/>
                <MainButtons name={'저장'} buttonType={'cta'} marginDis={true}
                             disabled={buttonAuthValid( 'create' , '20' )}
                             width={'56px'} clickEvent={infoRegisterRes}/>
              </div>
            </div>

            <RegisterComponent type={'text'} name={'title'} value={inputState.title} required={true} maxHeight={'600px'}
                               title={t( '제목' )} onChange={onChangeValue} placeholder={t( '제목을 입력해 주세요.' )}/>

            <AttachMultipleUploadBox fileKeyArray={setFileKey} fileKeyLength={5}
                                     placeholder={'*업로드 버튼을 클릭하여 파일을 업로드 해주세요.'}  />

            <div style={{display: 'flex' , borderBottom: '1px solid #E6E9F4' , boxSizing: 'border-box'}}>
              <div className={'input_title'} style={{height: '336px'}}>{t( '내용' )}</div>
              <div className={'input_box'} style={{height: '336px'}}>
                <InputTextArea placeholder={t( '내용을 입력해주세요.' )} maxLength={4000}
                               name={'content'}
                               onChange={onChangeValue}
                               value={inputState.content}
                />
              </div>
            </div>

          </RegisterBox>

          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , marginRight: '28px'}}/>

          <div style={{width: '377px'}}>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
              <MenuTitle title={'이미지 등록'} unitCase={'MenuCompTitle'}/>
              <div style={{color: '#7E84A3' , fontSize: '12px' , lineHeight: '16px' , height: '34px'}}>
                <div>{t( '*최대 6개의 이미지 등록 가능' )}</div>
                <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
              </div>
            </div>
            <TotalImageUploadBox imageKeyArray={( data ) => setImageKeyState( data )}
                                 profileSelect={false} imageLength={6}/>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default EventRegistration;
const RegisterBox = styled.div`
  width: 784px;
  height: 620px;
  padding-right: 28px;
  overflow-y: scroll;
  .input {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;

      &:after {
        content: '*';
        margin-left: 4px;
        color: red;
      }
    }

    &_box {
      border-top: 1px solid #E6E9F4;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 16px;
    }
`

const InputTextArea = styled.textarea`
  width: 600px;
  height: 316px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d7dbec;
  font-family: 'Nanum Gothic', sans-serif;

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: normal;
    font-family: 'Nanum Gothic', sans-serif;
  }
  //overflow-y: overlay;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: block;
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`
