import React , {useEffect , useState} from 'react';
import styled from 'styled-components';
import MenuTitle from "../../../../../components/title/MenuTitle";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import {useParams} from "react-router-dom";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {useTranslation} from "react-i18next";
import MainButtons from "../../../../../components/button/MainButtons";
import {Modal_View_Type} from "../../../../../types/Fundamental";
import {
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  initModal ,
  loadingRemove
} from "../../../../../function/function";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import AttachMultipleUploadBox from "../../../../../components/input/AttachMultipleUploadBox";

const EventModify = ( {} ) => {
  const params = useParams()
  const {id} = params
  const [imageKeyState , setImageKeyState] = useState<string[]>( [] )
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal );
  const [infoModify , setInfoModify] = useState<any>( {} )

  const [receiveFileData, setReceiveFileData] = useState<any>({fileKey:[],fileName:[],fileUrl:[]})
  const [fileKeyState,setFileKeyState] = useState<string[]>([]);


  const {t}: any = useTranslation()
  const detailInfo = async () => {
    try {
      const response = await client.post( '/infoNotice/detail' , {
        infoNum: params.id
      } , {
                                            headers: {Authorization}
                                          } )
      const data = response.data.data

      const {file, fileUrl, fileName} = data
      setInfoModify(data)
      setReceiveFileData(({fileKey: file,fileName: fileName, fileUrl: fileUrl}))

    } catch ( e ) {
      console.log( e )
    }
  }
  React.useEffect( () => {
    detailInfo().then()
  } , [] )
  const onChange = ( e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
    const {name , value} = e.target
    setInfoModify( {...infoModify , [name]: value} )
  }
  const infoModifyRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( '/infoNotice/update' , {
        ...infoModify ,
        image: imageKeyState ,
        file: dataValid(fileKeyState,[])
      } , {headers: {Authorization}} )

      if( response.data.status !== 200 ){
        setDataModal( {menu: 'confirm' , message: t( response.data.message )} )
        loadingRemove()
      } else{
        setTimeout( () => {
          loadingRemove()
          setDataModal( {menu: 'success' , message: '수정 되었습니다.'} )
        } , 500 )
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  const infoDeleteRes = async () => {
    try {
      setDataModal( initModal )
      dataAccessLoading()
      const response = await client.post( '/infoNotice/delete' , {
        infoNum: id
      } , {headers: {Authorization}} )
      if( response.data.status !== 200 ){
        setDataModal( {menu: 'confirm' , message: t( response.data.message )} )
        loadingRemove()
      } else{
        setTimeout( () => {
          loadingRemove()
          setDataModal( {menu: 'success' , message: '삭제 되었습니다.'} )
        } , 500 )
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'error' , message: e.message} )
      loadingRemove()
    }
  }
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );

  return (
    <SectionFrame>
      {dataModal.menu === 'success' ? <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {
          setDataModal( initModal )
          if( dataModal.message?.includes( '수정' ) ){
            window.location.href = `/admin/fas/info/${infoModify?.infoNum}`
          } else if( dataModal.message?.includes( '삭제' ) ){
            window.location.href = `/admin/fas/info`
          }
        }}/>
        : dataModal.menu === 'error' ?
          <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => { setDataModal( initModal ) }}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                    closeEvent={() => { setDataModal( initModal ) }}/> : null}
      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={dataModal.message}
                        clickEvent={() => {
                          setDataModal( initModal )
                          if( dataModal.message?.includes( '수정' ) ){
                            window.location.href = `/admin/fas/info/${infoModify?.infoNum}`
                          } else if( dataModal.message?.includes( '삭제' ) ){
                            window.location.href = `/admin/fas/info`
                          }
                        }}
          />
      }

      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
                                                          clickEvent={infoDeleteRes}
                                                          cancelEvent={() => setDataModal( initModal )}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={() => {
                                infoModifyRes().then();
                                setDataModal( initModal )
                              }}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              viewType={true}
                              clickEvent={() => { window.location.href = `/admin/fas/info/${infoModify?.infoNum}`}}
          />
      }

      <MenuTitle title={'게시판 관리 > 이벤트&공지 관리 > 수정'} unitCase={'MenuTitle'}/>
      <ContentFrame>
        <div style={{display: 'flex' , userSelect: 'none'}}>
          <EditBox>
            <div
              style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , marginBottom: '16px'}}>
              <MenuTitle title={t( '이벤트&공지 수정' )} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex'}}>
                <MainButtons width={'56px'} name={'취소'}
                             clickEvent={() => {
                               setDataModal( {menu: 'cancel' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )
                             }}/>
                <MainButtons name={'삭제'} disabled={buttonAuthValid( 'delete' , '20' )} width={'56px'}
                             clickEvent={() => {setDataModal( {menu: 'select' , message: '정말 삭제 하시겠습니까?'} )}}/>
                <MainButtons buttonType={'cta'} width={'56px'} name={'저장'} marginDis={true}
                             disabled={buttonAuthValid( 'update' , '20' )}
                             clickEvent={() => {setDataModal( {menu: 'register' , message: '이대로 수정하시겠습니까?'} )}}/>
              </div>
            </div>

            <div style={{overflowY:'scroll',position:'relative', maxHeight:'570px'}}>
              <div style={{display: 'flex'}}>
                <div className={'detail_title'} style={{background: "#E6E9F4"}}>{t( '게시글 No.' )}</div>
                <div className={'detail_content'}>{infoModify?.infoNum}</div>
                <div className={'detail_title'} style={{background: "#E6E9F4"}}>{t( '등록일' )}</div>
                <div className={'detail_content'}>{infoModify?.modifiedDate}</div>
              </div>

              <RegisterComponent type={'text'} name={'title'} value={infoModify?.title} required={true}
                                 title={t( '제목' )} onChange={onChange} placeholder={t( '제목을 입력해 주세요.' )}/>

              <AttachMultipleUploadBox fileKeyArray={setFileKeyState} fileKeyLength={5}
                                       receiveKey={dataValid(receiveFileData.fileKey,[])}
                                       receiveUrl={dataValid(receiveFileData.fileUrl,[])}
                                       receiveFileName={dataValid(receiveFileData.fileName,[])}
                                       placeholder={'* 업로드 버튼을 클릭하여 파일을 등록 해주세요.'}/>

              <div style={{display: 'flex' , borderBottom: '1px solid #E6E9F4' , boxSizing: 'border-box'}}>
                <div className={'detail_title_require'} style={{height: '336px'}}>{t( '내용' )}</div>
                <div className={'detail_input'} style={{height: '336px'}}>
                  <InputTextArea onChange={onChange} name={'content'} value={infoModify?.content}/>
                </div>
              </div>
            </div>

          </EditBox>

          <div style={{width: '1px' , backgroundColor: '#e0e0e0' , margin: '0 28px'}}/>

          <div style={{width: '377px' , boxSizing: 'border-box'}}>
            <div
              style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '20px'}}>
              <MenuTitle title={'이미지 수정'} unitCase={'MenuCompTitle'}/>
              <div style={{color: '#7E84A3' , fontSize: '12px' , lineHeight: '16px' , height: '34px'}}>
                <div>{t( '*최대 6개의 이미지 등록 가능' )}</div>
                <div>{t( '*jpg, png 10mb 이하의 파일 등록 가능' )}</div>
              </div>
            </div>

            <TotalImageUploadBox receiveKey={infoModify?.image} receiveUrl={infoModify?.imageUrl} imageLength={6}
                                 imageKeyArray={( data ) => setImageKeyState( data )} profileSelect={false}/>
          </div>
        </div>
      </ContentFrame>
    </SectionFrame>
  );
};
export default EventModify;
const EditBox = styled.div`
  width: 784px;
  height: 620px;

  .detail {
    &_title {
      display: flex;
      align-items: center;
      height: 48px;
      width: 168px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;
    }

    &_title_require {
      display: flex;
      align-items: center;
      height: 48px;
      width: 168px;
      padding-left: 16px;
      box-sizing: border-box;
      font-weight: 900;
      font-size: 12px;
      min-width: 168px;
      background-color: #F5F6FA;
      border-top: 1px solid #E6E9F4;

      &:after {
        content: '*';
        margin-left: 4px;
        color: red;
      }
    }

    &_content {
      font-size: 14px;
      font-family: "NanumGothicOTF", sans-serif;
      width: 224px;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }

    &_input {
      font-family: "NanumGothicOTF", sans-serif;
      display: flex;
      align-items: center;
      border-top: 1px solid #E6E9F4;
      padding-left: 16px;
      width: 600px;
    }
  }
`
const InputTextArea = styled.textarea`
  width: 100%;
  height: 316px;
  resize: none;
  outline: none;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #d7dbec;
  font-family: "NanumGothicOTF", sans-serif;

  &::placeholder {
    font-size: 14px;
    color: rgba(23, 23, 37, 0.3);
    font-weight: 700;
    font-family: "NanumGothicOTF", sans-serif;
  }

  &:focus {
    border: 1px solid #0058ff;
  }

  //overflow-y: overlay;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #E6E9F4;
    border-radius: 10px;
    background-clip: padding-box;
    border: 6px solid transparent;
  }
`
