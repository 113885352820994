import React , {useEffect} from 'react';
import MenuTitle from "../../../../../components/title/MenuTitle";
import {
  autoTextValueValidation ,
  ContentFrame ,
  NoData ,
  SectionFrame ,
  validationValue
} from "../../../../../style/StyleComponent";
import styled from 'styled-components';
import DetailImageView from "../../../../../components/modal/DetailImageView";
import PaginationList from "../../../../../components/pagination/PaginationList";
import {useTranslation} from "react-i18next";
import {useRecoilState , useRecoilValue} from "recoil";
import {
  persistBlockPage , persistCompanyData ,
  persistPage , searchInputState ,
  selectedCode , userPersistCompanyData
} from "../../../../../common/StateManage";
import MainButtons from "../../../../../components/button/MainButtons";
import SearchBar from "../../../../../components/input/SearchBar";
import {buttonAuthValid , dataValid , initError , menuVisitLogRegister} from "../../../../../function/function";
import {useLocation} from "react-router-dom";
import {Error_Type} from "../../../../../types/Fundamental";
import {ListCustomHooks , ListSearchPostCustomHooks} from "../../../../../CustomHooks";
import {GridTableComponent, GridTableSelect} from "../../../../../style/StyleModule";

const FactoryManagement = () => {
  const location = useLocation()
  const authValid = location.pathname.includes( '/admin' )
  const userCompanyData = useRecoilValue(authValid ? persistCompanyData : userPersistCompanyData )
  const [pageState , setPageState] = useRecoilState<number>( persistPage );
  const [pageBlock , setPageBlock] = useRecoilState<number>( persistBlockPage )
  const [totalPage , setTotalPage] = React.useState<number>( 0 )
  const [factoryDataList , setFactoryDataList] = React.useState<any[]>( [] );
  const [imageViewData , setImageViewData] = React.useState<boolean>( false );
  const [drawingImageState , setDrawingImageState] = React.useState<{ url: string, factoryName: string }>( {
                                                                                                             url: '' ,
                                                                                                             factoryName: ''
                                                                                                           } )
  const [selectFactory , setSelectFactory] = useRecoilState( selectedCode );
  const [searchInput , setSearchInput] = useRecoilState<string>( searchInputState )
  const [prevSearch , setPrevSearch] = React.useState<string>( searchInput )
  const [errorState , setErrorState] = React.useState<Error_Type>( initError )
  const {t}: any = useTranslation();
  const factoryDataTitle = authValid ?
    ['업체명' , '대표' , '공장명' , '공장 주소' , '전화번호' , '공장 담당자' , '공장 담당자 전화번호' , '도면정보'] : ['공장명' , '공장 주소' , '전화번호' , '공장 담당자' , '공장 담당자 전화번호' , '도면정보']
  const FactoryList = () => ListCustomHooks( `/factory/list/${pageState}/10/${dataValid(userCompanyData.companyCode,'@')}` , pageState , setTotalPage , setPageBlock , setFactoryDataList , setErrorState , setSearchInput , setPrevSearch )
  // const FactorySearchList = () => ListSearchCustomHooks(authValid?
  //                                                         `/factory/admin/search/${pageState}/10/${searchInput}`
  //                                                         :
  //                                                         `/factory/user/search/${pageState}/10/${userCompanyData.companyCode}/${searchInput}`,pageState,setTotalPage,setPageBlock,setFactoryDataList,setErrorState)
  const FactorySearchList = () => ListSearchPostCustomHooks(
    authValid ? `/factory/admin/search` : `/factory/user/search`
    , pageState , setTotalPage , setPageBlock , setFactoryDataList , setErrorState , authValid ? {
      page: pageState ,
      size: 10 ,
      keyword: searchInput
    } : {page: pageState , size: 10 , keyword: searchInput , companyCode: userCompanyData.companyCode} )
  const searchEventFunc = () => {
    setPrevSearch( searchInput )
    setPageState( 1 )
    setPageBlock( 0 )
  }
  React.useEffect( () => {
    if( searchInput === '' ){
      FactoryList().then()
    } else{
      FactorySearchList().then()
    }} , [pageState , prevSearch,userCompanyData] )

  useEffect(()=>{
    if(!window.localStorage.getItem('auth')!?.includes('admin')){
      menuVisitLogRegister(window.localStorage.getItem('companyID'),'공장 기본정보').then()
    }
  },[])

  return (
    <SectionFrame>
      {/*{searchModalOpen && <CompanySearchPop buttonClick={(data)=>companyDataSelect(data)} selectedCompany={prevCompanyData.companyName}*/}
      {/*                                      searchValue={searchInput} clickEvent={setPrevCompanyData}*/}
      {/*                                      accessButton={()=>{*/}
      {/*                                          setPersistCompanyCode({companyName: prevCompanyData.companyName, companyCode: prevCompanyData.companyCode})*/}
      {/*                                          setSearchInput('')*/}
      {/*                                      setSearchModalOpen(false)}}/>}*/}
      <React.Fragment>
        <MenuTitle title={( authValid ? '업체/' : '' ) + '기본정보 관리 > 공장 기본정보'} unitCase={'MenuTitle'}/>
        <ContentFrame justify={'space-between'}>
          <div style={{userSelect: 'none'}}>
            <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center'}}>
              <MenuTitle title={'공장 기본정보'} unitCase={'MenuCompTitle'}/>
              <div style={{display: 'flex' , alignItems: 'center' , position: 'relative'}}>
                {/*/!*버튼테스트 *!/*/}
                {/*    {persistCompanyCode.companyCode === '@'?*/}
                {/*       '' :*/}
                {/*    <SearchFilterButton name={persistCompanyCode.companyName} cancelEvent={()=>''}*/}
                {/*                        clickEvent={totalFacilityList}/>}*/}
                {/* /!*버튼테스트 끝*!/*/}
                {/*<MainButtons name={'전체 목록'} clickEvent={totalFacilityList} width={'112px'}/>*/}
                {/*{persistCompanyCode.companyCode === '@'?*/}
                {/*    <MainButtons name={'업체별 검색'} clickEvent={() => setSearchModalOpen(true)} width={'112px'}/>*/}
                {/*    :*/}
                {/*    persistCompanyCode.companyCode === undefined?*/}
                {/*        <MainButtons name={'업체별 검색'} clickEvent={() => setSearchModalOpen(true)} />*/}
                {/*        :*/}
                {/*        <MainButtons name={persistCompanyCode.companyName} buttonType={'cta'}*/}
                {/*                     clickEvent={() => setSearchModalOpen(true)}*/}
                {/*                     width={'auto'}*/}
                {/*                     padding={'0 16px'}/>*/}
                {/*}*/}
                {/*<MainButtons name={'공장 등록'} buttonType={'cta'} clickEvent={()=>(setContainerMove(true))}/>*/}

                {/*<SearchFilterButton name={persistCompanyCode.companyCode === '@'? '업체 목록 보기' : persistCompanyCode.companyName}*/}
                {/*                    clickEvent={()=>setSearchModalOpen(true)} closeButton={persistCompanyCode.companyCode === '@'}*/}
                {/*                    cancelEvent={totalFacilityList} width={'120px'}/>*/}
                <SearchBar keyFunction={( e ) => setSearchInput( e.target.value.trimStart() )} keyPressDisable={true}
                           clickEvent={searchEventFunc} value={searchInput}
                           clearButton={FactoryList} clearButtonAccess={searchInput !== ''}/>
                <MainButtons buttonType={'cta'} width={'80px'} name={'등록'} marginDis={true}
                             disabled={buttonAuthValid( 'create' , '3' )}
                             clickEvent={() => window.location.href = authValid ? `/admin/fas/factory/register` : `/fas/factory/register`}/>
              </div>
            </div>
            <GridTableComponent
              tempCol={authValid ? '160px 120px 160px 240px 150px 120px 150px 1fr' : '160px 440px 160px 120px 140px 1fr'}
              scrollX_Hide={true} scrollY_Hide={true} height={528} scrollWidth={1210}>
              <div className={'grid-title-unchecked'}>
                {factoryDataTitle.map( ( v , i ) => (
                  <div key={i}>{t( v )}</div>
                ) )}
              </div>
              {factoryDataList.length === 0 ?
                <NoData>{errorState.notice}</NoData>
                :
                factoryDataList.map( ( v ) => (
                  <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                   selected={v.factoryCode === selectFactory}
                                   onDoubleClick={() => {window.location.href = authValid ? `/admin/fas/factory/${v.factoryCode}` : `/fas/factory/${v.factoryCode}`}}
                                   onClick={() => {setSelectFactory( v.factoryCode )}}>
                    {authValid &&  autoTextValueValidation({value: v.companyName})}
                    {authValid && autoTextValueValidation({value: v.president })}
                    {autoTextValueValidation({value: v.factoryName })}
                    {autoTextValueValidation({value: v.address })}
                    {validationValue( v.factoryNumber )}
                    {autoTextValueValidation({value: v.factoryManager })}
                    {validationValue( v.managerNumber )}
                      <ImageView drawing={!v.factoryDrawing}
                                 onClick={( event ) => {
                                   event.stopPropagation();
                                   if( !v.factoryDrawing ){
                                     setImageViewData( false )
                                   } else{
                                     setImageViewData( true );
                                     setDrawingImageState( {url: v.factoryDrawing , factoryName: v.factoryName} )
                                   }
                                 }}>{( !v.factoryDrawing ) ? '-' : t( '미리보기' )}
                      </ImageView>
                  </GridTableSelect>
                ) )
              }
            </GridTableComponent>
            {/*}*/}
          </div>
          {factoryDataList.length !== 0 &&
              <PaginationList limit={10} counts={totalPage} setBlockNum={setPageBlock}
                              pageLimit={10} blockNum={pageBlock}
                              page={pageState} setPage={setPageState}/>}
        </ContentFrame>
      </React.Fragment>
      {imageViewData && <DetailImageView image={drawingImageState.url} closeMenu={() => setImageViewData( false )}/>}
    </SectionFrame>
  );
};
export default FactoryManagement;

const ImageView = styled.div<{ drawing?: boolean }>`
  text-decoration: ${props => props.drawing ? 'none' : 'underline'};
  text-underline-offset: 3px;
  text-align: center;
  &:hover {
    text-decoration: none;
    text-underline-offset: 0;
  }
`
