import React,{useState} from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
  listResultMessage ,
  orderComponent ,
  tooltipModule ,
  validationValue
} from "../../style/StyleComponent";

import {
  authValid ,
  dataValid , handleDecimalChange , initModal ,
  loadingRemove ,
  locationFilter ,
  modalListLoading ,
  numberLocaleString , onChangeArray
} from "../../function/function";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import MainButtons from "../button/MainButtons";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../common/StateManage";
import ListInnerInput from "../input/ListInnerInput";
import TableValue from "../../style/TableValue";
import {useTranslation} from "react-i18next";
import SearchBar from "../input/SearchBar";
import {Modal_View_Type} from "../../types/Fundamental";
import ToastPopup from "./ToastPopup";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";

interface FacilityProps {
  companyCode: string
  viewType?: boolean
  cancelEvent: ()=>void
  selectProduct: any
  selectedFacility?: string[]
  receivedFacility:(data:any) => void
}

const FacilityProductAutoCheckModal:React.FunctionComponent<FacilityProps> = ({companyCode, selectedFacility, receivedFacility,
                                                                                selectProduct, cancelEvent, viewType}) => {
  const [facilityListData, setFacilityListData] = useState<Readonly<any[]>>([])
  const [selectFacilityData, setSelectFacilityData] = useState<string[]>(selectedFacility ? selectedFacility : []);
  const [apiMessage, setApiMessage] = useState<string>('')
  const [searchState, setSearchState] = useState<{input: string, value: string}>({input:'', value:''})
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)

  const selectCompany = useRecoilValue(authValid()? persistCompanyData : userPersistCompanyData)
  const {t}:any = useTranslation()
  const titleList = authValid()? ['NO.','기종','제조번호','제조사','기계명','ICT번호','최대톤수', '금형채널', '로드톤 범위','슬라이드 카운터','지시서 및 이력 자동생성', '생산카운트 유형'] : ['NO.','기종','제조번호','제조사','기계명','최대톤수','금형채널','로드톤 범위','슬라이드 카운터','지시서 및 이력 자동생성', '생산카운트 유형']
  // 기계 다중선택을 위한 기능
  const onSelectedList = (select:boolean, value:any) =>{
    if(select){
      setSelectFacilityData([...selectFacilityData, value.facilityCode])
    }else{
      setSelectFacilityData(selectFacilityData.filter(el=>el !== value.facilityCode));
    }
  }

  // 선택한 모든 기계의 입력값을 모아둔 배열 이후 목록에서 넘어온 값을 기계 목록에 직접 넣어두기 위해 사용
  const mergeFacilityData = selectFacilityData?.map((v,i)=>({
    facilityCode: v,
    autoWorkOrderCheck: !selectProduct?.autoWorkOrderCheck? 'off' : selectProduct?.autoWorkOrderCheck[i],
    autoWorkHistoryCheck: !selectProduct?.autoWorkHistoryCheck? 'off' : selectProduct?.autoWorkHistoryCheck[i],
    loadTonRange: !selectProduct?.loadTonRange? '' : selectProduct?.loadTonRange[i],
    slideCountRange: !selectProduct?.slideCountRange? '' : selectProduct?.slideCountRange[i],
    countType: !selectProduct?.countType? 'total' : selectProduct?.countType[i],
    channel: !selectProduct?.channel? null : selectProduct?.channel[i],
    channelCheck: !selectProduct?.channel? false : selectProduct?.channel[i]
  }))

  //팝업창이 열리고 기계 목록을 가져올 때, 기존의 입력된 값들로 대체 되어 바인딩 되도록 하기 위해 존재하는 기능
  // API 목록이 가져와지는 동시 setFacilityListData를 통해 facilityListData 로 state가 변경된다.
  const mergeArrays = (baseArray:any, updateArray:any) => {
    // 가져온 기본 데이터를 복사하여 기존의 데이터가 수정되지 않도록 함.
    const resultArray = JSON.parse(JSON.stringify(baseArray));

    // updateArray의 각 항목을 순회하면서 해당 키를 찾아 값을 업데이트 하도록 함.
    updateArray.forEach((updateItem:any) => {
      const matchingItem = resultArray.find((item:any) => item.facilityCode === updateItem.facilityCode);

      if (matchingItem) {
        Object.assign(matchingItem, updateItem);
      } else {
        // 일치하는 항목이 없으면 새로운 항목으로 추가.
        resultArray.push(updateItem);
      }
    });
    return resultArray;
  };

  const facilityListRes = async () => {
    setApiMessage('기계 목록을 가져오는 중 입니다.')
    try {
      modalListLoading()
      const response = await client.get(`/facility/list/live/check/${companyCode}/${selectProduct.productCode}/${dataValid(searchState.value,'@')}`,{
        headers:{Authorization}
      })
      const data = response.data.data
      if(data){
        // 기계 상태값이 possible('현재 pms 연동된 설비인지 확인') 인 경우 기본값을 넣고, 아닌경우엔 빈문자열로 넘겨야 함
        setFacilityListData(mergeArrays(data.row.map((v:any)=>({...v,
          autoWorkOrderCheck: v.facilityStatus === 'possible'? 'off' : '',
          autoWorkHistoryCheck: v.facilityStatus === 'possible'? 'off' : '',
          // loadTonRangeFront: v.loadTonRange?.split('-')[0],
          // loadTonRangeBack: v.loadTonRange?.split('-')[1],
          loadTonRange: v.facilityStatus === 'possible'? '0-0' : '',
          // slideCountRangeFront: v.slideCountRange?.split('-')[0],
          // slideCountRangeBack: v.slideCountRange?.split('-')[1],
          slideCountRange: v.facilityStatus === 'possible'? '0-0' : '',
          countType: v.facilityStatus === 'possible'? 'total' : '',
        })), mergeFacilityData))
      }
      else{
        setFacilityListData([])
      }
      loadingRemove()
      setApiMessage('등록된 기계가 없습니다.')
    }catch (e:any) {
      loadingRemove()
      console.log(e)
      setApiMessage(e.message)
    }
  }

  React.useEffect(()=>{
    facilityListRes().then()
  },[searchState.value])

  const productChannelCheckRes = async (facilityCode: string, channel: string|number) => {
    if(!channel){
      setDataModal({menu:'warn', message:'채널을 입력해 주세요.'})
    }
    else{
      try {
        const response = await client.get(`/product/channel/check/${facilityCode}/${channel}`,{headers:{Authorization}})
        if(response.data.status === 200){
          setFacilityListData( facilityListData.map( ( v: any ) => {
            return v.facilityCode === facilityCode ? {
              ...v ,
              channelCheck: true,
            } : v
          } ) )
        }
        else{
          setDataModal({menu:'warn', message:response.data.message})
        }
      }
      catch ( e:any ) {
        setDataModal({menu:'error', message:e.message})
        console.log(e)
      }
    }
  }

// 중복 선택을 막기위해 필터링
  const selectedFilterData = facilityListData.filter((v)=>(selectFacilityData?.includes(v.facilityCode)))

  //최종적으로 선택된 품목의 기계 리스트 내부에 값을 바인딩하기위해 정리된 각각의 배열 데이터
  const receivedCheckedData = {
    autoWorkOrderCheck: selectedFilterData.map((v)=>(v.facilityStatus === 'possible'? v.autoWorkOrderCheck : '')),
    autoWorkHistoryCheck: selectedFilterData.map((v)=>(v.facilityStatus === 'possible'? v.autoWorkHistoryCheck : '')),
    facilityCode: selectedFilterData.map((v)=>(v.facilityCode)),
    loadTonRange: selectedFilterData.map((v)=>(
      v.facilityStatus === 'possible'?
        (
          ((v.loadTonRangeFront)? v.loadTonRangeFront : !v.loadTonRange?.split('-')[0]? '0' : v.loadTonRange?.split('-')[0]) + '-' +
          ((v.loadTonRangeBack)? v.loadTonRangeBack : !v.loadTonRange?.split('-')[1]? '0' : v.loadTonRange?.split('-')[1])
        ) : ''
    )),
    slideCountRange: selectedFilterData.map((v)=>(
      v.facilityStatus === 'possible'?
        (
          ((v.slideCountRangeFront)? v.slideCountRangeFront : !v.slideCountRange?.split('-')[0]? '0' : v.slideCountRange?.split('-')[0]) + '-' +
          ((v.slideCountRangeBack)? v.slideCountRangeBack : !v.slideCountRange?.split('-')[1]? '0' : v.slideCountRange?.split('-')[1])
        ) : ''
    )),
    countType: selectedFilterData.map((v)=>(v.facilityStatus === 'possible'? v.countType : '')),
    facilityStatus: selectedFilterData.map((v)=>(v.facilityStatus)),
    channel : selectedFilterData.map((v)=>(v.channel))
  }
  console.log(facilityListData)

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <React.Fragment>
          {dataModal.menu === 'success' ?
            <ToastPopup text={t( dataModal.message )} state={'success'} closeEvent={() => {setDataModal( initModal )}}/>
            : dataModal.menu === 'error' ?
              <ToastPopup text={t( dataModal.message )} state={'error'} closeEvent={() => {setDataModal( initModal )}}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={t( dataModal.message )} state={'warn'}
                                                        closeEvent={() => {setDataModal( initModal )}}/> : null}
          <BaseComponent>
            <div className={'modal_title'} style={{backgroundColor: authValid()? '#CFFA61' : '#0058ff',
              color: authValid()? '#171725' : '#fff'
            }}>
              <div>{t(viewType)? t('기계 보기') : t('기계 등록')}</div>
              {!viewType && <div>{t('(해당 제품 생산시 사용 하는 기계를 등록해 주세요.)')}</div>}
            </div>
            <div className={'modal_content'}>
              {
                authValid()?
                  <ModalInfoTable>
                    {orderComponent(t('업체명'),selectCompany.companyName)}
                    {orderComponent(t('지역'),locationFilter(selectCompany.address))}
                    {orderComponent(t('업체코드'),selectCompany.companyCode)}
                    {orderComponent(t('단위'),selectProduct.unit)}
                    {orderComponent(t('모델'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerModelName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                    {orderComponent('CODE',tooltipModule(dataValid(selectProduct.serialCode || selectProduct.productSerialCode)))}
                    {orderComponent(t('품명'),tooltipModule(dataValid(selectProduct.productName)))}
                    {orderComponent(t('품목 종류'),selectProduct.productType || selectProduct.type)}
                  </ModalInfoTable>
                  :
                  <ModalInfoTable>
                    {orderComponent(t('거래처'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                    {orderComponent(t('모델'),(selectProduct.customer?.length === 0 || !selectProduct.customer)? '' :selectProduct.customer[0].customerModelName + ((selectProduct.customer.length -1) !== 0 ?` 외 ${selectProduct.customer.length-1}` : ''))}
                    {orderComponent('CODE',tooltipModule(dataValid(selectProduct.serialCode || selectProduct.productSerialCode)))}
                    {orderComponent(t('품명'),tooltipModule(dataValid(selectProduct.productName)))}
                    {orderComponent(t('단위'),selectProduct.unit)}
                    {orderComponent(t('품목 종류'),selectProduct.productType || selectProduct.type)}
                    {orderComponent(t('생산 공정'),selectProduct.processName)}
                    {orderComponent(t('캐비티'),selectProduct.cavity)}
                  </ModalInfoTable>
              }
              <div style={{display:'flex', justifyContent:'space-between',marginTop:'16px'}}>
                <div style={{display:'flex', alignItems:'center'}}>
                  <div style={{fontSize:'18px', fontWeight:800, marginRight:'8px'}}>{t('기계 리스트')}</div>
                  {!viewType &&
                    <div style={{display:'flex', fontSize:'14px', fontWeight:800, alignItems:'center'}}>
                      {t('(선택된 기계 수:')}
                      <div style={{color:'#0058ff', textDecoration:'underline', textUnderlineOffset:'3px',margin:'0 4px'}}>{selectFacilityData.length}</div>
                      {t('개)')}
                    </div>}
                </div>
                {!viewType &&
                    <SearchBar clickEvent={()=> {setSearchState((prev)=>({...prev, value: prev.input}))}} value={searchState.input}
                               clearButton={()=>{setSearchState({input:'', value:''})}}
                               keyFunction={(e)=>(setSearchState((prev)=>({...prev, input: e.target.value.trimStart()})))}
                               clearButtonAccess={searchState.input !== ''} disabled={facilityListData.length === 0 && searchState.value === ''}/>
                }

              </div>
              <GridTableComponent tempCol={authValid()? viewType?
                  '60px 88px 164px 108px 128px 108px 96px 70px repeat(2, 140px) repeat(2, 240px)' :
                  '60px 88px 164px 108px 128px 108px 128px 148px repeat(2, 300px) repeat(2, 300px)' :
                viewType? '60px 88px 164px 108px 128px 96px 70px repeat(2, 140px) repeat(2, 240px)' :
                  '60px 88px 164px 108px 128px 128px 148px repeat(2, 300px) repeat(2, 300px)'}
                                  scrollWidth={authValid()? viewType? 1582 : 2132 : viewType? 1474 : 2023}
                                  height={250}>
                <div className={'grid-title-unchecked'}>
                  {
                    titleList.map((v,i)=>(
                      <TableValue title={v} key={i} requiredValue={['']}/>))
                  }
                </div>
                {
                  viewType?
                    facilityListData.filter((v)=>(selectedFacility?.includes(v.facilityCode))).length === 0?
                      listResultMessage(true, apiMessage,{width:1000, admin:192, user:192})
                      :
                      // 기계 보기
                      facilityListData.filter((v)=>(selectedFacility?.includes(v.facilityCode)))?.map((value,i)=>(
                        <GridTableSelect selected={false}
                                         className={'grid-list-unchecked-no_icon'} key={value.facilityCode}>
                          <div>{i+1}</div>
                          {validationValue(value.type)}
                          {tooltipModule(dataValid(value.manufacturingNumber),16)}
                          {tooltipModule(dataValid(value.maker),8)}
                          {tooltipModule(dataValid(value.facilityName),12)}
                          {authValid() && validationValue(value.ictNumber,'할당 안됨')}
                          <ListInnerInput type={'noneInputUnit'} inputData={dataValid(numberLocaleString(value.maxTon),'-')} unit={'ton'}/>
                          {tooltipModule(dataValid(value.channel))}
                          <ListInnerInput type={'noneInputUnit'} unit={'ton'} inputData={dataValid(value?.loadTonRange,0)}/>
                          <ListInnerInput type={'noneInputUnit'} unit={'mm'} inputData={dataValid(value?.slideCountRange,0)}/>
                          <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{display:'flex',alignItems:'center', marginRight:'16px'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'작업지시서'}</div>
                              <input type="checkbox" checked={value?.autoWorkOrderCheck === 'on'} disabled={true}/>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'작업 이력'}</div>
                              <input type="checkbox" checked={value?.autoWorkHistoryCheck === 'on'} disabled={true}/>
                            </div>
                          </div>

                          <div style={{display:'flex', flexDirection:'row'}}>
                            <div style={{display:'flex',alignItems:'center', marginRight:'16px'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'총 카운터'}</div>
                              <input type="radio" checked={value?.countType === 'total'} disabled={true}/>
                            </div>
                            <div style={{display:'flex', alignItems:'center'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'현재 카운터'}</div>
                              <input type="radio" checked={value?.countType === 'preset'} disabled={true}/>
                            </div>
                          </div>
                        </GridTableSelect>
                      ))
                    :
                    //기계 선택(등록)
                    facilityListData.length === 0?
                      listResultMessage(true, '* 등록된 정보가 없습니다.',{width:1000, admin:192, user:192})
                      :
                      facilityListData?.map((value, i)=>(
                        <GridTableSelect selected={selectFacilityData?.map((v)=>(v)).includes(value.facilityCode)}
                          // style={{transform: `translateY(${-i}px)`}}
                                         onClick={()=>{onSelectedList(!selectFacilityData?.map((v)=>(v)).includes(value.facilityCode), value)}}
                                         className={'grid-list-unchecked-no_icon'} key={value.facilityCode}>
                          <div>{i+1}</div>
                          {validationValue(value.type)}
                          {tooltipModule(dataValid(value.manufacturingNumber),16)}
                          {tooltipModule(dataValid(value.maker),8)}
                          {tooltipModule(dataValid(value.facilityName),12)}
                          {authValid() && validationValue(value.ictNumber,'할당 안됨')}
                          <ListInnerInput type={'noneInputUnit'} inputData={numberLocaleString(value.maxTon)} unit={'ton'}/>
                          <ListInnerInput type={'withButton'} maxLength={2}
                                          name={'channel'} placeholder={'-'}
                                          inputData={value.channel} disabled={(value.channelCheck || !value.channel)}
                                          onChange={( e ) => {
                                            setFacilityListData( facilityListData.map( ( v: any ) => {
                                              return v.facilityCode === value.facilityCode ? {
                                                ...v ,
                                                channel: (e.target.value.replace(/[^0-9]/g, '').trim()).toString(),
                                                channelCheck: false,
                                              } : v
                                            } ) )
                                            // Number(value.replace(/[^0-9]/g, '').trim()).toString()
                                            // handleDecimalChange( i , e , facilityListData , setFacilityListData , 'ea' )

                                          }}
                                          clickButton={() => productChannelCheckRes(value.facilityCode, value.channel)}
                                          buttonText={!value.channelCheck ? '중복확인' : '확인됨'}
                                          buttonWidth={'60px'}/>
                          {/* 최소, 최대 값 두가지 입력이 필요. 두 값을 입력한 후 범위값 하나로 값을 넘겨 주어야 함. 이미 가져온 범위값의 "-" 기준으로 나눠서
                          앞의 최소값은 rangeFront로 뒷부분은 rangeBack으로 입력, 입력한 값이 없고, 범위 값이 존재하지 않는 경우. 0으로 표기,
                          facilityStatus 가 'impossible' 인 경우 range값 자체가 "" 로 넘어가도록 변경. */}
                          <div style={{display:'flex', flexDirection:'row'}}>
                            <ListInnerInput type={'unit'} width={'140px'} name={'loadTonRangeFront'} placeholder={'-'} unit={'ton'}
                                            disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                            inputData={value.facilityStatus !== 'possible'? '자동 생성불가' :
                                              !value?.loadTonRangeFront?
                                                value?.loadTonRange?.split('-')[0] :
                                                value?.loadTonRangeFront?.includes('.')? value?.loadTonRangeFront : numberLocaleString(value?.loadTonRangeFront)} limit={15}
                                            onChange={(e) => {handleDecimalChange(i, e, facilityListData, setFacilityListData,'')}}/>
                            <ListInnerInput type={'unit'} width={'140px'} name={'loadTonRangeBack'} placeholder={'-'} unit={'ton'} disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                            inputData={value.facilityStatus !== 'possible'? '자동 생성불가' :
                                              !value?.loadTonRangeBack?
                                                value?.loadTonRange?.split('-')[1] :
                                                value?.loadTonRangeBack?.includes('.')? value?.loadTonRangeBack : numberLocaleString(value?.loadTonRangeBack)} limit={15}
                                            onChange={(e) => {handleDecimalChange(i, e, facilityListData, setFacilityListData,'')}}/>
                          </div>
                          {/* 최소, 최대 값 두가지 입력이 필요. 두 값을 입력한 후 범위값 하나로 값을 넘겨 주어야 함. 이미 가져온 범위값의 "-" 기준으로 나눠서
                           앞의 최소값은 rangeFront로 뒷부분은 rangeBack으로 입력, 입력한 값이 없고, 범위 값이 존재하지 않는 경우. 0으로 표기,
                           facilityStatus 가 'impossible' 인 경우 range값 자체가 "" 로 넘어가도록 변경. */}
                          <div style={{display:'flex',flexDirection:'row'}}>
                            <ListInnerInput type={'unit'} width={'140px'} name={'slideCountRangeFront'} placeholder={'-'} unit={'mm'} disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                            inputData={value.facilityStatus !== 'possible'? '자동 생성불가' :
                                              !value?.slideCountRangeFront?
                                                value?.slideCountRange?.split('-')[0] :
                                                value?.slideCountRangeFront?.includes('.')? value?.slideCountRangeFront : numberLocaleString(value?.slideCountRangeFront)} limit={15}
                                            onChange={(e) => {handleDecimalChange(i, e, facilityListData, setFacilityListData,'')}}/>
                            <ListInnerInput type={'unit'} width={'140px'} name={'slideCountRangeBack'} placeholder={'-'} unit={'mm'} disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                            inputData={value.facilityStatus !== 'possible'? '자동 생성불가' :
                                              !value?.slideCountRangeBack?
                                                value?.slideCountRange?.split('-')[1] :
                                                value?.slideCountRangeBack?.includes('.')? value?.slideCountRangeBack : numberLocaleString(value?.slideCountRangeBack)} limit={15}
                                            onChange={(e) => {handleDecimalChange(i, e, facilityListData, setFacilityListData,'')}}/>
                          </div>
                          {/*  */}
                          <div style={{display:'flex', flexDirection:'row', opacity: (!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')? 0.3 : 1, height:'46px'}} onClick={(e)=>(e.stopPropagation())}>
                            <label style={{display:'flex',alignItems:'center', marginRight:'16px'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'작업지시서'}</div>
                              <input type="checkbox" name={'workOrder'} disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                     onChange={(e)=>{
                                       const copyProductList = [...facilityListData]
                                       // 자동생성 시 작업 지시서만 단독으로 자동 생성을 하는 경우는 없음
                                       if(e.target.checked){
                                         copyProductList[i]['autoWorkOrderCheck'] = 'on'
                                         copyProductList[i]['autoWorkHistoryCheck'] = 'on'
                                         // copyProductList[iconSelect]['autoProcess'] = { ...copyProductList[iconSelect]['autoProcess'], workOrder: '1' }
                                         setFacilityListData(copyProductList)
                                       }else{
                                         copyProductList[i]['autoWorkOrderCheck'] = 'off'
                                         // copyProductList[iconSelect]['autoProcess'] = { ...copyProductList[iconSelect]['autoProcess'], workOrder: '0' }
                                         setFacilityListData(copyProductList)
                                       }
                                     }} checked={value?.autoWorkOrderCheck === 'on'}/>
                            </label>
                            <label style={{display:'flex', alignItems:'center'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'작업 이력'}</div>
                              <input type="checkbox" name={'workHistory'} disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                     onChange={(e)=>{
                                       const copyProductList = [...facilityListData]
                                       // 자동생성 시 작업 지시서만 단독으로 자동 생성을 하는 경우는 없음
                                       if(e.target.checked){
                                         // copyProductList[iconSelect]['autoProcess'] = { ...copyProductList[iconSelect]['autoProcess'], workHistory: '1' }
                                         copyProductList[i]['autoWorkHistoryCheck'] = 'on'
                                         setFacilityListData(copyProductList)
                                       }else{
                                         // copyProductList[iconSelect]['autoProcess'] = { ...copyProductList[iconSelect]['autoProcess'], workHistory: '0' }
                                         copyProductList[i]['autoWorkHistoryCheck'] = 'off'
                                         copyProductList[i]['autoWorkOrderCheck'] = 'off'
                                         setFacilityListData(copyProductList)
                                       }
                                     }} checked={value?.autoWorkHistoryCheck === 'on'}/>
                            </label>
                          </div>

                          <div style={{display:'flex', flexDirection:'row', opacity: (!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')? 0.3 : 1,}}
                               onClick={(e)=>(e.stopPropagation())}>
                            {/* 카운트 유형 선택 - default = 'total' , facilityStatus = 'impossible'인 경우 ""로 입력 */}
                            <label style={{display:'flex',alignItems:'center', marginRight:'16px'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'총 카운터'}</div>
                              <input type="radio" name={`countType_${value.facilityCode}`} value={'total'}
                                     disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                     checked={value.countType === 'total'}
                                     onChange={()=> {
                                       const copyFacilityList = [...facilityListData]
                                       copyFacilityList[i]['countType'] = 'total'
                                       setFacilityListData(copyFacilityList)
                                     }} />
                            </label>
                            <label style={{display:'flex', alignItems:'center'}}>
                              <div style={{fontSize:'12px', fontWeight:700, marginRight:'8px'}}>{'현재 카운터'}</div>
                              <input type="radio" name={`countType_${value.facilityCode}`} value={'preset'}
                                     checked={value.countType === 'preset'}
                                     disabled={(!selectFacilityData?.includes(value.facilityCode) || value.facilityStatus !== 'possible')}
                                     onChange={()=> {
                                       const copyFacilityList = [...facilityListData]
                                       copyFacilityList[i]['countType'] = 'preset'
                                       setFacilityListData(copyFacilityList)
                                     }} />
                            </label>
                          </div>
                        </GridTableSelect>
                      ))
                }
              </GridTableComponent>
              <div style={{display:'flex',justifyContent:'center',marginTop:'8px'}}>
                {!viewType?
                  <React.Fragment>
                    <MainButtons name={'취소'} width={'144px'} margin={'0 12px'} clickEvent={cancelEvent}/>
                    <MainButtons name={'선택'} width={'144px'} margin={'0 12px'} clickEvent={()=> {
                      if(facilityListData.some((el)=> !el.channelCheck && el.channel)){
                        setDataModal({menu:'warn', message:'금형채널 중복 확인이 필요합니다.'})
                      }
                      else{
                        receivedFacility(receivedCheckedData); cancelEvent()
                      }

                    }} buttonType={'popupCta'}/>
                  </React.Fragment>
                  :
                  <MainButtons name={'확인'} width={'144px'} buttonType={'popupCta'} clickEvent={cancelEvent}/>}
              </div>
            </div>
          </BaseComponent>
        </React.Fragment>
      }/>
    </React.Fragment>
  );
};
export default FacilityProductAutoCheckModal;


const BaseComponent = styled.div`
  width: 1032px;
  height: 512px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;

  .modal_title {
    display: flex;
    //background-color: #CFFA61;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    &>div:nth-child(1) {
      font-size: 18px;
      font-weight: 800;
      margin-right: 8px;
    }

    &>div:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      color: rgba(23, 23, 37, 0.5);
    }
  }
  
  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px;
  }
`

const ModalInfoTable =  styled.div`
    border-bottom: 1px solid #E6E9F4;
    display: grid;
    grid-template-columns: repeat(4, 104px 146px);
    align-items: center;
    box-sizing: border-box;
    .order{
        &_title{
            height: 32px;
            background-color: #F5F6FA;
            display: flex;
            align-items: center;
            padding-left: 16px;
            font-weight: bold;
            font-size: 12px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
        }
        &_content{
            font-size: 14px;
            font-weight: normal;
            color: #171725;
            display: flex;
            align-items: center;
            height: 32px;
            padding-left: 16px;
            box-sizing: border-box;
            border-top: 1px solid #E6E9F4;
            white-space: pre;
        }
    }
`

