import React from 'react';
import {useLocation} from "react-router-dom";
import {PageContainer} from "../../../style/StyleComponent";
import IntegratedSideMenu from "../../../components/sideMenu/IntegratedSideMenu";
import SectionContainer from "../../../containers/common/SectionContainer";
import BusinessManagement from "../../../containers/admin/fas/business/BusinessManagement";
import BusinessRegistration from "../../../containers/admin/fas/business/BusinessRegistration";
import BusinessDetail from "../../../containers/admin/fas/business/BusinessDetail";
import BusinessModify from "../../../containers/admin/fas/business/BusinessModify";
import {useWindowResizeScale} from "../../../function/function";

const AdminFasBusiness = () => {
  const location = useLocation();
  const locationId = location.pathname.length
  const register = location.pathname.includes('register')
  const modify = location.pathname.includes('modify')
  // const { scale, ref } = useWindowResizeScale();

  return (
    <PageContainer>
      <IntegratedSideMenu/>
      {locationId === 19 ?
        <SectionContainer contentContainer={<BusinessManagement/>}/>
        :
        register ?
          <SectionContainer contentContainer={<BusinessRegistration/>}/>
          :
          modify ?
            <SectionContainer contentContainer={<BusinessModify/>}/>
            :
            <SectionContainer contentContainer={<BusinessDetail/>}/>
      }
    </PageContainer>

  );
};
export default AdminFasBusiness;