import React, {useState} from 'react';
import {MobileSectionFrame} from "../../../../style/StyleComponent";
import styled from "styled-components";
import {authValid , initModal} from "../../../../function/function";
import {Modal_View_Type} from "../../../../types/Fundamental";
import ConfirmPopup from "../../../../components/modal/ConfirmPopup";
import MobileSideMenu from "../../../../components/sideMenu/MobileSideMenu";

const MobileMainContainer = () => {

  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)

  const solutionArray = [
    {title: 'P-BAS', link:authValid() ? '/admin/pbas/managelog' : ''},
    {title: 'P-MS', link:authValid() ? '/admin/pms/monitoring_system' : '/pms/monitoring_system'},
    {title: 'R-BAS', link:''},
    {title: 'C-BAS', link:''},
    {title: 'A-BAS', link:''},
    {title: 'F-EMS', link:''},
    // {title: authValid()? 'MES' : 'MES(키오스크)', link: authValid()? '' : '/kiosk'},
    {title: 'MES', link: authValid()? '' : '/kiosk'},
  ]

  return (
    <React.Fragment>
      {dataModal.menu === 'confirm' && <ConfirmPopup statusText={dataModal.message} clickEvent={()=>setDataModal(initModal)}/>}
      <MobileSideMenu menu={'FAS'} sub={''}/>
      <MobileSectionFrame>
        <div style={{display:'grid', gridTemplateColumns:'1fr 1fr',gap:'24px 16px', boxSizing:'border-box', padding:'16px 24px'}}>
          {solutionArray.map((v)=>(
            <SolutionSquare style={{opacity: v.link? 1 : 0.3 , pointerEvents: v.link? 'auto':'none'}}
                            onClick={()=>
                              // setDataModal({menu:'confirm', message:'모바일 메뉴는 준비중 입니다.'})
                              window.location.href = v.link
            } background={v.title}>
              {v.title}
            </SolutionSquare>
          ))}
        </div>
      </MobileSectionFrame>
    </React.Fragment>
  );
};
export default MobileMainContainer;

const SolutionSquare = styled.div<{background: string}>`
  min-width: 144px;
  min-height: 104px;
  border-radius: 16px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px #00000029;
  font-weight: 700;
  font-size: 20px;
  padding: 16px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  color: ${props => props.background === 'MES'? '#7E84A3' : '#FFF'};
  background-color: ${
  props => props.background === 'P-BAS' ? '#54BEEA' :
  props => props.background === 'P-MS' ? '#0058FF' :        
  props => props.background === 'R-BAS' ? '#4AA261': 
  props => props.background === 'C-BAS' ? '#E79F3C' :
  props => props.background === 'A-BAS' ? '#6963B0' :
  props => props.background === 'F-EMS' ? '#CC5642' :
  '#FFF'};
  user-select: none;
};
`