import React , {ChangeEvent , useState , useEffect} from 'react';
import {ContentFrame , multipleImageGridStyle , SectionFrame} from "../../../../../style/StyleComponent";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import styled from "styled-components";
import TotalImageUploadBox from "../../../../../components/input/TotalImageUploadBox";
import SingleImageUploadBox from "../../../../../components/input/SingleImageUploadBox";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {Modal_View_Type , PeripheralType , SearchCompanyType} from "../../../../../types/Fundamental";
import {useParams} from "react-router-dom";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading , dataValid , initModal ,
  loadingRemove ,
  locationFilter ,
} from "../../../../../function/function";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import dayjs from "dayjs";
import FactoryDropMenu from "../../../../../components/sideMenu/FactoryDropMenu";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import RegisterInput from "../../../../../components/input/RegisterInput";

const PeripheralsModify = () => {

  const peripheralsCode = useParams()
  const peripheralDrop = ['미스피드 검출장치' , '하사점 검출장치' , '로드모니터' , '앵글시퀀서' , '엔코더' , '통관센서' , '유틸리티센서'];
  const [dataModal , setDataModal] = useState<Modal_View_Type>( initModal )
  const [modifyState , setModifyState] = useState<PeripheralType | any>( {} )
  const [imageKeyState , setImageKeyState] = useState<any[]>( [] );
  const [specImageKey , setSpecImageKey] = useState<string | null>( null );
  const [abilityImageKey , setAbilityImageKey] = useState<string | null>( null );
  const [manualImageKey , setManualImageKey] = useState<string | null>( null );
  const [factoryDataList , setFactoryDataList] = useState<SearchCompanyType[]>( [] )
  const [factorySubName , setFactorySubName] = useState<string[]>( ['미등록'] )
  const onEdit = ( e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> , trim?: boolean ) => {
    const {name , value} = e.target
    setModifyState( {
                      ...modifyState ,
                      [name]: trim ? value.trim() : value
                    } )
  }
  const PeripheralsDetailRes = async () => {
    try {
      const response = await client.post( `/peripheral/detail` , {
        peripheralCode: peripheralsCode.peripheralCode
      } , {headers: {Authorization}} )
      const data = response.data.data
      setModifyState( {...data , codeDoubleCheck: true} );
    } catch ( e ) {
      console.log( e )
    }
  }
  useEffect( () => {
    PeripheralsDetailRes().then()
  } , [peripheralsCode.peripheralsCode] )
  const factoryListRes = async ( companyCode?: string ) => {
    try {
      const response = authValid() ?
        await client.post( `/factory/admin/search` , {page: 1 , size: 10 , keyword: companyCode} ,
                           {headers: {Authorization}} )
        :
        await client.get( `/factory/list/${1}/${10}/${companyCode}` , {headers: {Authorization}} )
      const data = response.data.data
      setFactoryDataList( data.row )
      const segment = await client.post( '/factory/subdivision/list' , {
        factoryCode: modifyState.factoryCode
      } , {headers: {Authorization}} )
      const segmentData = segment.data.data.subdivision
      if( !segmentData[0] ){
        setFactorySubName( ['미등록'] )
      } else{
        setFactorySubName( segmentData )
      }
    } catch ( e ) {
      console.log( e )
    }
  }
  const factoryDivision = async ( factoryCode: string ) => {
    try {
      const response = await client.post( '/factory/subdivision/list' , {
        factoryCode: factoryCode
      } , {headers: {Authorization}} )
      const segmentData = response.data.data.subdivision
      //@ts-ignore
      if( !segmentData[0] ){
        setFactorySubName( ['미등록'] )
      } else{
        setFactorySubName( segmentData )
      }
    } catch ( e ) {
      console.log( e )
    }
  }
  const PeripheralsDeleteRes = async () => {
    try {
      dataAccessLoading()
      const response = await client.post( `/peripheral/delete` , [peripheralsCode.peripheralCode] , {
        headers: {Authorization}
      } )
      const data = response.data
      if( data.status !== 200 ){
        setDataModal( {menu: 'confirm' , message: data.message} )
        loadingRemove()
      } else{
        setTimeout( () => {
          loadingRemove()
          setDataModal( {menu: 'confirm' , message: '삭제 되었습니다.'} )
        } , 500 )
      }
    } catch ( e: any ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: e.message} )
      loadingRemove()
    }
  }
  const PeripheralsUpdateRes = async () => {
    if( !modifyState?.peripheralName ){
      setDataModal( {menu: 'confirm' , message: '장치 이름이 없습니다.'} )
    } else if( !modifyState?.peripheralManufacturer ){
      setDataModal( {menu: 'confirm' , message: '장치 제조사가 없습니다.'} )
    } else if( !modifyState?.codeDoubleCheck ){
      setDataModal( {menu: 'confirm' , message: '장치 제조번호 중복 확인을 해주세요.'} )
    } else{
      try {
        dataAccessLoading()
        const response = await client.post( `/peripheral/update` , {
          ...modifyState ,
          peripheralImg: imageKeyState ,
          abilityImg: abilityImageKey ,
          manualImg: manualImageKey ,
          specImg: specImageKey ,
        } , {
                                              headers: {Authorization}
                                            } )
        const data = response.data
        if( response.data.status !== 200 ){
          setDataModal( {menu: 'confirm' , message: data.message} )
          loadingRemove()
        } else{
          setTimeout( () => {
            loadingRemove()
            setDataModal( {menu: 'confirm' , message: '수정 되었습니다.'} )
          } , 500 )
        }
      } catch ( e: any ) {
        loadingRemove()
        setDataModal( {menu: 'confirm' , message: e.message} )
        console.log( e )
      }
    }
  }
  useEffect( () => {
    factoryListRes( modifyState?.companyCode ).then()
  } , [modifyState?.companyCode] )
  useEffect( () => {
    const handleBeforeUnload = ( event: BeforeUnloadEvent ) => {
      if( dataModal.menu === '' ){
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener( 'beforeunload' , handleBeforeUnload );
    return () => {
      window.removeEventListener( 'beforeunload' , handleBeforeUnload );
    };
  } , [dataModal.menu] );
  const serialDoubleCheck = async ( serialCode: string , type: string ) => {
    try {
      const response = await client.post( '/peripheral/serial/check' , {
        companyCode: modifyState.companyCode ,
        peripheralNumber: serialCode ,
        peripheralType: type
      } , {headers: {Authorization}} )
      if( response.data.status === 200 ){
        setModifyState( ( prev: any ) => ( {...prev , codeDoubleCheck: true} ) )
        setDataModal( {menu: 'confirm' , message: response.data.message} )
      } else{
        setDataModal( {menu: 'confirm' , message: response.data.message} )
      }
    } catch ( e ) {
      console.log( e )
      setDataModal( {menu: 'confirm' , message: ' * 서버 에러 입니다. \n 중복확인 할 수 없습니다.'} )
    }
  }
  return (
    <React.Fragment>
      {dataModal.menu === 'select' && <SelectConfirmModal statusText={dataModal.message}
                                                          noticeText={'* 삭제된 데이터는 복구되지 않습니다.'}
                                                          cancelEvent={() => {setDataModal( initModal );}}
                                                          clickEvent={() => {
                                                            PeripheralsDeleteRes().then();
                                                            setDataModal( initModal )
                                                          }}/>}

      {dataModal.menu === 'confirm' && <ConfirmPopup clickEvent={() => {
        if( dataModal.message === '수정 되었습니다.' ){
          window.location.href = `${authValid() ? `/admin/fas/peripherals/${peripheralsCode.peripheralCode}` : `/fas/peripherals/${peripheralsCode.peripheralCode}`}`;
        }
        if( dataModal.message === '삭제 되었습니다.' ){
          window.location.href = `${authValid() ? `/admin/fas/peripherals` : `/fas/peripherals`}`
        } else{
          setDataModal( initModal );
        }
      }} statusText={dataModal.message}/>}

      {dataModal.menu === 'register' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              clickEvent={PeripheralsUpdateRes}
                              cancelEvent={() => {setDataModal( initModal )}}/>}

      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              viewType={true}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={() => {setDataModal( initModal )}}
                              clickEvent={() => { window.location.href = authValid() ? `/admin/fas/peripherals/${peripheralsCode.peripheralCode}` : `/fas/peripherals/${peripheralsCode.peripheralCode}`}}
          />
      }

      <SectionFrame>
        <MenuTitle title={( authValid() ? '업체/' : '' ) + '기본정보 관리 > 주변장치 기본정보 > 수정'} unitCase={'MenuTitle'}/>
        <ContentFrame height={'680px'}>
          <div style={{display: 'flex' , marginBottom: '16px'}}>
            <RegisterComp>
              <div style={{
                display: 'flex' ,
                justifyContent: 'space-between' ,
                alignItems: 'center' ,
                marginBottom: '16px'
              }}>
                <MenuTitle title={'주변장치 기본정보 수정'} unitCase=
                  {'MenuCompTitle'}/>
                <div style={{display: 'flex'}}>
                  <MainButtons width={'56px'} name={'취소'} clickEvent={() => {
                    setDataModal( {
                                    menu: 'cancel' ,
                                    message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'
                                  } )
                  }}/>
                  <MainButtons width={'56px'} name={'삭제'} buttonType={'delete'}
                               disabled={buttonAuthValid( 'delete' , '7' )}
                               clickEvent={() => ( setDataModal( {menu: 'select' , message: '삭제 하시겠습니까?'} ) )}/>
                  <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                               disabled={buttonAuthValid( 'update' , '7' )}
                               clickEvent={() => {setDataModal( {menu: 'register' , message: '이대로 수정 하시겠습니까?'} )}}/>
                </div>
              </div>
              {authValid() &&
                  <React.Fragment>
                    <ListInfoComponent title={'업체명'} value={modifyState?.companyName} bottomBorder={false}/>
                    <ListInfoComponent title={'지역'}
                                       value={locationFilter( modifyState?.companyAddress?.split( '/' ).pop() )}
                                       bottomBorder={false}/>
                  </React.Fragment>}
              <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2, 1fr)'}}>

                <ModifyOption>
                  <div>{'공장명'}</div>
                  <div style={{position: 'relative' , display: 'flex'}}>
                    <FactoryDropMenu clickEvent={( data ) => {
                      if( data.factoryName === '선택 없음' ){
                        setFactorySubName( ['미등록'] )
                        setModifyState( ( prev: PeripheralType ) => ( {...prev , factorySubDivision: ''} ) )
                      }
                      setModifyState( ( prev: PeripheralType ) => ( {...prev , ...data} ) );
                      if( data.factoryCode ){
                        factoryDivision( dataValid( data.factoryCode , '' ) ).then()
                      }
                    }} selectCode={dataValid( modifyState?.factoryCode , '' )}
                                     menuArray={factoryDataList?.map( ( v ) => ( {
                                       factoryAddress: v.factoryAddress ,
                                       factoryCode: v.factoryCode ,
                                       factoryName: v.factoryName
                                     } ) )}/>
                  </div>
                </ModifyOption>

                <RegisterComponent type={'drop'} title={'공장 세분화'} name={'factorySubDivision'} dropWidth={'208px'}
                                   value={modifyState?.factorySubDivision} onChange={onEdit}
                                   dropArray={factorySubName}
                                   dropAddNoneSelect={factorySubName[0] !== '미등록'}
                                   dropClickEvent={( v ) => {
                                     if( v === '미등록' ){
                                       setModifyState( ( prev: PeripheralType ) => ( {
                                         ...prev ,
                                         factorySubDivision: ''
                                       } ) )
                                     } else{
                                       setModifyState( ( prev: PeripheralType ) => ( {
                                         ...prev ,
                                         factorySubDivision: v
                                       } ) )
                                     }
                                   }}/>

                <RegisterComponent type={'drop'} title={'장치 종류'} name={'peripheralType'} required={true}
                                   dropClickEvent={( v ) => setModifyState( ( prev: PeripheralType ) => ( {
                                     ...prev ,
                                     peripheralType: v
                                   } ) )}
                                   value={modifyState?.peripheralType} dropArray={peripheralDrop} onChange={onEdit}/>

                <RegisterComponent type={'text'} title={'담당자'} name={'managerName'} value={modifyState?.managerName}
                                   onChange={( e ) => onEdit( e , true )}/>

                {/*<div style={{display:'flex',alignItems:'center',borderTop:'1px solid #E6E9F4'}}>*/}
                {/*    <div className={'calender_wrap'}>{'제조 연월일'}</div>*/}
                {/*    <div style={{width:'190px',paddingLeft:'16px'}}>*/}
                {/*        <ListInnerInput type={'calendar'} name={'manufactureDate'} inputData={modifyState?.manufactureDate}*/}
                {/*                        onChange={(v)=>setModifyState((prev:PeripheralType)=>({...prev, manufactureDate: dayjs(v).format('YYYY.MM.DD')}))}*/}
                {/*                        placeholder={'날짜 선택'} style={{cursor:'pointer'}}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <RegisterComponent type={'calendar'} name={'manufactureDate'}
                                   value={modifyState?.manufactureDate}
                                   selectDate={modifyState?.manufactureDate}
                                   title={'제조 연월일'} left={- 100} top={45}
                                   deleteEvent={() => setModifyState( ( prev: any ) => ( {
                                     ...prev ,
                                     manufactureDate: ''
                                   } ) )}
                                   onChange={( v ) => setModifyState( ( prev: any ) => ( {
                                     ...prev ,
                                     manufactureDate: dayjs( v ).format( 'YYYY.MM.DD' )
                                   } ) )}
                                   placeholder={'날짜 선택'}/>


                <RegisterComponent type={'doubleCheck'} title={'장치 제조번호'}
                                   name={'peripheralNumber'} value={modifyState?.peripheralNumber}
                                   disabled={( modifyState?.codeDoubleCheck )}
                                   clickEvent={() => serialDoubleCheck( modifyState?.peripheralNumber , modifyState?.peripheralType ).then()}
                                   onChange={( e ) => ( setModifyState( ( prev: any ) => ( {
                                     ...prev ,
                                     peripheralNumber: e.target.value.trim() ,
                                     codeDoubleCheck: false
                                   } ) ) )}/>
                <RegisterComponent type={'text'} title={'장치 제조사'} name={'peripheralManufacturer'} paddingRight={true}
                                   value={modifyState?.peripheralManufacturer} onChange={( e ) => onEdit( e , true )}
                                   required={true}/>
                <RegisterComponent type={'text'} title={'장치 이름'} name={'peripheralName'}
                                   value={modifyState?.peripheralName} onChange={( e ) => onEdit( e , true )}
                                   required={true}/>
                {/*<ListInfoComponent type={'textClick'} title={'연동설비'} contentText={'연동 설비 보기'} value={'연동설비'} bottomBorder={false}/>*/}
                {/*<ListInfoComponent title={'-'} value={'-'} bottomBorder={false}/>*/}
              </div>
              <div style={{
                display: 'grid' ,
                gridTemplateColumns: '168px 1fr' ,
                borderBottom: '1px solid rgba(213, 215, 227, 0.5)'
              }}>
                <div className={'info-title'} style={{height: '240px'}}>{'비고'}</div>
                <div className={'info-content'} style={{height: '240px'}}>
                  <RegisterInput type={'textarea'} name={'contents'} value={modifyState?.note}
                                 maxHeight={'224px'} limit={2000}
                                 onChange={onEdit} placeholder={'내용을 입력해주세요.'}/>
                </div>
              </div>
            </RegisterComp>

            <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>

            <div className={'scroll_comp'} style={{width: '379px' , height: '600px' , overflowY: 'scroll'}}>
              <div style={{display: 'flex' , flexWrap: 'wrap'}}>
                <div style={{display: 'flex' , alignItems: 'center' , justifyContent: 'space-between' , width: '100%'}}>
                  <MenuTitle title={'장치 이미지 등록'} unitCase={'MenuCompTitle'}/>
                  <div style={{
                    color: '#7E84A3' ,
                    textAlign: 'right' ,
                    fontSize: '12px' ,
                    lineHeight: '16px' ,
                    height: '34px'
                  }}>
                    <div>{'*최대 4개의 이미지 등록 가능'}</div>
                    <div>{'*jpg, png 10mb 이하의 파일 등록 가능'}</div>
                  </div>
                </div>
                <div style={{marginTop: '12px'}}>

                  <TotalImageUploadBox
                    imageKeyArray={( data ) => setImageKeyState( data )}
                    receiveUrl={modifyState?.peripheralImgUrl}
                    receiveKey={modifyState?.peripheralImgUrl}
                    profileSelect={false}
                  />


                </div>
                <div style={{...multipleImageGridStyle , marginTop: '16px'}}>
                  <div>
                    <MenuTitle title={'스펙 명판'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={( data ) => setSpecImageKey( data )}
                                          receiveKey={modifyState?.specImg}
                                          receiveUrl={modifyState?.specImgUrl}
                                          inputSize={168} profileSelect={false}/>
                  </div>
                  <div>
                    <MenuTitle title={'능력 명판'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={( data ) => setAbilityImageKey( data )}
                                          receiveKey={modifyState?.abilityImg}
                                          receiveUrl={modifyState?.abilityImgUrl}
                                          inputSize={168} profileSelect={false}/>
                  </div>
                  <div>
                    <MenuTitle title={'사용 설명서'} unitCase={'MenuCompTitle'}/>
                    <SingleImageUploadBox imageKey={( data ) => setManualImageKey( data )}
                                          receiveKey={modifyState?.manualImg}
                                          receiveUrl={modifyState?.manualImgUrl} inputSize={168} profileSelect={false}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentFrame>
      </SectionFrame>
    </React.Fragment>
  );
};
export default PeripheralsModify;
const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}

  .info, .reg {
    display: grid;
    box-sizing: border-box;

    &-title {
      padding: 16px;
      width: 168px;
      box-sizing: border-box;
      display: flex;
      //justify-content: space-between;
      align-items: center;
      height: 48px;
      background-color: #F5F6FA;
      color: #131523;
      font-size: 12px;
      font-weight: 800;
      border-top: 1px solid rgba(213, 215, 227, 0.5);
    }

    &-content {
      display: flex;
      padding-left: 16px;
      min-width: 224px;
      box-sizing: border-box;
      font-size: 14px;
      height: 48px;
      border-top: 1px solid rgba(213, 215, 227, 0.5);
      align-items: center;
    }
  }

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }

  .calender_wrap {
    width: 168px;
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    background-color: #F5F6FA;
  }
`
const ModifyOption = styled.div`
  display: flex;
  border-top: 1px solid #E6E9F4;
  user-select: none;

  & > div {
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    padding-left: 16px;
    align-items: center;
    display: flex;
  }

  & > div:first-child {
    width: 168px;
    min-width: 168px;
    color: #131523;
    background-color: #F5F6FA;
    font-size: 12px;
    font-weight: 800;
  }

  & > div:last-child {
    width: 100%;
    max-width: 616px;
    max-height: 48px;
  }
`

