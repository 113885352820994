import React,{useState} from 'react';
import ModalBase from './ModalBase'
import styled from 'styled-components'
import {
  GridTableComponent ,
  GridTableSelect , helperModule ,
  modalTitleBase , NoData ,
  orderComponent ,
  validationValue
} from '../../style/StyleComponent'
import MenuTitle from '../title/MenuTitle'
import TableValue from '../../style/TableValue'
import MainButtons from '../button/MainButtons'
import {
  authValid , dataAccessLoading , dataValid , decimalNumberValid , formatNumber , handleDecimalChange ,
  initModal , loadingRemove ,
} from '../../function/function'
import {Modal_View_Type} from '../../types/Fundamental'
import ToastPopup from './ToastPopup'
import ListInnerInput from '../input/ListInnerInput'
import client from '../../common/Config'
import {Authorization} from '../../common/authorization'
import {useRecoilValue} from 'recoil'
import {persistCompanyData , userPersistCompanyData} from '../../common/StateManage'
import AccountSelectModal from './AccountSelectModal'
import IntegratedBomSearchModal from './IntegratedBomSearchModal'
import SelectCaseModal from "./SelectCaseModal";

interface Process_Wave_Reg_Type{
  cancelEvent: ()=>void
  responseAPI:()=>void
}
const ProcessWaveProductRegModal:React.FunctionComponent<Process_Wave_Reg_Type> = ({cancelEvent, responseAPI}) => {

  const companyCodeState = useRecoilValue(authValid() ? persistCompanyData : userPersistCompanyData)
  const [dataModal, setDataModal] = useState<Modal_View_Type>(initModal)
  const [registerProduct, setRegisterProduct] = useState<any>({customer:[], cavity:'1'})
  const [rowList, setRowList] = useState<any>([])
  const [processPage, setProcessPage] = useState<number>(1)
  const [processTotalPage, setProcessTotalPage] = useState<number>(1)
  const [processList, setProcessList] = useState<any>([])
  const [selectedID, setSelectedID] = useState<number>(-1)
  const [rowCount, setRowCount] = useState(0)

  const processListRes = async () => {
    try {
      const response = await client.get(`/process/type/list/${processPage}/10/${companyCodeState.companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      setProcessTotalPage(data.total_page)
      if(data.total_page > 1 && processPage > 1){
        setProcessList((prev: any) => prev.concat(data.row))
      } else{
        setProcessList(data.row)
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  React.useEffect(()=>{
    processListRes().then()
  },[processPage])

  const processWaveRegister = async () => {
    if(rowList?.length === 0){
      setDataModal({menu:'warn', message:'공정 수를 선택해 주세요.'})
    }
    else if(rowList?.length === 0){
      setDataModal({menu:'warn', message:'공정 수를 선택해 주세요.'})
    }
    else if(registerProduct?.customer.length === 0){
      setDataModal({menu:'warn', message:'거래처를 등록해 주세요.'})
    }
    else if(!registerProduct?.serialCode){
      setDataModal({menu:'warn', message:'품목CODE를 입력해 주세요.'})
    }
    else if(!registerProduct?.doubleCheck){
      setDataModal({menu:'warn', message:'품목CODE 중복체크를 해주세요.'})
    }
    else if(!registerProduct?.productName){
      setDataModal({menu:'warn', message:'품명을 입력해 주세요.'})
    }
    else if(!rowList[0]?.bomInfo[0].productSubCode){
      setDataModal({menu:'warn', message:'투입자재를 입력해 주세요.'})
    }
    else if(rowList.some((v:any)=>!v.processCode) || (!registerProduct?.processCode)){
      setDataModal({menu:'warn', message:'선택하지 않은 공정이 있습니다.'})
    }
    else{
      try {
        dataAccessLoading()
        const response = await client.post('/product/process/register',
                                           (rowList.map((v:any)=>({...v,
                                             customerCode: registerProduct.customer.map((v:any)=>(v.customerCode)).filter((value:any, index:number, self:any) => self.indexOf(value) === index).join(','),
                                             customerModelCode: registerProduct.customer.map((v:any)=>(v.customerModelCode)).join(','),
                                             serialCode: registerProduct.serialCode,
                                             productName: registerProduct.productName, type:'반제품',
                                           })).concat(
                                             {...registerProduct, indexNo: rowList?.length, type:'완제품',
                                               companyCode: companyCodeState.companyCode ,
                                               customerCode: registerProduct.customer.map((v:any)=>(v.customerCode)).filter((value:any, index:number, self:any) => self.indexOf(value) === index).join(','),
                                               customerModelCode: registerProduct.customer.map((v:any)=>(v.customerModelCode)).join(','),
                                             }))
          ,{headers:{Authorization}})
        if(response.data.status === 200){
          setTimeout(()=>{
            loadingRemove()
            setDataModal({menu:'success', message:'품목이 등록 되었습니다.'})
            setTimeout(()=>{
              responseAPI()
            },1000)
          },500)
        }
        else{
          loadingRemove()
          setDataModal({menu:'warn', message: response.data.message})
        }
      }
      catch (e:any) {
        console.log(e)
        loadingRemove()
        setDataModal({menu:'error', message: e.message})
      }
    }
  }

  const serialDoubleCheck = async () => {
    if(!registerProduct.serialCode){
      setDataModal({menu:'warn', message:'시리얼CODE를 입력해 주세요.'})
    }
    else{
      try {
        const response = await client.post('/product/serial/check',{
          companyCode: companyCodeState.companyCode,
          serialCode: registerProduct.serialCode
        },{headers:{Authorization}})
        const duplicateCount = response.data.data
        if(duplicateCount[0] === 0){
          setDataModal({menu:'success', message:'중복되지 않은 시리얼CODE 입니다.'})
          setRegisterProduct({...registerProduct, doubleCheck: true})
        }
        else{
          setDataModal({menu:'doubleCheck', message:'이미 사용중인 시리얼CODE 입니다. \n 중복사용 하시겠습니까?'})
        }
      }
      catch (e) {
        console.log(e)
      }
    }
  }

  const customerCount = (customer: any) => customer.filter((v: any , i: number , arr: any) => arr.findIndex((el: any) => ( el.customerCode === v.customerCode )) === i)

  const typeDivision = rowList?.length === Number(selectedID)

  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {dataModal.menu === 'success' ?
            <ToastPopup text={(dataModal.message)} state={'success'}
                        closeEvent={() => {setDataModal(initModal)}}/>
            : dataModal.menu === 'error' ?
              <ToastPopup text={(dataModal.message)} state={'error'} closeEvent={() => {setDataModal(initModal)}}/>
              : dataModal.menu === 'warn' ? <ToastPopup text={(dataModal.message)} state={'warn'}
                                                        closeEvent={() => {setDataModal(initModal)}}/> : null}

          {dataModal.menu === 'customer' &&
              <AccountSelectModal
                  receivedData={(e)=> setRegisterProduct((prev:any)=>({...prev, customer: e}))}
                  closeModal={()=>setDataModal(initModal)}
                  initModelData={dataValid(registerProduct?.customer,[])}
                  initCustomerData={dataValid(registerProduct?.customer,[])}/>}

          {dataModal.menu === 'typeSelect' &&
              <IntegratedBomSearchModal authValid={authValid()} firstTypes={'원자재'} menuArrayType={'rawMaterial'}
                                        receiveData={(v: any) => {
                                          setSelectedID(0)
                                          setRowList(rowList?.map((el:any)=>{return el.indexNo === 0 ? {...el, bomInfo: [
                                              {...el.bomInfo[0],
                                                productSubCode: v.productCode || v.rawMaterialCode || v.subMaterialCode,
                                                serialCode: v.serialCode || v.rawMaterialSerialCode || v.subMaterialSerialCode,
                                                name: v.productName || v.rawMaterialName || v.subMaterialName,
                                                type: v.type || v.rawMaterialType || v.subMaterialType,
                                                unit: v.unit || v.rawMaterialUnit || v.subMaterialUnit,
                                              }
                                            ]
                                          } : el}))
                                        }}
                                        closeEvent={() => {setDataModal(initModal)}}/>}

          {dataModal.menu === 'bomInfoAdd' &&
              <IntegratedBomSearchModal authValid={authValid()} firstTypes={'반제품'}
                                        filterArray={
                                          rowList?.length === Number(selectedID)?
                                            registerProduct?.bomInfo.filter((v:any)=>(v.productSubCode)).map((el:any)=>(el.productSubCode))
                                            :
                                            rowList[selectedID]?.bomInfo.filter((v:any)=>(v.productSubCode)).map((el:any)=>(el.productSubCode))
                                        }
                                        menuArrayType={'process'}
                                        receiveData={(v: any) => {
                                          if(dataModal.view){
                                            setRegisterProduct( {...registerProduct ,
                                                                  bomInfo: registerProduct.bomInfo.concat( {
                                                                                                             productSubCode: v.productCode || v.rawMaterialCode || v.subMaterialCode,
                                                                                                             serialCode: v.serialCode || v.rawMaterialSerialCode || v.subMaterialSerialCode,
                                                                                                             name: v.productName || v.rawMaterialName || v.subMaterialName, type: v.type || v.rawMaterialType || v.subMaterialType,
                                                                                                             unit: v.unit || v.rawMaterialUnit || v.subMaterialUnit,
                                                                                                             requirementFormula: '1' ,
                                                                                                             usage: '1',
                                                                                                           } )
                                                                } )
                                          }
                                          else{
                                            setRowList( rowList.map( ( el: any ) => {
                                              return el.indexNo === selectedID ? {
                                                ...el ,
                                                bomInfo: el.bomInfo.concat( {
                                                                              productSubCode: v.productCode || v.rawMaterialCode || v.subMaterialCode,
                                                                              serialCode: v.serialCode || v.rawMaterialSerialCode || v.subMaterialSerialCode,
                                                                              name: v.productName || v.rawMaterialName || v.subMaterialName, type: v.type || v.rawMaterialType || v.subMaterialType,
                                                                              unit: v.unit || v.rawMaterialUnit || v.subMaterialUnit,
                                                                              requirementFormula: '1' ,
                                                                              usage: '1',
                                                                            } )
                                              } : el
                                            } ) )
                                          }
                                        }}
                                        closeEvent={() => {setDataModal(initModal)}}/>}

          {dataModal.menu === 'doubleCheck' &&
              <SelectCaseModal buttonTitle={{confirm: '중복확인'}}
                               statusText={dataModal.message}
                               noticeText={'* 중복 사용시 해당 CODE에 추가번호가 부여됩니다.'}
                               clickEvent={() => {
                                 setRegisterProduct({...registerProduct, serialCode: registerProduct.serialCode + '(1)', doubleCheck: true})
                                 setDataModal(initModal)
                               }}
                               cancelEvent={() => setDataModal(initModal)}
                               buttonWidth={'124px'} buttonFontSize={'12px'}/>}

          {modalTitleBase({mainTitle:'공정순 품목 등록', subTitle:'(현재 개발 진행중 입니다.)'})}
          <div className={'modal_content'}>
            <TableDefaultOption>
              <React.Fragment>
                <div className={'order_title'}>{'거래처'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'accountSearch'}
                                  inputData={dataValid(customerCount(registerProduct?.customer)[0]?.customerName) + ( dataValid(( customerCount(registerProduct?.customer).length - 1 ) , 0) > 0 ? ` 외 ${customerCount(registerProduct?.customer).length - 1}` : '' )}
                                  clickButton={()=>setDataModal({menu:'customer'})}/>
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className={'order_title'}>{'CODE'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'withButton'} width={'120px'} inputData={registerProduct?.serialCode}
                                  name={'serialCode'} placeholder={'시리얼CODE'}
                                  onChange={(e)=>setRegisterProduct((prev:any)=>({...prev, serialCode: e.target.value, doubleCheck: false}))}
                                  clickButton={serialDoubleCheck} disabled={registerProduct?.doubleCheck}
                                  buttonText={registerProduct?.doubleCheck? '확인됨' : '중복확인'} buttonWidth={'60px'}/>
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className={'order_title'}>{'세로(Feeder)'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'text'} name={'height'} disabled={true}
                                  onChange={(e)=>setRegisterProduct((prev:any)=>({...prev, height: e.target.value}))}
                                  placeholder={'세로(Feeder)'}/>
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className={'order_title'}>{'투입자재'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'search'} inputData={dataValid(rowList[0]?.bomInfo[0]?.serialCode)}
                                  clickButton={()=> {
                                    if(rowList?.length === 0){
                                      setDataModal({menu:'warn', message:'공정 수를 먼저 선택해주세요.'})
                                    }
                                    else{
                                      setDataModal( {menu: 'typeSelect'} )
                                    }
                                  }}/>
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className={'order_title'}>{'모델'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'accountSearch'}
                                  inputData={dataValid((registerProduct?.customer)[0]?.customerModelName) + ( dataValid(((registerProduct?.customer).length - 1 ) , 0) > 0 ? ` 외 ${(registerProduct?.customer).length - 1}` : '' )}
                                  clickButton={()=>setDataModal({menu:'customer'})}/>
                </div>
              </React.Fragment>
              <React.Fragment>
                <div className={'order_title'}>{'품명'}</div>
                <div className={'order_content'}>
                  <ListInnerInput type={'text'} name={'productName'}
                                  onChange={(e)=>setRegisterProduct((prev:any)=>({...prev, productName: e.target.value}))}
                                  placeholder={'품목명'}/>
                </div>
              </React.Fragment>
              {/*<React.Fragment>*/}
              {/*  <div className={'order_title'}>{'캐비티'}</div>*/}
              {/*  <div className={'order_content'}>*/}
              {/*    <ListInnerInput type={'text'} name={'cavity'} inputData={decimalNumberValid(dataValid(rowList[0]?.cavity,'0'))}*/}
              {/*                    onChange={(e)=> {*/}
              {/*                      handleDecimalChange(0 , e , rowList , setRowList , '')*/}
              {/*                      // setRegisterProduct((prev: any) => ( {...prev , cavity: e.target.value} ))*/}
              {/*                    }}*/}
              {/*                    placeholder={'캐비티'}/>*/}
              {/*  </div>*/}
              {/*</React.Fragment>*/}
              {orderComponent('-','-')}
              {orderComponent('-','-')}
            </TableDefaultOption>
            <div style={{marginTop:'16px'}}>
              <GridDivide>
                <div style={{borderBottom:'1px solid #D8D8D8'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <MenuTitle title={'공정 순서'} unitCase={'MenuCompTitle'}/>
                    <div style={{color:'red', fontSize:'12px', marginLeft:'16px'}}>{'현재 개발 진행중 입니다.'}</div>
                  </div>
                  <GridTableComponent tempCol={'40px 160px 96px 120px'} scrollWidth={570}
                                      scrollX_Hide={true} height={288}>
                    <div className={'grid-title-unchecked'}>
                      {['순서', '생산공정', '캐비티', '품목 타입'].map((v) => (
                        <TableValue title={v}
                                    requiredValue={[]} key={v}/>
                      ))}
                    </div>
                    {
                      rowList?.length === 0?
                        <NoData height={240}>{'* 공정 수를 입력해 주세요.'}</NoData>
                        :
                        <React.Fragment>
                          {rowList?.map( ( v: any , i: number ) => (
                            <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                             selected={v.indexNo === selectedID}
                                             onClick={()=>setSelectedID(v.indexNo)}>
                              {validationValue( i + 1 )}
                              <ListInnerInput type={'objectDrop'} name={'processName'}
                                              inputData={v?.processName} dropKey={'processName'}
                                              dropScrollHas={processTotalPage > processPage}
                                              dropScrollEvent={() => setProcessPage( ( prev ) => prev + 1 )}
                                              dropMenuList={processList}
                                              clickButton={()=>setSelectedID(v.indexNo)}
                                              selectDrop={( e ) => {
                                                setRowList( rowList.map( ( v: any ) => {
                                                  return v.indexNo === selectedID ? {
                                                    ...v , processCode: e?.processCode , processName: e?.processName} : v
                                                } ) )
                                              }}/>

                              <ListInnerInput type={'unit'} name={'cavity'}
                                              onChange={( e ) => {handleDecimalChange( i , e , rowList , setRowList , dataValid( v.unit , 'ea' ) )}}
                                              unit={dataValid( v.unit , 'ea' )} limit={5}
                                              inputData={decimalNumberValid( dataValid( v?.cavity , '0') )}/>
                              {validationValue('반제품')}
                            </GridTableSelect>
                          ) )}
                          <GridTableSelect className={'grid-list-unchecked-no_icon'} selected={typeDivision}
                                           onClick={()=>setSelectedID(Number(rowList?.length))}>
                            {validationValue(rowCount)}
                            <ListInnerInput type={'objectDrop'} name={'processName'}
                                            inputData={registerProduct?.processName} dropKey={'processName'}
                                            dropScrollHas={processTotalPage > processPage} dropTopDirection={true}
                                            dropScrollEvent={() => setProcessPage( ( prev ) => prev + 1 )}
                                            dropMenuList={processList}
                                            selectDrop={(e) => {
                                              setRegisterProduct({...registerProduct, processCode: e?.processCode , processName: e?.processName})
                                            }}/>
                            <ListInnerInput type={'unit'} name={'cavity'}
                                            onChange={( e ) => {setRegisterProduct((prev: any) => ( {...prev , cavity: e.target.value.replace(/[^0-9]/g, '')} ))}}
                                            unit={dataValid( registerProduct?.unit , 'ea' )} limit={5}
                                            inputData={decimalNumberValid( dataValid( registerProduct?.cavity , '0') )}/>
                            {validationValue('완제품')}
                          </GridTableSelect>
                        </React.Fragment>
                    }
                  </GridTableComponent>
                </div>
                <div style={{borderBottom:'1px solid #D8D8D8'}}>
                  <div style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <div style={{display:'flex', alignItems:'center', fontWeight:700}}>
                      <MenuTitle title={'BOM 정보'} unitCase={'MenuCompTitle'}/>
                      {
                        selectedID !== -1 &&
                          <div style={{color: '#0058ff' , fontSize: '18px' , fontWeight: 700 , marginLeft: '8px'}}>
                            {rowList?.length === Number(selectedID) ? '(마지막 공정)' : `(${selectedID+1}번 공정)`}
                          </div>
                      }
                      {helperModule('재고 소요량 \n · 캐비티 적용 : 생산량 * 사용량 \n · 캐비티 미적용 : (생산량 * 사용량) * 캐비티' , '230px' , '0 0 0 8px')}
                    </div>

                    <div style={{display:'flex'}}>
                      <div style={{maxWidth:'145px'}}>
                        <ListInnerInput type={'withButtonUnit'} inputData={rowCount === 0? '' : rowCount}
                                        onChange={(e)=>{setRowCount(e.target.value.replace(/[^0-9]/g, '').trim())}}
                                        disabled={rowList?.length !== 0 || !rowCount} inputDisabled={rowList?.length !== 0}
                                        clickButton={()=> {
                                          setRowList( Array( Number( rowCount - 1 ) ).fill( {
                                                                                              companyCode: companyCodeState.companyCode ,
                                                                                              productSubCode: '' ,
                                                                                              cavity: '1' , unit:'ea',
                                                                                              bomInfo: [{
                                                                                                productSubCode: '' ,
                                                                                                requirementFormula: '1' ,
                                                                                                usage: '1', unit:'ea'
                                                                                              }]
                                                                                            } ).map( ( v: any , i: number ) => ( {...v , indexNo: i
                                          })))
                                          setRegisterProduct({...registerProduct, bomInfo: [{
                                              productSubCode: '' ,
                                              requirementFormula: '1' ,
                                              usage: '1', unit: 'ea'
                                            }]})
                                        }
                                        }
                                        buttonText={'확인'}
                                        limit={2} unit={!rowCount? '' : '개'}
                                        buttonWidth={'50px'} placeholder={'공정 수 입력'}/>
                      </div>
                    </div>
                  </div>

                  <GridTableComponent tempCol={'160px 96px 72px 160px'} scrollWidth={570}
                                      scrollX_Hide={true} height={288}>
                    <div className={'grid-title-unchecked'}>
                      {['CODE' , '품명' , '품목종류'].map((v) => (
                        <TableValue title={v} requiredValue={['CODE' , '사용량 / 캐비티 적용']} viewType={false}
                                    key={v}/>
                      ))}
                      <div style={{display: 'flex'}}>
                        <TableValue title={'사용량 / 캐비티 적용'} requiredValue={['사용량 / 캐비티 적용']}
                                    viewType={false}/>
                      </div>
                    </div>
                    {
                      rowList?.length === 0?
                        <NoData height={240}>{'* 공정 수를 입력해 주세요.'}</NoData>
                        :
                        selectedID === -1?
                          <NoData height={240}>{'* 공정을 선택해 주세요.'}</NoData>
                          :
                          rowList?.length === Number(selectedID)?
                            registerProduct?.bomInfo?.map((v:any, i:number)=>(
                              <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                                {validationValue(v.serialCode, `${Number(rowCount)-1}번 공정 반제품`)}
                                {validationValue(v.name)}
                                {validationValue(v.type, '반제품')}
                                <ListInnerInput type={'withButtonUnit'} width={'80px'} name={'usage'} inputData={decimalNumberValid(v.usage)}
                                                limit={10}
                                                buttonText={v.requirementFormula === '2' ? '적용됨' : '캐비티'} buttonWidth={'48px'}
                                                checked={v.requirementFormula === '2'}
                                                clickButton={()=>{
                                                  const updateRequireFormula = registerProduct.bomInfo.map((inValue:any, inIndex:number)=>{
                                                    if(inIndex === i){
                                                      return {...inValue, requirementFormula: v.requirementFormula === '2' ? '1' : '2'}
                                                    }
                                                    else{
                                                      return inValue
                                                    }
                                                  })
                                                  return setRegisterProduct({...registerProduct, bomInfo: updateRequireFormula})
                                                }}
                                                onChange={(e)=>{
                                                  const updateUsage = registerProduct.bomInfo.map((inValue:any, inIndex:number)=>{
                                                    if(inIndex === i){
                                                      return {...inValue, usage: v.unit === 'ea'? e.target.value.replace(/[^0-9]/g, '').trim() : e.target.value.includes('.')? formatNumber(e.target.value).replace(',','') : e.target.value.replace(/[^0-9]/g, '').trim()}
                                                    }
                                                    else{
                                                      return inValue
                                                    }
                                                  })
                                                  return setRegisterProduct({...registerProduct, bomInfo: updateUsage})
                                                }}
                                                unit={dataValid(v.unit , 'ea')}/>
                              </GridTableSelect>
                            ))
                            :
                            rowList[selectedID]?.bomInfo?.map((v:any, i:number)=>(
                              <GridTableSelect className={'grid-list-unchecked-no_icon'}>
                                {validationValue(v.serialCode, selectedID === 0? '-' : `${selectedID}번 공정 반제품`)}
                                {validationValue(v.name)}
                                {validationValue(v.type, '반제품')}
                                <ListInnerInput type={'withButtonUnit'} width={'80px'} name={'usage'} inputData={decimalNumberValid(v.usage)}
                                                limit={10}
                                                buttonText={v.requirementFormula === '2' ? '적용됨' : '캐비티'} buttonWidth={'48px'}
                                                checked={v.requirementFormula === '2'}
                                                onChange={(e)=>{
                                                  // 가장 먼저 공정순 목록에서 선택한 indexNo를 비교하여, 동일한 No의 bomInfo를 가져온 뒤,
                                                  // 선택된 bom목록 index와 update할 bomInfo의 index와 일치하는 값만 변경해준다.
                                                  setRowList(rowList.map((el:any)=>{
                                                    if(el.indexNo === selectedID){
                                                      const updateUsage = el.bomInfo.map((inValue:any, inIndex: number)=>{
                                                        if(inIndex === i){
                                                          return {...inValue, usage: v.unit === 'ea'? e.target.value.replace(/[^0-9]/g, '').trim() : e.target.value.includes('.')? formatNumber(e.target.value).replace(',','') : e.target.value.replace(/[^0-9]/g, '').trim()}
                                                        }
                                                        else{
                                                          return inValue
                                                        }
                                                      })
                                                      return {...el, bomInfo: updateUsage}
                                                    }
                                                    return el
                                                  }))
                                                }}

                                                clickButton={() => {
                                                  setRowList(rowList.map((el:any)=>{
                                                    if(el.indexNo === selectedID){
                                                      const updateRequireFormula = el.bomInfo.map((inValue:any, inIndex: number)=>{
                                                        if(inIndex === i){
                                                          return {...inValue, requirementFormula: v.requirementFormula === '2' ? '1' : '2'}
                                                        }
                                                        else{
                                                          return inValue
                                                        }
                                                      })
                                                      return {...el, bomInfo: updateRequireFormula}
                                                    }
                                                    return el
                                                  }))
                                                }}
                                                unit={dataValid(v.unit , 'ea')}/>
                              </GridTableSelect>
                            ))
                    }
                    {(rowList?.length !== 0 && selectedID !== -1) &&
                        <div style={{display: 'flex' , alignItems: 'center' , justifyContent: 'center' , height: '48px'}}>
                          <MainButtons name={'BOM 정보 추가'} width={'120px'}
                                       clickEvent={() => {
                                         if(typeDivision){
                                           // view가 true인 경우 registerProduct(완제품)의 bom정보가 등록되도록.
                                           setDataModal({menu:'bomInfoAdd', view: true})
                                         }
                                         else{
                                           setDataModal({menu:'bomInfoAdd', view: false})
                                         }
                                       }
                                       }/>
                        </div>
                    }
                  </GridTableComponent>
                </div>
              </GridDivide>
            </div>

            <div style={{marginTop:'32px', display:'flex', justifyContent:'center'}}>
              <MainButtons name={'취소'} clickEvent={cancelEvent}/>
              <MainButtons buttonType={'cta'} name={'저장'}
                           // disabled={(window.localStorage.getItem('companyID') !== ('zestech' || 'zeseng' || "xiaonian" || 'parc' || 'pomadetaek'))}
                           disabled={!['zestech','zeseng','xiaonian','parc','pomadetaek'].some((v)=> window.localStorage.getItem('companyID') === v)}
                           clickEvent={processWaveRegister} />
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default ProcessWaveProductRegModal;

const BaseComponent = styled.div`
  width: 1230px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  user-select: none;
  justify-content: space-between;
  transition: height 0.3s;

  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
    cursor: move;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`

const TableDefaultOption =  styled.div`
  border-bottom: 1px solid #E6E9F4;
  display: grid;
  grid-template-columns: repeat(1, 104px 208px 104px 260px 104px 128px 104px 186px);
  align-items: center;
  box-sizing: border-box;

  .order {
    &_title {
      height: 34px;
      background-color: #F5F6FA;
      display: flex;
      align-items: center;
      padding-left: 16px;
      font-weight: bold;
      font-size: 12px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
    }

    &_content {
      font-size: 14px;
      font-weight: normal;
      color: #171725;
      display: flex;
      align-items: center;
      height: 34px;
      padding-left: 8px;
      box-sizing: border-box;
      border-top: 1px solid #E6E9F4;
      white-space: pre;
    }
  }
`

const GridDivide = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 580px);
  gap: 38px;
`