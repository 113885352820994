import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {
  GridTableComponent ,
  GridTableSelect ,
  modalTitleBase , tooltipModule ,
} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";

const DeleteCaseModal:React.FunctionComponent<{workOrder?:boolean, targetName: string, caseArray:{code: string, case: string}[], closeEvent:()=>void}> = ({caseArray, targetName,closeEvent, workOrder}) => {
  return (
    <React.Fragment>
      <ModalBase modalContent={
        <BaseComponent>
          {modalTitleBase({mainTitle: workOrder? '하위 BOM의 재고량이 없습니다.' : '다음의 이유로 삭제할 수 없습니다.'},)}
          <div className={'modal_content'}>
            <div>
                <GridTableComponent id={'grid_scroll'} tempCol={'144px 337px'}
                                    height={336} scrollWidth={514}
                                    scrollX_Hide={true}>
                  <div className={'grid-title-unchecked'}>
                    {[targetName, workOrder? '' : '사유'].map((v)=>(<div key={v}>{(v)}</div>))}
                  </div>
                  {caseArray?.map((v)=>(
                      <GridTableSelect className={'grid-list-unchecked-no_icon'}
                                       onMouseDown={()=>''}>
                        {tooltipModule(v.code)}
                        {tooltipModule(v.case)}
                      </GridTableSelect>
                    ))
                  }
                </GridTableComponent>
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
              <MainButtons name={'확인'} margin={'0 12px'} width={'144px'} clickEvent={closeEvent}/>
            </div>
          </div>
        </BaseComponent>
      }/>
    </React.Fragment>
  );
};
export default DeleteCaseModal;

const BaseComponent = styled.div`
  position: absolute;
  width: 556px;
  height: 512px;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  user-select: none;
  justify-content: space-between;
  
  .modal_title {
    display: flex;
    min-height: 56px;
    align-items: center;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .modal_content{
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    justify-content: space-between;
  }
`