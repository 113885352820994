import React , {useState} from 'react';
import {ContentFrame , SectionFrame} from "../../../../../style/StyleComponent";
import {useParams} from "react-router-dom";
import {
  Modal_View_Type ,
  RawMaterialRowType ,
} from "../../../../../types/Fundamental";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading , dataValid , decimalNumberValid , formatNumber ,
  initModal , loadingRemove , numberLocaleString ,
} from "../../../../../function/function";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import styled from "styled-components";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import {Authorization} from "../../../../../common/authorization";
import client from "../../../../../common/Config";
import {useTranslation} from "react-i18next";

const RawMaterialsDetail = () => {
  const {t}:any = useTranslation()
  const params = useParams()
  const {rawMaterialCode} = params
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany = useRecoilValue(authValid() ? persistCompanyData : userPersistCompanyData)
  // const [selectedCompany , setSelectedCompany] = useRecoilState<any>(  )
  const [selectPage, setSelectPage] = useState<number>(0)
  const [rawMaterialDetail, setRawMaterialDetail] = useState<RawMaterialRowType |any>({})
  const [deleteCaseArray, setDeleteCaseArray] = useState<any>([])
  const [accountCode , setAccountCode] = useState<any>({})
  const [rawMaterialInitState, setRawMaterialInitState] = useState<RawMaterialRowType | any>({})

  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string) => {
    const {name, value} = e.target
    setRawMaterialDetail({...rawMaterialDetail,[name]: type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? formatNumber(value).replace(/,/g,'') :
          value.trim()})
  }
  //원자재 디테일
  const rawMaterialDetailRes = async () => {
    try{
      const response = await client.get(  `/rawMaterial/detail/${rawMaterialCode}`,
                                          {headers: {Authorization}})
      const data = response.data;
      setRawMaterialDetail( {...data.data,codeDoubleCheck:true})
      setRawMaterialInitState(data.data)
    } catch( error ){
      console.log( `error` , error )
    }
  }
  React.useEffect(() => {
    rawMaterialDetailRes().then()
  }, [])


  const rawMaterialUpdateRes = async () => {
    if(!rawMaterialDetail.rawMaterialSerialCode){
      setDataModal({menu: 'confirm' , message: '원자재 CODE를 입력하지 않았습니다.'})
    } else if(!rawMaterialDetail.rawMaterialName){
      setDataModal({menu: 'confirm' , message: '원자재 품명을 입력하지 않았습니다.'})
    } else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post('/rawMaterial/update' , {
          ...rawMaterialDetail,
          companyCode: selectedCompany.companyCode ,
          rawMaterialSerialCode: rawMaterialDetail.rawMaterialSerialCode,
          // rawMaterialStock:rawMaterialDetail.rawMaterialStock.replace
        } , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          setDataModal({menu: 'confirm' , message: data.message})
          loadingRemove()
        } else{
          setTimeout(() => {
            setDataModal({menu: 'success' , message: '수정 되었습니다.'})
            loadingRemove()
            setSelectPage(0)
          } , 500)

        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }

  //원자재 삭제
  const rawDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/rawMaterial/delete` ,
                                         [rawMaterialCode]
        , {headers: {Authorization}})
      const data = response.data
      const bomCaseArray = data.data?.bom?.map((v: any) => ( {code: v , case: '하위 BOM으로 등록 되어 있습니다.'} ))
      const inventoryCaseArray = data.data?.inventory?.map((v: any) => ( {code: v , case: '원자재 재고가 존재합니다.'} ))
      const caseArray = [bomCaseArray , inventoryCaseArray].flat()
      if(data.status === 200){
        setTimeout(() => {
          setDataModal({menu: 'success' , message: '선택한 원자재 정보가 삭제 되었습니다.'})
          loadingRemove()
        } , 500)
        setTimeout( () => {
          window.location.href = `${authValid() ? '/admin/fas/rawmaterial' : '/fas/rawmaterial'}`
        } , 1500 )

      } else if(data.status === 601){
        setTimeout(() => {
          setDataModal({menu: 'deleteCase'})
          loadingRemove()
        } , 500)
      } else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }

  const serialDoubleCheck = async (serialCode: string) => {
    if (!serialCode) {
      setDataModal({ menu: 'confirm', message: '원자재 CODE를 입력해 주세요.' });
      return;
    }

    try {
      const response = await client.post('/rawMaterial/serial/check', {
        companyCode: selectedCompany.companyCode,
        rawMaterialSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 원자재 CODE 입니다.' });
        setRawMaterialDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
      }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 원자재 CODE 입니다.' });
        setRawMaterialDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      serialDoubleCheck(rawMaterialDetail.rawMaterialSerialCode).then()
    }
  }

  return (
    <SectionFrame>
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              viewType={true}
                              clickEvent={()=>{ window.location.href = authValid()? '/admin/fas/rawmaterial' : '/fas/rawmaterial'}}
          />
      }
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu ==='back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 저장없이 이동합니다.'}
                              viewType={true}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{
                                setRawMaterialDetail(rawMaterialInitState)
                                setDataModal(initModal)
                                setSelectPage(0)
                              }
                              }/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {
                                if(dataModal.index === 0){
                                  Object.assign(rawMaterialDetail ,
                                                {customer: accountCode.customerName , customerName: accountCode.customerName})
                                } else{
                                  Object.assign(rawMaterialDetail , {customer: accountCode.customerName , customerName: accountCode.customerName})
                                }
                                setDataModal(initModal)
                              }}
                              cancelEvent={() => {
                                setDataModal(initModal)
                                setAccountCode({})
                              }}
                              receiveData={setAccountCode}/>}

      {dataModal.menu === 'modify' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 변경전 데이터는 복구되지 않습니다.'}
                              cancelEvent={() => setDataModal(initModal)}
                              clickEvent={rawMaterialUpdateRes}/>}
      {selectPage === 0 ?
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 원자재 기본정보 > 조회'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'원자재 기본정보 조회'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'목록'} width={'56px'} clickEvent={() => {
                        window.location.href = `${authValid() ? '/admin/fas/rawmaterial' : '/fas/rawmaterial'}`
                      }}/>
                      <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   disabled={buttonAuthValid( 'update' , '7' )}
                                   clickEvent={() => {setSelectPage( 1 )}}/>
                    </div>
                  </div>
                </div>

                <ListInfoComponent title={'원자재 CODE'} value={dataValid( rawMaterialDetail?.rawMaterialSerialCode )}/>
                <ListInfoComponent title={'원자재 품명'} value={dataValid( rawMaterialDetail?.rawMaterialName )}/>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                  <ListInfoComponent title={'재질'} value={dataValid( rawMaterialDetail?.material )}/>
                  <ListInfoComponent title={'재질 종류'} value={dataValid( rawMaterialDetail?.materialType )}/>
                  <ListInfoComponent type={'unit'} unit={'mm'} title={'피더'} value={numberLocaleString( rawMaterialDetail?.feeder)}/>
                  <ListInfoComponent title={'규격'} value={dataValid( rawMaterialDetail?.standard )}/>
                  <ListInfoComponent type={'unit'} unit={'mm'} title={'가로'} value={numberLocaleString( rawMaterialDetail?.width )}/>
                  <ListInfoComponent type={'unit'} unit={'mm'} title={'세로'} value={numberLocaleString( rawMaterialDetail?.length )}/>
                  <ListInfoComponent type={'unit'} unit={'T'} title={'두께'} value={numberLocaleString( rawMaterialDetail?.thickness )}/>
                  <ListInfoComponent title={'단위'} value={dataValid( rawMaterialDetail?.rawMaterialUnit )}/>
                  <ListInfoComponent type={'unit'} unit={rawMaterialDetail?.rawMaterialUnit} title={'안전 재고량'} value={dataValid( rawMaterialDetail?.rawMaterialUnit !== 'ea' ? rawMaterialDetail?.rawMaterialStock : numberLocaleString(rawMaterialDetail?.rawMaterialStock) )}/>
                  <ListInfoComponent type={'unit'} unit={'일'} title={'사용 기준일'} value={dataValid(numberLocaleString( rawMaterialDetail?.standardDate) )}/>
                </div>
                  <ListInfoComponent title={'거래처'} value={dataValid( rawMaterialDetail?.customerName )} bottomBorder={true}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              {/*@ts-ignore*/}
              <div className={'scroll_comp'} style={{width: '379px' , overflowY: 'overlay'}}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>
        :
        // 수정 페이지
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 원자재 기본정보 > 수정'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'원자재 기본정보 수정'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={() => {
                        setDataModal( {menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )
                      }}/>
                      <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
                                   clickEvent={rawDeleteRes}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   clickEvent={rawMaterialUpdateRes}/>
                    </div>
                  </div>
                </div>
                <RegisterComponent type={'doubleCheck'} onKeyPress={onKeyPress} paddingRight={true}
                                   title={'원자재 CODE'} name={'rawMaterialSerialCode'} buttonName={rawMaterialDetail.codeDoubleCheck ? '확인됨' : '중복확인'}
                                   disabled={(!rawMaterialDetail.rawMaterialSerialCode || rawMaterialDetail.codeDoubleCheck)}
                                   value={rawMaterialDetail.rawMaterialSerialCode}
                                   required={true}
                                   onChange={(e)=>setRawMaterialDetail((prev:any)=>({...prev, rawMaterialSerialCode: e.target.value, codeDoubleCheck:false}))}
                                   clickEvent={() => {serialDoubleCheck( rawMaterialDetail.rawMaterialSerialCode ).then()}}/>
                <RegisterComponent type={'text'} title={'원자재 품명'} name={'rawMaterialName'}
                                   value={rawMaterialDetail.rawMaterialName} paddingRight={true}
                                   required={true} onChange={onChange}/>
                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                  <RegisterComponent type={'text'} title={'재질'} name={'material'} value={rawMaterialDetail.material}
                                     paddingRight={true} onChange={onChange}/>

                  <RegisterComponent type={'drop'} title={'재질 종류'}
                                     name={'materialType'} value={rawMaterialDetail.materialType} onChange={onChange}
                                     dropClickEvent={(v)=>{setRawMaterialDetail((prev:any) => ({...prev, materialType:v,
                                       rawMaterialUnit: v === 'COIL' ? 'kg' : 'ea',
                                       rawMaterialStock:'',length: v === 'COIL' ? '' : prev.length,
                                       standard: (v === '소재' || v === '절단')? 'ø' : '',
                                     }))}}
                                     dropArray={['COIL' , 'SHEET', '소재', '절단']}/>

                  <ListInfoComponent type={'unit'} unit={'mm'} title={'피더'} value={decimalNumberValid( rawMaterialDetail?.feeder)}/>

                  <RegisterComponent type={!(rawMaterialDetail.materialType === '소재' || rawMaterialDetail.materialType === '절단')? 'empty' : 'drop'} title={'규격'}
                                     name={'standard'} value={dataValid(rawMaterialDetail.standard)} onChange={onChange}
                                     disabled={!(rawMaterialDetail.materialType === '소재' || rawMaterialDetail.materialType === '절단')}
                                     dropClickEvent={(v)=>{setRawMaterialDetail((prev:any) => ({...prev, standard: v}))}}
                                     dropArray={['ø','六','□']}/>


                  <RegisterComponent type={'unit'} title={'가로'} name={'width'}  value={decimalNumberValid(rawMaterialDetail.width)}
                                     unit={'mm'}  paddingRight={true} limit={15}
                                     onChange={( e: any ) => {onChange( e , 'decimal' )}}/>

                  <RegisterComponent type={'unit'} title={'세로'} name={'length'}  value={decimalNumberValid(rawMaterialDetail.length)}
                                     unit={'mm'} paddingRight={true}  disabled={rawMaterialDetail?.materialType === 'COIL'} limit={15}
                                     onChange={( e: any ) => {onChange( e , 'decimal' )}}/>

                  <RegisterComponent type={'unit'} unit={'T'} title={'두께'} name={'thickness'}
                                     value={decimalNumberValid(rawMaterialDetail.thickness)} paddingRight={true} limit={15}
                                     onChange={(e)=>{onChange(e,'decimal')}}/>

                  <RegisterComponent type={'drop'} title={'단위'} name={'rawMaterialUnit'}
                                     disabled={rawMaterialDetail?.materialType === 'COIL' || rawMaterialDetail?.materialType === 'SHEET'}
                                     value={rawMaterialDetail.rawMaterialUnit}
                                     required={true}
                                     dropClickEvent={(v)=>{setRawMaterialDetail((prev:any) => ({...prev,rawMaterialUnit:v,rawMaterialStock:''}))}}
                                     // dropClickEvent={Object.assign( {} , rawMaterialDetail , {
                                     //   rawMaterialUnit: rawMaterialDetail.rawMaterialUnit ,
                                     //   rawMaterialStock: ''
                                     // } )}
                                     dropArray={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>

                  <RegisterComponent type={'unit'} title={'안전 재고량'} name={'rawMaterialStock'} paddingRight={true} limit={rawMaterialDetail?.rawMaterialUnit === 'ea' ? 11 : 15}
                                     value={rawMaterialDetail.rawMaterialUnit !== 'ea' ? rawMaterialDetail.rawMaterialStock : numberLocaleString(rawMaterialDetail.rawMaterialStock)}
                                     unit={rawMaterialDetail.rawMaterialUnit}
                                     onChange={( e: any ) => {onChange( e , rawMaterialDetail?.rawMaterialUnit === 'ea' ? 'number' :'decimal' )}}/>


                  <RegisterComponent type={'unit'} unit={'일'} title={'사용 기준일'} name={'standardDate'}
                                     value={numberLocaleString(rawMaterialDetail.standardDate)} paddingRight={true}
                                     onChange={(e)=>{onChange(e,'number')}}/>

                </div>
                  <RegisterComponent type={'search'} title={'거래처명'} name={'customerName'} paddingRight={true}
                                     value={rawMaterialDetail.customerName} bottomBorder={true} readOnly={true}
                                     onChange={onChange} clickEvent={() => {
                    setDataModal( {menu: 'account' , index: 0} )
                  }}/>

              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0', margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'} style={{width: '379px' , height: '600px' }}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>}
    </SectionFrame>
  );
};
export default RawMaterialsDetail;


const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`