import React from 'react';
import styled from "styled-components";
import ModalBase from "./ModalBase";
import {useLocation} from "react-router-dom";
import {ModalLabel, RegisterButton} from "../../style/StyleComponent";
import {useRecoilState} from "recoil";
import {LanguageAtom} from "../../common/StateManage";
import {useTranslation} from "react-i18next";

interface Props {
	closeModal:()=>void
}

const LanguageSelector:React.FunctionComponent<Props> = ({closeModal}) => {

	const location = useLocation()
	const { t }:any = useTranslation();

	const [selectLang, setSelectLang] = useRecoilState<{lang:string,text:string,index:number}>(LanguageAtom)

	return (
		<React.Fragment>
			<ModalBase modalContent={
				<BaseComponent>
					<ModalLabel location={location.pathname.slice(1,6) === 'admin'}>
						{t('언어')}
					</ModalLabel>
					<div style={{display:'flex',flexDirection:'column',boxSizing:'border-box', height:'268px',justifyContent:'space-between',padding: '33px 16px'}}>
						<div>
							<div style={{color:'rgba(23,23,37,0.5)',fontSize:'14px',fontWeight:700}}>{t('언어 선택')}</div>
							<div style={{marginTop:'8px'}}>
								{[{text:'English',lang:'en'}, {text:t('한국어'),lang:'ko'}].map((v,i)=>(
									<LanguageSelect onClick={
										()=> {
											setSelectLang({lang:v.lang, text:v.text, index: i})
										}
									}
										selected={i === selectLang.index}>{v.text}</LanguageSelect>
								))}
							</div>
						</div>
						<RegisterButton onClick={closeModal} style={{alignSelf:'center'}}>{t('확인')}</RegisterButton>
					</div>
				</BaseComponent>}/>
		</React.Fragment>
	);
};

export default LanguageSelector;

const BaseComponent = styled.div`
	width: 416px;
	height: 320px;
	box-sizing: border-box;
	overflow: hidden;
	border-radius: 6px;
	background-color: #fff;
`
const LanguageSelect = styled.div<{selected: boolean}>`
	height: 48px;
	line-height: 48px;
	box-sizing: border-box;
	padding-left: 15px;
	border: 1px solid ${ props => props.selected? '#0058ff' : '#fff'};
	cursor: pointer;
	color: ${ props => props.selected? '#0058ff' : '#171725'};
	font-weight: ${ props => props.selected? 700 : 400};
	background-color: ${ props => props.selected? 'rgba(0,88,255,0.05)' : '#fff'};
	
	&:hover{
		color: #0058ff;
		font-weight: 700;
		background-color: rgba(0,88,255,0.05);
		border: 1px solid #0058ff;
	}
`