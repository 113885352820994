import React , {useState} from 'react';
import {ContentFrame , SectionFrame} from '../../../../../style/StyleComponent';
import MenuTitle from "../../../../../components/title/MenuTitle";
import MainButtons from "../../../../../components/button/MainButtons";
import {
  authValid ,
  buttonAuthValid ,
  dataAccessLoading ,
  dataValid ,
  formatNumber ,
  initModal , loadingRemove , numberLocaleString
} from "../../../../../function/function";
import {Modal_View_Type , SubMaterialRowType} from "../../../../../types/Fundamental";
import {useRecoilValue} from "recoil";
import {persistCompanyData , userPersistCompanyData} from "../../../../../common/StateManage";
import ListInfoComponent from "../../../../../components/list/ListInfoComponent";
import RegisterComponent from "../../../../../components/input/RegisterComponent";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import client from "../../../../../common/Config";
import {Authorization} from "../../../../../common/authorization";
import SelectConfirmModal from "../../../../../components/modal/SelectConfirmModal";
import ToastPopup from "../../../../../components/modal/ToastPopup";
import ConfirmPopup from "../../../../../components/modal/ConfirmPopup";
import AccountSearchModal from "../../../../../components/modal/AccountSearchModal";
import {useTranslation} from "react-i18next";

const SubMaterialsDetail = () => {
  const {t}:any = useTranslation()

  const params =useParams()
  const {subMaterialCode} = params
  const [accountCode , setAccountCode] = useState<any>({})
  const [dataModal,setDataModal] = useState<Modal_View_Type>(initModal)
  const selectedCompany  = useRecoilValue<any>( authValid() ? persistCompanyData : userPersistCompanyData )
  const [selectPage, setSelectPage] = useState<number>(0)
  const [subMaterialDetail, setSubMaterialDetail] = useState<SubMaterialRowType | any>( {} )
  const [subMaterialInit, setSubMaterialInit] = useState<SubMaterialRowType | any>( {} )

  const onChange = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, type?:string) => {
    const {name, value} = e.target
    setSubMaterialDetail({...subMaterialDetail,[name]:type === 'number'? value.replace(/[^0-9]/g, '') :
        type === 'decimal'? formatNumber(value) :
          value.trim()})
  }
  const subMaterialDetailRes = async () => {
    try{
      const response = await client.get(  `/subMaterial/detail/${subMaterialCode}`,
                                          {headers: {Authorization}})
      const data = response.data;
      setSubMaterialDetail({...data.data, codeDoubleCheck:true})
      setSubMaterialInit(data.data)
    } catch( error ){
      console.log( `error` , error )
    }
  }
  React.useEffect(() => {
    subMaterialDetailRes().then()
  }, [])

  const subUpdateRes = async () => {
    if(!subMaterialDetail.subMaterialSerialCode){
      setDataModal({menu: 'confirm' , message: '부자재 CODE를 입력하지 않았습니다.'})
    } else if(!subMaterialDetail.subMaterialName){
      setDataModal({menu: 'confirm' , message: '부자재 품명을 입력하지 않았습니다.'})
    } else{
      try {
        setDataModal(initModal)
        dataAccessLoading()
        const response = await client.post(`/subMaterial/update` , {
          companyCode: selectedCompany?.companyCode ,
          ...subMaterialDetail
        } , {headers: {Authorization}})
        const data = response.data
        if(data.status !== 200){
          loadingRemove()
          setDataModal({menu: 'confirm' , message: data.message})
        } else{
          setTimeout(() => {
            setDataModal({menu: 'success' , message: '수정 되었습니다.'})
            loadingRemove()
            setSelectPage(0)
          } , 500)
        }
      }
      catch (e: any) {
        console.log(e)
        setDataModal({menu: 'error' , message: e.message})
        loadingRemove()
      }
    }
  }
  //부자재 시리얼코드 중복체크
  const serialDoubleCheck = async (serialCode: string) => {
    if (!serialCode) {
      setDataModal({ menu: 'confirm', message: '부자재 CODE를 입력해 주세요.' });
      return;
    }
    try {
      const response = await client.post('/subMaterial/serial/check', {
        companyCode: selectedCompany.companyCode,
        subMaterialSerialCode: serialCode
      }, { headers: { Authorization } });

      const duplicateCount = response.data.data;

      if  (duplicateCount > 0) {
        setDataModal({ menu: 'error', message: '중복된 부자재 CODE 입니다.' });
        setSubMaterialDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: false
        }));
      }
      else if (duplicateCount === 0) {
      } else {
        setDataModal({ menu: 'success', message: '사용 가능한 부자재 CODE 입니다.' });
        setSubMaterialDetail((prevState:any) => ({
          ...prevState,
          codeDoubleCheck: true
        }));
      }
    } catch (e) {
      console.error(e);
      setDataModal({ menu: 'error', message: '* 서버 에러 입니다. \n 중복 확인을 할 수 없습니다.' });
    }
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter'){
      serialDoubleCheck(subMaterialDetail.subMaterialSerialCode).then()
    }
  }


  //부자재 삭제
  const subDeleteRes = async () => {
    try {
      setDataModal(initModal)
      dataAccessLoading()
      const response = await client.post(`/subMaterial/delete` ,
                                         [subMaterialCode]
        , {headers: {Authorization}})
      const data = response.data
      const bomCaseArray = data.data?.bom?.map((v: any) => ( {code: v , case: '하위 BOM으로 등록 되어 있습니다.'} ))
      const inventoryCaseArray = data.data?.inventory?.map((v: any) => ( {code: v , case: '부자재 재고가 존재합니다.'} ))
      const caseArray = [bomCaseArray , inventoryCaseArray].flat()
      if(data.status === 200){
        setTimeout(() => {
          setDataModal({menu: 'success' , message: '선택한 부자재 정보가 삭제 되었습니다.'})
          loadingRemove()
        } , 500)
        setTimeout( () => {
          window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'
        } , 1500 )


      } else if(data.status === 601){
        setTimeout(() => {
          setDataModal({menu: 'deleteCase'})
          loadingRemove()
        } , 500)
      } else{
        setDataModal({menu: 'error' , message: data.message})
        loadingRemove()
      }
    }
    catch (e: any) {
      console.log(e)
      setDataModal({menu: 'error' , message: e.message})
      loadingRemove()
    }
  }

  return (

    <SectionFrame>
      {dataModal.menu === 'cancel' &&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={' * "예" 선택시 저장없이 이동합니다.'}
                              cancelEvent={()=>{setDataModal(initModal)}}
                              viewType={true}
                              clickEvent={()=>{ window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'}}
          />
      }
      {dataModal.menu === 'success' ? <ToastPopup text={t(dataModal.message)} state={'success'} closeEvent={()=>setDataModal(initModal)}/>
        : dataModal.menu === 'error' ? <ToastPopup text={t(dataModal.message)} state={'error'} closeEvent={()=>setDataModal(initModal)}/>
          : dataModal.menu === 'warn' ? <ToastPopup text={t(dataModal.message)} state={'warn'} closeEvent={()=>setDataModal(initModal)}/> : null}

      {dataModal.menu === 'confirm' &&
          <ConfirmPopup statusText={t(dataModal.message)} clickEvent={()=>{setDataModal(initModal)}}/>}

      {dataModal.menu === 'account' &&
          <AccountSearchModal selectCode={accountCode.customerCode} companyCode={selectedCompany.companyCode}
                              modalCase={'customer'}
                              authValid={authValid()}
                              confirmEvent={() => {
                                if(dataModal.index === 0){
                                  Object.assign(subMaterialDetail ,
                                                {customer: accountCode.customerName , customerName: accountCode.customerName})
                                } else{
                                  Object.assign(subMaterialDetail , {customer: accountCode.customerName , customerName: accountCode.customerName})
                                }
                                setDataModal(initModal)
                              }}
                              cancelEvent={() => {
                                setDataModal(initModal)
                                setAccountCode({})
                              }}
                              receiveData={setAccountCode}/>}
      {dataModal.menu ==='back'&&
          <SelectConfirmModal statusText={dataModal.message}
                              noticeText={'* "예" 선택시 저장없이 이동합니다.'}
                              viewType={true}
                              cancelEvent={()=>setDataModal(initModal)}
                              clickEvent={()=>{
                                setSubMaterialDetail(subMaterialInit)
                                setDataModal(initModal)
                                setSelectPage(0)
                              }
                              }/>}
      {selectPage === 0 ?
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 부자재 기본정보 > 조회'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{display: 'flex' , justifyContent: 'space-between' , alignItems: 'center' , marginBottom: '16px'}}>
                  <MenuTitle title={'부자재 기본정보 조회'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'목록'} width={'56px'} clickEvent={() => {
                        window.location.href = authValid() ? '/admin/fas/submaterial' : '/fas/submaterial'
                      }}/>
                      <MainButtons name={'수정'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   disabled={buttonAuthValid( 'update' , '7' )}
                                   clickEvent={() => {setSelectPage( 1 )}}/>
                    </div>
                  </div>
                </div>
                <ListInfoComponent title={'부자재 CODE'} value={dataValid(subMaterialDetail?.subMaterialSerialCode)}/>
                <ListInfoComponent title={'부자재 품명'} value={dataValid(subMaterialDetail?.subMaterialName)}/>

                <div style={{display: 'grid',gridTemplateColumns:'repeat(2,1fr)'}}>
                  <ListInfoComponent title={'단위'} value={dataValid(subMaterialDetail?.subMaterialUnit)}/>
                  <ListInfoComponent type={'unit'} unit={subMaterialDetail?.subMaterialUnit} title={'안전 재고량'}
                                     value={dataValid( subMaterialDetail?.subMaterialUnit !== 'ea' ? subMaterialDetail?.subMaterialStock : numberLocaleString(subMaterialDetail?.subMaterialStock) )}/>
                </div>

                <ListInfoComponent title={'거래처'} value={dataValid(subMaterialDetail?.customerName)} bottomBorder={true}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0' , margin: '48px 24px 0 24px'}}/>
              {/*@ts-ignore*/}
              <div className={'scroll_comp'} style={{width: '379px' , overflowY: 'overlay'}}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>
        :
        // 수정 페이지
        <React.Fragment>
          <MenuTitle title={(authValid()? '업체/' : '')+'기본정보 관리 > 부자재 기본정보 > 수정'}
                     unitCase={'MenuTitle'}/>
          <ContentFrame height={'680px'}>
            <div style={{display: 'flex' , height: '620px'}}>
              <RegisterComp>
                <div style={{
                  display: 'flex' ,
                  justifyContent: 'space-between' ,
                  alignItems: 'center' ,
                  marginBottom: '16px'
                }}>
                  <MenuTitle title={'부자재 기본정보 수정'} unitCase={'MenuCompTitle'}/>
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex'}}>
                      <MainButtons name={'취소'} width={'56px'} clickEvent={() => {
                        setDataModal( {menu: 'back' , message: '작성중인 내용이 저장되지 않았습니다.\n"취소" 하시겠습니까?'} )
                      }}/>
                      <MainButtons name={'삭제'} width={'56px'} buttonType={'delete'}
                                   clickEvent={() => {
                                     subDeleteRes().then()
                                   }}/>
                      <MainButtons name={'저장'} buttonType={'cta'} width={'56px'} marginDis={true}
                                   clickEvent={() => {
                                     subUpdateRes().then()
                                   }}/>
                    </div>
                  </div>
                </div>

                <RegisterComponent type={'doubleCheck'} onKeyPress={onKeyPress} paddingRight={true}
                                   title={'부자재 CODE'} name={'subMaterialSerialCode'} buttonName={subMaterialDetail.codeDoubleCheck ? '확인됨' : '중복확인'}
                                   disabled={(!subMaterialDetail.subMaterialSerialCode || subMaterialDetail.codeDoubleCheck)}
                                   value={subMaterialDetail.subMaterialSerialCode}
                                   required={true}
                                   onChange={(e)=>setSubMaterialDetail((prev:any)=>({...prev, subMaterialSerialCode: e.target.value, codeDoubleCheck:false}))}
                                   clickEvent={() => {serialDoubleCheck( subMaterialDetail.subMaterialSerialCode ).then()}}/>

                <RegisterComponent type={'text'} title={'부자재 품명'} name={'subMaterialName'}
                                   value={subMaterialDetail.subMaterialName} paddingRight={true}
                                   required={true} onChange={onChange}/>

                <div style={{display: 'grid' , gridTemplateColumns: 'repeat(2,1fr)'}}>
                  <RegisterComponent type={'drop'} title={'단위'} name={'subMaterialUnit'}
                                     value={subMaterialDetail.subMaterialUnit}
                                     required={true}
                                     dropClickEvent={(v)=>{setSubMaterialDetail((prev:any) => ({...prev,subMaterialUnit:v,subMaterialStock:''}))}}
                                     dropArray={['ea' , 'g' , 'kg' , 'Ton' , 'ml' , 'L']}/>


                  <RegisterComponent type={'unit'} title={'안전 재고량'} name={'subMaterialStock'} paddingRight={true} limit={subMaterialDetail?.subMaterialUnit === 'ea' ? 11 : 15}
                                     value={subMaterialDetail.subMaterialStock !== 'ea' ? subMaterialDetail.subMaterialStock : numberLocaleString(subMaterialDetail.subMaterialStock)}
                                     unit={subMaterialDetail.subMaterialUnit}
                                     onChange={( e: any ) => {onChange( e , subMaterialDetail?.subMaterialUnit === 'ea' ? 'number' :'decimal' )}}/>

                </div>
                  <RegisterComponent type={'search'} title={'거래처'} name={'customerName'} paddingRight={true}
                                     value={subMaterialDetail.customerName} bottomBorder={true} readOnly={true}
                                     onChange={() => {}} clickEvent={() => {
                    setDataModal( {menu: 'account' , index: 0} )
                  }}/>
              </RegisterComp>
              <div style={{width: '1px' , height: '568px' , backgroundColor: '#e0e0e0', margin: '48px 24px 0 24px'}}/>

              <div className={'scroll_comp'} style={{width: '379px' , height: '600px' }}>
              </div>
            </div>
          </ContentFrame>
        </React.Fragment>}
    </SectionFrame>
  );
};
export default SubMaterialsDetail;


const RegisterComp = styled.div`
  width: 784px;
  height: 100%;
  //.register_notice{
  //	min-height: 112px;
  //	height: 112px;
  //	}

  .scroll_func {
    width: 804px;
    height: 570px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 20px;
      display: block;
    }
  }
`