import React from 'react';
import styled from 'styled-components';
import ModalBase from "./ModalBase";
import SearchBar from "../input/SearchBar";
import {NoData , tooltipModule , validationValue} from "../../style/StyleComponent";
import MainButtons from "../button/MainButtons";
import {Tooltip , Zoom} from "@mui/material";
import client from "../../common/Config";
import {Authorization} from "../../common/authorization";
import {AccountInfoType} from "../../types/Fundamental";
import InfiniteScroll from "react-infinite-scroll-component";
import {dataValid , loadingRemove , modalListLoading} from "../../function/function";
import {useTranslation} from "react-i18next";
import {GridTableComponent, GridTableSelect} from "../../style/StyleModule";
import PaginationList from "../pagination/PaginationList";

interface AccountProps {
  value?: string | number | null | undefined
  keyFunction?: (e: React.ChangeEvent<HTMLInputElement>) => void
  receiveData: (object: any) => void
  selectCode?: string
  confirmEvent?: () => void
  cancelEvent?: () => void
  confirmModelEvent?: () => void
  cancelModelEvent?: () => void
  companyCode: string
  modalCase?: string
  searchKey?: string
  authValid?: boolean
  customerCode?: string
}

const AccountSearchModal = ({
                              confirmModelEvent , confirmEvent , cancelEvent , cancelModelEvent , customerCode ,
                              receiveData , companyCode , modalCase , searchKey , authValid
                            }: AccountProps) => {
  const [customerList , setCustomerList] = React.useState<AccountInfoType[]>([])
  const [selectAccount , setSelectAccount] = React.useState<any>()
  const [page , setPage] = React.useState<number>(1)
  const [pageBlock , setPageBlock] = React.useState<number>(0)
  const [totalPage , setTotalPage] = React.useState<number>(1)
  const [searchInput , setSearchInput] = React.useState<string>('')
  // 모델 검색 백 수정 되면 SearchKey 넣어야함.
  const [pushInput , setPushInput] = React.useState<string>(searchKey ?? '')
  // const [pushInput, setPushInput] = React.useState<string>('')
  const {t}: any = useTranslation()
  const customerListRes = async () => {
    try {
      modalListLoading()
      const response = await client.get(`/${modalCase}/list/${page}/${5}/${companyCode}` , {
        headers: {Authorization}
      })
      const data = response.data.data
      setTotalPage(data.total_page)
      loadingRemove()
      // if(data.total_page > 1 && page > 1){
      //   setCustomerList((prev: any) => prev.concat(data.row))
      // } else{
      //   setCustomerList(data.row)
      // }
        setCustomerList(data.row)
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const customerModelRes = async () => {
    try {
      modalListLoading()
      const response = await client.post(`/customerModel/search/${page}/${5}` , {
        customerCode: [customerCode]
      } , {
                                           headers: {Authorization}
                                         })
      const data = response.data.data
      setTotalPage(data.total_page)
      loadingRemove()
      // if(data.total_page > 1 && page > 1){
      //   setCustomerList((prev: any) => prev.concat(data.row))
      // } else{
      //   setCustomerList(data.row)
      // }
        setCustomerList(data.row)
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  const customerSearchListRes = async () => {
    try {
      modalListLoading()
      const response = await client.post(`/${modalCase}/search` , {
        ///${page}/${10}/${companyCode}/${pushInput}
        page: page ,
        size: 5 ,
        companyCode: companyCode ,
        keyword: pushInput
      } , {
                                           headers: {Authorization}
                                         })
      const data = response.data.data
      setTotalPage(data.total_page)
      loadingRemove()
      // if(data.total_page > 1 && page > 1){
      //   setCustomerList((prev: any) => prev.concat(data.row))
      // } else{
      //   setCustomerList(data.row)
      // }
        setCustomerList(data.row)
    }
    catch (e) {
      console.log(e)
      loadingRemove()
    }
  }
  React.useEffect(() => {
    if(pushInput === ''){
      if(modalCase === 'customer'){
        customerListRes().then()
      } else{
        customerModelRes().then()
      }
    } else{
      customerSearchListRes().then()
    }
  } , [pushInput , page])
  const modalCaseArray = () => {
    if(modalCase === 'customer'){
      return ['거래처명' , '주소']
    } else{
      return ['모델명' , '거래처명']
    }
  }
  const modalCaseContent = () => {
    if(modalCase === 'customer'){
      return (
        customerList.map((v) => (
          <GridTableSelect selected={v.customerCode === selectAccount}
                     className={'grid-list-unchecked'}
                     onDoubleClick={() => {
                       if(receiveData){
                         receiveData({
                                       customerCode: v.customerCode ,
                                       customerName: v.customerName , businessNumber: v.businessNumber
                                     })
                       }
                       if(confirmEvent){
                         confirmEvent()
                       }
                     }}
                     onClick={() => {
                       setSelectAccount(v.customerCode)
                       if(receiveData){
                         receiveData({
                                       customerCode: v.customerCode ,
                                       customerName: v.customerName , businessNumber: v.businessNumber
                                     })
                       }
                     }} key={v.customerCode}>
            <div>{v.customerName}</div>
            <Tooltip componentsProps={{tooltip: {sx: {backgroundColor: '#D7DBEC' , color: '#171725'}}}}
                     title={<div
                       style={{fontSize: '14px' , fontWeight: 700 , fontFamily: 'Nanum Gothic' , padding: '6px' , boxSizing: 'border-box'}}>{v.address}</div>}
                     TransitionComponent={Zoom} enterDelay={300} placement={'bottom'} followCursor>
              {validationValue(v.address)}
            </Tooltip>
          </GridTableSelect>
        )) )
    }
    if(modalCase === 'customerModel'){
      return (
        customerList.map((v) => (
          <GridTableSelect selected={v.customerModelCode === selectAccount}
                     className={'grid-list-unchecked'}
                     onDoubleClick={() => {
                       if(confirmModelEvent){
                         confirmModelEvent()
                       }
                       if(receiveData){
                         receiveData({
                                       customerModelCode: v.customerModelCode ,
                                       customerModelName: v.customerModelName
                                     })
                       }
                     }}
                     onClick={() => {
                       setSelectAccount(v.customerModelCode)
                       if(receiveData){
                         receiveData({
                                       customerModelCode: v.customerModelCode ,
                                       customerModelName: v.customerModelName
                                     })
                       }
                     }} key={v.customerModelCode}>
            {tooltipModule(dataValid(v.customerModelName , '-') , 11)}
            {tooltipModule(dataValid(v.customerName , '-') , 16)}

          </GridTableSelect>
        )) )
    }
  }
  return (
    <ModalBase modalContent={
      <BaseComponent>
        <div className={'modal_title'}
             style={{backgroundColor: `${authValid ? '#CFFA61' : '#0058ff'}` , color: `${authValid ? '#171725' : '#fff'}`}}
        >{t(modalCase === 'customer' ? '거래처 검색' : '거래처 모델 검색')}</div>
        <div className={'modal_list'}>
          <div style={{marginBottom: '24px'}}>
            {modalCase === 'customer' &&
              <SearchBar widthCus={'384px'}
                         value={searchInput}
                         keyFunction={(e) => setSearchInput(e.target.value.trimStart())}
                         clickEvent={() => {
                           setPushInput(searchInput)
                           setPage(1)
                         }}/>}
            <GridTableComponent tempCol={'128px 1fr'} scrollWidth={366} scrollY_Hide={true} scrollX_Hide={true} height={300}>
              <div className={'grid-title-unchecked'}>
                {modalCaseArray().map((value) => (
                  <div>{t(value)}</div> ))}
              </div>

              {customerList?.length !== 0 ?
                // <InfiniteScroll dataLength={customerList?.length === undefined ? 0 : customerList?.length}
                //                 loader={<div/>} hasMore={totalPage > page}
                //                 next={() => setPage((prev) => prev + 1)}
                //                 scrollableTarget={'scroll_func'}>
                //   <div className={'scroll_func'} id={'scroll_func'}>
                //   </div>
                // </InfiniteScroll>
                modalCaseContent()
                :
                <NoData height={260}>{t('*검색 결과가 없습니다.')}</NoData>
              }
            </GridTableComponent>
            <PaginationList limit={5} setPage={setPage} page={page} pageLimit={5} counts={totalPage} setBlockNum={setPageBlock} blockNum={pageBlock}/>
          </div>
          <div style={{display: 'flex' , alignSelf: 'center'}}>
            <MainButtons name={'취소'} buttonType={'popupNormal'} width={'144px'}
                         clickEvent={() => {
                           if(modalCase === 'customer'){
                             if(cancelEvent){
                               cancelEvent()
                             }
                           } else{
                             if(cancelModelEvent){
                               cancelModelEvent()
                             }
                           }
                         }
                         }/>

            <MainButtons name={'확인'} buttonType={'cta'} width={'144px'}
                         clickEvent={() => {
                           if(modalCase === 'customer'){
                             if(confirmEvent){
                               confirmEvent()
                             }
                           } else{
                             if(confirmModelEvent){
                               confirmModelEvent()
                             }
                           }
                         }
                         }/>
          </div>
        </div>
      </BaseComponent>
    }/>
  );
};
export default AccountSearchModal;
const BaseComponent = styled.div`
  position: absolute;
  width: 416px;
  box-sizing: border-box;

  .scroll_func {
    height: 262px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: block;
      width: 18px;
    }
  }


  .modal_title {
    height: 56px;
    border-radius: 6px 6px 0 0;
    box-shadow: 0 1px 4px #15223214;
    display: flex;
    align-items: center;
    padding: 0 24px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 18px;
    justify-content: space-between;
    user-select: none;
  }

  .modal_list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 16px 24px 24px;
    box-sizing: border-box;
    background-color: #fff;
    height: 486px;
    box-shadow: 0 1px 4px #15223214;
    border-radius: 0 0 6px 6px;
  }

`
